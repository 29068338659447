/* eslint-disable react/no-unescaped-entities */
import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { avaInterviewSelectors } from 'src/redux'
import withProps from 'recompose/withProps'
import ImgFounded from 'src/static/ic_ava_report_founded_mobile.png'
import ImgFunding from 'src/static/ic_ava_report_funding_mobile.png'
import ImgChart from 'src/static/ic_ava_report_chart_mobile.png'
import ImgRevenue from 'src/static/ic_ava_report_revenue_mobile.png'
import ImgUsers from 'src/static/ic_ava_report_users_mobile.png'
import ImgEployeesGroup from 'src/static/icEployeesGroup.png'
import { TitleText } from '../elements/styled-components'
import ReportNumberComponentMobile from './ReportNumberComponentMobile'
import RetryReport from './RetryReport'

const Container = withProps()(styled.div`
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  margin: 24px -18px 25px -18px;
  padding: 36px 0 35px 0;
`)

const TitleTextStyled = styled(TitleText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  margin-left: 18px;
  margin-bottom: 27px;
  width: auto;
`

const TextsContainer = styled.div`
  margin: 0 18px 24px 18px;
`

const Text = styled.div<{ color: string; link?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ color }) => color};
  text-decoration: ${({ link }) => (link ? 'underline' : 'none')};
`

const BoldText = styled(Text)`
  font-weight: 500;
  margin-bottom: 4px;
`

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const FlexRowContainer = styled(RowContainer)`
  margin: auto 0;
`

const SliderContainer = styled.div`
  margin: 0 0 35px 0;
`

const SliderItemContainer = styled.div`
  height: 410px;
  background: #fff;
  border: 1px solid #e1e3ea;
  border-radius: 8px;
  margin: 0 9px;
  padding: 16px;
  overflow-y: auto;
  display: flex !important;
  flex-direction: column;
`

const NumbersBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const EmployessIcon = styled.img`
  width: 106px;
  height: 42px;
  object-fit: contain;
`

const EmployeesCountStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #222b45;
`

const EmployeesLabelStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #222b45;
`

const isEmpty = (obj) => {
  return !obj || Object.keys(obj).length === 0
}

const CompanyOverviewComponentMobile = () => {
  const interview = useSelector(avaInterviewSelectors.data)

  const openCompanyWebsite = useCallback(() => {
    let { website } = interview.position.company_overview
    if (!website.startsWith('http')) {
      website = `https://${website}`
    }
    window.open(website, '_blank', 'noreferrer')
  }, [interview])

  if (!interview) return undefined

  return (
    <Container>
      <TitleTextStyled>Company overview</TitleTextStyled>
      {!isEmpty(interview.position?.company_overview) && (
        <TextsContainer>
          <BoldText color="#F3F3F3">{interview.position.company}</BoldText>
          <RowContainer>
            <ColContainer>
              <Text color="#F3F3F3">
                {interview.position.company_overview.industry} company
              </Text>
              <Text color="#F3F3F3">
                Headquartered in {interview.position.company_overview.headquarters}
              </Text>
            </ColContainer>
            <ColContainer>
              <Text color="#F3F3F3" link onClick={openCompanyWebsite}>
                Company Website
              </Text>
            </ColContainer>
          </RowContainer>
        </TextsContainer>
      )}

      <SliderContainer>
        <Slider
          infinite
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          speed={400}
          centerMode
          centerPadding="30px"
        >
          <SliderItemContainer>
            {!isEmpty(interview.position?.company_overview) && (
              <>
                <ReportNumberComponentMobile
                  icon={ImgFounded}
                  text="Founded"
                  value={interview.position.company_overview.founded}
                />
                <FlexRowContainer>
                  <ColContainer>
                    <EmployeesCountStyled>
                      {interview.position.company_overview.number_of_employees}
                    </EmployeesCountStyled>
                    <EmployeesLabelStyled>employees</EmployeesLabelStyled>
                  </ColContainer>
                  <EmployessIcon src={ImgEployeesGroup} alt="eployeesGroupIcon" />
                </FlexRowContainer>
                <NumbersBlockContainer>
                  <ReportNumberComponentMobile
                    icon={ImgRevenue}
                    text="Revenue"
                    value={interview.position.company_overview.revenue}
                    unavailable={!interview.position.company_overview.revenue}
                  />
                  <ReportNumberComponentMobile
                    icon={ImgUsers}
                    text="Customers"
                    value={interview.position.company_overview.number_of_customers}
                    unavailable={!interview.position.company_overview.number_of_customers}
                  />
                  <ReportNumberComponentMobile
                    icon={ImgFunding}
                    text="Funding"
                    value={interview.position.company_overview.funding}
                    unavailable={!interview.position.company_overview.funding}
                  />
                  <ReportNumberComponentMobile
                    icon={ImgChart}
                    text="Valuation"
                    value={interview.position.company_overview.valuation}
                    unavailable={!interview.position.company_overview.valuation}
                  />
                </NumbersBlockContainer>
              </>
            )}
            {isEmpty(interview.position?.company_overview) && <RetryReport />}
          </SliderItemContainer>
          <SliderItemContainer>
            <BoldText color="#3B424F">{`${interview.position.company} at a glance`}</BoldText>
            {interview.position.company_values && (
              <Text color="#3B424F">{interview.position.company_values}</Text>
            )}
            {!interview.position.company_values && <RetryReport />}
          </SliderItemContainer>
          <SliderItemContainer>
            <BoldText color="#3B424F">Market and competitive landscape</BoldText>
            {interview.position.company_market && (
              <Text color="#3B424F">{interview.position.company_market}</Text>
            )}
            {!interview.position.company_market && <RetryReport />}
          </SliderItemContainer>
        </Slider>
      </SliderContainer>
    </Container>
  )
}

export default CompanyOverviewComponentMobile
