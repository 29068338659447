import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import * as authSelectors from '../selectors'
import { changePassword, changePasswordClearError } from '../actions'
import { Input, Button, Notification } from '../../components'

const Form = styled('form')({
  marginBottom: '12px',
})

const checkFieldsFill = (fields: string[], values: object = {}) => {
  return fields.reduce((prev, curr) => !!values[curr] && prev, true)
}

function ResetForm() {
  const dispatch = useDispatch()
  const apiError = useSelector(authSelectors.changePassword.error)
  const isLoading = useSelector(authSelectors.changePassword.isLoading)
  const { handleSubmit, errors, control, getValues } = useForm({ mode: 'onChange' })
  const onSubmit = (payload) => {
    dispatch(changePassword(payload))
  }
  const clearError = () => {
    dispatch(changePasswordClearError())
  }

  const disabled = !checkFieldsFill(['password'], getValues())
  const hasFormErrors = Object.keys(errors).length > 0

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {apiError && <Notification onClose={clearError} message={apiError} />}
      <Controller
        as={Input}
        label="New password"
        name="password"
        type="password"
        disableWhitespaces
        control={control}
        error={errors.password}
        rules={{
          required: true,
          validate: {
            letterCase: (value) =>
              (/[a-z]/.test(value) && /[A-Z]/.test(value)) ||
              'Password should have one lowercase, one uppercase letter',
            letterAndDigit: (value) =>
              (/\d/.test(value) && /[a-zA-Z]/.test(value)) ||
              'Password should contain a number and a letter',
            length: (value) =>
              value.length >= 8 ||
              'Please make sure your password is at least 8 characters long',
          },
        }}
        defaultValue=""
      />
      <Button disabled={isLoading || disabled || hasFormErrors} title="Change password" />
    </Form>
  )
}

export default ResetForm
