import styled from '@emotion/styled'
import React from 'react'

import SvgVideo from 'src/static/components/SvgVideo'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
  MobileHeaderButtonContainer,
  MobileHeaderButtonLabel,
} from './BaseButtons'

const VideoButtonContainer = styled(HeaderButtonContainer)`
  cursor: default;
`

interface IVideoMuteButton extends React.HTMLProps<HTMLDivElement> {
  active: boolean
  disabled: boolean
  theme: any
  onClick: () => void
}

export function VideoMuteButton({
  active,
  disabled,
  theme,
  onClick,
  children,
}: IVideoMuteButton) {
  const label = active ? 'Stop video' : 'Show video'
  return (
    <VideoButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgVideo inactive={!active} disabled={disabled} fill={theme.textColor} />
        <HeaderButtonLabel>{label}</HeaderButtonLabel>
      </HeaderIconContainer>
      {children}
    </VideoButtonContainer>
  )
}

interface IVideoMuteButtonMobile {
  active: boolean
  disabled: boolean
  theme: any
  onClick: () => void
}

export function VideoMuteButtonMobile({
  active,
  disabled,
  theme,
  onClick,
}: IVideoMuteButton) {
  const label = active ? 'Stop video' : 'Show video'
  return (
    <MobileHeaderButtonContainer onClick={!disabled ? onClick : undefined}>
      <SvgVideo inactive={!active} disabled={disabled} fill={theme.textColor} />
      <MobileHeaderButtonLabel>{label}</MobileHeaderButtonLabel>
    </MobileHeaderButtonContainer>
  )
}
