import React from 'react'

interface SvgProps {
  fill: string
  disabled: boolean
  inactive: boolean
}

function SvgUnmuteWhite({ fill, disabled, inactive }: SvgProps) {
  return (
    <svg
      className="svg"
      style={{ overflow: 'visible' }}
      width={37}
      height={21}
      viewBox="0 0 37 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M22.0857 9.6544C22.0857 9.68709 22.0857 9.85188 22.0857 9.90641C22.0857 11.6983 20.5305 13.1561 18.619 13.1561C16.7637 13.1561 15.2441 11.7828 15.1563 10.0636L15.1523 9.90641V5.23304C15.1523 3.44115 16.7075 1.98334 18.619 1.98334C20.4743 1.98334 21.9939 3.35666 22.0817 5.07583L22.0857 5.23304V9.6544ZM20.765 5.23304C20.765 4.12378 19.8023 3.22132 18.619 3.22132C17.4812 3.22132 16.5474 4.05569 16.4772 5.106L16.473 5.23304V9.90641C16.473 11.0157 17.4357 11.9181 18.619 11.9181C19.8023 11.9181 20.765 11.0157 20.765 9.90641C20.765 9.87006 20.765 9.78605 20.765 9.6544V5.23304Z"
          fill={fill || '#000'}
        />
        <path
          d="M24.6856 9.52401C24.6856 9.15666 24.392 8.85889 24.0297 8.85889C23.6675 8.85889 23.3739 9.15666 23.3739 9.52401C23.3739 12.183 21.2408 14.3462 18.6189 14.3462C15.997 14.3462 13.864 12.183 13.864 9.52401C13.864 9.15666 13.5703 8.85889 13.2081 8.85889C12.8459 8.85889 12.5522 9.15666 12.5522 9.52401C12.5522 12.6918 14.9252 15.3081 17.9631 15.6407V17.2535C17.8591 17.9597 18.0778 18.3128 18.6189 18.3128C19.1601 18.3128 19.3787 17.9597 19.2748 17.2535V15.6407C22.3127 15.3081 24.6856 12.6918 24.6856 9.52401Z"
          fill={fill || '#000'}
        />
        {inactive && !disabled && (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2 4.95833C13.4703 4.95833 14.6203 5.46891 15.4527 6.29441C16.2851 7.11991 16.8 8.26033 16.8 9.52V13.09C16.8 14.3497 16.2851 15.4901 15.4527 16.3156C14.6203 17.1411 13.4703 17.6517 12.2 17.6517H8.6C7.32974 17.6517 6.17975 17.1411 5.34731 16.3156C4.51487 15.4901 4 14.3497 4 13.09V9.52C4 8.26033 4.51487 7.11991 5.34731 6.29441C6.17975 5.46891 7.32974 4.95833 8.6 4.95833H12.2Z"
              fill="white"
              stroke="#1D0E3B"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2 5.95C14.1882 5.95 15.8 7.54834 15.8 9.52V13.09C15.8 15.0617 14.1882 16.66 12.2 16.66H8.6C6.61177 16.66 5 15.0617 5 13.09V9.52C5 7.54834 6.61177 5.95 8.6 5.95H12.2ZM8.96774 9.04977C8.73236 8.86828 8.39203 8.88478 8.17574 9.09927C7.95945 9.31376 7.94281 9.65126 8.12582 9.88467L8.17574 9.94072L9.551 11.305L8.17574 12.6693C7.94142 12.9016 7.94142 13.2784 8.17574 13.5107C8.39203 13.7252 8.73236 13.7417 8.96774 13.5602L9.02426 13.5107L10.4 12.1469L11.7757 13.5107L11.8323 13.5602C12.0676 13.7417 12.408 13.7252 12.6243 13.5107C12.8406 13.2962 12.8572 12.9587 12.6742 12.7253L12.6243 12.6693L11.249 11.305L12.6243 9.94072C12.8586 9.70836 12.8586 9.33163 12.6243 9.09927C12.408 8.88478 12.0676 8.86828 11.8323 9.04977L11.7757 9.09927L10.4 10.4631L9.02426 9.09927L8.96774 9.04977Z"
              fill="#FF002A"
            />
          </>
        )}
      </g>
    </svg>
  )
}

export default SvgUnmuteWhite
