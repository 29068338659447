import React from 'react'
import styled from '@emotion/styled'

import SvgSettingIcon from 'src/static/components/SvgSettings'
import SvgUser from 'src/static/components/SvgUser'
import SvgChat from 'src/static/components/SvgChat'
import SvgBag from 'src/static/components/SvgBag'
import SvgNotes from 'src/static/components/SvgNotes'

import { Text } from '../MobileText'

const Container = styled.div<{ hasMask: boolean }>`
  align-items: center;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  border-radius: ${({ hasMask }) => (hasMask ? '20px' : '20px 20px 0 0')};
  display: flex;
  flex-direction: column;
  margin: 0px 24px 0 24px;
  padding: 40px 20px 10px 20px;

  @media (orientation: landscape) {
    border-radius: 20px;
    margin: 0;
    padding: 38px 24px 24px 24px;
  }
`

const MenuItem = styled.div<{ inactive: boolean }>`
  align-items: center;
  border-top: ${({ theme }: { theme: any }) =>
    `0.5px solid ${theme.menuSidebarSeparatorColor}`};
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 15px 5px;
  width: 100%;
  opacity: ${({ inactive }) => (inactive ? '0.5' : '1')};

  svg {
    width: 20px;
    height: auto;
  }

  &:first-of-type {
    border-top: none;
  }

  @media (orientation: landscape) {
    padding: 10px 20px;
  }
`

const MenuItemLabel = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`

export enum MenuItemId {
  Settings = 'settings',
  Details = 'details',
  SessionDetails = 'sessionDetails',
  Chat = 'chat',
  PersonalNotes = 'personalNotes',
  Feedback = 'feedback',
}

export interface IMenuSidebar {
  hasMask: boolean
  isExpert: boolean
  theme: any
  inactive: boolean
  joinedToCall: boolean
  showChatBadge: boolean
  onSelect: (id: MenuItemId) => void
}

export default function MenuSidebar({
  hasMask,
  isExpert,
  theme,
  inactive,
  joinedToCall,
  showChatBadge,
  onSelect,
}: IMenuSidebar) {
  const items = [
    {
      id: MenuItemId.Settings,
      label: 'Settings',
      inactive,
      icon: (fill) => <SvgSettingIcon fill={fill} />,
    },
    {
      id: MenuItemId.Chat,
      label: 'Chat',
      inactive: !joinedToCall,
      icon: (fill) => (
        <SvgChat
          style={{ width: '24px', height: '24px' }}
          fill={fill}
          showBadge={showChatBadge}
        />
      ),
    },
    {
      id: MenuItemId.Details,
      label: isExpert ? 'Candidate profile' : 'Expert profile',
      inactive: false,
      icon: (fill) => <SvgUser fill={fill} />,
    },
  ]

  if (isExpert) {
    items.splice(2, 0, {
      id: MenuItemId.SessionDetails,
      label: 'Session details',
      inactive: false,
      icon: (fill) => <SvgBag fill={fill} />,
    })
  }

  if (!isExpert) {
    items.push({
      id: MenuItemId.PersonalNotes,
      label: 'Personal notes',
      inactive: false,
      icon: (fill) => <SvgNotes fill={fill} />,
    })
  }

  if (isExpert) {
    items.push({
      id: MenuItemId.Feedback,
      label: 'Feedback',
      inactive: false,
      icon: (fill) => <SvgNotes fill={fill} />,
    })
  }

  return (
    <Container hasMask={hasMask}>
      {items.map((i) => (
        <MenuItem
          inactive={i.inactive}
          key={i.id}
          onClick={!i.inactive ? () => onSelect(i.id) : null}
        >
          <MenuItemLabel>{i.label}</MenuItemLabel>
          {i.icon(theme.textColor)}
        </MenuItem>
      ))}
    </Container>
  )
}
