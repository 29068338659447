import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  avaGetInterviewAction,
  avaQuestionSelectors,
  avaSettingsSelectors,
  updateAvaSettingsAction,
} from '../../redux'
import Header from '../components/Header'
import AIQuestionComponent from '../components/interview/AIQuestionComponent'
import { InterviewPart } from '../models'
import RotatePhoneComponent from '../components/elements/RotatePhoneComponent'
import SettingsBar from '../components/elements/SettingsBar'
import useInterview from '../services/useInterview'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${isMobileOnly &&
  css`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #f6f4fd;
    min-height: 100%;
    min-width: 100%;
  `}
`

function InterviewContainer() {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const [reportClicked, setReportClicked] = useState(false)

  const question = useSelector(avaQuestionSelectors.data)
  const avaSettings = useSelector(avaSettingsSelectors.data)

  const { canGoBack, goNextUnanswered, questions, goBack } = useInterview(interviewToken)

  useEffect(() => {
    if (interviewToken) dispatch(avaGetInterviewAction({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const microphones = devices.filter((d) => d.kind === 'audioinput')
      if (microphones.length > 0) {
        dispatch(
          updateAvaSettingsAction({
            ...avaSettings,
            microphoneId: microphones[0].deviceId,
          })
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!question && !!questions) goNextUnanswered()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, questions])

  return (
    <Layout>
      <Header
        showInterviewPart
        showSettingsButton
        showReportButton
        showBackButton={canGoBack}
        interviewPart={InterviewPart.Interview}
        onReportClick={() => setReportClicked(true)}
        onBack={goBack}
      />
      <AIQuestionComponent
        reportClicked={reportClicked}
        clearReportClicked={() => setReportClicked(false)}
      />
      {isMobileOnly && <RotatePhoneComponent />}
      <SettingsBar />
      <audio id="ava-audio" />
    </Layout>
  )
}

export default InterviewContainer
