import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { NotAllowed, NotFound } from '../components'
import * as interviewSelectors from '../interview/selectors'
import { isSuperAdminRole } from '../utils/jwtToken'

import AvaLayout from './Layout'
import AvaPromptsContainer from './containers/AvaPromptsContainer'

function PromptsHOC({ children }: { children: JSX.Element }) {
  return isSuperAdminRole() ? children : <NotAllowed />
}

function AvaHOC({ children }: { children: JSX.Element }) {
  const agency = useSelector(interviewSelectors.agency)

  if (agency?.id === '') return null
  return agency?.enable_ava ? children : <NotFound />
}

function Ava() {
  return (
    <Switch>
      <Route exact path="/ava/:interviewToken">
        <AvaHOC>
          <AvaLayout />
        </AvaHOC>
      </Route>
      <Route exact path="/ava/:interviewToken/prompts">
        <PromptsHOC>
          <AvaPromptsContainer />
        </PromptsHOC>
      </Route>
      <Route path="/ava">
        <AvaHOC>
          <AvaLayout />
        </AvaHOC>
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Ava
