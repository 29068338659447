import React from 'react'

interface SvgProps {
  fill?: string
}

function SvgMicCoross({ fill }: SvgProps) {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.17586 2.86394C7.79312 2.24668 8.63031 1.8999 9.50325 1.8999C10.3762 1.8999 11.2134 2.24668 11.8306 2.86394C12.4479 3.4812 12.7947 4.31839 12.7947 5.19133V8.4999M1.90005 10.677C2.16588 12.5035 3.08043 14.1733 4.4764 15.3808C5.87236 16.5883 7.6564 17.2528 9.50215 17.2528M9.50215 17.2528C11.3479 17.2528 13.1319 16.5883 14.5279 15.3808C15.9239 14.1733 16.8384 12.5035 17.1043 10.677M9.50215 17.2528V21.0999M6.21182 7.2999V9.5799C6.21182 10.4528 6.55859 11.29 7.17586 11.9073C7.79312 12.5246 8.63031 12.8713 9.50325 12.8713C10.0618 12.8713 10.6056 12.7294 11.0866 12.4655M1.30005 1.8999L18.7 19.2999"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

SvgMicCoross.defaultProps = {
  fill: 'black',
}

export default SvgMicCoross
