/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { isEqualDate, normalizeDate } from 'src/utils/date'
import { useSelector } from 'react-redux'
import { IExpertProfile, IInterview, ITimeSlot } from 'src/api/models'
import SvgCalendar from 'src/static/components/SvgCalendar'
import SvgPrice from 'src/static/components/SvgPrice'
import closeIcon from 'src/static/close.svg'
import { get } from 'lodash'
import isSlotExpired from 'src/utils/slots'
import useBranding from 'src/utils/useBranding'
import * as interviewSelectors from '../selectors'
import { Avatar } from '../../components'

const DaysContainer = styled.div``

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(115, 121, 145, 0.3);
  backdrop-filter: blur(10px);
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  max-height: 100%;
  background: white;
  border-radius: 16px 16px 0px 0px;
`

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  padding: 16px;
  overflow-y: auto;
`

const DayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #edeef2;
  padding: 12px 0 6px 0;
`

const DayLabelContainer = styled.div`
  display: flex;
  width: 108px;
  align-items: center;
  justify-content: space-between;

  > svg {
    margin-right: 15px;
  }
`

const WeekDay = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #353c5a;
`

const DateLabel = styled(WeekDay)`
  font-weight: 500;
`

const SlotContainer = styled.div<{ selected?: boolean; fill?: string }>`
  width: 76px;
  height: 40px;
  background: ${({ selected, fill }) => (selected ? fill ?? '#B202FF' : '#ffffff')};
  border: 1px solid #e1e3ea;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ selected }) => (selected ? 'white' : '#3b424f')};
  margin: 6px 8px 6px 8px;
  cursor: pointer;

  &:nth-child(5n + 6) {
    margin-left: 24px;
  }
`

const ExpertFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const SelectedSlotDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: break-spaces;
`

const BottomContainer = styled.div`
  box-shadow: 0px -2px 16px rgba(115, 121, 145, 0.16);
  padding: 16px;
`

const PriceBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`

const PriceText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #200e32;
  margin: 0 4px;
`

const Button = styled.div<{ fill: string; disabled?: boolean }>`
  background: ${(props) => props.fill ?? '#B202FF'};
  border-radius: 4px;
  color: white;
  width: 162px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-self: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`

const OutlineButton = styled(Button)`
  color: ${(props) => props.fill ?? '#B202FF'};
  border: 1px solid ${(props) => props.fill ?? '#B202FF'};
  background: white;
  height: auto;
  width: auto;
  padding: 5px 16px;
  font-size: 12px;
  margin: 12px 0;

  &:active {
    opacity: 0.5;
  }
`

const EmptyContainer = styled.div<{ boldText?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
  font-weight: ${({ boldText }) => (boldText ? 500 : 300)};
  font-size: 14px;
  line-height: 26px;
  color: #3b424f;
`

const ExpertContainer = styled.div<{ fill: string }>`
  width: auto;
  box-sizing: border-box;
  margin: 0;
  background: ${(props) => `${props.fill}12`};
  border-radius: 8px;
`

const Separator = styled.div<{ margin?: number }>`
  background: #edeef2;
  height: 1px;
  width: 100%;
  margin: ${({ margin }) => margin ?? 20}px 0;
`

const TopPart = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const StyledText = styled.div<{
  fontSize?: number
  fontWeight?: number
  lineHeight?: number
}>`
  font-size: ${({ fontSize }) => fontSize ?? 12}px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  line-height: ${({ lineHeight }) => lineHeight ?? 24}px;
  letter-spacing: 0;
  color: #353c5a;
`

const BlockTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgba(115, 121, 145, 0.8);
  margin-bottom: 8px;
`

const MainInfo = styled.div`
  flex: 1;
`

const About = styled.div`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  @media screen and (max-width: 600px) {
    margin-bottom: 8px;
    margin-top: 20px;
  }
`

const InfoBlock = styled.div`
  margin-top: 15px;

  @media screen and (max-width: 600px) {
    margin-top: 25px;
  }
`

const CloseButton = styled.div`
  align-self: flex-start;
  margin: 5px 5px;

  > img {
    display: block;
    width: 15px;
    height: 15px;
  }
`

const BottomSection = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
`

const NoneSlots = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
`

const NoneSlotsButton = styled.div<{ fill: string }>`
  font-size: 14px;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  margin: 0;
  color: ${({ fill }) => fill ?? '#B202FF'};
  width: auto;
`

interface IProps {
  expert?: IExpertProfile
  sessionPrice?: string
  onSlotSelect: (slot: ITimeSlot) => void
  onSlotRequested: () => void
  onClose: () => void
}

export default function InterviewSlotsListMobile({
  expert,
  sessionPrice,
  onSlotSelect,
  onSlotRequested,
  onClose,
}: IProps) {
  const interview: Partial<IInterview> = get(
    useSelector(interviewSelectors.interview),
    'interview',
    {}
  )
  const interviewDuration = get(interview, 'duration', 60)
  const timeZoneGMT = format(new Date(), 'O')

  const [days, setDays] = useState([])
  const [selectedSlot, setSelectedSlot] = useState<ITimeSlot>(null)
  const { agencyColor } = useBranding()

  const getDaySlots = (date) => {
    const daySlots = expert.time_slots.filter(
      (s) => isEqualDate(s.from, date) && !isSlotExpired(s.from)
    )
    return daySlots
  }

  const getSlotDetails = (slot: ITimeSlot) => {
    const timeStamp = normalizeDate(slot.from)
    const date = format(timeStamp, 'MM/dd')
    const weekDay = format(timeStamp, 'eee')
    const time = format(timeStamp, 'h:mm a')
    return (
      <>
        <WeekDay>{weekDay}, </WeekDay>
        <DateLabel>{date}</DateLabel>
        <WeekDay>{`  |  ${time}`}</WeekDay>
      </>
    )
  }

  useEffect(() => {
    const newDays = []
    expert?.time_slots?.forEach((s) => {
      if (!newDays.some((d) => isEqualDate(d, s.from)) && !isSlotExpired(s.from)) {
        newDays.push(s.from)
      }
    })
    setDays(newDays)
    setSelectedSlot(null)
  }, [expert])

  return (
    <Container>
      <DataContainer>
        <MiddleContainer>
          <ExpertContainer fill={agencyColor}>
            <TopPart>
              <Avatar user={expert} size={45} />
              <MainInfo>
                <StyledText fontSize={14} fontWeight={500} lineHeight={26}>
                  {expert.first_name}
                </StyledText>
                <StyledText fontSize={14} fontWeight={400} lineHeight={22}>{`${
                  expert.current_position
                } ${expert.company ? `at ${expert.company}` : ''}`}</StyledText>
              </MainInfo>
              <CloseButton onClick={onClose}>
                <img src={closeIcon} alt="close" />
              </CloseButton>
            </TopPart>
            <Separator />
            <About>
              <StyledText fontSize={12} fontWeight={400} lineHeight={24}>
                {expert.about}
              </StyledText>
            </About>
            {expert.experiences?.some((e) => e !== '') && (
              <InfoBlock>
                <BlockTitle>Experience</BlockTitle>
                {expert.experiences.map((exp, i) => (
                  <StyledText key={i} fontSize={12} fontWeight={400} lineHeight={24}>
                    {exp}
                  </StyledText>
                ))}
              </InfoBlock>
            )}
            {expert.educations?.some((e) => e !== '') && (
              <InfoBlock>
                <BlockTitle>Education</BlockTitle>
                {expert.educations.map((ed, i) => (
                  <StyledText key={i} fontSize={12} fontWeight={400} lineHeight={24}>
                    {ed}
                  </StyledText>
                ))}
              </InfoBlock>
            )}
          </ExpertContainer>
          <Separator />
          <StyledText fontSize={16} fontWeight={500} lineHeight={24}>
            Select a time slot for your session | {interviewDuration}min
          </StyledText>
          <StyledText>
            Time zone: {Intl.DateTimeFormat().resolvedOptions().timeZone} ({timeZoneGMT})
          </StyledText>
          {days.length === 0 && !expert && (
            <EmptyContainer>Select an expert to view their availability</EmptyContainer>
          )}
          {days.length === 0 && expert && (
            <EmptyContainer boldText>
              {expert.first_name}&apos;s time slots have expired
              <OutlineButton fill={agencyColor} onClick={onSlotRequested}>
                Request new time slots
              </OutlineButton>
            </EmptyContainer>
          )}
          {days.length > 0 && (
            <DaysContainer>
              {days.map((day) => {
                const timeStamp = normalizeDate(day)
                const date = format(timeStamp, 'MM/dd')
                const weekDay = format(timeStamp, 'eee')
                return (
                  <DayContainer key={day}>
                    <DayLabelContainer>
                      <SvgCalendar fill="black" />
                      <WeekDay>{weekDay}, </WeekDay>
                      <DateLabel>{date}</DateLabel>
                    </DayLabelContainer>
                    {getDaySlots(day).map((slot) => {
                      const slotTimeStamp = normalizeDate(slot.from)
                      const time = format(slotTimeStamp, 'h:mm a')
                      return (
                        <SlotContainer
                          key={slot.id}
                          onClick={() => setSelectedSlot(slot)}
                          fill={agencyColor}
                          selected={selectedSlot?.id === slot.id}
                        >
                          {time}
                        </SlotContainer>
                      )
                    })}
                  </DayContainer>
                )
              })}
            </DaysContainer>
          )}
        </MiddleContainer>
        <BottomContainer>
          <ExpertFooter>
            <LineContainer>
              {sessionPrice && (
                <PriceBlock>
                  <SvgPrice fill={agencyColor} />
                  <PriceText>{`Session price: ${sessionPrice}`}</PriceText>
                </PriceBlock>
              )}
              {selectedSlot && (
                <SelectedSlotDetails>{getSlotDetails(selectedSlot)}</SelectedSlotDetails>
              )}
            </LineContainer>
            <Separator margin={12} />
            <Button
              onClick={() => {
                onSlotSelect(selectedSlot)
                onClose()
              }}
              fill={agencyColor}
              disabled={!selectedSlot}
            >
              Book a session
            </Button>
            <Separator margin={12} />
          </ExpertFooter>
          <BottomSection>
            <NoneSlots>None of the time slots fit?</NoneSlots>
            <NoneSlotsButton fill={agencyColor} onClick={onSlotRequested}>
              Ask for additional slots
            </NoneSlotsButton>
          </BottomSection>
        </BottomContainer>
      </DataContainer>
    </Container>
  )
}

InterviewSlotsListMobile.defaultProps = {
  expert: null,
  sessionPrice: '',
}
