import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div<{
  show: boolean
  top?: number
  left: number
  toRight?: boolean
}>`
  position: fixed;
  display: ${({ show }) => (show ? 'inline' : 'none')};
  top: ${({ top }) => top}px;
  left: ${({ left }) => (isMobileOnly ? left - 50 : left)}px;
  width: 153px;
  padding: 12px;
  background: #3e3452;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  border-radius: 12px;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -17px;
    left: ${isMobileOnly ? 'calc(100% - 27px)' : '50%'};
    transform: translateX(-50%);
    border-top: 10px solid #353c5a;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
  }
`

interface IProps {
  text: string
  id: string
  show: boolean
}

interface Position {
  x: number
  y: number
}

const TopBlockTip = ({ text, id, show }: IProps) => {
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 })

  useEffect(() => {
    if (show) {
      const rootText = document.getElementById(id) as HTMLDivElement
      const rect = rootText.getBoundingClientRect()
      // const maxX = window.screen.width - (isMobileOnly ? 170 : 153) - 16
      // let x = Math.min(rect.left, maxX)
      // if (!isMobileOnly) {
      //   x = rect.left - 153 + rect.width
      // }
      const newPosition = {
        x: rect.left - 153 / 2 + rect.width / 2,
        y: rect.top - 50,
      }
      setPosition(newPosition)
    }
  }, [show, id])
  return (
    <Container top={position.y} left={position.x} show={show}>
      {text}
    </Container>
  )
}

export default TopBlockTip
