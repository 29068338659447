import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { checkUrl } from 'src/utils/string'
import StyledTextArea from '../elements/StyledTextArea'
import StepLayout from './StepLayout'

const DataContainer = styled.div`
  margin-bottom: ${isMobileOnly ? 24 : 0}px;
`

interface IProps {
  value?: string
  text: string
  placeholder?: string
  title?: string
  subTitle?: string
  canGoBack?: boolean
  backMode: boolean
  noUrl?: boolean
  optional?: boolean
  goNext: (value: string) => void
  goBack?: () => void
}

const QuestionComponent = ({
  value,
  text,
  placeholder,
  title,
  subTitle,
  canGoBack,
  backMode,
  noUrl,
  optional,
  goNext,
  goBack,
}: IProps) => {
  const [textValue, setTextValue] = useState(value ?? '')
  const [error, setError] = useState(false)

  const onNext = useCallback(() => {
    if (noUrl) {
      const hasError = checkUrl(textValue)
      setError(hasError)
      if (!hasError) {
        goNext(textValue)
      }
    } else {
      goNext(textValue)
    }
  }, [noUrl, textValue, goNext])

  useEffect(() => {
    setTextValue(value)
  }, [value, text])

  return (
    <StepLayout
      title={text}
      subTitle={subTitle}
      goNext={onNext}
      goBack={goBack}
      backMode={backMode}
      canGoBack={canGoBack}
      errorText="Please don’t include URLs in the description"
      showError={error}
    >
      <DataContainer>
        <StyledTextArea
          maxLength={5000}
          title={title}
          maxRows={8}
          reduceFontFromRow={5}
          value={textValue}
          placeholder={placeholder}
          onChange={setTextValue}
          showError={error}
          optional={optional}
          errorText="Please provide a text description"
        />
      </DataContainer>
    </StepLayout>
  )
}

QuestionComponent.defaultProps = {
  value: '',
  placeholder: '',
  title: '',
  subTitle: '',
  canGoBack: false,
  noUrl: false,
  optional: false,
  goBack: () => {},
}

export default QuestionComponent
