export default function playSound(file) {
  // eslint-disable-next-line no-console
  const audio = new Audio(file)
  try {
    audio.play()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Could not play sound', err)
  }
}
