import { put, takeEvery, call } from 'typed-redux-saga'
import handleErrorSaga from 'src/utils/handleErrorSaga'
import {
  getAgencies as getAgenciesAction,
  getAgenciesSuccess,
  getAgenciesFailure,
  getInterviewsSuccess,
  getInterviewsFailure,
  getInterviews as getInterviewsActions,
  getStats as getStatsAction,
  getStatsSuccess,
  getStatsFailure,
} from './actions'
import { IGetInterviewsAction, IGetStatsAction } from './types'
import { getAgencies, getInterviews, getStats } from '../api'

function* getAgenciesSaga() {
  try {
    const agencies = yield* call(getAgencies)
    yield put(getAgenciesSuccess({ agencies }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getAgenciesFailure({
        error: errorMessage,
      })
    )
  }
}

function* getInterviewsSaga(action: IGetInterviewsAction) {
  try {
    const { interviews, pages } = yield* call(getInterviews, action.payload)
    yield put(getInterviewsSuccess({ interviews, pages }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getInterviewsFailure({
        error: errorMessage,
      })
    )
  }
}

function* getStatsSaga(action: IGetStatsAction) {
  try {
    const stats = yield* call(getStats, action.payload)
    yield put(getStatsSuccess({ stats }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getStatsFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetAgencies() {
  yield takeEvery(getAgenciesAction.type, getAgenciesSaga)
}

export function* watchGetInterviews() {
  yield takeEvery(getInterviewsActions.type, getInterviewsSaga)
}

export function* watchGetStats() {
  yield takeEvery(getStatsAction.type, getStatsSaga)
}
