import { reduxDataFactory } from '../../../utils/redux'

export interface IAvaSettings {
  muted: boolean
  showSettings: boolean
  showSupport: boolean
  microphoneId: string
  showSpeakerPopup: boolean
  speakerId: string
}

const DefaultAvaSettings: IAvaSettings = {
  muted: false,
  showSettings: false,
  showSupport: false,
  showSpeakerPopup: false,
  microphoneId: 'default',
  speakerId: 'default',
}

const {
  action: updateAvaSettingsAction,
  actionType: updateAvaSettingsActionType,
  reducer: avaSettingsDataReducer,
  selectors: avaSettingsSelectors,
} = reduxDataFactory<IAvaSettings>('settings', 'ava', DefaultAvaSettings)

export {
  updateAvaSettingsAction,
  updateAvaSettingsActionType,
  avaSettingsDataReducer,
  avaSettingsSelectors,
}
