import ChatSidebar from './Chat'
import DetailsSidebar from './DetailsSidebar'
import MenuSidebar, { MenuItemId } from './MenuSidebar'
import NotesSidebar from './NotesSidebar'
import SettingsSidebar from './SettingsSidebar'
import SessionDetailsSidebarMobile from './SessionDetailsSidebarMobile'
import FeedbackSidebar from './FeedbackSidebar'
import FeedbackSidebarMobile from './FeedbackSidebarMobile'

export default {
  DetailsSidebar,
  MenuItemId,
  MenuSidebar,
  SettingsSidebar,
  ChatSidebar,
  SessionDetailsSidebarMobile,
  NotesSidebar,
  FeedbackSidebar,
  FeedbackSidebarMobile,
}
