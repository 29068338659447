/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React from 'react'
import LinesBack from 'src/static/landing/pink-lines.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { useMediaQuery } from 'react-responsive'
import {
  BaseLayout,
  LandingInfo,
  LandingTitle,
  LayoutWrapper,
} from './landing-styled-components'

const Layout = styled(BaseLayout)`
  padding: 96px 80px 120px 80px;
  width: 100%;

  @media screen and (max-width: 1439px) {
    padding: 80px 40px;
  }

  @media screen and (max-width: 899px) {
    padding: 64px 24px;
    max-width: unset;
  }

  ${isMobileOnly &&
  css`
    padding: 40px 24px !important;
  `}
`

const DataContainer = styled.div`
  position: relative;
  background: white;
  border-radius: 32px;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 899px) {
    padding: 40px 16px;
  }

  ${isMobileOnly &&
  css`
    padding: 16px 24px !important;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  margin-bottom: 32px;
  white-space: break-spaces;

  ${isMobileOnly &&
  css`
    margin-bottom: 24px !important;
  `}
`

const LandingInfoStyled = styled(LandingInfo)`
  max-width: 736px;

  @media screen and (max-width: 1439px) {
    max-width: 100%;
  }
`

const PinkText = styled.span`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -10%;
    left: -3%;
    background: url(${LinesBack});
    background-size: 100%;
    width: 244px;
    height: 52px;

    ${isMobileOnly &&
    css`
      width: 152px !important;
      height: 33px !important;
    `}
  }
`

const LandingPreparation = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 899px)' }) || isMobileOnly

  return (
    <LayoutWrapper background="#f5f5f7">
      <Layout>
        <DataContainer>
          <LandingTitleStyled>
            Career preparation{isSmallScreen ? '\n' : ' '}
            <PinkText>reimagined</PinkText>
          </LandingTitleStyled>
          <LandingInfoStyled>
            {`You strive to best prepare your applicants, but limited scale, resources, and industry experience makes offering in-depth career guidance and interview preparation a challenge.\nEnriched by thousands of successful coaching sessions, we’ve gained valuable insights into the interview processes of over 12,000 companies globally.`}
          </LandingInfoStyled>
        </DataContainer>
      </Layout>
    </LayoutWrapper>
  )
}

export default LandingPreparation
