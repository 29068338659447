import { Widget } from '@typeform/embed-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { FirstPageBehaviors, InterviewStatus } from '../../api'
import { Loader } from '../../components'
import {
  BookingStatus,
  bookingStatusSelectors,
  candidateRescheduleInterviewSelectors,
  completedPreMeetingSelectors,
} from '../../redux'
import Report from '../../report'
import VideoCallContainer from '../../videocall/Layout'

import { getInterview, reportInterview } from '../actions'
import ApplicantRescheduledComponent from '../components/ApplicantRescheduledComponent'
import PositionDetails from '../forms/PositionDetails'
import StudentDetails from '../forms/StudentDetails'
import * as interviewSelectors from '../selectors'

import ApplicantPreStartContainer from './ApplicantPreStartContainer'
import ExpertAvailabilityContainer from './ExpertAvailabilityContainer'
import ExpertInterviewSuccess from './ExpertInterviewSuccess'
import ExpertPreStartContainer from './ExpertPreStartContainer'
import Landing from './Landing'
import Scheduling from './Scheduling'
import SchedulingThankYou from './SchedulingThankYou'
import UpdateInterviewDetailsSuccess from './UpdateInterviewDetailsSuccess'

const CandidateInterview = () => {
  const {
    invited_users_first_page: firstPage = FirstPageBehaviors.Landing,
  } = useSelector(interviewSelectors.agency)

  const hasRescheduled = useSelector(interviewSelectors.hasRescheduled)
  const isRescheduling = useSelector(candidateRescheduleInterviewSelectors.isLoading)

  const interviewStatus = useSelector(interviewSelectors.status)
  const bookingStatus = useSelector(bookingStatusSelectors.data)
  const completedPreMeeting = useSelector(completedPreMeetingSelectors.data)
  const assignTimeSlotApiSuccess = useSelector(interviewSelectors.assignTimeSlotSuccess)

  switch (interviewStatus) {
    case InterviewStatus.MATCHING:
    case InterviewStatus.EXPERTS_INVITED:
    case InterviewStatus.EXPERTS_CONFIRMED:
      return hasRescheduled ? (
        <ApplicantRescheduledComponent />
      ) : (
        <UpdateInterviewDetailsSuccess />
      )
    case InterviewStatus.MATCHED:
      return isRescheduling ? <ApplicantRescheduledComponent /> : <Scheduling />
    case InterviewStatus.SCHEDULED:
      if (assignTimeSlotApiSuccess) return <SchedulingThankYou />
      return completedPreMeeting ? <VideoCallContainer /> : <ApplicantPreStartContainer />
    case InterviewStatus.STARTED:
      return <VideoCallContainer />
    case InterviewStatus.ENDED:
    case InterviewStatus.REPORT_PROVIDED:
    case InterviewStatus.COMPLETED:
      return <Report />
    case InterviewStatus.PENDING:
    case InterviewStatus.CANDIDATE_INVITED:
    default:
      if (bookingStatus) {
        switch (bookingStatus) {
          case BookingStatus.Student:
            return <StudentDetails />
          default:
            return <PositionDetails />
        }
      }

      switch (firstPage) {
        case FirstPageBehaviors.OrderForm:
          return <PositionDetails />
        default:
          return <Landing />
      }
  }
}

const ExpertTypeform = () => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const candidateName = useSelector(interviewSelectors.candidateName)
  const orderNumber = useSelector(interviewSelectors.interviewOrderNumber)
  const position = useSelector(interviewSelectors.interviewPosition)
  const company = useSelector(interviewSelectors.interviewPositionCompany)

  const handleSubmit = ({ responseId }) => {
    dispatch(reportInterview({ interviewToken, responseId }))
  }

  return (
    <Widget
      id="wZiFXW"
      style={{ height: '100%', overflow: 'hidden' }}
      onSubmit={handleSubmit}
      hidden={{
        id: orderNumber.toString(),
        name: candidateName,
        position,
        company,
        value1: 'First impression',
        value2: 'Role-related knowledge',
        value3: 'Communication',
        value4: 'Problem solving',
      }}
    />
  )
}

const ExpertInterview = () => {
  const interviewStatus = useSelector(interviewSelectors.status)
  const preventRedirect = useSelector(interviewSelectors.preventRedirect)
  const completedPreMeeting = useSelector(completedPreMeetingSelectors.data)
  const [currentComponent, setCurrentComponent] = useState<JSX.Element>(null)

  useEffect(() => {
    if (!preventRedirect) {
      switch (interviewStatus) {
        case InterviewStatus.SCHEDULED:
          setCurrentComponent(
            completedPreMeeting ? <VideoCallContainer /> : <ExpertPreStartContainer />
          )
          break
        case InterviewStatus.STARTED:
          setCurrentComponent(<VideoCallContainer />)
          break
        case InterviewStatus.ENDED:
          setCurrentComponent(<ExpertTypeform />)
          break
        case InterviewStatus.REPORT_PROVIDED:
        case InterviewStatus.COMPLETED:
          setCurrentComponent(<ExpertInterviewSuccess />)
          break
        default:
          setCurrentComponent(<>No page for this interview status</>)
      }
    }
  }, [completedPreMeeting, interviewStatus, preventRedirect])

  return currentComponent
}

const PotentialExpertInterview = () => {
  return <ExpertAvailabilityContainer />
}

const RejectedExpertInterview = () => {
  return <ExpertAvailabilityContainer />
}

const DirectInterview = () => {
  const {
    allow_direct_registration: allowedDirectBooking,
    direct_users_first_page: firstPage = FirstPageBehaviors.Landing,
  } = useSelector(interviewSelectors.agency)
  const bookingStatus = useSelector(bookingStatusSelectors.data)

  if (bookingStatus) {
    switch (bookingStatus) {
      case BookingStatus.Student:
        return <StudentDetails />
      default:
        return <PositionDetails />
    }
  }

  if (!allowedDirectBooking) return <Landing />

  switch (firstPage) {
    case FirstPageBehaviors.OrderForm:
      return <PositionDetails />
    default:
      return <Landing />
  }
}

const InterviewRouter = () => {
  const { role } = useSelector(interviewSelectors.token)

  switch (role) {
    case 'candidate':
      return <CandidateInterview />
    case 'expert':
      return <ExpertInterview />
    case 'potential_expert':
      return <PotentialExpertInterview />
    case 'rejected_expert':
      return <RejectedExpertInterview />
    default:
      return <DirectInterview />
  }
}

const InterviewContainer = () => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const isLoading = useSelector(interviewSelectors.isLoading)

  useEffect(() => {
    if (interviewToken) dispatch(getInterview({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && <InterviewRouter />}
    </>
  )
}

export default InterviewContainer
