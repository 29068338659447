import styled from '@emotion/styled'
import React from 'react'

const HamburgerContainer = styled.div`
  width: 24px;
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  margin-left: 16px;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: #0e0224;
`

const HamburgerButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <HamburgerContainer onClick={onClick}>
      <HamburgerLine />
      <HamburgerLine />
      <HamburgerLine />
    </HamburgerContainer>
  )
}

HamburgerButton.defaultProps = {
  onClick: () => {},
}

export default HamburgerButton
