import React from 'react'
import styled from '@emotion/styled'

export const FooterBlocker = styled.div<{ isTransparent: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isTransparent }) => (isTransparent ? 'transparent' : '#0c051a')};
  opacity: 0.8;
  z-index: 100;
`

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }: { theme: any }) => theme.footerBackgroundColor};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: relative;

  padding: 0 52px;
  @media screen and (max-width: 2500px) {
    padding: 0 44px;
  }
  @media screen and (max-width: 2000px) {
    padding: 0 35px;
  }
  @media screen and (max-width: 1200px) {
    padding: 0 26px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 18px;
  }
`

export const FooterSeparator = styled.div`
  height: 46px;
  width: 0px;
  border: 1px solid rgba(225, 227, 234, 0.4);
  margin: 0 16px;
`

export function Footer({ children }: React.HTMLProps<HTMLDivElement>) {
  return <Container>{children}</Container>
}

const MobileContainer = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }: { theme: any }) => theme.footerBackgroundColor};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  grid-area: footer;
  padding: 16px 24px 16px 24px;
  position: relative;
  width: 100%;
  height: fit-content;

  @media (orientation: landscape) {
    bottom: 0;
    height: 72px;
    padding: 8px;
    position: absolute;
  }
`

interface IFooterMobile extends React.HTMLProps<HTMLDivElement> {
  visible: boolean
}

export function FooterMobile({ children, visible }: IFooterMobile) {
  return <MobileContainer visible={visible}>{children}</MobileContainer>
}
