import styled from '@emotion/styled'

export const CheckboxContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
`

export const CheckboxContentTitle = styled.h3`
  color: #21304e;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 22px;
  margin: 0;
`
