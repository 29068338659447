import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import SvgShareScreen from 'src/static/components/SvgShareScreen'

import * as interviewSelectors from '../../../interview/selectors'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

export const ScreenShareButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

const HintMessage = styled.div`
  position: absolute;
  top: 80px;
  background: #000000;
  color: white;
  width: 285px;
  left: -67px;
  font-size: 15px;
  text-align: center;
  padding: 10px 14px;
  line-height: 19.4px;
  border-radius: 40px;
  font-weight: 400;
  z-index: 1000;

  @media screen and (max-width: 2500px) {
    top: 67px;
    width: 238px;
    left: -62px;
    font-size: 13px;
    padding: 9px 14px;
    line-height: 16.4px;
  }
  @media screen and (max-width: 2000px) {
    top: 51px;
    width: 190px;
    left: -65px;
    font-size: 12px;
    padding: 7px 13px;
    line-height: 14.4px;
  }
  @media screen and (max-width: 1200px) {
    top: 39px;
    width: 143px;
    left: -47px;
    font-size: 8px;
    padding: 5px 10px;
    line-height: 12.4px;
  }
  @media screen and (max-width: 900px) {
    top: 26px;
    width: 95px;
    left: -38px;
    font-size: 6px;
    padding: 3px 8px;
    line-height: 9.4px;
  }
`

interface IScreenShareButton {
  active: boolean
  disabled: boolean
  inCodeSharing: boolean
  peerInCodeSharing: boolean
  theme: any
  onClick: () => void
}

export default function ScreenShareButton({
  active,
  disabled,
  inCodeSharing,
  peerInCodeSharing,
  theme,
  onClick,
}: IScreenShareButton) {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const [hintVisible, setHintVisible] = useState(false)
  const label = active ? 'Stop sharing' : 'Share screen'
  const hintCodeshare = "You can't share your screen while code sharing"
  const hintPeerCodeshare = `You can't share your screen while ${peer.first_name} is code sharing`

  function onMouseEnter() {
    setHintVisible(true)
    setTimeout(() => setHintVisible(false), 3000)
  }

  return (
    <HeaderButtonContainer>
      <HeaderIconContainer
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        onMouseEnter={inCodeSharing || peerInCodeSharing ? onMouseEnter : undefined}
      >
        <SvgShareScreen
          disabled={disabled}
          inactive={!active}
          fill={active ? theme.textColorActive : theme.textColor}
        />
        <ScreenShareButtonLabel active={active}>{label}</ScreenShareButtonLabel>
      </HeaderIconContainer>
      {inCodeSharing && hintVisible && <HintMessage>{hintCodeshare}</HintMessage>}
      {peerInCodeSharing && !inCodeSharing && hintVisible && (
        <HintMessage>{hintPeerCodeshare}</HintMessage>
      )}
    </HeaderButtonContainer>
  )
}
