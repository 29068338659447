import { IAvaQuestion } from 'src/api'
import { reduxDataFactory } from '../../../utils/redux'

export interface IAvaData {
  selectedQuestion: IAvaQuestion
}

const DefaultAvaData: IAvaData = {
  selectedQuestion: null,
}

const {
  action: updateAvaDataAction,
  actionType: updateAvaDataActionType,
  reducer: avaDataDataReducer,
  selectors: avaDataSelectors,
} = reduxDataFactory<IAvaData>('data', 'ava', DefaultAvaData)

export {
  updateAvaDataAction,
  updateAvaDataActionType,
  avaDataDataReducer,
  avaDataSelectors,
}
