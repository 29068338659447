import { call, delay, put, race, take } from 'typed-redux-saga'

import { AvaInterviewStatus, PromptStatus, avaInterviewStatus } from '../../api'
import { reduxWatcherFactory } from '../../utils/redux'
import { avaGetInterviewAction } from '../requests/ava/getInterview'

interface IAvaReportWatcherAction {
  interviewToken: string
}

const {
  startAction: startAvaReportWatcherAction,
  startActionType: startAvaReportWatcherActionType,
  stopAction: stopAvaReportWatcherAction,
  stopActionType: stopAvaReportWatcherActionType,
} = reduxWatcherFactory<IAvaReportWatcherAction>('avaReport')

function* avaReportWatcher(interviewToken) {
  while (true) {
    try {
      const interview = yield* call(avaInterviewStatus, interviewToken)

      if (
        interview?.status === AvaInterviewStatus.COMPLETED &&
        !interview.is_retrying_prompts &&
        Object.values<PromptStatus>(interview?.prompt_status.report).every((v) =>
          [PromptStatus.Skipped, PromptStatus.Done, PromptStatus.Failed].includes(v)
        )
      ) {
        yield put(avaGetInterviewAction({ interviewToken }))
        yield put(stopAvaReportWatcherAction)
      } else {
        yield call(delay, 1000)
      }
    } catch (err) {
      yield put(stopAvaReportWatcherAction)
    }
  }
}

function* watchAvaReportWatcher() {
  while (true) {
    const action = yield take(startAvaReportWatcherActionType)
    yield race([
      call(avaReportWatcher, action.payload.interviewToken),
      take(stopAvaReportWatcherActionType),
    ])
  }
}

export {
  startAvaReportWatcherAction,
  startAvaReportWatcherActionType,
  stopAvaReportWatcherAction,
  stopAvaReportWatcherActionType,
  watchAvaReportWatcher,
}
