import { reduxDataFactory } from '../../../utils/redux'

const {
  action: updateSynthesizedVoiceAction,
  actionType: updateSynthesizedVoiceActionType,
  reducer: synthesizedVoiceDataReducer,
  selectors: synthesizedVoiceSelectors,
} = reduxDataFactory<{ audioContent: any; text: string }>('synthesizedVoice', 'ava', {
  audioContent: undefined,
  text: undefined,
})

export {
  updateSynthesizedVoiceAction,
  updateSynthesizedVoiceActionType,
  synthesizedVoiceDataReducer,
  synthesizedVoiceSelectors,
}
