import { RootState } from '../redux'

export const login = {
  token(state: RootState) {
    return state.auth.login.token
  },
  isLoading(state: RootState) {
    return state.auth.login.isLoading
  },
  error(state: RootState) {
    return state.auth.login.error
  },
}

export const reset = {
  isLoading(state: RootState) {
    return state.auth.reset.isLoading
  },
  error(state: RootState) {
    return state.auth.reset.error
  },
}

export const changePassword = {
  isLoading(state: RootState) {
    return state.auth.changePassword.isLoading
  },
  error(state: RootState) {
    return state.auth.changePassword.error
  },
}
