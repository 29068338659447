/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react'
import useResizeAware from 'react-resize-aware'
import useStateRef from 'react-usestateref'
import { datadogLogs } from '@datadog/browser-logs'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'emotion-theming'
import { isMobileOnly, isTablet, withOrientationChange } from 'react-device-detect'
import Slider from 'react-slick'
import { useDispatch, useSelector } from 'react-redux'

import * as interviewSelectors from 'src/interview/selectors'
import { preventInterviewRedirect } from 'src/interview/actions'
import * as moment from 'moment'
import { linkConversationAction } from '../interview/redux'
import {
  moveToAlternativeLinkAction,
  systemSettingsSelectors,
  updateCallNetworkRemoteAction,
} from '../redux'
import useLogrocket from '../utils/useLogrocket'

import TextFeedback from './components/TextFeedback'
import { MobileQuickReactions, QuickReactions } from './components/QuickReactions'
import { ReadyToJoin, MobileReadyToJoin } from './components/ReadyToJoinButton'
import Logo from './components/Logo'
import { Feedback, MobileFeedback } from './components/Feedback'
import CameraPermission from './components/CameraPermission'
import CameraNotAllowed from './components/CameraNotAllowed'
import SharingPopup from './components/SharingPopup'
import InterruptSharinPopup from './components/InterruptSharinPopup'
import Buttons from './components/butttons'
import Layout from './components/layout'
import Notifications from './components/notifications'
import Sidebars from './components/sidebars'
import Video from './components/video'
import PrecallTestView from './components/precall/precallTestView'
import SmallIntercomButton from './components/butttons/SmallIntercomButton'
import ProgressBar from './components/ProgressBar'
import { OnBoardingStep } from './components/notifications/onboarding/OnBoardingConstants'
import { JoiningCall, MobileJoiningCall } from './components/JoiningCall'

import useFullscreen from './services/useFullscreen'
import useMediaDevices from './services/useMediaDevices'
import useRtc from './services/useRtc'
import useRtm, { RtmCommands } from './services/useRtm'
import useChat from './services/useChat'
import useScreenshare from './services/useScreenshare'
import useInterview from './services/useInterview'
import useOnBoarding from './services/useOnboarding'
import useDrawer from './services/useDrawer'
import useCodeshare from './services/useCodeshare'
import useSupport from './services/useSupport'

import Theme from './theme'
import useOrientation, {
  LandscapeAspectRatio,
  PortraitAspectRatio,
} from './services/useOrientation'
import useSymbl from './services/useSymbl'
import useLocalNetworkQuality from './services/useLocalNetworkQuality'
import usePeerNetworkQuality from './services/usePeerNetworkQuality'
import { IExpertNoteMood, InterviewStatus } from '../api'
import useFeedback from './services/useFeedback'
import LateMessage, { UserLateMessage } from './components/notifications/LateMessage'

if (!window.location.hostname.includes('localhost')) {
  datadogLogs.init({
    clientToken: 'pub50ad6f944a780d2299386c3cd44b81b6',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'wizco.io',
  })
}

const pickVideoDimensions = (height, width, coef) => {
  return [
    { width, height: width * coef },
    { width: height / coef, height },
  ].find((s) => s.width <= width && s.height <= height)
}

function VideoCallContainer({ isLandscape }: any) {
  const { setLogrocket } = useLogrocket()
  const dispatch = useDispatch()
  const theme = isMobileOnly ? Theme.mobile : Theme.dark
  const systemSettings = useSelector(systemSettingsSelectors.data)
  const preventRedirect = useSelector(interviewSelectors.preventRedirect)
  const isBackgeounBlurAlowed = systemSettings?.blurBackgroundEnabled ?? false

  const [inPreview, setInPreview] = useStateRef(false)
  const [joinInProgress, setJoinInProgress] = useStateRef(false)
  const [connectToAlternativeRoom, setConnectToAlternativeRoom] = useStateRef(false)
  const [peerConnectedToAltRoom, setPeerConnectedToAltRoom] = useStateRef(false)
  const [hasConnectionError, setHasConnectionError] = useState(false)
  const [userLateMessage, setUserLateMessage, userLateMessageRef] = useStateRef<
    UserLateMessage
  >(UserLateMessage.None)

  const { interviewToken } = useParams()
  const [resizeListener, sizes] = useResizeAware()

  const {
    canEndCall,
    codingSessionUrl,
    endNotification,
    endSoonNotification,
    endSoonNotificationHidden,
    interviewId,
    interviewOrderNumber,
    interviewStartedAt,
    interviewScheduledTime,
    interviewStatus,
    isExpert,
    minutesTillEnd,
    peerFirstName,
    peerLastName,
    peerLogo,
    profileFirstName,
    profileLastName,
    profileId,
    profileLogo,
    enableConversationIntelligence,
    backupMeetingURL,
    goToAltRoom,
    endInterview,
    hideEndNotification,
    hideEndSoonNotification,
    showEndNotification,
    startInterview,
    stopWatchInterviewEnd,
    updateInterviewInfo,
    watchInterviewEnd,
    watchInterviewStart,
  } = useInterview()

  const { activeFeedback, sendFeedback, deleteFeedback, editFeedback } = useFeedback()

  const {
    cameraId,
    cameraAvailable,
    devices,
    devicesInitialized,
    hasUnavailableDevice,
    microphoneId,
    microphoneAvailable,
    permissionsGranted,
    permissionsRequested,
    settings,
    speakerId,
    speakerAvailable,
    videoProfile,
    changeCamera,
    changeMicrophone,
    changeSpeaker,
    changeVideoProfile,
    initDevices,
    scanDevices,
    selectNextCamera,
    selectNextMicrophone,
    skipPermissionRequest,
  } = useMediaDevices()

  const {
    callUid,
    canMuteAudio,
    canMuteCamera,
    hasScreenshare,
    isDisconnected,
    isRemoteScreenSharing,
    joinedToCall,
    localAudioMuted,
    localAudioTrack,
    localAudioTrackBusy,
    localCameraMuted,
    localCameraTrackBusy,
    peerJoined,
    peerLeft,
    remoteAudioMuted,
    remoteAudioTrack,
    remoteCameraMuted,
    rtc,
    isBackgroundBlurEnabled,
    changeEncoderConfig,
    changeLocalAudio,
    changeLocalCamera,
    changeLocalSpeaker,
    initCall,
    joinCall,
    leaveCall,
    muteAudio,
    muteCamera,
    muteCameraPoorNetwork,
    notifyLocalScreenshareChange,
    publishTracks,
    restoreLocalCamera,
    switchFromCodeshare,
    switchFromScreenshare,
    switchToCodeshare,
    switchToScreenshare,
    setBackgroundBlurring,
  } = useRtc(interviewId, isExpert, interviewOrderNumber)

  const {
    interruptScreenshareDialog,
    isScreenSharing,
    screenError,
    closeScreenshareInterruptDialog,
    initScreenshare,
    interruptScreenshare,
    joinScreenshare,
    leaveScreenshare,
    openScreenshareInterruptDialog,
    playScreenshare,
    hideScreenError,
  } = useScreenshare(rtc, interviewId, isExpert)

  const {
    chatBadge,
    chatLastMessage,
    chatMessages,
    chatNotification,
    hideChatNotification,
    joinChat,
    leaveChat,
    // sendChatFile,
    sendChatMessage,
    setChatOpen,
  } = useChat(profileFirstName, interviewId)

  const {
    rtmLastMessage,
    joinRtm,
    leaveRtm,
    sendCallEnded,
    sendCodeshareStatus,
    sendInterruptScreenshare,
    sendOrientationStatus,
    sendNetworkQuality,
    sendConnectAltRoom,
  } = useRtm(callUid, interviewId)

  const {
    hasPoorConnectionNotification,
    hidePoorConnectionNotification,
  } = useLocalNetworkQuality(sendNetworkQuality)

  usePeerNetworkQuality(joinedToCall && peerJoined && !peerLeft)

  const { onBoardingStep, closeOnBoarding, progressOnBoarding } = useOnBoarding(
    isExpert,
    permissionsRequested && !interviewStartedAt && !joinInProgress && !joinedToCall,
    !!codingSessionUrl
  )

  const {
    inCodeSharing,
    peerInCodeSharing,
    peerJoinedCodeNotification,
    peerLeftCodeNotification,
    closePeerJoinedCodeNotification,
    closePeerLeftCodeNotification,
    joinCodeshare,
    leaveCodeshare,
    onPeerJoinedCodeshare,
    onPeerLeftCodeshare,
  } = useCodeshare(sendCodeshareStatus)

  const {
    chatDrawerOpen,
    detailsDrawerOpen,
    hasDrawer,
    menuDrawerOpen,
    precallDrawerOpen,
    settingsDrawerOpen,
    sessionDetailsDrawerOpen,
    notesDrawerOpen,
    feedbackDrawerOpen,
    closeDrawer,
    openChatDrawer,
    openDetailsDrawer,
    openMenuDrawer,
    openPrecallDrawer,
    openSettingsDrawer,
    openSessionDetailsDrawer,
    openNotesDrawer,
    openFeedbackDrawer,
  } = useDrawer()

  const {
    localOrientation,
    peerOrientation,
    changeLocalOrienation,
    changePeerOrientation,
  } = useOrientation()

  const { conversationId, runSymbl, stopSymbl, muteSymbl, changeSymblDevice } = useSymbl(
    profileId,
    `${profileFirstName} ${profileLastName}`,
    interviewId,
    isExpert,
    !enableConversationIntelligence
  )

  const { openSupport } = useSupport()

  const { activeFullscreen, extendFullscreen, toggleFullscreen } = useFullscreen()

  const handleChatOpen = () => {
    setChatOpen(true)
    openChatDrawer()
  }

  const handleCloseDrawer = () => {
    restoreLocalCamera()
    setChatOpen(false)
    closeDrawer()
  }

  const handleInterruptScreenshare = async () => {
    await sendInterruptScreenshare()
    await interruptScreenshare()
  }

  const handleConnectToAltRoom = () => {
    setConnectToAlternativeRoom(true)
    sendConnectAltRoom()
  }

  const handleMenuSelect = (itemId: string) => {
    switch (itemId) {
      case Sidebars.MenuItemId.Details:
        openDetailsDrawer()
        break
      case Sidebars.MenuItemId.Settings:
        openSettingsDrawer()
        break
      case Sidebars.MenuItemId.SessionDetails:
        openSessionDetailsDrawer()
        break
      case Sidebars.MenuItemId.Chat:
        handleChatOpen()
        break
      case Sidebars.MenuItemId.PersonalNotes:
        openNotesDrawer()
        break
      case Sidebars.MenuItemId.Feedback:
        openFeedbackDrawer()
        break
    }
  }

  const handleOpenSupport = () => {
    handleCloseDrawer()
    openSupport()
  }

  const handleOrientationChange = async () => {
    changeLocalOrienation(
      window.orientation === 0 ? PortraitAspectRatio : LandscapeAspectRatio
    )
    await sendOrientationStatus(window.orientation === 0)
  }

  const handleRunPrecallTests = () => {
    closeOnBoarding()
    openPrecallDrawer()
  }

  const handleTryScreenshare = async () => {
    if (isRemoteScreenSharing && !isScreenSharing) {
      openScreenshareInterruptDialog()
      return
    }
    await joinScreenshare()
  }

  const join = async () => {
    closeOnBoarding()
    handleCloseDrawer()
    updateInterviewInfo(interviewToken)
    setJoinInProgress(true)

    let timeout = setTimeout(() => {
      setHasConnectionError(true)
      timeout = null
    }, 10000)
    await joinRtm()
    if (timeout) {
      await joinChat()
    }
    let joined = false
    if (timeout) {
      joined = await joinCall()
    }

    if (timeout || joined) {
      clearTimeout(timeout)
      timeout = null
      setHasConnectionError(false)
      if (!isMobileOnly && !isExpert) openNotesDrawer()
      setJoinInProgress(false)
    }
  }

  const leave = async (leaveDeviceAlive: boolean = false) => {
    if (enableConversationIntelligence) {
      stopSymbl()
    }
    await leaveScreenshare()
    await leaveCall(leaveDeviceAlive)
    await leaveRtm()
    await leaveChat()
  }

  const endSession = async (quiet = false) => {
    endInterview(interviewToken, quiet)
    if (!quiet) await sendCallEnded()
    await leave(isExpert && quiet)
  }

  const handleEndCallClick = async () => {
    await endSession()
  }

  const handlePrecallTestClose = (success: boolean) => {
    handleCloseDrawer()
    if (success) join()
  }

  const handleSendFeedback = (text: string, mood?: IExpertNoteMood) => {
    sendFeedback(interviewToken, text, mood)
    if (!isMobileOnly && !hasDrawer) {
      openFeedbackDrawer()
    }
  }

  const handleDeleteFeedback = (id: string) => {
    deleteFeedback(interviewToken, id)
  }

  const handleEditFeedback = (id: string, text: string) => {
    if (!text) {
      deleteFeedback(interviewToken, id)
    } else {
      editFeedback(interviewToken, id, text)
    }
  }

  const getVideoDimensions = () => {
    const { height, width } = sizes
    return {
      localVideoDimensions: pickVideoDimensions(height, width, localOrientation),
      localSmallVideoDimensions: pickVideoDimensions(
        height,
        width / 5,
        localOrientation > 1 ? PortraitAspectRatio : LandscapeAspectRatio
      ),
      peerVideoDimensions: pickVideoDimensions(height, width, peerOrientation),
      screenVideoDimensions: pickVideoDimensions(height, width, LandscapeAspectRatio),
      screenSmallVideoDimensions: pickVideoDimensions(
        height,
        width / 2,
        localOrientation > 1 ? PortraitAspectRatio : LandscapeAspectRatio
      ),
    }
  }

  const handleMoveToAlternativeLink = () => {
    dispatch(moveToAlternativeLinkAction({ interviewToken }))
    window.location.assign(backupMeetingURL)
  }

  const handleCloseLateMessage = () => {
    switch (userLateMessage) {
      case UserLateMessage.First:
        setUserLateMessage(UserLateMessage.FirstClosed)
        break
      case UserLateMessage.Last:
        setUserLateMessage(UserLateMessage.LastClosed)
        window.open('about:blank', '_self')
        window.close()
        break
      default:
        break
    }
  }

  const reload = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (interviewOrderNumber && profileFirstName && profileLastName) {
      setLogrocket(
        `${interviewOrderNumber.toString()}`,
        `${isExpert ? 'Expert' : 'Applicant'} cockpit`
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewOrderNumber, isExpert, profileFirstName, profileLastName])

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientationChange, false)
    handleOrientationChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    switch (interviewStatus) {
      case InterviewStatus.SCHEDULED:
        if (isExpert && peerJoined) {
          startInterview(interviewToken)
          datadogLogs.logger.log('interview started', {
            timestamp: new Date(),
            interviewId: interviewOrderNumber,
          })
        }
        break
      case InterviewStatus.ENDED:
      case InterviewStatus.REPORT_PROVIDED:
        if (!preventRedirect) {
          endSession(true)
        }
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus, isExpert, peerJoined])

  useEffect(() => {
    if (!rtc) return
    initDevices(rtc)
    initCall()
    initScreenshare()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtc])

  useEffect(() => {
    if (isDisconnected) leave()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected])

  useEffect(() => {
    let intervalRef: NodeJS.Timeout
    if (rtc && devicesInitialized) {
      intervalRef = setInterval(() => scanDevices(rtc), 1000)
    }
    return () => intervalRef && clearInterval(intervalRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInitialized, rtc])

  useEffect(() => {
    if (joinedToCall && !interviewStartedAt) watchInterviewStart(interviewToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStartedAt, interviewToken, joinedToCall])

  useEffect(() => {
    if (
      enableConversationIntelligence &&
      joinedToCall &&
      interviewStatus === InterviewStatus.STARTED
    ) {
      runSymbl()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus, joinedToCall])

  useEffect(() => {
    if (joinedToCall) publishTracks()
    if (enableConversationIntelligence) muteSymbl(localAudioMuted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedToCall, localAudioMuted, localCameraMuted])

  useEffect(() => {
    if (joinedToCall && peerJoined) {
      if (peerLeft) {
        watchInterviewEnd(interviewToken)
      } else {
        stopWatchInterviewEnd()
      }
      playScreenshare()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedToCall, peerJoined, peerLeft])

  useEffect(() => {
    setInPreview(
      cameraAvailable &&
        // microphoneAvailable &&
        // speakerAvailable &&
        permissionsRequested &&
        permissionsGranted &&
        (!joinedToCall || !peerJoined || peerLeft)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cameraAvailable,
    permissionsRequested,
    permissionsGranted,
    joinedToCall,
    peerJoined,
    peerLeft,
  ])

  useEffect(() => {
    if (!devicesInitialized) return
    const errorHandler = () => selectNextMicrophone()
    changeLocalAudio(microphoneId, errorHandler).catch(errorHandler)
    changeSymblDevice(microphoneId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInitialized, microphoneId])

  useEffect(() => {
    if (!devicesInitialized) return
    const errorHandler = () => selectNextCamera()
    changeLocalCamera(cameraId).catch(errorHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInitialized, cameraId])

  useEffect(() => {
    if (!devicesInitialized) return
    changeLocalSpeaker(speakerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInitialized, speakerId])

  useEffect(() => {
    if (!devicesInitialized) return
    changeEncoderConfig(videoProfile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesInitialized, videoProfile])

  useEffect(() => {
    notifyLocalScreenshareChange(isScreenSharing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenSharing])

  useEffect(() => {
    if (hasScreenshare) switchToScreenshare()
    else switchFromScreenshare()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasScreenshare])

  useEffect(() => {
    if (inCodeSharing) switchToCodeshare()
    else switchFromCodeshare()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inCodeSharing])

  useEffect(() => {
    if (!rtmLastMessage?.text) return
    switch (rtmLastMessage.text) {
      case RtmCommands.PleaseStopScreensharingForMe:
        leaveScreenshare()
        break
      case RtmCommands.CallEnded:
        dispatch(preventInterviewRedirect())
        endSession(true)
        if (isExpert) {
          showEndNotification()
        }
        break
      case RtmCommands.OrientationChangedLandscape:
        changePeerOrientation(LandscapeAspectRatio)
        break
      case RtmCommands.OrientationChangedPortrait:
        changePeerOrientation(PortraitAspectRatio)
        break
      case RtmCommands.PeerJoinedCoding:
        onPeerJoinedCodeshare()
        break
      case RtmCommands.PeerLeftCoding:
        onPeerLeftCodeshare()
        break
      case RtmCommands.ConnectAltRoom:
        setPeerConnectedToAltRoom(true)
        break
      default:
        if (/^PEER_NETWORK_QUALITY_[0-9]+_[0-9]+$/.test(rtmLastMessage.text)) {
          const match = rtmLastMessage.text.match(
            /^PEER_NETWORK_QUALITY_([0-9]+)_([0-9]+)$/
          )
          dispatch(
            updateCallNetworkRemoteAction({
              downlinkNetworkQuality: Number(match[1]) as any,
              uplinkNetworkQuality: Number(match[2]) as any,
            })
          )
        }
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtmLastMessage])

  useEffect(() => {
    if (conversationId) {
      dispatch(linkConversationAction({ interviewToken, conversationId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId])

  useEffect(() => {
    if (!chatDrawerOpen) {
      setChatOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatDrawerOpen])

  useEffect(() => {
    if (interviewStatus === InterviewStatus.SCHEDULED && joinedToCall && !peerJoined) {
      const intervalID = setInterval(() => {
        console.warn(moment.default())
        const minutesPassed = moment
          .default()
          .diff(moment.default(interviewScheduledTime), 'minutes')

        if (minutesPassed > 4 && userLateMessageRef.current < UserLateMessage.First) {
          setUserLateMessage(UserLateMessage.First)
        }

        if (minutesPassed > 9 && userLateMessageRef.current < UserLateMessage.Last) {
          setUserLateMessage(UserLateMessage.Last)
        }
      }, 10000)
      return () => {
        clearInterval(intervalID)
        setUserLateMessage(UserLateMessage.None)
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedToCall, peerJoined, interviewStatus, interviewScheduledTime])

  const isFullscreen =
    !hasPoorConnectionNotification &&
    !(endSoonNotification && !endSoonNotificationHidden) &&
    permissionsRequested &&
    permissionsGranted &&
    activeFullscreen &&
    !inPreview &&
    !hasDrawer &&
    !chatNotification

  const drawerHasMask =
    hasDrawer &&
    ((peerJoined && !remoteCameraMuted) || (!peerJoined && !localCameraMuted))

  const {
    localVideoDimensions,
    localSmallVideoDimensions,
    peerVideoDimensions,
    screenVideoDimensions,
    screenSmallVideoDimensions,
  } = getVideoDimensions()

  console.log(
    '[end button status]',
    canEndCall,
    !joinedToCall,
    joinInProgress,
    !preventRedirect
  )

  const hasUserLateMessage = [UserLateMessage.First, UserLateMessage.Last].includes(
    userLateMessage
  )

  return (
    <ThemeProvider theme={theme}>
      {/* Desktop Layout */}

      {!isMobileOnly && (
        <Layout.Page>
          <Layout.Header>
            <Layout.HeaderSession>
              <Logo isExpert={isExpert} />
            </Layout.HeaderSession>
            <Layout.HeaderControls>
              <Buttons.AudioMuteButton
                active={!localAudioMuted}
                disabled={!permissionsGranted || joinInProgress || !canMuteAudio}
                theme={theme}
                onClick={muteAudio}
              />
              <Buttons.VideoMuteButton
                active={!localCameraMuted}
                disabled={!permissionsGranted || joinInProgress || !canMuteCamera}
                theme={theme}
                onClick={muteCamera}
              >
                {hasPoorConnectionNotification && (
                  <Notifications.PoorConnectionIndicator
                    hasVideo={!localCameraMuted}
                    onHide={hidePoorConnectionNotification}
                  />
                )}
              </Buttons.VideoMuteButton>
              <Buttons.ScreenShareButton
                active={isScreenSharing}
                disabled={
                  !permissionsGranted ||
                  !joinedToCall ||
                  inCodeSharing ||
                  peerInCodeSharing ||
                  joinInProgress
                }
                inCodeSharing={inCodeSharing}
                peerInCodeSharing={peerInCodeSharing}
                theme={theme}
                onClick={isScreenSharing ? leaveScreenshare : handleTryScreenshare}
              />
              {codingSessionUrl && (
                <Buttons.CodeShareButton
                  active={inCodeSharing}
                  disabled={
                    !permissionsGranted ||
                    !joinedToCall ||
                    hasScreenshare ||
                    joinInProgress
                  }
                  isTransparentDisabled={onBoardingStep?.type === OnBoardingStep.Code}
                  theme={theme}
                  inScreenSharing={isScreenSharing}
                  peerInScreenSharing={isRemoteScreenSharing}
                  onClick={inCodeSharing ? leaveCodeshare : joinCodeshare}
                >
                  {!inCodeSharing && peerInCodeSharing && peerJoinedCodeNotification && (
                    <Notifications.PeerJoinedCodeNotification
                      onJoin={joinCodeshare}
                      onClose={closePeerJoinedCodeNotification}
                    />
                  )}
                  {inCodeSharing && !peerInCodeSharing && peerLeftCodeNotification && (
                    <Notifications.PeerLeftCodeNotification
                      onLeave={leaveCodeshare}
                      onClose={closePeerLeftCodeNotification}
                    />
                  )}
                  {!joinedToCall && onBoardingStep?.type === OnBoardingStep.Code && (
                    <Notifications.OnBoarding
                      step={onBoardingStep}
                      onClose={closeOnBoarding}
                      onNext={progressOnBoarding}
                    />
                  )}
                </Buttons.CodeShareButton>
              )}
              <Buttons.EndButton
                minutesToCanEnd={joinedToCall ? minutesTillEnd : 0}
                disabled={
                  (!canEndCall || !joinedToCall || joinInProgress) && !preventRedirect
                }
                isTransparentDisabled={onBoardingStep?.type === OnBoardingStep.Report}
                onClick={preventRedirect ? reload : showEndNotification}
              >
                {!joinedToCall && onBoardingStep?.type === OnBoardingStep.Report && (
                  <Notifications.OnBoarding
                    step={onBoardingStep}
                    onClose={closeOnBoarding}
                    onNext={progressOnBoarding}
                  />
                )}
                {joinedToCall && endSoonNotification && !endSoonNotificationHidden && (
                  <Notifications.EndCallNotification onClose={hideEndSoonNotification} />
                )}
              </Buttons.EndButton>
            </Layout.HeaderControls>
            <Layout.HeaderSettings>
              <Buttons.ChatButton
                active={chatDrawerOpen}
                disabled={!permissionsGranted || !joinedToCall || joinInProgress}
                showBadge={chatBadge}
                theme={theme}
                onClick={chatDrawerOpen ? handleCloseDrawer : handleChatOpen}
              >
                {chatNotification && !chatDrawerOpen && joinedToCall && (
                  <Notifications.OnBoardingChat
                    userName={profileFirstName}
                    message={chatLastMessage?.text}
                    onClick={handleChatOpen}
                  />
                )}
              </Buttons.ChatButton>
              {!isExpert && (
                <Buttons.NotesButton
                  disabled={!permissionsRequested || joinInProgress}
                  active={notesDrawerOpen}
                  theme={theme}
                  onClick={notesDrawerOpen ? handleCloseDrawer : openNotesDrawer}
                >
                  {!joinedToCall && onBoardingStep?.type === OnBoardingStep.Notes && (
                    <Notifications.OnBoarding
                      step={onBoardingStep}
                      onClose={closeOnBoarding}
                      onNext={progressOnBoarding}
                    />
                  )}
                </Buttons.NotesButton>
              )}
              {isExpert && (
                <Buttons.CoachingButton
                  disabled={!permissionsRequested || joinInProgress}
                  active={detailsDrawerOpen}
                  theme={theme}
                  onClick={detailsDrawerOpen ? handleCloseDrawer : openDetailsDrawer}
                >
                  {!joinedToCall && onBoardingStep?.type === OnBoardingStep.Profile && (
                    <Notifications.OnBoarding
                      step={onBoardingStep}
                      isExpert={isExpert}
                      onClose={closeOnBoarding}
                      onNext={progressOnBoarding}
                    />
                  )}
                </Buttons.CoachingButton>
              )}
              {!isExpert && (
                <Buttons.DetailsButton
                  disabled={!permissionsRequested || joinInProgress}
                  active={detailsDrawerOpen}
                  userName={peerFirstName}
                  theme={theme}
                  onClick={detailsDrawerOpen ? handleCloseDrawer : openDetailsDrawer}
                >
                  {!joinedToCall && onBoardingStep?.type === OnBoardingStep.Profile && (
                    <Notifications.OnBoarding
                      step={onBoardingStep}
                      onClose={closeOnBoarding}
                      onNext={progressOnBoarding}
                    />
                  )}
                </Buttons.DetailsButton>
              )}
              <Buttons.SettingsButton
                disabled={!permissionsRequested || !permissionsGranted || joinInProgress}
                active={settingsDrawerOpen}
                theme={theme}
                onClick={settingsDrawerOpen ? handleCloseDrawer : openSettingsDrawer}
              />
              {isExpert && (
                <Buttons.FeedbackButton
                  disabled={!permissionsRequested || joinInProgress}
                  active={feedbackDrawerOpen}
                  theme={theme}
                  onClick={feedbackDrawerOpen ? handleCloseDrawer : openFeedbackDrawer}
                />
              )}
            </Layout.HeaderSettings>
          </Layout.Header>

          <Layout.Main>
            <Layout.MainContent hasJoined={joinedToCall} hasSidebar={hasDrawer}>
              {!permissionsRequested && (
                <CameraPermission onClose={skipPermissionRequest} />
              )}
              {permissionsRequested && !permissionsGranted && <CameraNotAllowed />}
              {permissionsRequested && permissionsGranted && (
                <Layout.MainVideo
                  isExpert={isExpert}
                  hasJoined={joinedToCall || preventRedirect}
                  inCodeSharing={inCodeSharing}
                  isPortraitTablet={isTablet && !isLandscape}
                >
                  {((inPreview && !inCodeSharing && !hasScreenshare) ||
                    preventRedirect) && (
                    <Video.PreviewVideo
                      id="preview-video"
                      videoMuted={localCameraMuted}
                      audioMuted={localAudioMuted}
                      track={localAudioTrack}
                      dimensions={localVideoDimensions}
                      hasJoined={joinedToCall}
                      canBlur={isBackgeounBlurAlowed}
                      onBlurClicked={() =>
                        setBackgroundBlurring(!isBackgroundBlurEnabled)
                      }
                      isBackgroundBlurEnabled={isBackgroundBlurEnabled}
                    >
                      {inPreview && joinedToCall && !peerJoined && (
                        <Notifications.StartingSoon isExpert={isExpert} />
                      )}
                      {inPreview && joinedToCall && peerLeft && (
                        <Notifications.PeerLeft isExpert={isExpert} />
                      )}
                    </Video.PreviewVideo>
                  )}
                  {!inCodeSharing &&
                    (hasScreenshare || (peerJoined && !peerLeft)) &&
                    !preventRedirect && (
                      <Video.RemoteVideo
                        id="main-video"
                        audioMuted={
                          !isScreenSharing && !isRemoteScreenSharing && remoteAudioMuted
                        }
                        videoMuted={
                          !isScreenSharing && !isRemoteScreenSharing && remoteCameraMuted
                        }
                        track={
                          isRemoteScreenSharing || isScreenSharing
                            ? null
                            : remoteAudioTrack
                        }
                        logo={peerLogo}
                        username={peerFirstName}
                        dimensions={
                          hasScreenshare ? screenVideoDimensions : peerVideoDimensions
                        }
                        hasScreenshare={hasScreenshare}
                      >
                        {activeFeedback && <Feedback activeFeedback={activeFeedback} />}
                      </Video.RemoteVideo>
                    )}
                  {inCodeSharing && codingSessionUrl && (
                    <iframe
                      src={codingSessionUrl}
                      title="codeshare"
                      height={screenVideoDimensions.height}
                      width={screenVideoDimensions.width}
                    />
                  )}
                  {resizeListener}
                </Layout.MainVideo>
              )}
              {inPreview && !joinedToCall && !joinInProgress && !preventRedirect && (
                <ReadyToJoin
                  isReadyToJoin={!localAudioTrackBusy && !localCameraTrackBusy}
                  showHint={!precallDrawerOpen}
                  onJoin={join}
                  onTest={handleRunPrecallTests}
                />
              )}
              {inPreview && !joinedToCall && joinInProgress && !preventRedirect && (
                <JoiningCall />
              )}
              {joinedToCall && interviewStartedAt && (
                <ProgressBar isExpert={isExpert} width={screenVideoDimensions.width} />
              )}
            </Layout.MainContent>

            <Layout.Sidebar
              hasJoined={joinedToCall}
              height={screenVideoDimensions.height}
              inCodeSharing={inCodeSharing}
              isExpert={isExpert}
              visible={hasDrawer}
              onClose={handleCloseDrawer}
            >
              {detailsDrawerOpen && (
                <Sidebars.DetailsSidebar isExpert={isExpert} theme={theme} />
              )}
              {notesDrawerOpen && (
                <Sidebars.NotesSidebar interviewToken={interviewToken} onClose={null} />
              )}
              {settingsDrawerOpen && (
                <Sidebars.SettingsSidebar
                  agoraRTC={rtc}
                  audioTrack={localAudioTrack}
                  devices={devices}
                  isScreensharing={isScreenSharing}
                  settings={settings}
                  theme={theme}
                  isBackgroundBlurEnabled={isBackgroundBlurEnabled}
                  canBlur={isBackgeounBlurAlowed}
                  changeCamera={changeCamera}
                  changeMicrophone={changeMicrophone}
                  changeSpeaker={changeSpeaker}
                  changeVideoProfile={changeVideoProfile}
                  changeBackgroundBlur={setBackgroundBlurring}
                />
              )}
              {precallDrawerOpen && !joinedToCall && (
                <PrecallTestView
                  agoraRTC={rtc}
                  devices={devices}
                  settings={settings}
                  changeCamera={changeCamera}
                  changeMicrophone={changeMicrophone}
                  changeSpeaker={changeSpeaker}
                  onClose={handlePrecallTestClose}
                  onShowSupport={handleOpenSupport}
                />
              )}
              {chatDrawerOpen && (
                <Sidebars.ChatSidebar
                  isExpert={isExpert}
                  onSend={sendChatMessage}
                  // onSendFile={sendChatFile}
                  chatMessages={chatMessages}
                  onClose={null}
                  isLandscape={false}
                />
              )}
              {feedbackDrawerOpen && (
                <Sidebars.FeedbackSidebar
                  onDelete={handleDeleteFeedback}
                  onEdit={handleEditFeedback}
                />
              )}
            </Layout.Sidebar>
            {((peerJoined && !peerLeft) || hasScreenshare || inCodeSharing) &&
              !preventRedirect && (
                <Video.SmallVideosContainer
                  isExpert={isExpert}
                  canCollapse={
                    (hasScreenshare || inCodeSharing) && peerJoined && !peerLeft
                  }
                >
                  <Video.SmallVideo
                    id="local-video"
                    isLocal
                    username={profileFirstName}
                    videoMuted={localCameraMuted}
                    audioMuted={localAudioMuted}
                    track={localAudioTrack}
                    logo={profileLogo}
                    isCompact={
                      hasScreenshare || (inCodeSharing && peerJoined && !peerLeft)
                    }
                    contain={isTablet && !isLandscape}
                  />
                  {peerJoined && !peerLeft && (hasScreenshare || inCodeSharing) && (
                    <Video.SmallVideo
                      id="peer-video"
                      isLocal={false}
                      username={peerFirstName}
                      videoMuted={remoteCameraMuted}
                      audioMuted={remoteAudioMuted}
                      track={remoteAudioTrack}
                      logo={peerLogo}
                      isCompact={hasScreenshare || inCodeSharing}
                      contain={false}
                    />
                  )}
                </Video.SmallVideosContainer>
              )}
          </Layout.Main>

          {isExpert && (
            <Layout.Footer>
              {(!peerJoined ||
                (peerLeft && interviewStatus !== InterviewStatus.ENDED)) && (
                <Layout.FooterBlocker
                  isTransparent={onBoardingStep?.type === OnBoardingStep.Reactions}
                />
              )}
              <QuickReactions sendNote={handleSendFeedback}>
                {!joinedToCall && onBoardingStep?.type === OnBoardingStep.Reactions && (
                  <Notifications.OnBoarding
                    step={onBoardingStep}
                    onClose={closeOnBoarding}
                    onNext={progressOnBoarding}
                  />
                )}
              </QuickReactions>
              <Layout.FooterSeparator />
              <TextFeedback sendNote={handleSendFeedback} />
            </Layout.Footer>
          )}

          {hasUnavailableDevice && (
            <Notifications.DeviceUnavailable
              micAvailable={microphoneAvailable}
              cameraAvailable={cameraAvailable}
              speakerAvailable={speakerAvailable}
              onCancel={handleConnectToAltRoom}
            />
          )}
          {interruptScreenshareDialog && (
            <InterruptSharinPopup
              userName={peerFirstName}
              onCancel={closeScreenshareInterruptDialog}
              onShare={handleInterruptScreenshare}
            />
          )}
          {isScreenSharing && <SharingPopup stopSharing={leaveScreenshare} />}
          {endNotification && (
            <Notifications.EndCallView
              onEnd={handleEndCallClick}
              onClose={(showFeedback) => {
                hideEndNotification()
                if (preventRedirect && showFeedback) {
                  openFeedbackDrawer()
                }
              }}
              extra={preventRedirect}
              isExpert={isExpert}
            />
          )}
          {isDisconnected && <Notifications.DisconnectedNotification />}
        </Layout.Page>
      )}

      {/* Mobile Layout */}

      {isMobileOnly && (
        <Layout.PageMobile
          fullscreen={isFullscreen}
          isExpert={isExpert}
          extendFullscreen={extendFullscreen}
        >
          <Layout.MainMobile
            blur={drawerHasMask}
            isExpert={isExpert}
            toggleFullscreen={toggleFullscreen}
          >
            {permissionsRequested && !permissionsGranted && <CameraNotAllowed />}
            {permissionsRequested && permissionsGranted && (
              <Layout.MobileMainVideo
                isFullscreen={isFullscreen}
                hasScreenshare={hasScreenshare}
                preview={inPreview && !joinedToCall && !preventRedirect}
                isExpert={isExpert}
              >
                <Slider arrows={false} dots infinite={false}>
                  {(inPreview || preventRedirect) && (
                    <Video.MobilePreviewVideo
                      id="preview-video"
                      videoMuted={localCameraMuted}
                      audioMuted={localAudioMuted}
                      track={localAudioTrack}
                      dimensions={localVideoDimensions}
                      hasJoined={joinedToCall || preventRedirect}
                    />
                  )}
                  {joinedToCall && peerJoined && !peerLeft && (
                    <Video.MobileRemoteVideo
                      id="main-video"
                      audioMuted={
                        !isScreenSharing && !isRemoteScreenSharing && remoteAudioMuted
                      }
                      videoMuted={
                        !isScreenSharing && !isRemoteScreenSharing && remoteCameraMuted
                      }
                      track={
                        isRemoteScreenSharing || isScreenSharing ? null : remoteAudioTrack
                      }
                      username={peerFirstName}
                      logo={peerLogo}
                      dimensions={
                        hasScreenshare ? screenVideoDimensions : peerVideoDimensions
                      }
                      hasScreenshare={hasScreenshare}
                    />
                  )}
                  {joinedToCall && peerJoined && !peerLeft && hasScreenshare && (
                    <Video.MobileSmallSliderContainer height={window.innerHeight}>
                      <Video.SmallVideoMobile
                        id="local-video"
                        isLocal
                        username={profileFirstName}
                        videoMuted={localCameraMuted}
                        audioMuted={localAudioMuted}
                        track={localAudioTrack}
                        dimensions={screenSmallVideoDimensions}
                        logo={profileLogo}
                      />
                      <Video.SmallVideoMobile
                        id="peer-video"
                        isLocal={false}
                        username={peerFirstName}
                        videoMuted={remoteCameraMuted}
                        audioMuted={remoteAudioMuted}
                        track={remoteAudioTrack}
                        dimensions={screenSmallVideoDimensions}
                        logo={peerLogo}
                      />
                    </Video.MobileSmallSliderContainer>
                  )}
                </Slider>
                {resizeListener}
              </Layout.MobileMainVideo>
            )}
            {inPreview && !joinedToCall && !joinInProgress && !preventRedirect && (
              <MobileReadyToJoin
                isReadyToJoin={!localAudioTrackBusy && !localCameraTrackBusy}
                showHint={!precallDrawerOpen}
                onJoin={join}
                onTest={handleRunPrecallTests}
              />
            )}
            {inPreview && !joinedToCall && joinInProgress && <MobileJoiningCall />}
            {inPreview && joinedToCall && !peerJoined && (
              <Notifications.MobileStartingSoon />
            )}
            {((inPreview && joinedToCall && peerLeft) || preventRedirect) && (
              <Notifications.MobilePeerLeft />
            )}
            {!hasScreenshare && joinedToCall && peerJoined && !peerLeft && (
              <Video.MobileSmallContainer>
                <Video.SmallVideoMobile
                  id="local-video"
                  isLocal
                  username={profileFirstName}
                  videoMuted={localCameraMuted}
                  audioMuted={localAudioMuted}
                  track={localAudioTrack}
                  dimensions={localSmallVideoDimensions}
                  logo={profileLogo}
                />
              </Video.MobileSmallContainer>
            )}
            {activeFeedback && (
              <MobileFeedback
                activeFeedback={activeFeedback}
                isFullscreen={isFullscreen}
              />
            )}
          </Layout.MainMobile>

          <Layout.HeaderMobile visible={!isFullscreen}>
            <Buttons.AudioMuteButtonMobile
              disabled={!permissionsGranted || joinInProgress || !canMuteAudio}
              active={!localAudioMuted}
              theme={theme}
              onClick={muteAudio}
            />
            <Buttons.VideoMuteButtonMobile
              disabled={!permissionsGranted || joinInProgress || !canMuteCamera}
              active={!localCameraMuted}
              theme={theme}
              onClick={muteCamera}
            />
            <Buttons.MoreButton
              disabled={joinInProgress}
              theme={theme}
              showBadge={chatBadge}
              onClick={menuDrawerOpen ? handleCloseDrawer : openMenuDrawer}
            />
            <Buttons.EndButtonMobile
              minutesToCanEnd={joinedToCall ? minutesTillEnd : 0}
              disabled={
                (!canEndCall || !joinedToCall || joinInProgress) && !preventRedirect
              }
              isTransparentDisabled={onBoardingStep?.type === OnBoardingStep.Report}
              onClick={preventRedirect ? reload : showEndNotification}
            />
          </Layout.HeaderMobile>

          {!hasDrawer && isExpert && (
            <Layout.FooterMobile visible={!isFullscreen}>
              {(!peerJoined ||
                (peerLeft && interviewStatus !== InterviewStatus.ENDED)) && (
                <Layout.FooterBlocker
                  isTransparent={onBoardingStep?.type === OnBoardingStep.Reactions}
                />
              )}
              <MobileQuickReactions sendNote={handleSendFeedback} />
            </Layout.FooterMobile>
          )}
          {hasDrawer && (
            <Layout.DrawerMobile
              hasMask={drawerHasMask}
              isMenu={menuDrawerOpen}
              onClose={handleCloseDrawer}
            >
              {menuDrawerOpen && (
                <Sidebars.MenuSidebar
                  hasMask={drawerHasMask}
                  isExpert={isExpert}
                  theme={theme}
                  joinedToCall={joinedToCall}
                  inactive={!permissionsRequested || !permissionsGranted}
                  showChatBadge={chatBadge}
                  onSelect={handleMenuSelect}
                />
              )}
              {detailsDrawerOpen && (
                <Sidebars.DetailsSidebar isExpert={isExpert} theme={theme} />
              )}
              {settingsDrawerOpen && (
                <Sidebars.SettingsSidebar
                  agoraRTC={rtc}
                  audioTrack={localAudioTrack}
                  devices={devices}
                  isScreensharing={isScreenSharing}
                  settings={settings}
                  theme={theme}
                  isBackgroundBlurEnabled={isBackgroundBlurEnabled}
                  canBlur={isBackgeounBlurAlowed}
                  changeCamera={changeCamera}
                  changeMicrophone={changeMicrophone}
                  changeSpeaker={changeSpeaker}
                  changeVideoProfile={changeVideoProfile}
                  changeBackgroundBlur={setBackgroundBlurring}
                />
              )}
              {sessionDetailsDrawerOpen && (
                <Sidebars.SessionDetailsSidebarMobile theme={theme} />
              )}
              {precallDrawerOpen && !joinedToCall && (
                <PrecallTestView
                  agoraRTC={rtc}
                  devices={devices}
                  settings={settings}
                  changeCamera={changeCamera}
                  changeMicrophone={changeMicrophone}
                  changeSpeaker={changeSpeaker}
                  onClose={handlePrecallTestClose}
                  onShowSupport={handleOpenSupport}
                />
              )}
            </Layout.DrawerMobile>
          )}

          {hasUnavailableDevice && (
            <Notifications.DeviceUnavailable
              micAvailable={microphoneAvailable}
              cameraAvailable={cameraAvailable}
              speakerAvailable={speakerAvailable}
              onCancel={handleConnectToAltRoom}
            />
          )}
          {hasPoorConnectionNotification && (
            <Notifications.MobilePoorConnectionIndicator
              theme={theme}
              muteVideo={!localCameraMuted ? muteCameraPoorNetwork : undefined}
              onHide={hidePoorConnectionNotification}
            />
          )}
          {!inCodeSharing && peerInCodeSharing && peerJoinedCodeNotification && (
            <Notifications.MobilePeerJoinedCodeNotification
              theme={theme}
              onClose={closePeerJoinedCodeNotification}
            />
          )}

          {!joinedToCall && onBoardingStep && (
            <Notifications.OnBoardingMobile
              step={onBoardingStep}
              onClose={closeOnBoarding}
              onNext={progressOnBoarding}
              isExpert={isExpert}
            />
          )}
          {chatNotification && !hasDrawer && joinedToCall && (
            <Notifications.OnBoardingChatMobile
              userName={profileFirstName}
              message={chatLastMessage?.text}
              onClick={handleChatOpen}
              onClose={hideChatNotification}
            />
          )}
          {joinedToCall && endSoonNotification && !endSoonNotificationHidden && (
            <Notifications.EndCallNotificationMobile onClose={hideEndSoonNotification} />
          )}
          {endNotification && (
            <Notifications.EndCallView
              onEnd={handleEndCallClick}
              onClose={(showFeedback) => {
                hideEndNotification()
                if (preventRedirect && showFeedback) {
                  openFeedbackDrawer()
                }
              }}
              extra={preventRedirect}
              isExpert={isExpert}
            />
          )}
          {chatDrawerOpen && (
            <Sidebars.ChatSidebar
              isExpert={isExpert}
              onSend={sendChatMessage}
              // onSendFile={sendChatFile}
              chatMessages={chatMessages}
              onClose={handleCloseDrawer}
              isLandscape={isLandscape}
            />
          )}
          {notesDrawerOpen && (
            <Sidebars.NotesSidebar
              interviewToken={interviewToken}
              onClose={handleCloseDrawer}
            />
          )}
          {feedbackDrawerOpen && (
            <Sidebars.FeedbackSidebarMobile
              onAdd={handleSendFeedback}
              onDelete={handleDeleteFeedback}
              onEdit={handleEditFeedback}
              onClose={handleCloseDrawer}
            />
          )}

          {isDisconnected && <Notifications.DisconnectedNotification />}
        </Layout.PageMobile>
      )}

      {hasConnectionError && !connectToAlternativeRoom && (
        <Notifications.AgoraError onCancel={handleConnectToAltRoom} />
      )}

      {connectToAlternativeRoom && (
        <Notifications.ConnectAlternativeRoom
          link={backupMeetingURL}
          onClick={handleMoveToAlternativeLink}
        />
      )}

      {(goToAltRoom || peerConnectedToAltRoom) && (
        <Notifications.GoToAltRoomNotification
          link={backupMeetingURL}
          peerName={peerFirstName}
        />
      )}

      {screenError && (
        <Notifications.ScreenShareErrorNotification onClose={hideScreenError} />
      )}

      <SmallIntercomButton
        onClick={handleOpenSupport}
        isMobile={isMobileOnly}
        isLandscape={isLandscape}
      />

      {hasUserLateMessage && (
        <LateMessage
          messageType={userLateMessage}
          isExpert={isExpert}
          peerFirstName={peerFirstName}
          peerLastName={peerLastName}
          onClose={handleCloseLateMessage}
        />
      )}
    </ThemeProvider>
  )
}

export default withOrientationChange(VideoCallContainer)
