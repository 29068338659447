import { all } from 'redux-saga/effects'
import { watchSignIn, watchReset, watchSignOut, watchChangePassword } from '../auth/sagas'
import {
  watchGetAgencies,
  watchGetInterviews as watchGetInterviewsAdmin,
  watchGetStats,
} from '../admin/sagas'
import {
  watchAskExpertAvailabilitySaga,
  watchGetInterviews,
  watchGetInterview as watchGetAgencyInterview,
  watchSendToCandidate,
  watchSaveSessionReport,
  watchInterviewComplete,
  watchInterviewToEnd,
  watchLoadExpertDataFailure,
  watchLoadExpertDataSuccess,
  watchGetStats as watchGetAgencyStats,
  watchUpdateAvailabilityInfo,
  watchUpdateOrderInfo,
  watchUpdatePostSessionInfo,
  watchUpdateSchedulingInfo,
} from '../agency/sagas'
import {
  watchGetAgencyBySubdomain,
  watchUpdateBooking,
  watchGetInterview,
  watchGetInterviewStatus,
  watchStartInterview,
  watchInterviewCodeSharing,
  watchEndInterview,
  watchReportInterview,
  watchCreateBooking as watchCreateCandidateBooking,
  watchAssignTimeSlot,
  watchCreateIntent,
  watchPaySucceed,
  pollEndTaskWatcher,
  watchSubmitAvailability,
  watchDeclineAvailability,
  watchGenerateConversationToken,
  watchLinkConversation,
} from '../interview/sagas'
import { watchSendFeedback } from '../report/sagas'

import { watchAssignOps } from './requests/assignOps'
import { watchCreateAgency } from './requests/createAgency'
import { watchDeleteAgencyInterview } from './requests/deleteAgencyInterview'
import { watchDownloadAdminReport } from './requests/downloadAdminReport'
import { watchDownloadAgencyReport } from './requests/downloadAgencyReport'
import { watchEditAgency } from './requests/editAgency'
import { watchGetAgency } from './requests/getAgency'
import { watchGetConversation } from './requests/getConversation'
import { watchGetSystemSettings } from './requests/getSystemSettings'
import { watchSetInterviewTimestamp } from './requests/setInterviewTimestamp'
import { watchSetRecordingTimestamp } from './requests/setRecordingTimestamp'
import { watchUpdateSystemSettings } from './requests/updateSystemSettings'
import { watchSendApplicantNote } from './requests/sendApplicantNote'
import { watchRemoveApplicantNote } from './requests/removeApplicantNote'
import { watchGetApplicantNotes } from './requests/getApplicantNotes'
import { watchAdminRescheduleInterview } from './requests/adminRescheduleInterview'
import { watchCandidateRescheduleInterview } from './requests/candidateRescheduleInterview'
import { watchBulkCreateInterview } from './requests/bulkCreateInterview'
import { watchAvaGetInterview } from './requests/ava/getInterview'
import { watchAvaGetInterviews } from './requests/ava/getInterviews'
import { watchAvaSaveAnswer } from './requests/ava/saveAnswer'
import { watchAvaSaveInfo } from './requests/ava/saveInfo'
import { watchAvaSynthesizeVoice } from './requests/ava/synthesizeVoice'
import { watchMoveToAlternativeLink } from './requests/moveToAlternativeLink'
import { watchInterviewStartWatcher } from './watchers/interviewStartWatcher'
import { watchSaveExpertNote } from './requests/saveExpertNote'
import { watchRemoveExpertNote } from './requests/removeExpertNote'
import { watchCreateInterview } from './requests/createInterview'
import { watchGetBulkInterviews } from './requests/getBulkInterviews'
import { watchAvaInterviewWatcher } from './watchers/avaInterviewWatcher'
import { watchAvaInterviewFollowUpWatcher } from './watchers/avaInterviewFollowUpWatcher'
import { watchAvaReportWatcher } from './watchers/avaReportWatcher'
import { watchAvaGetInterviewPrompts } from './requests/ava/getInterviewPrompts'
import { watchRequestDemo } from './requests/requestDemo'
import { watchSubscribe } from './requests/subscribe'
import { watchAvaCreateInterview } from './requests/ava/createInterview'
import { watchAvaReplaceQuestion } from './requests/ava/replaceQuestion'
import { watchAvaCompleteInterview } from './requests/ava/completeInterview'
import { watchAvaRetryQuestionsGeneration } from './requests/ava/retryQuestionGeneration'
import { watchAvaRetryReportGeneration } from './requests/ava/retryReportGeneration'

export default function* rootSaga() {
  yield all([
    watchSignIn(),
    watchReset(),
    watchSignOut(),
    watchGetAgencies(),
    watchGetInterviewsAdmin(),
    watchAskExpertAvailabilitySaga(),
    watchGetAgency(),
    watchGetInterviews(),
    watchGetInterviewStatus(),
    watchGetAgencyInterview(),
    watchChangePassword(),
    watchGetAgencyBySubdomain(),
    watchUpdateBooking(),
    watchGetInterview(),
    watchCreateCandidateBooking(),
    watchStartInterview(),
    watchInterviewCodeSharing(),
    pollEndTaskWatcher(),
    watchSendToCandidate(),
    watchEndInterview(),
    watchReportInterview(),
    watchAssignTimeSlot(),
    watchCreateIntent(),
    watchPaySucceed(),
    watchSaveSessionReport(),
    watchInterviewComplete(),
    watchInterviewToEnd(),
    watchSendFeedback(),
    watchLoadExpertDataFailure(),
    watchLoadExpertDataSuccess(),
    watchGetStats(),
    watchGetAgencyStats(),
    watchSubmitAvailability(),
    watchDeclineAvailability(),
    watchUpdateAvailabilityInfo(),
    watchUpdateOrderInfo(),
    watchUpdatePostSessionInfo(),
    watchUpdateSchedulingInfo(),
    watchGenerateConversationToken(),
    watchLinkConversation(),
    watchAssignOps(),
    watchCreateAgency(),
    watchDeleteAgencyInterview(),
    watchDownloadAdminReport(),
    watchDownloadAgencyReport(),
    watchEditAgency(),
    watchGetAgency(),
    watchGetConversation(),
    watchGetSystemSettings(),
    watchSetInterviewTimestamp(),
    watchSetRecordingTimestamp(),
    watchUpdateSystemSettings(),
    watchSendApplicantNote(),
    watchRemoveApplicantNote(),
    watchGetApplicantNotes(),
    watchAdminRescheduleInterview(),
    watchCandidateRescheduleInterview(),
    watchBulkCreateInterview(),
    watchMoveToAlternativeLink(),
    watchAvaCompleteInterview(),
    watchAvaCreateInterview(),
    watchAvaGetInterview(),
    watchAvaGetInterviews(),
    watchAvaSaveAnswer(),
    watchAvaSaveInfo(),
    watchAvaSynthesizeVoice(),
    watchInterviewStartWatcher(),
    watchSaveExpertNote(),
    watchRemoveExpertNote(),
    watchCreateInterview(),
    watchGetBulkInterviews(),
    watchAvaInterviewWatcher(),
    watchAvaReportWatcher(),
    watchAvaGetInterviewPrompts(),
    watchRequestDemo(),
    watchSubscribe(),
    watchAvaReplaceQuestion(),
    watchAvaInterviewFollowUpWatcher(),
    watchAvaRetryQuestionsGeneration(),
    watchAvaRetryReportGeneration(),
  ])
}
