/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { AvaInterviewStatus, FirstPageBehaviors } from 'src/api'
import * as interviewSelectors from 'src/interview/selectors'
import { Loader, NotFound } from 'src/components'
import useSupport from 'src/utils/useSupport'
import {
  avaGetInterviewAction,
  avaInterviewSelectors,
  avaSettingsSelectors,
  avaStepSelectors,
  clearAvaInterviewAction,
  clearAvaIntroStepAction,
  clearAvaOnboardingStepAction,
  clearAvaStepAction,
  updateAvaIntroStepAction,
  updateAvaStepAction,
} from '../redux'

import IntroContainer, { IntroStep } from './containers/IntroContainer'
import OnboardingContainer from './containers/OnboardingContainer'
import { AvaStep } from './models'
import ReportContainer from './containers/ReportContainer'
import LandingContainer from './containers/LandingContainer'
import InterviewContainer from './containers/InterviewContainer'

function AvaLayout() {
  const dispatch = useDispatch()

  const avaStep = useSelector(avaStepSelectors.data)
  const interview = useSelector(avaInterviewSelectors.data)
  const agency = useSelector(interviewSelectors.agency)
  const avaSettings = useSelector(avaSettingsSelectors.data)

  const { openSupport, hideLauncher } = useSupport(true)
  const params = new URLSearchParams(useLocation().search)
  const { interviewToken } = useParams()

  const skiplanding = params.get('sl')

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    ;(window as any).intercomSettings = { alignment: 'right' }
  }, [])

  useEffect(() => {
    if (avaStep !== AvaStep.Landing) hideLauncher()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaStep])

  useEffect(() => {
    if (skiplanding) hideLauncher()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skiplanding])

  const showSupportRef = useRef(false)

  useEffect(() => {
    if (showSupportRef.current !== avaSettings.showSupport) {
      showSupportRef.current = avaSettings.showSupport
      openSupport()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaSettings.showSupport])

  useEffect(() => {
    if (interviewToken) dispatch(avaGetInterviewAction({ interviewToken }))

    return () => {
      dispatch(clearAvaOnboardingStepAction)
      dispatch(clearAvaStepAction)
      dispatch(clearAvaIntroStepAction)
      dispatch(clearAvaInterviewAction)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  useEffect(() => {
    if (!loaded && agency?.id) {
      if (!interviewToken) {
        if (agency.direct_users_first_page === FirstPageBehaviors.OrderForm)
          dispatch(updateAvaStepAction(AvaStep.Onboarding))
        setLoaded(true)
      } else if (interview?.order_number) {
        switch (interview.status) {
          case AvaInterviewStatus.IN_INTERVIEW:
            dispatch(updateAvaStepAction(AvaStep.Intro))
            dispatch(updateAvaIntroStepAction(IntroStep.GettingQuestions))

            break
          case AvaInterviewStatus.COMPLETED:
            dispatch(updateAvaStepAction(AvaStep.Report))
            break
          default:
            if (agency.invited_users_first_page === FirstPageBehaviors.OrderForm)
              dispatch(updateAvaStepAction(AvaStep.Onboarding))
            break
        }
        setLoaded(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, interview, interviewToken, loaded])

  const renderStep = (currentStep: AvaStep) => {
    if (!agency.id && currentStep !== AvaStep.Report) return null

    if (!interviewToken && !agency.allow_direct_registration) {
      return <NotFound />
    }

    switch (currentStep) {
      case AvaStep.Landing:
        if (skiplanding) {
          return <OnboardingContainer />
        }
        return <LandingContainer />
      case AvaStep.Onboarding:
        return <OnboardingContainer />
      case AvaStep.Intro:
        return <IntroContainer />
      case AvaStep.Interview:
        return <InterviewContainer />
      default:
        return <ReportContainer />
    }
  }

  return !loaded ? <Loader /> : renderStep(avaStep)
}

export default AvaLayout
