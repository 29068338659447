import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: transparent;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const DataContainer = styled.div`
  background: #ffffff;
  border: 1px solid #b202ff;
  border-radius: 10px;
  padding: 40px;
  width: 549px;

  ${isMobileOnly &&
  css`
    width: 95%;
    max-width: 343px;
    padding: 19px 16px;
  `}
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #1d0e3b;
  margin-bottom: 4px;

  ${isMobileOnly &&
  css`
    font-size: 20px;
    line-height: 24px;
  `}
`

const SubTitle = styled(Title)`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
`

const Label = styled.div<{ isLink?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isLink }) => (isLink ? '#B202FF' : '#3c424e')};
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};
  text-decoration: ${({ isLink }) => (isLink ? 'underline' : 'none')};
`

const ListStyled = styled.ol`
  padding-left: 18px;
  font-weight: 400;
  font-size: 14px;
  color: #3c424e;
`

const LineContainer = styled.div`
  font-weight: 500;
  display: flex;
`

const BoldText = styled(Title)`
  font-weight: 500;
  font-size: 12px;
`

const LinkItem = styled.span`
  cursor: pointer;
  font-size: 12px;
  color: rgba(178, 2, 255, 1);
  text-decoration: underline;
  margin: 0 0 0 12px;
`

interface IDeviceUnavailable {
  cameraAvailable: boolean
  micAvailable: boolean
  speakerAvailable: boolean
  onCancel: () => void
}

const HelpItems = [
  {
    text:
      'Quit all other apps that might be using your camera (zoom, other tabs, other browsers)',
  },
  { text: 'Try to reload the page', link: true },
  {
    text: 'Try using a different browser (most recommended: chrome/firefox)',
  },
  { text: 'Try another device' },
]

export default function DeviceUnavailable({
  cameraAvailable,
  micAvailable,
  speakerAvailable,
  onCancel,
}: IDeviceUnavailable) {
  let title = ''
  const pushToTitle = (text: string) => {
    if (title) {
      title += ` and ${text}`
    } else {
      title = `We can’t detect your ${text}`
    }
  }
  if (!cameraAvailable) {
    pushToTitle('camera')
  }
  if (!micAvailable) {
    pushToTitle('microphone')
  }
  if (!speakerAvailable) {
    pushToTitle('speaker')
  }

  // eslint-disable-next-line no-restricted-globals
  const onRefresh = () => location.reload()

  return (
    <Container>
      <DataContainer>
        <Title>{title}</Title>
        <SubTitle>Please follow the next steps:</SubTitle>
        <ListStyled type="1">
          {HelpItems.map((item) => (
            <li key={item.text}>
              <Label
                isLink={item.link}
                onClick={() => {
                  if (item.link) {
                    onRefresh()
                  }
                }}
              >
                {item.text}
              </Label>
            </li>
          ))}
        </ListStyled>
        <LineContainer>
          <BoldText>
            Are you able to connect to your session after following the steps?
          </BoldText>
          <LinkItem onClick={onCancel}>No </LinkItem>
        </LineContainer>
      </DataContainer>
    </Container>
  )
}
