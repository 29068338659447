import useStateRef from 'react-usestateref'

const useCodeshare = (sendCodeshareStatus: (status: boolean) => Promise<void>) => {
  const [inCodeSharing, setInCodeSharing] = useStateRef(false)
  const [peerInCodeSharing, setPeerInCodeSharing] = useStateRef(false)

  const [peerJoinedCodeNotification, setPeerJoinedCodeNotification] = useStateRef(false)
  const [peerLeftCodeNotification, setPeerLeftCodeNotification] = useStateRef(false)

  const closePeerJoinedCodeNotification = () => setPeerJoinedCodeNotification(false)
  const closePeerLeftCodeNotification = () => setPeerLeftCodeNotification(false)

  const onPeerJoinedCodeshare = () => {
    setPeerInCodeSharing(true)
    setPeerLeftCodeNotification(false)
    setPeerJoinedCodeNotification(true)
  }

  const onPeerLeftCodeshare = () => {
    setPeerInCodeSharing(false)
    setPeerJoinedCodeNotification(false)
    setPeerLeftCodeNotification(true)
  }

  const joinCodeshare = () => {
    sendCodeshareStatus(true)
    setInCodeSharing(true)
    setPeerJoinedCodeNotification(false)
    setPeerLeftCodeNotification(false)
  }

  const leaveCodeshare = () => {
    sendCodeshareStatus(false)
    setInCodeSharing(false)
    setPeerJoinedCodeNotification(false)
    setPeerLeftCodeNotification(false)
  }

  return {
    inCodeSharing,
    peerInCodeSharing,
    peerJoinedCodeNotification,
    peerLeftCodeNotification,
    closePeerJoinedCodeNotification,
    closePeerLeftCodeNotification,
    joinCodeshare,
    leaveCodeshare,
    onPeerJoinedCodeshare,
    onPeerLeftCodeshare,
  }
}

export default useCodeshare
