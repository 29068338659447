import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import SvgFeedback from 'src/static/components/SvgFeedback'
import SvgRemove from 'src/static/components/SvgRemove'

import useInterview from 'src/videocall/services/useInterview'
import { IExpertNote } from '../../../api'
import { Text } from '../../../components'
import { expertNotesSelectors } from '../../../redux'

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e3ea;
  display: flex;
  justify-content: flex-start;

  height: 105px;
  padding: 0 39px;
  @media screen and (max-width: 2500px) {
    height: 87px;
    padding: 0 32px;
  }
  @media screen and (max-width: 2000px) {
    height: 70px;
    padding: 0 26px;
  }
  @media screen and (max-width: 1200px) {
    height: 52px;
    padding: 0 19px;
  }
  @media screen and (max-width: 900px) {
    height: 35px;
    padding: 0 13px;
  }

  > svg {
    height: 20px;
    width: 20px;
    @media screen and (max-width: 2500px) {
      height: 18px;
      width: 18px;
    }
    @media screen and (max-width: 2000px) {
      height: 14px;
      width: 14px;
    }
    @media screen and (max-width: 1200px) {
      height: 11px;
      width: 11px;
    }
    @media screen and (max-width: 900px) {
      height: 7px;
      width: 7px;
    }
  }
`

const HeaderTitle = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.sidebarTitleColor};

  font-size: 18px;
  line-height: 36px;
  margin-left: 15px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
    margin-left: 13px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
    margin-left: 8px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
    margin-left: 5px;
  }
`

const ItemsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-bottom: 1px solid #e1e3ea;
  animation: growDown 0.2s ease-in-out;
  transform-origin: top center;
  overflow: hidden;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`

const getMoodColor = (mood?: string) => {
  if (!mood) return 'transparent'
  switch (mood) {
    case 'Positive':
      return 'rgba(0, 222, 159, 0.2)'
    default:
      return 'rgba(242, 52, 130, 0.2)'
  }
}

const ItemText = styled.div<{ mood?: string }>`
  margin-right: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #21304e;
  padding: ${({ mood }) => (mood ? 4 : 0)}px;
  border-radius: ${({ mood }) => (mood ? 12 : 0)}px;
  background: ${({ mood }) => getMoodColor(mood)};
  overflow: hidden;
`

const TimeLabel = styled.div<{ mood?: string }>`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8f9bb3;
  background: #f5f6f8;
  border-radius: 12px;
  height: fit-content;
  padding: 2px;
  margin-left: 8px;
  align-self: ${({ mood }) => (mood ? 'center' : 'auto')};
`

const HoverOverlay = styled.div<{ visible?: boolean }>`
  background: #ffffffa0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const HoverContainer = styled.div<{ visible?: boolean; display: boolean }>`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  background: white;
  top: 0;
  right: 0;
  height: 100%;
  border-left: 1px solid #e1e3ea;
  transform: translateX(${({ visible }) => (visible ? '0' : '100%')});
  transition: all 0.15s linear;
`

const HoverSeparator = styled.div`
  width: 1px;
  height: 60%;
  background: #e1e3ea;
`

const HoverButtonContainer = styled.div<{ float?: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 4px;
  margin: 0 20px;
  border-radius: 5px;
  cursor: pointer;
  float: ${({ float }) => float ?? 'unset'};

  &:hover {
    background: lightgray;
  }

  &:active {
    background: lightgray;
    opacity: 0.6;
  }
`

const EditContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 14px;
  left: 12px;
  width: calc(100% - 24px);
  height: calc(100% - 28px);
  background: #fff;
  border: 1px solid #b202ff;
  border-radius: 4px;
  padding: 12px;
`

const LineContainer = styled.div`
  display: flex;
  flex: 1;
`

const EditTextArea = styled.textarea`
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  color: #000000;
  flex: 1;
  border: none;
  outline: none;
  resize: none;
`

const StyledButton = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 145.19%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 7px 20px;
  background: #b202ff;
  border-radius: 4px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`

interface IProps {
  onDelete: (id: string) => void
  onEdit: (id: string, text: string) => void
}

export default function FeedbackSidebar({ onDelete, onEdit }: IProps) {
  const expertNotes = useSelector(expertNotesSelectors.data)

  const [hoverItem, setHoverItem] = useState<IExpertNote>()
  const [editFeedback, setEditFeeback] = useState<IExpertNote>()
  const [displayHover, setDisplayHover] = useState(false)
  const scrollAnchor = useRef<HTMLDivElement>()

  const { interviewStartedAt } = useInterview()

  const getRelativeTimestamp = (feedbackTs: string) => {
    const interviewStarted = new Date(interviewStartedAt)
    const feedback = new Date(feedbackTs)
    const ts = feedback.getTime() - interviewStarted.getTime()
    const resultTs = new Date(ts)
    const hours = resultTs.getUTCHours()
    const minutes = resultTs.getUTCMinutes()
    const seconds = resultTs.getUTCSeconds()

    const getTimeSegment = (num: number) => {
      return `${num < 10 ? '0' : ''}${num}`
    }

    return `${getTimeSegment(hours * 60 + minutes)}.${getTimeSegment(seconds)}`
  }

  const onEditFeedbackText = (text: string) => {
    setEditFeeback((feedback) => {
      return {
        ...feedback,
        text,
      }
    })
  }

  const onFinishEdit = (feedback: IExpertNote) => {
    onEdit(feedback.id, feedback.text)
    setEditFeeback(null)
    setHoverItem(null)
  }

  useEffect(() => {
    scrollAnchor.current?.scrollIntoView({ behavior: 'smooth' })
  }, [expertNotes])

  // hack. last item in list somewhy is broken after sidebar openning
  useEffect(() => {
    setTimeout(() => {
      setDisplayHover(true)
    }, 500)
  }, [])

  return (
    <Container>
      <Header>
        <SvgFeedback fill="#2f2f2f" />
        <HeaderTitle>Feedback</HeaderTitle>
      </Header>
      <ItemsContainer>
        {!editFeedback &&
          expertNotes.map((item) => (
            <ItemContainer
              key={item.id}
              onMouseMove={() => setHoverItem(item)}
              onMouseLeave={() => setHoverItem(null)}
            >
              <ItemText mood={item.mood}>{item.text}</ItemText>
              <TimeLabel mood={item.mood}>
                {getRelativeTimestamp(item.created_at)}
              </TimeLabel>
              <HoverOverlay visible={hoverItem?.id === item.id} />
              <HoverContainer display={displayHover} visible={hoverItem?.id === item.id}>
                {!item.mood && (
                  <>
                    <HoverButtonContainer onClick={() => setEditFeeback(item)}>
                      <SvgFeedback fill="#B202FF" />
                    </HoverButtonContainer>
                    <HoverSeparator />
                  </>
                )}
                <HoverButtonContainer onClick={() => onDelete(item.id)}>
                  <SvgRemove fill="#B202FF" />
                </HoverButtonContainer>
              </HoverContainer>
              <div ref={scrollAnchor} />
            </ItemContainer>
          ))}
        {editFeedback && (
          <EditContainer>
            <LineContainer>
              <EditTextArea
                value={editFeedback.text}
                onChange={(ev) => onEditFeedbackText(ev.target.value)}
              />
              <HoverButtonContainer float="right" onClick={() => onEditFeedbackText('')}>
                <SvgRemove fill="#B202FF" />
              </HoverButtonContainer>
            </LineContainer>
            <StyledButton onClick={() => onFinishEdit(editFeedback)}>Save</StyledButton>
          </EditContainer>
        )}
      </ItemsContainer>
    </Container>
  )
}
