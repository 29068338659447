import React from 'react'
import styled from '@emotion/styled'

export const HeaderSession = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

export const HeaderControls = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`

export const HeaderSettings = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  position: relative;
`

export const HeaderDivider = styled.span`
  border: 1px solid #462f71;
  height: 56%;
  margin: 0 10px;
  width: 1px;
`

const Container = styled.div`
  background-color: ${({ theme }: { theme: any }) => theme.headerBackgroundColor};
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  flex-shrink: 0;

  padding: 15px 90px;
  @media screen and (max-width: 2500px) {
    padding: 12px 75px;
  }
  @media screen and (max-width: 2000px) {
    padding: 10px 60px;
  }
  @media screen and (max-width: 1200px) {
    padding: 7px 45px;
  }
  @media screen and (max-width: 900px) {
    padding: 5px 30px;
  }
`

export function Header({ children }: React.HTMLProps<HTMLDivElement>) {
  return <Container>{children}</Container>
}

const MobileContainer = styled.div<{ visible: boolean }>`
  align-items: center;
  background-color: ${({ theme }: { theme: any }) => theme.headerBackgroundColor};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  grid-area: header;
  justify-content: space-between;
  padding: 8px 30px;
  width: 100%;

  @media (orientation: landscape) {
    height: 67px;
    padding: 10px 30%;
    position: absolute;
    top: 0;
  }
`

interface IHeaderMobile extends React.HTMLProps<HTMLDivElement> {
  visible: boolean
}

export function HeaderMobile({ children, visible }: IHeaderMobile) {
  return <MobileContainer visible={visible}>{children}</MobileContainer>
}
