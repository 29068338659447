import { put, takeEvery, call, take, race, delay } from 'typed-redux-saga'
import { push } from 'connected-react-router'
import {
  updateBooking as updateBookingAction,
  updateBookingSuccess,
  updateBookingFailure,
  createBooking as createBookingAction,
  createBookingSuccess,
  createBookingFailure,
  getInterview as getInterviewAction,
  getInterviewStatus,
  getInterviewSuccess,
  getInterviewFailure,
  getAgencyBySubdomain as getAgencyBySubdomainAction,
  getAgencyBySubdomainSuccess,
  getAgencyBySubdomainFailure,
  startInterview as startInterviewAction,
  startInterviewSuccess,
  startInterviewFailure,
  endInterview as endInterviewAction,
  endInterviewSuccess,
  endInterviewFailure,
  reportInterview as reportInterviewAction,
  reportInterviewSuccess,
  reportInterviewFailure,
  assignTimeSlotFailure,
  assignTimeSlotSuccess,
  assignTimeSlot as assignTimeSlotAction,
  createIntent as createIntentAction,
  createIntentSuccess,
  createIntentFailure,
  paySucceed as paySucceedAction,
  paySucceedFailure,
  paySucceedSuccess,
  interviewCodeSharing as interviewCodeSharingAction,
  submitAvailabilitySuccess,
  submitAvailabilityFailure,
  declineAvailabilitySuccess,
  declineAvailabilityFailure,
  submitAvailability as submitAvailabilityAction,
  declineAvailability as declineAvailabilityAction,
} from './actions'
import {
  IUpdateBookingAction,
  ICreateBookingAction,
  IGetInterviewAction,
  IGetAgencyBySubdomainAction,
  IStartInterviewAction,
  IInterviewCodeSharingAction,
  IEndInterviewAction,
  IReportInterviewAction,
  IAssignTimeSlotAction,
  ICreateIntentAction,
  IPaySucceedAction,
  IGetInterviewStatusAction,
  ISubmitAvailabilityAction,
  IDeclineAvailabilityAction,
} from './types'
import handleErrorSaga from '../utils/handleErrorSaga'
import {
  conversationTokenAction,
  generateConversationTokenActionFailure,
  generateConversationTokenActionSuccess,
  generateConversationTokenActionType,
  ILinkConversationActionPayload,
  linkConversationActionFailure,
  linkConversationActionSuccess,
  linkConversationActionType,
} from './redux'
import {
  assignTimeSlot,
  createIntent,
  createInterview,
  declineAvailability,
  endInterview,
  generateConversationToken,
  getAgencyBySubdomain,
  getInterview,
  interviewCodeSharing,
  InterviewStatus,
  linkConversation,
  paySucceed,
  reportInterview,
  startInterview,
  submitAvailability,
  updateInterview,
} from '../api'
import { ISagaAction } from '../utils/redux'
import { updateExpertNotesAction } from '../redux/data/expertNotes'

function* pollInterviewEnd(interviewToken) {
  while (true) {
    try {
      const interview = yield* call(getInterview, interviewToken)
      yield put(getInterviewSuccess({ interview }))

      if (interview.interview.status === InterviewStatus.ENDED) {
        yield put({ type: 'STOP_CALL_END_WATCHER_TASK' })
      } else {
        yield call(delay, 1000)
      }
    } catch (err) {
      const errorMessage = 'Server error. Please, contact support'
      yield put(
        getInterviewFailure({
          error: errorMessage,
        })
      )
      yield put({ type: 'STOP_CALL_END_WATCHER_TASK', err })
    }
  }
}

export function* pollEndTaskWatcher() {
  while (true) {
    const action = yield take('CALL_END_WATCHER_TASK')
    yield race([
      call(pollInterviewEnd, action.payload.interviewToken),
      take('STOP_CALL_END_WATCHER_TASK'),
    ])
  }
}

function* updateBookingSaga(action: IUpdateBookingAction) {
  try {
    yield* call(updateInterview, action.payload)
    yield put(updateBookingSuccess())
    yield put(getInterviewAction({ interviewToken: action.payload.interviewToken }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.status === 403) {
      errorMessage = 'Token is expired'
    }
    if (err.response && err.response.status === 404) {
      errorMessage = "We can't find your invitation"
    }
    yield put(
      updateBookingFailure({
        error: errorMessage,
      })
    )
  }
}

function* createBookingSaga(action: ICreateBookingAction) {
  try {
    const token = yield* call(createInterview, action.payload)
    yield put(createBookingSuccess())
    yield put(push(`/interviews/${token}`))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.status === 403) {
      errorMessage = 'Insufficient permissions'
    }
    if (err.response && err.response.status === 404) {
      errorMessage = "Couldn't find Agency"
    }
    yield put(
      createBookingFailure({
        error: errorMessage,
      })
    )
  }
}

function* getInterviewSaga(action: IGetInterviewAction) {
  const { interviewToken } = action.payload
  try {
    const interview = yield* call(getInterview, interviewToken)
    yield put(getInterviewSuccess({ interview }))
    if (interview?.interview.status === InterviewStatus.STARTED && interview.expert_notes)
      yield put(updateExpertNotesAction(interview.expert_notes))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.status === 404) {
      errorMessage = "We can't find your invitation"
    }
    yield put(
      getInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* getInterviewStatusSaga(action: IGetInterviewStatusAction) {
  const { interviewToken } = action.payload
  try {
    const interview = yield* call(getInterview, interviewToken)
    yield put(getInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.status === 404) {
      errorMessage = "We can't find your invitation"
    }
    yield put(
      getInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* getAgencyBySubdomainSaga(action: IGetAgencyBySubdomainAction) {
  const { subdomain } = action.payload
  try {
    const agency = yield* call(getAgencyBySubdomain, subdomain)
    yield put(getAgencyBySubdomainSuccess({ agency }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data.errors || err.response.data
    }
    yield put(
      getAgencyBySubdomainFailure({
        error: errorMessage,
      })
    )
  }
}

function* startInterviewSaga(action: IStartInterviewAction) {
  const { interviewToken } = action.payload
  try {
    const interview = yield* call(startInterview, interviewToken)
    yield put(startInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield put(
      startInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* interviewCodeSharingSaga(action: IInterviewCodeSharingAction) {
  const { interviewToken } = action.payload
  try {
    const interview = yield* call(interviewCodeSharing, interviewToken)
    yield put(getInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield put(
      startInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* endInterviewSaga(action: IEndInterviewAction) {
  const { interviewToken, quiet } = action.payload
  try {
    if (!quiet) {
      const interview = yield* call(endInterview, interviewToken)
      yield put(endInterviewSuccess({ interview }))
    }
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield put(
      endInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* reportInterviewSaga(action: IReportInterviewAction) {
  const { interviewToken, responseId } = action.payload
  try {
    const interview = yield* call(reportInterview, interviewToken, responseId)
    yield put(reportInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield put(
      reportInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* assignTimeSlotSaga(action: IAssignTimeSlotAction) {
  const { interviewToken, timezone, slotId } = action.payload
  try {
    yield* call(assignTimeSlot, interviewToken, timezone, slotId)
    const interview = yield* call(getInterview, interviewToken)
    yield put(getInterviewSuccess({ interview }))
    yield put(assignTimeSlotSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data.errors
    }
    yield call(handleErrorSaga, {
      message: 'Timeslot already booked. Refresh to see updated availability.',
    })
    yield put(
      assignTimeSlotFailure({
        error: errorMessage,
      })
    )
  }
}

function* createIntentSaga(action: ICreateIntentAction) {
  const { interviewToken } = action.payload
  try {
    const clientSecret = yield* call(createIntent, interviewToken)
    yield put(createIntentSuccess({ clientSecret }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data.errors
    }
    yield put(
      createIntentFailure({
        error: errorMessage,
      })
    )
  }
}

function* paySucceedSaga(action: IPaySucceedAction) {
  const { interviewToken, id, payment_method } = action.payload
  try {
    yield* call(paySucceed, interviewToken, id, payment_method)
    yield put(paySucceedSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data.errors
    }
    yield put(
      paySucceedFailure({
        error: errorMessage,
      })
    )
  }
}

function* submitAvailabilitySaga(action: ISubmitAvailabilityAction) {
  try {
    yield* call(
      submitAvailability,
      action.payload.expertToken,
      action.payload.timeSlots,
      action.payload.expertComment
    )
    yield put(submitAvailabilitySuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      submitAvailabilityFailure({
        error: errorMessage,
      })
    )
  }
}

function* declineAvailabilitySaga(action: IDeclineAvailabilityAction) {
  try {
    yield* call(
      declineAvailability,
      action.payload.expertToken,
      action.payload.expertComment,
      action.payload.declineReason
    )
    yield put(declineAvailabilitySuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      declineAvailabilityFailure({
        error: errorMessage,
      })
    )
  }
}

function* generateConversationTokenSaga() {
  try {
    const token = yield* call(generateConversationToken)
    yield put(generateConversationTokenActionSuccess())
    yield put(conversationTokenAction(token))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      generateConversationTokenActionFailure({
        error: errorMessage,
      })
    )
  }
}

function* linkConversationSaga(action: ISagaAction<ILinkConversationActionPayload>) {
  try {
    const { interviewToken, conversationId } = action.payload
    yield* call(linkConversation, interviewToken, conversationId)
    yield put(linkConversationActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      linkConversationActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetInterview() {
  yield takeEvery(getInterviewAction.type, getInterviewSaga)
}

export function* watchGetInterviewStatus() {
  yield takeEvery(getInterviewStatus.type, getInterviewStatusSaga)
}

export function* watchGetAgencyBySubdomain() {
  yield takeEvery(getAgencyBySubdomainAction.type, getAgencyBySubdomainSaga)
}

export function* watchUpdateBooking() {
  yield takeEvery(updateBookingAction.type, updateBookingSaga)
}

export function* watchCreateBooking() {
  yield takeEvery(createBookingAction.type, createBookingSaga)
}

export function* watchStartInterview() {
  yield takeEvery(startInterviewAction.type, startInterviewSaga)
}

export function* watchInterviewCodeSharing() {
  yield takeEvery(interviewCodeSharingAction.type, interviewCodeSharingSaga)
}

export function* watchEndInterview() {
  yield takeEvery(endInterviewAction.type, endInterviewSaga)
}

export function* watchReportInterview() {
  yield takeEvery(reportInterviewAction.type, reportInterviewSaga)
}

export function* watchAssignTimeSlot() {
  yield takeEvery(assignTimeSlotAction.type, assignTimeSlotSaga)
}

export function* watchCreateIntent() {
  yield takeEvery(createIntentAction.type, createIntentSaga)
}

export function* watchPaySucceed() {
  yield takeEvery(paySucceedAction.type, paySucceedSaga)
}

export function* watchSubmitAvailability() {
  yield takeEvery(submitAvailabilityAction.type, submitAvailabilitySaga)
}

export function* watchDeclineAvailability() {
  yield takeEvery(declineAvailabilityAction.type, declineAvailabilitySaga)
}

export function* watchGenerateConversationToken() {
  yield takeEvery(generateConversationTokenActionType, generateConversationTokenSaga)
}

export function* watchLinkConversation() {
  yield takeEvery(linkConversationActionType, linkConversationSaga)
}
