import React from 'react'
import styled from '@emotion/styled'
import withProps from 'recompose/withProps'

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const ChBox = withProps()(styled.input<{ error: boolean }>`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 11px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    height: 16px;
    width: 16px;
    border: 1px solid ${({ error }) => (error ? '#FF1F44' : 'rgba(143, 155, 179, 0.49)')};
    border-radius: 3px;
    background-color: #ffffff;
    flex-shrink: 0;
  }

  &:checked + label:before {
    background: ${({ theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return color
    }};
    flex-shrink: 0;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 10px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -6px 0 white;
    transform: rotate(45deg);
  }
`)

const Label = styled('label')`
  font-size: 14px;
  margin: 0;
`

interface IProps {
  value: boolean
  disabled?: boolean
  children?: any
  error?: boolean
  onChange?: (v: boolean) => void
}

const CheckBox = (props: IProps) => {
  const { value, disabled, onChange, children, error } = props

  function onChanged() {
    onChange(!value)
  }

  return (
    <Container disabled={disabled}>
      <ChBox
        type="checkbox"
        name="chBoxLabel"
        checked={value}
        onChange={onChanged}
        error={error}
      />
      <Label htmlFor="chBoxLabel" onClick={onChanged} />
      {children}
    </Container>
  )
}

CheckBox.defaultProps = {
  disabled: false,
  onChange: () => undefined,
  children: null,
  error: false,
}

export default CheckBox
