import styled from '@emotion/styled'

export const DivBase = styled.div<{
  top?: number
  right?: number
  bottom?: number
  left?: number
}>`
  margin: ${(props) =>
    `${props.top ?? 0}px ${props.right ?? 0}px ${props.bottom ?? 0}px ${
      props.left ?? 0
    }px`};
`

export const AnimatedBase = styled(DivBase)`
  animation: fadeInFromNone 0.5s ease-out;

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const ButtonBase = styled(AnimatedBase)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`

export const ScrollableBase = styled.div`
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`
