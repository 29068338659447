import React from 'react'

function SvgVolume() {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9394 6.375C13.5252 6.375 14 6.8507 14 7.4375C14 10.9491 11.4234 13.8579 8.06085 14.37L8.06061 15.9375C8.06061 16.5243 7.58576 17 7 17C6.45085 17 5.99918 16.5819 5.94487 16.0461L5.93939 15.9375L5.94001 14.3701C2.57701 13.8584 0 10.9494 0 7.4375C0 6.8507 0.474849 6.375 1.06061 6.375C1.64636 6.375 2.12121 6.8507 2.12121 7.4375C2.12121 10.1368 4.30552 12.325 7 12.325C9.69448 12.325 11.8788 10.1368 11.8788 7.4375C11.8788 6.8507 12.3536 6.375 12.9394 6.375ZM7 0C8.87442 0 10.3939 1.52223 10.3939 3.4V7.225C10.3939 9.10277 8.87442 10.625 7 10.625C5.12558 10.625 3.60606 9.10277 3.60606 7.225V3.4C3.60606 1.52223 5.12558 0 7 0Z"
        fill="#737991"
      />
    </svg>
  )
}

export default SvgVolume
