import React from 'react'
import { TextBold } from '../../components'
import Layout from '../Layout'

function Login() {
  return (
    <Layout
      sidebarText="Find the perfect match from thousands of experts to prepare your applicants for their job interviews"
      title={`Incoming!
      We just sent you an email`}
    >
      <>
        <TextBold>
          Please follow the instructions in the email to access your account.
        </TextBold>
        <TextBold>
          If you do not get an email within 5 minutes, check the email address you entered
          and try again.
        </TextBold>
      </>
    </Layout>
  )
}

export default Login
