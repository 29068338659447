import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { systemSettingsSelectors } from 'src/redux/data/systemSettings'
import { useForm, Controller } from 'react-hook-form'
import { isSuperAdminRole } from 'src/utils/jwtToken'
import { updateSystemSettingsAction } from 'src/redux/requests/updateSystemSettings'
import { get } from 'lodash'
import AgencyPositions from 'src/agency/forms/agency/AgencyPositions'
import {
  Title,
  Input,
  Button,
  Textarea,
  TimeInput,
  Checkbox,
  Select,
} from '../../components'

const STTProviders = [
  { value: 'symbl', label: 'Symbl.AI' },
  { value: 'revai', label: 'Rev.AI' },
]

const Container = styled.div`
  box-sizing: border-box;
`

const Header = styled.header`
  margin-bottom: 25px;
`

const Form = styled.form`
  margin-top: 15px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`

const ButtonStyled = styled(Button)`
  && {
    margin-top: 40px;
  }
`

const LineContainer = styled.div<{ count: number }>`
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  > div {
    width: calc(${(props) => 100 - props.count}% / ${(props) => props.count});
  }
`
const Subtitle = styled.h3`
  margin-bottom: 24px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 0;
`
const SectionName = styled.div`
  margin-top: 24px;
  margin-bottom: 12px;
  color: black;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
`

function SystemSettings(this: any) {
  const settings = useSelector(systemSettingsSelectors.data)
  const blurBackgroundEnabled: boolean = get(settings, 'blurBackgroundEnabled', false)
  const dispatch = useDispatch()

  const isSuperAdmin = isSuperAdminRole()

  const timeToMs = (time: string) => {
    const timeParts = time.split(':')
    let result = Number.parseInt(timeParts[0], 10) * 60 * 1000
    if (timeParts.length > 1) {
      result += Number.parseInt(timeParts[1], 10) * 1000
    }
    return result
  }

  const msToTime = (ms: number) => {
    if (ms === 0) return '00:00'
    if (!ms) return ''
    const minutes = Math.floor(ms / 1000 / 60)
    const seconds = ms - minutes * 60 * 1000
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const {
    handleSubmit,
    errors,
    control,
    formState: { isDirty },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      settings,
      convert: {
        analyticsDurationAfterStart: msToTime(settings?.analyticsDurationAfterStart),
        analyticsDurationBeforeEnd: msToTime(settings?.analyticsDurationBeforeEnd),
      },
    },
  })

  useEffect(() => {
    if (settings)
      reset({
        settings,
        convert: {
          analyticsDurationAfterStart: msToTime(settings?.analyticsDurationAfterStart),
          analyticsDurationBeforeEnd: msToTime(settings?.analyticsDurationBeforeEnd),
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  const onSubmit = (payload: {
    settings: {
      agencyPositions: any
      speechSpeedMin: any
      speechSpeedMax: any
      singularPronounsMin: any
      singularPronounsMax: any
      dataUsageScoreMin: any
      dataUsageScoreMax: any
      confidentScoreMin: any
      confidentScoreMax: any
      pastTenseRangeMin: any
      pastTenseRangeMax: any
      sentimentPositiveMin: any
      sentimentNegativeMax: any
      singularPronounsAWords: any
      singularPronounsBWords: any
      dataUsageWords: any
      confidentPhrases: any
      tensePastVerbs: any
      tensePresentVerbs: any
      tenseFutureVerbs: any
      minWordsCount: any
      blurBackgroundEnabled: any
      sttProvider: any
    }
    convert: {
      analyticsDurationAfterStart: any
      analyticsDurationBeforeEnd: any
    }
  }) => {
    const confirmed = window.confirm(`Are you sure you want to save changes?`)
    if (confirmed)
      dispatch(
        updateSystemSettingsAction({
          settings: {
            speechSpeedMin: payload.settings.speechSpeedMin,
            speechSpeedMax: payload.settings.speechSpeedMax,
            singularPronounsMin: payload.settings.singularPronounsMin,
            singularPronounsMax: payload.settings.singularPronounsMax,
            dataUsageScoreMin: payload.settings.dataUsageScoreMin,
            dataUsageScoreMax: payload.settings.dataUsageScoreMax,
            confidentScoreMin: payload.settings.confidentScoreMin,
            confidentScoreMax: payload.settings.confidentScoreMax,
            pastTenseRangeMin: payload.settings.pastTenseRangeMin,
            pastTenseRangeMax: payload.settings.pastTenseRangeMax,
            sentimentPositiveMin: payload.settings.sentimentPositiveMin,
            sentimentNegativeMax: payload.settings.sentimentNegativeMax,
            singularPronounsAWords: payload.settings.singularPronounsAWords,
            singularPronounsBWords: payload.settings.singularPronounsBWords,
            dataUsageWords: payload.settings.dataUsageWords,
            confidentPhrases: payload.settings.confidentPhrases,
            tensePastVerbs: payload.settings.tensePastVerbs,
            tensePresentVerbs: payload.settings.tensePresentVerbs,
            tenseFutureVerbs: payload.settings.tenseFutureVerbs,
            analyticsDurationAfterStart: timeToMs(
              payload.convert.analyticsDurationAfterStart
            ),
            analyticsDurationBeforeEnd: timeToMs(
              payload.convert.analyticsDurationBeforeEnd
            ),
            minWordsCount: payload.settings.minWordsCount,
            blurBackgroundEnabled: payload.settings.blurBackgroundEnabled,
            agencyPositions: payload.settings.agencyPositions,
            sttProvider: payload.settings.sttProvider,
          },
        })
      )
  }

  const hasFormErrors = Object.keys(errors).length > 0

  return (
    <Container>
      {!isSuperAdmin && (
        <Header>
          <Title>Permission denied</Title>
        </Header>
      )}
      {isSuperAdmin && (
        <>
          <Header>
            <Title>System settings</Title>
          </Header>
          <Subtitle>Interview intelligence</Subtitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {settings && (
              <>
                <SectionName>Cockpit</SectionName>
                <Row>
                  <Controller
                    as={Checkbox}
                    label="Blur background"
                    name="settings.blurBackgroundEnabled"
                    control={control}
                    useTheme
                    defaultChecked={blurBackgroundEnabled}
                  />
                </Row>
              </>
            )}
            <SectionName>Display rule</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Minimum number of applicant's words required"
                name="settings.minWordsCount"
                control={control}
                error={errors.settings?.minWordsCount}
                rules={{ required: true, min: 0 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <SectionName>Analysis duration</SectionName>
            <Row>
              <Controller
                name="convert.analyticsDurationAfterStart"
                label="Start at (mm:ss after the session starts)"
                control={control}
                error={errors.convert?.analyticsDurationAfterStart}
                rules={{
                  required: true,
                  validate: (value) => {
                    return value.length === 5
                  },
                }}
                as={TimeInput}
              />
            </Row>
            <Row>
              <Controller
                name="convert.analyticsDurationBeforeEnd"
                label="End at (mm:ss before the session ends)"
                control={control}
                error={errors.convert?.analyticsDurationBeforeEnd}
                rules={{
                  required: true,
                  validate: (value) => {
                    return value.length === 5
                  },
                }}
                as={TimeInput}
              />
            </Row>
            <SectionName>Speech tempo</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Recommended range min (wpm) "
                name="settings.speechSpeedMin"
                control={control}
                error={errors.settings?.speechSpeedMin}
                rules={{ required: true, min: 1, max: 220 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Input}
                label="Recommended range max (wpm)"
                name="settings.speechSpeedMax"
                control={control}
                error={errors.settings?.speechSpeedMax}
                rules={{ required: true, min: 1, max: 220 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <SectionName>Workplace culture</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Recommended range min (Singular pronoun %)"
                name="settings.singularPronounsMin"
                control={control}
                error={errors.settings?.singularPronounsMin}
                rules={{ required: true, min: 1, max: 100 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Input}
                label="Recommended range max (Singular pronoun %)"
                name="settings.singularPronounsMax"
                control={control}
                error={errors.settings?.singularPronounsMax}
                rules={{ required: true, min: 1, max: 100 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Textarea}
                label="Singular pronoun vocabulary"
                name="settings.singularPronounsAWords"
                control={control}
                error={errors.settings?.singularPronounsAWords}
                rules={{ required: true }}
                defaultValue=""
              />
              <Controller
                as={Textarea}
                label="Plural pronoun vocabulary"
                name="settings.singularPronounsBWords"
                control={control}
                error={errors.settings?.singularPronounsBWords}
                rules={{ required: true }}
                defaultValue=""
              />
            </Row>
            <SectionName>Data usage</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Recommended range min (data usage per 1,000 words)"
                name="settings.dataUsageScoreMin"
                control={control}
                error={errors.settings?.dataUsageScoreMin}
                rules={{ required: true, min: 0 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Input}
                label="Recommended range max (data usage per 1,000 words)"
                name="settings.dataUsageScoreMax"
                control={control}
                error={errors.settings?.dataUsageScoreMax}
                rules={{ required: true, min: 0, max: 150 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Textarea}
                label="Data usage words"
                name="settings.dataUsageWords"
                control={control}
                error={errors.settings?.dataUsageWords}
                rules={{ required: true }}
                defaultValue=""
              />
            </Row>
            <SectionName>Confident</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Semi-confident min (warning phrase per 1000 words) "
                name="settings.confidentScoreMin"
                control={control}
                error={errors.settings?.confidentScoreMin}
                rules={{ required: true, min: 0 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Input}
                label="Semi-confident max (warning phrase per 1000 words)"
                name="settings.confidentScoreMax"
                control={control}
                error={errors.settings?.confidentScoreMax}
                rules={{ required: true, min: 0, max: 100 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Textarea}
                label="Warning phrases"
                name="settings.confidentPhrases"
                control={control}
                error={errors.settings?.confidentPhrases}
                rules={{ required: true }}
                defaultValue=""
              />
            </Row>
            <SectionName>Practical experience</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Limited experience min (past tense %)"
                name="settings.pastTenseRangeMin"
                control={control}
                error={errors.settings?.pastTenseRangeMin}
                rules={{ required: true, min: 0, max: 100 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Input}
                label="Limited experience max (past tense %)"
                name="settings.pastTenseRangeMax"
                control={control}
                error={errors.settings?.pastTenseRangeMax}
                rules={{ required: true, min: 0, max: 100 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <LineContainer count={3}>
                <Controller
                  as={Textarea}
                  label="Group A - Past tense verbs"
                  name="settings.tensePastVerbs"
                  control={control}
                  error={errors.settings?.tensePastVerbs}
                  rules={{ required: true }}
                  defaultValue=""
                />
                <Controller
                  as={Textarea}
                  label="Group B - Present tense verbs"
                  name="settings.tensePresentVerbs"
                  control={control}
                  error={errors.settings?.tensePresentVerbs}
                  rules={{ required: true }}
                  defaultValue=""
                />
                <Controller
                  as={Textarea}
                  label="Group C - Future tense verbs"
                  name="settings.tenseFutureVerbs"
                  control={control}
                  error={errors.settings?.tenseFutureVerbs}
                  rules={{ required: true }}
                  defaultValue=""
                />
              </LineContainer>
            </Row>
            <SectionName>Attitude</SectionName>
            <Row>
              <Controller
                as={Input}
                label="Neutral min (-1.0 to 1.0)"
                name="settings.sentimentPositiveMin"
                control={control}
                error={errors.settings?.sentimentPositiveMin}
                rules={{ required: true, min: -1, max: 1 }}
                type="number"
                defaultValue=""
              />
            </Row>
            <Row>
              <Controller
                as={Input}
                label="Neutral max (-1.0 to 1.0)"
                name="settings.sentimentNegativeMax"
                control={control}
                error={errors.settings?.sentimentNegativeMax}
                rules={{ required: true, min: -1, max: 1 }}
                type="number"
                defaultValue=""
              />
            </Row>

            <SectionName>Speech-to-text Provider</SectionName>
            <Row>
              <Controller
                name="settings.sttProvider"
                control={control}
                defaultValue=""
                render={({ onChange, value, name: selectName }) => (
                  <Select
                    name={selectName}
                    label="Provider"
                    onChange={onChange}
                    value={value}
                    options={STTProviders}
                    useTheme
                    error={errors.settings?.sttProvider}
                  />
                )}
              />
            </Row>

            <Controller
              name="settings.agencyPositions"
              control={control}
              render={({ onChange, value }) => (
                <AgencyPositions positions={value} onChange={onChange} />
              )}
            />

            <ButtonStyled disabled={!isDirty || hasFormErrors} title="Save" />
          </Form>
        </>
      )}
    </Container>
  )
}

export default SystemSettings
