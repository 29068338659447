import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'

import chevron from 'src/static/chevron-down.svg'

import { AgencyCoachingTypes } from '../../../api'
import { Autocomplete, Input, Text } from '../../../components'

const Container = styled.div`
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label<{ collapsed: boolean }>`
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: all 200ms;
  }
`

const List = styled.div<{ collapsed: boolean }>`
  border-top: 1px solid #e1e3ea;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  margin-top: 10px;
  padding-top: 10px;
`

const Item = styled.div`
  background-color: #e1e3ea20;
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 10px;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 24px;

  > div {
    height: 40px;
    margin: 0 5px;

    > input {
      padding-top: 10px;
    }

    > p {
      margin: 0;
      padding-top: 5px;
    }
  }
`

const AddButton = styled(Text)`
  cursor: pointer;
  margin: 0;
  margin-top: 10px;
  text-decoration: underline;
  width: fit-content;
`

const DeleteButton = styled(Text)`
  color: red;
  cursor: pointer;
  margin: 0 10px;
  width: fit-content;
`

interface IProps {
  control: any
  errors: any
}

function InternalExperts({ control, errors }: IProps) {
  const [collapsed, setCollapsed] = useState(true)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'agency.internal_experts',
  })

  return (
    <Container>
      <Title collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        Internal Experts
      </Title>
      <List collapsed={collapsed}>
        {fields.map((item, index) => (
          <Item key={item.id}>
            <Controller
              as={Input}
              label="IExpert ID"
              name={`agency.internal_experts[${index}].id`}
              control={control}
              error={errors.agency?.internal_experts?.[index]?.id}
              defaultValue={item.id}
              hidden
            />

            <Row>
              <Controller
                as={Input}
                label="IExpert name"
                name={`agency.internal_experts[${index}].name`}
                control={control}
                error={errors.agency?.internal_experts?.[index]?.name}
                rules={{ required: true }}
                defaultValue={item.name}
              />
              <DeleteButton onClick={() => remove(index)}>Delete</DeleteButton>
            </Row>

            <Controller
              name={`agency.internal_experts[${index}].coaching_types`}
              error={errors.agency?.internal_experts?.[index]?.coaching_types}
              control={control}
              render={({ onChange, value }) => (
                <Autocomplete
                  multiple
                  label="Coaching types"
                  options={AgencyCoachingTypes}
                  onChange={(e) => onChange(e.map((t) => t.value))}
                  value={AgencyCoachingTypes.filter((t) => value.includes(t.value))}
                />
              )}
              rules={{
                validate: {
                  valid: (value) =>
                    value?.length > 0 || 'Please select at least one type',
                },
              }}
              defaultValue={item.coaching_types}
            />
          </Item>
        ))}
        <AddButton onClick={() => append({ name: '', coaching_types: [] })}>
          + Add
        </AddButton>
      </List>
    </Container>
  )
}

export default InternalExperts
