import React from 'react'
import styled from '@emotion/styled'

import SvgBag from 'src/static/components/SvgBag'

import { Text as MobileText } from '../MobileText'
import SessionDetails from './SessionDetails'

const MobileContainer = styled.div`
  align-items: center;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  padding: 24px;

  @media (orientation: landscape) {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`

const MobileHeader = styled.div`
  align-items: center;
  border-bottom: 0.5px solid #324fa5;
  display: flex;
  padding: 0px 8px 14px 8px;
  width: 100%;

  @media (orientation: landscape) {
    background: #1d0e3b;
    border: none;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 57px;
    padding: 29px 24px 4px 24px;
  }
`

const MobileHeaderTitle = styled(MobileText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-left: 8px;
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (orientation: landscape) {
    height: calc(100% - 57px);
    overflow: scroll;
    padding: 0 44px;
  }
`

interface IDetailsSidebar {
  theme: any
}

export default function SessionDetailsSidebarMobile({ theme }: IDetailsSidebar) {
  const title = 'Session details'

  return (
    <MobileContainer>
      <MobileHeader>
        <SvgBag fill={theme.textColor} />
        <MobileHeaderTitle>{title}</MobileHeaderTitle>
      </MobileHeader>
      <MobileContent>
        <SessionDetails />
      </MobileContent>
    </MobileContainer>
  )
}
