import { put, takeEvery, call } from 'typed-redux-saga'

import { getSystemSettings } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { reduxRequestFactory } from '../../utils/redux'
import { updateSystemSettingsDataAction } from '../data/systemSettings'

const {
  action: getSystemSettingsAction,
  actionFailure: getSystemSettingsActionFailure,
  actionSuccess: getSystemSettingsActionSuccess,
  actionSuccessClear: getSystemSettingsActionSuccessClear,
  actionFailureClear: getSystemSettingsActionFailureClear,
  actionType: getSystemSettingsActionType,
  actionTypeFailure: getSystemSettingsActionFailureType,
  actionTypeSuccess: getSystemSettingsActionSuccessType,
  reducer: getSystemSettingsRequestReducer,
  selectors: getSystemSettingsSelectors,
} = reduxRequestFactory<void>('getSystemSettings', 'requests')

export {
  getSystemSettingsAction,
  getSystemSettingsActionFailure,
  getSystemSettingsActionSuccess,
  getSystemSettingsActionSuccessClear,
  getSystemSettingsActionFailureClear,
  getSystemSettingsActionType,
  getSystemSettingsActionFailureType,
  getSystemSettingsActionSuccessType,
  getSystemSettingsRequestReducer,
  getSystemSettingsSelectors,
}

function* getSystemSettingsSaga() {
  try {
    const data = yield* call(getSystemSettings)
    yield put(getSystemSettingsActionSuccess())
    yield put(updateSystemSettingsDataAction(data))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getSystemSettingsActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetSystemSettings() {
  yield takeEvery(getSystemSettingsActionType, getSystemSettingsSaga)
}
