/* eslint-disable no-case-declarations */
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import _ from 'lodash'
import { format } from 'date-fns'
import FilterIcon from 'src/static/filter_icon_small.png'
import { isSuperAdminRole } from 'src/utils/jwtToken'

import { normalizeDate } from 'src/utils/date'

import { Text, Avatar, Loader } from 'src/components'
import { ReactComponent as ReportIcon } from 'src/static/report.svg'
import copy from 'copy-to-clipboard'
import getStatusColor from 'src/utils/statusColor'
import Bag from '../../static/bag.svg'
import Layout from '../Layout'
import SortBy from './Sort'

import { interviews as interviewsSelector, stats as statsSelector } from '../selectors'
import { getInterviews, getStats } from '../actions'
import PaginationFooter from './PaginationFooter'
import SearchInput from './SearchInput'
import SearchBy from './SearchBy'
import {
  AdminInterviewStatus,
  AgencyInterviewStatus,
  IAdminInterview,
  IAdminInterviewExpert,
  InterviewPriorities,
  InterviewSearch,
  InterviewSort,
  InterviewStatus,
  OpsManagers,
} from '../../api'
import { agencySelectors, assignOpsAction, downloadAgencyReportAction } from '../../redux'
import HiredChanceComponent from './HiredChanceComponent'
import FilterOpsComponent from './FilterOpsComponent'
import FilterComponent from './FilterComponent'

const getShortFormat = (date) => {
  return format(normalizeDate(date), 'MM/dd')
}

interface IEditButtonProps {
  title: string
  onClick?: any
  children: any
  disabled?: boolean
}

interface ITabProps {
  color: string
  isSelected: boolean
  onClick: (any) => void
}

interface IStatusProps {
  color: string
}

const bang = keyframes`    
  from {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
`
const createCSS = () => {
  const styles = {}

  for (let $i = 0; $i < 50; $i += 1) {
    styles[`i:nth-of-type(${$i})`] = {
      transform: `translate3d(${_.random(1, 190) - 50}px,${
        _.random(1, 50) - 50
      }px,0) rotate(${_.random(1, 360)}deg)`,
      background: `hsla(${_.random(1, 360)},100%,50%,1)`,
      animation: `${bang} 2000ms ease-out forwards`,
      opacity: 0,
    }
  }

  return styles
}

const TableWrapper = styled.div`
  tr > td {
    word-break: break-all;
  }

  .table {
    border-collapse: separate;
    border-spacing: 0px 10px;
    border: none;

    &-header {
      display: none;
    }

    &-row {
      border-radius: 10px;

      td {
        background-color: rgba(245, 246, 248, 0.5);
        border: none !important;
        padding: 25px 5px !important;
        text-overflow: ellipsis;
        //overflow: hidden;

        &:first-of-type {
          border-radius: 10px 0 0 10px;
        }

        &:last-of-type {
          border-radius: 0 10px 10px 0;
          padding: 20px 40px 20px 0 !important;
        }
      }

      &.hired {
        background: rgba(0, 222, 159, 0.04);
        td {
          border-top: 1px solid rgba(0, 222, 159, 0.44) !important;
          border-bottom: 1px solid rgba(0, 222, 159, 0.44) !important;

          &:first-of-type {
            border-left: 1px solid rgba(0, 222, 159, 0.44) !important;
            border-top: 1px solid rgba(0, 222, 159, 0.44) !important;
            border-bottom: 1px solid rgba(0, 222, 159, 0.44) !important;
          }

          &:last-of-type {
            border-radius: 0 10px 10px 0;
            padding: 20px 40px 20px 0 !important;
            border-right: 1px solid rgba(0, 222, 159, 0.44) !important;
            border-top: 1px solid rgba(0, 222, 159, 0.44) !important;
            border-bottom: 1px solid rgba(0, 222, 159, 0.44) !important;
          }
        }

        &:hover {
          ${createCSS}
        }
      }
    }
  }
`

const ButtonEditStyled = styled.div<IEditButtonProps>`
  cursor: pointer;
  margin-left: 78px;
  margin-top: 10px;
  font-size: 12px;
  width: 70px;
  svg {
    margin-right: 6px;
    width: 18px;
  }
`

const ButtonReportStyled = styled.div<IEditButtonProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  width: 70px;

  svg,
  span {
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  }

  svg {
    height: 20px;
    margin-right: 5px;

    g {
      fill: #3b424f;
    }
  }

  span {
    font-weight: normal;
  }

  > div {
    display: ${(props) => (props.disabled ? 'block' : 'none')};
    visibility: hidden;
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    width: 290px;
    padding: 16px;
    border-radius: 10px;
    transition: all 100ms;
    z-index: 3;
    color: #fff;
    background: #353c5a;
    word-break: break-word;
    text-align: left;

    &:before {
      content: '';
      bottom: -5px;
      right: 25px;
      position: absolute;
      width: 10px;
      height: 10px;
      background: #353c5a;
      transform: rotate(45deg);
    }
  }

  &:hover {
    > div {
      visibility: visible;
    }
  }
`
const PaginationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f6f8;
  padding-top: 10px;
  font-size: 12px;
`

const CandidateWrapper = styled.div`
  display: flex;
  padding: 0 15px;
  position: relative;

  i {
    position: absolute;
    display: block;
    left: 20px;
    top: 10px;
    width: 3px;
    height: 5px;
    background: red;
    opacity: 0;
  }
`
const CandidateInfoWrapper = styled.div`
  width: 200px;
`
const CandidateName = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
`
const HiredBag = styled(Text)`
  position: absolute;
  left: 7px;
  top: 25px;
  border-radius: 50px;
  padding: 3px 0 0 5px;
  height: 30px;
  width: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.11);
`

const TextLabel = styled(Text)`
  font-size: 12px;
  color: rgba(115, 121, 145, 0.8);
  letter-spacing: 0;
  line-height: 24px;
  word-break: initial;
`

const InterviewWrapper = styled.div``
const StyledText = styled(Text)`
  font-size: 12px;
  word-break: initial;

  span {
    font-weight: bold;
  }
`

const InterviewStatusWrapper = styled(InterviewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: end;
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  float: right;
`

const TimeWrapper = styled.div<{ isOld: boolean }>`
  display: flex;
  margin-top: 4px;
  color: ${({ isOld }) => (isOld ? 'red' : 'black')};
`

const LinksWrapper = styled.div`
  display: block;
`

const Tabs = styled.div`
  margin-bottom: 30px;
  text-align: right;
`

const Tab = styled.div<ITabProps>`
  padding: 10px 15px;
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? props.color : '#8F9BB3')};

  &:after {
    position: absolute;
    content: '';
    bottom: -6px;
    left: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${(props) => props.color};
    display: ${(props) => (props.isSelected ? 'block' : 'none')};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const StatusElement = styled.div<IStatusProps>`
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
  text-transform: uppercase;
  width: fit-content;
  word-break: break-word;
  max-width: 110px;
  text-align: center;
  background: ${({ color }) => color};
`

const SearchContainer = styled.div`
  align-items: center;
  display: flex;
`

const FilterWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: 18px;
`

const LinkButton = styled.div<{ fill?: boolean }>`
  cursor: pointer;
  font-size: 12px;
  color: #b202ff;
  text-decoration: underline;
  margin: 0 10px;
`

const FilterIconStyled = styled.img`
  height: 13px;
  margin: 3px 8px 0 8px;
`

const SupplyBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 10px;
  height: 100%;
  padding: 0px !important;
`

const PriorityContainer = styled.div<{ isHighPriority?: boolean }>`
  background-color: ${({ isHighPriority }) =>
    isHighPriority ? 'rgba(255, 0, 0, 0.3)' : 'rgba(245, 246, 248, 0.5)'};
  border-radius: 10px;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
`

const HighPriorityTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
`

const HighPriorityReason = styled.div`
  font-size: 14px;
`

const SelectStyled = styled.select`
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  font-size: 14px;
`

const OptionStyled = styled.option``

const OpsManagerContainer = styled(PriorityContainer)`
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DefaultOpsManager = { name: 'Unassigned', email: 'none' }

function Dashboard() {
  const dispatch = useDispatch()
  const agency = useSelector(agencySelectors.data)
  const interviews = useSelector(interviewsSelector.interviews)
  const pagesTotal = useSelector(interviewsSelector.pages)
  const stats = useSelector(statsSelector.stats)
  const isLoading = useSelector(interviewsSelector.isLoading)

  const { main_color: mainColor = '#B202FF', full_domain: fullDomain } = agency

  const isSuperAdmin = isSuperAdminRole()

  const [selectedTab, setSelectedTab] = useState<
    AdminInterviewStatus | AgencyInterviewStatus
  >(AdminInterviewStatus.ALL)
  const [selectedPage, setSelectedPage] = useState(1)
  const [sortKey, setSortKey] = useState<InterviewSort>('updated_at')
  const [searchKey, setSearchKey] = useState<InterviewSearch>('candidate_name')
  const [searchText, setSearchText] = useState('')

  const [opsManagers, setOpsManagers] = useState([])

  const [isPriorityFilterVisible, setIsProirityFilterVisible] = useState(false)
  const [isOpsFilterVisible, setIsOpsFilterVisible] = useState(false)

  const [excludedPriorities, setExcludedPriorities] = useState<string[]>([])
  const [excludedOps, setExcludedOps] = useState<string[]>([])

  const handleChangeSort = useCallback((newSortKey: InterviewSort) => {
    setSelectedPage(1)
    setSortKey(newSortKey)
  }, [])

  const handleChangePriorityFilter = useCallback((newExcludedPriorities: string[]) => {
    setSelectedPage(1)
    setExcludedPriorities(newExcludedPriorities)
  }, [])

  const handleChangeOpsFilter = useCallback((newExcludedOps: string[]) => {
    setSelectedPage(1)
    setExcludedOps(newExcludedOps)
  }, [])

  const handleChangeSearchKey = useCallback((newSearchKey: InterviewSearch) => {
    setSelectedPage(1)
    setSearchKey(newSearchKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeSearch = useCallback((newSearch: string) => {
    setSelectedPage(1)
    setSearchText(newSearch)
  }, [])

  const handleSelectTab = useCallback(
    (newTab: AdminInterviewStatus | AgencyInterviewStatus) => () => {
      setSelectedPage(1)
      setSelectedTab(newTab)
    },
    []
  )

  const handleSelectPage = useCallback((newPage: number) => {
    setSelectedPage(newPage)
  }, [])

  const handleDownloadReport = () => {
    dispatch(downloadAgencyReportAction({ id: agency.id }))
  }

  const handleOpsManagerSelected = (
    id: string,
    agencyId: string,
    opsManagerEmail: string
  ) => {
    dispatch(assignOpsAction({ id, agencyId, opsManagerEmail }))
  }

  useEffect(() => {
    if (agency?.id)
      dispatch(
        getStats({
          params: {
            agency_id: agency.id,
            exclude_ops: excludedOps ?? undefined,
            exclude_priorities: excludedPriorities ?? undefined,
            search_field: searchKey ?? undefined,
            search: searchText ?? undefined,
          },
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, excludedOps, excludedPriorities, searchKey, searchText])

  useEffect(() => {
    if (agency?.id)
      dispatch(
        getInterviews({
          params: {
            agency_id: agency.id,
            exclude_ops: excludedOps ?? undefined,
            exclude_priorities: excludedPriorities ?? undefined,
            page: selectedPage,
            search_field: searchKey ?? undefined,
            search: searchText ?? undefined,
            sort: sortKey,
            status: selectedTab !== 'All' ? selectedTab : undefined,
          },
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agency,
    excludedOps,
    excludedPriorities,
    selectedPage,
    selectedTab,
    searchKey,
    searchText,
    sortKey,
  ])

  useEffect(() => {
    setOpsManagers([DefaultOpsManager, ...OpsManagers])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [alertText, setAlertText] = useState('')

  const columns = [
    {
      dataField: 'supply_block',
      text: '',
      style: { width: '180px', height: '1px' },
      hidden: !isSuperAdmin,
      formatter: (cell, row: IAdminInterview) => {
        const isHighPriority = row.priority === 'high'
        return (
          <SupplyBlockContainer>
            <PriorityContainer isHighPriority={isHighPriority}>
              {isHighPriority && (
                <>
                  <HighPriorityTitle>High priority</HighPriorityTitle>
                  <HighPriorityReason>{row.priority_reason}</HighPriorityReason>
                </>
              )}
            </PriorityContainer>
            <OpsManagerContainer>
              <SelectStyled
                onChange={(event) =>
                  handleOpsManagerSelected(row.id, row.agency.id, event.target.value)
                }
              >
                {opsManagers.map((dev) => (
                  <OptionStyled
                    value={dev.email}
                    selected={dev.email === row.ops_manager_email}
                  >
                    {dev.name}
                  </OptionStyled>
                ))}
              </SelectStyled>
            </OpsManagerContainer>
          </SupplyBlockContainer>
        )
      },
    },
    {
      dataField: 'candidate_profile',
      text: '',
      formatter: (cell, row: IAdminInterview) => {
        const {
          candidate_profile: candidate,
          is_hired: isHired,
          order_number: orderNumber,
        } = row
        const { email, first_name: firstName, last_name: lastName } = candidate || {}

        return (
          <CandidateWrapper>
            {isHired && Array(50).fill(<i />)}
            <Avatar user={candidate} size={40} />
            <CandidateInfoWrapper>
              <CandidateName>{`${firstName} ${lastName}`}</CandidateName>
              {isHired && (
                <HiredBag>
                  <img src={Bag} alt="bag" />
                </HiredBag>
              )}
              <TextLabel>{email}</TextLabel>
              <TextLabel>{`ID #${orderNumber}`}</TextLabel>
            </CandidateInfoWrapper>
          </CandidateWrapper>
        )
      },
    },
    {
      dataField: 'order_number',
      hidden: true,
    },
    {
      dataField: 'desired_position',
      text: '',
      formatter: (cell, row: IAdminInterview) => {
        const {
          desired_position: position,
          expert_profile: expert,
          position_company: company,
        } = row
        const {
          company: expertCompany,
          current_position: expertPosition,
          first_name: expertFirstName,
          last_name: expertLastName,
        } = expert || ({} as IAdminInterviewExpert)

        return (
          <InterviewWrapper>
            <>
              {position && (
                <StyledText>
                  Coaching for <span>{position}</span>
                  {company ? ` at ${company}` : null}
                </StyledText>
              )}

              {expert && (
                <>
                  <TextLabel>{`${
                    expertFirstName || expertLastName ? 'by ' : ''
                  }${expertFirstName} ${expertLastName}  (${expertPosition}${
                    expertPosition && expertCompany ? ' at ' : ''
                  }${expertCompany})`}</TextLabel>
                </>
              )}
            </>
          </InterviewWrapper>
        )
      },
    },
    {
      dataField: 'scheduled_time',
      text: '',
      style: { width: '180px' },
      formatter: (cell, row: IAdminInterview) => {
        const {
          scheduled_time: scheduledTime,
          interview_date: interviewDate,
          interview_date_option: interviewDateOption,
        } = row

        // eslint-disable-next-line no-nested-ternary
        const interviewDateReadable = interviewDate
          ? getShortFormat(interviewDate)
          : ['Confirmed Date', "I don't know"].includes(interviewDateOption)
          ? interviewDateOption
          : 'N/A'
        const scheduledTimeReadable = scheduledTime
          ? getShortFormat(scheduledTime)
          : 'Pending'

        return (
          <InterviewWrapper>
            <StyledText>
              <>
                Coaching date: <span>{scheduledTimeReadable}</span>
              </>
            </StyledText>
            <TextLabel>
              <>
                Interview date: <span>{interviewDateReadable}</span>
              </>
            </TextLabel>
          </InterviewWrapper>
        )
      },
    },
    {
      dataField: 'status',
      text: '',
      style: { width: '186px', overflow: 'visible' },
      formatter: (cell, row: IAdminInterview) => {
        const {
          admin_status: adminStatus,
          agency_status: agencyStatus,
          agree_to_share: agreedToShare,
          candidate_token_code: candidateTokenCode,
          days_since_update: daysSinceUpdate,
          hired_chance_scale: hiredChance,
          id,
          status,
        } = row
        const statusReadable = isSuperAdmin ? adminStatus : agencyStatus
        const isCompleted = status === InterviewStatus.COMPLETED

        const enableReportButton = (agreedToShare && !isSuperAdmin) || isSuperAdmin
        const reportUrl = `/interviews/${candidateTokenCode}`

        const copyToClipboard = () => {
          setAlertText('Link copied!')
          setTimeout(() => setAlertText(''), 5000)
          copy(`${fullDomain}/interviews/${candidateTokenCode}`)
        }

        return (
          <InterviewStatusWrapper>
            <StatusWrapper>
              <StatusElement color={getStatusColor(statusReadable)}>
                {statusReadable}
              </StatusElement>
              {!isCompleted && isSuperAdmin && (
                <TimeWrapper isOld={daysSinceUpdate > 2}>
                  {daysSinceUpdate > 0 ? daysSinceUpdate : '<1'} Days
                </TimeWrapper>
              )}
            </StatusWrapper>

            {isCompleted && (
              <ButtonReportStyled
                title="Report"
                onClick={() =>
                  enableReportButton ? window.open(reportUrl, '_blank') : {}
                }
                disabled={!enableReportButton}
              >
                <ReportIcon title="Report" />
                <span>Report</span>
                <div>
                  According to the applicant request coaching session details can not be
                  shared
                </div>
              </ButtonReportStyled>
            )}

            <LinksWrapper>
              {isSuperAdmin && (
                <ButtonEditStyled
                  title="edit"
                  onClick={() => window.open(`/agencies/${agency?.id}/edit/${id}`)}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4923 2.789H7.7533C4.6783 2.789 2.75031 4.966 2.75031 8.048V16.362C2.75031 19.444 4.6693 21.621 7.7533 21.621H16.5773C19.6623 21.621 21.5813 19.444 21.5813 16.362V12.334"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.8278 10.9209L16.3008 3.4479C17.2318 2.5179 18.7408 2.5179 19.6718 3.4479L20.8888 4.6649C21.8198 5.5959 21.8198 7.1059 20.8888 8.0359L13.3798 15.5449C12.9728 15.9519 12.4208 16.1809 11.8448 16.1809H8.0988L8.1928 12.4009C8.2068 11.8449 8.4338 11.3149 8.8278 10.9209Z"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.1652 4.60251L19.7312 9.16851"
                      stroke="#200E32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Edit</span>
                </ButtonEditStyled>
              )}

              {agency?.show_interview_links && (
                <ButtonEditStyled title="Copy link" onClick={copyToClipboard}>
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g
                      id="Iconly/Light-Outline/Document"
                      stroke="none"
                      fill="none"
                      transform="matrix(0.835481, 0, 0, 0.800257, 4.792106, -0.063252)"
                    >
                      <g
                        id="Document"
                        transform="translate(3.000000, 2.000000)"
                        fill="#000000"
                      >
                        <path
                          d="M 5.519 5.892 C 8.663 5.892 10.775 8.171 10.775 11.561 L 10.775 21.292 C 10.775 24.711 8.728 26.935 5.56 26.957 L -2.133 26.96 C -5.277 26.96 -7.39 24.681 -7.39 21.292 L -7.39 11.561 C -7.39 8.14 -5.343 5.917 -2.175 5.897 L 5.518 5.892 L 5.519 5.892 Z M 5.519 7.48 L -2.17 7.484 C -4.498 7.498 -5.89 9.022 -5.89 11.561 L -5.89 21.292 C -5.89 23.847 -4.485 25.373 -2.134 25.373 L 5.555 25.369 C 7.883 25.355 9.275 23.829 9.275 21.292 L 9.275 11.561 C 9.275 9.006 7.871 7.48 5.519 7.48 Z"
                          id="path-1"
                        />
                        <path
                          d="M 13.721 -1.596 C 16.865 -1.596 18.977 0.713 18.977 4.15 L 18.977 14.013 C 18.977 17.48 16.93 19.735 13.762 19.756 L 6.069 19.759 C 2.925 19.759 0.812 17.45 0.812 14.013 L 0.812 4.15 C 0.812 0.682 2.859 -1.571 6.027 -1.592 L 13.72 -1.596 L 13.721 -1.596 Z M 13.721 0.012 L 6.032 0.017 C 3.704 0.032 2.312 1.576 2.312 4.15 L 2.312 14.013 C 2.312 16.603 3.717 18.15 6.068 18.15 L 13.757 18.147 C 16.085 18.133 17.477 16.586 17.477 14.013 L 17.477 4.15 C 17.477 1.559 16.073 0.012 13.721 0.012 Z"
                          id="Combined-Shape"
                        />
                      </g>
                    </g>
                  </svg>
                  <span>Copy</span>
                </ButtonEditStyled>
              )}
            </LinksWrapper>

            {isCompleted && hiredChance && <HiredChanceComponent scale={hiredChance} />}
          </InterviewStatusWrapper>
        )
      },
    },
  ]

  const rowClasses = (row: IAdminInterview) => {
    return row.is_hired ? 'table-row hired' : 'table-row'
  }

  const filterPriorityApplied = excludedPriorities.length > 0
  const filterOpsApplied = excludedOps.length > 0

  const tabs = Object.values(isSuperAdmin ? AdminInterviewStatus : AgencyInterviewStatus)

  return (
    <Layout
      title="Activity"
      hideBg
      fullBody
      alertText={alertText}
      alertType="fixed"
      onButtonClick={handleDownloadReport}
    >
      <>
        <TableWrapper>
          <Tabs>
            {tabs.map((t) => (
              <Tab
                key={t}
                color={mainColor}
                isSelected={selectedTab === t}
                onClick={handleSelectTab(t)}
              >
                {t} ({stats[t] || 0})
              </Tab>
            ))}
          </Tabs>
          <ToolkitProvider keyField="order" data={interviews} columns={columns} search>
            {(props) => (
              /* eslint-disable react/jsx-props-no-spreading */
              /* eslint-disable react/prop-types */
              <div>
                <PaginationHeader>
                  <SortBy onSelect={handleChangeSort} />
                  <FilterWrapper>
                    {isSuperAdmin && (
                      <>
                        <LinkButton onClick={() => setIsOpsFilterVisible(true)}>
                          Assigned Filter
                        </LinkButton>
                        {filterOpsApplied && (
                          <FilterIconStyled alt="filter" src={FilterIcon} />
                        )}
                        <LinkButton onClick={() => setIsProirityFilterVisible(true)}>
                          High priority
                        </LinkButton>
                        {filterPriorityApplied && (
                          <FilterIconStyled alt="filter" src={FilterIcon} />
                        )}
                      </>
                    )}
                  </FilterWrapper>

                  <SearchContainer>
                    <SearchBy
                      isSuperAdmin={isSuperAdmin}
                      onSelect={handleChangeSearchKey}
                    />
                    <SearchInput onChange={handleChangeSearch} />
                  </SearchContainer>
                </PaginationHeader>

                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <BootstrapTable
                      {...props.baseProps}
                      rowClasses={rowClasses}
                      classes="table"
                      headerWrapperClasses="table-header"
                      bodyClasses="table-tbody"
                      wrapperClasses="table-wrapper"
                    />
                    {pagesTotal > 1 && (
                      <PaginationFooter
                        current={selectedPage}
                        pages={pagesTotal}
                        onSelect={handleSelectPage}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </ToolkitProvider>
        </TableWrapper>
        {isPriorityFilterVisible && (
          <FilterComponent
            data={InterviewPriorities}
            excludedData={excludedPriorities}
            onChange={handleChangePriorityFilter}
            onClose={() => setIsProirityFilterVisible(false)}
          />
        )}
        {isOpsFilterVisible && (
          <FilterOpsComponent
            excludedOps={excludedOps}
            onChange={handleChangeOpsFilter}
            onClose={() => setIsOpsFilterVisible(false)}
          />
        )}
      </>
    </Layout>
  )
}

export default Dashboard
