import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import getCurrency from 'src/utils/currency'
import SvgPrice from 'src/static/components/SvgPrice'
import getClearLink from 'src/utils/links'
import { format } from 'date-fns'
import { IExpertProfile } from '../../api'
import * as interviewSelectors from '../selectors'
import ResumeIcon from '../../static/availability_resume_icon.png'
import LinkedInIcon from '../../static/ic_linked_in.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const DetailsContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #edeef2;
  border-radius: 8px;
`

const DetailsHeader = styled.div`
  display: flex;
  height: 80px;
  padding: 8px 15px;
  border-bottom: 1px solid #edeef2;
`

const ProfilePhoto = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`

const HeaderDataContainer = styled.div<{ centered?: boolean }>`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.centered ? 'center' : 'start')};
`

const ApplicantName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #3b424f;
`

const ApplicantLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const ApplicantResume = styled.a`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  color: black;
  padding-left: 22px;
  margin-right: 9px;
  text-decoration-line: underline;

  &:before {
    position: absolute;
    left: 0;
    content: '';
    width: 15px;
    height: 16px;
    background: url(${ResumeIcon});
    background-size: 100% 100%;
  }
`

const LinkedInLink = styled.div`
  width: 24px;
  height: 24px;
  background: url(${LinkedInIcon});
  background-size: 100% 100%;
  cursor: pointer;
`

const HeaderText = styled.div`
  font-weight: 700;
  font-size: 18px;
  padding: 0px 15px;
  line-height: 24px;
  color: #222b45;
  margin: 43px 0 32px 0;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #222b45;
  margin: 0 0 7px 0;
  padding: 0px 15px;
`

const DetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px 15px;
`

const DetailsTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: rgba(115, 121, 145, 0.8);
  margin: 12px 0 4px;
`

const DetailsText = styled.div<{ zeroMargin?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-bottom: ${(props) => (props.zeroMargin ? 0 : 6)}px;

  > svg {
    margin-right: 8px;
  }

  > p {
    margin-bottom: 0;
  }
`

const DetailsLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: black;
  margin-bottom: 6px;
  width: fit-content;
  text-decoration: underline;
`

const BottomButtonsContainer = styled.div`
  height: 64px;
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  justify-content: space-between;

  @media (orientation: landscape) {
    justify-content: start;
  }
`

const BottomButton = styled.div<{ width: number; filled?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.filled ? 'white' : '#B202FF')};
  background: ${(props) => (props.filled ? '#B202FF' : 'white')};
  border: 1px solid #b202ff;
  border-radius: 4px;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  font-weight: 500;
  font-size: 12px;

  @media (orientation: landscape) {
    margin-right: 40px;
  }

  & :hover {
    opacity: 0.8;
  }

  & :active {
    opacity: 0.5;
  }
`

const LinkButton = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 0 4px;
  color: black;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
`

interface IProps {
  onDecline: () => void
  onContinue: () => void
}

function AvailabilityApplicantInfoMobile({ onDecline, onContinue }: IProps) {
  // const agency = useSelector(interviewSelectors.agency)
  const interviewDetails = useSelector(interviewSelectors.interview)
  const [showShortDescription, setShowShortDescription] = useState(
    interviewDetails.interview.position_description?.length > 210
  )
  const peerFullName = `${interviewDetails.peer.first_name} ${interviewDetails.peer.last_name}`
  const resumeLink = interviewDetails.interview.cv_file?.file?.url
  const linkedInLink = interviewDetails.peer.linkedin_profile
    ? `${interviewDetails.peer.linkedin_profile.startsWith('http') ? '' : 'https://'}${
        interviewDetails.peer.linkedin_profile
      }`
    : ''

  const desiredPosition = `${interviewDetails.interview.desired_position}${
    interviewDetails.interview.position_company
      ? `, ${interviewDetails.interview.position_company}`
      : ''
  }${
    interviewDetails.interview.position_location
      ? `, ${interviewDetails.interview.position_location}`
      : ''
  }`
  const positionDescroptionShort = `${interviewDetails.interview.position_description?.substring(
    0,
    210
  )}...`

  const expertProfile: IExpertProfile = interviewDetails.profile
  const expertPrice = `${getCurrency(expertProfile.expert_currency)}${
    expertProfile.expert_price
  }`
  const hasSpecialRequest =
    interviewDetails.interview.special_request ||
    interviewDetails.interview.special_request_by_admin
  const hasLinks = resumeLink || linkedInLink
  const linkToPosition = `//${getClearLink(interviewDetails.interview.link_to_position)}`

  return (
    <Container>
      <HeaderText>
        Coaching session for {interviewDetails.interview.desired_position} position
        {interviewDetails.interview.position_company
          ? ` at ${interviewDetails.interview.position_company}`
          : ''}
        {interviewDetails.interview.position_location
          ? `, ${interviewDetails.interview.position_location}`
          : ''}
      </HeaderText>
      <Title>{`Help ${interviewDetails.peer.first_name} land their dream job!`}</Title>
      <DetailsContainer>
        <DetailsHeader>
          <ProfilePhoto src={interviewDetails.peer.logo.url} />
          <HeaderDataContainer centered={!hasLinks}>
            <ApplicantName>{peerFullName}</ApplicantName>
            {hasLinks && (
              <ApplicantLinks>
                {resumeLink && (
                  <ApplicantResume
                    href={resumeLink}
                    target="_blank"
                  >{`${interviewDetails.peer.first_name}'s resume`}</ApplicantResume>
                )}
                {linkedInLink && (
                  <LinkedInLink onClick={() => window.open(linkedInLink, '_blank')} />
                )}
              </ApplicantLinks>
            )}
          </HeaderDataContainer>
        </DetailsHeader>
        <DetailsInfoContainer>
          <DetailsTitle>Desired position</DetailsTitle>
          <DetailsText zeroMargin>{desiredPosition}</DetailsText>
          {interviewDetails.interview.link_to_position && (
            <DetailsLink href={linkToPosition} target="_blank">
              Position URL
            </DetailsLink>
          )}
          {hasSpecialRequest && (
            <>
              <DetailsTitle>Session request</DetailsTitle>
              {interviewDetails.interview.special_request && (
                <DetailsText
                  zeroMargin
                >{`"${interviewDetails.interview.special_request}"`}</DetailsText>
              )}
              {interviewDetails.interview.special_request_by_admin && (
                <DetailsText>{`"${interviewDetails.interview.special_request_by_admin}"`}</DetailsText>
              )}
            </>
          )}
          {interviewDetails.interview.programming_language && (
            <>
              <DetailsTitle>Programming language</DetailsTitle>
              <DetailsText>{interviewDetails.interview.programming_language}</DetailsText>
            </>
          )}
          {interviewDetails.interview.interview_date && (
            <>
              <DetailsTitle>Job interview date</DetailsTitle>
              <DetailsText>
                {format(new Date(interviewDetails.interview.interview_date), 'MMM dd')}
              </DetailsText>
            </>
          )}
          <DetailsTitle>Session rate</DetailsTitle>
          <DetailsText>
            <SvgPrice fill="#000" />
            {expertPrice}
          </DetailsText>
          {interviewDetails.interview.position_description && (
            <>
              <DetailsTitle>Position description</DetailsTitle>
              <DetailsText>
                {showShortDescription ? (
                  <p>
                    {positionDescroptionShort}
                    <LinkButton onClick={() => setShowShortDescription(false)}>
                      More
                    </LinkButton>
                  </p>
                ) : (
                  interviewDetails.interview.position_description
                )}
              </DetailsText>
            </>
          )}
        </DetailsInfoContainer>
      </DetailsContainer>
      <BottomButtonsContainer>
        <BottomButton onClick={onDecline} width={112}>
          Decline
        </BottomButton>
        <BottomButton onClick={onContinue} width={186} filled>
          Continue
        </BottomButton>
      </BottomButtonsContainer>
    </Container>
  )
}

export default AvailabilityApplicantInfoMobile
