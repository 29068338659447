import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: transparent;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const LineContainer = styled.div`
  font-weight: 500;
  display: flex;
`

const DataContainer = styled.div`
  width: 566px;
  background: #ffffff;
  border: 1px solid #b202ff;
  border-radius: 10px;
  padding: 44px;

  ${isMobileOnly &&
  css`
    width: 90%;
    padding: 19px;
    @media (orientation: landscape) {
      width: 343px;
    }
  `}
`

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #1d0e3b;
  margin-bottom: 4px;

  ${isMobileOnly &&
  css`
    font-size: 20px;
  `}
`

const SubHeader = styled(Header)`
  font-weight: 500;
  font-size: 16px;
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`

const TextItem = styled.div<{ fontSize?: number }>`
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  line-height: 150%;
  color: #222b45;
`

const LinkItem = styled.span<{ fontSize?: number; margin?: string }>`
  cursor: pointer;
  font-size: ${({ fontSize }) => fontSize ?? 14}px;
  color: rgba(178, 2, 255, 1);
  text-decoration: underline;
  margin: ${({ margin }) => margin ?? 0};
`

const BoldItem = styled(TextItem)`
  font-weight: 500;
`

interface IProps {
  onCancel?: () => void
}

const AgoraError = ({ onCancel }: IProps) => {
  // eslint-disable-next-line no-restricted-globals
  const onRefresh = () => location.reload()

  return (
    <>
      <Container>
        <DataContainer>
          <Header>We weren’t able to connect you to your session</Header>
          <SubHeader>Please follow the next steps:</SubHeader>
          <ItemsContainer>
            <TextItem>1. Ensure you’re using a stable internet connection</TextItem>
            <TextItem>
              2. Ensure you’re not connected to a VPN, or being blocked by your firewall
            </TextItem>
            <TextItem>
              3. <LinkItem onClick={onRefresh}>Try to reload the page.</LinkItem>
            </TextItem>
            <TextItem>
              4. Try using a different browser - we recommend Chrome or Firefox
            </TextItem>
            <TextItem>5. Use another device</TextItem>
          </ItemsContainer>
          <LineContainer>
            <BoldItem fontSize={12}>
              Are you able to connect to your session after following the steps?
            </BoldItem>
            <LinkItem fontSize={12} margin="0 0 0 12px" onClick={onCancel}>
              No
            </LinkItem>
          </LineContainer>
        </DataContainer>
      </Container>
    </>
  )
}

AgoraError.defaultProps = {
  onCancel: () => {},
}

export default AgoraError
