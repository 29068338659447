import React from 'react'

interface SvgProps {
  fill: string
}

function SvgReplaceQuestion({ fill }: SvgProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9998 21.5548H5.99978C4.6743 21.5548 3.59978 20.4803 3.59979 19.1548L3.59989 4.75484C3.59989 3.42937 4.67441 2.35486 5.99989 2.35486H16.8002C18.1256 2.35486 19.2002 3.42937 19.2002 4.75486V8.95486M15.6002 16.0124C15.6002 14.7158 16.6747 13.6646 18.0002 13.6646C19.3257 13.6646 20.4002 14.7158 20.4002 16.0124C20.4002 17.3091 19.3257 18.3603 18.0002 18.3603M18.0002 21.645V21.5548M7.80017 7.15486H15.0002M7.80017 10.7549H15.0002M7.80017 14.3549H11.4002"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgReplaceQuestion
