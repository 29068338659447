import React from 'react'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'

const MainLayout: React.SFC = styled.div`
  color: black;
  height: ${isMobile ? '100%' : '100vh'};
  width: 100%;
`

export default MainLayout
