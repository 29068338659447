import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const ChanceContainer = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
`

const ChanceItem = styled.div<{ color?: string; fill?: boolean }>`
  background-color: ${(props) => (props.fill ? props.color : '#8F99B3')};
  height: 16px;
  width: 2px;
`

const Label = styled.p`
  color: #8f99b3;
  font-size: 12px;
  margin: 0;
`

interface IProps {
  scale: string
}

const HiredChanceComponent = ({ scale }: IProps) => {
  let color
  let fill

  switch (scale) {
    case 'High':
      color = '#00DE9F'
      fill = 18
      break
    case 'Medium':
      color = '#F1BE2B'
      fill = 11
      break
    default:
      color = '#FF3D71'
      fill = 6
      break
  }

  const elements = [...Array(18).keys()]

  return (
    <Container>
      <ChanceContainer>
        {elements.map((e) => (
          <ChanceItem key={e} color={color} fill={e < fill} />
        ))}
      </ChanceContainer>
      <Label>Success chances</Label>
    </Container>
  )
}

export default HiredChanceComponent
