import React, { useState } from 'react'
import styled from '@emotion/styled'

import chatIcon from 'src/static/chat-icon.svg'
import chevronRight from 'src/static/chevron-right.svg'

import { SwipeEventData, useSwipeable } from 'react-swipeable'
import {
  OnboardingPopupChat,
  MobileOnboardingPopupChat,
  ChatIcon,
  ChevronIcon,
  MessageTitle,
  MessageText,
  MobileChatIcon,
  MobileChevronIcon,
  MobileMessageTitle,
  MobileMessageText,
} from './BaseOnboarding'

const HeaderOnboardingContainer = styled(OnboardingPopupChat)`
  cursor: pointer;
  flex-wrap: nowrap;
  max-height: 50vh;
  position: absolute;
  right: 50%;
  top: 100%;
  padding: 0;
  transform: translate(20%, 0.75rem);

  &:after {
    left: auto;
    right: 19%;
  }
`

const MessageTitleContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e3ea;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 8px;
  width: 100%;
`

const MobileReactionContainer = styled(MobileOnboardingPopupChat)<{ delta: number }>`
  flex-wrap: nowrap;
  max-height: 20vh;
  right: ${({ delta }) => `calc(50% - ${delta}px)`};
  top: 72px;
  transform: translate(50%, 0);

  &:after {
    left: 70%;
  }

  @media (orientation: landscape) {
    max-height: 50vh;
    right: 50%;
    top: 70px;

    &:after {
      left: 70%;
    }
  }
`

const MobileChatTitle = styled(MobileMessageTitle)`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
`

const ChatText = styled(MessageText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const MobileChatText = styled(MobileMessageText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

interface IOnBoardingChat {
  userName: string
  message: string
  onClick: () => void
}

export function OnBoardingChat({ userName, message, onClick }: IOnBoardingChat) {
  return (
    <HeaderOnboardingContainer onClick={onClick}>
      <MessageTitleContainer>
        <ChatIcon src={chatIcon} />
        <MessageTitle>Message from {userName}</MessageTitle>
        <ChevronIcon src={chevronRight} />
      </MessageTitleContainer>
      <ChatText>{message}</ChatText>
    </HeaderOnboardingContainer>
  )
}

interface IOnBoardingChatMobile {
  userName: string
  message: string
  onClick: () => void
  onClose: () => void
}

export function OnBoardingChatMobile({
  userName,
  message,
  onClick,
  onClose,
}: IOnBoardingChatMobile) {
  const [delta, setDelta] = useState(0)

  const handleSwiping = ({ deltaX }: SwipeEventData) => {
    setDelta(deltaX < 0 ? deltaX : 0)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => onClose(),
    onSwiping: handleSwiping,
  })

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MobileReactionContainer delta={delta} onClick={onClick} {...handlers}>
      <MessageTitleContainer>
        <MobileChatIcon src={chatIcon} />
        <MobileChatTitle>Message from {userName}</MobileChatTitle>
        <MobileChevronIcon src={chevronRight} />
      </MessageTitleContainer>
      <MobileChatText>{message}</MobileChatText>
    </MobileReactionContainer>
  )
}
