import { PreviewVideo, MobilePreviewVideo } from './PreviewVideo'
import { RemoteVideo, MobileRemoteVideo } from './RemoteVideo'
import SmallVideo from './SmallVideo'
import SmallVideoMobile from './SmallVideoMobile'
import {
  MobileSmallContainer,
  MobileSmallSliderContainer,
  SmallVideosContainer,
  MobileFullHeight,
} from './SmallVideosContainer'

export default {
  MobileSmallContainer,
  MobileSmallSliderContainer,
  PreviewVideo,
  RemoteVideo,
  SmallVideosContainer,
  SmallVideo,
  SmallVideoMobile,
  MobileFullHeight,
  MobilePreviewVideo,
  MobileRemoteVideo,
}
