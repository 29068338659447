import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

interface Time {
  hh: string
  mm: string
  ss: string
}

export interface IProps {
  max?: number
  currentTime?: number
  progress?: number
  onChange?: (time: number, offsetTime: number) => void
  hideHoverTime?: boolean
  offset?: number
  secondsPrefix?: string
  minutesPrefix?: string
  limitTimeTooltipBySides?: boolean
  sliderLineHeight?: number
  hasTimelineInfo?: boolean
  style: any
}

const Container = styled.div`
  position: relative;
  width: 100%;
`

const Track = styled.div`
  padding: 10px 0;
  cursor: pointer;
  outline: none;
`

const Main = styled.div`
  width: 100%;
  background-color: rgb(237, 238, 242);
  height: 10px;
  border-radius: 30px;
  position: absolute;
  left: 0 top 10 - math.div(10px, 2);
  overflow: hidden;
  transition: transform 0.4s;
  outline: none;
`

const Buffered = styled.div`
  position: absolute;
  background-color: rgba(#fff, 0.3);
  width: 100%;
  height: 100%;
  transform: scaleX(0.8);
  z-index: 2;
  transform-origin: 0 0;
  transition: 0.5s transform;
`

const SeekHover = styled.div`
  position: absolute;
  background-color: rgba(#fff, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: scaleX(0.6);
  transform-origin: 0 0;
  opacity: 0;
  transition: opacity 0.4s;
`

const Connect = styled.div`
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 3;
  left: 0;
  transform: scaleX(0.13);
  transform-origin: 0 0;
`

const HoverTime = styled.div<{ active?: boolean }>`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  line-height: 18px;
  font-size: 16px;
  color: #ddd;
  top: -25px;
  left: 0;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: translateX(150px);
  pointer-events: none;
`

const Thumb = styled.div`
  pointer-events: none;
  position: absolute;
  left: -8px;
  top: 7px;
  z-index: 11;
  transform: translateX(100px);

  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #b202ff !important;
  border: solid 4px #6938aa;
`

const SliderHandleVertical = styled.div<{
  height: number
  background: string
}>`
  position: absolute;
  width: 2px;
  margin-top: 10px;
  height: ${(props) => props.height}px;
  background: ${(props) => props.background};
  transform: scale(1);
  top: calc(50% - 4px);
  margin-left: -1px;
  z-index: 10;
  ${isMobileOnly &&
  css`
    margin-top: -12px;
  `}
`

function secondsToTime(seconds: number, offset: number): Time {
  const roundedSeconds = Math.round(seconds + offset)

  const hours: number = Math.floor(roundedSeconds / 3600)
  const divirsForMinutes: number = roundedSeconds % 3600
  const minutes: number = Math.floor(divirsForMinutes / 60)
  const sec: number = Math.ceil(divirsForMinutes % 60)

  return {
    hh: hours.toString(),
    mm: minutes < 10 ? `0${minutes}` : minutes.toString(),
    ss: sec < 10 ? `0${sec}` : sec.toString(),
  }
}

export const VideoSeekSlider = ({
  max,
  currentTime,
  progress,
  hideHoverTime,
  offset,
  secondsPrefix,
  minutesPrefix,
  onChange,
  limitTimeTooltipBySides,
  sliderLineHeight,
  hasTimelineInfo,
  style,
}: IProps) => {
  const [seekHoverPosition, setSeekHoverPosition] = useState(0)

  const seeking = useRef(false)
  const trackWidth = useRef(0)
  const mobileSeeking = useRef(false)
  const track = useRef<HTMLDivElement>(null)
  const hoverTime = useRef<HTMLDivElement>(null)

  const hoverTimeValue = useMemo(() => {
    const percent: number = (seekHoverPosition * 100) / trackWidth.current
    const time: number = Math.floor(+(percent * (max / 100)))
    const times: Time = secondsToTime(time, offset)

    if (max + offset < 60) {
      return secondsPrefix + times.ss
    }

    if (max + offset < 3600) {
      return `${minutesPrefix + times.mm}:${times.ss}`
    }

    return `${times.hh}:${times.mm}:${times.ss}`
  }, [max, minutesPrefix, offset, secondsPrefix, seekHoverPosition])

  function changeCurrentTimePosition(pageX: number): void {
    const left = track.current?.getBoundingClientRect().left || 0
    let position = pageX - left

    position = position < 0 ? 0 : position
    position = position > trackWidth.current ? trackWidth.current : position

    setSeekHoverPosition(position)

    const percent = (position * 100) / trackWidth.current
    const time = +(percent * (max / 100)).toFixed(0)

    onChange(time, time + offset)
  }

  function handleTouchSeeking(event: TouchEvent): void {
    event.preventDefault()
    event.stopPropagation()

    let pageX = 0

    for (let i = 0; i < event.changedTouches.length; i += 1) {
      pageX = event.changedTouches?.[i].pageX
    }

    pageX = pageX < 0 ? 0 : pageX

    if (mobileSeeking.current) {
      changeCurrentTimePosition(pageX)
    }
  }

  function handleSeeking(event: MouseEvent): void {
    if (seeking.current) {
      changeCurrentTimePosition(event.pageX)
    }
  }

  function setTrackWidthState(): void {
    if (track.current) {
      trackWidth.current = track.current.offsetWidth
    }
  }

  function handleTrackHover(
    clear: boolean,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    const left = track.current?.getBoundingClientRect().left || 0
    const position = clear ? 0 : event.pageX - left

    setSeekHoverPosition(position)
  }

  function getPositionStyle(time: number): { transform: string } {
    const divider = max || -1 // prevent division by zero
    const position = (time * 100) / divider

    return { transform: `scaleX(${position / 100})` }
  }

  function getThumbHandlerPosition(): { transform: string } {
    const position = trackWidth.current / (max / currentTime)

    return { transform: `translateX(${position}px)` }
  }

  function getSeekHoverPosition(): { transform: string } {
    const position = (seekHoverPosition * 100) / trackWidth.current

    return { transform: `scaleX(${position / 100})` }
  }

  function getHoverTimePosition(): { transform: string } {
    let position = 0

    if (hoverTime.current) {
      position = seekHoverPosition - hoverTime.current.offsetWidth / 2

      if (limitTimeTooltipBySides) {
        if (position < 0) {
          position = 0
        } else if (position + hoverTime.current.offsetWidth > trackWidth.current) {
          position = trackWidth.current - hoverTime.current.offsetWidth
        }
      }
    }

    return { transform: `translateX(${position}px)` }
  }

  function setMobileSeeking(state = true): void {
    mobileSeeking.current = state
    setSeekHoverPosition(state ? seekHoverPosition : 0)
  }

  function setSeeking(state: boolean, event: MouseEvent): void {
    event.preventDefault()

    handleSeeking(event)
    seeking.current = state

    setSeekHoverPosition(state ? seekHoverPosition : 0)
  }

  function mouseSeekingHandler(event: MouseEvent): void {
    setSeeking(false, event)
  }

  function mobileTouchSeekingHandler(): void {
    setMobileSeeking(false)
  }

  useEffect(() => {
    setTrackWidthState()

    window.addEventListener('resize', setTrackWidthState)
    window.addEventListener('mousemove', handleSeeking)
    window.addEventListener('mouseup', mouseSeekingHandler)
    window.addEventListener('touchmove', handleTouchSeeking)
    window.addEventListener('touchend', mobileTouchSeekingHandler)

    return () => {
      window.removeEventListener('resize', setTrackWidthState)
      window.removeEventListener('mousemove', handleSeeking)
      window.removeEventListener('mouseup', mouseSeekingHandler)
      window.removeEventListener('touchmove', handleTouchSeeking)
      window.removeEventListener('touchend', mobileTouchSeekingHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, offset])

  useEffect(() => {
    setTrackWidthState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTimelineInfo, sliderLineHeight])

  const isActive = seekHoverPosition > 0

  return (
    <Container style={style}>
      <Track
        ref={track}
        onMouseMove={(event) => handleTrackHover(false, event)}
        onMouseLeave={(event) => handleTrackHover(true, event)}
        onMouseDown={(event) => setSeeking(true, event as any)}
        onTouchStart={() => setMobileSeeking(true)}
      >
        <Main>
          <Buffered style={getPositionStyle(progress)} />

          <SeekHover style={getSeekHoverPosition()} />

          <Connect style={getPositionStyle(currentTime)} />
        </Main>
      </Track>

      {!hideHoverTime && (
        <HoverTime style={getHoverTimePosition()} ref={hoverTime} active={isActive}>
          {hoverTimeValue}
        </HoverTime>
      )}

      <SliderHandleVertical
        style={getThumbHandlerPosition()}
        height={sliderLineHeight}
        background="#6938AA"
      />

      <Thumb style={getThumbHandlerPosition()} />
    </Container>
  )
}

VideoSeekSlider.defaultProps = {
  max: 100,
  currentTime: 0,
  progress: 0,
  hideHoverTime: false,
  offset: 0,
  secondsPrefix: '',
  minutesPrefix: '',
  onChange: () => undefined,
  limitTimeTooltipBySides: false,
  sliderLineHeight: 0,
  hasTimelineInfo: false,
}
