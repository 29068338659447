import React from 'react'

interface SvgProps {
  fill?: string
}

function SvgCancelUploading({ fill }: SvgProps) {
  return (
    <svg
      className="svg"
      style={{ overflow: 'visible' }}
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 0C17.866 0 21 3.13401 21 7V14C21 17.866 17.866 21 14 21H7C3.13401 21 0 17.866 0 14V7C0 3.13401 3.13401 0 7 0H14ZM7.71505 6.07799C7.25738 5.72213 6.59561 5.75448 6.17504 6.17504C5.75448 6.59561 5.72213 7.25738 6.07799 7.71505L6.17504 7.82496L8.84917 10.5L6.17504 13.175C5.71943 13.6307 5.71943 14.3693 6.17504 14.825C6.59561 15.2455 7.25738 15.2779 7.71505 14.922L7.82496 14.825L10.5 12.1508L13.175 14.825L13.285 14.922C13.7426 15.2779 14.4044 15.2455 14.825 14.825C15.2455 14.4044 15.2779 13.7426 14.922 13.285L14.825 13.175L12.1508 10.5L14.825 7.82496C15.2806 7.36935 15.2806 6.63065 14.825 6.17504C14.4044 5.75448 13.7426 5.72213 13.285 6.07799L13.175 6.17504L10.5 8.84917L7.82496 6.17504L7.71505 6.07799Z"
          fill={fill || '#6938AA'}
        />
      </g>
    </svg>
  )
}

SvgCancelUploading.defaultProps = {
  fill: undefined,
}

export default SvgCancelUploading
