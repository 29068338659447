import { put, takeEvery, call } from 'typed-redux-saga'

import { setInterviewTimestamp } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

type InterviewTypestampType =
  | 'screen_start_candidate'
  | 'screen_start_expert'
  | 'screen_stop_candidate'
  | 'screen_stop_expert'
  | 'code_start'
  | 'code_stop'

interface ISetInterviewTimestampAction {
  interviewToken: string
  name: InterviewTypestampType
  time: number
}

const {
  action: setInterviewTimestampAction,
  actionFailure: setInterviewTimestampActionFailure,
  actionSuccess: setInterviewTimestampActionSuccess,
  actionSuccessClear: setInterviewTimestampActionSuccessClear,
  actionFailureClear: setInterviewTimestampActionFailureClear,
  actionType: setInterviewTimestampActionType,
  actionTypeFailure: setInterviewTimestampActionFailureType,
  actionTypeSuccess: setInterviewTimestampActionSuccessType,
  reducer: setInterviewTimestampReducer,
  selectors: setInterviewTimestampSelectors,
} = reduxRequestFactory<ISetInterviewTimestampAction>('setInterviewTimestamp', 'requests')

export {
  setInterviewTimestampAction,
  setInterviewTimestampActionFailure,
  setInterviewTimestampActionSuccess,
  setInterviewTimestampActionSuccessClear,
  setInterviewTimestampActionFailureClear,
  setInterviewTimestampActionType,
  setInterviewTimestampActionFailureType,
  setInterviewTimestampActionSuccessType,
  setInterviewTimestampReducer,
  setInterviewTimestampSelectors,
}

function* setInterviewTimestampSaga(action: ISagaAction<ISetInterviewTimestampAction>) {
  try {
    const { interviewToken, name, time } = action.payload
    yield* call(setInterviewTimestamp, interviewToken, name, time)
    yield put(setInterviewTimestampActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      setInterviewTimestampActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchSetInterviewTimestamp() {
  yield takeEvery(setInterviewTimestampActionType, setInterviewTimestampSaga)
}
