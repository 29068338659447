import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import SvgCloseDrawer from 'src/static/components/SvgCloseDrawer'

const SideMenuContainer = styled.div<{ show: boolean; blur?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  background: ${({ show, blur }) =>
    show && blur ? 'rgba(115, 121, 145, 0.30)' : 'transparent'};
  backdrop-filter: ${({ blur, show }) => (blur && show ? 'blur(10px)' : 'none')};
  transition: background 200ms;
  pointer-events: ${({ show }) => (show ? 'all' : 'none')};
  z-index: 10;
`

const SideMenuItemsContainer = styled.div<{ show: boolean; autoHeight?: boolean }>`
  border-radius: 12px;
  background: #fff;
  margin: 27px 20px;
  width: 316px;
  height: ${({ autoHeight }) => (autoHeight ? 'fit-content' : '460px')};
  animation: translateAnimation 500ms;
  position: relative;
  transform: translateX(${({ show }) => (show ? '0' : '110')}%);
  transition: all 200ms;
  padding: 62px 22px 31px 34px;
  box-shadow: 0 0 4px lightgray;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      height: auto;
      overflow: auto;
    }
  `}
`

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 22px;
  width: 16px;
  z-index: 10;

  > svg {
    width: 100%;
  }
`

const LandingSideMenu = ({ show, onClose, autoHeight, blur, children }: any) => {
  const onDataContainerClicked = (ev) => {
    ev.stopPropagation()
  }
  return (
    <SideMenuContainer show={show} blur={blur} onClick={onClose}>
      <SideMenuItemsContainer
        show={show}
        autoHeight={autoHeight}
        onClick={onDataContainerClicked}
      >
        <CloseButton onClick={onClose}>
          <SvgCloseDrawer fill="#000" />
        </CloseButton>
        {children}
      </SideMenuItemsContainer>
    </SideMenuContainer>
  )
}

export default LandingSideMenu
