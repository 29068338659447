import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Redirect, useRouteMatch, useParams } from 'react-router-dom'

import { NotFound, NotAllowed } from '../components'
import { agencySelectors, getAgencyAction } from '../redux'
import { getMainAdminPage, isAgencyAdminRole, isSuperAdminRole } from '../utils/jwtToken'

import AgencyRoute from './components/AgencyRoute'
import AvaDashboard from './components/AvaDashboard'
import Dashboard from './components/Dashboard'

import CoachingSessionDetails from './containers/CoachingSessionDetails'
import NewBooking from './containers/NewBooking'
import Statistics from './containers/Statistics'
import AgencyBulk from './containers/AgencyBulk'
import AgencyForm, { AgencyFormType } from './forms/AgencyForm'

function SuperAdminHOC({ children }: { children: JSX.Element }) {
  const isSuperAdmin = isSuperAdminRole()
  return isSuperAdmin ? children : <NotAllowed />
}

function AgencyHOC({ children }: { children: JSX.Element }) {
  const dispatch = useDispatch()
  const { id } = useParams()

  const isAgencyAdmin = isAgencyAdminRole(id)
  const isSuperAdmin = isSuperAdminRole()
  const isAllowed = isAgencyAdmin || isSuperAdmin

  useEffect(() => {
    dispatch(getAgencyAction({ agencyId: id }))
  }, [dispatch, id])

  return isAllowed ? children : <NotAllowed />
}

function BookingHOC({ children }: { children: JSX.Element }) {
  const { id } = useParams()
  const agency = useSelector(agencySelectors.data)

  const isSuperAdmin = isSuperAdminRole()
  const isAllowed = isSuperAdmin || !agency.created_at || agency.admin_booking

  return isAllowed ? children : <Redirect to={`/agencies/${id}/activity`} />
}

function Agencies() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <AgencyRoute exact path={path}>
        <Redirect to={getMainAdminPage()} />
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/create">
        <SuperAdminHOC>
          <AgencyHOC>
            <AgencyForm mode={AgencyFormType.Create} />
          </AgencyHOC>
        </SuperAdminHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id">
        <AgencyHOC>
          <BookingHOC>
            <NewBooking />
          </BookingHOC>
        </AgencyHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id/activity">
        <AgencyHOC>
          <Dashboard />
        </AgencyHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id/ava_activity">
        <AgencyHOC>
          <AvaDashboard />
        </AgencyHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id/statistics">
        <AgencyHOC>
          <Statistics />
        </AgencyHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id/edit">
        <SuperAdminHOC>
          <AgencyHOC>
            <AgencyForm mode={AgencyFormType.Edit} />
          </AgencyHOC>
        </SuperAdminHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id/bulk">
        <SuperAdminHOC>
          <AgencyHOC>
            <AgencyBulk />
          </AgencyHOC>
        </SuperAdminHOC>
      </AgencyRoute>
      <AgencyRoute exact path="/agencies/:id/edit/:interviewId">
        <SuperAdminHOC>
          <AgencyHOC>
            <CoachingSessionDetails />
          </AgencyHOC>
        </SuperAdminHOC>
      </AgencyRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Agencies
