import { ISendFeedbackPayload } from '../api'
import { IFailure } from '../utils/redux'

export const SEND_FEEDBACK = 'REPORT/SEND_FEEDBACK'
export const SEND_FEEDBACK_SUCCESS = 'REPORT/SEND_FEEDBACK_SUCCESS'
export const SEND_FEEDBACK_FAILURE = 'REPORT/SEND_FEEDBACK_FAILURE'

export interface ISendFeedbackAction {
  type: typeof SEND_FEEDBACK
  payload: ISendFeedbackPayload
}

export interface ISendFeedbackSuccessAction {
  type: typeof SEND_FEEDBACK_SUCCESS
  payload: {}
}

export interface ISendFeedbackFailureAction {
  type: typeof SEND_FEEDBACK_FAILURE
  payload: IFailure
}

export interface ITranscriptText {
  author: string
  avatar: string
  text: string
  timestamp?: number
  isCandidate?: boolean
  lengthMs?: number
  sentimentScore?: number
}

export enum ITimelineInfoType {
  None,
  Feedback,
  Notes,
  Questions,
  ScreenActivity,
}

export enum IMetrics {
  None,
  Feedback,
  Notes,
  Questions,
}

export interface IMetricsData {
  type: MetricsType
  tooltip: string
  info: string
  result: string
  color: string
  progress: number
}

export enum MetricsType {
  Attitude,
  WorkplaceCulture,
  SpeechTempo,
  UseOfData,
  Confidence,
  Experience,
}

export interface MetricsVocabularyItem {
  type: MetricsType
  tooltip: string
  minText: MetricsVocabularyText
  avgText: MetricsVocabularyText
  maxText: MetricsVocabularyText
}

export interface MetricsVocabularyText {
  result: string
  info: string
  color: string
}
