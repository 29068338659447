import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import lockedIcon from 'src/static/locked-icon.png'
import pciIcon from 'src/static/pci-icon.png'
import * as interviewSelectors from '../selectors'

import PaymentForm from '../forms/PaymentForm'
import { Text, TextBold } from '../../components'

const Container = styled.div`
  padding-left: 40px;
  margin-left: 40px;
  width: 440px;
  border-left: 1px solid #eee;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }
`

const StyledTitle = styled(TextBold)`
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 40px;
`

const StyledTextBold = styled(TextBold)`
  font-size: 18px;
  font-family: 'Open Sans';
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 12px;
  }
`

const StyledSmallText = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 25px;
  }
`

const StyledSmallPaymentText = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  @media screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 12px;
  }
`

const SecuredPaymentTitle = styled.div`
  margin-top: 30px;
  margin-bottom: 25px;
  padding-left: 25px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    z-index: 1;
    background: url(${lockedIcon}) no-repeat center center / auto 100%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 24px;
    z-index: 1;
    background: url(${pciIcon}) no-repeat center center / auto 100%;
    @media screen and (max-width: 600px) {
      top: 12px;
    }
  }

  @media screen and (max-width: 600px) {
    padding-right: 100px;
  }
`
const stripeKey = process.env.REACT_APP_STRIPE_KEY
const promise = loadStripe(stripeKey)

export default function PaymentDetails() {
  const scrollAnchor = useRef<HTMLDivElement>()

  const interviewPrice = useSelector(interviewSelectors.interviewPrice)
  const interviewCurrency = useSelector(interviewSelectors.interviewCurrency)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  useEffect(() => {
    scrollAnchor.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <Container>
      {!isMobile && <StyledTitle>Payment details</StyledTitle>}
      <StyledTextBold>
        Total amount: {interviewCurrency}
        {interviewPrice}
      </StyledTextBold>
      <StyledSmallText>
        You can cancel your order up to 24 hours in advanced free of charge.
      </StyledSmallText>
      <SecuredPaymentTitle>
        <StyledSmallPaymentText>
          Your payment will be secured by our secure server.
        </StyledSmallPaymentText>
      </SecuredPaymentTitle>
      <Elements stripe={promise}>
        <PaymentForm />
      </Elements>
      <div ref={scrollAnchor} />
    </Container>
  )
}
