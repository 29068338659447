export enum OnBoardingStep {
  Reactions = 1,
  Code,
  Notes,
  Profile,
  Report,
}

enum Role {
  Candidate = 'candidate',
  Expert = 'expert',
}

interface OnBoardingMessageContent {
  subtitle?: string
  subtitleAlt?: string
  title?: string
}

interface OnBoardingMessage {
  type: OnBoardingStep
  role: Role[]
  desktop?: OnBoardingMessageContent
  mobile?: OnBoardingMessageContent
}

const OnboardingSteps: OnBoardingMessage[] = [
  {
    type: OnBoardingStep.Reactions,
    role: [Role.Expert],
    desktop: {
      title: 'Share reactions and feedback during the session to empower the report',
      subtitle: 'Your feedback will be shared only after the session ends',
    },
    mobile: {
      title: 'Share emotions and feedback during the session',
      subtitle: 'Your feedback will be shared only after the session ends',
    },
  },
  {
    type: OnBoardingStep.Code,
    role: [Role.Candidate, Role.Expert],
    desktop: {
      subtitle:
        'Our code sharing tool empowers you and the candidate to solve coding problems in real-time with an online code editor and compilers for all popular languages.',
    },
  },
  {
    type: OnBoardingStep.Notes,
    role: [Role.Candidate],
    desktop: {
      subtitle:
        "Capture your thoughts and action items during your session with our note-taking tool. You'll be able to review your notes at anytime after the session in your post-session report.",
    },
    mobile: {
      subtitle:
        "Capture your thoughts and action items during your session with our note-taking tool. You'll be able to review your notes at anytime after the session in your post-session report.",
    },
  },
  {
    type: OnBoardingStep.Profile,
    role: [Role.Candidate],
    desktop: {
      subtitle:
        'Review your expert profile and ask questions and guides related to their unique experience.',
    },
    mobile: {
      subtitle:
        'Review your expert profile and ask questions and guides related to their unique experience.',
    },
  },
  {
    type: OnBoardingStep.Profile,
    role: [Role.Expert],
    desktop: {
      subtitle: 'You can always open the candidate profile during the session from here',
      subtitleAlt: 'View the applicant’s professional profile and session details here',
    },
    mobile: {
      subtitle: 'You can open the candidate profile during the session from here',
      subtitleAlt: 'View the applicant’s professional profile and session details here',
    },
  },
  {
    type: OnBoardingStep.Report,
    role: [Role.Expert],
    desktop: {
      subtitle:
        'Please make sure to click here at the end of the session to fill up the report',
    },
    mobile: {
      subtitle:
        'Please make sure to click here at the end of the session to fill up the report',
    },
  },
]

export interface IOnBoardingStep {
  index: number
  next: OnBoardingStep
  subtitle?: string
  subtitleAlt?: string
  title?: string
  total: number
  type: OnBoardingStep
}

export function getOnboardingSteps(
  isExpert: boolean,
  isMobile: boolean,
  hasCode = false
): IOnBoardingStep[] {
  return OnboardingSteps.filter((s) =>
    s.role.includes(isExpert ? Role.Expert : Role.Candidate)
  )
    .filter((s) => (isMobile ? !!s.mobile : !!s.desktop))
    .filter((s) => hasCode || s.type !== OnBoardingStep.Code)
    .map((s, i, acc) => ({
      index: i + 1,
      next: acc[i + 1]?.type,
      total: acc.length,
      type: s.type,
      ...(isMobile ? s.mobile : s.desktop),
    }))
}
