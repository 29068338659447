import { useSelector } from 'react-redux'
import { conversationSelectors } from 'src/redux'
import { systemSettingsSelectors } from 'src/redux/data/systemSettings'
import { useEffect, useState } from 'react'
import {
  IMetricsData,
  MetricsType,
  MetricsVocabularyItem,
  MetricsVocabularyText,
} from '../types'

const positiveColor = '#00DE9F'
const neturalColor = '#F1BE2B'
const negativeColor = '#F23482'

export const MetricsVocabulary: MetricsVocabularyItem[] = [
  {
    type: MetricsType.Attitude,
    tooltip: `There's power in positivity. A cheerful and optimistic attitude will leave a lasting impression and help you build rapport with your interviewer, a key indicator of a great hire.`,
    minText: {
      result: 'Negative',
      info: `Phrases that have negative connotations such as "I can't" or "I didn't like" suggest that you're not proactive and a go-getter; qualities that hiring teams look for in a candidate. Even if you're well qualified for the position, including these types of phrases will hurt your chances of getting the job.`,
      color: negativeColor,
    },
    avgText: {
      result: 'Neutral',
      info: `Although you showed positivity in some of your responses, there were times where you used negative words. High-performing candidates express very few negative words or phrases when compared to low-performing candidates.`,
      color: neturalColor,
    },
    maxText: {
      result: 'Positive',
      info: `Great job! Your upbeat personality and can-do attitude suggest that you're easy to work with and handle the unexpected well.`,
      color: positiveColor,
    },
  },
  {
    type: MetricsType.WorkplaceCulture,
    tooltip: `Hiring companies will want to know to how well you collaborate with others and contribute to a successful team but need assurance that they can trust you to accomplish tasks with little to no supervision. You'll need to give specific examples of both approaches to show you're capable of working individually and as part of a team.`,
    minText: {
      result: 'Team-orientated',
      info: `You emphasized the needs and accomplishments of the group rather than your individual achievements. Balance your responses by showcasing your own individual professional strengths and successes and using more first-person singular pronouns - e.g. I, me, my, mine, myself.`,
      color: neturalColor,
    },
    avgText: {
      result: 'Independent team player',
      info: `You demonstrated that you're able to own your tasks, take initiative and contribute both as an individual and with others; every hiring manager's dream!`,
      color: positiveColor,
    },
    maxText: {
      result: 'Independent',
      info: `You emphasized your personal contributions and unique qualities which is great but you'll need to show that you're also a team-player by including examples of where you were successful in group projects and using more first person plural pronouns - e.g. we, us, our.`,
      color: neturalColor,
    },
  },
  {
    type: MetricsType.Confidence,
    tooltip: `How you communicate directly affects the way people see you. Make sure to avoid any words or phrases that undermine your value.`,
    minText: {
      result: 'Self-assured',
      info: `You come across as a highly confident individual. By believing in yourself and your abilities, others will too.`,
      color: positiveColor,
    },
    avgText: {
      result: 'Semi-confident',
      info: `Although you scored well in terms of confidence, there were still times where you undervalued your voice by using words or phrases such as "maybe", "just" or "I think".`,
      color: neturalColor,
    },
    maxText: {
      result: 'Hesitant',
      info: `Using words and phrases such as "maybe", "just" or "I think" undermines your confidence and makes you appear unsure of yourself and the valuable skills we know you have.`,
      color: negativeColor,
    },
  },
  {
    type: MetricsType.Experience,
    tooltip: `Being able to communicate your experience from past jobs is key to acing an interview. High performers do this well by providing simple and focused examples of past professional experience.`,
    minText: {
      result: 'Inexperienced',
      info: `In most cases you gave examples of what're currently doing or what you hope to do in the future. Try giving more examples of what you achieved in the past.`,
      color: negativeColor,
    },
    avgText: {
      result: 'Limited experience',
      info: `In some cases you gave examples of what're currently doing or what you hope to do in the future. Try giving more examples of what you achieved in the past.`,
      color: neturalColor,
    },
    maxText: {
      result: 'Experienced',
      info: `Good job! You tell simple, focused stories and detail the facts as they happened showcasing you have the relevant experience and skills needed to make your next career move with confidence.`,
      color: positiveColor,
    },
  },
  {
    type: MetricsType.UseOfData,
    tooltip: `Using data to substantiate your interview responses is often the difference between a mediocre answer and an excellent answer. A good answer should have a balance of enough data but not too much, demonstrating that you are clear on the details but still engaging the listener.`,
    minText: {
      result: 'Too vague',
      info: `Whenever you can, replace adjectives or general information with data. For example, instead of saying "Almost all customers", say "87% of new customers".`,
      color: neturalColor,
    },
    avgText: {
      result: 'Data-driven',
      info: `You did an excellent job at successfully using data to back up and work through your interview responses.`,
      color: positiveColor,
    },
    maxText: {
      result: 'Data-obsessed',
      info: `You love data. We do too. Using too much data, however, can make your responses hard to follow or boring to listen to. Throw in a personal anecdote to make your responses more engaging.`,
      color: neturalColor,
    },
  },
  {
    type: MetricsType.SpeechTempo,
    tooltip: `Speaking slowly is good for introducing new ideas and indicating seriousness, while speaking quickly shows passion and enthusiasm. Engage your interviewer changing up your speech tempo and directing the flow of conversation through pauses.`,
    minText: {
      result: 'Slow pace',
      info: `You did a great job at clearly communicating your message, but be careful to not speak too slowly as this might cause your listener to become impatient and lose interest.`,
      color: neturalColor,
    },
    avgText: {
      result: 'Optimal pace',
      info: `You spoke slowly enough for the listener to understand and quick enough to keep them engaged and interested. Although this sounds easy to do, it's actually quite challenging - well done!`,
      color: positiveColor,
    },
    maxText: {
      result: 'Fast pace',
      info: `Speaking too quickly makes you difficult to follow, and seem nervous. Try slowing down by pausing before answering any questions. When you finish a sentence, count two beats again before continuing. Don't be afraid of silence.`,
      color: neturalColor,
    },
  },
]

const useMetrics = () => {
  const conversation = useSelector(conversationSelectors.data)
  const systemSettings = useSelector(systemSettingsSelectors.data)
  const [metricsData, setMetricsData] = useState<IMetricsData[]>([])

  const {
    attitude: usersSentimentScore,
    confidence: usersConfidentScore,
    data_use: usersdataUsage,
    experience: usersTenseScore,
    speech_tempo: usersWpm,
    workplace_culture: usersFpp,
  } = conversation?.metrics || ({} as any)

  const getMetrics = () => {
    const resMetricsData: IMetricsData[] = []
    let progress = 0
    // Attitude
    const neturalMinAttitudeScore = systemSettings.sentimentPositiveMin
    const neturalMaxAttitudeScore = systemSettings.sentimentNegativeMax
    const attitudeData = MetricsVocabulary.find((v) => v.type === MetricsType.Attitude)
    let attitudeDataText: MetricsVocabularyText = null
    if (usersSentimentScore > neturalMaxAttitudeScore) {
      attitudeDataText = attitudeData.maxText
      progress =
        (usersSentimentScore - neturalMaxAttitudeScore) / (1 - neturalMaxAttitudeScore)
    } else if (usersSentimentScore > neturalMinAttitudeScore) {
      attitudeDataText = attitudeData.avgText
      progress =
        (usersSentimentScore - neturalMinAttitudeScore) /
        (neturalMaxAttitudeScore - neturalMinAttitudeScore)
    } else {
      attitudeDataText = attitudeData.minText
      progress = (usersSentimentScore + 1) / (neturalMinAttitudeScore + 1)
    }
    resMetricsData.push({
      type: MetricsType.Attitude,
      info: attitudeDataText.info,
      result: attitudeDataText.result,
      color: attitudeDataText.color,
      tooltip: attitudeData.tooltip,
      progress,
    })

    // Workplace culture
    const minFpp = systemSettings.singularPronounsMin
    const maxFpp = systemSettings.singularPronounsMax
    const fppData = MetricsVocabulary.find((v) => v.type === MetricsType.WorkplaceCulture)
    let fppDataText: MetricsVocabularyText = null

    if (usersFpp > maxFpp) {
      fppDataText = fppData.maxText
      progress = (usersFpp - maxFpp) / (100 - maxFpp)
    } else if (usersFpp > minFpp) {
      fppDataText = fppData.avgText
      progress = (usersFpp - minFpp) / (maxFpp - minFpp)
    } else {
      fppDataText = fppData.minText
      progress = usersFpp / minFpp
    }
    resMetricsData.push({
      type: MetricsType.WorkplaceCulture,
      info: fppDataText.info,
      result: fppDataText.result,
      color: fppDataText.color,
      tooltip: fppData.tooltip,
      progress,
    })

    // confident
    const minConfidentScore = systemSettings.confidentScoreMin
    const maxConfidentScore = systemSettings.confidentScoreMax
    const lastConfidentScore = 101
    const confidentData = MetricsVocabulary.find((v) => v.type === MetricsType.Confidence)
    let confidentDataText: MetricsVocabularyText = null
    if (usersConfidentScore > maxConfidentScore) {
      confidentDataText = confidentData.maxText
      progress =
        1 -
        (usersConfidentScore - maxConfidentScore) /
          (lastConfidentScore - maxConfidentScore)
    } else if (usersConfidentScore > minConfidentScore) {
      confidentDataText = confidentData.avgText
      progress =
        1 -
        (usersConfidentScore - minConfidentScore) /
          (maxConfidentScore - minConfidentScore)
    } else {
      confidentDataText = confidentData.minText
      progress = 1 - usersConfidentScore / minConfidentScore
    }
    if (progress < 0) progress = 0
    resMetricsData.push({
      type: MetricsType.Confidence,
      info: confidentDataText.info,
      result: confidentDataText.result,
      color: confidentDataText.color,
      tooltip: confidentData.tooltip,
      progress,
    })

    // Experience
    const minTenseScore = systemSettings.pastTenseRangeMin
    const maxTenseScore = systemSettings.pastTenseRangeMax
    const tenseData = MetricsVocabulary.find((v) => v.type === MetricsType.Experience)
    let tenseDataText: MetricsVocabularyText = null
    if (usersTenseScore > maxTenseScore) {
      tenseDataText = tenseData.maxText
      progress = (usersTenseScore - maxTenseScore) / (100 - maxTenseScore)
    } else if (usersTenseScore > minTenseScore) {
      tenseDataText = tenseData.avgText
      progress = (usersTenseScore - minTenseScore) / (maxTenseScore - minTenseScore)
    } else {
      tenseDataText = tenseData.minText
      progress = usersTenseScore / minTenseScore
    }
    resMetricsData.push({
      type: MetricsType.Experience,
      info: tenseDataText.info,
      result: tenseDataText.result,
      color: tenseDataText.color,
      tooltip: tenseData.tooltip,
      progress,
    })

    // Use of data
    const lastDataUsage = 151
    const minDataUsage = systemSettings.dataUsageScoreMin
    const maxDataUsage = systemSettings.dataUsageScoreMax
    const dataUsageDataData = MetricsVocabulary.find(
      (v) => v.type === MetricsType.UseOfData
    )
    let dataUsageDataText: MetricsVocabularyText = null
    if (usersdataUsage > maxDataUsage) {
      dataUsageDataText = dataUsageDataData.maxText
      progress = (usersdataUsage - maxDataUsage) / (lastDataUsage - maxDataUsage)
      if (progress > 1) progress = 1
    } else if (usersdataUsage > minDataUsage) {
      dataUsageDataText = dataUsageDataData.avgText
      progress = (usersdataUsage - minDataUsage) / (maxDataUsage - minDataUsage)
    } else {
      dataUsageDataText = dataUsageDataData.minText
      progress = usersdataUsage / minDataUsage
    }
    resMetricsData.push({
      type: MetricsType.UseOfData,
      info: dataUsageDataText.info,
      result: dataUsageDataText.result,
      color: dataUsageDataText.color,
      tooltip: dataUsageDataData.tooltip,
      progress,
    })

    // Speech tempo
    const lastWpm = 221
    const minWpm = systemSettings.speechSpeedMin
    const maxWpm = systemSettings.speechSpeedMax
    const wpmDataData = MetricsVocabulary.find((v) => v.type === MetricsType.SpeechTempo)
    let wpmDataText: MetricsVocabularyText = null
    if (usersWpm > maxWpm) {
      wpmDataText = wpmDataData.maxText
      progress = (usersWpm - maxWpm) / (lastWpm - maxWpm)
      if (progress > 1) progress = 1
    } else if (usersWpm > minWpm) {
      wpmDataText = wpmDataData.avgText
      progress = (usersWpm - minWpm) / (maxWpm - minWpm)
    } else {
      wpmDataText = wpmDataData.minText
      progress = usersWpm / minWpm
    }
    resMetricsData.push({
      type: MetricsType.SpeechTempo,
      info: wpmDataText.info,
      result: wpmDataText.result,
      color: wpmDataText.color,
      tooltip: wpmDataData.tooltip,
      progress,
    })

    setMetricsData(resMetricsData)
    return resMetricsData
  }

  const getMetricsName = (type: MetricsType) => {
    switch (type) {
      case MetricsType.Attitude:
        return 'Attitude'
      case MetricsType.Confidence:
        return 'Confidence'
      case MetricsType.Experience:
        return 'Experience'
      case MetricsType.SpeechTempo:
        return 'Speech tempo'
      case MetricsType.UseOfData:
        return 'Use of data'
      case MetricsType.WorkplaceCulture:
        return 'Workplace culture'
      default:
        return ''
    }
  }

  useEffect(() => {
    if (conversation && systemSettings) {
      getMetrics()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, systemSettings])

  return { getMetrics, getMetricsName, metricsData }
}

export default useMetrics
