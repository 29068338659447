import { put, takeEvery, call } from 'typed-redux-saga'

import { avaCreateInterview } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'

interface IAvaCreateInterviewAction {
  agencyId: string
  email?: string
  firstName?: string
  lastName?: string
  target?: string
  onCreated?: (token: string) => void
}

const {
  action: avaCreateInterviewAction,
  actionFailure: avaCreateInterviewActionFailure,
  actionSuccess: avaCreateInterviewActionSuccess,
  actionSuccessClear: avaCreateInterviewActionSuccessClear,
  actionFailureClear: avaCreateInterviewActionFailureClear,
  actionType: avaCreateInterviewActionType,
  actionTypeFailure: avaCreateInterviewActionFailureType,
  actionTypeSuccess: avaCreateInterviewActionSuccessType,
  reducer: avaCreateInterviewRequestReducer,
  selectors: avaCreateInterviewSelectors,
} = reduxRequestFactory<IAvaCreateInterviewAction>('avaCreateInterview', 'requests')

export {
  avaCreateInterviewAction,
  avaCreateInterviewActionFailure,
  avaCreateInterviewActionSuccess,
  avaCreateInterviewActionSuccessClear,
  avaCreateInterviewActionFailureClear,
  avaCreateInterviewActionType,
  avaCreateInterviewActionFailureType,
  avaCreateInterviewActionSuccessType,
  avaCreateInterviewRequestReducer,
  avaCreateInterviewSelectors,
}

function* avaCreateInterviewSaga(action: ISagaAction<IAvaCreateInterviewAction>) {
  try {
    const {
      agencyId,
      email,
      firstName: first_name,
      lastName: last_name,
      onCreated,
    } = action.payload
    const token = yield* call(avaCreateInterview, agencyId, {
      email,
      first_name,
      last_name,
    })
    yield put(avaCreateInterviewActionSuccess())
    if (onCreated) {
      onCreated(token)
    }
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      avaCreateInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaCreateInterview() {
  yield takeEvery(avaCreateInterviewActionType, avaCreateInterviewSaga)
}
