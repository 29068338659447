/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'

const MenuBlockContainer = styled.div<{ open: boolean; maxHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ open, maxHeight }) => (open ? maxHeight : 21)}px;
  transition: height 0.2s linear;
  margin-bottom: 15px;
`

const MenuBlockTitle = styled.div<{ canHover?: boolean }>`
  color: #0e0224;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 15px;
  cursor: pointer;
  width: fit-content;

  &:hover {
    color: ${({ canHover }) => (canHover ? '#b202ff' : '#0e0224')};
  }
`

const MenuBlockLink = styled(MenuBlockTitle)`
  font-weight: 300;
  &:hover {
    color: #b202ff;
  }
`

interface IMenuBlockProps {
  title?: string
  link?: string
  items?: { label: string; value: string }[]
  onItemClick?: (item: any) => void
}

const MenuBlock = ({ title, link, items, onItemClick }: IMenuBlockProps) => {
  const [open, setOpen] = useState(false)

  const handleTitleClicked = useCallback(() => {
    if (link) {
      onItemClick({ label: title, value: link })
    }
  }, [link, onItemClick, title])

  return (
    <MenuBlockContainer
      open={open}
      maxHeight={items.length * (21 + 15) + 21}
      onMouseMove={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <MenuBlockTitle canHover={items.length === 0} onClick={handleTitleClicked}>
        {title}
      </MenuBlockTitle>
      {items.map((item, index) => (
        <MenuBlockLink key={index} onClick={() => onItemClick(item)}>
          {item.label}
        </MenuBlockLink>
      ))}
    </MenuBlockContainer>
  )
}

MenuBlock.defaultProps = {
  title: '',
  items: [],
  link: '',
  onItemClick: () => {},
}

export default MenuBlock
