import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { AvaInterviewStatus } from '../../api'
import { Loader } from '../../components'
import {
  IAvaPrompt,
  avaGetInterviewAction,
  avaGetInterviewPromptsAction,
  avaInterviewPromptsSelectors,
  avaInterviewSelectors,
} from '../../redux'

const getPromptColor = (prompt: IAvaPrompt) => {
  if (prompt.is_loading) return '#0000ff20'
  if (prompt.is_invalid) return '#ff000020'
  return '#00800020'
}

const Prompt = styled.div<{ color: string }>`
  margin: 20px;
  border: 1px solid;
  padding: 10px;
  background-color: ${({ color }) => color};
`

const CompactInfoContainer = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  background: #ffffffa0;
  border-radius: 10px;
  margin: 10px 0;
`

const CompactTitle = styled.p`
  padding: 10px;
  margin: 0;
  font-weight: bold;
`

const CompactText = styled.p<{ code: boolean }>`
  padding: 10px;
  margin: 0;
  border-top: 1px solid;
  white-space: ${({ code }) => (code ? 'pre-wrap' : 'pre-line')};
`

const PromptsPage = styled.div`
  display: grid;
  grid-template-rows: 50px calc(100vh - 50px);
`

const CandidateInfo = styled.div`
  background-color: #c746ff;
  display: flex;
  justify-content: space-between;
`

const PromptsContainer = styled.div`
  overflow: auto;
  height: 100%;
`

const CandidateText = styled.p`
  padding: 10px;
  margin: 0;
  font-weight: bold;
`

interface ICompactInfoProps {
  code?: boolean
  name: string
  text: string
}

function CompactInfo({ name, text, code }: ICompactInfoProps) {
  const [open, setOpen] = useState(false)

  return (
    <CompactInfoContainer onClick={() => setOpen(!open)}>
      <CompactTitle>{name}</CompactTitle>
      {open && <CompactText code={code}>{text}</CompactText>}
    </CompactInfoContainer>
  )
}

CompactInfo.defaultProps = {
  code: false,
}

const AvaPromptsContainer = () => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const interview = useSelector(avaInterviewSelectors.data)
  const prompts = useSelector(avaInterviewPromptsSelectors.data)

  useEffect(() => {
    dispatch(avaGetInterviewAction({ interviewToken }))
    dispatch(avaGetInterviewPromptsAction({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!interview || !prompts) return <Loader />

  return (
    <PromptsPage>
      <CandidateInfo>
        <CandidateText>
          {interview.status !== AvaInterviewStatus.INVITED
            ? `${interview.candidate.first_name} ${interview.candidate.last_name} (${interview.candidate.email})`
            : '???'}
        </CandidateText>
        <CandidateText>
          {interview.position?.position || '???'} / {interview.position?.role || '???'}
        </CandidateText>
      </CandidateInfo>
      <PromptsContainer>
        {prompts.map((p, i) => (
          <Prompt key={p.id} color={getPromptColor(p)}>
            <p>
              {`#${i + 1} - ${p.prompt_type} - ${
                !p.is_loading ? `(done in ${p.duration}s)` : '(loading...)'
              } - Attempts: ${p.attempts}`}
            </p>
            {p.prompt.model && (
              <CompactInfo
                name="Prompt Parameters"
                text={`Model: ${p.prompt.model};
                  Mode: Chat;
                  frequency_penalty: ${p.prompt.frequency_penalty};
                  presence_penalty: ${p.prompt.presence_penalty};
                  temperature: ${p.prompt.temperature};
                  top_p: ${p.prompt.top_p};
                  max_tokens: ${p.prompt.max_tokens}
                `}
              />
            )}
            {!!p.usage && (
              <CompactInfo
                name="Token Usage"
                text={`${p.usage.total_tokens} tokens used - ${p.usage.completion_tokens} for completion, ${p.usage.prompt_tokens} for prompt`}
              />
            )}
            {p.prompt.messages && (
              <CompactInfo
                name="Prompt"
                text={p.prompt.messages.map((m) => m.content).join('\n\n')}
              />
            )}
            {p.prompt.functions && (
              <CompactInfo
                name="Functions"
                code
                text={JSON.stringify(p.prompt.functions, undefined, '  ')}
              />
            )}
            {!p.is_loading && p.error && (
              <CompactInfo name="Error" text={`${p.error.code} ${p.error.message}`} />
            )}
            {!p.is_loading && !p.error && p.prompt.messages && (
              <CompactInfo
                name="Response"
                code={!!p.function_call?.arguments}
                text={p.content || p.function_call?.arguments}
              />
            )}
          </Prompt>
        ))}
      </PromptsContainer>
    </PromptsPage>
  )
}

export default AvaPromptsContainer
