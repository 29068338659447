import React, { useState } from 'react'
import styled from '@emotion/styled'

import SvgEndCall from 'src/static/components/SvgEndCall'

import { MobileHeaderButtonContainer } from './BaseButtons'

const Container = styled.div<{ disabled?: boolean; isTransparentDisabled?: boolean }>`
  color: #fff;
  font-family: Rubik;
  letter-spacing: 0;
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ disabled, isTransparentDisabled }) =>
    disabled && !isTransparentDisabled ? '#F6677E' : '#ff092a'};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  height: 60px;
  width: 60px;
  border-radius: 30px;
  @media screen and (max-width: 2500px) {
    height: 50px;
    width: 50px;
    border-radius: 25px;
  }
  @media screen and (max-width: 2000px) {
    height: 36px;
    width: 36px;
    border-radius: 18px;
  }
  @media screen and (max-width: 1200px) {
    height: 28px;
    width: 28px;
    border-radius: 14px;
  }
  @media screen and (max-width: 900px) {
    height: 20px;
    width: 20px;
    border-radius: 10px;
  }
`

const Button = styled.div<{ disabled?: boolean }>`
  align-items: center;
  display: flex;

  border-radius: 18px;
  height: 100%;
  width: 100%;
  justify-content: center;
`

export const ButtonIconContainer = styled.div<{ disabled?: boolean }>`
  display: flex;

  height: 37px;
  width: 37px;
  @media screen and (max-width: 2500px) {
    height: 31px;
    width: 31px;
  }
  @media screen and (max-width: 2000px) {
    height: 25px;
    width: 25px;
  }
  @media screen and (max-width: 1200px) {
    height: 18px;
    width: 18px;
  }
  @media screen and (max-width: 900px) {
    height: 11px;
    width: 11px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`

const HintMessage = styled.div`
  position: absolute;
  top: 80px;
  background: #00000050;
  width: 214px;
  left: -67px;
  font-size: 15px;
  text-align: center;
  padding: 10px 14px;
  line-height: 19.4px;
  border-radius: 40px;
  font-weight: 400;
  z-index: 1000;

  @media screen and (max-width: 2500px) {
    top: 67px;
    width: 183px;
    left: -62px;
    font-size: 13px;
    padding: 9px 14px;
    line-height: 16.4px;
  }
  @media screen and (max-width: 2000px) {
    top: 51px;
    width: 170px;
    left: -65px;
    font-size: 12px;
    padding: 7px 13px;
    line-height: 14.4px;
  }
  @media screen and (max-width: 1200px) {
    top: 39px;
    width: 123px;
    left: -47px;
    font-size: 8px;
    padding: 5px 10px;
    line-height: 12.4px;
  }
  @media screen and (max-width: 900px) {
    top: 26px;
    width: 95px;
    left: -38px;
    font-size: 6px;
    padding: 3px 8px;
    line-height: 9.4px;
  }
`

interface IEndButton extends React.HTMLProps<HTMLDivElement> {
  disabled: boolean
  isTransparentDisabled: boolean
  minutesToCanEnd: number
  onClick: () => void
}

export function EndButton({
  disabled,
  isTransparentDisabled,
  minutesToCanEnd,
  children,
  onClick,
}: IEndButton) {
  const [hintVisible, setHintVisible] = useState(false)

  function onMouseEnter() {
    setHintVisible(true)
    setTimeout(() => setHintVisible(false), 3000)
  }

  return (
    <Container
      disabled={disabled}
      isTransparentDisabled={isTransparentDisabled}
      onMouseEnter={onMouseEnter}
    >
      <Button onClick={!disabled ? onClick : undefined}>
        <ButtonIconContainer>
          <SvgEndCall />
        </ButtonIconContainer>
      </Button>
      {hintVisible && minutesToCanEnd > 0 && (
        <HintMessage>{`You'll only be able to end the session in ${minutesToCanEnd} minutes`}</HintMessage>
      )}
      {children}
    </Container>
  )
}

const MobileButton = styled.button<{
  isDisabled?: boolean
  isTransparentDisabled: boolean
}>`
  align-items: center;
  background: ${({ isDisabled, isTransparentDisabled }) =>
    isDisabled && !isTransparentDisabled ? '#F6677E' : '#FF002A'};
  border: none;
  padding: 0;
  border-radius: 23px;
  display: flex;
  height: 46px;
  justify-content: center;
  width: 46px;

  &:focus {
    outline: none;
  }
`

const MobileHintMessage = styled.div`
  background: #00000050;
  border-radius: 40px;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  left: 50%;
  line-height: 19.4px;
  padding: 10px 14px;
  position: absolute;
  text-align: center;
  top: 70px;
  transform: translate(-50%, 0);
  width: 214px;
`

export function EndButtonMobile({
  disabled,
  minutesToCanEnd,
  isTransparentDisabled,
  onClick,
}: IEndButton) {
  const [hintVisible, setHintVisible] = useState(false)

  function onDisabledClick() {
    setHintVisible(true)
    setTimeout(() => setHintVisible(false), 3000)
  }

  return (
    <MobileHeaderButtonContainer>
      <MobileButton
        isDisabled={disabled}
        isTransparentDisabled={isTransparentDisabled}
        onClick={disabled ? onDisabledClick : onClick}
      >
        <SvgEndCall />
      </MobileButton>
      {hintVisible && minutesToCanEnd > 0 && (
        <MobileHintMessage>{`You'll only be able to end the session in ${minutesToCanEnd} minutes`}</MobileHintMessage>
      )}
    </MobileHeaderButtonContainer>
  )
}
