import React, { useState } from 'react'
import styled from '@emotion/styled'

const themes = {
  filled: {
    primary: {
      color: 'white',
      backgroundColor: '#B202FF',
      borderColor: 'transparent',
    },
    hover: {
      color: 'white',
      backgroundColor: 'rgba(178,2,255,0.5)',
      borderColor: 'transparent',
    },
    active: {
      color: 'white',
      backgroundColor: 'rgba(178,2,255,0.75)',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'white',
      backgroundColor: 'rgba(178,2,255,0.25)',
      borderColor: 'transparent',
    },
  },
  outline: {
    primary: {
      color: '#B202FF',
      backgroundColor: 'white',
      borderColor: '#B202FF',
    },
    hover: {
      color: '#B202FF',
      backgroundColor: 'white',
      borderColor: 'rgba(178,2,255,0.75)',
    },
    active: {
      color: '#7B2E9D',
      backgroundColor: 'white',
      borderColor: '#7B2E9D',
    },
    disabled: {
      color: 'rgba(178,2,255,0.5)',
      backgroundColor: 'white',
      borderColor: '#EDEEF2',
    },
  },
}

const getColor = ({ customTheme, theme, useTheme }: any, prop: string, state: string) => {
  if (theme.agencyColor && useTheme && customTheme === 'outline') {
    if (prop === 'color' || prop === 'borderColor') return theme.agencyColor
    return '#fff'
  }
  if (theme.agencyColor && useTheme) {
    if (prop === 'color') return '#fff'
    return theme.agencyColor
  }

  return themes[customTheme][state][prop]
}

const getOpacity = ({ useTheme, theme }: any, state: string) => {
  if (!useTheme || !theme.agencyColor) return 1

  switch (state) {
    case 'primary':
      return 1
    case 'hover':
      return 0.5
    case 'active':
      return 0.75
    case 'disabled':
      return 0.25
    default:
      return 1
  }
}

interface IButtonProps {
  title: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  // eslint-disable-next-line
  onClick?: any
  className?: string
  customTheme?: 'filled' | 'outline'
  useTheme?: boolean
  options: { label: string; value: any }[]
}

interface IButtonStyledProps {
  active: boolean
  customTheme: 'filled' | 'outline'
  useTheme: boolean
}

// theme ? theme.agencyColor : themes[customTheme].primary.backgroundColor};
const ButtonStyled = styled('button')<IButtonStyledProps>`
  outline: none !important;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => getColor(props, 'borderColor', 'primary')};
  background-color: ${(props) => getColor(props, 'backgroundColor', 'primary')};
  color: ${(props) => getColor(props, 'color', 'primary')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: ${({ active }) => (active ? '0px' : '4px')};
  border-bottom-right-radius: ${({ active }) => (active ? '0px' : '4px')};
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  max-width: 400px;
  height: 56px;
  margin-bottom: 20px;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => getColor(props, 'backgroundColor', 'hover')};
    border-color: ${(props) => getColor(props, 'borderColor', 'hover')};
    color: ${(props) => getColor(props, 'color', 'hover')};
    opacity: ${(props) => getOpacity(props, 'hover')};
  }

  &:active {
    background-color: ${(props) => getColor(props, 'backgroundColor', 'active')};
    border-color: ${(props) => getColor(props, 'borderColor', 'active')};
    color: ${(props) => getColor(props, 'color', 'active')};
    opacity: ${(props) => getOpacity(props, 'active')};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => getColor(props, 'backgroundColor', 'disabled')};
    border-color: ${(props) => getColor(props, 'borderColor', 'disabled')};
    color: ${(props) => getColor(props, 'color', 'disabled')};
    opacity: ${(props) => getOpacity(props, 'disabled')};
  }
`

const DropdownContainer = styled.div<IButtonStyledProps>`
  background-color: ${(props) => getColor(props, 'backgroundColor', 'primary')};
  border: 1px solid;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: ${(props) => getColor(props, 'borderColor', 'primary')};
  color: ${(props) => getColor(props, 'color', 'primary')};
  position: absolute;
  left: -1px;
  top: 100%;
  width: 100%;
`
const DropdownItem = styled.div<any>`
  border-top: 1px solid;
  border-color: ${(props) => getColor(props, 'borderColor', 'primary')};
  padding: 5px 0;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => getColor(props, 'backgroundColor', 'hover')};
    border-color: ${(props) => getColor(props, 'borderColor', 'hover')};
    color: ${(props) => getColor(props, 'color', 'hover')};
    opacity: ${(props) => getOpacity(props, 'hover')};
  }

  &:active {
    background-color: ${(props) => getColor(props, 'backgroundColor', 'active')};
    border-color: ${(props) => getColor(props, 'borderColor', 'active')};
    color: ${(props) => getColor(props, 'color', 'active')};
    opacity: ${(props) => getOpacity(props, 'active')};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => getColor(props, 'backgroundColor', 'disabled')};
    border-color: ${(props) => getColor(props, 'borderColor', 'disabled')};
    color: ${(props) => getColor(props, 'color', 'disabled')};
    opacity: ${(props) => getOpacity(props, 'disabled')};
  }
`

const DropdownButton = React.forwardRef((props: IButtonProps, ref: any) => {
  const [active, setActive] = useState(false)
  const {
    type,
    disabled,
    title,
    onClick,
    className,
    customTheme,
    useTheme,
    options,
  } = props

  const clickHandler = () => setActive(!active)
  const handleSelect = (e, value) => {
    e.stopPropagation()
    onClick?.(value)
    setActive(false)
  }

  return (
    <ButtonStyled
      active={active}
      useTheme={useTheme}
      className={className}
      onClick={clickHandler}
      disabled={disabled}
      type={type}
      ref={ref}
      customTheme={customTheme || 'filled'}
    >
      {title}
      {active && (
        <DropdownContainer
          active={active}
          customTheme={customTheme || 'filled'}
          useTheme={useTheme}
        >
          {options.map((o) => (
            <DropdownItem
              key={o.value}
              customTheme={customTheme || 'filled'}
              useTheme={useTheme}
              onClick={(e) => handleSelect(e, o.value)}
            >
              {o.label}
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </ButtonStyled>
  )
})

DropdownButton.defaultProps = {
  useTheme: false,
  disabled: false,
  type: 'submit',
  className: '',
  customTheme: 'filled',
}

export default DropdownButton
