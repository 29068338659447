/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import styled from '@emotion/styled'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  width: 100%;
`

interface IProps {
  onFileDrop: (any) => void
  children: JSX.Element
}

export default function DropContainer({ onFileDrop, children }: IProps) {
  const dropRef = React.useRef()
  const [dragging, setDragging] = useState(false)
  const [dragCounter, setDragCounter, dragCounterRef] = useState(0)

  function handleDrag(e) {
    e.preventDefault()
    e.stopPropagation()
  }
  function handleDragIn(e) {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter(dragCounterRef.current + 1)
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true)
    }
  }
  function handleDragOut(e) {
    e.preventDefault()
    e.stopPropagation()
    setDragCounter(dragCounterRef.current - 1)
    if (dragCounterRef.current > 0) return
    setDragging(false)
  }
  function handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onFileDrop(e.dataTransfer.files[0])
      e.dataTransfer.clearData()
      setDragCounter(0)
    }
  }

  useEffect(() => {
    const div = dropRef.current as HTMLDivElement
    div.addEventListener('dragenter', handleDragIn)
    div.addEventListener('dragleave', handleDragOut)
    div.addEventListener('dragover', handleDrag)
    div.addEventListener('drop', handleDrop)

    return () => {
      div.removeEventListener('dragenter', handleDragIn)
      div.removeEventListener('dragleave', handleDragOut)
      div.removeEventListener('dragover', handleDrag)
      div.removeEventListener('drop', handleDrop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Container ref={dropRef}>{children}</Container>
}
