import React from 'react'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { DatePicker, Input, Text } from '../../components'
import * as interviewSelectors from '../selectors'

export const SearchPeriodContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -11px 0 20px;
`

export const SearchPeriodIconContainer = styled.div`
  width: 24px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 15px;
`

export const SearchPeriodIcon = styled.svg`
  width: auto;
  height: 100%;
  transform: rotate(180deg);
  vertical-align: unset;
`

export const SearchPeriodText = styled(Text)`
  line-height: 19px;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`

interface IPositionDetailsForm {
  errors: any
  profile: any
  control: any
  interview: any
  fieldsWereFilled: boolean
  inSearchPeriod: boolean
}

const PositionDetailsForm = ({
  errors,
  profile,
  control,
  interview,
  fieldsWereFilled,
  inSearchPeriod,
}: IPositionDetailsForm) => {
  const agencyMainColor = useSelector(interviewSelectors.agencyMainColor)

  return (
    <>
      <FlexRow>
        <Controller
          as={Input}
          label="First Name"
          name="firstName"
          control={control}
          error={errors.firstName}
          useTheme
          rules={{ required: true }}
          defaultValue={profile.first_name}
        />
        <Controller
          as={Input}
          label="Last Name"
          name="lastName"
          control={control}
          error={errors.lastName}
          useTheme
          rules={{ required: true }}
          defaultValue={profile.last_name}
        />
      </FlexRow>

      <Controller
        as={Input}
        label="Email"
        name="email"
        control={control}
        error={errors.email}
        disableWhitespaces
        useTheme
        rules={{ required: true }}
        defaultValue={profile.email}
      />

      {!!interview.desired_position && (
        <Controller
          as={Input}
          label="Desired Position"
          name="desiredPosition"
          control={control}
          error={errors.desiredPosition}
          useTheme
          rules={{ required: true }}
          defaultValue={interview.desired_position}
        />
      )}

      {!!interview.programming_language && (
        <Controller
          as={Input}
          label="Programming language"
          name="programmingLanguage"
          control={control}
          error={errors.programmingLanguage}
          useTheme
          rules={{ required: false }}
          defaultValue={interview.programming_language}
        />
      )}
      {!!interview.position_company && (
        <Controller
          as={Input}
          label="Company"
          name="company"
          control={control}
          error={errors.company}
          useTheme
          rules={{ required: true }}
          defaultValue={interview.position_company}
        />
      )}

      {!!interview.link_to_position && (
        <Controller
          as={Input}
          label="Link to Position"
          name="link_to_position"
          control={control}
          error={errors.link_to_position}
          disableWhitespaces
          useTheme
          optional
          rules={{ required: false }}
          defaultValue={interview.link_to_position}
        />
      )}

      {interview.interview_date && (
        <Controller
          render={({ onChange, value }) => (
            <DatePicker
              label="Job Interview Date"
              onChange={onChange}
              value={value}
              useTheme
              shouldCloseOnSelect
              optional
            />
          )}
          name="date"
          optional
          control={control}
          defaultValue={
            interview.interview_date ? new Date(interview.interview_date) : ''
          }
        />
      )}

      {interview.interview_date && fieldsWereFilled && inSearchPeriod && (
        <SearchPeriodContainer>
          <SearchPeriodIconContainer>
            <SearchPeriodIcon
              version="1.1"
              fill={agencyMainColor || '#B202FF'}
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              // style="enable-background:new 0 0 512 512;"
            >
              <g>
                <g>
                  <path
                    d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
                    C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
                    c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877
                    C276,137.831,267.046,128.877,256,128.877z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <circle cx="256" cy="349.16" r="27" />
                </g>
              </g>
            </SearchPeriodIcon>
          </SearchPeriodIconContainer>
          <SearchPeriodText>
            Finding an expert at such short notice will be challenging, but we promise to
            do our best
          </SearchPeriodText>
        </SearchPeriodContainer>
      )}
    </>
  )
}

export default PositionDetailsForm
