import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import DonutChart from 'react-svg-donut-chart'
import { getProgressColor } from 'src/ava/services/reportHelper'
import TopBlockTip from './TopBlockTip'

const Container = styled.div<{ useHint: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 54px;
  width: 60px;
  cursor: ${({ useHint }) => (useHint ? 'pointer' : 'default')};

  @media screen and (max-width: 1280px) {
    min-height: 54px;
    min-width: 60px;
  }
`

const ValueContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #3b424f;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
`

const ValueSpan = styled.span`
  color: #353c5a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
`

interface IProps {
  value: number
  maxValue: number
  useHint?: boolean
  hintText?: string
}

export default function CircleProgressSmall({
  maxValue,
  value,
  useHint,
  hintText,
}: IProps) {
  const [showHint, setShowHint] = useState(false)
  const [id, setId] = useState(0)
  const step = 100 / maxValue
  const color = getProgressColor(value / maxValue)

  const handleMouseOver = () => {
    if (useHint) {
      setShowHint(true)
    }
  }

  const handleMouseLeave = () => {
    if (useHint) {
      setShowHint(false)
    }
  }

  useEffect(() => {
    const rnd = Math.floor(Math.random() * 99999)
    setId(rnd)
  }, [])

  return (
    <Container
      useHint={useHint}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      id={id.toString()}
    >
      <DonutChart
        data={[
          { value: step * value, stroke: color, strokeWidth: 3 },
          { value: 100 - step * value, stroke: '#E1E3EA', strokeWidth: 3 },
        ]}
        style={{
          transform: 'rotate(90deg) scale(-1,1)',
          flex: '1',
        }}
      />
      <ValueContainer>
        {value}
        <ValueSpan>/10</ValueSpan>
      </ValueContainer>
      <TopBlockTip text={hintText} id={id.toString()} show={showHint} />
    </Container>
  )
}

CircleProgressSmall.defaultProps = {
  useHint: false,
  hintText: 'set hintText',
}
