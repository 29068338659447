import useStateRef from 'react-usestateref'

type NetworkQuality = 0 | 1 | 2 | 3 | 4 | 5 | 6

const getNetworkQuality = (quality: NetworkQuality) => {
  switch (quality) {
    case 1:
      return 'Excellent'
    case 2:
      return 'Good'
    case 3:
      return 'Average'
    case 4:
      return 'Poor'
    case 5:
      return 'Bad'
    case 6:
      return 'None'
    default:
      return 'Unknown'
  }
}

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Network]',
  'color:#1e133b',
]

const useNetworkQuality = (label: string) => {
  const [up, setUp, upRef] = useStateRef<NetworkQuality>()
  const [down, setDown, downRef] = useStateRef<NetworkQuality>()

  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const updateNetworkQuality = (
    upQuality: NetworkQuality,
    downQuality: NetworkQuality
  ) => {
    if (upRef.current !== upQuality || downRef.current !== downQuality) {
      setUp(upQuality)
      setDown(downQuality)
      log(
        `${label} quality: ${getNetworkQuality(upQuality)} uplink, ${getNetworkQuality(
          downQuality
        )} downlink`
      )
    }
  }

  return {
    down,
    up,
    updateNetworkQuality,
  }
}

export default useNetworkQuality
