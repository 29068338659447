import React from 'react'
import { Switch, Route, HashRouter } from 'react-router-dom'

import { NotFound } from '../components'
import VideoCallContainer from './Layout'

function RecordCall() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/record/:interviewToken">
          <VideoCallContainer />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </HashRouter>
  )
}

export default RecordCall
