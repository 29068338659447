import React from 'react'
import styled from '@emotion/styled'

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
`

export const MainContent = styled.div<{ hasJoined: boolean; hasSidebar: boolean }>`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: ${({ hasSidebar }) => (hasSidebar ? '0 0.5rem' : '0')};
  overflow: hidden;
  padding: ${({ hasJoined }) => (hasJoined ? '0' : '5vh 0')};
  position: relative;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
`

export function Main({ children }: React.HTMLProps<HTMLDivElement>) {
  return <MainContainer>{children}</MainContainer>
}

interface IMainMobile extends React.HTMLProps<HTMLDivElement> {
  blur: boolean
  isExpert: boolean
  toggleFullscreen: (e: any) => void
}

const MobileContainer = styled.div<{
  blur: boolean
  isExpert: boolean
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: main;
  justify-content: 'center';
  position: relative;
  backdrop-filter: ${({ blur }) => (blur ? 'blur(18px)' : 'none')};

  @media (orientation: landscape) {
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    padding: ${({ isExpert }) => (!isExpert ? '0px 8px' : '0px 8px')};
    width: calc(100vw - 16px);
  }

  video {
    border-radius: 7px;
  }
`

const MainBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
`

export function MainMobile({ blur, children, isExpert, toggleFullscreen }: IMainMobile) {
  return (
    <MobileContainer blur={blur} isExpert={isExpert} onClick={toggleFullscreen}>
      {children}
      {blur && <MainBlur />}
    </MobileContainer>
  )
}
