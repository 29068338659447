/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'

const Container = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
`

const DetailsContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 0px 14px -3px lightgrey;
  border-radius: 8px;
`

const DetailsHeader = styled.div`
  display: flex;
  height: 80px;
  padding: 8px 15px;
  border-bottom: 1px solid #edeef2;
`

const ProfilePhoto = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`

const HeaderDataContainer = styled.div<{ centered?: boolean }>`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ApplicantName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #3b424f;
`

const DetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px 15px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`

const DetailsTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(115, 121, 145, 0.8);
  margin: 18px 0 8px;
`

const DetailsText = styled.div<{ zeroMargin?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-bottom: ${(props) => (props.zeroMargin ? 0 : 6)}px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }

  > p {
    margin-bottom: 0;
  }
`

function AvailabilityExpertInfo() {
  const interviewDetails = useSelector(interviewSelectors.interview)

  const peerFullName = `${interviewDetails.peer.first_name} ${interviewDetails.peer.last_name}`

  return (
    <Container>
      <DetailsContainer>
        <DetailsHeader>
          <ProfilePhoto src={interviewDetails.peer.logo.url} />
          <HeaderDataContainer>
            <ApplicantName>{peerFullName}</ApplicantName>
            <DetailsText zeroMargin>{interviewDetails.peer.current_position}</DetailsText>
          </HeaderDataContainer>
        </DetailsHeader>
        <DetailsInfoContainer>
          {interviewDetails.peer.about && (
            <>
              <DetailsText zeroMargin>{interviewDetails.peer.about}</DetailsText>
            </>
          )}
          <DetailsTitle>Experience</DetailsTitle>
          {interviewDetails.peer.experiences.map((exp, index) => (
            <DetailsText zeroMargin key={index}>
              {exp}
            </DetailsText>
          ))}
          <DetailsTitle>Education</DetailsTitle>
          {interviewDetails.peer.educations.map((exp, index) => (
            <DetailsText zeroMargin key={index}>
              {exp}
            </DetailsText>
          ))}
        </DetailsInfoContainer>
      </DetailsContainer>
    </Container>
  )
}

export default AvailabilityExpertInfo
