import React, { useState } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40%;
  background: #f5f6f8;
  border-radius: 4px;
  padding: 4px 12px;
  margin: 8px 0;
  height: calc(100% - 16px);
`

const FeedbackInput = styled.input`
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: black;
  outline: none;
  width: 100%;
  flex: 1;
  background: transparent;

  &:placeholder {
    color: rgb(115, 121, 145);
  }
`

const StyledButton = styled.div<{ disabled?: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 145.19%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 7px 20px;
  background: #b202ff;
  border-radius: 4px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 12px;
  cursor: pointer;
  background: ${({ disabled }) => (disabled ? '#898A8D' : '#b202ff')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`

export default function TextFeedback({ sendNote }: { sendNote: (text: string) => void }) {
  const [text, setText] = useState('')

  return (
    <Container>
      <FeedbackInput
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            sendNote(text)
            setText('')
          }
        }}
        placeholder="Write your feedback…"
      />
      <StyledButton
        disabled={!text}
        onClick={() => {
          sendNote(text)
          setText('')
        }}
      >
        Add
      </StyledButton>
    </Container>
  )
}
