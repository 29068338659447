import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  position: absolute;
  width: 350px;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 30px;
  background-color: #1d0e3b;
  border: solid 2px #b202ff;
  border-radius: 10px;

  transform: translate(-50%, -50%);
  left: 50vw;
  top: 50vh;
`

const Text = styled.div`
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
`

const Button = styled.button<{ color: string }>`
  width: 132px;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 15px;
  background-color: ${(props) => props.color};
  border-radius: 10px;
  border: none;
  padding: 3px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 28px;
  width: 100%;
`

interface IProps {
  userName: string
  onCancel: () => void
  onShare: () => void
}

const InterruptSharinPopup = ({ userName, onCancel, onShare }: IProps) => {
  return (
    <Container>
      <Text>{`This will let you take over ${userName} as the main presenter`}</Text>
      <ButtonsContainer>
        <Button color="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="#b202ff" onClick={onShare}>
          Share now
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export default InterruptSharinPopup
