import React from 'react'

function SvgPrice({ fill }: { fill: string }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0793 0.32389C16.8535 0.0983355 16.4876 0.0983355 16.2618 0.32389L15.2322 1.35351C15.1354 1.32654 15.0356 1.31228 14.9352 1.31109H9.0853C8.77858 1.31136 8.48452 1.43342 8.26777 1.65044L0.586134 9.32822C0.135026 9.77989 0.135026 10.5116 0.586134 10.9633L6.43606 16.8132C6.88773 17.2643 7.61943 17.2643 8.07111 16.8132L15.7489 9.13541C15.9645 8.91803 16.0851 8.62405 16.0844 8.31788V2.46796C16.0832 2.36756 16.0689 2.26775 16.042 2.17103L17.0716 1.14141C17.2993 0.917712 17.3027 0.551847 17.0793 0.32389ZM14.9314 8.31788L7.25358 15.9957L1.40366 10.1457L9.0853 2.46796H14.1177L12.3515 4.23412C12.0796 4.08925 11.7766 4.0125 11.4685 4.01046C10.4036 4.01046 9.54033 4.87371 9.54033 5.93858C9.54033 7.00345 10.4036 7.8667 11.4685 7.8667C12.5333 7.8667 13.3966 7.00345 13.3966 5.93858C13.3934 5.63014 13.3153 5.3271 13.1691 5.0555L14.9352 3.28934L14.9314 8.31788ZM12.232 5.93858C12.232 6.36453 11.8867 6.70983 11.4607 6.70983C11.0348 6.70983 10.6895 6.36453 10.6895 5.93858C10.6895 5.51263 11.0348 5.16733 11.4607 5.16733C11.666 5.16631 11.8631 5.24711 12.0086 5.39186C12.1541 5.53661 12.2359 5.73336 12.2358 5.93858H12.232Z"
        fill={fill || '#6938AA'}
      />
    </svg>
  )
}

export default SvgPrice
