import { combineReducers, createReducer } from '@reduxjs/toolkit'
import { IAdminInterview, IAdminStats, IAgency, IAgencyStats } from '../api'
import { IDefaultState } from '../utils/redux'
import {
  getAgencies,
  getAgenciesSuccess,
  getAgenciesFailure,
  getInterviews,
  getInterviewsSuccess,
  getInterviewsFailure,
  getStats,
  getStatsFailure,
  getStatsSuccess,
} from './actions'
import {
  IGetAgenciesSuccessAction,
  IGetAgenciesFailureAction,
  IGetInterviewsSuccessAction,
  IGetInterviewsFailureAction,
  IGetStatsSuccessAction,
  IGetStatsFailureAction,
} from './types'

interface IAdminAgenciesState extends IDefaultState {
  agencies: IAgency[]
}

interface IAdminStatsState extends IDefaultState {
  stats: IAdminStats | IAgencyStats
}

interface IAdminInterviewsState extends IDefaultState {
  interviews: IAdminInterview[]
  pages: number
}

const agenciesInitialState: IAdminAgenciesState = {
  agencies: [],
  isLoading: false,
  error: '',
}

const interviewsInitialState: IAdminInterviewsState = {
  interviews: [],
  pages: 1,
  isLoading: false,
  error: '',
}

const statsInitialState: IAdminStatsState = {
  isLoading: false,
  success: false,
  error: '',
  stats: {},
}

const agenciesReducer = createReducer(agenciesInitialState, {
  [getAgencies.type]: (state: IAdminAgenciesState) => ({
    ...state,
    isLoading: true,
    errors: '',
    agencies: [],
  }),
  [getAgenciesSuccess.type]: (
    state: IAdminAgenciesState,
    action: IGetAgenciesSuccessAction
  ) => ({
    ...state,
    isLoading: false,
    error: '',
    agencies: action.payload.agencies,
  }),
  [getAgenciesFailure.type]: (
    state: IAdminAgenciesState,
    action: IGetAgenciesFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    agencies: [],
  }),
})

const interviewsReducer = createReducer(interviewsInitialState, {
  [getInterviews.type]: (state: IAdminInterviewsState) => ({
    ...state,
    isLoading: true,
    errors: '',
    interviews: [],
    pages: 1,
  }),
  [getInterviewsSuccess.type]: (
    state: IAdminInterviewsState,
    action: IGetInterviewsSuccessAction
  ) => ({
    ...state,
    isLoading: false,
    error: '',
    interviews: action.payload.interviews,
    pages: action.payload.pages,
  }),
  [getInterviewsFailure.type]: (
    state: IAdminInterviewsState,
    action: IGetInterviewsFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    interviews: [],
    pages: 1,
  }),
})

const statsReducer = createReducer(statsInitialState, {
  [getStats.type]: (state: IAdminStatsState) => ({
    ...state,
    isLoading: true,
    error: '',
    stats: {},
  }),
  [getStatsSuccess.type]: (state: IAdminStatsState, action: IGetStatsSuccessAction) => ({
    ...state,
    isLoading: false,
    error: '',
    stats: action.payload.stats,
  }),
  [getStatsFailure.type]: (state: IAdminStatsState, action: IGetStatsFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    stats: {},
  }),
})

export default combineReducers({
  agencies: agenciesReducer,
  interviews: interviewsReducer,
  stats: statsReducer,
})
