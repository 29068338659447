import styled from '@emotion/styled'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

import { interview as agencyInterviewSelector } from '../selectors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
`

const ReschedulingList = styled.div<{ warning: boolean }>`
  display: flex;
  flex-direction: column;

  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid #eee;

  ${({ warning }) => warning && 'p {color: red;}'};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const ReschedulingRequest = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding: 10px 0;

  &:last-of-type {
    border-bottom: none;
  }
`

const ReschedulingInfo = () => {
  const interview = useSelector(agencyInterviewSelector.interview)
  const rescheduleRequests = interview.interview.reschedule_requests || []
  if (!rescheduleRequests.length) return null

  return (
    <Container>
      <Title>History</Title>
      <ReschedulingList warning={rescheduleRequests.length > 1}>
        <List>
          {[...rescheduleRequests].reverse().map((r) => (
            <ReschedulingRequest key={r.id}>
              <p>{moment(r.created_at).utc().format('DD/MM/YY, HH:mm')} UTC</p>
              <p>Created by: {r.created_by}</p>
              <p>Status: {r.status}</p>
              {r.offset_time && (
                <p>
                  {r.offset_time} {r.offset < 0 ? 'before' : 'after'} coaching time
                </p>
              )}
              <p>{r.use_same_expert ? 'Use same expert' : 'Use different expert'}</p>
              {r.expert_ids.length ? (
                <p>
                  (
                  {r.expert_ids
                    .map((e) => {
                      const expert = interview.expert_profiles.find((ep) => ep.id === e)
                      return expert
                        ? `${expert.first_name} ${expert.last_name}`
                        : 'Deleted Expert'
                    })
                    .join(', ')}
                  )
                </p>
              ) : undefined}
              {r.comment && <p>Comment: {r.comment}</p>}
            </ReschedulingRequest>
          ))}
        </List>
      </ReschedulingList>
    </Container>
  )
}

export default ReschedulingInfo
