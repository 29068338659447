import React, { useEffect, useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import TextField from '@material-ui/core/TextField'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import styled from '@emotion/styled'

import { IOpsManager, OpsManagers } from '../../api'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon htmlColor="#b202ff" fontSize="small" />

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
`

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 600px;
  background: white;
  padding: 16px;
  border-radius: 8px;
`

const ItemsContainer = styled.div`
  overflow-y: auto;
  margin-top: 12px;
  flex: 1;
`

const Item = styled.div`
  cursor: pointer;
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 12px;
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: end;
  border-top: 1px solid lightgray;
  margin-top: 12px;
  padding-top: 12px;
`

const LinkButton = styled.div<{ fill?: boolean }>`
  cursor: pointer;
  font-size: 12px;
  color: #b202ff;
  margin-right: 12px;
  text-decoration: underline;
`

const Button = styled.div<{ fill?: boolean }>`
  cursor: pointer;
  padding: 6px 18px;
  margin: 0 6px;
  background: ${(props) => (props.fill ? '#b202ff' : 'transparent')};
  border: 1px solid ${(props) => (props.fill ? 'transparent' : '#b202ff')};
  color: ${(props) => (props.fill ? 'white' : '#b202ff')};
  border-radius: 4px;
`

interface IAgencyItem {
  id: string
  label: string
}

interface IProps {
  excludedOps: string[]
  onChange: (agencyIds: string[]) => void
  onClose: () => void
}

const DefaultOpsManager = { name: 'Unassigned', email: 'none' }

const FilterOpsComponent = (props: IProps) => {
  const { excludedOps, onChange, onClose } = props

  const [excludedItems, setExcludedItems] = useState<string[]>(excludedOps)
  const [filteredItems, setFilteredItems] = useState<IOpsManager[]>([])
  const [filter, setFilter] = useState('')

  function filterTextChanged(e) {
    setFilter(e.target.value)
  }

  function handleChildClick(e) {
    e.stopPropagation()
  }

  function toggleItem(item: IOpsManager) {
    const selectedCopy = [...excludedItems]
    if (excludedItems.includes(item.email)) {
      const indexOf = selectedCopy.findIndex((i) => i === item.email)
      selectedCopy.splice(indexOf, 1)
    } else {
      selectedCopy.push(item.email)
    }
    setExcludedItems(selectedCopy)
  }

  function includeAll() {
    setExcludedItems([])
  }

  function excludeAll() {
    setExcludedItems(OpsManagers.map((a) => a.email))
  }

  function onSave() {
    onChange(excludedItems)
    onClose()
  }

  useEffect(() => {
    if (filter === '') {
      setFilteredItems([DefaultOpsManager, ...OpsManagers])
    } else {
      setFilteredItems(OpsManagers.filter((i) => i.email.includes(filter)))
    }
  }, [filter])

  return (
    <StyledWrapper onClick={onClose}>
      <Panel onClick={handleChildClick}>
        <TopWrapper>
          <LinkButton onClick={includeAll}>Select all</LinkButton>
          <LinkButton onClick={excludeAll}>Clear</LinkButton>
        </TopWrapper>
        <TextField variant="outlined" label="Filter" onChange={filterTextChanged} />
        <ItemsContainer>
          {filteredItems.map((item) => (
            <Item key={item.email} onClick={() => toggleItem(item)}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={!excludedItems.includes(item.email)}
              />
              {item.name}
            </Item>
          ))}
        </ItemsContainer>
        <BottomWrapper>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button fill onClick={onSave}>
            OK
          </Button>
        </BottomWrapper>
      </Panel>
    </StyledWrapper>
  )
}

export default FilterOpsComponent
