import React from 'react'

interface SvgProps {
  fill?: string
}

function SvgInfo({ fill }: SvgProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18.5714C5.26613 18.5714 1.42857 14.7339 1.42857 10C1.42857 5.26613 5.26613 1.42857 10 1.42857C14.7339 1.42857 18.5714 5.26613 18.5714 10C18.5714 14.7339 14.7339 18.5714 10 18.5714ZM10.2381 6.66667C10.6326 6.66667 10.9524 6.34687 10.9524 5.95238C10.9524 5.55789 10.6326 5.2381 10.2381 5.2381C9.84361 5.2381 9.52381 5.55789 9.52381 5.95238C9.52381 6.34687 9.84361 6.66667 10.2381 6.66667ZM10.2381 15.9524C10.6326 15.9524 10.9524 15.6326 10.9524 15.2381V8.57143C10.9524 8.17694 10.6326 7.85714 10.2381 7.85714C9.84361 7.85714 9.52381 8.17694 9.52381 8.57143V15.2381C9.52381 15.6326 9.84361 15.9524 10.2381 15.9524Z"
        fill={fill}
      />
    </svg>
  )
}

SvgInfo.defaultProps = {
  fill: '#B202FF',
}

export default SvgInfo
