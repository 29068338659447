import React from 'react'
import styled from '@emotion/styled'
import {
  Container,
  InputStyled,
  LabelsContainer,
  Label,
  OptionalLabel,
} from './input-styles'
import calendar from '../static/calendar.svg'

const InputStyledWithBorder = styled(InputStyled)`
  background-image: url(${calendar});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center right 10px;
  &:focus {
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, useTheme }: any) => {
      const borderColor = theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
      return borderColor
    }};
  }
`

const Input = React.forwardRef((props: any, ref: any) => {
  const {
    name,
    value,
    disabled,
    optional,
    onClick,
    autoFocus,
    useTheme,
    customLabel,
  } = props
  const label = 'Job Interview Date'

  return (
    <Container disabled={disabled} hasError={false}>
      <InputStyledWithBorder
        autoFocus={autoFocus}
        isSelected={!!value}
        disabled={disabled}
        useTheme={useTheme}
        name={name}
        value={value}
        ref={ref}
        readOnly
        onClick={onClick}
        hasError={false}
      />
      <LabelsContainer>
        <Label>{customLabel || label}</Label>
        {optional && <OptionalLabel>Optional</OptionalLabel>}
      </LabelsContainer>
    </Container>
  )
})

Input.defaultProps = {
  type: 'text',
  optional: false,
  useTheme: false,
}

export default Input
