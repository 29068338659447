import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  InputStyled,
  LabelsContainer,
  Label,
  OptionalLabel,
  Error,
} from './input-styles'

const ContainerStyled = styled(Container)`
  height: auto;
`

interface ILabelsContainer {
  isSelected: boolean
}

const LabelsContainerStyled = styled(LabelsContainer)<ILabelsContainer>`
  pointer-events: none;
  position: absolute;
  left: 6px;
  top: 6px;
  margin-top: -10px;
  margin: 0;
  width: calc(100% - 12px);
  padding: 0 10px;
  z-index: 100;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  background-color: transparent;
`

const TextAreaContainer = styled.div<{ labelHeight: number }>`
  padding-top: ${({ labelHeight }) => `${labelHeight}px`};
`

const TextareaStyledComponent = styled(InputStyled)<any>`
  font-family: 'Rubik';
  resize: none;

  padding-top: 5px;

  ::placeholder {
    color: #8992a3;
    font-family: Rubik;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 16px;
    font-weight: 400;
  }

  & + div > label {
    transform: none;
    line-height: 19px;
    /* height: auto; */
    padding: 4px 0;
  }

  &:focus + div > label {
    transform: none;
    line-height: 17px;
  }
`.withComponent('textarea')

const ErrorLabel = styled(Error)`
  margin: 10px;
  height: auto;
`

const Textarea = React.forwardRef((props: any, ref: any) => {
  const [labelHeight, setLabelHeight] = useState(0)
  const labelRef = useRef(null)
  const {
    name,
    error,
    onChange,
    onBlur,
    value,
    disabled,
    label,
    optional,
    placeholder,
    customTheme,
    useTheme,
    rows = 5,
  } = props
  const hasError = !!error?.type

  useEffect(() => {
    setLabelHeight(labelRef.current?.clientHeight || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelRef.current])

  return (
    <ContainerStyled disabled={disabled} hasError={hasError}>
      <TextAreaContainer labelHeight={labelHeight}>
        <TextareaStyledComponent
          useTheme={useTheme}
          rows={rows}
          disabled={disabled}
          onBlur={onBlur}
          name={name}
          customTheme={customTheme}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
          placeholder={placeholder}
          hasError={hasError}
          isSelected={!!value}
          value={value || ''}
        />
      </TextAreaContainer>
      <LabelsContainerStyled ref={labelRef} isSelected={!!value}>
        <Label>{label}</Label>
        {optional && <OptionalLabel>Optional</OptionalLabel>}
      </LabelsContainerStyled>
      {hasError && error!.type !== 'required' && <ErrorLabel>{error.message}</ErrorLabel>}
      {hasError && error!.type === 'required' && <ErrorLabel>Required</ErrorLabel>}
    </ContainerStyled>
  )
})

export default Textarea
