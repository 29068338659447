import React, { useCallback } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const LabelLink = styled.a`
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
`

const Label = styled.p`
  cursor: default;
  margin-left: 5px;
  margin-right: 5px;
`

interface IProps {
  current: number
  pages: number
  onSelect: (page: number) => void
}

const PaginationFooter = ({ current, pages, onSelect }: IProps) => {
  const toStart = useCallback(() => {
    onSelect(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toPrev = useCallback(() => {
    onSelect(current - 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  const toPage = useCallback((page) => {
    onSelect(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toNext = useCallback(() => {
    onSelect(current + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])

  const toEnd = useCallback(() => {
    onSelect(pages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages])

  const labels = [current - 2, current - 1, current, current + 1, current + 2].filter(
    (p) => p > 0 && p <= pages
  )

  return (
    <Container>
      {current !== 1 && <LabelLink onClick={toPrev}>&#8592;</LabelLink>}
      {labels[0] > 1 && <LabelLink onClick={toStart}>1</LabelLink>}
      {labels[0] > 2 && <Label>...</Label>}
      {labels.map((l) => (
        <>
          {l !== current && (
            <LabelLink key={l} onClick={() => toPage(l)}>
              {l}
            </LabelLink>
          )}
          {l === current && <Label key={l}>{l}</Label>}
        </>
      ))}
      {labels[labels.length - 1] < pages - 1 && <Label>...</Label>}
      {labels[labels.length - 1] < pages && (
        <LabelLink onClick={toEnd}>{pages}</LabelLink>
      )}
      {current !== pages && <LabelLink onClick={toNext}>&#8594;</LabelLink>}
    </Container>
  )
}

export default PaginationFooter
