import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AdminContainer from './containers/AdminContainer'
import { NotFound } from '../components'
import OverviewContainer from './containers/OverviewContainer'
// import DashboardContainer from './containers/DashboardContainer'
import SettingsContainer from './containers/SettingsContainer'

function Admin() {
  return (
    <Switch>
      <Route path="/admin/overview">
        <OverviewContainer />
      </Route>
      {/* <Route path="/admin/dashboard">
        <DashboardContainer />
      </Route> */}
      <Route path="/admin/settings">
        <SettingsContainer />
      </Route>
      <Route path="/admin">
        <AdminContainer />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Admin
