import React from 'react'
import { Link, TextBold } from '../../components'
import Layout from '../Layout'
import ResetForm from '../forms/Reset'

function Reset() {
  return (
    <Layout
      sidebarText="Find the perfect match from thousands of experts to prepare your applicants for their job interviews"
      title="Recover password"
      subtitle="Don’t worry, happens to the best of us."
    >
      <>
        <TextBold>Please enter your email</TextBold>
        <ResetForm />
        <Link to="login">Back to login</Link>
      </>
    </Layout>
  )
}

export default Reset
