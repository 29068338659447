import { put, takeEvery, call } from 'typed-redux-saga'

import { avaGetInterviews } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { updateAvaInterviewsAction } from '../../data/ava/interviews'

export interface IAvaGetInterviewsAction {
  agencyId: string
}

const {
  action: avaGetInterviewsAction,
  actionFailure: avaGetInterviewsActionFailure,
  actionSuccess: avaGetInterviewsActionSuccess,
  actionSuccessClear: avaGetInterviewsActionSuccessClear,
  actionFailureClear: avaGetInterviewsActionFailureClear,
  actionType: avaGetInterviewsActionType,
  actionTypeFailure: avaGetInterviewsActionFailureType,
  actionTypeSuccess: avaGetInterviewsActionSuccessType,
  reducer: avaGetInterviewsRequestReducer,
  selectors: avaGetInterviewsSelectors,
} = reduxRequestFactory<IAvaGetInterviewsAction>('avaGetInterviews', 'requests')

export {
  avaGetInterviewsAction,
  avaGetInterviewsActionFailure,
  avaGetInterviewsActionSuccess,
  avaGetInterviewsActionSuccessClear,
  avaGetInterviewsActionFailureClear,
  avaGetInterviewsActionType,
  avaGetInterviewsActionFailureType,
  avaGetInterviewsActionSuccessType,
  avaGetInterviewsRequestReducer,
  avaGetInterviewsSelectors,
}

function* avaGetInterviewsSaga(action: ISagaAction<IAvaGetInterviewsAction>) {
  try {
    const { agencyId } = action.payload
    const interviews = yield* call(avaGetInterviews, agencyId)
    yield put(avaGetInterviewsActionSuccess())
    yield put(updateAvaInterviewsAction(interviews))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaGetInterviewsActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaGetInterviews() {
  yield takeEvery(avaGetInterviewsActionType, avaGetInterviewsSaga)
}
