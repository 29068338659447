import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import {
  ApplicantAvailabilityDaysNew,
  ApplicantAvailabilityDays,
  ApplicantAvailabilityTimesNew,
  ApplicantAvailabilityTimes,
} from 'src/utils/staticData'
import moment from 'moment'
import AvailabilityExpertCalendar from 'src/interview/components/AvailabilityExpertCalendar'
import AvailabilityDaysCheckbox from 'src/interview/components/AvailabilityDaysCheckbox'
import AvailabilityTimeCheckbox from 'src/interview/components/AvailabilityTimeCheckbox'

import { IInterviewAvailabilityDays, IInterviewAvailabilityTime } from '../../api'
import { RadioButtonGroup } from '../../components'
import { interview as interviewSelector } from '../selectors'

const Container = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  min-height: ${isMobileOnly ? '100%' : '100vh'};
  background: #00000050;
`

const BlockContainer = styled.form`
  background: white;
  display: flex;
  width: 737px;
  flex-direction: column;
  border-radius: 16px;
  padding: 20px 40px;
  margin-top: 21px;
  margin-bottom: 41px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`

const BottomRowContainer = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    margin-bottom: 0;
  }
`

const CalendarWrapper = styled.div`
  margin: 12px 0 18px 0;
`

const Button = styled.button<{ disabled?: boolean }>`
  width: 96px;
  height: 40px;
  cursor: pointer;
  background: #b202ff;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  border: none;
  outline: 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  &:active {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
    width: 100%;
  }
`

const AvailabilityLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #21304e;
  margin-bottom: 8px;
  text-transform: uppercase;
`

const AvailabilityDaysRowWrap = styled.div<{ newFlow?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ newFlow }) => (newFlow ? 'flex-start' : 'space-between')};
  margin-bottom: 20px;

  & > div {
    width: ${({ newFlow }) => (newFlow ? '75px' : '48%')};
    height: ${({ newFlow }) => (newFlow ? '66px' : '56px')};
    margin: ${({ newFlow }) => (newFlow ? '0 12px 12px 0' : '0')};
  }
`

const TimezoneLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: black;
  margin-bottom: 8px;
`

const AvailabilityTimeRowWrap = styled.div<{ newFlow?: boolean }>`
  display: flex;
  flex-wrap: ${({ newFlow }) => (newFlow ? 'wrap' : 'no-wrap')};
  justify-content: ${({ newFlow }) => (newFlow ? 'flex-start' : 'space-between')};

  & > div {
    width: ${({ newFlow }) => (newFlow ? '102px' : '31%')};
    height: ${({ newFlow }) => (newFlow ? '78px' : 'auto')};
    margin: ${({ newFlow }) => (newFlow ? '0 12px 12px 0' : '0')};
  }
`

const AvailabilitySlotsOption = 'Select specific time slots'
const AvailabilityDaysOption = 'Select group days and times'
const AvailabilityOptions = [AvailabilitySlotsOption, AvailabilityDaysOption]

interface IPayload {
  selectedDays?: number[]
  availabilityDays?: IInterviewAvailabilityDays
  availabilityTime?: IInterviewAvailabilityTime
}

interface IProps {
  preSelectedDays?: number[]
  preSelectedAailabilityDays?: IInterviewAvailabilityDays
  preSelectedAvailabilityTime?: IInterviewAvailabilityTime
  onClose?: () => void
  onSave?: (payload: IPayload) => void
}

function ApplicantAvailabilityPopup({
  preSelectedDays,
  preSelectedAailabilityDays,
  preSelectedAvailabilityTime,
  onClose,
  onSave,
}: IProps) {
  const interviewDetails = useSelector(interviewSelector.interview)
  const newApplicantAvailabilityFlow =
    interviewDetails?.interview?.use_new_applicant_availability_flow
  const [availabilityType, setAvailabilityType] = useState(
    !Object.values(preSelectedAailabilityDays).find((v) => !!v)
      ? AvailabilitySlotsOption
      : AvailabilityDaysOption
  )

  const timeZone: string = interviewDetails?.candidate_profile.time_zone ?? ''

  const getZoneLabel = (tz: any) => {
    const offset = moment.tz(tz).utcOffset() / 60
    return `${tz}, GMT ${offset >= 0 ? '+' : ''}${offset}`
  }

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedDays: preSelectedDays,
      availabilityDays: preSelectedAailabilityDays,
      availabilityTime: preSelectedAvailabilityTime,
    },
  })

  const { selectedDays, availabilityDays, availabilityTime } = watch()
  const weekOptionChecked =
    availabilityDays && Object.values(availabilityDays).find((v) => !!v)
  const timeOptionChecked =
    availabilityTime && Object.values(availabilityTime).find((v) => !!v)

  const onReschedule = useCallback(
    (payload) => {
      console.log('[payload]', payload)
      onSave(payload)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availabilityType, selectedDays?.length]
  )

  const canReschedule =
    (availabilityType === AvailabilitySlotsOption && selectedDays?.length > 0) ||
    (availabilityType === AvailabilityDaysOption &&
      weekOptionChecked &&
      timeOptionChecked)
  const applicantAvailabilityDays = newApplicantAvailabilityFlow
    ? ApplicantAvailabilityDaysNew
    : ApplicantAvailabilityDays
  const applicantAvailabilityTimes = newApplicantAvailabilityFlow
    ? ApplicantAvailabilityTimesNew
    : ApplicantAvailabilityTimes

  return (
    <Container onClick={onClose}>
      <BlockContainer
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onReschedule)}
      >
        <RadioButtonGroup
          value={availabilityType}
          onChange={setAvailabilityType}
          useTheme={false}
          options={AvailabilityOptions.map((o) => {
            return { text: o, value: o }
          })}
        />
        <CalendarWrapper>
          {availabilityType === AvailabilitySlotsOption && (
            <Controller
              render={({ onChange, value }) => (
                <AvailabilityExpertCalendar
                  selectedDays={value}
                  onChange={onChange}
                  fullWeek
                  useTheme
                  useStaticTimezone
                  staticTimezone={timeZone}
                />
              )}
              defaultValue={[]}
              name="selectedDays"
              control={control}
            />
          )}
          {availabilityType === AvailabilityDaysOption && (
            <>
              <AvailabilityLabel>Days available</AvailabilityLabel>
              <AvailabilityDaysRowWrap newFlow={newApplicantAvailabilityFlow}>
                {applicantAvailabilityDays.map((item) => (
                  <Controller
                    key={item.name}
                    render={({ onChange, onBlur, value, name }) => (
                      <AvailabilityDaysCheckbox
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.checked)}
                        name={name}
                        checked={value}
                        title={item.title}
                        hideCheckBox={newApplicantAvailabilityFlow}
                      />
                    )}
                    defaultValue={false}
                    name={item.name}
                    control={control}
                  />
                ))}
              </AvailabilityDaysRowWrap>
              <AvailabilityLabel>Time available</AvailabilityLabel>
              <TimezoneLabel>{getZoneLabel(timeZone)}</TimezoneLabel>
              <AvailabilityTimeRowWrap newFlow={newApplicantAvailabilityFlow}>
                {applicantAvailabilityTimes.map((item) => (
                  <Controller
                    key={item.name}
                    render={({ onChange, onBlur, value, name }) => (
                      <AvailabilityTimeCheckbox
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.checked)}
                        name={name}
                        checked={value}
                        icon={item.icon}
                        title={item.info}
                        time={isMobileOnly ? item.timeMobile : item.time}
                        hideCheckBox={newApplicantAvailabilityFlow}
                      />
                    )}
                    defaultValue={false}
                    name={item.name}
                    control={control}
                  />
                ))}
              </AvailabilityTimeRowWrap>
            </>
          )}
        </CalendarWrapper>
        <BottomRowContainer>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={!canReschedule}>
            Done
          </Button>
        </BottomRowContainer>
      </BlockContainer>
    </Container>
  )
}

ApplicantAvailabilityPopup.defaultProps = {
  preSelectedDays: [],
  preSelectedAailabilityDays: {},
  preSelectedAvailabilityTime: {},
  onClose: () => {},
  onSave: () => {},
}

export default ApplicantAvailabilityPopup
