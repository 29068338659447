import { isString } from 'lodash'
import { format } from 'date-fns'
import moment from 'moment'
import { AvailableDay } from 'src/api/models'

export const normalizeDate = (date) => {
  if (isString(date)) {
    return Date.parse(date)
  }
  return date
}

export const formattedDate = (date, dateFormat = 'M/d/yyyy HH:mm') => {
  if (date) {
    return date === 'N/A' ? 'N/A' : format(Date.parse(date), dateFormat)
  }
  return '--:--'
}

export const formattedDateShort = (date, extraDays = 0) => {
  if (date) {
    return date === 'N/A'
      ? 'N/A'
      : format(Date.parse(date) + extraDays * 24 * 60 * 60 * 1000, 'M/d/yyyy')
  }
  return '--:--'
}

export const getTimezoneOffset = (timeZone) => {
  return moment.tz(timeZone).utcOffset()
}

export const getTimeStamp = (mSeconds: number) => {
  let minutes = Math.floor(mSeconds / 1000 / 60)
  minutes = minutes < 1 ? 0 : minutes
  const seconds = Math.round(Math.ceil(mSeconds / 1000) - minutes * 60)
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

export const getSecondsTimeStamp = (mSeconds: number) => {
  const result = mSeconds / 1000
  return result
}

export const getShortLocalTime = (date: Date | string) => {
  if (!date) return null
  let nDate: Date
  if (isString(date)) {
    nDate = new Date(date)
  } else {
    nDate = date
  }
  return nDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export const isToday = (date: Date) => {
  const today = new Date()

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  )
}

export const isTomorow = (date: Date) => {
  const today = new Date()
  today.setTime(today.getTime() + 24 * 60 * 60 * 1000)

  return (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  )
}

export const isEqualDate = (date1, date2) => {
  const nDate1 = new Date(normalizeDate(date1))
  const nDate2 = new Date(normalizeDate(date2))

  return (
    nDate1.getFullYear() === nDate2.getFullYear() &&
    nDate1.getMonth() === nDate2.getMonth() &&
    nDate1.getDate() === nDate2.getDate()
  )
}

export const isEqualFullDate = (date1, date2) => {
  const nDate1 = new Date(normalizeDate(date1))
  const nDate2 = new Date(normalizeDate(date2))

  return (
    nDate1.getFullYear() === nDate2.getFullYear() &&
    nDate1.getMonth() === nDate2.getMonth() &&
    nDate1.getDate() === nDate2.getDate() &&
    nDate1.getHours() === nDate2.getHours() &&
    nDate1.getMinutes() === nDate2.getMinutes()
  )
}

export const isAvailableDayMatchWeekPosition = (
  day: AvailableDay,
  weekPosition: number
) => {
  switch (day) {
    case 'Sun':
      return weekPosition === 6
    case 'Mon':
      return weekPosition === 0
    case 'Tue':
      return weekPosition === 1
    case 'Wed':
      return weekPosition === 2
    case 'Thu':
      return weekPosition === 3
    case 'Fri':
      return weekPosition === 4
    case 'Sat':
      return weekPosition === 5
    case 'Weekday':
      return weekPosition >= 0 && weekPosition <= 5
    case 'Weekend':
      return weekPosition === 5 || weekPosition === 6
    default:
      return false
  }
}
