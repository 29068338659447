import styled from '@emotion/styled'
import React from 'react'
import ImgPersonalize from 'src/static/icLandingPersonalize.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  LandingInfo,
  LandingButton,
  LandingTitle,
  BaseResizableLayout,
} from './landing-styled-components'

const Layout = styled(BaseResizableLayout)`
  width: 100%;
  display: flex;
  margin: 0 auto 40px;

  @media screen and (max-width: 1439px) {
    margin: 0 auto 40px;
  }

  @media screen and (max-width: 1144px) {
    flex-direction: column;
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 0;
  }

  ${isMobileOnly &&
  css`
    height: auto;
    width: auto;
    flex-direction: column;
    margin-bottom: 0 !important;
  `}
`
const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: #ffffff;
  border-radius: 32px;
  flex: 1;

  &:first-of-type {
    margin-right: 20px;
  }

  &:last-child {
    margin-left: 20px;
  }

  @media screen and (max-width: 1144px) {
    margin: 0 0 48px 0;
    &:first-of-type {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }

  ${isMobileOnly &&
  css`
    padding: 24px;
    margin: 0 0 26px 0 !important;

    &:first-of-type {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  `}
`

const PersonalizeImg = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: 97px;

  @media screen and (max-width: 1439px) {
    margin: 40px auto;
    width: 80%;
  }

  ${isMobileOnly &&
  css`
    margin-top: 22px;
  `}
`

const VideoContainer = styled.div`
  width: 100%;
  margin: auto;
`

const LandingButtonStyled = styled(LandingButton)`
  margin: 0 auto;
`

interface IProps {
  onStart: () => void
}

const LandingPersonalizeBlock = ({ onStart }: IProps) => {
  return (
    <Layout>
      <BlockContainer>
        <LandingTitle>Personalize your session</LandingTitle>
        <LandingInfo>
          With an extensive track record of successful sessions, Ava has gained valuable
          insights into the interview processes of over 10,000 companies worldwide. This
          allows Ava to provide you with expert guidance that is specific to your
          industry, role, and desired outcomes.
        </LandingInfo>
        <LandingButtonStyled onClick={onStart}>Start Practicing</LandingButtonStyled>
        <PersonalizeImg src={ImgPersonalize} alt="rersonalize" />
      </BlockContainer>
      <BlockContainer>
        <LandingTitle>Practice answering interview questions</LandingTitle>
        <LandingInfo>
          Practicing your interview skills with Ava is easy and enjoyable. Ava transcribes
          your responses in real-time, giving you a natural conversation experience.
        </LandingInfo>
        <VideoContainer>
          <video
            src="/static/media/video/landing_neural.mp4"
            width="100%"
            height="100%"
            loop
            autoPlay
            muted
            playsInline
            onContextMenu={(event) => event.preventDefault()}
          />
        </VideoContainer>
      </BlockContainer>
    </Layout>
  )
}

export default LandingPersonalizeBlock
