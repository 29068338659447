import { put, takeEvery, call } from 'typed-redux-saga'

import { avaSynthesizeVoice } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { updateSynthesizedVoiceAction } from '../../data/ava/synthesizedVoice'

export interface IAvaSynthesizeVoiceAction {
  text: string
}

const {
  action: avaSynthesizeVoiceAction,
  actionFailure: avaSynthesizeVoiceActionFailure,
  actionSuccess: avaSynthesizeVoiceActionSuccess,
  actionSuccessClear: avaSynthesizeVoiceActionSuccessClear,
  actionFailureClear: avaSynthesizeVoiceActionFailureClear,
  actionType: avaSynthesizeVoiceActionType,
  actionTypeFailure: avaSynthesizeVoiceActionFailureType,
  actionTypeSuccess: avaSynthesizeVoiceActionSuccessType,
  reducer: avaSynthesizeVoiceRequestReducer,
  selectors: avaSynthesizeVoiceSelectors,
} = reduxRequestFactory<IAvaSynthesizeVoiceAction>('avaSynthesizeVoice', 'requests')

export {
  avaSynthesizeVoiceAction,
  avaSynthesizeVoiceActionFailure,
  avaSynthesizeVoiceActionSuccess,
  avaSynthesizeVoiceActionSuccessClear,
  avaSynthesizeVoiceActionFailureClear,
  avaSynthesizeVoiceActionType,
  avaSynthesizeVoiceActionFailureType,
  avaSynthesizeVoiceActionSuccessType,
  avaSynthesizeVoiceRequestReducer,
  avaSynthesizeVoiceSelectors,
}

function* avaSynthesizeVoiceSaga(action: ISagaAction<IAvaSynthesizeVoiceAction>) {
  try {
    const { text } = action.payload
    const audioContent = yield* call(
      avaSynthesizeVoice,
      text.replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
      )
    )
    yield put(avaSynthesizeVoiceActionSuccess())
    yield put(updateSynthesizedVoiceAction({ audioContent, text }))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaSynthesizeVoiceActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaSynthesizeVoice() {
  yield takeEvery(avaSynthesizeVoiceActionType, avaSynthesizeVoiceSaga)
}
