import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgUnMute({ fill }: SvgProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8965 1.74277C13.0401 0.813548 14.75 1.62741 14.75 3.10097V20.899C14.75 22.3726 13.0401 23.1864 11.8965 22.2572L6.34911 17.75H4C2.48122 17.75 1.25 16.5188 1.25 15V9C1.25 7.48122 2.48122 6.25 4 6.25H6.3491L11.8965 1.74277ZM13.25 3.10097C13.25 2.89046 13.0057 2.77419 12.8424 2.90694L6.75 7.85697V16.143L12.8424 21.0931C13.0057 21.2258 13.25 21.1095 13.25 20.899V3.10097ZM5.25 7.75V16.25H4C3.30964 16.25 2.75 15.6904 2.75 15V9C2.75 8.30964 3.30964 7.75 4 7.75H5.25Z"
        fill={fill}
      />
      <path
        d="M22 15.0607L19.5303 12.591L17.0607 15.0607L16 14L18.4697 11.5303L16 9.06066L17.0607 8L19.5303 10.4697L22 8.00001L23.0607 9.06067L20.591 11.5303L23.0607 14L22 15.0607Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgUnMute
