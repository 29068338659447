import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import * as authSelectors from '../selectors'
import { reset } from '../actions'
import { Input, Button } from '../../components'
import { EmailRegex } from '../../utils/regex'

const Form = styled('form')({
  marginBottom: '12px',
})

const checkFieldsFill = (fields: string[], values: object = {}) => {
  return fields.reduce((prev, curr) => !!values[curr] && prev, true)
}

function ResetForm() {
  const dispatch = useDispatch()
  const isLoading = useSelector(authSelectors.reset.isLoading)
  const { handleSubmit, errors, control, getValues } = useForm({ mode: 'onChange' })
  const onSubmit = (payload) => {
    dispatch(reset(payload))
  }

  const disabled = !checkFieldsFill(['email'], getValues())
  const hasFormErrors = Object.keys(errors).length > 0

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={Input}
        label="Email"
        name="email"
        control={control}
        disableWhitespaces
        error={errors.email}
        rules={{
          required: true,
          validate: {
            valid: (value) =>
              EmailRegex.test(value) ||
              "That format doesn't look right. Make sure there aren't any typos",
          },
        }}
        defaultValue=""
      />
      <Button
        disabled={isLoading || disabled || hasFormErrors}
        title="Email me a recovery link"
      />
    </Form>
  )
}

export default ResetForm
