import React, { ChangeEventHandler, useEffect } from 'react'
import styled from '@emotion/styled'
import { IAgoraRTC, ICameraVideoTrack } from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useStateRef from 'react-usestateref'

import { MediaDeviceGroup } from '../../services/useMediaDevices'

import Select from '../Select'
import { DefaultVideoProfile } from '../VideoProfiles'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`

const Text = styled.div`
  color: ${({ theme }: { theme: any }) => theme.precallTextColor};
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const SelectorText = styled.div`
  color: #737991;
  margin: 12px 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  ${!isMobileOnly &&
  css`
    font-weight: normal;

    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const VideoPreview = styled.div`
  margin-top: 19px;
  width: 165px;
  height: 275px;

  align-self: center;
  transform: rotateY(180deg);

  @media (orientation: landscape) {
    width: 275px;
    height: 165px;
  }

  div {
    background-color: transparent !important;
  }

  ${!isMobileOnly &&
  css`
    width: 225px;
    height: 150px;
    @media screen and (max-width: 2500px) {
      width: 188px;
      height: 125px;
    }
    @media screen and (max-width: 2000px) {
      width: 150px;
      height: 100px;
    }
    @media screen and (max-width: 1200px) {
      width: 113px;
      height: 75px;
    }
    @media screen and (max-width: 900px) {
      width: 75px;
      height: 50px;
    }
  `}
`

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Precall]',
  'color:#1e133b',
]

interface IProps {
  agoraRTC: IAgoraRTC
  deviceId: string
  devices: MediaDeviceGroup[]
  changeCamera: (deviceId: string) => void
}

const CameraTestView = ({ agoraRTC, deviceId, devices, changeCamera }: IProps) => {
  const [cameras, setCameras] = useStateRef<MediaDeviceGroup[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cameraTrack, setCameraTrack, cameraTrackRef] = useStateRef<ICameraVideoTrack>(
    undefined
  )

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const changeVideoInput = async (rtc, cameraId) => {
    if (!rtc || !cameraId) return
    if (cameraTrackRef.current) {
      log(`Changing camera track device (${cameraId})`)
      try {
        await cameraTrackRef.current?.setDevice(cameraId)
        log(`Successfully changed camera track device`)
      } catch (e) {
        error(`Failed to change camera track device: ${JSON.stringify(e)}`)
        throw e
      }
    } else {
      log(`Creating new camera track with device (${cameraId})`)
      try {
        setCameraTrack(
          await rtc.createCameraVideoTrack({
            optimizationMode: 'detail',
            encoderConfig: {
              width: DefaultVideoProfile.width,
              height: DefaultVideoProfile.height,
            },
            cameraId,
          })
        )
        cameraTrackRef.current.play('precall-video', { fit: 'contain', mirror: false })
        log(`Successfully created new camera track`)
      } catch (e) {
        error(`Failed to create new camera track: ${JSON.stringify(e)}`)
        throw e
      }
    }
  }

  useEffect(() => {
    return () => {
      log('Closing camera track')
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cameraTrackRef.current?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCameras(devices.filter((d) => d.kind === 'videoinput'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices])

  useEffect(() => {
    changeVideoInput(agoraRTC, deviceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agoraRTC, deviceId])

  const switchCamera: ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeCamera(e.target.value)
  }

  return (
    <Container>
      <Text>Can you see your video feed?</Text>
      <SelectorText>Choose a camera</SelectorText>
      <Select id="selectCamera" onChange={switchCamera} value={deviceId}>
        {cameras.map((it) => (
          <option key={it.deviceId} value={it.deviceId}>
            {it.label}
          </option>
        ))}
      </Select>
      <VideoPreview id="precall-video" />
    </Container>
  )
}

export default CameraTestView
