import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useState from 'react-usestateref'
import styled from '@emotion/styled'
import { isMobileOnly, isMacOs } from 'react-device-detect'
import SvgSend from 'src/static/chat_button_icon.svg'
import SvgSendActive from 'src/static/chat_button_icon_active.svg'
import SvgSendActiveMobile from 'src/static/chat_button_icon_active_mobile.svg'
import SvgClose from 'src/static/close_white.svg'
import SvgUser from 'src/static/components/SvgUser'
import SvgFileAttach from 'src/static/attach_file.svg'
import SvgFileAttachMobile from 'src/static/attach_file_mobile.svg'
import htmlParser from 'html-react-parser'
import TextareaAutosize from 'react-textarea-autosize'

import * as interviewSelectors from '../../../interview/selectors'
import { TextBold } from '../../../components'
import { Text as MobileText } from '../MobileText'

export interface IChatItem {
  author: string
  text: string
  timestamp: Date
  isMy: boolean
  showAuthor: boolean
  mediaId: string
}

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: 109px;
  width: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
  align-items: center;
  box-shadow: 0px 9px 14px -5px rgb(0 0 0 / 10%);

  height: 141px;
  margin-top: 37px;
  @media screen and (max-width: 2500px) {
    margin-top: 31px;
    height: 125px;
  }
  @media screen and (max-width: 2000px) {
    margin-top: 25px;
    height: 109px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 18px;
    height: 93px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 12px;
    height: 77px;
  }
`

const ChatViewContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;
`

const ChatView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 12px;
  overflow-y: auto;
`

const ChatHint = styled(MobileText)`
  color: #8f9bb3;
  font-size: 0.75vw;
  text-align: center;
  width: 90%;

  margin: 18px 5%;
  @media screen and (max-width: 2500px) {
    margin: 15px 5%;
  }
  @media screen and (max-width: 2000px) {
    margin: 12px 5%;
  }
  @media screen and (max-width: 1200px) {
    margin: 9px 5%;
  }
  @media screen and (max-width: 900px) {
    margin: 6px 5%;
  }
`

const Footer = styled.div`
  align-items: center;
  background: #6938aa;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  padding: 16px 8px;
  position: relative;
  width: 100%;
`

const ChatEntry = styled(TextareaAutosize)`
  background: #542c89;
  border-radius: 5px;
  border-width: 0;
  margin: 0 12px;
  padding: 10px;
  color: white;
  width: 100%;
  outline: none;
  resize: none;

  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  font-size: 18px;
  line-height: 21px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 18px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 11px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 7px;
  }
`

const ChatItemContainer = styled.div<{ isMy: boolean }>`
  @keyframes message {
    0% {
      max-height: 100vmax;
    }
    80% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
      max-height: 100vmax;
      overflow: visible;
    }
  }

  display: flex;
  max-width: 100%;
  margin-bottom: 16px;
  flex-direction: column;
  width: fit-content;
  align-self: ${({ isMy }) => (isMy ? 'end' : 'start')};
  -webkit-align-self: ${({ isMy }) => (isMy ? 'flex-end' : 'flex-start')};
  position: relative;

  animation: message 0.15s ease-out 0s forwards;
`

const ChatItemAuthor = styled.p<{ isMy: boolean }>`
  font-weight: 500;
  color: ${({ isMy }) => (isMy ? '#8F9BB3' : '#6938AA')};

  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin: 0 0 4px 0;

  font-size: 16px;
  line-height: 18px;
  @media screen and (max-width: 2500px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 10px;
    line-height: 12px;
  }
  @media screen and (max-width: 900px) {
    font-size: 8px;
    line-height: 10px;
  }
`

const ChatTextContainer = styled.div<{ isMy: boolean }>`
  align-items: flex-end;
  background-color: ${({ isMy }) => (isMy ? 'white' : '#6938AA')};
  border: ${({ isMy }) => (isMy ? '1px solid rgba(112, 124, 151, 0.25)' : 'none')};
  color: ${({ isMy }) => (isMy ? '#707C97' : 'white')};
  display: flex;
  width: 100%;

  padding: 18px;
  border-radius: ${({ isMy }) => (isMy ? '20px 20px 0px 20px' : '0px 20px 20px 20px')};
  @media screen and (max-width: 2500px) {
    padding: 15px;
    border-radius: ${({ isMy }) => (isMy ? '18px 18px 0px 18px' : '0px 18px 18px 18px')};
  }
  @media screen and (max-width: 2000px) {
    padding: 12px;
    border-radius: ${({ isMy }) => (isMy ? '16px 16px 0px 16px' : '0px 16px 16px 16px')};
  }
  @media screen and (max-width: 1200px) {
    padding: 9px;
    border-radius: ${({ isMy }) => (isMy ? '14px 14px 0px 14px' : '0px 14px 14px 14px')};
  }
  @media screen and (max-width: 900px) {
    padding: 6px;
    border-radius: ${({ isMy }) => (isMy ? '12px 12px 0px 12px' : '0px 12px 12px 12px')};
  }
`

const ChatText = styled.p`
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin: 0;
  overflow: auto;

  font-size: 18px;
  line-height: 33px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 28px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 22px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 17px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 11px;
  }
`

const ChatItemTimestamp = styled.div<{ isMy: boolean }>`
  font-weight: 300;
  color: ${({ isMy }) => (isMy ? '#707C97' : 'white')};
  margin-left: 4px;

  font-size: 12px;
  line-height: 13px;
  @media screen and (max-width: 2500px) {
    font-size: 10px;
    line-height: 11px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 8px;
    line-height: 9px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 6px;
    line-height: 7px;
  }
  @media screen and (max-width: 900px) {
    font-size: 4px;
    line-height: 5px;
  }
`

const AttachInput = styled.input`
  display: none;
`

const AttachButton = styled.img`
  cursor: pointer;

  width: 36px;
  height: 36px;
  @media screen and (max-width: 2500px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 2000px) {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 1200px) {
    width: 18px;
    height: 18px;
  }
  @media screen and (max-width: 900px) {
    width: 12px;
    height: 12px;
  }
`

const SendButton = styled.img`
  cursor: pointer;
  padding: 2px;

  width: 36px;
  height: 36px;
  @media screen and (max-width: 2500px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (max-width: 2000px) {
    width: 24px;
    height: 24px;
  }
  @media screen and (max-width: 1200px) {
    width: 18px;
    height: 18px;
  }
  @media screen and (max-width: 900px) {
    width: 12px;
    height: 12px;
  }
`

const PhotoContainer = styled.div<{ hasBorder: boolean }>`
  overflow: hidden;
  background: white;
  display: flex;
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #e1e3ea' : 'none')};

  width: 74px;
  height: 74px;
  border-radius: 37px;
  margin-bottom: 16px;
  @media screen and (max-width: 2500px) {
    width: 68px;
    height: 68px;
    border-radius: 34px;
    margin-bottom: 14px;
  }
  @media screen and (max-width: 2000px) {
    width: 62px;
    height: 62px;
    border-radius: 31px;
    margin-bottom: 12px;
  }
  @media screen and (max-width: 1200px) {
    width: 56px;
    height: 56px;
    border-radius: 28px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 900px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-bottom: 8px;
  }

  & svg {
    display: block;
    flex: 1;
    margin: auto;
    width: auto;

    height: 39px;
    @media screen and (max-width: 2500px) {
      height: 32px;
    }
    @media screen and (max-width: 2000px) {
      height: 26px;
    }
    @media screen and (max-width: 1200px) {
      height: 20px;
    }
    @media screen and (max-width: 900px) {
      height: 13px;
    }
  }
`

const Photo = styled.img`
  width: auto;
  height: 100%;
`

const Name = styled(TextBold)`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0px 10px;

  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 900px) {
    font-size: 10px;
    line-height: 12px;
  }
`

const MobileContainer = styled.div`
  z-index: 1;
  align-items: center;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  height: 100%;
  width: 100%;
  background: #2b1b4b;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`

const MobileHeader = styled.div`
  align-items: center;
  display: flex;
  height: 92px;
  width: 100%;
  background: #1d0e3b;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 2px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);

  @media (orientation: landscape) {
    width: 80%;
  }
`

const MobileChatViewContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  width: 100%;

  @media (orientation: landscape) {
    width: 80%;
  }
`

const MobileChatView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding: 12px;
  overflow-y: auto;
  max-height: 100%;

  @media (orientation: landscape) {
    padding-left: 0;
    padding-right: 8px;
  }
`

const MobileChatHint = styled(MobileText)`
  color: #8f9bb3;
  line-height: 14px;
  text-align: center;
  width: 100%;

  font-size: 3vw;
  padding: 20px 5%;
  @media (orientation: landscape) {
    font-size: 3vh;
    padding: 12px 30%;
  }
`

const MobileChatItemAuthor = styled(ChatItemAuthor)`
  color: white;
  font-size: 12px !important;
  line-height: 14px !important;
`

const MobileFooter = styled(Footer)`
  background: #1d0e3b;
  padding: 12px 16px 24px 16px;

  @media (orientation: landscape) {
    padding: 12px 76px;
  }
`

const MobileChatTextContainer = styled.div<{ isMy: boolean }>`
  width: 100%;
  background-color: ${({ isMy }) => (isMy ? 'white' : '#6938AA')};
  border: ${({ isMy }) => (isMy ? '1px solid rgba(112, 124, 151, 0.25)' : 'none')};
  color: ${({ isMy }) => (isMy ? '#707C97' : 'white')};
  padding: 8px 36px 8px 8px;
  border-radius: ${({ isMy }) => (isMy ? '16px 16px 0px 16px' : '0px 16px 16px 16px')};
`

const MobileChatText = styled(ChatText)`
  font-size: 14px !important;
  line-height: 26px !important;
`

const MobileChatEntry = styled(ChatEntry)`
  background: #ffffff;
  border-radius: 8px !important;
  color: #000000;
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 4px 12px;

  ::placeholder {
    color: rgba(112, 124, 151, 0.5);
  }
`

const MobileChatItemTimestamp = styled(ChatItemTimestamp)`
  font-size: 8px !important;
  bottom: 14px !important;
`

const MobileAttachButton = styled(AttachButton)`
  width: 24px !important;
  height: 24px !important;
`

const MobileSendButton = styled(SendButton)`
  width: 24px !important;
  height: 24px !important;
`

const MobilePhotoContainer = styled.div<{ hasBorder: boolean }>`
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: 30px;
  overflow: hidden;
  background: white;
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #e1e3ea' : 'none')};

  & svg {
    display: block;
    flex: 1;
    margin: auto;
    width: auto;
    height: 16px;
  }
`

const MobilePhoto = styled.img`
  height: 100%;
  width: auto;
`

const MobileName = styled(MobileText)`
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0px 10px;
  text-align: center;
  overflow: hidden;
`

const MobileCloseIcon = styled.img`
  position: absolute;
  right: 18px;
  top: 18px;
  height: 20px;
  width: 20px;
  @media (orientation: landscape) {
    right: calc(10% + 20px);
  }
`

interface IDetailsSidebar {
  isExpert: boolean
  isLandscape: boolean
  chatMessages: IChatItem[]
  onSend: (text: string) => void
  // onSendFile: (file: File) => void
  onClose: () => void
}

export default function ChatSidebar({
  isExpert,
  isLandscape,
  chatMessages,
  onSend,
  // onSendFile,
  onClose,
}: IDetailsSidebar) {
  const attachRef = useRef<HTMLInputElement>()
  const { peer: profile } = useSelector(interviewSelectors.interview)
  const name =
    // eslint-disable-next-line no-nested-ternary
    profile !== null ? profile.first_name : isExpert ? 'Expert' : 'Applicant'
  const peerAvatar = profile?.logo?.url
  const [items, setItems] = useState<IChatItem[]>([])
  const [text, setText] = useState('')
  const [hintVisible, setHintVisible] = useState(true)
  const [sendingMessage, setSendingMessage, sendingMessageRef] = useState(false)
  const hint =
    'Messages are only available during the session and will be deleted once the session ends.'

  useEffect(() => {
    const reverseItems = items.slice()
    chatMessages.forEach((m) => {
      if (
        !items.some(
          (item) =>
            m.text === item.text &&
            m.author === item.author &&
            m.timestamp === item.timestamp
        )
      ) {
        reverseItems.splice(0, 0, m)
      }
    })
    setItems(reverseItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages])

  function onSendInternal() {
    const isMessageValid = /[^\n\t\s]+/.test(text)
    if (isMessageValid && !sendingMessageRef.current) {
      setSendingMessage(true)
      onSend(text)
      setText('')
      setTimeout(() => setSendingMessage(false), 1000)
    }
  }

  function onKeyDown(e: any) {
    if (e.keyCode === 13 && !e.shiftKey && !(isMacOs && e.altKey)) {
      e.preventDefault()
      onSendInternal()
    }
    if (isMacOs && e.keyCode === 13 && e.altKey) {
      setText(`${text}\n`)
    }
  }

  function toShortTimeString(timestamp: Date) {
    return `${timestamp.getHours() < 10 ? '0' : ''}${timestamp.getHours()}:${
      timestamp.getMinutes() < 10 ? '0' : ''
    }${timestamp.getMinutes()}`
  }

  function getNormalName(userName: string) {
    const pattern = /_[a-zA-Z0-9]+$/
    return userName.replace(pattern, '')
  }

  function openFileDialog() {
    // attachRef.current.click()
  }

  function sendFile() {
    if (attachRef.current.files[0]) {
      // onSendFile(attachRef.current.files[0])
      attachRef.current.value = null
    }
  }

  function handleChatRows(height: number, { rowHeight }) {
    const rowsCount = (height - (isMobileOnly ? 4 : 10) * 2) / rowHeight
    setHintVisible(rowsCount === 1)
  }

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Body>
            <Header>
              <PhotoContainer hasBorder={!peerAvatar}>
                {!peerAvatar && <SvgUser fill="#200E32" />}
                {peerAvatar && <Photo src={peerAvatar} />}
              </PhotoContainer>
              <Name>{name}</Name>
            </Header>
            <ChatViewContainer>
              <ChatView>
                {items.map((msg) => {
                  return (
                    <ChatItemContainer
                      key={`${msg.timestamp}${msg.author}`}
                      isMy={msg.isMy}
                    >
                      {msg.showAuthor && (
                        <ChatItemAuthor isMy={msg.isMy}>
                          {getNormalName(msg.author)}
                        </ChatItemAuthor>
                      )}
                      <ChatTextContainer isMy={msg.isMy}>
                        <ChatText>{htmlParser(msg.text)}</ChatText>
                        <ChatItemTimestamp isMy={msg.isMy}>
                          {toShortTimeString(msg.timestamp)}
                        </ChatItemTimestamp>
                      </ChatTextContainer>
                    </ChatItemContainer>
                  )
                })}
              </ChatView>
              {hintVisible && <ChatHint>{hint}</ChatHint>}
            </ChatViewContainer>
            <Footer>
              {false && (
                <>
                  <AttachInput type="file" ref={attachRef} onChange={sendFile} />
                  <AttachButton src={SvgFileAttach} onClick={openFileDialog} />
                </>
              )}
              <ChatEntry
                placeholder="Write a message..."
                value={text}
                onKeyDown={onKeyDown}
                onChange={(e) => setText(e.target.value)}
                maxRows={4}
                onHeightChange={handleChatRows}
              />
              <SendButton
                src={text && !sendingMessage ? SvgSendActive : SvgSend}
                onClick={onSendInternal}
              />
            </Footer>
          </Body>
        </Container>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileHeader>
            <MobilePhotoContainer hasBorder={!peerAvatar}>
              {!peerAvatar && <SvgUser fill="#200E32" />}
              {peerAvatar && <MobilePhoto src={peerAvatar} />}
            </MobilePhotoContainer>
            <MobileName>{name}</MobileName>
          </MobileHeader>
          <MobileChatViewContainer>
            <MobileChatView>
              {items.map((msg) => {
                return (
                  <ChatItemContainer
                    key={`${msg.timestamp}${msg.author}`}
                    isMy={msg.isMy}
                  >
                    {msg.showAuthor && (
                      <MobileChatItemAuthor isMy={msg.isMy}>
                        {getNormalName(msg.author)}
                      </MobileChatItemAuthor>
                    )}
                    <MobileChatTextContainer isMy={msg.isMy}>
                      <MobileChatText>{htmlParser(msg.text)}</MobileChatText>
                      <MobileChatItemTimestamp isMy={msg.isMy}>
                        {toShortTimeString(msg.timestamp)}
                      </MobileChatItemTimestamp>
                    </MobileChatTextContainer>
                  </ChatItemContainer>
                )
              })}
            </MobileChatView>
            {hintVisible && <MobileChatHint>{hint}</MobileChatHint>}
          </MobileChatViewContainer>
          <MobileFooter>
            {false && (
              <>
                <AttachInput type="file" ref={attachRef} onChange={sendFile} />
                <MobileAttachButton src={SvgFileAttachMobile} onClick={openFileDialog} />
              </>
            )}
            <MobileChatEntry
              placeholder="Write a message..."
              value={text}
              onKeyDown={onKeyDown}
              onChange={(e) => setText(e.target.value)}
              maxRows={isLandscape ? 2 : 4}
              onHeightChange={handleChatRows}
            />
            <MobileSendButton
              src={text && !sendingMessage ? SvgSendActiveMobile : SvgSend}
              onClick={onSendInternal}
            />
          </MobileFooter>
          <MobileCloseIcon src={SvgClose} onClick={onClose} />
        </MobileContainer>
      )}
    </>
  )
}
