import React, { useState } from 'react'
import styled from '@emotion/styled'
import SvgClose from 'src/static/close_gray.svg'
import { KippAccounts } from 'src/utils/staticData'
import Autocomplete from 'src/components/Autocomplete'
import * as Components from './landing-styles'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(115, 121, 145, 0.3);
  backdrop-filter: blur(10px);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    align-items: center;
    height: 100vh;
  }
`

const ContentBlock = styled.div`
  background: #ffffff;
  border-radius: 8px;
  width: 469px;
  height: 305px;
  margin-top: 136px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    width: calc(100% - 20px);
    height: auto;
    margin-top: 0;
    padding: 20px 40px;
  }
`

const CloseButton = styled.img`
  width: 23px;
  height: 23px;
  padding: 4px;
  cursor: pointer;
  margin: 16px 16px 0px 0;
  align-self: end;

  @media screen and (max-width: 600px) {
    margin: 0 -20px 0 0;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #222b45;
  margin-bottom: 12px;
  text-align: center;
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-bottom: 16px;
`

const AutocompleteWrapper = styled.div`
  > div {
    width: 400px;
    min-height: 52px;
    padding: 0;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

interface IProps {
  onContinue?: (school: any) => void
  onClose?: () => void
}

const SchoolAccountPopup = ({ onContinue, onClose }: IProps) => {
  const [selectedRegion, setSelectedRegion] = useState(null)
  const onContinueInternal = () => {
    if (onContinue) {
      onContinue(selectedRegion?.value)
    }
  }

  return (
    <Container>
      <ContentBlock>
        <CloseButton onClick={onClose} src={SvgClose} />
        <Title>Access your school account</Title>
        <SubTitle>Select your region from the list below </SubTitle>
        <AutocompleteWrapper>
          <Autocomplete
            label="Region"
            options={KippAccounts}
            useTheme
            noOptionsText="No results"
            onChange={setSelectedRegion}
            disableCloseOnSelect={false}
          />
        </AutocompleteWrapper>
        <Components.StyledButton
          disabled={!selectedRegion}
          title="Continue"
          onClick={onContinueInternal}
        />
      </ContentBlock>
    </Container>
  )
}

SchoolAccountPopup.defaultProps = {
  onContinue: () => {},
  onClose: () => {},
}

export default SchoolAccountPopup
