import { RootState } from '../redux'

export const interviews = {
  interviews(state: RootState) {
    return state.agency.interviews.interviews
  },
  pages(state: RootState) {
    return state.agency.interviews.pages || 1
  },
  isLoading(state: RootState) {
    return state.agency.interviews.isLoading
  },
  error(state: RootState) {
    return state.agency.interviews.error
  },
}

export const interview = {
  interview(state: RootState) {
    return state.agency.interview.interview
  },
  isLoading(state: RootState) {
    return state.agency.interview.isLoading
  },
  error(state: RootState) {
    return state.agency.interview.error
  },
}

export const stats = {
  stats(state: RootState) {
    return state.agency.stats.stats
  },
  isLoading(state: RootState) {
    return state.agency.stats.isLoading
  },
  error(state: RootState) {
    return state.agency.stats.error
  },
}

export const sendToApplicant = {
  success(state: RootState) {
    return state.agency.sendToApplicant.success
  },
  isLoading(state: RootState) {
    return state.agency.sendToApplicant.isLoading
  },
  error(state: RootState) {
    return state.agency.sendToApplicant.error
  },
}

export const interviewComplete = {
  success(state: RootState) {
    return state.agency.interviewComplete.success
  },
  isLoading(state: RootState) {
    return state.agency.interviewComplete.isLoading
  },
  error(state: RootState) {
    return state.agency.interviewComplete.error
  },
}

export const interviewToEnd = {
  success(state: RootState) {
    return state.agency.interviewToEnd.success
  },
  isLoading(state: RootState) {
    return state.agency.interviewToEnd.isLoading
  },
  error(state: RootState) {
    return state.agency.interviewToEnd.error
  },
}

export const sessionReport = {
  success(state: RootState) {
    return state.agency.saveSessionReport.success
  },
  isLoading(state: RootState) {
    return state.agency.saveSessionReport.isLoading
  },
  error(state: RootState) {
    return state.agency.saveSessionReport.error
  },
}

export const reportDetails = {
  report(state: RootState) {
    return state.agency.interview.interview.report
  },
}
