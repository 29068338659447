import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgNotes({ fill }: SvgProps) {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5391 18.7727H5.23083V3.22727H12.2055V2H5.23083C4.55106 2 4 2.54947 4 3.22727V18.7727C4 19.4505 4.55106 20 5.23083 20H17.5391C18.2189 20 18.77 19.4505 18.77 18.7727V11H17.5391V18.7727Z"
        fill={fill || '#fff'}
      />
      <path
        d="M19.6401 3.5299L18.7704 2.66263C18.2898 2.18407 17.5113 2.18407 17.0308 2.66263C17.0308 2.66263 10.1915 9.47399 10.0889 9.88718L9.78119 11.1144C9.67585 11.533 9.79867 11.9759 10.1047 12.281C10.4107 12.5861 10.8549 12.7086 11.2746 12.6035L12.5054 12.2967C12.9157 12.1904 19.6401 5.26854 19.6401 5.26854C20.1201 4.78938 20.1201 4.01315 19.6401 3.53399V3.5299ZM12.9937 10.1572L12.1526 9.31854L12.1239 9.294L17.9006 3.53399L18.7704 4.40127L12.9937 10.1572Z"
        fill={fill || '#fff'}
      />
    </svg>
  )
}

export default SvgNotes
