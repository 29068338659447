/* eslint-disable react/destructuring-assignment */
import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Browser, detectBrowser } from '../services/browserDetection'

const Border = styled.div`
  display: flex;
  border: 1px solid #e1e4ea;
  background: white;
  box-shadow: 0px 0px 0px 5px #f5f6f8;
  border-radius: 25px;
  @media screen and (max-width: 2500px) {
    border-radius: 21px;
  }
  @media screen and (max-width: 2000px) {
    border-radius: 17px;
  }
  @media screen and (max-width: 1200px) {
    border-radius: 13px;
  }
  @media screen and (max-width: 900px) {
    border-radius: 8px;
  }
`

const SelectElement = styled.select<{ isSafari: boolean }>`
  -webkit-appearance: ${(props) => (props.isSafari ? 'none' : 'auto')};
  border: 0px;
  background: transparent;
  color: #8992a3;
  font-family: Rubik;
  letter-spacing: 0.5px;
  width: 100%;

  &:focus {
    outline: none;
  }

  font-size: 16px;
  height: 46px;
  line-height: 24px;
  margin-right: ${(props) => (props.isSafari ? '0' : '16px')};
  padding: 0px 16px;
  @media screen and (max-width: 2500px) {
    margin-right: ${(props) => (props.isSafari ? '0' : '13px')};
    font-size: 14px;
    height: 39px;
    line-height: 20px;
    padding: 0 13px;
  }
  @media screen and (max-width: 2000px) {
    margin-right: ${(props) => (props.isSafari ? '0' : '11px')};
    font-size: 22px;
    font-size: 11px;
    height: 31px;
    line-height: 16px;
    padding: 0 11px;
  }
  @media screen and (max-width: 1200px) {
    margin-right: ${(props) => (props.isSafari ? '0' : '8px')};
    font-size: 8px;
    height: 23px;
    line-height: 12px;
    padding: 0 8px;
  }
  @media screen and (max-width: 900px) {
    margin-right: ${(props) => (props.isSafari ? '0' : '5px')};
    font-size: 5px;
    height: 15px;
    line-height: 8px;
    padding: 0 5px;
  }
`

const MobileBorder = styled.div`
  display: flex;
  background: #f5f6f8;
  box-shadow: 0px 0px 0px 5px #f5f6f8;
  border-radius: 10px;
  height: 30px;
  flex: 1;
`

const MobileSelect = styled.select`
  border: 0px;
  background: transparent;
  box-sizing: border-box;
  color: #3b424f;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  width: 100%;
  line-height: 17px;
  margin-right: 5px;
  padding: 0px 12px;

  &:focus {
    outline: none;
  }
`

export default function Select(props: any) {
  const browser = detectBrowser()
  const isSafari = browser === Browser.Safari
  return (
    <>
      {!isMobileOnly && (
        <Border>
          <SelectElement
            isSafari={isSafari}
            id={props.id}
            onChange={props.onChange}
            value={props.value}
          >
            {props.children}
          </SelectElement>
        </Border>
      )}
      {isMobileOnly && (
        <MobileBorder>
          <MobileSelect id={props.id} onChange={props.onChange} value={props.value}>
            {props.children}
          </MobileSelect>
        </MobileBorder>
      )}
    </>
  )
}
