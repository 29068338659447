import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  LandingInfo,
  LandingButton,
  LandingTitle,
  BaseResizableLayout,
} from './landing-styled-components'

const Layout = styled(BaseResizableLayout)`
  width: 100%;
  display: flex;
  height: 670px;
  background: #fff;
  border-radius: 32px;
  padding: 80px 0 0;
  margin: 0 auto 160px auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    margin-bottom: 120px;
    padding: 80px 40px 0;
  }

  @media screen and (max-width: 899px) {
    padding: 40px 40px 0;
  }

  ${isMobileOnly &&
  css`
    height: auto;
    margin: 0 0 42px 0 !important;
    width: auto;
    flex-direction: column-reverse;
  `}
`
const BlockContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 40px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  ${isMobileOnly &&
  css`
    width: 100%;
    margin: 0;
  `}
`

const VideoContainer = styled.div`
  width: 50%;
  display: flex;

  @media screen and (max-width: 1439px) {
    margin-top: 0;
    width: 360px;
  }

  @media screen and (max-width: 1144px) {
    width: 360px;
  }

  @media screen and (max-width: 899px) {
    width: 320px;
  }

  ${isMobileOnly &&
  css`
    width: 238px !important;
  `}
`

const LandingButtonStyled = styled(LandingButton)`
  @media screen and (max-width: 1439px) {
    margin: 0 auto 80px auto;
    padding: 12px 24px;
  }

  ${isMobileOnly &&
  css`
    margin: 0 auto 24px auto !important;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  margin-bottom: 16px;
  text-align: Left;

  @media screen and (max-width: 1439px) {
    text-align: center;
    margin-bottom: 24px;
    line-height: normal !important;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 8px !important;
  `}
`

const LandingInfStyled = styled(LandingInfo)`
  margin-bottom: 16px;
  text-align: Left;

  @media screen and (max-width: 1439px) {
    text-align: center;
    margin-bottom: 24px;
  }
`

interface IProps {
  onStart: () => void
}

const LandingInsightsBlock = ({ onStart }: IProps) => {
  return (
    <Layout>
      <VideoContainer>
        <video
          src="/static/media/video/landing_phone.mp4"
          width="100%"
          height="100%"
          loop
          muted
          autoPlay
          playsInline
          onContextMenu={(event) => event.preventDefault()}
        />
      </VideoContainer>
      <BlockContainer>
        <LandingTitleStyled align="Left">
          Get insights and tips about your answers
        </LandingTitleStyled>
        <LandingInfStyled align="Left">
          Ava provides personalized AI-driven insights, performance analytics, a tailored
          action plan, and communication tips to boost your interview success. Ava&apos;s
          comprehensive approach will give you the tools and confidence you need to shine
          in your interviews.
        </LandingInfStyled>
        <LandingButtonStyled onClick={onStart}>Start Practicing</LandingButtonStyled>
      </BlockContainer>
    </Layout>
  )
}

export default LandingInsightsBlock
