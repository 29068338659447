import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgFeedback({ fill }: SvgProps) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4203 2.95981L14.0637 0.603214C13.5117 0.0518585 12.6174 0.0518585 12.0653 0.603214L0.753682 11.9149C0.324782 12.3438 0.338921 13.3854 0.338921 13.3854V17.1559L4.10947 16.6846C4.61652 16.6679 5.10779 16.5042 5.52343 16.2133L16.4203 4.9582C16.9717 4.40616 16.9717 3.51185 16.4203 2.95981ZM4.10947 15.2707L1.75288 12.9141L13.0645 1.60241L15.4211 3.959L4.10947 15.2707Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgFeedback
