/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import 'react-custom-scroll/dist/customScroll.css'
import { isSuperAdminRole } from 'src/utils/jwtToken'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { IExpertNote } from '../../api'
import { Text } from '../../components'
import wellSaidIcon from '../../static/report_well_said.svg'
import wouldntMentionIcon from '../../static/report_wouldnt_mention.svg'
import rephraseIcon from '../../static/report_rephrase.svg'
import confusedIcon from '../../static/report_confused.svg'
import textIcon from '../../static/report_free_comment.svg'

const getIconAndText = (note: IExpertNote) => {
  switch (note.icon) {
    case 'WELL_SAID':
      return { icon: wellSaidIcon, text: 'Great' }
    case 'CONFUSED':
      return { icon: confusedIcon, text: 'Confusing' }
    case 'REPHRASE':
      return { icon: rephraseIcon, text: 'Rephrase' }
    case 'NOT_MENTION':
      return { icon: wouldntMentionIcon, text: 'Leave out' }
    default:
      return { icon: textIcon, text: note.text }
  }
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;

  ${isMobileOnly &&
  css`
    width: 100%;
  `}
`

interface INoteProps {
  disabled: boolean
  active: boolean
  fill: string
}

const getMoodColor = (mood?: string) => {
  if (!mood) return 'transparent'
  switch (mood) {
    case 'Positive':
      return 'rgba(0, 222, 159, 0.2)'
    default:
      return 'rgba(242, 52, 130, 0.2)'
  }
}

const NoteText = styled(Text)<{ mood?: string }>`
  line-height: normal;
  border-radius: 12px;
  padding: ${({ mood }) => (mood ? 4 : 0)}px;
  background: ${({ mood }) => getMoodColor(mood)};
`
const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const NoteTime = styled.div<{ fill: string; active: boolean; opacity?: number }>`
  font-size: 11px;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: fit-content;
  padding: 1px 4px;
  margin-top: 3px;
  border-radius: 12px;
  background: ${(props) => (props.active ? props.fill : '#f5f6f8')};
  color: ${({ active }) => (active ? 'white' : 'rgb(137, 146, 163)')};
  box-sizing: border-box;
  opacity: ${(props) => props.opacity ?? 1};
`

const NoteContainer = styled.div<INoteProps>`
  display: flex;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: pointer;
  border-bottom: 1px solid ${(props) => (props.active ? props.fill : '#e1e3ea')};
  border-top: ${(props) => (props.active ? `1px solid ${props.fill}` : '0px')};
  word-break: break-word;
  padding: 10px 10px 10px 24px;

  &:hover {
    background-color: #f5f6f8;
  }
`

interface IWrapperProps {
  active: boolean
  fill: string
}

const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  position: relative;
  align-items: center;
  flex: 1;
  margin-right: 6px;

  &:after {
    display: ${({ active }) => (active ? 'block' : 'none')};
    content: '';
    width: 10px;
    height: 10px;
    background: ${({ fill }) => fill};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -17px;
    border-radius: 10px;
  }
`

const NoteIconContainer = styled.div`
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 10px;
`

const NoteIcon = styled.img`
  width: auto;
  vertical-align: unset;
  height: 100%;
`

const TextNoteIcon = styled(NoteIcon)`
  height: 137%;
  margin-left: -5px;
  margin-top: -2px;
`

function Notes({
  notes,
  duration,
  progress,
  accentColor,
  showIcons,
  activeNote,
  onNoteSelected,
}: {
  notes: IExpertNote[]
  duration: any
  progress: any
  accentColor: string
  showIcons?: boolean
  activeNote?: IExpertNote
  onNoteSelected: (note) => void
}) {
  const progressInSec = duration * progress
  const isSuperAdmin = isSuperAdminRole()

  const checkDisabledNote = (note: IExpertNote) => {
    if (!activeNote || activeNote.offset < duration) {
      return (
        Math.floor(note.offset + (note?.duration ?? 5000) / 1000) <
        Math.floor(progressInSec)
      )
    }

    return note.offset < activeNote.offset
  }

  return (
    <Container>
      {notes.map((note) => {
        const { icon, text } = getIconAndText(note)
        const offset = moment
          .duration(note.offset, 'seconds')
          .format('mm:ss', { trim: false })
        return (
          <NoteContainer
            fill={accentColor}
            active={note.id === activeNote?.id}
            disabled={checkDisabledNote(note)}
            key={note.id}
            onClick={() => onNoteSelected(note)}
          >
            <Wrapper active={note.id === activeNote?.id} fill={accentColor}>
              {showIcons && !note.mood && (
                <NoteIconContainer>
                  {!note.icon && <TextNoteIcon src={icon} />}
                  {note.icon && <NoteIcon src={icon} />}
                </NoteIconContainer>
              )}
              <NoteText mood={note.mood}>{text}</NoteText>
            </Wrapper>
            <TimeContainer>
              <NoteTime active={note.id === activeNote?.id} fill={accentColor}>
                {offset}
              </NoteTime>
              {isSuperAdmin && (
                <NoteTime active={false} fill={accentColor} opacity={0.5}>
                  {note.score?.toFixed(2)}
                </NoteTime>
              )}
            </TimeContainer>
          </NoteContainer>
        )
      })}
    </Container>
  )
}

Notes.defaultProps = {
  showIcons: true,
  activeNote: null,
}

export default Notes
