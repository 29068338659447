import React from 'react'
import styled from '@emotion/styled'
import SvgQuote from 'src/static/components/SvgQuote'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  line-height: 150%;

  @media screen and (max-width: 1439px) {
    font-size: 24px;
  }

  @media screen and (max-width: 1144px) {
    font-size: 20px;
  }

  ${isMobileOnly &&
  css`
    font-size: 16px !important;
    padding: 30px 0;
  `}
`

const QuoteContainer = styled.div<{ flip?: boolean }>`
  transform: rotate(${({ flip }) => (flip ? 180 : 0)}deg);
  margin: ${({ flip }) => (flip ? '18px 0 0' : '0 0 18px 0')};
`

interface IProps {
  fill: string
  children?: any
}

const QuotedText = ({ fill, children }: IProps) => {
  return (
    <Container>
      <QuoteContainer>
        <SvgQuote fill={fill} />
      </QuoteContainer>
      {children}
      <QuoteContainer flip>
        <SvgQuote fill={fill} />
      </QuoteContainer>
    </Container>
  )
}

QuotedText.defaultProps = {
  children: null,
}

export default QuotedText
