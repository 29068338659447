/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { css } from '@emotion/core'
import InfoIcon from '../../static/ic-question-circle.png'
import { IMetricsData } from '../types'
import useMetrics from '../services/useMetrics'
import MetricsPushpin from './MetricsPushpin'

// const addColorAlpha = (color: string, opacity: number) => {
//   const dOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
//   const sOpacity = dOpacity.toString(16)
//   return color + (sOpacity.length > 1 ? sOpacity : `0${sOpacity}`)
// }

const MetricsDataContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
`

const MetricItemsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${isMobileOnly &&
  css`
    justify-content: space-between !important;
    @media (orientation: landscape) {
      flex-wrap: wrap;
    }
  `}

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

const MetricItemContainer = styled.div<{
  selected?: boolean
  selectedColor?: string
  itemsCount: number
}>`
  position: relative;
  height: 115px;
  width: ${({ itemsCount }) => 100 / itemsCount - 1}%;
  border: 1px solid ${(props) => (props.selected ? props.selectedColor : '#e1e3ea')};
  border: 1px solid
    ${({ selected, theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return selected ? color : 'transparent'
    }};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  cursor: pointer;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  background: white;

  ${isMobileOnly &&
  css`
    height: 99px !important;
    width: 49% !important;
    margin: 0 0 2% 0 !important;
  `}

  @media screen and (max-width: 375px) {
    height: 87px;
  }

  @media screen and (max-width: 1280px) {
    padding: 12px;
  }

  @media screen and (max-width: 900px) {
    padding: 12px;
    width: 30%;
    margin: 0 3% 3% 0;
  }
`

const MetricsName = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%px;
  margin-right: 4px;
  color: #222b45;
  flex: 1;

  @media screen and (max-width: 375px) {
    font-size: 9px;
  }
`

const MetricsInfoButton = styled.div`
  width: 12px;
  height: 12px;
  margin-top: 2px;
  background: url(${InfoIcon});
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
`

const MetricsInfoContainer = styled.div<{ arrowTo?: 'Left' | 'Right' | 'Middle' }>`
  position: absolute;
  padding: 16px;
  border: 1px solid #b202ff;
  border-radius: 10px;
  top: 39px;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  white-space: break-spaces;
  background: white;
  width: ${isMobileOnly ? 280 : 317}px;
  z-index: 9999;

  ${({ arrowTo }) => {
    switch (arrowTo) {
      case 'Left':
        return css`
          right: -235px;
        `
      case 'Middle':
        return css`
          right: -125px;
        `
      default:
        return css`
          right: ${isMobileOnly ? 0 : -40}px;
        `
    }
  }}

  &:after {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-top: 1px solid #b202ff;
    border-left: 1px solid #b202ff;
    transform: rotate(45deg);
    background: white;
    top: -6px;
    ${({ arrowTo }) => {
      switch (arrowTo) {
        case 'Left':
          return css`
            right: ${isMobileOnly ? 248 : 251}px;
          `
        case 'Middle':
          return css`
            right: 137px;
          `
        default:
          return css`
            right: ${isMobileOnly ? 12 : 55}px;
          `
      }
    }}
  }
`

const MetricsRowContainer = styled.div<{ height: number }>`
  display: flex;
  width: 100%;
  height: ${({ height }) => height}%;
  align-items: flex-start;
`

const MetricsLight = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-top: 4px;
  background: ${({ color }) => color};
  margin-right: 4px;

  @media screen and (max-width: 375px) {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
`

const MetricsLightText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #222b45;
  flex: 1;
  color: #3b424f;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }

  @media screen and (max-width: 375px) {
    font-size: 8px;
    margin-top: 4px;
  }
`

const InsightsContainer = styled.div`
  margin-top: 45px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    margin-top: 0px;
    flex-direction: column-reverse;
  }
`

const InsightsItemContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: ${({ margin }) => margin ?? 0};

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  ${isMobileOnly &&
  css`
    margin-top: 39px !important;
    width: 100%;
  `}
`

const InsightsHeader = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  color: #3b424f;
`

const InsightsText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #3b424f;
`

interface IProps {
  metricsData: IMetricsData[]
}

export default function MetricsInfo({ metricsData }: IProps) {
  const { getMetricsName } = useMetrics()
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' }) && !isMobileOnly

  const [activeTooltipMetricIndex, setActiveTooltipMetricIndex] = useState(-1)
  const [selectedMetricIndex, setActiveMetricIndex] = useState(0)

  const showInfo = (index) => {
    setActiveTooltipMetricIndex(index)
  }

  const getToolTipLocation = (index: number) => {
    if (isMobileOnly) {
      if ([0, 2, 4].some((i) => i === index)) {
        return 'Middle'
      }
      if ([1, 3, 5].some((i) => i === index)) {
        return 'Right'
      }
      return 'Middle'
    }
    return index === 0 || (isSmallScreen && index === 3) ? 'Left' : 'Right'
  }

  return (
    <MetricsDataContainer>
      <MetricItemsContainer>
        {metricsData.map((metrix, index) => (
          <MetricItemContainer
            itemsCount={metricsData.length}
            key={metrix.type}
            selectedColor={metrix.color}
            selected={selectedMetricIndex === index}
            onClick={() => setActiveMetricIndex(index)}
          >
            <MetricsRowContainer height={55}>
              <MetricsName>{getMetricsName(metrix.type)}</MetricsName>
              <MetricsInfoButton
                onMouseEnter={() => showInfo(index)}
                onMouseLeave={() => showInfo(-1)}
              >
                {activeTooltipMetricIndex === index && (
                  <MetricsInfoContainer
                    arrowTo={getToolTipLocation(index)}
                    // style={metricsInfoStyle}
                    // arrowRight={metricsInfoStyle.arrowRight}
                  >
                    {metrix.tooltip}
                  </MetricsInfoContainer>
                )}
              </MetricsInfoButton>
            </MetricsRowContainer>
            <MetricsRowContainer height={45}>
              <MetricsLight color={metrix.color} />
              <MetricsLightText>{metrix.result}</MetricsLightText>
            </MetricsRowContainer>
          </MetricItemContainer>
        ))}
      </MetricItemsContainer>
      {metricsData.length > 0 && (
        <InsightsContainer>
          <InsightsItemContainer>
            <InsightsHeader>Insights</InsightsHeader>
            <InsightsText>{metricsData[selectedMetricIndex].info}</InsightsText>
          </InsightsItemContainer>
          <InsightsItemContainer margin={isMobileOnly ? '5px,0,24px,0' : '0'}>
            <MetricsPushpin metricsData={metricsData[selectedMetricIndex]} />
          </InsightsItemContainer>
        </InsightsContainer>
      )}
    </MetricsDataContainer>
  )
}
