/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useCallback, useState } from 'react'
import MeetImg11 from 'src/static/landing/landing_meet_11.png'
import MeetImg12 from 'src/static/landing/landing_meet_12.png'
import MeetImg13 from 'src/static/landing/landing_meet_13.png'
import MeetImg14 from 'src/static/landing/landing_meet_14.png'
import ImgSessionMock from 'src/static/landing/landing_session_mock.png'
import ImgSessionTechnical from 'src/static/landing/landing_session_technical.png'
import ImgSessionPlanning from 'src/static/landing/landing_session_planning.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import AppearBlock from 'src/interview/components/AppearBlock'
import { BaseLayout, LayoutWrapper } from './landing-styled-components'

const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: column;
  padding: 0px 80px 190px;
  position: relative;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1439px) {
    padding: 0;
  }

  ${isMobileOnly &&
  css`
    padding: 0 16px !important;
  `}
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    align-items: center;
  }
`

const AppearBlockWrapper = styled.div`
  &:first-child {
    margin-bottom: 56px;
  }
`

const SideContainerContent = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;

  @media screen and (max-width: 1439px) {
    align-items: center;
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #b202ff;
  text-transform: uppercase;
  margin-bottom: 8px;

  @media screen and (max-width: 1439px) {
    text-align: center;
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 16px;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 8px !important;
  `}
`

const Header = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #0e0224;
  margin-bottom: 8px;

  @media screen and (max-width: 1439px) {
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    font-size: 24px !important;
    line-height: 32px; !important;
  `}
`

const Text = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  margin-bottom: 40px;

  @media screen and (max-width: 1439px) {
    text-align: center;
    max-width: 624px;
  }

  ${isMobileOnly &&
  css`
    font-size: 14px !important;
    line-height: 20px !important;
    max-width: 100% !important;
    margin-bottom: 24px !important;
  `}
`

const Link = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0e0224;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 40px;
  pointer-events: all;
  width: fit-content;

  @media screen and (max-width: 1439px) {
    text-align: center;
  }
`

const ButtonLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: #b202ff;
  border-radius: 60px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  padding: 12px 24px;
  pointer-events: all;

  @media screen and (max-width: 1439px) {
    align-self: center;
    margin-bottom: 24px;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`

const ImageStyled = styled.img<{
  show: boolean
  width: number
  height: number
  top: number
  right: number
}>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;
  object-fit: contain;
  opacity: ${({ show }) => (show ? 1 : 0)};

  &:nth-child(1) {
    transition: opacity 0.6s linear;
  }

  &:nth-child(2) {
    transition: opacity 0.6s linear 0.4s;
  }

  &:nth-child(3) {
    transition: opacity 0.6s linear 1s;
  }

  &:nth-child(4) {
    transition: opacity 0.6s linear 1.6s;
  }
`

const ImagesContainer = styled.div`
  position: relative;
  width: 560px;
  height: 436px;
  margin-left: 120px;

  @media screen and (max-width: 1439px) {
    margin-left: 0;
  }

  ${isMobileOnly &&
  css`
    width: 315px !important;
    height: 235px !important;
  `}
`

const VideoStyled = styled.video<{ show: boolean }>`
  width: 560px;
  height: auto;
  object-fit: contain;
  margin-left: 120px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.6s linear;

  @media screen and (max-width: 1439px) {
    margin: 40px 0 80px 0;
  }

  @media screen and (max-width: 899px) {
    margin: 40px 0 60px 0;
  }

  ${isMobileOnly &&
  css`
    width: 280px !important;
    height: auto !important;
    margin: 0 0 56px 0 !important;
  `}
`

const SessionTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  ${isMobileOnly &&
  css`
    justify-content: space-between;
  `}
`

const SessionTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 170px;
  margin-right: 20px;

  @media screen and (max-width: 899px) {
    flex-direction: column;
    padding-right: 0;
    margin: 0 32px;
    width: 94px;
  }

  ${isMobileOnly &&
  css`
    width: 30% !important;
    margin: 0 !important;
  `}
`

const SessionTypeImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 30px;
  background: #e0d8fc;
  object-fit: contain;
  padding: 9px;
  overflow: visible;
  margin-right: 12px;

  @media screen and (max-width: 899px) {
    margin: 0 0 16px 0;
  }
`

const SessionTypeLabel = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #0e0224;
  max-width: 110px;

  @media screen and (max-width: 899px) {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }
`

const MeetItems = [
  {
    title: 'NETWORK OF EXPERTS',
    header: 'Meet our 5,000+ experts',
    text:
      'Sharing industry-specific knowledge and insider tips that come from years of practical hiring experience, our network of 5,000+ experts offers one on one tailored guidance on navigating the professional landscape, helping your students outshine their competitors.',
    link: {
      label: `Tap into the power of Wizco's network of world-class experts`,
      url: 'https://coaching.wizco.io/interviews',
    },
    button: {
      label: `Learn more`,
    },
    showSessionTypes: true,
    images: isMobileOnly
      ? [
          { src: MeetImg11, width: 231, height: 190, top: 0, right: 43 },
          { src: MeetImg12, width: 64, height: 138, top: 56, right: 247 },
          { src: MeetImg13, width: 170, height: 102, top: 137, right: 69 },
          { src: MeetImg14, width: 64, height: 10, top: 177, right: 59 },
        ]
      : [
          { src: MeetImg11, width: 361, height: 297, top: 62, right: 92 },
          { src: MeetImg12, width: 100, height: 216, top: 153, right: 410 },
          { src: MeetImg13, width: 266, height: 160, top: 275, right: 133 },
          { src: MeetImg14, width: 100, height: 16, top: 336, right: 100 },
        ],
    video: null,
  },
  {
    title: 'AI-POWERED INTERVIEW COACH',
    header: 'Meet AVA',
    text:
      'Ava is our revolutionary AI-powered interview coach, providing a natural conversational interview experience like never seen before. Powered by data-enriched insights from ten of thousands of successful coaching sessions, Ava offers personalized expert guidance in a cost-effective, scalable, and on-demand solution.',
    link: {
      label: `Discover the future of interview coaching with Ava`,
      url: 'https://ai.wizco.io/ava',
    },
    button: {
      label: `Learn more`,
    },
    showSessionTypes: false,
    images: null,
    video: '/static/media/video/home-ava-meet.mp4',
  },
]

const SessionTypes = [
  { img: ImgSessionMock, label: 'Professional mock-interviews' },
  { img: ImgSessionTechnical, label: 'Technical coding sessions' },
  { img: ImgSessionPlanning, label: 'Career strategy planning' },
]

interface IProps {
  onStart: () => void
}

const LandingMeetUs = ({ onStart }: IProps) => {
  const [linesToShow, setLinesToShow] = useState([])

  const showLine = useCallback(
    (line: number) => {
      const lines = [...linesToShow]
      lines.push(line)
      setLinesToShow(lines)
    },
    [linesToShow]
  )

  return (
    <LayoutWrapper background="#f5f5f7">
      <Layout>
        {MeetItems.map((meetItem, index) => (
          <AppearBlockWrapper key={index}>
            <AppearBlock
              margin={400}
              onAppear={(show) => {
                if (show) {
                  showLine(index)
                }
              }}
            >
              <LineContainer>
                <SideContainerContent>
                  <Title>{meetItem.title}</Title>
                  <Header>{meetItem.header}</Header>
                  <Text>{meetItem.text}</Text>
                  {meetItem.showSessionTypes && (
                    <SessionTypesContainer>
                      {SessionTypes.map((sessionItem) => (
                        <SessionTypeContainer key={sessionItem.label}>
                          <SessionTypeImage
                            src={sessionItem.img}
                            alt="sessionTypeImage"
                          />
                          <SessionTypeLabel>{sessionItem.label}</SessionTypeLabel>
                        </SessionTypeContainer>
                      ))}
                    </SessionTypesContainer>
                  )}
                  <Link href={meetItem.link.url} target="_self">
                    {meetItem.link.label}
                  </Link>
                  <ButtonLink onClick={onStart}>{meetItem.button.label}</ButtonLink>
                </SideContainerContent>
                {meetItem.images && (
                  <ImagesContainer>
                    {meetItem.images.map((image, imIndex) => (
                      <ImageStyled
                        key={imIndex}
                        src={image.src}
                        width={image.width}
                        height={image.height}
                        top={image.top}
                        right={image.right}
                        show={linesToShow.some((l) => l === index)}
                      />
                    ))}
                  </ImagesContainer>
                )}
                {meetItem.video && (
                  <VideoStyled
                    show={linesToShow.some((l) => l === index)}
                    src={meetItem.video}
                    loop
                    autoPlay
                    muted
                    playsInline
                    onContextMenu={(event) => event.preventDefault()}
                  />
                )}
              </LineContainer>
            </AppearBlock>
          </AppearBlockWrapper>
        ))}
      </Layout>
    </LayoutWrapper>
  )
}

export default LandingMeetUs
