import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'

import chevron from 'src/static/chevron-down.svg'

import { Input, Text } from '../../../components'
import { EmailRegex } from '../../../utils/regex'

const Container = styled.div`
  border: 1px solid #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label<{ collapsed: boolean }>`
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: all 200ms;
  }
`

const List = styled.div<{ collapsed: boolean }>`
  border-top: 1px solid #e1e3ea;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  margin-top: 10px;
  padding-top: 10px;
`

const Item = styled.div`
  background-color: #e1e3ea20;
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 10px;
`

const Row = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 24px;

  > div {
    height: 40px;
    margin: 0 5px;

    > input {
      padding-top: 10px;
    }

    > p {
      margin: 0;
      padding-top: 5px;
    }
  }
`

const AddButton = styled(Text)`
  cursor: pointer;
  margin: 0;
  margin-top: 10px;
  text-decoration: underline;
  width: fit-content;
`

const DeleteButton = styled(Text)`
  color: red;
  cursor: pointer;
  margin: 0 10px;
  width: fit-content;
`

interface IProps {
  control: any
  errors: any
}

function AgencyAdmins({ control, errors }: IProps) {
  const [collapsed, setCollapsed] = useState(true)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'agency.agency_admins',
  })

  return (
    <Container>
      <Title collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        Agency Admins
      </Title>
      <List collapsed={collapsed}>
        {fields.map((item, index) => (
          <Item key={item.id}>
            <Row>
              <Controller
                as={Input}
                label="First name"
                name={`agency.agency_admins[${index}].first_name`}
                control={control}
                error={errors.agency?.agency_admins?.[index]?.first_name}
                // rules={{ required: true }}
                defaultValue={item.first_name}
                disabled={item.password === undefined}
              />
              <Controller
                as={Input}
                label="Last name"
                name={`agency.agency_admins[${index}].last_name`}
                control={control}
                error={errors.agency?.agency_admins?.[index]?.last_name}
                // rules={{ required: true }}
                defaultValue={item.last_name}
                disabled={item.password === undefined}
              />
            </Row>

            <Row>
              <Controller
                as={Input}
                label="Email"
                name={`agency.agency_admins[${index}].email`}
                control={control}
                error={errors.agency?.agency_admins?.[index]?.email}
                rules={{
                  required: true,
                  validate: {
                    valid: (value) =>
                      !value || value === 'admin@wizco.io'
                        ? 'Wrong email'
                        : EmailRegex.test(value) ||
                          "That format doesn't look right. Make sure there aren't any typos",
                    unique: (value) =>
                      !fields.some((f) => f.id !== item.id && f.email === value) ||
                      'Email is already added',
                  },
                }}
                defaultValue={item.email}
                disabled={item.password === undefined}
              />
              {item.password !== undefined && (
                <Controller
                  as={Input}
                  label="Password"
                  name={`agency.agency_admins[${index}].password`}
                  type="password"
                  disableWhitespaces
                  control={control}
                  error={errors.agency?.agency_admins?.[index]?.password}
                  rules={{
                    required: true,
                    validate: {
                      letterCase: (value) =>
                        (/[a-z]/.test(value) && /[A-Z]/.test(value)) ||
                        'Password should have one lowercase, one uppercase letter',
                      letterAndDigit: (value) =>
                        (/\d/.test(value) && /[a-zA-Z]/.test(value)) ||
                        'Password should contain a number and a letter',
                      length: (value) =>
                        value.length >= 8 ||
                        'Please make sure your password is at least 8 characters long',
                    },
                  }}
                  defaultValue={item.password}
                />
              )}
            </Row>

            <DeleteButton onClick={() => remove(index)}>Delete</DeleteButton>
          </Item>
        ))}
        <AddButton
          onClick={() =>
            append({ first_name: '', last_name: '', email: '', password: '' })
          }
        >
          + Add
        </AddButton>
      </List>
    </Container>
  )
}

export default AgencyAdmins
