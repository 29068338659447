// eslint-disable-next-line import/prefer-default-export
import eveningIcon from '../static/evening.png'
import noonIcon from '../static/noon.png'
import morningIcon from '../static/morning.png'

export const Languages = [
  'English',
  'Arabic',
  'Bengali',
  'Cantonese',
  'Dutch',
  'French',
  'German',
  'Hebrew',
  'Hindi',
  'Italian',
  'Japanese',
  'Mandarin',
  'Norwegian',
  'Portuguese',
  'Russian',
  'Spanish',
  'Swiss-German',
  'Swedish',
  'Thai',
  'Urdu',
]

export const ApplicantAvailabilityDays = [
  { title: 'Weekdays', name: 'availabilityDays[weekday]' },
  { title: 'Weekend', name: 'availabilityDays[weekend]' },
]

export const ApplicantAvailabilityDaysNew = [
  { title: 'Mon', name: 'availabilityDays[monday]' },
  { title: 'Tue', name: 'availabilityDays[tuesday]' },
  { title: 'Wed', name: 'availabilityDays[wednesday]' },
  { title: 'Thu', name: 'availabilityDays[thursday]' },
  { title: 'Fri', name: 'availabilityDays[friday]' },
  { title: 'Sat', name: 'availabilityDays[saturday]' },
  { title: 'Sun', name: 'availabilityDays[sunday]' },
]

export const ApplicantAvailabilityTimes = [
  {
    time: '8am-12pm',
    info: 'Morning',
    timeMobile: '8-12',
    name: 'availabilityTime[8-12]',
    icon: morningIcon,
  },
  {
    time: '12pm-6pm',
    info: 'Afternoon',
    timeMobile: '12-6',
    name: 'availabilityTime[12-18]',
    icon: noonIcon,
  },
  {
    time: '6pm-10pm',
    info: 'Evening',
    timeMobile: '6-10',
    name: 'availabilityTime[18-22]',
    icon: eveningIcon,
  },
]

export const ApplicantAvailabilityTimesNew = [
  {
    time: '9am-12pm',
    info: '',
    timeMobile: '9-12',
    name: 'availabilityTime[9-12]',
    icon: noonIcon,
  },
  {
    time: '12pm-3pm',
    info: '',
    timeMobile: '12-3',
    name: 'availabilityTime[12-15]',
    icon: noonIcon,
  },
  {
    time: '3pm-6pm',
    info: '',
    timeMobile: '3-6',
    name: 'availabilityTime[15-18]',
    icon: noonIcon,
  },
  {
    time: '6pm-9pm',
    timeMobile: '6-9',
    name: 'availabilityTime[18-21]',
    icon: morningIcon,
  },
  {
    time: '9pm-12am',
    info: '',
    timeMobile: '9-12',
    name: 'availabilityTime[21-24]',
    icon: eveningIcon,
  },
]

export const KippAccounts = [
  { value: 'https://kipp-albany.wizco.io/', label: 'KIPP Albany' },
  {
    value: 'https://kipp-metro-atlanta.wizco.io/interviews',
    label: 'KIPP Metro Atlanta',
  },
  {
    value: 'https://kipp-baltimore.wizco.io/interviews',
    label: 'KIPP Baltimore',
  },
  {
    value: 'https://kipp-chicago.wizco.io/interviews',
    label: 'KIPP Chicago',
  },
  {
    value: 'https://kipp-columbus.wizco.io/interviews',
    label: 'KIPP Columbus',
  },
  {
    value: 'https://kipp-dc.wizco.io/interviews',
    label: 'KIPP DC',
  },
  {
    value: 'https://kipp-delta.wizco.io/interviews',
    label: 'KIPP Delta',
  },
  {
    value: 'https://kipp-indianapolis.wizco.io/interviews',
    label: 'KIPP Indianapolis',
  },
  {
    value: 'https://kipp-jacksonville.wizco.io/interviews',
    label: 'KIPP Jacksonville',
  },
  {
    value: 'https://kipp-kansas-city.wizco.io/interviews',
    label: 'KIPP Kansas City',
  },
  {
    value: 'https://kipp-massachusetts.wizco.io/interviews',
    label: 'KIPP Massachusetts',
  },
  {
    value: 'https://kipp-memphis.wizco.io/interviews',
    label: 'KIPP Memphis',
  },
  {
    value: 'https://kipp-minnesota.wizco.io/interviews',
    label: 'KIPP Minnesota',
  },
  {
    value: 'https://kipp-nashville.wizco.io/interviews',
    label: 'KIPP Nasvhille',
  },
  {
    value: 'https://kipp-new-jersey.wizco.io/interviews',
    label: 'KIPP New Jersey',
  },
  {
    value: 'https://kipp-new-orleans.wizco.io/interviews',
    label: 'KIPP New Orleans',
  },
  {
    value: 'https://kipp-north-carolina.wizco.io/interviews',
    label: 'KIPP NorCal',
  },
  {
    value: 'https://kipp-metro-atlanta.wizco.io/interviews',
    label: 'KIPP North Carolina',
  },
  {
    value: 'https://kipp-nyc.wizco.io/interviews',
    label: 'KIPP NYC',
  },
  {
    value: 'https://kipp-oklahoma-city.wizco.io/interviews',
    label: 'KIPP Oklahoma City',
  },
  {
    value: 'https://kipp-philadelphia.wizco.io/interviews',
    label: 'KIPP Philadelphia',
  },
  {
    value: 'https://kipp-so-cal.wizco.io/interviews',
    label: 'KIPP SoCal',
  },
  {
    value: 'https://kipp-st-louis.wizco.io/interviews',
    label: 'KIPP St. Louis',
  },
  {
    value: 'https://kipp-tulsa.wizco.io/interviews',
    label: 'KIPP Tulsa',
  },
  {
    value: 'https://kipp-texas.wizco.io/interviews',
    label: 'KIPP Texas',
  },
]
