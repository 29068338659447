/* eslint-disable no-shadow */
import React from 'react'
import styled from '@emotion/styled'
import { IMicrophoneAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'

import audioMutedIcon from 'src/static/candidate_cockpit_muted.png'
import peopleIcon from 'src/static/icUser.png'
import SvgAudioMute from 'src/static/components/SvgAudioMute'

import Volume from '../volume'
import { Text as MobileText } from '../MobileText'

import VideoResizeContainer from './VideoResizeContainer'
import VideoMutedOverlay from './VideoMutedOverlay'
import { RemoteNetworkQualityIndicator } from '../volume/NetworkQualityIndicator'

const Video = styled.div<{ hidden?: boolean }>`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  opacity: ${({ hidden }) => (hidden ? '0' : '100')}%;

  div {
    background-color: ${isMobileOnly ? 'transparent' : '#353c5a'} !important;
    border-radius: ${isMobileOnly ? '0' : '0.75rem'} !important;

    video {
      border-radius: ${isMobileOnly ? '0' : '0.75rem'} !important;
    }
  }
`

const MuteIcon = styled.img`
  background: black;
  border-radius: 50%;
  height: 100%;
  opacity: 0.8;
`

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  justify-content: center;

  top: 22px;
  height: 60px;
  right: 30px;
  width: 60px;
  @media screen and (max-width: 2500px) {
    top: 18px;
    height: 50px;
    right: 25px;
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    top: 15px;
    height: 40px;
    right: 20px;
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    top: 11px;
    height: 30px;
    right: 15px;
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    top: 7px;
    height: 20px;
    right: 10px;
    width: 20px;
  }
`

const LeftTopContainer = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  justify-content: center;

  top: 22px;
  height: 45px;
  left: 30px;
  width: 60px;
  @media screen and (max-width: 2500px) {
    top: 18px;
    height: 37.5px;
    left: 25px;
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    top: 15px;
    height: 30px;
    left: 20px;
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    top: 11px;
    height: 22.5px;
    left: 15px;
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    top: 7px;
    height: 15px;
    left: 10px;
    width: 20px;
  }
`

const MobileMuteOverlay = styled.div`
  align-items: center;
  background-color: #353c5a;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const MobileUsername = styled(MobileText)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`

const MobileButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 24px;
`

const MobileLeftTopContainer = styled.div`
  align-items: center;
  display: flex;
  height: 18px;
  position: absolute;
  left: 10px;
  top: 8px;
  width: 24px;
`

const MobileAvatarLogo = styled.img`
  border-radius: 50px;
  height: 100px;
  margin-bottom: 12px;
  width: 100px;
`

const MobileAvatarDefault = styled.img`
  background: white;
  border-radius: 50px;
  height: 100px;
  margin-bottom: 12px;
  padding: 35px;
  width: 100px;
`

interface IRemoteVideo extends React.HTMLProps<HTMLDivElement> {
  audioMuted: boolean
  id: string
  videoMuted: boolean
  track: IMicrophoneAudioTrack | IRemoteAudioTrack
  username: string
  dimensions: {
    height: number
    width: number
  }
  logo: string
  hasScreenshare: boolean
}

export const RemoteVideo = ({
  audioMuted,
  id,
  videoMuted,
  track,
  username,
  dimensions: { height, width },
  logo,
  children,
  hasScreenshare,
}: IRemoteVideo) => {
  return (
    <VideoResizeContainer height={height} width={width}>
      <Video id={id} hidden={videoMuted} />
      {videoMuted && <VideoMutedOverlay hasOverlay logo={logo} username={username} />}
      {!hasScreenshare && (
        <LeftTopContainer>
          <RemoteNetworkQualityIndicator />
        </LeftTopContainer>
      )}
      <ButtonsContainer>
        {!audioMuted && track && (
          <Volume.AudioVolumeIndicator element={undefined} track={track} />
        )}
        {audioMuted && <MuteIcon src={audioMutedIcon} />}
      </ButtonsContainer>
      {children}
    </VideoResizeContainer>
  )
}

interface IMobileRemoteVideo {
  audioMuted: boolean
  id: string
  videoMuted: boolean
  track: IMicrophoneAudioTrack | IRemoteAudioTrack
  username: string
  dimensions: {
    height: number
    width: number
  }
  logo: string
  hasScreenshare: boolean
}

export const MobileRemoteVideo = ({
  audioMuted,
  id,
  videoMuted,
  track,
  username,
  dimensions: { height, width },
  logo,
  hasScreenshare,
}: IMobileRemoteVideo) => {
  return (
    <VideoResizeContainer height={height} width={width}>
      <Video id={id} hidden={videoMuted} />
      {videoMuted && (
        <>
          <MobileMuteOverlay>
            {logo ? (
              <MobileAvatarLogo src={logo} />
            ) : (
              <MobileAvatarDefault src={peopleIcon} />
            )}
            <MobileUsername>{username}</MobileUsername>
          </MobileMuteOverlay>
        </>
      )}
      {!hasScreenshare && (
        <MobileLeftTopContainer>
          <RemoteNetworkQualityIndicator />
        </MobileLeftTopContainer>
      )}
      <MobileButtonsContainer>
        {!audioMuted && track && (
          <Volume.AudioVolumeIndicator element={undefined} track={track} />
        )}
        {audioMuted && <SvgAudioMute />}
      </MobileButtonsContainer>
    </VideoResizeContainer>
  )
}
