import React from 'react'
import styled from '@emotion/styled'
// import colors from '../../static/colors'

interface IListProps {
  data: string[]
}

const Container = styled.div`
  margin-bottom: 30px;
`

const ListComponent = styled.ul`
  list-style: none;
  padding-left: 0;
`

const ListItem = styled.li`
  color: #3b424f;
  font-size: 12px;
  margin-bottom: 12px;
  line-height: 24px;

  &::before {
    background-color: #b202ff;
    border-radius: 50%;
    content: '';
    display: inline-block;
    margin-right: 15px;
    height: 10px;
    width: 10px;
  }
`

function List({ data }: IListProps) {
  return (
    <Container>
      <ListComponent>
        {data.map((text) => (
          <ListItem key={text}>{text}</ListItem>
        ))}
      </ListComponent>
    </Container>
  )
}

export default List
