export const validateEmail = (val: string) => {
  if (!val) return false

  return val
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const checkPersonalEmail = (val: string) => {
  if (!val) return false

  return val
    .toLowerCase()
    .match(
      /(@gmail.com)|(@yahoo.com)|(@outlook.com)|(@aol.com)|(@hotmail.com)|(@icloud.com)|(@me.com)|(@mac.com)/
    )
}

export const checkUrl = (val: string): boolean => {
  if (!val) return false
  const trimValue = val.trim()
  const matches = val
    .toLocaleLowerCase()
    .match(
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/
    )
  if (matches?.length > 0) {
    return trimValue === matches[0]
  }
  return false
}
