export default {
  title: '#222B45',
  subtitle: '#8F9BB3',
  formHint: '#3b424f',
  link: '#3B424F',
  formBorderColor: '#e4e9f2',
  formBackgroundColor: '#edeef2',
  formActiveBackgroundColor: '#fff',
  formActiveAccentColor: '#B202FF',
  formButtonTextColor: '#fff',
  formDisabledBackgroundColor: 'rgba(178,2,255,0.25)',
}
