/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { IAgoraRTC } from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'

import speakerIcon from 'src/static/icSpeaker.png'
import successIcon from 'src/static/icSuccess.png'
import failedIcon from 'src/static/icFailed.png'
import micIcon from 'src/static/icMic.png'
import supportIcon from 'src/static/icSupport.png'
import videoIcon from 'src/static/icVideo.png'
import connectionIcon from 'src/static/icConnection.png'

import { MediaDeviceGroup } from '../../services/useMediaDevices'

import ProgressView from '../progress'
import MicrophoneTestView from './microphoneTestView'
import SpeakerTestView from './speakerTestView'
import PrecallTestFailView from './precallTestFail'
import CameraTestView from './cameraTestView'
import ConnectionTestView from './connectionTestView'
import FinishTestView from './finishTestView'
import { ISettings } from '../../interfaces'

const Container = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem;
  position: relative;
`

const MobileContainer = styled.div`
  align-items: flex-start;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  position: relative;
  padding: 28px 24px 0 24px;

  @media (orientation: landscape) {
    height: 100%;
    margin: 0;
    padding: 16px 0 0 0;
  }
`

const PrecallContainer = ({ children }: React.HTMLProps<HTMLDivElement>) => {
  return !isMobileOnly ? (
    <Container>{children}</Container>
  ) : (
    <MobileContainer>{children}</MobileContainer>
  )
}

const TitleIcon = styled.img`
  width: auto;

  ${!isMobileOnly &&
  css`
    width: 21px;
    @media screen and (max-width: 2500px) {
      width: 18px;
    }
    @media screen and (max-width: 2000px) {
      width: 14px;
    }
    @media screen and (max-width: 1200px) {
      width: 11px;
    }
    @media screen and (max-width: 900px) {
      width: 7px;
    }
  `}
`
const SupportIcon = styled.img`
  width: 21px;
  margin-right: 6px;

  ${!isMobileOnly &&
  css`
    width: 31px;
    margin-right: 9px;
    @media screen and (max-width: 2500px) {
      width: 26px;
      margin-right: 7px;
    }
    @media screen and (max-width: 2000px) {
      width: 21px;
      margin-right: 6px;
    }
    @media screen and (max-width: 1200px) {
      width: 16px;
      margin-right: 4px;
    }
    @media screen and (max-width: 900px) {
      width: 10px;
      margin-right: 3px;
    }
  `}
`
const Header = styled.div`
  display: flex;
  align-items: center;

  @media (orientation: landscape) {
    padding: 0 5%;
  }
`
const Title = styled.div<{ isFinal?: boolean }>`
  color: ${(props) => (props.isFinal ? '#fff' : '#737991')};
  margin-left: 8px;

  ${!isMobileOnly &&
  css`
    color: #737991;
    font-size: 24px;
    margin-left: 11px;
    @media screen and (max-width: 2500px) {
      font-size: 20px;
      margin-left: 9px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 16px;
      margin-left: 7px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 12px;
      margin-left: 5px;
    }
    @media screen and (max-width: 900px) {
      font-size: 8px;
      margin-left: 3px;
    }
  `}
`
const ProgressUnderline = styled.div`
  background: #e1e3ea;
  height: 1px;
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
`
const Progress = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (orientation: landscape) {
    padding: 0 5%;
  }
`
const ProgressLabel = styled.div`
  color: #e1e3ea;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    margin: 0 18px 24px 6px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      margin: 0 15px 20px 6px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      margin: 0 12px 16px 4px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      margin: 0 9px 12px 3px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      margin: 0 6px 8px 2px;
    }
  `}

  ${isMobileOnly &&
  css`
    font-size: 12px;
    margin: 0 12px 16px 4px;
  `}
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  margin: 12px 0;
  width: 100%;

  ${!isMobileOnly &&
  css`
    margin: 18px 0;
    @media screen and (max-width: 2500px) {
      margin: 15px 0;
    }
    @media screen and (max-width: 2000px) {
      margin: 12px 0;
    }
    @media screen and (max-width: 1200px) {
      margin: 9px 0;
    }
    @media screen and (max-width: 900px) {
      margin: 6px 0;
    }
  `}
`

const Button = styled.button<{
  transparent?: boolean
  long?: boolean
  disabled?: boolean
}>`
  height: 32px;
  width: ${(props) => (props.long ? 96 : 61)}px;
  min-height: 32px;
  border-radius: 14px;
  border: 1px solid #6938aa;
  color: ${(props) => (props.transparent ? '#6938AA' : '#fff')};
  background-color: ${(props) =>
    props.transparent ? (!isMobileOnly ? 'transparent' : '#F4DCFF') : '#6938AA'};
  font-weight: 1000;
  outline: none;
  font-size: 13px;
  margin: 0 10px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  padding: 0;

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: default;
      opacity: 0.5;
    }
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    !isMobileOnly &&
    css`
      height: 48px;
      width: ${props.long ? 144 : 92}px;
      min-height: 48px;
      border-radius: 21px;
      font-size: 20px;
      margin: 0 15px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
      @media screen and (max-width: 2500px) {
        height: 40px;
        width: ${props.long ? 120 : 76}px;
        min-height: 40px;
        border-radius: 18px;
        font-size: 16px;
        margin: 0 13px;
      }
      @media screen and (max-width: 2000px) {
        height: 32px;
        width: ${props.long ? 96 : 61}px;
        min-height: 32px;
        border-radius: 14px;
        font-size: 13px;
        margin: 0 10px;
      }
      @media screen and (max-width: 1200px) {
        height: 24px;
        width: ${props.long ? 72 : 46}px;
        min-height: 24px;
        border-radius: 11px;
        font-size: 10px;
        margin: 0 8px;
      }
      @media screen and (max-width: 900px) {
        height: 16px;
        width: ${props.long ? 48 : 31}px;
        min-height: 16px;
        border-radius: 7px;
        font-size: 6px;
        margin: 0 5px;
      }
    `}
`
const SupportButton = styled.div`
  display: flex;
  justify-content: center;
  color: #b202ff;
  background: transparent;
  border: transparent;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  align-self: center;
  margin: 10px 0;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: default;
      opacity: 0.5;
    }
  }

  &:focus {
    outline: none;
  }

  ${() =>
    !isMobileOnly &&
    css`
      font-size: 20px;
      margin: 0 15px;
      @media screen and (max-width: 2500px) {
        font-size: 16px;
        margin: 0 13px;
      }
      @media screen and (max-width: 2000px) {
        font-size: 13px;
        margin: 0 10px;
      }
      @media screen and (max-width: 1200px) {
        font-size: 10px;
        margin: 0 8px;
      }
      @media screen and (max-width: 900px) {
        font-size: 7px;
        margin: 0 5px;
      }
    `}
`

const OverflowContainer = styled.div`
  overflow: auto;
  width: 100%;

  @media (orientation: landscape) {
    padding: 0 5%;
  }
`

interface IStepInfo {
  element: JSX.Element
  title: string
  icon: string
  failHeader: string
  failText1: string
  failText2: string
}

interface IProps {
  agoraRTC: IAgoraRTC
  devices: MediaDeviceGroup[]
  settings: ISettings
  changeCamera: (deviceId: string) => void
  changeMicrophone: (deviceId: string) => void
  changeSpeaker: (deviceId: string) => void
  onClose: (boolean) => void
  onShowSupport: () => void
}

const stepCount = 4
const PrecallTestView = ({
  agoraRTC,
  devices,
  settings,
  changeCamera,
  changeMicrophone,
  changeSpeaker,
  onClose,
  onShowSupport,
}: IProps) => {
  const [step, setStep] = useState<number>(0)
  const [isFail, setIsFail] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const failedSteps = useRef<number[]>([])
  const [isFailed, setIsFailed] = useState(false)

  function goNext(failedStep?: number) {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(failedStep)) {
      failedSteps.current.push(failedStep)
    }
    if (step + 1 === stepCount) {
      if (isFinish) {
        onClose(true)
      } else {
        setIsFinish(true)
        setIsFailed(failedSteps.current.length > 0)
      }
    } else {
      setStep((v) => v + 1)
      setIsFail(false)
    }
  }

  const Steps: IStepInfo[] = [
    {
      element: (
        <MicrophoneTestView
          agoraRTC={agoraRTC}
          devices={devices}
          deviceId={settings.microphoneId}
          changeMicrophone={changeMicrophone}
        />
      ),
      title: 'Microphone',
      icon: micIcon,
      failHeader: 'Microphone not working?',
      failText1:
        'You may need to restart your browser and/or computer then try this test again.',
      failText2:
        'Please continue with all tests. At the end, you will be able to contact our support team to help you troubleshoot any issues.',
    },
    {
      element: (
        <SpeakerTestView
          agoraRTC={agoraRTC}
          devices={devices}
          deviceId={settings.speakerId}
          changeSpeaker={changeSpeaker}
        />
      ),
      title: 'Speaker',
      icon: speakerIcon,
      failHeader: 'Audio not working?',
      failText1:
        'Please ensure your computer and browser are properly connected to speakers and your computer volume is turned up.',
      failText2:
        'Please continue with all tests. At the end, you will be able to contact our support team to help you troubleshoot any issues.',
    },
    {
      element: (
        <CameraTestView
          agoraRTC={agoraRTC}
          devices={devices}
          deviceId={settings.cameraId}
          changeCamera={changeCamera}
        />
      ),
      title: 'Video',
      icon: videoIcon,
      failHeader: 'Can`t see your video?',
      failText1:
        'If you can`t see your video feed there may be a problem with your camera or the connection to our video services. Please ensure you have a working, plugged-in camera, and internet connection.',
      failText2:
        'Please continue with all tests. At the end, you will be able to contact our support team to help you troubleshoot any issues.',
    },
    {
      element: (
        <ConnectionTestView
          agoraRTC={agoraRTC}
          onContinue={(res) => {
            if (res) {
              goNext()
            } else {
              goNext(step)
            }
          }}
        />
      ),
      title: 'Connection',
      icon: connectionIcon,
      failHeader: '',
      failText1: '',
      failText2: '',
    },
  ]

  function onFail() {
    setIsFail(true)
  }

  function onRetry(restart?: boolean) {
    setIsFail(false)
    if (restart) {
      setStep(0)
      setIsFail(false)
      setIsFinish(false)
      failedSteps.current = []
    }
  }

  return (
    <PrecallContainer>
      <Header>
        <TitleIcon
          // eslint-disable-next-line no-nested-ternary
          src={!isFinish ? Steps[step].icon : isFailed ? failedIcon : successIcon}
        />
        <Title isFinal={isFinish}>
          {!isFinish
            ? Steps[step].title
            : `Testing ${isFailed ? 'failed' : 'successfull'}`}
        </Title>
      </Header>
      <Progress>
        <ProgressUnderline />
        <ProgressView
          value={0.25 * (step + 1)}
          // eslint-disable-next-line no-nested-ternary
          color={isFinish ? (isFailed ? '#EB3337' : '#00DE9F') : '#6938aa'}
        />
        <ProgressLabel>{`${step + 1} / 4`}</ProgressLabel>
      </Progress>
      <OverflowContainer>
        {!isFinish ? (
          <>
            {isFail ? (
              <>
                <PrecallTestFailView
                  header={Steps[step].failHeader}
                  text1={Steps[step].failText1}
                  text2={Steps[step].failText2}
                />
                <ButtonsContainer>
                  <Button transparent long onClick={() => onRetry()}>
                    Retry test
                  </Button>
                  <Button onClick={() => goNext(step)} long>
                    Continue
                  </Button>
                </ButtonsContainer>
              </>
            ) : (
              <>
                {Steps[step].element}
                {step !== 3 && (
                  <ButtonsContainer>
                    <Button transparent onClick={onFail}>
                      No
                    </Button>
                    <Button onClick={() => goNext()}>Yes</Button>
                  </ButtonsContainer>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <FinishTestView failedSteps={failedSteps.current} />
            <ButtonsContainer>
              {isFailed ? (
                <Button long onClick={() => onRetry(true)}>
                  Retry test
                </Button>
              ) : (
                <Button long onClick={() => goNext(step)}>
                  Join now
                </Button>
              )}
            </ButtonsContainer>
            {isFailed && (
              <SupportButton onClick={onShowSupport}>
                <SupportIcon src={supportIcon} />
                Contact support
              </SupportButton>
            )}
          </>
        )}
      </OverflowContainer>
    </PrecallContainer>
  )
}

export default PrecallTestView
