import styled from '@emotion/styled'
import React, { useRef, useEffect } from 'react'
import { Text } from 'src/components/Typography'
import SvgCode from '../../static/components/SvgCode'
import {
  DataIconContainer,
  DataIcon,
  DataTitle,
  DataTitleArrow,
  DataBody,
} from './SharedStyledComponents'

const SessionContainerStyled = styled.div`
  border-radius: 20px;
  background-color: #fff;
  width: 100%;
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    height: auto;
    border-radius: 5px;
  }
`

const SessionContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 29px;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`

const SessionDataHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  @media screen and (max-width: 600px) {
    padding: 0px 16px 0;
  }
`
const SessionDataSubheader = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  margin-bottom: 12px;
  padding: 0 30px;

  @media screen and (max-width: 600px) {
    justify-content: center;
    padding: 0;
    margin-top: 10px;
  }
`

const CodeLinkContainer = styled.div`
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), -3px 13px 15px rgba(0, 0, 0, 0.07);
  display: flex;
  gap: 6px;
  height: 32px;
  justify-content: center;
  padding: 0 12px;

  @media screen and (max-width: 600px) {
    flex: 1;
  }
`

const CodeLinkLabel = styled(Text)`
  cursor: default;
`

const CodeLink = styled.a`
  color: #b202ff;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  text-decoration: underline;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 18px;
  }
`

export default function SessionDataContainer({
  icon,
  title,
  children,
  expanded = true,
  codingPlaybackUrl,
  handleExpandedTab,
  fixed,
}: {
  icon: any
  title: string
  children: any
  expanded?: boolean
  codingPlaybackUrl: string
  handleExpandedTab?: (string) => void
  fixed?: boolean
}) {
  const dataRef = useRef(null)
  const handleHidden = () => {
    if (fixed) return
    if (expanded) {
      dataRef.current?.classList.add('hidden')
      setTimeout(() => {
        handleExpandedTab(title)
      }, 300)
    } else {
      dataRef.current?.classList.remove('hidden')
      handleExpandedTab(title)
    }
  }

  useEffect(() => {
    dataRef.current?.classList.remove('hidden')
  }, [expanded])
  return (
    <SessionContainerStyled>
      <SessionContainerBody>
        <SessionDataHeader onClick={() => handleHidden()}>
          <DataIconContainer>
            <DataIcon src={icon} />
          </DataIconContainer>
          <DataTitle>
            {title}
            {!fixed && <DataTitleArrow expanded={expanded} />}
          </DataTitle>
        </SessionDataHeader>
        {codingPlaybackUrl && (expanded || fixed) && (
          <SessionDataSubheader>
            <CodeLinkContainer>
              <SvgCode fill="#00de9f" />
              <CodeLinkLabel>View coding playback.</CodeLinkLabel>
              <CodeLink href={codingPlaybackUrl} target="_blank">
                View playback
              </CodeLink>
            </CodeLinkContainer>
          </SessionDataSubheader>
        )}
        <DataBody expanded={expanded || fixed} ref={dataRef}>
          {children}
        </DataBody>
      </SessionContainerBody>
    </SessionContainerStyled>
  )
}

SessionDataContainer.defaultProps = {
  expanded: false,
  handleExpandedTab: () => {},
  fixed: false,
}
