import styled from '@emotion/styled'
import { format } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BusinessModel, IInterviewPositionState } from '../../api'
import { Input, Button, TextBold, Text, Textarea, Select } from '../../components'
import { BookingStatus, updateBookingStatusAction } from '../../redux'
import { Languages } from '../../utils/staticData'
import useLogrocket from '../../utils/useLogrocket'

import { saveBookingPosition } from '../actions'
import CompanyDetails from '../components/CompanyDetails'
import PositionDetailsForm from '../components/PositionDetailsForm'
import PriceTiers from '../components/PriceTiers'
import Layout from '../Layout'
import * as interviewSelectors from '../selectors'

const Form = styled.form`
  margin-top: 20px;
`

const TextBoldStyled = styled(TextBold)`
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  & button {
    border: none;
    background: none;
    font-weight: 500;
    text-decoration: underline;
    margin-left: auto;
  }

  & span {
    font-size: 12px;
    color: grey;
    margin-left: 23px;
    font-weight: normal;

    @media screen and (max-width: 600px) {
      margin-left: 11px;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 40px;
`
const OptionalLabel = styled.span`
  display: inline-block;
  line-height: 20px;
  opacity: 0.5;
  color: #21304e !important;
  font-size: 11px !important;
  letter-spacing: 0.5px;
  pointer-events: none;
  margin-left: 20px;
  transition: opacity 150ms ease-out;
  font-weight: 500 !important;
`

const today = new Date()

export const SearchPeriodContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -11px 0 20px;
`

export const SearchPeriodIconContainer = styled.div`
  width: 24px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 15px;
`

export const SearchPeriodIcon = styled.svg`
  width: auto;
  height: 100%;
  transform: rotate(180deg);
  vertical-align: unset;
`

export const SearchPeriodText = styled(Text)`
  line-height: 19px;
`
const TextHint = styled.div`
  color: grey;
  font-size: 12px;
  margin: 0 20px;

  @media screen and (max-width: 600px) {
    margin: 20px 0 0;
    color: #3b424f;
  }
`

const FilledBlock = styled.div`
  position: relative;
  margin-bottom: 40px;
`

const FilledBlockRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c1c8d5;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 7px;

  & span {
    font-weight: 500;
    color: grey;
  }
`

const FilledBlockRowText = styled(TextBold)`
  font-size: 12px;
  min-width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 62%;
  @media screen and (max-width: 600px) {
    min-width: auto;
  }
`

function PositionDetails() {
  const dispatch = useDispatch()
  const { setLogrocket } = useLogrocket()
  const { interviewToken } = useParams()
  const { profile, interview } = useSelector(interviewSelectors.interview)
  const { r_and_d, business_model, special_request: specialRequest } = useSelector(
    interviewSelectors.agency
  )
  const { handleSubmit, errors, control, watch } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { name, date, haveCompany, coachingLanguage, company, priceTears } = watch()

  const hasFormErrors = Object.keys(errors).length > 0
  const inSearchPeriod = date && moment(date).isBefore(moment(today).add(5, 'days'))
  const options = [
    { value: 'yes', label: 'Yes, I have a specific company in mind.' },
    { value: 'no', label: "No, I'm open to opportunities." },
  ]
  const languageList = Languages.map((l) => {
    return { value: l, label: l }
  })

  const fieldsWereFilled = interview.desired_position || interview.position_company
  const requiredFieldsFilled =
    !!name &&
    !!haveCompany &&
    !!coachingLanguage &&
    (haveCompany === 'yes' ? !!company : true) &&
    (business_model === BusinessModel.B2C ? priceTears?.length > 0 : true)
  const disabled = !fieldsWereFilled && !requiredFieldsFilled
  const [editMode, setEditMode] = useState<boolean>(false)
  const handleChangeMode = () => setEditMode(!editMode)

  const dateRes =
    (interview.interview_date && new Date(interview.interview_date).toString()) ||
    date ||
    ''

  const onSubmit = (payload: IInterviewPositionState) => {
    const position: IInterviewPositionState = {
      firstName: profile.first_name,
      lastName: profile.last_name,
      desiredPosition: interview.desired_position || '',
      company: interview.position_company || '',
      date: dateRes,
      link_to_position: interview.link_to_position || '',
      ...payload,
    }

    dispatch(
      saveBookingPosition({
        interviewToken,
        position,
      })
    )

    dispatch(updateBookingStatusAction(BookingStatus.Student))
  }

  useEffect(() => {
    if (interview) {
      setLogrocket(
        `${interview?.order_number.toString()}`,
        'Applicant order form - first page'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  return (
    <Layout
      title="Tell us a little about your needs"
      subtitle="This information will help us find the best possible match"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          {fieldsWereFilled && (
            <>
              <TextBoldStyled>
                YOUR DETAILS{' '}
                <span>
                  <span> </span>Filled by your career advisor
                </span>
                {!editMode && (
                  <button onClick={handleChangeMode} type="button">
                    Edit
                  </button>
                )}
              </TextBoldStyled>

              {editMode && (
                <FilledBlock>
                  <PositionDetailsForm
                    inSearchPeriod={inSearchPeriod}
                    fieldsWereFilled={!!fieldsWereFilled}
                    control={control}
                    interview={interview}
                    profile={profile}
                    errors={errors}
                  />
                </FilledBlock>
              )}

              {!editMode && (
                <FilledBlock>
                  <FilledBlockRow>
                    Name:{' '}
                    <FilledBlockRowText>
                      {profile.first_name} {profile.last_name}
                    </FilledBlockRowText>
                  </FilledBlockRow>
                  <FilledBlockRow>
                    Email: <FilledBlockRowText>{profile.email}</FilledBlockRowText>
                  </FilledBlockRow>
                  <FilledBlockRow>
                    Desired Position:{' '}
                    <FilledBlockRowText>{interview.desired_position}</FilledBlockRowText>
                  </FilledBlockRow>
                  {interview.programming_language && (
                    <FilledBlockRow>
                      Programming language:{' '}
                      <FilledBlockRowText>
                        {interview.programming_language}
                      </FilledBlockRowText>
                    </FilledBlockRow>
                  )}
                  <FilledBlockRow>
                    Company:{' '}
                    <FilledBlockRowText>{interview.position_company}</FilledBlockRowText>
                  </FilledBlockRow>

                  {interview.link_to_position && (
                    <FilledBlockRow>
                      Link to Position:{' '}
                      <FilledBlockRowText>
                        <a href={interview.link_to_position}>
                          {interview.link_to_position}
                        </a>
                      </FilledBlockRowText>
                    </FilledBlockRow>
                  )}

                  {interview.interview_date && (
                    <FilledBlockRow>
                      Job Interview Date:{' '}
                      <FilledBlockRowText>
                        {format(new Date(interview.interview_date), 'dd.MM.yyyy')}
                      </FilledBlockRowText>
                    </FilledBlockRow>
                  )}
                </FilledBlock>
              )}
            </>
          )}

          {r_and_d ? (
            <>
              <TextBoldStyled>
                YOUR COACHING SESSION
                {fieldsWereFilled && <OptionalLabel>Optional</OptionalLabel>}
              </TextBoldStyled>
              {!fieldsWereFilled && (
                <>
                  <Controller
                    as={Input}
                    label="Desired job title"
                    name="name"
                    extended
                    control={control}
                    error={errors.name}
                    rules={{ required: !fieldsWereFilled }}
                    placeholder="E.g. Senior full-stack engineer"
                    defaultValue=""
                    useTheme
                  />

                  <Controller
                    as={Input}
                    label="Programming language"
                    name="programmingLanguage"
                    extended
                    optional={!fieldsWereFilled}
                    placeholder="Programming languages you'd like to focus on"
                    control={control}
                    defaultValue=""
                    useTheme
                  />
                </>
              )}
            </>
          ) : (
            <>
              <TextBoldStyled>
                YOUR COACHING SESSION
                {fieldsWereFilled && <OptionalLabel>Optional</OptionalLabel>}
              </TextBoldStyled>
              {!fieldsWereFilled && (
                <Controller
                  as={Input}
                  label="Desired position"
                  name="name"
                  control={control}
                  error={errors.name}
                  rules={{ required: !fieldsWereFilled }}
                  defaultValue=""
                  useTheme
                />
              )}
            </>
          )}

          <Controller
            as={Textarea}
            label={specialRequest || 'Any special requests for your coach?'}
            name="request"
            control={control}
            error={errors.request}
            defaultValue=""
            useTheme
            placeholder={
              !specialRequest
                ? 'Tell us if there’s anything you’d like to share with your coach before the session.'
                : ''
            }
          />
          <Controller
            name="coachingLanguage"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value, name: selectName }) => (
              <Select
                name={selectName}
                label="Coaching language"
                onChange={onChange}
                value={value ?? ' '}
                options={languageList}
                useTheme
                error={errors.coachingLanguage}
                marginBottom={20}
              />
            )}
            defaultValue={languageList[0].value}
            useTheme
          />
          {!fieldsWereFilled && (
            <>
              <Controller
                name="haveCompany"
                control={control}
                rules={{ required: !fieldsWereFilled }}
                render={({ onChange, value, name: selectName }) => (
                  <Select
                    name={selectName}
                    label="Would you like to be coached for a specific company?"
                    onChange={onChange}
                    // rules={{required: true}}
                    value={value ?? ' '}
                    options={options}
                    useTheme
                    error={errors.haveCompany}
                  />
                )}
                defaultValue=""
                useTheme
              />
              <TextHint>This information helps us optimize your expert search</TextHint>
            </>
          )}
        </FormGroup>
        <CompanyDetails
          date={interview.interview_date}
          fieldsWereFilled={!!fieldsWereFilled}
          control={control}
          errors={errors}
          showLinkToPosition={!fieldsWereFilled || !interview.link_to_position}
          showInterviewDate={!interview.interview_date}
          inSearchPeriod={inSearchPeriod}
        />
        {business_model === BusinessModel.B2C && (
          <Controller
            name="priceTears"
            control={control}
            rules={{ required: !fieldsWereFilled }}
            render={({ onChange }) => <PriceTiers onChange={onChange} />}
            defaultValue={[]}
            useTheme
          />
        )}
        <Button
          disabled={fieldsWereFilled ? hasFormErrors : disabled || hasFormErrors}
          useTheme
          title="Continue"
        />
      </Form>
    </Layout>
  )
}

export default PositionDetails
