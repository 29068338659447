import { put, takeEvery, call } from 'typed-redux-saga'
import { push } from 'connected-react-router'
import { getAuthorizationToken, isSuperAdminRole } from 'src/utils/jwtToken'
import { ISignInAction, IResetAction, IChangePasswordAction } from './types'
import {
  signIn,
  setSignInResult,
  reset,
  signInFailure,
  resetFailure,
  signOut,
  changePassword,
  changePasswordFailure,
} from './actions'
import httpClient from '../httpClient'
import authApi from './api'

function* signInSaga(action: ISignInAction) {
  try {
    const { password, email } = action.payload
    const token = yield* call(authApi.signIn, { user: { email, password } })
    localStorage.setItem('token', token)
    httpClient.defaults.headers.Authorization = token
    yield put(setSignInResult({ token }))

    yield put({ type: 'RESET_STORE' })

    if (isSuperAdminRole()) {
      yield put(push('/admin/overview'))
    } else {
      yield put(push('/agencies'))
    }
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.status === 422) {
      errorMessage = 'You entered an incorrect email, password or both.'
    }
    yield put(
      signInFailure({
        error: errorMessage,
      })
    )
  }
}

function* resetSaga(action: IResetAction) {
  try {
    const { email } = action.payload
    yield* call(authApi.reset, { user: { email } })
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield put(
      resetFailure({
        error: errorMessage,
      })
    )
  }

  yield put(push('/user/reset_success'))
}

function* changePasswordSaga(action: IChangePasswordAction) {
  try {
    const urlParams = new URLSearchParams(window.location.search)
    const resetToken = urlParams.get('reset_password_token') || ''
    const { password } = action.payload
    yield* call(authApi.changePassword, {
      password,
      resetToken,
    })
    yield put(push('/user/change_password_success'))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'

    if (err.response && err.response.data) {
      const { errors } = err.response.data
      if (errors.reset_password_token[0] === 'is invalid') {
        errorMessage = 'This link is expired.'
      }
      if (errors.reset_password_token[0] === "can't be blank") {
        errorMessage = 'Please, use a link from your email.'
      }
    }
    yield put(
      changePasswordFailure({
        error: errorMessage,
      })
    )
  }
}

function* signOutSaga() {
  const token = getAuthorizationToken()

  if (token) {
    yield* call(authApi.signOut)
  }

  localStorage.removeItem('token')
  httpClient.defaults.headers.Authorization = undefined
  yield put(push('/user/login'))
  yield put({ type: 'RESET_STORE' })
}

export function* watchSignIn() {
  yield takeEvery(signIn.type, signInSaga)
}

export function* watchReset() {
  yield takeEvery(reset.type, resetSaga)
}

export function* watchSignOut() {
  yield takeEvery(signOut.type, signOutSaga)
}

export function* watchChangePassword() {
  yield takeEvery(changePassword.type, changePasswordSaga)
}
