import { ISettings } from 'src/api/controllers/system_settings.controller'
import { getSecondsTimeStamp, getTimeStamp } from 'src/utils/date'
import { ITranscriptText } from '../types'
import { dataAnalyser, wordsCountPatern } from './dataAnalyser'

const useReport = () => {
  const { applyPattern } = dataAnalyser()
  const getReport = (data: ITranscriptText[], systemSettings: ISettings) => {
    let string = ''
    let wordsCount = 0
    data.forEach((t) => {
      wordsCount += (t.text.match(wordsCountPatern) || []).length
    })
    string += `,Mathces\n`
    string += `"All words",${wordsCount}\n`
    // pronoun
    const pronounAWords = systemSettings.singularPronounsAWords.split('\n')
    const pronounBWords = systemSettings.singularPronounsBWords.split('\n')
    const pronounAWordsCount = applyPattern(pronounAWords, data, false)
    const pronounBWordsCount = applyPattern(pronounBWords, data, false)
    string += `,\n`
    string += `"Pronoun analysis",${pronounAWordsCount + pronounBWordsCount}\n`
    string += `Group A words - Singular first-person pronouns,${pronounAWordsCount}\n`
    pronounAWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })
    string += `Group B words - Plural first-person pronouns,${pronounBWordsCount}\n`
    pronounBWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })

    // data usage
    const dataUsageWords = systemSettings.dataUsageWords.split('\n')
    const dataUsageWordsCount = applyPattern(dataUsageWords, data, false)
    string += `,\n`
    string += `"Data usage",${dataUsageWordsCount}\n`
    dataUsageWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })

    // confident
    const confidentWords = systemSettings.confidentPhrases.split('\n')
    const confidentWordsCount = applyPattern(confidentWords, data, false)
    string += `,\n`
    string += `" Confident & leadership",${confidentWordsCount}\n`
    confidentWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })

    // Tense // Practical experience
    const tenseAWords = systemSettings.tensePastVerbs.split('\n')
    const tenseBWords = systemSettings.tensePresentVerbs.split('\n')
    const tenseCWords = systemSettings.tenseFutureVerbs.split('\n')
    const tenseAWordsCount = applyPattern(tenseAWords, data, false)
    const tenseBWordsCount = applyPattern(tenseBWords, data, false)
    const tenseCWordsCount = applyPattern(tenseCWords, data, false)
    string += `,\n`
    string += `"Tense // Practical experience",${
      tenseAWordsCount + tenseBWordsCount + tenseCWordsCount
    }\n`
    string += `Group A -  Past tense verbs,${tenseAWordsCount}\n`
    tenseAWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })
    string += `Group B - Present tense verbs,${tenseBWordsCount}\n`
    tenseBWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })
    string += `Group C - Future tense verbs,${tenseCWordsCount}\n`
    tenseCWords.forEach((w) => {
      const wordCount = applyPattern([w], data, false)
      string += `"${w}",${wordCount}\n`
    })
    return string
  }

  const getTranscript = (data: ITranscriptText[]) => {
    let string = ''
    data.forEach((t) => {
      string += `${getTimeStamp(t.timestamp)}${t.author ? ` ${t.author}` : ''}: ${
        t.text
      }\n\n`
    })
    return string
  }

  const getAttitude = (
    data: ITranscriptText[],
    sentimentLevel: number,
    wpmTotalLevel: number
  ) => {
    let string = `"Attitude final score: ${sentimentLevel}","","","",""\n"WPM final score: ${wpmTotalLevel}","","","",""\nTimestamp,Duration (sec),Word count,WPM,Transcript,Sentiment score\n`
    data.forEach((t) => {
      const wMatches = t.text.match(wordsCountPatern) || []
      const wordsCount = wMatches.length
      const wpm = Math.round((wordsCount / t.lengthMs) * 1000 * 60)
      string += `${getTimeStamp(t.timestamp)},"${getSecondsTimeStamp(
        t.lengthMs
      )}",${wordsCount},${wpm},"${t.text}",${t.sentimentScore}\n`
    })
    return string
  }

  return { getReport, getTranscript, getAttitude }
}

export default useReport
