import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Text, Title } from './MobileText'

const ReadyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 3vh;
`

const StyledTitle = styled(Title)`
  margin-bottom: 0.5em;
  text-align: center;

  font-size: 39px;
  line-height: 48px;
  @media screen and (max-width: 2500px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 26px;
    line-height: 32px;
  }
`

const Button = styled.button`
  background-color: #b202ff;
  border: none;
  border-radius: 0.75rem;
  color: #fff;
  font-weight: 500;
  height: 4vh;
  min-height: 33px;
  min-width: 102px;
  outline: none;
  padding: 0;
  width: 8vw;

  font-size: 19px;
  @media screen and (max-width: 2500px) {
    font-size: 16px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 13px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: default;
      opacity: 0.5;
    }
  }

  &:focus {
    outline: none;
  }
`

const Hint = styled(Text)`
  cursor: pointer;
  margin-top: 1em;
  text-decoration-line: underline;

  font-size: 21px;
  line-height: 25px;
  @media screen and (max-width: 2500px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 14px;
    line-height: 17px;
  }
`

const MobileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 10px;
`

const MobileStyledTitle = styled(Text)`
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 15px;
`

const MobileButton = styled.button`
  align-items: flex-start;
  background-color: #b202ff;
  border: none;
  border-radius: 14px;
  color: ${({ theme }: { theme: any }) => theme.textColor};
  display: flex;
  flex-direction: row;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  height: 33px;
  padding: 8px 20px;
  width: 106px;
`

const MobileHint = styled(Text)`
  font-size: 14px;
  line-height: 17px;
  margin-top: 32px;
  text-decoration-line: underline;
`

export function ReadyToJoin({
  isReadyToJoin,
  showHint,
  onJoin,
  onTest,
}: {
  isReadyToJoin: boolean
  showHint: boolean
  onJoin: () => void
  onTest: () => void
}) {
  const title = 'Ready to join?'
  const label = 'Join now'
  const hint = 'Check your audio and video before joining'

  const [joinButtonEnabled, setJoinButtonEnabled] = useState(true)

  function onJoinClicked() {
    setJoinButtonEnabled(false)
    onJoin()
  }

  return (
    <ReadyContainer>
      <StyledTitle>{title}</StyledTitle>
      <Button disabled={!isReadyToJoin || !joinButtonEnabled} onClick={onJoinClicked}>
        {label}
      </Button>
      {showHint && <Hint onClick={onTest}>{hint}</Hint>}
    </ReadyContainer>
  )
}

export function MobileReadyToJoin({
  isReadyToJoin,
  showHint,
  onJoin,
  onTest,
}: {
  isReadyToJoin: boolean
  showHint: boolean
  onJoin: () => void
  onTest: () => void
}) {
  const title = 'Ready to join?'
  const label = 'Join now'
  const hint = 'Check your audio and video before joining'

  const [joinButtonEnabled, setJoinButtonEnabled] = useState(true)

  function onJoinClicked() {
    setJoinButtonEnabled(false)
    onJoin()
  }

  return (
    <MobileContainer>
      <MobileStyledTitle>{title}</MobileStyledTitle>
      <MobileButton
        disabled={!isReadyToJoin || !joinButtonEnabled}
        onClick={onJoinClicked}
      >
        {label}
      </MobileButton>
      {showHint && <MobileHint onClick={onTest}>{hint}</MobileHint>}
    </MobileContainer>
  )
}
