import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import getCurrency from 'src/utils/currency'
import SvgPrice from 'src/static/components/SvgPrice'
import getClearLink from 'src/utils/links'
import { format } from 'date-fns'
import { IExpertProfile } from '../../api'
import * as interviewSelectors from '../selectors'
import ResumeIcon from '../../static/availability_resume_icon.png'

const Container = styled.div<{ height?: number }>`
  height: ${(props) => props.height ?? 645}px;
  display: flex;
  flex-direction: column;
`

const DetailsContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 0px 14px -3px lightgrey;
  border-radius: 8px;
`

const DetailsHeader = styled.div`
  display: flex;
  height: 80px;
  padding: 8px 15px;
  border-bottom: 1px solid #edeef2;
`

const ProfilePhoto = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`

const HeaderDataContainer = styled.div<{ centered?: boolean }>`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.centered ? 'center' : 'start')};
`

const ApplicantName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #3b424f;
`

const ApplicantLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  white-space: break-spaces;
`

const ApplicantLink = styled.a<{ showIcon?: boolean }>`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  color: black;
  padding-left: ${({ showIcon }) => (showIcon ? '22' : '0')}px;
  text-decoration-line: underline;

  &:before {
    position: absolute;
    display: ${({ showIcon }) => (showIcon ? 'block' : 'none')};
    left: 0;
    content: '';
    width: 15px;
    height: 16px;
    background: url(${ResumeIcon});
    background-size: 100% 100%;
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #222b45;
  height: 64px;
  margin: 29px 0 12px 0;
`

const DetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0px 15px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`

const DetailsTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: rgba(115, 121, 145, 0.8);
  margin: 18px 0 8px;
`

const DetailsText = styled.div<{ zeroMargin?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-bottom: ${(props) => (props.zeroMargin ? 0 : 6)}px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }

  > p {
    margin-bottom: 0;
  }
`

const DetailsLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: black;
  margin-bottom: 6px;
  width: fit-content;
  text-decoration: underline;
`

const LinkButton = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 0 4px;
  color: black;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
`

interface IProps {
  showTitle?: boolean
  height?: number
  preCall?: boolean
}

function AvailabilityApplicantInfo({ showTitle, height, preCall }: IProps) {
  // const agency = useSelector(interviewSelectors.agency)
  const interviewDetails = useSelector(interviewSelectors.interview)
  const [showShortDescription, setShowShortDescription] = useState(
    interviewDetails.interview.position_description?.length > 210
  )

  const peerFullName = `${interviewDetails.peer.first_name} ${interviewDetails.peer.last_name}`
  const resumeLink = interviewDetails.interview.cv_file?.file?.url
  const linkedInLink = interviewDetails.peer.linkedin_profile
    ? `${interviewDetails.peer.linkedin_profile.startsWith('http') ? '' : 'https://'}${
        interviewDetails.peer.linkedin_profile
      }`
    : ''

  const desiredPosition = `${interviewDetails.interview.desired_position}${
    interviewDetails.interview.position_company
      ? `, ${interviewDetails.interview.position_company}`
      : ''
  }${
    interviewDetails.interview.position_location
      ? `, ${interviewDetails.interview.position_location}`
      : ''
  }`

  const expertProfile: IExpertProfile = interviewDetails.profile
  const expertPrice = `${getCurrency(expertProfile.expert_currency)}${
    expertProfile.expert_price
  }`

  const positionDescroptionShort = `${interviewDetails.interview.position_description?.substring(
    0,
    210
  )}...`

  // console.log('[interviewDetails]', interviewDetails)
  const hasSpecialRequest =
    interviewDetails.interview.special_request ||
    interviewDetails.interview.special_request_by_admin
  const hasLinks = resumeLink || linkedInLink
  const linkToPosition = `//${getClearLink(interviewDetails.interview.link_to_position)}`

  return (
    <Container height={height}>
      {showTitle && (
        <Title>{`Help ${interviewDetails.peer.first_name} land their dream job!`}</Title>
      )}
      <DetailsContainer>
        <DetailsHeader>
          <ProfilePhoto src={interviewDetails.peer.logo.url} />
          <HeaderDataContainer centered={!hasLinks}>
            <ApplicantName>{peerFullName}</ApplicantName>
            {hasLinks && (
              <ApplicantLinks>
                {resumeLink && (
                  <ApplicantLink
                    showIcon
                    href={resumeLink}
                    target="_blank"
                  >{`${interviewDetails.peer.first_name}'s resume`}</ApplicantLink>
                )}
                {resumeLink && linkedInLink ? `, ` : ''}
                {linkedInLink && (
                  <ApplicantLink href={linkedInLink} target="_blank">
                    LinkedIn profile
                  </ApplicantLink>
                )}
              </ApplicantLinks>
            )}
          </HeaderDataContainer>
        </DetailsHeader>
        <DetailsInfoContainer>
          {interviewDetails.peer.current_position && (
            <>
              <DetailsTitle>Current position</DetailsTitle>
              <DetailsText zeroMargin>
                {interviewDetails.peer.current_position}
              </DetailsText>
            </>
          )}
          <DetailsTitle>Desired position</DetailsTitle>
          <DetailsText zeroMargin>{desiredPosition}</DetailsText>
          {interviewDetails.interview.link_to_position && (
            <DetailsLink href={linkToPosition} target="_blank">
              Position URL
            </DetailsLink>
          )}
          {hasSpecialRequest && (
            <>
              <DetailsTitle>Session request</DetailsTitle>
              {interviewDetails.interview.special_request && (
                <DetailsText
                  zeroMargin
                >{`"${interviewDetails.interview.special_request}"`}</DetailsText>
              )}
              {interviewDetails.interview.special_request_by_admin && (
                <DetailsText>{`"${interviewDetails.interview.special_request_by_admin}"`}</DetailsText>
              )}
            </>
          )}
          {interviewDetails.interview.programming_language && (
            <>
              <DetailsTitle>Programming language</DetailsTitle>
              <DetailsText>{interviewDetails.interview.programming_language}</DetailsText>
            </>
          )}
          {interviewDetails.interview.interview_date && (
            <>
              <DetailsTitle>Job interview date</DetailsTitle>
              <DetailsText>
                {format(new Date(interviewDetails.interview.interview_date), 'MMM dd')}
              </DetailsText>
            </>
          )}
          {!preCall && (
            <>
              <DetailsTitle>Session rate</DetailsTitle>
              <DetailsText>
                <SvgPrice fill="#000" />
                {expertPrice}
              </DetailsText>
            </>
          )}
          {interviewDetails.interview.position_description && (
            <>
              <DetailsTitle>Position description</DetailsTitle>
              <DetailsText>
                {showShortDescription ? (
                  <p>
                    {positionDescroptionShort}
                    <LinkButton onClick={() => setShowShortDescription(false)}>
                      More
                    </LinkButton>
                  </p>
                ) : (
                  interviewDetails.interview.position_description
                )}
              </DetailsText>
            </>
          )}
          {preCall && (
            <>
              <DetailsTitle>Session rate</DetailsTitle>
              <DetailsText>
                <SvgPrice fill="#000" />
                {expertPrice}
              </DetailsText>
            </>
          )}
        </DetailsInfoContainer>
      </DetailsContainer>
    </Container>
  )
}

export default AvailabilityApplicantInfo

AvailabilityApplicantInfo.defaultProps = {
  showTitle: true,
  height: null,
  preCall: false,
}
