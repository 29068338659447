import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import { checkUrl } from 'src/utils/string'
import { avaInterviewSelectors } from '../../../redux'
import StyledInput from '../elements/StyledInput'
import StyledTextArea from '../elements/StyledTextArea'
import StepLayout from './StepLayout'
import { SpeechTexts } from '../../speechService'

const DataContainer = styled.div`
  margin-bottom: ${isMobileOnly ? 24 : 0}px;
`

interface IProps {
  backMode: boolean
  goBack: () => void
  goNext: ({ company, qualifications, responsibilities }) => void
}

const PositionDetailsComponent = ({ backMode, goNext, goBack }: IProps) => {
  const interview = useSelector(avaInterviewSelectors.data)

  const [jobCompany, setJobCompany] = useState<string>()
  const [jobResponsibilities, setJobResponsibilities] = useState<string>('')
  const [jobQualifications, setJobQualifications] = useState<string>('')
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (!interview.position) return
    if (interview.position.company !== ' ') setJobCompany(interview.position.company)
    setJobResponsibilities(interview.position.responsibilities)
    setJobQualifications(interview.position.qualifications)
  }, [interview])

  const onNext = useCallback(() => {
    const newErrors = []
    if (checkUrl(jobResponsibilities)) {
      newErrors.push('jobResponsibilities')
    }
    if (checkUrl(jobQualifications)) {
      newErrors.push('jobQualifications')
    }
    setErrors(newErrors)
    if (newErrors.length > 0) return

    goNext({
      company: jobCompany,
      responsibilities: jobResponsibilities,
      qualifications: jobQualifications,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobResponsibilities, jobQualifications, jobCompany])

  return (
    <StepLayout
      title={SpeechTexts.WhatCompany}
      subTitle={SpeechTexts.MoreDataMoreAccurate}
      backMode={backMode}
      errorText="Please don’t include URLs in the description"
      showError={errors.length > 0}
      goNext={onNext}
      goBack={goBack}
    >
      <DataContainer>
        <StyledInput
          maxLength={100}
          value={jobCompany}
          placeholder="Company"
          optional
          onChange={setJobCompany}
        />
        <StyledTextArea
          maxLength={5000}
          value={jobResponsibilities}
          title="Job responsibilities"
          placeholder="You can copy and paste this information from the job description"
          onChange={setJobResponsibilities}
          optional
          showError={errors.some((e) => e === 'jobResponsibilities')}
          errorText="Please provide a text description"
        />
        <StyledTextArea
          maxLength={5000}
          value={jobQualifications}
          title="Required qualifications"
          placeholder="You can copy and paste this information from the job description"
          onChange={setJobQualifications}
          optional
          showError={errors.some((e) => e === 'jobQualifications')}
          errorText="Please provide a text description"
        />
      </DataContainer>
    </StepLayout>
  )
}

export default PositionDetailsComponent
