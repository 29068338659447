import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../Layout'
import * as interviewSelectors from '../selectors'

function ExpertInterviewSuccess() {
  const interview = useSelector(interviewSelectors.interview)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout
      title="Thank you for submitting your report! "
      subtitle={`Your feedback will help ${interview.peer.first_name} ${interview.peer.last_name} to ace the upcoming interviews`}
      success
      expert
    >
      <></>
    </Layout>
  )
}

export default ExpertInterviewSuccess
