// TYPES

export type AvailableDay =
  | 'Mon'
  | 'Tue'
  | 'Wed'
  | 'Thu'
  | 'Fri'
  | 'Sat'
  | 'Sun'
  | 'Weekday'
  | 'Weekend'

export type AvailableTime =
  | '9-12'
  | '12-15'
  | '15-18'
  | '18-21'
  | '21-24'
  | '8-12'
  | '12-18'
  | '18-22'

export type Currency = 'ILS' | 'EUR' | 'USD' | 'GBP' | 'CAD' | 'AUD'

export type IConversationCandidate = Pick<
  ICandidateProfile,
  'id' | 'first_name' | 'full_name' | 'last_name'
>

export type IConversationExpert = Pick<
  IExpertProfile,
  'id' | 'first_name' | 'full_name' | 'last_name'
>

export type IExpertNoteMood = 'Positive' | 'Negative'

export type InterviewTokenRole =
  | 'expert'
  | 'candidate'
  | 'potential_expert'
  | 'rejected_expert'
export type UploadedFile = {
  url?: string
}

export type SystemSettingName =
  | 'agency_positions'
  | 'speech_speed_min'
  | 'speech_speed_max'
  | 'singular_pronouns_min'
  | 'singular_pronouns_max'
  | 'confident_score_min'
  | 'confident_score_max'
  | 'data_usage_score_min'
  | 'data_usage_score_max'
  | 'singular_pronouns_a_words'
  | 'singular_pronouns_b_words'
  | 'data_usage_words'
  | 'confident_phrases'
  | 'past_tense_range_min'
  | 'past_tense_range_max'
  | 'sentiment_min'
  | 'sentiment_max'
  | 'analytics_duration_before_end'
  | 'analytics_duration_after_start'
  | 'tense_past_verbs'
  | 'tense_present_verbs'
  | 'tense_future_verbs'
  | 'min_words_count'
  | 'blur_background_enabled'
  | 'stt_provider'

// ENUMS

export enum AdminInterviewStatus {
  ALL = 'All',
  INVITED = 'Invited',
  ORDER_FORM_SUBMITTED = 'Order form submitted',
  PENDING_EXPERT_AVAILABILITY = 'Pending expert availability',
  EXPERT_AVAILABILITY_PROVIDED = 'Expert availability provided',
  MATCHED = 'Matched',
  SCHEDULED = 'Scheduled',
  STARTED = 'Started',
  PENDING_REPORT = 'Pending Report',
  PENDING_CONFIRMATION = 'Pending Confirmation',
  COMPLETED = 'Completed',
  HIRED = 'Hired',
  INCOMPLETE = 'Incomplete',
}

export enum AgencyInterviewStatus {
  ALL = 'All',
  INVITED = 'Invited',
  MATCHING = 'Matching',
  MATCHED = 'Matched',
  SCHEDULED = 'Scheduled',
  PENDING_REPORT = 'Pending Report',
  COMPLETED = 'Completed',
  HIRED = 'Hired',
}

export enum AgencyExpertSessionType {
  FULL = 'Full',
  MOCK = 'Mock',
}

export enum AgencyTheme {
  NORMAL = 'Normal',
  DEI = 'DEI',
  EXECUTIVE = 'Executive',
}

export enum ApplicantAvailabilityFlow {
  OLD_FLOW = 'old',
  NEW_FLOW = 'new',
  AB_FLOW = 'ab',
}

export enum AvaInterviewStatus {
  INVITED = 'invited',
  IN_INTRO = 'in_intro',
  IN_INTERVIEW = 'in_interview',
  COMPLETED = 'completed',
}

export enum BusinessModel {
  B2B = 'B2B',
  B2C = 'B2C',
  B2B2C = 'B2B2C',
  B2C_FREEMIUM = 'B2C_FREEMIUM',
}

export enum CoachingLanguage {
  ENGLISH = 'English',
  ARABIC = 'Arabic',
  BENGALI = 'Bengali',
  CANTONESE = 'Cantonese',
  DUTCH = 'Dutch',
  FRENCH = 'French',
  GERMAN = 'German',
  HEBREW = 'Hebrew',
  HINDI = 'Hindi',
  ITALIAN = 'Italian',
  JAPANESE = 'Japanese',
  MANDARIN = 'Mandarin',
  NORWEGIAN = 'Norwegian',
  PORTUGESE = 'Portuguese',
  RUSSIAM = 'Russian',
  SPANISH = 'Spanish',
  SWISS_GERMAN = 'Swiss-German',
  SWEDISH = 'Swedish',
  THAI = 'Thai',
  URDU = 'Urdu',
}

export enum CoachingType {
  MOCK_INTERVIEW = 'Professional mock interview',
  HR_COACHING = 'HR Coaching',
  PHONE_INTERVIEW = 'Phone or video interview preparation',
}

export enum FirstPageBehaviors {
  Landing = 'landing',
  OrderForm = 'form',
}

export enum InterviewStatus {
  PENDING = 'pending',
  CANDIDATE_INVITED = 'candidate_invited',
  MATCHING = 'matching',
  EXPERTS_INVITED = 'experts_invited',
  EXPERTS_CONFIRMED = 'experts_confirmed',
  MATCHED = 'matched',
  SCHEDULED = 'scheduled',
  STARTED = 'started',
  ENDED = 'ended',
  REPORT_PROVIDED = 'report_provided',
  COMPLETED = 'completed',
}

export enum YesNo {
  YES = 'Yes',
  NO = 'No',
}

// INTERFACES

export interface IAdminProfile {
  id?: string

  // fields
  created_at?: string
  updated_at?: string

  // dependencies
  agency?: IAgency
  agency_id?: string
  user?: IUser
  user_id: string
}

export interface IAgency {
  id?: string

  // fields
  additional_booking_url?: string
  additional_booking_url_sponsored?: string
  additional_info?: boolean
  admin_booking?: boolean
  allow_direct_registration?: boolean
  applicant_availability_flow?: ApplicantAvailabilityFlow
  business_model: BusinessModel
  candidate_onboarding_url?: string
  can_skip_question?: boolean
  code_sharing?: boolean
  company_logo?: UploadedFile
  created_at?: string
  currency: Currency
  direct_users_first_page?: FirstPageBehaviors
  disable_copy_paste_answer?: boolean
  display_topbar_footer?: boolean
  duration?: number
  email: string
  email_domain_validations?: string[]
  enable_ava?: boolean
  enable_conversation_intelligence?: boolean
  enable_experts?: boolean
  expert_onboarding_url?: string
  expert_session_type?: AgencyExpertSessionType
  has_ab_testing?: boolean
  has_coaching_types?: boolean
  ignored_emails?: string[]
  internal_name?: string
  invited_users_first_page?: FirstPageBehaviors
  main_color?: string
  meeting_url?: string
  monthly_sessions?: number
  name: string
  negative_logo?: UploadedFile
  positions?: IAgencyPositions
  priority_account?: boolean
  r_and_d?: boolean
  session_price: number
  session_price_discount?: number
  show_interview_links?: boolean
  special_request?: string
  subdomain: string
  success_manager_email?: string
  success_manager_name?: string
  survey_url?: string
  survey_url_ava?: string
  time_zone?: string
  theme?: AgencyTheme
  updated_at?: string
  use_new_applicant_availability_flow?: boolean
  whitelisted?: boolean

  // methods
  agency_positions?: IAgencyPositions
  currency_sign?: string
  full_domain?: string

  // dependencies
  agency_admins?: IAdminProfile[]
  agency_managers?: IAgencyManager[]
  agency_price_tiers?: IAgencyPriceTier[]
  api_keys?: IApiKey[]
  internal_experts?: IInternalExpert[]
  interviews?: IInterview[]
  users?: IUser[]
}

export interface IAgencyManager {
  id?: string

  created_at?: string
  email: string
  name?: string
  updated_at?: string
}

export interface IAgencyPositions {
  [key: string]: {
    label: string
    is_manager?: boolean
  }[]
}

export interface IAgencyPriceTier {
  id?: string

  // fields
  created_at?: string
  description?: string
  name: string
  price_max: number
  price_min: number
  updated_at?: string

  // dependencies
  agency?: IAgency
  agency_id: string
}

export interface IAgencyPriceTiersB2C {
  senior: Pick<IAgencyPriceTier, 'price_max' | 'price_min'>
  team_lead: Pick<IAgencyPriceTier, 'price_max' | 'price_min'>
  executive: Pick<IAgencyPriceTier, 'price_max' | 'price_min'>
}

export interface IAgoraRecording {
  id?: string

  // fields
  active?: boolean
  created_at?: string
  resource_id?: string
  sid?: string
  status?: string
  stopped_at?: string
  uid?: string
  updated_at?: string
  web_screen_url?: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface IApiKey {
  id?: string
  key: string
}

export interface IApplicantNote {
  id?: string

  // fields
  created_at?: string
  text: string
  updated_at?: string

  // methods
  offset?: number

  // dependencies
  interview?: IInterview
  interview_id: string
}

export enum PromptStatus {
  Skipped = 'Skipped',
  Failed = 'Failed',
  Waiting = 'Waiting',
  Missing = 'Missing',
  Done = 'Done',
}

export interface IAvaInterview {
  id: string

  // fields
  agree_terms_and_privacy?: boolean
  agree_to_receive_offers?: boolean
  agree_to_share?: boolean
  completed_at?: string
  created_at?: string
  in_interview_at?: string
  in_intro_at?: string
  is_retrying_prompts?: boolean
  order_number: number
  overall_score?: IAvaOverallScore
  prompt_status?: {
    follow_up: PromptStatus
    interview: {
      experience: PromptStatus
      qualifications: PromptStatus
      questions: PromptStatus
      responsibilities: PromptStatus
    }
    report: {
      company: PromptStatus
      conversation: PromptStatus
      overall_score: PromptStatus
      position: PromptStatus
      recommendations: PromptStatus
    }
  }
  status: AvaInterviewStatus
  updated_at?: string

  // dependencies
  agency_id: string
  agency?: IAgency
  candidate?: IAvaCandidate
  position?: IAvaPosition
  questions?: IAvaQuestion[]

  // delegations
  candidate_token_code?: string
}

export interface IAvaAnswer {
  id: string

  // fields
  created_at?: string
  is_valid?: boolean
  text: string
  updated_at?: string

  // dependencies
  ava_interview?: IAvaInterview
  ava_interview_id?: string
  ava_question?: IAvaQuestion
  ava_question_id?: string
}

export interface IAvaCandidate {
  id: string

  // fields
  email: string
  experience: string
  experience_overview: string
  experience_valid: Boolean
  first_name: string
  last_name: string

  // dependencies
  ava_interview?: IAvaInterview
  ava_interview_id: string
}

export interface IAvaOverallScore {
  attitude: string
  communication_skills: string
  confidence: string
  feedback: string
  problem_solving_skills: string
  role_related_knowledge: string
  score: number
  workplace_culture: string
}

export interface IAvaPosition {
  id: string

  // fields
  company: string
  company_market: string
  company_overview?: {
    website: string
    industry: string
    headquarters: string
    founded: string
    number_of_employees: string
    funding: string
    valuation: string
    revenue: string
    number_of_customers: string
  }
  company_valid: boolean
  company_values: string
  is_manager: boolean
  is_specific_company?: boolean
  position: string
  position_readings?: { title: string; link: string }[]
  position_terminologies?: { name: string; description: string }[]
  qualifications: string
  qualifications_overview: string
  qualifications_valid: boolean
  responsibilities: string
  responsibilities_overview: string
  responsibilities_valid: boolean
  role: string

  // dependencies
  ava_interview?: IAvaInterview
  ava_interview_id: string
}

export interface IAvaQuestion {
  id: string

  // fields
  created_at: string
  follow_up?: IAvaQuestion[]
  generated_answer?: string
  intro?: string
  is_generating_follow_up?: boolean
  order: number
  parent_id?: string
  question_type: 'Behavioral' | 'Technical'
  recommendations?: string
  score?: number
  text: string
  updated_at: string

  // dependencies
  answer?: IAvaAnswer
  ava_interview?: IAvaInterview
  ava_interview_id: string
}

export interface IBulkInterview {
  candidate_invited_at: IBulkInterviewNotification
  file: ICVFile
  first_bulk_reminder_at: IBulkInterviewNotification
  second_bulk_reminder_at: IBulkInterviewNotification
  third_bulk_reminder_at: IBulkInterviewNotification
  fourth_bulk_reminder_at: IBulkInterviewNotification
}

export interface IBulkInterviewNotification {
  count: number
  date: string
  sent: boolean
}

export interface ICandidateProfile {
  id?: string

  // fields
  about?: string
  company?: string
  created_at?: string
  current_position?: string
  educations?: string[]
  email: string
  experiences?: string[]
  first_name: string
  last_name: string
  linkedin_profile?: string
  logo?: UploadedFile
  rating?: string
  time_zone?: string
  updated_at?: string

  // methods
  full_name?: string

  // dependencies
  interview?: IInterview

  // delegations
  candidate_token_code?: string
}

export interface ISymblConversation {
  messages: ISymblMessage[]
  metrics: ISymblCandidateMetrics
  questions: ISymblQuestion[]
}

export interface ISymblCandidateMetrics {
  attitude: number
  attitude_text: string
  confidence: number
  confidence_text: string
  data_use: number
  data_use_text: string
  experience: number
  experience_text: string
  speech_tempo: number
  speech_tempo_text: string
  word_count: number
  workplace_culture: number
  workplace_culture_text: string
}

export interface ISymblMessage {
  id?: string

  author?: string
  avatar?: string
  duration?: number
  end_time?: string
  is_candidate?: boolean
  sentiment_score?: number
  start_time?: string
  text?: string
  timestamp?: number
}

export interface ISymblQuestion {
  id?: string

  duration?: number
  end_time?: string
  score?: number
  start_time?: string
  text?: string
  timestamp?: number
}

export interface ICVFile {
  id?: string

  // fields
  content_type: string
  created_at?: string
  file: UploadedFile
  file_name: string
  size: number
  updated_at?: string
}

export interface IExpertProfile {
  id?: string

  // fields
  about?: string
  adhoc?: boolean
  availability_link_sent_at?: string
  availability_provided_at?: string
  comment?: string
  company?: string
  contact_email?: string
  created_at?: string
  current_position?: string
  decline_reason?: string
  educations?: string[]
  email?: string
  experiences?: string[]
  expert_currency?: Currency
  expert_price?: number
  first_name?: string
  include_onboarding?: boolean
  last_name?: string
  linkedin_profile?: string
  logo?: UploadedFile
  rating?: string
  slot_price?: number
  updated_at?: string

  // methods
  full_name?: string

  // dependencies
  interview_token?: IInterviewToken
  time_slots?: ITimeSlot[]
}

export interface IInternalExpert {
  id?: string

  // fields
  coaching_types?: CoachingType[]
  name?: string
}

export interface IInterviewAvailabilityDays {
  weekday: boolean
  weekend: boolean
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}

export interface IInterviewAvailabilityTime {
  '8-12': boolean
  '12-18': boolean
  '18-22': boolean
  '9-12': boolean
  '12-15': boolean
  '15-18': boolean
  '18-21': boolean
  '21-24': boolean
}

export interface IInterview {
  id?: string

  // fields
  admin_notes?: string
  agree_terms_and_privacy?: boolean
  agree_to_receive_offers?: boolean
  agree_to_share?: boolean
  availability_days?: IInterviewAvailabilityDays
  availability_time?: IInterviewAvailabilityTime
  candidate_available_at?: number[]
  candidate_available_days?: AvailableDay[]
  candidate_available_hours?: AvailableTime[]
  candidate_invited_at?: string
  coaching_language?: CoachingLanguage
  coaching_type?: CoachingType
  coding_playback_url?: string
  coding_session_url?: string
  completed_at?: string
  conversation_id?: string
  created_at?: string
  desired_position?: string
  displayed_scheduled_time?: string
  duration: number
  email_timestamps?: {
    candidate_invited_at: IBulkInterviewNotification
    first_bulk_reminder_at: IBulkInterviewNotification
    second_bulk_reminder_at: IBulkInterviewNotification
    third_bulk_reminder_at: IBulkInterviewNotification
    fourth_bulk_reminder_at: IBulkInterviewNotification
  }
  enable_conversation_intelligence?: boolean
  ended_at?: string
  expert_confirmed?: boolean
  expert_paid?: boolean
  expert_passed_training?: boolean
  expert_payment_provider?: string
  expert_payment_reference?: string
  experts_confirmed_at?: string
  experts_invited_at?: string
  feedback_expert_rating?: number
  feedback_rating?: number
  feedback_text?: string
  has_rescheduled?: boolean
  hired_candidate?: string
  hired_chance_scale?: string
  hired_chance_score?: number
  interview_date?: string
  interview_date_option?: string
  joined_at?: string
  link_to_position?: string
  matched_at?: string
  matching_at?: string
  meta_data?: any
  ops_manager_email?: string
  order_number?: number
  position_company?: string
  position_description?: string
  position_location?: string
  price?: number
  priority?: 'high'
  priority_reason?: string
  programming_language?: string
  report_provided_at?: string
  rescheduled?: boolean
  scheduled_at?: string
  scheduled_time?: string
  sent_reminders?: string[]
  sent_to_applicant_at?: string
  special_request?: string
  special_request_by_admin?: string
  sponsored_session_available?: number
  started_at?: string
  status?: InterviewStatus
  status_changed_at?: string
  stripe_client_secret?: string
  updated_at?: string
  use_general_link?: boolean
  use_new_applicant_availability_flow?: boolean

  // methods
  admin_status?: AdminInterviewStatus
  agency_status?: AgencyInterviewStatus
  days_since_update?: number
  is_hired?: boolean
  is_incomplete?: boolean
  payment_required?: boolean

  // dependencies
  agency?: IAgency
  agency_id: string
  agora_recording?: IAgoraRecording
  candidate_profile?: ICandidateProfile
  candidate_profile_id?: string
  candidate_token?: IInterviewToken
  created_by?: IUser
  created_by_id?: string
  cv_file?: ICVFile
  cv_file_id?: string
  expert_notes?: IExpertNote[]
  expert_profiles?: IExpertProfile[]
  expert_token?: IInterviewToken
  interview_internal_experts?: IInterviewInternalExpert[]
  interview_internal_experts_attributes?: IInterviewInternalExpert[]
  interview_missed_times?: IInterviewMissedTime[]
  interview_price_tiers?: IInterviewPriceTier[]
  interview_recording?: IInterviewRecording
  interview_report?: IInterviewReport
  interview_status_check?: IInterviewStatusCheck
  interview_tokens?: IInterviewToken[]
  invoice?: IInvoice
  main_time_slot?: ITimeSlot
  main_time_slot_id?: string
  reschedule_requests?: IRescheduleRequest[]
  time_slots?: ITimeSlot[]

  // delegations
  candidate_token_code?: string
  expert_profile?: IExpertProfile
  interview_recording_end_at?: string
  interview_recording_start_at?: string
  interview_recording_status?: string
  invoice_paid?: boolean
}

export interface IInterviewInternalExpert {
  id?: string

  // fields
  name?: string

  // dependencies
  internal_expert?: IInternalExpert
  internal_expert_id?: string
  interview?: IInterview
  interview_id?: string
}

export interface IExpertNote {
  id?: string

  // fields
  created_at?: string
  icon?: string
  mood?: IExpertNoteMood
  text?: string
  updated_at?: string

  // methods
  offset?: number

  // dependencies
  interview?: IInterview
  interview_id?: string

  // delegations
  interview_recording?: IInterviewRecording

  // extra
  score: number
  duration?: number
  type?: string
}

export interface IInterviewMissedTime {
  id?: string

  // fields
  candidate_missed: boolean
  created_at?: string
  expert_missed: boolean
  scheduled_time: string
  updated_at?: string

  // dependencies
  interview?: IInterview
  interview_id?: string
}

export interface IInterviewPriceTier {
  id?: string

  // fields
  created_at?: string
  updated_at?: string

  // dependencies
  agency_price_tier?: IAgencyPriceTier
  agency_price_tier_id?: string
  interview?: IInterview
  interview_id?: string
}

export interface IInterviewRecording {
  id?: string

  // fields
  candidate_recording_url?: string
  created_at?: string
  end_at?: string
  expert_recording_url?: string
  overall_recording_url?: string
  start_at?: string
  status?: string
  updated_at?: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface IInterviewReport {
  id?: string

  // fields
  advancement?: boolean
  advancement_rating?: number
  app_improvement?: string
  cockpit_rating?: number
  communication_rating?: number
  created_at?: string
  first_impression_rating?: number
  future_coach_rating?: number
  items_to_accomplish?: string
  items_to_improve?: string
  items_to_keep?: string
  knowledge_rating?: number
  problem_solving_rating?: number
  summary?: string
  typeform_response_id?: string
  updated_at?: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface IInterviewStatusCheck {
  id?: string

  created_at: string
  candidate_joined_5min: boolean
  candidate_joined_9min: boolean
  expert_joined_5min: boolean
  expert_joined_9min: boolean
  updated_at: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface IInterviewTimestamp {
  id?: string

  // fields
  name?: string
  time?: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface IInterviewToken {
  id?: string

  // fields

  code?: string
  created_at?: string
  role?: InterviewTokenRole
  type?: string
  updated_at?: string

  // dependencies
  expert_profile?: IExpertProfile
  expert_profile_id?: string
  interview?: IInterview
  interview_id: string
}

export interface IInvoice {
  id?: string

  // fields
  amount: number
  client_secret: string
  created_at?: string
  currency: Currency
  paid_at?: string
  payment_intent_id: string
  payment_method_info?: any
  updated_at?: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface IOpsManager {
  email: string
  name: string
}

export interface IRescheduleRequest {
  id?: string

  // fields
  comment?: string
  created_at?: string
  created_by?: string
  expert_ids?: string[]
  scheduled_expert_id?: string
  scheduled_time?: string
  status?: string
  updated_at?: string
  use_same_expert?: boolean

  // methods
  offset?: number
  offset_time?: string

  // dependencies
  interview?: IInterview
  interview_id: string
}

export interface ISystemSetting {
  id?: string

  // fields
  created_at?: string
  name: SystemSettingName
  updated_at?: string
  value: string
}

export interface ITimeSlot {
  id?: string

  // fields
  created_at?: string
  from: string
  time_zone?: string
  to: string
  updated_at?: string

  // dependencies
  expert_profile?: IExpertProfile
  expert_profile_id?: string
  interview?: IInterview
  interview_id?: string
}

export interface IUser {
  id?: string

  // fields
  created_at?: string
  email: string
  encrypted_password: string
  first_name?: string
  last_name?: string
  remember_created_at?: string
  reset_password_sent_at?: string
  reset_password_token?: string
  updated_at?: string

  // methods
  name?: string
}

// CONSTANTS

export const AgencyCoachingTypes = [
  {
    value: CoachingType.MOCK_INTERVIEW,
    label: 'Professional mock interview - All programs',
  },
  { value: CoachingType.HR_COACHING, label: 'HR Coaching - only IMBA' },
  { value: CoachingType.PHONE_INTERVIEW, label: CoachingType.PHONE_INTERVIEW },
]

export const InterviewPriorities = [
  { label: 'High priority', id: 'high' },
  { label: 'Regular priority', id: 'regular' },
]

export const OpsManagers: IOpsManager[] = [
  { name: 'Barry', email: 'barry@wizco.io' },
  { name: 'Callen', email: 'callen@wizco.io' },
  { name: 'Carin', email: 'carin@wizco.io' },
  { name: 'Chamabert', email: 'chamabert@wizco.io' },
  { name: 'Daniela', email: 'daniela@wizco.io' },
  { name: 'Sadrina', email: 'sadrina@wizco.io' },
]
