import React from 'react'

function SvgReportIcon({ fill }: { fill: string }) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="-2 -1 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg
        width="14"
        height="17"
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.96028 5.64H9.72028M3.96028 8.52H9.72028M3.96028 11.4H6.84028M2.52005 1H11.1603C12.2207 1 13.0803 1.85961 13.0803 2.92V16.36H6.35998H2.51997C1.45958 16.36 0.599969 15.5003 0.599976 14.4399L0.60005 2.91999C0.600057 1.85961 1.45967 1 2.52005 1Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </svg>
  )
}

export default SvgReportIcon
