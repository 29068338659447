import Banners from 'src/static/banners'

export const ReviewsItems = [
  {
    text:
      'WOW!  Ava is an amazing  resource! I loved how easy and convenient it was to use. The questions were very accurate to what I got in my interview and the insights helped me get confident and quickly improve into my interview.',
    author: 'Vanessa | Product Data Analyst at TikTok',
    logo: Banners.TikTok,
  },
  {
    text:
      'This experience really boosted my confidence for the interview. The session report gave me some awesome tips that I was able to put into practice, and helped me to get the job!',
    author: 'Riya | Senior Market Research Manager at Microsoft',
    logo: Banners.Microsoft,
  },
  {
    text:
      'The practice I did before my interviews and the feedback I received was a real game changer and it made a huge difference in boosting my skills and confidence. I would recommend this to anyone looking to improve their interview performance!',
    author: 'Daniel | Market Risk Management Analyst at Citi',
    logo: Banners.Citi,
  },
  {
    text:
      'The questions were challenging and realistic, and the feedback helped me boost my confidence and improve my performance. Ava exceeded my expectations!',
    author: 'Emily | Product Manager at Netflix',
    logo: Banners.Netflix,
  },
  {
    text:
      'Ava really stands out for its accuracy and customization. The questions were tailored to the specific role and company I was interviewing for, and the insights and feedback were incredibly valuable!',
    author: 'Adam | Account Executive at Slack',
    logo: Banners.Slack,
  },
]

export const FaqsItems = [
  {
    question: 'How much does it cost?',
    answer: `In commitment to supporting you on your career path, [external agency name] offers Ava coaching sessions free of charge.`,
  },
  {
    question: 'What types of industries and roles is Ava best suited for?',
    answer: `Ava is designed to provide valuable insights and support for job applicants across various industries and roles. Our platform has accumulated valuable knowledge and understanding of the interview processes of over 10,000 companies worldwide, making it a versatile tool for anyone seeking to enhance their interview skills.\n\nWhether you're pursuing a career in technology, finance, marketing, or any other field, Ava is here to help you shine.`,
  },
  {
    question:
      'Can Ava help with specific interview formats, such as behavioral or technical interviews?',
    answer: `Yes, Ava is equipped to assist with various interview formats, including behavioral interviews and technical interviews.`,
  },
  {
    question: 'How long does a typical practice session with Ava last?',
    answer: `A typical practice session with Ava involves answering five interview questions. On average, users complete these questions within approximately 20 minutes. This duration allows for a focused and comprehensive practice experience while efficiently utilizing your time to enhance your interview skills.`,
  },
  {
    question: 'Do you use a bank of questions?',
    answer: `No, Ava does not rely on a generic bank of questions. Unlike other platforms, Ava takes a personalized approach to interview preparation. Instead of using pre-determined questions, Ava tailors the practice session to your specific needs and desired position. This ensures that you receive a more targeted and relevant interview experience, allowing you to better prepare for the specific challenges and requirements of your desired role.`,
  },
  {
    question: 'How does Ava provide personalized AI-driven insights?',
    answer: `Ava analyzes your interview responses and behavior using advanced AI algorithms trained on a vast amount of data collected from tens of thousands of successful Wizco interview coaching sessions. Based on this analysis, it offers personalized feedback, suggestions, and recommendations to help you improve your interview performance.`,
  },
  {
    question: 'Is there a mobile app available for Ava?',
    answer: `Currently, Ava is accessible through our web platform. Stay tuned for updates as we continue to expand our offerings and provide more flexibility in accessing Ava's powerful interview preparation tools.`,
  },
]
