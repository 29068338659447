import React from 'react'
import { Link } from '../../components'
import Layout from '../Layout'
import LoginForm from '../forms/Login'

function Login() {
  return (
    <Layout
      sidebarText="Find the perfect match from thousands of experts to prepare your applicants for their job interviews"
      title="Log in"
    >
      <>
        <LoginForm />
        <Link to="reset">Forgot password?</Link>
      </>
    </Layout>
  )
}

export default Login
