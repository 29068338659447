import React from 'react'

function SvgStudent({ fill }: { fill: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M10.0772 17.9101L8.65194 12.8644L10.9207 10.6094M11.628 13.1134L16.7327 8.03933L9.72766 6.38587L4.62303 11.46L11.628 13.1134ZM11.628 13.1134L15.1219 9.64065C16.0583 10.8712 16.6389 12.3352 16.8005 13.8731C15.1409 14.5207 13.7317 15.6818 12.7787 17.187C11.1792 16.403 9.37106 16.1507 7.61807 16.4669C6.951 15.0718 6.67986 13.5203 6.83421 11.9817L11.628 13.1134Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16.778"
            height="16.778"
            fill={fill}
            transform="translate(0.894531 5.9751) rotate(-15.7736)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgStudent
