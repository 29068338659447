import { reduxDataFactory } from '../../../utils/redux'

interface IAvaQuestionIndex {
  questionCount: number
  questionNumber: number
  questionNumberText: string
}

const defaultValues: IAvaQuestionIndex = {
  questionCount: 0,
  questionNumber: 0,
  questionNumberText: '',
}

const {
  action: updateAvaIntroIndex,
  actionType: updateAvaIntroIndexType,
  reducer: avaIntroIndexReducer,
  selectors: avaIntroIndexSelectors,
} = reduxDataFactory<IAvaQuestionIndex>('questionIndex', 'ava', defaultValues)

export {
  updateAvaIntroIndex,
  updateAvaIntroIndexType,
  avaIntroIndexReducer,
  avaIntroIndexSelectors,
}
