import { IAgoraRTC } from 'agora-rtc-sdk-ng'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import useStateRef from 'react-usestateref'

import { DefaultVideoProfile } from '../components/VideoProfiles'
import { IVideoProfile } from '../interfaces'

const backCameraKeywords: string[] = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'belakang',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
]

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Media]',
  'color:#1e133b',
]

export interface MediaDeviceGroup {
  deviceId?: string
  deviceIds: string[]
  groupId: string
  label: string
  kind: MediaDeviceKind
}

const isDefaultDevice = (deviceIds: string[]) => {
  return deviceIds.includes('default')
}

const isFrontCamera = (label: string) => {
  return !backCameraKeywords.some((k) => label.toLocaleLowerCase().includes(k))
}

const groupDevices = (devices: MediaDeviceInfo[]): MediaDeviceGroup[] => {
  return devices
    .filter((d) => !!d.deviceId)
    .reduce<MediaDeviceGroup[]>((acc, d) => {
      const { deviceId, groupId, kind, label } = d

      const index = groupId
        ? acc.findIndex(
            (ad) =>
              ad.kind === kind &&
              ad.groupId === groupId &&
              !ad.deviceIds.includes(deviceId)
          )
        : -1

      if (index !== -1) {
        const { deviceIds } = acc[index]
        acc[index].deviceIds =
          d.deviceId === 'default'
            ? [d.deviceId, ...deviceIds]
            : [...deviceIds, d.deviceId]
      } else acc.push({ deviceIds: [deviceId], groupId, kind, label })
      return acc
    }, [])
    .map((d) => ({ ...d, deviceId: d.deviceIds[0] }))
}

const useMediaDevices = () => {
  const [permissionsGranted, setPermissionsGranted] = useStateRef(false)
  const [permissionsRequested, setPermissionsRequested] = useStateRef(false)
  const [devicesInitialized, setDevicesInitialized, devicesInitializedRef] = useStateRef(
    false
  )
  const [devices, setDevices, devicesRef] = useStateRef<MediaDeviceGroup[]>([])
  const [devicesCount, setDevicesCount, devicesCountRef] = useStateRef(0)
  const [cameraId, setCameraId, cameraIdRef] = useStateRef<string>(undefined)
  const [microphoneId, setMicrophoneId, microphoneIdRef] = useStateRef<string>(undefined)
  const [speakerId, setSpeakerId, speakerIdRef] = useStateRef<string>(undefined)
  const [videoProfile, setVideoProfile] = useState<IVideoProfile>(DefaultVideoProfile)
  const [lastCameraId, setLastCameraId, lastCameraIdRef] = useStateRef<string>(undefined)
  const [lastMicrophoneId, setLastMicrophoneId, lastMicrophoneIdRef] = useStateRef<
    string
  >(undefined)
  const [lastSpeakerId, setLastSpeakerId, lastSpeakerIdRef] = useStateRef<string>(
    undefined
  )

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const changeCamera = (deviceId: string) => {
    if (deviceId !== cameraId) {
      setCameraId(deviceId)
      log(`Selected new camera (${deviceId})`)
    }
  }

  const changeMicrophone = (deviceId: string) => {
    if (deviceId !== microphoneId) {
      setMicrophoneId(deviceId)
      log(`Selected new microphone (${deviceId})`)
    }
  }

  const changeSpeaker = (deviceId: string) => {
    if (deviceId !== speakerId) {
      setSpeakerId(deviceId)
      log(`Selected new speaker (${deviceId})`)
    }
  }

  const changeVideoProfile = (profile: IVideoProfile) => {
    setVideoProfile(profile)
    log(
      `Selected new video profile (${profile.width}x${profile.height}px, ${profile.frameRate}fps)`
    )
  }

  const getCameras = (): MediaDeviceGroup[] => {
    const cameras: MediaDeviceGroup[] = devicesRef.current.filter(
      (d) => d.kind === 'videoinput'
    )
    return cameras
      .sort((a, b) =>
        isMobile && !isFrontCamera(a.label) && isFrontCamera(b.label) ? 1 : 0
      )
      .sort((a, b) =>
        !isDefaultDevice(a.deviceIds) && isDefaultDevice(b.deviceIds) ? 1 : 0
      )
  }

  const getMicrophones = (): MediaDeviceGroup[] => {
    return devicesRef.current
      .filter((d) => d.kind === 'audioinput')
      .sort((a, b) =>
        !isDefaultDevice(a.deviceIds) && isDefaultDevice(b.deviceIds) ? 1 : 0
      )
  }

  const getSpeakers = (): MediaDeviceGroup[] => {
    return devicesRef.current
      .filter((d) => d.kind === 'audiooutput')
      .sort((a, b) =>
        !isDefaultDevice(a.deviceIds) && isDefaultDevice(b.deviceIds) ? 1 : 0
      )
  }

  const connectCamera = async (deviceId: string, connected: boolean) => {
    if (!connected) {
      log(`Camera disconnected (${deviceId})`)
      const cameras = getCameras().filter((d) => !d.deviceIds.includes(deviceId))
      const lastCameraIndex = lastCameraIdRef.current
        ? cameras.findIndex((d) => d.deviceIds.includes(lastCameraIdRef.current))
        : -1
      if (lastCameraIndex !== -1) changeCamera(lastCameraIdRef.current)
      else if (cameras.length > 0) changeCamera(cameras[0].deviceId)
      setLastCameraId(undefined)
    } else if (deviceId) {
      setLastCameraId(cameraIdRef.current)
      changeCamera(deviceId)
      log(`Connected new camera (${deviceId})`)
    }
  }

  const connectMicrophone = async (deviceId: string, connected: boolean) => {
    if (!connected) {
      log(`Microphone disconnected (${deviceId})`)
      const microphones = getMicrophones().filter((d) => !d.deviceIds.includes(deviceId))
      const lastMicrophoneIndex = lastMicrophoneIdRef.current
        ? microphones.findIndex((d) => d.deviceIds.includes(lastMicrophoneIdRef.current))
        : -1
      if (lastMicrophoneIndex !== -1) changeMicrophone(lastMicrophoneIdRef.current)
      else if (microphones.length > 0) changeMicrophone(microphones[0].deviceId)
      setLastMicrophoneId(undefined)
    } else if (deviceId) {
      setLastMicrophoneId(microphoneIdRef.current)
      changeMicrophone(deviceId)
      log(`Connected new microphone (${deviceId})`)
    }
  }

  const connectSpeaker = async (deviceId: string, connected: boolean) => {
    if (!connected) {
      log(`Speaker disconnected (${deviceId})`)
      const speakers = getSpeakers().filter((d) => !d.deviceIds.includes(deviceId))
      const lastSpeakerIndex = lastSpeakerIdRef.current
        ? speakers.findIndex((d) => d.deviceIds.includes(lastSpeakerIdRef.current))
        : -1
      if (lastSpeakerIndex !== -1) changeSpeaker(lastSpeakerIdRef.current)
      else if (speakers.length > 0) changeSpeaker(speakers[0].deviceId)
      setLastSpeakerId(undefined)
    } else if (deviceId) {
      setLastSpeakerId(speakerIdRef.current)
      changeSpeaker(deviceId)
      log(`Connected new speaker (${deviceId})`)
    }
  }

  const selectNextCamera = async () => {
    const cameras = getCameras()
    const index = cameras.findIndex((d) => d.deviceIds.includes(cameraIdRef.current))
    if (index !== cameras.length - 1) {
      changeCamera(cameras[index + 1].deviceId)
    } else {
      error('Failed to select next camera')
    }
  }

  const selectNextMicrophone = async () => {
    const microphones = getMicrophones()
    const index = microphones.findIndex((d) =>
      d.deviceIds.includes(microphoneIdRef.current)
    )
    if (index !== microphones.length - 1) {
      changeMicrophone(microphones[index + 1].deviceId)
    } else {
      error('Failed to select next microphone')
    }
  }

  const selectNextSpeaker = async () => {
    const speakers = getSpeakers()
    const index = speakers.findIndex((d) => d.deviceIds.includes(speakerIdRef.current))
    if (index !== speakers.length - 1) {
      changeSpeaker(speakers[index + 1].deviceId)
    } else {
      error('Failed to select next speaker')
    }
  }

  const setupHotpluggiing = (rtc: IAgoraRTC) => {
    rtc.on('camera-changed', (changedDevice) => {
      connectCamera(changedDevice.device.deviceId, changedDevice.state === 'ACTIVE')
    })
    rtc.on('microphone-changed', (changedDevice) => {
      connectMicrophone(changedDevice.device.deviceId, changedDevice.state === 'ACTIVE')
    })
    rtc.on('playback-device-changed', (changedDevice) => {
      connectSpeaker(changedDevice.device.deviceId, changedDevice.state === 'ACTIVE')
    })
  }

  const skipPermissionRequest = () => {
    setPermissionsRequested(true)
  }

  const scanDevices = async (rtc: IAgoraRTC): Promise<boolean> => {
    let mediaDevices: MediaDeviceInfo[] = []
    try {
      mediaDevices = await rtc.getDevices()
    } catch (err) {
      const { code, name } = (err as any) || {}
      if (name === 'AgoraRTCException' && code === 'PERMISSION_DENIED') {
        error('Device permissions denied. Please grant camera and microphone permissions')
      } else {
        error(`Devices scan failed. ${JSON.stringify(error)}`)
      }
      setPermissionsGranted(false)
      setPermissionsRequested(true)
      return false
    }

    if (mediaDevices.length !== devicesCountRef.current) {
      const groupedDevices = groupDevices(mediaDevices)
      setDevices(groupedDevices)
      setDevicesCount(mediaDevices.length)

      const cameras = getCameras()
      const microphones = getMicrophones()
      const speakers = getSpeakers()

      log(
        'Navigator devices:',
        mediaDevices
          .map((d) => `- [${d.kind}] ${d.label} (id: ${d.deviceId}, group: ${d.groupId})`)
          .join('\n')
      )

      log(
        '\nCameras:',
        cameras
          .map((d) => `- ${d.label} (ids: [${d.deviceIds.join()}], group: ${d.groupId})`)
          .join('\n'),
        '\nMicrophones:',
        microphones
          .map((d) => `- ${d.label} (ids: [${d.deviceIds.join()}], group: ${d.groupId})`)
          .join('\n'),
        '\nSpeakers:',
        speakers
          .map((d) => `- ${d.label} (ids: [${d.deviceIds.join()}], group: ${d.groupId})`)
          .join('\n')
      )

      if (!devicesInitializedRef.current) {
        if (cameras.length > 0) setCameraId(cameras[0].deviceId)
        if (microphones.length > 0) setMicrophoneId(microphones[0].deviceId)
        if (speakers.length > 0) setSpeakerId(speakers[0].deviceId)

        log(
          'Selected devices',
          `Audio input: ${microphoneIdRef.current}`,
          `Audio output: ${speakerIdRef.current}`,
          `Video input: ${cameraIdRef.current}`
        )
      } else {
        if (
          cameraIdRef.current &&
          !cameras.some((d) => d.deviceIds.includes(cameraIdRef.current))
        ) {
          error('Selected camera missing')
        }
        if (
          microphoneIdRef.current &&
          !microphones.some((d) => d.deviceIds.includes(microphoneIdRef.current))
        ) {
          error('Selected microphone missing')
        }
        if (
          speakerIdRef.current &&
          !speakers.some((d) => d.deviceIds.includes(speakerIdRef.current))
        ) {
          error('Selected speaker missing')
        }
      }
    }
    return true
  }

  const initDevices = async (rtc: IAgoraRTC) => {
    if (!(await scanDevices(rtc))) return
    setupHotpluggiing(rtc)
    setPermissionsGranted(true)
    setPermissionsRequested(true)
    setDevicesInitialized(true)
    log('Devices initialized')
  }

  const cameraAvailable = !!cameraId
  const microphoneAvailable = !!microphoneId
  const speakerAvailable = true // isSafari || isFirefox || !!speakerId

  return {
    cameraId,
    cameraAvailable,
    devices,
    devicesCount,
    devicesInitialized,
    hasUnavailableDevice:
      devicesInitialized &&
      (!microphoneAvailable || !cameraAvailable || !speakerAvailable),
    lastCameraId,
    lastMicrophoneId,
    lastSpeakerId,
    microphoneId,
    microphoneAvailable,
    permissionsGranted,
    permissionsRequested,
    settings: {
      cameraId,
      microphoneId,
      speakerId,
      videoProfile,
    },
    speakerId,
    speakerAvailable,
    videoProfile,
    changeCamera,
    changeMicrophone,
    changeSpeaker,
    changeVideoProfile,
    connectCamera,
    connectMicrophone,
    connectSpeaker,
    initDevices,
    scanDevices,
    selectNextCamera,
    selectNextMicrophone,
    selectNextSpeaker,
    skipPermissionRequest,
  }
}

export default useMediaDevices
