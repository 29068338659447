import styled from '@emotion/styled'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import * as interviewSelectors from '../../interview/selectors'
import { Text } from './MobileText'

const ProgressBarContainer = styled.div<{ isExpert: boolean; width: number }>`
  bottom: ${({ isExpert }) => (isExpert ? '0' : '5vh')};
  display: flex;
  height: 1.5rem;
  left: 50%;
  position: absolute;
  transform: ${({ isExpert }) =>
    isExpert
      ? ' translateX(calc(-50% - 0.75rem))'
      : 'translate(calc(-50% - 0.75rem), 100%)'};
  max-width: ${({ width }) => `calc(${width}px - 1.5rem)`};
  width: 75%;
`

const ProgressBarSection = styled.div<{
  min: number
  max: number
  value: number
  width: number
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: fit-content;
  position: relative;
  width: ${({ width }) => `${width}%`};

  &::after {
    border-radius: 1rem;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: ${({ min, max, value }) =>
      `calc(100% * min(max(${(value - min) / (max - min)}, 0), 1) + 1.5rem)`};
    z-index: -1;
  }

  &::before {
    border-radius: 1rem;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: calc(100% + 1.5rem);
    z-index: -1;
  }

  p {
    line-height: 1.5rem;
    padding: 0 0.75rem;
    text-align: center;
    top: 0;
    width: fit-content;
    white-space: nowrap;
    z-index: 100;

    span {
      font-weight: 500;
      margin-right: 0.25em;
    }

    font-size: 21px;
    @media screen and (max-width: 2500px) {
      font-size: 18px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }
    @media screen and (max-width: 900px) {
      font-size: 10px;
    }
  }
`

const IntroProgressBarSection = styled(ProgressBarSection)`
  flex-shrink: 0;

  &::after {
    background: #f1be2b;
  }

  &::before {
    background: #f1be2b;
  }
`

const InterviewProgressBarSection = styled(ProgressBarSection)`
  flex: 1;

  &::after {
    background: #b202ff;
  }

  &::before {
    background: #b202ff;
  }
`

const FeedbackProgressBarSection = styled(ProgressBarSection)`
  flex-shrink: 0;

  p {
    transform: translateX(0.75rem);
  }

  &::after {
    background: #f23482;
  }

  &::before {
    background: #f23482;
  }
`

interface IProps {
  isExpert: boolean
  width: number
}

export default function ProgressBar({ isExpert, width }: IProps) {
  const interviewDuration = useSelector(interviewSelectors.interviewDuration)
  const startedAt = new Date(useSelector(interviewSelectors.interviewStartedAt)).getTime()
  const [time, setTime] = useState(startedAt)

  const mockDuration = interviewDuration - 15

  useEffect(() => {
    const handle = setInterval(() => setTime(Date.now()), 1000)
    return () => clearInterval(handle)
  }, [])

  const introEnd = moment(startedAt).add(5, 'm').toDate().getTime()
  const feedbackStart = moment(startedAt)
    .add(5 + mockDuration, 'm')
    .toDate()
    .getTime()
  const interviewEnd = moment(startedAt).add(interviewDuration, 'm').toDate().getTime()

  const introShare = Math.round((5 / interviewDuration) * 100)
  const feedbackShare = Math.round((10 / interviewDuration) * 100)
  const interviewShare = 100 - introShare - feedbackShare

  return (
    <ProgressBarContainer isExpert={isExpert} width={width}>
      {introShare !== 0 && (
        <IntroProgressBarSection
          min={startedAt}
          max={introEnd}
          value={time}
          width={introShare}
        >
          <Text>
            <span>INTRO</span> 5 min
          </Text>
        </IntroProgressBarSection>
      )}
      {interviewShare !== 0 && (
        <InterviewProgressBarSection
          min={introEnd}
          max={feedbackStart}
          value={time}
          width={interviewShare}
        >
          <Text>
            <span>MOCK INTERVIEW</span> {mockDuration} min
          </Text>
        </InterviewProgressBarSection>
      )}
      {feedbackShare !== 0 && (
        <FeedbackProgressBarSection
          min={feedbackStart}
          max={interviewEnd}
          value={time}
          width={feedbackShare}
        >
          <Text>
            <span>FEEDBACK</span> 10 min
          </Text>
        </FeedbackProgressBarSection>
      )}
    </ProgressBarContainer>
  )
}
