import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'

import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: transparent;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const DataContainer = styled.div`
  width: 549px;
  background: #ffffff;
  border: 1px solid #b202ff;
  border-radius: 10px;
  padding: 44px;
  display: flex;
  flex-direction: column;

  ${isMobileOnly &&
  css`
    width: 90%;
    padding: 19px;
    @media (orientation: landscape) {
      width: 343px;
    }
  `}
`

const Header = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #1d0e3b;
  margin-bottom: 4px;

  ${isMobileOnly &&
  css`
    font-size: 20px;
  `}
`

const SubHeader = styled(Header)`
  font-weight: 500;
  font-size: 16px;
`

const TextItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #222b45;
`

const LinkText = styled.div`
  font-weight: 500;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #8f9bb3;
  margin-bottom: 24px;
`

const ConnectButton = styled(Button)`
  width: fit-content;
  height: auto;
  border-radius: 14px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin: 12px 0;
  align-self: center;
`

interface IProps {
  link: string
  onClick: () => void
}

const ConnectAlternativeRoom = ({ link, onClick }: IProps) => {
  return (
    <>
      <Container>
        <DataContainer>
          <Header>Connect to an alternative video room</Header>
          <SubHeader>
            It looks like you are having technical issues connecting to your session.
            Click the button below to be redirected to an alternative video room.
          </SubHeader>
          <ConnectButton title="Connect via alternative room" onClick={onClick} />
          <LinkText>Your link: {link}</LinkText>
          <TextItem>
            We will notify your expert to join you in the new URL - it usually takes 2-5
            minutes
          </TextItem>
        </DataContainer>
      </Container>
    </>
  )
}

export default ConnectAlternativeRoom
