import React, { useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { format } from 'date-fns'

import { AgencyCoachingTypes, CoachingType } from '../../api'
import {
  Autocomplete,
  Input,
  Button,
  Notification,
  TextBold,
  Text,
  Textarea,
  Select,
} from '../../components'
import DatePicker from '../../components/DatePicker'
import SelectComponent from '../../components/Select'
import Calendar from '../../static/calendar.svg'
import {
  agencySelectors,
  createInterviewAction,
  createInterviewActionFailureClear,
  createInterviewSelectors,
} from '../../redux'
import { EmailRegex } from '../../utils/regex'

const HeaderText = styled(TextBold)`
  margin-top: 25px;
`

const VSize = styled.div`
  height: 10px;
`

const Form = styled.form`
  margin-top: 15px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`

const ButtonStyled = styled(Button)`
  && {
    margin-top: 40px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 15px;
`

const StyledTextBold = styled(TextBold)`
  text-transform: uppercase;
  margin: ${({ margin }: any) => margin || '0 5px 0 0'};
`

const DateIcon = styled.img`
  width: 16px;
  display: block;
  margin-left: auto;
`

const StyledText = styled(TextBold)`
  text-transform: uppercase;
  margin: 0 5px 0 3px;
`

const OptionalText = styled(Text)`
  color: #8f9bb3;
  margin-left: 15px;
`

const checkFieldsFill = (fields: string[], values: object = {}) => {
  return fields.reduce((prev, curr) => !!values[curr] && prev, true)
}

interface IProps {
  isAvaInterview: boolean
}

function SingleBookingForm({ isAvaInterview }: IProps) {
  const { id } = useParams()
  const dispatch = useDispatch()
  const isLoading = useSelector(createInterviewSelectors.isLoading)
  const apiError = useSelector(createInterviewSelectors.error)

  const agency = useSelector(agencySelectors.data)
  const internalExperts = agency.internal_experts
  const internalExpertsOptions = useMemo(
    () =>
      (internalExperts || [])
        .map((e) => ({ label: e.name, value: e }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [internalExperts]
  )
  const { handleSubmit, errors, control, getValues, watch, setValue } = useForm({
    mode: 'onChange',
  })
  const coachingType = watch('coaching_type')
  const values = getValues()

  const [isSpecificCompany, setSpecificCompany] = useState<number | null>(null)
  const [isSpecificDate, setSpecificDate] = useState<string>('')

  const curr = new Date() // get current date
  const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
  const last = first + 7 // last day is the first day + 6

  const lastday = new Date(curr.setDate(last))

  const todayInWeek = format(new Date(), 'EEEE')

  const firstDayCurrentWeek = format(new Date(), 'MM/dd')
  const lastDayCurrentWeek =
    todayInWeek === 'Sunday' ? format(new Date(), 'MM/dd') : format(lastday, 'MM/dd')

  const firstdayNextWeek =
    todayInWeek === 'Sunday'
      ? format(new Date().setDate(first + 1), 'MM/dd')
      : format(new Date().setDate(last + 1), 'MM/dd')

  const onSubmit = (payload) => {
    const { internalCoach = [] } = payload

    if (isSpecificCompany === 0 && !isSpecificDate) {
      return
    }

    dispatch(
      createInterviewAction({
        id,
        isAvaInterview,
        ...payload,
        interview_date_option: payload.confirmedDate
          ? format(payload.confirmedDate, 'MM/dd')
          : isSpecificDate,
        internal_experts: internalCoach.map(({ value }) => value),
      })
    )
  }
  const clearError = () => {
    dispatch(createInterviewActionFailureClear())
  }

  const disabled = !checkFieldsFill(['email'], getValues())
  const hasFormErrors = Object.keys(errors).length > 0

  const isValidSpecificCompany =
    isAvaInterview || !agency.additional_info
      ? true
      : !!isSpecificCompany || isSpecificCompany === 0
  const isNotValid =
    !isAvaInterview &&
    ((isSpecificCompany === null && agency.additional_info) ||
      (isSpecificCompany === 0 && !isSpecificDate) ||
      (isSpecificDate === 'Confirm date' && !values.confirmedDate) ||
      (isSpecificCompany === 0 && (!values.company || !values.desiredPosition)))
  const hasSessionDetails = agency.additional_info || agency.has_coaching_types

  useEffect(() => {
    if (!coachingType) return

    const selectedInternalExperts = (getValues()?.internalCoach || []).filter((e) =>
      e.value?.coaching_types.includes(coachingType)
    )
    if (selectedInternalExperts.length > 0) {
      setValue('internalCoach', selectedInternalExperts)
    } else if (coachingType !== CoachingType.MOCK_INTERVIEW) {
      const availableIE = internalExpertsOptions.filter((e) =>
        e.value.coaching_types.includes(coachingType ?? CoachingType.MOCK_INTERVIEW)
      )
      if (availableIE.length <= 1) {
        setValue('internalCoach', availableIE)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachingType, internalExpertsOptions])

  return (
    <>
      <HeaderText>APPLICANT DETAILS</HeaderText>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {apiError && <Notification onClose={clearError} message={apiError} />}
        <Row>
          <Controller
            as={Input}
            label="First Name"
            name="firstName"
            control={control}
            error={errors.firstName}
            rules={{ required: !isAvaInterview }}
            defaultValue=""
          />
          <Controller
            as={Input}
            label="Last Name"
            name="lastName"
            control={control}
            error={errors.lastName}
            rules={{ required: !isAvaInterview }}
            defaultValue=""
          />
        </Row>
        <Controller
          as={Input}
          label="Email"
          name="email"
          control={control}
          disableWhitespaces
          error={errors.email}
          rules={{
            required: true,
            validate: {
              valid: (value) =>
                EmailRegex.test(value) ||
                "That format doesn't look right. Make sure there aren't any typos",
            },
          }}
          defaultValue=""
        />

        {!isAvaInterview && hasSessionDetails && (
          <>
            <TextWrapper>
              <StyledTextBold>SESSION DETAILS</StyledTextBold>
            </TextWrapper>

            {agency.has_coaching_types && (
              <Controller
                name="coaching_type"
                control={control}
                render={({ onChange, value, name: selectName }) => (
                  <Select
                    name={selectName}
                    label="IE Coaching type"
                    onChange={onChange}
                    value={value ?? ''}
                    options={AgencyCoachingTypes}
                    error={errors.coaching_type}
                    marginBottom={20}
                  />
                )}
                defaultValue={CoachingType.MOCK_INTERVIEW}
              />
            )}

            {agency.additional_info && (
              <SelectComponent
                options={[
                  { value: 0, label: 'Yes' },
                  { value: 1, label: 'No' },
                  {
                    value: 2,
                    label:
                      "I don't know - no worries, we'll ask the applicant to fill this out",
                  },
                ]}
                value={isSpecificCompany}
                onChange={setSpecificCompany}
                label={
                  'Would the applicant like to be coached for a \n specific company?'
                }
              />
            )}

            {isSpecificCompany === 0 && (
              <>
                <VSize />
                <Controller
                  as={Input}
                  rules={{ required: isSpecificCompany === 0 }}
                  label="Company"
                  name="company"
                  control={control}
                  error={errors.company}
                  defaultValue=""
                />
                <Controller
                  as={Input}
                  label="Desired Position"
                  name="desiredPosition"
                  rules={{ required: isSpecificCompany === 0 }}
                  control={control}
                  error={errors.desiredPosition}
                  defaultValue=""
                />

                <SelectComponent
                  options={[
                    {
                      value: 'Confirm date',
                      label: 'Confirm date',
                      icon: <DateIcon src={Calendar} alt="Calendar Icon" />,
                    },
                    {
                      value: `${firstDayCurrentWeek}-${lastDayCurrentWeek}`,
                      label: `This week`,
                    },
                    {
                      value: `${firstdayNextWeek} or later`,
                      label: `Next week or later`,
                    },

                    { value: 'N/A', label: "I don't know" },
                  ]}
                  rules={{ required: isSpecificCompany === 0 }}
                  onChange={setSpecificDate}
                  label="Job Interview Date"
                />

                {isSpecificDate === 'Confirm date' ? (
                  <>
                    <VSize />
                    <Controller
                      label="Confirm Date"
                      name="confirmedDate"
                      control={control}
                      disableWhitespaces
                      rules={{ required: true }}
                      error={errors.confirmedDate}
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <DatePicker
                          shouldCloseOnSelect
                          value={value}
                          onChange={onChange}
                          openOnLoad
                        />
                      )}
                    />
                  </>
                ) : (
                  <VSize />
                )}

                <Controller
                  as={Input}
                  label="Link to Position"
                  optional
                  name="positionLink"
                  control={control}
                  disableWhitespaces
                  error={errors.positionLink}
                  defaultValue=""
                />
              </>
            )}
          </>
        )}

        {!isAvaInterview && (
          <>
            <TextWrapper>
              <StyledText>ADDITIONAL INFO </StyledText> {/* &#8226; */}
              <OptionalText> Optional</OptionalText>
            </TextWrapper>

            <Controller
              as={Textarea}
              label="Anything you would like us to know?"
              name="moreInfo"
              control={control}
              defaultValue=""
              placeholder="This is information you'd like to share with us or with the coach"
            />
          </>
        )}

        {!isAvaInterview && internalExperts?.length > 0 && (
          <>
            <TextWrapper>
              <StyledTextBold>Internal coach </StyledTextBold>
              <OptionalText>Optional</OptionalText>
            </TextWrapper>
            <Controller
              as={Autocomplete}
              multiple
              label="Manually match an internal coach for the session"
              name="internalCoach"
              error={errors.internalCoach}
              options={internalExpertsOptions.filter((e) =>
                e.value.coaching_types.includes(
                  coachingType ?? CoachingType.MOCK_INTERVIEW
                )
              )}
              control={control}
              defaultValue={[]}
            />
          </>
        )}

        <ButtonStyled
          disabled={
            isLoading ||
            disabled ||
            hasFormErrors ||
            !isValidSpecificCompany ||
            isNotValid
          }
          title="Send invite"
        />
      </Form>
    </>
  )
}

export default SingleBookingForm
