import styled from '@emotion/styled'
import React, { useState } from 'react'
import AppearBlock from 'src/interview/components/AppearBlock'
import { NumbersItemRow } from 'src/interview/components/landing-styles'
import DynamicNumber from 'src/interview/components/DynamicNumber'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useBranding from 'src/utils/useBranding'
import { LandingInfo, LandingTitle } from './landing-styled-components'

const Layout = styled.div`
  width: 100%;
  margin-bottom: 160px;

  @media screen and (max-width: 1439px) {
    margin-bottom: 120px;
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 32px;
  }

  ${isMobileOnly &&
  css`
    height: auto;
    margin: 0 50px;
    width: auto;
    flex-direction: column;
    margin-bottom: 24px !important;
  `}
`

const NumberItemsContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 899px) {
    flex-direction: column;
    align-items: center;
  }

  ${isMobileOnly &&
  css`
    flex-direction: column;
    margin-top: 16px;
    align-items: center;

    @media (orientation: landscape) {
      flex-direction: row !important;
    }
  `}
`

const NumberItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  ${isMobileOnly &&
  css`
    max-width: 330px !important;
  `}
`

const NumberContainer = styled(NumbersItemRow)`
  justify-content: center;
  width: 100%;

  ${isMobileOnly &&
  css`
    font-size: 32px;
    line-height: 150%;
    width: auto;
  `}
`

const LandingInfoStyled = styled(LandingInfo)`
  margin: 0;

  @media screen and (max-width: 899px) {
    margin-bottom: 48px;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 32px !important;
  `}
`

const LandingNumbersBlock = () => {
  const { agencyColor } = useBranding()

  const [showNumbers, setShowNumbers] = useState(false)
  return (
    <Layout>
      <LandingTitle>Numbers speak louder than words</LandingTitle>
      <AppearBlock onAppear={setShowNumbers}>
        <NumberItemsContainer>
          <NumberItem>
            <NumberContainer color="#F1BE2B">
              <DynamicNumber value={87} show={showNumbers} round />%
            </NumberContainer>
            <LandingInfoStyled>{`of users secure jobs within\n90 days of use.`}</LandingInfoStyled>
          </NumberItem>
          <NumberItem>
            <NumberContainer color={agencyColor}>
              <DynamicNumber value={96} show={showNumbers} round />%
            </NumberContainer>
            <LandingInfoStyled>
              of job seekers would recommend Ava coaching to a friend or colleague.
            </LandingInfoStyled>
          </NumberItem>
        </NumberItemsContainer>
      </AppearBlock>
    </Layout>
  )
}

export default LandingNumbersBlock
