/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import ArrowImage from 'src/static/icArrow.png'
import ImgOrgToronto from 'src/static/landing/landing_org_toronto.png'
import ImgOrgKipp from 'src/static/landing/landing_org_kipp.png'
import ImgOrgSydney from 'src/static/landing/landing_org_sydney.png'
import IcBracers from 'src/static/landing/icBracers.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { BaseLayout, LayoutWrapper } from './landing-styled-components'

const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 80px 80px 24px 80px;

  @media screen and (max-width: 1439px) {
    padding: 39px 62px 24px 62px;
  }

  @media screen and (max-width: 899px) {
    padding: 40px 24px 16px 24px;
  }
`

const ColunmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 110px);

  @media screen and (max-width: 899px) {
    width: 100%;
  }
`

const DotsContainer = styled.div`
  display: flex;
  margin-top: 100px;

  @media screen and (max-width: 1439px) {
    margin-top: 68px;
  }
`

const DotItem = styled.div<{ active?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 0 6px;
  background: ${({ active }) => (active ? '#B202FF' : '#E1E3EA')};
  cursor: pointer;
`

const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
`

const BackButton = styled.img<{ mirror?: boolean }>`
  width: 55px;
  height: 55px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 30px;
  object-fit: contain;
  cursor: pointer;
  transform: rotate(${({ mirror }) => (mirror ? 180 : 0)}deg);

  @media screen and (max-width: 899px) {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
`

const SlideText = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  padding: 0 52px;
  margin-bottom: 30px;

  @media screen and (max-width: 1439px) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 1px;
    content: '';
    width: 43px;
    height: 28px;
    background: url(${IcBracers});
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    content: '';
    width: 43px;
    height: 28px;
    background: url(${IcBracers});
    background-size: 100%;
    background-repeat: no-repeat;
  }

  ${isMobileOnly &&
  css`
    padding: 34px 0 !important;
  `}
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AuthorText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: white;
  white-space: break-spaces;
`

const OrgText = styled(AuthorText)`
  font-weight: 700;
`

const SliderWrapper = styled.div`
  width: 100%;
  padding: 0 10%;

  @media screen and (max-width: 1439px) {
    padding: 0 56px;
  }

  @media screen and (max-width: 899px) {
    padding: 0;
  }
`

const Logo = styled.img`
  width: 70px;
  height: auto;
  object-fit: contain;
  margin-right: 16px;
`

const SlideItemContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1439px) {
    height: 216px;
    justify-content: space-around;
  }

  ${isMobileOnly &&
  css`
    height: 300px !important;
  `}
`

const Slides = [
  {
    text:
      'We have collaborated with the Wizco team to bring industry-led coaching and mock interview sessions to our participants in the Job Smart program. Thanks to the partnership with Wizco, our students can receive career advice from industry experts worldwide. The participants have found the sessions very valuable.',
    author:
      'Denis Qui\nJob Smart Program Manager - \nCareers  and Employability Office at',
    organization: 'The University of Sydney',
    logo: ImgOrgSydney,
  },
  {
    text:
      'Our Co-op Team was thoroughly impressed with our first time using Wizco. The industry experts were thoughtfully paired with diverse co-op students across 4 unique faculties, and we received a high level of positive feedback about the overall live mock interview experience. Thank you, Wizco!',
    author: 'Taryn De Ruyter,\nManager Career Integrated Learning',
    organization: 'Toronto Metropolitan University',
    logo: ImgOrgToronto,
  },
  {
    text:
      'Wizco does not provide “regular career coaching”, they are setting the bar for stellar customer service and career coaching. Their coaches are trained to provide high quality feedback with actionable steps for improvement all tailored to user needs! Their innovative and engaging platform is culturally relevant and captivating for our Gen Z students and early career alumni!',
    author: 'Margarita Moreno\nSenior Manager, Career Continuum at',
    organization: 'KIPP Forward',
    logo: ImgOrgKipp,
  },
]

const LandingReviews = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(1)
  const sliderRef = useRef<Slider>(null)

  const handleGoBack = () => {
    sliderRef.current.slickPrev()
  }

  const handleGoNext = () => {
    sliderRef.current.slickNext()
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(activeSlideIndex, false)
  }, [activeSlideIndex])

  return (
    <LayoutWrapper background="#0e0224">
      <Layout>
        <BackButton mirror src={ArrowImage} onClick={handleGoBack} />
        <ColunmContainer>
          <SliderContainer>
            <SliderWrapper>
              <Slider
                ref={sliderRef}
                arrows={false}
                slidesToShow={1}
                slidesToScroll={1}
                speed={400}
                centerMode
                centerPadding="0"
                beforeChange={(_, newIndex) => setActiveSlideIndex(newIndex)}
              >
                {Slides.map((item) => (
                  <SlideItemContainer key={item.text}>
                    <SlideText>{item.text}</SlideText>
                    <LineContainer>
                      <Logo src={item.logo} alt="logo" />
                      <ColContainer>
                        <AuthorText>{item.author}</AuthorText>
                        <OrgText>{item.organization}</OrgText>
                      </ColContainer>
                    </LineContainer>
                  </SlideItemContainer>
                ))}
              </Slider>
            </SliderWrapper>
          </SliderContainer>
          <DotsContainer>
            {Slides.map((_, index) => (
              <DotItem
                key={index}
                active={index === activeSlideIndex}
                onClick={() => setActiveSlideIndex(index)}
              />
            ))}
          </DotsContainer>
        </ColunmContainer>
        <BackButton src={ArrowImage} onClick={handleGoNext} />
      </Layout>
    </LayoutWrapper>
  )
}

export default LandingReviews
