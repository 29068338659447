import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgRemove({ fill }: SvgProps) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7293 2.54379H12.2093C11.602 1.01296 10.0631 0 8.34473 0C6.62639 0 5.08747 1.01296 4.48011 2.54379H0.960111C0.620244 2.54379 0.344727 2.80618 0.344727 3.12985C0.344727 3.45353 0.620244 3.71592 0.960111 3.71592H1.37037V15.8279C1.37037 16.4752 1.9214 17 2.60114 17H13.6781C14.3578 17 14.9088 16.4752 14.9088 15.8279V3.71592H15.7293C16.0692 3.71592 16.3447 3.45353 16.3447 3.12985C16.3447 2.80618 16.0692 2.54379 15.7293 2.54379ZM8.34473 1.17631C9.46473 1.17631 10.437 1.56702 10.917 2.54379H5.77242C6.25242 1.56311 7.22473 1.17631 8.34473 1.17631ZM13.6781 15.8279H12.037V9.18583C12.037 8.86216 11.7615 8.59977 11.4216 8.59977C11.0818 8.59977 10.8063 8.86216 10.8063 9.18583V15.8279H8.75498V6.84158C8.75498 6.51791 8.47947 6.25552 8.1396 6.25552C7.79973 6.25552 7.52421 6.51791 7.52421 6.84158V15.8279H5.47293V9.18583C5.47293 8.86216 5.19741 8.59977 4.85755 8.59977C4.51768 8.59977 4.24216 8.86216 4.24216 9.18583V15.8279H2.60114V3.71592H13.6781V15.8279Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgRemove
