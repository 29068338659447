import { getPrivacyLink } from 'src/utils/links'
import CoachingStep1 from '../static/ic-landing-coachingstep-1.png'
import CoachingStep2 from '../static/ic-landing-coachingstep-2.png'
import CoachingStep3 from '../static/ic-landing-coachingstep-3.png'
import Banners from '../static/banners'
import intelligenceCompany from '../static/ic-landing-company.png'
import intelligenceCareer from '../static/ic-landing-career.png'
import intelligenceCulture from '../static/ic-landing-culture.png'
import intelligenceAttitude from '../static/ic-landing-attitude.png'
import intelligenceRole from '../static/ic-landing-role.png'
import intelligenceProblem from '../static/ic-landing-problem.png'
import howitworks1 from '../static/how-it-works-1.png'
import howitworks2 from '../static/how-it-works-2.png'
import howitworks3 from '../static/how-it-works-3.png'
import howitworks4 from '../static/how-it-works-4.png'

export const CoachingSteps = [
  {
    title: 'Ace your next interview',
    text:
      'Practice for your big interview under the guidance of an expert with insider knowledge of your desired company and position',
    icon: CoachingStep1,
  },
  {
    title: 'Plan your career strategy',
    text:
      'Map out a solid career plan for your future. Stay focussed, motivated and make well-informed decisions',
    icon: CoachingStep2,
  },
  {
    title: 'Showcase your talents ',
    text:
      'Build a modern resume and LinkedIn profile that tells your unique career story and double your chances of getting an interview, guaranteed.',
    icon: CoachingStep3,
  },
]

export const HowItWorksSteps = [
  {
    title: 'Personalize your session',
    text: 'Decide on the type of session you need and specify your coaching goals.',
    video: '/static/media/video/video_3.webm',
    videoIos: howitworks1,
  },
  {
    title: 'Pick your expert and time',
    text: `We ensure you'll only be matched with experts whose professional experience is aligned with your desired position and specific coaching needs.`,
    video: '/static/media/video/video_1.webm',
    videoIos: howitworks3,
  },
  {
    title: '45-minute coaching session',
    text:
      'Identify your strengths, develop new skills and get answers to all your career questions in your expert-led, fully personalized coaching session.',
    video: '/static/media/video/video_2.webm',
    videoIos: howitworks2,
  },
  {
    title: 'Review your session report',
    text:
      'Gain insights and a deeper understanding with actionable feedback from your chosen expert and our advanced AI Interview Intelligence.',
    video: '/static/media/video/video_4.webm',
    videoIos: howitworks4,
  },
]

export const AdBanners1 = [
  { img: Banners.McKinsey },
  { img: Banners.JPMorgan },
  { img: Banners.Microsoft },
  { img: Banners.Citi },
  { img: Banners.Uber },
  { img: Banners.Robinhood },
  { img: Banners.TikTok },
  { img: Banners.UiPath },
  { img: Banners.Walmart },
  { img: Banners.Disney },
  { img: Banners.PayPal },
  { img: Banners.Tesla },
  { img: Banners.Nvidia },
  { img: Banners.Visa },
  { img: Banners.JohnsonJohnson },
  { img: Banners.BankOfAmerica },
]

export const AdBanners2 = [
  { img: Banners.Slack },
  { img: Banners.Meta },
  { img: Banners.Netflix },
  { img: Banners.Amazon },
  { img: Banners.Apple },
  { img: Banners.Google },
  { img: Banners.SalesForce },
  { img: Banners.BCG },
  { img: Banners.StitchFix },
  { img: Banners.Klarna },
  { img: Banners.PG },
  { img: Banners.Abbvie },
  { img: Banners.Pfizer },
  { img: Banners.CocaCola },
  { img: Banners.WellsFargo },
  { img: Banners.Airbnb },
]

export const CBAdBanners1 = [
  { img: Banners.Mondo },
  { img: Banners.Delta },
  { img: Banners.Deliotte },
  { img: Banners.WellsFargo },
  { img: Banners.Mutual },
  { img: Banners.Danaher },
  { img: Banners.Arista },
  { img: Banners.Walmart },
  { img: Banners.B8451 },
  { img: Banners.Disney },
  { img: Banners.PayPal },
  { img: Banners.Tesla },
  { img: Banners.Nvidia },
  { img: Banners.Visa },
  { img: Banners.JohnsonJohnson },
  { img: Banners.BankOfAmerica },
]

export const CBAdBanners2 = [
  { img: Banners.Meta },
  { img: Banners.Aep },
  { img: Banners.Amazon },
  { img: Banners.Fedex },
  { img: Banners.TotoRooter },
  { img: Banners.Kone },
  { img: Banners.Pwc },
  { img: Banners.Rippling },
  { img: Banners.Cisco },
  { img: Banners.Klarna },
  { img: Banners.PG },
  { img: Banners.Abbvie },
  { img: Banners.Pfizer },
  { img: Banners.CocaCola },
  { img: Banners.WellsFargo },
  { img: Banners.Airbnb },
]

export const IntelligenceAnimationLengthSec = 0.8
export const IntelligenceAnimationStepSec = 0.4

export const IntelligenceItems = [
  { img: intelligenceCompany, order: 2, width: 119, widthMobile: '19%' },
  { img: intelligenceCareer, order: 5, width: 119, widthMobile: '19%' },
  { img: intelligenceCulture, order: 0, width: 378, widthMobile: '55%' },
  { img: intelligenceAttitude, order: 3, width: 378, widthMobile: '55%' },
  { img: intelligenceProblem, order: 1, width: 119, widthMobile: '19%' },
  { img: intelligenceRole, order: 4, width: 119, widthMobile: '19%' },
]

export const ReviewsItems = [
  {
    text:
      'Jonathan was really passionate and professional. He asked good technical questions, and gave great feedback on how to improve. I really enjoyed this!',
    author: 'Lisa | Fullstack engineer at Slack',
    logo: Banners.Slack,
  },
  {
    text:
      'WOW! Elizabeth blew me away. Not only did she make me feel so incredibly comfortable, she also had fantastic advice and asked pertinent questions that helped me reshape my own professional narrative.',
    author: 'Eric | Senior Partner Marketing Manager at Microsoft',
    logo: Banners.Microsoft,
  },
  {
    text:
      'I got the job offer! Jacob was excellent! He gave me very insightful feedback and advice, some of the best feedback I have received!',
    author: 'Laura | Product Manager at Amazon',
    logo: Banners.Amazon,
  },
  {
    text:
      'It was honestly perfect, my coach went above and beyond to help me and best of all, the mock was very accurate to my real interview.',
    author: 'Martin | Account Strategist at Google',
    logo: Banners.Google,
  },
  {
    text:
      'I now feel much more confident and fully prepared. Having worked for one of the companies I am interviewing with, my coach had insider knowladge that was extremely valuable.',
    author: 'Jason | Enterprise Corporate Sales at Salesforce',
    logo: Banners.SalesForce,
    height: 42,
  },
  {
    text:
      'Laura was amazing and extremely helpful!!! She was very clear in her recommendations and took the time to make sure I understood everything she explained. It  definitely helped me in my interview. ',
    author: 'Grace | Data analyst at TikTok',
    logo: Banners.TikTok,
  },
]

export const CBReviewsItems = [
  {
    text:
      'Excellent advice and examples for dealing with a high level interview situation.  I now feel that I WILL be successful in future interviews.',
    author: 'Kyla | Assistant Director, Somerset County',
    logo: Banners.Somerset,
  },
  {
    text: `I'm so grateful for the opportunity to polish my interview skills. My coach was kind, insightful and had the professional guidance I needed to land my dream job.`,
    author: 'Isaiah | Building Inspector, The LiRo Group',
    logo: Banners.LiroGroup,
  },
  {
    text: `Natasha was super helpful. She helped me prepare and practice for my interviews and I can't imagine how I would have gotten this job without her help!`,
    author: 'Amanda | Warehouse supervisor, UNFI',
    logo: Banners.Unfi,
  },
  {
    text:
      'I wish I signed up for this sooner.  The feedback was given clearly and concisely. I felt heard and I respected my coach’s input and feedback because she has worked in science.  ',
    author: 'Monica | HR Information Systems Analyst, Forte Consulting',
    logo: Banners.Forte,
    height: undefined,
  },
  {
    text: `This has been the single most valuable tool that I've had access to for my job search. Now I finally feel excited about getting where I want to go. I am so grateful for the opportunity to do this!`,
    author: 'Keon | Creative Copywriter, Pierpont Communications',
    logo: Banners.Pierpont,
  },
]

export const FaqsItems = [
  {
    question: 'How does the matching algorithm work?',
    answer: `Guided by your unique individual coaching needs, you'll be matched with experts whose experience and skills ensure you get the most value from your coaching session.`,
  },
  {
    question: 'How much does a session cost?',
    answer: `Our session prices range from %min_price - %max_price depending on your needs and budget.`,
  },
  {
    question: 'What is your rescheduling or cancellation policy, and how do I cancel?',
    answer: `We understand that life happens, and there will be times we'll you need to cancel your session. We kindly ask that you aim to cancel a session at least 24 hours in advance so that we have time to communicate the change to your chosen expert and reschedule.`,
  },
  {
    question: 'What coaching services do you offer?',
    answer: `We offer a range of services including mock interviews, career Q&As, advice on career transitioning, resume, LinkedIn and cover letter reviews, business plan assessments, and elevator pitch practice. We're always looking for ways to improve our service offering so if you're looking for a different type of coaching, feel free to let us know at hi@wizco.io`,
  },
  {
    question: 'What is a mock interview?',
    answer: `Have an upcoming interview and need to prepare? Work with an expert who has first-hand experience in hiring for your desired position and company to practice your interview skills, better understand what and how to improve, and get clear visibility on how you come across professionally. Conducted on our innovative platform, we're recreating the interview experience but with added benefit of actionable feedback, smart reporting and a session recording.`,
  },
  {
    question: 'What types of interview preparation do you offer?',
    answer: `Our interview preparation covers behavioural and competency-based questions, one-on-one and panel interviews, phone and video interview prep, case-studies, and technical interviews.`,
  },
  {
    question: 'Do you offer coaching services in languages other than English?',
    answer: `Our vast network of industry experts allows us to offer coaching in any language.`,
  },
  {
    question: 'Where are your experts located?',
    answer: `We work with experts from all corners of the globe.`,
  },
  {
    question: 'How does Wizco protect my privacy?',
    answer: `Wizco takes privacy and security seriously and implemented security means and internal policies to ensure compliance with data protection regulations such as the EU GDPR and CCPA.\n\rYou may access, amend, or remove your personal data from our database at any point. For a full breakdown, please see our `,
    link: 'Privacy Policy',
    linkUrl: getPrivacyLink(),
  },
]

export const CBFaqsItems = [
  {
    question: 'What is the 100,000 careers initiative?',
    answer: `The mission of the “100,000 Careers” initiative is to help people in the Black community find a job and build the career of their dreams. The platform includes tools to help candidates get started, such as job listings, easy resume building, and custom content designed to help Black listeners identify their transferrable skills and discover new careers. The “100,000 Careers” platform is a one-stop-shop for connecting employers with Black American career-seekers looking to advance.  
    • See how your salary compares to others with the same job title in your area. Not only can you compare your salary, but you can also see what skills you are missing to earn more money.
    • Easily apply to multiple jobs with one click! Quick Apply shows you recommended jobs based off your most recent search and allows you to apply to 25+ jobs in a matter of seconds!
    • Keep track of positions that you’re interested in by signing up for job alert emails. You’ll be notified via email when new jobs are posted in that search.`,
  },
  {
    question: 'How does the matching algorithm work?',
    answer: `Guided by your unique individual coaching needs, you'll be matched with experts whose experience and skills ensure you get the most value from your coaching session.`,
  },
  {
    question: 'How much does a session cost?',
    answer: `Our session prices range from %min_price - %max_price depending on your needs and budget.`,
  },
  {
    question: 'What is your rescheduling or cancellation policy, and how do I cancel?',
    answer: `We understand that life happens, and there will be times we'll you need to cancel your session. We kindly ask that you aim to cancel a session at least 24 hours in advance so that we have time to communicate the change to your chosen expert and reschedule.`,
  },
  {
    question: 'What coaching services do you offer?',
    answer: `We offer a range of services including mock interviews, career Q&As, advice on career transitioning, resume, LinkedIn and cover letter reviews, business plan assessments, and elevator pitch practice. We're always looking for ways to improve our service offering so if you're looking for a different type of coaching, feel free to let us know at hi@wizco.io`,
  },
  {
    question: 'What is a mock interview?',
    answer: `Have an upcoming interview and need to prepare? Work with an expert who has first-hand experience in hiring for your desired position and company to practice your interview skills, better understand what and how to improve, and get clear visibility on how you come across professionally. Conducted on our innovative platform, we're recreating the interview experience but with added benefit of actionable feedback, smart reporting and a session recording.`,
  },
  {
    question: 'What types of interview preparation do you offer?',
    answer: `Our interview preparation covers behavioural and competency-based questions, one-on-one and panel interviews, phone and video interview prep, case-studies, and technical interviews.`,
  },
  {
    question: 'How does Wizco protect my privacy?',
    answer: `Wizco takes privacy and security seriously and implemented security means and internal policies to ensure compliance with data protection regulations such as the EU GDPR and CCPA.\n\rYou may access, amend, or remove your personal data from our database at any point. For a full breakdown, please see our `,
    link: 'Privacy Policy',
    linkUrl: getPrivacyLink(),
  },
]
