import styled from '@emotion/styled'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useStateRef from 'react-usestateref'

import { Button, DropdownButton, Text } from '../../components'
import { bulkCreateInterviewAction } from '../../redux'
import downloadCSV from '../../utils/downloadCSV'
import downloadXLSX from '../../utils/downloadXLSX'

import BulkInterviewFileDrop from '../components/BulkInterviewFileDrop'

enum TemplateFileType {
  CSV = 'csv',
  XLSX = 'xlsx',
}

const TemplateFileOptions = [
  { label: '.csv', value: TemplateFileType.CSV },
  { label: '.xlsx', value: TemplateFileType.XLSX },
]

const TemplateData = 'Applicant email,Applicant name'
const TemplateDataAva = 'Applicant email,Applicant name (optional)'

const TemplateButton = styled(DropdownButton)`
  height: 40px;
  padding-right: 25px;
  position: relative;
  width: 170px;

  &::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;

    border: solid #273238;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg) translateX(-50%) translateY(-50%);
    -webkit-transform: rotate(45deg) translateX(-50%) translateY(-50%);
  }

  > div {
    width: 170px;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 40px;
`

const FirstText = styled(Text)`
  margin-top: 25px;
`

interface IProps {
  isAvaInterview: boolean
}

function BulkBookingForm({ isAvaInterview }: IProps) {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [fileData, setFileData] = useStateRef(undefined)

  const downloadTemplate = (option: TemplateFileType) => {
    const template = isAvaInterview ? TemplateDataAva : TemplateData
    if (option === TemplateFileType.CSV)
      downloadCSV(template, 'Wizco_applicants_bulk_upload_template')
    else downloadXLSX(template, 'Wizco_applicants_bulk_upload_template')
  }

  const onSubmit = () => {
    dispatch(
      bulkCreateInterviewAction({
        agencyId: id,
        isAvaInterview,
        ...fileData,
      })
    )
    setFileData(undefined)
  }

  return (
    <>
      <FirstText>1. Download a template (optional)</FirstText>
      <TemplateButton
        customTheme="outline"
        title="Download template"
        options={TemplateFileOptions}
        onClick={downloadTemplate}
      />
      <Text>2. Edit and save your file</Text>
      <Text>3. Upload your CSV/EXCEL file</Text>
      <BulkInterviewFileDrop
        ignoreNameValidation={isAvaInterview}
        onUpdate={setFileData}
      />
      <SubmitButton disabled={!fileData} title="Send invite" onClick={onSubmit} />
    </>
  )
}

export default BulkBookingForm
