import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import SvgClose from 'src/static/close_gray.svg'
import RadioButtonGroup from 'src/components/RadioButtonGroup'
import { useSelector } from 'react-redux'
import { getShortLocalTime } from 'src/utils/date'
import { css } from '@emotion/core'
import { Controller, useForm } from 'react-hook-form'
import {
  ApplicantAvailabilityDaysNew,
  ApplicantAvailabilityDays,
  ApplicantAvailabilityTimesNew,
  ApplicantAvailabilityTimes,
} from 'src/utils/staticData'
import moment from 'moment'
import SvgInfo from 'src/static/components/SvgInfo'
import AvailabilityExpertCalendar from './AvailabilityExpertCalendar'
import * as interviewSelectors from '../selectors'
import AvailabilityDaysCheckbox from './AvailabilityDaysCheckbox'
import AvailabilityTimeCheckbox from './AvailabilityTimeCheckbox'

const Container = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  background: #00000050;
`

const BlockContainer = styled.form`
  background: white;
  display: flex;
  width: 737px;
  flex-direction: column;
  border-radius: 16px;
  padding: 40px;
  margin-top: 41px;
  margin-bottom: 41px;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 16px;
  }
`

const RowContainer = styled.div<{ bottom?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ bottom }) => bottom ?? 12}px;
`

const ExpertsWrapper = styled.div`
  margin-bottom: 32px;
`

const BottomRowContainer = styled(RowContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
  > div {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: #222b45;
  flex: 1;
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-bottom: 6px;
`

const CalendarSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-top: 18px;
`

const BlockTitle = styled.div<{ top?: number; bottom?: number }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: ${({ bottom }) => bottom ?? 12}px;
  margin-top: ${({ top }) => top ?? 0}px;
  color: #21304e;
  text-transform: uppercase;
`

const CloseButton = styled.img`
  cursor: pointer;

  height: 15px;
  width: 15px;
`

const CalendarWrapper = styled.div`
  margin: 12px 0 18px 0;
`

const SelectedSlotsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SlotContainer = styled.div`
  position: relative;
  display: flex;
  margin-right: 26px;
  margin-bottom: 23px;
  padding-left: 28px;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #e1e3ea;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const SlotDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  width: 50px;
`

const WeekLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  width: 30px;
`

const SlotTime = styled(WeekLabel)`
  width: unset;
  max-width: 150px;
  min-width: 100px;
`

const DetailsText = styled.textarea`
  height: 77px;
  background: #f5f6f8;
  border: 1px solid #e1e3ea;
  border-radius: 12px;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 12px;
  resize: none;

  &:focus {
    outline: none !important;
    border: 1px solid #b202ff;
  }

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-right: 40px;
      height: 85px;
    }
  `}
`

const Button = styled.button<{ disabled?: boolean }>`
  width: 96px;
  height: 40px;
  cursor: pointer;
  background: #b202ff;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  border: none;
  outline: 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  &:active {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
    width: 100%;
  }
`

const AvailabilityLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #21304e;
  margin-bottom: 8px;
  text-transform: uppercase;
`

const AvailabilityDaysRowWrap = styled.div<{ newFlow?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ newFlow }) => (newFlow ? 'flex-start' : 'space-between')};
  margin-bottom: 20px;

  & > div {
    width: ${({ newFlow }) => (newFlow ? '75px' : '48%')};
    height: ${({ newFlow }) => (newFlow ? '66px' : '56px')};
    margin: ${({ newFlow }) => (newFlow ? '0 12px 12px 0' : '0')};
  }
`

const TimezoneLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: black;
  margin-bottom: 8px;
`

const AvailabilityTimeRowWrap = styled.div<{ newFlow?: boolean }>`
  display: flex;
  flex-wrap: ${({ newFlow }) => (newFlow ? 'wrap' : 'no-wrap')};
  justify-content: ${({ newFlow }) => (newFlow ? 'flex-start' : 'space-between')};

  & > div {
    width: ${({ newFlow }) => (newFlow ? '102px' : '31%')};
    height: ${({ newFlow }) => (newFlow ? '78px' : 'auto')};
    margin: ${({ newFlow }) => (newFlow ? '0 12px 12px 0' : '0')};
  }
`

const ErrorContainer = styled.div`
  background: rgba(178, 2, 255, 0.04);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 18px;
`

const ErrorText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  color: #3c424e;
  margin-left: 14px;
`

const AvailabilitySlotsOption = 'Select specific time slots'
const AvailabilityDaysOption = 'Select group days and times'
const AvailabilityOptions = [AvailabilitySlotsOption, AvailabilityDaysOption]

interface IProps {
  onClose?: () => void
  onRescheduled?: (payload: any) => void
}

function SchedulingReschedulePopup({ onClose, onRescheduled }: IProps) {
  const scrollAnchor = useRef<HTMLDivElement>()
  const interviewDetails = useSelector(interviewSelectors.interview)
  console.log('[interviewDetails]', interviewDetails)
  const newApplicantAvailabilityFlow =
    interviewDetails?.interview?.use_new_applicant_availability_flow
  const [availabilityType, setAvailabilityType] = useState(AvailabilitySlotsOption)
  const [error, setError] = useState(false)
  const [expertError, setExpertError] = useState(false)

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      selectedExpertId: undefined,
      selectedDays: [],
      availabilityDays: interviewDetails.interview.availability_days,
      availabilityTime: interviewDetails.interview.availability_time,
    },
  })

  const { selectedDays, availabilityDays, availabilityTime } = watch()
  const weekOptionChecked =
    availabilityDays && Object.values(availabilityDays).find((v) => !!v)
  const timeOptionChecked =
    availabilityTime && Object.values(availabilityTime).find((v) => !!v)

  const getDateLabel = (dateMs: number) => {
    const date = new Date(dateMs)
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`
    return `${day}/${month},`
  }

  const getWeekLabel = (dateMs: number) => {
    const date = new Date(dateMs)
    return `${date.toLocaleDateString('en-US', { weekday: 'short' })}`
  }

  const getTimeLabel = (dateMs: number) => {
    const interviewLengthMs = interviewDetails.agency.duration * 60 * 1000
    return `| ${getShortLocalTime(new Date(dateMs))} - ${getShortLocalTime(
      new Date(dateMs + interviewLengthMs)
    )}`
  }

  const onReschedule = useCallback(
    (payload) => {
      console.log('[payload]', payload)
      if (availabilityType === AvailabilitySlotsOption && selectedDays?.length < 3) {
        setError(true)
        scrollAnchor.current.scrollIntoView({ behavior: 'smooth' })
        return
      }
      if (!payload.selectedExpertId) {
        setExpertError(true)
        return
      }

      onRescheduled({ ...payload, useTheSameExpert: payload.selectedExpertId !== '-1' })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availabilityType, selectedDays?.length]
  )

  useEffect(() => {
    setError(false)
    setExpertError(false)
  }, [availabilityType])

  const timeZoneText = `${moment.tz.guess()} (${getShortLocalTime(new Date())})`
  const canReschedule =
    availabilityType === AvailabilitySlotsOption ||
    (availabilityType === AvailabilityDaysOption &&
      weekOptionChecked &&
      timeOptionChecked)
  const applicantAvailabilityDays = newApplicantAvailabilityFlow
    ? ApplicantAvailabilityDaysNew
    : ApplicantAvailabilityDays
  const applicantAvailabilityTimes = newApplicantAvailabilityFlow
    ? ApplicantAvailabilityTimesNew
    : ApplicantAvailabilityTimes

  const expertList = interviewDetails.experts
    .filter((ex) => !ex.decline_reason && !!ex.time_slots?.length)
    .map((ex) => {
      return {
        text: `${ex.first_name} ${ex.last_name} (${ex.current_position}, ${ex.company})`,
        value: ex.id,
      }
    })

  expertList.push({
    text: 'I would like another expert',
    value: '-1',
  })

  return (
    <Container onClick={onClose}>
      <BlockContainer
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSubmit(onReschedule)}
      >
        <RowContainer bottom={32}>
          <Title>Update your availability </Title>
          <CloseButton onClick={onClose} src={SvgClose} />
        </RowContainer>
        <BlockTitle bottom={6}>Your availability</BlockTitle>
        <RadioButtonGroup
          value={availabilityType}
          onChange={setAvailabilityType}
          useTheme={false}
          options={AvailabilityOptions.map((o) => {
            return { text: o, value: o }
          })}
        />
        <div ref={scrollAnchor} />
        {error && (
          <ErrorContainer>
            <SvgInfo />
            <ErrorText>Please select at least three time slots</ErrorText>
          </ErrorContainer>
        )}
        {!error && availabilityType === AvailabilitySlotsOption && (
          <CalendarSubTitle>Please select at least three time slots</CalendarSubTitle>
        )}
        <CalendarWrapper>
          {availabilityType === AvailabilitySlotsOption && (
            <>
              <Controller
                render={({ onChange, value }) => (
                  <AvailabilityExpertCalendar
                    selectedDays={value}
                    onChange={onChange}
                    fullWeek
                    disableTodaySlots
                    useTheme={false}
                  />
                )}
                defaultValue={[]}
                name="selectedDays"
                control={control}
              />
              {selectedDays?.length > 0 && (
                <>
                  <BlockTitle top={24}>Your chosen availability</BlockTitle>
                  <SelectedSlotsContainer>
                    {selectedDays.map((item) => (
                      <SlotContainer key={item}>
                        <SlotDate>{getDateLabel(item)}</SlotDate>
                        <WeekLabel>{getWeekLabel(item)}</WeekLabel>
                        <SlotTime>{getTimeLabel(item)}</SlotTime>
                      </SlotContainer>
                    ))}
                  </SelectedSlotsContainer>
                </>
              )}
            </>
          )}
          {availabilityType === AvailabilityDaysOption && (
            <>
              <AvailabilityLabel>Days available</AvailabilityLabel>
              <AvailabilityDaysRowWrap newFlow={newApplicantAvailabilityFlow}>
                {applicantAvailabilityDays.map((item) => (
                  <Controller
                    key={item.name}
                    render={({ onChange, onBlur, value, name }) => (
                      <AvailabilityDaysCheckbox
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.checked)}
                        name={name}
                        checked={value}
                        title={item.title}
                        hideCheckBox={newApplicantAvailabilityFlow}
                      />
                    )}
                    defaultValue={false}
                    name={item.name}
                    control={control}
                  />
                ))}
              </AvailabilityDaysRowWrap>
              <AvailabilityLabel>Time available</AvailabilityLabel>
              <TimezoneLabel>{timeZoneText}</TimezoneLabel>
              <AvailabilityTimeRowWrap newFlow={newApplicantAvailabilityFlow}>
                {applicantAvailabilityTimes.map((item) => (
                  <Controller
                    key={item.name}
                    render={({ onChange, onBlur, value, name }) => (
                      <AvailabilityTimeCheckbox
                        onBlur={onBlur}
                        onChange={(e) => onChange(e.target.checked)}
                        name={name}
                        checked={value}
                        icon={item.icon}
                        title={item.info}
                        time={isMobileOnly ? item.timeMobile : item.time}
                        hideCheckBox={newApplicantAvailabilityFlow}
                      />
                    )}
                    defaultValue={false}
                    name={item.name}
                    control={control}
                  />
                ))}
              </AvailabilityTimeRowWrap>
            </>
          )}
        </CalendarWrapper>
        <SubTitle>I would like to get new time slots from:</SubTitle>
        {expertError && (
          <ErrorContainer>
            <SvgInfo />
            <ErrorText>Please select your preferred option below.</ErrorText>
          </ErrorContainer>
        )}
        <Controller
          render={({ onChange, value }) => (
            <ExpertsWrapper>
              <RadioButtonGroup
                value={value}
                direction="column"
                onChange={onChange}
                useTheme={false}
                options={expertList}
              />
            </ExpertsWrapper>
          )}
          defaultValue={null}
          name="selectedExpertId"
          control={control}
        />
        <BlockTitle>ADDITIONAL INFORMATION (OPTIONAL)</BlockTitle>
        <Controller
          render={({ onChange, value }) => (
            <DetailsText
              onChange={(e) => onChange(e.target.value)}
              placeholder={
                isMobileOnly
                  ? `Anything you'd like us to know?`
                  : `Anything you'd like us to know? (optional)`
              }
              value={value || ''}
            />
          )}
          defaultValue=""
          name="additionalText"
          control={control}
        />
        <BottomRowContainer>
          <Button type="submit" disabled={!canReschedule}>
            Done
          </Button>
        </BottomRowContainer>
      </BlockContainer>
    </Container>
  )
}

SchedulingReschedulePopup.defaultProps = {
  onClose: () => {},
  onRescheduled: () => {},
}

export default SchedulingReschedulePopup
