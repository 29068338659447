import useStateRef from 'react-usestateref'

export const LandscapeAspectRatio = 9 / 16
export const PortraitAspectRatio = 16 / 9

const useOrientation = () => {
  const [localOrientation, setLocalOrientation] = useStateRef(LandscapeAspectRatio)
  const [peerOrientation, setPeerOrientation] = useStateRef(LandscapeAspectRatio)

  return {
    localOrientation,
    peerOrientation,
    changeLocalOrienation: setLocalOrientation,
    changePeerOrientation: setPeerOrientation,
  }
}

export default useOrientation
