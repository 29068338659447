import React, { useRef } from 'react'
import styled from '@emotion/styled'

// import searchIcon from 'src/static/search.svg'
/* background: url(${searchIcon}) no-repeat right top 7px / 20px 20px; */

const StyledSearchInput = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  padding: 0 25px 0 15px;
  width: 320px;
  outline: none;
  color: rgba(115, 121, 145, 0.8);
  font-size: 12px;
  font-family: Rubik;
  margin-left: 18px;
`

interface IProps {
  onChange: (val: string) => void
}

const SearchInput = ({ onChange }: IProps) => {
  const searchRef = useRef('')

  const handleChange = (e) => {
    e.preventDefault()
    const { value } = e.target
    searchRef.current = value

    setTimeout(() => {
      if (value === searchRef.current) {
        onChange(searchRef.current.trim())
      }
    }, 2000)
  }

  return <StyledSearchInput placeholder="Search…" onChange={handleChange} />
}

export default SearchInput
