import { reduxDataFactory } from '../../../utils/redux'

export interface IAvaPrompt {
  id: string
  content?: string
  created_at: string
  duration: number
  error?: {
    code?: number
    message?: string
    param?: any
    type?: string
  }
  function_call?: {
    arguments: any
    name: string
  }
  is_invalid: boolean
  is_loading: boolean
  prompt: {
    max_tokens: number
    messages: { role: string; content: string }[]
    frequency_penalty: number
    functions?: any
    model: string
    presence_penalty: number
    temperature: number
    top_p: number
  }
  prompt_type: string
  attempts: number
  updated_at: string
  usage?: {
    prompt_tokens: number
    completion_tokens: number
    total_tokens: number
  }
}

const {
  action: updateAvaInterviewPromptsAction,
  actionType: updateAvaInterviewPromptsActionType,
  reducer: avaInterviewPromptsDataReducer,
  selectors: avaInterviewPromptsSelectors,
} = reduxDataFactory<IAvaPrompt[]>('interviewPrompts', 'ava', [])

const clearAvaInterviewPromptsAction = updateAvaInterviewPromptsAction([])

export {
  updateAvaInterviewPromptsAction,
  updateAvaInterviewPromptsActionType,
  avaInterviewPromptsDataReducer,
  avaInterviewPromptsSelectors,
  clearAvaInterviewPromptsAction,
}
