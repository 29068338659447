import styled from '@emotion/styled'
import { css } from '@emotion/core'
import colors from '../static/colors'

interface IError {
  type: string
  message: string
  ref: any
}

const themes = {
  default: {
    borderColor: colors.formActiveAccentColor,
  },
  blue: {
    borderColor: '#0095FF',
  },
}

const getColor = ({ customTheme, theme, useTheme }: any) => {
  if (theme.agencyColor && useTheme) {
    return theme.agencyColor
  }

  if (themes[customTheme]) {
    return themes[customTheme].borderColor
  }

  return '#B202FF'
}

interface IStyledInputProps {
  isSelected: boolean
  disabled: boolean
  useTheme?: boolean
  hasError: boolean
  customTheme?: string
}

interface IContainerProps {
  hasError: boolean
  disabled: boolean
  hidden?: boolean
}

const disabledStyles = {
  borderColor: '#E1E3EA',
  backgroundColor: '#F5F6F8',
  opacity: '0.6',
  color: '#21304E',
}

const hiddenStyles = {
  display: 'none',
}

export const Container = styled('div')<IContainerProps>(
  {
    position: 'relative',
    boxSizing: 'border-box',
    padding: '5px',
    height: '56px',
    width: '400px',
    border: `1px solid #E1E3EA`,
    borderRadius: '6px',
    backgroundColor: '#F5F6F8',
    marginBottom: '20px',

    '& + button': {
      '&': {
        marginTop: '20px',
      },
    },

    '&:hover': {
      backgroundColor: '#EDEEF2',
      borderColor: '#E1E3EA',
    },

    '&:hover input': {
      backgroundColor: '#EDEEF2',
    },

    '&:hover textarea': {
      backgroundColor: '#EDEEF2',
    },

    '&:hover textarea + div': {
      // backgroundColor: '#EDEEF2',
    },
    '@media screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  (props) => {
    const errorStyles =
      props.hasError &&
      css({
        borderColor: '#F23482 !important',
      })

    const containerDisabledStyles = props.disabled && css(disabledStyles)
    const containerHiddenStyles = props.hidden && css(hiddenStyles)

    return css`
      ${errorStyles};
      ${containerDisabledStyles};
      ${containerHiddenStyles};
    `
  }
)

const activeLabelStyles = {
  transform: 'translateY(-10px)',
  fontSize: '10px',
}

export const createInput = (activeLabelStylesParam: any) =>
  styled('input')<IStyledInputProps>`
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    /* border-color: ${({ customTheme, hasError, isSelected, theme, useTheme }) => {
      if (hasError) return 'transparent'
      if (isSelected) return getColor({ customTheme, theme, useTheme })

      return 'transparent'
    }}; */
    border-color: transparent;
    /* background-color: ${({ isSelected }) => {
      if (isSelected) return '#fff'

      return '#f5f6f8'
    }}; */
    background-color: #f5f6f8 !important;
    border-radius: 4px;
    padding-top: 14px;
    padding-left: 9px;
    color: #21304e;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    &:hover {
      background-color: #edeef2;
    }
    &:disabled {
      ${disabledStyles};
      border-color: transparent;
      cursor: not-allowed;
    }
    &:focus {
      background-color: ${colors.formActiveBackgroundColor} !important;
      border-color: ${({ customTheme, hasError, theme, useTheme }) => {
        if (hasError) return 'transparent'
        if (customTheme || useTheme) return getColor({ customTheme, theme, useTheme })

        return '#b202ff'
      }};
    }
    &:focus + div > label {
      ${activeLabelStylesParam}
    }
    &:focus + div > span {
      opacity: 0;
    }
    & + div > label {
      transform: ${({ isSelected }) => (isSelected ? 'translateY(-10px)' : 'none')};
      font-size: ${({ isSelected }) => (isSelected ? '10px' : '12px')};
    }
    & + div > span {
      opacity: ${({ isSelected }) => (isSelected ? 'translateY(-10px)' : 'none')};
      font-size: ${({ isSelected }) => (isSelected ? 0 : 1)};
    }
  `

export const InputStyled = createInput(activeLabelStyles)

export const LabelsContainer = styled.div`
  pointer-events: none;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -12px;
`

export const Label = styled.label`
  display: inline-block;
  line-height: 20px;
  transition: transform 150ms ease-in, font-size 150ms ease-in;
  opacity: 0.5;
  color: #21304e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  pointer-events: none;
  margin: 0;
`
export const OptionalLabel = styled.span`
  display: inline-block;
  line-height: 20px;
  opacity: 0.5;
  color: #21304e;
  font-size: 11px;
  letter-spacing: 0.5px;
  pointer-events: none;
  margin-left: 20px;
  transition: opacity 150ms ease-out;
`
export const Revealer = styled.img`
  display: inline-block;
  position: absolute;
  width: 18px;
  right: 15px;
  top: 50%;
  line-height: 20px;
  margin-top: -10px;
  opacity: 0.6;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const Error = styled.p`
  height: 24px;
  color: ${colors.formHint};
  font-size: 12px;
  letter-spacing: 0;
  margin-top: 10px;
  line-height: 120%;
  padding: 0;
`
