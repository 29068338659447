import { put, takeEvery, call } from 'typed-redux-saga'

import { IExpertNoteMood, saveExpertNote } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateExpertNotesAction } from '../data/expertNotes'

interface ISaveExpertNoteAction {
  id?: string
  interviewToken: string
  mood?: IExpertNoteMood
  text: string
}

const {
  action: saveExpertNoteAction,
  actionFailure: saveExpertNoteActionFailure,
  actionSuccess: saveExpertNoteActionSuccess,
  actionSuccessClear: saveExpertNoteActionSuccessClear,
  actionFailureClear: saveExpertNoteActionFailureClear,
  actionType: saveExpertNoteActionType,
  actionTypeFailure: saveExpertNoteActionFailureType,
  actionTypeSuccess: saveExpertNoteActionSuccessType,
  reducer: saveExpertNoteRequestReducer,
  selectors: saveExpertNoteSelectors,
} = reduxRequestFactory<ISaveExpertNoteAction>('saveExpertNote', 'requests')

export {
  saveExpertNoteAction,
  saveExpertNoteActionFailure,
  saveExpertNoteActionSuccess,
  saveExpertNoteActionSuccessClear,
  saveExpertNoteActionFailureClear,
  saveExpertNoteActionType,
  saveExpertNoteActionFailureType,
  saveExpertNoteActionSuccessType,
  saveExpertNoteRequestReducer,
  saveExpertNoteSelectors,
}

function* saveExpertNoteSaga(action: ISagaAction<ISaveExpertNoteAction>) {
  try {
    const { interviewToken, ...expertNote } = action.payload
    const notes = yield* call(saveExpertNote, interviewToken, expertNote)
    yield put(saveExpertNoteActionSuccess())
    yield put(updateExpertNotesAction(notes))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      saveExpertNoteActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchSaveExpertNote() {
  yield takeEvery(saveExpertNoteActionType, saveExpertNoteSaga)
}
