import styled from '@emotion/styled'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getPrivacyLink, getTermsOfuseLink } from 'src/utils/links'
import {
  IAgencyPriceTier,
  IInterviewPriceTier,
  IUpdateInterviewPayload,
  ICreateInterviewPayload,
  NestedAttibutes,
  BusinessModel,
} from '../../api'
import {
  Input,
  Button,
  Notification,
  TextBold,
  Checkbox,
  Text,
  HyperLink,
} from '../../components'
import { BookingStatus, updateBookingStatusAction } from '../../redux'
import eveningIcon from '../../static/evening.png'
import morningIcon from '../../static/morning.png'
import noonIcon from '../../static/noon.png'
import { getShortLocalTime } from '../../utils/date'
import { EmailRegex } from '../../utils/regex'
import {
  ApplicantAvailabilityDaysNew,
  ApplicantAvailabilityTimesNew,
} from '../../utils/staticData'
import useLogrocket from '../../utils/useLogrocket'

import { createBooking, updateBooking, createBookingFailureClear } from '../actions'
import * as interviewSelectors from '../selectors'
import AvailabilityTimeCheckbox from '../components/AvailabilityTimeCheckbox'
import AvailabilityDaysCheckbox from '../components/AvailabilityDaysCheckbox'
import FileUploadView from '../components/FileUploadView'
import SessionPrice from '../components/SessionPrice'
import Layout from '../Layout'

const Form = styled.form`
  margin-top: 20px;
`

const InputsRow = styled.div<{ alignLeft?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.alignLeft ? 'start' : 'space-between')};

  & > div {
    width: 48%;
  }
`

const AvailabilityDaysRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & > div {
    width: 48%;
    height: 56px;
  }
`

const AvailabilityDaysRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;

  & > div {
    width: 75px;
    height: 66px;
    margin: 0 12px 12px 0;
  }

  @media screen and (max-width: 600px) {
    & > div {
      width: 59px;
      height: 50px;
      margin: 0 12px 12px 0;
    }
  }
`

const AvailabilityLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #21304e;
  margin-bottom: 8px;
  text-transform: uppercase;
`

const TimezoneLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  color: black;
  margin-bottom: 8px;
`

const AvailabilityTimeRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 31%;
  }
`

const AvailabilityTimeRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > div {
    width: 102px;
    height: 78px;
    margin: 0 12px 12px 0;
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;

    & > div {
      height: 78px;
      flex: 1;
    }
  }
`

const ButtonStyled = styled(Button)`
  && {
    margin-top: 40px;
  }
`

const TextBoldStyled = styled(TextBold)`
  margin-top: 10px;
  margin-bottom: 15px;
`

const GrayText = styled.p`
  color: rgba(115, 121, 145, 0.8);
  font-family: Rubik;
  font-size: 12px;
  line-height: ${isMobileOnly ? 18 : 24}px;
  margin-left: 8px;
  margin-top: 10px;
`

const FormGroup = styled.div`
  margin-bottom: 30px;
`

const HyperLinkStyled = styled(HyperLink)`
  font-weight: normal;
`

const linkedinLinkRegex = /((https:\/\/)?(www\.)?linkedin\.com(\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?)/

function StudentDetails() {
  const { setLogrocket } = useLogrocket()
  const { interviewToken } = useParams()
  const dispatch = useDispatch()
  const profile = useSelector(interviewSelectors.profile)
  const agency = useSelector(interviewSelectors.agency)

  const position = useSelector(interviewSelectors.createBooking.position)
  const isLoading = useSelector(interviewSelectors.createBooking.isLoading)
  const success = useSelector(interviewSelectors.createBooking.success)
  const apiError = useSelector(interviewSelectors.createBooking.error)

  const { handleSubmit, errors, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: profile.first_name,
      lastName: profile.last_name,
      email: profile.email,
      linkedin: '',
      terms: true,
      carrerCenter: true,
      availabilityDays: [],
      availabilityTime: [],
    },
  })

  const onSubmit = (payload) => {
    const { availabilityDays, availabilityTime } = payload
    const interviewTimezone = moment.tz.guess()

    const selectedAgencyPriceTiers: IAgencyPriceTier[] = position.priceTears

    const callPayload: ICreateInterviewPayload | IUpdateInterviewPayload = {
      position,
      student: {
        ...payload,
        availabilityDays,
        availabilityTime,
        timezone: interviewTimezone,
      },
      agree_terms_and_privacy: payload.terms,
      agree_to_share: payload.carrerCenter,
      agree_to_receive_offers: payload.marketing,
      cv_file_id: payload.cv_file_id,
      interview_price_tiers_attributes: selectedAgencyPriceTiers
        ? selectedAgencyPriceTiers.reduce<NestedAttibutes<IInterviewPriceTier>>(
            (acc, t, i) => {
              acc[i + 1] = { agency_price_tier_id: t.id }
              return acc
            },
            {}
          )
        : null,
    }

    if (interviewToken) {
      dispatch(updateBooking({ interviewToken, ...callPayload }))
    } else {
      dispatch(createBooking({ agencyId: agency.id, ...callPayload }))
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (success) dispatch(updateBookingStatusAction(BookingStatus.Success))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const clearError = () => {
    dispatch(createBookingFailureClear())
  }

  const {
    availabilityDays,
    firstName,
    lastName,
    email,
    terms,
    availabilityTime,
  } = watch()
  const { interview, token } = useSelector(interviewSelectors.interview)
  const fieldsWereFilled = interview.desired_position || interview.position_company
  const requiredFieldsFilled = !fieldsWereFilled
    ? firstName && lastName && email && terms
    : true
  const weekOptionChecked =
    availabilityDays && Object.values(availabilityDays).find((v) => !!v)
  const timeOptionChecked =
    availabilityTime && Object.values(availabilityTime).find((v) => !!v)
  const disabled = !(requiredFieldsFilled && weekOptionChecked && timeOptionChecked)
  const hasFormErrors = Object.keys(errors).length > 0
  const hasEmailDomainValidation = useSelector(
    interviewSelectors.hasEmailDomainValidation
  )

  const emailDomainValidations = get(agency, 'email_domain_validations', [])
  const agencyName = get(agency, 'name', '')
  const tokenCode = get(token, 'code', '')

  useEffect(() => {
    if (interview) {
      setLogrocket(
        `${interview?.order_number.toString()}`,
        'Applicant order form - second page'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  const newApplicantAvailabilityFlow = get(
    interview,
    'use_new_applicant_availability_flow',
    agency.use_new_applicant_availability_flow
  )

  const timeZoneText = `${moment.tz.guess()} (${getShortLocalTime(new Date())})`

  return (
    <Layout
      title="Tell us a little about yourself"
      subtitle="This information will help us find the best possible match"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          {apiError && <Notification onClose={clearError} message={apiError} />}
          <TextBoldStyled>YOUR DETAILS</TextBoldStyled>
          {!fieldsWereFilled && (
            <>
              <InputsRow>
                <Controller
                  as={Input}
                  label="First Name"
                  name="firstName"
                  control={control}
                  error={errors.firstName}
                  disableWhitespaces
                  useTheme
                  rules={{ required: !fieldsWereFilled }}
                  defaultValue={profile.first_name}
                />
                <Controller
                  as={Input}
                  label="Last Name"
                  disableWhitespaces
                  name="lastName"
                  control={control}
                  useTheme
                  error={errors.lastName}
                  rules={{ required: !fieldsWereFilled }}
                />
              </InputsRow>
              <Controller
                as={Input}
                label={
                  hasEmailDomainValidation && !tokenCode
                    ? `Email (your ${agencyName} email)`
                    : 'Email'
                }
                disableWhitespaces
                useTheme
                extended={hasEmailDomainValidation && !tokenCode}
                name="email"
                placeholder={
                  hasEmailDomainValidation && !tokenCode
                    ? `elon.musk${emailDomainValidations[0]}`
                    : ''
                }
                control={control}
                error={errors.email}
                rules={{
                  validate: {
                    valid: (value) => {
                      const validFormat = EmailRegex.test(value)
                      const validEmail =
                        hasEmailDomainValidation &&
                        !tokenCode &&
                        emailDomainValidations.find((e) => value.indexOf(e) !== -1)

                      if (!validFormat) {
                        return "That format doesn't look right. Make sure there aren't any typos"
                      }
                      if (hasEmailDomainValidation && !tokenCode && !validEmail) {
                        return `Please use your ${agencyName} email`
                      }

                      return true
                    },
                  },
                }}
              />
            </>
          )}
          <InputsRow alignLeft>
            <TextBoldStyled>YOUR PROFESSIONAL BACKGROUND</TextBoldStyled>
            <GrayText>Optional</GrayText>
          </InputsRow>
          <Controller
            as={Input}
            label="LinkedIn profile"
            name="linkedin"
            disableWhitespaces
            control={control}
            useTheme
            rules={{
              validate: {
                valid: (value) =>
                  value
                    ? linkedinLinkRegex.test(value.toString().toLowerCase()) ||
                      "That format doesn't look right."
                    : true,
              },
            }}
            error={errors.linkedin}
            defaultValue=""
          />
          <Controller
            render={({ onChange }) => (
              <FileUploadView onUploaded={(fileID) => onChange(fileID)} />
            )}
            defaultValue={false}
            name="cv_file_id"
            control={control}
          />
        </FormGroup>
        <TextBoldStyled>YOUR AVAILABILITY</TextBoldStyled>
        {newApplicantAvailabilityFlow && (
          <>
            <AvailabilityLabel>Days available</AvailabilityLabel>
            <AvailabilityDaysRowWrap>
              {ApplicantAvailabilityDaysNew.map((item) => (
                <Controller
                  key={item.name}
                  render={({ onChange, onBlur, value, name }) => (
                    <AvailabilityDaysCheckbox
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.checked)}
                      name={name}
                      useTheme
                      checked={value}
                      title={item.title}
                      hideCheckBox
                    />
                  )}
                  defaultValue={false}
                  name={item.name}
                  control={control}
                />
              ))}
            </AvailabilityDaysRowWrap>
            <AvailabilityLabel>Time available</AvailabilityLabel>
            <TimezoneLabel>{timeZoneText}</TimezoneLabel>
            <AvailabilityTimeRowWrap>
              {ApplicantAvailabilityTimesNew.map((item) => (
                <Controller
                  key={item.name}
                  render={({ onChange, onBlur, value, name }) => (
                    <AvailabilityTimeCheckbox
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.checked)}
                      name={name}
                      useTheme
                      checked={value}
                      icon={item.icon}
                      time={isMobileOnly ? item.timeMobile : item.time}
                      hideCheckBox
                    />
                  )}
                  defaultValue={false}
                  name={item.name}
                  control={control}
                />
              ))}
            </AvailabilityTimeRowWrap>
          </>
        )}
        {!newApplicantAvailabilityFlow && (
          <>
            <AvailabilityDaysRow>
              <Controller
                render={({ onChange, onBlur, value, name }) => (
                  <AvailabilityDaysCheckbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    useTheme
                    checked={value}
                    title="Weekdays"
                  />
                )}
                defaultValue={false}
                name="availabilityDays[weekday]"
                control={control}
              />
              <Controller
                render={({ onChange, onBlur, value, name }) => (
                  <AvailabilityDaysCheckbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    useTheme
                    name={name}
                    checked={value}
                    title="Weekend"
                  />
                )}
                defaultValue={false}
                name="availabilityDays[weekend]"
                control={control}
              />
            </AvailabilityDaysRow>
            <AvailabilityTimeRow>
              <Controller
                render={({ onChange, onBlur, value, name }) => (
                  <AvailabilityTimeCheckbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    useTheme
                    checked={value}
                    icon={morningIcon}
                    time="8am-12pm"
                    title="Morning"
                  />
                )}
                defaultValue={false}
                name="availabilityTime[8-12]"
                control={control}
              />
              <Controller
                render={({ onChange, onBlur, value, name }) => (
                  <AvailabilityTimeCheckbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    checked={value}
                    useTheme
                    icon={noonIcon}
                    time="12pm-6pm"
                    title="Afternoon"
                  />
                )}
                defaultValue={false}
                name="availabilityTime[12-18]"
                control={control}
              />
              <Controller
                render={({ onChange, onBlur, value, name }) => (
                  <AvailabilityTimeCheckbox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    checked={value}
                    useTheme
                    icon={eveningIcon}
                    time="6pm-10pm"
                    title="Evening"
                  />
                )}
                defaultValue={false}
                name="availabilityTime[18-22]"
                control={control}
              />
            </AvailabilityTimeRow>
          </>
        )}
        {agency.business_model === BusinessModel.B2B2C && <SessionPrice />}

        <ButtonStyled
          useTheme
          type="submit"
          disabled={isLoading || disabled || hasFormErrors}
          title="Done"
        />
        <Controller
          as={Checkbox}
          name="terms"
          control={control}
          rules={{
            required: true,
          }}
          useTheme
          defaultChecked
          defaultValue
        >
          <Text>
            I agree to the Wizco{' '}
            <HyperLinkStyled target="_blank" href={getTermsOfuseLink()}>
              Terms of Use
            </HyperLinkStyled>{' '}
            and{' '}
            <HyperLinkStyled target="_blank" href={getPrivacyLink()}>
              Privacy Policy
            </HyperLinkStyled>
          </Text>
        </Controller>
        <Controller
          as={Checkbox}
          useTheme
          name="carrerCenter"
          label={`I agree to share my report with ${agency.name}`}
          control={control}
          defaultChecked
          defaultValue
        />
        <Controller
          as={Checkbox}
          useTheme
          name="marketing"
          label={`I agree to receive marketing and commercial offers from Wizco${
            agency.name === 'CareerBuilder' ? ' and CareerBuilder' : ''
          }`}
          control={control}
          defaultChecked
          defaultValue
        />
      </Form>
    </Layout>
  )
}

export default StudentDetails
