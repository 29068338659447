/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { IAvaQuestion } from 'src/api/models'
import { avaInterviewSelectors } from 'src/redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useComunicationInsights from 'src/ava/services/useCommunicationInsights'
import SvgFollowUp from 'src/static/components/SvgFollowUp'
import QuestionShortText from './QuestionShortText'
import QuestionDetails from './QuestionsDetails'

const Container = styled.div`
  width: 100%;
  height: 640px;
  border: 1px solid #e1e3ea;
  border-radius: 20px;
  display: flex;

  @media screen and (max-width: 920px) {
    flex-direction: column;
    height: auto;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 36px;
  `}
`

const QuestionListContainer = styled.div`
  border-right: 1px solid #e1e3ea;
  padding: 32px;
  width: 424px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media screen and (max-width: 1440px) {
    width: 376px;
  }

  @media screen and (max-width: 920px) {
    width: 100%;
    border-right: none;
  }
`

const QuestionContainer = styled.div<{ isFollow?: boolean; selected?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ selected, theme }: any) => {
      const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return selected ? agencyColor : '#E1E3EA'
    }};
  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 12px 0px rgba(0, 0, 0, 0.16)' : 'none'};
  margin-top: 16px;
  align-items: start;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0px;
  }

  @media screen and (max-width: 920px) {
    min-height: 95px;
  }

  ${({ isFollow }) =>
    isFollow &&
    `
    margin-left: 24px;

    &::before {
      border-bottom-left-radius: 5px;
      content: '';
      display: inline-block;
      height: 70px;
      width: 15px;
      position: absolute;
      top: -17px;
      left: -16px;
      border-left: 1px solid #e1e3ea;
      border-bottom: 1px solid #e1e3ea;
    }
  `}
`

const QuestionIndexLabel = styled.div`
  color: #a9a9a9;
  /* text-align: center; */
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 8px;
  position: relative;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
`

const QuestionHeader = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SkippedTag = styled.div`
  background: #b202ff26;
  border-radius: 5px;
  color: #b202ff;
  padding: 2px 5px;
  text-transform: none;
`

const CommunicationInsightsComponent = () => {
  const { getInterviewQuestionIndexText } = useComunicationInsights()
  const interview = useSelector(avaInterviewSelectors.data)
  const questions = interview?.questions.map((q) => [q, ...(q.follow_up || [])]).flat()

  const isSmallScreen = useMediaQuery({ query: '(max-width: 920px)' })

  const [selectedQuestion, setSelectedQuestion] = useState<IAvaQuestion>(null)

  useEffect(() => {
    if (!selectedQuestion && !!questions?.length && !isSmallScreen)
      setSelectedQuestion(questions[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, isSmallScreen])

  const handleQuestionClick = (question) => {
    if (isSmallScreen && selectedQuestion?.id === question.id) {
      setSelectedQuestion(undefined)
    } else setSelectedQuestion(question)
  }

  return (
    <Container>
      <QuestionListContainer>
        {questions.map((question) => (
          <QuestionContainer
            key={question.id}
            // isFollow={!!question.parent_id}
            selected={question.id === selectedQuestion?.id}
            onClick={() => handleQuestionClick(question)}
          >
            <QuestionIndexLabel>
              {!!question.parent_id && <SvgFollowUp fill="#A9A9A9" />}
              <QuestionHeader>
                {getInterviewQuestionIndexText(question, questions)}
              </QuestionHeader>
              {!question.answer?.text && <SkippedTag>Skipped</SkippedTag>}
            </QuestionIndexLabel>
            <QuestionShortText
              text={question.text}
              selected={question.id === selectedQuestion?.id}
            />
            {isSmallScreen && (
              <QuestionDetails
                usePadding={false}
                question={question}
                visible={selectedQuestion?.id === question.id}
              />
            )}
          </QuestionContainer>
        ))}
      </QuestionListContainer>
      {!isSmallScreen && (
        <QuestionDetails visible usePadding question={selectedQuestion} />
      )}
    </Container>
  )
}

export default CommunicationInsightsComponent
