import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { avaInterviewSelectors } from '../../../redux'
import { SpeechTexts } from '../../speechService'
import ChoiseComponent from './ChoiseComponent'
import { YesNo } from '../../../api'

const Items = [YesNo.YES, YesNo.NO]

interface IProps {
  backMode: boolean
  goBack: () => void
  goNext: (res: boolean) => void
}

const PracticeForCompanyComponent = ({ backMode, goNext, goBack }: IProps) => {
  const interview = useSelector(avaInterviewSelectors.data)

  const [selectedItem, setSelectedItem] = useState<string>()
  const [showError, setShowError] = useState(false)

  const onNext = () => {
    if (!selectedItem) {
      setShowError(true)
    } else {
      const isSpecificCompany = selectedItem === YesNo.YES
      goNext(isSpecificCompany)
    }
  }

  useEffect(() => {
    if (!interview.position || interview.position.is_specific_company === null) return
    setSelectedItem(interview.position.is_specific_company ? YesNo.YES : YesNo.NO)
  }, [interview])

  useEffect(() => {
    if (selectedItem) {
      setShowError(false)
    }
  }, [selectedItem])

  return (
    <ChoiseComponent
      backMode={backMode}
      title={SpeechTexts.PracticeForCompany}
      subTitle={SpeechTexts.WillHelpPersonolize}
      errorText="Please select your answer "
      showError={showError}
      items={Items}
      fixedItemsSize
      inverseTitles
      onSelect={setSelectedItem}
      selectedItem={selectedItem}
      goBack={goBack}
      goNext={onNext}
    />
  )
}

export default PracticeForCompanyComponent
