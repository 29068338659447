import httpClient from '../../httpClient'

// agency_ava_interviews#create - POST /agency/:agency_id/ava_interviews
export const createAgencyAvaInterview = ({
  id,
  data: { firstName, lastName, email },
}) => {
  return httpClient
    .post(`/agencies/${id}/ava_interviews`, {
      candidate: {
        first_name: firstName,
        last_name: lastName,
        email,
      },
    })
    .then((response) => response.data)
}

// agency_ava_interviews#show - GET /agency/:agency_id/ava_interviews/:ava_interview_id

// agency_ava_interviews#bulk_create - POST /agency/:agency_id/ava_interviews/bulk_create
export const bulkCreateAvaInterview = (id, bulk_interview_file_id, data) => {
  return httpClient
    .post(`/agencies/${id}/ava_interviews/bulk_create`, { bulk_interview_file_id, data })
    .then((response) => response.data)
}
