import styled from '@emotion/styled'
import React from 'react'
import DonutChart from 'react-svg-donut-chart'
import { getProgressColor } from 'src/ava/services/reportHelper'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 160px;
  width: 160px;

  @media screen and (max-width: 1280px) {
    height: 120px;
    width: 120px;
  }
`

const ValueContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #3b424f;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  display: flex;
  align-items: center;
`

const ValueSmallText = styled.span`
  color: #353c5a;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.5;
`

interface IProps {
  value: number
  maxValue: number
}

export default function CircleProgress({ maxValue, value }: IProps) {
  const step = 100 / maxValue
  const color = getProgressColor(value / maxValue)

  return (
    <Container>
      <DonutChart
        data={[
          { value: step * value, stroke: color, strokeWidth: 3 },
          { value: 100 - step * value, stroke: '#E1E3EA', strokeWidth: 3 },
        ]}
        style={{
          transform: 'rotate(90deg) scale(-1,1)',
          flex: '1',
        }}
      />
      <ValueContainer>
        {value}
        <ValueSmallText>/{maxValue}</ValueSmallText>
      </ValueContainer>
    </Container>
  )
}
