import React from 'react'
import styled from '@emotion/styled'
import withProps from 'recompose/withProps'
import { Text } from './Typography'

interface IContainerProps {
  disabled?: boolean
}

interface ICheckboxProps {
  isSelected: boolean
  disabled: boolean
  hasError: boolean
}

interface ILabelProps {
  htmlFor: string
}

const Container = styled('div')<IContainerProps>`
  margin-bottom: 8px;
`

const CheckboxStyled = withProps({ type: 'checkbox' })(
  styled('input')<ICheckboxProps>`
    position: absolute;
    opacity: 0;

    &:disabled + label {
      opacity: 0.6;
      border-color: transparent;
      cursor: not-allowed;
    }

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    & + label:before {
      content: '';
      margin-right: 11px;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 1px solid rgba(143, 155, 179, 0.49);
      border-radius: 3px;
      background-color: #ffffff;
      flex-shrink: 0;
    }

    &:checked + label:before {
      background-color: #0095ff;
      background-color: ${({ theme, useTheme }: any) => {
        const checkedColor = theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
        return checkedColor
      }};
      flex-shrink: 0;
      margin-top: 3px;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 11px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
        4px -6px 0 white, 4px -6px 0 white;
      transform: rotate(45deg);
    }
  `
)

const Label = styled('label')<ILabelProps>`
  display: inline-block;
  line-height: 20px;
  color: #3b424f;
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0;
  display: flex;
  align-items: start;
`

const Checkbox = React.forwardRef((props: any, ref: any) => {
  const {
    name,
    onChange,
    onBlur,
    disabled,
    label,
    children,
    defaultChecked,
    useTheme,
  } = props

  return (
    <Container>
      <CheckboxStyled
        useTheme={useTheme}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onBlur={onBlur}
        name={name}
        ref={ref}
        onChange={(e) => onChange(e.target.checked)}
        id={name}
      />
      <Label htmlFor={name}>{children || <Text>{label}</Text>}</Label>
    </Container>
  )
})

export default Checkbox
