import { put, takeEvery, call } from 'typed-redux-saga'

import { getAgency } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { defaultAgency, updateAgencyAction } from '../data/agency'

export interface IGetAgencyAction {
  agencyId: string
}

const {
  action: getAgencyAction,
  actionFailure: getAgencyActionFailure,
  actionSuccess: getAgencyActionSuccess,
  actionSuccessClear: getAgencyActionSuccessClear,
  actionFailureClear: getAgencyActionFailureClear,
  actionType: getAgencyActionType,
  actionTypeFailure: getAgencyActionFailureType,
  actionTypeSuccess: getAgencyActionSuccessType,
  reducer: getAgencyRequestReducer,
  selectors: getAgencySelectors,
} = reduxRequestFactory<IGetAgencyAction>('getAgency', 'requests')

export {
  getAgencyAction,
  getAgencyActionFailure,
  getAgencyActionSuccess,
  getAgencyActionSuccessClear,
  getAgencyActionFailureClear,
  getAgencyActionType,
  getAgencyActionFailureType,
  getAgencyActionSuccessType,
  getAgencyRequestReducer,
  getAgencySelectors,
}

function* getAgencySaga(action: ISagaAction<IGetAgencyAction>) {
  try {
    const { agencyId } = action.payload

    if (agencyId) {
      const agency = yield* call(getAgency, agencyId)
      yield put(getAgencyActionSuccess())
      yield put(updateAgencyAction(agency))
    } else {
      yield put(updateAgencyAction(defaultAgency))
    }
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getAgencyActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetAgency() {
  yield takeEvery(getAgencyActionType, getAgencySaga)
}
