import React from 'react'

function SvgEndCall() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8911 10.6295C22.1137 10.6683 23.8841 12.5015 23.8453 14.7241L23.8141 16.5127C23.7925 17.7475 22.774 18.731 21.5393 18.7095L19.3084 18.6705C18.2604 18.6522 17.3658 17.9086 17.1564 16.8817L16.9616 15.9268C16.8568 15.4134 16.4095 15.0416 15.8856 15.0324L12.115 14.9666C11.5913 14.9575 11.1314 15.3132 11.0086 15.8224L10.78 16.7704C10.5349 17.7893 9.61485 18.5013 8.56694 18.483L6.33603 18.4441C5.10125 18.4225 4.11773 17.4041 4.13928 16.1693L4.1705 14.3807C4.2093 12.1581 6.04253 10.3877 8.26514 10.4265L19.8911 10.6295Z"
        fill="#F5F6F8"
      />
    </svg>
  )
}

export default SvgEndCall
