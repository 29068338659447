import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'
import { useDispatch, useSelector } from 'react-redux'
import LandingFooter from 'src/components/LandingFooter'
import * as interviewSelectors from 'src/interview/selectors'
import { subscribeAction } from '../../../redux'
import { ButtonBase } from '../elements/styled-components'

const Layout = styled.div`
  margin: 0 -60px;
  background: #0e0224;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    margin: 0 -40px;
  }

  @media screen and (max-width: 899px) {
    margin: 0 -24px;
  }

  ${isMobileOnly &&
  css`
    width: auto;
    margin: 0 -16px !important;
  `}
`

const TopContainer = styled.div`
  background: #f5f5f7;
  border-radius: 0px 0px 38px 38px;
  padding: 0 80px 72px 80px;

  @media screen and (max-width: 1439px) {
    padding: 0 40px 72px 40px;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 40px 56px 40px;
  }

  @media screen and (max-width: 1144px) {
    padding: 0 40px 56px 40px;
  }

  @media screen and (max-width: 899px) {
    padding: 0 24px 32px 24px;
  }

  ${isMobileOnly &&
  css`
    padding: 0 16px 24px 16px !important;
    margin: 0;
    border-radius: 0;
  `}
`

const TopPanel = withProps()(styled(ButtonBase)`
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 32px;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 899px) {
    padding: 32px 80px;
    height: auto;
  }

  ${isMobileOnly &&
  css`
    padding: 24px 21px;
    justify-content: space-between;
    height: auto;
  `}
`)

const TopTitle = styled.div`
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  color: #fff;
  margin-bottom: 24px;
  text-align: center;

  @media screen and (max-width: 899px) {
    font-size: 40px;
    line-height: 48px;
  }

  ${isMobileOnly &&
  css`
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 160% !important;
    text-align: center;
    margin-bottom: 30px !important;
  `}
`

const LinkStyled = styled.div`
  font-weight: 500;
  border: 1px solid #fff;
  padding: 16px 24px;
  border-radius: 100px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #fff;

  @media screen and (max-width: 899px) {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 24px;
  }
`

interface IProps {
  onStart: () => void
}

const LandingFooterBlock = ({ onStart }: IProps) => {
  const dispatch = useDispatch()
  const agency = useSelector(interviewSelectors.agency)

  const handleBookDemo = (email: string) => {
    dispatch(subscribeAction({ email, source: 'home_ava' }))
  }

  return (
    <Layout>
      <TopContainer>
        <TopPanel onClick={onStart}>
          <TopTitle>Ready to land your dream job?</TopTitle>
          <LinkStyled>Start Practicing</LinkStyled>
        </TopPanel>
      </TopContainer>
      {(agency.display_topbar_footer ?? false) && (
        <LandingFooter onClick={handleBookDemo} />
      )}
    </Layout>
  )
}

export default LandingFooterBlock
