// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import styled from '@emotion/styled'
import { CountryDropdown } from 'react-country-region-selector'
import { useSelector } from 'react-redux'
import { DatePicker, Input, Text, Textarea, TextBold } from '../../components'
import { LabelsContainer, OptionalLabel } from '../../components/input-styles'
import chevron from '../../static/chevron_gray.svg'
import colors from '../../static/colors'
import * as interviewSelectors from '../selectors'

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const SearchPeriodContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -11px 0 20px;
`

export const SearchPeriodIconContainer = styled.div`
  width: 24px;
  height: 21px;
  flex-shrink: 0;
  margin-right: 15px;
`

export const SearchPeriodIcon = styled.svg`
  width: auto;
  height: 100%;
  transform: rotate(180deg);
  vertical-align: unset;
`

export const SearchPeriodText = styled(Text)`
  line-height: 19px;
`
const CountryDropdownContainerStyled = styled.div`
  position: relative;
  margin-bottom: 21px;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 6px;
  background-color: #f5f6f8;
  border: 1px solid #e1e3ea;
`

const CountryDropdownLabelsContainer = styled(LabelsContainer)`
  left: 140px;
  top: 48%;
`

interface ICountryDropdownProps {
  value: string
  useTheme: boolean
}

const CountryDropdownStyled = styled(CountryDropdown)<ICountryDropdownProps>`
  box-sizing: border-box;
  background: url(${chevron}) no-repeat center right 15px;
  padding: 5px 20px 5px 14px;
  background-size: 11px;
  border-style: solid;
  border-width: 1px;
  /* border-color: ${({ value, theme, useTheme }: any) => {
    const borderColor = theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
    return value ? borderColor : 'transparent'
  }}; */
  border-color: transparent;
  /* background-color: ${({ value }) => {
    return value ? colors.formActiveBackgroundColor : '#f5f6f8'
  }}; */
  background-color: transparent;
  color: ${({ value }) => {
    return value ? '#21304e' : 'rgba(33, 48, 78, 0.5)'
  }};
  height: 44px;
  width: 100%;
  border-radius: 6px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
`

const linkRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/

interface ICompanyDetailsProps {
  control: any
  errors: any
  fieldsWereFilled: boolean
  date?: string | Date
  showLinkToPosition: boolean
  showInterviewDate: boolean
  inSearchPeriod: any
}

export default function CompanyDetails({
  control,
  errors,
  fieldsWereFilled,
  date,
  showLinkToPosition,
  showInterviewDate,
  inSearchPeriod,
}: ICompanyDetailsProps) {
  const haveCompany = useWatch({
    control,
    name: 'haveCompany', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: '', // default value before the render
  })

  const isCorrectDate = !!date && !Number.isNaN(Number(date[0]))
  const agencyMainColor = useSelector(interviewSelectors.agencyMainColor)

  if (haveCompany === 'yes' || fieldsWereFilled) {
    return (
      <>
        <TitleWrapper>
          <TextBold>
            YOUR UPCOMING INTERVIEW{' '}
            {fieldsWereFilled && <OptionalLabel>Optional</OptionalLabel>}
          </TextBold>
        </TitleWrapper>
        {!fieldsWereFilled && (
          <Controller
            as={Input}
            label="Company"
            name="company"
            useTheme
            control={control}
            error={errors.company}
            defaultValue=""
          />
        )}

        {showInterviewDate && !isCorrectDate && (
          <Controller
            render={({ onChange, value }) => (
              <DatePicker
                label="Job Interview Date"
                onChange={onChange}
                value={value}
                useTheme
                shouldCloseOnSelect
                optional={!fieldsWereFilled}
              />
            )}
            name="date"
            optional
            control={control}
            defaultValue=""
          />
        )}

        {showInterviewDate && inSearchPeriod && (
          <SearchPeriodContainer>
            <SearchPeriodIconContainer>
              <SearchPeriodIcon
                version="1.1"
                fill={agencyMainColor || '#B202FF'}
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                // style="enable-background:new 0 0 512 512;"
              >
                <g>
                  <g>
                    <path
                      d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
                    C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
                    c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877
                    C276,137.831,267.046,128.877,256,128.877z"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <circle cx="256" cy="349.16" r="27" />
                  </g>
                </g>
              </SearchPeriodIcon>
            </SearchPeriodIconContainer>
            <SearchPeriodText>
              Finding an expert at such short notice will be challenging, but we promise
              to do our best
            </SearchPeriodText>
          </SearchPeriodContainer>
        )}

        <Controller
          render={({ onChange, value }) => {
            return (
              <CountryDropdownContainerStyled>
                <CountryDropdownLabelsContainer>
                  {!fieldsWereFilled && !value && <OptionalLabel>Optional</OptionalLabel>}
                </CountryDropdownLabelsContainer>
                <CountryDropdownStyled
                  useTheme
                  defaultOptionLabel="Position location"
                  value={value}
                  onChange={onChange}
                />
              </CountryDropdownContainerStyled>
            )
          }}
          optional={!fieldsWereFilled}
          name="location"
          useTheme
          control={control}
          defaultValue=""
        />

        {showLinkToPosition && (
          <Controller
            as={Input}
            label="Link to position"
            name="link"
            control={control}
            disableWhitespaces
            useTheme
            optional={!fieldsWereFilled}
            error={errors.link}
            rules={{
              validate: {
                valid: (value) =>
                  value
                    ? linkRegex.test(value) ||
                      "That format doesn't look right. Make sure there aren't any typos"
                    : true,
              },
            }}
            defaultValue=""
          />
        )}

        <Controller
          as={Textarea}
          label="Position description"
          name="description"
          control={control}
          useTheme
          optional={!fieldsWereFilled}
          error={errors.description}
          defaultValue=""
          placeholder="Roles and responsibilities, qualifications, etc."
        />
      </>
    ) // only re-render at the component level, when firstName changes
  }
  return null
}

CompanyDetails.defaultProps = {
  date: '',
}
