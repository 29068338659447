/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useState } from 'react'
import AppearBlock from 'src/interview/components/AppearBlock'
import { NumbersItem, NumbersItemRow } from 'src/interview/components/landing-styles'
import DynamicNumber from 'src/interview/components/DynamicNumber'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { LandingInfo, LandingTitle } from './landing-styled-components'

const Layout = styled.div`
  padding: 168px 0 136px 0;
  background: #f5f5f7;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1439px) {
    padding: 88px 72px 80px 72px;
  }

  @media screen and (max-width: 899px) {
    padding: 82px 0 109px 0;
  }

  ${isMobileOnly &&
  css`
    padding: 48px 16px !important;
  `}
`

const LineBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-bottom: 48px;
  }
`

const NumbersItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 899px) {
    flex-direction: column;
    align-items: center;
  }
`

const NumbersItemStyled = styled(NumbersItem)`
  align-items: center;
  width: 400px;

  &:first-child {
    width: 323px;
    margin-right: 104px;
  }

  @media screen and (max-width: 1439px) {
    &:first-child {
      margin-right: 32px;
    }
  }

  @media screen and (max-width: 899px) {
    &:first-child {
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
`

const NumbersItemTextStyled = styled.div`
  color: #21304e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

  @media screen and (max-width: 899px) {
    font-size: 20px;
    line-height: 28px;
  }

  ${isMobileOnly &&
  css`
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    max-width: 250px !important;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  @media screen and (max-width: 1439px) {
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 8px !important;
  `}
`

const LandingInfoStyled = styled(LandingInfo)`
  max-width: 675px;
`

const NumbersItemRowStyled = styled(NumbersItemRow)`
  font-size: 80px;

  @media screen and (max-width: 899px) {
    font-size: 56px;
    line-height: 64px;
  }

  ${isMobileOnly &&
  css`
    font-size: 40px !important;
    line-height: 48px !important;
  `}
`

const LandingNumbers = () => {
  const [showNumbers, setShowNumbers] = useState(false)
  return (
    <Layout>
      <LineBlock>
        <LandingTitleStyled>Numbers speak louder than words</LandingTitleStyled>
        <LandingInfoStyled>
          Our innovative solution stands out for its practicality, personalization,
          data-driven insights, and proven effectiveness.
        </LandingInfoStyled>
      </LineBlock>
      <LineBlock>
        <AppearBlock onAppear={setShowNumbers}>
          <NumbersItemsContainer>
            <NumbersItemStyled>
              <NumbersItemRowStyled color="#B202FF">
                <DynamicNumber value={87} show={showNumbers} round />%
              </NumbersItemRowStyled>
              <NumbersItemTextStyled>
                of job-seekers employed within 90 days of use
              </NumbersItemTextStyled>
            </NumbersItemStyled>
            <NumbersItemStyled>
              <NumbersItemRowStyled color="#B202FF">
                <DynamicNumber value={96} show={showNumbers} round />%
              </NumbersItemRowStyled>
              <NumbersItemTextStyled>
                of users would recommend Wizco coaching to a friend or colleague
              </NumbersItemTextStyled>
            </NumbersItemStyled>
          </NumbersItemsContainer>
        </AppearBlock>
      </LineBlock>
    </Layout>
  )
}

export default LandingNumbers
