import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { IMetricsData, MetricsType, MetricsVocabularyText } from '../types'
import { MetricsVocabulary } from '../services/useMetrics'
import PushpinIcon from '../../static/ic-pushpin.png'

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const PushpinContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 44px;
  margin-bottom: 5px;
`

const Pushpin = styled.div<{ visible: boolean; progres?: number }>`
  position: absolute;
  width: 32px;
  height: 44px;
  background: url(${PushpinIcon});
  background-size: 100%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.5s ease;
  left: calc(${(props) => props.progres * 100 ?? 0}% - 16px);
`

const ProgresContainer = styled.div`
  display: flex;
  height: 20px;
  border-radius: 10px;
  background: #e1e3ea;
  margin: 5px 0;
`

const ProgresItem = styled.div<{ color: string }>`
  height: 100%;
  width: 33.33%;
  border-radius: 10px;
  background-color: ${({ color }) => color};
  transition: background-color 0.5s ease;
`

const TextsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`

const Text = styled.div<{ color: string }>`
  font-weight: 600;
  font-size: 12px;
  width: 33.33%;
  text-align: center;
  line-height: 150%;
  color: ${({ color }) => color};
  transition: color 0.5s ease;
  @media screen and (max-width: 800px) {
    font-size: 10px;
  }
`

enum ActivePushpin {
  A,
  B,
  None,
}

enum ActiveSide {
  Left = 0,
  Middle = 1,
  Right = 2,
  None = -1,
}

interface IProps {
  metricsData: IMetricsData
}

const DefTextColor = '#8F9BB3'
const DefProgressColor = '#00000000'

export default function MetricsPushpin({ metricsData }: IProps) {
  const [activeSide, setActiveSide] = useState<ActiveSide>(ActiveSide.None)
  const [activePushpin, setActivePushpin] = useState<ActivePushpin>(ActivePushpin.None)

  const [leftTextColor, setLeftTextColor] = useState(DefTextColor)
  const [leftProgressColor, setLeftProgressColor] = useState(DefProgressColor)
  const [leftText, setLeftText] = useState(DefTextColor)
  const [middleTextColor, setMiddleTextColor] = useState(DefTextColor)
  const [middleProgressColor, setMiddleProgressColor] = useState(DefProgressColor)
  const [middleText, setMiddleText] = useState(DefTextColor)
  const [rightTextColor, setRightTextColor] = useState(DefTextColor)
  const [rightProgressColor, setRightProgressColor] = useState(DefProgressColor)
  const [rightText, setRightText] = useState(DefTextColor)

  const [aPushpinProgress, setAPushpinProgress] = useState(0)
  const [bPushpinProgress, setBPushpinProgress] = useState(0)

  useEffect(() => {
    const metricsVocabularyData = MetricsVocabulary.find(
      (m) => m.type === metricsData.type
    )
    let leftTextItem: MetricsVocabularyText
    let middleTextItem: MetricsVocabularyText
    let rightTextItem: MetricsVocabularyText
    switch (metricsData.type) {
      case MetricsType.Confidence:
        leftTextItem = metricsVocabularyData.maxText
        middleTextItem = metricsVocabularyData.avgText
        rightTextItem = metricsVocabularyData.minText
        break
      default:
        leftTextItem = metricsVocabularyData.minText
        middleTextItem = metricsVocabularyData.avgText
        rightTextItem = metricsVocabularyData.maxText
    }

    if (metricsData.result === leftTextItem.result) {
      setActiveSide(ActiveSide.Left)
    } else if (metricsData.result === middleTextItem.result) {
      setActiveSide(ActiveSide.Middle)
    } else {
      setActiveSide(ActiveSide.Right)
    }
    setLeftText(leftTextItem.result)
    setMiddleText(middleTextItem.result)
    setRightText(rightTextItem.result)
    setActivePushpin((val) =>
      val === ActivePushpin.A ? ActivePushpin.B : ActivePushpin.A
    )
  }, [metricsData])

  useEffect(() => {
    setLeftTextColor(activeSide === ActiveSide.Left ? metricsData.color : DefTextColor)
    setMiddleTextColor(
      activeSide === ActiveSide.Middle ? metricsData.color : DefTextColor
    )
    setRightTextColor(activeSide === ActiveSide.Right ? metricsData.color : DefTextColor)

    setLeftProgressColor(
      activeSide === ActiveSide.Left ? metricsData.color : DefProgressColor
    )
    setMiddleProgressColor(
      activeSide === ActiveSide.Middle ? metricsData.color : DefProgressColor
    )
    setRightProgressColor(
      activeSide === ActiveSide.Right ? metricsData.color : DefProgressColor
    )
  }, [activeSide, metricsData])

  useEffect(() => {
    const innerShift = 0.04
    const progress =
      activeSide * 0.3333 + innerShift + (0.3333 - innerShift * 2) * metricsData.progress
    switch (activePushpin) {
      case ActivePushpin.A:
        setAPushpinProgress(progress)
        break
      default:
        setBPushpinProgress(progress)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePushpin])

  return (
    <Container>
      <PushpinContainer>
        <Pushpin progres={aPushpinProgress} visible={activePushpin === ActivePushpin.A} />
        <Pushpin progres={bPushpinProgress} visible={activePushpin === ActivePushpin.B} />
      </PushpinContainer>
      <ProgresContainer>
        <ProgresItem color={leftProgressColor} />
        <ProgresItem color={middleProgressColor} />
        <ProgresItem color={rightProgressColor} />
      </ProgresContainer>
      <TextsContainer>
        <Text color={leftTextColor}>{leftText}</Text>
        <Text color={middleTextColor}>{middleText}</Text>
        <Text color={rightTextColor}>{rightText}</Text>
      </TextsContainer>
    </Container>
  )
}
