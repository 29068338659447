/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import React, { useCallback, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive'
import { ReviewsCard, ReviewsLogo } from 'src/interview/components/landing-styles'
import ArrowImage from 'src/static/icArrowBack.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import QuotedText from 'src/components/QuotedText'
import useBranding from 'src/utils/useBranding'
import { ReviewsItems } from './landingConstants'
import { BackButton } from '../elements/styled-components'
import { LandingTitle } from './landing-styled-components'

const Layout = styled.div`
  margin: 0 -60px 90px -60px;
  overflow: hidden;

  @media screen and (max-width: 1439px) {
    margin: 0 -40px;
    width: auto;
  }

  ${isMobileOnly &&
  css`
    width: auto;
    overflow: unset;
    overflow-x: clip;
    margin: 0 -16px !important;
  `}
`

const CarouselContainer = styled.div`
  margin-top: 32px;

  ${isMobileOnly &&
  css`
    margin-top: 12px;
  `}
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 142px;
  margin: 42px auto;
  justify-content: space-between;

  @media screen and (max-width: 1439px) {
    margin: 42px auto 120px auto;
  }

  ${isMobileOnly &&
  css`
    width: 136px !important;
    margin: 20px auto 38px !important;
  `}
`

const ButtonStyled = styled(BackButton)<{ mirror?: boolean }>`
  margin: 0;
  transform: scale(${({ mirror }) => (mirror ? -1 : 1)}, 1);

  ${isMobileOnly &&
  css`
    width: 48px;
    height: 48px;
    padding: 15px;
  `}
`

const ReviewsCardStyled = styled(ReviewsCard)`
  border: none;
  border-radius: 32px;
  height: 560px;
  width: 560px !important;
  display: flex !important;
  flex-direction: column;
  padding: 32px;
  margin: 0 20px;

  @media screen and (max-width: 1439px) {
    height: 520px;
    width: 560px !important;
    padding: 40px;
  }

  ${isMobileOnly &&
  css`
    height: 403px !important;
    padding: 12px 16px !important;
    margin: 0 8px !important;
  `}
`

const ReviewsAuthor = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #21304e;
  margin-top: auto;
  margin-bottom: 10px;

  ${isMobileOnly &&
  css`
    font-size: 12px !important;
  `}
`

const ReviewsLogoStyled = styled(ReviewsLogo)`
  margin: 0;
  opacity: 1;
`

const LandingTitleStyled = styled(LandingTitle)`
  @media screen and (max-width: 1439px) {
    margin-bottom: 48px;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 16px !important;
  `}
`

const LandingReviewsBlock = () => {
  const { agencyColor } = useBranding()

  const [selectedReviewCardIndex, setSelectedReviewCardIndex] = useState(1)
  const sliderRef = useRef<Slider>(null)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' }) && !isMobileOnly
  const isExtraSmallScreen =
    useMediaQuery({ query: '(max-width: 899px)' }) && !isMobileOnly

  const goForward = useCallback(() => {
    let newIndex = 0
    if (selectedReviewCardIndex + 1 !== ReviewsItems.length) {
      newIndex = selectedReviewCardIndex + 1
    }
    setSelectedReviewCardIndex(newIndex)
    sliderRef.current.slickGoTo(newIndex, false)
  }, [selectedReviewCardIndex])

  const goBack = useCallback(() => {
    let newIndex = 0
    if (selectedReviewCardIndex === 0) {
      newIndex = ReviewsItems.length - 1
    } else {
      newIndex = selectedReviewCardIndex - 1
    }
    setSelectedReviewCardIndex(newIndex)
    sliderRef.current.slickGoTo(newIndex, false)
  }, [selectedReviewCardIndex])

  return (
    <Layout>
      <LandingTitleStyled>What people are saying</LandingTitleStyled>
      <CarouselContainer>
        <Slider
          ref={sliderRef}
          arrows={false}
          slidesToShow={!isMobileOnly && !isSmallScreen ? 2 : 1}
          slidesToScroll={1}
          speed={400}
          centerMode
          centerPadding={
            isExtraSmallScreen
              ? '20px'
              : isSmallScreen
              ? '15%'
              : isMobileOnly
              ? '20px'
              : '5%'
          }
          beforeChange={(oldIndex, newIndex) => setSelectedReviewCardIndex(newIndex)}
        >
          {ReviewsItems.map((item) => (
            <ReviewsCardStyled key={item.text}>
              {/* <ReviewsCardTextStyled>{item.text}</ReviewsCardTextStyled> */}
              <QuotedText fill={agencyColor}>{item.text}</QuotedText>
              <ReviewsAuthor>{item.author}</ReviewsAuthor>
              <ReviewsLogoStyled src={item.logo} />
            </ReviewsCardStyled>
          ))}
        </Slider>
      </CarouselContainer>
      <ButtonsContainer>
        <ButtonStyled src={ArrowImage} onClick={goBack} />
        <ButtonStyled src={ArrowImage} mirror onClick={goForward} />
      </ButtonsContainer>
    </Layout>
  )
}

export default LandingReviewsBlock
