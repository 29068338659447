import { push } from 'connected-react-router'
import { put, takeEvery, call } from 'typed-redux-saga'

import { createAgency, IAgency, IAgencyPriceTiersB2C } from '../../api'

import handleErrorSaga from '../../utils/handleErrorSaga'
import handleSuccessSaga from '../../utils/handleSuccessSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { updateAgencyAction } from '../data/agency'

interface ICreateAgencyAction {
  agency: Partial<IAgency>
  priceTiers?: IAgencyPriceTiersB2C
}

const {
  action: createAgencyAction,
  actionFailure: createAgencyActionFailure,
  actionSuccess: createAgencyActionSuccess,
  actionSuccessClear: createAgencyActionSuccessClear,
  actionFailureClear: createAgencyActionFailureClear,
  actionType: createAgencyActionType,
  actionTypeFailure: createAgencyActionFailureType,
  actionTypeSuccess: createAgencyActionSuccessType,
  reducer: createAgencyRequestReducer,
  selectors: createAgencySelectors,
} = reduxRequestFactory<ICreateAgencyAction>('createAgency', 'requests')

export {
  createAgencyAction,
  createAgencyActionFailure,
  createAgencyActionSuccess,
  createAgencyActionSuccessClear,
  createAgencyActionFailureClear,
  createAgencyActionType,
  createAgencyActionFailureType,
  createAgencyActionSuccessType,
  createAgencyRequestReducer,
  createAgencySelectors,
}

function* createAgencySaga({ payload }: ISagaAction<ICreateAgencyAction>) {
  try {
    const { agency, priceTiers } = payload
    const createdAgency = yield* call(createAgency, agency, priceTiers)
    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(updateAgencyAction(createdAgency))
    yield put(createAgencyActionSuccess())
    if (createdAgency.id) yield put(push(`/agencies/${createdAgency.id}/edit`))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'

    if (error.response && error.response.data) {
      errorMessage = error.response.data.errors
    }

    yield call(handleErrorSaga, { message: errorMessage })
    yield put(
      createAgencyActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchCreateAgency() {
  yield takeEvery(createAgencyActionType, createAgencySaga)
}
