/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { css } from '@emotion/core'
import LogoIe from 'src/static/landing/logo_ie.png'
import LogoCornel from 'src/static/landing/logo_cornel.png'
import LogoAdp from 'src/static/landing/logo_adp.png'
import LogoKipp from 'src/static/landing/logo_kipp.png'
import LogoCb from 'src/static/landing/logo_cb.png'
import CycledCarousel from 'src/components/CycledCarousel'
import { isMobileOnly } from 'react-device-detect'
import { BaseLayout, LandingInfo, LayoutWrapper } from './landing-styled-components'

const Layout = styled(BaseLayout)`
  padding: 48px 80px;

  @media screen and (max-width: 1439px) {
    padding: 48px;
  }

  @media screen and (max-width: 1144px) {
    padding: 48px 0;
  }

  @media screen and (max-width: 899px) {
    padding: 34px 0;
  }
`

const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const LogoItem = styled.img<{ width: number; height: number; margin?: number }>`
  width: ${({ width, margin }) => width + margin * 2}px;
  padding: ${({ margin }) => (margin ? `0 ${margin}px` : '0')};
  height: auto;
  object-fit: contain;
`

const LandingInfoStyled = styled(LandingInfo)`
  margin-bottom: 40px;

  @media screen and (max-width: 1439px) {
    margin-bottom: 24px;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 12px !important;
  `}
`

const Logos = [
  { img: LogoIe, width: 112, height: 50 },
  { img: LogoCornel, width: 203, height: 45 },
  { img: LogoAdp, width: 67, height: 30 },
  { img: LogoKipp, width: 87, height: 25 },
  { img: LogoCb, width: 250, height: 25 },
]

const LandingTrusted = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 899px)' }) || isMobileOnly

  return (
    <LayoutWrapper background="#0e0224">
      <Layout>
        <LandingInfoStyled color="white" bottom={24}>
          Trusted by top organizations
        </LandingInfoStyled>
        {!isSmallScreen && (
          <LogosContainer>
            {Logos.map((logo, index) => (
              <LogoItem
                key={index}
                src={logo.img}
                width={logo.width}
                height={logo.height}
                alt="logo"
              />
            ))}
          </LogosContainer>
        )}

        {isSmallScreen && (
          <CycledCarousel>
            {Logos.map((logo) => (
              <LogoItem
                src={logo.img}
                width={logo.width}
                height={logo.height}
                alt="logo"
                margin={20}
              />
            ))}
          </CycledCarousel>
        )}
      </Layout>
    </LayoutWrapper>
  )
}

export default LandingTrusted
