import { format } from 'date-fns'

import httpClient from '../../httpClient'
import {
  CoachingType,
  ICandidateProfile,
  ICVFile,
  IExpertProfile,
  IInternalExpert,
  IInterview,
  IInterviewPriceTier,
  IInterviewReport,
  IInterviewTimestamp,
  IInterviewToken,
  IInvoice,
  ITimeSlot,
  IUser,
} from '../models'

export interface ICreateInterviewParams {
  id: string
  data: {
    firstName: string
    lastName: string
    email: string
    internal_experts?: IInternalExpert[]
    moreInfo?: string
    desiredPosition?: string
    programmingLanguage?: string
    company?: string
    confirmedDate?: Date
    positionLink?: string
    interview_date_option?: string
    coaching_type?: CoachingType
  }
}

interface ICreateInterviewRequestPayload {
  candidate?: Partial<ICandidateProfile>
  experts?: Array<Partial<IExpertProfile>>
  interview?: Partial<IInterview>
}

export interface IGetAgencyInterviewResponse {
  candidate_profile?: ICandidateProfile
  candidate_token?: IInterviewToken
  created_by?: IUser
  cv_file?: ICVFile
  expert_profile?: IExpertProfile
  expert_profiles?: IExpertProfile[]
  expert_token?: IInterviewToken
  interview: IInterview
  interview_price_tiers?: IInterviewPriceTier[]
  invoive?: IInvoice
  report?: IInterviewReport
  timestamps?: IInterviewTimestamp[]
}

export interface IUpdateAgencyInterviewOrderInfoPayload {
  candidate?: Pick<
    ICandidateProfile,
    'first_name' | 'last_name' | 'email' | 'linkedin_profile'
  >
  interview?: Pick<
    IInterview,
    | 'desired_position'
    | 'position_company'
    | 'position_location'
    | 'link_to_position'
    | 'position_description'
    | 'programming_language'
    | 'interview_date'
    | 'special_request'
    | 'special_request_by_admin'
    | 'coding_session_url'
    | 'coding_playback_url'
    | 'cv_file_id'
    | 'coaching_language'
    | 'coaching_type'
    | 'interview_internal_experts_attributes'
    | 'availability_days'
    | 'availability_time'
    | 'candidate_available_at'
  >
}

export interface IUpdateAgencyInterviewAvailabilityInfoPayload {
  candidate?: Pick<
    ICandidateProfile,
    'logo' | 'current_position' | 'educations' | 'experiences'
  >
  experts?: Array<
    Pick<
      IExpertProfile,
      | 'id'
      | 'first_name'
      | 'last_name'
      | 'email'
      | 'logo'
      | 'linkedin_profile'
      | 'company'
      | 'rating'
      | 'about'
      | 'current_position'
      | 'expert_price'
      | 'expert_currency'
      | 'adhoc'
      | 'contact_email'
      | 'educations'
      | 'experiences'
    > & {
      _destroy?: boolean
      remote_logo_url?: string
      time_slots?: Array<Pick<ITimeSlot, 'from' | 'to' | 'time_zone'>>
    }
  >
  interview?: Pick<IInterview, 'use_general_link' | 'enable_conversation_intelligence'>
}

export interface IUpdateAgencyInterviewSchedulingInfoPayload {
  interview?: Pick<
    IInterview,
    'main_time_slot_id' | 'admin_notes' | 'expert_confirmed' | 'expert_passed_training'
  >
  reschedule_request?: {
    comment?: string
  }
}

export interface IUpdateAgencyInterviewPostSessionInfoPayload {
  interview?: Pick<
    IInterview,
    | 'hired_candidate'
    | 'expert_payment_reference'
    | 'expert_payment_provider'
    | 'expert_paid'
  >
}

export interface IBulkInterviewData {
  email: string
  name: string
}

// agency_interviews#create - POST /agency/:agency_id/interviews
export const createAgencyInterview = ({
  id: agencyId,
  data: {
    firstName,
    lastName,
    email,
    moreInfo,
    desiredPosition,
    programmingLanguage,
    company,
    confirmedDate,
    interview_date_option,
    positionLink,
    internal_experts,
    coaching_type,
  },
}: any) => {
  const requestOption: ICreateInterviewRequestPayload = {
    candidate: {
      first_name: firstName,
      last_name: lastName,
      email,
    },
  }

  if (internal_experts) {
    requestOption.interview = {
      special_request_by_admin: moreInfo,
      desired_position: desiredPosition,
      programming_language: programmingLanguage,
      interview_date: confirmedDate ? format(confirmedDate, 'dd-MM-yyyy') : '',
      position_company: company,
      link_to_position: positionLink,
      interview_date_option: interview_date_option || '',
      coaching_type,
    }
    requestOption.interview.interview_internal_experts_attributes = internal_experts.map(
      (ie) => {
        return { name: ie.name, internal_expert_id: ie.id }
      }
    )
  }

  return httpClient
    .post(`/agencies/${agencyId}/interviews`, requestOption)
    .then((response) => response.data)
}

// agency_interviews#destroy - DELETE /agency/:agency_id/interviews/:interview_id
export const deleteAgencyInterview = (agencyId, interviewId) => {
  return httpClient
    .delete(`/agencies/${agencyId}/interviews/${interviewId}`)
    .then((response) => response.data)
}

// agency_interviews#show - GET /agency/:agency_id/interviews/:interview_id
export const getAgencyInterview = (agencyId, interviewId) => {
  return httpClient
    .get(`/agencies/${agencyId}/interviews/${interviewId}`)
    .then((response) => response.data)
}

// agency_interviews#assign_ops - POST /agency/:agency_id/interviews/:interview_id/assign_ops
export const assignOpsInterview = (agencyId, interviewId, ops_manager_email) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/assign_ops`, {
      interview: { ops_manager_email },
    })
    .then((response) => response.data)
}

// agency_interviews#bulk_create - POST /agency/:agency_id/interviews/bulk_create
export const bulkCreateInterview = (agencyId, bulk_interview_file_id, data) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/bulk_create`, {
      bulk_interview_file_id,
      data,
    })
    .then((response) => response.data)
}

// agency_interviews#complete - POST /agency/:agency_id/interviews/:interview_id/complete
export const completeInterview = (agencyId, interviewId) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/complete`)
    .then((response) => response.data)
}

// agency_interviews#match - POST /agency/:agency_id/interviews/:interview_id/match
export const matchApplicant = (agencyId, interviewId) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/match`)
    .then((response) => response.data)
}

// agency_interviews#report - POST /agency/:agency_id/interviews/:interview_id/report
export const saveSessionReport = (agencyId, interviewId, report) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/report`, { report })
    .then((response) => response.data)
}

// agency_interviews#reschedule - POST /agency/:agency_id/interviews/:interview_id/reschedule
export const adminRescheduleInterview = (
  agencyId,
  interviewId,
  reschedule_request,
  candidate_available_at
) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/reschedule`, {
      interview: { candidate_available_at },
      reschedule_request,
    })
    .then((response) => response.data)
}

// agency_interviews#to_end - POST /agency/:agency_id/interviews/:interview_id/to_end
export const endAgencyInterview = (agencyId, interviewId) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/to_end`)
    .then((response) => response.data)
}

// agency_interviews#update_availability_info - POST /agency/:agency_id/interviews/:interview_id/update_availability_info
export const updateAvailabilityInfo = (agencyId, interviewId, payload) => {
  return httpClient
    .post(
      `/agencies/${agencyId}/interviews/${interviewId}/update_availability_info`,
      payload
    )
    .then((response) => response.data)
}

// agency_interviews#update_order_info - POST /agency/:agency_id/interviews/:interview_id/update_order_info
export const updateOrderInfo = (agencyId, interviewId, payload) => {
  return httpClient
    .post(`/agencies/${agencyId}/interviews/${interviewId}/update_order_info`, payload)
    .then((response) => response.data)
}

// agency_interviews#update_post_session_info - POST /agency/:agency_id/interviews/:interview_id/update_post_session_info
export const updatePostSessionInfo = (agencyId, interviewId, payload) => {
  return httpClient
    .post(
      `/agencies/${agencyId}/interviews/${interviewId}/update_post_session_info`,
      payload
    )
    .then((response) => response.data)
}

// agency_interviews#update_scheduling_info - POST /agency/:agency_id/interviews/:interview_id/update_scheduling_info
export const updateSchedulingInfo = (agencyId, interviewId, payload) => {
  return httpClient
    .post(
      `/agencies/${agencyId}/interviews/${interviewId}/update_scheduling_info`,
      payload
    )
    .then((response) => response.data)
}
