import { createReducer } from '@reduxjs/toolkit'
import { IGetInterviewResponse, IInterviewPositionState } from '../api'
import { interview } from '../initialStates'
import { IDefaultState } from '../utils/redux'
import {
  saveBookingPosition,
  updateBooking,
  updateBookingSuccess,
  updateBookingFailure,
  createBookingFailureClear,
  createBooking,
  createBookingSuccess,
  createBookingFailure,
  getInterview,
  getInterviewStatus,
  getInterviewSuccess,
  getInterviewFailure,
  getAgencyBySubdomain,
  getAgencyBySubdomainSuccess,
  getAgencyBySubdomainFailure,
  startInterviewSuccess,
  startInterviewFailure,
  endInterviewSuccess,
  endInterviewFailure,
  assignTimeSlotSuccess,
  assignTimeSlot,
  assignTimeSlotFailure,
  assignTimeSlotFailureClear,
  assignTimeSlotSuccessClear,
  createIntent,
  createIntentFailure,
  createIntentFailureClear,
  createIntentSuccess,
  createIntentSuccessClear,
  createPayment,
  createPaymentSuccess,
  createPaymentFailure,
  createPaymentSuccessClear,
  createPaymentFailureClear,
  paySucceed,
  paySucceedFailure,
  paySucceedFailureClear,
  paySucceedSuccess,
  paySucceedSuccessClear,
  submitAvailability,
  submitAvailabilitySuccess,
  submitAvailabilityFailure,
  declineAvailability,
  declineAvailabilitySuccess,
  declineAvailabilityFailure,
  preventInterviewRedirect,
} from './actions'
import {
  ISaveBookingPositionAction,
  IUpdateStudentDetailsFailureAction,
  ICreateStudentDetailsFailureAction,
  IGetInterviewSuccessAction,
  IGetInterviewFailureAction,
  IGetAgencyBySubdomainSuccessAction,
  IGetAgencyBySubdomainFailureAction,
  IStartInterviewSuccessAction,
  IEndInterviewSuccessAction,
  ICreateIntentSuccessAction,
  ICreateIntentFailureAction,
  ICreatePaymentFailureAction,
  IPaySucceedFailureAction,
  ISubmitAvailabilityFailureAction,
  IDeclineAvailabilityFailureAction,
} from './types'

interface IInterviewState extends IDefaultState {
  data: IGetInterviewResponse
  preventRedirect: boolean
}

interface IInterviewCreateBookingState extends IDefaultState {
  position: IInterviewPositionState
}

interface ICreateIntentState extends IDefaultState {
  clientSecret: string
}

const initialState: IInterviewState = {
  data: interview,
  isLoading: false,
  error: '',
  success: false,
  preventRedirect: false,
}

const assignTimeSlotInitialState: IDefaultState = {
  isLoading: false,
  error: '',
  success: false,
}

const createPaymentInitialState: IDefaultState = {
  isLoading: false,
  error: '',
  success: false,
}

const createIntentInitialState: ICreateIntentState = {
  isLoading: false,
  error: '',
  success: false,
  clientSecret: '',
}

const createBookingInitialState: IInterviewCreateBookingState = {
  // step: 'landing',
  isLoading: false,
  error: '',
  success: false,
  position: {
    name: '',
    request: '',
    company: '',
    location: '',
    link: '',
    description: '',
    date: '',
  },
}

const submitAvailabilityInitialState: IDefaultState = {
  isLoading: false,
  error: '',
  success: false,
}

const declineAvailabilityInitialState: IDefaultState = {
  isLoading: false,
  error: '',
  success: false,
}

export const interviewReducer = createReducer(initialState, {
  [getInterview.type]: (state) => ({
    ...state,
    isLoading: true,
    errors: '',
    // data: interview,
  }),
  [getInterviewStatus.type]: (state) => ({
    ...state,
    isLoading: false,
    errors: '',
    // data: interview,
  }),
  [getInterviewSuccess.type]: (state, action: IGetInterviewSuccessAction) => ({
    ...state,
    isLoading: false,
    error: '',
    data: action.payload.interview,
  }),
  [getInterviewFailure.type]: (state, action: IGetInterviewFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    // data: interview,
  }),
  [getAgencyBySubdomain.type]: (state) => ({
    ...state,
    isLoading: true,
    errors: '',
    data: interview,
  }),
  [getAgencyBySubdomainSuccess.type]: (
    state,
    action: IGetAgencyBySubdomainSuccessAction
  ) => {
    return {
      ...state,
      isLoading: false,
      error: '',
      data: {
        ...state.data,
        agency: {
          ...state.data.agency,
          ...action.payload.agency,
        },
      },
    }
  },
  [getAgencyBySubdomainFailure.type]: (
    state,
    action: IGetAgencyBySubdomainFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    data: interview,
  }),
  [startInterviewSuccess.type]: (state, action: IStartInterviewSuccessAction) => ({
    ...state,
    data: action.payload.interview,
  }),
  [startInterviewFailure.type]: (state) => ({
    ...state,
    data: interview,
  }),
  [endInterviewSuccess.type]: (state, action: IEndInterviewSuccessAction) => ({
    ...state,
    data: action.payload.interview,
  }),
  [endInterviewFailure.type]: (state) => ({
    ...state,
  }),
  [preventInterviewRedirect.type]: (state) => ({
    ...state,
    preventRedirect: true,
  }),
})

export const assignTimeSlotReducer = createReducer(assignTimeSlotInitialState, {
  [assignTimeSlot.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [assignTimeSlotSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
    success: true,
  }),
  [assignTimeSlotFailure.type]: (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [assignTimeSlotSuccessClear.type]: (state) => ({
    ...state,
    success: false,
  }),
  [assignTimeSlotFailureClear.type]: (state) => ({
    ...state,
    error: '',
  }),
})

export const createIntentReducer = createReducer(createIntentInitialState, {
  [createIntent.type]: (state: ICreateIntentState) => ({
    ...state,
    isLoading: true,
  }),
  [createIntentSuccess.type]: (state, action: ICreateIntentSuccessAction) => ({
    ...state,
    isLoading: false,
    error: '',
    success: true,
    clientSecret: action.payload.clientSecret.clientSecret,
  }),
  [createIntentFailure.type]: (state, action: ICreateIntentFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [createIntentSuccessClear.type]: (state) => ({
    ...state,
    success: false,
  }),
  [createIntentFailureClear.type]: (state) => ({
    ...state,
    error: '',
  }),
})

export const createPaymentReducer = createReducer(createPaymentInitialState, {
  [createPayment.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
  }),
  [createPaymentSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
    success: true,
  }),
  [createPaymentFailure.type]: (state, action: ICreatePaymentFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [createPaymentSuccessClear.type]: (state) => ({
    ...state,
    success: false,
  }),
  [createPaymentFailureClear.type]: (state) => ({
    ...state,
    error: '',
  }),
})

export const paySucceedReducer = createReducer(createPaymentInitialState, {
  [paySucceed.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
  }),
  [paySucceedSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
    success: true,
  }),
  [paySucceedFailure.type]: (state, action: IPaySucceedFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [paySucceedSuccessClear.type]: (state) => ({
    ...state,
    success: false,
  }),
  [paySucceedFailureClear.type]: (state) => ({
    ...state,
    error: '',
  }),
})

export const createBookingReducer = createReducer(createBookingInitialState, {
  [saveBookingPosition.type]: (
    state: IInterviewCreateBookingState,
    action: ISaveBookingPositionAction
  ) => {
    return {
      ...state,
      position: action.payload.position,
    }
  },
  [updateBooking.type]: (state: IInterviewCreateBookingState) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [updateBookingSuccess.type]: (state: IInterviewCreateBookingState) => ({
    ...state,
    error: '',
    isLoading: false,
    success: true,
  }),
  [updateBookingFailure.type]: (
    state: IInterviewCreateBookingState,
    action: IUpdateStudentDetailsFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [createBooking.type]: (state: IInterviewCreateBookingState) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [createBookingSuccess.type]: (state: IInterviewCreateBookingState) => ({
    ...state,
    error: '',
    isLoading: false,
    success: true,
  }),
  [createBookingFailure.type]: (
    state: IInterviewCreateBookingState,
    action: ICreateStudentDetailsFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [createBookingFailureClear.type]: (state: IInterviewCreateBookingState) => ({
    ...state,
    error: '',
  }),
})

export const submitAvailabilityReducer = createReducer(submitAvailabilityInitialState, {
  [submitAvailability.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [submitAvailabilitySuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
    success: true,
  }),
  [submitAvailabilityFailure.type]: (
    state,
    action: ISubmitAvailabilityFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
})

export const declineAvailabilityReducer = createReducer(declineAvailabilityInitialState, {
  [declineAvailability.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [declineAvailabilitySuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
    success: true,
  }),
  [declineAvailabilityFailure.type]: (
    state,
    action: IDeclineAvailabilityFailureAction
  ) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
})
