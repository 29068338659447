import React from 'react'
import { Switch, Route } from 'react-router-dom'
import LandingContainer from './containers/LandingContainer'

function Landing() {
  return (
    <Switch>
      <Route exact path="/home">
        <LandingContainer />
      </Route>
    </Switch>
  )
}

export default Landing
