import { IAvaInterview } from '../../../api'
import { reduxDataFactory } from '../../../utils/redux'

const {
  action: updateAvaInterviewAction,
  actionType: updateAvaInterviewActionType,
  reducer: avaInterviewDataReducer,
  selectors: avaInterviewSelectors,
} = reduxDataFactory<IAvaInterview>('interview', 'ava')

const clearAvaInterviewAction = updateAvaInterviewAction(undefined)

export {
  updateAvaInterviewAction,
  updateAvaInterviewActionType,
  avaInterviewDataReducer,
  avaInterviewSelectors,
  clearAvaInterviewAction,
}
