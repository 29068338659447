/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import styled from '@emotion/styled'
import { isChrome, isFirefox, isMobileOnly, isSafari } from 'react-device-detect'

import { css } from '@emotion/core'
import closeIcon from 'src/static/close_gray.svg'
import FirefoxShareHelp from 'src/static/firefox-screenshare-help.png'
import { Close } from './onboarding/BaseOnboarding'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: transparent;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 549px;
  padding: 40px;
  border: 1px solid #b202ff;
  border-radius: 10px;
  background: white;

  ${isMobileOnly &&
  css`
    width: 90%;
    padding: 19px;
    @media (orientation: landscape) {
      width: 50%;
    }
  `}
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #1d0e3b;
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #3c424e;
`

const StyledList = styled.ol`
  padding-left: 20px;
  margin-top: 25px;
  color: #3c424e;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
`

const HelpImage = styled.img`
  margin: 24px 0 10px 0;
`

const BoldText = styled.span`
  font-weight: 700;
`

interface IProps {
  onClose: () => void
}

const ScreenShareErrorNotification = ({ onClose }: IProps) => {
  const getBrowserHelp = () => {
    if (isSafari) {
      return (
        <StyledList type="1">
          <li>{`Open Safari Preferences and at the top menu click on 'Websites'.`}</li>
          <li>{`At the side menu, choose 'Screen sharing', set xxx.wizco.io to 'Ask' and try again.`}</li>
          <li>{`If you still can't share your screen, on Mac, go to System Preferences > Security & Privacy > Screen Recording, and enable the option for your browser.`}</li>
        </StyledList>
      )
    }
    if (isFirefox) {
      return (
        <StyledList type="1">
          <li>
            Click on the <BoldText>Permission</BoldText> icon next to your URL bar. Make
            sure that the permission to Share the screen is not blocked for this website.
            You may need to reload the page for changes to apply.
            <HelpImage src={FirefoxShareHelp} alt="help" />
          </li>
          <li>
            {`If you still can't share your screen, on Mac, go to System Preferences > Security & Privacy > Screen Recording, and enable the option for your browser.`}
          </li>
        </StyledList>
      )
    }

    return (
      <StyledList type="1">
        <li>
          <BoldText>Share a specific tab or window</BoldText>, you can select to share a
          specific tab or window instead of your entire screen
        </li>
        <li>
          <BoldText>Share your entire screen:</BoldText> Grant your browser permission to
          record your screen. On Mac, go to your System Preferences, then Security &
          Privacy, and select Screen Recording. From there, enable the option for your
          browser.
        </li>
      </StyledList>
    )
  }
  return (
    <Container>
      <DataContainer>
        <LineContainer>
          <Title>Can&apos;t share your {isChrome ? 'entire ' : ''} screen</Title>
          <Close onClick={onClose} src={closeIcon} />
        </LineContainer>
        <Text>
          It looks like your browser doesn&apos;t have permission to share your{' '}
          {isChrome ? 'entire ' : ''}
          screen.{' '}
          {isChrome ? 'Please follow these steps' : 'To fix that, follow these steps'}:
        </Text>
        {getBrowserHelp()}
      </DataContainer>
    </Container>
  )
}

export default ScreenShareErrorNotification
