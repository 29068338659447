/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import withProps from 'recompose/withProps'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const ItemsContainer = styled.div<{ shiftPct: number }>`
  display: flex;
  align-items: center;
`

const ItemContainer = styled.div<{ selected: boolean; growPct: number }>`
  min-width: 33.33%;
  transform: scale(${({ selected, growPct }) => (selected ? 1 : 1 - growPct)});
  transition: transform 0.5s;
  cursor: pointer;

  @media screen and (max-width: 1100px) {
    min-width: 60%;
    margin: 0 20%;
  }
`

const PaginationContainer = styled.div<{ top?: number }>`
  display: flex;
  justify-content: center;
  margin-top: ${({ top }) => top ?? 56}px;
`

const PagingItem = withProps()(styled.div<{ active: boolean; color?: string }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 0 17px;
  cursor: pointer;
  background: ${({ active, theme, color }: any) =>
    active
      ? color ||
        (theme.agencyColor && theme.agencyColor !== '' ? theme.agencyColor : '#b202ff')
      : '#E1E3EA'};
`)

interface IProps {
  items: any[]
  render?: (item: any) => JSX.Element
  selectedIndex?: number
  onSelectedIndexChanged?: (index: number) => void
  paginationTop?: number
  showPagination?: boolean
  growSelected?: boolean
  growPct?: number
  paginationColor?: string
  onItemClicked?: (index: number) => void
}

function BlockCarousel({
  selectedIndex,
  onSelectedIndexChanged,
  render,
  items,
  paginationTop,
  showPagination,
  growSelected,
  growPct,
  paginationColor,
  onItemClicked,
}: IProps) {
  const [innerItems, setInnerItems] = useState([])
  const [shift, setShift] = useState(undefined)
  const [firstShift, setFirstShift] = useState(true)

  const sideRef = useRef(1)
  const lastIndex = useRef(selectedIndex)
  const isSmallScreenRef = useRef(
    useMediaQuery({ query: '(max-width: 1100px)' }) && !isMobileOnly
  )
  const slideStepRef = useRef(isSmallScreenRef.current ? 100 : 33.33)

  const getShift = useCallback(
    (index: number) => {
      let newShift = 0
      if (index === innerItems.length - 1) {
        newShift = (index - 2) * slideStepRef.current * -1
      } else {
        newShift =
          (index - 1 + sideRef.current * items.length) * slideStepRef.current * -1
      }
      return newShift - (isSmallScreenRef.current ? 100 : 0)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [innerItems, items]
  )

  const getRealIndex = (index: number) => {
    const realIndex = index % items.length
    return realIndex
  }

  const getRealVirtualIndex = (index: number) => {
    const virtualIndex = index + sideRef.current * items.length
    return virtualIndex
  }

  const handleItemClicked = (index: number, isItem: boolean = false) => {
    const realIndex = getRealIndex(index)
    const newSide = Math.floor(index / items.length)
    sideRef.current = newSide
    if (isItem) onItemClicked(realIndex)
    if (onSelectedIndexChanged) onSelectedIndexChanged(realIndex)
  }

  const handleSelectedIndexChanged = (index: number) => {
    const goingForward =
      lastIndex.current + 1 !== items.length
        ? index - lastIndex.current === 1
        : index === 0
    if (goingForward && index === 0 && sideRef.current === 1) {
      sideRef.current = 2
    }
    if (!goingForward && index + 1 === items.length && sideRef.current === 1) {
      sideRef.current = 0
    }
    setShift(getShift(index))
    lastIndex.current = index
  }

  const handleWindowResize = useCallback(
    (ev) => {
      const isSmallDesktop = ev.target.innerWidth < 1100 && !isMobileOnly
      isSmallScreenRef.current = isSmallDesktop
      slideStepRef.current = isSmallDesktop ? 100 : 33.33
      handleSelectedIndexChanged(selectedIndex)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIndex]
  )

  useEffect(() => {
    const newItems = [...items]
    newItems.push(...items)
    newItems.push(...items)
    setInnerItems(newItems)
  }, [items])

  useEffect(() => {
    handleSelectedIndexChanged(selectedIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex])

  useEffect(() => {
    if (shift === undefined) return
    const container = document.getElementById('block-items-container') as HTMLDivElement

    if (firstShift) {
      setFirstShift(false)
      container.style.transition = ''
    } else {
      container.style.transition = 'transform 0.5s'
    }
    container.style.transform = `translateX(${shift}%)`

    if (shift / (slideStepRef.current * -1) === items.length - 2) {
      container.style.pointerEvents = 'none'
      setTimeout(() => {
        const newShift = items.length * (slideStepRef.current * -1) + shift
        container.style.transition = ''
        container.style.transform = `translateX(${newShift}%)`
        container.style.pointerEvents = 'all'
        sideRef.current = 1
      }, 500)
    } else if (shift / (slideStepRef.current * -1) === items.length * 2 - 1) {
      container.style.pointerEvents = 'none'
      setTimeout(() => {
        const newShift = (items.length - 1) * (slideStepRef.current * -1)
        container.style.transition = ''
        container.style.transform = `translateX(${newShift}%)`
        container.style.pointerEvents = 'all'
        sideRef.current = 1
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <Container>
      <ItemsContainer shiftPct={0} id="block-items-container">
        {innerItems.map((item, index) => (
          <ItemContainer
            key={index}
            selected={getRealIndex(index) === selectedIndex && growSelected}
            onClick={() => handleItemClicked(index, true)}
            growPct={growPct}
          >
            {render(item)}
          </ItemContainer>
        ))}
      </ItemsContainer>
      {showPagination && (
        <PaginationContainer top={paginationTop}>
          {items.map((item, index) => (
            <PagingItem
              key={index}
              color={paginationColor}
              active={index === selectedIndex}
              onClick={() => handleItemClicked(getRealVirtualIndex(index))}
            />
          ))}
        </PaginationContainer>
      )}
    </Container>
  )
}

export default BlockCarousel

BlockCarousel.defaultProps = {
  selectedIndex: 0,
  onSelectedIndexChanged: () => {},
  render: () => {},
  paginationTop: 56,
  showPagination: true,
  growSelected: true,
  growPct: 0.07,
  paginationColor: null,
  onItemClicked: () => {},
}
