import React from 'react'
import styled from '@emotion/styled'

import { useSelector } from 'react-redux'

import closeIcon from 'src/static/close_gray.svg'
import dangerIcon from 'src/static/danger.svg'

import * as interviewSelectors from '../../../interview/selectors'

import { OnboardingPopup, StyledText, Close } from './onboarding/BaseOnboarding'

const Popup = styled(OnboardingPopup)`
  align-items: flex-start;
  background: rgba(24, 11, 49, 0.8);
  border: 1px solid #353c5a;
  flex-direction: row;
  position: absolute;
  right: 50%;
  top: 100%;
  transform: translate(50%, 0.75rem);
  width: max-content !important;

  &:after {
    background-color: rgba(24, 11, 49, 0.8);
    border-top: 1px solid #353c5a;
    border-right: 1px solid #353c5a;
  }

  padding: 18px;
  min-width: 500px;
  @media screen and (max-width: 2500px) {
    padding: 15px;
    min-width: 420px;
  }
  @media screen and (max-width: 2000px) {
    padding: 12px;
    min-width: 330px;
  }
  @media screen and (max-width: 1200px) {
    padding: 9px;
    min-width: 250px;
  }
  @media screen and (max-width: 900px) {
    padding: 6px;
    min-width: 170px;
  }
`

const PopupTitle = styled(StyledText)`
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CloseLink = styled(StyledText)`
  color: #ffffff;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  margin: 0 !important;
`

const DangerIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  height: 28px;
  margin-right: 28px;
  width: 28px;
  @media screen and (max-width: 2500px) {
    height: 24px;
    margin-right: 24px;
    width: 24px;
  }
  @media screen and (max-width: 2000px) {
    height: 19px;
    margin-right: 19px;
    width: 19px;
  }
  @media screen and (max-width: 1200px) {
    height: 14px;
    margin-right: 14px;
    width: 14px;
  }
  @media screen and (max-width: 900px) {
    height: 9px;
    margin-right: 9px;
    width: 9px;
  }
`

const DangerIcon = styled.img`
  width: 100%;
`

const PopupClose = styled(Close)`
  align-self: flex-start;
  flex-shrink: 0;

  height: 28px;
  margin-left: 28px;
  width: 28px;
  @media screen and (max-width: 2500px) {
    height: 24px;
    margin-left: 24px;
    width: 24px;
  }
  @media screen and (max-width: 2000px) {
    height: 19px;
    margin-left: 19px;
    width: 19px;
  }
  @media screen and (max-width: 1200px) {
    height: 14px;
    margin-left: 14px;
    width: 14px;
  }
  @media screen and (max-width: 900px) {
    height: 9px;
    margin-left: 9px;
    width: 9px;
  }
`

const PopupContent = styled.div`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`

export default function PeerLeftCodeNotification({
  onClose,
  onLeave,
}: {
  onClose: () => void
  onLeave: () => void
}) {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const label = `${peer.first_name} left the coding environment`

  const handleClose = (e) => {
    e.stopPropagation()
    onClose()
  }

  const handleLeave = (e) => {
    e.stopPropagation()
    onLeave()
  }

  return (
    <Popup>
      <DangerIconContainer>
        <DangerIcon src={dangerIcon} />
      </DangerIconContainer>
      <PopupContent>
        <PopupTitle>{label}</PopupTitle>
        <CloseLink onClick={handleLeave}>Leave coding environment</CloseLink>
      </PopupContent>
      <PopupClose onClick={handleClose} src={closeIcon} />
    </Popup>
  )
}
