import * as moment from 'moment'
import { useEffect } from 'react'
import useState from 'react-usestateref'

const useFullscreen = () => {
  const [lastActive, setLastActive, lastActiveRef] = useState<moment.Moment>(undefined)

  useEffect(() => {
    if (lastActive) {
      setTimeout(() => {
        if (lastActiveRef.current && lastActiveRef.current === lastActive)
          setLastActive(undefined)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActive])

  const onToggle = (e) => {
    e.stopPropagation()
    setLastActive(lastActiveRef.current ? undefined : moment.default())
  }

  const onExtend = () => {
    setLastActive(moment.default())
  }

  return {
    activeFullscreen: !lastActive,
    extendFullscreen: onExtend,
    toggleFullscreen: onToggle,
  }
}

export default useFullscreen
