import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import ReduxToastr from 'react-redux-toastr'
import { ThemeProvider } from 'emotion-theming'
import * as Bowser from 'bowser'

import * as interviewSelectors from './interview/selectors'
import MainLayout from './layouts/Main'
import { agencySelectors, getSystemSettingsAction } from './redux'
import { history } from './redux/store'
import Router from './Router'
import useLogrocket from './utils/useLogrocket'

function isBrowserSupported() {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const res = browser.satisfies({
    safari: '>=12',
  })

  return res === undefined || res
}

function App() {
  const dispatch = useDispatch()
  const { initLogrocket } = useLogrocket()
  if (!isBrowserSupported()) {
    window.location.href = '/unsupported.html'
  }

  const agency = useSelector(agencySelectors.data)
  const interviewAgency = useSelector(interviewSelectors.agency)

  useEffect(() => {
    initLogrocket('bbxr6j/wizco-prod')
    dispatch(getSystemSettingsAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <ConnectedRouter history={history}>
        <ThemeProvider
          theme={{ agencyColor: interviewAgency?.main_color || agency.main_color }}
        >
          <Router />
        </ThemeProvider>
      </ConnectedRouter>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </MainLayout>
  )
}

export default App
