const downloadCSV = (data, filename) => {
  const element = document.createElement('a')
  const file = new Blob([data], { type: 'text/plain' })
  element.href = URL.createObjectURL(file)
  element.download = `${filename}.csv`
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export default downloadCSV
