import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import withProps from 'recompose/withProps'
import { ButtonBase } from '../elements/styled-components'

export const BaseResizableLayout = styled.div`
  max-width: 1440px;

  // @media screen and (max-width: 1439px) {
  //   max-width: 1200px;
  // }

  @media screen and (max-width: 1279px) {
    max-width: 1072px;
  }

  @media screen and (max-width: 1144px) {
    max-width: 1072px;
  }

  // @media screen and (max-width: 899px) {
  //   max-width: unset;
  // }
`

export const LandingTitle = styled.div<{
  color?: string
  fontSize?: number
  lineHeight?: number
  align?: string
}>`
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize ?? 40}px;
  line-height: ${({ lineHeight }) => lineHeight ?? 50}px;
  text-align: ${({ align }) => align ?? 'center'};
  color: ${({ color }) => color ?? '#21304e'};
  margin-bottom: 10px;

  @media screen and (max-width: 1439px) {
    font-size: 40px;
    line-height: 48px;
  }

  ${isMobileOnly &&
  css`
    font-family: 'Open Sans';
    font-size: 24px !important;
    line-height: 150% !important;
    margin-bottom: 8px;
  `}
`

export const LandingInfo = styled.div<{
  color?: string
  fontSize?: number
  align?: string
}>`
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize ?? 20}px;
  line-height: 150%;
  text-align: center;
  color: ${({ color }) => color ?? '#000'};
  margin-bottom: 40px;
  text-align: ${({ align }) => align ?? 'center'};
  white-space: break-spaces;

  @media screen and (max-width: 1439px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 24px;
  }

  ${isMobileOnly &&
  css`
    font-size: 14px !important;
    line-height: 150% !important;
  `}
`

export const LandingButton = withProps()(styled(ButtonBase)<{ inverse?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 24px;
  color: ${({ theme, inverse }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return inverse ? color : 'white'
  }};
  background: ${({ theme, inverse }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return inverse ? 'white' : color
  }};
  border-radius: 32px;
  width: fit-content;
  margin: 0;

  @media screen and (max-width: 1439px) {
    padding: 12px 24px;
    line-height: 20px;
  }

  ${isMobileOnly &&
  css`
    font-size: 12px !important;
    line-height: normal !important;
    padding: 9px 18px !important;
  `}
`)
