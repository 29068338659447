import { put, takeEvery, call } from 'typed-redux-saga'

import { avaSaveInfo } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { avaGetInterviewAction } from './getInterview'

interface IAvaSaveInfoAction {
  candidate?: {
    first_name?: string
    last_name?: string
    email?: string
    experience?: string
  }
  interview?: {
    agree_terms_and_privacy?: boolean
    agree_to_receive_offers?: boolean
    agree_to_share?: boolean
  }
  interviewToken: string
  params?: {
    to_interview?: boolean
    to_intro?: boolean
  }
  position?: {
    position?: string
    role?: string
    is_manager?: boolean
    is_specific_company?: boolean
    company?: string
    responsibilities?: string
    qualifications?: string
  }
}

const {
  action: avaSaveInfoAction,
  actionFailure: avaSaveInfoActionFailure,
  actionSuccess: avaSaveInfoActionSuccess,
  actionSuccessClear: avaSaveInfoActionSuccessClear,
  actionFailureClear: avaSaveInfoActionFailureClear,
  actionType: avaSaveInfoActionType,
  actionTypeFailure: avaSaveInfoActionFailureType,
  actionTypeSuccess: avaSaveInfoActionSuccessType,
  reducer: avaSaveInfoRequestReducer,
  selectors: avaSaveInfoSelectors,
} = reduxRequestFactory<IAvaSaveInfoAction>('avaSaveInfo', 'requests')

export {
  avaSaveInfoAction,
  avaSaveInfoActionFailure,
  avaSaveInfoActionSuccess,
  avaSaveInfoActionSuccessClear,
  avaSaveInfoActionFailureClear,
  avaSaveInfoActionType,
  avaSaveInfoActionFailureType,
  avaSaveInfoActionSuccessType,
  avaSaveInfoRequestReducer,
  avaSaveInfoSelectors,
}

function* avaSaveInfoSaga(action: ISagaAction<IAvaSaveInfoAction>) {
  try {
    const {
      candidate = {},
      interview = {},
      interviewToken,
      params = {},
      position = {},
    } = action.payload
    yield* call(avaSaveInfo, interviewToken, { candidate, interview, position }, params)
    yield put(avaGetInterviewAction({ interviewToken }))
    yield put(avaSaveInfoActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      avaSaveInfoActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaSaveInfo() {
  yield takeEvery(avaSaveInfoActionType, avaSaveInfoSaga)
}
