import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import * as authSelectors from '../selectors'
import { signIn, signInClearError } from '../actions'
import { Input, Button, Notification } from '../../components'

const Form = styled('form')({
  marginBottom: '12px',
})

const checkFieldsFill = (fields: string[], values: object = {}) => {
  return fields.reduce((prev, curr) => !!values[curr] && prev, true)
}

function LoginForm() {
  const dispatch = useDispatch()
  const isLoading = useSelector(authSelectors.login.isLoading)
  const apiError = useSelector(authSelectors.login.error)
  const { handleSubmit, errors, control, getValues } = useForm({
    mode: 'onChange',
  })
  const onSubmit = (payload) => {
    dispatch(signIn(payload))
  }
  const clearError = () => {
    dispatch(signInClearError())
  }

  const disabled = !checkFieldsFill(['email', 'password'], getValues())
  const hasFormErrors = Object.keys(errors).length > 0

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {apiError && <Notification onClose={clearError} message={apiError} />}
      <Controller
        as={Input}
        name="email"
        disableWhitespaces
        label="Email"
        control={control}
        error={errors.email}
        rules={{
          required: true,
          validate: {
            valid: (value) =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value) ||
              "That format doesn't look right. Make sure there aren't any typos",
          },
        }}
        defaultValue=""
      />
      <Controller
        as={Input}
        label="Password"
        name="password"
        disableWhitespaces
        type="password"
        control={control}
        error={errors.password}
        rules={{ required: true }}
        defaultValue=""
      />
      <Button disabled={isLoading || disabled || hasFormErrors} title="Log in" />
    </Form>
  )
}

export default LoginForm
