/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import React, { useEffect, useState } from 'react'
import LogoImg from 'src/static/logo.png'
import { isMobileOnly } from 'react-device-detect'
import withProps from 'recompose/withProps'
import HamburgerButton from './HamburgerButton'
import LandingSideMenu from './LandingSideMenu'
import MenuBlock from './MenuBlock'
import { ButtonBase } from './styled-components'

const LayoutWrapper = styled.div`
  position: sticky;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
`

const Layout = styled.div`
  width: 100%;
  padding: 26px 80px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  max-width: 1440px;

  @media screen and (max-width: 1439px) {
    padding: 22px 40px;
    // max-width: 1280px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 1144px;
  }

  // @media screen and (max-width: 1144px) {
  //   max-width: 900px;
  // }

  @media screen and (max-width: 899px) {
    padding: 22px 40px;
  }

  ${isMobileOnly &&
  css`
    height: 64px !important;
    padding: 0 24px !important;
  `}
`

const Logo = styled.img`
  max-width: 120px;
  max-height: 80%;
  object-fit: contain;
  cursor: pointer;

  @media screen and (max-width: 899px) {
    width: 110px;
    height: 26px;
  }

  ${isMobileOnly &&
  css`
    width: 71px !important;
    height: 17px !important;
  `}
`

const MenuContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  @media screen and (max-width: 925px) {
    display: none;
  }
`

const SubmenuContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 215px;
  left: 50%;
  transform: translateX(-50%);
  padding: 44px 0 0;
  top: 0;
  display: flex:
  flex-direction: column-reverse;

  @media screen and (max-width: 899px) {
    padding: 22px 0 0;
    width: 156px;
  }
`

const SubmenuDataContainer = styled.div`
  background: white;
  border-radius: 5px;
  padding: 10px 15px 5px 15px;
`

const MenuItem = styled.div`
  position: relative;
  margin: 0 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0e0224;
  cursor: pointer;

  @media screen and (max-width: 899px) {
    font-size: 12px;
  }

  > span {
    position: relative;
  }

  &:hover {
    text-decoration: underline;
    color: #b202ff;
  }
`

const SubmenuItem = styled(MenuItem)`
  margin: 10px 0;
  text-align: center;

  @media screen and (max-width: 899px) {
    margin: 5px 0;
  }
`

const SubmenuSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #e0d8fc;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`

const LoginButton = styled.div`
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  cursor: pointer;

  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};

  @media screen and (max-width: 899px) {
    font-size: 12px;
    margin-right: 14px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const SideMenuDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const SideMenuFooterContainer = styled.div`
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: space-between;
`

const LandingButton = withProps()(styled(ButtonBase)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: white;
  padding: 12px 24px;
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 32px;
  width: fit-content;
`)

const LandingButtonStyled = styled(LandingButton)<{ show: boolean }>`
  margin-left: 18px;
  max-width: ${({ show }) => (show ? 999 : 0)}px;
  padding: ${({ show }) => (show ? '12px 24px' : '12px 0')};
  transition: all 0.2s;
  height: 44px;
  white-space: nowrap;
  overflow: hidden;
  // eslint-disable-next-line no-nested-ternary
  display: ${({ show }) => (isMobileOnly ? (show ? 'block' : 'none') : 'block')};

  // @media screen and (max-width: 1439px) {
  //   right: 40px;
  // }

  ${isMobileOnly &&
  css`
    right: 24px !important;
    padding: 9px 18px !important;
    font-size: 12px !important;
    line-height: normal !important;
    height: auto;
  `}
`

const MenuItems = [
  {
    id: 1,
    text: 'AI coaching',
    link: '/ava/',
    sublinks: [],
  },
  {
    id: 2,
    text: 'Expert coaching',
    link: '/interviews',
    sublinks: [],
  },
]

interface IProps {
  onStart: () => void
  showLoginButton?: boolean
  startButtonText?: string
  showMenu?: boolean
  logo?: string
}

const LandingHeader = ({
  onStart,
  showLoginButton,
  startButtonText,
  showMenu,
  logo,
}: IProps) => {
  const [canShowStartButton, setCanShowStartButton] = useState(false)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1)

  const isSmallScreen = useMediaQuery({ query: `(max-width: 925px)` }) && !isMobileOnly

  const onSelectMenu = (ev, menuData) => {
    ev.stopPropagation()
    if (menuData.link) {
      window.open(menuData.link, '_self')
    } else {
      setActiveMenuIndex(MenuItems.findIndex((m) => m.id === menuData.id))
    }
  }

  const handleHoverMenu = (ev, menuData) => {
    ev.stopPropagation()
    setActiveMenuIndex(MenuItems.findIndex((m) => m.id === menuData.id))
  }

  const handleLogin = () => {
    const isProd = window.location.host.indexOf('wizco.io') !== -1
    window.open(`https://admin.${isProd ? 'wizco.io' : 'wizco.dev'}/user/login`, '_self')
  }

  const handleLogoClicked = () => {
    window.open('/', '_self')
  }

  const handleStartClicked = () => {
    onStart()
    setShowSideMenu(false)
  }

  useEffect(() => {
    window.onmousemove = () => setActiveMenuIndex(-1)
    const rootContainer = document.getElementById('landing-root')
    rootContainer.onscroll = (ev) => {
      const { scrollTop } = ev.target as HTMLDivElement
      setCanShowStartButton(scrollTop > 0)
    }
  }, [])

  const showStartButton =
    (canShowStartButton && !isSmallScreen && !isMobileOnly) || !showMenu

  return (
    <LayoutWrapper>
      <Layout>
        <Logo src={logo ?? LogoImg} alt="logo" onClick={handleLogoClicked} />
        {showMenu && (
          <MenuContainer>
            {MenuItems.map((menuData, index) => (
              <MenuItem
                key={menuData.id}
                onMouseMove={(ev) => handleHoverMenu(ev, menuData)}
                onClick={(ev) => onSelectMenu(ev, menuData)}
              >
                {activeMenuIndex === index && menuData.sublinks?.length > 0 && (
                  <SubmenuContainer onMouseMove={(ev) => ev.stopPropagation()}>
                    <SubmenuDataContainer>
                      {menuData.sublinks.map((submenuData, submenuIndex) => (
                        <div key={submenuData.id}>
                          <SubmenuItem onClick={(ev) => onSelectMenu(ev, submenuData)}>
                            {submenuData.text}
                          </SubmenuItem>
                          {menuData.sublinks.length - 1 !== submenuIndex && (
                            <SubmenuSeparator />
                          )}
                        </div>
                      ))}
                    </SubmenuDataContainer>
                  </SubmenuContainer>
                )}
                <span>{menuData.text}</span>
              </MenuItem>
            ))}
          </MenuContainer>
        )}
        <RightContainer>
          {showLoginButton && !isSmallScreen && !isMobileOnly && (
            <LoginButton onClick={handleLogin}>Admin log in</LoginButton>
          )}

          <LandingButtonStyled show={showStartButton} onClick={handleStartClicked}>
            {startButtonText}
          </LandingButtonStyled>

          {(isSmallScreen || isMobileOnly) && showMenu && (
            <HamburgerButton onClick={() => setShowSideMenu(true)} />
          )}
        </RightContainer>
        {(isSmallScreen || isMobileOnly) && (
          <LandingSideMenu show={showSideMenu} onClose={() => setShowSideMenu(false)}>
            <SideMenuDataContainer>
              {MenuItems.map((menuItem, index) => (
                <MenuBlock
                  key={index}
                  title={menuItem.text}
                  link={menuItem.link}
                  items={menuItem.sublinks?.map((sublink) => {
                    return { label: sublink.text, value: sublink.link }
                  })}
                  onItemClick={(item) => {
                    if (item.value) {
                      window.open(item.value, '_blank')
                    }
                  }}
                />
              ))}
              <SideMenuFooterContainer>
                {showLoginButton && (
                  <LoginButton onClick={handleLogin}>Admin log in</LoginButton>
                )}
                <LandingButton onClick={handleStartClicked}>
                  {startButtonText}
                </LandingButton>
              </SideMenuFooterContainer>
            </SideMenuDataContainer>
          </LandingSideMenu>
        )}
      </Layout>
    </LayoutWrapper>
  )
}

LandingHeader.defaultProps = {
  showLoginButton: false,
  startButtonText: 'Start',
  showMenu: true,
  logo: undefined,
}

export default LandingHeader
