import { put, takeEvery, call } from 'typed-redux-saga'

import { assignOpsInterview } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface IAssignOpsAction {
  agencyId: string
  id: string
  opsManagerEmail: string
}

const {
  action: assignOpsAction,
  actionFailure: assignOpsActionFailure,
  actionSuccess: assignOpsActionSuccess,
  actionSuccessClear: assignOpsActionSuccessClear,
  actionFailureClear: assignOpsActionFailureClear,
  actionType: assignOpsActionType,
  actionTypeFailure: assignOpsActionFailureType,
  actionTypeSuccess: assignOpsActionSuccessType,
  reducer: assignOpsRequestReducer,
  selectors: assignOpsSelectors,
} = reduxRequestFactory<IAssignOpsAction>('assignOps', 'requests')

export {
  assignOpsAction,
  assignOpsActionFailure,
  assignOpsActionSuccess,
  assignOpsActionSuccessClear,
  assignOpsActionFailureClear,
  assignOpsActionType,
  assignOpsActionFailureType,
  assignOpsActionSuccessType,
  assignOpsRequestReducer,
  assignOpsSelectors,
}

function* assignOpsSaga(action: ISagaAction<IAssignOpsAction>) {
  try {
    const { id, agencyId, opsManagerEmail } = action.payload
    yield* call(assignOpsInterview, agencyId, id, opsManagerEmail)
    yield put(assignOpsActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      assignOpsActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAssignOps() {
  yield takeEvery(assignOpsActionType, assignOpsSaga)
}
