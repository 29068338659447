import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import * as interviewSelectors from '../../interview/selectors'
import expertLogo from '../../static/logo_white.png'
import preventImageCache from '../../utils/preventImageCache'

const Container = styled.div`
  width: 120px;
  @media screen and (max-width: 2500px) {
    width: 100px;
  }
  @media screen and (max-width: 2000px) {
    width: 80px;
  }
  @media screen and (max-width: 1200px) {
    width: 60px;
  }
  @media screen and (max-width: 900px) {
    width: 40px;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
`

interface IProps {
  isExpert: boolean
}

const Logo = ({ isExpert }: IProps) => {
  const agency = useSelector(interviewSelectors.agency)
  const companyLogo = preventImageCache(agency.negative_logo?.url)
  const src = (isExpert ? expertLogo : companyLogo) || expertLogo
  return (
    <Container>
      <Image src={src} />
    </Container>
  )
}

export default Logo
