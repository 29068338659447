import { put, takeEvery, call } from 'typed-redux-saga'

import { removeApplicantNote } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateApplicantNotesAction } from '../data/applicantNotes'

interface IRemoveApplicantNoteAction {
  id: string
  interviewToken: string
}

const {
  action: removeApplicantNoteAction,
  actionFailure: removeApplicantNoteActionFailure,
  actionSuccess: removeApplicantNoteActionSuccess,
  actionSuccessClear: removeApplicantNoteActionSuccessClear,
  actionFailureClear: removeApplicantNoteActionFailureClear,
  actionType: removeApplicantNoteActionType,
  actionTypeFailure: removeApplicantNoteActionFailureType,
  actionTypeSuccess: removeApplicantNoteActionSuccessType,
  reducer: removeApplicantNoteRequestReducer,
  selectors: removeApplicantNoteSelectors,
} = reduxRequestFactory<IRemoveApplicantNoteAction>('removeApplicantNote', 'requests')

export {
  removeApplicantNoteAction,
  removeApplicantNoteActionFailure,
  removeApplicantNoteActionSuccess,
  removeApplicantNoteActionSuccessClear,
  removeApplicantNoteActionFailureClear,
  removeApplicantNoteActionType,
  removeApplicantNoteActionFailureType,
  removeApplicantNoteActionSuccessType,
  removeApplicantNoteRequestReducer,
  removeApplicantNoteSelectors,
}

function* removeApplicantNoteSaga(action: ISagaAction<IRemoveApplicantNoteAction>) {
  try {
    const { interviewToken, id } = action.payload
    const notes = yield* call(removeApplicantNote, interviewToken, id)
    yield put(removeApplicantNoteActionSuccess())
    yield put(updateApplicantNotesAction(notes))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      removeApplicantNoteActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchRemoveApplicantNote() {
  yield takeEvery(removeApplicantNoteActionType, removeApplicantNoteSaga)
}
