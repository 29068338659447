import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { ButtonBase, DivBase } from 'src/components/styled-components'

export const LayoutWrapper = styled.div<{ background?: string }>`
  width: 100%;
  background: ${({ background }) => background ?? '#fff'};
`

export const BaseLayout = styled.div`
  max-width: 1440px;

  @media screen and (max-width: 1439px) {
    max-width: 1280px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 1143px;
  }

  @media screen and (max-width: 1144px) {
    max-width: 899px;
  }

  @media screen and (max-width: 899px) {
    max-width: 552px;
  }
  margin: 0 auto;
`

export const LandingTitle = styled(DivBase)<{
  color?: string
  align?: string
  uppercase?: boolean
}>`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: ${({ align }) => align ?? 'center'};
  color: ${({ color }) => color ?? 'rgb(14, 2, 36)'};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};

  ${isMobileOnly &&
  css`
    font-size: 24px !important;
    line-height: 32px !important;
  `}
`

export const LandingInfo = styled(DivBase)<{
  color?: string
  align?: string
  maxWidth?: number
}>`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: ${({ color }) => color ?? '#1D0254'};
  text-align: ${({ align }) => align ?? 'center'};
  white-space: break-spaces;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 24px;
  }

  ${isMobileOnly &&
  css`
    font-size: 14px !important;
    line-height: 20px !important;
  `}
`

export const LandingButton = styled(ButtonBase)<{ inverse?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ inverse }) => (inverse ? '#b202ff' : 'white')};
  padding: 12px 24px;
  background: ${({ inverse }) => (inverse ? 'white' : '#b202ff')};
  border-radius: 32px;
  width: fit-content;
`
