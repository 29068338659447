import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useRef, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import {
  DataIconContainer,
  DataIcon,
  DataTitle,
  DataTitleArrow,
  DataBody,
} from './SharedStyledComponents'

const DataHeader = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 10px;
    margin: -10px;
  }
`

export const DataContainerStyled = styled.div`
  position: relative;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin-bottom: 57px;
  padding: 46px 54px;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    height: auto;
    border-radius: 5px;
    padding: 20px 30px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1280px) {
    padding: 16px 18px;
  }

  ${isMobileOnly &&
  css`
    box-shadow: none;
    padding: 0;
    margin-bottom: 0;
  `}
`

export default function ReportBlockContainer({
  icon,
  title,
  children,
  expanded = true,
  handleExpandedTab,
  isTitleLink,
  onTitleClick,
}: {
  icon: any
  title: string
  children: any
  expanded?: boolean
  handleExpandedTab?: (string) => void
  isTitleLink?: boolean
  onTitleClick?: (ev?: any) => void
}) {
  const dataRef = useRef(null)

  const handleHidden = () => {
    if (expanded) {
      dataRef.current?.classList.add('hidden')
      setTimeout(() => {
        handleExpandedTab(title)
      }, 300)
    } else {
      dataRef.current?.classList.remove('hidden')
      handleExpandedTab(title)
    }
  }

  useEffect(() => {
    dataRef.current?.classList.remove('hidden')
  }, [expanded])

  return (
    <DataContainerStyled>
      <DataHeader onClick={() => handleHidden()}>
        {icon && (
          <DataIconContainer>
            <DataIcon src={icon} />
          </DataIconContainer>
        )}
        <DataTitle
          onClick={(ev) => {
            if (isTitleLink) {
              onTitleClick(ev)
            }
          }}
          isLink={isTitleLink}
        >
          {title}
          <DataTitleArrow expanded={expanded} />
        </DataTitle>
      </DataHeader>
      <DataBody expanded={expanded} ref={dataRef}>
        {children}
      </DataBody>
    </DataContainerStyled>
  )
}

ReportBlockContainer.defaultProps = {
  expanded: false,
  handleExpandedTab: () => {},
  isTitleLink: false,
  onTitleClick: () => {},
}
