import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div<{
  show: boolean
  top?: number
  left: number
  toRight?: boolean
}>`
  position: fixed;
  display: ${({ show }) => (show ? 'inline' : 'none')};
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  background: rgba(24, 11, 49, 1);
  font-weight: 400;
  font-size: ${isMobileOnly ? 12 : 14}px;
  line-height: 17px;
  color: #fff;
  width: ${isMobileOnly ? 170 : 300}px;
  padding: 12px;
  border-radius: 4px;
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: -9px;
    left: ${({ toRight }) => (toRight ? 269 : 11)}px;
    border-bottom: 10px solid rgba(24, 11, 49, 1);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`
interface IProps {
  text: string
  id: string
  show: boolean
}

interface Position {
  x: number
  y: number
  toRight?: boolean
}

const HighLinghtedTextTip = ({ text, id, show }: IProps) => {
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 })

  useEffect(() => {
    if (show) {
      const rootText = document.getElementById(id) as HTMLDivElement
      const rect = rootText.getBoundingClientRect()
      const maxX = window.screen.width - (isMobileOnly ? 170 : 300) - 16
      const toRight = rect.left > maxX
      let x = Math.min(rect.left, maxX)
      if (!isMobileOnly && toRight) {
        x = rect.left - 300 + rect.width
      }
      const newPosition = {
        x,
        y: rect.top + 25,
        toRight,
      }
      setPosition(newPosition)
    }
  }, [show, id])
  return (
    <Container top={position.y} left={position.x} toRight={position.toRight} show={show}>
      {text}
    </Container>
  )
}

export default HighLinghtedTextTip
