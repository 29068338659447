/* eslint-disable @typescript-eslint/no-unused-expressions */

import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import useStateRef from 'react-usestateref'
import Papa from 'papaparse'

import SvgCancelUploading from '../../static/components/SvgCancelUploading'
import SvgUpload from '../../static/components/SvgUpload'
import cvIcon from '../../static/cv_icon.png'
import downloadCSV from '../../utils/downloadCSV'
import downloadXLSX from '../../utils/downloadXLSX'

import DropContainer from '../../components/DropContainer'
import ProgressBar from '../../components/ProgressBar'
import { Text, TextBold } from '../../components/Typography'

enum FileDropStep {
  Select = 'select',
  Upload = 'upload',
  Finished = 'finished',
  Error = 'error',
}

type FileData = {
  email: string
  name: string
  errors: string[]
}

const acceptedTypes = ['.csv', '.xlsx']
const fileTypes = acceptedTypes.join(', ')

const DropSelectContainer = styled.div`
  border: 1px dashed #b202ff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 96px;
  margin: 6px 0;
  padding: 10px 0;
`

const DropSelectText = styled(Text)`
  margin: 0;
  line-height: 12px;
`

const UploadText = styled(TextBold)`
  color: #b202ff;
  margin: 0;
  text-decoration: underline;
`

const DropUploadContainer = styled.div`
  align-items: center;
  background: #f5f6f8;
  border: 1px solid #e1e3ea;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  height: 96px;
  justify-content: space-between;
  margin: 6px 0;
  padding: 6px 16px;

  > div {
    flex-direction: row;
  }
`

const DropUploadIcon = styled.img`
  width: 25px;
  height: auto;
`

const ProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin: 0 16px;
`

const FileNameBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`

const FileNameText = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #222b45;
  flex: 1;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const PercentLabel = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #8f9bb3;
  margin: 0;
`

const CloseIcon = styled.div`
  width: 21px;
  height: 21px;
  cursor: pointer;
`

const ErrorText = styled(FileNameText)`
  color: #ff3d71;
`

const ReportText = styled(TextBold)`
  color: #b202ff;
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
`

const SuccessText = styled(FileNameText)`
  color: #14bb8c;
`

interface IFileDropSelect {
  onClick: () => void
  onDrop: (file) => void
}

const FileDropSelect = ({ onDrop, onClick }: IFileDropSelect) => {
  return (
    <DropSelectContainer onClick={onClick}>
      <DropContainer onFileDrop={onDrop}>
        <>
          <SvgUpload />
          <DropSelectText>Upload your file here</DropSelectText>
          <UploadText>Upload</UploadText>
        </>
      </DropContainer>
    </DropSelectContainer>
  )
}

interface IFileDropUpload {
  file
  ignoreNameValidation: boolean
  onCancel: () => void
  onError: (error: string, errorData?: FileData[]) => void
  onSuccess: (data: FileData[]) => void
}

const FileDropUpload = ({
  file,
  ignoreNameValidation,
  onSuccess,
  onError,
  onCancel,
}: IFileDropUpload) => {
  const [progress, setProgress, progressRef] = useStateRef(0)
  const timeoutRef = useRef(null)

  const onCancelUploading = () => {
    clearInterval(timeoutRef.current)
    setProgress(0)
    onCancel()
  }

  const validateFile = ({ data }) => {
    const validatedData: FileData[] = data.map((i) => {
      const errors = []
      if (!i.email) errors.push("The applicant's email can’t be blank")

      if (
        !!i.email &&
        !i.email.trim().match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/)
      )
        errors.push('Invalid email format')

      if (!ignoreNameValidation) {
        if (!i.name) errors.push("The applicant's name can’t be blank")
        else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [firstName, ...lastName] = i.name.trim().split(' ')
          if (lastName.filter((p) => !!p).length === 0)
            errors.push('Please include both first and last names for the applicant')
        }
      }

      return { ...i, errors }
    })

    const errorsCount = validatedData.map((i) => i.errors).flat().length

    if (!errorsCount) onSuccess(validatedData)
    else {
      const error = `${errorsCount} errors found, fix the errors and upload again`
      onError(error, validatedData)
    }
  }

  useEffect(() => {
    timeoutRef.current = setInterval(async () => {
      if (progressRef.current === 1) {
        clearInterval(timeoutRef.current)

        Papa.parse<string[]>(file, {
          header: true,
          worker: true, // use a web worker so that the page doesn't hang up
          complete({ data }) {
            validateFile({
              data: data.map((r) => ({
                email: r['Applicant email'],
                name: r['Applicant name'],
              })),
            })
          },
        })
      } else setProgress(progressRef.current + 0.25)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DropUploadContainer>
      <DropContainer onFileDrop={undefined}>
        <>
          <DropUploadIcon src={cvIcon} />
          <ProgressBlock>
            <FileNameBlock>
              <FileNameText>{file.name}</FileNameText>
              <PercentLabel>{progress * 100}%</PercentLabel>
            </FileNameBlock>
            <ProgressBar progress={progress} />
          </ProgressBlock>
          <CloseIcon onClick={onCancelUploading}>
            <SvgCancelUploading />
          </CloseIcon>
        </>
      </DropContainer>
    </DropUploadContainer>
  )
}

interface IFileDropError {
  error: string
  errorData: FileData[]
  file
  ignoreNameValidation: boolean
  onCancel: () => void
  onDrop: (file) => void
}

const FileDropError = ({
  error,
  errorData,
  file,
  ignoreNameValidation,
  onCancel,
  onDrop,
}: IFileDropError) => {
  const downloadErrorReport = () => {
    const fileData = `Applicant email,Applicant name${
      ignoreNameValidation ? ' (optional)' : ''
    },Error reason${errorData.reduce(
      (acc, i) => `${acc}\n${[i.email, i.name, `"${i.errors.join(',')}"`].join(',')}`,
      ''
    )}`

    file.type === 'text/csv'
      ? downloadCSV(fileData, 'Wizco_applicants_bulk_upload_template_error_report')
      : downloadXLSX(fileData, 'Wizco_applicants_bulk_upload_template_error_report')
  }

  return (
    <DropUploadContainer>
      <DropContainer onFileDrop={onDrop}>
        <>
          <DropUploadIcon src={cvIcon} />
          <ProgressBlock>
            <FileNameBlock>
              <FileNameText>{file.name}</FileNameText>
            </FileNameBlock>
            <ErrorText>{error}</ErrorText>
            <ReportText onClick={downloadErrorReport}>Download the report</ReportText>
          </ProgressBlock>
          <CloseIcon onClick={onCancel}>
            <SvgCancelUploading />
          </CloseIcon>
        </>
      </DropContainer>
    </DropUploadContainer>
  )
}

interface IFileDropFinished {
  file
  onCancel: () => void
  onDrop: (file) => void
}

const FileDropFinished = ({ file, onCancel, onDrop }: IFileDropFinished) => {
  return (
    <DropUploadContainer>
      <DropContainer onFileDrop={onDrop}>
        <>
          <DropUploadIcon src={cvIcon} />
          <ProgressBlock>
            <FileNameBlock>
              <FileNameText>{file.name}</FileNameText>
            </FileNameBlock>
            <SuccessText>Successfully uploaded</SuccessText>
          </ProgressBlock>
          <CloseIcon onClick={onCancel}>
            <SvgCancelUploading />
          </CloseIcon>
        </>
      </DropContainer>
    </DropUploadContainer>
  )
}

interface IFileDrop {
  ignoreNameValidation: boolean
  onUpdate: ({ file, data }) => void
}

export default function FileDrop({ ignoreNameValidation, onUpdate }: IFileDrop) {
  const [data, setData] = useStateRef<FileData[]>(undefined)
  const [error, setError] = useStateRef<string>(undefined)
  const [file, setFile] = useStateRef(null)
  const [step, setStep] = useStateRef(FileDropStep.Select)

  const inputRef = React.useRef(null)

  const handleCancel = () => {
    inputRef.current.value = ''
    onUpdate(undefined)
    setStep(FileDropStep.Select)
    setError(undefined)
    setData(undefined)
    setFile(undefined)
  }

  const handleError = (e: string, errorData?: FileData[]) => {
    onUpdate(undefined)
    setError(e)
    setData(errorData)
    setStep(FileDropStep.Error)
  }

  const handleSelect = (f) => {
    onUpdate(undefined)
    setFile(f)
    setStep(FileDropStep.Upload)
  }

  const handleUpload = (fileData: FileData[]) => {
    setData(fileData)
    setStep(FileDropStep.Finished)
    onUpdate({
      file,
      data: fileData.map((d) => ({
        email: d.email?.trim(),
        name: d.name?.trim(),
      })),
    })
  }

  const handleInputCLick = () => {
    inputRef.current.click()
  }

  const handleFileDrop = (f) => {
    const fileExtension = `.${/(?:\.([^.]+))?$/.exec(f.name)[1]}`
    acceptedTypes.includes(fileExtension)
      ? handleSelect(f)
      : handleError('Invalid filetype')
  }

  const stepComponent = (currentStep) => {
    switch (currentStep) {
      case FileDropStep.Select:
        return <FileDropSelect onDrop={handleFileDrop} onClick={handleInputCLick} />
      case FileDropStep.Upload:
        return (
          <FileDropUpload
            file={file}
            ignoreNameValidation={ignoreNameValidation}
            onSuccess={handleUpload}
            onError={handleError}
            onCancel={handleCancel}
          />
        )
      case FileDropStep.Error:
        return (
          <FileDropError
            error={error}
            errorData={data}
            file={file}
            ignoreNameValidation={ignoreNameValidation}
            onDrop={handleFileDrop}
            onCancel={handleCancel}
          />
        )
      case FileDropStep.Finished:
        return (
          <FileDropFinished file={file} onDrop={handleFileDrop} onCancel={handleCancel} />
        )
      default:
        return undefined
    }
  }

  return (
    <>
      {stepComponent(step)}
      <input
        type="file"
        accept={fileTypes}
        ref={inputRef}
        hidden
        onChange={(e) => handleSelect(e.target.files[0])}
      />
    </>
  )
}
