import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isSuperAdminRole } from 'src/utils/jwtToken'

import Layout from '../Layout'
import * as adminSelectors from '../selectors'
import { getAgencies } from '../actions'
import AgenciesList from '../components/AgenciesList'

function AdminContainer() {
  const dispatch = useDispatch()
  const isLoading = useSelector(adminSelectors.agencies.isLoading)
  const agencies = useSelector(adminSelectors.agencies.agencies)

  const isSuperAdmin = isSuperAdminRole()

  useEffect(() => {
    dispatch(getAgencies())
  }, [dispatch])

  return (
    <Layout showRightBar>
      <>
        {isLoading && <div>loading...</div>}
        {!isLoading && agencies && (
          <AgenciesList isSuperAdmin={isSuperAdmin} agencies={agencies} />
        )}
      </>
    </Layout>
  )
}

export default AdminContainer
