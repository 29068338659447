import { reduxDataFactory } from '../../utils/redux'

export interface IBookingInfo {
  count?: number
  email?: string
  firstName?: string
  lastName?: string
  token?: string
}

const {
  action: updateBookingInfoAction,
  actionType: updateBookingInfoActionType,
  reducer: bookingInfoDataReducer,
  selectors: bookingInfoSelectors,
} = reduxDataFactory<IBookingInfo>('bookingInfo')

const clearBookingInfoAction = updateBookingInfoAction(undefined)

export {
  clearBookingInfoAction,
  updateBookingInfoAction,
  updateBookingInfoActionType,
  bookingInfoDataReducer,
  bookingInfoSelectors,
}
