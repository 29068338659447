import { reduxRequestFactory } from '../utils/redux'

import {
  ICandidateProfile,
  IExpertProfile,
  IInterviewCandidateState,
  IInterviewInternalExpert,
  IInterviewPositionState,
} from '../api'

export interface IUpdateOrderInfoPayload {
  agencyId: string
  id: string
  student?: IInterviewCandidateState
  position?: IInterviewPositionState
  codingSessionUrl?: string
  codingPlaybackUrl?: string
  cv_file_id?: string
  interview_internal_experts_attributes?: IInterviewInternalExpert[]
}

export interface IUpdateAvailabilityInfoPayload {
  agencyId: string
  id: string
  candidate?: ICandidateProfile
  experts?: IExpertProfile[]
  use_general_link?: boolean
  enable_conversation_intelligence?: boolean
}

export interface IUpdateSchedulingInfoPayload {
  agencyId: string
  id: string
  interview?: {
    main_time_slot_id?: string
    expert_confirmed?: boolean
    expert_passed_training?: boolean
  }
  reschedule_request: {
    comment?: string
  }
}

export interface IUpdatePostSessionInfoPayload {
  agencyId: string
  id: string
  hired_candidate?: string
  expert_paid?: boolean
  expert_payment_provider?: string
  expert_payment_reference?: string
}

const {
  action: updateOrderInfoAction,
  actionFailure: updateOrderInfoActionFailure,
  actionSuccess: updateOrderInfoActionSuccess,
  actionSuccessClear: updateOrderInfoActionSuccessClear,
  actionFailureClear: updateOrderInfoActionFailureClear,
  actionType: updateOrderInfoActionType,
  actionTypeFailure: updateOrderInfoActionFailureType,
  actionTypeSuccess: updateOrderInfoActionSuccessType,
  reducer: updateOrderInfoReducer,
  selectors: updateOrderInfoSelectors,
} = reduxRequestFactory<IUpdateOrderInfoPayload>('updateOrderInfo', 'agency')

const {
  action: updateAvailabilityInfoAction,
  actionFailure: updateAvailabilityInfoActionFailure,
  actionSuccess: updateAvailabilityInfoActionSuccess,
  actionSuccessClear: updateAvailabilityInfoActionSuccessClear,
  actionFailureClear: updateAvailabilityInfoActionFailureClear,
  actionType: updateAvailabilityInfoActionType,
  actionTypeFailure: updateAvailabilityInfoActionFailureType,
  actionTypeSuccess: updateAvailabilityInfoActionSuccessType,
  reducer: updateAvailabilityInfoRedcuer,
  selectors: updateAvailabilityInfoSelectors,
} = reduxRequestFactory<IUpdateAvailabilityInfoPayload>(
  'updateAvailabilityInfo',
  'agency'
)

const {
  action: updateSchedulingInfoAction,
  actionFailure: updateSchedulingInfoActionFailure,
  actionSuccess: updateSchedulingInfoActionSuccess,
  actionSuccessClear: updateSchedulingInfoActionSuccessClear,
  actionFailureClear: updateSchedulingInfoActionFailureClear,
  actionType: updateSchedulingInfoActionType,
  actionTypeFailure: updateSchedulingInfoActionFailureType,
  actionTypeSuccess: updateSchedulingInfoActionSuccessType,
  reducer: updateSchedulingInfoReducer,
  selectors: updateSchedulingInfoSelectors,
} = reduxRequestFactory<IUpdateSchedulingInfoPayload>('updateSchedulingInfo', 'agency')

const {
  action: updatePostSessionInfoAction,
  actionFailure: updatePostSessionInfoActionFailure,
  actionSuccess: updatePostSessionInfoActionSuccess,
  actionSuccessClear: updatePostSessionInfoActionSuccessClear,
  actionFailureClear: updatePostSessionInfoActionFailureClear,
  actionType: updatePostSessionInfoActionType,
  actionTypeFailure: updatePostSessionInfoActionFailureType,
  actionTypeSuccess: updatePostSessionInfoActionSuccessType,
  reducer: updatePostSessionInfoReducer,
  selectors: updatePostSessionInfoSelectors,
} = reduxRequestFactory<IUpdatePostSessionInfoPayload>('updatePostSessionInfo', 'agency')

export {
  updateOrderInfoAction,
  updateOrderInfoActionFailure,
  updateOrderInfoActionSuccess,
  updateOrderInfoActionSuccessClear,
  updateOrderInfoActionFailureClear,
  updateOrderInfoActionType,
  updateOrderInfoActionFailureType,
  updateOrderInfoActionSuccessType,
  updateOrderInfoReducer,
  updateOrderInfoSelectors,
  updateAvailabilityInfoAction,
  updateAvailabilityInfoActionFailure,
  updateAvailabilityInfoActionSuccess,
  updateAvailabilityInfoActionSuccessClear,
  updateAvailabilityInfoActionFailureClear,
  updateAvailabilityInfoActionType,
  updateAvailabilityInfoActionFailureType,
  updateAvailabilityInfoActionSuccessType,
  updateAvailabilityInfoRedcuer,
  updateAvailabilityInfoSelectors,
  updateSchedulingInfoAction,
  updateSchedulingInfoActionFailure,
  updateSchedulingInfoActionSuccess,
  updateSchedulingInfoActionSuccessClear,
  updateSchedulingInfoActionFailureClear,
  updateSchedulingInfoActionType,
  updateSchedulingInfoActionFailureType,
  updateSchedulingInfoActionSuccessType,
  updateSchedulingInfoReducer,
  updateSchedulingInfoSelectors,
  updatePostSessionInfoAction,
  updatePostSessionInfoActionFailure,
  updatePostSessionInfoActionSuccess,
  updatePostSessionInfoActionSuccessClear,
  updatePostSessionInfoActionFailureClear,
  updatePostSessionInfoActionType,
  updatePostSessionInfoActionFailureType,
  updatePostSessionInfoActionSuccessType,
  updatePostSessionInfoReducer,
  updatePostSessionInfoSelectors,
}
