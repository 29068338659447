import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: ${isMobileOnly ? 10 : 33}px;
`

const Text = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  color: #200e32;
`

const IndexText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  color: #200e32;
`

const TransparentText = styled.span`
  opacity: 0.5;
`

interface IProps {
  index: number
  count: number
  indexText?: string
}

const QuestionCounter = ({ index, indexText, count }: IProps) => {
  const getNumberText = (num: number) => {
    return `${num < 10 ? '0' : ''}${num}`
  }

  return (
    <Container>
      <Text>
        {`${getNumberText(index + 1)}`}
        {indexText && <IndexText> {indexText}</IndexText>}
        <TransparentText>/{`${getNumberText(count)}`}</TransparentText>
      </Text>
    </Container>
  )
}

QuestionCounter.defaultProps = {
  indexText: '',
}

export default QuestionCounter
