import McKinsey from './ic-ad-banner1.png'
import JPMorgan from './ic-ad-banner2.png'
import Microsoft from './ic-ad-banner3.png'
import Citi from './ic-ad-banner4.png'
import Uber from './ic-ad-banner5.png'
import Robinhood from './ic-ad-banner6.png'
import TikTok from './ic-ad-banner7.png'
import UiPath from './ic-ad-banner8.png'
import Walmart from './ic-ad-banner9.png'
import Disney from './ic-ad-banner10.png'

import PayPal from './ic-ad-banner11.png'
import Tesla from './ic-ad-banner12.png'
import Nvidia from './ic-ad-banner13.png'
import Visa from './ic-ad-banner14.png'
import JohnsonJohnson from './ic-ad-banner15.png'
import BankOfAmerica from './ic-ad-banner16.png'
import Slack from './ic-ad-banner17.png'
import Meta from './ic-ad-banner18.png'
import Netflix from './ic-ad-banner19.png'
import Amazon from './ic-ad-banner20.png'

import Apple from './ic-ad-banner21.png'
import Google from './ic-ad-banner22.png'
import SalesForce from './ic-ad-banner23.png'
import BCG from './ic-ad-banner24.png'
import StitchFix from './ic-ad-banner25.png'
import Klarna from './ic-ad-banner26.png'
import PG from './ic-ad-banner27.png'
import Abbvie from './ic-ad-banner28.png'
import Pfizer from './ic-ad-banner29.png'
import CocaCola from './ic-ad-banner30.png'

import WellsFargo from './ic-ad-banner31.png'
import Airbnb from './ic-ad-banner32.png'

import Somerset from './ic-ad-banner33.png'
import LiroGroup from './ic-ad-banner34.png'
import Unfi from './ic-ad-banner35.png'
import B8451 from './ic-ad-banner-8451.png'
import Aep from './ic-ad-banner-aep.png'
import Arista from './ic-ad-banner-arista.png'
import Cisco from './ic-ad-banner-cisco.png'
import Danaher from './ic-ad-banner-danaher.png'
import Deliotte from './ic-ad-banner-deloitte.png'
import Delta from './ic-ad-banner-delta.png'
import Fedex from './ic-ad-banner-fedex.png'
import Kone from './ic-ad-banner-kone.png'
import Mondo from './ic-ad-banner-mondo.png'
import Mutual from './ic-ad-banner-mutual.png'
import Pwc from './ic-ad-banner-pwc.png'
import Rabo from './ic-ad-banner-rabo.png'
import Rippling from './ic-ad-banner-rippling.png'
import TotoRooter from './ic-ad-banner-totorooter.png'
import WelsFargo from './ic-ad-banner-wels-fargo.png'
import Pierpont from './ic-ad-banner-pierpont.png'
import Forte from './ic-ad-banner-forte.png'

export default {
  McKinsey,
  JPMorgan,
  Microsoft,
  Citi,
  Uber,
  Robinhood,
  TikTok,
  UiPath,
  Walmart,
  Disney,
  PayPal,
  Tesla,
  Nvidia,
  Visa,
  JohnsonJohnson,
  BankOfAmerica,
  Slack,
  Meta,
  Netflix,
  Amazon,
  Apple,
  Google,
  SalesForce,
  BCG,
  StitchFix,
  Klarna,
  PG,
  Abbvie,
  Pfizer,
  CocaCola,
  WellsFargo,
  Airbnb,
  Somerset,
  LiroGroup,
  Unfi,
  B8451,
  Aep,
  Arista,
  Cisco,
  Danaher,
  Deliotte,
  Delta,
  Fedex,
  Kone,
  Mondo,
  Mutual,
  Pwc,
  Rabo,
  Rippling,
  TotoRooter,
  WelsFargo,
  Forte,
  Pierpont,
}
