/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStateRef from 'react-usestateref'
import { Symbl, StreamingAPIConnection } from '@symblai/symbl-web-sdk'
import {
  conversationTokenSelectors,
  generateConversationTokenAction,
  generateConversationTokenSelectors,
} from '../../interview/redux'

const useSymbl = (
  userId: string,
  userName: string,
  interviewId: string,
  isExpert: boolean,
  silent: boolean,
  onRecognition?: (text: string, isFinal: boolean) => void,
  onDisconnected?: () => void
) => {
  const dispatch = useDispatch()
  const [conversationId, setConversationId] = useStateRef('')
  const [tokenGenerated, setTokenGenerated] = useState(false)
  const [isConnected, setIsConnected] = useState(false)

  const firstResult = useRef(true)

  const token = useSelector(conversationTokenSelectors.data)
  // const tokenGeneratedSuccess = useSelector(generateConversationTokenSelectors.success)
  const uniqueMeetingId = btoa(interviewId)

  const connection = useRef<StreamingAPIConnection>(null)

  const log = (title: string, data?: any) => {
    // eslint-disable-next-line no-console
    console.log(`[SYMBL][${title}]`, data ?? '')
  }

  const insightTypes: string[] = []
  if (isExpert) {
    insightTypes.push('question')
  }

  const config = {
    insightTypes,
    noConnectionTimeout: 600,
    disconnectOnStopRequest: false,
    disconnectOnStopRequestTimeout: 1800,
    config: {
      meetingTitle: 'Wizco meeting',
      languageCode: 'en-US',
      // encoding: 'LINEAR16',
      // sampleRateHertz: sampleRate,
    },
    speaker: {
      userId,
      name: userName,
    },
  }

  const handleSpeechRecognition = (speechData) => {
    const { punctuated, isFinal } = speechData
    if (!connection.current.isProcessing()) {
      log(`speech_recognition_${isFinal}`, '** ignored [mute] **')
      return
    }
    if (firstResult.current && isFinal) {
      // ignore
      log(`speech_recognition_${isFinal}`, '** ignored [first_final] **')
      firstResult.current = false
      return
    }
    firstResult.current = false
    log(`speech_recognition_${isFinal}`, punctuated)
    if (onRecognition) {
      onRecognition(punctuated.transcript, isFinal)
    }
  }

  const refreshConnectionState = () => {
    setIsConnected(connection.current.isProcessing() && connection.current.isConnected())
  }

  const runSymbl = async () => {
    log('run')
    if (silent) {
      log('silent mode. skipped')
      return
    }
    log('is expert', isExpert)
    if (connection.current) {
      log('is already run')
      return
    }
    log('token', token)
    const symbl = new Symbl({
      accessToken: token?.accessToken ?? 'wizco_default_token',
      reconnectOnError: true,
    })
    connection.current = await symbl.createConnection(uniqueMeetingId)
    connection.current.on('speech_recognition', handleSpeechRecognition)
    connection.current.on('error', (data) => {
      log(`error`, data)
    })
    connection.current.on('conversation_created', (data) => {
      setConversationId(data.data.conversationId)
      log(`conversation_created`, data.data.conversationId)
    })
    connection.current.on('offline', () => {
      if (onDisconnected) {
        onDisconnected()
      }
      log('offline')
    })
    connection.current.on('processing_stopped', () => {
      log('processing_stopped')
      refreshConnectionState()
    })
    connection.current.on('processing_started', () => {
      log('processing_started')
      refreshConnectionState()
    })
    connection.current.on('connected', () => {
      log('connected')
      refreshConnectionState()
    })
    connection.current.on('disconnected', () => {
      log('disconnected')
      refreshConnectionState()
    })
    await connection.current.startProcessing(config)
  }

  const stopSymbl = async () => {
    if (connection.current && connection.current.isConnected()) {
      await connection.current.stopProcessing()
      const cnct = connection.current as any
      cnct?.audioStream.mediaStream?.getTracks().forEach((track) => {
        track.stop()
      })
      connection.current?.disconnect()
      connection.current = null
      firstResult.current = true
    }
  }

  useEffect(() => {
    return () => {
      log('[stop]')
      stopSymbl()
    }
  }, [])

  useEffect(() => {
    if (token) {
      log('tokenGeneratedSuccess', token)
      setTokenGenerated(true)
    }
  }, [token])

  const changeSymblDevice = (deviceId: string) => {
    if (connection.current) {
      log('change device', deviceId)
      const cnct = connection.current as any
      cnct.audioStream
        .updateAudioDevice(deviceId)
        .then(() => {
          log('device has been change')
        })
        .catch((err) => {
          log('device change error', err)
        })
    }
  }

  const muteSymbl = async (mute: boolean) => {
    log('mute', mute)
    if (!connection.current) return
    if (mute) {
      firstResult.current = true
      await connection.current.stopProcessing()
      await connection.current.disconnect()
    } else {
      await connection.current.connect()
      await connection.current.startProcessing()
    }

    // const cnct = connection.current as any
    // const { audioStream } = cnct
    // const { mediaStream } = audioStream
    // const audioTracks = mediaStream.getAudioTracks()
    // if (audioTracks?.length > 0) {
    //   audioTracks.forEach((tr) => {
    //     // eslint-disable-next-line no-param-reassign
    //     tr.enabled = !mute
    //   })
    // }
  }

  useEffect(() => {
    if (!silent) {
      dispatch(generateConversationTokenAction())
    } else {
      log('Silent mode')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    conversationId,
    tokenGenerated,
    isConnected,
    runSymbl,
    stopSymbl,
    muteSymbl,
    changeSymblDevice,
  }
}

export default useSymbl
