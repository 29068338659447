import {
  IAgency,
  ExpertDeclineReason,
  IGetInterviewResponse,
  IUpdateInterviewPayload,
  ICreateInterviewPayload,
  IInterviewPositionState,
  IClientSecret,
} from '../api'
import { IFailure } from '../utils/redux'

export const SAVE_BOOKING_POSITION = 'INTERVIEW/SAVE_BOOKING_POSITION'
export const UPDATE_BOOKING = 'INTERVIEW/UPDATE_BOOKING'
export const UPDATE_BOOKING_SUCCESS = 'INTERVIEW/UPDATE_BOOKING_SUCCESS'
export const UPDATE_BOOKING_FAILURE = 'INTERVIEW/UPDATE_BOOKING_FAILURE'
export const CREATE_BOOKING_FAILURE_CLEAR = 'INTERVIEW/CREATE_BOOKING_FAILURE_CLEAR'
export const CREATE_STUDENT_DETAILS = 'INTERVIEW/CREATE_STUDENT_DETAILS'
export const CREATE_STUDENT_DETAILS_SUCCESS = 'INTERVIEW/CREATE_STUDENT_DETAILS_SUCCESS'
export const CREATE_STUDENT_DETAILS_FAILURE = 'INTERVIEW/CREATE_STUDENT_DETAILS_FAILURE'
export const GET_INTERVIEW = 'INTERVIEW/GET_INTERVIEW'
export const GET_INTERVIEW_STATUS = 'INTERVIEW/GET_INTERVIEW_STATUS'
export const GET_INTERVIEW_SUCCESS = 'INTERVIEW/GET_INTERVIEW_SUCCESS'
export const GET_INTERVIEW_FAILURE = 'INTERVIEW/GET_INTERVIEW_FAILURE'
export const GET_AGENCY_BY_SUBDOMAIN = 'INTERVIEW/GET_AGENCY_BY_SUBDOMAIN'
export const GET_AGENCY_BY_SUBDOMAIN_SUCCESS = 'INTERVIEW/GET_AGENCY_BY_SUBDOMAIN_SUCCESS'
export const GET_AGENCY_BY_SUBDOMAIN_FAILURE = 'INTERVIEW/GET_AGENCY_BY_SUBDOMAIN_FAILURE'
export const INTERVIEW_CODE_SHARING = 'INTERVIEW/INTERVIEW_CODE_SHARING'
export const START_INTERVIEW = 'INTERVIEW/START_INTERVIEW'
export const START_INTERVIEW_SUCCESS = 'INTERVIEW/START_INTERVIEW_SUCCESS'
export const START_INTERVIEW_FAILURE = 'INTERVIEW/START_INTERVIEW_FAILURE'
export const END_INTERVIEW = 'INTERVIEW/END_INTERVIEW'
export const END_INTERVIEW_SUCCESS = 'INTERVIEW/END_INTERVIEW_SUCCESS'
export const END_INTERVIEW_FAILURE = 'INTERVIEW/END_INTERVIEW_FAILURE'
export const REPORT_INTERVIEW = 'INTERVIEW/REPORT_INTERVIEW'
export const REPORT_INTERVIEW_SUCCESS = 'INTERVIEW/REPORT_INTERVIEW_SUCCESS'
export const REPORT_INTERVIEW_FAILURE = 'INTERVIEW/REPORT_INTERVIEW_FAILURE'
export const ASSIGN_TIME_SLOT = 'INTERVIEW/ASSIGN_TIME_SLOT'
export const ASSIGN_TIME_SLOT_SUCCESS = 'INTERVIEW/ASSIGN_TIME_SLOT_SUCCESS'
export const ASSIGN_TIME_SLOT_FAILURE = 'INTERVIEW/ASSIGN_TIME_SLOT_FAILURE'
export const ASSIGN_TIME_SLOT_SUCCESS_CLEAR = 'INTERVIEW/ASSIGN_TIME_SLOT_SUCCESS_CLEAR'
export const ASSIGN_TIME_SLOT_FAILURE_CLEAR = 'INTERVIEW/ASSIGN_TIME_SLOT_FAILURE_CLEAR'
export const CREATE_INTENT = 'INTERVIEW/CREATE_INTENT'
export const CREATE_INTENT_SUCCESS = 'INTERVIEW/CREATE_INTENT_SUCCESS'
export const CREATE_INTENT_FAILURE = 'INTERVIEW/CREATE_INTENT_FAILURE'
export const CREATE_INTENT_SUCCESS_CLEAR = 'INTERVIEW/CREATE_INTENT_SUCCESS_CLEAR'
export const CREATE_INTENT_FAILURE_CLEAR = 'INTERVIEW/CREATE_INTENT_FAILURE_CLEAR'
export const CREATE_PAYMENT = 'INTERVIEW/CREATE_PAYMENT'
export const CREATE_PAYMENT_SUCCESS = 'INTERVIEW/CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'INTERVIEW/CREATE_PAYMENT_FAILURE'
export const CREATE_PAYMENT_SUCCESS_CLEAR = 'INTERVIEW/CREATE_PAYMENT_SUCCESS_CLEAR'
export const CREATE_PAYMENT_FAILURE_CLEAR = 'INTERVIEW/CREATE_PAYMENT_FAILURE_CLEAR'
export const PAY_SUCCEED = 'INTERVIEW/PAY_SUCCEED'
export const PAY_SUCCEED_SUCCESS = 'INTERVIEW/PAY_SUCCEED_SUCCESS'
export const PAY_SUCCEED_FAILURE = 'INTERVIEW/PAY_SUCCEED_FAILURE'
export const PAY_SUCCEED_SUCCESS_CLEAR = 'INTERVIEW/PAY_SUCCEED_SUCCESS_CLEAR'
export const PAY_SUCCEED_FAILURE_CLEAR = 'INTERVIEW/PAY_SUCCEED_FAILURE_CLEAR'
export const SET_PARTICIPANT = 'INTERVIEW/SET_PARTICIPANT'
export const SUBMIT_AVAILABILITY = 'INTERVIEW/SUBMIT_AVAILABILITY'
export const SUBMIT_AVAILABILITY_SUCCESS = 'INTERVIEW/SUBMIT_AVAILABILITY_SUCCESS'
export const SUBMIT_AVAILABILITY_FAILURE = 'INTERVIEW/SUBMIT_AVAILABILITY_FAILURE'
export const DECLINE_AVAILABILITY = 'INTERVIEW/DECLINE_AVAILABILITY'
export const DECLINE_AVAILABILITY_SUCCESS = 'INTERVIEW/DECLINE_AVAILABILITY_SUCCESS'
export const DECLINE_AVAILABILITY_FAILURE = 'INTERVIEW/DECLINE_AVAILABILITY_FAILURE'
export const PREVENT_INTERVIEW_REDIRECT = 'INTERVIEW/PREVENT_INTERVIEW_REDIRECT'

export interface ISaveBookingPositionAction {
  type: typeof SAVE_BOOKING_POSITION
  payload: {
    position: IInterviewPositionState
    interviewToken: string
  }
}

export interface IUpdateBookingAction {
  type: typeof UPDATE_BOOKING
  payload: IUpdateInterviewPayload
}

export interface IUpdateStudentDetailsSuccessAction {
  type: typeof UPDATE_BOOKING_SUCCESS
  payload: {
    position: IInterviewPositionState
    interview: IGetInterviewResponse
  }
}

export interface IUpdateStudentDetailsFailureAction {
  type: typeof UPDATE_BOOKING_FAILURE
  payload: IFailure
}

export interface ICreateBookingAction {
  type: typeof CREATE_STUDENT_DETAILS
  payload: ICreateInterviewPayload
}

export interface ICreateStudentDetailsSuccessAction {
  type: typeof CREATE_STUDENT_DETAILS_SUCCESS
  payload: {
    position: IInterviewPositionState
    interview: IGetInterviewResponse
  }
}

export interface ICreateStudentDetailsFailureAction {
  type: typeof CREATE_STUDENT_DETAILS_FAILURE
  payload: IFailure
}

export interface IGetInterviewAction {
  type: typeof GET_INTERVIEW
  payload: {
    interviewToken: string
  }
}

export interface IGetInterviewStatusAction {
  type: typeof GET_INTERVIEW_STATUS
  payload: {
    interviewToken: string
  }
}

export interface IGetInterviewSuccessAction {
  type: typeof GET_INTERVIEW_SUCCESS
  payload: {
    interview: IGetInterviewResponse
  }
}

export interface IGetInterviewFailureAction {
  type: typeof GET_INTERVIEW_FAILURE
  payload: IFailure
}

export interface IGetAgencyBySubdomainAction {
  type: typeof GET_AGENCY_BY_SUBDOMAIN
  payload: {
    subdomain: string
  }
}

export interface IGetAgencyBySubdomainSuccessAction {
  type: typeof GET_AGENCY_BY_SUBDOMAIN_SUCCESS
  payload: {
    agency: IAgency
  }
}

export interface IGetAgencyBySubdomainFailureAction {
  type: typeof GET_AGENCY_BY_SUBDOMAIN_FAILURE
  payload: IFailure
}

export interface IEndInterviewAction {
  type: typeof END_INTERVIEW
  payload: {
    interviewToken: string
    filename?: string
    quiet?: boolean
  }
}

export interface IEndInterviewSuccessAction {
  type: typeof END_INTERVIEW_SUCCESS
  payload: {
    interview: IGetInterviewResponse
  }
}

export interface IEndInterviewFailureAction {
  type: typeof END_INTERVIEW_FAILURE
  payload: IFailure
}

export interface IReportInterviewAction {
  type: typeof REPORT_INTERVIEW
  payload: {
    interviewToken: string
    responseId: string
  }
}

export interface IReportInterviewSuccessAction {
  type: typeof REPORT_INTERVIEW_SUCCESS
  payload: {
    interview: IGetInterviewResponse
  }
}

export interface IReportInterviewFailureAction {
  type: typeof REPORT_INTERVIEW_FAILURE
  payload: IFailure
}

export interface IStartInterviewAction {
  type: typeof START_INTERVIEW
  payload: {
    interviewToken: string
  }
}

export interface IInterviewCodeSharingAction {
  type: typeof INTERVIEW_CODE_SHARING
  payload: {
    interviewToken: string
  }
}

export interface IStartInterviewSuccessAction {
  type: typeof START_INTERVIEW_SUCCESS
  payload: {
    interview: IGetInterviewResponse
  }
}

export interface IStartInterviewFailureAction {
  type: typeof START_INTERVIEW_FAILURE
  payload: IFailure
}

export interface ICreateRoomAction {
  type: typeof START_INTERVIEW
  payload: {
    token: string
    interviewToken: string
  }
}

export interface ICreateRoomSuccessAction {
  type: typeof START_INTERVIEW_SUCCESS
  payload: {
    interview: IGetInterviewResponse
  }
}

export interface ICreateRoomFailureAction {
  type: typeof START_INTERVIEW_FAILURE
  payload: IFailure
}

export interface IAssignTimeSlotAction {
  type: typeof ASSIGN_TIME_SLOT
  payload: {
    interviewToken: string
    timezone: string
    slotId: string
  }
}

export interface ICreateIntentAction {
  type: typeof CREATE_INTENT
  payload: {
    interviewToken: string
  }
}

export interface IPaySucceedAction {
  type: typeof PAY_SUCCEED
  payload: {
    id: string
    interviewToken: string
    payment_method: string
  }
}

export interface ICreateIntentSuccessAction {
  type: typeof CREATE_INTENT_SUCCESS
  payload: {
    clientSecret: IClientSecret
  }
}
export interface ICreateIntentFailureAction {
  type: typeof CREATE_INTENT_FAILURE
  payload: IFailure
}

export interface ICreatePaymentFailureAction {
  type: typeof CREATE_INTENT_FAILURE
  payload: IFailure
}

export interface IPaySucceedFailureAction {
  type: typeof PAY_SUCCEED_FAILURE
  payload: IFailure
}

export enum SelectingState {
  Select,
  Success,
  Decline,
  Expire,
}

export interface ISubmitAvailabilityAction {
  type: typeof SUBMIT_AVAILABILITY
  payload: {
    expertComment?: string
    expertToken: string
    timeSlots: number[]
  }
}

export interface ISubmitAvailabilitySuccessAction {
  type: typeof SUBMIT_AVAILABILITY_SUCCESS
  payload: null
}

export interface ISubmitAvailabilityFailureAction {
  type: typeof SUBMIT_AVAILABILITY_FAILURE
  payload: IFailure
}

export interface IDeclineAvailabilityAction {
  type: typeof DECLINE_AVAILABILITY
  payload: {
    expertToken: string
    expertComment?: string
    declineReason?: ExpertDeclineReason
  }
}

export interface IDeclineAvailabilitySuccessAction {
  type: typeof DECLINE_AVAILABILITY_SUCCESS
  payload: null
}

export interface IDeclineAvailabilityFailureAction {
  type: typeof DECLINE_AVAILABILITY_FAILURE
  payload: IFailure
}
