import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isSuperAdminRole } from 'src/utils/jwtToken'
import summaryIcon from 'src/static/report_summary.svg'
import mIcon from 'src/static/report_ii.svg'
import { useSelector } from 'react-redux'
import {
  avaInterviewSelectors,
  conversationSelectors,
  systemSettingsSelectors,
} from 'src/redux'
import useReport from 'src/report/services/useReport'
import { formattedDate } from 'src/utils/date'
import AvaReportBlockContainer from './AvaReportBlockContainer'
import { ReportSubtitleText, ReportTitle } from './report-styled-components'
import AvaInsightsBlock from './AvaInsightsBlock'
import BlockIcon from './BlockIcon'

const Container = styled.div``

const LineContainer = styled.div`
  display: flex;
  width: 100%;
`

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const LinksContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: max-content;
  top: 81px;
  left: 98px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px grey;
  border-radius: 4px;
  border: solid 1px white;
  background: white;
`

const LinkItem = styled.div`
  font-size: 12px;
  background: white;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f5f6f8;
  }
`

enum Link {
  Report = 'Report',
  Transcript = 'Transcript',
  Attitude = 'Attitude',
}

const Links = [Link.Report, Link.Transcript, Link.Attitude]

const InsightsComponent = () => {
  const { interviewToken } = useParams()
  const systemSettings = useSelector(systemSettingsSelectors.data)
  const conversation = useSelector(conversationSelectors.data)
  const interview = useSelector(avaInterviewSelectors.data)

  const [showMenu, setShowMenu] = useState(false)

  const downloadReport = () => {
    if (!isSuperAdminRole()) return
    const candidatTexts = conversation.messages.map((m) => {
      return {
        author: m.author,
        avatar: '',
        text: m.text,
        sentimentScore: m.sentiment_score,
      }
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { getReport } = useReport()
    const string = getReport(candidatTexts, systemSettings)
    const file = new Blob([string], {
      type: 'text/plain',
    })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = 'ava_report.csv'
    document.body.appendChild(element)
    element.click()
  }

  const onDownload = (mode: 'Transcripts' | 'Attitude & WPM') => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { getTranscript, getAttitude } = useReport()
    const candidatTexts = conversation.messages.map((m) => {
      return {
        author: m.author,
        avatar: '',
        text: m.text,
        sentimentScore: m.sentiment_score,
        timestamp: m.timestamp * 1000,
      }
    })
    let string = ''
    let fileName = ''
    if (mode === 'Attitude & WPM') {
      string = getAttitude(
        candidatTexts,
        conversation.metrics.attitude,
        conversation.metrics.speech_tempo
      )
      fileName = `Wizco_AVA_Attitude_WPM_report_(session_${interviewToken}).csv`
    } else {
      string = `Session: ${interview.position.position}${
        interview.position.company ? ` at ${interview.position.company}` : ''
      }\n`
      string += `Date: ${formattedDate(interview.created_at, 'MMMM dd, yyyy')}\n`
      string += `Session ID: ${interviewToken}\n`
      string += `\n\nSession transcript\n\n`
      string += getTranscript(candidatTexts)
      fileName = `Wizco_AVA_Session_Transcript_$(session_${interviewToken}).txt`
    }

    const element = document.createElement('a')
    const file = new Blob([string], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  const onLinkSelected = (item: Link) => {
    setShowMenu(false)
    switch (item) {
      case Link.Report:
        downloadReport()
        break
      case Link.Transcript:
        onDownload('Transcripts')
        break
      case Link.Attitude:
        onDownload('Attitude & WPM')
        break
      default:
      // do nothing
    }
  }

  useEffect(() => {
    window.onclick = () => setShowMenu(false)
  }, [])

  return (
    <Container>
      <LineContainer>
        <BlockIcon icon={mIcon} />
        <ColumnContainer>
          <ReportTitle>Interview intelligence™</ReportTitle>
          <ReportSubtitleText>
            In-depth analysis of your interview performance
          </ReportSubtitleText>
        </ColumnContainer>
      </LineContainer>
      <AvaReportBlockContainer
        icon={summaryIcon}
        isTitleLink={isSuperAdminRole()}
        onTitleClick={(ev) => {
          ev.stopPropagation()
          setShowMenu(true)
        }}
      >
        {showMenu && (
          <LinksContainer>
            {Links.map((item) => (
              <LinkItem key={item} onClick={() => onLinkSelected(item)}>
                {item}
              </LinkItem>
            ))}
          </LinksContainer>
        )}
        <AvaInsightsBlock />
      </AvaReportBlockContainer>
    </Container>
  )
}

export default InsightsComponent
