import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { push } from 'connected-react-router'
import styled from '@emotion/styled'
// import { toastr } from 'react-redux-toastr'
import copy from 'copy-to-clipboard'
import { get } from 'lodash'
import { Text, Button, TextBold } from '../../components'
import Layout from '../Layout'
import success from '../../static/success.svg'

import {
  agencySelectors,
  bookingInfoSelectors,
  clearBookingInfoAction,
} from '../../redux'

interface IMessageStyle {
  paddingRight: string
}

const Message = styled.div<IMessageStyle>`
  display: flex;
  margin-bottom: 25px;
  position: relative;
  padding-right: ${(props) => `${props.paddingRight}px`};
`

const MessageIconContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: #fbf3ff;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CopyLinkButton = styled.div`
  position: absolute;
  width: 100px;
  height: 30px;
  background: #f5f6f8;
  color: #000000;
  top: 0;
  right: 0;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  padding-top: 1px;
  cursor: pointer;
  border: 5px solid #f5f6f8;
  &:hover {
    background-color: #edeef2;
    border-color: #e1e3ea;
  }
`

const MessageIcon = styled.img`
  width: 14px;
`

const TextStyled = styled(Text)`
  flex: 1;
`

const BoldTextStyled = styled(TextBold)`
  align-items: center;
  display: flex;
  font-size: 14px;
  flex: 1;
  justify-content: center;
`

function ThankYou() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { count, email, firstName, lastName, token } = useSelector(
    bookingInfoSelectors.data
  )
  const [alertText, setAlertText] = useState<string>('')

  const agency = useSelector(agencySelectors.data)
  const fullDomain = get(agency, 'full_domain', '')
  const studentInterview = `${fullDomain}/interviews/${token}`
  const message = count
    ? `${count} applicants were successfully invited`
    : `${firstName} ${lastName} (${email}) has been successfully invited to schedule a coaching session`

  const isShowLinks = agency.show_interview_links && !count

  const copyToClipboard = () => {
    setAlertText('Link copied!')
    setTimeout(() => setAlertText(''), 5000)
    copy(studentInterview)
  }

  return (
    <Layout title="Thank you" bg="thankYou" alertText={alertText} alertType="static">
      <>
        <Message paddingRight={isShowLinks ? '100' : '0'}>
          <>
            <MessageIconContainer>
              <MessageIcon src={success} />
            </MessageIconContainer>
            {count ? (
              <BoldTextStyled>{message}</BoldTextStyled>
            ) : (
              <TextStyled>{message}</TextStyled>
            )}
          </>
          {isShowLinks && (
            <CopyLinkButton onClick={copyToClipboard}>Copy Link</CopyLinkButton>
          )}
        </Message>
        <Button
          onClick={() => dispatch(push(`/agencies/${id}/activity`))}
          title="View session in dashboard"
        />
        <Button
          onClick={() => dispatch(clearBookingInfoAction)}
          title="New booking"
          customTheme="outline"
        />
      </>
    </Layout>
  )
}

export default ThankYou
