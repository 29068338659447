/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useDispatch, useSelector } from 'react-redux'
import { avaInterviewSelectors } from 'src/redux'
import useComunicationInsights from 'src/ava/services/useCommunicationInsights'
import { IAvaQuestion } from 'src/api'
import { updateAvaDataAction } from 'src/redux/data/ava/data'
import SvgDiscloserSmall from 'src/static/components/SvgDiscloserSmall'
import SvgFollowUp from 'src/static/components/SvgFollowUp'

const Container = styled.div`
  width: calc(100% + 32px);
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  margin: 0 -16px;
`

const QuestionContainer = styled.div`
  display: flex !important;
  position: relative;
  flex-direction: column;
  padding: 16px;
  border-radius: 14px;
  border: 1px solid #e1e3ea;
  align-items: start;
  margin: 16px 8px 0 8px;
  height: 240px;

  &:first-of-type {
    margin-top: 0px;
  }
`

const QuestionIndexLabel = styled.div`
  color: #a9a9a9;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: start;
  position: relative;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
`

const DotsContainer = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 30px;
  justify-content: center;
`

const DotItem = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 6px;
  margin: 0 6px;
  background: ${({ theme, active }: any) => {
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return active ? agencyColor : '#E1E3EA'
  }};
  cursor: pointer;
`

const ButtonStyled = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 52px;
  background: ${({ theme }: any) => {
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return agencyColor
  }};
  margin-top: 18px;
  padding: 8px 16px;
  width: fit-content;
  margin-left: auto;

  > svg {
    margin-left: 4px;
    transform: rotate(270deg);
  }
`

const QuestionText = styled.div`
  color: #222b45;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: auto;
`

const QuestionHeader = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SkippedTag = styled.div`
  background: #b202ff26;
  border-radius: 5px;
  color: #b202ff;
  padding: 2px 5px;
`

const CommunicationInsightsComponentMobile = () => {
  const dispatch = useDispatch()
  const { getInterviewQuestionIndexText } = useComunicationInsights()
  const interview = useSelector(avaInterviewSelectors.data)
  const questions = interview?.questions.map((q) => [q, ...(q.follow_up || [])]).flat()

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const sliderRef = useRef<Slider>(null)

  const setSelectedQuestion = (question: IAvaQuestion) => {
    dispatch(updateAvaDataAction({ selectedQuestion: question }))
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(activeSlideIndex, false)
  }, [activeSlideIndex])

  return (
    <Container>
      <Slider
        ref={sliderRef}
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        speed={400}
        centerMode
        centerPadding="30px"
        beforeChange={(_, newIndex) => setActiveSlideIndex(newIndex)}
      >
        {questions.map((question) => (
          <QuestionContainer key={question.id}>
            <QuestionIndexLabel>
              {!!question.parent_id && <SvgFollowUp fill="#A9A9A9" />}
              <QuestionHeader>
                {getInterviewQuestionIndexText(question, questions)}
              </QuestionHeader>
              {!question.answer?.text && <SkippedTag>Skipped</SkippedTag>}
            </QuestionIndexLabel>
            <QuestionText>{question.text}</QuestionText>
            <ButtonStyled onClick={() => setSelectedQuestion(question)}>
              View feedback
              <SvgDiscloserSmall fill="#fff" />
            </ButtonStyled>
          </QuestionContainer>
        ))}
      </Slider>
      <DotsContainer>
        {questions.map((_, index) => (
          <DotItem
            key={index}
            active={index === activeSlideIndex}
            onClick={() => setActiveSlideIndex(index)}
          />
        ))}
      </DotsContainer>
    </Container>
  )
}

export default CommunicationInsightsComponentMobile
