/* eslint-disable no-nested-ternary */
import React from 'react'
import styled from '@emotion/styled'
import withProps from 'recompose/withProps'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 10px 10px 0;
  cursor: pointer;
`

const RadiobuttonStyled = withProps()(styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ theme, useTheme, checked }: any) => {
    const agencyColor =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    const checkedColor = checked ? (useTheme ? agencyColor : '#B202FF') : 'transparent'
    return checkedColor
  }};
  border: solid 2px lightgray;
  border-radius: 20px;
`)

const Label = styled.div`
  color: #3b424f;
  font-size: 14px;
  margin-left: 8px;
`

const RadioButton = React.forwardRef((props: any, ref: any) => {
  const { name, onClick, label, useTheme, checked } = props

  return (
    <Container onClick={onClick}>
      <RadiobuttonStyled
        useTheme={useTheme}
        name={name}
        ref={ref}
        checked={checked}
        id={name}
      />
      <Label>{label}</Label>
    </Container>
  )
})

export default RadioButton
