import React, { ChangeEventHandler, useEffect } from 'react'
import styled from '@emotion/styled'
import { IAgoraRTC, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useStateRef from 'react-usestateref'

import { MediaDeviceGroup } from '../../services/useMediaDevices'

import PrecallVolumeView from './precallVolumeView'
import Select from '../Select'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`

const Text = styled.div`
  color: ${({ theme }: { theme: any }) => theme.precallTextColor};
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const SelectorText = styled.div`
  color: #737991;
  margin: 12px 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  ${!isMobileOnly &&
  css`
    font-weight: normal;

    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const MeterText = styled.div`
  color: #737991;
  font-size: 12px;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Precall]',
  'color:#1e133b',
]

interface IProps {
  agoraRTC: IAgoraRTC
  deviceId: string
  devices: MediaDeviceGroup[]
  changeMicrophone: (deviceId: string) => void
}

const MicrophoneTestView = ({
  agoraRTC,
  deviceId,
  devices,
  changeMicrophone,
}: IProps) => {
  const [microphones, setMicrophones] = useStateRef<MediaDeviceGroup[]>([])
  const [audioTrack, setAudioTrack, audioTrackRef] = useStateRef<IMicrophoneAudioTrack>(
    null
  )

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const changeAudioInput = async (rtc, microphoneId) => {
    if (!rtc || !microphoneId) return

    if (audioTrackRef.current) {
      log(`Changing audio track device (${microphoneId})`)
      try {
        await audioTrackRef.current?.setDevice(microphoneId)
        log(`Successfully changed audio track device`)
      } catch (e) {
        error(`Failed to change audio track device: ${JSON.stringify(e)}`)
        throw e
      }
    } else {
      log(`Creating new audio track with device (${microphoneId})`)
      try {
        setAudioTrack(await rtc.createMicrophoneAudioTrack({ microphoneId }))
        log(`Successfully created new audio track`)
      } catch (e) {
        error(`Failed to create new audio track: ${JSON.stringify(e)}`)
        throw e
      }
    }
  }

  useEffect(() => {
    return () => {
      log('Closing audio track')
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioTrackRef.current?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMicrophones(devices.filter((d) => d.kind === 'audioinput'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices])

  useEffect(() => {
    changeAudioInput(agoraRTC, deviceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agoraRTC, deviceId])

  const switchMicrophone: ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeMicrophone(e.target.value)
  }

  return (
    <Container>
      <Text>Speak into your microphone, is the meter below moving?</Text>
      <SelectorText>Choose a microphone</SelectorText>
      <Select id="selectMicrophone" onChange={switchMicrophone} value={deviceId}>
        {microphones.map((it) => (
          <option key={it.deviceId} value={it.deviceId}>
            {it.label}
          </option>
        ))}
      </Select>
      <br />
      <MeterText>Meter:</MeterText>
      <PrecallVolumeView track={audioTrack} />
    </Container>
  )
}

export default MicrophoneTestView
