import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import { Title as MobileTitle } from '../MobileText'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.5);
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const Label = styled.p`
  color: white;
  text-shadow: 0 0 2px black;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 0px;
`

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 45px;
`

const Button = styled.button`
  height: 33px;
  width: 67px;
  border-radius: 14px;
  font-size: 14px;
  border: none;
  color: #fff;
  background-color: #b202ff;
  font-weight: 500;
  display: block;
  margin: 0 33px;
  outline: none !important;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const LinkButton = styled(Button)`
  text-decoration: underline;
  background-color: transparent;
  font-weight: 400;
`

const MobileContainer = styled.div`
  z-index: 1;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`

const MobileLabel = styled(MobileTitle)`
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  white-space: pre-line;
  margin-bottom: 21px;
  text-align: center;
`

const MobileButton = styled.button`
  background: #b202ff;
  border: none;
  border-radius: 14px;
  color: ${({ theme }: { theme: any }) => theme.textColor};
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  margin: 0 33px;
  height: 33px;
  line-height: 17px;
  outline: none !important;
  width: 67px;
`

const MobileLinkButton = styled(MobileButton)`
  text-decoration: underline;
  background-color: transparent;
  font-weight: 400;
`

export default function DisconnectedNotification() {
  const line1 = `You were disconnected as you're connected to Wizco on another device.`
  const line2 = `If you'd like to reconnect using this device, Refresh and join again.`

  // eslint-disable-next-line no-restricted-globals
  const onRefresh = () => location.reload()

  // eslint-disable-next-line no-restricted-globals
  const onExit = () => {
    window.location.href = 'https://wizco.io'
  }

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Label>{line1}</Label>
          <Label>{line2}</Label>
          <ButtonsContainer>
            <LinkButton onClick={onRefresh}>Refresh</LinkButton>
            <Button onClick={onExit}>Exit</Button>
          </ButtonsContainer>
        </Container>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileLabel>{line1}</MobileLabel>
          <MobileLabel>{line2}</MobileLabel>
          <ButtonsContainer>
            <MobileLinkButton onClick={onRefresh}>Refresh</MobileLinkButton>
            <MobileButton onClick={onExit}>Exit</MobileButton>
          </ButtonsContainer>
        </MobileContainer>
      )}
    </>
  )
}
