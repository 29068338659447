import React from 'react'
import OverviewComponent from '../components/OverviewComponent'
import Layout from '../Layout'

function OverviewContainer() {
  return (
    <Layout>
      <OverviewComponent />
    </Layout>
  )
}

export default OverviewContainer
