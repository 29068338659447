/* eslint-disable default-case */
/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react'
import useState from 'react-usestateref'
import { IAgoraRTC, IAgoraRTCClient } from 'agora-rtc-sdk-ng'
import { useParams } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'
import { useDispatch } from 'react-redux'

import { IRemoteRtcData } from './interfaces'
import RemoteVideo from './components/RemoteVideo'
import SmallVideo from './components/SmallVideo'
import * as RecLayout from './components/RecLayout'
import Preview from './components/Preview'
import useRtm from './useRtm'
import makeRandom from './services/random'

import { setInterviewTimestampAction, setRecordingTimestampAction } from '../redux'
import { getAgoraToken, getInterview, IGetInterviewResponse, stopRecording } from '../api'

const CHANNEL = 'test' // REMOVE with our test module
const ExpertUidPrefix = 2
const StudentUidPrefix = 1
const ScreensharingUidPrefix = 2

if (!window.location.hostname.includes('localhost')) {
  datadogLogs.init({
    clientToken: 'pub50ad6f944a780d2299386c3cd44b81b6',
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    service: 'wizco.io',
    silentMultipleInit: true,
  })
}

function VideoCallContainer() {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const { initRtm, removeRtmUser } = useRtm(interviewToken)
  const [interviewPosition, setInterviewPosition] = useState('')
  const [expertName, setExpertName] = useState(null)
  const [studentName, setStudentName] = useState(null)
  const [expertAvatar, setExpertAvatar] = useState(null)
  const [studentAvatar, setStudentAvatar] = useState(null)
  const [expertJoined, setExpertJoined, expertJoinedRef] = useState(false)
  const [studentJoined, setStudentJoined, studentJoinedRef] = useState(false)
  const [expertVideoMuted, setExpertVideoMuted] = useState(true)
  const [expertAudioMuted, setExpertAudioMuted] = useState(true)
  const [studentAudioMuted, setStudentAudioMuted] = useState(true)
  const [studentVideoMuted, setStudentVideoMuted] = useState(true)
  const [
    isStudentScreenSharing,
    setIsStudentScreenSharing,
    isStudentScreenSharingRef,
  ] = useState(false)
  const [
    isExpertScreenSharing,
    setIsExpertScreenSharing,
    isExpertScreenSharingRef,
  ] = useState(false)
  const cameraClient = useRef<IAgoraRTCClient>()
  const AgoraRTC = useRef<IAgoraRTC>()

  const expertRtcData = useRef<IRemoteRtcData>({
    audioTrack: null,
    cameraTrack: null,
    screenTrack: null,
    uid: 20,
    quality: 6,
  })
  const studentRtcData = useRef<IRemoteRtcData>({
    audioTrack: null,
    cameraTrack: null,
    screenTrack: null,
    uid: 10,
    quality: 6,
  })
  const autostopTimer = useRef(null)
  const interviewId = useRef('')

  function hideStudentSharing() {
    setIsStudentScreenSharing(false)
  }

  async function showStudentSharing() {
    setIsStudentScreenSharing(true)
  }

  function showExpertSharing() {
    setIsExpertScreenSharing(true)
  }

  function hideExpertSharing() {
    setIsExpertScreenSharing(false)
  }

  function datadogLog(text: string) {
    datadogLogs.logger.log(`[recording] ${text}`, {
      timestamp: new Date(),
      interviewId: interviewId.current,
    })
  }

  function isScreensharing(uid: string): boolean {
    return uid.endsWith(ScreensharingUidPrefix.toString())
  }

  function isExpertStream(uid: string): boolean {
    return uid.startsWith(ExpertUidPrefix.toString())
  }

  function isStudentStream(uid: string): boolean {
    return uid.startsWith(StudentUidPrefix.toString())
  }

  useEffect(() => {
    dispatch(
      setRecordingTimestampAction({ interviewToken, recordingTimestamp: Date.now() })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { cameraTrack: expert } = expertRtcData.current
    if (expertVideoMuted) {
      expert?.stop()
    } else {
      const hasScreenshare =
        isStudentScreenSharingRef.current || isExpertScreenSharingRef.current
      if (!expert?.isPlaying && !hasScreenshare) {
        expert?.play('mid-expert-video', {
          fit: 'cover',
          mirror: false,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertVideoMuted])

  useEffect(() => {
    const { cameraTrack: student } = studentRtcData.current
    if (studentVideoMuted) {
      student?.stop()
    } else {
      const hasScreenshare =
        isStudentScreenSharingRef.current || isExpertScreenSharingRef.current
      if (!student?.isPlaying && !hasScreenshare) {
        student?.play('mid-student-video', {
          fit: 'cover',
          mirror: false,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentVideoMuted])

  function startTimeoutTimer() {
    if (autostopTimer.current) return
    datadogLog('start timeout')
    console.debug('RTC. Run autostop timer')
    autostopTimer.current = setTimeout(async () => {
      if (
        !expertRtcData.current.cameraTrack &&
        !studentRtcData.current.cameraTrack &&
        !expertRtcData.current.screenTrack &&
        !studentRtcData.current.screenTrack
      ) {
        datadogLog('stop recording')
        await stopRecording(interviewToken)
      }
    }, 600000)
  }

  function stopTimeoutTimer() {
    if (!autostopTimer.current) return
    datadogLog('cancel timeout')
    console.debug('RTC. Cancel autostop timer')
    clearTimeout(autostopTimer.current)
    autostopTimer.current = null
  }

  useEffect(() => {
    const hasScreenshare = isExpertScreenSharing || isStudentScreenSharing
    const { cameraTrack: expert, screenTrack: expertShare } = expertRtcData.current
    const { cameraTrack: student, screenTrack: studentShare } = studentRtcData.current
    expert?.stop()
    student?.stop()
    if (hasScreenshare) {
      if (isExpertScreenSharing) {
        if (studentShare?.isPlaying) studentShare?.stop()
        expertShare?.play('main-video', { fit: 'contain', mirror: false })
      } else {
        if (expertShare?.isPlaying) expertShare?.stop()
        studentShare?.play('main-video', { fit: 'contain', mirror: false })
      }
    } else {
      expert?.play('mid-expert-video', { fit: 'cover', mirror: false })
      student?.play('mid-student-video', { fit: 'cover', mirror: false })
    }
  }, [isExpertScreenSharing, isStudentScreenSharing])

  function subscribeToRemoteStreams() {
    cameraClient.current.on('user-joined', (user) => {
      datadogLog(`user joined  [${user.uid}]`)
      console.debug(`RTC. User joined [${user.uid}]`)
      const uid = user.uid.toString()
      if (isScreensharing(uid)) return
      if (isExpertStream(uid)) setExpertJoined(true)
      if (isStudentStream(uid)) setStudentJoined(true)
      stopTimeoutTimer()
    })
    cameraClient.current.on('user-left', (user) => {
      datadogLog(`user left  [${user.uid}]`)
      console.debug(`RTC. User left [${user.uid}]`)
      const uid = user.uid.toString()
      if (isScreensharing(uid)) return
      if (isExpertStream(uid)) {
        removeRtmUser(ExpertUidPrefix)
        setExpertJoined(false)
      }
      if (isStudentStream(uid)) {
        removeRtmUser(StudentUidPrefix)
        setStudentJoined(false)
      }
      if (!expertJoinedRef.current && !studentJoinedRef.current) startTimeoutTimer()
    })

    cameraClient.current.on('user-published', async (user, mediaType) => {
      console.debug(`RTC. User published [${user.uid}]`, mediaType)
      await cameraClient.current.subscribe(user, mediaType)
      const uid = user.uid.toString()
      if (!isScreensharing(uid)) {
        if (isExpertStream(uid)) {
          expertRtcData.current.uid = uid
        }
        if (isStudentStream(uid)) {
          studentRtcData.current.uid = uid
        }
      }
      switch (mediaType) {
        case 'video':
          if (isScreensharing(uid)) {
            if (isStudentStream(uid)) {
              studentRtcData.current.screenTrack = user.videoTrack
              showStudentSharing()
              dispatch(
                setInterviewTimestampAction({
                  interviewToken,
                  name: 'screen_start_candidate',
                  time: Date.now(),
                })
              )
            }
            if (isExpertStream(uid)) {
              expertRtcData.current.screenTrack = user.videoTrack
              showExpertSharing()
              dispatch(
                setInterviewTimestampAction({
                  interviewToken,
                  name: 'screen_start_expert',
                  time: Date.now(),
                })
              )
            }
          } else {
            if (isStudentStream(uid)) {
              studentRtcData.current.cameraTrack = user.videoTrack
              setStudentVideoMuted(false)
            }
            if (isExpertStream(uid)) {
              expertRtcData.current.cameraTrack = user.videoTrack
              setExpertVideoMuted(false)
            }
          }
          break
        case 'audio':
          if (isStudentStream(uid)) {
            studentRtcData.current.audioTrack = user.audioTrack
            studentRtcData.current.audioTrack?.play()
            setStudentAudioMuted(false)
          }
          if (isExpertStream(uid)) {
            expertRtcData.current.audioTrack = user.audioTrack
            expertRtcData.current.audioTrack?.play()
            setExpertAudioMuted(false)
          }
          break
      }
      document.getElementsByClassName('cky-consent-bar')?.item(0)?.remove()
      document.getElementsByClassName('intercom-lightweight-app')?.item(0)?.remove()
      document.getElementById('intercom-frame')?.remove()
    })
    cameraClient.current.on('user-unpublished', (user, mediaType) => {
      console.debug(`RTC. User unpublished [${user.uid}]`, mediaType)
      const uid = user.uid.toString()
      switch (mediaType) {
        case 'audio':
          if (isStudentStream(uid)) {
            studentRtcData.current.audioTrack?.stop()
            studentRtcData.current.audioTrack = null
            setStudentAudioMuted(true)
          }
          if (isExpertStream(uid)) {
            expertRtcData.current.audioTrack?.stop()
            expertRtcData.current.audioTrack = null
            setExpertAudioMuted(true)
          }
          break
        case 'video':
          if (isScreensharing(uid)) {
            if (isStudentStream(uid)) {
              studentRtcData.current.screenTrack?.stop()
              studentRtcData.current.screenTrack = null
              hideStudentSharing()
              dispatch(
                setInterviewTimestampAction({
                  interviewToken,
                  name: 'screen_stop_candidate',
                  time: Date.now(),
                })
              )
            }
            if (isExpertStream(uid)) {
              expertRtcData.current.screenTrack?.stop()
              expertRtcData.current.screenTrack = null
              hideExpertSharing()
              dispatch(
                setInterviewTimestampAction({
                  interviewToken,
                  name: 'screen_stop_expert',
                  time: Date.now(),
                })
              )
            }
          } else {
            if (isStudentStream(uid)) {
              studentRtcData.current.cameraTrack?.stop()
              studentRtcData.current.cameraTrack = null
              setStudentVideoMuted(true)
            }
            if (isExpertStream(uid)) {
              expertRtcData.current.cameraTrack?.stop()
              expertRtcData.current.cameraTrack = null
              setExpertVideoMuted(true)
            }
          }
          break
      }
    })
  }

  async function joinCall() {
    const interview: IGetInterviewResponse =
      interviewToken !== CHANNEL
        ? await getInterview(interviewToken)
        : { interview: undefined }
    if (interview.interview) {
      setInterviewPosition(interview.interview.desired_position)
      if (interview.token.role === 'expert') {
        setExpertName(`${interview.profile.first_name} ${interview.profile.last_name}`)
        setStudentName(`${interview.peer.first_name} ${interview.peer.last_name}`)
        setExpertAvatar(interview.profile.logo?.url)
        setStudentAvatar(interview.peer.logo?.url)
      } else {
        setStudentName(`${interview.profile.first_name} ${interview.profile.last_name}`)
        setExpertName(`${interview.peer.first_name} ${interview.peer.last_name}`)
        setStudentAvatar(interview.profile.logo?.url)
        setExpertAvatar(interview.peer.logo?.url)
      }
    }
    cameraClient.current.setClientRole('audience')
    subscribeToRemoteStreams()
    cameraClient.current.on('connection-state-change', (state) => {
      console.debug('RTC. connection state changed', state)
    })
    cameraClient.current.on('error', (state) => {
      console.debug('RTC. error', state)
    })
    const agoraUserName = 30
    cameraClient.current.on('token-privilege-will-expire', async () => {
      const tokenData = await getAgoraToken(
        agoraUserName,
        interview.interview.id || CHANNEL
      )
      cameraClient.current.renewToken(tokenData.token)
    })
    const tokenData = await getAgoraToken(
      agoraUserName,
      interview?.interview?.id || CHANNEL
    )
    const uid = await cameraClient.current.join(
      process.env.REACT_APP_AGORA_APP_ID,
      interview?.interview?.id || CHANNEL,
      tokenData.token,
      agoraUserName
    )
    console.debug('RTC. Joined', uid)
    const rmtUserName = `30_${makeRandom(10)}`
    initRtm(rmtUserName, interview.interview.id || CHANNEL)
    interviewId.current = interview?.interview?.order_number.toString() || CHANNEL
    datadogLog('started')
  }

  useEffect(() => {
    const importObj =
      process.env.TEST === '1'
        ? import('./services/agoraRtcMock')
        : import('./services/agoraRtc')
    importObj.then((rtcService) => {
      AgoraRTC.current = rtcService.default()
      cameraClient.current = AgoraRTC.current.createClient({
        mode: 'live',
        codec: 'h264',
      })
      joinCall()
    })
    document.getElementById('intercom-frame')?.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RecLayout.Container>
      <RecLayout.Content>
        {!expertJoined && !studentJoined && studentName && expertName && (
          <Preview
            studentName={studentName}
            expertName={expertName}
            position={interviewPosition}
          />
        )}
        {(isStudentScreenSharing || isExpertScreenSharing) && (
          <RemoteVideo id="main-video" audioMuted={false} videoMuted={false} />
        )}
        {expertJoined && !(isStudentScreenSharing || isExpertScreenSharing) && (
          <SmallVideo
            id="mid-expert-video"
            userName={expertName}
            userAvatar={expertAvatar}
            videoMuted={expertVideoMuted}
            audioMuted={expertAudioMuted}
            sidebar={false}
            track={expertRtcData.current.audioTrack}
          />
        )}
        {studentJoined && !(isStudentScreenSharing || isExpertScreenSharing) && (
          <SmallVideo
            id="mid-student-video"
            userName={studentName}
            userAvatar={studentAvatar}
            videoMuted={studentVideoMuted}
            audioMuted={studentAudioMuted}
            sidebar={false}
            track={studentRtcData.current.audioTrack}
          />
        )}
      </RecLayout.Content>
    </RecLayout.Container>
  )
}

export default VideoCallContainer
