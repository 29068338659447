import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  height: 100%:
`

interface IProps {
  onAppear: (shown: boolean) => void
  children: JSX.Element
  margin?: number
}

const AppearBlock = ({ children, onAppear, margin }: IProps) => {
  const blockRef = useRef<HTMLDivElement>(null)

  const [isShown, setIsShown] = useState(false)

  const getOffset = (element) => {
    if (!element.offsetParent) {
      return element.offsetTop
    }
    return getOffset(element.offsetParent) + element.offsetTop
  }

  const onScroll = useCallback(
    (ev: any) => {
      if (!blockRef.current) return
      const { scrollTop } = ev.target
      const anchorTop = getOffset(blockRef.current)
      const windowHeight = window.innerHeight
      const anchorDiff = scrollTop - anchorTop + windowHeight - margin
      if (anchorDiff > 0) {
        if (!isShown) {
          setIsShown(true)
        }
      } else {
        setIsShown(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShown]
  )

  useEffect(() => {
    window.addEventListener('scroll', onScroll, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    onAppear(isShown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown])

  return <Container ref={blockRef}>{children}</Container>
}

AppearBlock.defaultProps = {
  margin: 0,
}

export default AppearBlock
