import { useCallback } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { avaSettingsSelectors } from 'src/redux/data/ava/settings'

const useAvaSound = () => {
  const avaSettings = useSelector(avaSettingsSelectors.data)

  const playSound = useCallback(
    (file) => {
      if (avaSettings.muted || isMobileOnly) return
      const audio: any = new Audio(file)
      if (audio.setSinkId) {
        audio.setSinkId(avaSettings.speakerId)
      }
      audio.play().catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('Could not play sound', err)
      })
    },
    [avaSettings]
  )

  return { playSound }
}

export default useAvaSound
