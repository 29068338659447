import React from 'react'
import styled from '@emotion/styled'

import SvgDangerIcon from 'src/static/components/SvgDanger'
import SvgCloseIcon from 'src/static/components/SvgClose'

import closeIcon from 'src/static/close_gray.svg'

import { Text as MobileText } from '../MobileText'
import CallNotification from './BaseNotifications'
import {
  Close,
  OnboardingPopup,
  StyledText,
  StyledTitle,
} from './onboarding/BaseOnboarding'

const PoorConnectionPopup = styled(OnboardingPopup)`
  position: absolute;
  right: 50%;
  top: 100%;
  transform: translate(20%, 0.75rem);

  &:after {
    left: auto;
    right: 19%;
  }

  img {
    margin-bottom: 0 !important;
  }
`

const PoorConnectionText = styled(StyledText)`
  margin: 0 !important;
`

interface IPoorConnectionIndicator {
  onHide: () => void
  hasVideo: boolean
}

export const PoorConnectionIndicator = ({
  hasVideo,
  onHide,
}: IPoorConnectionIndicator) => {
  return (
    <PoorConnectionPopup>
      <Close onClick={onHide} src={closeIcon} />
      <StyledTitle>Weak Connection</StyledTitle>
      <PoorConnectionText>Communication might be affected</PoorConnectionText>
      {hasVideo && (
        <PoorConnectionText>Turn off video to improve communication</PoorConnectionText>
      )}
    </PoorConnectionPopup>
  )
}

const MobileContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  padding: 12px;
  width: 100%;
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 38px 0 13px;
  position: relative;
  width: 100%;
`

const MobileDangerIcon = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  opacity: 0.75;

  svg {
    width: 16px;
    height: 16px;
  }
`

const MobileStyledTitle = styled(MobileText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
`

const MobileStyledText = styled(MobileText)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`

const MobileHint = styled(MobileText)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 12px;
  text-decoration-line: underline;
  text-align: center;
`

const MobileCloseIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`

interface IMobilePoorConnectionIndicator {
  theme: any
  muteVideo: () => void
  onHide: () => void
}

export const MobilePoorConnectionIndicator = ({
  theme,
  muteVideo,
  onHide,
}: IMobilePoorConnectionIndicator) => {
  return (
    <CallNotification>
      <MobileContainer>
        <MobileDangerIcon>
          <SvgDangerIcon fill={theme.textColor} />
        </MobileDangerIcon>
        <MobileContent>
          <MobileStyledTitle>Weak Connection</MobileStyledTitle>
          <MobileStyledText>Communication might be affected.</MobileStyledText>
          {muteVideo ? (
            <>
              <MobileStyledText>Turn off video to improve communication</MobileStyledText>
              <MobileHint onClick={muteVideo}>Turn off video</MobileHint>
            </>
          ) : undefined}
          <MobileCloseIcon onClick={onHide}>
            <SvgCloseIcon fill={theme.textColor} />
          </MobileCloseIcon>
        </MobileContent>
      </MobileContainer>
    </CallNotification>
  )
}
