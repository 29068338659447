import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

import successIcon from 'src/static/icSuccess.png'
import failedIcon from 'src/static/icFailed.png'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`

const Text = styled.div`
  color: ${({ theme }: { theme: any }) => theme.precallTextColor};
  font-size: 14px;
  line-height: 17px;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 20px;
`
const StepItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;

  ${!isMobileOnly &&
  css`
    height: 48px;
    @media screen and (max-width: 2500px) {
      height: 40px;
    }
    @media screen and (max-width: 2000px) {
      height: 32px;
    }
    @media screen and (max-width: 1200px) {
      height: 24px;
    }
    @media screen and (max-width: 900px) {
      height: 16px;
    }
  `}
`
const StepIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;

  ${!isMobileOnly &&
  css`
    width: 30px;
    height: 30px;
    margin-right: 18px;
    @media screen and (max-width: 2500px) {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }
    @media screen and (max-width: 2000px) {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
    @media screen and (max-width: 1200px) {
      width: 15px;
      height: 15px;
      margin-right: 9px;
    }
    @media screen and (max-width: 900px) {
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
  `}
`
const StepName = styled.div`
  font-size: 16px;
  color: #737991;

  ${!isMobileOnly &&
  css`
    font-size: 24px;
    @media screen and (max-width: 2500px) {
      font-size: 20px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }
    @media screen and (max-width: 900px) {
      font-size: 8px;
    }
  `}
`

interface IProps {
  failedSteps: number[]
}

const Steps = ['Microphones', 'Speaker', 'Camera', 'Connection']

const ConnectionTestView = ({ failedSteps }: IProps) => {
  const success = failedSteps.length === 0

  return (
    <Container>
      <Text>
        {success
          ? 'All tests were successful! You can now continue having online meetings with confidence.'
          : 'One or more of the tests failed. You may need to restart your browser and / or computer then try this test again.'}
      </Text>
      {!success && (
        <>
          <br />
          <Text>Still doesn`t work?</Text>
          <Text>Please contact our support team to help you.</Text>
          <StepContainer>
            {Steps.map((step, index) => (
              <StepItem>
                <StepIcon
                  src={failedSteps.indexOf(index) > -1 ? failedIcon : successIcon}
                />
                <StepName>{step}</StepName>
              </StepItem>
            ))}
          </StepContainer>
        </>
      )}
    </Container>
  )
}

export default ConnectionTestView
