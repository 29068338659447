/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { avaInterviewSelectors } from 'src/redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import SvgDisclaimer from 'src/static/components/SvgDisclaimer'
import AvaInsightsScoreBlock from './AvaInsightsScoreBlock'
import CircleProgress from './CircleProgress'
import RetryReport from './RetryReport'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: ${isMobileOnly ? '100%' : 'auto'};
`

const BorderContainer = styled.div`
  border-radius: 14px;
  border: 1px solid #e3e5ea;
  padding: 16px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ColumnContainer = styled(BorderContainer)`
  display: flex;
  flex-direction: column;
`

const ScoreData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: start;
  margin-right: 16px;
`

const TitleText = styled.div`
  color: #222b45;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;

  ${isMobileOnly &&
  css`
    font-size: 16px;
  `}
`

const OveralScoreText = styled.div`
  color: #222b45;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 32px;

  @media screen and (max-width: 900px) {
    margin-bottom: 8px;
  }
`

const OveralScoreInfo = styled.div`
  color: #222b45;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`

const InfoText = styled.div`
  color: #222b45;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`

const SummaryContainer = styled(ColumnContainer)`
  width: 100%;
  padding: 40px;
  margin-bottom: 24px;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    width: calc(100% - 338px);
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    padding: 24px;
  }

  ${isMobileOnly &&
  css`
    padding: 16px;
  `}
`

const ScoreContainer = styled(ColumnContainer)`
  width: 304px;
  height: 368px;
  padding: 24px;
  align-items: center;
  margin: 0 24px 0 0;

  @media screen and (max-width: 1280px) {
    margin: 0 0 24px 24px;
    height: 356px;
  }

  @media screen and (max-width: 900px) {
    padding: 20px 40px;
    height: auto;
    margin: 0 0 24px 0;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  ${isMobileOnly &&
  css`
    flex-direction: column !important;
    padding: 24px 16px !important;
  `}
`

const MetricsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-between;
`

const MetricItemContainer = styled(ColumnContainer)`
  width: calc(50% - 12px);
  margin-bottom: 16px;

  &:nth-child(5) {
    margin-bottom: 0;
  }
  &:nth-child(6) {
    margin-bottom: 0;
  }

  @media screen and (max-width: 900px) {
    width: 100%;

    &:nth-child(5) {
      margin-bottom: 16px;
    }
    &:nth-child(6) {
      margin-bottom: 0;
    }
  }

  ${isMobileOnly &&
  css`
    margin: 0 8px;
    padding: 24px 16px;
    height: 160px;
  `}
`

const DotsContainer = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 50px;
  justify-content: center;
`

const DotItem = styled.div<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 6px;
  margin: 0 6px;
  background: ${({ theme, active }: any) => {
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return active ? agencyColor : '#E1E3EA'
  }};
  cursor: pointer;
`

const SliderContainer = styled.div`
  width: calc(100% + 32px);
  margin-left: -16px;
  height: fit-content;
`

const ReportDisclaimer = styled(ColumnContainer)`
  border: 1px solid #b202ff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 24px;
  width: 100%;

  svg {
    flex-shrink: 0;
  }
`

const DisclaimerText = styled.div`
  margin: 0 15px;
  width: inherit;
`

const DisclaimerDismisss = styled.div`
  color: #b202ff;
  cursor: pointer;
  font-weight: bold;
`

interface IMetricsBlock {
  title: string
  steps: string[]
  value: string
}

const StepsData: IMetricsBlock[] = [
  { title: 'Attitude', steps: ['Negative', 'Neutral', 'Positive'], value: 'Negative' },
  {
    title: 'Role-related knowledge',
    steps: ['Unfamiliar', 'Basic understanding', 'Expert'],
    value: 'Unfamiliar',
  },
  {
    title: 'Workplace culture',
    steps: ['Independent', 'Occasional collaborator', 'Team player'],
    value: 'Independent',
  },
  {
    title: 'Communication',
    steps: ['Ineffective', 'Adequate', 'Articulate'],
    value: 'Ineffective',
  },
  {
    title: 'Confidence',
    steps: ['Hesitant', 'Semi-confident', 'Self-assured'],
    value: 'Hesitant',
  },
  {
    title: 'Problem-solving',
    steps: ['Reactive', 'Situationally proactive', 'Proactive'],
    value: 'Reactive',
  },
]

export default function AvaInsightsBlock() {
  const interview = useSelector(avaInterviewSelectors.data)
  const [stepsData, setStepsData] = useState(StepsData)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)
  const [showDisclaimer, setShowDisclaimer] = useState(true)

  const sliderRef = useRef<Slider>(null)

  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' }) && !isMobileOnly

  const questions = (interview?.questions || [])
    .map((q) => [q, ...(q.follow_up || [])])
    .flat()
  const questionsAnswered = questions.filter((q) => !!q.answer?.text).length

  const getOveralScoreInfo = (score: number) => {
    if (score <= 4) {
      return `You're at the beginning stages of your career journey. There's room for growth, so use this feedback as a guide to improve your chances of success.`
    }
    if (score <= 7) {
      return `You're right on track. Tap into your full potential, and keep moving towards your career goals.`
    }
    if (score <= 9) {
      return `You're well on your way to securing that position. Keep refining your skills and get that offer letter.`
    }
    return `You're not just prepared; you're the epitome of excellence!`
  }

  const handleDismissDisclaimer = () => setShowDisclaimer(false)

  useEffect(() => {
    if (interview) {
      const newStepsData = [...StepsData]
      newStepsData.find((s) => s.title === 'Attitude').value =
        interview.overall_score.attitude
      newStepsData.find((s) => s.title === 'Role-related knowledge').value =
        interview.overall_score.role_related_knowledge
      newStepsData.find((s) => s.title === 'Workplace culture').value =
        interview.overall_score.workplace_culture
      newStepsData.find((s) => s.title === 'Communication').value =
        interview.overall_score.communication_skills
      newStepsData.find((s) => s.title === 'Confidence').value =
        interview.overall_score.confidence
      newStepsData.find((s) => s.title === 'Problem-solving').value =
        interview.overall_score.problem_solving_skills
      setStepsData(newStepsData)
    }
  }, [interview])

  useEffect(() => {
    if (isMobileOnly) {
      sliderRef.current?.slickGoTo(activeSlideIndex, false)
    }
  }, [activeSlideIndex])

  if (!Object.keys(interview.overall_score).length) return <RetryReport />

  return (
    <Container>
      {questionsAnswered < 4 && showDisclaimer && (
        <ReportDisclaimer>
          <SvgDisclaimer fill="#B202FF" />
          <DisclaimerText>{`As you only answered ${questionsAnswered} question${
            questionsAnswered === 1 ? '' : 's'
          }, this section's feedback may be limited.`}</DisclaimerText>
          <DisclaimerDismisss onClick={handleDismissDisclaimer}>
            Dismiss
          </DisclaimerDismisss>
        </ReportDisclaimer>
      )}
      <SummaryContainer>
        <TitleText>Summary</TitleText>
        <InfoText>{interview.overall_score.feedback}</InfoText>
      </SummaryContainer>
      <ScoreContainer>
        {!isSmallScreen && (
          <>
            <OveralScoreText>Your overall score</OveralScoreText>
            <CircleProgress value={interview?.overall_score.score} maxValue={10} />
            <OveralScoreInfo>
              {getOveralScoreInfo(interview?.overall_score.score)}
            </OveralScoreInfo>
          </>
        )}
        {isSmallScreen && (
          <RowContainer>
            <ScoreData>
              <OveralScoreText>Your overall score</OveralScoreText>
              <OveralScoreInfo>
                {getOveralScoreInfo(interview?.overall_score.score)}
              </OveralScoreInfo>
            </ScoreData>
            <CircleProgress value={interview?.overall_score.score} maxValue={10} />
          </RowContainer>
        )}
      </ScoreContainer>
      {!isMobileOnly && (
        <MetricsContainer>
          {stepsData.map((m) => (
            <MetricItemContainer key={m.title}>
              <AvaInsightsScoreBlock title={m.title} steps={m.steps} value={m.value} />
            </MetricItemContainer>
          ))}
        </MetricsContainer>
      )}
      {isMobileOnly && (
        <SliderContainer>
          <Slider
            ref={sliderRef}
            arrows={false}
            slidesToShow={1}
            slidesToScroll={1}
            speed={400}
            centerMode
            centerPadding="30px"
            beforeChange={(_, newIndex) => setActiveSlideIndex(newIndex)}
          >
            {stepsData.map((m) => (
              <MetricItemContainer key={m.title}>
                <AvaInsightsScoreBlock title={m.title} steps={m.steps} value={m.value} />
              </MetricItemContainer>
            ))}
          </Slider>
          <DotsContainer>
            {stepsData.map((_, index) => (
              <DotItem
                key={index}
                active={index === activeSlideIndex}
                onClick={() => setActiveSlideIndex(index)}
              />
            ))}
          </DotsContainer>
        </SliderContainer>
      )}
    </Container>
  )
}

AvaInsightsBlock.defaultProps = {
  isTitleLink: false,
  title: '',
  subTitle: '',
  onTitleClick: () => {},
}
