import {
  ICandidateProfile,
  IInterviewReport,
  IExpertProfile,
  IAdminInterview,
  IAdminStats,
  IAgencyStats,
  IGetAdminInterviewsParams,
  IGetAdminStatsParams,
  IGetAgencyInterviewResponse,
  IInterviewCandidateState,
  IInterviewPositionState,
} from '../api'
import { IFailure } from '../utils/redux'

export const GET_INTERVIEWS = 'AGENCY/GET_INTERVIEWS'
export const GET_INTERVIEWS_SUCCESS = 'AGENCY/GET_INTERVIEWS_SUCCESS'
export const GET_INTERVIEWS_FAILURE = 'AGENCY/GET_INTERVIEWS_FAILURE'
export const GET_INTERVIEW = 'AGENCY/GET_INTERVIEW'
export const GET_INTERVIEW_SUCCESS = 'AGENCY/GET_INTERVIEW_SUCCESS'
export const GET_INTERVIEW_FAILURE = 'AGENCY/GET_INTERVIEW_FAILURE'
export const SAVE_SESSION_REPORT = 'AGENCY/SAVE_SESSION_REPORT'
export const SAVE_SESSION_REPORT_SUCCESS = 'AGENCY/SAVE_SESSION_REPORT_SUCCESS'
export const SAVE_SESSION_REPORT_FAILURE = 'AGENCY/SAVE_SESSION_REPORT_FAILURE'
export const SAVE_SESSION_REPORT_CLEAR_SUCCESS =
  'AGENCY/SAVE_SESSION_REPORT_CLEAR_SUCCESS'
export const SAVE_SESSION_REPORT_CLEAR_ERROR = 'AGENCY/SAVE_SESSION_REPORT_CLEAR_ERROR'
export const SEND_TO_APPLICANT = 'AGENCY/SEND_TO_APPLICANT'
export const SEND_TO_APPLICANT_SUCCESS = 'AGENCY/SEND_TO_APPLICANT_SUCCESS'
export const SEND_TO_APPLICANT_FAILURE = 'AGENCY/SEND_TO_APPLICANT_FAILURE'
export const SEND_TO_APPLICANT_CLEAR_SUCCESS = 'AGENCY/SEND_TO_APPLICANT_CLEAR_SUCCESS'
export const SEND_TO_APPLICANT_CLEAR_ERROR = 'AGENCY/SEND_TO_APPLICANT_CLEAR_ERROR'
export const INTERVIEW_COMPLETE = 'AGENCY/  INTERVIEW_COMPLETE'
export const INTERVIEW_COMPLETE_SUCCESS = 'AGENCY/  INTERVIEW_COMPLETE_SUCCESS'
export const INTERVIEW_COMPLETE_FAILURE = 'AGENCY/  INTERVIEW_COMPLETE_FAILURE'
export const INTERVIEW_COMPLETE_CLEAR_SUCCESS =
  'AGENCY/  INTERVIEW_COMPLETE_CLEAR_SUCCESS'
export const INTERVIEW_COMPLETE_CLEAR_ERROR = 'AGENCY/  INTERVIEW_COMPLETE_CLEAR_ERROR'
export const INTERVIEW_TO_END = 'AGENCY/  INTERVIEW_TO_END'
export const INTERVIEW_TO_END_SUCCESS = 'AGENCY/  INTERVIEW_TO_END_SUCCESS'
export const INTERVIEW_TO_END_FAILURE = 'AGENCY/  INTERVIEW_TO_END_FAILURE'
export const INTERVIEW_TO_END_CLEAR_SUCCESS = 'AGENCY/  INTERVIEW_TO_END_CLEAR_SUCCESS'
export const INTERVIEW_TO_END_CLEAR_ERROR = 'AGENCY/  INTERVIEW_TO_END_CLEAR_ERROR'
export const LOAD_EXPERT_DATA_SUCCESS = 'AGENCY/  LOAD_EXPERT_DATA_SUCCESS'
export const LOAD_EXPERT_DATA_FAILURE = 'AGENCY/  LOAD_EXPERT_DATA_FAILURE'
export const ASK_EXPERT_AVAILABILITY = 'AGENCY/ ASK_EXPERT_AVAILABILITY'
export const GET_STATS = 'AGENCY/GET_STATS'
export const GET_STATS_SUCCESS = 'AGENCY/GET_STATS_SUCCESS'
export const GET_STATS_FAILURE = 'AGENCY/GET_STATS_FAILURE'

export interface IGetInterviewsAction {
  type: typeof GET_INTERVIEWS
  payload: {
    params?: IGetAdminInterviewsParams
  }
}

export interface IGetInterviewsSuccessAction {
  type: typeof GET_INTERVIEWS_SUCCESS
  payload: {
    interviews: IAdminInterview[]
    pages: number
  }
}

export interface IGetInterviewsFailureAction {
  type: typeof GET_INTERVIEWS_FAILURE
  payload: IFailure
}

export interface IGetInterviewAction {
  type: typeof GET_INTERVIEW
  payload: {
    agencyId: string
    id: string
  }
}

export interface IAskExpertAvailability {
  type: typeof ASK_EXPERT_AVAILABILITY
  payload: {
    interviewId: string
    expertId: string
    agencyId: string
  }
}

export interface IGetInterviewSuccessAction {
  type: typeof GET_INTERVIEW_SUCCESS
  payload: {
    interview: IGetAgencyInterviewResponse
  }
}

export interface IGetInterviewFailureAction {
  type: typeof GET_INTERVIEW_FAILURE
  payload: IFailure
}

export interface ISaveInterviewDetailsActionPayload {
  agencyId: string
  id: string
  candidate?: ICandidateProfile
  experts?: IExpertProfile[]
  student?: IInterviewCandidateState
  position?: IInterviewPositionState
  interview?: {
    main_time_slot_id?: string
    admin_notes?: string
    expert_confirmed?: boolean
    expert_passed_training?: boolean
  }
  use_general_link?: boolean
  hired_candidate?: string
  codingSessionUrl?: string
  codingPlaybackUrl?: string
  cv_file_id?: string
  expert_paid?: boolean
  expert_payment_provider?: string
  expert_payment_reference?: string
}

export interface ISessionReportAction {
  type: typeof SAVE_SESSION_REPORT
  payload: {
    agencyId: string
    id: string
    report: Partial<IInterviewReport>
  }
}

export interface ISessionReportFailureAction {
  type: typeof SAVE_SESSION_REPORT_FAILURE
  payload: IFailure
}

export interface ILoadExpertDataSuccessAction {
  type: typeof LOAD_EXPERT_DATA_SUCCESS
}

export interface ILoadExpertDataFailureAction {
  type: typeof LOAD_EXPERT_DATA_FAILURE
}

export interface IGetStatsAction {
  type: typeof GET_STATS
  payload: {
    params: IGetAdminStatsParams
  }
}

export interface IGetStatsSuccessAction {
  type: typeof GET_STATS_SUCCESS
  payload: {
    stats: IAdminStats | IAgencyStats
  }
}

export interface IGetStatsFailureAction {
  type: typeof GET_STATS_FAILURE
  payload: IFailure
}
