import React, { useEffect, useState } from 'react'
import ArrowImage from 'src/static/icArrowBack.png'
import { isMobileOnly } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import useAvaSound from 'src/ava/services/avaSound'
import { avaIntroIndexSelectors } from 'src/redux/data/ava/questionIndex'
import { useSelector } from 'react-redux'
import { avaIntroStepSelectors } from 'src/redux/data/ava/step'
import { IntroStep } from 'src/ava/containers/IntroContainer'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {
  TitleText,
  ComponentContainer,
  InputContainer,
  QuestionContainer,
  SubTitleText,
  ErrorContainer,
  BackButton,
  NavButton,
} from '../elements/styled-components'
import LiveText from '../LiveText'
import BottomComponent from './BottomComponent'
import QuestionCounter from './QuestionCounter'

const NavButtonStyled = styled(NavButton)`
  ${isMobileOnly &&
  css`
    margin-bottom: 16px;
    margin-top: 8px;
  `}
`

const BackButtonStyled = styled(BackButton)`
  margin: 0 24px 0 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-self: start;
  margin-top: auto;
  position: relative;
  width: 100%;
`

interface IProps extends React.HTMLProps<HTMLDivElement> {
  disabled?: boolean
  title?: string
  subTitle?: string
  canGoBack?: boolean
  errorText?: string
  showError?: boolean
  inverseTitles?: boolean
  backMode?: boolean
  goBack?: () => void
  goNext?: () => void
}

const StepLayout = ({
  children,
  disabled,
  title,
  subTitle,
  canGoBack,
  errorText,
  showError,
  inverseTitles,
  backMode,
  goNext,
  goBack,
}: IProps) => {
  const { playSound } = useAvaSound()
  const questionIndex = useSelector(avaIntroIndexSelectors.data)
  const step = useSelector(avaIntroStepSelectors.data)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' }) && !isMobileOnly
  const [showInput, setShowInput] = useState(false)
  const [subTitleReady, setSubTitleReady] = useState(false)
  const [titleReady, setTitleReady] = useState(false)

  const onGoBack = () => {
    goBack()
    playSound('/static/sound/ava-back.wav')
  }

  const onGoNext = () => {
    goNext()
    playSound('/static/sound/ava-forward.wav')
  }

  useEffect(() => {
    if (!backMode) {
      setShowInput(false)
    }
  }, [title, subTitle, backMode])

  useEffect(() => {
    playSound('/static/sound/ava-go-input.wav')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput])

  return (
    <ComponentContainer>
      <QuestionContainer>
        {step !== IntroStep.GettingQuestions && (
          <QuestionCounter
            index={questionIndex.questionNumber}
            indexText={questionIndex.questionNumberText}
            count={questionIndex.questionCount}
          />
        )}
        {subTitle && !inverseTitles && (
          <SubTitleText>
            <LiveText
              staticText={backMode}
              text={subTitle}
              onFinish={() => setSubTitleReady(true)}
            />
          </SubTitleText>
        )}
        {(subTitleReady || subTitle === '' || inverseTitles) && (
          <TitleText>
            <LiveText
              text={title}
              staticText={backMode}
              onFinish={() => {
                if (inverseTitles && subTitle) {
                  setTitleReady(true)
                } else {
                  setShowInput(true)
                }
              }}
            />
          </TitleText>
        )}
        {subTitle && titleReady && inverseTitles && (
          <SubTitleText>
            <LiveText
              staticText={backMode}
              text={subTitle}
              onFinish={() => setShowInput(true)}
            />
          </SubTitleText>
        )}
        {!isMobileOnly && !isSmallScreen && showInput && (
          <>
            <ButtonsContainer>
              {canGoBack && (
                <BackButtonStyled src={ArrowImage} onClick={onGoBack} alt="back" />
              )}
              <NavButtonStyled
                onClick={onGoNext}
                disabled={disabled}
                hideIcon={isMobileOnly}
              >
                Next
              </NavButtonStyled>
              {showError && (
                <ErrorContainer left={0} top={72}>
                  {errorText}
                </ErrorContainer>
              )}
            </ButtonsContainer>
          </>
        )}
      </QuestionContainer>
      {showInput && (
        <InputContainer>
          {children}
          {(isMobileOnly || isSmallScreen) && (
            <>
              <BottomComponent disabled={disabled} onNext={onGoNext} onBack={onGoBack} />
              {showError && <ErrorContainer>{errorText}</ErrorContainer>}
            </>
          )}
        </InputContainer>
      )}
    </ComponentContainer>
  )
}

StepLayout.defaultProps = {
  disabled: false,
  title: '',
  subTitle: '',
  canGoBack: true,
  errorText: '',
  showError: false,
  inverseTitles: false,
  backMode: false,
  goBack: () => {},
  goNext: () => {},
}

export default StepLayout
