import httpClient from '../../httpClient'

// conversations#generate_token - POST /conversations/generate_token

export interface IConversationToken {
  accessToken: string
  expiresIn: number
}

export const generateConversationToken = (): Promise<IConversationToken> => {
  return httpClient
    .post(`/conversations/generate_token`)
    .then((response) => response.data)
}
