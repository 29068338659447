import { createAction } from '@reduxjs/toolkit'
import { IFailure } from '../utils/redux'
import {
  SAVE_BOOKING_POSITION,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAILURE,
  CREATE_BOOKING_FAILURE_CLEAR,
  CREATE_STUDENT_DETAILS,
  CREATE_STUDENT_DETAILS_SUCCESS,
  CREATE_STUDENT_DETAILS_FAILURE,
  ISaveBookingPositionAction,
  IUpdateBookingAction,
  ICreateBookingAction,
  GET_INTERVIEW,
  GET_INTERVIEW_STATUS,
  GET_INTERVIEW_SUCCESS,
  GET_INTERVIEW_FAILURE,
  GET_AGENCY_BY_SUBDOMAIN,
  GET_AGENCY_BY_SUBDOMAIN_SUCCESS,
  GET_AGENCY_BY_SUBDOMAIN_FAILURE,
  START_INTERVIEW,
  START_INTERVIEW_SUCCESS,
  START_INTERVIEW_FAILURE,
  END_INTERVIEW,
  END_INTERVIEW_SUCCESS,
  END_INTERVIEW_FAILURE,
  REPORT_INTERVIEW,
  REPORT_INTERVIEW_SUCCESS,
  REPORT_INTERVIEW_FAILURE,
  ASSIGN_TIME_SLOT,
  ASSIGN_TIME_SLOT_SUCCESS,
  ASSIGN_TIME_SLOT_FAILURE,
  ASSIGN_TIME_SLOT_SUCCESS_CLEAR,
  ASSIGN_TIME_SLOT_FAILURE_CLEAR,
  CREATE_INTENT,
  CREATE_INTENT_FAILURE,
  CREATE_INTENT_FAILURE_CLEAR,
  CREATE_INTENT_SUCCESS,
  CREATE_INTENT_SUCCESS_CLEAR,
  PAY_SUCCEED,
  PAY_SUCCEED_FAILURE,
  PAY_SUCCEED_FAILURE_CLEAR,
  PAY_SUCCEED_SUCCESS,
  PAY_SUCCEED_SUCCESS_CLEAR,
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_FAILURE_CLEAR,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_SUCCESS_CLEAR,
  IGetInterviewAction,
  IGetInterviewSuccessAction,
  IGetAgencyBySubdomainAction,
  IGetAgencyBySubdomainSuccessAction,
  IStartInterviewAction,
  IGetInterviewStatusAction,
  IStartInterviewSuccessAction,
  IEndInterviewAction,
  IEndInterviewSuccessAction,
  IReportInterviewAction,
  IReportInterviewSuccessAction,
  IAssignTimeSlotAction,
  ICreateIntentSuccessAction,
  ICreateIntentAction,
  IPaySucceedAction,
  IInterviewCodeSharingAction,
  INTERVIEW_CODE_SHARING,
  SUBMIT_AVAILABILITY,
  ISubmitAvailabilityAction,
  SUBMIT_AVAILABILITY_FAILURE,
  SUBMIT_AVAILABILITY_SUCCESS,
  DECLINE_AVAILABILITY,
  DECLINE_AVAILABILITY_SUCCESS,
  DECLINE_AVAILABILITY_FAILURE,
  IDeclineAvailabilityAction,
  PREVENT_INTERVIEW_REDIRECT,
} from './types'

export const saveBookingPosition = createAction<ISaveBookingPositionAction['payload']>(
  SAVE_BOOKING_POSITION
)
export const updateBooking = createAction<IUpdateBookingAction['payload']>(UPDATE_BOOKING)
export const updateBookingSuccess = createAction(UPDATE_BOOKING_SUCCESS)
export const updateBookingFailure = createAction<IFailure>(UPDATE_BOOKING_FAILURE)
export const createBookingFailureClear = createAction(CREATE_BOOKING_FAILURE_CLEAR)
export const createBooking = createAction<ICreateBookingAction['payload']>(
  CREATE_STUDENT_DETAILS
)
export const createBookingSuccess = createAction(CREATE_STUDENT_DETAILS_SUCCESS)
export const createBookingFailure = createAction<IFailure>(CREATE_STUDENT_DETAILS_FAILURE)

export const getInterview = createAction<IGetInterviewAction['payload']>(GET_INTERVIEW)

export const getInterviewStatus = createAction<IGetInterviewStatusAction['payload']>(
  GET_INTERVIEW_STATUS
)
export const getInterviewSuccess = createAction<IGetInterviewSuccessAction['payload']>(
  GET_INTERVIEW_SUCCESS
)

export const getInterviewFailure = createAction<IFailure>(GET_INTERVIEW_FAILURE)
export const getAgencyBySubdomain = createAction<IGetAgencyBySubdomainAction['payload']>(
  GET_AGENCY_BY_SUBDOMAIN
)
export const getAgencyBySubdomainSuccess = createAction<
  IGetAgencyBySubdomainSuccessAction['payload']
>(GET_AGENCY_BY_SUBDOMAIN_SUCCESS)
export const getAgencyBySubdomainFailure = createAction<IFailure>(
  GET_AGENCY_BY_SUBDOMAIN_FAILURE
)
export const interviewCodeSharing = createAction<IInterviewCodeSharingAction['payload']>(
  INTERVIEW_CODE_SHARING
)
export const startInterview = createAction<IStartInterviewAction['payload']>(
  START_INTERVIEW
)
export const startInterviewSuccess = createAction<
  IStartInterviewSuccessAction['payload']
>(START_INTERVIEW_SUCCESS)
export const startInterviewFailure = createAction<IFailure>(START_INTERVIEW_FAILURE)
export const endInterview = createAction<IEndInterviewAction['payload']>(END_INTERVIEW)
export const endInterviewSuccess = createAction<IEndInterviewSuccessAction['payload']>(
  END_INTERVIEW_SUCCESS
)

export const endInterviewFailure = createAction<IFailure>(END_INTERVIEW_FAILURE)
export const reportInterview = createAction<IReportInterviewAction['payload']>(
  REPORT_INTERVIEW
)
export const reportInterviewSuccess = createAction<
  IReportInterviewSuccessAction['payload']
>(REPORT_INTERVIEW_SUCCESS)
export const reportInterviewFailure = createAction<IFailure>(REPORT_INTERVIEW_FAILURE)

export const assignTimeSlot = createAction<IAssignTimeSlotAction['payload']>(
  ASSIGN_TIME_SLOT
)
export const assignTimeSlotSuccess = createAction(ASSIGN_TIME_SLOT_SUCCESS)
export const assignTimeSlotFailure = createAction<IFailure>(ASSIGN_TIME_SLOT_FAILURE)
export const assignTimeSlotSuccessClear = createAction(ASSIGN_TIME_SLOT_SUCCESS_CLEAR)
export const assignTimeSlotFailureClear = createAction(ASSIGN_TIME_SLOT_FAILURE_CLEAR)

export const createIntent = createAction<ICreateIntentAction['payload']>(CREATE_INTENT)
export const createIntentSuccess = createAction<ICreateIntentSuccessAction['payload']>(
  CREATE_INTENT_SUCCESS
)
export const createIntentFailure = createAction<IFailure>(CREATE_INTENT_FAILURE)
export const createIntentSuccessClear = createAction(CREATE_INTENT_SUCCESS_CLEAR)
export const createIntentFailureClear = createAction(CREATE_INTENT_FAILURE_CLEAR)

export const createPayment = createAction(CREATE_PAYMENT)
export const createPaymentSuccess = createAction(CREATE_PAYMENT_FAILURE)
export const createPaymentFailure = createAction<IFailure>(CREATE_PAYMENT_FAILURE_CLEAR)
export const createPaymentSuccessClear = createAction(CREATE_PAYMENT_SUCCESS)
export const createPaymentFailureClear = createAction(CREATE_PAYMENT_SUCCESS_CLEAR)

export const paySucceed = createAction<IPaySucceedAction['payload']>(PAY_SUCCEED)
export const paySucceedSuccess = createAction(PAY_SUCCEED_SUCCESS)
export const paySucceedFailure = createAction<IFailure>(PAY_SUCCEED_FAILURE)
export const paySucceedSuccessClear = createAction(PAY_SUCCEED_SUCCESS_CLEAR)
export const paySucceedFailureClear = createAction(PAY_SUCCEED_FAILURE_CLEAR)
export const submitAvailability = createAction<ISubmitAvailabilityAction['payload']>(
  SUBMIT_AVAILABILITY
)
export const submitAvailabilitySuccess = createAction(SUBMIT_AVAILABILITY_SUCCESS)
export const submitAvailabilityFailure = createAction<IFailure>(
  SUBMIT_AVAILABILITY_FAILURE
)
export const declineAvailability = createAction<IDeclineAvailabilityAction['payload']>(
  DECLINE_AVAILABILITY
)
export const declineAvailabilitySuccess = createAction(DECLINE_AVAILABILITY_SUCCESS)
export const declineAvailabilityFailure = createAction<IFailure>(
  DECLINE_AVAILABILITY_FAILURE
)
export const preventInterviewRedirect = createAction(PREVENT_INTERVIEW_REDIRECT)
