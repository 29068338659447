import styled from '@emotion/styled'
import { Link as LinkComponent } from 'react-router-dom'
import colors from '../static/colors'

interface IError {
  type: string
  message: string
  ref: any
}

export const Text = styled.p`
  color: ${colors.formHint};
  font-size: 12px;
  line-height: 24px;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 18px;
  }
`

export const TextBold = styled(Text)`
  white-space: pre-line;
  font-weight: 500;
`

export const LinesText = styled(Text)`
  white-space: pre-line;
`

export const Title = styled.h2`
  color: #222b45;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 6px;
  color: ${colors.title};
  white-space: pre-line;

  @media screen and (max-width: 600px) {
    padding-bottom: 10px;
  }
`

export const Subtitle = styled.h4`
  margin-bottom: 24px;
  color: #3b424f;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 0;
`

export const Link = styled(LinkComponent)`
  color: ${colors.link};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;
  display: inline-block;

  &:hover {
    color: ${colors.link};
    text-decoration: none;
  }
`
export const RegularLink = styled.a`
  color: ${colors.link};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-decoration: underline;
  display: inline-block;

  &:hover {
    color: ${colors.link};
    text-decoration: none;
  }
`

export const RegularHyperLink = styled(RegularLink)``.withComponent('a')
export const HyperLink = styled(Link)``.withComponent('a')
