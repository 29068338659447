import styled from '@emotion/styled'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { avaRetryReportGenerationAction } from '../../../redux'
import { Button } from '../../../components'

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
  gap: 5px;
  border-radius: 10px;
  flex: 1;
`

const Text = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
`

const Retry = styled(Button)`
  width: fit-content;
  padding: 0 50px;
  border-width: 2px;
  font-size: 14px;
  border-radius: 10px;
  height: 48px;
`

export default function RetryReport() {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const handleRetry = () => {
    dispatch(avaRetryReportGenerationAction({ interviewToken }))
  }

  return (
    <Container>
      <Text>We are currently experiencing issues loading this section.</Text>
      <Retry onClick={handleRetry} title="Try again" customTheme="outline" />
    </Container>
  )
}
