import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { IMicrophoneAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng'
import SvgAudioMute from 'src/static/components/SvgAudioMute'
import { useSelector } from 'react-redux'

import audioMutedIcon from 'src/static/candidate_cockpit_muted.png'
import peopleIcon from 'src/static/icUser.png'

import Bowser from 'bowser'
import * as interviewSelectors from '../../../interview/selectors'
import { Text as MobileText } from '../MobileText'
import Volume from '../volume'
import VideoMutedOverlay from './VideoMutedOverlay'
import { LocalNetworkQualityIndicator } from '../volume/NetworkQualityIndicator'
import BlurIcon from '../../../static/ic-blur.png'

const Container = styled.div`
  position: relative;
  display: block;
  align-items: center;
  flex: 1;
  flex-grow: 0;
`

const Video = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: scale(-1, 1);
  height: 100%;

  div {
    background-color: ${isMobileOnly ? 'black' : 'transparent'} !important;
    border-radius: ${isMobileOnly ? '0' : '0.75rem'} !important;

    video {
      border-radius: ${isMobileOnly ? '0' : '0.75rem'} !important;
    }
  }
`
interface IVideoContainerProps {
  height: number
  width: number
}

const VideoContainer = styled.div<IVideoContainerProps>`
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  top: 22px;
  height: 60px;
  right: 30px;
  width: 60px;
  @media screen and (max-width: 2500px) {
    top: 18px;
    height: 50px;
    right: 25px;
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    top: 15px;
    height: 40px;
    right: 20px;
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    top: 11px;
    height: 30px;
    right: 15px;
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    top: 7px;
    height: 20px;
    right: 10px;
    width: 20px;
  }
`

const RightBottomContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  bottom: 22px;
  height: 60px;
  right: 30px;
  width: 60px;
  @media screen and (max-width: 2500px) {
    bottom: 18px;
    height: 50px;
    right: 25px;
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    bottom: 15px;
    height: 40px;
    right: 20px;
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    bottom: 11px;
    height: 30px;
    right: 15px;
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    bottom: 7px;
    height: 20px;
    right: 10px;
    width: 20px;
  }
`

const LeftTopContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  top: 22px;
  height: 45px;
  left: 30px;
  width: 60px;
  @media screen and (max-width: 2500px) {
    top: 18px;
    height: 37.5px;
    left: 25px;
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    top: 15px;
    height: 30px;
    left: 20px;
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    top: 11px;
    height: 22.5px;
    left: 15px;
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    top: 7px;
    height: 15px;
    left: 10px;
    width: 20px;
  }
`

const MuteOverlay = styled.div`
  align-items: center;
  color: white;
  background: black;
  border-radius: 0.75rem;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  font-size: 21px;
  @media screen and (max-width: 2500px) {
    font-size: 17px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 10px;
  }
  @media screen and (max-width: 900px) {
    font-size: 7px;
  }
`

const MuteIcon = styled.img`
  background: black;
  border-radius: 50%;
  height: 100%;
  margin-right: 5px;
  opacity: 0.8;
`

const MobileLeftTopContainer = styled.div`
  align-items: center;
  display: flex;
  height: 18px;
  position: absolute;
  left: 10px;
  top: 8px;
  width: 24px;
`

const MobileButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 24px;
`

const MobileMuteOverlay = styled(MobileText)`
  align-items: center;
  color: white;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  font-size: 20px;
  @media (orientation: landscape) {
    font-size: 14px;
  }
`

const MobileMuteOverlayAvatar = styled.div`
  align-items: center;
  background-color: #353c5a;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const MobileAvatarLogo = styled.img`
  border-radius: 50px;
  height: 100px;
  margin-bottom: 12px;
  width: 100px;
`

const MobileAvatarDefault = styled.img`
  background: white;
  border-radius: 50px;
  height: 100px;
  margin-bottom: 12px;
  padding: 35px;
  width: 100px;
`

const MobileUsername = styled(MobileText)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`

const BlurButton = styled.div<{ isEnabled: boolean }>`
  width: 43px;
  height: 35px;
  background: white;
  border: 5px solid
    ${({ isEnabled }) => (isEnabled ? 'rgba(105, 56, 170, 1)' : '#f5f6f8')};
  border-radius: 12px;
  cursor: pointer;
  padding: 5px;
`

const BlurImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
`

interface IMobileProps extends React.HTMLProps<HTMLDivElement> {
  id: string
  videoMuted: boolean
  audioMuted: boolean
  track: IMicrophoneAudioTrack | IRemoteAudioTrack
  dimensions: {
    height: number
    width: number
  }
  hasJoined: boolean
}

interface IProps extends IMobileProps {
  onBlurClicked: () => void
  isBackgroundBlurEnabled: boolean
  canBlur: boolean
}

export const PreviewVideo = ({
  id,
  track,
  videoMuted,
  audioMuted,
  dimensions: { height, width },
  children,
  hasJoined,
  onBlurClicked,
  isBackgroundBlurEnabled,
  canBlur,
}: IProps) => {
  const preventRedirect = useSelector(interviewSelectors.preventRedirect)
  const [isBrowserSupported, setIsBrowserSupported] = useState(false)
  const interview = useSelector(interviewSelectors.interview)
  const logo = interview.profile.logo?.url
  const username = interview.profile.first_name

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const res = browser.satisfies({
      chrome: '>=74',
    })
    setIsBrowserSupported(res)
  }, [])

  return (
    <Container>
      <VideoContainer height={height} width={width}>
        <MuteOverlay>Camera is starting</MuteOverlay>
        <Video id={id} />
        {videoMuted && !hasJoined && <MuteOverlay>Camera is off</MuteOverlay>}
        {videoMuted && (hasJoined || preventRedirect) && (
          <VideoMutedOverlay hasOverlay logo={logo} username={username} />
        )}
        <LeftTopContainer>
          <LocalNetworkQualityIndicator />
        </LeftTopContainer>
        {isBrowserSupported && canBlur && (
          <RightBottomContainer>
            <BlurButton isEnabled={isBackgroundBlurEnabled} onClick={onBlurClicked}>
              <BlurImage src={BlurIcon} />
            </BlurButton>
          </RightBottomContainer>
        )}
        <ButtonsContainer>
          {!audioMuted && (
            <Volume.AudioVolumeIndicator element={undefined} track={track} />
          )}
          {audioMuted && <MuteIcon src={audioMutedIcon} />}
        </ButtonsContainer>
      </VideoContainer>
      {children}
    </Container>
  )
}

export const MobilePreviewVideo = ({
  id,
  track,
  videoMuted,
  audioMuted,
  dimensions: { height, width },
  hasJoined,
}: IMobileProps) => {
  const interview = useSelector(interviewSelectors.interview)
  const logo = interview.profile.logo?.url
  const username = interview.profile.first_name
  const hint = 'Camera is off'

  return (
    <Container>
      <VideoContainer height={height} width={width}>
        <Video id={id} />
        {videoMuted && !hasJoined && <MobileMuteOverlay>{hint}</MobileMuteOverlay>}
        {videoMuted && hasJoined && (
          <MobileMuteOverlayAvatar>
            {logo ? (
              <MobileAvatarLogo src={logo} />
            ) : (
              <MobileAvatarDefault src={peopleIcon} />
            )}
            <MobileUsername>{username}</MobileUsername>
          </MobileMuteOverlayAvatar>
        )}
        <MobileLeftTopContainer>
          <LocalNetworkQualityIndicator />
        </MobileLeftTopContainer>
        <MobileButtonsContainer>
          {!audioMuted && (
            <Volume.AudioVolumeIndicator element={undefined} track={track} />
          )}
          {audioMuted && <SvgAudioMute />}
        </MobileButtonsContainer>
      </VideoContainer>
    </Container>
  )
}
