import { put, takeEvery, call } from 'typed-redux-saga'

import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { requestDemo } from '../../api'

const {
  action: requestDemoAction,
  actionFailure: requestDemoActionFailure,
  actionSuccess: requestDemoActionSuccess,
  actionSuccessClear: requestDemoActionSuccessClear,
  actionFailureClear: requestDemoActionFailureClear,
  actionType: requestDemoActionType,
  actionTypeFailure: requestDemoActionFailureType,
  actionTypeSuccess: requestDemoActionSuccessType,
  reducer: requestDemoRequestReducer,
  selectors: requestDemoSelectors,
} = reduxRequestFactory<string>('requestDemo', 'requests')

export {
  requestDemoAction,
  requestDemoActionFailure,
  requestDemoActionSuccess,
  requestDemoActionSuccessClear,
  requestDemoActionFailureClear,
  requestDemoActionType,
  requestDemoActionFailureType,
  requestDemoActionSuccessType,
  requestDemoRequestReducer,
  requestDemoSelectors,
}

function* requestDemoSaga({ payload }: ISagaAction<string>) {
  try {
    const email = payload
    yield* call(requestDemo, email)
    yield put(requestDemoActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      requestDemoActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchRequestDemo() {
  yield takeEvery(requestDemoActionType, requestDemoSaga)
}
