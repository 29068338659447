import httpClient from '../../httpClient'

// agora_recordings#generate_rtc_token - POST /agora_recordings/generate_rtc_token

export const getAgoraToken = (uid: number, channel: string) => {
  return httpClient
    .post('/agora_recordings/generate_rtc_token', { channel, uid })
    .then((response) => response.data)
}

// agora_recordings#generate_rtm_token - POST /agora_recordings/generate_rtm_token

export const getRTMAgoraToken = (user: string) => {
  return httpClient
    .post('/agora_recordings/generate_rtm_token', { user })
    .then((response) => response.data)
}
