import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgQuote({ fill }: SvgProps) {
  return (
    <svg
      width="43"
      height="28"
      viewBox="0 0 43 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="&#226;&#128;&#156;"
        d="M19.0312 8.21875C16.7604 8.21875 15.1146 9.29167 14.0938 11.4375C13.6771 12.3958 13.4375 13.4688 13.375 14.6562H19.0312V27.8438H0V15.25C0 7.875 3.48958 3.1875 10.4688 1.1875C12.8646 0.5 15.7188 0.15625 19.0312 0.15625V8.21875ZM42.5 8.21875C40.1042 8.21875 38.4583 9.29167 37.5625 11.4375C37.1458 12.3958 36.9167 13.4688 36.875 14.6562H42.5V27.8438H23.5V15.25C23.5 7.875 26.9792 3.1875 33.9375 1.1875C36.3333 0.5 39.1875 0.15625 42.5 0.15625V8.21875Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgQuote
