import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { IMicrophoneAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'

const AnimationLengthMs = 200
const CheckIntervalMs = 100

const grow = (h) => keyframes`
  from {
    height: 25%;
  }

  to {
    height: ${h}%;
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  height: ${!isMobileOnly ? '50%' : '100%'};
  justify-content: space-between;
  width: ${!isMobileOnly ? '50%' : '100%'};
`

const LevelItem = styled.div<{ maxHeight: 60 | 80; value: number }>`
  background: #b202ff;
  border-radius: 100px;
  height: 25%;
  width: 25%;
`

const LevelItemAninated = styled(LevelItem)`
  animation: ${(props) => grow(props.maxHeight)} ${AnimationLengthMs}ms linear infinite
    alternate;
`

interface IProps {
  element: HTMLAudioElement
  track: IMicrophoneAudioTrack | IRemoteAudioTrack
}

const AudioVolumeIndicator = ({ element, track }: IProps) => {
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    if (!element) return undefined
    const interval = setInterval(() => {
      const level = element.volume
      setVolume(level)
    }, CheckIntervalMs)
    return () => clearInterval(interval)
  }, [element])

  useEffect(() => {
    if (!track) return undefined
    const interval = setInterval(() => {
      const level = track?.getVolumeLevel()
      setVolume(level)
    }, CheckIntervalMs)
    return () => clearInterval(interval)
  }, [track])

  return (
    <Container>
      {(element || track) && volume > 0.01 ? (
        <>
          <LevelItemAninated maxHeight={60} value={volume} />
          <LevelItemAninated maxHeight={80} value={volume} />
          <LevelItemAninated maxHeight={60} value={volume} />
        </>
      ) : (
        <>
          <LevelItem maxHeight={60} value={volume} />
          <LevelItem maxHeight={80} value={volume} />
          <LevelItem maxHeight={60} value={volume} />
        </>
      )}
    </Container>
  )
}

export default AudioVolumeIndicator
