import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const Container = styled.div``

const BallItem = styled.span`
  background: #b202ff;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  margin: 0 2px;
  display: inline-block;

  ${isMobileOnly &&
  css`
    background: #fff;
    width: 8px;
    height: 8px;
    margin: 0 5px;
  `}

  &:nth-child(1) {
    animation: bounce 1s ease-in-out infinite;
  }
  &:nth-child(2) {
    animation: bounce 1s ease-in-out 0.33s infinite;
  }
  &:nth-child(3) {
    animation: bounce 1s ease-in-out 0.66s infinite;
  }

  @keyframes bounce {
    0%,
    75%,
    100% {
      transform: translateY(0);
    }

    25% {
      transform: translateY(-10px);
    }
  }
`

const BallsAnimation = () => {
  return (
    <Container>
      <BallItem />
      <BallItem />
      <BallItem />
    </Container>
  )
}

export default BallsAnimation
