import styled from '@emotion/styled'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { AnimatedBase } from './styled-components'

const Container = styled.div<{ width?: number }>`
  position: relative;
  display: flex;
  width: ${({ width }) => width ?? 100}%;
  justify-content: space-between;
  margin-bottom: 24px;
`

const InputStyled = styled.input`
  border: none;
  width: 100%;
  background: transparent;
  padding: 12px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  color: black;
  border-bottom: 0.5px solid #000000;
  outline: none;
  animation: fadeInFromNone 0.5s ease-out;

  &:placeholder {
    opacity: 0.8;
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

const PlaceholderStyled = styled.div<{ focused: boolean }>`
  position: absolute;
  pointer-events: none;
  left: 0;
  bottom: 12px;
  font-weight: 500;
  font-size: ${({ focused }) => (focused ? 10 : 14)}px;
  line-height: 22px;
  color: black;
  animation: fadeInFromNone 0.5s ease-out;
  transform: translateY(${({ focused }) => (focused ? -22 : 0)}px);
  transition: all 0.15s linear;

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

const Error = styled(AnimatedBase)<{ toTheRight?: boolean; isSmallScreen?: boolean }>`
  position: absolute;
  right: ${({ toTheRight, isSmallScreen }) =>
    !isSmallScreen || (toTheRight && !isMobileOnly) ? `0px` : 'unset'};
  left: ${({ toTheRight, isSmallScreen }) =>
    isSmallScreen && !toTheRight ? `0px` : 'unset'};
  top: ${({ toTheRight, isSmallScreen }) =>
    isSmallScreen && !toTheRight ? `12px` : '48px'};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff002a;
  pointer-events: none;
`

const OptionalLabel = styled.span`
  color: #000;
  font-style: italic;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 1px;
  opacity: 0.5;
  margin-left: 6px;
`

interface IProps {
  width?: number
  placeholder?: string
  maxLength?: number
  value?: string
  errorText?: string
  showError?: boolean
  optional?: boolean
  onChange?: (data: string) => void
}

function StyledInput({
  value,
  width,
  maxLength,
  placeholder,
  errorText,
  showError,
  optional,
  onChange,
}: IProps) {
  const [focused, setFocused] = useState(false)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' }) || isMobileOnly
  return (
    <Container width={width}>
      <InputStyled
        maxLength={maxLength}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
      />
      <PlaceholderStyled
        focused={focused || (value && value !== '') || (isSmallScreen && showError)}
      >
        {placeholder}
        {optional && <OptionalLabel>(Optional)</OptionalLabel>}
      </PlaceholderStyled>
      {showError && (
        <Error toTheRight={value?.length > 0} isSmallScreen={isSmallScreen}>
          {errorText}
        </Error>
      )}
    </Container>
  )
}

StyledInput.defaultProps = {
  width: 100,
  placeholder: '',
  maxLength: undefined,
  value: '',
  errorText: '',
  optional: false,
  showError: false,
  onChange: () => {},
}

export default StyledInput
