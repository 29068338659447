import styled from '@emotion/styled'

export const Text = styled.p`
  color: ${({ theme }: { theme: any }) => theme.textColor};
  font-family: 'Rubik';
  margin: 0;
`

export const Title = styled(Text)`
  font-family: 'Helvetica';
`
