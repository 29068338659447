/* eslint-disable no-console */
import { ITranscriptText } from '../types'

export const wordsCountPatern = /(\d+\.\d+)|[\w-']+/g

export const dataAnalyser = () => {
  const applyPattern = (
    patternWords: string[],
    data: ITranscriptText[],
    divideByWords: boolean = true,
    divider: number = 1,
    debug: boolean = false
  ) => {
    const patternStrings = patternWords.map((word) => {
      if (!word) return ''
      const part = `[${word[0].toUpperCase()}${word[0].toLowerCase()}]${word
        .substring(1)
        .replace(/\s/g, '\\s')}`
      return part
    })
    let count = 0
    let wordsCount = 0
    if (data.length > 0) {
      data.forEach((text) => {
        patternStrings.forEach((paternString) => {
          if (paternString) {
            const pattern = new RegExp(`(?:^|\\W)(${paternString})(?:$|\\W)`, 'g')
            const matches = text.text.match(pattern) || []
            count += matches.length
          }
        })
        const wMatches = text.text.match(wordsCountPatern) || []
        wordsCount += wMatches.length
      })
      if (debug) {
        console.log('[dataAnalyser][pattern words]', patternWords)
        console.log('[dataAnalyser][data]', data)
        console.log('[dataAnalyser][matched words]', count)
        console.log('[dataAnalyser][total words]', wordsCount)
        console.log('[dataAnalyser][divideByWords]', divideByWords)
        console.log('[dataAnalyser][divider]', divider)
      }
      const result =
        Math.round((count / (divideByWords ? wordsCount : 1)) * divider * 100) / 100
      if (debug) {
        console.log('[dataAnalyser][result]', result)
      }
      return result
    }
    return count
  }

  const getDataUsage = (data: ITranscriptText[], vocabulary: string[]) => {
    const result = applyPattern(vocabulary, data, true, 1000)
    console.log('[DataUsage]', result)
    return result
  }

  const getConfidentLevel = (data: ITranscriptText[], vocabulary: string[]) => {
    const result = applyPattern(vocabulary, data, true, 1000)
    console.log('[Confident]', result)
    return result
  }

  const getPronounAnalysis = (
    data: ITranscriptText[],
    vocabularyA: string[],
    vocabularyB: string[]
  ) => {
    const aValue = applyPattern(vocabularyA, data, false)
    const bValue = applyPattern(vocabularyB, data, false)
    const result = Math.round((aValue / (aValue + bValue)) * 100)
    console.log('[Pronoun]', result)
    return result
  }

  const getTenseAnalysis = (
    data: ITranscriptText[],
    pastVocabulary: string[],
    presentVocabulary: string[],
    futureVocabulary: string[]
  ) => {
    const aValue = applyPattern(pastVocabulary, data, false)
    const bValue = applyPattern(presentVocabulary, data, false)
    const cValue = applyPattern(futureVocabulary, data, false)
    const result = Math.round((aValue / (aValue + bValue + cValue)) * 100)
    console.log('[Tense]', result)
    return result
  }

  const getSentimentLevel = (data: ITranscriptText[]) => {
    let weightSum = 0
    let scoreSum = 0
    data.forEach((t) => {
      const wMatches = t.text.match(wordsCountPatern) || []
      const score = t.sentimentScore * wMatches.length
      weightSum += wMatches.length
      scoreSum += score
    })
    const result = scoreSum / weightSum
    console.log('[Sentiment]', result)
    return result
  }

  const getWPM = (data: ITranscriptText[]) => {
    let wordsCount = 0
    let totalLength = 0
    data.forEach((t) => {
      totalLength += t.lengthMs
      const wMatches = t.text.match(wordsCountPatern) || []
      wordsCount += wMatches.length
    })
    const result = Math.round((wordsCount / totalLength) * 1000 * 60)
    console.log('[WPM]', result)
    return result
  }

  const getWordsCount = (data: string[]) => {
    let wordsCount = 0
    data.forEach((t) => {
      const wMatches = t.match(wordsCountPatern) || []
      wordsCount += wMatches.length
    })
    return wordsCount
  }

  return {
    getDataUsage,
    getConfidentLevel,
    getPronounAnalysis,
    getTenseAnalysis,
    getSentimentLevel,
    applyPattern,
    getWPM,
    getWordsCount,
  }
}
