import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Heading = styled.h2``

function NotAllowed() {
  return (
    <Container>
      <Heading>You do not have permissions to view this page</Heading>
    </Container>
  )
}

export default NotAllowed
