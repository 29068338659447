import React, { useState } from 'react'
import styled from '@emotion/styled'
import eye from '../static/eye.svg'
import {
  Container,
  InputStyled,
  LabelsContainer,
  Label,
  OptionalLabel,
  Revealer,
  Error,
} from './input-styles'

interface ILabelsContainer {
  isSelected: boolean
}

const LabelsContainerStyled = styled(LabelsContainer)<ILabelsContainer>`
  pointer-events: none;
  position: absolute;
  left: 6px;
  top: 6px;
  margin: 0;
  width: calc(100% - 12px);
  padding: 0 10px;
  z-index: 100;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  background-color: transparent;
`
export const ExtendedLabel = styled.label`
  display: inline-block;
  line-height: 20px;
  transition: transform 150ms ease-in, font-size 150ms ease-in;
  opacity: 0.5;
  color: #21304e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  pointer-events: none;
  margin: 0;
  min-width: 140px;
  white-space: nowrap;
`

const InputStyledComponent = styled(InputStyled)`
  &:focus + div > label {
    transform: translateY(-3px);
    line-height: 12px;
    white-space: nowrap;
  }
  & + div > label {
    transform: translateY(-3px);
    line-height: 12px;
    white-space: nowrap;
  }
  & + div > span {
    position: relative;
    top: -3px;
    font-size: 12px;
    opacity: ${({ isSelected }) => (isSelected ? '0' : 'none')};
  }

  ::placeholder {
    color: #8992a3;
    font-family: Rubik;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 16px;
    font-weight: 400;
  }
`

const Input = React.forwardRef((props: any, ref: any) => {
  const {
    name,
    error,
    type,
    onChange,
    onBlur,
    value,
    disabled,
    label,
    disableWhitespaces,
    optional,
    customTheme,
    useTheme,
    placeholder,
    hidden,
    extended = false,
  } = props
  const [revealed, setRevealed] = useState(false)
  const hasError = !!error.type

  if (extended) {
    return (
      <Container disabled={disabled} hidden={hidden} hasError={hasError}>
        <InputStyledComponent
          useTheme={useTheme}
          disabled={disabled}
          onBlur={onBlur}
          name={name}
          type={revealed ? 'text' : type}
          ref={ref}
          placeholder={placeholder}
          onChange={
            !disableWhitespaces
              ? (e) => onChange(e.target.value)
              : (e) => {
                  e.target.value = e.target.value.replace(/\s/gim, '')
                  onChange(e.target.value)
                }
          }
          value={value}
          hasError={hasError}
          isSelected={!!value}
          customTheme={customTheme}
        />
        <LabelsContainerStyled isSelected={!!value}>
          <ExtendedLabel>{label}</ExtendedLabel>
          {optional && <OptionalLabel>Optional</OptionalLabel>}
        </LabelsContainerStyled>
        {type === 'password' && (
          <Revealer
            title="Toggle password visibility"
            src={eye}
            onClick={() => setRevealed(!revealed)}
          />
        )}
        {error && error!.type !== 'required' && <Error>{error.message}</Error>}
        {error && error!.type === 'required' && <Error>Required</Error>}
      </Container>
    )
  }

  return (
    <Container hidden={hidden} disabled={disabled} hasError={hasError}>
      <InputStyled
        useTheme={useTheme}
        disabled={disabled}
        onBlur={onBlur}
        name={name}
        type={revealed ? 'text' : type}
        ref={ref}
        onChange={
          !disableWhitespaces
            ? (e) => onChange(e.target.value)
            : (e) => {
                e.target.value = e.target.value.replace(/\s/gim, '')
                onChange(e.target.value)
              }
        }
        value={value}
        hasError={hasError}
        isSelected={value !== null}
        customTheme={customTheme}
      />
      <LabelsContainer>
        <Label>{label}</Label>
        {optional && <OptionalLabel>Optional</OptionalLabel>}
      </LabelsContainer>
      {type === 'password' && (
        <Revealer
          title="Toggle password visibility"
          src={eye}
          onClick={() => setRevealed(!revealed)}
        />
      )}
      {error && error!.type !== 'required' && <Error>{error.message}</Error>}
      {error && error!.type === 'required' && <Error>Required</Error>}
    </Container>
  )
})

Input.defaultProps = {
  error: {
    type: '',
    message: '',
    ref: '',
  },
  type: 'text',
  optional: false,
  useTheme: false,
  customTheme: '',
  hidden: false,
}

export default Input
