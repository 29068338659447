import React, { useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'

import SvgClose from 'src/static/close_gray.svg'

const MainSidebar = styled.div<{
  hasJoined: boolean
  height: number
  inCodeSharing: boolean
  isExpert: boolean
  visible: boolean
}>`
  align-self: center;
  display: flex;
  height: ${({ hasJoined, height }) => (hasJoined ? `${height}px` : `100%`)};
  margin-bottom: ${({ hasJoined, inCodeSharing, isExpert }) => {
    if (isExpert && inCodeSharing) return '1.5rem'
    return hasJoined && !isExpert ? '5vh' : '0'
  }};
  position: relative;
  width: ${({ visible }) => (visible ? '20vw' : '0')};
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
`

const SidebarClose = styled.img`
  cursor: pointer;
  position: absolute;

  height: 15px;
  width: 15px;
  right: 39px;
  top: 45px;
  @media screen and (max-width: 2500px) {
    height: 12px;
    width: 12px;
    right: 32px;
    top: 38px;
  }
  @media screen and (max-width: 2000px) {
    height: 10px;
    width: 10px;
    right: 26px;
    top: 30px;
  }
  @media screen and (max-width: 1200px) {
    height: 7px;
    width: 7px;
    right: 20px;
    top: 23px;
  }
  @media screen and (max-width: 900px) {
    height: 5px;
    width: 5px;
    right: 13px;
    top: 15px;
  }
`

interface ISidebar extends React.HTMLProps<HTMLDivElement> {
  hasJoined: boolean
  height: number
  inCodeSharing: boolean
  isExpert: boolean
  visible: boolean
  onClose: () => void
}

export function Sidebar({
  children,
  hasJoined,
  height,
  inCodeSharing,
  isExpert,
  visible,
  onClose,
}: ISidebar) {
  return (
    <MainSidebar
      hasJoined={hasJoined}
      height={height}
      inCodeSharing={inCodeSharing}
      isExpert={isExpert}
      visible={visible}
    >
      {visible && (
        <>
          {children}
          <SidebarClose onClick={onClose} src={SvgClose} />
        </>
      )}
    </MainSidebar>
  )
}

const MobileContainer = styled.div<{ hasMask: boolean; isMenu: boolean }>`
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 999999999;

  @media (orientation: landscape) {
    margin-bottom: 0;
    align-items: center;
    background: ${({ theme }: any) => theme.sidebarMaskColorLandscape};
    display: flex;
    flex-direction: column;
    height: calc(100% - 67px);
    justify-content: flex-end;

    > div {
      width: 75%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
`

const MobileCloseContainer = styled.div<{ hasMask: boolean; isMenu: boolean }>`
  align-items: center;
  position: absolute;
  top: 5px;
  right: 29px;
  width: 40px;
  height: 40px;
  background: ${({ hasMask, isMenu, theme }: any) =>
    isMenu && hasMask ? 'none' : theme.sidebarBackgroundColor};
  display: flex;
  justify-content: center;

  @media (orientation: landscape) {
    top: 5px;
    right: 10px;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
  }
`

const MobileCloseIcon = styled.img`
  height: 15px;
  width: 15px;
`

function useComponentVisible() {
  const [isComponentVisible, setIsComponentVisible] = useState(true)
  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      event.stopPropagation()
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}
interface IDrawerMobile extends React.HTMLProps<HTMLDivElement> {
  hasMask: boolean
  isMenu: boolean
  onClose: () => void
}

export function DrawerMobile({ children, hasMask, isMenu, onClose }: IDrawerMobile) {
  const { ref, isComponentVisible } = useComponentVisible()

  useEffect(() => {
    if (!isComponentVisible) onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible])

  return (
    <MobileContainer hasMask={hasMask} isMenu={isMenu} ref={ref}>
      {children}
      <MobileCloseContainer hasMask={hasMask} isMenu={isMenu}>
        <MobileCloseIcon onClick={onClose} src={SvgClose} />
      </MobileCloseContainer>
    </MobileContainer>
  )
}
