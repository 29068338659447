import React, { useState } from 'react'

import PasswordComponent from '../components/PasswordComponent'
import SystemSettings from '../components/SystemSettings'
import Layout from '../Layout'

const SettingsPsw = 'Wizc0Amen'

function SettingsContainer() {
  const [isAuthorized, setIsAuthorized] = useState(false)

  return (
    <Layout>
      <>
        {!isAuthorized && (
          <PasswordComponent
            password={SettingsPsw}
            onEnter={() => setIsAuthorized(true)}
          />
        )}
        {isAuthorized && <SystemSettings />}
      </>
    </Layout>
  )
}

export default SettingsContainer
