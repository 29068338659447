import styled from '@emotion/styled'

import { Text } from '../MobileText'

export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  & svg {
    display: block;
    flex: 1;
    margin: auto;
    width: auto;

    max-height: 39px;
    @media screen and (max-width: 2500px) {
      max-height: 32px;
    }
    @media screen and (max-width: 2000px) {
      max-height: 26px;
    }
    @media screen and (max-width: 1200px) {
      max-height: 20px;
    }
    @media screen and (max-width: 900px) {
      max-height: 13px;
    }
  }

  height: 66px;
  min-width: 91px;
  @media screen and (max-width: 2500px) {
    height: 54px;
    min-width: 76px;
  }
  @media screen and (max-width: 2000px) {
    height: 44px;
    min-width: 61px;
  }
  @media screen and (max-width: 1200px) {
    height: 34px;
    min-width: 46px;
  }
  @media screen and (max-width: 900px) {
    height: 22px;
    min-width: 26px;
  }
`

export const HeaderIconContainer = styled.div<{
  disabled: boolean
  isTransparentDisabled?: boolean
}>`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: ${({ disabled, isTransparentDisabled }) =>
    disabled && !isTransparentDisabled ? 0.25 : 1};
`

export const HeaderButtonLabel = styled.p`
  color: ${({ theme }: { theme: any }) => theme.textColor};
  letter-spacing: 0;
  margin: 0;
  text-align: center;
  width: 100%;

  font-size: 15px;
  line-height: 27px;
  @media screen and (max-width: 2500px) {
    font-size: 12px;
    line-height: 22px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 10px;
    line-height: 18px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 8px;
    line-height: 14px;
  }
  @media screen and (max-width: 900px) {
    font-size: 5px;
    line-height: 9px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const MobileHeaderButtonContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;

  & > svg {
    display: block;
    margin: auto;
    width: auto;
  }
`

export const MobileHeaderButtonLabel = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`
