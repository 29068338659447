/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { get } from 'lodash'
import moment from 'moment'
import { format } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import topSectionSvg from 'src/static/sheduling-top.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Text, TextBold, Loader } from 'src/components'
import useLogrocket from 'src/utils/useLogrocket'
import { isMobileOnly } from 'react-device-detect'
import { BusinessModel, IExpertProfile, ITimeSlot, IInterview } from '../../api'
import Slots from '../components/InterviewSlotsList'
import colors from '../../static/colors'
import { candidateRescheduleInterviewAction } from '../../redux'

import * as interviewSelectors from '../selectors'
import ScheduleThankYou from '../components/ScheduleThankYou'
import { assignTimeSlot } from '../actions'
import PaymentDetails from '../components/PaymentDetails'
import { validateAgencyLogoWhite } from '../../utils/validateAgencyLogo'
import InterviewExpertsList from '../components/InterviewExpertsList'
import InterviewExpertsListMobile from '../components/InterviewExpertsListMobile'
import InterviewSlotsListMobile from '../components/InterviewSlotsListMobile'
import SchedulingReschedulePopup from '../components/SchedulingReschedulePopup'

const StyledText = styled(Text)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  @media screen and (max-width: 600px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
`

const MainWrap = styled.div<{ noScroll?: boolean }>`
  overflow: ${({ noScroll }) => (noScroll ? 'hidden' : 'visible')};
  position: relative;
`

const StyledTextBold = styled(TextBold)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
`

const StyledBigTextBold = styled(StyledTextBold)`
  font-size: 16px;
  @media screen and (max-width: 600px) {
    font-size: 23px;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 14px;
  }
`

const StyledLargeTextBold = styled(TextBold)`
  font-size: 32px;
  margin-bottom: 4px;

  @media screen and (max-width: 600px) {
    font-size: 24px;
    margin-bottom: 6px;
  }
`

const LandingWrapper = styled.div`
  color: ${colors.formHint};
  max-width: 1920px;
  margin: 0 auto;
  border: 1px solid transparent;
`
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 0;

  @media screen and (max-width: 600px) {
    margin: 0;
    background-size: cover;
    justify-content: normal;
    background: #cb55ff url(${topSectionSvg}) no-repeat;
    align-items: normal;
    flex-direction: column-reverse;
    padding: 30px 30px 50px;
  }
`
const Promo = styled.div`
  height: 190px;
  width: 820px;
  border-radius: 95px;
  background-color: #f5f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 200px;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    border-radius: 0;
    background: none;
    padding-top: 80px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: #ffdb46;
    width: 190px;
    height: 190px;
    z-index: 1;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 95px;
    background: #f5f6f8;
    width: 100px;
    height: 190px;
    z-index: 1;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`

const VioletBlock = styled.div`
  display: block;
  position: absolute;
  left: 48px;
  top: 48px;
  width: 96px;
  height: 195px;
  background: #b202ff;
  z-index: 2;
  border-radius: 48px;
  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    border-radius: 50%;
    background: #cb55ff;
    width: 96px;
    height: 96px;
    z-index: 3;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`
const PromoTitle = styled(Text)`
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    color: #fff;
    font-size: 34px;
    font-weight: 500;
    line-height: 120%;
    width: 90%;
  }
`
const PromoPaymentTitle = styled(Text)`
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    color: #fff;
    font-size: 36px;
    font-weight: 500;
    line-height: 120%;
    width: 90%;
  }
`
const PromoSubTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const Logo = styled.div`
  height: 54px;

  & img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }

  @media screen and (max-width: 600px) {
    height: 35px;
  }
`

const MiddleSectionTitle = styled.div`
  display: flex;
  margin-bottom: 32px;
`

const MiddleSectionBlock = styled.div<{ width: string }>`
  width: ${({ width }) => width}px;
`

const MiddleSectionMainContent = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    justify-content: normal;
    height: auto;
  }
`
const SlotsWrapper = styled.div`
  @media screen and (max-width: 600px) {
    padding: 0;
    border: none;
    width: 100%;
  }
`

const ExpertsWrapper = styled.div`
  margin-right: 30px;
  border-right: 1px solid #edeef2;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 0;
    border: none;
    width: 100%;
  }
`

const BottomSection = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    margin-bottom: -30px;
  }
`

const NoneSlots = styled(Text)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
`

const NoneSlotsButton = styled(Button)`
  font-size: 14px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 10px;
  margin: 0;
  width: auto;
  margin-left: 15px;
`

const MiddleSection = styled.div`
  margin-top: 80px;
  @media screen and (min-height: 900px) {
    margin-top: 135px;
  }

  @media screen and (max-width: 600px) {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: -30px;
    background: #fff;
    padding: 40px 16px 30px;
  }
`

const ResponsiveWrapper = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    padding: 0;
    margin: 0;
  }
`

const ThankYouWrapper = styled.div`
  display: flex;
  margin-top: 80px;
  @media screen and (min-height: 900px) {
    margin-top: 135px;
  }

  @media screen and (max-width: 600px) {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: -30px;
    background: #fff;
    padding: 40px 30px 30px;
    display: block;
  }
`

const Scheduling = () => {
  const { setLogrocket } = useLogrocket()
  const { interviewToken } = useParams()
  const dispatch = useDispatch()
  const agency = useSelector(interviewSelectors.agency)
  const apiExperts = useSelector(interviewSelectors.experts) || []
  const experts = apiExperts.filter((e) => e.time_slots?.length > 0)
  const [selectedExpert, setSelectedExpert] = useState<IExpertProfile>(
    !isMobileOnly && experts?.length === 1 ? experts[0] : null
  )

  const interview: Partial<IInterview> = get(
    useSelector(interviewSelectors.interview),
    'interview',
    {}
  )
  const interviewDuration = get(interview, 'duration', 60)
  const profile = useSelector(interviewSelectors.profile)
  const assignTimeSlotApiSuccess = useSelector(interviewSelectors.assignTimeSlotSuccess)
  const createPaymentPending = useSelector(interviewSelectors.createPaymentPending)
  const paySucceedSuccess = useSelector(interviewSelectors.paySucceedSuccess)
  const invoicePaid = useSelector(interviewSelectors.invoicePaid)
  // const [selectedSlot, setSelectedSlot] = useState(sortedSlots[0])
  const [selectedForInterview, setSelectedForInterview] = useState<ITimeSlot>()
  const [showNonSlotModal, setShowNonSlotModal] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [showMobileSlots, setShowMobileSlots] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const desiredPosition = get(interview, 'desired_position', '')
  const desiredCompany: string = get(interview, 'company', '')
  const paymentRequired = get(interview, 'payment_required', true)
  const showThanks =
    (paymentRequired && paySucceedSuccess) || !paymentRequired || invoicePaid
  const interviewPosition = `${desiredPosition} ${
    desiredCompany ? `at ${desiredCompany}` : ''
  }`

  const timeZoneGMT = format(new Date(), 'O')

  useEffect(() => {
    if (selectedForInterview) {
      const interviewTimezone = moment.tz.guess()
      setShowPayment(true)
      dispatch(
        assignTimeSlot({
          interviewToken,
          timezone: interviewTimezone,
          slotId: selectedForInterview.id,
        })
      )
    }
  }, [selectedForInterview, dispatch, interviewToken, timeZoneGMT])

  useEffect(() => {
    if (paySucceedSuccess && invoicePaid) {
      setShowPayment(false)
    }
  }, [paySucceedSuccess, invoicePaid, showPayment])

  useEffect(() => {
    if (!interview || !profile) return
    setLogrocket(
      `${interview.order_number.toString()}`,
      `Applicant scheduling page - ${
        selectedForInterview && assignTimeSlotApiSuccess ? 'selection' : 'summary'
      }`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview, profile, selectedForInterview, assignTimeSlotApiSuccess])

  useEffect(() => {
    if (isMobileOnly && selectedExpert) {
      setShowMobileSlots(true)
    }
  }, [selectedExpert])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRescheduled = (payload) => {
    dispatch(
      candidateRescheduleInterviewAction({
        interviewToken,
        ...payload,
      })
    )
    setShowMobileSlots(false)
    setShowNonSlotModal(false)
  }

  const paymentRequiredFunc = () => {
    return <PaymentDetails />
  }

  const conditionalTitleFunc = () => {
    if (selectedForInterview && assignTimeSlotApiSuccess) {
      return isMobile && showPayment && !showThanks && !paySucceedSuccess ? (
        <PromoPaymentTitle>Payment details</PromoPaymentTitle>
      ) : (
        <PromoTitle>It pays to be prepared.</PromoTitle>
      )
    }

    return <PromoTitle>Schedule your session</PromoTitle>
  }

  const logoRenderFunc = () => {
    return !isMobile ? (
      <img src={validateAgencyLogoWhite(agency.company_logo?.url)} alt="Logo" />
    ) : (
      <img src={validateAgencyLogoWhite(agency.negative_logo?.url)} alt="Logo" />
    )
  }

  const sessionPrice =
    selectedExpert && agency.business_model !== BusinessModel.B2B
      ? `${agency.currency_sign}${selectedExpert?.slot_price}`
      : ''

  return (
    <MainWrap>
      {createPaymentPending && <Loader />}
      {showNonSlotModal && (
        <SchedulingReschedulePopup
          onClose={() => setShowNonSlotModal(false)}
          onRescheduled={onRescheduled}
        />
      )}
      {!showMobileSlots && (
        <LandingWrapper>
          <ResponsiveWrapper>
            <TopSection>
              <Promo>
                <VioletBlock />
                {conditionalTitleFunc()}
                <PromoSubTitle>{`Coaching session for ${interviewPosition}`}</PromoSubTitle>
              </Promo>
              <Logo>{logoRenderFunc()}</Logo>
            </TopSection>

            {selectedForInterview && assignTimeSlotApiSuccess ? (
              <ThankYouWrapper>
                {(showPayment || paySucceedSuccess) && (
                  <ScheduleThankYou
                    slot={selectedForInterview}
                    position={interviewPosition}
                    expert={selectedExpert}
                    showThanks={showThanks}
                  />
                )}
                {paymentRequired &&
                  !paySucceedSuccess &&
                  !invoicePaid &&
                  paymentRequiredFunc()}
              </ThankYouWrapper>
            ) : (
              <MiddleSection>
                <MiddleSectionTitle>
                  <MiddleSectionBlock width="530">
                    <StyledLargeTextBold>
                      Meet your {experts.length > 1 ? 'experts' : 'expert'}!
                    </StyledLargeTextBold>
                    <StyledText>
                      {experts.length > 1
                        ? `You have ${experts.length} matches. Select an expert to view their full profile and availability.`
                        : ''}
                    </StyledText>
                  </MiddleSectionBlock>
                  {!isMobileOnly && (
                    <MiddleSectionBlock width="500">
                      <StyledBigTextBold>
                        Select a time slot for your session | {interviewDuration}min
                      </StyledBigTextBold>
                      <StyledText>
                        Time zone: {Intl.DateTimeFormat().resolvedOptions().timeZone} (
                        {timeZoneGMT})
                      </StyledText>
                    </MiddleSectionBlock>
                  )}
                </MiddleSectionTitle>
                <MiddleSectionMainContent>
                  <ExpertsWrapper>
                    {!isMobile ? (
                      <InterviewExpertsList
                        experts={experts}
                        onSelect={setSelectedExpert}
                        selectedExpert={selectedExpert}
                      />
                    ) : (
                      <InterviewExpertsListMobile
                        experts={experts}
                        onSelect={(ex) => {
                          setSelectedExpert(ex)
                          setShowMobileSlots(true)
                        }}
                      />
                    )}
                    {isMobile && (
                      <BottomSection>
                        <NoneSlots>None of the time slots fit?</NoneSlots>
                        <NoneSlotsButton
                          type="button"
                          customTheme="outline"
                          title="Ask for additional slots"
                          useTheme
                          onClick={() => setShowNonSlotModal(true)}
                        />
                      </BottomSection>
                    )}
                  </ExpertsWrapper>
                  {!isMobile && (
                    <SlotsWrapper>
                      <Slots
                        expert={selectedExpert}
                        sessionPrice={sessionPrice}
                        onSlotSelect={setSelectedForInterview}
                        onSlotRequested={() => setShowNonSlotModal(true)}
                      />
                      <BottomSection>
                        <NoneSlots>None of the time slots fit?</NoneSlots>
                        <NoneSlotsButton
                          type="button"
                          customTheme="outline"
                          title="Ask for additional slots"
                          useTheme
                          onClick={() => setShowNonSlotModal(true)}
                        />
                      </BottomSection>
                    </SlotsWrapper>
                  )}
                </MiddleSectionMainContent>
              </MiddleSection>
            )}
          </ResponsiveWrapper>
        </LandingWrapper>
      )}
      {showMobileSlots && (
        <InterviewSlotsListMobile
          expert={selectedExpert}
          sessionPrice={sessionPrice}
          onSlotSelect={setSelectedForInterview}
          onSlotRequested={() => setShowNonSlotModal(true)}
          onClose={() => setShowMobileSlots(false)}
        />
      )}
    </MainWrap>
  )
}

export default Scheduling
