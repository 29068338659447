import React, { useEffect, useRef, useState } from 'react'

interface IProps {
  value: number
  show: boolean
  round?: boolean
}

const StepCount = 30
const AnimationLengthSec = 1.5

const DynamicNumber = ({ value, show, round }: IProps) => {
  const timerRef = useRef(null)
  const [displayValue, setDisplayValue] = useState(0)

  useEffect(() => {
    if (show) {
      const step = round ? Math.round(value / StepCount) : value / StepCount
      let stepCounter = 0
      timerRef.current = setInterval(() => {
        let newValue = Math.round((stepCounter * step + step) * 100) / 100
        if (newValue > value) newValue = value
        setDisplayValue(newValue)
        stepCounter += 1
        if (Math.abs(newValue - value) < 0.1) {
          clearInterval(timerRef.current)
          timerRef.current = null
        }
      }, (AnimationLengthSec / StepCount) * 1000)
    } else {
      setDisplayValue(0)
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [round, show, value])

  // useEffect(() => {
  //   setDisplayValue(value)
  // }, [value])

  return <>{displayValue}</>
}

export default DynamicNumber

DynamicNumber.defaultProps = {
  round: false,
}
