import { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import useStateRef from 'react-usestateref'

import {
  IOnBoardingStep,
  getOnboardingSteps,
} from '../components/notifications/onboarding/OnBoardingConstants'

const useOnBoarding = (
  isExpert: boolean,
  hasOnBoarding: boolean,
  canCodeshare: boolean
) => {
  const [onBoardingStep, setOnBoardingStep, onBoardingStepRef] = useStateRef<
    IOnBoardingStep
  >(undefined)

  const progressOnBoarding = () => {
    const steps = getOnboardingSteps(isExpert, isMobileOnly, canCodeshare)
    const index = steps.findIndex((s) => s.type === onBoardingStepRef.current.type)
    setOnBoardingStep(steps[index + 1])
  }

  const closeOnBoarding = () => {
    setOnBoardingStep(undefined)
  }

  useEffect(() => {
    if (!hasOnBoarding) return
    const steps = getOnboardingSteps(isExpert, isMobileOnly, canCodeshare)
    setOnBoardingStep(steps[0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpert, hasOnBoarding])

  return {
    onBoardingStep,
    closeOnBoarding,
    progressOnBoarding,
  }
}

export default useOnBoarding
