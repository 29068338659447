import { put, takeEvery, call } from 'typed-redux-saga'

import { downloadAdminReport } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { reduxRequestFactory } from '../../utils/redux'

const {
  action: downloadAdminReportAction,
  actionFailure: downloadAdminReportActionFailure,
  actionSuccess: downloadAdminReportActionSuccess,
  actionSuccessClear: downloadAdminReportActionSuccessClear,
  actionFailureClear: downloadAdminReportActionFailureClear,
  actionType: downloadAdminReportActionType,
  actionTypeFailure: downloadAdminReportActionFailureType,
  actionTypeSuccess: downloadAdminReportActionSuccessType,
  reducer: downloadAdminReportRequestReducer,
  selectors: downloadAdminReportSelectors,
} = reduxRequestFactory<void>('downloadAdminReport', 'requests')

export {
  downloadAdminReportAction,
  downloadAdminReportActionFailure,
  downloadAdminReportActionSuccess,
  downloadAdminReportActionSuccessClear,
  downloadAdminReportActionFailureClear,
  downloadAdminReportActionType,
  downloadAdminReportActionFailureType,
  downloadAdminReportActionSuccessType,
  downloadAdminReportRequestReducer,
  downloadAdminReportSelectors,
}

function* downloadAdminReportSaga() {
  try {
    const report = yield* call(downloadAdminReport)

    const element = document.createElement('a')
    const file = new Blob([report], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'wizco_activity_table.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()

    yield put(downloadAdminReportActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      downloadAdminReportActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchDownloadAdminReport() {
  yield takeEvery(downloadAdminReportActionType, downloadAdminReportSaga)
}
