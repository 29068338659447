import styled from '@emotion/styled'
import React from 'react'
import withProps from 'recompose/withProps'

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const IconStyled = withProps()(styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 20px;
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 20px;
  padding: 9px;
  overflow: visible;
`)

const TextStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #222b45;
`

const ValueStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #222b45;
`

const EmojiStyled = styled.div`
  font-weight: 500;
  font-size: 34px;
`

const UnavailableStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #222b45;
`

interface IProps {
  icon: any
  text: string
  value: string
  unavailable?: boolean
}

const ReportNumberComponentMobile = ({ icon, text, value, unavailable }: IProps) => {
  return (
    <Container>
      <IconStyled src={icon} alt="icon" />
      <ColContainer>
        <TextStyled>{text}</TextStyled>
        {unavailable && (
          <>
            <EmojiStyled>🫣</EmojiStyled>
            <UnavailableStyled>Not publicly disclosed</UnavailableStyled>
          </>
        )}
        {!unavailable && <ValueStyled>{value}</ValueStyled>}
      </ColContainer>
    </Container>
  )
}

ReportNumberComponentMobile.defaultProps = {
  unavailable: false,
}

export default ReportNumberComponentMobile
