import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import SvgFeedback from 'src/static/components/SvgFeedback'
import SvgClose from 'src/static/components/SvgClose'

import { IExpertNote } from '../../../api'
import { expertNotesSelectors } from '../../../redux'
import FeedbackItemMobile from './FeedbackItemMobile'

const ItemsContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
  flex-direction: column;
  overflow: auto;
`

const MobileContainer = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
`

const MobileHeader = styled.div`
  height: 92px;
  width: 100%;
  display: flex;
  padding: 37px 20px;
  align-items: center;
  box-shadow: 0px 4px 9px rgba(115, 121, 145, 0.14);
  border-radius: 8px;
`

const MobileTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 145.19%;
  color: #737991;
  margin-left: 10px;
  flex: 1;
`

const MobileCloseIcon = styled.div`
  width: 20px;
  height: 20px;
  padding: 4px;
  display: flex;

  > svg {
    height: 100%;
    width: 100%;
  }
`

const InputContainer = styled.div<{ active: boolean }>`
  background: white;
  display: flex;
  border: 1px solid rgba(178, 2, 255, ${({ active }) => (active ? 1 : 0.2)});
  padding: 12px;
  margin: 12px;
  height: 150px;
  width: calc(100% - 24px);
  border-radius: 4px;
`

const StyledButton = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 145.19%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 7px 20px;
  background: #b202ff;
  border-radius: 4px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 12px;

  &:active {
    opacity: 0.5;
  }
`

const StyledTextarea = styled.textarea`
  width: 100%;
  flex: 1;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 12px;
  color: black;

  &:placeholder {
    color: #8f9bb3;
  }
`

interface IProps {
  onAdd: (text: string) => void
  onDelete: (id: string) => void
  onEdit: (id: string, text: string) => void
  onClose?: () => void
}

export default function FeedbackSidebarMobile({
  onAdd,
  onDelete,
  onEdit,
  onClose,
}: IProps) {
  const expertNotes = useSelector(expertNotesSelectors.data)

  const [editMode, setEditMode] = useState<'Add' | 'Edit'>(null)
  const [editFeedback, setEditFeeback] = useState<IExpertNote>()
  const [activeFeedbackId, setActiveFeedbackId] = useState(null)
  const scrollAnchor = useRef<HTMLDivElement>()

  const onEditFeedbackText = (text: string) => {
    setEditFeeback((feedback) => {
      return {
        ...feedback,
        text,
      }
    })
  }

  const onFinishEdit = useCallback(
    (feedback: IExpertNote) => {
      if (editMode === 'Add') {
        onAdd(feedback.text)
      } else {
        onEdit(feedback.id, feedback.text)
      }
      setEditFeeback(null)
      setEditMode(null)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode]
  )

  const startAddFeedback = useCallback(() => {
    if (editMode === null) {
      setEditMode('Add')
      setEditFeeback({ text: '', score: 0 })
    }
  }, [editMode])

  const startEditFeedback = useCallback(
    (data: IExpertNote) => {
      if (editMode !== 'Add') {
        setEditMode('Edit')
        setEditFeeback(data)
      }
    },
    [editMode]
  )

  useEffect(() => {
    scrollAnchor.current?.scrollIntoView({ behavior: 'smooth' })
  }, [expertNotes])

  return (
    <MobileContainer>
      <MobileHeader>
        <SvgFeedback fill="#737991" />
        <MobileTitle>Feedback</MobileTitle>
        <MobileCloseIcon onClick={onClose}>
          <SvgClose fill="#737991" />
        </MobileCloseIcon>
      </MobileHeader>
      <InputContainer active={editMode !== null}>
        <StyledTextarea
          placeholder="Write your feedback"
          onClick={startAddFeedback}
          value={editFeedback?.text ?? ''}
          onChange={(ev) => onEditFeedbackText(ev.target.value)}
        />
        {editMode && (
          <StyledButton onClick={() => onFinishEdit(editFeedback)}>
            {editFeedback?.id ? 'Save' : 'Add'}
          </StyledButton>
        )}
      </InputContainer>
      {!editMode && (
        <ItemsContainer>
          {expertNotes.map((item) => (
            <FeedbackItemMobile
              key={item.id}
              text={item.text}
              mood={item.mood}
              createdAt={item.created_at}
              showMenu={activeFeedbackId === item.id}
              onShowMenu={() => setActiveFeedbackId(item.id)}
              onHideMenu={() => setActiveFeedbackId(null)}
              onEdit={() => startEditFeedback(item)}
              onDelete={() => onDelete(item.id)}
            />
          ))}
          <div ref={scrollAnchor} />
        </ItemsContainer>
      )}
    </MobileContainer>
  )
}

FeedbackSidebarMobile.defaultProps = {
  onClose: () => {},
}
