import { createAction } from '@reduxjs/toolkit'
import { IFailure } from '../utils/redux'
import {
  SIGN_IN,
  SET_SIGN_IN_RESULT,
  SIGN_IN_FAILURE,
  SIGN_IN_CLEAR_ERROR,
  RESET,
  RESET_SUCCESS,
  RESET_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_CLEAR_ERROR,
  SIGN_OUT,
  ISignInAction,
  ISetSignInAction,
  IResetAction,
  IChangePasswordAction,
  IChangePasswordSuccessAction,
} from './types'

export const signIn = createAction<ISignInAction['payload']>(SIGN_IN)
export const setSignInResult = createAction<ISetSignInAction['payload']>(
  SET_SIGN_IN_RESULT
)
export const signInFailure = createAction<IFailure>(SIGN_IN_FAILURE)
export const signInClearError = createAction(SIGN_IN_CLEAR_ERROR)
export const reset = createAction<IResetAction['payload']>(RESET)
export const resetSuccess = createAction(RESET_SUCCESS)
export const resetFailure = createAction<IFailure>(RESET_FAILURE)
export const changePassword = createAction<IChangePasswordAction['payload']>(
  CHANGE_PASSWORD
)
export const changePasswordSuccess = createAction<
  IChangePasswordSuccessAction['payload']
>(CHANGE_PASSWORD_SUCCESS)
export const changePasswordFailure = createAction<IFailure>(CHANGE_PASSWORD_FAILURE)
export const changePasswordClearError = createAction(CHANGE_PASSWORD_CLEAR_ERROR)
export const signOut = createAction(SIGN_OUT)
