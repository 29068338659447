import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import { formattedDate } from 'src/utils/date'
import { AvaInterviewStatus } from '../../api'
import { Text } from '../../components'
import {
  agencySelectors,
  avaGetInterviewsAction,
  avaInterviewsSelectors,
} from '../../redux'
import { isSuperAdminRole } from '../../utils/jwtToken'

import Layout from '../Layout'

const getColor = (status: AvaInterviewStatus) => {
  switch (status) {
    case AvaInterviewStatus.IN_INTRO:
      return '#F23482'
    case AvaInterviewStatus.IN_INTERVIEW:
      return '#C746FF'
    case AvaInterviewStatus.COMPLETED:
      return '#3D95F7'
    default:
      return '#F1BE2B'
  }
}

const getStatus = (status: AvaInterviewStatus) => {
  switch (status) {
    case AvaInterviewStatus.IN_INTRO:
      return 'intro'
    case AvaInterviewStatus.IN_INTERVIEW:
      return 'interview'
    default:
      return status
  }
}

const InterviewItem = styled.div`
  border-bottom: 1px solid;
  justify-content: space-between;
  padding: 10px 0;
  grid-template-columns: 1fr 4fr 1fr 1fr;
  display: grid;
`

const InterviewInfo = styled.div`
  display: block;
`

const InterviewInfoRow = styled(Text)`
  margin: 0;
`

const InterviewStatus = styled.div<{ status: AvaInterviewStatus }>`
  text-transform: uppercase;
  color: ${({ status }) => getColor(status)};
`

const InterviewLabel = styled.div`
  display: flex;
  justify-content: space-between;
`

const InterviewLink = styled.a`
  text-align: center;
`

const InterviewPromptsLink = styled(InterviewLink)`
  color: darkmagenta;
`

const AvaDashboard = () => {
  const dispatch = useDispatch()

  const agency = useSelector(agencySelectors.data)
  const interviews = useSelector(avaInterviewsSelectors.data)
  const isSuperAdmin = isSuperAdminRole()

  const fullDomain = get(agency, 'full_domain', '')

  useEffect(() => {
    if (agency?.id) dispatch(avaGetInterviewsAction({ agencyId: agency.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency])

  return (
    <Layout title="Activity" hideBg fullBody>
      <>
        {interviews.map((interview) => (
          <InterviewItem>
            <InterviewLabel>Interview #{interview.order_number}</InterviewLabel>
            <InterviewInfo>
              <InterviewStatus status={interview.status}>
                {getStatus(interview.status)}
              </InterviewStatus>
              <InterviewInfoRow>
                Created: {formattedDate(interview.created_at)}
              </InterviewInfoRow>
              <InterviewInfoRow>
                Last updated: {formattedDate(interview.updated_at)}
              </InterviewInfoRow>
              {interview.candidate?.email && (
                <InterviewInfoRow>{`${interview.candidate.first_name ?? ''} ${
                  interview.candidate.last_name ?? ''
                } (${interview.candidate.email})`}</InterviewInfoRow>
              )}
            </InterviewInfo>
            {isSuperAdmin && (
              <InterviewPromptsLink
                href={`/ava/${interview.candidate_token_code}/prompts`}
                target="_blank"
              >
                Prompts
              </InterviewPromptsLink>
            )}
            <InterviewLink
              href={`${fullDomain}/ava/${interview.candidate_token_code}`}
              target="_blank"
            >
              Select
            </InterviewLink>
          </InterviewItem>
        ))}
      </>
    </Layout>
  )
}

export default AvaDashboard
