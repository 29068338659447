import React from 'react'
import styled from '@emotion/styled'

import { useSelector } from 'react-redux'

import SvgDangerIcon from 'src/static/components/SvgDanger'
import SvgCloseIcon from 'src/static/components/SvgClose'
import closeIcon from 'src/static/close_gray.svg'
import dangerIcon from 'src/static/danger.svg'

import * as interviewSelectors from '../../../interview/selectors'

import { OnboardingPopup, StyledText, Close } from './onboarding/BaseOnboarding'
import { Text as MobileText } from '../MobileText'

const Popup = styled(OnboardingPopup)`
  align-items: flex-start;
  background: rgba(24, 11, 49, 0.8);
  border: 1px solid #353c5a;
  flex-direction: row;
  position: absolute;
  right: 50%;
  top: 100%;
  transform: translate(50%, 0.75rem);
  width: max-content !important;

  &:after {
    background-color: rgba(24, 11, 49, 0.8);
    border-top: 1px solid #353c5a;
    border-right: 1px solid #353c5a;
  }

  padding: 18px;
  min-width: 500px;
  @media screen and (max-width: 2500px) {
    padding: 15px;
    min-width: 420px;
  }
  @media screen and (max-width: 2000px) {
    padding: 12px;
    min-width: 330px;
  }
  @media screen and (max-width: 1200px) {
    padding: 9px;
    min-width: 250px;
  }
  @media screen and (max-width: 900px) {
    padding: 6px;
    min-width: 170px;
  }
`

const PopupTitle = styled(StyledText)`
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CloseLink = styled(StyledText)`
  color: #ffffff;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  margin: 0 !important;
`

const DangerIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  height: 28px;
  margin-right: 28px;
  width: 28px;
  @media screen and (max-width: 2500px) {
    height: 24px;
    margin-right: 24px;
    width: 24px;
  }
  @media screen and (max-width: 2000px) {
    height: 19px;
    margin-right: 19px;
    width: 19px;
  }
  @media screen and (max-width: 1200px) {
    height: 14px;
    margin-right: 14px;
    width: 14px;
  }
  @media screen and (max-width: 900px) {
    height: 9px;
    margin-right: 9px;
    width: 9px;
  }
`

const DangerIcon = styled.img`
  width: 100%;
`

const PopupClose = styled(Close)`
  align-self: flex-start;
  flex-shrink: 0;

  height: 28px;
  margin-left: 28px;
  width: 28px;
  @media screen and (max-width: 2500px) {
    height: 24px;
    margin-left: 24px;
    width: 24px;
  }
  @media screen and (max-width: 2000px) {
    height: 19px;
    margin-left: 19px;
    width: 19px;
  }
  @media screen and (max-width: 1200px) {
    height: 14px;
    margin-left: 14px;
    width: 14px;
  }
  @media screen and (max-width: 900px) {
    height: 9px;
    margin-left: 9px;
    width: 9px;
  }
`

const PopupContent = styled.div`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`

export function PeerJoinedCodeNotification({
  onClose,
  onJoin,
}: {
  onClose: () => void
  onJoin: () => void
}) {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const label = `Join ${peer.first_name} in a live coding environment`

  const handleClose = (e) => {
    e.stopPropagation()
    onClose()
  }

  const handleJoin = (e) => {
    e.stopPropagation()
    onJoin()
  }

  return (
    <Popup>
      <DangerIconContainer>
        <DangerIcon src={dangerIcon} />
      </DangerIconContainer>
      <PopupContent>
        <PopupTitle>{label}</PopupTitle>
        <CloseLink onClick={handleJoin}>Join now</CloseLink>
      </PopupContent>
      <PopupClose onClick={handleClose} src={closeIcon} />
    </Popup>
  )
}

const MobileContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  padding: 12px;
  width: 100%;
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 38px 0 13px;
  position: relative;
  width: 100%;
`

const MobileDangerIcon = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  opacity: 0.75;

  svg {
    width: 16px;
    height: 16px;
  }
`

const MobileStyledTitle = styled(MobileText)`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
  overflow: hidden;
`

const MobileStyledText = styled(MobileText)`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`

const MobileCloseIcon = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  svg {
    width: 14px;
    height: 14px;
  }
`

const MobileCallNotification = styled.div`
  background: rgba(24, 11, 49, 0.8);
  backdrop-filter: blur(18px);
  border: 1px solid #353c5a;
  border-radius: 12px;
  box-siizing: border-box;
  height: 78px;
  left: 50%;
  position: absolute;
  top: 62px;
  transform: translate(-50%, 0);
  width: 334.61px;

  @media (orientation: landscape) {
    top: 67px;
  }
`

export function MobilePeerJoinedCodeNotification({
  theme,
  onClose,
}: {
  theme: any
  onClose: () => void
}) {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const title = `${peer.first_name} has created a live coding environment`
  const label = 'Please switch to desktop to join.'
  const handleClose = (e) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <MobileCallNotification>
      <MobileContainer>
        <MobileDangerIcon>
          <SvgDangerIcon fill={theme.textColor} />
        </MobileDangerIcon>
        <MobileContent>
          <MobileStyledTitle>{title}</MobileStyledTitle>
          <MobileStyledText>{label}</MobileStyledText>
        </MobileContent>
        <MobileCloseIcon onClick={handleClose}>
          <SvgCloseIcon fill={theme.textColor} />
        </MobileCloseIcon>
      </MobileContainer>
    </MobileCallNotification>
  )
}
