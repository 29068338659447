/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useStateRef from 'react-usestateref'
import { AnimatedBase, NavButton, TitleText } from '../elements/styled-components'
import LiveText from '../LiveText'
import { SpeechTexts } from '../../speechService'
import {
  avaInterviewSelectors,
  avaRetryQuestionsGenerationAction,
  startAvaInterviewWatcherAction,
} from '../../../redux'
import LoadingBar from '../elements/LoadingBar'
import { PromptStatus } from '../../../api'

const AnimationFirstSpeedMs = 500
const AnimationLastSpeedMs = 2000

const WaitingContainer = styled.div`
  position: relative;
  max-width: 1000px;
  margin: 94px auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  ${isMobileOnly &&
  css`
    flex: 1;
    justify-content: start;
    flex-direction: column;
    margin: 0;
    padding: 42px 16px;
    background: white;
    border-radius: 20px 20px 0 0;
  `}
`

const InfoContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  flex: 1;
  padding: 94px 200px 0 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;

  @media screen and (max-width: 1100px) {
    padding: 40px;
  }

  ${isMobileOnly &&
  css`
    flex: 1;
    flex-direction: column;
    margin: 0;
    padding: 42px 16px 16px !important;
    background: white;
    border-radius: 20px 20px 0 0;
  `}
`

const LoadingText = styled.div<{ disabled?: boolean }>`
  color: rgba(115, 121, 145, 0.6);
  font-family: Rubik;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  margin-top: 16px;
`

const SubTitleText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000;
  margin-bottom: 136px;
`

const LoadItems = [
  'Analyzing your information',
  'Generating potential questions',
  'Ensuring accuracy and relevance',
]

const InfoText = styled.div`
  font-weight: 600;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #21304e;
  margin-top: 9px;
  text-align: center;

  ${isMobileOnly &&
  css`
    width: 100%;
    text-align: start;
  `}
`

const Separator = styled(AnimatedBase)`
  width: 510px;
  height: 0px;
  border: 0.5px solid #000000;
  margin: 42px 0;
`

const LineContainer = styled.div`
  display: flex;
  ${isMobileOnly &&
  css`
    margin-top: auto;
    width: 100%;
  `}
`

const GoButton = styled(NavButton)`
  height: 55px;

  ${isMobileOnly &&
  css`
    margin: 24px 0 0 0;
    width: 100%;
  `}
`

enum ProgressStep {
  Waiting,
  Done,
  Failed,
}

interface IProps {
  backMode: boolean
  goNext: () => void
}

const GettingQuestionsComponent = ({ backMode, goNext }: IProps) => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const interview = useSelector(avaInterviewSelectors.data)
  const [titleIsReady, setTitleIsReady] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [loadingText, setLoadingText] = useState(LoadItems[0])
  const [animationSpeedMs, setAnimationSpeedMs] = useState(0)
  const [progress, setProgress, progressRef] = useStateRef(0)
  const [step, setStep, stepRef] = useStateRef<ProgressStep>(undefined)

  const runProgress = async () => {
    while (progressRef.current < 0.91) {
      await new Promise((resolve) => setTimeout(resolve, AnimationFirstSpeedMs))
      setProgress((v) => v + 0.01)
    }
    while (progressRef.current < 0.99) {
      await new Promise((resolve) => setTimeout(resolve, AnimationLastSpeedMs))
      setProgress((v) => v + 0.01)
    }
  }

  useEffect(() => {
    if (showDetails && step === ProgressStep.Waiting) runProgress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetails, step])

  useEffect(() => {
    if (progress > 1) setProgress(1)
    if (progress < 0.31) {
      setLoadingText(LoadItems[0])
    } else if (progress < 0.72) {
      setLoadingText(LoadItems[1])
    } else {
      setLoadingText(LoadItems[2])
    }

    if (progress < 0.91 || progress === 1) {
      setAnimationSpeedMs(AnimationFirstSpeedMs)
    } else {
      setAnimationSpeedMs(AnimationLastSpeedMs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  useEffect(() => {
    if (
      interview.is_retrying_prompts ||
      Object.values(interview?.prompt_status.interview).some((v) =>
        [PromptStatus.Missing, PromptStatus.Waiting].includes(v)
      )
    ) {
      if (stepRef.current !== ProgressStep.Waiting) {
        setTitleIsReady(false)
        setShowDetails(false)
        setStep(ProgressStep.Waiting)
      }
    } else if (
      !interview.is_retrying_prompts &&
      Object.values(interview?.prompt_status.interview).some(
        (v) => v === PromptStatus.Failed
      )
    ) {
      if (stepRef.current !== ProgressStep.Failed) {
        setShowDetails(false)
        setShowButton(false)
        setStep(ProgressStep.Failed)
      }
    } else if (
      !interview.is_retrying_prompts &&
      Object.values(interview?.prompt_status.interview).every((v) =>
        [PromptStatus.Skipped, PromptStatus.Done].includes(v)
      )
    ) {
      if (stepRef.current !== ProgressStep.Done) {
        setProgress(1)
        setTimeout(() => {
          setShowDetails(false)
          setShowButton(false)
          setStep(ProgressStep.Done)
        }, 1000)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  useEffect(() => {
    dispatch(startAvaInterviewWatcherAction({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  return (
    <>
      {step === ProgressStep.Waiting && (
        <WaitingContainer>
          <TitleText textAlign="center">
            <LiveText
              staticText={backMode}
              text={SpeechTexts.GettingQuestions}
              onFinish={() => setTitleIsReady(true)}
            />
          </TitleText>
          {titleIsReady && (
            <SubTitleText>
              <LiveText
                staticText={backMode}
                text={SpeechTexts.IfYouDontLikeQuestion}
                onFinish={() => setShowDetails(true)}
              />
            </SubTitleText>
          )}
          {showDetails && (
            <>
              <LoadingBar progress={progress} animationSpeedMs={animationSpeedMs} />
              <LoadingText>{loadingText}</LoadingText>
            </>
          )}
        </WaitingContainer>
      )}
      {step === ProgressStep.Done && (
        <InfoContainer>
          <TitleText textAlign={isMobileOnly ? 'start' : 'center'}>
            <LiveText
              staticText={backMode}
              text={SpeechTexts.IHaveEverything}
              onFinish={() => setShowDetails(true)}
            />
          </TitleText>
          {showDetails && (
            <InfoText>
              <LiveText
                staticText={backMode}
                text={SpeechTexts.IAskYouQuestions}
                onFinish={() => setShowButton(true)}
              />
            </InfoText>
          )}
          {showButton && (
            <>
              {!isMobileOnly && <Separator />}
              <LineContainer>
                <GoButton onClick={goNext} hideIcon>
                  Let&#39;s Go!
                </GoButton>
              </LineContainer>
            </>
          )}
        </InfoContainer>
      )}
      {step === ProgressStep.Failed && (
        <InfoContainer>
          <TitleText textAlign={isMobileOnly ? 'start' : 'center'}>
            <LiveText
              staticText={backMode}
              text={SpeechTexts.SomethingWentWrong}
              onFinish={() => setShowDetails(true)}
            />
          </TitleText>
          {showDetails && (
            <InfoText>
              <LiveText
                staticText={backMode}
                text={SpeechTexts.WeExperenceTroubleQuestions}
                onFinish={() => setShowButton(true)}
              />
            </InfoText>
          )}
          {showButton && (
            <>
              {!isMobileOnly && <Separator />}
              <LineContainer>
                <GoButton
                  onClick={() =>
                    dispatch(avaRetryQuestionsGenerationAction({ interviewToken }))
                  }
                  hideIcon
                >
                  Try Again
                </GoButton>
              </LineContainer>
            </>
          )}
        </InfoContainer>
      )}
    </>
  )
}

export default GettingQuestionsComponent
