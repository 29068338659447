import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { format, addMinutes } from 'date-fns'
import AddToCalendar from 'react-add-to-calendar'
import { normalizeDate } from 'src/utils/date'
import { useMediaQuery } from 'react-responsive'
import { IExpertProfile, ITimeSlot } from 'src/api/models'
import * as interviewSelectors from '../selectors'

import { Text, TextBold, Avatar } from '../../components'

const Container = styled.div`
  padding-right: 40px;
  @media screen and (max-width: 600px) {
    padding-right: 0;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const StyledTitle = styled(TextBold)`
  font-size: 26px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 15px;
  font-weight: bold;

  @media screen and (max-width: 600px) {
    font-size: 26px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 25px;
  }
`

const StyledTextBold = styled(TextBold)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
`

const CoachPosition = styled(StyledTextBold)`
  margin-bottom: 25px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
  }
`

const CoachDate = styled(StyledTextBold)`
  margin-right: 15px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const StyledSmallText = styled(Text)<{ top?: number }>`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: ${(props) => props.top ?? 0}px;
`

const StyledSmallBoldText = styled(TextBold)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
`

const ExpertWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`

const ExpertDetails = styled.div`
  text-align: left;
`

const AddToCalendarButtonWrapper = styled.div`
  margin-bottom: 70px;
  display: inline-block;
  .react-add-to-calendar {
    position: relative;
  }
  .react-add-to-calendar__dropdown {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    ul {
      list-style: none;
      background: #fff;
      padding: 0;
      margin: 0;
      margin-top: -1px;
      border: 1px solid ${(props) => props.color};
      display: flex;
      flex-direction: column;
      li:first-of-type {
        order: 1;
      }
      a {
        color: #000;
        display: block;
        padding: 10px;
        font-size: 12px;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
          text-decoration: none;
          color: #fff;
          background: ${(props) => props.color};
        }
      }
    }
  }
  .react-add-to-calendar__wrapper {
    display: block;
  }
  a.react-add-to-calendar__button {
    display: block;
    color: #fff;
    background: ${(prop) => prop.color};
    width: fit-content;
    padding: 15px 40px;
    margin: 0;
    outline: none;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: ${(prop) => prop.color};
    border-radius: 4px;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 12px;

    @media screen and (max-width: 600px) {
      color: ${(prop) => prop.color};
      padding: 0;
      background: none;
      border: none;
      font-size: 14px;
    }

    &--light {
      border-radius: 4px 4px 0 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.5;
      text-decoration: none;
    }
  }
  @media screen and (min-height: 900px) {
    margin-bottom: 60px;
  }
`

const AddToCalendarButton = styled(AddToCalendar)``

interface IProps {
  slot: ITimeSlot
  expert: IExpertProfile
  position: string
  showThanks?: boolean
}

export default function ScheduleThankYou({ slot, position, expert, showThanks }: IProps) {
  const agency = useSelector(interviewSelectors.agency)
  const interviewDuration = get(agency, 'duration', 60)
  const agencyColor = get(agency, 'main_color')

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const agencyColorWithDefault =
    !!agencyColor && agencyColor !== 'null' ? agencyColor : '#B202FF'
  const timeZoneGMT = format(new Date(), 'O')
  const timeStampFrom = useMemo(() => normalizeDate(slot.from), [slot.from])
  const timeStampTo = useMemo(
    () => addMinutes(normalizeDate(slot.from), interviewDuration),
    [slot.from, interviewDuration]
  )
  const startDate = useMemo(() => format(timeStampFrom, "MM/dd, eeee 'at' h:mm a"), [
    timeStampFrom,
  ])
  const startDateMobile = useMemo(() => `${format(timeStampFrom, 'eeee, MMM dd')}`, [
    timeStampFrom,
  ])
  const endDate = useMemo(() => format(timeStampTo, ' - h:mm a'), [timeStampTo])
  const interViewDate = useMemo(() => `${startDate}${endDate}`, [startDate, endDate])
  const interViewDateMobile = useMemo(() => `${startDateMobile}`, [startDateMobile])
  const timeMobile = useMemo(
    () => `${format(timeStampFrom, 'h:mm a')}${format(timeStampTo, ' - h:mm a')}`,
    [timeStampFrom, timeStampTo]
  )
  const coachLocation = window.location.href
  const coachLink = `<a href={${coachLocation}}>${coachLocation}</a>`
  const calendarEvent = {
    title: `Coaching session with ${expert.first_name} ${expert.last_name}`,
    description: `To join the session or if you need to reschedule: 
      ${coachLink}`,
    location: coachLocation,
    startTime: timeStampFrom,
    endTime: timeStampTo,
  }

  return (
    <Container>
      {showThanks && (
        <StyledTitle>{isMobile ? `See you soon!` : `Thank you!`}</StyledTitle>
      )}
      <CoachPosition>
        {`Your coaching session for ${position} is scheduled for:`}
      </CoachPosition>
      <Flex>
        <CoachDate>{isMobile ? interViewDateMobile : interViewDate}</CoachDate>
        {!isMobile ? (
          <StyledSmallText>
            {Intl.DateTimeFormat().resolvedOptions().timeZone} ({timeZoneGMT})
          </StyledSmallText>
        ) : (
          <>
            <CoachDate>|</CoachDate>
            <StyledSmallText>{timeMobile}</StyledSmallText>
          </>
        )}
      </Flex>
      {isMobile && (
        <StyledSmallText top={10}>
          {Intl.DateTimeFormat().resolvedOptions().timeZone} ({timeZoneGMT})
        </StyledSmallText>
      )}
      <ExpertWrapper>
        <Avatar user={{ ...slot, logo: expert.logo }} size={45} />
        <ExpertDetails>
          <StyledSmallBoldText>{`With ${expert.first_name}`}</StyledSmallBoldText>
          <StyledSmallText>{`${expert.current_position}${
            expert.current_position && expert.company && ' at '
          }${expert.company}`}</StyledSmallText>
        </ExpertDetails>
      </ExpertWrapper>

      {showThanks && (
        <AddToCalendarButtonWrapper color={agencyColorWithDefault}>
          <AddToCalendarButton
            event={calendarEvent}
            buttonTemplate={{ textOnly: 'none' }}
            buttonLabel="Add to calendar"
          />
        </AddToCalendarButtonWrapper>
      )}

      {showThanks && !isMobile && <StyledTitle>It pays to be prepared.</StyledTitle>}
    </Container>
  )
}

ScheduleThankYou.defaultProps = {
  showThanks: true,
}
