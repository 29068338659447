import * as React from 'react'

interface SvgProps {
  fill: string
  disabled: boolean
  inactive: boolean
}

function SvgVideo({ fill, disabled, inactive }: SvgProps) {
  return (
    <svg
      className="svg"
      style={{ overflow: 'visible' }}
      width={39}
      height={21}
      viewBox="0 0 39 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M27.8232 4.51697C27.6337 4.3883 27.397 4.37655 27.1977 4.48591L22.985 6.79907V5.11765C22.985 3.9481 22.1216 3 21.0565 3H12.0565C10.9914 3 10.1279 3.9481 10.1279 5.11765V13.5882C10.1279 14.7578 10.9914 15.7059 12.0565 15.7059H21.0565C22.1216 15.7059 22.985 14.7578 22.985 13.5882V11.9068L27.1977 14.2235C27.5153 14.3978 27.9014 14.2564 28.0601 13.9076C28.1052 13.8085 28.1285 13.699 28.1279 13.5882V5.11765C28.1279 4.87296 28.0127 4.64568 27.8232 4.51697ZM21.775 13.8082C21.775 14.2184 21.4787 14.5508 21.1132 14.5508H11.8485C11.483 14.5508 11.1868 14.2184 11.1868 13.8082V4.89764C11.1868 4.48753 11.483 4.15508 11.8485 4.15508H21.1132C21.4787 4.15508 21.775 4.48753 21.775 4.89764V13.8082ZM27.0691 12.2406L22.8338 10.2637V8.44222L27.0691 6.46524V12.2406Z"
          fill={fill || '#000'}
        />
        <path d="M6 2h20v20H6z" />
        {inactive && !disabled && (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2 6C13.4703 6 14.6203 6.51487 15.4527 7.34731C16.2851 8.17975 16.8 9.32974 16.8 10.6V14.2C16.8 15.4703 16.2851 16.6203 15.4527 17.4527C14.6203 18.2851 13.4703 18.8 12.2 18.8H8.6C7.32974 18.8 6.17975 18.2851 5.34731 17.4527C4.51487 16.6203 4 15.4703 4 14.2V10.6C4 9.32974 4.51487 8.17975 5.34731 7.34731C6.17975 6.51487 7.32974 6 8.6 6H12.2Z"
              fill="white"
              stroke="#1D0E3B"
              strokeWidth="2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.2 7C14.1882 7 15.8 8.61177 15.8 10.6V14.2C15.8 16.1882 14.1882 17.8 12.2 17.8H8.6C6.61177 17.8 5 16.1882 5 14.2V10.6C5 8.61177 6.61177 7 8.6 7H12.2ZM8.96774 10.1258C8.73236 9.94281 8.39203 9.95945 8.17574 10.1757C7.95945 10.392 7.94281 10.7324 8.12582 10.9677L8.17574 11.0243L9.551 12.4L8.17574 13.7757C7.94142 14.0101 7.94142 14.3899 8.17574 14.6243C8.39203 14.8406 8.73236 14.8572 8.96774 14.6742L9.02426 14.6243L10.4 13.249L11.7757 14.6243L11.8323 14.6742C12.0676 14.8572 12.408 14.8406 12.6243 14.6243C12.8406 14.408 12.8572 14.0676 12.6742 13.8323L12.6243 13.7757L11.249 12.4L12.6243 11.0243C12.8586 10.7899 12.8586 10.4101 12.6243 10.1757C12.408 9.95945 12.0676 9.94281 11.8323 10.1258L11.7757 10.1757L10.4 11.551L9.02426 10.1757L8.96774 10.1258Z"
              fill="#FF002A"
            />
          </>
        )}
      </g>
    </svg>
  )
}

export default SvgVideo
