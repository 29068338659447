import React from 'react'

function SvgCloseIcon({ fill }: { fill: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="414.667"
      viewBox="0 0 311 311.077"
      width="414.667"
      fill={fill}
    >
      <path d="M16.035 311.078c-4.098 0-8.195-1.559-11.309-4.695-6.25-6.25-6.25-16.383 0-22.633L283.789 4.688c6.25-6.25 16.383-6.25 22.633 0s6.25 16.383 0 22.637L27.363 306.383c-3.137 3.117-7.234 4.695-11.328 4.695zm0 0" />
      <path d="M295.117 311.078c-4.098 0-8.191-1.559-11.309-4.695L4.727 27.324c-6.25-6.254-6.25-16.387 0-22.637s16.383-6.25 22.637 0L306.422 283.75c6.25 6.25 6.25 16.383 0 22.633-3.137 3.117-7.23 4.695-11.305 4.695zm0 0" />
    </svg>
  )
}

export default SvgCloseIcon
