import { reduxDataFactory } from '../../../utils/redux'

export enum BookingStatus {
  Position = 'position',
  Student = 'student',
  Success = 'success',
}

const {
  action: updateBookingStatusAction,
  actionType: updateBookingStatusActionType,
  reducer: bookingStatusDataReducer,
  selectors: bookingStatusSelectors,
} = reduxDataFactory<BookingStatus>('bookingStatus', 'interviewParams', undefined)

export {
  updateBookingStatusAction,
  updateBookingStatusActionType,
  bookingStatusDataReducer,
  bookingStatusSelectors,
}
