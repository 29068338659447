import React from 'react'
import styled from '@emotion/styled'

import { Text, Title } from './MobileText'

const ReadyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 3vh;
`

const StyledTitle = styled(Title)`
  margin-bottom: 0.5em;
  text-align: center;

  font-size: 39px;
  line-height: 48px;
  @media screen and (max-width: 2500px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 26px;
    line-height: 32px;
  }
`

const MobileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-top: 10px;
`

const MobileStyledTitle = styled(Text)`
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 15px;
`

export function JoiningCall() {
  const title = 'Joining...'

  return (
    <ReadyContainer>
      <StyledTitle>{title}</StyledTitle>
    </ReadyContainer>
  )
}

export function MobileJoiningCall() {
  const title = 'Joining...'

  return (
    <MobileContainer>
      <MobileStyledTitle>{title}</MobileStyledTitle>
    </MobileContainer>
  )
}
