import useSymbl from '../../videocall/services/useSymbl'
import useRevAi from './useRevAi'

export type STTProvider = 'symbl' | 'revai'
interface IProps {
  interviewId: string
  provider: STTProvider
  onRecognition?: (text: string, isFinal: boolean) => void
  onDisconnected?: () => void
}

const useSpeechToText = ({
  interviewId,
  provider = 'symbl',
  onDisconnected,
  onRecognition,
}: IProps) => {
  const handleOnDisconnect = (p: STTProvider) => {
    if (p === provider) {
      onDisconnected()
    }
  }

  const {
    runSymbl,
    stopSymbl,
    muteSymbl,
    tokenGenerated,
    isConnected: isConnectedSymbl,
    changeSymblDevice,
  } = useSymbl('1', `AVA_user`, interviewId, false, false, onRecognition, () =>
    handleOnDisconnect('symbl')
  )

  const {
    isConnected: isConnectedRevAi,
    changeMicDevice: changeRevAiDevice,
    muteSpeechToText: muteRevAiSTT,
    runSpeechToText: runRevAiSTT,
    stopSpeechToText: stopRevAiSTT,
  } = useRevAi(onRecognition, () => handleOnDisconnect('revai'))

  const changeMicDevice = (micId: string) => {
    switch (provider) {
      case 'revai':
        return changeRevAiDevice(micId)
      default:
        return changeSymblDevice(micId)
    }
  }

  const getConnectedStatus = () => {
    switch (provider) {
      case 'revai':
        return isConnectedRevAi
      default:
        return isConnectedSymbl
    }
  }

  const getReadyStatus = () => {
    switch (provider) {
      case 'revai':
        return true
      default:
        return tokenGenerated
    }
  }

  const runSpeechToText = async () => {
    console.warn(provider)
    switch (provider) {
      case 'revai':
        return runRevAiSTT()
      default:
        return runSymbl()
    }
  }

  const muteSpeechToText = (status: boolean) => {
    switch (provider) {
      case 'revai':
        return muteRevAiSTT(status)
      default:
        return muteSymbl(status)
    }
  }

  const stopSpeechToText = () => {
    switch (provider) {
      case 'revai':
        return stopRevAiSTT()
      default:
        return stopSymbl()
    }
  }

  return {
    isConnected: getConnectedStatus(),
    isReady: getReadyStatus(),
    changeMicDevice,
    muteSpeechToText,
    runSpeechToText,
    stopSpeechToText,
  }
}

export default useSpeechToText
