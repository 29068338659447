import { put, takeEvery, call } from 'typed-redux-saga'

import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { subscribe } from '../../api'
import { validateEmail } from '../../utils/string'

interface ISubscribeAction {
  email: string
  source: string
}

const {
  action: subscribeAction,
  actionFailure: subscribeActionFailure,
  actionSuccess: subscribeActionSuccess,
  actionSuccessClear: subscribeActionSuccessClear,
  actionFailureClear: subscribeActionFailureClear,
  actionType: subscribeActionType,
  actionTypeFailure: subscribeActionFailureType,
  actionTypeSuccess: subscribeActionSuccessType,
  reducer: subscribeRequestReducer,
  selectors: subscribeSelectors,
} = reduxRequestFactory<ISubscribeAction>('subscribe', 'requests')

export {
  subscribeAction,
  subscribeActionFailure,
  subscribeActionSuccess,
  subscribeActionSuccessClear,
  subscribeActionFailureClear,
  subscribeActionType,
  subscribeActionFailureType,
  subscribeActionSuccessType,
  subscribeRequestReducer,
  subscribeSelectors,
}

function* subscribeSaga({ payload }: ISagaAction<ISubscribeAction>) {
  try {
    const { email, source } = payload

    if (validateEmail(email)) {
      yield* call(subscribe, email, { source })
      yield put(subscribeActionSuccess())
    } else {
      const errorMessage = 'Please enter a valid email address'
      yield call(handleErrorSaga, errorMessage)
      yield put(subscribeActionFailure({ error: errorMessage }))
    }
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      subscribeActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchSubscribe() {
  yield takeEvery(subscribeActionType, subscribeSaga)
}
