import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  background-color: ${({ theme }: { theme: any }) => theme.bodyBackgroundColor};
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: -webkit-fill-available;
`

export function Page({ children }: React.HTMLProps<HTMLDivElement>) {
  return <Container>{children}</Container>
}

const MobileContainer = styled.div<{
  isExpert: boolean
  fullscreen: boolean
  height: number
}>`
  background-color: ${({ theme }: { theme: any }) => theme.bodyBackgroundColor};
  display: grid;
  grid-template-areas: ${({ isExpert }) =>
    `"header" "main" ${isExpert ? '"footer"' : ''}`};
  grid-template-rows: ${({ isExpert }) => `62px auto ${isExpert ? '152px' : ''}`};
  height: ${({ height }) => `${height}px`};
  position: relative;

  @media (orientation: landscape) {
    grid-template-areas: 'main';
    grid-template-rows: auto;
  }
`

interface IPageMobile extends React.HTMLProps<HTMLDivElement> {
  fullscreen: boolean
  isExpert: boolean
  extendFullscreen: () => void
}

export function PageMobile({
  children,
  fullscreen,
  isExpert,
  extendFullscreen,
}: IPageMobile) {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(window.innerHeight)
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight)
    })
  }, [])

  return (
    <MobileContainer
      fullscreen={fullscreen}
      height={height}
      isExpert={isExpert}
      onClick={extendFullscreen}
    >
      {children}
    </MobileContainer>
  )
}
