import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'
import logo from '../../static/logo_white.png'

const Container = styled.div`
  margin: 35px 16px 24px;
  display: flex;
  flex-direction: column;
  z-index: 10;
`

const WizcoLogo = styled.img`
  height: 24px;
  width: 106px;
  margin-bottom: 60px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #fff;
`

interface IProps {
  isExpert?: boolean
}

function PreStartHeaderMobile({ isExpert }: IProps) {
  const interviewDetails = useSelector(interviewSelectors.interview)

  return (
    <Container>
      <WizcoLogo src={logo} />
      <Title>
        {isExpert &&
          `${interviewDetails.peer.first_name} ${interviewDetails.peer.last_name} has selected
you as their expert!`}
        {!isExpert &&
          `Coaching session for ${interviewDetails.interview.desired_position} at ${interviewDetails.interview.position_company}`}
      </Title>
    </Container>
  )
}

PreStartHeaderMobile.defaultProps = {
  isExpert: false,
}

export default PreStartHeaderMobile
