import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useStateRef from 'react-usestateref'
import { updateSynthesizedVoiceAction, avaSettingsSelectors } from '../../redux'
import { getSpeechData, loadSpeechData } from '../speechService'

interface IProps {
  text: string
  canSpeak?: boolean
  delayMs?: number
  staticText?: boolean
  onFinish?: () => void
}

const ShortSpeed = 150
const AvgSpeed = 200
const LongSpeed = 380
const ExtraLongSpeed = 750
const ExtraDelaySmall = 400
const ExtraDelay = 1000

function LiveText({ text, canSpeak, delayMs, staticText, onFinish }: IProps) {
  const dispatch = useDispatch()
  const avaSettings = useSelector(avaSettingsSelectors.data)
  const avaSettingsRef = useRef(avaSettings)

  const [, setActualText, actualTextRef] = useStateRef('')
  const [result, setResult] = useState('')
  const [textFinished, setTextFinished] = useState(false)
  const [speechFinished, setSpeechFinished] = useState(false)
  const isAudioRunRef = useRef(false)
  const timerRef = useRef<any>()
  const textRef = useRef('')
  const audioRef = useRef<HTMLAudioElement>(null)

  const timeout = (delay: number) => {
    return new Promise((res) => setTimeout(res, delay))
  }

  const getTimeout = (word: string) => {
    let delay = ExtraLongSpeed
    if (word.length <= 10) delay = LongSpeed
    if (word.length <= 7) delay = AvgSpeed
    if (word.length <= 3) delay = ShortSpeed

    if (word.match(/[,/!/?]\s*$/)) {
      delay += ExtraDelaySmall
    }
    if (word.match(/\.\s*$/)) {
      delay += ExtraDelay
    }
    return delay
  }

  const run = async (txt: string) => {
    setResult('')
    textRef.current = txt
    if (timerRef.current) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
    const regexp = /[\w!?’\\.'`:()%-]+[\s,]*/g
    const words = txt.match(regexp).map((r) => r)
    let delay = getTimeout(words[0])
    setResult(words[0])
    for (let i = 1; i < words.length; i += 1) {
      const newWord = words[i]
      delay = getTimeout(words[i - 1])
      // eslint-disable-next-line no-await-in-loop
      await timeout(delay)
      if (txt === textRef.current) {
        setResult((s) => `${s}${newWord}`)
      } else {
        break
      }
    }
    setTextFinished(true)
  }

  const playSoundData = useCallback(
    (data: string) => {
      const mp3 = `data:audio/mpeg;base64,${data}`
      // const audio: any = new Audio(mp3)
      const audio = document.getElementById('ava-audio') as any
      audio.src = mp3
      if (audio.setSinkId) {
        audio.setSinkId(avaSettings.speakerId)
      }
      audioRef.current = audio
      audio.onended = () => {
        audioRef.current = null
        setSpeechFinished(true)
      }
      isAudioRunRef.current = true
      if (!avaSettingsRef.current.muted && canSpeak) {
        audio
          .play()
          .then()
          .catch((err) => {
            isAudioRunRef.current = false
            setSpeechFinished(true)
            console.warn('Could not play sound', err)
          })
      }
    },
    [avaSettings.speakerId, canSpeak]
  )

  useEffect(() => {
    if (audioRef.current) {
      if ((audioRef.current as any).setSinkId) {
        ;(audioRef.current as any).setSinkId(avaSettings.speakerId)
      }
    }
  }, [avaSettings.speakerId])

  useEffect(() => {
    setActualText(text)
    if (text) {
      setTextFinished(false)
      setSpeechFinished(false)
      setResult('')
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current = null
      }
      if (staticText) {
        setResult(text)
        onFinish()
      } else {
        setTimeout(() => {
          getSpeechData(text)
            .then((soundData) => {
              if (actualTextRef.current !== text) return
              playSoundData(soundData)
              run(text)
            })
            .catch(() => {
              loadSpeechData(text)
                .then((soundData) => {
                  playSoundData(soundData)
                  run(text)
                })
                .catch(() => {
                  run(text)
                  setSpeechFinished(true)
                })
            })
        }, delayMs)
      }
    }

    return () => {
      setActualText('')
      audioRef.current?.pause()
      isAudioRunRef.current = false
      dispatch(updateSynthesizedVoiceAction({ audioContent: '', text: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  useEffect(() => {
    if (audioRef.current) {
      if (canSpeak) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [canSpeak])

  useEffect(() => {
    avaSettingsRef.current = avaSettings
  }, [avaSettings])

  useEffect(() => {
    if (audioRef.current) {
      if (avaSettings.muted) {
        audioRef.current.pause()
      } else {
        audioRef.current.play().catch((e) => console.log('[sound play error]', e))
      }
    }
  }, [avaSettings.muted])

  useEffect(() => {
    if (textFinished && (speechFinished || avaSettings.muted)) {
      onFinish()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFinished, speechFinished, avaSettings.muted])

  return <>{result}</>
}

LiveText.defaultProps = {
  onFinish: () => {},
  delayMs: 0,
  staticText: false,
  canSpeak: true,
}

export default LiveText
