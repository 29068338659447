import React from 'react'
import { Route } from 'react-router-dom'
import { isAdminRole } from 'src/utils/jwtToken'
import { NotAllowed } from '../../components'

// eslint-disable-next-line
function AuthRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line
      {...rest}
      render={() => (isAdminRole() ? children : <NotAllowed />)}
    />
  )
}

export default AuthRoute
