import React from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const AnimationLengthMs = 400

const grow = (inverse?: boolean) => keyframes`
  from {
    height: ${inverse ? 100 : 50}%;
  }

  to {
    height: ${inverse ? 50 : 100}%;
  }
`

const Container = styled.div`
  width: 66px;
  height: 32px;
  padding: 10px 24px;
  background: #f4dcff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LevelItemAnimated = styled.div<{ inverse?: boolean }>`
  background: #b202ff;
  border-radius: 100px;
  height: 25%;
  width: 4.5px;
  animation: ${(props) => grow(props.inverse)} ${AnimationLengthMs}ms linear infinite
    alternate;
`

const ListeningAnimation = () => {
  return (
    <Container>
      <LevelItemAnimated />
      <LevelItemAnimated inverse />
      <LevelItemAnimated />
    </Container>
  )
}

export default ListeningAnimation
