import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'
import logo from '../../static/logo_white.png'

const Container = styled.div`
  margin: 35px 16px 24px;
  display: flex;
  flex-direction: column;
  z-index: 10;
`

const WizcoLogo = styled.img`
  height: 24px;
  width: 106px;
  margin-bottom: 60px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #fff;
`

function AvailabilityHeaderMobile() {
  const interviewDetails = useSelector(interviewSelectors.interview)

  return (
    <Container>
      <WizcoLogo src={logo} />
      <Title>{interviewDetails.profile.first_name}, you have a potential match!</Title>
    </Container>
  )
}

export default AvailabilityHeaderMobile
