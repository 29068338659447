import * as React from 'react'

interface SvgProps {
  fill: string
  showBadge: boolean
  style?: any
}

function SvgChat({ fill, style, showBadge }: SvgProps) {
  return (
    <svg
      style={style}
      width="15"
      height="15"
      viewBox="0 -2 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12439 -6.56991e-05C10.0665 0.021671 12.69 1.85431 13.7187 4.60679C14.7475 7.35954 13.9674 10.4606 11.7581 12.4013C9.5492 14.3417 6.37 14.7193 3.76683 13.3505L3.73439 13.3392C3.72933 13.3362 3.72851 13.3337 3.72742 13.3303L3.24396 13.0567C3.14549 13.0164 3.03638 13.0099 2.96976 13.0268C2.4672 13.2074 1.95357 13.3558 1.43202 13.471L1.33642 13.4825C0.762278 13.4952 0.472994 13.1227 0.472994 12.5805L0.486836 12.4644C0.617216 11.9252 0.780613 11.3945 0.96658 10.903C0.995424 10.81 0.985822 10.7093 0.936044 10.616L0.805837 10.3622C-0.345042 8.16176 -0.25825 5.52033 1.03459 3.39995C2.32726 1.27984 4.637 -0.00994702 7.12439 -6.56991e-05ZM7.11759 0.987741L6.91864 0.990855C4.85558 1.04988 2.95614 2.14698 1.87847 3.91447C0.766187 5.73873 0.691524 8.01104 1.68344 9.90759L1.8116 10.1575C1.98205 10.4765 2.01771 10.8503 1.90109 11.2235C1.71986 11.7048 1.56839 12.1968 1.44752 12.6967L1.51418 12.4363L1.79402 12.3632C1.98597 12.3103 2.17887 12.2518 2.37484 12.1871L2.67136 12.0853C2.98459 11.999 3.31762 12.019 3.64569 12.1542C3.71455 12.1874 3.8 12.2335 3.90771 12.2944L4.20686 12.466C4.21192 12.4674 4.21636 12.4686 4.21975 12.4695L4.21101 12.4684L4.41482 12.5705C6.5579 13.6053 9.11104 13.301 10.9499 11.7912L11.1058 11.6587C13.0068 9.98886 13.6779 7.321 12.7929 4.95279C11.9077 2.58431 9.64968 1.007 7.11759 0.987741ZM3.97797 6.27541C4.42429 6.27541 4.78644 6.63696 4.78644 7.08336C4.78644 7.52976 4.42429 7.89131 3.97797 7.89131C3.53165 7.89131 3.16951 7.52976 3.16951 7.08336C3.16951 6.63696 3.53165 6.27541 3.97797 6.27541ZM7.12076 6.27541C7.56708 6.27541 7.92922 6.63696 7.92922 7.08336C7.92922 7.52976 7.56708 7.89131 7.12076 7.89131C6.67444 7.89131 6.3123 7.52976 6.3123 7.08336C6.3123 6.63696 6.67444 6.27541 7.12076 6.27541ZM10.2635 6.27541C10.7099 6.27541 11.072 6.63696 11.072 7.08336C11.072 7.52976 10.7099 7.89131 10.2635 7.89131C9.81723 7.89131 9.45509 7.52976 9.45509 7.08336C9.45509 6.63696 9.81723 6.27541 10.2635 6.27541Z"
        fill={fill || '#fff'}
      />
      {showBadge && <circle cx="12" cy="12" r="4" fill="#FF002A" />}
    </svg>
  )
}

SvgChat.defaultProps = {
  style: null,
}

export default SvgChat
