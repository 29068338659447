import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgUser({ fill }: SvgProps) {
  return (
    <svg
      width="12"
      height="15"
      viewBox="-3 -1 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12963 9.08146C5.1523 8.99125 6.18088 8.99125 7.20355 9.08146L7.53007 9.10909C7.9767 9.15311 8.42016 9.22205 8.85656 9.31522C9.96888 9.54436 10.7766 9.95085 11.1341 10.7049C11.3998 11.2658 11.3998 11.9171 11.134 12.4783C10.7756 13.2342 9.95745 13.6441 8.86486 13.8596L8.53382 13.9284C8.09137 14.0133 7.6452 14.0713 7.202 14.1016C6.37035 14.1724 5.53482 14.1853 4.72885 14.141L4.58356 14.1404L4.52784 14.1394L4.42565 14.1356C4.30142 14.1293 4.1876 14.1162 4.13984 14.1022C3.57911 14.0641 3.02211 13.983 2.48777 13.8624L2.30652 13.8246C1.29471 13.5998 0.54539 13.1942 0.198785 12.4775C0.0675175 12.1994 -0.000380053 11.8954 1.40229e-05 11.5903C-0.00112663 11.2829 0.0673251 10.9793 0.203475 10.6957C0.564451 9.97071 1.41256 9.53442 2.47266 9.31605C3.02191 9.19834 3.57875 9.1197 4.12963 9.08146ZM7.1142 10.103C6.15098 10.018 5.1822 10.018 4.20959 10.1037C3.69724 10.1393 3.18803 10.2113 2.6818 10.3197C1.89778 10.4812 1.29834 10.7896 1.12002 11.1477C1.05435 11.2845 1.02053 11.4345 1.02109 11.589C1.0209 11.7444 1.05518 11.8979 1.11935 12.0339C1.30275 12.413 1.86839 12.6964 2.69717 12.8589L2.94687 12.9117C3.36507 12.9946 3.79578 13.0518 4.27443 13.0879C4.33488 13.1001 4.40011 13.1075 4.47751 13.1115L4.75631 13.1163C5.54269 13.1588 6.33105 13.1466 7.12412 13.0792C7.63524 13.0441 8.14301 12.9704 8.6555 12.8561L8.81485 12.8223C9.54268 12.6573 10.0463 12.3872 10.212 12.0377C10.3457 11.7553 10.3457 11.4276 10.2121 11.1455C10.0347 10.7714 9.47404 10.4892 8.64782 10.319C8.14521 10.2117 7.63577 10.1397 7.12319 10.1037L7.1142 10.103ZM5.66971 0C7.74798 0 9.43274 1.69195 9.43274 3.77908C9.43274 5.86621 7.74798 7.55816 5.66971 7.55816C3.59144 7.55816 1.90667 5.86621 1.90667 3.77908C1.90667 1.69195 3.59144 0 5.66971 0ZM5.66971 1.02543C4.15537 1.02543 2.92775 2.25828 2.92775 3.77908C2.92775 5.29988 4.15537 6.53273 5.66971 6.53273C7.18405 6.53273 8.41167 5.29988 8.41167 3.77908C8.41167 2.25828 7.18405 1.02543 5.66971 1.02543Z"
        fill={fill || '#fff'}
      />
    </svg>
  )
}

export default SvgUser
