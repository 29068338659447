import React from 'react'

function SvgAudioMute() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.5115 10.1053C21.0395 10.1053 21.4674 10.5429 21.4674 11.0828C21.4674 15.7386 18.0089 19.5839 13.5812 20.0728V22.4432C13.7327 23.4811 13.414 24 12.6253 24C11.8366 24 11.518 23.4811 11.6694 22.4432V20.0728C7.24171 19.5839 3.7832 15.7386 3.7832 11.0828C3.7832 10.5429 4.21116 10.1053 4.73911 10.1053C5.26705 10.1053 5.69501 10.5429 5.69501 11.0828C5.69501 14.9908 8.80394 18.1702 12.6253 18.1702C16.4467 18.1702 19.5556 14.9908 19.5556 11.0828C19.5556 10.5429 19.9836 10.1053 20.5115 10.1053ZM12.6253 0C15.3294 0 17.5442 2.01842 17.6721 4.54515L17.6779 4.77621V11.6448C17.6779 14.2784 15.4113 16.4211 12.6253 16.4211C9.92123 16.4211 7.70646 14.4026 7.57851 11.8759L7.57268 11.6448V4.77621C7.57268 2.14261 9.83929 0 12.6253 0ZM12.6253 1.81951C10.967 1.81951 9.6059 3.04582 9.50365 4.5895L9.49749 4.77621V11.6448C9.49749 13.2752 10.9006 14.6015 12.6253 14.6015C14.35 14.6015 15.7531 13.2752 15.7531 11.6448V4.77621C15.7531 3.14588 14.35 1.81951 12.6253 1.81951Z"
          fill="#FF002A"
        />
        <path
          d="M20.3218 2.18604L3.12109 18.1582"
          stroke="#FF002A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgAudioMute
