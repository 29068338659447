// eslint-disable-next-line import/prefer-default-export
export const WarningPhrasesMap = [
  {
    pharses: ['Just'],
    tip: `"Just" demeans what you have to say. "Just" shrinks your power. It's time to say goodbye to the justs. --Tara Sophia Mohr`,
  },
  {
    pharses: ['Actually'],
    tip: `"Actually" communicates a sense of surprise that you have something to say. Of course you want to add something. Of course you have questions. There's nothing surprising about it. --Tara Sophia Mohr`,
  },
  {
    pharses: ['Sorry'],
    tip: `Using "sorry" frequently undermines your gravitas and makes you appear unfit for leadership. --Sylvia Ann Hewlett`,
  },
  {
    pharses: ['Apologize', 'Apologies', 'Forgive'],
    tip: `Apologizing unnecessarily puts you in a subservient position and makes people lose respect for you --Bonnie Marcus`,
  },
  {
    pharses: ['I think', 'We think'],
    tip: `"I think" undermines your idea and displays an overall lack of self-confidence. --Lydia Dishman`,
  },
  {
    pharses: [
      `I'm no expert`,
      'I am no expert',
      `I'm not an expert`,
      'I am not an expert',
      `We're no expert`,
      'We are no expert',
      `We're no experts`,
      'We are no experts',
      `We're not experts`,
      'We are not experts',
    ],
    tip: `"I'm no expert" undermines your idea and displays an overall lack of self-confidence. --Lydia Dishman`,
  },
  {
    pharses: ['Yes, but', 'Yes but'],
    tip: `The "Yes, but" syndrome is entirely counterproductive, particularly in a work setting. You will become an integral part of any team if you are willing to build ideas rather than discard them. --Victoria Simon, Ph.D. and Holly Pedersen, Ph.D.`,
  },
  {
    pharses: ['Literally'],
    tip: `If something is literal, your readers should know it without you needing to use this word to clarify it. More often than not, the word "literally" makes writing sound flabby and juvenile, which is probably not what you're going for. --Julia McCoy`,
  },
  {
    pharses: ['Very'],
    tip: `The word 'very' does not communicate enough information. Find a stronger, more meaningful adverb, or omit it completely. --Andrea Ayres`,
  },
  {
    pharses: ['Kind of', 'Kinda', 'Sort of', 'Sorta'],
    tip: `This qualifier weakens the message as well as the authority of the writer. --Victoria Simon, Ph.D. and Holly Pedersen, Ph.D.`,
  },
  {
    pharses: [
      `Does that make sense`,
      'Did that make sense',
      `Does this make sense`,
      'Did this make sense',
      `Does it make sense`,
      'Did it make sense',
      `If that makes sense`,
      'If this makes sense',
      `Am I making sense`,
      'Did I make sense',
      'Did I explain that correctly',
      'Am I explaining that correctly',
    ],
    tip: `"does that make sense" comes across either as condescending (like your audience can't understand) or it implies you feel you've been incoherent. A better way to close is something like "I look forward to hearing your thoughts." You can leave it up to the other party to let you know if they are confused about something, rather than implying that you "didn't make sense." --Tara Sophia Mohr`,
  },
  {
    pharses: ['Try', 'Trying', 'Tried'],
    tip: `"Do or do not. There is no try." --Yoda`,
  },
  {
    pharses: ['I should', 'We should'],
    tip: `The word "should" is inherently negative. "Should" implies a lose: lose situation and it's just not conducive to positive outcomes in life. It's a form of criticism, and it's best left out of your everyday language. Instead of beating yourself up for what you should have done, focus on what you have the power to change. -- Zoe B`,
  },
  {
    pharses: ['I feel', 'We feel'],
    tip: `If you write an opinion, the reader understands that you also believe it is right. --David Bowman`,
  },
  {
    pharses: ['I believe', 'We believe'],
    tip: `Phrases containing "we believe," "we think," and "we feel" pervade presentation narratives to such a degree that they spill over into sentences where caution is unnecessary...the spillage weakens what should otherwise be assertive language. --Jerry Weissman`,
  },
  {
    pharses: [`I'm just saying`, 'I am just saying', 'Just saying'],
    tip: `I think what you're saying is that you said something. If you're using it to mitigate something that may be offensive or embarrassing, then don't say it. Say something else. Otherwise, say what you're saying without the "just saying." We already know you're saying it... after all, you just said it! --Robert Bruce`,
  },
  {
    pharses: ['In my opinion', 'In my honest opinion'],
    tip: `Phrases such as "in my opinion," "I think that," and "I believe" create three problems for writers: 1. They delay the writer's message; 2. They demonstrate insecurity; and 3. They tell the reader what he already knows. Remove that phrase, or any similar phrase, and get to the point. --David Bowman`,
  },
  {
    pharses: [
      `I'm just being honest here`,
      'I am just being honest here',
      `I'm just being honest`,
      'I am just being honest',
      `If I'm being honest`,
      'If I am being honest',
      `Honestly`,
      'To be honest',
      `In all honesty`,
      'To be perfectly honest',
      'To be frank',
    ],
    tip: `Tee-ups like this may make the reader shut down and respond negatively to your comment. --Thorin Klosowski`,
  },
  {
    pharses: [
      `This might be a stupid question`,
      'This is a stupid question',
      `Is this a stupid question`,
      'This might be a silly idea',
      `This is a silly idea`,
      'Is this a silly idea',
      `This might be a dumb idea`,
      'This is a dumb idea',
      `Is this a dumb idea`,
    ],
    tip: `Like they said in school, there are no stupid questions. Well, sometimes there are--but ask, don't caveat. --Alexandra Macon`,
  },
  {
    pharses: [
      `I may be wrong`,
      'I might be wrong',
      `I could be wrong`,
      'This might be a silly idea',
      `I think I'm wrong`,
      'I think I am wrong',
    ],
    tip: `Don't lessen the impact of what you say before you say it. --Alexandra Macon`,
  },
  {
    pharses: ['I guess', 'I guess so', 'I guessed'],
    tip: `If you're sure of something, "guessing" detracts from your message and opens doubt in the reader's mind. --Mary Rezek`,
  },
  {
    pharses: ['Maybe', 'Might be'],
    tip: `By adding "maybe" to your sentence, it makes it seem like you aren't confident in your answer/suggestion. Say what you mean. If you mean no, say no, if you mean yes, say yes.`,
  },
]
