import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: row;
  align-items: center;

  > :nth-child(2) {
    margin-left: 5px;
  }
`

export const Sidebar = styled.div`
  background-color: #f5f6f8;
  align-items: center;
  position: relative;
  flex-shrink: 0;
  justify-content: center;
  display: flex;
  overflow: hidden;
  align-items: center;
  display: flex;
  width: 300px;
  margin: 0 10px;
  flex-direction: column;
`
