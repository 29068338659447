import { NetworkQuality } from 'agora-rtc-sdk-ng'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useStateRef from 'react-usestateref'

import { callNetworkLocalSelectors } from '../../redux'
import { CallNetworkQuality } from '../../redux/types'
import getNetworkQuality from '../../utils/networkQuality'

const getNetworkQualityLabel = (quality: 0 | 1 | 2 | 3 | 4 | 5 | 6) => {
  switch (quality) {
    case 1:
      return 'Excellent'
    case 2:
      return 'Good'
    case 3:
      return 'Average'
    case 4:
      return 'Poor'
    case 5:
      return 'Bad'
    case 6:
      return 'None'
    default:
      return 'Unknown'
  }
}

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Network]',
  'color:#1e133b',
]

const useNetworkQuality = (sendNetworkQuality: (quality: NetworkQuality) => void) => {
  const [hadPoorConnection, setHadPoorConnection, hadPoorConnectionRef] = useStateRef(
    false
  )
  const [hasPoorConnection, setHasPoorConnection, hasPoorConnectionRef] = useStateRef(
    false
  )
  const [
    notificationVisible,
    setNotificationVisible,
    notificationVisibleRef,
  ] = useStateRef(true)

  const [hasNoConnection, setHasNoConnection, hasNoConnectionRef] = useStateRef(false)

  const data = useSelector(callNetworkLocalSelectors.data)

  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  useEffect(() => {
    if (!data) return
    const { downlinkNetworkQuality: down, uplinkNetworkQuality: up } = data
    log(
      `Local quality: ${getNetworkQualityLabel(up)} uplink, ${getNetworkQualityLabel(
        down
      )} downlink`
    )
    sendNetworkQuality(data)

    const quality = getNetworkQuality(data)

    if (hasNoConnectionRef.current && quality !== CallNetworkQuality.NONE)
      setHasNoConnection(false)
    else if (!hasNoConnectionRef.current && quality === CallNetworkQuality.NONE)
      setHasNoConnection(true)

    if (hadPoorConnectionRef.current || !notificationVisibleRef.current || !quality)
      return

    if (quality === CallNetworkQuality.BAD && !hasPoorConnectionRef.current) {
      setHasPoorConnection(true)
    } else if (quality !== CallNetworkQuality.BAD && hasPoorConnectionRef.current) {
      setHadPoorConnection(true)
      setHasPoorConnection(false)
      setTimeout(() => {
        setHadPoorConnection(false)
        setNotificationVisible(false)
      }, 30000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const hidePoorConnectionNotification = () => {
    setNotificationVisible(false)
  }

  return {
    hasNoConnection,
    hasPoorConnectionNotification:
      (hasPoorConnection || hadPoorConnection) && notificationVisible,
    hidePoorConnectionNotification,
  }
}

export default useNetworkQuality
