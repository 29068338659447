import React from 'react'
import styled from '@emotion/styled'

const themes = {
  filled: {
    primary: {
      color: 'white',
      backgroundColor: '#B202FF',
      borderColor: 'transparent',
    },
    hover: {
      color: 'white',
      backgroundColor: 'rgba(178,2,255,0.5)',
      borderColor: 'transparent',
    },
    active: {
      color: 'white',
      backgroundColor: 'rgba(178,2,255,0.75)',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'white',
      backgroundColor: 'rgba(178,2,255,0.25)',
      borderColor: 'transparent',
    },
  },
  outline: {
    primary: {
      color: '#B202FF',
      backgroundColor: 'white',
      borderColor: '#B202FF',
    },
    hover: {
      color: '#B202FF',
      backgroundColor: 'white',
      borderColor: 'rgba(178,2,255,0.75)',
    },
    active: {
      color: '#7B2E9D',
      backgroundColor: 'white',
      borderColor: '#7B2E9D',
    },
    disabled: {
      color: 'rgba(178,2,255,0.5)',
      backgroundColor: 'white',
      borderColor: '#EDEEF2',
    },
  },
  blue: {
    primary: {
      color: 'white',
      backgroundColor: '#0095FF',
      borderColor: 'transparent',
    },
    hover: {
      color: 'white',
      backgroundColor: 'кgba(0,149,255,0.5)',
      borderColor: 'transparent',
    },
    active: {
      color: 'white',
      backgroundColor: '#0179ce',
      borderColor: 'transparent',
    },
    disabled: {
      color: 'white',
      backgroundColor: 'rgba(0,149,255,0.25)',
      borderColor: 'transparent',
    },
  },
  dark: {
    primary: {
      color: 'white',
      backgroundColor: '#353C5A',
      borderColor: 'transparent',
    },
    hover: {
      color: 'white',
      backgroundColor: 'rgba(53, 60, 90, 0.9)',
      borderColor: 'transparent',
    },
    active: {
      color: 'white',
      backgroundColor: 'rgba(34, 38, 58)',
      borderColor: 'transparent',
    },
    disabled: {
      color: '#353C5A',
      backgroundColor: 'rgba(53, 60, 90, 0.25)',
      borderColor: 'transparent',
    },
  },
  darkOutline: {
    primary: {
      color: 'rgba(53, 60, 90, 1)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(53, 60, 90, 1)',
    },
    hover: {
      color: 'rgba(53, 60, 90, 0.75)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(53, 60, 90, 0.75)',
    },
    active: {
      color: 'rgba(53, 60, 90, 1)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(53, 60, 90, 1)',
    },
    disabled: {
      color: 'rgba(53, 60, 90, 0.5)',
      backgroundColor: 'transparent',
      borderColor: 'rgba(53, 60, 90, 0.5)',
    },
  },
}

export const getColor = (
  { customTheme, theme, useTheme }: any,
  prop: string,
  state: string
) => {
  if (theme.agencyColor && useTheme && customTheme === 'outline') {
    if (prop === 'color' || prop === 'borderColor') return theme.agencyColor
    return '#fff'
  }
  if (theme.agencyColor && useTheme) {
    if (prop === 'color') return '#fff'
    return theme.agencyColor
  }

  return themes[customTheme][state][prop]
}

const getOpacity = ({ useTheme, theme }: any, state: string) => {
  if (!useTheme || !theme.agencyColor) return 1

  switch (state) {
    case 'primary':
      return 1
    case 'hover':
      return 0.5
    case 'active':
      return 0.75
    case 'disabled':
      return 0.25
    default:
      return 1
  }
}

interface IButtonProps {
  title: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  // eslint-disable-next-line
  onClick?: any
  className?: string
  customTheme?: 'filled' | 'outline' | 'blue' | 'dark' | 'darkOutline'
  useTheme?: boolean
}

interface IButtonStyledProps {
  customTheme: 'filled' | 'outline' | 'blue' | 'dark' | 'darkOutline'
  useTheme: boolean
}

// theme ? theme.agencyColor : themes[customTheme].primary.backgroundColor};
const ButtonStyled = styled('button')<IButtonStyledProps>`
  outline: none !important;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => getColor(props, 'borderColor', 'primary')};
  background-color: ${(props) => getColor(props, 'backgroundColor', 'primary')};
  color: ${(props) => getColor(props, 'color', 'primary')};
  border-radius: 4px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  max-width: 400px;
  height: 56px;
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => getColor(props, 'backgroundColor', 'hover')};
    border-color: ${(props) => getColor(props, 'borderColor', 'hover')};
    color: ${(props) => getColor(props, 'color', 'hover')};
    opacity: ${(props) => getOpacity(props, 'hover')};
  }

  &:active {
    background-color: ${(props) => getColor(props, 'backgroundColor', 'active')};
    border-color: ${(props) => getColor(props, 'borderColor', 'active')};
    color: ${(props) => getColor(props, 'color', 'active')};
    opacity: ${(props) => getOpacity(props, 'active')};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => getColor(props, 'backgroundColor', 'disabled')};
    border-color: ${(props) => getColor(props, 'borderColor', 'disabled')};
    color: ${(props) => getColor(props, 'color', 'disabled')};
    opacity: ${(props) => getOpacity(props, 'disabled')};
  }
`

const Button = React.forwardRef((props: IButtonProps, ref: any) => {
  const { type, disabled, title, onClick, className, customTheme, useTheme } = props

  const clickHandler = onClick || (() => {})

  return (
    <ButtonStyled
      useTheme={useTheme}
      className={className}
      onClick={clickHandler}
      disabled={disabled}
      type={type}
      ref={ref}
      customTheme={customTheme || 'filled'}
    >
      {title}
    </ButtonStyled>
  )
})

Button.defaultProps = {
  useTheme: false,
  disabled: false,
  type: 'submit',
  className: '',
  customTheme: 'filled',
}

export default Button
