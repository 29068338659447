import styled from '@emotion/styled'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  LandingInfo,
  LandingButton,
  LandingTitle,
  BaseResizableLayout,
} from './landing-styled-components'

const Layout = styled(BaseResizableLayout)`
  width: 100%;
  height: 670px;
  background: #000000;
  border-radius: 32px;
  display: flex;
  margin: 40px auto 40px;

  @media screen and (max-width: 1439px) {
    margin: 24px auto 40px;
  }

  @media screen and (max-width: 1279px) {
    height: auto;
  }

  @media screen and (max-width: 1144px) {
    height: auto;
  }

  @media screen and (max-width: 899px) {
    flex-direction: column-reverse;
    height: auto;
    padding: 57px 62px;
    margin: 16px auto 48px;
  }

  ${isMobileOnly &&
  css`
    flex-direction: column-reverse;
    height: auto !important;
    margin: 32px 0px 24px 0px !important;
    width: auto;
    padding: 26px 0 37px 0 !important;
  `}
`

const DataContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;

  @media screen and (max-width: 899px) {
    margin-top: 40px;
  }

  ${isMobileOnly &&
  css`
    width: 100% !important;
    align-items: center;
    margin-top: 0px !important;
  `}
`

const DataBlock = styled.div`
  width: 100%;
  padding-left: 160px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 1280px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 1144px) {
    padding-left: 64px;
  }

  @media screen and (max-width: 899px) {
    padding: 0;
  }

  ${isMobileOnly &&
  css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `}
`

const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 431px;
  margin: 50px 160px 37px 0;

  @media screen and (max-width: 1280px) {
    width: 375px;
    margin: 37px 80px 37px 0;
  }

  @media screen and (max-width: 899px) {
    flex-direction: column-reverse;
    height: auto;
    margin: 0 auto;
    padding-right: 40px;
  }

  > video {
    margin-left: auto;

    @media screen and (max-width: 899px) {
      margin: 0;
    }
  }

  ${isMobileOnly &&
  css`
    height: auto !important;
    flex: unset !important;
    width: 107px !important;
    margin: 0 auto 12px auto !important;

    > video {
      width: 100%;
    }
  `}
`

const LandingButtonStyled = styled(LandingButton)`
  margin: 40px 0 0;
  padding: 12px 48px;
  line-height: 20px;

  @media screen and (max-width: 1144px) {
    margin: 24px 0 0;
  }

  @media screen and (max-width: 899px) {
    margin: 24px auto 0;
  }

  ${isMobileOnly &&
  css`
    margin: 9px 0 0 !important;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  font-size: 200px;
  line-height: 160px;
  margin-bottom: 24px;
  text-align: start;

  @media screen and (max-width: 1439px) {
    font-size: 200px;
    line-height: 160px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 120px;
    line-height: 136px;
  }

  @media screen and (max-width: 1144px) {
    font-size: 120px;
    line-height: 120px;
  }

  @media screen and (max-width: 899px) {
    text-align: center;
  }
`

const LandingInfoStyled = styled(LandingInfo)`
  margin-bottom: auto;
  font-size: 32px;
  text-align: start;

  @media screen and (max-width: 1439px) {
    font-size: 32px;
    line-height: 44px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 899px) {
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    ont-size: 20px;
  `}
`

interface IProps {
  onStart: () => void
}

const LandingAvaCoachBlock = ({ onStart }: IProps) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1144px)' }) && !isMobileOnly
  return (
    <Layout>
      <DataContainer>
        <DataBlock>
          <LandingTitleStyled color="#fff">Ava.</LandingTitleStyled>
          <LandingInfoStyled color="#fff">
            {isSmallScreen
              ? 'Unlock your full potential with Ava, your AI-powered interview coach.'
              : `Unlock your full potential with Ava,\nyour AI-powered interview coach.`}
          </LandingInfoStyled>
          <LandingButtonStyled onClick={onStart} inverse>
            Meet Ava
          </LandingButtonStyled>
        </DataBlock>
      </DataContainer>
      <VideoContainer>
        <video
          src="/static/media/video/landing_coach.mp4"
          height="100%"
          width="100%"
          loop
          autoPlay
          muted
          playsInline
          onContextMenu={(event) => event.preventDefault()}
        />
      </VideoContainer>
    </Layout>
  )
}

export default LandingAvaCoachBlock
