import React from 'react'
import styled from '@emotion/styled'
import { Button, Text, TextBold } from 'src/components'
import UserIcon from 'src/static/profile.svg'
import Letter from 'src/static/letter.svg'
import { useSelector } from 'react-redux'

import { agencySelectors } from '../../redux'

const ContactWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Title = styled(Text)`
  font-size: 22px;
  margin-bottom: 20px;
`

const SubTitle = styled(Text)`
  font-size: 12px;
  margin-bottom: 20px;
`

const EmailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const ContactRoleBlock = styled.div`
  flex: 0 0 48%;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    flex: 0 0 100%;
  }
`

const RoleTitle = styled(TextBold)`
  margin-bottom: 10px;
`

const AddressWrapper = styled.div``

const AddressItem = styled(Text)``

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  width: 100px;
  height: 40px;
  margin-bottom: 0;
`

const EmailItem = styled.div`
  display: flex;
  align-items: center;
`

const RoleIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  img {
    width: 100%;
  }
`

const RoleName = styled(Text)``

const RoleEmail = styled.a`
  font-size: 12px;
  line-height: 24px;
`

const ContactUs = (props: any) => {
  const { onClose } = props

  const agency = useSelector(agencySelectors.data)

  const managerEmail = agency.success_manager_email || 'amir@wizco.io'
  const managerName = agency.success_manager_name || 'Amir Erez'

  return (
    <ContactWrapper>
      <Title>Contact Us</Title>
      <SubTitle>Feel free to contact us, we are standing by, ready to help</SubTitle>
      <EmailsWrapper>
        <ContactRoleBlock>
          <RoleTitle>Success Manager</RoleTitle>
          <EmailItem>
            <RoleIcon>
              <img src={UserIcon} alt="ico" />
            </RoleIcon>
            <RoleName>{managerName}</RoleName>
          </EmailItem>
          <EmailItem>
            <RoleIcon>
              <img src={Letter} alt="ico" />
            </RoleIcon>
            <RoleEmail href={`mailto:${managerEmail}`}>{managerEmail}</RoleEmail>
          </EmailItem>
        </ContactRoleBlock>
        <ContactRoleBlock>
          <RoleTitle>Customer care</RoleTitle>
          <EmailItem>
            <RoleIcon>
              <img src={Letter} alt="ico" />
            </RoleIcon>
            <RoleEmail href="mailto:hi@wizco.io">hi@wizco.io</RoleEmail>
          </EmailItem>
        </ContactRoleBlock>
      </EmailsWrapper>
      <AddressWrapper>
        <RoleTitle>Postal Address</RoleTitle>
        <AddressItem>108 Leonard Street, 10J</AddressItem>
        <AddressItem>New York, NY 10013</AddressItem>
        <AddressItem>United States</AddressItem>
      </AddressWrapper>
      <ButtonWrapper>
        <StyledButton title="OK" onClick={onClose} />
      </ButtonWrapper>
    </ContactWrapper>
  )
}

export default ContactUs
