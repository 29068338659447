import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  height: 6px;
  width: 100%;
  border-radius: 3px;
  background: #e1e3ea;
`

const Progress = styled.div<{ progress: number; color?: string }>`
  height: 100%;
  width: ${(props) => props.progress * 100}%;
  border-radius: 3px;
  background: ${(props) => props.color || '#B202FF'};
`

export default function ProgressBar({
  progress,
  color,
}: {
  progress: number
  color?: string
}) {
  return (
    <Container>
      <Progress progress={progress} color={color} />
    </Container>
  )
}

ProgressBar.defaultProps = {
  color: undefined,
}
