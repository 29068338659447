import React from 'react'
import styled from '@emotion/styled'
import ErrorIconImg from 'src/static/icErrorInfo.png'

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`

const Input = styled.input<{ error?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ error }) => (error ? '#f23482' : '#0e0224')};
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: solid 1px ${({ error }) => (error ? '#f23482' : '#0e0224')};
  margin-bottom: 32px;
  border-radius: 0;
  padding: 1px 0;
  outline: none;
`

const ErrorContainer = styled.div<{ show: boolean }>`
  width: fit-content;
  position: absolute;
  left: 0;
  top: 23px;
  color: #f23482;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  padding: 6px 9px 6px 17px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.4s;

  &:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    top: 8px;
    left: 0;
    background: url(${ErrorIconImg});
    background-size: 100% 100%;
  }
`

const InputStyled = ({
  value,
  placeholder,
  onChange,
  error,
}: {
  value: string
  onChange: (string) => void
  error?: string
  placeholder?: string
}) => {
  return (
    <InputContainer>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        error={error !== ''}
      />
      <ErrorContainer show={error !== ''}>{error}</ErrorContainer>
    </InputContainer>
  )
}

InputStyled.defaultProps = {
  error: '',
  placeholder: '',
}

export default InputStyled
