export default function getCurrency(code) {
  switch (code) {
    case 'USD':
      return '$'
    case 'ILS':
      return '₪'
    case 'EUR':
      return '€'
    case 'GBP':
      return '£'
    case 'CAD':
      return 'C$'
    case 'AUD':
      return 'A$'
    default:
      return ''
  }
}
