import { createAction } from '@reduxjs/toolkit'
import { IFailure } from '../utils/redux'
import {
  GET_INTERVIEWS,
  GET_INTERVIEWS_SUCCESS,
  GET_INTERVIEWS_FAILURE,
  GET_INTERVIEW,
  GET_INTERVIEW_SUCCESS,
  GET_INTERVIEW_FAILURE,
  SEND_TO_APPLICANT,
  SEND_TO_APPLICANT_SUCCESS,
  SEND_TO_APPLICANT_FAILURE,
  SEND_TO_APPLICANT_CLEAR_SUCCESS,
  SEND_TO_APPLICANT_CLEAR_ERROR,
  INTERVIEW_COMPLETE,
  INTERVIEW_COMPLETE_SUCCESS,
  INTERVIEW_COMPLETE_FAILURE,
  INTERVIEW_COMPLETE_CLEAR_SUCCESS,
  INTERVIEW_COMPLETE_CLEAR_ERROR,
  INTERVIEW_TO_END,
  INTERVIEW_TO_END_SUCCESS,
  INTERVIEW_TO_END_FAILURE,
  INTERVIEW_TO_END_CLEAR_SUCCESS,
  INTERVIEW_TO_END_CLEAR_ERROR,
  IGetInterviewsAction,
  IGetInterviewsSuccessAction,
  IGetInterviewAction,
  IGetInterviewSuccessAction,
  SAVE_SESSION_REPORT,
  SAVE_SESSION_REPORT_CLEAR_ERROR,
  SAVE_SESSION_REPORT_CLEAR_SUCCESS,
  SAVE_SESSION_REPORT_FAILURE,
  SAVE_SESSION_REPORT_SUCCESS,
  ISessionReportAction,
  LOAD_EXPERT_DATA_FAILURE,
  LOAD_EXPERT_DATA_SUCCESS,
  IAskExpertAvailability,
  ASK_EXPERT_AVAILABILITY,
  GET_STATS,
  GET_STATS_FAILURE,
  GET_STATS_SUCCESS,
  IGetStatsSuccessAction,
  IGetStatsAction,
} from './types'

export const askExpertAvailability = createAction<IAskExpertAvailability['payload']>(
  ASK_EXPERT_AVAILABILITY
)

export const getInterviews = createAction<IGetInterviewsAction['payload']>(GET_INTERVIEWS)
export const getInterviewsSuccess = createAction<IGetInterviewsSuccessAction['payload']>(
  GET_INTERVIEWS_SUCCESS
)
export const getInterviewsFailure = createAction<IFailure>(GET_INTERVIEWS_FAILURE)

export const getInterview = createAction<IGetInterviewAction['payload']>(GET_INTERVIEW)
export const getInterviewSuccess = createAction<IGetInterviewSuccessAction['payload']>(
  GET_INTERVIEW_SUCCESS
)
export const getInterviewFailure = createAction<IFailure>(GET_INTERVIEW_FAILURE)

export const saveSessionReport = createAction<ISessionReportAction['payload']>(
  SAVE_SESSION_REPORT
)
export const saveSessionReportSuccess = createAction(SAVE_SESSION_REPORT_SUCCESS)
export const saveSessionReportFailure = createAction<IFailure>(
  SAVE_SESSION_REPORT_FAILURE
)
export const saveSessionReportClearSuccess = createAction(
  SAVE_SESSION_REPORT_CLEAR_SUCCESS
)
export const saveSessionReportClearError = createAction(SAVE_SESSION_REPORT_CLEAR_ERROR)

export const sendToApplicant = createAction<IGetInterviewAction['payload']>(
  SEND_TO_APPLICANT
)
export const sendToApplicantSuccess = createAction(SEND_TO_APPLICANT_SUCCESS)
export const sendToApplicantFailure = createAction<IFailure>(SEND_TO_APPLICANT_FAILURE)
export const sendToApplicantClearSuccess = createAction(SEND_TO_APPLICANT_CLEAR_SUCCESS)
export const sendToApplicantClearError = createAction(SEND_TO_APPLICANT_CLEAR_ERROR)

export const interviewComplete = createAction<IGetInterviewAction['payload']>(
  INTERVIEW_COMPLETE
)
export const interviewCompleteSuccess = createAction(INTERVIEW_COMPLETE_SUCCESS)
export const interviewCompleteFailure = createAction<IFailure>(INTERVIEW_COMPLETE_FAILURE)
export const interviewCompleteClearSuccess = createAction(
  INTERVIEW_COMPLETE_CLEAR_SUCCESS
)
export const interviewCompleteClearError = createAction(INTERVIEW_COMPLETE_CLEAR_ERROR)

export const interviewToEnd = createAction<IGetInterviewAction['payload']>(
  INTERVIEW_TO_END
)
export const interviewToEndSuccess = createAction(INTERVIEW_TO_END_SUCCESS)
export const interviewToEndFailure = createAction<IFailure>(INTERVIEW_TO_END_FAILURE)
export const interviewToEndClearSuccess = createAction(INTERVIEW_TO_END_CLEAR_SUCCESS)
export const interviewToEndClearError = createAction(INTERVIEW_TO_END_CLEAR_ERROR)

export const loadExpertDataSuccess = createAction(LOAD_EXPERT_DATA_SUCCESS)
export const loadExpertDataFailure = createAction(LOAD_EXPERT_DATA_FAILURE)
export const getStats = createAction<IGetStatsAction['payload']>(GET_STATS)
export const getStatsSuccess = createAction<IGetStatsSuccessAction['payload']>(
  GET_STATS_SUCCESS
)
export const getStatsFailure = createAction<IFailure>(GET_STATS_FAILURE)
