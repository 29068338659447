/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { isMobileOnly } from 'react-device-detect'

const BlockWidth = isMobileOnly ? 144 : 180

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: none;
`

const AdBanner = styled.div`
  top: 0;
  width: ${BlockWidth}px;
  height: ${isMobileOnly ? 56 : 70}px;
  margin: 4px 8px;
  background: white;
  box-shadow: 0px -0.785374px 3.14149px rgba(0, 0, 0, 0.1),
    0px 3.14149px 3.14149px rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  padding: 15px;
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Separator = styled.div`
  height: 12px;
`

interface IProps {
  firstLineItems: any[]
  secondLineItems: any[]
}

function CycledCarousel({ firstLineItems, secondLineItems }: IProps) {
  const sliderRef = useRef<Slider>(null)
  const slider2Ref = useRef<Slider>(null)

  const runAnimation = () => {
    setInterval(() => {
      sliderRef.current?.slickNext()
      slider2Ref.current?.slickPrev()
    }, 1)
  }

  useEffect(() => {
    runAnimation()
  }, [])

  return (
    <Container>
      <Slider
        ref={sliderRef}
        arrows={false}
        slidesToShow={1}
        infinite
        slidesToScroll={1}
        variableWidth
        cssEase="linear"
        speed={2500}
        centerPadding="30px"
      >
        {firstLineItems.map((banner, index) => (
          <AdBanner style={{ width: BlockWidth }} key={index}>
            <BannerImage src={banner.img} alt="banner" />
          </AdBanner>
        ))}
      </Slider>
      <Separator />
      <Slider
        ref={slider2Ref}
        arrows={false}
        slidesToShow={1}
        infinite
        slidesToScroll={1}
        variableWidth
        cssEase="linear"
        speed={2500}
        centerPadding="30px"
      >
        {secondLineItems.map((banner, index) => (
          <AdBanner style={{ width: BlockWidth }} key={index}>
            <BannerImage src={banner.img} alt="banner" />
          </AdBanner>
        ))}
      </Slider>
    </Container>
  )
}

export default CycledCarousel
