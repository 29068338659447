import httpClient from '../../httpClient'

import { IExpertProfile } from '../models'

// expert_profiles#load - POST /expert_profiles/load

export const loadExpertData = (email): Promise<IExpertProfile> => {
  return httpClient
    .get(`/expert_profiles/load`, { params: { email } })
    .then((response) => response.data)
    .catch(() => null)
}

// expert_profiles#request_availability - POST /agora_recordings/:expertId/request_availability

export const requestExpertAvailability = (expertId, interviewId) => {
  return httpClient
    .get(`/expert_profiles/${expertId}/request_availability`, {
      params: { interview_id: interviewId },
    })
    .then((response) => response.data)
}
