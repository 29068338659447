import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import ArrowImage from 'src/static/icArrowBack.png'
import { css } from '@emotion/core'
import useAvaSound from 'src/ava/services/avaSound'
import { TitleText, NavButton, BackButton } from '../elements/styled-components'
import LiveText from '../LiveText'

export const ComponentContainer = styled.div<{ nonFullHeight?: boolean }>`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  ${isMobileOnly &&
  css`
    flex-direction: column;
    height: calc(100% - 72px);
    padding: 42px 16px 16px;
    background: white;
  `}
`

const TextContainer = styled.div`
  flex: 1;
  padding: 0 64px;
  display: flex;
  flex-direction: column;

  ${isMobileOnly &&
  css`
    flex: unset;
    min-height: 177px;
    padding: 0;
  `}
`

const VideoContainer = styled.div`
  position: relative;
  width: 60%;
  height: 100vh;
  margin-top: -144px;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-top: 0;
  }
  ${isMobileOnly &&
  css`
    flex: 1;
    width: 100%;
    height: auto;
    margin-top: 0;
    border: 1px solid #e1e3ea;
    border-radius: 12px;
    margin-bottom: 42px;
  `}
`

const TitleStyled = styled(TitleText)`
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 24px;

  ${isMobileOnly &&
  css`
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 6px;
    text-align: center;
  `}
`

const SubTitleStyled = styled(TitleText)`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  ${isMobileOnly &&
  css`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 1px;
    margin-bottom: 24px;
    text-align: center;
  `}
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 72px;

  @media screen and (max-width: 1100px) {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  ${isMobileOnly &&
  css`
    height: 55px;
    margin: 0 !important;
  `}
`

const ButtonStyled = styled(NavButton)`
  width: fit-content;
  margin-left: 32px;
  height: fit-content;

  ${isMobileOnly &&
  css`
    position: absolute;
    margin-bottom: 0 !important;
    margin-left: 0;
    left: 18px;
    right: 18px;
    width: auto;
  `}
`

const BackButtonStyled = styled(BackButton)`
  @media screen and (max-width: 1100px) {
    display: block !important;
    margin: 0;
  }
`

const VideoStyled = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

interface IProps {
  title?: string
  subTitle?: string
  nextButtonText?: string
  hideNextButtonIcon?: boolean
  videoUrl?: string
  goNext?: () => void
  goBack?: () => void
}

const OnboardingStepLayout = ({
  title,
  subTitle,
  nextButtonText,
  videoUrl,
  hideNextButtonIcon,
  goNext,
  goBack,
}: IProps) => {
  const { playSound } = useAvaSound()
  const [titleReady, setTitleReady] = useState(false)

  const onGoNext = () => {
    goNext()
    playSound('/static/sound/ava-forward.wav')
  }

  useEffect(() => {
    setTitleReady(false)
  }, [title])

  return (
    <ComponentContainer>
      <TextContainer>
        <TitleStyled>
          <LiveText text={title} onFinish={() => setTitleReady(true)} />
        </TitleStyled>
        {titleReady && (
          <SubTitleStyled>
            <LiveText text={subTitle} />
          </SubTitleStyled>
        )}
        {!isMobileOnly && (
          <ButtonsContainer>
            <BackButtonStyled src={ArrowImage} onClick={goBack} />
            <ButtonStyled hideIcon={hideNextButtonIcon} onClick={onGoNext}>
              {nextButtonText}
            </ButtonStyled>
          </ButtonsContainer>
        )}
      </TextContainer>
      <VideoContainer>
        <VideoStyled
          src={videoUrl}
          loop
          autoPlay
          muted
          playsInline
          onContextMenu={(event) => event.preventDefault()}
        />
      </VideoContainer>
      {isMobileOnly && (
        <ButtonsContainer>
          <ButtonStyled onClick={onGoNext}>{nextButtonText}</ButtonStyled>
        </ButtonsContainer>
      )}
    </ComponentContainer>
  )
}

OnboardingStepLayout.defaultProps = {
  title: '',
  subTitle: '',
  nextButtonText: '',
  videoUrl: '',
  hideNextButtonIcon: false,
  goNext: () => {},
  goBack: () => {},
}

export default OnboardingStepLayout
