import * as moment from 'moment'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStateRef from 'react-usestateref'
import { CoachingLanguage, InterviewStatus } from 'src/api'

import {
  getInterviewStatus as getInterviewStatusAction,
  endInterview as endInterviewAction,
  startInterview as startInterviewAction,
} from '../../interview/actions'
import * as interviewSelectors from '../../interview/selectors'
import { sendApplicantNoteAction, startInterviewStartWatcherAction } from '../../redux'

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Interview]',
  'color:#1e133b',
]

const useInterview = () => {
  const dispatch = useDispatch()

  const interview = useSelector(interviewSelectors.interview)
  const enableConversationIntelligence = useSelector(
    interviewSelectors.conversationIntelligence
  )
  const interviewDuration = useSelector(interviewSelectors.interviewDuration)
  const interviewOrderNumber = useSelector(interviewSelectors.interviewOrderNumber)
  const interviewStartedAt = useSelector(interviewSelectors.interviewStartedAt)
  const interviewStatus = useSelector(interviewSelectors.status)
  const codingSessionUrl = useSelector(interviewSelectors.codingSessionUrl)
  const token = useSelector(interviewSelectors.token)

  const timerRef = useRef(null)
  const [canEndCall, setCanEndCall] = useStateRef(false)
  const [minutesTillEnd, setMinutesTillEnd] = useStateRef(0)

  const [endNotification, setEndNotification] = useStateRef(false)
  const [
    endSoonNotification,
    setEndSoonNotification,
    endSoonNotificationRef,
  ] = useStateRef(false)
  const [
    endSoonNotificationHidden,
    setEndSoonNotificationHidden,
    endSoonNotificationHiddenRef,
  ] = useStateRef(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const endInterview = (interviewToken: string, quiet: boolean) => {
    dispatch(endInterviewAction({ interviewToken, quiet }))
  }

  const startInterview = (interviewToken: string) => {
    dispatch(startInterviewAction({ interviewToken }))
  }

  const updateInterviewInfo = (interviewToken: string) => {
    dispatch(getInterviewStatusAction({ interviewToken }))
  }

  const sendApplicantNote = (interviewToken: string, text: string) => {
    dispatch(sendApplicantNoteAction({ interviewToken, text }))
  }

  const watchInterviewStart = (interviewToken: string) => {
    log('Interview start watcher initiated')
    dispatch(startInterviewStartWatcherAction({ interviewToken }))
  }

  const watchInterviewEnd = (interviewToken: string) => {
    log('Interview end watcher initiated')
    dispatch({ type: 'CALL_END_WATCHER_TASK', payload: { interviewToken } })
  }

  const stopWatchInterviewEnd = () => {
    log('Interview end watcher stopped')
    dispatch({ type: 'STOP_CALL_END_WATCHER_TASK' })
  }

  const isExpert = token?.role === 'expert'
  const profileFirstName = interview?.profile?.first_name
  const profileLastName = interview?.profile?.last_name
  const profileId = interview?.profile?.id

  useEffect(() => {
    if (interviewStatus === InterviewStatus.STARTED && interviewDuration > 0) {
      const intervalID = setInterval(() => {
        const minutesPassed = moment
          .default()
          .diff(moment.default(interviewStartedAt), 'minutes')
        const isEndCallPossible = minutesPassed >= (interviewDuration / 100) * 80
        if (isEndCallPossible) setCanEndCall(true)
        if (
          isExpert &&
          !endSoonNotificationRef.current &&
          !endSoonNotificationHiddenRef.current
        ) {
          const needEndCallNotification = minutesPassed >= interviewDuration - 10
          if (needEndCallNotification) setEndSoonNotification(true)
        }
      }, 10000)
      return () => clearInterval(intervalID)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus, interviewStartedAt, interviewDuration, isExpert])

  useEffect(() => {
    if (
      interviewStatus === InterviewStatus.STARTED &&
      interviewDuration > 0 &&
      !timerRef.current
    ) {
      timerRef.current = setInterval(() => {
        const minutesPassed = moment
          .default()
          .diff(moment.default(interviewStartedAt), 'minutes')
        const minutes = Math.ceil((interviewDuration / 100) * 80 - minutesPassed)
        setMinutesTillEnd(minutes)
      }, 1000)
    }

    if (interviewStatus !== InterviewStatus.STARTED && timerRef.current) {
      clearInterval(timerRef.current)
      timerRef.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewStatus, interviewDuration])

  return {
    canEndCall,
    codingSessionUrl,
    endNotification,
    endSoonNotification,
    endSoonNotificationHidden,
    interviewDuration,
    interviewId: interview?.interview?.id,
    interviewOrderNumber,
    interviewStartedAt,
    interviewScheduledTime: interview?.interview?.scheduled_time,
    interviewStatus,
    isExpert,
    minutesTillEnd,
    peerFirstName: interview?.peer?.first_name,
    peerLastName: interview?.peer?.last_name,
    peerLogo: interview.peer?.logo?.url,
    profileFirstName,
    profileLastName,
    profileId,
    profileLogo: interview.profile?.logo?.url,
    enableConversationIntelligence:
      enableConversationIntelligence &&
      (!interview.interview.coaching_language ||
        interview.interview.coaching_language === CoachingLanguage.ENGLISH),
    backupMeetingURL: interview.agency.meeting_url,
    goToAltRoom: !interview.interview.use_general_link,
    endInterview,
    hideEndNotification: () => setEndNotification(false),
    hideEndSoonNotification: () => setEndSoonNotificationHidden(true),
    sendApplicantNote,
    showEndNotification: () => setEndNotification(true),
    startInterview,
    stopWatchInterviewEnd,
    updateInterviewInfo,
    watchInterviewEnd,
    watchInterviewStart,
  }
}

export default useInterview
