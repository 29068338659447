import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgMuteBig({ fill }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.862 2.32369C17.3868 1.08473 19.6667 2.16988 19.6667 4.13462V27.8654C19.6667 29.8301 17.3868 30.9153 15.862 29.6763L8.4655 23.6667H5.33335C3.30831 23.6667 1.66669 22.025 1.66669 20V12C1.66669 9.97496 3.30831 8.33333 5.33335 8.33333H8.46548L15.862 2.32369ZM17.6667 4.13462C17.6667 3.85394 17.341 3.69892 17.1232 3.87592L9.00002 10.476V21.524L17.1232 28.1241C17.341 28.3011 17.6667 28.146 17.6667 27.8654V4.13462ZM7.00002 10.3333V21.6667H5.33335C4.41288 21.6667 3.66669 20.9205 3.66669 20V12C3.66669 11.0795 4.41288 10.3333 5.33335 10.3333H7.00002Z"
        fill={fill}
      />
      <path
        d="M25.622 16C25.622 18.6974 24.1379 20.9922 22.0664 21.8427V24C25.1335 23.083 27.3997 19.8489 27.3997 16C27.3997 12.1511 25.1335 8.91697 22.0664 8V10.1573C24.1379 11.0078 25.622 13.3026 25.622 16Z"
        fill={fill}
      />
      <path
        d="M23.8442 16C23.8442 17.5287 23.1292 18.8635 22.0664 19.5776V12.4224C23.1292 13.1365 23.8442 14.4713 23.8442 16Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgMuteBig
