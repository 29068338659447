import styled from '@emotion/styled'
import React, { useEffect, useCallback, useRef } from 'react'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999;
`

const ModalContainer = styled.div`
  display: block;
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 30px;
  margin: 30px;
  border-radius: 10px;
  z-index: 99999;
`

const Modal = (props: any) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const { onClose, children } = props

  const clickOutsideContent = useCallback(
    (e) => {
      /* eslint-disable @typescript-eslint/no-unused-expressions */
      !(modalRef.current && modalRef.current.contains(e.target)) && onClose()
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideContent)
    return () => {
      document.removeEventListener('mousedown', clickOutsideContent)
    }
  }, [clickOutsideContent])

  return (
    <ModalWrapper>
      <ModalContainer ref={modalRef}>{children}</ModalContainer>
    </ModalWrapper>
  )
}

export default Modal
