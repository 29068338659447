import { IBulkInterview } from '../../api'
import { reduxDataFactory } from '../../utils/redux'

const {
  action: updateBulkInterviewsAction,
  actionType: updateBulkInterviewsActionType,
  reducer: bulkInterviewsDataReducer,
  selectors: bulkInterviewsSelectors,
} = reduxDataFactory<IBulkInterview[]>('bulkInterviews', undefined, [])

const clearBulkInterviewsAction = updateBulkInterviewsAction([])

export {
  updateBulkInterviewsAction,
  updateBulkInterviewsActionType,
  bulkInterviewsDataReducer,
  bulkInterviewsSelectors,
  clearBulkInterviewsAction,
}
