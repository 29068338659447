import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import withProps from 'recompose/withProps'
import { useDispatch, useSelector } from 'react-redux'
import { avaSettingsSelectors, updateAvaSettingsAction } from 'src/redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import BackImg from 'src/static/icAvaBackMobile.png'
import LogoImg from 'src/static/logo.png'
import SvgReport from 'src/static/components/SvgReport'
import SvgSettings from 'src/static/components/SvgSettings'
import SupportIcon from 'src/static/components/SvgSupport'
import LandingSideMenu from 'src/components/LandingSideMenu'
import HamburgerButton from 'src/components/HamburgerButton'
import useBranding from 'src/utils/useBranding'
import SvgMuteBig from 'src/static/components/SvgMuteBig'
import { AnimatedBase, ButtonBase, NavButton } from './elements/styled-components'
import { InterviewPart } from '../models'
import MuteButton from './elements/MuteButton'
import * as interviewSelectors from '../../interview/selectors'

const DefSettingsColor = '#1d0e3b'

const LineContainer = styled.div<{ showMuteButton: boolean; backgroundMobile: string }>`
  position: relative;
  display: flex;
  height: 72px;
  min-height: 72px;
  width: 100%;
  justify-content: ${({ showMuteButton }) =>
    showMuteButton ? 'space-between' : 'center'};
  align-items: center;
  margin-bottom: 72px;
  padding: 0 42px 0 24px;
  background: ${({ backgroundMobile }) => (isMobileOnly ? backgroundMobile : 'unset')};

  @media screen and (max-width: 1280px) {
    padding: 0 16px 0 16px;
  }

  ${isMobileOnly &&
  css`
    padding: 0;
    height: 72px;
    min-height: 72px;
    justify-content: center;
    margin: 0;
  `}
`

const Logo = styled.img<{ showMuteButton: boolean }>`
  margin-left: 24px;
  max-width: 120px;
  max-height: 80%;
  object-fit: contain;
  position: ${({ showMuteButton }) => (showMuteButton ? 'relative' : 'absolute')};
  left: ${({ showMuteButton }) => (showMuteButton ? '0' : '24px')};

  ${isMobileOnly &&
  css`
    margin: 0;
    width: 88px;
    height: 22px;
  `}
`

const StepPanel = styled.div`
  display: flex;
`

const StepItem = styled.div<{ active?: boolean }>`
  font-family: 'Rubik';
  font-weight: ${({ active }) => (active ? 500 : 400)};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
  white-space: break-spaces;
`

const StartButton = withProps()(styled(NavButton)`
  z-index: 1;
  margin-left: 32px;
  padding: 16px 24px;
  font-size: 14px;
  background: white;
  border: 1px solid #000000;
  border-radius: 32px;

  &:hover {
    border: 1px solid transparent;
    background: ${({ theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return color
    }};
    color: white;
    opacity: 1;
  }

  &:active {
    border: 1px solid transparent;
    background: ${({ theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return `${color}bf`
    }};
    color: white;
    opacity: 1;
  }
`)

const ButtonsContainer = styled.div<{ toTheRight?: boolean }>`
  z-index: 10;
  margin-left: ${({ toTheRight }) => (toTheRight ? 'auto' : '0')};
  display: flex;
  align-items: center;

  ${isMobileOnly &&
  css`
    position: absolute;
    right: 24px;
    z-index: 0;
  `}
`

const BackButton = styled.img`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  padding: 5px;
  object-fit: contain;
`

const SettingsButton = styled.div`
  width: 26px;
  height: 26px;
  margin-top: 2px;
  cursor: pointer;
  display: flex;
  z-index: 10;
  margin-right: 20px;

  > svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`

const SupportButton = styled.div<{ selected?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  color: ${({ theme, selected }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return selected ? color : DefSettingsColor
  }};

  > svg {
    margin-left: 12px;
  }
`

const SideMenuContainer = styled.div`
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
`

const SideItemContainer = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;

  > svg {
    width: 24px;
    height: 24px;
  }
`

const SideItemText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
`

const MuteButtonContainer = styled.div`
  position: relative;
`

const SpeakerNotification = styled(AnimatedBase)`
  position: absolute;
  display: flex;
  border-radius: 8px;
  background: #3e3452;
  padding: 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: 280px;
  right: -9px;
  top: 45px;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 15px;
    border-left: solid 9px transparent;
    border-right: solid 9px transparent;
    border-top: solid 10px transparent;
    border-bottom: solid 10px #3e3452;
  }
`

const SpeakerNotificationButton = styled(ButtonBase)`
  padding: 8px;
  border-radius: 4px;
  color: #fff;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  margin-top: 16px;
  align-self: end;
`

const SpeakerNotificationMobile = styled.div`
  background: rgba(115, 121, 145, 0.3);
  backdrop-filter: blur(4px);
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  flex-direction: column-reverse;
`

const SpeakerNotificationMobileData = styled.div`
  padding: 37px 24px 16px 24px;
  border-radius: 24px 24px 0px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: upFromDown 0.2s ease-out;

  @keyframes upFromDown {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0%);
    }
  }
`

const SpeakerNotificationMobileTitle = styled.div`
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 12px 0 4px 0;
`

const SpeakerNotificationMobileText = styled.div`
  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 47px;
  white-space: break-spaces;
`

const SpeakerNotificationMobileButton = styled(ButtonBase)`
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  padding: 10px 0;
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 30px;
  text-align: center;
`

const NavButtonReport = styled(NavButton)`
  padding: 11px 20px;
  background: transparent;
  border: 1px solid #1d0e3b;
  color: #1d0e3b;
  font-size: 14px;
  margin-left: 15px;
`

interface IProps {
  showInterviewPart?: boolean
  showRunButton?: boolean
  showMuteButton?: boolean
  interviewPart?: InterviewPart
  showBackButton?: boolean
  showReportButton?: boolean
  showSettingsButton?: boolean
  backgroundMobile?: string
  onBack?: () => void
  onNext?: () => void
  onClosePopup?: () => void
  onReportClick?: () => void
}

const Steps = [InterviewPart.Intro, InterviewPart.Interview, InterviewPart.Report]

const Header = ({
  interviewPart,
  showInterviewPart,
  showMuteButton,
  showRunButton,
  showBackButton,
  showReportButton,
  showSettingsButton,
  backgroundMobile,
  onBack,
  onNext,
  onClosePopup,
  onReportClick,
}: IProps) => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const avaSettings = useSelector(avaSettingsSelectors.data)
  const agency = useSelector(interviewSelectors.agency)
  const { agencyColor } = useBranding()

  const [showSideMenu, setShowSideMenu] = useState(false)

  const hideInterviewPart =
    useMediaQuery({ query: '(max-width: 700px)' }) && !isMobileOnly
  const isInterviewPartVisible = !hideInterviewPart && showInterviewPart

  const handleMute = () => {
    dispatch(updateAvaSettingsAction({ ...avaSettings, muted: !avaSettings.muted }))
  }

  const handleSettings = () => {
    setShowSideMenu(false)
    dispatch(
      updateAvaSettingsAction({ ...avaSettings, showSettings: !avaSettings.showSettings })
    )
  }

  const handleSupport = () => {
    setShowSideMenu(false)
    dispatch(
      updateAvaSettingsAction({ ...avaSettings, showSupport: !avaSettings.showSupport })
    )
  }

  const handleCloseSpeakerPopup = () => {
    onClosePopup()
    const stotageKey = `avaSpeakerPopupShown_${interviewToken}`
    localStorage.setItem(stotageKey, '1')
    dispatch(updateAvaSettingsAction({ ...avaSettings, showSpeakerPopup: false }))
  }

  const handleReportClick = () => {
    setShowSideMenu(false)
    onReportClick()
  }

  const logo = agency?.whitelisted && agency?.company_logo?.url

  return (
    <LineContainer showMuteButton={showMuteButton} backgroundMobile={backgroundMobile}>
      {isMobileOnly && showBackButton && (
        <BackButton onClick={onBack} src={BackImg} alt="back" />
      )}
      <Logo
        src={logo ?? LogoImg}
        alt="logo"
        showMuteButton={showMuteButton || !showBackButton}
      />
      {!isMobileOnly && isInterviewPartVisible && (
        <StepPanel>
          {Steps.map((s, index) => (
            <div style={{ display: 'flex' }} key={s}>
              <StepItem active={s === interviewPart}>{s}</StepItem>
              {index !== Steps.length - 1 && <StepItem> - </StepItem>}
            </div>
          ))}
        </StepPanel>
      )}
      {!isMobileOnly && (
        <ButtonsContainer toTheRight={showRunButton}>
          <SupportButton onClick={handleSupport} selected={avaSettings.showSupport}>
            Live Support
            <SupportIcon
              fill={avaSettings.showSupport ? agencyColor : DefSettingsColor}
            />
          </SupportButton>
          {showSettingsButton && (
            <SettingsButton onClick={handleSettings}>
              <SvgSettings
                fill={avaSettings.showSettings ? agencyColor : DefSettingsColor}
              />
            </SettingsButton>
          )}
          {showMuteButton && (
            <MuteButtonContainer>
              <MuteButton
                onClick={handleMute}
                fill="#000"
                toTheRight={isInterviewPartVisible || showRunButton}
                muted={avaSettings.muted}
              />
              {avaSettings.showSpeakerPopup && (
                <SpeakerNotification>
                  Turn on your speaker to have a conversation with Ava.
                  <SpeakerNotificationButton onClick={handleCloseSpeakerPopup}>
                    Got it
                  </SpeakerNotificationButton>
                </SpeakerNotification>
              )}
            </MuteButtonContainer>
          )}
          {showReportButton && (
            <NavButtonReport hideIcon onClick={handleReportClick}>
              View Report
            </NavButtonReport>
          )}
        </ButtonsContainer>
      )}
      {isMobileOnly && (
        <ButtonsContainer>
          {showMuteButton && (
            <MuteButton
              onClick={handleMute}
              fill="#000"
              toTheRight={isInterviewPartVisible || showRunButton}
              muted={avaSettings.muted}
            />
          )}
          <HamburgerButton onClick={() => setShowSideMenu(true)} />
        </ButtonsContainer>
      )}
      {isMobileOnly && (
        <LandingSideMenu
          show={showSideMenu}
          autoHeight
          blur
          onClose={() => setShowSideMenu(false)}
        >
          <SideMenuContainer>
            {showReportButton && (
              <SideItemContainer onClick={handleReportClick}>
                <SvgReport fill={DefSettingsColor} />
                <SideItemText>View Report</SideItemText>
              </SideItemContainer>
            )}
            {showSettingsButton && (
              <SideItemContainer onClick={handleSettings}>
                <SvgSettings fill={DefSettingsColor} />
                <SideItemText>Audio settings</SideItemText>
              </SideItemContainer>
            )}
            <SideItemContainer onClick={handleSupport}>
              <SupportIcon fill={DefSettingsColor} />
              <SideItemText>Live support</SideItemText>
            </SideItemContainer>
          </SideMenuContainer>
        </LandingSideMenu>
      )}
      {showRunButton && (
        <StartButton onClick={onNext} transparent hideIcon>
          Start practicing
        </StartButton>
      )}
      {isMobileOnly && avaSettings.showSpeakerPopup && (
        <SpeakerNotificationMobile>
          <SpeakerNotificationMobileData>
            <SvgMuteBig fill={agencyColor} />
            <SpeakerNotificationMobileTitle>
              Enhance your experience!
            </SpeakerNotificationMobileTitle>
            <SpeakerNotificationMobileText>
              {`Turn on your speaker to have a\nconversation with Ava`}
            </SpeakerNotificationMobileText>
            <SpeakerNotificationMobileButton onClick={handleCloseSpeakerPopup}>
              Got it, Let’s go!
            </SpeakerNotificationMobileButton>
          </SpeakerNotificationMobileData>
        </SpeakerNotificationMobile>
      )}
    </LineContainer>
  )
}

Header.defaultProps = {
  showInterviewPart: false,
  showRunButton: false,
  showMuteButton: true,
  showBackButton: true,
  showReportButton: false,
  interviewPart: InterviewPart.Intro,
  showSettingsButton: false,
  backgroundMobile: '#f6f4fd',
  onBack: () => {},
  onNext: () => {},
  onClosePopup: () => {},
  onReportClick: () => {},
}

export default Header
