import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { IRemoteAudioTrack } from 'agora-rtc-sdk-ng'

const Sensebility = 1.8
const AnimationLengthMs = 400

const Container = styled.div`
  width: 21px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LevelItem = styled.div<{ maxHeight: number; value: number }>`
  width: 5px;
  height: ${(props) =>
    Math.min((props.maxHeight - 5) * props.value * Sensebility + 5, props.maxHeight)}px;
  background: #b202ff;
  border-radius: 2px;
  transition: height ${AnimationLengthMs}ms ease;
`

interface IProps {
  track: IRemoteAudioTrack
}

const MicVolumeIndicator = ({ track }: IProps) => {
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const level = track?.getVolumeLevel()
      setVolume(level)
    }, AnimationLengthMs)
    return () => clearInterval(interval)
  }, [track])
  return (
    <Container>
      <LevelItem maxHeight={14} value={volume} />
      <LevelItem maxHeight={20} value={volume} />
      <LevelItem maxHeight={14} value={volume} />
    </Container>
  )
}

export default MicVolumeIndicator
