import { IFailure } from '../utils/redux'

export const SIGN_IN = 'AUTH/SIGN_IN'
export const SET_SIGN_IN_RESULT = 'AUTH/SET_SIGN_IN_RESULT'
export const SIGN_IN_FAILURE = 'AUTH/SIGN_IN_FAILURE'
export const SIGN_IN_CLEAR_ERROR = 'AUTH/SIGN_IN_CLEAR_ERROR'
export const RESET = 'AUTH/RESET'
export const RESET_SUCCESS = 'AUTH/RESET_SUCCESS'
export const RESET_FAILURE = 'AUTH/RESET_FAILURE'
export const CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'AUTH/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'AUTH/CHANGE_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_CLEAR_ERROR = 'AUTH/CHANGE_PASSWORD_CLEAR_ERROR'
export const SIGN_OUT = 'AUTH/SIGN_OUT'

export interface ISignInAction {
  type: typeof SIGN_IN
  payload: {
    email: string
    password: string
  }
}

export interface ISetSignInAction {
  type: typeof SET_SIGN_IN_RESULT
  payload: {
    token: string
  }
}

export interface ISignInFailureAction {
  type: typeof SIGN_IN_FAILURE
  payload: IFailure
}

export interface ISignInClearError {
  type: typeof SIGN_IN_CLEAR_ERROR
  payload: {}
}

export interface IResetAction {
  type: typeof RESET
  payload: {
    email: string
    password: string
  }
}

export interface IResetSuccessAction {
  type: typeof RESET_SUCCESS
  payload: {}
}

export interface IResetFailureAction {
  type: typeof RESET_FAILURE
  payload: IFailure
}

export interface IChangePasswordAction {
  type: typeof CHANGE_PASSWORD
  payload: {
    password: string
  }
}

export interface IChangePasswordSuccessAction {
  type: typeof CHANGE_PASSWORD_SUCCESS
  payload: {}
}

export interface IChangePasswordFailureAction {
  type: typeof CHANGE_PASSWORD_FAILURE
  payload: IFailure
}

export interface IChangePasswordClearError {
  type: typeof CHANGE_PASSWORD_CLEAR_ERROR
  payload: {}
}

export interface ISignOutAction {
  type: typeof SIGN_OUT
  payload: {
    email: string
    password: string
  }
}
