/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import { isSuperAdminRole } from 'src/utils/jwtToken'
import Highlighter from 'react-highlight-words'
import { isMobileOnly } from 'react-device-detect'
import arrowDown from '../../static/arrow-down2.svg'
import searchIcon from '../../static/ic_search.png'
import { ITranscriptText } from '../types'
import SingleSelect from './SingleSelect'

const Container = styled.div<{ collapsed: boolean }>`
  position: relative;
  border-radius: 8px;
  border: 1px solid #e1e3ea;
  width: 100%;
  transition: height 0.3s linear;
  overflow: hidden;
  margin-bottom: 12px;
  height: ${(props) => (props.collapsed ? '69px' : '469px')};
`

const HeadContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 69px;
  width: 100%;
  padding: 16px;
  align-items: center;
`

const HeaderBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  cursor: pointer;
`

const HeaderIcon = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #f23482;
`

const HeaderText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #1d0e3b;
  margin: 0 0 0 8px;

  @media screen and (max-width: 600px) {
    margin-right: 8px;
  }
`

const SearchContainer = styled.div`
  position: relative;

  @media screen and (max-width: 600px) {
    diaplay: flex;
    margin: 0px 14px 10px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 346px;
    top: 8px;
    width: 21px;
    height: 21px;
    background: url(${searchIcon});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @media screen and (max-width: 600px) {
      right: 10px;
      left: unset;
    }
  }
`

const SearchBox = styled.input`
  height: 40px;
  width: 356px;
  padding: 14px 45px 14px 14px;
  margin: 0 16px 0 26px;
  border: 1px solid #e1e3ea;
  border-radius: 5px;
  background: transparent;
  position: relative;
  font-weight: 400;
  font-size: 12px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
    padding: 14px 40px 14px 14px;
  }
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
`

const HeadArrow = styled.span<{ expanded: boolean }>`
  background-image: url(${arrowDown});
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%;
  margin-right: 5px;
  transition: height 0.3s linear;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : '')};
`

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e1e3ea;
  max-height: 400px;
  overflow: auto;
`

const TextsElement = styled.div<{ past: boolean; active: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 18px 16px;
  border-bottom: 1px solid ${(props) => (props.active ? '#f23482' : '#e1e3ea')};
  border-top: 1px solid ${(props) => (props.active ? '#f23482' : 'transparent')};
  opacity: ${(props) => (props.past ? 0.4 : 1)};
  cursor: pointer;
`

const TextsElementTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

const UserName = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #8f9bb3;
`

const UserAvatar = styled.div<{ url: string }>`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 8px;
  background: url(${({ url }) => url});
  background-size: 100%;
`

const UserText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #3b424f;

  mark {
    background: #ffdb46;
    padding: 0;
  }
`

const Timestamp = styled.div<{ active?: boolean }>`
  height: 20px;
  background: ${(props) => (props.active ? '#F23482' : 'rgba(225, 227, 234, 1)')};
  border-radius: 12px;
  font-weight: 400;
  font-size: 11px;
  color: ${(props) => (props.active ? 'white' : '#8f9bb3')};
  margin: 0 12px 0 16px;
  padding: 2px 5px;
`

const ScrollButton = styled.div<{ top: boolean }>`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: ${(props) => (props.top ? 'unset' : '12px')};
  top: ${(props) => (props.top ? (isMobileOnly ? '130px' : '81px') : 'unset')};
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: #f5f6f8;
  border: 1px solid #b202ff;
  cursor: pointer;
  color: #b202ff;
  font-size: 32px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${(props) => (props.top ? 180 : 0)}deg);
`

const EmptyTextsLabel = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: black;
  opacity: 0.3;
`

const Button = styled.div`
  padding: 10px;
  border: solid 1px #b202ff;
  border-radius: 4px;
  height: 40px;
  font-size: 12px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background-color: #f5f6f8;
  }
`

enum ScrollDirection {
  None,
  Top,
  Bottom,
}

interface IProps {
  texts: ITranscriptText[]
  selectedTextIndex: number
  onSelectTranscription: (t: ITranscriptText) => void
  onFilter: (f: string) => void
  onDownload: (mode: any) => void
}

function CallTranscript({
  texts,
  selectedTextIndex,
  onSelectTranscription,
  onFilter,
  onDownload,
}: IProps) {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
  const [collapsed, setCollapsed] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.None
  )
  const textsContainerRef = useRef<HTMLDivElement>(null)
  const isSuperAdmin = isSuperAdminRole()
  const getTimeStamp = (mSeconds: number) => {
    let minutes = Math.floor(mSeconds / 1000 / 60)
    minutes = minutes < 1 ? 0 : minutes
    const seconds = Math.round(mSeconds / 1000 - minutes * 60)
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const scrollParentToChild = (parent: HTMLElement, child: HTMLElement) => {
    if (!parent || !child) {
      return
    }
    const parentRect = parent.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()
    const scrollTop = (parentRect.top - childRect.top) * -1

    parent.scrollBy({
      left: 0,
      top: scrollTop - 2,
      behavior: 'smooth',
    })
  }

  const getTextElement = (text: ITranscriptText, index: number) => {
    const past = index < selectedTextIndex
    const textString = text.text
    return (
      // eslint-disable-next-line react/no-array-index-key
      <TextsElement
        past={past}
        key={index}
        active={index === selectedTextIndex}
        onClick={() => onSelectTranscription(text)}
      >
        <TextsElementTextContainer>
          <UserNameContainer>
            <UserAvatar url={text.avatar} />
            <UserName>{text.author}</UserName>
          </UserNameContainer>
          <UserText>
            <Highlighter searchWords={[searchText]} textToHighlight={textString} />
          </UserText>
        </TextsElementTextContainer>
        <Timestamp active={index === selectedTextIndex}>
          {getTimeStamp(text.timestamp)}
        </Timestamp>
      </TextsElement>
    )
  }

  const onSearch = (event) => {
    const sText = event.target.value
    setSearchText(sText.replace('?', '\\?').replace(/\*/g, '\\*').replace('.', '\\.'))
    if (sText && collapsed) {
      setCollapsed(false)
    }
    onFilter(sText)
  }

  const onScroll = () => {
    if (searchText) return

    const child = textsContainerRef.current.children[selectedTextIndex] as HTMLDivElement
    if (!child) {
      setScrollDirection(ScrollDirection.None)
      return
    }
    const parent = textsContainerRef.current
    const parentRect = parent.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    if (childRect.height + childRect.top <= parentRect.top) {
      setScrollDirection(ScrollDirection.Top)
    } else if (childRect.top > parentRect.top + parentRect.height) {
      setScrollDirection(ScrollDirection.Bottom)
    } else {
      setScrollDirection(ScrollDirection.None)
    }
  }

  const scrollToSelectedItem = (force?: boolean) => {
    if (!force && scrollDirection !== ScrollDirection.None) {
      onScroll()
      return
    }

    const item = textsContainerRef.current.children[selectedTextIndex] as HTMLDivElement
    scrollParentToChild(textsContainerRef.current, item)
  }

  useEffect(() => {
    if (searchText) return

    scrollToSelectedItem()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTextIndex])

  useEffect(() => {
    if (searchText) {
      setScrollDirection(ScrollDirection.None)
    } else {
      onScroll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  return (
    <Container collapsed={collapsed}>
      <HeadContainer>
        <HeaderBackground onClick={() => setCollapsed(!collapsed)} />
        <HeaderIcon>Ab</HeaderIcon>
        <HeaderText>Call transcript</HeaderText>
        {(!isMobileOnly || (isMobileOnly && isLandscape)) && (
          <SearchContainer>
            <SearchBox placeholder="Search" onChange={onSearch} />
          </SearchContainer>
        )}
        {!isMobileOnly && isSuperAdmin && !collapsed && (
          <SingleSelect
            items={['Full', 'Applicant', 'Attitude & WPM']}
            onItemSelect={onDownload}
          >
            Download
          </SingleSelect>
        )}
        {!isSuperAdmin && <Button onClick={() => onDownload('Full')}>Download</Button>}
        <ArrowContainer>
          <HeadArrow expanded={!collapsed} />
        </ArrowContainer>
      </HeadContainer>
      {isMobileOnly && !isLandscape && (
        <SearchContainer>
          <SearchBox placeholder="Search" onChange={onSearch} />
        </SearchContainer>
      )}
      <TextsContainer ref={textsContainerRef} onScroll={onScroll}>
        {texts.map((text, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return getTextElement(text, index)
        })}
      </TextsContainer>
      {searchText && texts.length === 0 && (
        <EmptyTextsLabel>No mathces found</EmptyTextsLabel>
      )}
      {scrollDirection !== ScrollDirection.None && !collapsed && (
        <ScrollButton
          top={scrollDirection === ScrollDirection.Top}
          onClick={() => scrollToSelectedItem(true)}
        >
          ↓
        </ScrollButton>
      )}
    </Container>
  )
}

export default CallTranscript
