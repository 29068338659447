import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'

const Container = styled.div<{ isExpert: boolean }>`
  position: relative;
  margin: 40px 40px 70px 40px;
  height: 190px;
  display: flex;
  flex-direction: column;
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: ${({ isExpert }) => (isExpert ? 'start' : 'center')};
`

const HeaderContainer = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 189px;
`

const HeaderItem1 = styled.div`
  background: #ffdb46;
  height: 190px;
  width: 93px;
  border-radius: 95px 0 0 95px;
`

const HeaderItem2 = styled.div`
  background: #f5f6f8;
  height: 190px;
  flex: 1;
  border-radius: 0 95px 95px 0;
`

const HeaderItem3 = styled.div`
  position: absolute;
  left: 47px;
  top: 48px;
  background: #f5f6f8;
  height: 190px;
  width: 93px;
  border-radius: 95px;
  background: #b202ff;
`

const HeaderItem4 = styled.div`
  position: absolute;
  left: 47px;
  top: 145px;
  background: #f5f6f8;
  height: 93px;
  width: 93px;
  border-radius: 47px;
  background: #cb55ff;
`

const Title = styled.div<{ isExpert: boolean }>`
  font-weight: 700;
  font-size: 32px;
  color: #222b45;
  margin: ${({ isExpert }) => (isExpert ? '53px 0 0 181px;' : '0 0 0 181px;')};
  z-index: 10;
`

const SubText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #222b45;
  margin: 20px 0 0 181px;
  z-index: 10;
`

interface IProps {
  isExpert?: boolean
}

function PreStartHeader({ isExpert }: IProps) {
  const interviewDetails = useSelector(interviewSelectors.interview)

  return (
    <Container isExpert={isExpert}>
      <HeaderContainer>
        <HeaderItem1 />
        <HeaderItem2 />
        <HeaderItem3 />
        <HeaderItem4 />
      </HeaderContainer>
      <Title isExpert={isExpert}>
        {isExpert &&
          `${interviewDetails.peer.first_name} ${interviewDetails.peer.last_name} has selected
you as their expert!`}
        {!isExpert &&
          `Coaching session for ${interviewDetails.interview.desired_position} at ${interviewDetails.interview.position_company}`}
      </Title>
      {isExpert && (
        <SubText>
          You&apos;ll be coaching {interviewDetails.peer.first_name} for{' '}
          {interviewDetails.interview.desired_position} position
          {interviewDetails.interview.position_company
            ? ` at ${interviewDetails.interview.position_company}`
            : ''}
          {interviewDetails.interview.position_location
            ? `, ${interviewDetails.interview.position_location}`
            : ''}
        </SubText>
      )}
    </Container>
  )
}

PreStartHeader.defaultProps = {
  isExpert: false,
}

export default PreStartHeader
