import React from 'react'
import styled from '@emotion/styled'

import SvgCoaching from 'src/static/components/SvgCoaching'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

export const DetailsButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

const DetailsButtonContainer = styled(HeaderButtonContainer)`
  cursor: default;
`

interface ICoachingButton extends React.HTMLProps<HTMLDivElement> {
  disabled: boolean
  active: boolean
  theme: any
  onClick: () => void
}

export default function CoachingButton({
  disabled,
  active,
  theme,
  children,
  onClick,
}: ICoachingButton) {
  const label = 'Coaching info'
  return (
    <DetailsButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgCoaching fill={active ? theme.textColorActive : theme.textColor} />
        <DetailsButtonLabel active={active}>{label}</DetailsButtonLabel>
      </HeaderIconContainer>
      {children}
    </DetailsButtonContainer>
  )
}
