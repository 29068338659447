import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'

import { Input } from '../../../components'
import { agencySelectors } from '../../../redux'

const Container = styled.div`
  border: 1px solid #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label`
  margin-bottom: 0;
`

const AgencyPriceTierList = styled.div`
  border: 1px solid #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const AgencyPriceTierItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 0;
`

const AgencyPriceTierName = styled.label`
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0;
`

const AgencyPriceTierDescription = styled.label`
  font-size: 0.8rem;
  margin-bottom: 0;
`

const Row = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

interface IProps {
  control: any
  errors: any
}

const AgencyPriceTiers = ({ control, errors }: IProps) => {
  const { currency_sign } = useSelector(agencySelectors.data)

  return (
    <Container>
      <Title>Agency PriceTiers</Title>
      <AgencyPriceTierList>
        <AgencyPriceTierItem>
          <AgencyPriceTierName>Senior Collaborator</AgencyPriceTierName>
          <AgencyPriceTierDescription>
            Mentors with first-hand experience in your desired position&#39;s hiring
            process and responsibilities.
          </AgencyPriceTierDescription>
          <Row>
            <Controller
              as={Input}
              label={`Minimal Price (${currency_sign})`}
              name="senior.price_min"
              control={control}
              error={errors.senior?.price_min}
              rules={{ required: true, min: 0, max: 999 }}
              defaultValue=""
              type="number"
            />
            <Controller
              as={Input}
              label={`Maximum Price (${currency_sign})`}
              name="senior.price_max"
              control={control}
              error={errors.senior?.price_max}
              rules={{ required: true, min: 0, max: 999 }}
              defaultValue=""
              type="number"
            />
          </Row>
        </AgencyPriceTierItem>

        <AgencyPriceTierItem>
          <AgencyPriceTierName>Team Lead</AgencyPriceTierName>
          <AgencyPriceTierDescription>
            Experienced in interviewing, building and leading teams.
          </AgencyPriceTierDescription>
          <Row>
            <Controller
              as={Input}
              label={`Minimal Price (${currency_sign})`}
              name="team_lead.price_min"
              control={control}
              error={errors.team_lead?.price_min}
              rules={{ required: true, min: 0, max: 999 }}
              defaultValue=""
              type="number"
            />
            <Controller
              as={Input}
              label={`Maximum Price (${currency_sign})`}
              name="team_lead.price_max"
              control={control}
              error={errors.team_lead?.price_max}
              rules={{ required: true, min: 0, max: 999 }}
              defaultValue=""
              type="number"
            />
          </Row>
        </AgencyPriceTierItem>

        <AgencyPriceTierItem>
          <AgencyPriceTierName>Executive</AgencyPriceTierName>
          <AgencyPriceTierDescription>
            Top professional with years of experience heading companies and departments.
          </AgencyPriceTierDescription>
          <Row>
            <Controller
              as={Input}
              label={`Minimal Price (${currency_sign})`}
              name="executive.price_min"
              control={control}
              error={errors.executive?.price_min}
              rules={{ required: true, min: 0, max: 999 }}
              defaultValue=""
              type="number"
            />
            <Controller
              as={Input}
              label={`Maximum Price (${currency_sign})`}
              name="executive.price_max"
              control={control}
              error={errors.executive?.price_max}
              rules={{ required: true, min: 0, max: 999 }}
              defaultValue=""
              type="number"
            />
          </Row>
        </AgencyPriceTierItem>
      </AgencyPriceTierList>
    </Container>
  )
}

export default AgencyPriceTiers
