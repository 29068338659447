import React from 'react'
import styled from '@emotion/styled'

import SupportIcon from 'src/static/support_small.png'

const Button = styled.div<{ isMobile: boolean; isLandscape: boolean }>`
  position: fixed;
  top: ${({ isMobile, isLandscape }) =>
    // eslint-disable-next-line no-nested-ternary
    isMobile ? (isLandscape ? 67 : 62) : 0}px;
  left: 0;
  border-radius: 0px 20px 20px 20px;
  width: 44px;
  height: 183px;
  background: #b202ff;
  cursor: pointer;

  @media screen and (max-width: 2500px) {
    width: 33px;
    height: 145px;
  }
  @media screen and (max-width: 2000px) {
    width: 25px;
    height: 108px;
  }
  @media screen and (max-width: 1200px) {
    width: 21px;
    height: 88px;
  }
  @media screen and (max-width: 900px) {
    width: 16px;
    height: 70px;
  }
`

const Icon = styled.img`
  width: 33.5px;
  height: auto;
  margin-left: 3px;
  margin-top: 12px;

  @media screen and (max-width: 2500px) {
    width: 25.5px;
    margin-top: 10px;
  }
  @media screen and (max-width: 2000px) {
    width: 17.5px;
    margin-top: 8px;
  }
  @media screen and (max-width: 1200px) {
    width: 14.5px;
    margin-top: 2px;
  }
  @media screen and (max-width: 900px) {
    width: 12px;
    margin-top: 0px;
  }
`

const Text = styled.div`
  color: white;
  font-size: 20px;
  transform: rotate(-90deg);
  width: 120px;
  position: relative;
  top: 53px;
  left: -35px;
  line-height: 13px;
  font-weight: 400;

  @media screen and (max-width: 2500px) {
    font-size: 16px;
    left: -30px;
    top: 39px;
    width: 98px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    top: 29px;
    left: -23px;
    width: 73px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9.6px;
    left: -25px;
    top: 16px;
    width: 73px;
  }
  @media screen and (max-width: 900px) {
    font-size: 8px;
    left: -27px;
    top: 1px;
    width: 73px;
  }
`

interface IDetailsButton {
  isMobile: boolean
  isLandscape: boolean
  onClick: () => void
}

export default function SmallIntercomButton({
  onClick,
  isMobile,
  isLandscape,
}: IDetailsButton) {
  return (
    <Button onClick={onClick} isMobile={isMobile} isLandscape={isLandscape}>
      <Icon src={SupportIcon} />
      <Text>Live Support</Text>
    </Button>
  )
}
