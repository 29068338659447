import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import SvgVolume from 'src/static/components/SvgVolume'

const Container = styled.div`
  align-items: center;
  display: flex;

  margin-top: 18px;
  padding: 0 23px;
  @media screen and (max-width: 2500px) {
    margin-top: 15px;
    padding: 0 19px;
  }
  @media screen and (max-width: 2000px) {
    margin-top: 12px;
    padding: 0 15px;
  }
  @media screen and (max-width: 1200px) {
    margin-top: 9px;
    padding: 0 11px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 6px;
    padding: 0 8px;
  }
`

const Icon = styled.div`
  margin-right: 15px;
  @media screen and (max-width: 2500px) {
    margin-right: 12px;
  }
  @media screen and (max-width: 2000px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 1200px) {
    margin-right: 8px;
  }
  @media screen and (max-width: 900px) {
    margin-right: 5px;
  }

  > svg {
    height: 25px;
    width: 25px;
    @media screen and (max-width: 2500px) {
      height: 21px;
      width: 21px;
    }
    @media screen and (max-width: 2000px) {
      height: 17px;
      width: 17px;
    }
    @media screen and (max-width: 1200px) {
      height: 13px;
      width: 13px;
    }
    @media screen and (max-width: 900px) {
      height: 9px;
      width: 9px;
    }
  }
`

const Content = styled.div`
  display: flex;

  height: 15px;
  @media screen and (max-width: 2500px) {
    height: 12px;
  }
  @media screen and (max-width: 2000px) {
    height: 10px;
  }
  @media screen and (max-width: 1200px) {
    height: 8px;
  }
  @media screen and (max-width: 900px) {
    height: 5px;
  }
`

const Item = styled.div<{ isActive: boolean }>`
  border-radius: 5px;
  background-color: ${({ isActive }) => (isActive ? '#B202FF' : '#8f9ab3')};

  margin-right: 9px;
  width: 7px;
  @media screen and (max-width: 2500px) {
    margin-right: 7px;
    width: 6px;
  }
  @media screen and (max-width: 2000px) {
    margin-right: 6px;
    width: 5px;
  }
  @media screen and (max-width: 1200px) {
    margin-right: 4px;
    width: 4px;
  }
  @media screen and (max-width: 900px) {
    margin-right: 3px;
    width: 3px;
  }
`

const MobileContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 12px;
  padding: 0 15px;
`

const MobileIcon = styled.div`
  margin-right: 10px;
`

const MobileContent = styled.div`
  display: flex;
  height: 10px;
`

const MobileItem = styled.div<{ isActive: boolean }>`
  border-radius: 5px;
  background-color: ${({ isActive }) => (isActive ? '#B202FF' : '#8f9ab3')};
  margin-right: 6px;
  width: 5px;
`

export default function SpeakerVolumeIndicator({
  deviceId,
  active,
}: {
  deviceId: string
  active: boolean
}) {
  const [volume, setVolume] = useState(0)
  const macriphoneStream = useRef<MediaStream>(null)
  const scriptProcessor = useRef<ScriptProcessorNode>(null)

  useEffect(() => {
    if (active) {
      navigator.mediaDevices.getUserMedia({ audio: { deviceId } }).then((stream) => {
        macriphoneStream.current = stream

        const audioContext = new AudioContext()
        const analyser = audioContext.createAnalyser()
        const microphone = audioContext.createMediaStreamSource(stream)
        scriptProcessor.current = audioContext.createScriptProcessor(2048, 1, 1)

        analyser.smoothingTimeConstant = 0.8
        analyser.fftSize = 1024

        microphone.connect(analyser)
        analyser.connect(scriptProcessor.current)
        scriptProcessor.current.connect(audioContext.destination)
        scriptProcessor.current.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount)

          analyser.getByteFrequencyData(array)
          const arraySum = array.reduce((a, value) => a + value, 0)
          const average = arraySum / array.length
          // console.log(Math.round(average))
          setVolume(Math.round(average) / 2)
        }
      })
    } else {
      macriphoneStream.current?.getAudioTracks().forEach((t) => t.stop())
      scriptProcessor.current?.disconnect()
    }

    return () => {
      macriphoneStream.current?.getAudioTracks().forEach((t) => t.stop())
      scriptProcessor.current?.disconnect()
    }
  }, [active, deviceId])

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Icon>
            <SvgVolume />
          </Icon>
          <Content>
            {new Array(15)
              .fill(1)
              .map((it, key) => key)
              .map((it, k) => (
                <Item key={it} isActive={volume > k * 0.6} />
              ))}
          </Content>
        </Container>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileIcon>
            <SvgVolume />
          </MobileIcon>
          <MobileContent>
            {new Array(15)
              .fill(1)
              .map((it, key) => key)
              .map((it, k) => (
                <MobileItem key={it} isActive={volume > k * 0.6} />
              ))}
          </MobileContent>
        </MobileContainer>
      )}
    </>
  )
}
