import { ISymblConversation } from '../../api'
import { reduxDataFactory } from '../../utils/redux'

const {
  action: conversationAction,
  actionType: conversationActionType,
  reducer: conversationReducer,
  selectors: conversationSelectors,
} = reduxDataFactory<ISymblConversation>('conversation', 'symbl', {
  messages: [],
  metrics: {
    attitude: undefined,
    attitude_text: undefined,
    confidence: undefined,
    confidence_text: undefined,
    data_use: undefined,
    data_use_text: undefined,
    experience: undefined,
    experience_text: undefined,
    speech_tempo: undefined,
    speech_tempo_text: undefined,
    word_count: undefined,
    workplace_culture: undefined,
    workplace_culture_text: undefined,
  },
  questions: [],
})

export {
  conversationAction,
  conversationActionType,
  conversationReducer,
  conversationSelectors,
}
