import React from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { push } from 'connected-react-router'
import { Button } from '../../components'
import Layout from '../Layout'

const Wrapper = styled.div`
  width: 250px;
`

function ChangePasswordSuccess() {
  const dispatch = useDispatch()

  return (
    <Layout
      sidebarText="Find the perfect match from thousands of experts to prepare your applicants for their job interviews"
      title="Password Updated"
      subtitle="We successfully changed your password."
    >
      <Wrapper>
        <Button title="Back to login" onClick={() => dispatch(push('/user/login'))} />
      </Wrapper>
    </Layout>
  )
}

export default ChangePasswordSuccess
