import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import useAvaSound from 'src/ava/services/avaSound'
import withProps from 'recompose/withProps'
import StepLayout from './StepLayout'

const LineContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media screen and (max-width: 1100px) {
    margin-bottom: 30px;
  }
`

const ItemContainer = withProps()(styled.div<{
  selected?: boolean
  useMinSize?: boolean
}>`
  padding: 24px 42px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  min-width: ${({ useMinSize }) => (useMinSize ? '120px' : 'unset')};
  color: ${({ selected }) => (selected ? 'white' : '#21304e')};
  background: ${({ theme, selected }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return selected ? color : '#fff'
  }};
  border: 1px solid
    ${({ theme, selected }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return selected ? color : '#21304e'
    }};
  border-radius: 35px;
  animation: fadeInFromNone 0.5s ease-out;
  margin: 0 12px 12px 0;
  cursor: pointer;

  &:hover {
    background: ${({ theme, selected }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return selected ? color : `${color}80`
    }};
    border: 1px solid transparent;
    color: white;
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ${isMobileOnly &&
  css`
    padding: 14px 24px;
  `}
`)

const DetailsText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #21304e;
  margin-top: 38px;
`

interface IProps {
  items: string[]
  selectedItem: string
  title: string
  subTitle?: string
  fixedItemsSize?: boolean
  detailsText?: string
  errorText?: string
  showError?: boolean
  inverseTitles?: boolean
  backMode: boolean
  onSelect: (item: string) => void
  goBack: () => void
  goNext: () => void
}

const ChoiseComponent = ({
  items,
  fixedItemsSize,
  selectedItem,
  title,
  subTitle,
  detailsText,
  errorText,
  showError,
  inverseTitles,
  backMode,
  onSelect,
  goBack,
  goNext,
}: IProps) => {
  const { playSound } = useAvaSound()
  const onItemSelect = (item) => {
    playSound('/static/sound/ava-pick.wav')
    onSelect(item)
  }

  return (
    <StepLayout
      title={title}
      subTitle={subTitle}
      errorText={errorText}
      showError={showError}
      inverseTitles={inverseTitles}
      backMode={backMode}
      goNext={goNext}
      goBack={goBack}
    >
      <LineContainer>
        {items.map((p) => (
          <ItemContainer
            key={p}
            useMinSize={fixedItemsSize}
            selected={selectedItem === p}
            onClick={() => onItemSelect(p)}
          >
            {p}
          </ItemContainer>
        ))}
      </LineContainer>
      {detailsText && <DetailsText>{detailsText}</DetailsText>}
    </StepLayout>
  )
}

ChoiseComponent.defaultProps = {
  fixedItemsSize: false,
  detailsText: '',
  subTitle: '',
  errorText: '',
  showError: false,
  inverseTitles: false,
}

export default ChoiseComponent
