import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import ExpertHeader from '../../static/expert-header.png'
import * as interviewSelectors from '../selectors'

const Container = styled.div`
  margin: 40px 40px 0 40px;
  height: 236px;
  display: flex;
  flex-direction: column;
  background: url(${ExpertHeader});
  background-size: contain;
  background-repeat: no-repeat;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: #222b45;
  margin: 53px 0 0 181px;
`

const SubText = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #222b45;
  margin: 20px 0 0 181px;
`

function AvailabilityHeader() {
  const interviewDetails = useSelector(interviewSelectors.interview)

  return (
    <Container>
      <Title>{interviewDetails.profile.first_name}, you have a potential match!</Title>
      <SubText>
        Coaching session for {interviewDetails.interview.desired_position} position
        {interviewDetails.interview.position_company
          ? ` at ${interviewDetails.interview.position_company}`
          : ''}
        {interviewDetails.interview.position_location
          ? `, ${interviewDetails.interview.position_location}`
          : ''}
      </SubText>
    </Container>
  )
}

export default AvailabilityHeader
