import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Text,
  TextBold,
  HyperLink,
  Link,
  RegularHyperLink,
  RegularLink,
} from 'src/components'
import htmlParser from 'html-react-parser'
import moment from 'moment'
import { isAdminRole, isSuperAdminRole } from 'src/utils/jwtToken'
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive'
import { isMobileOnly } from 'react-device-detect'

import { formattedDate, getTimeStamp } from 'src/utils/date'
import useLogrocket from 'src/utils/useLogrocket'
import { CoachingLanguage, IExpertNote, InterviewStatus } from '../../api'
import {
  conversationSelectors,
  getConversationAction,
  systemSettingsSelectors,
} from '../../redux'
import VideoPlayer from '../components/VideoPlayer'
import CallTranscript from '../components/CallTranscript'
import * as interviewSelectors from '../../interview/selectors'
import Layout from '../Layout'
import summaryIcon from '../../static/report_summary.svg'
import performanceIcon from '../../static/report_performance.svg'
import actionItemsIcon from '../../static/report_action_items.svg'
import keepIcon from '../../static/report_keep.svg'
import workOnIcon from '../../static/report_work_on.svg'
import recordingIcon from '../../static/report_recording.svg'
// import messageIcon from '../../static/report_message.svg'
import calendarIcon from '../../static/report_calendar.svg'
import plusIcon from '../../static/report_plus.svg'
import positionIcon from '../../static/report_position.svg'
import logo from '../../static/logo.png'
import { IMetricsData, ITimelineInfoType, ITranscriptText } from '../types'
import DropdownBlock from '../components/DropdownBlock'
import FeedbackIcon from '../../static/feedback-icon.png'
import NotesIcon from '../../static/notes-icon.png'
import QuestionIcon from '../../static/question-icon.png'
import MetricsInfo from '../components/MetricsInfo'
import preventImageCache from '../../utils/preventImageCache'
import useReport from '../services/useReport'
import useMetrics from '../services/useMetrics'
import ChartComponent from '../components/ChartComponent'
import {
  DataContainerStyled,
  DataTitle,
  DataTitleArrow,
  ISessionDetails,
} from '../components/SharedStyledComponents'
import ReportBlockContainer from '../components/ReportBlockContainer'
import SessionDataContainer from '../components/SessionDataContainer'

const horizontalPadding = '54px'

const bounce = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }

  100% {
    opacity: 1;
    max-height: 100%;
  }
`
const bounceBack = keyframes`
  0% {
    opacity: 1;
    max-height: 100%;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
`

const SessionDetails = styled.div`
  margin: 35px 0;
  display: flex;
  align-items: center;
  padding: 0 150px 0 ${horizontalPadding};
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    background: #fff;
    border-radius: 5px;
    padding: 20px 30px 5px;
    overflow: hidden;
    margin: 15px 0;
    flex-direction: column;
    align-items: baseline;
    position: relative;
  }
  & > p {
    margin-bottom: 20px;
  }
  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      padding: 10px 0 0 0;
    }
  `}
`

const SessionDetailsContainer = styled.div<ISessionDetails>`
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    background: #fff;
    border-radius: 5px;
    padding: 0;
    overflow: hidden;
    margin: 0;
    flex-direction: column;
    align-items: baseline;
    position: relative;
  }
  & > div {
    display: ${(props) => (props.expanded ? 'flex' : 'none')};
    animation: ${bounce} 1s ease normal;
  }
  &.hidden {
    & > div {
      animation: ${bounceBack} 0.7s ease normal;
    }
  }
`

const StudentPhoto = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 70px;
  margin-right: 25px;
  float: left;
  @media screen and (max-width: 600px) {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    margin-right: 0;
  }
`

const StudentPhotoPlaceholder = styled.div`
  height: 70px;
  width: 70px;
  border-radius: 70px;
  margin-right: 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  border: 1px solid black;
  opacity: 0.4;
  @media screen and (max-width: 600px) {
    width: 55px;
    height: 55px;
    border-radius: 55px;
    margin-right: 0;
  }
`

const SessionDetailsSection = styled.div`
  flex: 1;
  display: flex;
  align-self: flex-start;
  max-width: 160px;

  @media screen and (max-width: 600px) {
    margin-bottom: 15px;
    padding-left: 20px;
    max-width: 100%;
    align-items: center;
    & p {
      line-height: 26px;
      display: inherit;
    }
  }
`

const SessionDetailsData = styled.div``

const SessionDetailsIconContainer = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 10px;
  margin-top: 3px;
  @media screen and (max-width: 600px) {
    margin-right: 20px;
  }
`

const SessionDetailsIcon = styled.img`
  width: 100%;
  height: auto;
`

const LinkIconContainer = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 14px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const LinkIcon = styled.img`
  width: 100%;
  vertical-align: unset;
  height: auto;
`

const ExpertTextBold = styled.div`
  color: #3b424f;
  font-size: 13px;
  line-height: 26px;
  margin: 0;
  white-space: pre-line;
  font-weight: 500;
  & > span {
    color: #3b424f;
    font-size: 13px;
    line-height: 26px;
    margin: 0;
    font-weight: normal;
  }
`

const DataButtonContainer = styled.div`
  max-width: 250px;
  z-index: 1;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-left: 10px;
    }
  `}

  @media screen and (max-width: 600px) {
    max-width: 100%;
    width: 100%;
    & > a {
      max-width: 100%;
      color: #b202ff;
      background: none;
      font-size: 14px;
    }
  }
`

interface IBookFooter extends React.HTMLAttributes<HTMLElement> {
  short: boolean
}

const BookFooterButtonContainer = styled.div<IBookFooter>`
  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;

    & > a {
      background-color: #b202ff;
    }
  }
`

const DataRowSingle = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    margin-bottom: 0;
    overflow: hidden;
  }

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-bottom: 25px;
    }
  `}
`

const DataRow = styled(DataRowSingle)`
  justify-content: space-between;

  & > div {
    width: 48%;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const Footer = styled.div`
  border-top: 1px solid #e1e3ea;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    border: none;
    padding-top: 10px;
  }
`

const FooterPhoto = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 56px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const FooterPhotoPlaceholder = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 56px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  border: 1px solid black;
  opacity: 0.4;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const FooterFeedbackBy = styled.div`
  margin-left: 15px;
  margin-right: 40px;
  max-width: 400px;
  align-self: flex-start;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const FooterLogoContainer = styled.div`
  height: 56px;
  width: 156px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const FooterLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const DetailsContainerMobile = styled.div`
  width: 100%;
  margin-bottom: 15px;

  & > img {
    height: 55px;
    width: 55px;
    border-radius: 55px;
    margin-right: 0;
  }
`

const ExpertDetailsContainerMobile = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border-top: 1px solid #e1e3ea;
  padding-top: 30px;
  min-height: 100px;
  display: flex;
  align-items: center;

  & > div {
    margin-bottom: 0;
  }
`

const DetailsContainer = styled.div``

const FooterActionsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const FooterExpert = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  & > p:first-of-type {
    white-space: nowrap;
    margin-right: 5px;
  }
`

const SessionRecordingContainerBody = styled.div<{ usePadding?: boolean }>`
  display: flex;
  width: 100%;
  padding-bottom: ${({ usePadding }) => (usePadding && isMobileOnly ? 30 : 0)}px;
  ${isMobileOnly &&
  css`
    display: block;
  `}
`

const HTMLContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #3b424f;
  font-size: 12px;

  > p {
    margin: 0;
  }
`

const HTMLContainerText = styled(HTMLContainer)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`

const SendButton = styled(HyperLink)`
  outline: none !important;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border: 1px solid #353c5a;
  color: #353c5a;
  border-radius: 4px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  max-width: 400px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    color: #353c5a;
    opacity: 0.75;
  }
`
const RegularSendButton = styled(RegularHyperLink)`
  outline: none !important;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border: 1px solid #353c5a;
  color: #353c5a;
  border-radius: 4px;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  max-width: 400px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    color: #353c5a;
    opacity: 0.75;
  }
`

const SessionDetailsSectionColumn = styled(SessionDetailsSection)`
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 600px) {
    align-items: flex-start;
    padding-left: 10px;
  }
`

const LinkStyled = styled(SendButton)`
  border: none;
  background: #353c5a;
  color: white;
  padding: 14px 30px;

  &:hover {
    color: white;
  }

  > p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`.withComponent(Link)

const RegularLinkStyled = styled(RegularSendButton)`
  border: none;
  background: #353c5a;
  color: white;
  padding: 14px 30px;

  &:hover {
    color: white;
  }

  > p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`.withComponent(RegularLink)

const PendingTextContainer = styled(DataContainerStyled)`
  text-align: center;

  @media screen and (max-width: 600px) {
    padding-top: 45px;
  }
`

const PendingTextTitle = styled(TextBold)`
  font-size: 20px;
  margin-bottom: 65px;
`

const GrayText = styled(Text)`
  color: rgba(115, 121, 145, 0.8);
`

const GrayHyperLink = styled(HyperLink)`
  color: rgba(115, 121, 145, 0.8);

  &:hover {
    color: rgba(115, 121, 145, 0.8);
  }
`

const WizcoLogo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const TimelineInfoContainer = styled.div<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  width: 260px;
  margin-left: 20px;
  margin-top: ${({ marginTop }) => marginTop}px;
  height: ${({ marginTop }) => (marginTop < 65 ? 'auto' : '474px')};
`

const BlockHeaderText = styled.div<{ isLink?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 16px;
  width: fit-content;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'default')};
  color: ${(props) => (props.isLink ? '#007bff' : 'black')};
  &:hover {
    text-decoration: ${(props) => (props.isLink ? 'underline' : 'none')};
  }
`

const SessionRecordingBlockContainer = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border-radius: 20px;
  padding: 40px 35px;
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 18px;
    padding: 20px 14px;
    border-radius: 5px;
  }
`

enum RecordingBlockWidth {
  Full,
  Big,
  Small,
}

const SessionRecordingBlock = styled.div<{ blockWidth: RecordingBlockWidth }>`
  width: ${({ blockWidth }) => {
    switch (blockWidth) {
      case RecordingBlockWidth.Full:
        return '100%'
      case RecordingBlockWidth.Big:
        return 'calc(100% - 280px)'
      default:
        return '280px'
    }
  }};
  ${isMobileOnly &&
  css`
    width: 100% !important;
  `}
`

function ReportContainer() {
  const { setLogrocket } = useLogrocket()
  const dispatch = useDispatch()
  const { getMetrics } = useMetrics()
  const { interviewToken } = useParams()
  const interviewData = useSelector(interviewSelectors.interview)
  const codingPlaybackUrl = useSelector(interviewSelectors.codingPlaybackUrl)

  const { messages: conversationTexts, metrics, questions: questionTexts } = useSelector(
    conversationSelectors.data
  )
  const systemSettings = useSelector(systemSettingsSelectors.data)

  const { attitude: sentimentLevel, speech_tempo: wpmLevel, word_count: wordsCount } =
    metrics || ({} as any)
  const {
    agency,
    applicant_notes: personalNotes,
    expert_notes: expertNotes,
    interview,
    interview_recording: interviewRecording,
    peer: expertProfile,
    profile: candidateProfile,
    report,
  } = interviewData

  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [metricsData, setMetricsData] = useState<IMetricsData[]>([])
  const [texts, setTexts] = useState<ITranscriptText[]>([])
  const [questions, setQuestions] = useState<IExpertNote[]>([])
  const [screenAcitivties, setScreenAcitivties] = useState<IExpertNote[]>([])
  const [filter, setFilter] = useState('')
  const [activeTimelineInfo, setActiveTimelineInfo] = useState<ITimelineInfoType>(
    ITimelineInfoType.None
  )
  const [expandTimelineInfo, setExpandTimelineInfo] = useState<ITimelineInfoType>(
    ITimelineInfoType.None
  )
  const [filteredTexts, setFilteredTexts] = useState([])
  const [activeTranscriptionItemIndex, setActiveTranscriptionItemIndex] = useState(-1)
  const [expandedTab, setExpandedTab] = useState('')
  const [showConversationIntelligence, setShowConversationIntelligence] = useState(false)

  const player = useRef<ReactPlayer>(null)
  const feedbackBlockeRef = useRef<any>(null)
  const personalNotesBlockeRef = useRef<any>(null)
  const questionsBlockeRef = useRef<any>(null)

  const isPreview =
    [InterviewStatus.ENDED, InterviewStatus.REPORT_PROVIDED].includes(interview.status) &&
    isAdminRole()
  const isSuperAdmin = isSuperAdminRole()
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const seekTo = useCallback((newValue) => {
    player.current?.seekTo(newValue, 'fraction')
    const playerElement = player.current?.getInternalPlayer() as HTMLVideoElement
    if (newValue > 0) {
      playerElement?.play()
    } else {
      playerElement?.pause()
    }
  }, [])

  const handleExpandedTab = useCallback(
    (title: string): any => {
      if (title === expandedTab) {
        setExpandedTab('')
      } else {
        setExpandedTab(title)
      }
    },
    [expandedTab]
  )

  const onSelectTranscription = (text: ITranscriptText) => {
    const newValue = text.timestamp / 1000
    seekTo(newValue)
    questionsBlockeRef.current?.scrollToSelectedItem(newValue, true)
    personalNotesBlockeRef.current?.scrollToSelectedItem(newValue, true)
    feedbackBlockeRef.current?.scrollToSelectedItem(newValue, true)
  }

  const activateTimelineInfo = (tineLinetype: ITimelineInfoType) => {
    setActiveTimelineInfo(tineLinetype)
    setExpandTimelineInfo(tineLinetype)
  }

  const getCandidateTexts = () => {
    const startTimeShift = systemSettings.analyticsDurationAfterStart
    const endTimeShift = systemSettings.analyticsDurationBeforeEnd
    const lastTextTimestamp = Math.max.apply(
      null,
      texts.map((t) => t.timestamp)
    )
    const finishTimeLine = lastTextTimestamp - endTimeShift
    const candidatTexts = texts.filter(
      (t) =>
        t.isCandidate && t.timestamp >= startTimeShift && t.timestamp <= finishTimeLine
    )
    return candidatTexts
  }

  const onGetMetricsClicked = () => {
    if (!isSuperAdmin) return
    const candidatTexts = getCandidateTexts()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { getReport } = useReport()
    const string = getReport(candidatTexts, systemSettings)
    const file = new Blob([string], {
      type: 'text/plain',
    })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = 'report.csv'
    document.body.appendChild(element)
    element.click()
  }

  const onDownload = (mode: 'Full' | 'Applicant' | 'Attitude & WPM') => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { getTranscript, getAttitude } = useReport()
    let string = ''
    let fileName = ''
    if (mode === 'Attitude & WPM') {
      string = getAttitude(
        texts.filter((t) => t.isCandidate),
        sentimentLevel,
        wpmLevel
      )
      fileName = `Wizco_Attitude_WPM_report_(session_${interview.order_number}).csv`
    } else {
      string = `Session: ${interview.desired_position}${
        interview.position_company ? ` at ${interview.position_company}` : ''
      }${interview.position_location ? `, ${interview.position_location}` : ''}\n`
      string += `Date: ${formattedDate(interview.started_at, 'MMMM dd, yyyy')}\n`
      string += `Duration: ${getTimeStamp(
        new Date(interview.ended_at).getTime() - new Date(interview.started_at).getTime()
      )}\n`
      string += `Session ID: ${interview.order_number}\n`
      string += `\n\nSession transcript${
        mode === 'Applicant' ? ' (Applicant only)' : ''
      }\n\n`
      let transcriptTexts = [...texts]
      if (mode === 'Applicant') {
        transcriptTexts = texts.filter((t) => t.isCandidate)
      }
      string += getTranscript(transcriptTexts)
      fileName = `Wizco_Session_Transcript_${
        mode === 'Applicant' ? 'Applicant_' : ''
      }(session_${interview.order_number}).txt`
    }

    const element = document.createElement('a')
    const file = new Blob([string], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }

  useEffect(() => {
    if (expertNotes.length > 0) {
      activateTimelineInfo(ITimelineInfoType.Feedback)
    } else if (personalNotes.length > 0) {
      activateTimelineInfo(ITimelineInfoType.Notes)
    } else if (questions.length > 0) {
      activateTimelineInfo(ITimelineInfoType.Questions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertNotes, questions, personalNotes])

  useEffect(() => {
    if (interviewData.timestamps && interviewRecording) {
      const screenActivityData: IExpertNote[] = []
      for (let i = 0; i < interviewData.timestamps.length; i += 1) {
        let activityType = ''
        let finalActionName = ''
        switch (interviewData.timestamps[i].name) {
          case 'screen_start_candidate':
          case 'screen_start_expert':
            activityType = 'screen'
            finalActionName =
              interviewData.timestamps[i].name === 'screen_start_candidate'
                ? 'screen_stop_candidate'
                : 'screen_stop_expert'
            break
          case 'code_start':
            activityType = 'code'
            finalActionName = 'code_stop'
            break
          default: // go next
        }
        if (!activityType) {
          // eslint-disable-next-line no-continue
          continue
        }

        for (let j = i + 1; j < interviewData.timestamps.length; j += 1) {
          if (interviewData.timestamps[j].name === finalActionName) {
            screenActivityData.push({
              id: i.toString(),
              text: '',
              offset:
                (new Date(interviewData.timestamps[i].time).getTime() -
                  new Date(interviewRecording.start_at).getTime()) /
                1000,
              duration:
                (new Date(interviewData.timestamps[j].time).getTime() -
                  new Date(interviewData.timestamps[i].time).getTime()) /
                1000,
              score: 0,
              type: activityType,
            })
            break
          }
        }
      }
      setScreenAcitivties(screenActivityData)
    }
  }, [interviewData, questions, interviewRecording])

  useEffect(() => {
    if (interviewData?.interview.enable_conversation_intelligence && interviewToken) {
      dispatch(getConversationAction({ interviewToken }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, interviewToken])

  useEffect(() => {
    if (conversationTexts?.length) {
      const newTexts: ITranscriptText[] = []
      conversationTexts.forEach((t) => {
        newTexts.push({
          text: t.text,
          author: t.author,
          avatar: t.avatar,
          timestamp: t.timestamp * 1000,
          isCandidate: t.is_candidate,
          lengthMs: t.duration * 1000,
          sentimentScore: t.sentiment_score,
        })
      })
      newTexts.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
      setTexts(newTexts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationTexts])

  useEffect(() => {
    if (questionTexts?.length && interviewRecording) {
      const newQuestions: IExpertNote[] = []
      questionTexts.forEach((t) => {
        newQuestions.push({
          id: t.id,
          text: t.text,
          offset:
            (new Date(t.start_time).getTime() -
              new Date(interviewRecording.start_at).getTime()) /
            1000,
          score: t.score,
          duration: Math.max(t.duration * 1000, 5000),
        })
      })
      newQuestions.sort((a, b) => (a.offset > b.offset ? 1 : -1))
      setQuestions(newQuestions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTexts])

  useEffect(() => {
    if (!filteredTexts || filteredTexts.length === 0) return

    const timeProgres = progress * duration * 1000
    let changed = false
    filteredTexts.forEach((text, index) => {
      if (index !== filteredTexts.length - 1) {
        if (
          text.timestamp <= timeProgres &&
          filteredTexts[index + 1].timestamp > timeProgres
        ) {
          setActiveTranscriptionItemIndex(index)
          changed = true
        }
      } else if (Math.abs(text.timestamp - timeProgres) < 500) {
        setActiveTranscriptionItemIndex(index)
        changed = true
      }
    })
    if (!changed) {
      if (timeProgres - filteredTexts[filteredTexts.length - 1].timestamp > 10 * 1000) {
        setActiveTranscriptionItemIndex(filteredTexts.length)
      }
      if (filteredTexts[0].timestamp > timeProgres) {
        setActiveTranscriptionItemIndex(-1)
      }
    }
  }, [duration, progress, filteredTexts])

  useEffect(() => {
    if (!filter) {
      setFilteredTexts(texts)
      return
    }
    const filtered = []
    texts.forEach((t) => {
      if (t.text.toLowerCase().includes(filter.trim().toLowerCase())) {
        filtered.push(t)
      }
    })
    setFilteredTexts(filtered)
  }, [texts, filter])

  useEffect(() => {
    if (!systemSettings) return

    if (wordsCount > 0) {
      setMetricsData(getMetrics())

      const enableConversationIntelligence =
        interviewData.interview.enable_conversation_intelligence &&
        (!interviewData.interview.coaching_language ||
          interviewData.interview.coaching_language === CoachingLanguage.ENGLISH) &&
        (wordsCount >= systemSettings.minWordsCount || isSuperAdmin)
      setShowConversationIntelligence(enableConversationIntelligence)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemSettings, wordsCount])

  useEffect(() => {
    if (interviewData) {
      setLogrocket(
        `${interviewData.interview?.order_number.toString()}`,
        'Applicant report'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewData])

  const studentPhotoRender = () =>
    candidateProfile.logo.url ? (
      <StudentPhoto src={candidateProfile.logo.url} />
    ) : (
      <StudentPhotoPlaceholder>
        {candidateProfile.first_name && candidateProfile.first_name[0]}
        {candidateProfile.last_name && candidateProfile.last_name[0]}
      </StudentPhotoPlaceholder>
    )

  const studentDetailsSectionRender = () => (
    <SessionDetailsSectionColumn>
      <TextBold>
        {candidateProfile.first_name} {candidateProfile.last_name}
      </TextBold>
      <Text>{candidateProfile.current_position}</Text>
    </SessionDetailsSectionColumn>
  )

  const expertPhotoRender = () =>
    expertProfile.logo.url ? (
      <StudentPhoto src={expertProfile.logo.url} />
    ) : (
      <StudentPhotoPlaceholder>
        {expertProfile.first_name && expertProfile.first_name[0]}
        {expertProfile.last_name && expertProfile.last_name[0]}
      </StudentPhotoPlaceholder>
    )

  const expertDetailsSectionRender = () => (
    <SessionDetailsSectionColumn>
      <ExpertTextBold>
        {expertProfile.first_name} {expertProfile.last_name},{' '}
        <span>{expertProfile.current_position}</span>
      </ExpertTextBold>
    </SessionDetailsSectionColumn>
  )

  const dataRef = useRef(null)
  const handleHidden = () => {
    if (expandedTab === 'Session Details') {
      dataRef.current?.classList.add('hidden')
      setTimeout(() => {
        handleExpandedTab('Session Details')
      }, 300)
    } else {
      dataRef.current?.classList.remove('hidden')
      handleExpandedTab('Session Details')
    }
  }

  const onTimelineInfoJump = (timeLinetype: ITimelineInfoType) => {
    setActiveTimelineInfo(timeLinetype)
    setExpandTimelineInfo(timeLinetype)
  }

  const getTimelineinfoTopPadding = () => {
    const startPadding = -130
    const stepPadding = -58
    const screenActivityStep = -54
    let topMargin = 0
    if (expertNotes.length > 0) {
      topMargin = startPadding
    }
    if (personalNotes.length > 0) {
      topMargin = topMargin !== 0 ? topMargin + stepPadding : startPadding
    }
    if (screenAcitivties.length > 0) {
      topMargin = topMargin !== 0 ? topMargin + screenActivityStep : startPadding
    }
    if (showConversationIntelligence && conversationTexts?.length > 0) {
      if (questions.length > 0) {
        topMargin = topMargin !== 0 ? topMargin + stepPadding : startPadding
      }
    }
    return topMargin
  }

  let additionalBookingUrl = interview.sponsored_session_available
    ? agency.additional_booking_url_sponsored
    : agency.additional_booking_url
  if (additionalBookingUrl && !additionalBookingUrl.match('^https?://')) {
    additionalBookingUrl = `https://${additionalBookingUrl}`
  }

  const hasTimelineInfo =
    expertNotes.length > 0 ||
    personalNotes.length > 0 ||
    (questions.length > 0 && showConversationIntelligence)

  return (
    <Layout
      title={isPreview ? 'Coaching session report (Preview)' : 'Coaching session report'}
      subtitle="Session recording with expert feedback included"
      sponsored={interview.sponsored_session_available}
    >
      <>
        <SessionDetails>
          {isMobile && (
            <DataTitle onClick={() => handleHidden()}>
              Session Details
              <DataTitleArrow expanded={expandedTab === 'Session Details'} />
            </DataTitle>
          )}
          <SessionDetailsContainer
            ref={dataRef}
            expanded={!isMobile || expandedTab === 'Session Details'}
          >
            {isMobile ? (
              <DetailsContainerMobile>
                {studentPhotoRender()}
                {studentDetailsSectionRender()}
              </DetailsContainerMobile>
            ) : (
              <DetailsContainer>
                {studentPhotoRender()}
                {studentDetailsSectionRender()}
              </DetailsContainer>
            )}
            {(interview.desired_position || interview.position_company) && (
              <SessionDetailsSection>
                <SessionDetailsIconContainer>
                  <SessionDetailsIcon src={positionIcon} />
                </SessionDetailsIconContainer>
                <SessionDetailsData>
                  <TextBold>{interview.desired_position}</TextBold>
                  <Text>{interview.position_company}</Text>
                </SessionDetailsData>
              </SessionDetailsSection>
            )}
            {isSuperAdmin && (
              <SessionDetailsSection>
                <SessionDetailsData>
                  <TextBold>Coaching language</TextBold>
                  <Text>{interview.coaching_language}</Text>
                </SessionDetailsData>
              </SessionDetailsSection>
            )}
            <SessionDetailsSection>
              <SessionDetailsIconContainer>
                <SessionDetailsIcon src={calendarIcon} />
              </SessionDetailsIconContainer>
              <SessionDetailsData>
                <TextBold>{moment(interview.started_at).format('DD/MM/YYYY')}</TextBold>
                {interviewRecording?.start_at && interviewRecording?.end_at ? (
                  <Text>
                    {moment(interviewRecording.end_at).diff(
                      moment(interviewRecording.start_at),
                      'minutes'
                    )}{' '}
                    min
                  </Text>
                ) : (
                  <Text>
                    {moment(interview.ended_at).diff(
                      moment(interview.started_at),
                      'minutes'
                    )}{' '}
                    min
                  </Text>
                )}
              </SessionDetailsData>
            </SessionDetailsSection>

            {isMobile && (
              <ExpertDetailsContainerMobile>
                {expertPhotoRender()}
                {expertDetailsSectionRender()}
              </ExpertDetailsContainerMobile>
            )}
            <DataButtonContainer>
              {!interview.sponsored_session_available && (
                <>
                  {additionalBookingUrl ? (
                    <RegularLinkStyled href={additionalBookingUrl} target="_blank">
                      <LinkIconContainer>
                        <LinkIcon src={plusIcon} />
                      </LinkIconContainer>
                      <p>Book another session</p>
                    </RegularLinkStyled>
                  ) : (
                    <LinkStyled to="/interviews">
                      <LinkIconContainer>
                        <LinkIcon src={plusIcon} />
                      </LinkIconContainer>
                      <p>Book another session</p>
                    </LinkStyled>
                  )}
                </>
              )}
            </DataButtonContainer>
          </SessionDetailsContainer>
        </SessionDetails>
        {interview.status !== InterviewStatus.COMPLETED && !isPreview && (
          <DataRowSingle>
            <PendingTextContainer>
              <PendingTextTitle>
                Your expert is completing your report. <br />
                We`ll notify you as soon as it`s ready!
              </PendingTextTitle>
              <GrayText>
                Please reach out to our support team at{' '}
                <GrayHyperLink href="mailto:hi@wizco.io">hi@wizco.io</GrayHyperLink>.
                We&apos;re standing by ready to help.
              </GrayText>
            </PendingTextContainer>
          </DataRowSingle>
        )}
        {(interview.status === InterviewStatus.COMPLETED || isPreview) && (
          <>
            <DataRowSingle>
              <ReportBlockContainer
                title="Report Summary"
                icon={summaryIcon}
                expanded={!isMobile || expandedTab === 'Report Summary'}
                handleExpandedTab={handleExpandedTab}
              >
                <HTMLContainerText>
                  {report.summary ? htmlParser(report.summary) : 'No data provided'}
                </HTMLContainerText>
              </ReportBlockContainer>
            </DataRowSingle>
            <DataRow>
              <ReportBlockContainer
                title="Performance"
                expanded={!isMobile || expandedTab === 'Performance'}
                icon={performanceIcon}
                handleExpandedTab={handleExpandedTab}
              >
                <ChartComponent
                  color="#F1BE2B"
                  value={report.first_impression_rating || 5}
                  title="First impression"
                />
                <ChartComponent
                  color="#F23482"
                  value={report.knowledge_rating || 5}
                  title="Role-related knowledge"
                />
                <ChartComponent
                  color="#6938AA"
                  value={report.communication_rating || 5}
                  title="Communication"
                />
                <ChartComponent
                  color="#B202FF"
                  value={report.problem_solving_rating || 5}
                  title="Problem solving"
                />
              </ReportBlockContainer>
              <ReportBlockContainer
                title="Action Items"
                expanded={!isMobile || expandedTab === 'Action Items'}
                icon={actionItemsIcon}
                handleExpandedTab={handleExpandedTab}
              >
                <HTMLContainer>
                  {report.items_to_accomplish
                    ? htmlParser(report.items_to_accomplish)
                    : 'No data provided'}
                </HTMLContainer>
              </ReportBlockContainer>
            </DataRow>
            <DataRow>
              <ReportBlockContainer
                title="Keep"
                expanded={!isMobile || expandedTab === 'Keep'}
                icon={keepIcon}
                handleExpandedTab={handleExpandedTab}
              >
                <HTMLContainer>
                  {report.items_to_keep
                    ? htmlParser(report.items_to_keep)
                    : 'No data provided'}
                </HTMLContainer>
              </ReportBlockContainer>
              <ReportBlockContainer
                title="Work On"
                expanded={!isMobile || expandedTab === 'Work On'}
                icon={workOnIcon}
                handleExpandedTab={handleExpandedTab}
              >
                <HTMLContainer>
                  {report.items_to_improve
                    ? htmlParser(report.items_to_improve)
                    : 'No data provided'}
                </HTMLContainer>
              </ReportBlockContainer>
            </DataRow>
            {showConversationIntelligence && metricsData.length > 0 && (
              <DataRowSingle>
                <ReportBlockContainer
                  title="Interview Intelligence™"
                  icon={summaryIcon}
                  expanded={!isMobile || expandedTab === 'Interview Intelligence™'}
                  handleExpandedTab={handleExpandedTab}
                  onTitleClick={onGetMetricsClicked}
                  isTitleLink={isSuperAdmin && !isMobileOnly}
                >
                  <MetricsInfo metricsData={metricsData} />
                </ReportBlockContainer>
              </DataRowSingle>
            )}
            {interviewRecording && (
              <DataRowSingle>
                <SessionRecordingBlockContainer>
                  <SessionRecordingBlock blockWidth={RecordingBlockWidth.Full}>
                    <SessionDataContainer
                      title="Session Recording"
                      icon={recordingIcon}
                      expanded={!isMobile || expandedTab === 'Session Recording'}
                      handleExpandedTab={handleExpandedTab}
                      codingPlaybackUrl={codingPlaybackUrl}
                    >
                      <SessionRecordingContainerBody usePadding={hasTimelineInfo}>
                        <VideoPlayer
                          progress={progress}
                          setProgress={setProgress}
                          personalNotes={personalNotes}
                          feedback={expertNotes}
                          questions={showConversationIntelligence ? questions : []}
                          screenAcitivties={screenAcitivties}
                          interviewData={interviewData}
                          duration={duration}
                          setDuration={setDuration}
                          recordings={interviewRecording}
                          activeTimelineInfo={activeTimelineInfo}
                          onTimelineInfoJump={onTimelineInfoJump}
                          codingPlaybackUrl={codingPlaybackUrl}
                          controlsRightMargin={hasTimelineInfo && !isMobileOnly ? 280 : 0}
                          ref={
                            {
                              player,
                            } as any
                          }
                        />
                      </SessionRecordingContainerBody>
                      {isMobileOnly &&
                        showConversationIntelligence &&
                        conversationTexts?.length > 0 && (
                          <SessionRecordingBlock
                            blockWidth={
                              hasTimelineInfo
                                ? RecordingBlockWidth.Big
                                : RecordingBlockWidth.Full
                            }
                          >
                            <CallTranscript
                              texts={filteredTexts}
                              selectedTextIndex={activeTranscriptionItemIndex}
                              onSelectTranscription={onSelectTranscription}
                              onFilter={setFilter}
                              onDownload={onDownload}
                            />
                          </SessionRecordingBlock>
                        )}
                    </SessionDataContainer>
                  </SessionRecordingBlock>
                  {!isMobileOnly && (
                    <SessionRecordingBlock
                      blockWidth={
                        hasTimelineInfo
                          ? RecordingBlockWidth.Big
                          : RecordingBlockWidth.Full
                      }
                    >
                      {showConversationIntelligence && conversationTexts?.length > 0 && (
                        <CallTranscript
                          texts={filteredTexts}
                          selectedTextIndex={activeTranscriptionItemIndex}
                          onSelectTranscription={onSelectTranscription}
                          onFilter={setFilter}
                          onDownload={onDownload}
                        />
                      )}
                    </SessionRecordingBlock>
                  )}
                  <SessionRecordingBlock blockWidth={RecordingBlockWidth.Small}>
                    {hasTimelineInfo && !isMobileOnly && (
                      <TimelineInfoContainer marginTop={getTimelineinfoTopPadding()}>
                        <BlockHeaderText>Timeline info</BlockHeaderText>
                        {expertNotes.length > 0 && (
                          <DropdownBlock
                            ref={feedbackBlockeRef}
                            expanded={expandTimelineInfo === ITimelineInfoType.Feedback}
                            enableConversationIntelligence={showConversationIntelligence}
                            title={`${expertProfile?.first_name}'s feedback`}
                            onExpand={() =>
                              activateTimelineInfo(ITimelineInfoType.Feedback)
                            }
                            onCollapse={() =>
                              setExpandTimelineInfo(ITimelineInfoType.None)
                            }
                            icon={FeedbackIcon}
                            accentColor="#6938AA"
                            seekTo={seekTo}
                            notes={expertNotes}
                            duration={duration}
                            progress={progress}
                          />
                        )}
                        {personalNotes.length > 0 && (
                          <DropdownBlock
                            ref={personalNotesBlockeRef}
                            expanded={expandTimelineInfo === ITimelineInfoType.Notes}
                            enableConversationIntelligence={showConversationIntelligence}
                            title="Personal notes"
                            onExpand={() => activateTimelineInfo(ITimelineInfoType.Notes)}
                            onCollapse={() =>
                              setExpandTimelineInfo(ITimelineInfoType.None)
                            }
                            icon={NotesIcon}
                            accentColor="#F1BE2B"
                            showIcons={false}
                            seekTo={seekTo}
                            notes={personalNotes as IExpertNote[]}
                            duration={duration}
                            progress={progress}
                          />
                        )}
                        {questions.length > 0 && showConversationIntelligence && (
                          <DropdownBlock
                            ref={questionsBlockeRef}
                            expanded={expandTimelineInfo === ITimelineInfoType.Questions}
                            enableConversationIntelligence={showConversationIntelligence}
                            title="Questions"
                            onExpand={() =>
                              activateTimelineInfo(ITimelineInfoType.Questions)
                            }
                            onCollapse={() =>
                              setExpandTimelineInfo(ITimelineInfoType.None)
                            }
                            icon={QuestionIcon}
                            accentColor="#B202FF"
                            showIcons={false}
                            seekTo={seekTo}
                            notes={questions}
                            duration={duration}
                            progress={progress}
                          />
                        )}
                      </TimelineInfoContainer>
                    )}
                  </SessionRecordingBlock>
                </SessionRecordingBlockContainer>
              </DataRowSingle>
            )}
          </>
        )}
        <Footer>
          <FooterActionsContainer>
            {expertProfile.logo.url ? (
              <FooterPhoto src={expertProfile.logo.url} />
            ) : (
              <FooterPhotoPlaceholder>
                {expertProfile.first_name && expertProfile.first_name[0]}
                {expertProfile.last_name && expertProfile.last_name[0]}
              </FooterPhotoPlaceholder>
            )}
            <FooterFeedbackBy>
              <Text>Feedback by:</Text>
              <FooterExpert>
                <TextBold>
                  {expertProfile.first_name} {expertProfile.last_name},
                </TextBold>
                <Text>
                  {' '}
                  {expertProfile.current_position} at {expertProfile.company}
                </Text>
              </FooterExpert>
            </FooterFeedbackBy>

            {/* <SendButtonContainer>
              <SendButton href={`mailto:${expertProfile.email}`}>
                <LinkIconContainerBig>
                  <LinkIcon src={messageIcon} />
                </LinkIconContainerBig>
                Send a message
              </SendButton>
            </SendButtonContainer> */}
            <BookFooterButtonContainer short={!!additionalBookingUrl}>
              {!interview.sponsored_session_available && (
                <>
                  {additionalBookingUrl ? (
                    <RegularLinkStyled href={additionalBookingUrl} target="_blank">
                      <LinkIconContainer>
                        <LinkIcon src={plusIcon} />
                      </LinkIconContainer>
                      <p>Book another session</p>
                    </RegularLinkStyled>
                  ) : (
                    <LinkStyled to="/interviews">
                      <LinkIconContainer>
                        <LinkIcon src={plusIcon} />
                      </LinkIconContainer>
                      <p>Book another session</p>
                    </LinkStyled>
                  )}
                </>
              )}
            </BookFooterButtonContainer>
          </FooterActionsContainer>
          <FooterLogoContainer>
            {agency.company_logo?.url ? (
              <FooterLogo src={preventImageCache(agency.company_logo.url)} />
            ) : (
              <WizcoLogo src={logo} />
            )}
          </FooterLogoContainer>
        </Footer>
      </>
    </Layout>
  )
}

export default ReportContainer
