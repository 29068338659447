import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { validateEmail } from 'src/utils/string'
import { avaInterviewSelectors } from '../../../redux'
import StyledInput from '../elements/StyledInput'
import StepLayout from './StepLayout'
import { SpeechTexts } from '../../speechService'

enum InputError {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
}

interface IProps {
  backMode: boolean
  goBack: () => void
  goNext: ({ first_name, last_name, email }) => void
}

const UserNameComponent = ({ backMode, goNext, goBack }: IProps) => {
  const interview = useSelector(avaInterviewSelectors.data)

  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [errors, setErrors] = useState<InputError[]>([])
  const [emailErrorText, setEmailErrorText] = useState('')

  useEffect(() => {
    if (!interview.candidate) return
    setEmail(interview.candidate.email)
    setFirstName(interview.candidate.first_name)
    setLastName(interview.candidate.last_name)
  }, [interview])

  const dropError = useCallback(
    (error: string) => {
      if (errors.some((e) => e === error)) {
        const newErrors = [...errors]
        const index = newErrors.findIndex((e) => e === error)
        if (index !== -1) {
          newErrors.splice(index, 1)
          setErrors(newErrors)
        }
      }
    },
    [errors]
  )

  const onNext = useCallback(() => {
    const newErrors = []
    if (!firstName) {
      newErrors.push(InputError.FirstName)
    }
    if (!lastName) {
      newErrors.push(InputError.LastName)
    }
    if (!email) {
      setEmailErrorText('Please enter your email address')
      newErrors.push(InputError.Email)
    } else if (!validateEmail(email)) {
      setEmailErrorText('Please enter a valid email address')
      newErrors.push(InputError.Email)
    }
    if (newErrors.length > 0) {
      setErrors(newErrors)
    } else {
      goNext({ first_name: firstName, last_name: lastName, email })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, firstName, lastName])

  return (
    <StepLayout
      title={SpeechTexts.NameAndEmail}
      subTitle={SpeechTexts.LoveToKnowYou}
      goNext={onNext}
      goBack={goBack}
      errorText="Please check you entry and try again"
      backMode={backMode}
      canGoBack
      showError={errors.length > 0}
    >
      <StyledInput
        value={firstName}
        placeholder="My first name"
        showError={errors.some((e) => e === InputError.FirstName)}
        onChange={(v) => {
          setFirstName(v)
          dropError(InputError.FirstName)
        }}
        errorText="Please enter your first name"
      />
      <StyledInput
        value={lastName}
        placeholder="My last name"
        showError={errors.some((e) => e === InputError.LastName)}
        errorText="Please enter your last name"
        onChange={(v) => {
          setLastName(v)
          dropError(InputError.LastName)
        }}
      />
      <StyledInput
        value={email}
        placeholder="My email address"
        showError={errors.some((e) => e === 'email')}
        onChange={(v) => {
          setEmail(v)
          dropError(InputError.Email)
        }}
        errorText={emailErrorText}
      />
    </StepLayout>
  )
}

export default UserNameComponent
