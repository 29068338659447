import React from 'react'
import styled from '@emotion/styled'
import chevron from '../static/chevron.svg'

interface IDropdownProps {
  children: JSX.Element
  menu: any
  className?: string
}

const Overlay = styled.div`
  position: absolute;
  padding: 25px 0 25px 25px;
  background-color: white;
  border-radius: 10px;
  box-shadow: -10px 2px 29px 0 #edeef2;
  width: 200px;
`

const Wrapper = styled.div`
  display: none;

  &:hover {
    display: block;
  }
`

const Trigger = styled.div`
  display: flex;
  align-items: center;
`

const IconContainer = styled.div`
  width: 8px;
  height: 8px;
  margin-left: 10px;
`
const Icon = styled.img`
  width: 100%;
  height: auto;
  display: block;
`

const Container = styled.div`
  &:hover ${Wrapper} {
    display: block;
  }

  &:hover ${IconContainer} {
    transform: rotateX(180deg);
  }
`

function Dropdown(props: IDropdownProps) {
  const { children, menu, className } = props
  return (
    <Container className={className}>
      <Trigger>
        {children}
        <IconContainer>
          <Icon src={chevron} />
        </IconContainer>
      </Trigger>
      <Wrapper>
        <Overlay>{menu()}</Overlay>
      </Wrapper>
    </Container>
  )
}

Dropdown.defaultProps = {
  className: '',
}

export default Dropdown
