import React, { ChangeEventHandler, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { IAgoraRTC } from 'agora-rtc-sdk-ng'
import { isFirefox, isMobileOnly, isSafari } from 'react-device-detect'
import { css } from '@emotion/core'
import useStateRef from 'react-usestateref'

import playIcon from 'src/static/icPlay.png'
import pauseIcon from 'src/static/icPause.png'

import { MediaDeviceGroup } from '../../services/useMediaDevices'

import Select from '../Select'

const DefaultSpeakers: MediaDeviceGroup[] = [
  {
    deviceId: 'default',
    deviceIds: ['default'],
    label: 'Default',
    groupId: 'default',
    kind: 'audiooutput',
  },
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-self: flex-start;
  width: 100%;
`

const Text = styled.div`
  color: ${({ theme }: { theme: any }) => theme.precallTextColor};
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const SelectorText = styled.div`
  color: #737991;
  margin: 20px 0 12px 0;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  ${!isMobileOnly &&
  css`
    font-weight: normal;

    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const Player = styled.div`
  display: flex;
  width: 216px;
  height: 40px;
  background: #f5f6f8;
  border-radius: 33px;
  align-self: center;
  margin: 15px 0;
  align-items: center;
  padding: 0 12px;

  ${!isMobileOnly &&
  css`
    width: 324px;
    height: 60px;
    border-radius: 50px;
    margin: 23px 0;
    padding: 0 18px;
    @media screen and (max-width: 2500px) {
      width: 270px;
      height: 50px;
      border-radius: 41px;
      margin: 19px 0;
      padding: 0 15px;
    }
    @media screen and (max-width: 2000px) {
      width: 216px;
      height: 40px;
      border-radius: 33px;
      margin: 15px 0;
      padding: 0 12px;
    }
    @media screen and (max-width: 1200px) {
      width: 162px;
      height: 30px;
      border-radius: 25px;
      margin: 11px 0;
      padding: 0 9px;
    }
    @media screen and (max-width: 900px) {
      width: 108px;
      height: 20px;
      border-radius: 17px;
      margin: 8px 0;
      padding: 0 6px;
    }
  `}
`
const PlayButton = styled.img`
  cursor: pointer;

  ${!isMobileOnly &&
  css`
    width: 30px;
    @media screen and (max-width: 2500px) {
      width: 25px;
    }
    @media screen and (max-width: 2000px) {
      width: 20px;
    }
    @media screen and (max-width: 1200px) {
      width: 15px;
    }
    @media screen and (max-width: 900px) {
      width: 10px;
    }
  `}
`
const PlayerProgressBar = styled.div`
  width: 0;
  background: #e1e3ea;
  margin: 0 10px;
  height: 6px;
  border-radius: 3px;
  flex: 1;
`
const PlayerProgress = styled.div<{ value: number }>`
  width: ${(props) => `calc(100% * ${props.value})`};
  background: #6938aa;
  border-radius: 3px;
  height: 100%;
`
const PlayerTimer = styled.div`
  font-size: 10px;

  ${!isMobileOnly &&
  css`
    font-size: 15px;
    @media screen and (max-width: 2500px) {
      font-size: 13px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 10px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 8px;
    }
    @media screen and (max-width: 900px) {
      font-size: 5px;
    }
  `}
`

const AudioFile = '/static/test_sound.wav'

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Precall]',
  'color:#1e133b',
]

interface IProps {
  agoraRTC: IAgoraRTC
  deviceId: string
  devices: MediaDeviceGroup[]
  changeSpeaker: (deviceId: string) => void
}

const SpeakerTestView = ({ agoraRTC, deviceId, devices, changeSpeaker }: IProps) => {
  const [speakers, setSpeakers] = useStateRef<MediaDeviceGroup[]>([])
  const [currentTime, setCurrentTime] = useStateRef(0)
  const [fullTime, setFullTime] = useStateRef(0)
  const [isPlaying, setIsPlaying, isPlayingRef] = useStateRef(false)
  const [isSpeakerDisable, setIsSpeakerDisable, isSpeakerDisableRef] = useStateRef(false)
  const audio = useRef<any>(new Audio(AudioFile))

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const changeAudioOutput = async (rtc, speakerId) => {
    if (!rtc || !speakerId) return
    if (audio.current) {
      try {
        log(`Changing speaker device (${speakerId})`)
        audio.current.setSinkId(speakerId)
        log(`Successfully changed speaker device`)
      } catch (e) {
        error(`Failed to change speaker device: ${JSON.stringify(e)}`)
        throw e
      }
    }
  }

  useEffect(() => {
    const speakerDevices = devices.filter((d) => d.kind === 'audiooutput')
    if (!isSafari && !isFirefox && speakerDevices.length > 0) {
      setSpeakers(speakerDevices)
    } else {
      setIsSpeakerDisable(true)
      setSpeakers(DefaultSpeakers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices])

  useEffect(() => {
    changeAudioOutput(agoraRTC, deviceId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agoraRTC, deviceId])

  useEffect(() => {
    audio.current.onloadedmetadata = () => {
      setFullTime(audio.current?.duration)
    }
    audio.current.ontimeupdate = () => {
      setCurrentTime(audio.current?.currentTime)
      setFullTime(audio.current?.duration)
    }
    audio.current.onended = () => {
      setCurrentTime(0)
      setIsPlaying(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function play() {
    if (isPlayingRef.current) {
      audio.current.pause()
    } else {
      audio.current.play()
    }
    setIsPlaying((v) => !v)
  }

  function toTime(seconds: number) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(seconds)) return ''
    const min = Math.round(seconds / 60)
    const sec = Math.round(seconds - min * 60)
    return `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`
  }

  const switchSpeaker: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (!isSpeakerDisableRef.current) changeSpeaker(e.target.value)
  }

  return (
    <Container>
      <Text>Can you hear audio from your speakers?</Text>
      <SelectorText>Choose speaker</SelectorText>
      <Select
        id="selectPeaker"
        onChange={switchSpeaker}
        value={deviceId}
        disabled={isSpeakerDisable}
      >
        {speakers.map((it) => (
          <option key={it.deviceId} value={it.deviceId}>
            {it.label}
          </option>
        ))}
      </Select>
      <br />
      <Player>
        <PlayButton src={isPlaying ? pauseIcon : playIcon} onClick={play} />
        <PlayerProgressBar>
          <PlayerProgress
            // eslint-disable-next-line no-restricted-globals
            value={currentTime === 0 || isNaN(fullTime) ? 0 : currentTime / fullTime}
          />
        </PlayerProgressBar>
        <PlayerTimer>{`${toTime(currentTime)} / ${toTime(fullTime)}`}</PlayerTimer>
      </Player>
    </Container>
  )
}

export default SpeakerTestView
