import React from 'react'
import styled from '@emotion/styled'

import peopleIcon from 'src/static/icUser.png'

import { Text } from '../MobileText'

const VideoMutedContainer = styled.div`
  align-items: center;
  background-color: #353c5a;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  width: 100%;
`

const OverlayVideoMutedContainer = styled(VideoMutedContainer)`
  &::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.47%, rgba(0, 0, 0, 0.46) 100%);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    bottom: 0;
    content: '';
    height: 20%;
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const VideoMutedAvatar = styled.div`
  height: 60%;
  width: 33.75%;
  max-height: 12.5vh;
  max-width: 12.5vh;
  overflow: hidden;
  text-align: center;
`

const VideoMutedAvatarLogo = styled.img`
  border-radius: 50%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
`

const VideoMutedAvatarDefault = styled.img`
  background: white;
  border-radius: 50%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 35%;
`

const VideoMutedUsername = styled(Text)`
  font-size: 1.25vw;
  font-weight: 500;
  margin-top: 0.5rem;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface IProps {
  hasOverlay: boolean
  logo: string
  username: string
}

export default function VideoMutedOverlay({ hasOverlay, logo, username }: IProps) {
  const children = (
    <>
      <VideoMutedAvatar>
        {logo ? (
          <VideoMutedAvatarLogo src={logo} />
        ) : (
          <VideoMutedAvatarDefault src={peopleIcon} />
        )}
      </VideoMutedAvatar>
      <VideoMutedUsername>{username}</VideoMutedUsername>
    </>
  )
  return hasOverlay ? (
    <OverlayVideoMutedContainer>{children}</OverlayVideoMutedContainer>
  ) : (
    <VideoMutedContainer>{children}</VideoMutedContainer>
  )
}
