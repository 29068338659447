import React, { ChangeEventHandler } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import { Text } from '../MobileText'
import Select from '../Select'

const SettingContainer = styled.label`
  width: 100%;
  margin-bottom: 40px;
  @media screen and (max-width: 2500px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 2000px) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 10px;
  }
`

const SettingLabel = styled(Text)`
  color: rgba(115, 121, 145, 0.8);
  font-family: Rubik;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 0.5rem;

  font-size: 18px;
  line-height: 36px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
  }

  padding-left: 20px;
  @media screen and (max-width: 2500px) {
    padding-left: 15px;
  }
  @media screen and (max-width: 2000px) {
    padding-left: 12px;
  }
  @media screen and (max-width: 1200px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 900px) {
    padding-left: 8px;
  }
`

const SettingContent = styled.div<{ isCompact: boolean }>`
  display: ${({ isCompact }) => (isCompact ? 'flex' : 'block')};
  justify-content: space-between;
`

export const MobileSettingContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;

  @media (orientation: landscape) {
    max-width: 35vw;
  }
`

export const MobileSettingLabel = styled(Text)`
  color: #737991;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 9px;
`

interface ISettingsItem extends React.HTMLProps<HTMLDivElement> {
  isCompact: boolean
  items?: any[]
  label: string
  value?: string
  onValueChange?: ChangeEventHandler<HTMLSelectElement>
}

export default function Setting({
  children,
  isCompact,
  items,
  label,
  value,
  onValueChange,
}: ISettingsItem) {
  const getDeviceLabel = (device) => {
    return device.label || device.name || device.deviceId.substring(0, 10)
  }
  return (
    <>
      {!isMobileOnly && (
        <SettingContainer>
          <SettingLabel>{label}</SettingLabel>
          <SettingContent isCompact={isCompact}>
            {items && (
              <Select onChange={onValueChange} value={value}>
                {items.map((it) => (
                  <option key={it.deviceId || it.name} value={it.deviceId || it.name}>
                    {it.label || it.name}
                  </option>
                ))}
              </Select>
            )}
            {children}
          </SettingContent>
        </SettingContainer>
      )}
      {isMobileOnly && (
        <MobileSettingContainer>
          <MobileSettingLabel>{label}</MobileSettingLabel>
          <SettingContent isCompact={isCompact}>
            {items && (
              <Select onChange={onValueChange} value={value}>
                {items.map((it) => (
                  <option key={it.deviceId || it.name} value={it.deviceId || it.name}>
                    {getDeviceLabel(it)}
                  </option>
                ))}
              </Select>
            )}
            {children}
          </SettingContent>
        </MobileSettingContainer>
      )}
    </>
  )
}

Setting.defaultProps = {
  items: null,
  onValueChange: () => {},
  value: '',
}
