import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, Input } from '../../components'

const Container = styled.div`
  display: flex:;
  flex-direction: column;

  > div {
    p {
      color: red;
    }
  }
`

const ButtonStyled = styled(Button)`
  && {
    margin-top: 40px;
  }
`

interface IProps {
  password: string
  onEnter: () => void
}

const PasswordComponent = (props: IProps) => {
  const { password, onEnter } = props
  const [pass, setPass] = useState('')
  const [error, setError] = useState({ type: '', message: '' })

  const onButtonClick = () => {
    if (pass === password) {
      onEnter()
    } else {
      setError({
        type: '',
        message: 'Wrong password',
      })
      setPass('')
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onButtonClick()
    }
  }

  return (
    <Container onKeyPress={handleKeyPress}>
      <Input
        type="password"
        label="Enter system admin password"
        error={error}
        value={pass}
        onChange={(e) => setPass(e)}
      />
      <ButtonStyled onClick={onButtonClick} title="Enter" />
    </Container>
  )
}

export default PasswordComponent
