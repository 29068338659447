import styled from '@emotion/styled'
import React, { useState } from 'react'

import chevron from 'src/static/chevron-down.svg'

import CheckBox from '../../components/CheckBox'

const Container = styled.div`
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label<{ collapsed: boolean }>`
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: all 200ms;
  }
`

const List = styled.div<{ collapsed: boolean }>`
  border-top: 1px solid #e1e3ea;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  margin-top: 10px;
  padding-top: 10px;

  > div {
    padding: 5px 0;
  }
`

interface IProps {
  emails: string[]
  onChange: (emails: string[]) => void
}

const IgnoredEmails = ({ emails, onChange }: IProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const toggleCandidateInvitedEmail = (toggle: boolean) => {
    onChange(
      toggle
        ? [...emails, 'created_for_candidate']
        : emails.filter((p) => p !== 'created_for_candidate')
    )
  }

  return (
    <Container>
      <Title collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        Ignored emails
      </Title>
      <List collapsed={collapsed}>
        <CheckBox
          label="Candidate invited"
          onChange={toggleCandidateInvitedEmail}
          value={emails?.includes('created_for_candidate') ?? false}
          name="created_for_candidate"
        />
      </List>
    </Container>
  )
}

export default IgnoredEmails
