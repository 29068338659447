import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { NotFound } from '../components'
import VideoCallContainer from './Layout'

function VideoCall() {
  return (
    <Switch>
      <Route exact path="/call/:interviewToken">
        <VideoCallContainer />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default VideoCall
