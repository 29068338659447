import { put, takeEvery, call } from 'typed-redux-saga'

import { sendFeedback } from '../api'
import {
  sendFeedback as sendFeedbackAction,
  sendFeedbackSuccess,
  sendFeedbackFailure,
} from './actions'
import { ISendFeedbackAction } from './types'

export function* sendFeedbackSaga(action: ISendFeedbackAction) {
  try {
    yield* call(sendFeedback, action.payload)
    yield put(sendFeedbackSuccess({}))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield put(
      sendFeedbackFailure({
        error: errorMessage,
      })
    )
  }

  setTimeout(() => {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }, 60000)
}

export function* watchSendFeedback() {
  yield takeEvery(sendFeedbackAction.type, sendFeedbackSaga)
}
