/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { checkPersonalEmail, validateEmail } from 'src/utils/string'
import { useDispatch } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  BaseLayout,
  LandingButton,
  LandingInfo,
  LandingTitle,
  LayoutWrapper,
} from './landing-styled-components'
import InputStyled from './InputStyled'
import { requestDemoAction } from '../../redux'

const LayoutWrapperStyled = styled(LayoutWrapper)`
  border-radius: 32px;
`

const Layout = styled(BaseLayout)`
  border-radius: 32px;
  padding: 72px 80px;
  max-width: unset;

  @media screen and (max-width: 1439px) {
    padding: 64px 40px;
  }

  @media screen and (max-width: 1144px) {
    max-width: unset;
    padding: 64px 80px;
  }

  @media screen and (max-width: 899px) {
    padding: 32px 24px;
  }

  ${isMobileOnly &&
  css`
    padding: 24px 16px !important;
  `}
`

const DataContainer = styled.div`
  background: #f0e4f8;
  border-radius: 32px;
  padding: 100px 80px;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  align-items: center;

  @media screen and (max-width: 1439px) {
    padding: 40px;
    flex-direction: column;
  }

  @media screen and (max-width: 899px) {
    padding: 40px 24px;
  }

  ${isMobileOnly &&
  css`
    padding: 24px 16px !important;
  `}
`

const TitleContainer = styled.div`
  flex: 1;

  @media screen and (max-width: 1439px) {
    margin-bottom: 40px;
  }
`

const EmailContainer = styled.div`
  margin-left: 206px;
  width: 400px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1439px) {
    margin-left: 0;
  }

  ${isMobileOnly &&
  css`
    width: 100% !important;
  `}
`

const EmailHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #0e0224;
  margin-bottom: 8px;

  ${isMobileOnly &&
  css`
    font-size: 14px !important;
    line-height: 20px !important;
  `}
`

const EmailInfo = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #0e0224;

  ${isMobileOnly &&
  css`
    font-size: 12px !important;
    line-height: 16px !important;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
  text-align: start;

  @media screen and (max-width: 1439px) {
    font-size: 40px;
    line-height: 49px;
    font-weight: 700;
  }

  @media screen and (max-width: 899px) {
    text-align: center;
    max-width: 504px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 48px;
  justify-content: end;

  @media screen and (max-width: 1439px) {
    justify-content: center;
  }

  ${isMobileOnly &&
  css`
    margin-top: 32px !important;
  `}
`

const SuccessDataContainer = styled(DataContainer)<{ show?: boolean }>`
  background: #b202ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0;
  pointer-events: none;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.4s linear;
`

const LandingInfoStyled = styled(LandingInfo)`
  margin-bottom: 20px;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
    margin-bottom: 0;
  }
`

const SuccessTitle = styled(LandingTitleStyled)`
  @media screen and (max-width: 1280px) {
    margin: 10px 0;
  }
`

const LandingSubscribe = () => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleBookDemo = useCallback(
    (ev) => {
      ev.stopPropagation()
      if (!email) {
        setError('Please enter your email address')
      } else if (!validateEmail(email)) {
        setError('Please enter a valid email address')
      } else if (checkPersonalEmail(email)) {
        setError('Please enter a valid work email address')
      } else {
        setError('')
        setSuccess(true)
        dispatch(requestDemoAction(email))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email]
  )

  useEffect(() => {
    setError('')
  }, [email])

  useEffect(() => {
    const rootContainer = document.getElementById('landing-root')
    rootContainer.onclick = () => {
      setSuccess(false)
    }
  }, [])

  return (
    <LayoutWrapperStyled background="#f5f5f7">
      <Layout>
        <DataContainer>
          <TitleContainer>
            <LandingTitleStyled>Ready to see Wizco in action?</LandingTitleStyled>
          </TitleContainer>
          <EmailContainer>
            <EmailHeader>Work Email</EmailHeader>
            <InputStyled
              placeholder="work email"
              value={email}
              onChange={setEmail}
              error={error}
            />
            <EmailInfo>
              Try Wizco and see the results for yourself. Schedule a 30-minute demo.
            </EmailInfo>
            <ButtonContainer>
              <LandingButton onClick={handleBookDemo}>Book your demo</LandingButton>
            </ButtonContainer>
          </EmailContainer>
          <SuccessDataContainer show={success}>
            <LandingInfoStyled color="#fff">Great!</LandingInfoStyled>
            <SuccessTitle uppercase color="#fff">
              We received your request
            </SuccessTitle>
            <LandingInfoStyled color="#fff">We’ll contact you soon</LandingInfoStyled>
          </SuccessDataContainer>
        </DataContainer>
      </Layout>
    </LayoutWrapperStyled>
  )
}

export default LandingSubscribe
