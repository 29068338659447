import React from 'react'

interface SvgProps {
  fill?: string
}

function SvgMic({ fill }: SvgProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89789 11.177C5.16372 13.0035 6.07827 14.6733 7.47423 15.8808C8.8702 17.0883 10.6542 17.7528 12.5 17.7528M12.5 17.7528C14.3457 17.7528 16.1298 17.0883 17.5257 15.8808C18.9217 14.6733 19.8363 13.0035 20.1021 11.177M12.5 17.7528V21.5999M12.5011 2.3999C11.6281 2.3999 10.791 2.74668 10.1737 3.36394C9.55643 3.9812 9.20966 4.81839 9.20966 5.69133V10.0799C9.20966 10.9528 9.55643 11.79 10.1737 12.4073C10.791 13.0246 11.6281 13.3713 12.5011 13.3713C13.374 13.3713 14.2112 13.0246 14.8285 12.4073C15.4457 11.79 15.7925 10.9528 15.7925 10.0799V5.69133C15.7925 4.81839 15.4457 3.9812 14.8285 3.36394C14.2112 2.74668 13.374 2.3999 12.5011 2.3999Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

SvgMic.defaultProps = {
  fill: 'black',
}

export default SvgMic
