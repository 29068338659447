import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import OvalLoader from 'react-loader-spinner'
import {
  hasAccessToAgencyOverview,
  isAgencyAdminRole,
  isSuperAdminRole,
} from 'src/utils/jwtToken'
import { Dropdown, Title, Subtitle, Link, Modal } from '../components'
import ContactUs from './components/ContactUs'
import { signOut } from '../auth/actions'
import colors from '../static/colors'
import agency_sidebar_bg from '../static/agency_sidebar_bg.jpg'
import agency_sidebar_bg2 from '../static/agency_sidebar_bg2.jpg'
import pattern from '../static/agency_sidebar_bg_pattern.jpg'
import locked from '../static/locked.svg'
import profile from '../static/profile.svg'
import booking from '../static/booking-menu.svg'
import dashboard from '../static/dashboard-menu.svg'
import statistics from '../static/statistics-menu.svg'
import contactUs from '../static/contact-us-menu.svg'
import DownloadIcon from '../static/ic_download.png'
import {
  agencySelectors,
  deleteAgencyInterviewAction,
  downloadAgencyReportSelectors,
} from '../redux'
import { validateAgencyLogo } from '../utils/validateAgencyLogo'
import logo from '../static/logo.png'

const Layout = styled.div`
  display: flex;
  min-height: 100vh;
`

const Content = styled.div`
  margin-left: 255px;
  padding: 100px 140px;
  flex: 1;
  height: 100vh;
  @media screen and (max-width: 1440px) {
    padding: 100px 100px;
  }
  @media screen and (max-width: 1300px) {
    padding: 100px 50px;
  }
`

const Sidebar = styled.div`
  position: fixed;
  width: 255px;
  height: 100vh;
  z-index: 999;
  background-color: ${colors.formBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 65px;
`

const SidebarRightDefault = styled.div`
  width: 300px;
  background: url(${agency_sidebar_bg}) 0 0 no-repeat;
  background-color: #6938ab;
`

const SidebarRightWrapper = styled.div`
  width: 300px;
  background: url(${pattern}) 0 0 repeat-y;
`

const SidebarRight = styled.img`
  width: 100%;
`

const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Header = styled.header`
  position: relative;
  display: flex;
`

const HeaderTitle = styled.div`
  flex: 1;
`

const Body = styled.div<IAgencyBodyProps>`
  margin-bottom: 50px;
  width: ${(props) => (props.fullBody ? 'auto' : '400px')};
`

const AgencyLogoContainer = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100px;
  background-color: #ffffff;
  box-shadow: 3px 3px 10px 0 rgba(234, 234, 234, 0.5);
  margin-bottom: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`
const FixedAlertContainer = styled.div<IAlertContainer>`
  padding: 8px 15px;
  border-radius: 4px;
  background-color: #d8eefef7;
  color: #000000;
  font-size: 12px;
  position: fixed;
  top: 40px;
  width: ${(props) => `${props.width}px`};
  @keyframes hideAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      display: none;
      opacity: 0;
    }
  }
  @keyframes showAnimation {
    0% {
      display: block;
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: showAnimation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
`

const StaticAlertContainer = styled.div<IAlertContainer>`
  padding: 8px 15px;
  border-radius: 4px;
  background-color: #d8eefef7;
  color: #000000;
  font-size: 12px;
  position: absolute;
  top: -40px;
  width: ${(props) => `${props.width}px`};
  @keyframes hideAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      display: none;
      opacity: 0;
    }
  }
  @keyframes showAnimation {
    0% {
      display: block;
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: showAnimation;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
`

const AgencyLogo = styled.img`
  height: auto;
  width: 100%;
`

const WizcoLogo = styled.img`
  width: 80px;
  height: 20px;
  margin-bottom: 50px;
`

const Name = styled.h3`
  color: #3b424f;
  font-size: 12px;
  font-weight: 500;
`

const DropdownStyled = styled(Dropdown)`
  margin-bottom: 50px;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const MenuLink = styled(NavLink)`
  color: #3b424f;
  &.${(props) => props.activeClassName} {
    border-right: 5px solid #b202ff;
    font-weight: bold;
  }
  font-size: 12px;
  line-height: 48px;
  letter-spacing: 0.6px;
  height: 48px;
  box-sizing: border-box;
  padding: 0 40px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
  }
  display: block;
  margin: 0;
  min-width: 210px;
`

const MenuLinkButton = styled.div`
  color: #3b424f;
  cursor: pointer;
  font-size: 12px;
  line-height: 48px;
  letter-spacing: 0.6px;
  height: 48px;
  box-sizing: border-box;
  padding: 0 40px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
    color: #3b424f;
  }
  display: block;
  margin: 0;
  min-width: 210px;
`

const DropdownLink = styled(MenuLink)`
  padding-left: 0;

  &:hover {
    color: #3b424f;
  }
`

const LogOut = styled(MenuLink)`
  padding-left: 0;

  &:hover {
    cursor: pointer;
  }
`.withComponent('p')

const BackToAgenciesLink = styled(Link)`
  padding-left: 40px;
  align-self: start;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 20px;
`

const AgencyEditLink = styled(Link)`
  padding-left: 40px;
  align-self: start;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 20px;
`

const MenuIconContainer = styled.div`
  display: inline-block;
  margin-right: 15px;
`
const MenuIcon = styled.img`
  vertical-align: middle;
  margin-top: -2px;
`

const HeaderTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`

const DownloadButton = styled.div<{ fill?: string }>`
  align-items: center;
  display: flex;
  width: 186px;
  height: 40px;
  border-radius: 4px;
  background: ${(props) => props.fill ?? '#B202FF'};
  color: white;
  cursor: pointer;
  padding: 8px 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  &:active {
    opacity: 0.5;
  }

  svg {
    margin-right: 10px;
  }
`

const DiwnloadImg = styled.img`
  width: 21px;
  margin-right: 10px;
`

const DeleteSessionButton = styled.div<{ fill: string }>`
  align-items: center;
  display: flex;
  width: 186px;
  height: 40px;
  border-radius: 4px;
  background: ${(props) => props.fill};
  color: white;
  cursor: pointer;
  padding: 8px 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  &:active {
    opacity: 0.5;
  }
`

interface IAgencyLayoutProps {
  children: React.ReactElement
  title: string
  subtitle?: string
  alertText?: string
  alertType?: string
  bg?: string
  hideBg?: boolean
  fullBody?: boolean
  hasSessionButton?: boolean
  onButtonClick?: () => void
}
interface IAgencyBodyProps {
  fullBody?: boolean
}
interface IAlertContainer {
  width?: string
}

function Overlay() {
  const dispatch = useDispatch()

  return (
    <>
      <DropdownLink to="/user/change_password">
        <MenuIconContainer>
          <MenuIcon src={locked} />
        </MenuIconContainer>
        Change Password
      </DropdownLink>
      <LogOut onClick={() => dispatch(signOut())}>
        <MenuIconContainer>
          <MenuIcon src={profile} />
        </MenuIconContainer>
        Log out
      </LogOut>
    </>
  )
}

function AgencyLayout(props: IAgencyLayoutProps) {
  const dispatch = useDispatch()
  const { id, interviewId } = useParams()
  const agency = useSelector(agencySelectors.data)
  const isLoadingReport = useSelector(downloadAgencyReportSelectors.isLoading)

  const isSuperAdmin = isSuperAdminRole()
  const isAgencyAdmin = isAgencyAdminRole(id)

  const {
    title,
    subtitle,
    children,
    bg,
    hideBg,
    fullBody,
    alertText,
    alertType,
    hasSessionButton,
    onButtonClick,
  } = props
  const [showContactUs, setShowContactUs] = useState(false)
  const headerRef = useRef(null)
  const bodyRef = useRef(null)

  const [width, setWidth] = useState('')

  useEffect(() => {
    if (alertType === 'static') {
      setWidth(bodyRef.current.offsetWidth)
    } else {
      setWidth(headerRef.current.offsetWidth)
    }

    const handleResize = () => {
      if (alertType === 'static') {
        setWidth(bodyRef.current.offsetWidth)
      } else {
        setWidth(headerRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [alertType, headerRef])

  const handleHideContactUs = useCallback(() => {
    setShowContactUs(false)
  }, [])

  const handleDeleteSession = useCallback(() => {
    const confirmed = window.confirm(`Are you sure you want to delete this session?`)
    if (confirmed)
      dispatch(deleteAgencyInterviewAction({ agencyId: id, id: interviewId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, interviewId])

  return (
    <>
      <Layout>
        <Sidebar>
          <SidebarBody>
            <AgencyLogoContainer>
              <AgencyLogo src={validateAgencyLogo(agency.company_logo.url)} />
            </AgencyLogoContainer>
            <DropdownStyled menu={Overlay}>
              <Name>{agency.name}</Name>
            </DropdownStyled>
            {isSuperAdmin && (
              <AgencyEditLink to={`/agencies/${id}/edit`}>Edit Agency</AgencyEditLink>
            )}

            {isSuperAdmin && (
              <AgencyEditLink to={`/agencies/${id}/bulk`}>Bulk invite</AgencyEditLink>
            )}

            {hasAccessToAgencyOverview() && (
              <BackToAgenciesLink to="/admin">Back to Agencies</BackToAgenciesLink>
            )}
            <Menu>
              {(isSuperAdmin || (isAgencyAdmin && agency.admin_booking)) && (
                <MenuLink activeClassName="active" exact to={`/agencies/${id}`}>
                  <MenuIconContainer>
                    <MenuIcon src={booking} />
                  </MenuIconContainer>
                  New Booking
                </MenuLink>
              )}
              {agency.enable_experts && (
                <MenuLink activeClassName="active" exact to={`/agencies/${id}/activity`}>
                  <MenuIconContainer>
                    <MenuIcon src={dashboard} />
                  </MenuIconContainer>
                  Activity
                </MenuLink>
              )}
              {agency.enable_ava && (
                <MenuLink
                  activeClassName="active"
                  exact
                  to={`/agencies/${id}/ava_activity`}
                >
                  <MenuIconContainer>
                    <MenuIcon src={dashboard} />
                  </MenuIconContainer>
                  Activity (Ava)
                </MenuLink>
              )}
              {agency.enable_experts && (
                <MenuLink activeClassName="active" to={`/agencies/${id}/statistics`}>
                  <MenuIconContainer>
                    <MenuIcon src={statistics} />
                  </MenuIconContainer>
                  Statistics
                </MenuLink>
              )}
              <MenuLinkButton onClick={() => setShowContactUs(true)}>
                <MenuIconContainer>
                  <MenuIcon src={contactUs} />
                </MenuIconContainer>
                Contact Us
              </MenuLinkButton>
            </Menu>
          </SidebarBody>
          <WizcoLogo src={logo} />
        </Sidebar>
        <Content>
          <Header ref={headerRef}>
            {alertText && alertType === 'fixed' && (
              <FixedAlertContainer width={width}>{alertText}</FixedAlertContainer>
            )}
            {alertText && alertType === 'static' && (
              <StaticAlertContainer width={width}>{alertText}</StaticAlertContainer>
            )}
            <HeaderTitleContainer>
              <HeaderTitle>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
              </HeaderTitle>
              {hasSessionButton && isSuperAdmin && (
                <DeleteSessionButton onClick={handleDeleteSession} fill="#ff0000">
                  <span>Delete Session</span>
                </DeleteSessionButton>
              )}
            </HeaderTitleContainer>
            {onButtonClick && (
              <DownloadButton onClick={onButtonClick}>
                {isLoadingReport && (
                  <OvalLoader type="Oval" color="white" width={20} height={20} />
                )}
                {!isLoadingReport && <DiwnloadImg src={DownloadIcon} alt="" />}
                <span>Download report</span>
              </DownloadButton>
            )}
          </Header>
          <Body fullBody={fullBody} ref={bodyRef}>
            {children}
          </Body>
        </Content>
        {!hideBg && bg === 'default' && <SidebarRightDefault />}
        {!hideBg && bg !== 'default' && (
          <SidebarRightWrapper>
            <SidebarRight src={agency_sidebar_bg2} />
          </SidebarRightWrapper>
        )}
      </Layout>
      {showContactUs && (
        <Modal onClose={handleHideContactUs}>
          <ContactUs onClose={handleHideContactUs} />
        </Modal>
      )}
    </>
  )
}

AgencyLayout.defaultProps = {
  subtitle: '',
  bg: 'default',
  hideBg: false,
  fullBody: false,
  alertText: '',
  alertType: 'static',
  onButtonClick: null,
  hasSessionButton: null,
}

export default AgencyLayout
