/* eslint-disable no-param-reassign */
import TextareaAutosize from 'react-textarea-autosize'
import styled from '@emotion/styled'
import React, { useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { isMobileOnly } from 'react-device-detect'
import { AnimatedBase } from './styled-components'

const FontSize = 14
const SmallFontSize = 12
const Padding = 12

const Container = styled.div<{ width?: number }>`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  width: ${({ width }) => width ?? 100}%;
  justify-content: space-between;
  flex-direction: column;

  > textarea {
    border: none;
    width: 100%;
    background: transparent;
    font-weight: 500;
    font-size: ${FontSize}px;
    padding: ${Padding}px 0;
    letter-spacing: 1px;
    color: black;
    border-bottom: 0.5px solid black;
    outline: none;
    animation: fadeInFromNone 0.5s ease-out;

    &:placeholder {
      opacity: 0.5;
    }

    @keyframes fadeInFromNone {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
`

const Title = styled.div<{ focused?: boolean }>`
  font-weight: 500;
  line-height: 14px;
  font-size: ${({ focused }) => (focused ? 10 : 14)}px;
  color: black;
  margin-bottom: 4px;
  transition: all 0.15s linear;
`

const Error = styled(AnimatedBase)<{ toTheRight?: boolean; isSmallScreen?: boolean }>`
  position: absolute;
  right: ${({ toTheRight, isSmallScreen }) =>
    !isSmallScreen || (toTheRight && !isMobileOnly) ? `0px` : 'unset'};
  left: ${({ toTheRight, isSmallScreen }) =>
    isSmallScreen && !toTheRight ? `0px` : 'unset'};
  bottom: -24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff002a;
  pointer-events: none;
`

const OptionalLabel = styled.span`
  color: #000;
  font-style: italic;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 1px;
  opacity: 0.5;
  margin-left: 6px;
`

interface IProps {
  width?: number
  title?: string
  placeholder?: string
  value?: string
  maxLength: number
  maxRows?: number
  reduceFontFromRow?: number
  errorText?: string
  showError?: boolean
  optional?: boolean
  onChange?: (data: string) => void
}

const StyledTextArea = ({
  value,
  title,
  width,
  placeholder,
  maxLength,
  maxRows,
  reduceFontFromRow,
  errorText,
  showError,
  optional,
  onChange,
}: IProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>()
  const [fontSize, setFontSize] = useState(FontSize)
  const [focused, setFocused] = useState(false)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1100px)' }) || isMobileOnly

  const onHeightChange = (data, param) => {
    if (!value) return
    const lineCount = Math.round((data - Padding * 2 - 1.5) / param.rowHeight)
    if (lineCount >= reduceFontFromRow) {
      setFontSize(SmallFontSize)
    } else {
      setFontSize(FontSize)
    }
  }

  return (
    <Container width={width}>
      {title && (
        <Title focused={focused || (value !== '' && value !== null)}>
          {title}
          {optional && <OptionalLabel>(Optional)</OptionalLabel>}
        </Title>
      )}
      <TextareaAutosize
        ref={textAreaRef}
        placeholder={placeholder}
        value={value}
        maxRows={maxRows}
        maxLength={maxLength}
        style={{ fontSize: `${fontSize}px` }}
        onChange={(ev) => onChange(ev.target.value)}
        onHeightChange={onHeightChange}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
      />
      {showError && (
        <Error toTheRight={value?.length > 0} isSmallScreen={isSmallScreen}>
          {errorText}
        </Error>
      )}
    </Container>
  )
}

StyledTextArea.defaultProps = {
  width: 100,
  placeholder: '',
  title: '',
  value: '',
  maxRows: 4,
  reduceFontFromRow: 3,
  onChange: () => {},
  errorText: '',
  showError: false,
  optional: false,
}

export default StyledTextArea
