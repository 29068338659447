import React from 'react'
import styled from '@emotion/styled'
import withProps from 'recompose/withProps'

interface IContainerProps {
  disabled?: boolean
}

interface ICheckboxProps {
  isSelected: boolean
  disabled: boolean
  hasError: boolean
  useTheme?: boolean
  checked?: boolean
  hideCheckBox?: boolean
}

interface ILabelContainerProps {
  htmlFor: string
  checked: boolean
  useTheme?: boolean
}

interface ILabelProps {
  htmlFor: string
  top?: number
  left?: number
}

const Container = styled('div')<IContainerProps>`
  margin-bottom: 8px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))

const CheckboxStyled = withProps({ type: 'checkbox' })(
  styled('input')<ICheckboxProps>`
    position: absolute;
    opacity: 0;

    & + label {
      cursor: pointer;
      padding: 0;
    }

    &:hover {
      cursor: pointer;
    }

    & + label:before {
      content: '';
      margin-right: 11px;
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      border: 1px solid rgba(143, 155, 179, 0.49);
      border-radius: 3px;
      background-color: #ffffff;
      opacity: ${({ hideCheckBox }) => (hideCheckBox ? 0 : 1)};
    }

    &:checked + label:before {
      background-color: ${({ theme, useTheme }: any) => {
        return theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
      }};
      opacity: ${({ hideCheckBox }) => (hideCheckBox ? 0 : 1)};
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
        4px -6px 0 white, 4px -6px 0 white;
      transform: rotate(45deg);
      opacity: ${({ hideCheckBox }) => (hideCheckBox ? 0 : 1)};
    }
  `
)

const LabelContainer = styled('label')<ILabelContainerProps>`
  display: flex;
  justify-content: center;
  height: 100%;
  border: 1px solid rgba(143, 155, 179, 0.4);
  border-radius: 3px;
  background-color: ${({ checked, theme, useTheme }: any) => {
    const checkedColor = theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
    const checkedColorRGB = hexToRgb(checkedColor)
    return checked
      ? `rgba(${checkedColorRGB[0]}, ${checkedColorRGB[1]}, ${checkedColorRGB[2]}, 0.1)`
      : 'white'
  }};

  /* background-color: ${({ checked }) => (checked ? 'rgba(0,149,255,0.1)' : 'white')}; */

  &:hover {
    cursor: pointer;
  }
`

const Label = styled('label')<ILabelProps>`
  display: inline-block;
  line-height: 20px;
  color: #3b424f;
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0;
  position: absolute;
  left: ${(props) => props.left ?? 9}px;
  top: ${(props) => props.top ?? 9}px;

  &:hover {
    cursor: pointer;
  }
`

const Body = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const CheckboxButton = React.forwardRef((props: any, ref: any) => {
  const {
    name,
    onChange,
    onBlur,
    disabled,
    checked,
    children,
    useTheme,
    top,
    left,
    hideCheckBox,
  } = props

  return (
    <Container>
      <LabelContainer useTheme={useTheme} checked={checked} htmlFor={name}>
        <CheckboxStyled
          useTheme={useTheme}
          disabled={disabled}
          onBlur={onBlur}
          name={name}
          ref={ref}
          checked={checked}
          onChange={onChange}
          id={name}
          hideCheckBox={hideCheckBox}
        />
        <Label htmlFor={name} top={top} left={left} />
        <Body>{children}</Body>
      </LabelContainer>
    </Container>
  )
})

export default CheckboxButton
