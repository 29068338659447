import { put, takeEvery, call, take } from 'typed-redux-saga'

import store from 'src/redux/store'

import { avaInterviewReplaceQuestion } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { avaGetInterviewAction, avaGetInterviewActionSuccessType } from './getInterview'
import { updateAvaQuestionAction } from '../../data/ava/question'

export interface IAvaReplaceQuestionAction {
  interviewToken: string
  questionId: string
}

const {
  action: avaReplaceQuestionAction,
  actionFailure: avaReplaceQuestionActionFailure,
  actionSuccess: avaReplaceQuestionActionSuccess,
  actionSuccessClear: avaReplaceQuestionActionSuccessClear,
  actionFailureClear: avaReplaceQuestionActionFailureClear,
  actionType: avaReplaceQuestionActionType,
  actionTypeFailure: avaReplaceQuestionActionFailureType,
  actionTypeSuccess: avaReplaceQuestionActionSuccessType,
  reducer: avaReplaceQuestionRequestReducer,
  selectors: avaReplaceQuestionSelectors,
} = reduxRequestFactory<IAvaReplaceQuestionAction>('avaReplaceQuestion', 'requests')

export {
  avaReplaceQuestionAction,
  avaReplaceQuestionActionFailure,
  avaReplaceQuestionActionSuccess,
  avaReplaceQuestionActionSuccessClear,
  avaReplaceQuestionActionFailureClear,
  avaReplaceQuestionActionType,
  avaReplaceQuestionActionFailureType,
  avaReplaceQuestionActionSuccessType,
  avaReplaceQuestionRequestReducer,
  avaReplaceQuestionSelectors,
}

function* avaReplaceQuestionSaga(action: ISagaAction<IAvaReplaceQuestionAction>) {
  try {
    const { interviewToken, questionId: question_id } = action.payload
    yield* call(avaInterviewReplaceQuestion, interviewToken, { question_id })
    yield put(avaGetInterviewAction({ interviewToken }))

    yield take(avaGetInterviewActionSuccessType)

    const state = store.getState()
    const { questions } = state.ava.interview.data
    const currentQuestion = questions.find((q) => q.id === state.ava.question.data?.id)
    yield put(updateAvaQuestionAction(currentQuestion))

    yield put(avaReplaceQuestionActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaReplaceQuestionActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaReplaceQuestion() {
  yield takeEvery(avaReplaceQuestionActionType, avaReplaceQuestionSaga)
}
