import { IExpertNote } from 'src/api'
import { reduxDataFactory } from 'src/utils/redux'

const {
  action: setActiveNoteAction,
  actionType: activeNoteActionType,
  reducer: activeNoteReducer,
  selectors: activeNoteSelectors,
} = reduxDataFactory<IExpertNote>('activeNoteInfo', 'report')

export {
  setActiveNoteAction,
  activeNoteActionType,
  activeNoteReducer,
  activeNoteSelectors,
}
