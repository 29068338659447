import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import useStateRef from 'react-usestateref'
import SvgFeedback from 'src/static/components/SvgFeedback'
import SvgRemove from 'src/static/components/SvgRemove'
import useInterview from 'src/videocall/services/useInterview'

const getMoodColor = (mood?: string) => {
  if (!mood) return 'transparent'
  switch (mood) {
    case 'Positive':
      return 'rgba(0, 222, 159, 0.2)'
    default:
      return 'rgba(242, 52, 130, 0.2)'
  }
}

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-bottom: 1px solid #e1e3ea;
  animation: growDown 0.2s ease-in-out;
  transform-origin: top center;
  overflow: hidden;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`

const ContainerOverlay = styled(ItemContainer)`
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  animation: none;
  border-bottom: none;
`

const ItemText = styled.div<{ mood?: string }>`
  margin-right: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #21304e;
  padding: ${({ mood }) => (mood ? 4 : 0)}px;
  border-radius: ${({ mood }) => (mood ? 12 : 0)}px;
  overflow: hidden;
  background: ${({ mood }) => getMoodColor(mood)};
`

const TimeLabel = styled.div<{ mood?: string }>`
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8f9bb3;
  background: #f5f6f8;
  border-radius: 12px;
  height: fit-content;
  padding: 2px;
  margin-left: 8px;
  align-self: ${({ mood }) => (mood ? 'center' : 'auto')};
`

const ItemMenuContainer = styled.div<{ translateX: number; animated: boolean }>`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  transform: translateX(${({ translateX }) => translateX}px);
  width: auto;
  height: 100%;
  display: flex;
  transition: ${({ animated }) => (animated ? 'all 0.1s linear' : 'none')};
`

const MenuItem = styled.div`
  height: 100%;
  width: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  background: white;
  border-left: 1px solid #e1e3ea;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8f9bb3;

  > svg {
    margin-bottom: 6px;
  }
`

interface IProps {
  mood: string
  text: string
  showMenu: boolean
  createdAt: string
  onShowMenu?: () => void
  onHideMenu?: () => void
  onEdit?: () => void
  onDelete?: () => void
}

const MaxSlide = 180

const FeedbackItemMobile = ({
  mood,
  text,
  showMenu,
  createdAt,
  onShowMenu,
  onHideMenu,
  onEdit,
  onDelete,
}: IProps) => {
  const [menuTranslate, setMenuTranslate] = useStateRef(180)
  const [useMenuAnimation, setUseMenuAnimation] = useState(false)
  const { interviewStartedAt } = useInterview()

  const hideMenu = () => {
    onHideMenu()
  }

  const onItemClick = () => {
    if (showMenu) {
      onHideMenu()
    } else {
      onShowMenu()
    }
  }

  const getRelativeTimestamp = (feedbackTs: string) => {
    const interviewStarted = new Date(interviewStartedAt)
    const feedback = new Date(feedbackTs)
    const ts = feedback.getTime() - interviewStarted.getTime()
    const resultTs = new Date(ts)
    const hours = resultTs.getUTCHours()
    const minutes = resultTs.getUTCMinutes()
    const seconds = resultTs.getUTCSeconds()

    const getTimeSegment = (num: number) => {
      return `${num < 10 ? '0' : ''}${num}`
    }

    return `${getTimeSegment(hours * 60 + minutes)}.${getTimeSegment(seconds)}`
  }

  useEffect(() => {
    if (showMenu) {
      setMenuTranslate(0)
    } else {
      setMenuTranslate(MaxSlide)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu])

  useEffect(() => {
    setUseMenuAnimation(true)
  }, [])

  return (
    <ItemContainer onClick={onItemClick}>
      <ItemText mood={mood}>{text}</ItemText>
      <TimeLabel mood={mood}>{getRelativeTimestamp(createdAt)}</TimeLabel>
      {showMenu && <ContainerOverlay onClick={hideMenu} />}
      <ItemMenuContainer translateX={menuTranslate} animated={useMenuAnimation}>
        {!mood && (
          <MenuItem onClick={onEdit}>
            <SvgFeedback fill="#B202FF" />
            Edit
          </MenuItem>
        )}
        <MenuItem onClick={onDelete}>
          <SvgRemove fill="#B202FF" />
          Delete
        </MenuItem>
      </ItemMenuContainer>
    </ItemContainer>
  )
}

FeedbackItemMobile.defaultProps = {
  onShowMenu: () => {},
  onHideMenu: () => {},
  onEdit: () => {},
  onDelete: () => {},
}

export default FeedbackItemMobile
