import React from 'react'
import styled from '@emotion/styled'

import { IMicrophoneAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng'

import audioMutedIcon from 'src/static/candidate_cockpit_muted.png'
import Volume from '../volume'
import VideoMutedOverlay from './VideoMutedOverlay'
import {
  LocalNetworkQualityIndicator,
  RemoteNetworkQualityIndicator,
} from '../volume/NetworkQualityIndicator'

const Video = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;

  div {
    background-color: #353c5a !important;
    border-radius: 0.75rem !important;

    video {
      border-radius: 0.75rem !important;
    }
  }
`

const LocalVideo = styled(Video)<{ contain: boolean }>`
  transform: scale(-1, 1);

  div {
    background-color: #353c5a !important;

    video {
      object-fit: ${({ contain }) => (contain ? 'contain' : 'cover')} !important;
    }
  }
`

const VideoContainer = styled.div<{ isCompact: boolean }>`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  height: ${({ isCompact }) => (isCompact ? '92.25px' : '143px')};
  width: ${({ isCompact }) => (isCompact ? '164px' : '240px')};
`

const MuteIcon = styled.img`
  background: black;
  border-radius: 50%;
  height: 100%;
  margin-right: 5px;
  opacity: 0.8;
`

const ButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;

  top: 15px;
  height: 60px;
  right: 15px;
  @media screen and (max-width: 2500px) {
    top: 12px;
    height: 50px;
    right: 12px;
  }
  @media screen and (max-width: 2000px) {
    top: 10px;
    height: 40px;
    right: 10px;
  }
  @media screen and (max-width: 1200px) {
    top: 7px;
    height: 30px;
    right: 7px;
  }
  @media screen and (max-width: 900px) {
    top: 5px;
    height: 20px;
    right: 5px;
  }
`

const MicWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;

  width: 60px;
  @media screen and (max-width: 2500px) {
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    width: 20px;
  }
`

const LeftTopContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;

  top: 15px;
  height: 45px;
  left: 15px;
  width: 60px;
  @media screen and (max-width: 2500px) {
    top: 12px;
    height: 37.5px;
    left: 12px;
    width: 50px;
  }
  @media screen and (max-width: 2000px) {
    top: 10px;
    height: 30px;
    left: 10px;
    width: 40px;
  }
  @media screen and (max-width: 1200px) {
    top: 7px;
    height: 22.5px;
    left: 7px;
    width: 30px;
  }
  @media screen and (max-width: 900px) {
    top: 5px;
    height: 15px;
    left: 5px;
    width: 20px;
  }
`

interface IProps {
  id: string
  isLocal: boolean
  username: string
  videoMuted: boolean
  audioMuted: boolean
  track: IMicrophoneAudioTrack | IRemoteAudioTrack
  logo: string
  isCompact: boolean
  contain: boolean
}

const SmallVideo = ({
  id,
  isLocal,
  username,
  videoMuted,
  audioMuted,
  track,
  logo,
  isCompact,
  contain,
}: IProps) => {
  return (
    <VideoContainer isCompact={isCompact}>
      {isLocal ? <LocalVideo id={id} contain={contain} /> : <Video id={id} />}
      {videoMuted && (
        <VideoMutedOverlay hasOverlay={false} logo={logo} username={username} />
      )}
      <LeftTopContainer>
        {isLocal ? <LocalNetworkQualityIndicator /> : <RemoteNetworkQualityIndicator />}
      </LeftTopContainer>
      <ButtonsContainer>
        {!audioMuted && track && (
          <MicWrap>
            <Volume.AudioVolumeIndicator element={undefined} track={track} />
          </MicWrap>
        )}
        {audioMuted && <MuteIcon src={audioMutedIcon} />}
      </ButtonsContainer>
    </VideoContainer>
  )
}

export default SmallVideo
