import React, { useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get, isDate, isNumber } from 'lodash'
import { CountryDropdown } from 'react-country-region-selector'
import moment from 'moment'
import withProps from 'recompose/withProps'
import styled from '@emotion/styled'
import { isSuperAdminRole } from 'src/utils/jwtToken'

import { formattedDate, normalizeDate } from 'src/utils/date'

import {
  Notification,
  Button,
  Loader,
  Input,
  Textarea,
  Checkbox,
  DatePicker,
  Select,
  Autocomplete,
} from 'src/components'
import AvailabilityTimeCheckbox from 'src/interview/components/AvailabilityTimeCheckbox'
import AvailabilityDaysCheckbox from 'src/interview/components/AvailabilityDaysCheckbox'

import eveningIcon from 'src/static/evening.png'
import noonIcon from 'src/static/noon.png'
import morningIcon from 'src/static/morning.png'
import chevron from 'src/static/chevron_gray.svg'

import colors from 'src/static/colors'
import { format } from 'date-fns'
import FileUploadView from 'src/interview/components/FileUploadView'

import { Languages } from 'src/utils/staticData'
import {
  AgencyCoachingTypes,
  BusinessModel,
  CoachingType,
  IInternalExpert,
  IInterviewAvailabilityDays,
  IInterviewAvailabilityTime,
  IInterviewCandidateState,
  IInterviewInternalExpert,
  IInterviewPositionState,
} from '../../api'
import { agencySelectors } from '../../redux'
import { EmailRegex } from '../../utils/regex'

import {
  updateOrderInfoAction,
  updateOrderInfoActionFailureClear,
  updateOrderInfoActionSuccessClear,
  updateOrderInfoSelectors,
} from '../redux'
import { interview as interviewSelector } from '../selectors'
import ApplicantAvailabilityPopup from '../components/ApplicantAvailabilityPopup'
import { BulkInviteNotification } from '../components/BulkInvites'

const ButtonStyled = styled(Button)`
  margin: 10px;

  && {
    margin-top: 40px;
  }
`

const Form = styled.form`
  margin-top: 20px;
`

const FormsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`

const FormWrapper = styled.div`
  width: 48%;
`

const BlockWrapper = styled.div`
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid #eee;
  margin-bottom: 30px;
`

const BlockWrapperTransparent = styled(BlockWrapper)`
  background: transparent;
  border: none;
  padding: 0;
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SlotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  flex-wrap: wrap;
`

const SlotContainer = styled.div`
  font-weight: 400;
  width: 110px;
  font-size: 12px;
  color: black;
  margin: 0 15px 15px 0;
  color: #21304e;
  > span {
    font-weight: 500;
  }
`

const Row = styled.div`
  display: flex;

  > div {
    width: 100%;
  }

  > div:last-of-type {
    margin-left: 10px;
  }

  .react-datepicker-wrapper {
    width: 100%;

    > div > div {
      width: 100%;
    }
  }

  .react-datepicker-popper {
    z-index: 999;
  }
`

const CountryDropdownContainerStyled = styled.div`
  margin-bottom: 21px;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 6px;
  background-color: #f5f6f8;
  border: 1px solid #e1e3ea;
`

interface ICountryDropdownProps {
  value: string
  useTheme: boolean
}

const CountryDropdownStyled = styled(CountryDropdown)<ICountryDropdownProps>`
  box-sizing: border-box;
  background: url(${chevron}) no-repeat center right 15px;
  padding: 5px 20px 5px 14px;
  background-size: 11px;
  /* border-style: solid;
  border-width: 1px; */
  /* border-color: ${({ value, theme, useTheme }: any) => {
    const borderColor = theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
    return value ? borderColor : 'transparent'
  }}; */
  border: transparent;
  /* background-color: ${({ value }) => {
    return value ? colors.formActiveBackgroundColor : '#f5f6f8'
  }}; */
  background-color: #f5f6f8;
  height: 44px;
  width: 100%;
  border-radius: 6px;
  color: ${({ value }) => (value ? '#21304e' : 'rgba(33, 48, 78, 0.5)')};
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
`

const AvailabilityDaysRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  & > div {
    width: 48%;
    height: 56px;
  }
`

const AvailabilityDaysRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;

  & > div {
    width: 75px;
    height: 66px;
    margin: 0 12px 12px 0;
  }
`

const AvailabilityTimeRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 31%;
  }
`

const AvailabilityTimeRowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > div {
    width: 102px;
    height: 78px;
    margin: 0 12px 12px 0;
  }
`

const CandidateConsent = styled.div`
  width: 50%;
`
const LineWarpper = styled.div`
  display: flex;
  justify-content: space-between;
`
const ConsentItem = styled.div`
  opacity: 0.4;

  div,
  input,
  label {
    cursor: not-allowed !important;
  }
`

const RowWrapper = styled.div`
  margin-bottom: 20px;
  width: calc(100% - 10px) !important;
`

interface IConsentSelectionProps {
  isChecked: boolean
}

const ConsentSelection = styled.div<IConsentSelectionProps>``

const FormTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
`

const ButtonsWrapper = styled.div`
  display: flex;
`

const TimezoneLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.666667px;
  color: black;
`

const LinkButton = withProps()(styled.div`
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
  color: ${({ useTheme, theme }: any) => {
    let color = theme.agencyColor
    if (color === '') {
      color = '#B202FF'
    }
    return useTheme ? color : '#B202FF'
  }};
  text-decoration: underline;
  cursor: pointer;
`)

const EmailReminders = styled.div`
  width: 50%;
`

const linkRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/
const linkedinLinkRegex = /((https:\/\/)?(www\.)?linkedin\.com(\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?)/

const applicantAvailabilityDayGropus = [
  { title: 'Weekdays', name: 'weekday' },
  { title: 'Weekend', name: 'weekend' },
]

const applicantAvailabilityDays = [
  { title: 'Mon', name: 'monday' },
  { title: 'Tue', name: 'tuesday' },
  { title: 'Wed', name: 'wednesday' },
  { title: 'Thu', name: 'thursday' },
  { title: 'Fri', name: 'friday' },
  { title: 'Sat', name: 'saturday' },
  { title: 'Sun', name: 'sunday' },
]

const applicantAvailabilityTimesOld = [
  { title: 'Morning', time: '8am-12pm', name: '8-12', icon: noonIcon },
  {
    title: 'Afternoon',
    time: '12pm-18pm',
    name: '12-18',
    icon: morningIcon,
  },
  { title: 'Evening', time: '18pm-22pm', name: '18-22', icon: eveningIcon },
]

const applicantAvailabilityTimes = [
  { time: '9am-12pm', name: '9-12', icon: noonIcon },
  {
    time: '12pm-3pm',
    name: '12-15',
    icon: noonIcon,
  },
  { time: '3pm-6pm', name: '15-18', icon: noonIcon },
  {
    time: '6pm-9pm',
    name: '18-21',
    icon: morningIcon,
  },
  {
    time: '9pm-12am',
    name: '21-24',
    icon: eveningIcon,
  },
]

function OrderInfo() {
  const { id, interviewId } = useParams()
  const dispatch = useDispatch()
  const interview = useSelector(interviewSelector.interview)
  const agency = useSelector(agencySelectors.data)
  const internalExperts = agency.internal_experts

  const [candidateAvailableAt, setCandidateAvailableAt] = useState(
    (get(interview, 'interview.candidate_available_at', []) as number[]).map(
      (v) => v * 1000
    )
  )
  const [availabilityDays, setAvailabilityDays] = useState<IInterviewAvailabilityDays>(
    get(interview, 'interview.availability_days', {})
  )
  const [availabilityTime, setAvailabilityTime] = useState<IInterviewAvailabilityTime>(
    get(interview, 'interview.availability_time', {})
  )

  const [showCalendar, setShowCalendar] = useState(false)

  const agreeToShare: boolean = get(interview, 'interview.agree_to_share', '')

  const interviewInternalExperts: IInterviewInternalExpert[] =
    interview?.interview.interview_internal_experts

  const agreeTermsAndPrivacy: boolean = get(
    interview,
    'interview.agree_terms_and_privacy',
    ''
  )
  const agreeToReceiveOffers: boolean = get(
    interview,
    'interview.agree_to_receive_offers',
    ''
  )

  const timeZone: string = get(interview, 'candidate_profile.time_zone', '')

  const getPrice = () => {
    if (agency.business_model === BusinessModel.B2C) {
      if (interview.interview_price_tiers) {
        return interview.interview_price_tiers
          .map((item) => {
            const priceTier = agency.agency_price_tiers.find(
              (itm) => itm.id === item.agency_price_tier_id
            )
            if (!priceTier) return ''
            return `${agency.currency_sign}${priceTier.price_min} - ${agency.currency_sign}${priceTier.price_max} ${priceTier.name}`
          })
          .join('\n')
      }
      return ''
    }

    return `${agency.currency_sign}${agency.session_price}`
  }

  const formDefaultValues = {
    position: {
      name: get(interview, 'interview.desired_position', '') as string,
      company: get(interview, 'interview.position_company', '') as string,
      location: get(interview, 'interview.position_location', '') as string,
      programmingLanguage: get(interview, 'interview.programming_language', '') as string,
      link: get(interview, 'interview.link_to_position', '') as string,
      description: get(interview, 'interview.position_description', '') as string,
      date: normalizeDate(get(interview, 'interview.interview_date', '') as string),
      requestApplicant: get(interview, 'interview.special_request', '') as string,
      request: get(interview, 'interview.special_request_by_admin', '') as string,
      coachingLanguage: get(interview, 'interview.coaching_language', '') as string,
      coachingType: get(interview, 'interview.coaching_type', '') as string,
    },
    student: {
      firstName: get(interview, 'candidate_profile.first_name', '') as string,
      lastName: get(interview, 'candidate_profile.last_name', '') as string,
      email: get(interview, 'candidate_profile.email', '') as string,
      linkedin: get(interview, 'candidate_profile.linkedin_profile', '') as string,
      timeZone: moment.tz.zone(timeZone) ? timeZone : '',
    },
    notSave: {
      orderNumber: get(interview, 'interview.order_number', '') as number,
      candidate_invited_at: get(
        interview,
        'interview.candidate_invited_at',
        ''
      ) as string,
      matching_at: get(interview, 'interview.matching_at', '') as string,

      experts_invited_at: get(interview, 'interview.experts_invited_at', '') as string,
      experts_confirmed_at: get(
        interview,
        'interview.experts_confirmed_at',
        ''
      ) as string,

      matched_at: get(interview, 'interview.matched_at', '') as string,
      scheduled_at: get(interview, 'interview.scheduled_at', '') as string,
      started_at: get(interview, 'interview.started_at', 'N/A') as string,
      ended_at: get(interview, 'interview.ended_at', 'N/A') as string,
      scheduled_time: get(interview, 'interview.scheduled_time', '') as string,
      report_provided_at: get(interview, 'interview.report_provided_at', '') as string,
      completed_at: get(interview, 'interview.completed_at', '') as string,
      agree_terms_and_privacy: agreeTermsAndPrivacy,
      agree_to_share: agreeToShare,
      agree_to_receive_offers: agreeToReceiveOffers,
      created_by_name: get(interview, 'created_by.name') as string,
      business_model: get(agency, 'business_model') as string,
      price: getPrice(),
      incomplete_at: (interview?.interview?.interview_missed_times || [])
        .map((t) => {
          let str = ''
          if (t.candidate_missed && t.expert_missed) str += 'Candidate/Expert'
          else str += t.candidate_missed ? 'Candidate' : 'Expert'

          return `${str} no-show ${formattedDate(t.scheduled_time)}`
        })
        .join('\n'),
      meta_data: Object.entries(get(interview, 'interview.meta_data', {}) ?? {})
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n'),
      sponsored_session_available: get(
        interview,
        'interview.sponsored_session_available',
        ''
      ) as string,
    },
    codingSessionUrl: get(interview, 'interview.coding_session_url', '') as string,
    codingPlaybackUrl: get(interview, 'interview.coding_playback_url', '') as string,
    internalCoach: [],
  }

  const { handleSubmit, control, errors, setValue, watch } = useForm({
    mode: 'all',
    defaultValues: formDefaultValues,
  })
  const coachingType = watch('position.coachingType') as CoachingType

  const internalExpertsOptions = useMemo(
    () =>
      (internalExperts || [])
        .filter(
          (ie) =>
            ie.coaching_types.includes(coachingType) ||
            (interviewInternalExperts || []).some(
              (iie) => iie.internal_expert_id === ie.id
            )
        )
        .map((e) => ({ label: e.name, value: e }))
        .concat(
          (interviewInternalExperts || [])
            .filter((iie) => !iie.internal_expert_id)
            .map((iie) => ({ label: iie.name, value: undefined }))
        )
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [coachingType, internalExperts, interviewInternalExperts]
  )

  const selectedInternalExperts = useMemo(
    () =>
      internalExpertsOptions.filter((ieo) =>
        ieo.value
          ? (interviewInternalExperts || []).some(
              (iie) => iie.internal_expert_id === ieo.value.id
            )
          : (interviewInternalExperts || []).some(
              (iie) => !iie.internal_expert_id && iie.name === ieo.label
            )
      ),
    [internalExpertsOptions, interviewInternalExperts]
  )

  useEffect(() => {
    setValue('internalCoach', selectedInternalExperts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInternalExperts])

  const updateBookingError = useSelector(updateOrderInfoSelectors.error)
  const updateBookingIsLoading = useSelector(updateOrderInfoSelectors.isLoading)

  const getPriceTearsRowCount = () => {
    if (interview.interview_price_tiers?.length > 1) {
      return interview.interview_price_tiers?.length
    }
    return 1
  }

  const onSubmit = (payload) => {
    const codingSessionUrl: string = get(payload, 'codingSessionUrl', '')
    const codingPlaybackUrl: string = get(payload, 'codingPlaybackUrl', '')
    const cvFileId: string = get(payload, 'cv_file_id', '')
    const date = get(payload, 'position.date', '')
    let newDate = ''
    if (isDate(date)) {
      newDate = date.toString()
    } else if (isNumber(date)) {
      newDate = new Date(date).toString()
    }

    const student: IInterviewCandidateState = get(payload, 'student', {})
    student.candidateAvailableAt = candidateAvailableAt
    student.availabilityDays = availabilityDays
    student.availabilityTime = availabilityTime
    const position: IInterviewPositionState = {
      ...get(payload, 'position', {}),
      date: newDate,
    }

    const selectedIE: Array<{ label: string; value?: IInternalExpert }> =
      payload?.internalCoach || []

    const prevInterviewIE = interviewInternalExperts.map((p) => {
      const isSelected = selectedIE.some((ie) =>
        p.internal_expert_id ? p.internal_expert_id === ie.value?.id : p.name === ie.label
      )
      return isSelected ? p : { ...p, _destroy: true }
    })

    const interview_internal_experts_attributes = prevInterviewIE.concat(
      selectedIE
        .filter(
          (ie) =>
            !interviewInternalExperts.some((p) =>
              ie.value ? p.internal_expert_id === ie.value?.id : p.name === ie.label
            )
        )
        .map((ie) => ({ name: ie.label, internal_expert_id: ie.value?.id }))
    )

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      'Are you sure you want to update Session Report info?'
    )

    if (confirmed) {
      dispatch(
        updateOrderInfoAction({
          agencyId: id,
          id: interviewId,
          student,
          position,
          codingSessionUrl,
          codingPlaybackUrl,
          cv_file_id: cvFileId,
          interview_internal_experts_attributes,
        })
      )
    }
  }

  useEffect(() => {
    return () => {
      dispatch(updateOrderInfoActionSuccessClear())
    }
  }, [dispatch])

  const clearErrorUpdate = () => {
    dispatch(updateOrderInfoActionFailureClear())
  }

  const isSuperAdmin = isSuperAdminRole()
  const interviewDateOption =
    interview.interview.interview_date_option === 'Confirm date'
      ? format(
          new Date(normalizeDate(get(interview, 'interview.interview_date', ''))),
          'MM/dd/yyyy'
        )
      : interview.interview.interview_date_option
  const languageList = Languages.map((l) => {
    return { value: l, label: l }
  })

  const newApplicantAvailabilityFlow = get(
    interview,
    'interview.use_new_applicant_availability_flow',
    false
  )

  const weekOptionChecked =
    availabilityDays && Object.values(availabilityDays).find((v) => !!v)
  const timeOptionChecked =
    availabilityTime && Object.values(availabilityTime).find((v) => !!v)
  const canSubmit =
    candidateAvailableAt?.length > 0 || (weekOptionChecked && timeOptionChecked)

  const hasSpecificSlots = candidateAvailableAt?.length > 0

  return (
    <>
      {updateBookingIsLoading && <Loader />}
      <div className="ql-editor">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormsWrapper>
            <FormWrapper>
              <FormTitle>Coaching session</FormTitle>
              <BlockWrapper>
                <Row>
                  <Controller
                    as={Input}
                    label="Session ID"
                    disabled
                    name="notSave.orderNumber"
                    control={control}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Input}
                    label="Agency Admin"
                    disabled
                    name="notSave.created_by_name"
                    control={control}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                {agency.has_coaching_types && (
                  <Row>
                    <Controller
                      name="position.coachingType"
                      control={control}
                      render={({ onChange, value, name: selectName }) => (
                        <Select
                          name={selectName}
                          label="IE Coaching type"
                          onChange={onChange}
                          value={value ?? ''}
                          options={AgencyCoachingTypes}
                          error={get(errors, 'position.coachingType', {})}
                          marginBottom={20}
                        />
                      )}
                      defaultValue={CoachingType.MOCK_INTERVIEW}
                    />
                  </Row>
                )}
                <Row>
                  <Controller
                    as={Input}
                    label="Desired position"
                    name="position.name"
                    control={control}
                    error={get(errors, 'position.name', {})}
                    rules={{
                      maxLength: { value: 500, message: 'Max characters : 500' },
                    }}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Input}
                    label="Programming language"
                    name="position.programmingLanguage"
                    control={control}
                    error={get(errors, 'position.programmingLanguage', {})}
                    rules={{
                      maxLength: { value: 50, message: 'Max characters : 50' },
                    }}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Textarea}
                    label="Special request from applicant"
                    name="position.requestApplicant"
                    control={control}
                    error={get(errors, 'position.requestApplicant', {})}
                    rules={{
                      maxLength: { value: 2000, message: 'Max characters : 2000' },
                    }}
                    useTheme
                    defaultValue={interview.interview.special_request || ''}
                  />
                </Row>
                <Row>
                  <Controller
                    as={Textarea}
                    label="Comment from the organization admin"
                    name="position.request"
                    control={control}
                    error={get(errors, 'position.request', {})}
                    rules={{
                      maxLength: { value: 2000, message: 'Max characters : 2000' },
                    }}
                    useTheme
                    defaultValue={interview.interview.special_request_by_admin || ''}
                  />
                </Row>
                <Row>
                  <Controller
                    name="position.coachingLanguage"
                    control={control}
                    // rules={{ required: true }}
                    render={({ onChange, value, name: selectName }) => (
                      <Select
                        name={selectName}
                        label="Coaching language"
                        onChange={onChange}
                        value={value ?? ' '}
                        options={languageList}
                        useTheme
                        error={get(errors, 'position.coachingLanguage', {})}
                      />
                    )}
                    defaultValue=""
                    useTheme
                  />
                </Row>
              </BlockWrapper>

              <FormTitle>Upcoming interview</FormTitle>
              <BlockWrapper>
                <Row>
                  <Controller
                    as={Input}
                    label="Company"
                    name="position.company"
                    control={control}
                    error={get(errors, 'position.company', {})}
                    rules={{
                      maxLength: { value: 50, message: 'Max characters : 50' },
                    }}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ onChange, value }) => {
                      return (
                        <CountryDropdownContainerStyled>
                          <CountryDropdownStyled
                            useTheme
                            defaultOptionLabel="Position location"
                            value={value}
                            onChange={onChange}
                          />
                        </CountryDropdownContainerStyled>
                      )
                    }}
                    label="Location"
                    name="position.location"
                    useTheme
                    control={control}
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ onChange, value }) => (
                      <DatePicker
                        onChange={onChange}
                        value={value}
                        useTheme
                        shouldCloseOnSelect
                      />
                    )}
                    name="position.date"
                    control={control}
                    defaultValue=""
                  />
                </Row>
                {isSuperAdmin && interviewDateOption && (
                  <Row>
                    <Controller
                      render={() => (
                        <Input
                          label="Job interview date (agency admin)"
                          value={interviewDateOption}
                          useTheme
                          disabled
                        />
                      )}
                      label="Job interview date (agency admin)"
                      name="notSave.started_at"
                      control={control}
                      defaultValue="N/A"
                      disabled
                    />
                  </Row>
                )}

                <Row>
                  <Controller
                    as={Input}
                    label="Link to position"
                    disableWhitespaces
                    name="position.link"
                    control={control}
                    error={get(errors, 'position.link', {})}
                    rules={{
                      validate: {
                        valid: (value) =>
                          value
                            ? linkRegex.test(value) ||
                              "That format doesn't look right. Make sure there aren't any typos"
                            : true,
                      },
                    }}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Textarea}
                    label="Position description"
                    name="position.description"
                    control={control}
                    error={get(errors, 'position.description', {})}
                    rules={{
                      maxLength: { value: 2000, message: 'Max characters : 2000' },
                    }}
                    useTheme
                    defaultValue=""
                  />
                </Row>
              </BlockWrapper>

              <FormTitle>Pricing info</FormTitle>
              <BlockWrapper>
                <Row>
                  <Controller
                    as={Input}
                    label="Pricing model"
                    name="notSave.business_model"
                    control={control}
                    disabled
                    useTheme
                    defaultValue="N/A"
                  />
                </Row>
                <Row>
                  <Controller
                    as={Textarea}
                    rows={getPriceTearsRowCount()}
                    label="Session price"
                    name="notSave.price"
                    control={control}
                    disabled
                    useTheme
                    defaultValue="N/A"
                  />
                </Row>
              </BlockWrapper>

              <FormTitle>Code-sharing</FormTitle>
              <BlockWrapper>
                <Row>
                  <Controller
                    as={Input}
                    label="Coding session URL"
                    name="codingSessionUrl"
                    control={control}
                    error={get(errors, 'codingSessionUrl', {})}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Input}
                    label="Coding playback URL"
                    name="codingPlaybackUrl"
                    control={control}
                    error={get(errors, 'codingPlaybackUrl', {})}
                    useTheme
                    defaultValue=""
                  />
                </Row>
              </BlockWrapper>

              {internalExpertsOptions?.length > 0 && (
                <>
                  <FormTitle>Internal Expert Matching</FormTitle>
                  <BlockWrapper>
                    <Controller
                      as={Autocomplete}
                      multiple
                      label="Manually match an internal coach for the session"
                      name="internalCoach"
                      error={errors.internalCoach}
                      options={internalExpertsOptions}
                      control={control}
                      customOnchange={() => {}}
                      defaultValue={[]}
                    />
                  </BlockWrapper>
                </>
              )}
            </FormWrapper>

            <FormWrapper>
              <FormTitle>Applicant details</FormTitle>
              <BlockWrapper>
                <Row>
                  <Controller
                    as={Input}
                    label="First Name"
                    name="student.firstName"
                    control={control}
                    error={get(errors, 'student.firstName', {})}
                    rules={{
                      required: true,
                      maxLength: { value: 50, message: 'Max characters : 50' },
                    }}
                    useTheme
                    defaultValue=""
                  />
                  <Controller
                    as={Input}
                    label="Last Name"
                    name="student.lastName"
                    control={control}
                    error={get(errors, 'student.lastName', {})}
                    rules={{
                      required: true,
                      maxLength: { value: 50, message: 'Max characters : 50' },
                    }}
                    useTheme
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Input}
                    label="Email"
                    name="student.email"
                    disableWhitespaces
                    useTheme
                    control={control}
                    error={get(errors, 'student.email', {})}
                    rules={{
                      validate: {
                        valid: (value) =>
                          EmailRegex.test(value) ||
                          "That format doesn't look right. Make sure there aren't any typos",
                      },
                    }}
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    as={Input}
                    label="LinkedIn profile"
                    name="student.linkedin"
                    disableWhitespaces
                    useTheme
                    optional
                    control={control}
                    error={get(errors, 'student.linkedin', {})}
                    rules={{
                      validate: {
                        valid: (value) =>
                          value
                            ? linkedinLinkRegex.test(value) ||
                              "That format doesn't look right. Make sure there aren't any typos"
                            : true,
                      },
                    }}
                    defaultValue=""
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ onChange }) => (
                      <RowWrapper>
                        <FileUploadView
                          onUploaded={(fileID) => onChange(fileID)}
                          file={interview.cv_file}
                          useLink
                        />
                      </RowWrapper>
                    )}
                    defaultValue={interview.cv_file?.id ?? ''}
                    name="cv_file_id"
                    control={control}
                  />
                </Row>
                <Row>
                  <Controller
                    as={Input}
                    label="Time Zone"
                    name="student.timeZone"
                    control={control}
                    useTheme
                    defaultValue=""
                    disabled
                  />
                </Row>
              </BlockWrapper>

              <LineContainer>
                <FormTitle>Applicant Availability</FormTitle>
                <LinkButton useTheme onClick={() => setShowCalendar(true)}>
                  Edit
                </LinkButton>
              </LineContainer>
              {newApplicantAvailabilityFlow && !hasSpecificSlots && (
                <BlockWrapper>
                  <AvailabilityDaysRowWrap>
                    {applicantAvailabilityDays.map((item) => (
                      <AvailabilityDaysCheckbox
                        onBlur={null}
                        hideCheckBox
                        onChange={(e) => {
                          setAvailabilityDays((v) => {
                            const newV = {
                              ...v,
                              [item.name]: e.target.checked,
                            }
                            return newV
                          })
                        }}
                        name={item.name}
                        useTheme
                        checked={get(availabilityDays, item.name, false)}
                        title={item.title}
                      />
                    ))}
                  </AvailabilityDaysRowWrap>
                  <AvailabilityTimeRowWrap>
                    {applicantAvailabilityTimes.map((item) => (
                      <AvailabilityTimeCheckbox
                        onBlur={null}
                        hideCheckBox
                        onChange={(e) =>
                          setAvailabilityTime((v) => {
                            const newV = {
                              ...v,
                              [item.name]: e.target.checked,
                            }
                            return newV
                          })
                        }
                        name={item.name}
                        useTheme
                        checked={get(availabilityTime, item.name, false)}
                        icon={morningIcon}
                        time={item.time}
                      />
                    ))}
                  </AvailabilityTimeRowWrap>
                </BlockWrapper>
              )}
              {!newApplicantAvailabilityFlow && !hasSpecificSlots && (
                <BlockWrapper>
                  <AvailabilityDaysRow>
                    {applicantAvailabilityDayGropus.map((item) => (
                      <AvailabilityDaysCheckbox
                        onBlur={null}
                        onChange={(e) => {
                          setAvailabilityDays((v) => {
                            const newV = {
                              ...v,
                              [item.name]: e.target.checked,
                            }
                            return newV
                          })
                        }}
                        name={item.name}
                        useTheme
                        checked={get(availabilityDays, item.name, false)}
                        title={item.title}
                      />
                    ))}
                  </AvailabilityDaysRow>
                  <AvailabilityTimeRow>
                    {applicantAvailabilityTimesOld.map((item) => (
                      <AvailabilityTimeCheckbox
                        onBlur={null}
                        onChange={(e) =>
                          setAvailabilityTime((v) => {
                            const newV = {
                              ...v,
                              [item.name]: e.target.checked,
                            }
                            return newV
                          })
                        }
                        name={item.title}
                        useTheme
                        checked={get(availabilityTime, item.name, false)}
                        icon={morningIcon}
                        time={item.time}
                        title={item.title}
                      />
                    ))}
                  </AvailabilityTimeRow>
                </BlockWrapper>
              )}
              {hasSpecificSlots && (
                <BlockWrapperTransparent>
                  <LineContainer>
                    <TimezoneLabel>
                      {timeZone} ({moment.tz(timeZone).format('HH:mm')})
                    </TimezoneLabel>
                  </LineContainer>
                  <SlotsContainer>
                    {candidateAvailableAt.map((t) => {
                      const tDate = moment(t).tz(timeZone)
                      return (
                        <SlotContainer key={t}>
                          <span>{tDate.format('DD/MM')}</span>, {tDate.format('ddd')} |{' '}
                          {tDate.format('HH:mm')}
                        </SlotContainer>
                      )
                    })}
                  </SlotsContainer>
                </BlockWrapperTransparent>
              )}
              <FormTitle>Booking Info</FormTitle>
              <BlockWrapper>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Invited"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Invited"
                    name="notSave.candidate_invited_at"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Order form submitted"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Order form submitted"
                    name="notSave.matching_at"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                {isSuperAdmin && (
                  <>
                    <Row>
                      <Controller
                        render={({ value, name }) => (
                          <Input
                            label="Pending expert availability"
                            name={name}
                            value={formattedDate(value)}
                            useTheme
                            disabled
                          />
                        )}
                        label="Pending expert availability"
                        name="notSave.experts_invited_at"
                        control={control}
                        defaultValue="N/A"
                        disabled
                      />
                    </Row>
                    <Row>
                      <Controller
                        render={({ value, name }) => (
                          <Input
                            label="Expert availability provided"
                            name={name}
                            value={formattedDate(value)}
                            useTheme
                            disabled
                          />
                        )}
                        label="Expert availability provided"
                        name="notSave.experts_confirmed_at"
                        control={control}
                        defaultValue="N/A"
                        disabled
                      />
                    </Row>
                  </>
                )}
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Matched"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Matched"
                    name="notSave.matched_at"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Scheduled on"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Scheduled on"
                    name="notSave.scheduled_at"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Coaching (scheduled date)"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Coaching (scheduled date)"
                    name="notSave.scheduled_time"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Session Start Time"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Session Start Time"
                    name="notSave.started_at"
                    control={control}
                    // defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Session End Time"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Session Start Time"
                    name="notSave.ended_at"
                    control={control}
                    // defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Report submitted"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Report submitted"
                    name="notSave.report_provided_at"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    render={({ value, name }) => (
                      <Input
                        label="Report published"
                        name={name}
                        value={formattedDate(value)}
                        useTheme
                        disabled
                      />
                    )}
                    label="Report published"
                    name="notSave.completed_at"
                    control={control}
                    defaultValue="N/A"
                    disabled
                  />
                </Row>
                <Row>
                  <Controller
                    as={Textarea}
                    label="Incomplete"
                    name="notSave.incomplete_at"
                    control={control}
                    disabled
                    useTheme
                    defaultValue=""
                  />
                </Row>
              </BlockWrapper>

              {formDefaultValues.notSave.created_by_name === 'Public API' && (
                <BlockWrapper>
                  <FormTitle>Public API</FormTitle>
                  <Row>
                    <Controller
                      as={Textarea}
                      label="Metadata"
                      name="notSave.meta_data"
                      control={control}
                      disabled
                      useTheme
                      defaultValue="N/A"
                    />
                  </Row>
                  <Row>
                    <Controller
                      render={({ value, name }) => (
                        <Input
                          label="Sponsored sessions available"
                          name={name}
                          value={value || 0}
                          useTheme
                          disabled
                        />
                      )}
                      label="Sponsored sessions available"
                      name="notSave.sponsored_session_available"
                      control={control}
                      disabled
                      useTheme
                      defaultValue=""
                    />
                  </Row>
                </BlockWrapper>
              )}
            </FormWrapper>
          </FormsWrapper>

          <LineWarpper>
            <>
              <CandidateConsent>
                <FormTitle>Candidate consent</FormTitle>
                <ConsentItem>
                  <Controller
                    as={Checkbox}
                    name="notSave.agree_terms_and_privacy"
                    control={control}
                    disabled
                    useTheme
                    defaultChecked={agreeTermsAndPrivacy}
                  >
                    Agree to terms of Use and Privacy policy
                  </Controller>
                  <Controller
                    as={Checkbox}
                    name="notSave.agree_to_share"
                    control={control}
                    disabled
                    useTheme
                    defaultChecked={agreeToShare}
                  >
                    Agree to share coaching session info with career center
                  </Controller>
                  <Controller
                    as={Checkbox}
                    name="notSave.agree_to_receive_offers"
                    control={control}
                    disabled
                    useTheme
                    defaultChecked={agreeToReceiveOffers}
                  >
                    Agree to get marketing communications
                  </Controller>
                  <ConsentSelection isChecked={false} />
                </ConsentItem>
              </CandidateConsent>
              {interview?.interview?.email_timestamps && (
                <EmailReminders>
                  <FormTitle>Invite follow-up emails</FormTitle>
                  <BulkInviteNotification
                    label="Invite email"
                    notification={
                      interview.interview.email_timestamps.candidate_invited_at
                    }
                  />
                  <BulkInviteNotification
                    label="1st follow up"
                    notification={
                      interview.interview.email_timestamps.first_bulk_reminder_at
                    }
                  />
                  <BulkInviteNotification
                    label="2nd follow up"
                    notification={
                      interview.interview.email_timestamps.second_bulk_reminder_at
                    }
                  />
                  <BulkInviteNotification
                    label="3rd follow up"
                    notification={
                      interview.interview.email_timestamps.third_bulk_reminder_at
                    }
                  />
                  <BulkInviteNotification
                    label="4th follow up"
                    notification={
                      interview.interview.email_timestamps.fourth_bulk_reminder_at
                    }
                  />
                </EmailReminders>
              )}
            </>
          </LineWarpper>

          {updateBookingError && (
            <Notification onClose={clearErrorUpdate} message={updateBookingError} />
          )}
          <ButtonsWrapper>
            <ButtonStyled
              useTheme
              disabled={!canSubmit}
              type="submit"
              title="Update booking info"
            />
          </ButtonsWrapper>
        </Form>
        {showCalendar && (
          <ApplicantAvailabilityPopup
            preSelectedDays={candidateAvailableAt}
            preSelectedAailabilityDays={availabilityDays}
            preSelectedAvailabilityTime={availabilityTime}
            onClose={() => setShowCalendar(false)}
            onSave={(v) => {
              setShowCalendar(false)
              setAvailabilityDays(v.availabilityDays)
              setAvailabilityTime(v.availabilityTime)
              setCandidateAvailableAt(v.selectedDays ?? [])
            }}
          />
        )}
      </div>
    </>
  )
}

export default OrderInfo
