import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgTrash({ fill }: SvgProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7293 7.54379H16.2093C15.602 6.01296 14.0631 5 12.3447 5C10.6264 5 9.08747 6.01296 8.48011 7.54379H4.96011C4.62024 7.54379 4.34473 7.80618 4.34473 8.12985C4.34473 8.45353 4.62024 8.71592 4.96011 8.71592H5.37037V20.8279C5.37037 21.4752 5.9214 22 6.60114 22H17.6781C18.3578 22 18.9088 21.4752 18.9088 20.8279V8.71592H19.7293C20.0692 8.71592 20.3447 8.45353 20.3447 8.12985C20.3447 7.80618 20.0692 7.54379 19.7293 7.54379V7.54379ZM12.3447 6.17631C13.4647 6.17631 14.437 6.56702 14.917 7.54379H9.77242C10.2524 6.56311 11.2247 6.17631 12.3447 6.17631ZM17.6781 20.8279H16.037V14.1858C16.037 13.8622 15.7615 13.5998 15.4216 13.5998C15.0818 13.5998 14.8063 13.8622 14.8063 14.1858V20.8279H12.755V11.8416C12.755 11.5179 12.4795 11.2555 12.1396 11.2555C11.7997 11.2555 11.5242 11.5179 11.5242 11.8416V20.8279H9.47293V14.1858C9.47293 13.8622 9.19741 13.5998 8.85755 13.5998C8.51768 13.5998 8.24216 13.8622 8.24216 14.1858V20.8279H6.60114V8.71592H17.6781V20.8279Z"
        fill={fill || '#fff'}
      />
    </svg>
  )
}

export default SvgTrash
