import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getAuthorizationToken } from 'src/utils/jwtToken'

// eslint-disable-next-line
function AuthRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line
      {...rest}
      render={({ location }) =>
        !getAuthorizationToken() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/agencies',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default AuthRoute
