import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { updateAvaStepAction } from 'src/redux/data/ava/step'
import { css } from '@emotion/core'
import { isMobileOnly } from 'react-device-detect'
import LandingHeader from 'src/components/LandingHeader'
import * as interviewSelectors from 'src/interview/selectors'
import { avaCreateInterviewAction } from 'src/redux'
import { AvaStep } from '../models'
import LandingAvaCoachBlock from '../components/landing/LandingAvaCoachBlock'
import LandingPersonalizeBlock from '../components/landing/LandingPersonalizeBlock'
import LandingInsightsBlock from '../components/landing/LandingInsightsBlock'
import LandingNumbersBlock from '../components/landing/LandingNumbersBlock'
import LandingReviewsBlock from '../components/landing/LandingReviewsBlock'
import LandingFAQBlock from '../components/landing/LandingFAQBlock'
import LandingFooterBlock from '../components/landing/LandingFooterBlock'
// import RotatePhoneComponent from '../components/elements/RotatePhoneComponent'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  min-width: 600px;
  overflow-y: auto;
  background: #f5f5f7;

  ${isMobileOnly &&
  css`
    position: fixed;
    display: flex;
    flex-direction: column;
    background: #f6f4fd;
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
  `}
`

const DataContainer = styled.div`
  padding: 0 60px;

  @media screen and (max-width: 1439px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 899px) {
    padding: 0 24px;
  }

  ${isMobileOnly &&
  css`
    padding: 0 16px !important;
  `}
`

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 100;
  background: white;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
`

const LandingContainer = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { interviewToken } = useParams()
  const agency = useSelector(interviewSelectors.agency)

  const runOnboarding = () => {
    if (interviewToken) {
      dispatch(updateAvaStepAction(AvaStep.Onboarding))
    } else {
      dispatch(
        avaCreateInterviewAction({
          agencyId: agency.id,
          onCreated: (token) => history.push(`/ava/${token}?sl=1`),
        })
      )
    }
  }

  const logo = agency?.whitelisted && agency?.company_logo?.url

  return (
    <Layout id="landing-root">
      <HeaderWrapper>
        <LandingHeader
          onStart={runOnboarding}
          startButtonText="Start practicing"
          showLoginButton={agency.display_topbar_footer ?? false}
          showMenu={agency.display_topbar_footer ?? false}
          logo={logo}
        />
      </HeaderWrapper>
      <DataContainer>
        <LandingAvaCoachBlock onStart={runOnboarding} />
        <LandingPersonalizeBlock onStart={runOnboarding} />
        <LandingInsightsBlock onStart={runOnboarding} />
        <LandingNumbersBlock />
        <LandingReviewsBlock />
        <LandingFAQBlock />
        <LandingFooterBlock onStart={runOnboarding} />
      </DataContainer>
    </Layout>
  )
}

export default LandingContainer
