import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import DatePicker from 'react-datepicker'
// import TimezoneSelect from 'react-timezone-select'
import { subDays } from 'date-fns'
import { get } from 'lodash'
import moment from 'moment-timezone'
import ReactSelect from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import '../datezonepicker.css'
import Input from './DatePickerInput'

interface IContainerProps {
  readOnly?: boolean
}

interface IDatePicker {
  value: any
  label?: string
  showTimeSelect?: boolean
  readOnly?: boolean
  interviewDuration?: any
  showWarning?: boolean
  onChange: (date: Date) => void
  onChangeZone: (from: any, to: any, tz: any) => void
}

const Container = styled.div<IContainerProps>`
  width: 100%;
  position: relative;
  input {
    background-color: ${(props) => (props.readOnly ? '#F5F6F8' : '#fff')};
    cursor: ${(props) => (props.readOnly ? 'not-allowed' : 'pointer')};
  }

  > div {
    > div > div {
      margin-bottom: 20px;
    }
  }
`

const TimeZoneWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  order: 1;
`

const WarningLabel = styled.div`
  position: absolute;
  left: 4px;
  bottom: 0;
  color: orange;
  font-size: 10px;
`

const today = new Date()

function DateZonePickerComponent(props: IDatePicker) {
  const {
    value,
    onChange,
    label,
    showTimeSelect,
    onChangeZone,
    readOnly,
    interviewDuration,
    showWarning,
  } = props
  const apiDate = value && new Date(value)
  const [selectedTime, setSelectedTime] = useState()
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    value: moment.tz.guess(),
    label: moment.tz.guess(),
  })

  const handleChangeTime = useCallback(
    (val, zone) => {
      if (selectedTimeZone) {
        const isCorrectZone =
          zone &&
          Object.prototype.hasOwnProperty.call(zone, 'label') &&
          Object.prototype.hasOwnProperty.call(zone, 'value')
        const tz = isCorrectZone
          ? get(zone, 'value', '')
          : get(selectedTimeZone, 'value', '')

        const currentOffset = val.getTimezoneOffset()
        const selectedTimeZoneOffset = moment(val).tz(tz).zone()

        const tzDifferenceFrom = selectedTimeZoneOffset - currentOffset
        const tzDifferenceTo = selectedTimeZoneOffset - currentOffset + interviewDuration

        const formattedForApiTimeFrom = moment
          .utc(val)
          .add(tzDifferenceFrom, 'minutes')
          .format()
        const formattedForApiTimeTo = moment
          .utc(val)
          .add(tzDifferenceTo, 'minutes')
          .format()

        onChangeZone(formattedForApiTimeFrom, formattedForApiTimeTo, tz)
      }

      setSelectedTime(val)
      onChange(val)
    },
    [selectedTimeZone, interviewDuration, onChange, onChangeZone]
  )

  const handleUpdateZone = useCallback(
    (tz) => {
      setSelectedTimeZone(tz)
      if (selectedTime || apiDate) {
        handleChangeTime(selectedTime || apiDate, tz)
      }
    },
    [selectedTime, apiDate, handleChangeTime]
  )

  const options = moment.tz
    .names()
    .map((i) => ({ label: i, value: i }))
    .filter((z) => z.value !== 'US/Pacific-New')

  return (
    <Container readOnly={readOnly}>
      <DatePicker
        popoverAttachment="bottom right"
        popoverTargetAttachment="top right"
        popperPlacement="top-start"
        adjustDateOnChange
        timeIntervals={15}
        selected={value}
        showTimeSelect={showTimeSelect}
        customInput={<Input customLabel={label} />}
        onChange={handleChangeTime}
        dayClassName={() => 'datezonepicker-day'}
        className="datezonepicker-input"
        calendarClassName="datezonepicker-component"
        highlightDates={[today]}
        // shouldCloseOnSelect={false}
        dateFormat="MM/dd/yyyy h:mm aa"
        readOnly={readOnly}
        filterDate={(date: string) => {
          return Date.parse(date) - subDays(today, 1).valueOf() >= 0
        }}
        fixedHeight
      >
        <TimeZoneWrapper>
          {/* <TimezoneSelect
            placeholder="Select timezone"
            value={selectedTimeZone}
            onChange={handleUpdateZone}
          /> */}
          <ReactSelect
            name="Select timezone"
            value={selectedTimeZone}
            onChange={handleUpdateZone}
            options={options}
          />
        </TimeZoneWrapper>
      </DatePicker>
      {showWarning && (
        <WarningLabel>
          ⓘ This time slot falls outside the applicant&apos;s availability
        </WarningLabel>
      )}
    </Container>
  )
}

DateZonePickerComponent.defaultProps = {
  label: '',
  showTimeSelect: true,
  readOnly: false,
  interviewDuration: 60,
  showWarning: false,
}

export default DateZonePickerComponent
