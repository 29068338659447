/* eslint-disable no-console */
import styled from '@emotion/styled'
import React, { useState } from 'react'
import httpClient from 'src/httpClient'

const Container = styled.div`
  width: 500px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
`

const Label = styled.div`
  font-size: 16px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`

const TextArea = styled.textarea`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  height: 100px;
`

const Button = styled.div`
  background: #b202ff;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  width: fit-content;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
`

const AvaSpeechDataGenerator = () => {
  const [inputText, setInputText] = useState('')
  const [resultText, setResultText] = useState('')
  const [loading, setLoading] = useState(false)

  const loadSpeechData = (text: string) => {
    const txt = text.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    setLoading(true)
    httpClient
      .post(`/ava/synthesize_voice`, { text: txt }, { responseType: 'arraybuffer' })
      .then((response) => {
        const binaryData = response.data
        const base64ImageString = Buffer.from(binaryData, 'binary').toString('base64')
        setResultText(base64ImageString)
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container>
      <Title>Speech data generator</Title>
      <TextArea
        placeholder="Input"
        onChange={(ev) => setInputText(ev.target.value)}
        value={inputText}
      />
      <LineContainer>
        <Button onClick={() => loadSpeechData(inputText)}>Generate</Button>
        {loading && <Label>Loading...</Label>}
      </LineContainer>
      <TextArea placeholder="Output" value={resultText} />
      <Button onClick={() => navigator.clipboard.writeText(resultText)}>Copy</Button>
    </Container>
  )
}

export default AvaSpeechDataGenerator
