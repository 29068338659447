import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import Draggable from 'react-draggable'
import arrowCollapse from 'src/static/arrow-collapse.svg'

const BaseSmallVideosContainer = styled.div<{
  isExpert: boolean
  canCollapse: boolean
}>`
  background: ${({ canCollapse }) => (canCollapse ? '#1d0e3b' : 'transparent')};
  border-radius: 8px;
  bottom: ${({ isExpert }) => (isExpert ? '2.5rem' : '7vh')};
  box-shadow: 0px 0px 8px rgba(178, 2, 255, 0.2);
  cursor: move;
  display: flex;
  left: 1.5rem;
  padding: ${({ canCollapse }) => (canCollapse ? '12px' : '0px')};
  position: absolute;
`

const SmallVideosWrap = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  overflow: hidden;
  position: relative;
  width: ${({ isCollapsed }) => (isCollapsed ? '25px' : 'fit-content')};

  > div:nth-of-type(2) {
    margin-left: 12px;
  }

  &::after {
    background: linear-gradient(
        270deg,
        #1d0e3b 0%,
        rgba(29, 14, 59, 0.79) 44.67%,
        rgba(0, 0, 0, 0) 100%
      ),
      url(.png);
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: ${({ isCollapsed }) => (isCollapsed ? '50%' : '0px')};
  }
`

const Collapse = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  width: 10px;
`

const CollapseIcon = styled.img<{ isCollapsed: boolean }>`
  transform: ${({ isCollapsed }) => (isCollapsed ? 'scaleX(-1)' : 'none')};
  width: 100%;
`

interface ISmallVideosContainer extends React.HTMLProps<HTMLDivElement> {
  isExpert: boolean
  canCollapse: boolean
}

export function SmallVideosContainer({
  canCollapse,
  isExpert,
  children,
}: ISmallVideosContainer) {
  const nodeRef = React.useRef(null)
  const [collapsed, setCollapsed] = useState(false)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  useEffect(() => {
    if (!canCollapse) setCollapsed(false)
  }, [canCollapse])

  return (
    <Draggable bounds="parent" nodeRef={nodeRef}>
      <BaseSmallVideosContainer
        canCollapse={canCollapse}
        isExpert={isExpert}
        ref={nodeRef}
      >
        <SmallVideosWrap isCollapsed={collapsed}>{children}</SmallVideosWrap>
        {canCollapse && (
          <Collapse onClick={toggleCollapsed}>
            <CollapseIcon isCollapsed={collapsed} src={arrowCollapse} />
          </Collapse>
        )}
      </BaseSmallVideosContainer>
    </Draggable>
  )
}

export const MobileSmallContainer = styled.div`
  position: absolute;
  bottom: 10%;
  left: 16px;

  @media (orientation: landscape) {
    bottom: 24px;
    top: auto;
  }
`

export const MobileSmallSliderContainer = styled.div<{ height: number }>`
  align-items: center;
  display: flex !important;
  height: 100%;
  justify-content: space-evenly;
  outline-style: none;

  @media (orientation: landscape) {
    height: 100%;
    justify-content: center;

    > div {
      margin: 0 7px;
    }
  }
`

export const MobileFullHeight = styled.div`
  height: 100%;
`
