import React from 'react'
import { Route } from 'react-router-dom'
import RecordCall from '../recordcall'

// eslint-disable-next-line
function RecordHashRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line
            {...rest}
      render={({ location }) => {
        return location.hash.includes('#/record') ? <RecordCall /> : children
      }}
    />
  )
}

export default RecordHashRoute
