/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import { Button } from 'src/components'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'

import { isMobileOnly } from 'react-device-detect'
import bgTop from '../../static/landing_top_background_mobile.png'
import bgBottom from '../../static/landing_bottom_background_mobile.png'
import bgProfessionalGuidance from '../../static/guidance_background_mobile.png'
import bgPerson from '../../static/landing-person-background.png'
import bgCoachingSteps from '../../static/ic-coachingsteps-bg.png'
import bgOurExperts from '../../static/our-experts-bg-mobile.png'
import icQuote from '../../static/ic-quote-yellow.png'

export const SliderDotsContainerMobile = withProps()(styled.div`
  margin-top: 12px;
  bottom: unset;
  li {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #e1e3ea;

    button {
      opacity: 0;
    }

    &.slick-active {
      background: ${({ theme }: any) => theme.agencyColor ?? '#B202FF'};
    }
  }
`)

const MiddleContainerMobile = styled.div`
  width: 100%;
  padding: 0 14px;
`

const DetailsTextMobile = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`

export const TopSectionMobile = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${bgTop}) no-repeat;
  background-size: 100% 100%;
`

export const TopLandingContainerMobile = styled.div`
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  font-family: 'Rubik', sans-serif;
`

export const LogoMobile = styled.img<{ isHidden: boolean }>`
  margin: 22px 0 24px 15px;
  height: 35px;
  width: auto;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
`

export const TopInfoContainerMobile = styled.div`
  display: flex;
`

export const TopInfoBlockMobile = styled.div<{ fullWidth?: boolean; showBg?: boolean }>`
  flex: ${({ fullWidth }) => (fullWidth ? '1' : 'none')};
  position: relative;
  ${({ showBg }) =>
    showBg
      ? css`
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background: url(${bgPerson}) no-repeat;
            background-size: 100% 100%;
            width: 190px;
            height: 177px;
          }
        `
      : null}
`
export const TopSectionTextMobile = styled.div<{ isCareerBuilder?: boolean }>`
  font-family: 'Open sans';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isCareerBuilder }) =>
    isCareerBuilder ? (isMobileOnly ? 24 : 28) : 32}px;
  line-height: 150%;
  color: #21304e;
  width: ${({ isCareerBuilder }) => (isCareerBuilder ? 'autp' : '672px')};
  white-space: break-spaces;
  margin: 34px 0 0 -43px;

  > span {
    font-weight: bold;
  }
`

export const PersonImageMobile = styled.img`
  position: relative;
  width: 217px;
  height: 217px;
  object-fit: contain;
  margin-left: -50px;
`

export const TopSectionInfoMobile = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  line-height: 22px;
  color: #3b424f;
  white-space: break-spaces;
  margin-bottom: 24px;
`

export const TopInfoBottomContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: white;
  border-radius: 24px 24px 0px 0px;
  margin-top: -42px;
  z-index: 1;
`

export const BlockTitleMobile = styled(MiddleContainerMobile)<{ alignLeft?: boolean }>`
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #21304e;
  margin: 0 0 12px 0;
  margin: ${({ alignLeft }) => (alignLeft ? '0' : '0 auto')};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
`

export const CoachingStepsBlockMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 46px;
`

export const CoachingStepsInfoMobile = styled(MiddleContainerMobile)`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: black;
  margin-bottom: 24px;
  text-align: center;
`
export const CoachingStepsItemsContainerMobile = styled.div`
  width: 100%;
  background: url(${bgCoachingSteps}) no-repeat;
  background-size: 100%;
  background-position: center;
`

export const CoachingStepsItemContainerMobile = styled.div`
  display: flex !important;
  flex-direction: column;
  background: #b202ff;
  width: 391px;
  height: 370px;
  border-radius: 12px;
  margin: 0 16px;
  position: relative;
  overflow: hidden;
  padding: 29px 0 0 24px;
  &:before {
    content: '';
    width: 330px;
    height: 330px;
    border-radius: 165px;
    background: #bf45f8;
    position: absolute;
    left: 163px;
    bottom: 130px;
    z-index: 1;
  }
`

export const CoachingStepsItemTitleMobile = styled.div`
  font-family: 'Open sans';
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: white;
  margin-bottom: 12px;
  z-index: 2;
`

export const CoachingStepsItemTextMobile = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: white;
  z-index: 2;
  margin-right: 32px;
`

export const HowItWorksBlockMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  padding-bottom: 36px;
`

export const HowItWorksDataContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`

export const HowItWorksItemContainerMobile = styled.div`
  display: flex !important;
  align-items: center;
  flex-direction: column;
  margin: 0 12px;
  border: 1px solid #e1e3ea;
  height: 511px;
  @media (orientation: landscape) {
    height: unset;
  }
`

export const HowItWorksItemAnimationMobile = styled.div`
  width: 208px;
  height: auto;
  margin-bottom: 10px;
  @media (orientation: landscape) {
    margin: 0 auto;
  }
`

export const HowItWorksItemAnimationImgMobile = styled.img`
  height: 350px;
  object-fit: contain;
  width: auto;
`

export const HowItWorksItemTitleMobile = styled.div<{ selected?: boolean }>`
  font-weight: 600;
  font-size: 14px
  line-height: 22px;
  color: #21304e;
  cursor: pointer;
  text-align: center;
  margin-top: 12px;
`

export const HowItWorksItemDataContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (orientation: landscape) {
    flex-direction: row;
  }
`

export const HowItWorksItemTextMobile = styled(DetailsTextMobile)`
  color: black;
  margin: 12px 16px 0px 16px;
  flex: 1;
  text-align: center;
  @media (orientation: landscape) {
    width: 50%;
    flex: unset;
    display: flex;
    align-items: center;
  }
`

export const OurExpertsBlockMobile = styled.div`
  margin-top: 70px;
  width: 100%;
  height: 819px;
  background: url(${bgOurExperts}) no-repeat;
  background-size: 100% 100%;
  padding-top: 14px;
`

export const OurExpertsDataContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export const OurExpertsImageMobile = styled.img`
  height: auto;
  margin: 0 16px;
  object-fit: contain;
  @media (orientation: landscape) {
    height: 322px;
  }
`

export const OurExpertsTextsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 16px 24px 16px;
`

export const OurExpertsTextHeaderMobile = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-top: 16px;
  margin-bottom: 12px;
  color: #21304e;
`

export const OurExpertsTextLabelMobile = styled(DetailsTextMobile)`
  color: #21304e;
`

export const RecommendationBlockWrapperMobile = styled(MiddleContainerMobile)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

export const RecommendationSideBlock = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`

export const RecommendationTextMobile = styled(DetailsTextMobile)`
  color: #000000;
  text-align: center;
  margin-top: 12px;
`

export const RecommendationImgMobile = styled.img`
  width: 100%;
  height: auto;
  margin-top: 24px;

  @media (orientation: landscape) {
    width: 60%;
    margin: 24px auto 0 auto;
  }
`

export const IntelligenceBlockMobile = styled(MiddleContainerMobile)`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`

export const IntelligenceTextMobile = styled(DetailsTextMobile)<{
  marginBottom?: number
  marginTop?: number
}>`
  color: #000000;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  text-align: center;
`

export const IntelligenceImagesWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  @media (orientation: landscape) {
    width: 370px;
    margin: 24px auto 0 auto;
  }
`

export const IntelligenceImageMobile = styled.img<{
  width?: number | string
  show: boolean
  animationLength?: number
  showDelay?: number
}>`
  width: ${({ width }) => width || 119}px;
  height: 90px;
  margin: 4px;
  object-fit: contain;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity ${({ animationLength }) => animationLength || 0.5}s
    ${({ showDelay }) => showDelay || 0}s;
`

export const NumbersBlockMobile = styled(MiddleContainerMobile)`
  display: flex;
  flex-direction: column;
  margin-top: 123px;
`

export const NumbersTextMobile = styled(DetailsTextMobile)`
  color: #000000;
  margin-top: 14px;
  text-align: center;
`

export const NumbersItemsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @media (orientation: landscape) {
    width: 60%;
    margin: 24px auto 0 auto;
  }
`

export const NumbersItemMobile = styled.div<{ hasBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 72px;
  border-bottom: ${({ hasBorder }) => (hasBorder ? 1 : 0)}px solid #e1e3ea;
`

export const NumbersItemNumberMobile = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 32px;
  width: 100px;
  color: ${({ color }) => color};
`

export const NumbersItemTextMobile = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: black;
  flex: 1;
`

export const ReviewsBlockMobile = styled(MiddleContainerMobile)`
  margin-top: 70px;
  padding-bottom: 36px;
`

export const ReviewsCardMobile = styled.div`
  background: #ffffff;
  height: 387px;
  border: 1px solid #e1e3ea;
  border-radius: 4px;
  display: flex !important;
  flex-direction: column;
  padding: 12px 16px;
`

export const ReviewsCardTextMobile = styled.div`
  font-weight: 400;
  font-size: 16px;
  flex: 1;
  line-height: 24px;
  color: #21304e;
  display: flex;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    background: url(${icQuote}) no-repeat;
    background-size: 100%;
    width: 50px;
    height: 33px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    background: url(${icQuote}) no-repeat;
    background-size: 100%;
    width: 50px;
    height: 33px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export const ReviewsAuthorMobile = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #21304e;
  margin-top: 24px;
`

export const ReviewsLogoMobile = styled.img<{ height?: number }>`
  width: 126px;
  height: ${({ height }) => height ?? 31}px;
  object-fit: contain;
  object-position: left;
  opacity: 0.4;
  margin-top: 12px;
`

export const FaqsContainerMobile = styled.div`
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BottomBlockMobile = styled.div`
  background: url(${bgBottom}) no-repeat;
  background-size: 100% 100%;
  height: 547px;
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0 40px 0;

  @media (orientation: landscape) {
    height: unset;
  }
`

export const BottomBlockTitleMobile = styled(BlockTitleMobile)`
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 24px;
`

export const BottomPersonImageMobile = styled.img`
  width: calc(100% - 120px);
  height: auto;
  object-fit: contain;

  @media (orientation: landscape) {
    width: 50%;
  }
`

export const StyledButtonMobile = withProps()(styled(Button)<{ fullWidth?: boolean }>`
  width: 100%;
  font-size: 16px;
  padding: 16px 0;
  height: auto;
  background: ${({ theme }: any) => {
    const color = theme.agencyColor ?? '#B202FF'
    return color
  }};

  &:hover {
    background-color: ${({ theme }: any) => {
      const color = theme.agencyColor ?? '#B202FF'
      return color
    }};
    opacity: 0.8;
  }
`)

export const ProffessionalGuidanceContainerMobile = styled.div`
  background: url(${bgProfessionalGuidance}) no-repeat;
  background-size: 100% 100%;
  padding: 49px 16px 60px 16px;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
`

export const GuidanceImageMobile = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;

  @media (orientation: landscape) {
    width: 50%;
    margin: 0 auto;
  }
`

export const GuidancePriceBlockMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;
`

export const GuidancePriceTextMobile = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: black;
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: center;
`

export const GuidancePriceLabelMobile = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-right: 12px;
  color: #000000;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 19px;
  }
`

export const GuidancePriceMobile = withProps()(styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }: any) => theme.agencyColor ?? '#B202FF'};

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 21px;
  }
`)

export const GuidancePriceSubTextMobile = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8992a3;
  margin-bottom: 12px;
`
