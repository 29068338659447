import { createAction } from '@reduxjs/toolkit'
import { IFailure } from '../utils/redux'
import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  ISendFeedbackAction,
  ISendFeedbackSuccessAction,
} from './types'

export const sendFeedback = createAction<ISendFeedbackAction['payload']>(SEND_FEEDBACK)
export const sendFeedbackSuccess = createAction<ISendFeedbackSuccessAction['payload']>(
  SEND_FEEDBACK_SUCCESS
)
export const sendFeedbackFailure = createAction<IFailure>(SEND_FEEDBACK_FAILURE)
