import styled from '@emotion/styled'
import React from 'react'
import DonutChart from 'react-svg-donut-chart'
import { useMediaQuery } from 'react-responsive'
import { TextBold, Text } from 'src/components/Typography'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const DonutContainer = styled.div`
  max-width: 120px;
  width: 25%;
  text-align: center;
  position: relative;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    flex-basis: 50%;
    max-width: 40%;
    margin-bottom: 40px;
  }
`

const DonutScore = styled(Text)`
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      top: 26%;
      font-size: 10px;
    }

    @media screen and (max-width: 800px) {
      top: 21%;
    }
  `}

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #aeaeae;
    margin-top: 5%;
    top: calc(50% - 14px);
  }
`
const DonutBoldScore = styled.span`
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 18px;
    font-weight: 500;
    color: #000;
    float: left;
    padding-right: 3px;
  }
`

const DonutTextBold = styled(TextBold)`
  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      line-height: 10px;
      font-size: 8px;
    }
  `}
  @media screen and (max-width: 600px) {
    position: absolute;
  }
`

export default function ChartComponent({
  title,
  value,
  color,
}: {
  title: string
  value: number
  color: string
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  return (
    <DonutContainer>
      <DonutChart
        data={[
          { value: 20 * value, stroke: color, strokeWidth: 3 },
          { value: 100 - 20 * value, stroke: '#E1E3EA', strokeWidth: 3 },
        ]}
        style={{
          transform: 'rotate(90deg) scale(-1,1)',
        }}
      />
      <DonutTextBold>{title}</DonutTextBold>
      <DonutScore>
        {isMobile ? <DonutBoldScore>{value}</DonutBoldScore> : value} / 5
      </DonutScore>
    </DonutContainer>
  )
}
