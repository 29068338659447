import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgCoaching({ fill }: SvgProps) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="1 -1 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5767 0C14.4531 0 16.173 1.728 16.2 4.6188V13.3119C16.2 16.2432 14.4756 18 11.5767 18H4.62423C4.25343 18 3.90153 17.9712 3.57033 17.9145L3.37413 17.8776C1.24023 17.4339 3.05176e-05 15.7995 3.05176e-05 13.3119V4.6881C3.05176e-05 4.3875 0.0180305 4.0995 0.0540305 3.8241C0.368131 1.4076 2.02863 0 4.62423 0H11.5767ZM11.5686 1.3113H4.62423C2.41383 1.3113 1.29333 2.448 1.29333 4.6881V13.3119C1.29333 15.552 2.41383 16.6887 4.62423 16.6887H11.5686C13.7781 16.6887 14.8914 15.552 14.8914 13.3119V4.6881C14.8914 2.448 13.7781 1.3113 11.5686 1.3113ZM11.3337 12.159C11.6883 12.159 11.9754 12.4506 11.9754 12.8106C11.9754 13.1706 11.6883 13.4622 11.3337 13.4622H4.84383C4.48923 13.4622 4.20123 13.1706 4.20123 12.8106C4.20123 12.4506 4.48923 12.159 4.84383 12.159H11.3337ZM11.3337 8.3439C11.5812 8.3169 11.8233 8.4357 11.9556 8.649C12.0879 8.8641 12.0879 9.1359 11.9556 9.351C11.8233 9.5643 11.5812 9.6831 11.3337 9.6561H4.84383C4.51443 9.6183 4.26513 9.3366 4.26513 9C4.26513 8.6634 4.51443 8.3808 4.84383 8.3439H11.3337ZM7.32063 4.5378C7.65003 4.5747 7.89933 4.8573 7.89933 5.1939C7.89933 5.5296 7.65003 5.8122 7.32063 5.8491H4.85193C4.52163 5.8122 4.27233 5.5296 4.27233 5.1939C4.27233 4.8573 4.52163 4.5747 4.85193 4.5378H7.32063Z"
        fill={fill || '#fff'}
      />
    </svg>
  )
}

export default SvgCoaching
