import React from 'react'
import styled from '@emotion/styled'
import { CheckboxButton } from '../../components'
import { CheckboxContentContainer, CheckboxContentTitle } from './checkbox-styles'

interface IAvailabilityTimeCheckbox {
  title?: string
  name: string
  onBlur: () => void
  onChange: (val) => void
  checked: boolean
  useTheme?: boolean
  icon: string
  time: string
  hideCheckBox?: boolean
}

const CheckboxContentImg = styled.img`
  height: 30px;
  width: 30px;
  margin-top: 14px;
  margin-bottom: 7px;
  display: inline-block;
`

const CheckboxContentSubtitle = styled.p`
  margin: 0;
  margin-bottom: 8px;
  color: rgba(115, 121, 145, 0.8);
  font-family: Rubik;
  font-size: 12px;
  letter-spacing: 0;
`

export default function AvailabilityTimeCheckbox(props: IAvailabilityTimeCheckbox) {
  const {
    icon,
    time,
    title,
    hideCheckBox,
    onBlur,
    onChange,
    checked,
    name,
    useTheme,
  } = props
  return (
    <CheckboxButton
      onBlur={onBlur}
      onChange={onChange}
      checked={checked}
      name={name}
      useTheme={useTheme}
      hideCheckBox={hideCheckBox}
    >
      <CheckboxContentContainer>
        <CheckboxContentImg src={icon} />
        <CheckboxContentTitle>{time}</CheckboxContentTitle>
        <CheckboxContentSubtitle>{title}</CheckboxContentSubtitle>
      </CheckboxContentContainer>
    </CheckboxButton>
  )
}

AvailabilityTimeCheckbox.defaultProps = {
  useTheme: false,
  title: '',
  hideCheckBox: false,
}
