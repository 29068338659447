import React, { useState, useMemo } from 'react'
import { get } from 'lodash'
import { Controller } from 'react-hook-form'
import styled from '@emotion/styled'
import { Input } from '../../components'
import PhotoEditor from '../components/PhotoEditor'
import { ICandidateProfile } from '../../api'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`

const RowEmpty = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 30px;
`

const PhotoWrapper = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto 15px;
`

interface IPhotoPlaceHolder {
  hasLogoError: boolean
}

const PhotoPlaceHolder = styled.div<IPhotoPlaceHolder>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: ${(props) => (props.hasLogoError ? '1px solid #f00' : '1px solid #666')};
  border-radius: 50%;
  overflow: hidden;
`

const StyledImg = styled.img`
  border: 1px solid #666;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  display: block;
`

const Logo = styled.img`
  width: 100%;
  display: block;
`

interface IApplicantDetails {
  control: any
  errors: any
  setLogos: (any) => void
  candidateApi: Partial<ICandidateProfile>
  onLogoChange: () => void
}

function ApplicantDetails({
  control,
  errors,
  setLogos,
  candidateApi,
  onLogoChange,
}: IApplicantDetails) {
  const [showPhotoEditor, setShowPhotoEditor] = useState(false)
  const [croppedPhoto, setCroppedPhoto] = useState<any>()
  const logoUrl: string = get(candidateApi, 'logo.url')
  const dataURLImage = useMemo(() => (croppedPhoto ? croppedPhoto.toDataURL() : ''), [
    croppedPhoto,
  ])

  const handleSave = (photo) => {
    setCroppedPhoto(photo)
    setLogos((prev) => ({
      ...prev,
      applicant: {
        ...prev.applicant,
        logo: photo.toDataURL(),
      },
    }))
    setShowPhotoEditor(false)
    onLogoChange()
  }

  return (
    <div>
      <PhotoWrapper onClick={() => setShowPhotoEditor(true)}>
        {dataURLImage ? (
          <StyledImg src={dataURLImage} alt="Add photo" />
        ) : (
          <PhotoPlaceHolder hasLogoError={false}>
            {logoUrl ? (
              <Logo src={logoUrl} alt="" />
            ) : (
              <>
                <span>Upload photo</span>
              </>
            )}
          </PhotoPlaceHolder>
        )}
      </PhotoWrapper>

      <Row>
        <Controller
          as={Input}
          label="Current position and company"
          name="applicant.current_position"
          control={control}
          error={get(errors, `applicant.current_position`, {})}
          rules={{
            required: false,
            maxLength: { value: 100, message: 'Max characters : 100' },
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <RowEmpty />
      <Row>
        <Controller
          as={Input}
          label="Education 1"
          name="applicant.educations.0"
          control={control}
          error={get(errors, `applicant.educations.0`, {})}
          rules={{
            required: false,
            maxLength: { value: 200, message: 'Max characters : 200' },
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <Row>
        <Controller
          as={Input}
          label="Education 2"
          name="applicant.educations.1"
          control={control}
          error={get(errors, `applicant.educations.1`, {})}
          rules={{
            required: false,
            maxLength: { value: 200, message: 'Max characters : 200' },
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <Row>
        <Controller
          as={Input}
          label="Education 3"
          name="applicant.educations.2"
          control={control}
          error={get(errors, `applicant.educations.2`, {})}
          rules={{
            required: false,
            maxLength: { value: 200, message: 'Max characters : 200' },
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <RowEmpty />
      <Row>
        <Controller
          as={Input}
          label="Experience 1"
          name="applicant.experiences.0"
          control={control}
          error={get(errors, `applicant.experiences.0`, {})}
          rules={{
            required: false,
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <Row>
        <Controller
          as={Input}
          label="Experience 2"
          name="applicant.experiences.1"
          control={control}
          error={get(errors, `applicant.experiences.1`, {})}
          rules={{
            required: false,
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <Row>
        <Controller
          as={Input}
          label="Experience 3"
          name="applicant.experiences.2"
          control={control}
          error={get(errors, `applicant.experiences.2`, {})}
          rules={{
            required: false,
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      {showPhotoEditor && (
        <PhotoEditor onClose={() => setShowPhotoEditor(false)} saveImage={handleSave} />
      )}
    </div>
  )
}

export default ApplicantDetails
