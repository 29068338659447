import { createReducer } from '@reduxjs/toolkit'
import {
  IAdminInterview,
  IAdminStats,
  IAgencyStats,
  IGetAgencyInterviewResponse,
} from '../api'
import { interviewBody, interviewReport, token, cvFile } from '../initialStates'
import { IDefaultState } from '../utils/redux'

import {
  getInterviews,
  getInterviewsSuccess,
  getInterviewsFailure,
  getInterview,
  getInterviewSuccess,
  getInterviewFailure,
  sendToApplicant,
  sendToApplicantSuccess,
  sendToApplicantFailure,
  sendToApplicantClearSuccess,
  sendToApplicantClearError,
  saveSessionReport,
  saveSessionReportClearError,
  saveSessionReportClearSuccess,
  saveSessionReportFailure,
  saveSessionReportSuccess,
  interviewComplete,
  interviewCompleteClearError,
  interviewCompleteClearSuccess,
  interviewCompleteFailure,
  interviewCompleteSuccess,
  interviewToEnd,
  interviewToEndClearError,
  interviewToEndClearSuccess,
  interviewToEndFailure,
  interviewToEndSuccess,
  getStats,
  getStatsFailure,
  getStatsSuccess,
} from './actions'
import {
  IGetInterviewsSuccessAction,
  IGetInterviewsFailureAction,
  IGetInterviewSuccessAction,
  IGetInterviewFailureAction,
  ISessionReportFailureAction,
  IGetStatsSuccessAction,
  IGetStatsFailureAction,
} from './types'

interface IAdminInterviewsState extends IDefaultState {
  interviews: IAdminInterview[]
  pages: number
}

interface IAdminInterviewState extends IDefaultState {
  interview: IGetAgencyInterviewResponse
}

interface IAgencyStatsState extends IDefaultState {
  stats: IAdminStats | IAgencyStats
}

interface ICreateBookingState extends IDefaultState {
  bookingDetails: {
    candidate_token_code: string
    email: string
    firstName: string
    lastName: string
  }
}

const interviewsInitialState: IAdminInterviewsState = {
  interviews: [],
  pages: 1,
  isLoading: false,
  error: '',
}

const interviewInitialState: IAdminInterviewState = {
  interview: {
    interview: interviewBody,
    candidate_token: token,
    expert_token: token,
    report: interviewReport,
    cv_file: cvFile,
  },
  isLoading: false,
  error: '',
}

const statsInitialState: IAgencyStatsState = {
  isLoading: false,
  success: false,
  error: '',
  stats: {},
}

const defaultStateWithSuccessError: IDefaultState = {
  isLoading: false,
  success: false,
  error: '',
}

export const interviewsReducer = createReducer(interviewsInitialState, {
  [getInterviews.type]: (state: IAdminInterviewsState) => {
    return {
      ...state,
      isLoading: true,
      errors: '',
      interviews: [],
      pages: 1,
    }
  },
  [getInterviewsSuccess.type]: (state, action: IGetInterviewsSuccessAction) => {
    return {
      ...state,
      isLoading: false,
      error: '',
      interviews: action.payload.interviews,
      pages: action.payload.pages,
    }
  },
  [getInterviewsFailure.type]: (state, action: IGetInterviewsFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    interviews: [],
    pages: 1,
  }),
})

export const agencyInterviewReducer = createReducer(interviewInitialState, {
  [getInterview.type]: (state: IAdminInterviewState) => ({
    ...state,
    isLoading: true,
    errors: '',
    interview: interviewInitialState.interview,
  }),
  [getInterviewSuccess.type]: (state, action: IGetInterviewSuccessAction) => ({
    ...state,
    isLoading: false,
    error: '',
    interview: action.payload.interview,
  }),
  [getInterviewFailure.type]: (state, action: IGetInterviewFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    interview: interviewInitialState.interview,
  }),
})

export const statsReducer = createReducer(statsInitialState, {
  [getStats.type]: (state: IAgencyStatsState) => ({
    ...state,
    isLoading: true,
    error: '',
    stats: {},
  }),
  [getStatsSuccess.type]: (state: IAgencyStatsState, action: IGetStatsSuccessAction) => ({
    ...state,
    isLoading: false,
    error: '',
    stats: action.payload.stats,
  }),
  [getStatsFailure.type]: (state: IAgencyStatsState, action: IGetStatsFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
    stats: {},
  }),
})

export const saveSessionReportReducer = createReducer(defaultStateWithSuccessError, {
  [saveSessionReport.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    errors: '',
  }),
  [saveSessionReportSuccess.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: false,
    success: true,
    error: '',
  }),
  [saveSessionReportFailure.type]: (
    state: IDefaultState,
    action: ISessionReportFailureAction
  ) => ({
    ...state,
    isLoading: false,
    success: false,
    error: action.payload.error,
  }),
  [saveSessionReportClearSuccess.type]: (state: IDefaultState) => ({
    ...state,
    success: false,
  }),
  [saveSessionReportClearError.type]: (state: IDefaultState) => ({
    ...state,
    error: '',
  }),
})

export const sendToApplicantReducer = createReducer(defaultStateWithSuccessError, {
  [sendToApplicant.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    errors: '',
  }),
  [sendToApplicantSuccess.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: false,
    success: true,
    error: '',
  }),
  [sendToApplicantFailure.type]: (state: IDefaultState, action: any) => ({
    ...state,
    isLoading: false,
    success: false,
    error: action.payload.error,
  }),
  [sendToApplicantClearSuccess.type]: (state: IDefaultState) => ({
    ...state,
    success: false,
  }),
  [sendToApplicantClearError.type]: (state: IDefaultState) => ({
    ...state,
    error: '',
  }),
})

export const interviewCompleteReducer = createReducer(defaultStateWithSuccessError, {
  [interviewComplete.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    errors: '',
  }),
  [interviewCompleteSuccess.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: false,
    success: true,
    error: '',
  }),
  [interviewCompleteFailure.type]: (state: IDefaultState, action: any) => ({
    ...state,
    isLoading: false,
    success: false,
    error: action.payload.error,
  }),
  [interviewCompleteClearSuccess.type]: (state: IDefaultState) => ({
    ...state,
    success: false,
  }),
  [interviewCompleteClearError.type]: (state: IDefaultState) => ({
    ...state,
    error: '',
  }),
})

export const interviewToEndReducer = createReducer(defaultStateWithSuccessError, {
  [interviewToEnd.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: true,
    errors: '',
  }),
  [interviewToEndSuccess.type]: (state: IDefaultState) => ({
    ...state,
    isLoading: false,
    success: true,
    error: '',
  }),
  [interviewToEndFailure.type]: (state: IDefaultState, action: any) => ({
    ...state,
    isLoading: false,
    success: false,
    error: action.payload.error,
  }),
  [interviewToEndClearSuccess.type]: (state: IDefaultState) => ({
    ...state,
    success: false,
  }),
  [interviewToEndClearError.type]: (state: IDefaultState) => ({
    ...state,
    error: '',
  }),
})
