import React from 'react'

interface SvgProps {
  fill: string
}

function SvgFollowUp({ fill }: SvgProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" />
      <path
        d="M10.5056 9.26399L12.419 11.132M12.419 11.132L10.5056 13M12.419 11.132H6.29609C5.028 11.132 4 10.104 4 8.8359V3"
        stroke={fill}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgFollowUp
