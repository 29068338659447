import React from 'react'
import styled from '@emotion/styled'
import SvgPrice from 'src/static/components/SvgPrice'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'

const Container = styled.div`
  width: 100%;
  margin: 40px 0 32px 0;
  display: flex;
  flex-direction: column;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const SessionPriceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #200e32;
  margin-right: 2px;
`

const PriceTitle = styled(Title)<{ fill: string }>`
  color: ${(props) => props.fill};
`

const AgencyName = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #353c5a;
`

const InfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #979797;
  margin-top: 12px;
`

const PriceIconWrapper = styled.div`
  margin: 0 5px;

  > svg {
    vertical-align: unset;
  }
`

export default function SessionPrice() {
  const agency = useSelector(interviewSelectors.agency)

  function getPrice(): string {
    let currencyString = ''
    switch (agency.currency) {
      case 'USD':
        currencyString = '$'
        break
      case 'ILS':
        currencyString = '₪'
        break
      case 'EUR':
        currencyString = '€'
        break
      case 'GBP':
        currencyString = '£'
        break
      case 'CAD':
        currencyString = 'C$'
        break
      case 'AUD':
        currencyString = 'A$'
        break
      default:
        currencyString = ''
    }

    return `${currencyString}${agency.session_price}`
  }

  return (
    <Container>
      <TopContainer>
        <PriceIconWrapper>
          <SvgPrice fill={agency.main_color} />
        </PriceIconWrapper>
        <SessionPriceContainer>
          <PriceContainer>
            <Title>Session price:</Title>
            <PriceTitle fill={agency.main_color}>{getPrice()}</PriceTitle>
          </PriceContainer>
          <AgencyName>{`Exclusive ${agency.name} price`}</AgencyName>
        </SessionPriceContainer>
      </TopContainer>
      <InfoText>
        We&apos;ll ask you to pay only once we&apos;ve found your perfect expert match and
        you&apos;re ready to schedule your session.
      </InfoText>
    </Container>
  )
}
