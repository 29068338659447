import moment from 'moment'

const slotExpiredHours = 1

const isSlotExpired = (date: string) => {
  const dateMoment = moment(date)
  const nowMoment = moment(new Date())
  const diff = dateMoment.diff(nowMoment, 'hour')
  return diff < slotExpiredHours
}

export default isSlotExpired
