import React from 'react'
import styled from '@emotion/styled'

import SvgDangerIcon from '../../static/components/SvgDanger'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 25px;
  font-weight: 500;
  background-color: #353c5a;
`

const MuteText = styled.p`
  color: white;
  font-size: 50px;
  margin-top: 25px;
  max-width: 50%;
  text-align: center;
`

const DangerIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
  }
`

interface IProps {
  studentName: string
  expertName: string
  position: string
}

const Preview = ({ studentName, expertName, position }: IProps) => {
  const text = `${studentName}'s coaching session for ${position} with ${expertName}`
  return (
    <Container>
      <DangerIcon>
        <SvgDangerIcon fill="#fff" />
      </DangerIcon>
      <MuteText>{text}</MuteText>
    </Container>
  )
}

export default Preview
