/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from '@emotion/styled'
import { IExpertProfile } from 'src/api/models'
import { Avatar, Text, TextBold } from '../../components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ExpertContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  margin-bottom: 16px;
  background: rgba(245, 246, 248, 0.6);
  border-radius: 8px;
`

const StyledText = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: #200e32;
`

const StyledTextBold = styled(TextBold)`
  font-size: 14px;
  font-weight: 500;
  color: #353c5a;
  line-height: 26px;
  letter-spacing: 0;
`

const Circle = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid #8f9bb3;
  border-radius: 9px;
  margin-right: 14px;
`

const MainInfo = styled.div`
  flex: 1;
`

interface IProps {
  experts: IExpertProfile[]
  onSelect: (expert: IExpertProfile) => void
}

export default function InterviewExpertsListMobile({ experts, onSelect }: IProps) {
  return (
    <Container>
      {experts.map((expert) => (
        <ExpertContainer key={expert.id} onClick={() => onSelect(expert)}>
          <Circle />
          <Avatar user={expert} size={40} />
          <MainInfo>
            <StyledTextBold>
              {expert.first_name} {expert.last_name}
            </StyledTextBold>
            <StyledText>{`${expert.current_position} ${
              expert.company ? `at ${expert.company}` : ''
            }`}</StyledText>
          </MainInfo>
        </ExpertContainer>
      ))}
    </Container>
  )
}
