import React from 'react'
import styled from '@emotion/styled'

import videoMutedIcon from '../../static/candidate_cockpit_video_muted.png'
import audioMutedIcon from '../../static/candidate_cockpit_muted.png'

const Video = styled.div`
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;

  div {
    background-color: #353c5a !important;
  }
`

const MuteOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: #353c5a;
`

const MuteIcon = styled.img`
  width: 40px;
  height: 40px;
  background: black;
  border-radius: 100px;
  opacity: 0.8;
`

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 90px;
  position: absolute;
  bottom: 10px;
  left: 10px;
`

const VideoResizeContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
`

interface IProps {
  audioMuted: boolean
  id: string
  videoMuted: boolean
}

const RemoteVideo = ({ audioMuted, id, videoMuted }: IProps) => {
  return (
    <VideoResizeContainer>
      <Video id={id} />
      {videoMuted && (
        <>
          <MuteOverlay />
        </>
      )}
      <IconsContainer>
        {videoMuted && <MuteIcon src={videoMutedIcon} />}
        {audioMuted && <MuteIcon src={audioMutedIcon} />}
      </IconsContainer>
    </VideoResizeContainer>
  )
}

export default RemoteVideo
