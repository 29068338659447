import jwt_decode from 'jwt-decode'

const jwtToken = () => {
  const token = localStorage.getItem('token')
  if (!token) return undefined

  const { agency_ids: agencyIds, role, can_change_agency_positions } = jwt_decode(token)
  if (role === 'superadmin')
    return { agencyIds, token, role, can_change_agency_positions }
  if (role === 'admin' && agencyIds?.length > 0)
    return { agencyIds, token, role, can_change_agency_positions }

  localStorage.removeItem('token')
  return undefined
}

export const getAuthorizationToken = () => {
  const { token } = jwtToken() || {}
  return token
}

export const isAdminRole = () => {
  const { role } = jwtToken() || {}
  return role === 'admin' || role === 'superadmin'
}

export const isSuperAdminRole = () => {
  const { role } = jwtToken() || {}
  return role === 'superadmin'
}

export const isAgencyAdminRole = (agencyId: string) => {
  const { role, agencyIds = [] } = jwtToken() || {}
  return role === 'admin' && agencyIds.includes(agencyId)
}

export const hasAccessToAgencyOverview = () => {
  const { role, agencyIds = [] } = jwtToken() || {}
  return role === 'superadmin' || (role === 'admin' && agencyIds.length > 1)
}

export const getMainAdminPage = () => {
  const { agencyIds = [] } = jwtToken() || {}
  return hasAccessToAgencyOverview() ? '/admin/overview' : `/agencies/${agencyIds[0]}`
}

export const canChangeAgencyPositions = () => {
  const { can_change_agency_positions = false } = jwtToken() || {}
  return can_change_agency_positions
}
