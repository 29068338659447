import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as interviewSelectors from 'src/interview/selectors'
import { avaInterviewSelectors } from '../../../redux'
import { SpeechTexts } from '../../speechService'
import ChoiseComponent from './ChoiseComponent'

interface IProps {
  backMode: boolean
  goBack: () => void
  goNext: (value) => void
}

const RoleComponent = ({ backMode, goNext, goBack }: IProps) => {
  const interview = useSelector(avaInterviewSelectors.data)
  const agency = useSelector(interviewSelectors.agency)

  const [selectedItem, setSelectedItem] = useState<any>()
  const [showError, setShowError] = useState(false)

  const roles = agency?.agency_positions[interview.position?.position] || []

  const onNext = () => {
    if (!selectedItem) {
      setShowError(true)
    } else {
      goNext(selectedItem)
    }
  }

  const handleItemSelected = (item: string) => {
    const sItem = roles.find((r) => r.label === item)
    setSelectedItem(sItem)
  }

  useEffect(() => {
    if (!interview.position) return
    const sItem = roles.find((r) => r.label === interview.position.role)
    setSelectedItem(sItem)
  }, [interview, roles])

  useEffect(() => {
    if (selectedItem) {
      setShowError(false)
    }
  }, [selectedItem])

  if (!roles) return null

  return (
    <ChoiseComponent
      backMode={backMode}
      title={SpeechTexts.WhatRole}
      errorText="Please select the role you want to practice for"
      showError={showError}
      items={roles.map((r) => r.label)}
      onSelect={handleItemSelected}
      selectedItem={selectedItem?.label}
      goNext={onNext}
      goBack={goBack}
    />
  )
}

export default RoleComponent
