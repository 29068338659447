import { put, takeEvery, call } from 'typed-redux-saga'

import { getApplicantNotes } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateApplicantNotesAction } from '../data/applicantNotes'

interface IGetApplicantNotesAction {
  interviewToken: string
}

const {
  action: getApplicantNotesAction,
  actionFailure: getApplicantNotesActionFailure,
  actionSuccess: getApplicantNotesActionSuccess,
  actionSuccessClear: getApplicantNotesActionSuccessClear,
  actionFailureClear: getApplicantNotesActionFailureClear,
  actionType: getApplicantNotesActionType,
  actionTypeFailure: getApplicantNotesActionFailureType,
  actionTypeSuccess: getApplicantNotesActionSuccessType,
  reducer: getApplicantNotesRequestReducer,
  selectors: getApplicantNotesSelectors,
} = reduxRequestFactory<IGetApplicantNotesAction>('getApplicantNotes', 'requests')

export {
  getApplicantNotesAction,
  getApplicantNotesActionFailure,
  getApplicantNotesActionSuccess,
  getApplicantNotesActionSuccessClear,
  getApplicantNotesActionFailureClear,
  getApplicantNotesActionType,
  getApplicantNotesActionFailureType,
  getApplicantNotesActionSuccessType,
  getApplicantNotesRequestReducer,
  getApplicantNotesSelectors,
}

function* getApplicantNotesSaga(action: ISagaAction<IGetApplicantNotesAction>) {
  try {
    const { interviewToken } = action.payload
    const notes = yield* call(getApplicantNotes, interviewToken)
    yield put(getApplicantNotesActionSuccess())
    yield put(updateApplicantNotesAction(notes))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      getApplicantNotesActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetApplicantNotes() {
  yield takeEvery(getApplicantNotesActionType, getApplicantNotesSaga)
}
