import {
  ClientConfig,
  IAgoraRTC,
  IAgoraRTCClient,
  ILocalVideoTrack,
  UID,
} from 'agora-rtc-sdk-ng'
import useStateRef from 'react-usestateref'

import { getAgoraToken } from '../../api'
import useNetworkQuality from './useNetworkQuality'

const ExpertUidPrefix = 2
const StudentUidPrefix = 1
const ScreensharingUidPrefix = 2
const CHANNEL = 'test'

const config: ClientConfig = {
  codec: 'vp8',
  mode: 'rtc',
}

const consoleStyles = ['%c%s', 'font-weight: bold;', '[Wizco Screenshare]']

const useScreenshare = (rtc: IAgoraRTC, channelId = CHANNEL, isExpert = false) => {
  const { updateNetworkQuality } = useNetworkQuality('Screenshare')

  const [uid, setUid, uidRef] = useStateRef<UID>()
  const [token, setToken, tokenRef] = useStateRef<string>()
  const [client, setClient, clientRef] = useStateRef<IAgoraRTCClient>()
  const [track, setTrack, trackRef] = useStateRef<ILocalVideoTrack>()
  const [interruptDialog, setInterruptDialog] = useStateRef(false)

  const [joining, setJoining, joiningRef] = useStateRef(false)
  const [joinedToScreenshare, setJoinedToScreenshare] = useStateRef(false)
  const [joinedToScreenshareFailure, setJoinedToScreenshareFailure] = useStateRef(false)
  const [screenShareError, setScreenShareError] = useStateRef(false)

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const generateUid = () => {
    setUid((isExpert ? ExpertUidPrefix : StudentUidPrefix) * 10 + ScreensharingUidPrefix)
  }

  const generateToken = async () => {
    const data = await getAgoraToken(uidRef.current as number, channelId)
    setToken(data.token)
  }

  const handleClientException = (exception) => {
    error('Error', JSON.stringify(exception))
  }

  const handleNetworkQuality = (quality) => {
    const { downlinkNetworkQuality, uplinkNetworkQuality } = quality
    updateNetworkQuality(uplinkNetworkQuality, downlinkNetworkQuality)
  }

  const handleTokenPrivilegeWillExpire = async () => {
    await generateToken()
    clientRef.current.renewToken(tokenRef.current)
    log('Renewed token')
  }

  const handleUserPublished = async (user, mediaType) => {
    const userUid = user.uid.toString()

    try {
      await clientRef.current.subscribe(user, mediaType)
    } catch (e) {
      error(
        `Failed to subscribe to user ${mediaType} track (uid: ${userUid}): ${JSON.stringify(
          e
        )}`
      )
    }
  }

  const handleUserInfoUpdated = (userUid, msg) => {
    switch (msg) {
      case 'mute-audio':
        log(`Received ${userUid} audio mute message`)
        break
      case 'mute-video':
        log(`Received ${userUid} video mute message`)
        break
      case 'unmute-audio':
        log(`Received ${userUid} audio unmute message`)
        break
      case 'unmute-video':
        log(`Received ${userUid} video unmute message`)
        break
      default:
        break
    }
  }

  const initScreenshare = () => {
    setClient(rtc.createClient(config))
    clientRef.current.on('exception', handleClientException)
    clientRef.current.on('network-quality', handleNetworkQuality)
    clientRef.current.on('token-privilege-will-expire', handleTokenPrivilegeWillExpire)
    clientRef.current.on('user-published', handleUserPublished)
    clientRef.current.on('user-info-updated', handleUserInfoUpdated)
    // eslint-disable-next-line no-underscore-dangle
    log(`Screenshare client initializing (id: ${(clientRef.current as any)?._clientId})`)
  }

  const leaveScreenshare = async () => {
    trackRef.current?.close()
    setTrack(undefined)
    try {
      await clientRef.current.leave()
      log('Left screenshare')
    } catch (e) {
      error(`Failed to leave screenshare: ${JSON.stringify(e)}`)
    }
  }

  const createTrack = async () => {
    const screenTrack = await rtc.createScreenVideoTrack(
      { optimizationMode: 'detail' },
      'disable'
    )
    setTrack(screenTrack)
    screenTrack.on('track-ended', leaveScreenshare)
  }

  const playScreenshare = () => {
    trackRef.current?.stop()
    trackRef.current?.play('main-video', {
      fit: 'contain',
      mirror: false,
    })
  }

  const joinScreenshare = async () => {
    if (joiningRef.current) return
    setJoining(true)

    try {
      await createTrack()
      log(`Successfully creted screen track`)
    } catch (e) {
      error(`Failed to create screen track: ${JSON.stringify(e)}`)
      setJoinedToScreenshareFailure(true)
      await leaveScreenshare()
      setJoining(false)
      setScreenShareError(true)
      return
    }

    const appId = process.env.REACT_APP_AGORA_APP_ID
    try {
      generateUid()
      await generateToken()
    } catch (e) {
      error(`Failed to generate token for RTC: ${JSON.stringify(e)}`)
      setJoinedToScreenshareFailure(true)
      setJoining(false)
      return
    }

    try {
      await clientRef.current.join(appId, channelId, tokenRef.current, uidRef.current)
      log(`Successfully joined call (uid: ${uidRef.current})`)
    } catch (e) {
      error(`Failed to join the channel: ${JSON.stringify(e)}`)
      setJoinedToScreenshareFailure(true)
      setJoining(false)
      return
    }

    try {
      await clientRef.current.publish(trackRef.current)
      log('Successfully published screen track')
    } catch (e) {
      error(`Failed to publish screen track: ${JSON.stringify(e)}`)
      setJoinedToScreenshareFailure(true)
      await leaveScreenshare()
      setJoining(false)
      return
    }

    setJoinedToScreenshareFailure(false)
    setJoinedToScreenshare(true)
    setJoining(false)
    playScreenshare()
  }

  const interruptScreenshare = async () => {
    setInterruptDialog(false)
    await joinScreenshare()
  }

  return {
    interruptScreenshareDialog: interruptDialog,
    isJoining: joining,
    isScreenSharing: !!track,
    screenInitialized: !!client,
    screenJoined: joinedToScreenshare,
    screenJoinedFailure: joinedToScreenshareFailure,
    screenToken: token,
    screenUid: uid,
    screenError: screenShareError,
    closeScreenshareInterruptDialog: () => setInterruptDialog(false),
    initScreenshare,
    interruptScreenshare,
    joinScreenshare,
    leaveScreenshare,
    openScreenshareInterruptDialog: () => setInterruptDialog(true),
    playScreenshare,
    hideScreenError: () => setScreenShareError(false),
  }
}

export default useScreenshare
