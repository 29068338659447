/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ItemsContainer = styled.div<{ size: number }>`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 16px;
  flex-direction: row;
  animation: scroll 10s linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-${({ size }) => size}px));
    }
  }
`

function CycledCarousel({ children }: any) {
  const [containerWidth, setContainerWidth] = useState(0)
  const [itemsToRender, setItemsToRender] = useState([])
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const newItems = [...children]
    children.forEach((item) => newItems.push(item))
    setItemsToRender(newItems)

    setTimeout(() => {
      setContainerWidth(ref.current.scrollWidth / 2)
    }, 500)
  }, [children])

  return (
    <Container>
      <ItemsContainer ref={ref} size={containerWidth}>
        {itemsToRender}
      </ItemsContainer>
    </Container>
  )
}

export default CycledCarousel
