/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { Loader } from '../../components'
import { updateSchedulingInfoSelectors } from '../redux'
import ReschedulingInfo from './ReschedulingInfo'
import SchedulingForm from './SchedulingForm'
import ReschedulingForm from './ReschedulingForm'

const ReschedulingContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
`

function SchedulingInfo() {
  const updateBookingIsLoading = useSelector(updateSchedulingInfoSelectors.isLoading)

  return (
    <>
      {updateBookingIsLoading && <Loader />}
      <div className="ql-editor">
        <SchedulingForm />

        <ReschedulingContainer>
          <ReschedulingForm />
          <ReschedulingInfo />
        </ReschedulingContainer>
      </div>
    </>
  )
}

export default SchedulingInfo
