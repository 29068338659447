import { NetworkQuality } from 'agora-rtc-sdk-ng'

import { reduxDataFactory } from '../../utils/redux'

const {
  action: updateCallNetworkRemoteAction,
  actionType: updateCallNetworkRemoteActionType,
  reducer: callNetworkRemoteDataReducer,
  selectors: callNetworkRemoteSelectors,
} = reduxDataFactory<NetworkQuality>('networkRemote', 'call')

export {
  updateCallNetworkRemoteAction,
  updateCallNetworkRemoteActionType,
  callNetworkRemoteDataReducer,
  callNetworkRemoteSelectors,
}
