import logo from '../static/logo.png'
import logoWhite from '../static/logo_white.png'

import preventImageCache from './preventImageCache'

export const validateAgencyLogo = (url: string) => {
  return url ? preventImageCache(url) : logo
}

export const validateAgencyLogoWhite = (url: string) => {
  return url ? preventImageCache(url) : logoWhite
}
