/* eslint-disable @typescript-eslint/no-unused-expressions */
import { CancelToken } from 'axios'

import httpClient from '../../httpClient'

// bulk_interview_files#create - POST /bulk_interview_files

// eslint-disable-next-line import/prefer-default-export
export const uploadBulkInterviewFile = (
  file: any,
  onProgress?: (number) => void,
  cancelToken?: CancelToken
) => {
  const formData = new FormData()
  formData.append('file', file)
  return httpClient
    .post('/bulk_interview_files', formData, {
      cancelToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (data) => {
        onProgress && onProgress(Math.round(data.loaded / data.total))
      },
    })
    .then((response) => response.data)
}
