import { put, takeEvery, call } from 'typed-redux-saga'

import { removeExpertNote } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateExpertNotesAction } from '../data/expertNotes'

interface IRemoveExpertNoteAction {
  id: string
  interviewToken: string
}

const {
  action: removeExpertNoteAction,
  actionFailure: removeExpertNoteActionFailure,
  actionSuccess: removeExpertNoteActionSuccess,
  actionSuccessClear: removeExpertNoteActionSuccessClear,
  actionFailureClear: removeExpertNoteActionFailureClear,
  actionType: removeExpertNoteActionType,
  actionTypeFailure: removeExpertNoteActionFailureType,
  actionTypeSuccess: removeExpertNoteActionSuccessType,
  reducer: removeExpertNoteRequestReducer,
  selectors: removeExpertNoteSelectors,
} = reduxRequestFactory<IRemoveExpertNoteAction>('removeExpertNote', 'requests')

export {
  removeExpertNoteAction,
  removeExpertNoteActionFailure,
  removeExpertNoteActionSuccess,
  removeExpertNoteActionSuccessClear,
  removeExpertNoteActionFailureClear,
  removeExpertNoteActionType,
  removeExpertNoteActionFailureType,
  removeExpertNoteActionSuccessType,
  removeExpertNoteRequestReducer,
  removeExpertNoteSelectors,
}

function* removeExpertNoteSaga(action: ISagaAction<IRemoveExpertNoteAction>) {
  try {
    const { interviewToken, ...expertNote } = action.payload
    const notes = yield* call(removeExpertNote, interviewToken, expertNote)
    yield put(removeExpertNoteActionSuccess())
    yield put(updateExpertNotesAction(notes))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      removeExpertNoteActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchRemoveExpertNote() {
  yield takeEvery(removeExpertNoteActionType, removeExpertNoteSaga)
}
