import React, { useState, forwardRef, useCallback, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive'
import { Slider, Direction, PlayerIcon } from 'react-player-controls'
import moment from 'moment'
import wizcoLogo from 'src/static/logo.png'
import SvgChevronRight from 'src/static/components/SvgChevronRight'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { Text } from 'src/components/Typography'
import { useSelector } from 'react-redux'
import Duration from './Duration'
import { IApplicantNote, IExpertNote } from '../../api'

import swapVideoIcon from '../../static/swap_video.png'
import videoPlaceHolder from '../../static/video-placeholder.png'
import videoPlaceHolderShape from '../../static/video-placeholder-shape.png'
import videoPlaceHolderArrow from '../../static/video-placeholder-arrow.png'
import videoCalendar from '../../static/video-calendar.svg'
import videoTime from '../../static/video-time.svg'
import wellSaidIcon from '../../static/report_well_said_white.svg'
import confusedIcon from '../../static/report_confused_white.svg'
import rephraseIcon from '../../static/report_rephrase_white.svg'
import wouldntMentionIcon from '../../static/report_wouldnt_mention_white.svg'
import { ITimelineInfoType } from '../types'
import FeedbackIcon from '../../static/feedback-icon.png'
import NotesIcon from '../../static/notes-icon.png'
import QuestionIcon from '../../static/question-icon.png'
import PlayIcon from '../../static/ic-play.png'
import Icon15 from '../../static/ic-15.png'
import IconPlayerSpeed from '../../static/ic-speed-decrease.png'
import { VideoSeekSlider } from './VideoSeekSlider'
import DropdownBlock from './DropdownBlock'
import SvgCode from '../../static/components/SvgCode'
import { activeNoteSelectors } from '../redux'

type VideoViewProps = {
  expertView: boolean
}

const VideoView = styled.div<VideoViewProps>`
  ${(props) =>
    props.expertView
      ? `
    position: absolute;
    width: 180px;
    height: 145px;
    bottom: 25px;
    left: 30px;
    z-index: 100;
    @media screen and (max-width: 600px) {
     width: 100px;
     height: auto;
     bottom: 0px;
     left: 0px;
    }
    `
      : `
    > a {
      display: none;
    }
    `}
`

const ReactPlayerWrap = styled.div`
  margin-top: 0;
  @media screen and (max-width: 600px) {
    margin-top: 5px;
    position: relative;
  }
`

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  flex: 1;
`

const VideoContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
`

const BottomArea = styled.div<{ marginRight?: number }>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ marginRight }) => marginRight ?? 0}px;
`

const Controls = styled.div`
  display: flex;
  justify-content: center;
`

const Playback = styled.div`
  display: flex;
  align-items: center;
  margin-left: 90px;
  > img {
    margin: 0px 5px !important;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }
  > svg {
    cursor: pointer;
    width: 42px;
    height: 42px;
    padding: 7px;
    margin: 0px 5px !important;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }
`

const SliderContainer = styled.div<{ short?: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  margin-bottom: ${({ short }) => (short ? 0 : 12)}px;

  ${isMobileOnly &&
  css`
    flex-direction: column;
  `}
`

const DurationContainer = styled.div<{ enableConversationIntelligence?: boolean }>`
  text-align: center;
  font-size: 14px;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    width: 140px;
    margin: -15px 0 ${(props) => (props.enableConversationIntelligence ? 16 : 0)}px 0;
    width: 100%;
    text-align: right;
  }
`

const JumpContainer = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
  }

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      width: 100%;
      justify-content: space-between;
    }
  `}
`

const JumpContainerButton = styled.div<{ active?: boolean }>`
  height: 32px;
  width: 67px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &::after {
    margin-left: 6px;
    color: #1d0e3b;
    font-weight: 400;
    font-size: 12px;
    content: '${(props) => (props.active ? 'Less' : 'More')}';
    display: inline-block;
  }
`

const JumpContainerButtonIcon = styled.img`
  height: 15px;
  width: 15px;
  object-fit: contain;
`

const JumpToFeedbackContainer = styled.div`
  margin-right: 30px;
  width: 190px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`

const JumpToFeedbackIconContainer = styled.div<{ mirror?: boolean; disabled?: boolean }>`
  cursor: pointer;
  transform: rotate(${({ mirror: rotate }) => (rotate ? 180 : 0)}deg);
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

const JumpToFeedbackText = styled.div<{ active: boolean; fill: string }>`
  width: 60%;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  line-height: unset;
  height: 32px;
  color: ${(props) => (props.active ? props.fill : '#353C5A')};
  font-weight: ${(props) => (props.active ? 600 : 400)};
`

const ActiveFeedback = styled.div`
  position: absolute;
  z-index: 200;
  display: flex;
  flex-direction: column;
  max-width: 446px;
  background: rgba(0, 0, 0, 0.11);
  bottom: 39px;
  border-radius: 100px;
  padding: 13px 40px;
  backdrop-filter: blur(2px);
  left: 50%;
  transform: translate(-50%, 0%);
  overflow: hidden;

  @media screen and (max-width: 600px) {
    bottom: 5px;
    min-width: 80%;
    max-width: 100%;
    width: fit-content;
  }
`

const ActiveFeedbackHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: white;
  margin-bottom: 6px;
  &::before {
    background-color: white;
    content: '';
    display: inline-block;
    height: 0px;
    width: 0px;
    position: absolute;
    top: 8px;
    left: 7px;
    box-shadow: 0 0 12px 5px white;
    z-index: -1;
  }
`

const ActiveFeedbackHeaderIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  object-fit: contain;
`

const ActiveFeedbackContent = styled.div<{ alignLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  @media screen and (max-width: 600px) {
    align-items: ${({ alignLeft }) => (alignLeft ? 'start' : 'center')};
  }
`

const Date = styled.div`
  background-image: url(${videoCalendar});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 2px;
  padding: 0px 0 0px 13px;
  position: absolute;
  bottom: 93px;
  color: #5d0e8b;
  left: 125px;
  span {
    display: inline-block;
    font-size: 14px;
    padding: 1px 7px;
    margin-left: 17px;
  }
  ${isMobileOnly &&
  css`
    position: unset;
    margin-bottom: 10px;
    margin-left: 79px;
    @media screen and (max-width: 600px) {
      margin-left: 54px;
    }
  `}
`

const Time = styled.div`
  background-image: url(${videoTime});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0;
  padding: 0px 0 0px 10px;
  position: absolute;
  bottom: 50px;
  color: #f1be2b;
  left: 125px;
  span {
    display: inline-block;
    padding: 1px 7px;
    margin-left: 20px;
    font-size: 14px;
  }
  ${isMobileOnly &&
  css`
    position: unset;
    margin-bottom: 10px;
    margin-left: 79px;
    @media screen and (max-width: 600px) {
      margin-left: 54px;
    }
  `}
`

const VideoThumbnailPlaceholder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -1px;
  z-index: 199;
  background-color: #f5f6f8;
  background-image: url(${videoPlaceHolder});
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 14px;
  align-items: baseline;
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 50px;

  & .description {
    font-size: 18px;
    padding: 10px 20px 10px 50px;
    font-weight: 500;
    background: #fff;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 25px;
    position: relative;
    width: 70%;
    margin-left: 30px;
    min-height: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 120%;

    & .company {
      display: inline-block;
      line-height: 110%;
      width: 100%;
      position: relative;
      overflow: hidden;
      padding-right: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-height: 21px;
    }

    & .expert {
      display: inline-block;
      width: 100%;
    }

    & .visual {
      position: absolute;
      height: 100%;
      width: auto;
      top: 0;
      left: -25px;

      img {
        width: auto;
        height: 100%;
      }
    }

    & .arrow {
      position: absolute;
      height: 76px;
      width: 37px;
      left: -7px;
      bottom: -42px;

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  & .logo {
    height: 28px;
    img {
      height: 100%;
      width: auto;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 20px;
    & .time {
      font-size: 12px;
      bottom: 0;
      left: 80px;
      background-size: 12px;
      padding-left: 15px;
      span {
        font-size: 12px;
        margin-left: 0;
      }
    }
    & .date {
      font-size: 12px;
      bottom: 25px;
      left: 80px;
      background-size: 12px;
      padding-left: 15px;
      span {
        font-size: 12px;
        margin-left: 0;
      }
    }

    & .logo {
      height: 18px;
      img {
        height: 100%;
        width: auto;
      }
    }

    & .description {
      height: 60px;
      width: 89%;
      font-size: 14px;
      margin-top: 15px;
      padding-left: 25px;
      justify-content: center;

      & .company {
        overflow: hidden;
        max-height: 30px;
        white-space: normal;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-width: 400px;
        margin: 0 auto;
        font-size: 14px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }

      .expert {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 21px;
      }

      & .arrow {
        height: 53px;
        bottom: -32px;
      }
    }
  }
`

const SwapVideoBtn = styled.a`
  width: 30px;
  height: 30px;
  background-color: rgba(53, 60, 90, 0.32);
  border-radius: 20px;
  padding: 5px;

  > img {
    margin-top: -2px;
  }
  @media screen and (max-width: 600px) {
    margin-left: 5px;
    display: none;
  }
`

const ActiveFeedbackIcon = styled.span`
  width: 40px;
  margin-bottom: 10px;
  img {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 25px;
    margin-bottom: 5px;
  }
`
const ActiveFeedbackText = styled.div`
  font-size: 14px;
  color: #fff;
  line-height: 17px;
  flex: 1;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 14px;
  }
`

interface ISliderStepItem {
  background: string
  left: number
  widthPct?: number
}

const SliderStepItem = styled.div<ISliderStepItem>`
  position: absolute;
  width: ${({ widthPct }) => (widthPct ? `${widthPct}%` : '6px')};
  height: 10px;
  border-radius: 4px;
  background-color: ${({ background }) => background};
  transform: scale(1);
  top: calc(50% - 4px);
  left: ${({ left }) => left}%;
  margin-left: -2px;
`

const NotesContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  max-height: ${({ expanded }) => (expanded ? 270 : 0)}px;
  overflow-y: hidden;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  transition: max-height 0.2s linear;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`

const PlayerButtonIcon = styled.img<{ mirror?: boolean; disabled?: boolean }>`
  width: 42px;
  height: 42px;
  padding: 10px;
  object-fit: contain;
  transform: scale(${({ mirror }) => (mirror ? -1 : 1)}, 1);
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  cursor: ${({ disabled }) => (disabled ? 'arrow' : 'pointer')};
`

const PlayerSpeedBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(115, 121, 145, 0.8);
  font-weight: 400;
  font-size: 12px;
  width: 42px;
  height: 22px;
  border: 2px solid #6938aa;
  border-radius: 2px;
`

const LegendContainer = styled.div`
  display: flex;
  margin-left: ${isMobileOnly ? 0 : 220}px;
  margin-top: ${isMobileOnly ? -4 : -24}px;
`

const LegendLabel = styled.div<{ color: string }>`
  position: relative;
  font-weight: 400;
  font-size: 12px;
  color: #737991;
  padding-left: 22px;
  margin-right: 32px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: ${({ color }) => color};
  }
`

const CodeLinkContainer = styled.div`
  position: absolute;
  top: 20px;
  background: white;
  left: 50%;
  transform: translate(-50%, 0);
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), -3px 13px 15px rgba(0, 0, 0, 0.07);
  display: flex;
  gap: 6px;
  height: 32px;
  justify-content: center;
  padding: 0 12px;

  @media screen and (max-width: 600px) {
    flex: 1;
    width: 100%;
  }
`

const CodeLinkLabelContainer = styled.div`
  display: flex;
  align-items: center;
`

const CodeLinkLabel = styled(Text)`
  cursor: default;
  margin-left: 8px;
`

const CodeLink = styled.a`
  color: #b202ff;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  text-decoration: underline;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 18px;
  }
`

const getText = (note: IExpertNote) => {
  switch (note.icon) {
    case 'WELL_SAID':
      return 'Great'
    case 'CONFUSED':
      return 'Confusing'
    case 'REPHRASE':
      return 'Rephrase'
    case 'NOT_MENTION':
      return 'Leave out'
    default:
      return note.text
  }
}
const getIcon = (note: IExpertNote) => {
  switch (note.icon) {
    case 'WELL_SAID':
      return wellSaidIcon
    case 'CONFUSED':
      return confusedIcon
    case 'REPHRASE':
      return rephraseIcon
    case 'NOT_MENTION':
      return wouldntMentionIcon
    default:
      return null
  }
}

interface IProps {
  recordings: any
  feedback: IExpertNote[]
  personalNotes: IApplicantNote[]
  questions: IExpertNote[]
  screenAcitivties: IExpertNote[]
  activeTimelineInfo: ITimelineInfoType
  duration: number
  setDuration: (n: number) => void
  progress: number
  setProgress: (n: number) => void
  interviewData: any
  onTimelineInfoJump: (item: ITimelineInfoType) => void
  controlsRightMargin: number
  codingPlaybackUrl: string
}

interface IActiveFeedback {
  type: ITimelineInfoType
  text: string
  icon: any
  duration: number
}

const VideoProgressDelayMs = 500
const feedbackChevronColor = '#6938AA'
const notesChevronColor = '#F1BE2B'
const questionsChevronColor = '#B202FF'
const defaultChevronColor = '#edeef2'
const screenActivityChevronColor = '#00DE9F'
const codeActivityChevronColor = '#8F9BB3'

const MinPlayerSpeed = 1
const MaxPlayerSpeed = 2
const PlayerSpeedStep = 0.25

export default forwardRef((props: IProps, ref: any) => {
  const {
    recordings,
    feedback,
    personalNotes,
    questions,
    screenAcitivties,
    activeTimelineInfo,
    duration,
    setDuration,
    progress,
    setProgress,
    interviewData,
    onTimelineInfoJump,
    controlsRightMargin,
    codingPlaybackUrl,
  } = props
  const activeNote = useSelector(activeNoteSelectors.data)
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
  const [playing, setPlaying] = useState(false)
  const [activeFeedback, setActiveFeedback] = useState<IActiveFeedback>(null)
  const [playerSpeed, setPlayerSpeed] = useState(1)
  const timeoutRef = useRef(null)
  // const [activeFeedbackIcon, setActiveFeedbackIcon] = useState<any>()
  const { peer: expertProfile, interview } = interviewData
  const { player } = ref
  const [swapVideo, setSwapVideo] = useState(false)
  const [initVideo, setInitVideo] = useState(false)
  const [showCodingPlayback, setShowCodingPlayback] = useState(false)
  const [sliderLineHeight, setSliderLineHeight] = useState(0)
  const [expandTimelineInfo, setExpandTimelineInfo] = useState<ITimelineInfoType>(
    ITimelineInfoType.None
  )
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const overallRecordingUrl = recordings.overall_recording_url
  const seekTo = (prgrs: number) => {
    const newValue = duration * prgrs
    player.current?.seekTo(newValue)

    const playerElement = player.current?.getInternalPlayer() as HTMLVideoElement
    playerElement?.play()
  }

  const jumpToNextItem = useCallback(
    (infoType: ITimelineInfoType) => {
      const progressInSec = duration * progress
      let nextItem: any
      switch (infoType) {
        case ITimelineInfoType.Notes:
          nextItem = personalNotes.filter((fb) => fb.offset > progressInSec)
          break
        case ITimelineInfoType.Feedback:
          nextItem = feedback.filter((fb) => fb.offset > progressInSec)
          break
        case ITimelineInfoType.Questions:
          nextItem = questions.filter((fb) => fb.offset > progressInSec)
          break
        case ITimelineInfoType.ScreenActivity:
          nextItem = screenAcitivties.filter((fb) => fb.offset > progressInSec)
          break
        default:
        // do nothing
      }

      if (nextItem?.length) {
        const newValue = nextItem[0].offset
        player.current?.seekTo(parseFloat(newValue))
      }
      onTimelineInfoJump(infoType)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration, progress, feedback, questions, screenAcitivties, personalNotes, player]
  )

  const canGoNextItem = useCallback(
    (infoType: ITimelineInfoType) => {
      const progressInSec = duration * progress
      let nextItem: any
      switch (infoType) {
        case ITimelineInfoType.Notes:
          nextItem = personalNotes.filter((fb) => fb.offset > progressInSec)
          break
        case ITimelineInfoType.Feedback:
          nextItem = feedback.filter((fb) => fb.offset > progressInSec)
          break
        case ITimelineInfoType.Questions:
          nextItem = questions.filter((fb) => fb.offset > progressInSec)
          break
        case ITimelineInfoType.ScreenActivity:
          nextItem = screenAcitivties.filter((fb) => fb.offset > progressInSec)
          break
        default:
        // do nothing
      }

      return nextItem?.length
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration, progress, feedback, questions, screenAcitivties, personalNotes, player]
  )

  const canGoPrevItem = useCallback(
    (infoType: ITimelineInfoType) => {
      const progressInSec = +(duration * progress).toFixed(5)
      let nextItem: any
      switch (infoType) {
        case ITimelineInfoType.Notes:
          nextItem = personalNotes.filter((fb) => +fb.offset.toFixed(5) < progressInSec)
          break
        case ITimelineInfoType.Feedback:
          nextItem = feedback.filter((fb) => +fb.offset.toFixed(5) < progressInSec)
          break
        case ITimelineInfoType.Questions:
          nextItem = questions.filter((fb) => +fb.offset.toFixed(5) < progressInSec)
          break
        case ITimelineInfoType.ScreenActivity:
          nextItem = screenAcitivties.filter(
            (fb) => +fb.offset.toFixed(5) < progressInSec
          )
          break
        default:
        // do nothing
      }

      return nextItem?.length
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration, progress, feedback, questions, screenAcitivties, personalNotes, player]
  )

  const showTimelineInfo = (infoType: ITimelineInfoType) => {
    if (expandTimelineInfo === infoType) {
      setExpandTimelineInfo(ITimelineInfoType.None)
    } else {
      onTimelineInfoJump(infoType)
      setExpandTimelineInfo(infoType)
    }
  }

  const jumpToPrevItem = useCallback(
    (infoType: ITimelineInfoType) => {
      const progressInSec = duration * progress - 0.5
      let prevItems: any
      switch (infoType) {
        case ITimelineInfoType.Notes:
          prevItems = personalNotes.filter((fb) => fb.offset < progressInSec)
          break
        case ITimelineInfoType.Feedback:
          prevItems = feedback.filter((fb) => fb.offset < progressInSec)
          break
        case ITimelineInfoType.Questions:
          prevItems = questions.filter((fb) => fb.offset < progressInSec)
          break
        case ITimelineInfoType.ScreenActivity:
          prevItems = screenAcitivties.filter((fb) => fb.offset < progressInSec)
          break
        default:
        // do nothing
      }

      if (prevItems?.length) {
        const newValue = prevItems[prevItems.length - 1].offset
        player.current?.seekTo(parseFloat(newValue))
      }
      onTimelineInfoJump(infoType)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration, progress, feedback, questions, screenAcitivties, personalNotes, player]
  )

  const onEnded = useCallback(() => {
    setPlaying(false)
    player.current?.seekTo(0)
  }, [setPlaying, player])

  const onPlay = useCallback(() => {
    setPlaying(true)
  }, [])

  const SliderBar = ({ direction, value, style }: any) => (
    <div
      style={{
        position: 'absolute',
        background: 'gray',
        borderRadius: 4,
        ...(direction === Direction.HORIZONTAL
          ? {
              top: 'calc(50% - 4px)',
              left: 0,
              width: `${value * 100}%`,
              height: 10,
            }
          : {
              right: 0,
              bottom: 0,
              left: 'calc(50% - 4px)',
              width: 8,
              height: `${value * 100}%`,
            }),
        ...style,
      }}
    />
  )

  const updateSliderLineHeight = useCallback(() => {
    // eslint-disable-next-line no-nested-ternary
    const startHeight = isMobileOnly ? (isLandscape ? 86 : 105) : 48
    // eslint-disable-next-line no-nested-ternary
    const stepHeight = isMobileOnly ? (isLandscape ? 60 : 70) : 60
    if (isMobileOnly) {
      switch (expandTimelineInfo) {
        case ITimelineInfoType.Feedback:
          setSliderLineHeight(startHeight)
          return
        case ITimelineInfoType.Notes:
          if (feedback.length > 0) {
            setSliderLineHeight(startHeight + stepHeight)
          } else {
            setSliderLineHeight(startHeight)
          }
          return
        case ITimelineInfoType.Questions:
          if (feedback.length > 0) {
            if (personalNotes.length > 0) {
              setSliderLineHeight(startHeight + stepHeight + stepHeight)
            } else {
              setSliderLineHeight(startHeight + stepHeight)
            }
          } else {
            setSliderLineHeight(startHeight)
          }
          return
        default:
        // do nothing, go next
      }
    }
    let height = 0
    if (feedback?.length > 0) {
      height += startHeight
    }
    if (personalNotes?.length > 0) {
      height += height > 0 ? stepHeight : startHeight
    }
    if (questions?.length > 0) {
      height += height > 0 ? stepHeight : startHeight
    }
    if (screenAcitivties?.length > 0) {
      height += height > 0 ? stepHeight : startHeight
    }
    setSliderLineHeight(height)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expandTimelineInfo,
    isLandscape,
    feedback,
    personalNotes,
    questions,
    screenAcitivties,
  ])

  useEffect(() => {
    updateSliderLineHeight()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandscape, expandTimelineInfo])

  const swapVideoAction = () => {
    if (!overallRecordingUrl) {
      setSwapVideo(!swapVideo)
    }
  }

  const getActiveFeedbackHeader = (type: ITimelineInfoType) => {
    switch (type) {
      case ITimelineInfoType.Feedback:
        return `${expertProfile.first_name}'s feedback`
      case ITimelineInfoType.Notes:
        return 'Note'
      case ITimelineInfoType.Questions:
        return 'Question'
      default:
        return ''
    }
  }

  const getActiveFeedbackHeaderIcon = (type: ITimelineInfoType) => {
    switch (type) {
      case ITimelineInfoType.Feedback:
        return FeedbackIcon
      case ITimelineInfoType.Notes:
        return NotesIcon
      case ITimelineInfoType.Questions:
        return QuestionIcon
      default:
        return ''
    }
  }

  const go15back = useCallback(() => {
    player.current?.seekTo(duration * progress - 15)
  }, [duration, player, progress])

  const upPlayerSpeed = () => {
    setPlayerSpeed((v) => (v !== MaxPlayerSpeed ? v + PlayerSpeedStep : MaxPlayerSpeed))
  }

  const downPlayerSpeed = () => {
    setPlayerSpeed((v) => (v !== MinPlayerSpeed ? v - PlayerSpeedStep : MinPlayerSpeed))
  }

  useEffect(() => {
    updateSliderLineHeight()
    setExpandTimelineInfo(activeTimelineInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTimelineInfo])

  useEffect(() => {
    const progressInSec = duration * progress
    let possibleActiveFeedback = []
    switch (activeTimelineInfo) {
      case ITimelineInfoType.Feedback:
        possibleActiveFeedback = feedback.filter((note) => {
          return Math.abs(note.offset - progressInSec) < 0.4
        })
        break
      case ITimelineInfoType.Notes:
        possibleActiveFeedback = personalNotes.filter((note) => {
          return Math.abs(note.offset - progressInSec) < 10
        })
        break
      case ITimelineInfoType.Questions:
        possibleActiveFeedback = questions.filter((q) => {
          return Math.abs(q.offset - progressInSec) < 0.4
        })
        break
      default: // do nothing
    }
    const closestFeedback = possibleActiveFeedback[0]
    const newFeedback =
      closestFeedback &&
      (getText(closestFeedback) !== activeFeedback?.text ||
        activeTimelineInfo !== activeFeedback?.type)

    if (closestFeedback && newFeedback) {
      let closeDuration = closestFeedback.duration
      if (activeTimelineInfo === ITimelineInfoType.Notes) {
        closeDuration =
          (20 - 10 + Math.abs(closestFeedback.offset - progressInSec)) * 1000
      }
      setActiveFeedback({
        text: getText(closestFeedback),
        type: activeTimelineInfo,
        icon: getIcon(closestFeedback),
        duration: closeDuration,
      })
      // setActiveFeedbackIcon(getIcon(closestFeedback))
    }
    updateSliderLineHeight()

    const possibleScreenActivity = screenAcitivties.filter((note) => {
      return progressInSec >= note.offset && note.offset + note.duration >= progressInSec
    })
    setShowCodingPlayback(possibleScreenActivity?.length > 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, duration, feedback, personalNotes, questions, screenAcitivties])

  useEffect(() => {
    if (activeNote) {
      setActiveFeedback({
        text: getText(activeNote as any),
        type: activeTimelineInfo,
        icon: getIcon(activeNote as any),
        duration: 5000,
      })
      onEnded()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNote])

  useEffect(() => {
    if (activeFeedback) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        setActiveFeedback(null)
        timeoutRef.current = null
      }, activeFeedback.duration ?? 5000)
    }
  }, [activeFeedback])

  // useWindowResise(updateSliderLineHeight)

  const hasTimelineInfo =
    personalNotes.length > 0 ||
    feedback.length > 0 ||
    questions.length > 0 ||
    screenAcitivties.length > 0

  const hasScreenSharing = screenAcitivties?.some((s) => s.type === 'screen')
  const hasCodeSharing = screenAcitivties?.some((s) => s.type === 'code')

  return (
    <Container>
      <VideoContainer>
        <VideoView expertView={swapVideo}>
          <SwapVideoBtn onClick={swapVideoAction}>
            <img src={swapVideoIcon} alt="Swap video" />
          </SwapVideoBtn>

          {overallRecordingUrl && !initVideo && !playing && progress === 0 && (
            <VideoThumbnailPlaceholder>
              <div className="logo">
                <img src={wizcoLogo} alt="Logo" />
              </div>
              <div className="description">
                <span className="company">
                  Coaching for {interview.desired_position}{' '}
                  {interview.position_company ? ` at ${interview.position_company}` : ''}
                </span>
                <span className="expert">
                  by {expertProfile.first_name} {expertProfile.last_name}
                </span>
                <div className="visual">
                  <img src={videoPlaceHolderShape} alt="" />
                </div>
                <div className="arrow">
                  <img src={videoPlaceHolderArrow} alt="" />
                </div>
              </div>
              <Date>
                <span>{moment(interview.started_at).format('ll')}</span>
              </Date>
              <Time>
                <span>
                  <Duration seconds={duration} />
                </span>
              </Time>
            </VideoThumbnailPlaceholder>
          )}
          <ReactPlayerWrap onDoubleClick={swapVideoAction}>
            <ReactPlayer
              width="100%"
              ref={player}
              playsinline
              height="100%"
              playing={playing}
              volume={1}
              progressInterval={VideoProgressDelayMs}
              onEnded={onEnded}
              onPlay={onPlay}
              onProgress={(e) => setProgress(e.played)}
              onDuration={(e) => {
                setDuration(e)
              }}
              playbackRate={playerSpeed}
              url={[
                {
                  src: overallRecordingUrl || recordings.expert_recording_url,
                  type: 'video/mp4',
                },
              ]}
            />
          </ReactPlayerWrap>
        </VideoView>
        {showCodingPlayback && (
          <CodeLinkContainer>
            <CodeLinkLabelContainer>
              <SvgCode fill="#00de9f" />
              <CodeLinkLabel>View coding playback.</CodeLinkLabel>
            </CodeLinkLabelContainer>
            <CodeLink href={codingPlaybackUrl} target="_blank">
              View playback
            </CodeLink>
          </CodeLinkContainer>
        )}

        {activeFeedback && (
          <ActiveFeedback>
            <ActiveFeedbackHeader>
              <ActiveFeedbackHeaderIcon
                src={getActiveFeedbackHeaderIcon(activeFeedback.type)}
              />
              {getActiveFeedbackHeader(activeFeedback.type)}
            </ActiveFeedbackHeader>
            <ActiveFeedbackContent alignLeft={!activeFeedback.icon}>
              {activeFeedback.icon && (
                <ActiveFeedbackIcon>
                  <img src={activeFeedback.icon} alt={activeFeedback.text} />
                </ActiveFeedbackIcon>
              )}
              <ActiveFeedbackText>{activeFeedback.text}</ActiveFeedbackText>
            </ActiveFeedbackContent>
          </ActiveFeedback>
        )}
      </VideoContainer>
      <Controls>
        <Playback>
          <PlayerButtonIcon src={Icon15} onClick={go15back} />
          {playing && (
            <PlayerIcon.Pause
              width={32}
              height={32}
              style={{ margin: 5, fill: '#B202FF' }}
              onClick={() => {
                setPlaying(false)
              }}
            />
          )}
          {!playing && (
            <PlayerButtonIcon
              src={PlayIcon}
              onClick={() => {
                setPlaying(true)
                setInitVideo(true)
              }}
            />
          )}
          <PlayerButtonIcon
            src={IconPlayerSpeed}
            onClick={downPlayerSpeed}
            disabled={playerSpeed === MinPlayerSpeed}
          />
          <PlayerSpeedBlock>{`x ${playerSpeed}`}</PlayerSpeedBlock>
          <PlayerButtonIcon
            src={IconPlayerSpeed}
            mirror
            onClick={upPlayerSpeed}
            disabled={playerSpeed === MaxPlayerSpeed}
          />
        </Playback>
      </Controls>
      {!isMobile && (
        <DurationContainer>
          <Duration seconds={duration * progress} />
          <span style={{ opacity: 0.5, whiteSpace: 'pre-wrap' }}> / </span>
          <Duration seconds={duration} />
        </DurationContainer>
      )}
      <BottomArea marginRight={controlsRightMargin}>
        <SliderContainer short>
          <VideoSeekSlider
            max={duration}
            currentTime={duration * progress}
            onChange={(time) => {
              player.current?.seekTo(time)
            }}
            sliderLineHeight={sliderLineHeight}
            style={{
              height: '8px',
              flex: 1,
              width: '100%',
              marginLeft: `${hasTimelineInfo && !isMobileOnly ? 220 : 0}px`,
            }}
          />
        </SliderContainer>
        {isMobile && (
          <DurationContainer
            enableConversationIntelligence={
              interviewData.interview.enable_conversation_intelligence || hasTimelineInfo
            }
          >
            <Duration seconds={duration * progress} />
            <span style={{ opacity: 0.5, whiteSpace: 'pre-wrap' }}> / </span>
            <Duration seconds={duration} />
          </DurationContainer>
        )}
        {feedback?.length > 0 && (
          <>
            <SliderContainer>
              <JumpContainer>
                <JumpToFeedbackContainer>
                  <JumpToFeedbackIconContainer
                    mirror
                    onClick={() => jumpToPrevItem(ITimelineInfoType.Feedback)}
                    disabled={!canGoPrevItem(ITimelineInfoType.Feedback)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.Feedback
                          ? feedbackChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                  <JumpToFeedbackText
                    active={activeTimelineInfo === ITimelineInfoType.Feedback}
                    fill={feedbackChevronColor}
                  >
                    {expertProfile.first_name}&apos;s feedback
                  </JumpToFeedbackText>
                  <JumpToFeedbackIconContainer
                    onClick={() => jumpToNextItem(ITimelineInfoType.Feedback)}
                    disabled={!canGoNextItem(ITimelineInfoType.Feedback)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.Feedback
                          ? feedbackChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                </JumpToFeedbackContainer>
                {isMobileOnly && (
                  <JumpContainerButton
                    active={expandTimelineInfo === ITimelineInfoType.Feedback}
                    onClick={() => showTimelineInfo(ITimelineInfoType.Feedback)}
                  >
                    <JumpContainerButtonIcon src={FeedbackIcon} />
                  </JumpContainerButton>
                )}
              </JumpContainer>
              <Slider
                direction={Direction.HORIZONTAL}
                style={{ height: '8px', flex: 1, width: '100%' }}
              >
                <SliderBar
                  direction={Direction.HORIZONTAL}
                  value={1}
                  style={{ background: defaultChevronColor }}
                />
                {feedback
                  .filter((fb) => fb.offset < duration)
                  .map((fb) => (
                    <SliderStepItem
                      key={fb.id}
                      background={feedbackChevronColor}
                      left={(Math.floor(fb.offset) / duration) * 100}
                    />
                  ))}
              </Slider>
            </SliderContainer>
            <NotesContainer
              expanded={isMobileOnly && expandTimelineInfo === ITimelineInfoType.Feedback}
            >
              <DropdownBlock
                expanded
                enableConversationIntelligence={
                  interviewData.interview.enable_conversation_intelligence
                }
                title={`${expertProfile?.first_name}'s feedback`}
                icon={FeedbackIcon}
                accentColor="#6938AA"
                seekTo={seekTo}
                notes={feedback}
                duration={duration}
                progress={progress}
                maxHeight={270}
              />
            </NotesContainer>
          </>
        )}
        {personalNotes?.length > 0 && (
          <>
            <SliderContainer>
              <JumpContainer>
                <JumpToFeedbackContainer>
                  <JumpToFeedbackIconContainer
                    mirror
                    onClick={() => jumpToPrevItem(ITimelineInfoType.Notes)}
                    disabled={!canGoPrevItem(ITimelineInfoType.Notes)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.Notes
                          ? notesChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                  <JumpToFeedbackText
                    active={activeTimelineInfo === ITimelineInfoType.Notes}
                    fill={notesChevronColor}
                  >
                    Personal Notes
                  </JumpToFeedbackText>
                  <JumpToFeedbackIconContainer
                    onClick={() => jumpToNextItem(ITimelineInfoType.Notes)}
                    disabled={!canGoNextItem(ITimelineInfoType.Notes)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.Notes
                          ? notesChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                </JumpToFeedbackContainer>
                {isMobileOnly && (
                  <JumpContainerButton
                    active={expandTimelineInfo === ITimelineInfoType.Notes}
                    onClick={() => showTimelineInfo(ITimelineInfoType.Notes)}
                  >
                    <JumpContainerButtonIcon src={NotesIcon} />
                  </JumpContainerButton>
                )}
              </JumpContainer>
              <Slider
                direction={Direction.HORIZONTAL}
                style={{ height: '8px', flex: 1, width: '100%' }}
              >
                <SliderBar
                  direction={Direction.HORIZONTAL}
                  value={1}
                  style={{ background: defaultChevronColor }}
                />
                {personalNotes
                  .filter((note) => note.offset < duration)
                  .map((note) => (
                    <SliderStepItem
                      key={note.id}
                      background={notesChevronColor}
                      left={(Math.floor(note.offset) / duration) * 100}
                    />
                  ))}
              </Slider>
            </SliderContainer>
            <NotesContainer
              expanded={isMobileOnly && expandTimelineInfo === ITimelineInfoType.Notes}
            >
              <DropdownBlock
                expanded
                enableConversationIntelligence={
                  interviewData.interview.enable_conversation_intelligence
                }
                title={`${expertProfile?.first_name}'s notes`}
                icon={FeedbackIcon}
                accentColor="#6938AA"
                seekTo={seekTo}
                notes={personalNotes as IExpertNote[]}
                duration={duration}
                progress={progress}
                maxHeight={270}
              />
            </NotesContainer>
          </>
        )}
        {questions?.length > 0 && (
          <>
            <SliderContainer>
              <JumpContainer>
                <JumpToFeedbackContainer>
                  <JumpToFeedbackIconContainer
                    mirror
                    onClick={() => jumpToPrevItem(ITimelineInfoType.Questions)}
                    disabled={!canGoPrevItem(ITimelineInfoType.Questions)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.Questions
                          ? questionsChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                  <JumpToFeedbackText
                    active={activeTimelineInfo === ITimelineInfoType.Questions}
                    fill={questionsChevronColor}
                  >
                    Questions
                  </JumpToFeedbackText>
                  <JumpToFeedbackIconContainer
                    onClick={() => jumpToNextItem(ITimelineInfoType.Questions)}
                    disabled={!canGoNextItem(ITimelineInfoType.Questions)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.Questions
                          ? questionsChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                </JumpToFeedbackContainer>
                {isMobileOnly && (
                  <JumpContainerButton
                    active={expandTimelineInfo === ITimelineInfoType.Questions}
                    onClick={() => showTimelineInfo(ITimelineInfoType.Questions)}
                  >
                    <JumpContainerButtonIcon src={QuestionIcon} />
                  </JumpContainerButton>
                )}
              </JumpContainer>
              <Slider
                direction={Direction.HORIZONTAL}
                style={{ height: '8px', flex: 1, width: '100%' }}
              >
                <SliderBar
                  direction={Direction.HORIZONTAL}
                  value={1}
                  style={{ background: defaultChevronColor }}
                />
                {questions
                  .filter((question) => question.offset < duration)
                  .map((question) => (
                    <SliderStepItem
                      key={question.id}
                      background={questionsChevronColor}
                      left={(Math.floor(question.offset) / duration) * 100}
                    />
                  ))}
              </Slider>
            </SliderContainer>
            <NotesContainer
              expanded={
                isMobileOnly && expandTimelineInfo === ITimelineInfoType.Questions
              }
            >
              <DropdownBlock
                expanded
                enableConversationIntelligence={
                  interviewData.interview.enable_conversation_intelligence
                }
                title="Questions"
                icon={QuestionIcon}
                accentColor="#B202FF"
                showIcons={false}
                seekTo={seekTo}
                notes={questions}
                duration={duration}
                progress={progress}
                maxHeight={270}
              />
            </NotesContainer>
          </>
        )}
        {screenAcitivties?.length > 0 && (
          <>
            <SliderContainer>
              <JumpContainer>
                <JumpToFeedbackContainer>
                  <JumpToFeedbackIconContainer
                    mirror
                    onClick={() => jumpToPrevItem(ITimelineInfoType.ScreenActivity)}
                    disabled={!canGoPrevItem(ITimelineInfoType.ScreenActivity)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.ScreenActivity
                          ? questionsChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                  <JumpToFeedbackText
                    active={activeTimelineInfo === ITimelineInfoType.ScreenActivity}
                    fill={questionsChevronColor}
                  >
                    Screen activity
                  </JumpToFeedbackText>
                  <JumpToFeedbackIconContainer
                    onClick={() => jumpToNextItem(ITimelineInfoType.ScreenActivity)}
                    disabled={!canGoNextItem(ITimelineInfoType.ScreenActivity)}
                  >
                    <SvgChevronRight
                      fill={
                        activeTimelineInfo === ITimelineInfoType.ScreenActivity
                          ? questionsChevronColor
                          : defaultChevronColor
                      }
                    />
                  </JumpToFeedbackIconContainer>
                </JumpToFeedbackContainer>
              </JumpContainer>
              <Slider
                direction={Direction.HORIZONTAL}
                style={{ height: '8px', flex: 1, width: '100%' }}
              >
                <SliderBar
                  direction={Direction.HORIZONTAL}
                  value={1}
                  style={{ background: defaultChevronColor }}
                />
                {screenAcitivties
                  .filter((item) => item.offset < duration)
                  .map((item) => (
                    <SliderStepItem
                      key={item.id}
                      background={
                        item.type === 'screen'
                          ? screenActivityChevronColor
                          : codeActivityChevronColor
                      }
                      left={(Math.floor(item.offset) / duration) * 100}
                      widthPct={(item.duration / duration) * 100}
                    />
                  ))}
              </Slider>
            </SliderContainer>
            <LegendContainer>
              {hasScreenSharing && (
                <LegendLabel color={screenActivityChevronColor}>
                  screen sharing
                </LegendLabel>
              )}
              {hasCodeSharing && (
                <LegendLabel color={codeActivityChevronColor}>code sharing</LegendLabel>
              )}
            </LegendContainer>
          </>
        )}
      </BottomArea>
    </Container>
  )
})
