import { put, takeEvery, call } from 'typed-redux-saga'

import { setRecordingTimestamp } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface ISetRecordingTimestampAction {
  interviewToken: string
  recordingTimestamp: number
}

const {
  action: setRecordingTimestampAction,
  actionFailure: setRecordingTimestampActionFailure,
  actionSuccess: setRecordingTimestampActionSuccess,
  actionSuccessClear: setRecordingTimestampActionSuccessClear,
  actionFailureClear: setRecordingTimestampActionFailureClear,
  actionType: setRecordingTimestampActionType,
  actionTypeFailure: setRecordingTimestampActionFailureType,
  actionTypeSuccess: setRecordingTimestampActionSuccessType,
  reducer: setRecordingTimestampReducer,
  selectors: setRecordingTimestampSelectors,
} = reduxRequestFactory<ISetRecordingTimestampAction>('setRecordingTimestamp', 'requests')

export {
  setRecordingTimestampAction,
  setRecordingTimestampActionFailure,
  setRecordingTimestampActionSuccess,
  setRecordingTimestampActionSuccessClear,
  setRecordingTimestampActionFailureClear,
  setRecordingTimestampActionType,
  setRecordingTimestampActionFailureType,
  setRecordingTimestampActionSuccessType,
  setRecordingTimestampReducer,
  setRecordingTimestampSelectors,
}

function* setRecordingTimestampSaga(action: ISagaAction<ISetRecordingTimestampAction>) {
  try {
    const { interviewToken, recordingTimestamp } = action.payload
    yield* call(setRecordingTimestamp, interviewToken, recordingTimestamp)
    yield put(setRecordingTimestampActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      setRecordingTimestampActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchSetRecordingTimestamp() {
  yield takeEvery(setRecordingTimestampActionType, setRecordingTimestampSaga)
}
