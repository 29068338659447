import React from 'react'

function SvgExpert({ fill }: { fill: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 17.8218L10.5916 7.88721H13.1833L15.7749 17.8218L11.8875 21.2773L8 17.8218Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5917 4H13.1833L14.4792 4.86388L13.1833 7.88745H10.5917L9.2959 4.86388L10.5917 4Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgExpert
