/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { get } from 'lodash'

import { Button, Checkbox, Text, Select, Textarea } from 'src/components'

import * as agencySelectors from '../selectors'
import { formattedDateShort } from '../../utils/date'
import { updatePostSessionInfoAction } from '../redux'
import { InterviewStatus } from '../../api'

const PostSessionInfoWrapper = styled.div`
  display: grid;
  padding: 32px 15px 12px 15px;
  grid-template-columns: 3fr 2fr;
`

const Row = styled.div`
  display: flex;
`

const Form = styled.form`
  margin-top: 0px;
`

const ButtonsWrapper = styled.div`
  display: flex;
`

const ButtonStyled = styled(Button)`
  margin-top: 20px;
`

const InfoItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`

const Name = styled(Text)`
  font-size: 14px;
  flex: 0 0 40%;
`

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
`

const Value = styled(Text)`
  font-size: 14px;
  flex: 0 0 60%;
`

const SelectWrapper = styled.div<{ width?: number }>`
  width: ${(props) => props.width ?? 200}px;

  div {
    padding: 0;
    height: 44px;

    div {
      div {
        div {
          padding-top: 37px !important;
        }
      }
    }
  }
`

const LeftWrapper = styled.div``
const RightWrapper = styled.div``

const PaymentProviders = [
  { value: 'Paypal', label: 'Paypal' },
  { value: 'Amazon gift card', label: 'Amazon gift card' },
  { value: 'Bank transfer', label: 'Bank transfer' },
  { value: 'Other', label: 'Other' },
]

const EmployedOptions = [
  { value: 'Yes - to the desired position', label: 'Yes - to the desired position' },
  { value: 'Yes - to a different position', label: 'Yes - to a different position' },
  { value: 'No', label: 'No' },
]

const PostSessionInfo = () => {
  const interview = useSelector(agencySelectors.interview.interview)
  const feedbackRating: number = get(interview, 'interview.feedback_rating', '')
  const expertRating: number = get(interview, 'interview.feedback_expert_rating', '')
  const feedbackText: string = get(interview, 'interview.feedback_text', '')
  const { id, interviewId } = useParams()
  const dispatch = useDispatch()
  const hiredCandidate: string = get(interview, 'interview.hired_candidate', null)
  const expertPaid: boolean = get(interview, 'interview.expert_paid', false)
  const paymentProvider: string = get(interview, 'interview.expert_payment_provider', '')
  const paymentReference: string = get(
    interview,
    'interview.expert_payment_reference',
    ''
  )
  const isCompletedInterview =
    get(interview, 'interview.status', '') === InterviewStatus.COMPLETED
  const status_check = interview?.interview.interview_status_check

  const { handleSubmit, control, formState } = useForm({
    mode: 'all',
    defaultValues: {
      hiredCandidate,
      expertPaid: false,
      paymentProvider: '',
      paymentReference: '',
    },
  })

  const { isDirty } = formState
  const [isDirtyForce, setIsDirtyForce] = useState(false)

  const handleChange = () => {
    setIsDirtyForce(true)
  }

  const onSubmit = (payload) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(`Are you sure you want to save changes?`)
    if (confirmed) {
      dispatch(
        updatePostSessionInfoAction({
          agencyId: id,
          id: interviewId,
          hired_candidate: payload.hiredCandidate,
          expert_paid: payload.expertPaid,
          expert_payment_provider: payload.paymentProvider,
          expert_payment_reference: payload.paymentReference,
        })
      )
      setIsDirtyForce(false)
    }
  }

  return (
    <PostSessionInfoWrapper>
      <LeftWrapper>
        <Row>
          <Title>Applicant feedback</Title>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <InfoItem>
              <Name>Audio and video quality</Name>
              <Value>{feedbackRating ? `${feedbackRating}/5` : 'Not provided'}</Value>
            </InfoItem>
          </Row>
          <Row>
            <InfoItem>
              <Name>Expert rating</Name>
              <Value>{expertRating ? `${expertRating}/5` : 'Not provided'}</Value>
            </InfoItem>
          </Row>
          <Row>
            <InfoItem>
              <Name>Open feedback</Name>
              <Value>{feedbackText || 'Not provided'}</Value>
            </InfoItem>
          </Row>
          <Row>
            <Title>Expert payment info</Title>
          </Row>
          <Row onClick={handleChange}>
            <Controller
              as={Checkbox}
              name="expertPaid"
              control={control}
              useTheme
              defaultChecked={expertPaid}
              disabled={!isCompletedInterview}
              defaultValue={false}
            >
              PAID
            </Controller>
          </Row>
          <Row>
            <SelectWrapper>
              <Controller
                name="paymentProvider"
                control={control}
                render={({ onChange, value, name }) => (
                  <Select
                    name={name}
                    label="Payment provider"
                    onChange={(ev) => {
                      onChange(ev)
                      handleChange()
                    }}
                    value={value ?? null}
                    options={PaymentProviders}
                    useTheme
                    disabled={!isCompletedInterview}
                  />
                )}
                defaultValue={paymentProvider}
                useTheme
              />
            </SelectWrapper>
          </Row>
          <Row>
            <InfoItem>
              <Controller
                as={Textarea}
                label="Payment reference"
                name="paymentReference"
                control={control}
                defaultValue={paymentReference}
                disabled={!isCompletedInterview}
                useTheme
              />
            </InfoItem>
          </Row>
          <Row>
            <Title>Other</Title>
          </Row>
          <Row>
            <SelectWrapper width={400}>
              <Controller
                name="hiredCandidate"
                control={control}
                render={({ onChange, value, name }) => (
                  <Select
                    name={name}
                    label={`Applicant employed with 90 days of use (${formattedDateShort(
                      interview.interview.ended_at,
                      90
                    )})`}
                    onChange={(ev) => {
                      onChange(ev)
                      handleChange()
                    }}
                    value={value ?? null}
                    options={EmployedOptions}
                    useTheme
                    disabled={!isCompletedInterview}
                  />
                )}
                defaultValue={hiredCandidate}
                useTheme
              />
            </SelectWrapper>
          </Row>
          <Row>
            <ButtonsWrapper>
              <ButtonStyled
                useTheme
                type="submit"
                title="Update info"
                disabled={!isCompletedInterview || (!isDirtyForce && !isDirty)}
              />
            </ButtonsWrapper>
          </Row>
        </Form>
      </LeftWrapper>
      <RightWrapper>
        <Title>Session logs</Title>
        <Value>Session started: {interview?.interview.started_at || 'N/A'}</Value>
        <Value>Session ended: {interview?.interview.ended_at || 'N/A'}</Value>
        <Value>
          User in cockpit 5 mins after the scheduled time: Applicant -{' '}
          {status_check?.candidate_joined_5min != null
            ? status_check.candidate_joined_5min
              ? 'Yes'
              : 'No'
            : 'N/A'}{' '}
          / Expert -{' '}
          {status_check?.expert_joined_5min != null
            ? status_check.expert_joined_5min
              ? 'Yes'
              : 'No'
            : 'N/A'}
        </Value>
        <Value>
          User in cockpit 9 mins after the scheduled time: Applicant -{' '}
          {status_check?.candidate_joined_9min != null
            ? status_check.candidate_joined_9min
              ? 'Yes'
              : 'No'
            : 'N/A'}{' '}
          / Expert -{' '}
          {status_check?.expert_joined_9min != null
            ? status_check.expert_joined_9min
              ? 'Yes'
              : 'No'
            : 'N/A'}
        </Value>
      </RightWrapper>
    </PostSessionInfoWrapper>
  )
}

export default PostSessionInfo
