import { useSelector } from 'react-redux'
import * as interviewSelectors from 'src/interview/selectors'

const useBranding = () => {
  const agency = useSelector(interviewSelectors.agency)

  const agencyColor =
    !agency.main_color || agency.main_color === '' ? '#B202FF' : agency.main_color

  return {
    agencyColor,
  }
}

export default useBranding
