import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import styled from '@emotion/styled'
import reportIcon from '../../static/report-white.svg'
import * as interviewSelectors from '../selectors'
import { validateAgencyLogoWhite } from '../../utils/validateAgencyLogo'

const LogoContainer = styled.div`
  height: 52px;
  margin-bottom: 70px;

  @media screen and (max-width: 600px) {
    height: 35px;
  }
`

const Logo = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
`

const Heading = styled.h1`
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 65px;
  margin-top: 0;
  margin-bottom: 40px;

  @media screen and (max-width: 600px) {
    font-size: 35px;
    line-height: 120%;
  }
`

const Details = styled.div`
  width: 250px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    display: none;
  }
`

const DetailsItem = styled.div`
  width: 48%;
  margin-bottom: 10px;
`
const DetailsTextValue = styled.h3`
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin: 0;
`
const DetailsImgContainer = styled.div`
  height: 40px;
  display: flex;
`
const DetailsImgValue = styled.img``
const DetailsDescription = styled.p`
  color: #ffffff;
  font-family: Rubik;
  font-size: 13px;
  letter-spacing: 0;
  margin: 0;
  width: 96px;
  margin-top: 5px;
`

function Sidebar() {
  const agency = useSelector(interviewSelectors.agency)
  const interviewDuration = get(agency, 'duration', 60)

  return (
    <>
      <LogoContainer>
        <Logo src={validateAgencyLogoWhite(agency.negative_logo?.url)} />
      </LogoContainer>
      <Heading>It pays to be prepared.</Heading>
      <Details>
        <DetailsItem>
          <DetailsTextValue>{interviewDuration}</DetailsTextValue>
          <DetailsDescription>Minute coaching session</DetailsDescription>
        </DetailsItem>
        <DetailsItem>
          <DetailsTextValue>1:1</DetailsTextValue>
          <DetailsDescription>Feedback session</DetailsDescription>
        </DetailsItem>
        <DetailsItem>
          <DetailsImgContainer>
            <DetailsImgValue src={reportIcon} />
          </DetailsImgContainer>
          <DetailsDescription>Personalised report & Video recording</DetailsDescription>
        </DetailsItem>
        <DetailsItem>
          <DetailsTextValue>5,000+</DetailsTextValue>
          <DetailsDescription>Experts for perfect match</DetailsDescription>
        </DetailsItem>
      </Details>
    </>
  )
}

export default Sidebar
