import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

import SvgCode from 'src/static/components/SvgCode'

import * as interviewSelectors from '../../../interview/selectors'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

const CodeShareButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

const HintMessage = styled.div`
  position: absolute;
  top: 80px;
  background: #000000;
  color: white;
  width: 285px;
  left: -67px;
  font-size: 15px;
  text-align: center;
  padding: 10px 14px;
  line-height: 19.4px;
  border-radius: 40px;
  font-weight: 400;
  z-index: 1000;

  @media screen and (max-width: 2500px) {
    top: 67px;
    width: 238px;
    left: -62px;
    font-size: 13px;
    padding: 9px 14px;
    line-height: 16.4px;
  }
  @media screen and (max-width: 2000px) {
    top: 51px;
    width: 190px;
    left: -65px;
    font-size: 12px;
    padding: 7px 13px;
    line-height: 14.4px;
  }
  @media screen and (max-width: 1200px) {
    top: 39px;
    width: 143px;
    left: -47px;
    font-size: 8px;
    padding: 5px 10px;
    line-height: 12.4px;
  }
  @media screen and (max-width: 900px) {
    top: 26px;
    width: 95px;
    left: -38px;
    font-size: 6px;
    padding: 3px 8px;
    line-height: 9.4px;
  }
`
interface ICodeShareButton extends React.HTMLProps<HTMLDivElement> {
  active: boolean
  disabled: boolean
  theme: any
  inScreenSharing: boolean
  peerInScreenSharing: boolean
  isTransparentDisabled: boolean
  onClick: () => void
}

export default function CodeShareButton({
  active,
  disabled,
  theme,
  inScreenSharing,
  peerInScreenSharing,
  isTransparentDisabled,
  onClick,
  children,
}: ICodeShareButton) {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const [hintVisible, setHintVisible] = useState(false)
  const hintScreenshare = "You can't join the coding environment while screen sharing"
  const hintPeerScreenshare = `You can't join the coding environment while ${peer.first_name} is sharing their screen`

  function onMouseEnter() {
    setHintVisible(true)
    setTimeout(() => setHintVisible(false), 3000)
  }

  return (
    <HeaderButtonContainer>
      <HeaderIconContainer
        disabled={disabled}
        isTransparentDisabled={isTransparentDisabled}
        onClick={!disabled ? onClick : undefined}
        onMouseEnter={inScreenSharing || peerInScreenSharing ? onMouseEnter : undefined}
      >
        <SvgCode fill={active ? theme.textColorActive : theme.textColor} />
        <CodeShareButtonLabel active={active}>Code</CodeShareButtonLabel>
      </HeaderIconContainer>
      {inScreenSharing && hintVisible && <HintMessage>{hintScreenshare}</HintMessage>}
      {peerInScreenSharing && !inScreenSharing && hintVisible && (
        <HintMessage>{hintPeerScreenshare}</HintMessage>
      )}
      {children}
    </HeaderButtonContainer>
  )
}
