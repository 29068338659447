import { useEffect, useRef } from 'react'

function observeDOM(obj, callback) {
  const { MutationObserver } = window
  if (!obj || obj.nodeType !== 1) return

  if (MutationObserver) {
    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(obj, { childList: true, subtree: true })
    // eslint-disable-next-line consistent-return
    return mutationObserver
  }

  // browser support fallback
  if (window.addEventListener) {
    obj.addEventListener('DOMNodeInserted', callback, false)
    obj.addEventListener('DOMNodeRemoved', callback, false)
  }
}

const useSupport = () => {
  const intercomButtons = useRef<HTMLElement[]>([])

  const openSupport = () => {
    intercomButtons.current.forEach((button) => button.click())
  }

  useEffect(() => {
    observeDOM(document.body, (m) => {
      m.forEach((record) => {
        if (record.addedNodes.length > 0) {
          record.addedNodes.forEach((node) => {
            const nodeElement = node as HTMLElement
            if (nodeElement.classList) {
              if (nodeElement.classList.contains('intercom-launcher')) {
                intercomButtons.current.push(nodeElement)
                nodeElement.hidden = true
              }
              if (nodeElement.classList.contains('intercom-app')) {
                const launchers = nodeElement.getElementsByClassName(
                  'intercom-launcher-frame'
                )
                if (launchers.length > 0) {
                  const launchFrame = launchers[0] as HTMLElement
                  intercomButtons.current.push(launchFrame)
                  launchFrame.hidden = true
                }
              }
            }
          })
        }
      })
    })
    const iLaunchers = document.getElementsByClassName('intercom-launcher')
    if (iLaunchers.length > 0) {
      const launcher = iLaunchers[0] as HTMLElement
      intercomButtons.current.push(launcher)
      launcher.hidden = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    openSupport,
  }
}

export default useSupport
