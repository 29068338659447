import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import logger from 'redux-logger'
import rootSaga from './rootSaga'
import rootReducer from './rootReducer'
import httpClient from '../httpClient'
import { signOut } from '../auth/actions'

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  sagaMiddleware,
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
]

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}

middleware.push(routerMiddleware(history))

const store = configureStore({
  reducer: rootReducer(history),
  middleware,
})

export default store

sagaMiddleware.run(rootSaga)

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      return store.dispatch(signOut())
    }

    return Promise.reject(error)
  }
)

export type RootState = ReturnType<typeof store.getState>
