import React from 'react'
import styled from '@emotion/styled'

import { IExpertNoteMood } from '../../api'
import reactions from './Reactions'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 40%;
  margin: 8px 0;
  height: calc(100% - 16px);
  border: 1px solid #353c5a;
  border-radius: 4px;
  padding: 8px 8px 0 8px;

  @media screen and (max-width: 600px) {
    width: 40%;
  }
`

const QuickReactionContainer = styled.div<{ mood: string }>`
  font-weight: 400;
  height: fit-content;
  font-size: 12px;
  line-height: 14px;
  padding: 4px;
  border-radius: 12px;
  height: fit-content;
  background: ${({ mood }) => (mood === 'Positive' ? '#c8f4e8' : '#fcd6e6')};
  margin-right: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }
`

const MobileContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: fit-content;
  width: 100%;
`

interface IQuickReactions extends React.HTMLProps<HTMLDivElement> {
  sendNote: (text: string, mood?: IExpertNoteMood) => void
}

export function QuickReactions({ children, sendNote }: IQuickReactions) {
  const handleSelect = ({ mood, text }) => {
    sendNote(text, mood)
  }

  return (
    <Container>
      {reactions.map((reaction) => (
        <QuickReactionContainer
          key={reaction.text}
          mood={reaction.mood}
          onClick={() => handleSelect(reaction)}
        >
          {reaction.text}
        </QuickReactionContainer>
      ))}
      {children}
    </Container>
  )
}

interface IMobileQuickReactions {
  sendNote: (text: string, mood?: IExpertNoteMood) => void
}

export function MobileQuickReactions({ sendNote }: IMobileQuickReactions) {
  const handleSelect = ({ mood, text }) => {
    sendNote(text, mood)
  }

  return (
    <MobileContainer>
      {reactions.map((reaction) => (
        <QuickReactionContainer
          key={reaction.text}
          mood={reaction.mood}
          onClick={() => handleSelect(reaction)}
        >
          {reaction.text}
        </QuickReactionContainer>
      ))}
    </MobileContainer>
  )
}
