import { AvaStep } from 'src/ava/models'
import { OnboardingStep } from 'src/ava/containers/OnboardingContainer'
import { IntroStep } from 'src/ava/containers/IntroContainer'
import { reduxDataFactory } from '../../../utils/redux'

const {
  action: updateAvaStepAction,
  actionType: updateAvaStepActionType,
  reducer: avaStepDataReducer,
  selectors: avaStepSelectors,
} = reduxDataFactory<AvaStep>('step', 'ava', AvaStep.Landing)

export interface IAvaOnboardingStep {
  onboardingStep: OnboardingStep
}

const DefaultAvaOnboardingSettings: IAvaOnboardingStep = {
  onboardingStep: OnboardingStep.Greeting,
}

const {
  action: updateAvaOnboardingStepAction,
  actionType: updateAvaOnboardingStepActionType,
  reducer: avaOnboardingStepDataReducer,
  selectors: avaOnboardingStepSelectors,
} = reduxDataFactory<IAvaOnboardingStep>(
  'onboardingStep',
  'ava',
  DefaultAvaOnboardingSettings
)

const {
  action: updateAvaIntroStepAction,
  actionType: updateAvaIntroStepActionType,
  reducer: avaIntroStepDataReducer,
  selectors: avaIntroStepSelectors,
} = reduxDataFactory<IntroStep>('introStep', 'ava', IntroStep.UserName)

const clearAvaStepAction = updateAvaStepAction(AvaStep.Landing)
const clearAvaOnboardingStepAction = updateAvaOnboardingStepAction(
  DefaultAvaOnboardingSettings
)
const clearAvaIntroStepAction = updateAvaIntroStepAction(IntroStep.UserName)

export {
  updateAvaStepAction,
  updateAvaStepActionType,
  avaStepDataReducer,
  avaStepSelectors,
  updateAvaOnboardingStepAction,
  updateAvaOnboardingStepActionType,
  avaOnboardingStepDataReducer,
  avaOnboardingStepSelectors,
  updateAvaIntroStepAction,
  updateAvaIntroStepActionType,
  avaIntroStepDataReducer,
  avaIntroStepSelectors,
  clearAvaStepAction,
  clearAvaOnboardingStepAction,
  clearAvaIntroStepAction,
}
