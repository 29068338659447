import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import SvgClose from 'src/static/close_gray.svg'
import { NavButton } from './elements/styled-components'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(9px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DataContainer = styled.div`
  background: #ffffff;
  border: 1px solid #b202ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 549px;
  padding: 40px;
`

const HeadContainer = styled.div`
  display: flex;
  width: 100;
`

const Title = styled.div`
  font-size: 32px;
  line-height: 34px;
  color: #1d0e3b;
  font-weight: 700;
  margin-bottom: 12px;
  flex: 1;
  @media screen and (max-width: 2500px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px;
    line-height: 18px;
  }
`

const AfterTitle = styled.div`
  font-size: 24px;
  line-height: 26px;
  color: #1d0e3b;
  font-weight: 500;
  margin: 4px 0 12px 0;
  flex: 1;
  @media screen and (max-width: 2500px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media screen and (max-width: 900px) {
    font-size: 8px;
    line-height: 10px;
  }
`

const BottomButton = styled(NavButton)`
  width: fit-content;
  height: auto;
  border-radius: 60px;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  align-self: center;
`

const CloseButton = styled.img`
  cursor: pointer;

  height: 18px;
  width: 18px;
  @media screen and (max-width: 2500px) {
    height: 16px;
    width: 16px;
  }
  @media screen and (max-width: 2000px) {
    height: 14px;
    width: 14px;
  }
  @media screen and (max-width: 1200px) {
    height: 12px;
    width: 12px;
  }
  @media screen and (max-width: 900px) {
    height: 10px;
    width: 10px;
  }
`

const ContentText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #3c424e;
  margin-bottom: 10px;
  white-space: break-spaces;
`

export enum ErrorType {
  PermissionDenied,
  DeviceError,
}

interface IProps {
  errorType: ErrorType
  onClose: () => void
}

const ErrorPopup = ({ errorType, onClose }: IProps) => {
  const getTitle = useCallback(() => {
    switch (errorType) {
      case ErrorType.PermissionDenied:
        return `Your microphone is blocked`
      case ErrorType.DeviceError:
        return `Device not found`
      default:
        return ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType])

  const getContent = useCallback(() => {
    switch (errorType) {
      case ErrorType.PermissionDenied:
        return `1. Click the microphone blocked icon in your browser’s bar\n2. Allow access and then refresh the page`
      case ErrorType.DeviceError:
        return `TBD`
      default:
        return ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorType])

  const onReload = () => {
    window.location.reload()
  }

  return (
    <Container>
      <DataContainer>
        <HeadContainer>
          <Title>{getTitle()}</Title>
          <CloseButton onClick={onClose} src={SvgClose} />
        </HeadContainer>
        <AfterTitle>Please follow the next steps:</AfterTitle>
        <ContentText>{getContent()}</ContentText>
        <BottomButton onClick={onReload}>Refresh</BottomButton>
      </DataContainer>
    </Container>
  )
}

export default ErrorPopup
