import styled from '@emotion/styled'
import { get, find, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { IExpertProfile, IInvoice, InterviewStatus, ITimeSlot } from '../../api'
import { Avatar, Button, Textarea, Checkbox, Select, Text } from '../../components'
import { updateSchedulingInfoAction } from '../redux'
import { interview as agencyInterviewSelector } from '../selectors'

const Form = styled.form`
  margin-top: 20px;
`

const FormTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
`

const FormsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
`

const FormWrapper = styled.div`
  width: 48%;
`

const BlockWrapper = styled.div`
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid #eee;
  margin-bottom: 30px;
`

const Row = styled.div`
  display: flex;
  > div {
    width: 100%;
  }
  > div:last-of-type {
    margin-left: 10px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    > div > div {
      width: 100%;
    }
  }
  .react-datepicker-popper {
    z-index: 999;
  }
`

const SelectedExpertInfo = styled.div``

const ExpertInfo = styled.div`
  display: flex;
  align-items: center;
`

const UserTextInfo = styled.div``

const Username = styled(Text)`
  font-weight: 500;
  font-size: 16px;
`

const UserEmail = styled(Text)`
  font-size: 14px;
`

const ExpertCheckboxWrapper = styled.div`
  margin-top: 20px;
`

const InvoiceDetails = styled.div``

const CardInfoBlock = styled.div`
  display: flex;
  margin: 10px 0;
  font-size: 14px;
`
const CardInfoBlockName = styled.div`
  flex: 0 0 50%;
`
const CardInfoBlockValue = styled.div`
  flex: 0 0 50%;
`

const ButtonsWrapper = styled.div`
  display: flex;
`

const ButtonStyled = styled(Button)`
  margin: 10px;
  && {
    margin-top: 40px;
  }
`

const validInterviewStatuses = [InterviewStatus.MATCHED, InterviewStatus.SCHEDULED]

const formatSlot = (date) => {
  if (date) {
    return date === 'N/A'
      ? 'N/A'
      : moment.utc(date).format('MMMM Do YYYY, HH:mm ([UTC/GMT])')
  }
  return '--:--'
}

const SchedulingForm = () => {
  const dispatch = useDispatch()
  const { id, interviewId } = useParams()

  const [selectedExpertForm, setSelectedExpertForm] = useState('')

  const interview = useSelector(agencyInterviewSelector.interview)
  const selectedExpert: Partial<IExpertProfile> = get(interview, 'expert_profile', {})
  const mainSlotId: string = get(interview, 'interview.main_time_slot_id', '')
  const expertConfirmed: boolean = get(interview, 'interview.expert_confirmed', false)
  const expertPassed: boolean = get(interview, 'interview.expert_passed_training', false)

  const invoicePaid: boolean = get(interview, 'interview.invoice_paid', false)
  const invoice: IInvoice = get(interview, 'invoice', {})

  const experts: IExpertProfile[] = get(interview, 'expert_profiles', [])
  const expertsOptions = experts.map((exp) => ({
    label: `${exp.first_name} ${exp.last_name}`,
    value: exp.id,
  }))

  const selectedExpertSlots = (get(
    find(experts, ['id', selectedExpertForm]),
    'time_slots',
    []
  ) as ITimeSlot[]).map((slot: ITimeSlot) => ({
    value: slot.id,
    label: formatSlot(slot.from),
  }))

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    formState: { isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: {
      selectedExpert: selectedExpert ? selectedExpert.id : '',
      selectedSlot: mainSlotId || null,
      adminComment: null,
      expertPassed,
      expertConfirmed,
    },
  })

  const onSubmit = (payload) => {
    const confirmed = window.confirm('Are you sure you want to update Scheduling info?')

    if (confirmed) {
      dispatch(
        updateSchedulingInfoAction({
          agencyId: id,
          id: interviewId,
          interview: {
            main_time_slot_id: payload.selectedSlot,
            expert_confirmed: payload.expertConfirmed,
            expert_passed_training: payload.expertPassed,
          },
          reschedule_request: {
            comment: payload.adminComment,
          },
        })
      )
    }
  }

  const handleExpertChange = (expertId) => {
    setSelectedExpertForm(expertId)
    setValue('selectedSlot', null)
  }

  const isValidInterviewStatus = validInterviewStatuses.includes(
    interview?.interview?.status
  )

  useEffect(() => {
    if (!isEmpty(selectedExpert)) {
      setSelectedExpertForm(selectedExpert.id)
    }
  }, [selectedExpert])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormsWrapper>
        <FormWrapper>
          <FormTitle>Coaching Details</FormTitle>
          <BlockWrapper>
            <Row>
              <Controller
                as={Select}
                name="selectedExpert"
                options={expertsOptions}
                useTheme
                error={errors?.selectedExpert}
                label="Selected expert"
                control={control}
                customOnchange={handleExpertChange}
                disabled={!isValidInterviewStatus}
              />
            </Row>
            <Row>
              <Controller
                as={Select}
                name="selectedSlot"
                options={selectedExpertSlots}
                useTheme
                error={errors?.selectedSlot}
                label="Selected slot"
                control={control}
                disabled={!isValidInterviewStatus}
              />
            </Row>
            {isDirty && (
              <>
                <Row>
                  <Controller
                    as={Textarea}
                    label="System admin comment"
                    name="adminComment"
                    control={control}
                    error={errors?.adminComment}
                    rules={{
                      maxLength: { value: 250, message: 'Max characters : 250' },
                    }}
                    value=""
                    useTheme
                    disabled={!isValidInterviewStatus}
                  />
                </Row>
                <ButtonsWrapper>
                  <ButtonStyled
                    disabled={!isValidInterviewStatus}
                    useTheme
                    type="submit"
                    title="Update scheduling info"
                  />
                </ButtonsWrapper>
              </>
            )}
          </BlockWrapper>
        </FormWrapper>

        <FormWrapper>
          {selectedExpert && (
            <SelectedExpertInfo>
              <FormTitle>Expert Details</FormTitle>
              <BlockWrapper>
                <ExpertInfo>
                  <Avatar user={selectedExpert} size={80} />
                  <UserTextInfo>
                    <Username>{`${selectedExpert.first_name} ${selectedExpert.last_name}`}</Username>
                    <UserEmail>{`${selectedExpert.email}`}</UserEmail>
                  </UserTextInfo>
                </ExpertInfo>
                <ExpertCheckboxWrapper>
                  <Controller
                    as={Checkbox}
                    name="expertConfirmed"
                    control={control}
                    useTheme
                    defaultChecked={expertConfirmed}
                    disabled={!isValidInterviewStatus}
                  >
                    Expert confirmed
                  </Controller>

                  <Controller
                    as={Checkbox}
                    name="expertPassed"
                    control={control}
                    useTheme
                    defaultChecked={expertPassed}
                    disabled={!isValidInterviewStatus}
                  >
                    Expert passed training
                  </Controller>
                </ExpertCheckboxWrapper>
              </BlockWrapper>
            </SelectedExpertInfo>
          )}
          {invoicePaid && (
            <InvoiceDetails>
              <FormTitle>Payment Details</FormTitle>
              <BlockWrapper>
                <CardInfoBlock>
                  <CardInfoBlockName>Transaction code</CardInfoBlockName>
                  <CardInfoBlockValue>{`${invoice.id}`}</CardInfoBlockValue>
                </CardInfoBlock>
                <CardInfoBlock>
                  <CardInfoBlockName>Card</CardInfoBlockName>
                  <CardInfoBlockValue>{`${invoice.payment_method_info.card.brand} **** ${invoice.payment_method_info.card.last4}`}</CardInfoBlockValue>
                </CardInfoBlock>
                <CardInfoBlock>
                  <CardInfoBlockName>Value</CardInfoBlockName>
                  <CardInfoBlockValue>{`${invoice.amount} ${invoice.currency}`}</CardInfoBlockValue>
                </CardInfoBlock>
                <CardInfoBlock>
                  <CardInfoBlockName>Replay</CardInfoBlockName>
                  <CardInfoBlockValue>{invoicePaid && 'Paid'}</CardInfoBlockValue>
                </CardInfoBlock>
              </BlockWrapper>
            </InvoiceDetails>
          )}
        </FormWrapper>
      </FormsWrapper>
    </Form>
  )
}

export default SchedulingForm
