export default function getClearLink(link: string) {
  if (!link) return link
  return link.replace('http://', '').replace('https://', '')
}

const getBaseAddress = () => {
  const isProd = window.location.host.indexOf('wizco.io') !== -1
  return `https://www.wizco.${isProd ? 'io' : 'dev'}`
}

const getTermsOfuseLink = () => {
  return `${getBaseAddress()}/terms-of-use`
}

const getPrivacyLink = () => {
  return `${getBaseAddress()}/privacy-policy`
}

export { getTermsOfuseLink, getPrivacyLink, getBaseAddress }
