import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import colors from '../static/colors'

const themes = {
  default: {
    borderColor: colors.formActiveAccentColor,
  },
  blue: {
    borderColor: '#0095FF',
  },
}

const disabledStyles = {
  borderColor: '#E1E3EA',
  backgroundColor: '#F5F6F8',
  opacity: '0.6',
  color: '#21304E',
}

interface IStyledInputProps {
  isSelected: boolean
  disabled: boolean
  useTheme?: boolean
  hasError: boolean
  customTheme?: string
  customOnchange?: any
}

const getColor = ({ customTheme, theme, useTheme }: any) => {
  if (theme.agencyColor && useTheme) {
    return theme.agencyColor
  }

  if (themes[customTheme]) {
    return themes[customTheme].borderColor
  }

  return '#B202FF'
}

const ScrollBlocker = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  overflow: hidden;
  background: transparent;
`
const StyledWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  height: 56px;
  border: 1px solid #e1e3ea;
  border-radius: 6px;
  background-color: #f5f6f8;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 10}px;
`

const StyledFormControl = styled(FormControl)`
  background-color: #f5f6f8;
  width: 100%;
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-size: 12px;
    font-family: 'Rubik';
    font-weight: 500;
    position: relative;
  }
`

const StyledSelect = styled(Select)<IStyledInputProps>`
  outline: none;
  margin-top: 0 !important;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  /* border-color: ${({ customTheme, hasError, isSelected, theme, useTheme }) => {
    if (hasError) return 'transparent'
    if (isSelected) return getColor({ customTheme, theme, useTheme })

    return 'transparent'
  }}; */
  border-color: transparent;
  /* background: ${({ isSelected }) => (isSelected ? '#fff' : 'transparent')}; */
  background: transparent;
  border-radius: 4px;

  color: #21304e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;

  &.Mui-focused {
    background: transparent;
  }

  .MuiSelect-root {
    background: none !important;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Rubik';
    padding-left: 9px;
    padding-top: 18px;
  }

  &:disabled {
    ${disabledStyles};
    border-color: transparent;
    cursor: not-allowed;
  }

  &:focus {
    background-color: transparent !important;
    border-color: ${({ customTheme, hasError, theme, useTheme }) => {
      if (hasError) return 'transparent'
      if (customTheme || useTheme) return getColor({ customTheme, theme, useTheme })

      return 'transparent'
    }};
  }

  &:before,
  &:after {
    display: none;
  }
`

const StyledLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    top: -13px !important;
    line-height: 19px !important;
    transform: translate(9px, 15px) scale(1);
    font-size: 12px;
    font-family: 'Rubik';
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.5;
    color: #21304e;
    border: 0;
    margin: 0;
    align-items: center;
    display: flex;
    justify-content: left;
    width: 92%;
    height: 40px;

    &.Mui-focused,
    &.MuiFormLabel-filled {
      color: #21304e;
      z-index: 2;
      font-size: 10px;
      line-height: 12px !important;
      transform: translate(9px, 9px) scale(1);
      width: 100%;
      height: 20px;
      top: -6px !important;

      @media screen and (max-width: 600px) {
        top: -8px !important;
        line-height: 10px !important;
      }
    }
  }
`

const SelectComponent = (props: any) => {
  const {
    onChange,
    value,
    label,
    options,
    name,
    error,
    disabled,
    customTheme,
    useTheme,
    customOnchange,
    multiple,
    marginBottom,
  } = props
  const hasError = !!error.type

  const [opened, setOpened] = useState(false)

  useEffect(() => {
    const page = document.getElementsByTagName('body')[0]
    page.style.touchAction = 'pan-y'
    document.addEventListener('scroll', () => {
      const popoverRoot = document.getElementsByClassName('MuiPopover-root')[0]
      const multiRoot = Array.from(
        document.getElementsByClassName('MuiFormControl-root') as HTMLCollectionOf<
          HTMLElement
        >
      )

      let topOffest = 0
      multiRoot.forEach((ulElement) => {
        // eslint-disable-next-line no-param-reassign
        topOffest = ulElement.offsetParent?.getBoundingClientRect().top
      })
      if (popoverRoot) {
        const optionsDiv = popoverRoot.getElementsByTagName('div')[2]
        optionsDiv.style.top = `${topOffest}px`
      }
    })
  }, [])

  const handleScrollPlaceClick = () => {
    const popoverRoot = document.getElementsByClassName('MuiPopover-root')[0]
    const divOne = popoverRoot.getElementsByTagName('div')[0]
    divOne.click()
  }

  /* IPHONE SCROLL ISSUE HACK   */
  const handleScrollPlate = () => {
    const popoverRoot = document.getElementsByClassName('MuiPopover-root')[0]
    const optionsDiv = popoverRoot.getElementsByTagName('div')[2]
    const divOne = popoverRoot.getElementsByTagName('div')[0]
    const divTwo = popoverRoot.getElementsByTagName('div')[1]
    const divTree = popoverRoot.getElementsByTagName('div')[2]
    const divFour = popoverRoot.getElementsByTagName('div')[3]
    const divFive = Array.from(
      popoverRoot.getElementsByClassName('MuiList-root') as HTMLCollectionOf<HTMLElement>
    )
    const divSix = Array.from(
      popoverRoot.getElementsByClassName('MuiListItem-root') as HTMLCollectionOf<
        HTMLElement
      >
    )
    const element = document.createElement('div')

    element.addEventListener('click', handleScrollPlaceClick)
    divOne.style.touchAction = 'none'
    divTwo.style.touchAction = 'none'
    divTree.style.touchAction = 'none'
    divFour.style.touchAction = 'none'
    divFive.forEach((ulElement) => {
      // eslint-disable-next-line no-param-reassign
      ulElement.style.touchAction = 'none'
    })
    divSix.forEach((ulElement) => {
      // eslint-disable-next-line no-param-reassign
      ulElement.style.touchAction = 'none'
    })
    // divFive.style.touchAction = 'none'
    optionsDiv.style.touchAction = 'none'
    optionsDiv.style.zIndex = '101'
    element.style.cssText =
      'position:fixed;width:100%;height:100%;z-index:100;touch-action: none;inset: 0px;'
    if (popoverRoot) {
      popoverRoot.append(element)
      // popoverRoot.style.touchAction = 'none'
    }
  }

  const handleScroll = (status) => {
    setOpened(status)
    const page = document.getElementsByTagName('html')[0]

    if (status) {
      page.classList.add('noscroll')
      setTimeout(() => {
        handleScrollPlate()
      }, 50)
    } else {
      page.classList.remove('noscroll')
    }
  }

  /* IPHONE SCROLL ISSUE HACK   */

  const renderValue = (v) => {
    if (typeof v === 'undefined') {
      return v
    }

    if (typeof options === 'undefined') {
      return v
    }

    const option = options.find((o) => o.value === v)

    if (typeof option === 'undefined') {
      return v
    }

    return option.label
  }

  return (
    <StyledWrapper marginBottom={marginBottom}>
      {opened && <ScrollBlocker />}
      <StyledFormControl disabled={disabled} error={hasError}>
        <StyledLabel id={name}>{label}</StyledLabel>
        <StyledSelect
          onClose={() => {
            handleScroll(false)
          }}
          labelId={name}
          renderValue={renderValue}
          onChange={(e) => {
            onChange(e.target.value)
            if (customOnchange) customOnchange(e.target.value)
          }}
          useTheme={useTheme}
          disabled={disabled}
          hasError={hasError}
          isSelected={!!value}
          defaultValue={value ?? ''}
          customTheme={customTheme}
          multiple={multiple}
          displayEmpty
          value={value}
        >
          {options.map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
              {option.icon && option.icon}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </StyledWrapper>
  )
}

SelectComponent.defaultProps = {
  error: {
    type: '',
    message: '',
    ref: '',
  },
  useTheme: false,
  customTheme: '',
  options: [],
}

export default SelectComponent
