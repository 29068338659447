/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react'
import useState from 'react-usestateref'
import styled from '@emotion/styled'
import axios from 'axios'
import { isMobileOnly } from 'react-device-detect'
import { useSelector } from 'react-redux'
import SvgCancelUploading from 'src/static/components/SvgCancelUploading'
import { ICVFile, uploadCV } from '../../api'
import cvIcon from '../../static/cv_icon.png'
import { Button, DropContainer, HyperLink, TextBold, ProgressBar } from '../../components'
import * as interviewSelectors from '../selectors'

const Container = styled.div`
  height: 78px;
  background: #f5f6f8;
  border: 1px solid rgba(143, 155, 179, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const VerticalContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin: 6px 0;
`

const HorizontalContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 16px;
  width: 100%;
`

const UploadButton = styled(Button)`
  width: 162px;
  height: 30px;
  margin: 0;
`

const CvIcon = styled.img`
  width: 25px;
  height: auto;
`

const CloseIcon = styled.div`
  width: 21px;
  height: 21px;
  cursor: pointer;
`

const FileNameText = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #222b45;
  flex: 1;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const CvLink = styled(HyperLink)`
  font-weight: 400;
  line-height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const UploadingResultText = styled(FileNameText)<{ success: boolean }>`
  color: ${(props) => (props.success ? '#14BB8C' : '#FF3D71')};
`

const PercentLabel = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #8f9bb3;
  margin: 0;
`

const ProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin: 0 16px;
`

const FileNameBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`

const TextBoldStyled = styled(TextBold)`
  margin: 0px;
  line-height: 12px;
`

const DefaulErrorText = 'Upload failed. Please try again.'
const InvalidFileErrorText = 'Invalid file'
const BigFileErrorText = 'File is to big'
const FileSizeLimitKb = 10000
const AvailableFileTypeList = ['.pdf', '.doc', '.docx', '.jpg', '.png']

interface IProps {
  onUploaded: (fileId: string) => void
  file?: ICVFile
  useLink?: boolean
}

export default function FileUploadView({ onUploaded, file, useLink }: IProps) {
  const hiddenFileInput = React.useRef(null)
  const agency = useSelector(interviewSelectors.agency)

  const [step, setStep] = useState('pickFile')
  const [selectedFile, setSelectedFile, selectedFileRef] = useState(null)
  const [errorText, setErrorText] = useState(DefaulErrorText)
  const [progress, setProgress] = useState(0)
  const [uploadingSuccess, setUploadingSuccess] = useState(false)
  const [selectedFileUrl, setSelectedFileUrl] = useState('')

  const cancelTokenSource = useRef(axios.CancelToken.source())

  function onPickFile() {
    console.log('[onPickFile]')
    hiddenFileInput.current.click()
  }

  function onCancelUploading() {
    // CANCEL UPLOADING
    cancelTokenSource.current.cancel()
    setStep('pickFile')
    setProgress(0)
    setUploadingSuccess(false)
    onUploaded('')
  }

  function clearFile() {
    setStep('pickFile')
    setProgress(0)
    setUploadingSuccess(true)
    onUploaded('')
  }

  async function upload() {
    setStep('uploading')
    try {
      const uploadResult = await uploadCV(
        selectedFileRef.current,
        (prog) => {
          setProgress(prog)
        },
        cancelTokenSource.current.token
      )
      if (uploadResult.success) {
        setUploadingSuccess(true)
        setSelectedFileUrl(uploadResult.file.file.url)
        onUploaded(uploadResult.file.id)
      } else {
        setUploadingSuccess(false)
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        // cancelled by user
      } else {
        setUploadingSuccess(false)
      }
    }
    setStep('finished')
  }

  function onFileDrop(dropFile) {
    setSelectedFile(dropFile)
    const extPattern = /(?:\.([^.]+))?$/
    const fileExtension = `.${extPattern.exec(dropFile.name)[1]}`
    if (!AvailableFileTypeList.some((t) => t === fileExtension)) {
      setUploadingSuccess(false)
      setErrorText(InvalidFileErrorText)
      setStep('finished')
    } else if (dropFile.size / 1024 > FileSizeLimitKb) {
      setUploadingSuccess(false)
      setErrorText(BigFileErrorText)
      setStep('finished')
    } else {
      upload()
    }
  }

  function onFilePicked(event: any) {
    const pickedFile = event.target.files[0]
    setSelectedFile(pickedFile)
    upload()
  }

  useEffect(() => {
    if (file) {
      setSelectedFile({ name: file.file_name })
      setSelectedFileUrl(file.file.url)
      setUploadingSuccess(true)
      setStep('finished')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderStep(currentStep: string) {
    switch (currentStep) {
      case 'pickFile':
        return (
          <VerticalContainer>
            {!isMobileOnly && <TextBoldStyled>Drag your resume here</TextBoldStyled>}
            <UploadButton
              type="button"
              title="Upload resume"
              onClick={onPickFile}
              useTheme
            />
            <input
              type="file"
              accept={AvailableFileTypeList.reduce((result, item) => {
                return `${result}, ${item}`
              })}
              ref={hiddenFileInput}
              hidden
              onChange={onFilePicked}
            />
          </VerticalContainer>
        )
      case 'uploading':
        return (
          <HorizontalContainer>
            <CvIcon src={cvIcon} />
            <ProgressBlock>
              <FileNameBlock>
                <FileNameText>{selectedFile.name}</FileNameText>
                <PercentLabel>{progress * 100}%</PercentLabel>
              </FileNameBlock>
              <ProgressBar progress={progress} color={agency.main_color} />
            </ProgressBlock>
            <CloseIcon onClick={onCancelUploading}>
              <SvgCancelUploading fill={agency.main_color} />
            </CloseIcon>
          </HorizontalContainer>
        )
      case 'finished':
        return (
          <HorizontalContainer>
            <CvIcon src={cvIcon} />
            <ProgressBlock>
              <FileNameBlock>
                {useLink ? (
                  <CvLink href={selectedFileUrl} target="_blank">
                    {selectedFile.name}
                  </CvLink>
                ) : (
                  <FileNameText>{selectedFile.name}</FileNameText>
                )}
              </FileNameBlock>
              <UploadingResultText success={uploadingSuccess}>
                {uploadingSuccess ? 'Successfully uploaded' : errorText}
              </UploadingResultText>
            </ProgressBlock>
            <CloseIcon onClick={clearFile}>
              <SvgCancelUploading fill={agency.main_color} />
            </CloseIcon>
          </HorizontalContainer>
        )
      default:
        return null
    }
  }

  return (
    <Container>
      <DropContainer onFileDrop={onFileDrop}>{renderStep(step)}</DropContainer>
    </Container>
  )
}

FileUploadView.defaultProps = {
  file: null,
  useLink: false,
}
