import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 315px;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
  border-radius: 44.5px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 82px;
  transform: translate(-50%, 0);
  left: 50vw;
`

const Button = styled.button`
  height: 25px;
  width: 98px;
  font-size: 12px;
  border-radius: 12px;
  border: none;
  color: #fff;
  background-color: #b202ff;
  font-weight: 500;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const StyledTitle = styled.p`
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0;
`

export default function SharingPopup({ stopSharing }: { stopSharing: () => void }) {
  return (
    <Container>
      <StyledTitle>You are screen sharing</StyledTitle>
      <Button onClick={stopSharing}>Stop sharing</Button>
    </Container>
  )
}
