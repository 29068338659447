import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getAuthorizationToken, hasAccessToAgencyOverview } from 'src/utils/jwtToken'

// eslint-disable-next-line
function SuperAdminRoute({ children, ...rest }) {
  const token = getAuthorizationToken()

  return token ? (
    <Route
      // eslint-disable-next-line
      {...rest}
      render={() =>
        hasAccessToAgencyOverview() ? children : <Redirect to="/agencies" />
      }
    />
  ) : (
    <Redirect to={{ pathname: '/user/login' }} />
  )
}

export default SuperAdminRoute
