import { put, takeEvery, call } from 'typed-redux-saga'

import { avaGetInterview } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { updateAvaInterviewAction } from '../../data/ava/interview'
import { conversationAction } from '../../data/conversation'

export interface IAvaGetInterviewAction {
  interviewToken: string
}

const {
  action: avaGetInterviewAction,
  actionFailure: avaGetInterviewActionFailure,
  actionSuccess: avaGetInterviewActionSuccess,
  actionSuccessClear: avaGetInterviewActionSuccessClear,
  actionFailureClear: avaGetInterviewActionFailureClear,
  actionType: avaGetInterviewActionType,
  actionTypeFailure: avaGetInterviewActionFailureType,
  actionTypeSuccess: avaGetInterviewActionSuccessType,
  reducer: avaGetInterviewRequestReducer,
  selectors: avaGetInterviewSelectors,
} = reduxRequestFactory<IAvaGetInterviewAction>('avaGetInterview', 'requests')

export {
  avaGetInterviewAction,
  avaGetInterviewActionFailure,
  avaGetInterviewActionSuccess,
  avaGetInterviewActionSuccessClear,
  avaGetInterviewActionFailureClear,
  avaGetInterviewActionType,
  avaGetInterviewActionFailureType,
  avaGetInterviewActionSuccessType,
  avaGetInterviewRequestReducer,
  avaGetInterviewSelectors,
}

export function* avaGetInterviewSaga(action: ISagaAction<IAvaGetInterviewAction>) {
  try {
    const { interviewToken } = action.payload
    const { conversation, interview } = yield* call(avaGetInterview, interviewToken)
    yield put(updateAvaInterviewAction(interview))
    yield put(conversationAction(conversation))
    yield put(avaGetInterviewActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaGetInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaGetInterview() {
  yield takeEvery(avaGetInterviewActionType, avaGetInterviewSaga)
}
