import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { useMediaQuery } from '@material-ui/core'
import LogoImg from '../../static/logo_big.png'
import BackgroundImg from '../../static/expert-finalbacground.png'
import MobileBackgroundBottomImg from '../../static/mobile_bottom_background.png'
import MobileBackgroundTopImg from '../../static/mobile_top_background.png'
import MobileBackgroundLeftImg from '../../static/mobile_left_background.png'
import MobileBackgroundRightImg from '../../static/mobile_right_background.png'
import { SelectingState } from '../types'
import useSupport from '../services/useSupport'
import * as interviewSelectors from '../selectors'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-direction: column;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      flex-direction: row;
    }
  `}
`

const Logo = styled.div`
  position: absolute;
  top: 62px;
  left: ${isMobileOnly ? 30 : 58}px;
  background: url(${LogoImg});
  background-size: 100% 100%;
  height: ${isMobileOnly ? 27 : 45}px;
  width: ${isMobileOnly ? 110 : 202}px;
`

const DataContainer = styled.div`
  width: ${isMobileOnly ? '100%' : '847px'};
  height: ${isMobileOnly ? 'auto' : '480px'};
  display: flex;
  padding-bottom: ${isMobileOnly ? 0 : 81}px;
  padding-top: ${isMobileOnly ? 0 : 195}px;
  padding-left: ${isMobileOnly ? 15 : 0}px;
  padding-right: ${isMobileOnly ? 15 : 0}px;
  background: ${isMobileOnly ? 'white' : ` url(${BackgroundImg})`};
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
`

const TextBlock = styled.div<{ margin?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.margin ?? 0}px;
`

const TextLine = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: #1d0e3b;
  text-align: center;
`

const Details = styled.div`
  font-weight: 400;
  font-size: 16px;
  max-width: ${isMobileOnly ? 250 : 511}px;
  color: #1d0e3b;
  text-align: center;

  ${isMobileOnly &&
  css`
    margin-top: 8px;
    @media (orientation: landscape) {
      max-width: 385px;
    }
  `}
`

const BotomText = styled.div`
  font-weight: 400;
  white-space: pre-wrap;
  font-size: 14px;
  color: #3b424f;
`

const TextButton = styled(BotomText)`
  cursor: pointer;
  color: #b202ff;
`

const BottomLink = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #b202ff;
`

const MobileBackgroundItem = styled.img<{ padding?: number }>`
  width: 100%;
  height: auto;
  padding: 25px;
  object-fit: contain;
  padding-top: ${(props) => props.padding ?? 0}%;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      width: auto;
      height: 70%;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 0;
    }
  `}
`

interface IProps {
  state: SelectingState
}

function AvailabilityExpertFinal({ state }: IProps) {
  const support = useSupport()
  const interviewDetails = useSelector(interviewSelectors.interview)
  const isLandscape = useMediaQuery('(orientation: landscape)')
  function headerText() {
    switch (state) {
      case SelectingState.Success:
        return 'Thank you for providing availability!'
      case SelectingState.Decline:
        return 'Thanks for the update!'
      case SelectingState.Expire:
        return 'Sorry, the link has expired.'
      default:
        return ''
    }
  }

  function detailsText() {
    switch (state) {
      case SelectingState.Success:
        return `We'll email you as soon as ${interviewDetails.peer.first_name} has selected their preferred time slot.`
      case SelectingState.Decline:
        return `We'll be in touch soon with a new applicant match.`
      case SelectingState.Expire:
        return "The applicant has already been matched with a different expert. We'll be in touch soon with a new applicant match."
      default:
        return ''
    }
  }

  return (
    <Container>
      {isMobileOnly && (
        <MobileBackgroundItem
          src={isLandscape ? MobileBackgroundLeftImg : MobileBackgroundTopImg}
          padding={isMobileOnly && isLandscape ? 10 : 0}
        />
      )}
      <DataContainer>
        <TextBlock>
          <Title>{headerText()}</Title>
          <Details>{detailsText()}</Details>
        </TextBlock>
        <TextBlock margin={isMobileOnly ? 20 : 69}>
          <BotomText>Have any questions?</BotomText>
          <TextLine>
            <BotomText>Check out our </BotomText>
            <BottomLink href="https://www.wizco.io/pages/faq" target="_blank">
              FAQ
            </BottomLink>
            <BotomText> or contact our </BotomText>
            <TextButton onClick={() => support.openSupport()}>Support Team</TextButton>
          </TextLine>
        </TextBlock>
      </DataContainer>
      {isMobileOnly && (
        <MobileBackgroundItem
          src={isLandscape ? MobileBackgroundRightImg : MobileBackgroundBottomImg}
        />
      )}
      <Logo />
    </Container>
  )
}

export default AvailabilityExpertFinal
