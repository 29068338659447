import { put, takeEvery, call } from 'typed-redux-saga'

import { candidateRescheduleInterview } from '../../api'
import { getInterview as getInterviewAction } from '../../interview/actions'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface ICandidateRescheduleInterviewAction {
  interviewToken: string
  useTheSameExpert?: boolean
  selectedDays?: []
  availabilityDays?: []
  availabilityTime?: []
  additionalText?: string
  selectedExpertId?: string
}

const {
  action: candidateRescheduleInterviewAction,
  actionFailure: candidateRescheduleInterviewActionFailure,
  actionSuccess: candidateRescheduleInterviewActionSuccess,
  actionSuccessClear: candidateRescheduleInterviewActionSuccessClear,
  actionFailureClear: candidateRescheduleInterviewActionFailureClear,
  actionType: candidateRescheduleInterviewActionType,
  actionTypeFailure: candidateRescheduleInterviewActionFailureType,
  actionTypeSuccess: candidateRescheduleInterviewActionSuccessType,
  reducer: candidateRescheduleInterviewRequestReducer,
  selectors: candidateRescheduleInterviewSelectors,
} = reduxRequestFactory<ICandidateRescheduleInterviewAction>(
  'candidateRescheduleInterview',
  'requests'
)

export {
  candidateRescheduleInterviewAction,
  candidateRescheduleInterviewActionFailure,
  candidateRescheduleInterviewActionSuccess,
  candidateRescheduleInterviewActionSuccessClear,
  candidateRescheduleInterviewActionFailureClear,
  candidateRescheduleInterviewActionType,
  candidateRescheduleInterviewActionFailureType,
  candidateRescheduleInterviewActionSuccessType,
  candidateRescheduleInterviewRequestReducer,
  candidateRescheduleInterviewSelectors,
}

function* candidateRescheduleInterviewSaga(
  action: ISagaAction<ICandidateRescheduleInterviewAction>
) {
  try {
    const {
      interviewToken,
      useTheSameExpert,
      selectedDays = [],
      availabilityDays = {} as any,
      availabilityTime = {} as any,
      additionalText,
      selectedExpertId,
    } = action.payload
    yield* call(
      candidateRescheduleInterview,
      interviewToken,
      {
        candidate_available_at: selectedDays.map((t) => Math.floor(t / 1000)),
        availability_days: availabilityDays,
        availability_time: availabilityTime,
      },
      {
        comment: additionalText,
        use_same_expert: useTheSameExpert,
        expert_ids: selectedExpertId !== '-1' ? [selectedExpertId] : undefined,
      }
    )
    yield put(candidateRescheduleInterviewActionSuccess())
    yield put(getInterviewAction({ interviewToken }))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      candidateRescheduleInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchCandidateRescheduleInterview() {
  yield takeEvery(
    candidateRescheduleInterviewActionType,
    candidateRescheduleInterviewSaga
  )
}
