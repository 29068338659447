import { call, delay, put, race, take } from 'typed-redux-saga'

import store from 'src/redux/store'
import { PromptStatus, avaInterviewStatus } from '../../api'
import { reduxWatcherFactory } from '../../utils/redux'
import {
  avaGetInterviewAction,
  avaGetInterviewActionSuccessType,
} from '../requests/ava/getInterview'
import { updateAvaQuestionAction } from '../data/ava/question'

interface IAvaInterviewFollowUpWatcherAction {
  interviewToken: string
}

const {
  startAction: startAvaInterviewFollowUpWatcherAction,
  startActionType: startAvaInterviewFollowUpWatcherActionType,
  stopAction: stopAvaInterviewFollowUpWatcherAction,
  stopActionType: stopAvaInterviewFollowUpWatcherActionType,
} = reduxWatcherFactory<IAvaInterviewFollowUpWatcherAction>('avaInterviewFollowUp')

function* avaInterviewFollowUpWatcher(interviewToken) {
  while (true) {
    try {
      const interview = yield* call(avaInterviewStatus, interviewToken)
      if (
        [PromptStatus.Done, PromptStatus.Failed].includes(
          interview?.prompt_status.follow_up
        )
      ) {
        yield put(avaGetInterviewAction({ interviewToken }))
        yield take(avaGetInterviewActionSuccessType)

        const state = store.getState()
        const { questions } = state.ava.interview.data
        const currentQuestion = questions.find(
          (q) => q.id === state.ava.question.data?.id
        )
        const nextQuestion =
          currentQuestion.follow_up[0] ||
          questions.find((q) => q.order === currentQuestion.order + 1)
        yield put(updateAvaQuestionAction(nextQuestion))
        yield put(stopAvaInterviewFollowUpWatcherAction)
      } else {
        yield call(delay, 1000)
      }
    } catch (err) {
      yield put(stopAvaInterviewFollowUpWatcherAction)
    }
  }
}

function* watchAvaInterviewFollowUpWatcher() {
  while (true) {
    const action = yield take(startAvaInterviewFollowUpWatcherActionType)
    yield race([
      call(avaInterviewFollowUpWatcher, action.payload.interviewToken),
      take(stopAvaInterviewFollowUpWatcherActionType),
    ])
  }
}

export {
  startAvaInterviewFollowUpWatcherAction,
  startAvaInterviewFollowUpWatcherActionType,
  stopAvaInterviewFollowUpWatcherAction,
  stopAvaInterviewFollowUpWatcherActionType,
  watchAvaInterviewFollowUpWatcher,
}
