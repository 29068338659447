import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'

import { useDispatch, useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'

import { Button, Notification } from '../../components'
import {
  createIntent,
  createPayment,
  createPaymentFailure,
  createPaymentFailureClear,
  createPaymentSuccess,
  paySucceed,
} from '../actions'

const Container = styled.div`
  width: 100%;
`

const ConfirmButton = styled(Button)`
  margin-top: 25px;
`

const CardElementsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  > div {
    width: 48%;
  }
`

const CardElementWrapper = styled.div`
  box-sizing: border-box;
  height: 60px;
  border: 1px solid #e1e3ea;
  border-radius: 5px;
  background-color: #f5f6f8;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  .StripeElement {
    width: 100%;
  }
`

const CARD_NUMBER_ELEMENT_OPTIONS = {
  showIcon: true,
  placeholder: 'Card Number',
  style: {
    base: {
      border: '1px solid #000',
      color: 'rgba(33, 48, 79, 0.5)',
      fontSize: '12px',
      fontWeight: 'bold',
      '::placeholder': {
        color: 'rgba(33, 48, 79, 0.5)',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}
const CARD_EXPIRY_ELEMENT_OPTIONS = {
  showIcon: true,
  placeholder: 'Expiration (MM/YY)',
  style: {
    base: {
      border: '1px solid #000',
      color: 'rgba(33, 48, 79, 0.5)',
      fontSize: '12px',
      fontWeight: 'bold',
      '::placeholder': {
        color: 'rgba(33, 48, 79, 0.5)',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}
const CARD_CVV_ELEMENT_OPTIONS = {
  showIcon: true,
  placeholder: 'CVV',
  style: {
    base: {
      border: '1px solid #000',
      color: 'rgba(33, 48, 79, 0.5)',
      fontSize: '12px',
      fontWeight: 'bold',
      '::placeholder': {
        color: 'rgba(33, 48, 79, 0.5)',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

export default function PaymentForm() {
  const { interviewToken } = useParams()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const clientSecret = useSelector(interviewSelectors.clientSecret)
  const error = useSelector(interviewSelectors.createPaymentError)

  const [cardNumberComplete, setCardNumberComplete] = useState(false)
  const [cardDateComplete, setCardDateComplete] = useState(false)
  const [cardCvcComplete, setCardCvcComplete] = useState(false)

  const cardNumberRef = useRef<any>()
  const cardDateRef = useRef<any>()
  const cardCvcRef = useRef<any>()
  const confirmRef = useRef<any>()

  const handleCardNumberChange = (e) => {
    if (e.complete) {
      setCardNumberComplete(e.complete)
      cardDateRef.current.focus()
    }
  }

  const handleCardDateChange = (e) => {
    if (e.complete) {
      setCardDateComplete(e.complete)
      cardCvcRef.current.focus()
    }
  }

  const handleCardCvcChange = (e) => {
    if (e.complete) {
      setCardCvcComplete(e.complete)
      confirmRef.current.focus()
    }
  }

  useEffect(() => {
    dispatch(createIntent({ interviewToken }))
  }, [dispatch, interviewToken])

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch(createPayment())
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    })

    if (payload.error) {
      dispatch(
        createPaymentFailure({
          error: `Payment failed. ${payload.error.message}`,
        })
      )
    } else {
      const pIntent = payload.paymentIntent
      const { id, payment_method } = pIntent
      dispatch(createPaymentSuccess())
      dispatch(paySucceed({ interviewToken, id, payment_method }))
    }
  }

  const clearError = () => {
    dispatch(createPaymentFailureClear())
  }

  return (
    <Container>
      {error && <Notification onClose={clearError} message={error} />}
      <form onSubmit={handleSubmit}>
        <CardElementWrapper>
          <CardNumberElement
            // eslint-disable-next-line no-return-assign
            onReady={(element) => (cardNumberRef.current = element)}
            options={CARD_NUMBER_ELEMENT_OPTIONS}
            onChange={handleCardNumberChange}
          />
        </CardElementWrapper>
        <CardElementsContainer>
          <CardElementWrapper>
            <CardExpiryElement
              // eslint-disable-next-line no-return-assign
              onReady={(element) => (cardDateRef.current = element)}
              options={CARD_EXPIRY_ELEMENT_OPTIONS}
              onChange={handleCardDateChange}
            />
          </CardElementWrapper>
          <CardElementWrapper>
            <CardCvcElement
              // eslint-disable-next-line no-return-assign
              onReady={(element) => (cardCvcRef.current = element)}
              options={CARD_CVV_ELEMENT_OPTIONS}
              onChange={handleCardCvcChange}
            />
          </CardElementWrapper>
        </CardElementsContainer>
        <ConfirmButton
          ref={confirmRef}
          title="Confirm payment"
          useTheme
          type="submit"
          disabled={!cardNumberComplete || !cardCvcComplete || !cardDateComplete}
        />
      </form>
    </Container>
  )
}
