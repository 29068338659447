import { AdminInterviewStatus, AgencyInterviewStatus } from '../api'

const getStatusColor = (status: AdminInterviewStatus | AgencyInterviewStatus) => {
  switch (status) {
    case AdminInterviewStatus.INVITED:
    case AgencyInterviewStatus.INVITED:
      return '#F1BE2B'
    case AdminInterviewStatus.ORDER_FORM_SUBMITTED:
    case AgencyInterviewStatus.MATCHING:
      return '#F23482'
    // case AdminInterviewStatus.PENDING_EXPERT_AVAILABILITY:
    case AdminInterviewStatus.EXPERT_AVAILABILITY_PROVIDED:
      return '#1dd4d4'
    case AdminInterviewStatus.MATCHED:
    case AgencyInterviewStatus.MATCHED:
      return '#FF63A3'
    case AdminInterviewStatus.SCHEDULED:
    case AgencyInterviewStatus.SCHEDULED:
      return '#B202FF'
    case AdminInterviewStatus.INCOMPLETE:
      return '#FF002A'
    case AdminInterviewStatus.STARTED:
      return '#C746FF'
    case AdminInterviewStatus.PENDING_REPORT:
    case AgencyInterviewStatus.PENDING_REPORT:
      return '#737991'
    case AdminInterviewStatus.PENDING_CONFIRMATION:
      return '#353C5A'
    case AdminInterviewStatus.COMPLETED:
      return '#3D95F7'
    case AdminInterviewStatus.HIRED:
      return '#00DE9F'
    default:
      return '#000000'
  }
}

export default getStatusColor
