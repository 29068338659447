import React, { useEffect, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import moment from 'moment'

import SvgNotes from 'src/static/components/SvgNotes'
import SvgTrash from 'src/static/components/SvgTrash'
import SvgClose from 'src/static/components/SvgClose'

import { IApplicantNote } from '../../../api'
import { Text } from '../../../components'
import useApplicantNotes from '../../services/useApplicantNotes'
import { Text as MobileText } from '../MobileText'

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e3ea;
  display: flex;
  justify-content: flex-start;

  height: 105px;
  padding: 0 39px;
  @media screen and (max-width: 2500px) {
    height: 87px;
    padding: 0 32px;
  }
  @media screen and (max-width: 2000px) {
    height: 70px;
    padding: 0 26px;
  }
  @media screen and (max-width: 1200px) {
    height: 52px;
    padding: 0 19px;
  }
  @media screen and (max-width: 900px) {
    height: 35px;
    padding: 0 13px;
  }

  > svg {
    height: 20px;
    width: 20px;
    @media screen and (max-width: 2500px) {
      height: 18px;
      width: 18px;
    }
    @media screen and (max-width: 2000px) {
      height: 14px;
      width: 14px;
    }
    @media screen and (max-width: 1200px) {
      height: 11px;
      width: 11px;
    }
    @media screen and (max-width: 900px) {
      height: 7px;
      width: 7px;
    }
  }
`

const HeaderTitle = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.sidebarTitleColor};

  font-size: 18px;
  line-height: 36px;
  margin-left: 15px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
    margin-left: 13px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
    margin-left: 8px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
    margin-left: 5px;
  }
`

const MobileContainer = styled.div`
  z-index: 1;
  align-items: center;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  height: 100%;
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  @media (orientation: landscape) {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`

const MobileHeader = styled.div`
  align-items: center;
  display: flex;
  height: 92px;
  width: 100%;
  background: white;
  align-items: center;
  padding: 16px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  position: relative;

  @media (orientation: landscape) {
    padding: 0 10%;
  }
`

const MobileHeaderTitle = styled(MobileText)`
  color: #737991;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-left: 12px;
`

const MobileContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 16px;

  @media (orientation: landscape) {
    width: 80%;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 0 12px;
`

const NotesContainer = styled.div`
  width: 100%;
`

interface INoteProps {
  isSelected: boolean
  isNew: boolean
}

const Note = styled.div<INoteProps>`
  position: relative;
  display: inline-block;
  border-radius: 4px;

  font-size: 12px;

  width: 100%;
  cursor: pointer;
  margin: 6px 0;

  ${({ isNew, isSelected }) =>
    !isNew &&
    !isSelected &&
    `
    &.note-animate {
      opacity: 0;
    }

    clip-path: polygon(
      calc(100% - 20px) 100%,
      0px 100%,
      0px 0px,
      100% 0px,
      100% calc(100% - 20px)
    );
    padding: 12px 30px 0px 12px;
    transition: clip-path 500ms, opacity 0.5s ease-in;
    border: 1px solid #e1e3ea;
    opacity: 1;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background: #e1e3ea;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 6px 0;
      transition: width 500ms, height 500ms;
    }

    &:hover {
      clip-path: polygon(100% 100%, 0px 100%, 0px 0px, 100% 0px, 100% 100%);
    }

    &:hover:after {
      height: 0px;
      width: 0px;
    }
  `};

  ${({ isSelected }) =>
    isSelected &&
    `
    padding: 0;
    border: 1px solid #f1be2b;
  `};

  ${({ isNew }) =>
    isNew &&
    `
    min-height: 30%;
    color: #979797;
  `};
`

const NoteText = styled(Text)`
  color: #21304e;
  word-break: break-word;
`

const NoteTime = styled(Text)`
  margin-top: 10px;
  color: #898a8d;
`

const NoNotesHint = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
  font-size: 12px;
`

const NoteTextarea = styled.textarea`
  flex: 1;
  border: none;
  border-radius: 4px;
  padding: 12px 30px 12px 12px;
  font-size: 12px;
  outline: 0;
  resize: none;
  height: 100%;
  width: 100%;
  max-height: 100%;
`

const TrashButton = styled.div`
  position: absolute;
  top: 8px;
  right: 5px;
  cursor: pointer;

  > svg {
    height: 24px;
    width: 24px;
  }
`

const AddNoteButton = styled.button`
  position: absolute;
  bottom: 15px;
  right: 15px;

  outline: none !important;
  display: block;
  height: 30px;
  width: 69px;
  box-sizing: border-box;
  border-width: 1px;
  background-color: #f1be2b;
  border-radius: 4px;
  font-family: 'Rubik';
  font-size: 12px;
  color: white;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: rgba(241, 190, 43, 0.5);
  }

  &:active {
    background-color: rgba(241, 190, 43, 0.75);
  }

  &:disabled {
    background-color: rgba(241, 190, 43, 0.25);
    cursor: default;
  }
`

const MobileCloseIcon = styled.div`
  position: absolute;
  right: 18px;
  top: calc(50% - 6px);
  height: 12px;
  width: 12px;
  display: flex;
  @media (orientation: landscape) {
    right: calc(10% + 20px);
  }

  > svg {
    height: 100%;
    width: 100%;
  }
`

interface INote {
  note: IApplicantNote
  isSelected: boolean
  onSelect: () => void
  onSave: (value: string) => void
  onRemove: () => void
}

function NoteItem({ note, isSelected, onSelect, onSave, onRemove }: INote) {
  const [value, setValue] = useState(note?.text ?? '')
  const ref = useRef<HTMLTextAreaElement>()
  const [resized, setResized] = useState(false)
  const [animated, setAnimated] = useState(false)

  const resetValue = () => {
    setValue(note?.text ?? '')
    setResized(false)
  }

  const handleClick = () => {
    if (isSelected) return
    onSelect()
    resetValue()
  }

  const handleRemove = (e) => {
    e.preventDefault()
    onRemove()
    resetValue()
  }

  const handleSave = (e) => {
    e.preventDefault()
    onSave(value)
    resetValue()
  }

  useEffect(() => {
    if (isSelected) {
      ref.current.focus()
      setResized(true)
    }
  }, [isSelected])

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '0px'
      const { scrollHeight } = ref.current

      ref.current.style.height = `${Math.max(scrollHeight + 20, 90)}px`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, value, resized])

  useEffect(() => {
    setTimeout(() => setAnimated(true), 10)
  }, [])

  return (
    <Note
      key={note?.id ?? 'new-note'}
      className={!animated ? 'note-animate' : ''}
      onClick={handleClick}
      isSelected={isSelected || !note?.id}
      isNew={!note?.id}
    >
      {!isSelected && note?.id && (
        <>
          <NoteText>{note.text}</NoteText>
          <NoteTime>
            {moment.duration(note.offset, 'seconds').format('mm:ss', { trim: false })}
          </NoteTime>
        </>
      )}
      {(isSelected || !note?.id) && (
        <>
          <NoteTextarea
            ref={ref}
            placeholder={!note?.id ? 'New Notes' : ''}
            disabled={!isSelected}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {note?.id && (
            <TrashButton onClick={handleRemove}>
              <SvgTrash fill="#2f2f2f" />
            </TrashButton>
          )}
          {isSelected && (
            <AddNoteButton disabled={!value?.length} onClick={handleSave}>
              {note?.id ? 'Save' : 'Add'}
            </AddNoteButton>
          )}
        </>
      )}
    </Note>
  )
}

interface INotesSidebar {
  interviewToken: string
  onClose: () => void
}

export default function NotesSidebar({ interviewToken, onClose }: INotesSidebar) {
  const { applicantNotes, removeApplicantNote, sendApplicantNote } = useApplicantNotes(
    interviewToken
  )

  const [selectedNote, setSelectedNote] = useStateRef<IApplicantNote>(undefined)

  const handleRemoveNote = () => {
    removeApplicantNote(selectedNote.id)
    setSelectedNote(undefined)
  }

  const handleSaveNote = (value) => {
    sendApplicantNote(value, selectedNote.id)
    setSelectedNote(undefined)
  }

  const handleNewNote = () => {
    setSelectedNote({} as IApplicantNote)
  }

  const handleSelectNote = (note: IApplicantNote) => {
    setSelectedNote(note)
  }

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Header>
            <SvgNotes fill="#2f2f2f" />
            <HeaderTitle>Personal notes</HeaderTitle>
          </Header>
          <Content>
            <NoteItem
              note={undefined}
              isSelected={selectedNote && !selectedNote.id}
              onSave={handleSaveNote}
              onRemove={undefined}
              onSelect={handleNewNote}
            />
            {applicantNotes.length > 0 && (
              <NotesContainer>
                {applicantNotes
                  .slice()
                  .reverse()
                  .map((n) => (
                    <NoteItem
                      note={n}
                      key={n.id}
                      isSelected={selectedNote?.id === n.id}
                      onSave={handleSaveNote}
                      onRemove={handleRemoveNote}
                      onSelect={() => handleSelectNote(n)}
                    />
                  ))}
              </NotesContainer>
            )}
            {applicantNotes.length === 0 && <NoNotesHint>No notes</NoNotesHint>}
          </Content>
        </Container>
      )}

      {isMobileOnly && (
        <MobileContainer>
          <MobileHeader>
            <SvgNotes fill="#F1BE2B" />
            <MobileHeaderTitle>Personal notes</MobileHeaderTitle>
            <MobileCloseIcon onClick={onClose}>
              <SvgClose fill="#737991" />
            </MobileCloseIcon>
          </MobileHeader>
          <MobileContent>
            <NoteItem
              note={undefined}
              isSelected={selectedNote && !selectedNote.id}
              onSave={handleSaveNote}
              onRemove={undefined}
              onSelect={handleNewNote}
            />
            {applicantNotes.length > 0 && (
              <NotesContainer>
                {applicantNotes
                  .slice()
                  .reverse()
                  .map((n) => (
                    <NoteItem
                      note={n}
                      key={n.id}
                      isSelected={selectedNote?.id === n.id}
                      onSave={handleSaveNote}
                      onRemove={handleRemoveNote}
                      onSelect={() => handleSelectNote(n)}
                    />
                  ))}
              </NotesContainer>
            )}
            {applicantNotes.length === 0 && <NoNotesHint>No notes</NoNotesHint>}
          </MobileContent>
        </MobileContainer>
      )}
    </>
  )
}
