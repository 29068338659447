import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { bookingInfoSelectors, clearBookingInfoAction } from '../../redux'
import NewBooking from '../components/NewBooking'
import ThankYou from '../components/ThankYou'

function NewBookingContainer() {
  const dispatch = useDispatch()
  const bookingInfo = useSelector(bookingInfoSelectors.data)

  useEffect(() => {
    return () => dispatch(clearBookingInfoAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return bookingInfo ? <ThankYou /> : <NewBooking />
}

export default NewBookingContainer
