import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { getIndexColor } from 'src/ava/services/reportHelper'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleText = styled.div`
  color: #222b45;
  font-family: Rubik;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: ${isMobileOnly ? 32 : 16}px;
`

const ProgressBar = styled.div<{ progress?: number }>`
  position: relative;
  border-radius: 64px;
  background: #f5f6f8;
  width: 100%;
  height: 8px;
  margin-bottom: 8px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(${({ progress }) => `100% * ${progress} / 3`});
    height: 100%;
    width: calc(100% / 3);
    background: ${({ progress }) => getIndexColor(progress)};
    border-radius: 64px;
  }
`

const StepsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

const StepText = styled.div<{ color: string; progress: number }>`
  color: ${({ color, progress }) => {
    const progressColor = getIndexColor(progress)
    if (color === progressColor) {
      return color
    }
    return '#8f9bb3'
  }};
  font-family: Rubik;
  font-size: 12px;
  font-style: italic;
  font-weight: ${({ color, progress }) => {
    const progressColor = getIndexColor(progress)
    if (color === progressColor) {
      return 500
    }
    return 400
  }};
  line-height: 16px;

  &:nth-child(2) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  ${isMobileOnly &&
  css`
    max-width: 33%;
    text-align: center;
  `}
`

interface IProps {
  title: string
  steps: string[]
  value: string
}

export default function AvaInsightsScoreBlock({ title, steps, value }: IProps) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (steps?.length > 0 && value) {
      setProgress(steps.map((s) => s.toUpperCase()).indexOf(value.toUpperCase()))
    }
  }, [steps, value])

  return (
    <Container>
      <TitleText>{title}</TitleText>
      <ProgressBar progress={progress} />
      <StepsContainer>
        {steps.map((s, index) => (
          <StepText progress={progress} color={getIndexColor(index)} key={s}>
            {s}
          </StepText>
        ))}
      </StepsContainer>
    </Container>
  )
}
