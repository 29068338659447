import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

import { IExpertProfile } from '../../api'
import { proceedToMeetingAction } from '../../redux'
import CalendarIcon from '../../static/ic_calendar_round.png'
import getClearLink from '../../utils/links'

import * as interviewSelectors from '../selectors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const BlockContainer = styled.div<{ top?: number }>`
  display: flex;
  flex-direction: column;
  padding: ${isMobileOnly ? '16px' : '24px 40px'};
  background: #f5f6f8;
  border-radius: 20px;
  margin-top: ${(props) => props.top ?? 0}px;
  margin-bottom: ${(props) => (isMobileOnly ? props.top ?? 24 : 24)}px;
  align-items: inline;
`

const LineContainer = styled.div<{ top?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${(props) => props.top ?? 0}px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-weight: 500;
  font-size: ${isMobileOnly ? 18 : 20}px;
  color: #222b45;
  margin: 0 12px;
`

const DateTimeBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DateTimeBlockMobile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justtify-content: space-between;
`

const DateLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #21304e;
`

const TimeLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #21304e;
  white-space: break-spaces;
`

const TimeZoneLabel = styled.div<{ top?: number }>`
  font-weight: 300;
  font-size: 12px;
  color: #000;
  white-space: break-spaces;
  margin-top: ${(props) => props.top ?? 0}px;
`

const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #21304e;
  margin-top: 16px;
  margin-bottom: 16px;
`

const BottomText = styled.div<{ top?: number }>`
  font-weight: 400;
  font-size: 14px;
  color: #21304e;
  margin-right: 32px;
  margin-top: ${(props) => props.top ?? 0}px;

  ${isMobileOnly &&
  css`
    margin-bottom: 16px;
  `}
`

const CalendarImg = styled.img`
  width: 40px;
  height: 42px;
`

const BottomButton = styled.div<{ filled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 31px;
  color: ${(props) => (props.filled ? 'white' : '#b202ff')};
  background: ${(props) => (props.filled ? '#b202ff' : 'white')};
  border: 1px solid #b202ff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;

  & :hover {
    opacity: 0.8;
  }

  & :active {
    opacity: 0.5;
  }
`

const LinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #b202ff;
  cursor: pointer;
  text-decoration: underline;
`

interface IProps {
  showExpertOnboarding?: boolean
  showApplicantOnboarding?: boolean
  isExpert?: boolean
}

function PreStartInfoComponent({
  showExpertOnboarding,
  showApplicantOnboarding,
  isExpert,
}: IProps) {
  const dispatch = useDispatch()
  const interviewDetails = useSelector(interviewSelectors.interview)
  const agency = useSelector(interviewSelectors.agency)

  const selectedTimeSlot = ((isExpert
    ? interviewDetails.profile
    : interviewDetails.peer) as IExpertProfile).time_slots?.find(
    (t) => t.id === interviewDetails.interview.main_time_slot_id
  )
  const onboardingUrl = `//${getClearLink(agency.expert_onboarding_url)}`

  const onShowOnboarding = () => {
    window.open(onboardingUrl, '_blank')
  }

  const timeZoneGMT = format(new Date(), 'O')
  const joinButtonInfoText = 'Your session will take place at:'
  const joinButtonLabel = 'Join the meeting'

  const onJoin = () => {
    if (
      !interviewDetails.interview?.use_general_link &&
      interviewDetails.agency?.meeting_url
    ) {
      let link = interviewDetails.agency?.meeting_url
      if (!link.match('^https?://')) {
        link = `http://${link}`
      }
      window.location.replace(link)
    } else dispatch(proceedToMeetingAction)
  }

  return (
    <Container>
      <BlockContainer top={isMobileOnly ? 16 : 0}>
        <TitleContainer>
          <CalendarImg src={CalendarIcon} />
          <Title>Your session is scheduled for</Title>
        </TitleContainer>
        {isMobileOnly && (
          <>
            <DateTimeBlockMobile>
              <DateLabel>
                {format(new Date(selectedTimeSlot?.from), 'EEEE, MMM dd')}
              </DateLabel>
              <TimeLabel>{` | ${format(
                new Date(selectedTimeSlot?.from),
                'h:mm a'
              )} - ${format(new Date(selectedTimeSlot?.to), 'h:mm a')}. `}</TimeLabel>
            </DateTimeBlockMobile>
            <TimeZoneLabel top={8}>
              {Intl.DateTimeFormat().resolvedOptions().timeZone} ({timeZoneGMT})
            </TimeZoneLabel>
          </>
        )}
        {!isMobileOnly && (
          <DateTimeBlock>
            <DateLabel>
              {format(new Date(selectedTimeSlot?.from), 'EEEE, MMM dd')}
            </DateLabel>
            <TimeLabel>{` | ${format(
              new Date(selectedTimeSlot?.from),
              'h:mm a'
            )} - ${format(new Date(selectedTimeSlot?.to), 'h:mm a')}. `}</TimeLabel>
            <TimeZoneLabel>
              {Intl.DateTimeFormat().resolvedOptions().timeZone} ({timeZoneGMT})
            </TimeZoneLabel>
          </DateTimeBlock>
        )}
        {showExpertOnboarding && (
          <>
            <InfoText>
              Ahead of your session, please make sure to complete your expert onboarding,
              which offers a guideline on how to structure the session and make the most
              of the Wizco coaching cockpit.
            </InfoText>
            <BottomButton filled onClick={onShowOnboarding}>
              Expert onboarding
            </BottomButton>
          </>
        )}
        {!showExpertOnboarding && (
          <>
            {isMobileOnly && (
              <>
                <BottomText top={12}>{joinButtonInfoText}</BottomText>
                <BottomButton filled onClick={onJoin}>
                  {joinButtonLabel}
                </BottomButton>
              </>
            )}
            {!isMobileOnly && (
              <>
                <LineContainer top={18}>
                  <BottomText>{joinButtonInfoText}</BottomText>
                  <BottomButton filled onClick={onJoin}>
                    {joinButtonLabel}
                  </BottomButton>
                </LineContainer>
              </>
            )}
          </>
        )}
        {showApplicantOnboarding && (
          <BottomText top={24}>
            To get the most from your session, we recommended you{' '}
            <LinkText href={agency.candidate_onboarding_url} target="_blank">
              complete your session onboarding.
            </LinkText>
          </BottomText>
        )}
      </BlockContainer>
      {showExpertOnboarding && (
        <>
          {isMobileOnly ? (
            <BlockContainer>
              <BottomText>{joinButtonInfoText}</BottomText>
              <BottomButton onClick={onJoin}>{joinButtonLabel}</BottomButton>
            </BlockContainer>
          ) : (
            <BlockContainer>
              <LineContainer>
                <BottomText>{joinButtonInfoText}</BottomText>
                <BottomButton onClick={onJoin}>{joinButtonLabel}</BottomButton>
              </LineContainer>
            </BlockContainer>
          )}
        </>
      )}
    </Container>
  )
}

PreStartInfoComponent.defaultProps = {
  isExpert: false,
  showApplicantOnboarding: false,
  showExpertOnboarding: false,
}

export default PreStartInfoComponent
