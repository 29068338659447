/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import withProps from 'recompose/withProps'
import { css } from '@emotion/core'
import { ButtonBase } from '../elements/styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3b424f;
  margin-bottom: 23px;
`

const HeadText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 12px;

  ${isMobileOnly &&
  css`
    font-size: 14px;
    line-height: 32px;
    margin-bottom: 0;
    color: #222b45;
  `}
`

const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 40px;
  width: 475px;
  text-align: center;

  ${isMobileOnly &&
  css`
    width: 100%;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 14px;
    color: #222b45;
  `}
`

const ButtonStyled = withProps()(styled(ButtonBase)`
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 32px;
  padding: 18px 46px;
  color: white;
  font-weight: 500;
  font-size: 16px;

  ${isMobileOnly &&
  css`
    width: 100%;
    text-align: center;
  `}
`)

const ScrollButton = withProps()(styled.div`
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: white;
  border: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return `1px solid ${color}`
  }};
  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  font-size: 32px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  margin-top: 12px;
`)

interface IProps {
  onNewSession: () => void
  onScrollTop?: () => void
}

const ReportFooterComponent = ({ onNewSession, onScrollTop }: IProps) => {
  return (
    <Container>
      <HeadText>Want to practice again?</HeadText>
      <InfoText>
        Try to reflect on what you said from the perspective of an interviewer, identify
        what you’d like to improve, and try again
      </InfoText>
      <ButtonStyled onClick={onNewSession}>Practice again</ButtonStyled>
      {isMobileOnly && <ScrollButton onClick={onScrollTop}>↓</ScrollButton>}
    </Container>
  )
}

ReportFooterComponent.defaultProps = {
  onScrollTop: () => {},
}

export default ReportFooterComponent
