import { createReducer } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { IDefaultState } from '../utils/redux'
import {
  signIn,
  setSignInResult,
  signInFailure,
  signInClearError,
  reset,
  resetSuccess,
  resetFailure,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  changePasswordClearError,
} from './actions'
import {
  ISetSignInAction,
  ISignInFailureAction,
  IResetFailureAction,
  IChangePasswordFailureAction,
} from './types'

interface ILoginState extends IDefaultState {
  token: string
}

const initialLoginState: ILoginState = {
  token: '',
  isLoading: false,
  error: '',
}

const initialResetState: IDefaultState = {
  isLoading: false,
  error: '',
}

const initialChangePasswordState: IDefaultState = {
  isLoading: false,
  error: '',
}

const loginReducer = createReducer(initialLoginState, {
  [signIn.type]: (state) => ({
    ...state,
    isLoading: true,
    error: '',
    token: '',
  }),
  [setSignInResult.type]: (state, action: ISetSignInAction) => ({
    ...state,
    isLoading: false,
    error: '',
    token: action.payload.token,
  }),
  [signInFailure.type]: (state, action: ISignInFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [signInClearError.type]: (state) => ({
    ...state,
    error: '',
  }),
})

const resetReducer = createReducer(initialResetState, {
  [reset.type]: (state) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [resetSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
  }),
  [resetFailure.type]: (state, action: IResetFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
})

const changePasswordReducer = createReducer(initialChangePasswordState, {
  [changePassword.type]: (state) => ({
    ...state,
    isLoading: true,
    error: '',
  }),
  [changePasswordSuccess.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
  }),
  [changePasswordFailure.type]: (state, action: IChangePasswordFailureAction) => ({
    ...state,
    isLoading: false,
    error: action.payload.error,
  }),
  [changePasswordClearError.type]: (state) => ({
    ...state,
    isLoading: false,
    error: '',
  }),
})

export default combineReducers({
  login: loginReducer,
  reset: resetReducer,
  changePassword: changePasswordReducer,
})
