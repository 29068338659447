import React, { useEffect, useState, useRef, ChangeEventHandler } from 'react'
import styled from '@emotion/styled'
import { IAgoraRTC, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng'
import { isAndroid, isMobileOnly } from 'react-device-detect'

import SvgSettings from 'src/static/components/SvgSettings'
// import SvgSpeaker from 'src/static/components/SvgSpeaker'

import { ISettings, IVideoProfile } from '../../interfaces'
import { detectBrowser, Browser } from '../../services/browserDetection'
import { MediaDeviceGroup } from '../../services/useMediaDevices'
import { Text } from '../MobileText'

import Volume from '../volume'
import Setting from './Setting'
import { VideoProfiles } from '../VideoProfiles'
import BlurIcon from '../../../static/ic-blur.png'
import BlurOffIcon from '../../../static/ic-blur-off.png'

const AudioFile = '/static/test_sound.wav'

const DefaultSpeakers: MediaDeviceGroup[] = [
  {
    deviceId: 'default',
    deviceIds: ['default'],
    label: 'Default',
    groupId: 'default',
    kind: 'audiooutput',
  },
]

const Container = styled.div<{ disabled?: boolean }>`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  filter: blur(${({ disabled }) => (disabled ? 1 : 0)}px);
  flex-direction: column;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  width: 100%;
`

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e3ea;
  display: flex;

  height: 105px;
  padding: 0 39px;
  @media screen and (max-width: 2500px) {
    height: 87px;
    padding: 0 32px;
  }
  @media screen and (max-width: 2000px) {
    height: 70px;
    padding: 0 26px;
  }
  @media screen and (max-width: 1200px) {
    height: 52px;
    padding: 0 19px;
  }
  @media screen and (max-width: 900px) {
    height: 35px;
    padding: 0 13px;
  }

  > svg {
    height: 20px;
    width: 20px;
    @media screen and (max-width: 2500px) {
      height: 18px;
      width: 18px;
    }
    @media screen and (max-width: 2000px) {
      height: 14px;
      width: 14px;
    }
    @media screen and (max-width: 1200px) {
      height: 11px;
      width: 11px;
    }
    @media screen and (max-width: 900px) {
      height: 7px;
      width: 7px;
    }
  }
`

const HeaderTitle = styled(Text)`
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.sidebarTitleColor};
  display: flex;

  font-size: 18px;
  line-height: 36px;
  margin-left: 15px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
    margin-left: 13px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
    margin-left: 8px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
    margin-left: 5px;
  }
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;

  padding: 60px 37px;
  @media screen and (max-width: 2500px) {
    padding: 55px 31px;
  }
  @media screen and (max-width: 2000px) {
    padding: 40px 25px;
  }
  @media screen and (max-width: 1200px) {
    padding: 30px 18px;
  }
  @media screen and (max-width: 900px) {
    padding: 20px 12px;
  }
`

const SpeakerTest = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;

  margin-left: 15px;
  width: 100px;
  @media screen and (max-width: 2500px) {
    margin-left: 12px;
    width: 75px;
  }
  @media screen and (max-width: 2000px) {
    margin-left: 10px;
    width: 60px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 7px;
    width: 50px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 5px;
    width: 33px;
  }
`

const Button = styled.input`
  background: #f4dcff;
  border: none;
  text-align: center;
  color: #c746ff;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
  }

  border-radius: 18px;
  font-size: 18px;
  height: 45px;
  line-height: 24px;
  @media screen and (max-width: 2500px) {
    border-radius: 15px;
    font-size: 15px;
    height: 37px;
    line-height: 20px;
  }
  @media screen and (max-width: 2000px) {
    border-radius: 12px;
    font-size: 12px;
    height: 30px;
    line-height: 16px;
  }
  @media screen and (max-width: 1200px) {
    border-radius: 9px;
    font-size: 9px;
    height: 22px;
    line-height: 12px;
  }
  @media screen and (max-width: 900px) {
    border-radius: 6px;
    font-size: 6px;
    height: 15px;
    line-height: 8px;
  }
`

const VolumeContainer = styled.div`
  align-items: center;
  background: #f4dcff;
  bottom: -100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 100%;

  border-radius: 18px;
  height: 45px;
  padding: 0 22%;
  @media screen and (max-width: 2500px) {
    border-radius: 15px;
    height: 37px;
  }
  @media screen and (max-width: 2000px) {
    border-radius: 12px;
    height: 30px;
  }
  @media screen and (max-width: 1200px) {
    border-radius: 9px;
    height: 22px;
  }
  @media screen and (max-width: 900px) {
    border-radius: 6px;
    height: 15px;
  }
`

const MobileContainer = styled.div`
  align-items: center;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  padding: 24px;

  @media (orientation: landscape) {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`

const MobileHeader = styled.div`
  align-items: center;
  border-bottom: 0.5px solid #324fa5;
  display: flex;
  padding: 4px 8px;
  width: 100%;

  @media (orientation: landscape) {
    background: #1d0e3b;
    border: none;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 57px;
    padding: 29px 24px 4px 24px;
  }
`

const MobileHeaderTitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-left: 8px;
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;

  @media (orientation: landscape) {
    height: calc(100% - 57px);
    overflow: scroll;
    padding: 15px 44px;
  }
`

const MobileSpeakerTest = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 62px;
  margin-left: 24px;
  width: 69px;
`

const MobileButton = styled.input`
  background: #f4dcff;
  border: none;
  border-radius: 14px;
  color: #c746ff;
  cursor: pointer;
  flex-shrink: 0;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 17px;
  margin: -5px 0 7px 0;
  text-align: center;
  width: 100%;

  &:focus {
    outline: none;
  }
`

const MobileVolumeContainer = styled.div`
  align-items: center;
  background: #f4dcff;
  border-radius: 14px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  height: 32px;
  padding: 0 25px;
  width: 100%;
`

const SettingsVideoContainer = styled.div`
  height: 150px;
  width: 100%;

  div {
    background-color: transparent !important;
    height: 150px !important;
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
`

const BlurButton = styled.div<{ isEnabled: boolean; text: string }>`
  position: relative;
  width: 43px;
  height: 35px;
  background: white;
  border: 5px solid
    ${({ isEnabled }) => (isEnabled ? 'rgba(105, 56, 170, 1)' : '#ABB4C6')};
  border-radius: 12px;
  cursor: pointer;
  padding: 4px;
  margin-right: 6px;
  &:after {
    content: '${({ text }) => text}';
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    bottom: -21px;
    display: flex;
    left: 0;
    color: ${({ isEnabled }) => (isEnabled ? 'rgba(105, 56, 170, 1)' : '#E1E3EA')};
    justify-content: center;
  }
`

const BlurImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
`
// const SpeakerButton = styled.div<{ active: boolean }>`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 3px 4px 3px 12px;

//   width: 164px;
//   height: 44px;
//   background: ${({ active }) => (active ? '#b202ff' : '#6938AA')};
//   border-radius: 22px;

//   > p {
//     color: ${({ theme }: { theme: any }) => theme.textColor};
//     margin: 0;
//     font-family: Rubik;
//     font-style: normal;
//     font-weight: normal;
//     font-size: 18px;
//     line-height: 21px;
//   }
// `

// const SpeakerIcon = styled.div`
//   align-items: center;
//   background: #ffffff;
//   border-radius: 19px;
//   display: flex;
//   height: 38px;
//   justify-content: center;
//   width: 38px;

//   > svg {
//     height: 60%;
//     width: 60%;
//   }
// `

interface ISettingsSidebar {
  agoraRTC: IAgoraRTC
  devices: MediaDeviceGroup[]
  isScreensharing: boolean
  settings: ISettings
  theme: any
  audioTrack: IMicrophoneAudioTrack
  isBackgroundBlurEnabled: boolean
  canBlur: boolean
  changeCamera: (deviceId: string) => void
  changeMicrophone: (deviceId: string) => void
  changeSpeaker: (deviceId: string) => void
  changeVideoProfile: (profile: IVideoProfile) => void
  changeBackgroundBlur: (enable: boolean) => void
}

export default function SettingsSidebar({
  agoraRTC,
  devices,
  isScreensharing,
  settings: { cameraId, microphoneId, speakerId, videoProfile },
  theme,
  audioTrack,
  isBackgroundBlurEnabled,
  canBlur,
  changeCamera,
  changeMicrophone,
  changeSpeaker,
  changeVideoProfile,
  changeBackgroundBlur,
}: ISettingsSidebar) {
  const [musicIndicator, setMusicIndicator] = useState<boolean>(false)
  const videoTrack = useRef<ICameraVideoTrack>()
  const browser = detectBrowser()
  const isFirefox = browser === Browser.Firefox
  const isSafari = browser === Browser.Safari
  const audio = useRef<any>(new Audio(AudioFile))

  const switchCamera: ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeCamera(e.target.value)
  }

  const switchMicrophone: ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeMicrophone(e.target.value)
  }

  const switchSpeaker: ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeSpeaker(e.target.value)
  }

  const switchVideoProfile: ChangeEventHandler<HTMLSelectElement> = (e) => {
    changeVideoProfile(VideoProfiles.find((p) => p.name === e.target.value))
  }

  const testSpeaker = () => {
    if (!audio.current.paused) {
      audio.current.pause()
      audio.current.currentTime = 0
    }

    if (audio.current.setSinkId) {
      audio.current.setSinkId(speakerId)
    }
    audio.current.addEventListener('ended', () => {
      setMusicIndicator(false)
    })
    audio.current
      .play()
      .then(() => setMusicIndicator(true))
      // eslint-disable-next-line no-console
      .catch((error) => console.error('playback prevented', error))
  }

  useEffect(() => {
    if (isMobileOnly) {
      agoraRTC
        .createCameraVideoTrack({
          optimizationMode: 'detail',
          encoderConfig: {
            width: videoProfile.width,
            height: videoProfile.height,
            frameRate: videoProfile.frameRate,
            bitrateMin: videoProfile.bitrate,
            bitrateMax: videoProfile.bitrate,
          },
        })
        .then((track) => {
          track?.setDevice(cameraId)
          videoTrack.current = track
          videoTrack.current.play('settings-video', { fit: 'contain' })
        })
      return () => videoTrack.current?.close()
    }
    return undefined
  }, [agoraRTC, cameraId, videoProfile])

  const cameras = devices.filter((d) => d.kind === 'videoinput')
  const microphones = devices.filter((d) => d.kind === 'audioinput')
  let speakers = devices.filter((d) => d.kind === 'audiooutput')
  if (speakers.length === 0) speakers = DefaultSpeakers

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Header>
            <SvgSettings fill={theme.sidebarIconColor} />
            <HeaderTitle>Settings</HeaderTitle>
          </Header>
          <Content>
            <Setting
              isCompact={false}
              items={microphones}
              label="Microphone"
              value={microphoneId}
              onValueChange={switchMicrophone}
            >
              <Volume.SpeakerVolumeIndicator track={audioTrack} />
            </Setting>
            {!isFirefox && !isSafari && (
              <Setting
                isCompact
                items={speakers}
                label="Speakers"
                value={speakerId}
                onValueChange={switchSpeaker}
              >
                <SpeakerTest>
                  <Button type="button" value="Test" onClick={testSpeaker} />
                  {musicIndicator && (
                    <VolumeContainer>
                      <Volume.AudioVolumeIndicator
                        element={audio.current}
                        track={undefined}
                      />
                    </VolumeContainer>
                  )}
                </SpeakerTest>
              </Setting>
            )}
            <Setting
              disabled={isScreensharing}
              isCompact={false}
              items={cameras}
              label="Camera"
              value={cameraId}
              onValueChange={switchCamera}
            />
            {!isMobileOnly && canBlur && (
              <Setting
                disabled={isScreensharing}
                isCompact={false}
                label="Blur background effect"
              >
                <ButtonsContainer>
                  <BlurButton
                    text="On"
                    isEnabled={isBackgroundBlurEnabled}
                    onClick={() => changeBackgroundBlur(true)}
                  >
                    <BlurImage src={BlurIcon} />
                  </BlurButton>
                  <BlurButton
                    text="Off"
                    isEnabled={!isBackgroundBlurEnabled}
                    onClick={() => changeBackgroundBlur(false)}
                  >
                    <BlurImage src={BlurOffIcon} />
                  </BlurButton>
                </ButtonsContainer>
              </Setting>
            )}
            <Setting
              disabled={isScreensharing}
              isCompact={false}
              items={VideoProfiles}
              label="Video Profile"
              value={videoProfile.name}
              onValueChange={switchVideoProfile}
            />
          </Content>
        </Container>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileHeader>
            <SvgSettings fill={theme.textColor} />
            <MobileHeaderTitle>Settings</MobileHeaderTitle>
          </MobileHeader>
          <MobileContent>
            <Setting
              isCompact={false}
              items={microphones}
              label="Microphone"
              value={microphoneId}
              onValueChange={switchMicrophone}
            >
              <Volume.SpeakerVolumeIndicator track={audioTrack} />
            </Setting>
            {!isFirefox && !isSafari && (
              <Setting
                isCompact
                items={speakers}
                label="Speakers"
                value={speakerId}
                onValueChange={switchSpeaker}
              >
                <MobileSpeakerTest>
                  <MobileButton type="button" value="Test" onClick={testSpeaker} />
                  {musicIndicator && (
                    <MobileVolumeContainer>
                      <Volume.AudioVolumeIndicator
                        element={audio.current}
                        track={undefined}
                      />
                    </MobileVolumeContainer>
                  )}
                </MobileSpeakerTest>
              </Setting>
            )}
            {!isAndroid && (
              <Setting
                disabled={isScreensharing}
                isCompact={false}
                items={cameras}
                label="Camera"
                value={cameraId}
                onValueChange={switchCamera}
              />
            )}
            <Setting
              disabled={isScreensharing}
              isCompact={false}
              items={VideoProfiles}
              label="Video Profile"
              value={videoProfile.name}
              onValueChange={switchVideoProfile}
            />
            <SettingsVideoContainer id="settings-video" />
          </MobileContent>
        </MobileContainer>
      )}
    </>
  )
}
