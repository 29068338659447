import React from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { isMobileOnly } from 'react-device-detect'
import { format } from 'date-fns'

import SvgCalendar from 'src/static/components/SvgCalendar'
import * as interviewSelectors from '../../../interview/selectors'

const DetailsBody = styled.div`
  display: flex;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;
  padding: 4px 12px;

  ${isMobileOnly &&
  css`
    max-height: 60vh;
  `}
`

const DetailsTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #737991;
  margin-top: 24px;

  ${isMobileOnly &&
  css`
    margin-top: 16px;
    color: #737991;
  `}
`

const DetailsText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  color: #000000;
  margin-top: 5px;
  display: flex;

  > svg {
    margin-right: 9px;
    margin-left: 5px;
  }

  ${isMobileOnly &&
  css`
    color: #ffffff;
  `}
`

const DetailsLink = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  margin: 12px 0 0;
  text-decoration: underline;
  width: fit-content;

  ${isMobileOnly &&
  css`
    color: #ffffff;
  `}
`

const DetailsTextBold = styled(DetailsText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`

export default function SessionDetails() {
  const { interview } = useSelector(interviewSelectors.interview)

  const getDesiredPositionLocation = () => {
    const items = []
    if (interview.position_company) {
      items.push(interview.position_company)
    }
    if (interview.position_location) {
      items.push(interview.position_location)
    }
    return items.join(', ')
  }

  const hasSpecialRequest =
    interview.special_request || interview.special_request_by_admin

  return (
    <DetailsBody>
      {interview.desired_position && (
        <>
          <DetailsTitle>Desired position</DetailsTitle>
          <DetailsTextBold>{interview.desired_position}</DetailsTextBold>
          <DetailsText>{getDesiredPositionLocation()}</DetailsText>
        </>
      )}
      {interview.link_to_position && (
        <DetailsLink
          href={interview.link_to_position.replace(/^www\./g, '//')}
          target="_blank"
        >
          Full position description
        </DetailsLink>
      )}
      {hasSpecialRequest && (
        <>
          <DetailsTitle>Session request</DetailsTitle>
          {interview.special_request && (
            <DetailsText>{`"${interview.special_request}"`}</DetailsText>
          )}
          {interview.special_request_by_admin && (
            <DetailsText>{`"${interview.special_request_by_admin}"`}</DetailsText>
          )}
        </>
      )}
      {interview.interview_date && (
        <>
          <DetailsTitle>Job interview date</DetailsTitle>
          <DetailsText>
            <SvgCalendar fill={isMobileOnly ? 'white' : '#200E32'} />
            {format(new Date(interview.interview_date), 'MMM dd')}
          </DetailsText>
        </>
      )}
      {interview.position_description && (
        <>
          <DetailsTitle>Applied positions details</DetailsTitle>
          <DetailsText>{interview.position_description}</DetailsText>
        </>
      )}
    </DetailsBody>
  )
}
