import { put, takeEvery, call, take } from 'typed-redux-saga'

import { avaCompleteInterview } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { avaGetInterviewAction, avaGetInterviewActionSuccessType } from './getInterview'

export interface IAvaCompleteInterviewAction {
  interviewToken: string
}

const {
  action: avaCompleteInterviewAction,
  actionFailure: avaCompleteInterviewActionFailure,
  actionSuccess: avaCompleteInterviewActionSuccess,
  actionSuccessClear: avaCompleteInterviewActionSuccessClear,
  actionFailureClear: avaCompleteInterviewActionFailureClear,
  actionType: avaCompleteInterviewActionType,
  actionTypeFailure: avaCompleteInterviewActionFailureType,
  actionTypeSuccess: avaCompleteInterviewActionSuccessType,
  reducer: avaCompleteInterviewRequestReducer,
  selectors: avaCompleteInterviewSelectors,
} = reduxRequestFactory<IAvaCompleteInterviewAction>('avaCompleteInterview', 'requests')

export {
  avaCompleteInterviewAction,
  avaCompleteInterviewActionFailure,
  avaCompleteInterviewActionSuccess,
  avaCompleteInterviewActionSuccessClear,
  avaCompleteInterviewActionFailureClear,
  avaCompleteInterviewActionType,
  avaCompleteInterviewActionFailureType,
  avaCompleteInterviewActionSuccessType,
  avaCompleteInterviewRequestReducer,
  avaCompleteInterviewSelectors,
}

function* avaCompleteInterviewSaga(action: ISagaAction<IAvaCompleteInterviewAction>) {
  try {
    const { interviewToken } = action.payload
    yield* call(avaCompleteInterview, interviewToken)

    yield put(avaGetInterviewAction({ interviewToken }))
    yield take(avaGetInterviewActionSuccessType)

    yield put(avaCompleteInterviewActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaCompleteInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaCompleteInterview() {
  yield takeEvery(avaCompleteInterviewActionType, avaCompleteInterviewSaga)
}
