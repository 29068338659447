import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { upperFirst } from 'lodash'
import { LinesText } from 'src/components/Typography'
import useLogrocket from 'src/utils/useLogrocket'
import Layout from '../Layout'
import * as interviewSelectors from '../selectors'
import { Text, TextBold, HyperLink } from '../../components'
import { BusinessModel } from '../../api'

const Container = styled.div`
  border: 1px solid #edeef2;
  border-radius: 10px;
  background-color: #f5f6f8;
  padding: 34px 22px 30px 32px;
  margin-top: 30px;
`

const BlockWrapper = styled.div`
  margin-bottom: 12px;
`

const TextWrapper = styled.div`
  margin-bottom: 10px;
`

const Title = styled(Text)`
  color: rgba(115, 121, 145, 0.8);
`

const GrayText = styled(Text)`
  color: rgba(115, 121, 145, 0.8);
`

const TitleBold = styled(Text)`
  color: rgba(115, 121, 145, 0.8);
  font-weight: 500;
`

const LinkedinLink = styled(HyperLink)`
  font-weight: 400;
  line-height: 12px;
`

const LinkDevider = styled(Text)`
  font-weight: 400;
  line-height: 12px;
`

const LinksContainer = styled.div`
  display: flex;
  margin: 4px 0 20px 0;
`

const CvLink = styled(HyperLink)`
  font-weight: 400;
  line-height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

const Email = styled(Text)``.withComponent('span')

const Footer = styled.div`
  padding: 23px 40px 0 32px;
`

const times = {
  '8-12': 'Morning',
  '9-12': '9am-12pm',
  '12-15': '12pm-3pm',
  '12-18': 'Afternoon',
  '15-18': '3pm-6pm',
  '18-21': '6pm-9pm',
  '18-22': 'Evening',
  '21-24': '9pm-12am',
}

const Link = styled(HyperLink)`
  font-weight: 400;
`

const getAvailableDaysFromArray = (days) => {
  return days.map((d) => upperFirst(d)).join(', ')
}

const removeDuplicates = (arr) => {
  const unique = []
  for (let i = 0; i < arr.length; i += 1) {
    if (unique.indexOf(arr[i]) === -1) {
      unique.push(arr[i])
    }
  }
  return unique
}

const getAvailableTimeFromArray = (time) => {
  return removeDuplicates(time.map((t) => times[t])).join(', ')
}

function UpdateInterviewDetailsSuccess() {
  const { setLogrocket } = useLogrocket()
  const interview = useSelector(interviewSelectors.interview)
  const candidateName = useSelector(interviewSelectors.candidateName)
  const profile = useSelector(interviewSelectors.profile)

  const getPrice = () => {
    if (interview.agency.business_model === BusinessModel.B2C) {
      if (interview.interview_price_tiers) {
        return interview.interview_price_tiers
          .map((item) => {
            const priceTier = interview.agency.agency_price_tiers.find(
              (itm) => itm.id === item.agency_price_tier_id
            )
            if (!priceTier) return ''
            return `${interview.agency.currency_sign}${priceTier.price_min} - ${interview.agency.currency_sign}${priceTier.price_max} (${priceTier.name})`
          })
          .join('\n')
      }
      return ''
    }
    return `${interview.agency.currency_sign}${interview.agency.session_price}`
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (interview) {
      setLogrocket(
        `${interview.interview?.order_number.toString()}`,
        'Applicant order form - summary screen'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  return (
    <Layout
      title="Thank you for providing us with this information!"
      subtitle="We will contact you shortly with available time-slots for your coaching session"
      success
    >
      <>
        <Container>
          <BlockWrapper>
            <TextBold>SUMMARY OF YOUR INFORMATION</TextBold>
          </BlockWrapper>
          <BlockWrapper>
            <TextWrapper>
              <TextBold>{candidateName}</TextBold>
              {profile && profile.email && (
                <Email>
                  {!profile.linkedin_profile ? profile.email : `${profile.email}, `}
                </Email>
              )}
              <LinksContainer>
                {profile.linkedin_profile && (
                  <>
                    <LinkedinLink
                      href={
                        /^http(s)?:\/\//.test(profile.linkedin_profile)
                          ? profile.linkedin_profile
                          : `https://${profile.linkedin_profile}`
                      }
                      target="_blank"
                    >
                      LinkedIn profile
                    </LinkedinLink>
                  </>
                )}
                {interview.interview.cv_file_id && (
                  <>
                    {profile.linkedin_profile && <LinkDevider>,&nbsp;</LinkDevider>}
                    <CvLink href={interview.interview.cv_file.file.url} target="_blank">
                      Resume
                    </CvLink>
                  </>
                )}
              </LinksContainer>
            </TextWrapper>
            {(interview.interview.desired_position || interview.profile.company) && (
              <TextWrapper>
                {interview.interview.desired_position && (
                  <TextBold>{interview.interview.desired_position}</TextBold>
                )}
                <Text>
                  {interview.interview.position_company
                    ? `${interview.interview.position_company},`
                    : ''}{' '}
                  {interview.interview.position_location}
                </Text>
              </TextWrapper>
            )}
            {interview.interview.programming_language && (
              <TextWrapper>
                <Title>Programming language</Title>
                <TextBold>{interview.interview.programming_language}</TextBold>
              </TextWrapper>
            )}
          </BlockWrapper>
          {(interview.interview.position_description ||
            interview.interview.link_to_position) && (
            <BlockWrapper>
              {interview.interview.position_description && (
                <TextWrapper>
                  <Title>Position description</Title>
                  <Text>{interview.interview.position_description}</Text>
                </TextWrapper>
              )}
              {interview.interview.link_to_position && (
                <Link
                  href={
                    /^http(s)?:\/\//.test(interview.interview.link_to_position)
                      ? interview.interview.link_to_position
                      : `https://${interview.interview.link_to_position}`
                  }
                  target="_blank"
                >
                  Link to position
                </Link>
              )}
            </BlockWrapper>
          )}
          {interview.interview.interview_date && (
            <TextWrapper>
              <Title>Job Interview Date</Title>
              <Text>
                {moment(interview.interview.interview_date).format('DD.MM.YYYY')}
              </Text>
            </TextWrapper>
          )}
          <TextWrapper>
            <Title>Availability</Title>
            <Text>
              {getAvailableDaysFromArray(interview.interview.candidate_available_days)},{' '}
              {getAvailableTimeFromArray(interview.interview.candidate_available_hours)}
            </Text>
          </TextWrapper>
          {interview.interview.special_request && (
            <TextWrapper>
              <Title>Special request</Title>
              <Text>{interview.interview.special_request}</Text>
            </TextWrapper>
          )}
          {interview.agency.business_model !== BusinessModel.B2B && (
            <TextWrapper>
              <Title>Session price</Title>
              <LinesText>{getPrice()}</LinesText>
            </TextWrapper>
          )}
        </Container>
        <Footer>
          <TitleBold>Incorrect information provided?</TitleBold>
          <GrayText>
            Please reach out to our support team at{' '}
            <HyperLink href="mailto:hi@wizco.io">hi@wizco.io</HyperLink>. <br />
            We&apos;re standing by ready to help.
          </GrayText>
        </Footer>
      </>
    </Layout>
  )
}

export default UpdateInterviewDetailsSuccess
