import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from '@emotion/styled'
import { get } from 'lodash'
import { isSuperAdminRole } from 'src/utils/jwtToken'

import { Loader, Text, TextBold, NotFound } from 'src/components'
import { formattedDate } from 'src/utils/date'
import CoachForm from '../forms/CoachForm'
import SessionReport from '../forms/SessionReport'
import OrderInfo from '../forms/OrderInfo'
import SchedulingInfo from '../forms/SchedulingInfo'
import PostSessionInfo from '../forms/PostSessionInfo'
import { interview as interviewSelector } from '../selectors'

import Layout from '../Layout'
import { CoachingLanguage, InterviewStatus } from '../../api'
import { agencySelectors, getConversationAction } from '../../redux'

const StatusBlock = styled.div`
  display: flex;
  width: 800px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
`

const SessionStatus = styled.div`
  display: flex;
`

const StyledText = styled(Text)`
  font-size: 16px;
  margin-right: 15px;
`

interface IStyledTextBigProps {
  isIncomplete: boolean
}

const StyledTextBig = styled(TextBold)<IStyledTextBigProps>`
  font-size: 18px;
  color: ${(props) => (props.isIncomplete ? '#ff0000' : '#3b424f')};
`
const StyledTextSmall = styled(Text)`
  font-size: 14px;
`

const CreatedUpdatedWrapper = styled.div``

const Content = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
`

const Tabs = styled.div``
const Tab = styled.div<ITabProps>`
  padding: 10px 20px;
  display: inline-block;
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  border-bottom: ${(props) => (props.isSelected ? `3px solid ${props.color}` : 'none')};
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledLink = styled.a``

enum TABS {
  ORDER_INFO = 'ORDER_INFO',
  AVAILABILITY = 'AVAILABILITY',
  SCHEDULE_INFO = 'SCHEDULE_INFO',
  SESSION_REPORT = 'SESSION_REPORT',
  POST_SESSION_INFO = 'POST_SESSION_INFO',
}

interface ITabProps {
  color: string
  isSelected: boolean
  onClick: (any) => void
}

function CoachSessionDetailsTab({ tab }: { tab: TABS }) {
  switch (tab) {
    case TABS.ORDER_INFO:
      return <OrderInfo />
    case TABS.AVAILABILITY:
      return <CoachForm />
    case TABS.SCHEDULE_INFO:
      return <SchedulingInfo />
    case TABS.SESSION_REPORT:
      return <SessionReport />
    case TABS.POST_SESSION_INFO:
      return <PostSessionInfo />
    default:
      return <CoachForm />
  }
}

function Dashboard() {
  const dispatch = useDispatch()
  const isSuperAdmin = isSuperAdminRole()
  const isLoading = useSelector(interviewSelector.isLoading)
  const interview = useSelector(interviewSelector.interview)
  const interviewDetailsError = useSelector(interviewSelector.error)
  const hasError = interviewDetailsError !== ''
  const interviewStatus: InterviewStatus = get(interview, 'interview.status', '')
  const interviewIsIncomplete: boolean = get(interview, 'interview.is_incomplete', false)
  const interviewId: number = get(interview, 'interview.order_number', '')
  const isIncomplete =
    interviewStatus.toUpperCase() === 'SCHEDULED' && interviewIsIncomplete
  const interviewStartedAt: string = get(interview, 'interview.created_at', '')
  const interviewUpdatedAt: string = get(interview, 'interview.updated_at', '')
  const agency = useSelector(agencySelectors.data)
  const mainColor = get(agency, 'main_color', '#B202FF')
  const fullDomain = get(agency, 'full_domain', '')
  const expertCode: string = get(interview, 'expert_token.code', '')
  const expertHasCode = useMemo(
    () => expertCode !== '' && expertCode !== null && expertCode !== 'null',
    [expertCode]
  )
  const candidateCode: string = get(interview, 'candidate_token.code', '')
  const canDelete = interview?.interview?.id && !interview?.interview?.started_at

  const studentInterview = useMemo(() => `${fullDomain}/interviews/${candidateCode}`, [
    fullDomain,
    candidateCode,
  ])
  const expertInterview = useMemo(() => `${fullDomain}/interviews/${expertCode}`, [
    fullDomain,
    expertCode,
  ])

  const [selectedTab, setSelectedTab] = useState(TABS.ORDER_INFO)

  const handleSelectTab = useCallback(
    (val) => () => {
      setSelectedTab(val)
    },
    []
  )

  useEffect(() => {
    const hasConversationIntelligence =
      interview?.interview.enable_conversation_intelligence &&
      (!interview?.interview.coaching_language ||
        interview?.interview.coaching_language === CoachingLanguage.ENGLISH)
    if (hasConversationIntelligence) {
      dispatch(getConversationAction({ interviewToken: candidateCode }))
    }
  }, [candidateCode, dispatch, interview])

  return (
    <Layout title="Coaching Session Details" hideBg fullBody hasSessionButton={canDelete}>
      <>
        {isLoading && <Loader />}
        <StatusBlock>
          <SessionStatus>
            <StyledText>Session status:</StyledText>
            <StyledTextBig isIncomplete={isIncomplete}>
              {(
                (isSuperAdmin
                  ? interview?.interview?.admin_status
                  : interview?.interview?.agency_status) || ''
              ).toUpperCase()}
            </StyledTextBig>
          </SessionStatus>
          <CreatedUpdatedWrapper>
            <StyledTextSmall>ID: #{interviewId}</StyledTextSmall>
            <StyledTextSmall>
              Created: {formattedDate(interviewStartedAt)}
            </StyledTextSmall>
            <StyledTextSmall>
              Last updated: {formattedDate(interviewUpdatedAt)}
            </StyledTextSmall>
            <StyledLinks>
              <StyledLink
                href={studentInterview}
                target="_blank"
                rel="noreferrer noopener"
              >
                Interview Student
              </StyledLink>
              {expertHasCode && (
                <StyledLink
                  href={expertInterview}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Interview Expert
                </StyledLink>
              )}
            </StyledLinks>
          </CreatedUpdatedWrapper>
        </StatusBlock>
        <Tabs>
          <Tab
            color={mainColor}
            isSelected={selectedTab === TABS.ORDER_INFO}
            onClick={handleSelectTab(TABS.ORDER_INFO)}
          >
            Order Info
          </Tab>
          <Tab
            color={mainColor}
            isSelected={selectedTab === TABS.AVAILABILITY}
            onClick={handleSelectTab(TABS.AVAILABILITY)}
          >
            Availability
          </Tab>
          <Tab
            color={mainColor}
            isSelected={selectedTab === TABS.SCHEDULE_INFO}
            onClick={handleSelectTab(TABS.SCHEDULE_INFO)}
          >
            Scheduling Info
          </Tab>
          <Tab
            color={mainColor}
            isSelected={selectedTab === TABS.SESSION_REPORT}
            onClick={handleSelectTab(TABS.SESSION_REPORT)}
          >
            Session Report
          </Tab>
          <Tab
            color={mainColor}
            isSelected={selectedTab === TABS.POST_SESSION_INFO}
            onClick={handleSelectTab(TABS.POST_SESSION_INFO)}
          >
            Post Session Info
          </Tab>
        </Tabs>

        <Content>
          {!isLoading && !hasError && <CoachSessionDetailsTab tab={selectedTab} />}
          {!isLoading && hasError && <NotFound />}
        </Content>
      </>
    </Layout>
  )
}

export default Dashboard
