import React from 'react'
import styled from '@emotion/styled'
import close from '../static/close.svg'

interface INotificationProps {
  message: string
  onClose: () => void
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(255, 0, 42, 0.1);
  max-width: 400px;
  padding: 12px 14px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`

const NotificationStyled = styled.p`
  border-radius: 4px;
  color: #3b424f;
  font-size: 12px;
  margin: 0;
`

const CloseLink = styled.img`
  border-radius: 4px;
  color: #3b424f;
  font-size: 12px;
  margin: 0;
  width: 10px;
  height: 10px;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`

const Notification = (props: INotificationProps) => {
  const { message, onClose } = props

  return (
    <Container>
      <NotificationStyled>{message}</NotificationStyled>
      <CloseLink src={close} onClick={onClose} />
    </Container>
  )
}
export default Notification
