import { IExpertNote } from '../../api'
import { reduxDataFactory } from '../../utils/redux'

const {
  action: updateExpertNotesAction,
  actionType: updateExpertNotesActionType,
  reducer: expertNotesDataReducer,
  selectors: expertNotesSelectors,
} = reduxDataFactory<IExpertNote[]>('expertNotes', undefined, [])

export {
  updateExpertNotesAction,
  updateExpertNotesActionType,
  expertNotesDataReducer,
  expertNotesSelectors,
}
