import { call, delay, put, race, take } from 'typed-redux-saga'

import { getInterview, InterviewStatus } from '../../api'
import { getInterviewFailure, getInterviewSuccess } from '../../interview/actions'
import { reduxWatcherFactory } from '../../utils/redux'

interface IInterviewStartWatcherAction {
  interviewToken: string
}

const {
  startAction: startInterviewStartWatcherAction,
  startActionType: startInterviewStartWatcherActionType,
  stopAction: stopInterviewStartWatcherAction,
  stopActionType: stopInterviewStartWatcherActionType,
} = reduxWatcherFactory<IInterviewStartWatcherAction>('interviewStart')

function* interviewStartWatcher(interviewToken) {
  while (true) {
    try {
      const interview = yield* call(getInterview, interviewToken)
      yield put(getInterviewSuccess({ interview }))

      if (interview.interview.status === InterviewStatus.STARTED) {
        yield put(stopInterviewStartWatcherAction)
      } else {
        yield call(delay, 1000)
      }
    } catch (err) {
      const error = err as any
      let errorMessage = 'Server error. Please, contact support'
      if (error.response && error.response.status === 404) {
        errorMessage = "We can't find your invitation"
      }
      yield put(getInterviewFailure({ error: errorMessage }))
      yield put(stopInterviewStartWatcherAction)
    }
  }
}

function* watchInterviewStartWatcher() {
  while (true) {
    const action = yield take(startInterviewStartWatcherActionType)
    yield race([
      call(interviewStartWatcher, action.payload.interviewToken),
      take(stopInterviewStartWatcherActionType),
    ])
  }
}

export {
  startInterviewStartWatcherAction,
  startInterviewStartWatcherActionType,
  stopInterviewStartWatcherAction,
  stopInterviewStartWatcherActionType,
  watchInterviewStartWatcher,
}
