import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import {
  IAgoraRTC,
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  NetworkQuality,
} from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

import loadingAnimation from 'src/static/loading.gif'
import loadingDarkAnimation from 'src/static/loadingdark.gif'
import ImageTestCompleted from 'src/static/icTestCompleted.png'

import { getAgoraToken } from '../../../api'
import PrecallTestFailView from './precallTestFail'
import { DefaultVideoProfile } from '../VideoProfiles'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 10px;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin: 12px 0;

  ${!isMobileOnly &&
  css`
    margin: 18px 0;
    @media screen and (max-width: 2500px) {
      margin: 15px 0;
    }
    @media screen and (max-width: 2000px) {
      margin: 12px 0;
    }
    @media screen and (max-width: 1200px) {
      margin: 9px 0;
    }
    @media screen and (max-width: 900px) {
      margin: 6px 0;
    }
  `}
`
const Button = styled.button<{
  transparent?: boolean
  long?: boolean
  disabled?: boolean
}>`
  height: 32px;
  width: ${(props) => (props.long ? 93 : 61)}px;
  min-height: 32px;
  border-radius: 14px;
  border: 1px solid #6938aa;
  color: ${(props) => (props.transparent ? '#6938AA' : '#fff')};
  background-color: ${(props) => (props.transparent ? 'transparent' : '#6938AA')};
  font-weight: 1000;
  outline: none;
  font-size: 13px;
  margin: 0 10px;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  padding: 0;

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: default;
      opacity: 0.5;
    }
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    !isMobileOnly &&
    css`
      height: 48px;
      width: ${props.long ? 144 : 92}px;
      min-height: 48px;
      border-radius: 21px;
      font-size: 20px;
      margin: 0 15px;
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
      @media screen and (max-width: 2500px) {
        height: 40px;
        width: ${props.long ? 120 : 76}px;
        min-height: 40px;
        border-radius: 18px;
        font-size: 16px;
        margin: 0 13px;
      }
      @media screen and (max-width: 2000px) {
        height: 32px;
        width: ${props.long ? 93 : 61}px;
        min-height: 32px;
        border-radius: 14px;
        font-size: 13px;
        margin: 0 10px;
      }
      @media screen and (max-width: 1200px) {
        height: 24px;
        width: ${props.long ? 72 : 46}px;
        min-height: 24px;
        border-radius: 11px;
        font-size: 10px;
        margin: 0 8px;
      }
      @media screen and (max-width: 900px) {
        height: 16px;
        width: ${props.long ? 48 : 31}px;
        min-height: 16px;
        border-radius: 7px;
        font-size: 6px;
        margin: 0 5px;
      }
    `}
`
const Text = styled.div`
  color: ${({ theme }: { theme: any }) => theme.precallTextColor};
  font-size: 14px;
  margin-bottom: 12px;
  margin-top: 5px;

  ${!isMobileOnly &&
  css`
    font-size: 18px;
    line-height: 27px;
    @media screen and (max-width: 2500px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 12px;
      line-height: 18px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 9px;
      line-height: 14px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6px;
      line-height: 9px;
    }
  `}
`
const Loading = styled.img`
  align-self: center;
  height: 78px;
  margin: 10px 0;

  ${!isMobileOnly &&
  css`
    height: 162px;
    margin: 15px 0;
    @media screen and (max-width: 2500px) {
      height: 135px;
      margin: 13px 0;
    }
    @media screen and (max-width: 2000px) {
      height: 108px;
      margin: 10px 0;
    }
    @media screen and (max-width: 1200px) {
      height: 81px;
      margin: 8px 0;
    }
    @media screen and (max-width: 900px) {
      height: 54px;
      margin: 5px 0;
    }
  `}
`
const Completed = styled.img`
  align-self: center;
  height: 50px;
  margin: 20px 0;

  ${!isMobileOnly &&
  css`
    height: 75px;
    margin: 30px 0;
    @media screen and (max-width: 2500px) {
      height: 62px;
      margin: 25px 0;
    }
    @media screen and (max-width: 2000px) {
      height: 50px;
      margin: 20px 0;
    }
    @media screen and (max-width: 1200px) {
      height: 38px;
      margin: 15px 0;
    }
    @media screen and (max-width: 900px) {
      height: 25px;
      margin: 10px 0;
    }
  `}
`

interface IProps {
  agoraRTC: IAgoraRTC
  onContinue: (boolean) => void
}

const TestChannel = 'connection_check'
const TestUid = 777
const TestDurationMs = 30000
const SuccessCount = 3

const consoleStyles = [
  '%c%s %c%s',
  'color:#1e133b;font-weight: bold;',
  '[Wizco Precall]',
  'color:#1e133b',
]

const ConnectionTestView = ({ agoraRTC, onContinue }: IProps) => {
  const [result, setResult] = useState<NetworkQuality>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const networkQuality = useRef<NetworkQuality>({
    uplinkNetworkQuality: 0,
    downlinkNetworkQuality: 0,
  })
  const tracks = useRef<[IMicrophoneAudioTrack, ICameraVideoTrack]>(null)
  const client = useRef<IAgoraRTCClient>(null)
  const counter = useRef<number>(0)

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  function dropTestStream() {
    if (client.current) {
      tracks.current?.forEach((t) => t.close())
      log('Closed audio/video tracks')
      client.current.leave()
      log('Left channel')
      client.current = null
    }
  }

  function stopTest() {
    dropTestStream()
    setResult(networkQuality.current)
    setIsLoading(false)
  }

  async function startTest() {
    counter.current = 0
    client.current = agoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    })
    log(`Precall client initializing (id: ${(client.current as any)?._clientId})`)
    setIsLoading(true)
    const timeOut = setTimeout(() => {
      stopTest()
    }, TestDurationMs)
    try {
      tracks.current = await agoraRTC.createMicrophoneAndCameraTracks(
        {},
        {
          encoderConfig: {
            width: DefaultVideoProfile.width,
            height: DefaultVideoProfile.height,
          },
          optimizationMode: 'detail',
        }
      )
      log('Audio/video tracks created')

      const tokenData = await getAgoraToken(TestUid, TestChannel)
      await client.current.join(
        process.env.REACT_APP_AGORA_APP_ID,
        TestChannel,
        tokenData.token,
        TestUid
      )
      log('Channel joined')

      await client.current.publish(tracks.current)
      log('Audio/video tracks published')

      client.current.on('network-quality', (quality) => {
        log(`Connection test: network-quality: ${quality}`)
        networkQuality.current = quality
        if (quality.downlinkNetworkQuality !== 0 && quality.uplinkNetworkQuality !== 0) {
          counter.current += 1
        }
        if (counter.current >= SuccessCount) {
          stopTest()
          clearTimeout(timeOut)
        }
      })
    } catch (err) {
      error('Conenction test error', JSON.stringify(err))
      setResult({
        downlinkNetworkQuality: 6,
        uplinkNetworkQuality: 6,
      })
      setIsLoading(false)
      dropTestStream()
    }
  }

  function getWorseValue(qResult: NetworkQuality) {
    if (!qResult) return 0
    if (qResult.downlinkNetworkQuality === 0 || qResult.uplinkNetworkQuality === 0)
      return 0
    return Math.max(qResult.downlinkNetworkQuality, qResult.uplinkNetworkQuality)
  }

  function getQualityText(value: number) {
    switch (value) {
      case 1:
        return 'Excellent connection.'
      case 2:
      case 3:
        return 'Good connection.'
      case 4:
      case 5:
        return 'Week connection.'
      case 0:
        return 'Internet connection unknown.'
      default:
        return 'No internet connection found.'
    }
  }

  function getHeadText(loading: boolean) {
    if (loading) {
      return 'Checking your internet connection speed.'
    }
    return getQualityText(getWorseValue(result))
  }

  function isGoodResult(qResult: NetworkQuality) {
    return [1, 2, 3].find((i) => i === getWorseValue(qResult))
  }

  function getDetailResult(quality: number) {
    switch (quality) {
      case 4:
      case 5:
        return [
          'We detected a slow internet connection speed. Voice and video quality may be impacted. Please ensure the internet connection is stable and try again.',
          'Please continue testing. You`ll be able to contact our support team to troubleshoot any issues once you`re done.',
        ]
      case 0:
        return [
          'We were unable to detect the quality of internet connection. Please ensure the internet connection is stable and try again.',
          'Please continue testing. You`ll be able to contact our support team to troubleshoot any issues once you`re done.',
        ]
      default:
        return [
          'It seems like the internet connection is down or temporarily paused. Please ensure you`re connected to the internet and try again.',
          'Please continue testing. You`ll be able to contact our support team to troubleshoot any issues once you`re done.',
        ]
    }
  }

  function getResultView(qResult: NetworkQuality) {
    if (isGoodResult(qResult)) {
      return <Completed src={ImageTestCompleted} />
    }

    return (
      <PrecallTestFailView
        text1={getDetailResult(result.downlinkNetworkQuality)[0]}
        text2={getDetailResult(result.downlinkNetworkQuality)[1]}
      />
    )
  }

  // function getContainerHeight(loading: boolean, qResult: NetworkQuality) {
  //   if (loading) {
  //     return 242
  //   }

  //   switch (getWorseValue(qResult)) {
  //     case 1:
  //     case 2:
  //     case 3:
  //       return 180
  //     case 4:
  //     case 5:
  //       return 250
  //     default:
  //       return 242
  //   }
  // }

  useEffect(() => {
    if (agoraRTC) {
      startTest()
      return () => {
        if (client.current) {
          tracks.current?.forEach((t) => t.close())
          client.current.leave()
        }
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agoraRTC])

  return (
    <Container>
      <Text>{getHeadText(isLoading)}</Text>
      {isLoading ? (
        <Loading src={isMobileOnly ? loadingDarkAnimation : loadingAnimation} />
      ) : (
        getResultView(result)
      )}
      <ButtonsContainer>
        {!isGoodResult(result) && (
          <Button transparent long onClick={startTest} disabled={isLoading}>
            Retry test
          </Button>
        )}
        <Button
          onClick={() => onContinue(isGoodResult(result))}
          long
          disabled={isLoading}
        >
          Continue
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

export default ConnectionTestView
