/* eslint-disable no-console */
/* eslint-disable no-bitwise */

import useStateRef from 'react-usestateref'

const useRevAi = (
  onRecognition?: (text: string, isFinal: boolean) => void,
  onDisconnected?: () => void
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [audioContext, setAudioContext, audioContextRef] = useStateRef<AudioContext>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [websocket, setWebsocket, websocketRef] = useStateRef<WebSocket>()
  const [isConnected, setIsConnected] = useStateRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMuted, setIsMuted, isMutedRef] = useStateRef(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeMicDevice = (micId: string) => {}

  const processAudioEvent = (e) => {
    if (
      audioContextRef.current.state === 'suspended' ||
      audioContextRef.current.state === 'closed' ||
      !websocketRef.current ||
      isMutedRef.current
    ) {
      return
    }

    const inputData = e.inputBuffer.getChannelData(0)

    // The samples are floats in range [-1, 1]. Convert to PCM16le.
    const output = new DataView(new ArrayBuffer(inputData.length * 2))
    for (let i = 0; i < inputData.length; i += 1) {
      const multiplier = inputData[i] < 0 ? 0x8000 : 0x7fff // 16-bit signed range is -32768 to 32767
      output.setInt16(i * 2, (inputData[i] * multiplier) | 0, true) // index, value, little edian
    }

    const intData = new Int16Array(output.buffer)
    let index = intData.length - 1
    while (intData[index] === 0 && index > 0) {
      index -= 1
    }
    websocketRef.current.send(intData.slice(0, index + 1))
  }

  const parseResponse = (response) => {
    let message = ''
    for (let i = 0; i < response.elements.length; i += 1) {
      message +=
        response.type === 'final'
          ? response.elements[i].value
          : `${response.elements[i].value} `
    }
    return message
  }

  const onOpen = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((mediaStream) => {
      audioContextRef.current.suspend()
      const scriptNode = audioContextRef.current.createScriptProcessor(4096, 1, 1)
      const source = new MediaStreamAudioSourceNode(audioContextRef.current, {
        mediaStream,
      })
      scriptNode.addEventListener('audioprocess', processAudioEvent)
      source.connect(scriptNode)
      scriptNode.connect(audioContextRef.current.destination)
      audioContextRef.current.resume()
      setIsConnected(true)
    })
  }

  const onClose = (event) => {
    setIsConnected(false)
    onDisconnected?.()
    console.log(`Closed with ${event.code}: ${event.reason}`)
  }

  const onMessage = (event) => {
    const data = JSON.parse(event.data)
    switch (data.type) {
      case 'connected':
        console.log(`Connected, job id is ${data.id}`)
        break
      case 'partial':
        if (onRecognition) onRecognition(parseResponse(data), false)
        break
      case 'final':
        if (onRecognition) onRecognition(parseResponse(data), true)
        break
      default:
        console.error('Received unexpected message')
        break
    }
  }

  const runSpeechToText = async () => {
    const context = new AudioContext({ sampleRate: 44100 })
    setAudioContext(context)

    const content_type = `audio/x-raw;layout=interleaved;rate=${audioContextRef.current?.sampleRate};format=S16LE;channels=1`
    const baseUrl = 'wss://api.rev.ai/speechtotext/v1/stream'
    const query = `access_token=${process.env.REACT_APP_REV_AI_TOKEN}&content_type=${content_type}`
    const ws = new WebSocket(`${baseUrl}?${query}`)

    ws.onopen = onOpen
    ws.onclose = onClose
    ws.onmessage = onMessage
    ws.onerror = console.error
    setWebsocket(ws)
  }

  const stopSpeechToText = () => {
    if (websocketRef.current) {
      websocketRef.current.send('EOS')
      websocketRef.current.close()
    }
    if (audioContextRef.current) {
      audioContextRef.current.close()
    }
  }

  return {
    isConnected,
    changeMicDevice,
    muteSpeechToText: setIsMuted,
    runSpeechToText,
    stopSpeechToText,
  }
}

export default useRevAi
