/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { useSelector } from 'react-redux'
import { getShortLocalTime } from 'src/utils/date'
import useLogrocket from 'src/utils/useLogrocket'
import CheckIconImg from '../../static/check-icon.png'
import * as interviewSelectors from '../selectors'

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${isMobileOnly ? '' : '0px 0px 14px -3px lightgrey'};
  border-radius: 8px;
  margin-top: ${isMobileOnly ? '32' : '105'}px;
`

const CalendarHeader = styled.div`
  height: 80px;
  padding: ${isMobileOnly ? '0 12px' : '0 24px'};
  display: flex;
  justify-content: ${isMobileOnly ? 'space-around' : 'space-between'};
  align-items: ${isMobileOnly ? 'start' : 'center'};
  flex-direction: ${isMobileOnly ? 'column-reverse' : 'row'};
`

const WeekSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`

const WeekDaysLabel = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #222b45;
`

const TimeZoneLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.666667px;
  color: black;
`

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
`

const TimeSlotContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  padding: ${isMobileOnly ? '12px' : '12px 18px'};
  border-top: 1px solid #edeef2;
`

const TimeSlotBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  flex-direction: row;
  margin: 8px 36px 8px 0;
  align-items: center;
`

const BottomButtonsContainer = styled.div`
  height: ${isMobileOnly ? '82px' : '64px'};
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: ${isMobileOnly ? 'space-between' : 'center'};
  padding: ${isMobileOnly ? '40px 12px 0 12px' : '0 18px'};

  ${isMobileOnly &&
  css`
    border-top: 1px solid #edeef2;

    @media (orientation: landscape) {
      justify-content: start;
    }
  `}
`

const CheckImage = styled.div`
  width: 24px;
  height: 24px;
  background: url(${CheckIconImg});
  background-size: 100% 100%;
  cursor: pointer;
  margin-right: 8px;
`

const SlotDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  width: 50px;
`

const WeekLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  width: 30px;
`

const SlotTime = styled(WeekLabel)`
  width: unset;
  max-width: 150px;
  min-width: 100px;
`

const BottomButton = styled.div<{ width: number; filled?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.filled ? 'white' : '#B202FF')};
  background: ${(props) => (props.filled ? '#B202FF' : 'white')};
  border: 1px solid #b202ff;
  border-radius: 4px;
  margin: ${isMobileOnly ? '0' : '0 12px'};
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  font-weight: 500;
  font-size: 12px;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-right: 40px;
    }
  `}

  & :hover {
    opacity: 0.8;
  }

  & :active {
    opacity: 0.5;
  }
`

const DetailsTextContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
`

const DetailsText = styled.textarea`
  height: ${isMobileOnly ? 105 : 216}px;
  background: #f5f6f8;
  border: 1px solid #e1e3ea;
  border-radius: 12px;
  margin: ${isMobileOnly ? '32px 12px' : '32px 16px'};
  padding: 20px 16px;
  font-size: 14px;
  resize: none;

  &:focus {
    outline: none !important;
    border: 1px solid #b202ff;
  }

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-right: 40px;
      height: 85px;
    }
  `}
`

interface IProps {
  selectedDays: number[]
  onChange: (dates: number[]) => void
  onEdit: () => void
  onConfirm: (data: IConfirmData) => void
}

interface IConfirmData {
  comment: string
}

function ConfirmExpertAvailability({
  selectedDays,
  onChange,
  onEdit,
  onConfirm,
}: IProps) {
  const { setLogrocket } = useLogrocket()
  const interviewDetails = useSelector(interviewSelectors.interview)
  const detailsTextRef = useRef<HTMLTextAreaElement>(null)
  const [confirmed, setConfirmed] = useState(false)

  function onSlotSelected(index: number) {
    const selectedDaysCopy = [...selectedDays]
    selectedDaysCopy.splice(index, 1)
    onChange(selectedDaysCopy)
    if (selectedDaysCopy.length === 0) {
      onEdit()
    }
  }

  function getDateLabel(dateMs: number) {
    const date = new Date(dateMs)
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`
    return `${day}/${month},`
  }

  function getWeekLabel(dateMs: number) {
    const date = new Date(dateMs)
    return `${date.toLocaleDateString('en-US', { weekday: 'short' })}`
  }

  function getTimeLabel(dateMs: number) {
    const interviewLengthMs = interviewDetails.agency.duration * 60 * 1000
    return `| ${getShortLocalTime(new Date(dateMs))} - ${getShortLocalTime(
      new Date(dateMs + interviewLengthMs)
    )}`
  }

  function handleConfirm() {
    setConfirmed(true)
    onConfirm({ comment: detailsTextRef?.current?.value })
  }

  useEffect(() => {
    if (interviewDetails) {
      setLogrocket(
        `${interviewDetails.interview?.order_number.toString()}`,
        'Expert availability page - provided'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewDetails])

  return (
    <Container>
      <CalendarContainer>
        <CalendarHeader>
          <WeekSwitchContainer>
            <WeekDaysLabel>
              {isMobileOnly ? 'Your availability' : 'Please review your availability'}
            </WeekDaysLabel>
          </WeekSwitchContainer>
          <TimeZoneLabel>
            {Intl.DateTimeFormat().resolvedOptions().timeZone} (
            {getShortLocalTime(new Date())})
          </TimeZoneLabel>
        </CalendarHeader>
        <ScrollContainer>
          <TimeSlotContainer>
            {selectedDays.map((weekDay, index) => {
              return (
                <TimeSlotBlock key={index}>
                  <CheckImage onClick={() => onSlotSelected(index)} />
                  <SlotDate>{getDateLabel(weekDay)}</SlotDate>
                  <WeekLabel>{getWeekLabel(weekDay)}</WeekLabel>
                  <SlotTime>{getTimeLabel(weekDay)}</SlotTime>
                </TimeSlotBlock>
              )
            })}
          </TimeSlotContainer>
          <DetailsTextContainer>
            <DetailsText
              ref={detailsTextRef}
              placeholder={
                isMobileOnly ? 'Additional info' : `Anything you'd like us to know?`
              }
            />
          </DetailsTextContainer>
        </ScrollContainer>
      </CalendarContainer>
      <BottomButtonsContainer>
        <BottomButton onClick={onEdit} width={90}>
          Edit
        </BottomButton>
        <BottomButton
          onClick={handleConfirm}
          width={186}
          filled
          disabled={confirmed || selectedDays.length === 0}
        >
          Send availability
        </BottomButton>
      </BottomButtonsContainer>
    </Container>
  )
}

export default ConfirmExpertAvailability
