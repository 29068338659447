/* eslint-disable no-alert */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get, cloneDeep, difference, differenceBy, findIndex } from 'lodash'
import styled from '@emotion/styled'
import closeIcon from 'src/static/close.svg'

import { isSuperAdminRole } from '../../utils/jwtToken'

import {
  BusinessModel,
  CoachingLanguage,
  ICandidateProfile,
  IExpertProfile,
  InterviewStatus,
  UploadedFile,
} from '../../api'
import { Notification, Button, Checkbox, Loader } from '../../components'
import { agencySelectors } from '../../redux'

import {
  sendToApplicant,
  sendToApplicantClearSuccess,
  sendToApplicantClearError,
} from '../actions'
import {
  updateAvailabilityInfoAction,
  updateAvailabilityInfoActionFailureClear,
  updateAvailabilityInfoActionSuccessClear,
  updateAvailabilityInfoSelectors,
} from '../redux'
import {
  interview as interviewSelector,
  sendToApplicant as sendToApplicantSlector,
} from '../selectors'

import ApplicantDetails from './ApplicantDetails'
import { ICoachFormLogos } from './CoachFormInterfaces'
import ExpertDetails from './ExpertDetails'

const ButtonStyled = styled(Button)`
  margin: 10px;
  && {
    margin-top: 40px;
  }
`

const SentText = styled.div`
  font-size: 14px;
  opacity: 0.8;
  margin: 0 4px;
  position: absolute;
  left: 426px;
  top: 107px;
`

const Form = styled.form`
  margin-top: 20px;
`

const FormsWrapper = styled.div`
  margin-bottom: 15px;
  white-space: nowrap;
`

const FormWrapper = styled.div`
  display: inline-flex;
  position: relative;
  vertical-align: top;
  margin: 10px;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid #eee;
`

const AddExpertWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`

const FormTitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
`

const CloseButton = styled.label`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  > img {
    display: block;
    width: 20px;
    height: 20px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  position: relative;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: 10px;
`

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function CoachForm() {
  const { id, interviewId } = useParams()
  const dispatch = useDispatch()
  const isSuperAdmin = isSuperAdminRole()

  const agency = useSelector(agencySelectors.data)
  const isB2C = agency?.business_model === BusinessModel.B2C
  const initialSessionPrice = isB2C ? 0 : agency.session_price

  const updateDetailsApiError = useSelector(updateAvailabilityInfoSelectors.error)
  const sendToApplicantApiError = useSelector(sendToApplicantSlector.error)
  const interview = useSelector(interviewSelector.interview)
  const isLoadingUpdate = useSelector(updateAvailabilityInfoSelectors.isLoading)
  const isLoadingSend = useSelector(sendToApplicantSlector.isLoading)
  const isSuccessUpdate = useSelector(sendToApplicantSlector.success)
  const candidateApi: Partial<ICandidateProfile> = get(interview, 'candidate_profile', {})
  const expertsApi: IExpertProfile[] = get(interview, 'expert_profiles', [])
  const useGeneralLink: boolean = get(interview, 'interview.use_general_link', false)
  const enableConversationIntelligence: boolean =
    get(interview, 'interview.enable_conversation_intelligence', false) &&
    (!interview.interview.coaching_language ||
      interview.interview.coaching_language === CoachingLanguage.ENGLISH)
  const getCanManageConversationIntelligence = () => {
    if (
      interview.interview.coaching_language !== null &&
      interview.interview.coaching_language !== CoachingLanguage.ENGLISH
    ) {
      return false
    }

    if (!enableConversationIntelligence) {
      const interviewStatus = get(interview, 'interview.status', '')
      const interviewIsIncomplete: boolean = get(
        interview,
        'interview.is_incomplete',
        false
      )
      const isIncomplete =
        interviewStatus.toUpperCase() === 'SCHEDULED' && interviewIsIncomplete

      if (isIncomplete) {
        return true
      }

      if (
        (interviewStatus.toUpperCase() === 'COMPLETED' ||
          interviewStatus.toUpperCase() === 'REPORT_PROVIDED' ||
          interviewStatus.toUpperCase() === 'ENDED') &&
        !interview.interview?.conversation_id
      ) {
        return false
      }

      if (interviewStatus.toUpperCase() === 'STARTED') {
        return false
      }
    }

    return true
  }
  const canManageConversationIntelligence = getCanManageConversationIntelligence()
  const sentToApplicantAt: string = get(interview, 'interview.sent_to_applicant_at', '')
  const isScheduledInterview =
    get(interview, 'interview.status', '') === InterviewStatus.SCHEDULED
  const [isInitial, setIsInitial] = useState(true)

  const [isDirtyForce, setIsDirtyForce] = useState(false)
  const [experts, setExperts] = useState<Array<Partial<IExpertProfile>>>([
    {
      id: '0',
      slot_price: initialSessionPrice,
      time_slots: [
        {
          id: '0',
          from: '',
          to: '',
          time_zone: '',
        },
      ],
    },
  ])
  const prevExperts: IExpertProfile[] = usePrevious(experts) || []
  const expertsCount = experts.length
  const onlyOneExpert = useMemo(() => expertsCount === 1, [expertsCount])

  const [logos, setLogos] = useState<ICoachFormLogos>({
    applicant: {
      logo: null,
      logoUrlFromApi: null,
    },
    experts: [
      {
        id: '0',
        logo: null,
        logoUrlFromApi: null,
      },
    ],
  })

  useEffect(() => {
    return () => {
      dispatch(updateAvailabilityInfoActionSuccessClear())
      dispatch(sendToApplicantClearSuccess())
    }
  }, [dispatch])

  useEffect(() => {
    if (expertsApi.length > 0) {
      const expertsClone = cloneDeep(expertsApi) as IExpertProfile[]
      expertsClone.forEach((e) => {
        if (e.time_slots?.length === 0) {
          e.time_slots = [
            {
              id: '0',
              from: '',
              to: '',
              time_zone: '',
            },
          ]
        }
      })
      setExperts(expertsClone)
      setLogos({
        applicant: {
          logo: null,
          logoUrlFromApi: get(candidateApi, 'logo.url', null),
        },
        experts: expertsClone.map((expert) => ({
          id: expert.id,
          logo: null,
          logoUrlFromApi: expert.logo.url,
        })),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertsApi, candidateApi])

  const initialExperts = cloneDeep(expertsApi) as IExpertProfile[]

  const showLoader = isLoadingUpdate || isLoadingSend
  const { handleSubmit, control, errors, setValue, getValues, formState } = useForm({
    mode: 'all',
    defaultValues: {
      experts: initialExperts,
      applicant: cloneDeep(candidateApi) as ICandidateProfile,
      useGeneralLink,
      enableConversationIntelligence:
        enableConversationIntelligence && canManageConversationIntelligence,
    },
  })

  const { isDirty } = formState
  const disableSaveButton = !isDirty && !isDirtyForce
  const formExperts = get(getValues(), `experts`) || []

  const onSubmit = (payload) => {
    const mergedExperts = payload.experts.map((rawExpert, index) => {
      const { price_tiers, session_currency, ...expert } = rawExpert
      /* eslint-disable-next-line no-param-reassign */
      expert.id = experts[index].id
      /* eslint-disable-next-line no-param-reassign */

      const timeSlots = experts[index].time_slots.filter((t) => t.from && t.to)
      const apiExpert = expertsApi.find((e) => e.id === expert.id)
      const hasTimeSlotChanges = !apiExpert?.time_slots.every((ts) =>
        timeSlots.some((s) => s.from === ts.from && s.to === ts.to)
      )

      expert.time_slots =
        !hasTimeSlotChanges && timeSlots.length === 0 ? undefined : timeSlots
      const expertLogo: UploadedFile = get(logos, `experts[${index}].logo`, null)
      const expertLogoApi: string = get(logos, `experts[${index}].logoUrlFromApi`, null)

      if (expertLogo) {
        /* eslint-disable-next-line no-param-reassign */
        expert.logo = expertLogo
      } else {
        // eslint-disable-next-line no-param-reassign
        expert.remote_logo_url = expertLogoApi
      }

      return expert
    })

    const deleted = differenceBy(expertsApi, mergedExperts, 'id').map((del) => ({
      ...del,
      _destroy: true,
    }))
    const applicantLogo: UploadedFile = get(logos, 'applicant.logo', null)

    const mergedData = {
      ...payload,
      applicant: {
        ...payload.applicant,
        logo: applicantLogo,
      },
      experts: [...mergedExperts, ...deleted],
    }

    const confirmed = window.confirm(`Are you sure you want to save changes?`)
    if (confirmed) {
      dispatch(
        updateAvailabilityInfoAction({
          agencyId: id,
          id: interviewId,
          candidate: mergedData.applicant,
          experts: mergedData.experts,
          use_general_link: payload.useGeneralLink,
          enable_conversation_intelligence:
            canManageConversationIntelligence && payload.enableConversationIntelligence,
        })
      )
      setIsDirtyForce(false)
    }
  }

  const handleSendToApplicant = () => {
    const confirmed = window.confirm(`Send to applicant?`)

    if (confirmed) {
      dispatch(sendToApplicant({ agencyId: id, id: interviewId }))
    }
  }

  const clearErrorUpdate = () => {
    dispatch(updateAvailabilityInfoActionFailureClear())
  }

  const clearErrorSend = () => {
    dispatch(sendToApplicantClearError())
  }

  const handleDeleteExpert = useCallback(
    (expertId) => () => {
      setIsInitial(false)
      const deleteConfirmed = window.confirm(
        `Are you sure you want to delete this expert?`
      )
      if (deleteConfirmed) {
        setExperts((prev) => {
          const newExperts = prev.slice()
          const toReturnExperts = newExperts.filter((exp) => exp.id !== expertId)
          return toReturnExperts
        })
        setLogos((prevLogos) => {
          const newExpertsLogo = prevLogos.experts.slice()
          const toReturnExperts = newExpertsLogo.filter((exp) => exp.id !== expertId)
          return { ...prevLogos, experts: toReturnExperts }
        })
        setIsDirtyForce(true)
      }
    },
    []
  )

  const getDateString = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleString()
  }

  // This effect fot sync form state and experts state after removing/adding experts
  useEffect(() => {
    if (prevExperts.length > experts.length) {
      const deletedItem = difference(prevExperts, experts)
      const deletedIndex = findIndex(
        prevExperts,
        (prSlot) => prSlot.id === deletedItem[0].id
      )
      const newExpertsFromForm = cloneDeep(
        formExperts.filter((slot, slotIndex) => slotIndex !== deletedIndex)
      )
      setValue(`experts`, newExpertsFromForm)
    }
    if (!isInitial && prevExperts.length < experts.length) {
      const newExpertId = experts.length - 1
      setValue(`experts[${newExpertId}]`, {
        slot_price: initialSessionPrice,
        time_slots: [
          {
            id: '0',
            from: '',
            to: '',
            time_zone: '',
          },
        ],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experts, formExperts, prevExperts, isInitial, setValue])

  const isDataCompleted =
    candidateApi.logo?.url &&
    candidateApi.current_position &&
    interview.interview.status !== InterviewStatus.CANDIDATE_INVITED &&
    expertsApi.length > 0 &&
    expertsApi.filter((e) => e.time_slots?.length > 0).length > 0 &&
    !expertsApi.some((e) => {
      if (e.first_name === '') return true
      if (e.last_name === '') return true
      if (e.email === '') return true
      if (!e.logo?.url) return true
      if (!e.expert_price) return true
      if (!e.expert_currency) return true
      if (e.company === '') return true
      if (e.current_position === '') return true
      if (e.about === '') return true
      if (!e.educations?.some((ed) => ed !== '')) return true
      if (!e.experiences?.some((ex) => ex !== '')) return true
      return false
    })

  return (
    <>
      {showLoader && <Loader />}
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {updateDetailsApiError && (
            <Notification onClose={clearErrorUpdate} message={updateDetailsApiError} />
          )}
          {sendToApplicantApiError && (
            <Notification onClose={clearErrorSend} message={sendToApplicantApiError} />
          )}
          <Row>
            <Controller
              as={Checkbox}
              name="useGeneralLink"
              control={control}
              useTheme
              defaultChecked={useGeneralLink}
            >
              Use General Link (Agora)
            </Controller>
          </Row>
          <Row>
            <Controller
              as={Checkbox}
              name="enableConversationIntelligence"
              control={control}
              useTheme
              defaultChecked={enableConversationIntelligence}
              disabled={
                (!isSuperAdmin && isScheduledInterview) ||
                !canManageConversationIntelligence
              }
            >
              Conversation intelligence
              {interview?.interview?.conversation_id
                ? ` (${interview.interview.conversation_id})`
                : ''}
            </Controller>
          </Row>
          <FormsWrapper>
            <FormWrapper>
              <FormTitle>Applicant details</FormTitle>
              <ApplicantDetails
                control={control}
                errors={errors}
                candidateApi={candidateApi}
                setLogos={setLogos}
                onLogoChange={() => setIsDirtyForce(true)}
              />
            </FormWrapper>
            {experts.map((exp, index) => (
              <FormWrapper key={exp.id}>
                <FormTitle>{`Expert ${index + 1} details`}</FormTitle>
                {!onlyOneExpert && !interview.interview?.started_at && (
                  <CloseButton onClick={handleDeleteExpert(exp.id)}>
                    <img src={closeIcon} alt="close" />
                  </CloseButton>
                )}

                <ExpertDetails
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  expertId={index}
                  expertUid={exp.id}
                  time_slots={exp.time_slots}
                  setExperts={setExperts}
                  setLogos={setLogos}
                  logoUrl={logos.experts[index].logoUrlFromApi}
                  isSuperAdmin={isSuperAdmin}
                  apiExpert={expertsApi[index]}
                  onLogoChange={() => setIsDirtyForce(true)}
                />
              </FormWrapper>
            ))}
            <AddExpertWrapper>
              <ButtonStyled
                onClick={() => {
                  setExperts((prev) => [
                    ...prev,
                    {
                      id: experts.length.toString(),
                      slot_price: initialSessionPrice,
                      time_slots: [
                        {
                          id: '0',
                          from: '',
                          to: '',
                          time_zone: '',
                        },
                      ],
                    },
                  ])
                  setLogos((prev) => ({
                    applicant: prev.applicant,
                    experts: [
                      ...prev.experts,
                      {
                        id: experts.length.toString(),
                        logo: null,
                        logoUrlFromApi: null,
                      },
                    ],
                  }))
                  setIsDirtyForce(true)
                }}
                title="Add additional expert"
                type="button"
                customTheme="outline"
                useTheme
              />
            </AddExpertWrapper>
          </FormsWrapper>
          <ButtonsWrapper>
            <ButtonStyled
              title="Update info"
              type="submit"
              disabled={disableSaveButton}
              useTheme
            />
            <ButtonStyled
              onClick={handleSendToApplicant}
              title="Send to applicant"
              type="button"
              disabled={!isDataCompleted || isSuccessUpdate}
              useTheme
            />
            {sentToApplicantAt && (
              <SentText>(Sent: {getDateString(sentToApplicantAt)})</SentText>
            )}
          </ButtonsWrapper>
        </Form>
      </div>
    </>
  )
}

export default CoachForm
