import React, { useCallback, useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import chevron from 'src/static/chevron-down.svg'
import { InterviewSearch } from '../../api'

const SearchWrapper = styled.div`
  position: relative;
`

interface ISearchButtonProps {
  expanded: boolean
}
const SearchButton = styled.div<ISearchButtonProps>`
  position: relative;
  padding-right: 25px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: all 200ms;
  }
`

const SearchItems = styled.div`
  position: absolute;
  background: #fff;
  padding: 15px 0;
  z-index: 5;
  border-radius: 10px;
  box-shadow: -10px 2px 29px 0 #edeef2;
  width: 150px;
`

interface ISearchItemProps {
  isSelected: boolean
}

interface ISearchProps {
  isSuperAdmin: boolean
  onSelect: (val) => void
}

const SearchItem = styled.div<ISearchItemProps>`
  padding: 5px 25px;
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const searchOptions: Array<{
  id: InterviewSearch
  label: string
  onlySuperAdmin?: boolean
}> = [
  { id: 'candidate_email', label: 'Applicant email' },
  { id: 'candidate_name', label: 'Applicant name' },
  { id: 'position_company', label: 'Company' },
  { id: 'expert_name', label: 'Expert Name', onlySuperAdmin: true },
  { id: 'desired_position', label: 'Position' },
  { id: 'order_number', label: 'Session ID' },
  { id: 'conversation_id', label: 'Conversation ID', onlySuperAdmin: true },
]

const Search = ({ isSuperAdmin, onSelect }: ISearchProps) => {
  const sortRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState<InterviewSearch>('candidate_name')

  const handleSelect = useCallback(
    (val: InterviewSearch) => () => {
      onSelect(val)
      setVisible(false)
      setSelected(val)
    },
    [onSelect]
  )

  const toggleVisible = useCallback(() => {
    setVisible((prev) => !prev)
  }, [])

  const clickOutsideDropMenu = useCallback((e) => {
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    !(sortRef.current && sortRef.current.contains(e.target)) && setVisible(false)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideDropMenu)
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropMenu)
    }
  }, [clickOutsideDropMenu])

  return (
    <SearchWrapper ref={sortRef}>
      <SearchButton expanded={visible} onClick={toggleVisible}>
        {searchOptions.find((o) => o.id === selected)?.label || 'Search by'}
      </SearchButton>
      {visible && (
        <SearchItems>
          {searchOptions.map(
            (o) =>
              (!o.onlySuperAdmin || isSuperAdmin) && (
                <SearchItem
                  key={o.id}
                  isSelected={selected === o.id}
                  onClick={handleSelect(o.id)}
                >
                  {o.label}
                </SearchItem>
              )
          )}
        </SearchItems>
      )}
    </SearchWrapper>
  )
}

export default Search
