export const AvaBadColor = '#F23482'
export const AvaNormColor = '#F1BE2B'
export const AvaGoodColor = '#00DE9F'

export const getProgressColor = (progress: number) => {
  if (progress <= 0.4) return AvaBadColor
  if (progress <= 0.7) return AvaNormColor
  return AvaGoodColor
}

export const getIndexColor = (index: number) => {
  switch (index) {
    case 0:
      return AvaBadColor
    case 1:
      return AvaNormColor
    default:
      return AvaGoodColor
  }
}
