import { createAction } from '@reduxjs/toolkit'
import { IFailure } from '../utils/redux'
import {
  GET_AGENCIES,
  GET_AGENCIES_SUCCESS,
  GET_AGENCIES_FAILURE,
  GET_INTERVIEWS,
  GET_INTERVIEWS_SUCCESS,
  GET_INTERVIEWS_FAILURE,
  IGetAgenciesSuccessAction,
  IGetInterviewsAction,
  IGetInterviewsSuccessAction,
  GET_STATS,
  GET_STATS_FAILURE,
  GET_STATS_SUCCESS,
  IGetStatsSuccessAction,
  IGetStatsAction,
} from './types'

export const getInterviews = createAction<IGetInterviewsAction['payload']>(GET_INTERVIEWS)
export const getInterviewsSuccess = createAction<IGetInterviewsSuccessAction['payload']>(
  GET_INTERVIEWS_SUCCESS
)
export const getInterviewsFailure = createAction<IFailure>(GET_INTERVIEWS_FAILURE)
export const getAgencies = createAction(GET_AGENCIES)
export const getAgenciesSuccess = createAction<IGetAgenciesSuccessAction['payload']>(
  GET_AGENCIES_SUCCESS
)
export const getAgenciesFailure = createAction<IFailure>(GET_AGENCIES_FAILURE)
export const getStats = createAction<IGetStatsAction['payload']>(GET_STATS)
export const getStatsSuccess = createAction<IGetStatsSuccessAction['payload']>(
  GET_STATS_SUCCESS
)
export const getStatsFailure = createAction<IFailure>(GET_STATS_FAILURE)
