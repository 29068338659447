import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'
import { formattedDate } from 'src/utils/date'
import ReactQuill from 'react-quill'
import styled from '@emotion/styled'
import {
  Notification,
  Button,
  Loader,
  TextBold,
  Text,
  Input,
  Select,
} from 'src/components'
import EditorToolbar, { getModules, formats } from 'src/components/EditorToolbar'
import { conversationSelectors, systemSettingsSelectors } from 'src/redux'
import * as agencySelectors from '../selectors'
import {
  saveSessionReport,
  saveSessionReportClearError,
  saveSessionReportClearSuccess,
  interviewComplete,
  interviewCompleteClearSuccess,
  interviewCompleteClearError,
  interviewToEnd,
  interviewToEndClearSuccess,
  interviewToEndClearError,
} from '../actions'
import { IInterviewReport, InterviewStatus } from '../../api'

const MINUTES_MS = 1000 * 60

const booleanList = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const ButtonStyled = styled(Button)`
  margin: 10px;
  && {
    margin-top: 40px;
  }
`

const Form = styled.form`
  margin-top: 20px;
`
const EditorBlock = styled.div`
  display: flex;
  margin-bottom: 30px;
  .ql-disabled {
    cursor: not-allowed;
    background-color: #f5f6f8;
    opacity: 0.6;
  }
`

const HeaderBlock = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
`

const EditorTitle = styled(TextBold)`
  font-size: 16px;
  margin-right: 20px !important;
  flex: 0 0 150px;
`
const EditorWrapper = styled.div`
  width: 100%;
`

const PerformanceWrapper = styled.div`
  width: 100%;
`
const PerformanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const PerformanceItem = styled.div`
  width: 49%;
  display: flex;
  > div {
    width: 100%;
    &:last-of-type {
      flex: 0 0 33%;
    }
  }
`

const FormTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
`

const ButtonsWrapper = styled.div`
  display: flex;
`
const SessionStartWrapper = styled.div`
  display: flex;
`

const SessionStartValue = styled.div`
  width: 200px;
`

const LinksWrapper = styled.div``
const TypeFormLinkWrapper = styled.div`
  display: flex;
`

const LinkStyled = styled.a``
const TypeFormId = styled.div`
  margin-right: 15px;
`
const VideoNotReady = styled(Text)`
  font-size: 16px;
  color: #f00;
`
const ToEndButtonWrapper = styled.div`
  padding-right: 200px;
  > button {
    margin-top: 0 !important;
  }
`

function SessionReport() {
  const { id, interviewId } = useParams()
  const dispatch = useDispatch()
  const { metrics } = useSelector(conversationSelectors.data)
  const systemSettings = useSelector(systemSettingsSelectors.data)
  const reportDetailsApi = useSelector(agencySelectors.reportDetails.report)

  const { word_count: wordsCount } = metrics || ({} as any)

  const { handleSubmit, control, errors } = useForm({
    mode: 'all',
    defaultValues: reportDetailsApi,
  })

  const [reportSummary, setReportSummary] = useState('')
  const [actionItems, setActionItems] = useState('')
  const [keep, setKeep] = useState('')
  const [workOn, setWorkOn] = useState('')
  const [reportWarninText, setReportWarningText] = useState('')

  useEffect(() => {
    setReportSummary(reportDetailsApi.summary)
    setActionItems(reportDetailsApi.items_to_accomplish)
    setKeep(reportDetailsApi.items_to_keep)
    setWorkOn(reportDetailsApi.items_to_improve)
  }, [reportDetailsApi])

  useEffect(() => {
    if (wordsCount > 0 && systemSettings) {
      const minTextsCount = systemSettings.minWordsCount
      if (wordsCount < minTextsCount) {
        setReportWarningText(
          `Interview intelligence is hidden (${wordsCount} < ${minTextsCount})`
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordsCount, systemSettings])

  const interview = useSelector(agencySelectors.interview.interview)
  const videoStatus = get(interview, 'interview.interview_recording_status', '')
  const recordingStartAt = get(interview, 'interview.interview_recording_start_at', '')
  const recordingStartMs = recordingStartAt ? new Date(recordingStartAt).getTime() : 0
  const recordingEndAt: string = get(
    interview,
    'interview.interview_recording_end_at',
    ''
  )
  const recordingEndMs = recordingEndAt ? new Date(recordingEndAt).getTime() : 0
  const videoIsReady = videoStatus === 'uploaded'
  const typeFormId: number = get(interview, 'interview.order_number', '')
  const startedAt: string = get(interview, 'interview.started_at')
  const formatedStart = formattedDate(startedAt)
  const startMs = startedAt ? new Date(startedAt).getTime() : 0
  const ended_at: string = get(interview, 'interview.ended_at')
  const endMs = ended_at ? new Date(ended_at).getTime() : 0
  const duration = () => {
    if (recordingStartMs !== 0 && recordingEndMs !== 0) {
      return Math.round((recordingEndMs - recordingStartMs) / MINUTES_MS)
    }
    if (startMs === 0 && endMs === 0) {
      return 0
    }
    if (startMs === 0 && endMs !== 0) {
      return 'Dates conflict'
    }
    if (startMs !== 0 && endMs === 0) {
      return 'In progress'
    }
    return Math.round((endMs - startMs) / MINUTES_MS)
  }

  const status: InterviewStatus = get(interview, 'interview.status', '')
  const isReportProvided = status === InterviewStatus.REPORT_PROVIDED
  const canBeEnded =
    status === InterviewStatus.SCHEDULED || status === InterviewStatus.STARTED
  const saveSessionReportSuccess = useSelector(agencySelectors.sessionReport.success)
  const saveSessionReportError = useSelector(agencySelectors.sessionReport.error)
  const isLoadingInterviewComplete = useSelector(
    agencySelectors.interviewComplete.isLoading
  )
  const interviewCompleteError = useSelector(agencySelectors.interviewComplete.error)
  const saveSessionReportSLoading = useSelector(agencySelectors.sessionReport.isLoading)
  const interviewToEndError = useSelector(agencySelectors.interviewToEnd.error)

  const isLoadingSend = useSelector(agencySelectors.sendToApplicant.isLoading)
  const showLoader =
    isLoadingSend || saveSessionReportSLoading || isLoadingInterviewComplete

  const onSubmit = (payload: IInterviewReport) => {
    const request: Partial<IInterviewReport> = {
      advancement: payload.advancement,
      advancement_rating: payload.advancement_rating,
      communication_rating: payload.communication_rating,
      first_impression_rating: payload.first_impression_rating,
      items_to_accomplish: actionItems,
      items_to_improve: workOn,
      items_to_keep: keep,
      knowledge_rating: payload.knowledge_rating,
      problem_solving_rating: payload.problem_solving_rating,
      summary: reportSummary,
    }

    const confirmed = window.confirm(
      'Are you sure you want to update Session Report info?'
    )

    if (confirmed) {
      dispatch(saveSessionReport({ agencyId: id, id: interviewId, report: request }))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(saveSessionReportClearSuccess())
      dispatch(interviewCompleteClearSuccess())
      dispatch(interviewToEndClearSuccess())
    }
  }, [dispatch])

  const clearErrorUpdate = () => {
    dispatch(saveSessionReportClearError())
  }

  const clearErrorComplete = () => {
    dispatch(interviewCompleteClearError())
  }

  const clearErrorToEnd = () => {
    dispatch(interviewToEndClearError())
  }

  const handleInterviewToEnd = () => {
    const confirmed = window.confirm(`Are you sure?`)

    if (confirmed) {
      dispatch(interviewToEnd({ agencyId: id, id: interviewId }))
    }
  }

  return (
    <>
      {showLoader && <Loader />}
      <div className="ql-editor">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormTitle>Expert Feedback</FormTitle>

          <HeaderBlock>
            <SessionStartWrapper>
              <EditorTitle>Session Start Time</EditorTitle>
              <SessionStartValue>{formatedStart}</SessionStartValue>
            </SessionStartWrapper>
            <LinksWrapper>
              <TypeFormLinkWrapper>
                <TypeFormId>Session ID: {typeFormId}</TypeFormId>
                <LinkStyled
                  href="https://admin.typeform.com/form/wZiFXW/results#responses"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Search on Typeform
                </LinkStyled>
              </TypeFormLinkWrapper>
              <TypeFormLinkWrapper>
                {!videoIsReady && <VideoNotReady>Video is not ready yet</VideoNotReady>}
              </TypeFormLinkWrapper>
              <LinkStyled
                href={`/interviews/${interview.candidate_token.code}`}
                target="_blank"
              >
                Preview Report
              </LinkStyled>
              {reportWarninText && <VideoNotReady>{reportWarninText}</VideoNotReady>}
            </LinksWrapper>
          </HeaderBlock>

          <HeaderBlock>
            <SessionStartWrapper>
              <EditorTitle>Session Duration</EditorTitle>
              <SessionStartValue>{duration()} min</SessionStartValue>
            </SessionStartWrapper>
            <ToEndButtonWrapper>
              <ButtonStyled
                useTheme
                type="button"
                title="Set To Ended"
                disabled={!canBeEnded}
                onClick={handleInterviewToEnd}
              />
            </ToEndButtonWrapper>
          </HeaderBlock>

          <EditorBlock>
            <EditorTitle>Next round</EditorTitle>
            <PerformanceWrapper>
              <PerformanceRow>
                <PerformanceItem>
                  <Controller
                    name="advancement"
                    control={control}
                    render={({ onChange, value, name: selectName }) => (
                      <Select
                        name={selectName}
                        label="Advance?"
                        onChange={onChange}
                        value={value ?? true}
                        options={booleanList}
                      />
                    )}
                  />
                  <Controller
                    as={Input}
                    type="number"
                    min="1"
                    max="5"
                    label="Confident"
                    name="advancement_rating"
                    control={control}
                    error={get(errors, `advancement_rating`, {})}
                    rules={{
                      required: true,
                      min: { value: 1, message: 'Min: 1' },
                      max: { value: 5, message: 'Max: 5' },
                    }}
                    defaultValue={5}
                  />
                </PerformanceItem>
              </PerformanceRow>
            </PerformanceWrapper>
          </EditorBlock>

          <EditorBlock>
            <EditorTitle>Report Summary</EditorTitle>
            <EditorWrapper>
              <EditorToolbar id="1" />
              <ReactQuill
                placeholder="Add text"
                value={reportSummary}
                onChange={setReportSummary}
                modules={getModules('1')}
                formats={formats}
              />
            </EditorWrapper>
          </EditorBlock>

          <EditorBlock>
            <EditorTitle>Performance</EditorTitle>
            <PerformanceWrapper>
              <PerformanceRow>
                <PerformanceItem>
                  <Controller
                    as={Input}
                    label="Parameter 1 name"
                    name="First impression"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: { value: 25, message: 'Max characters : 25' },
                    }}
                    defaultValue="First impression"
                    disabled
                  />
                  <Controller
                    as={Input}
                    type="number"
                    min="1"
                    max="5"
                    label="Value"
                    name="first_impression_rating"
                    control={control}
                    error={get(errors, `first_impression_rating`, {})}
                    rules={{
                      required: true,
                      min: { value: 1, message: 'Min: 1' },
                      max: { value: 5, message: 'Max: 5' },
                    }}
                    defaultValue={5}
                  />
                </PerformanceItem>
                <PerformanceItem>
                  <Controller
                    as={Input}
                    label="Parameter 2 name"
                    name="Professional skills"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: { value: 25, message: 'Max characters : 25' },
                    }}
                    defaultValue="Professional skills"
                    disabled
                  />
                  <Controller
                    as={Input}
                    type="number"
                    min={1}
                    max={5}
                    label="Value"
                    name="knowledge_rating"
                    control={control}
                    error={get(errors, `knowledge_rating`, {})}
                    rules={{
                      required: true,
                      min: { value: 1, message: 'Min: 1' },
                      max: { value: 5, message: 'Max: 5' },
                    }}
                    defaultValue={5}
                  />
                </PerformanceItem>
              </PerformanceRow>
              <PerformanceRow>
                <PerformanceItem>
                  <Controller
                    as={Input}
                    label="Parameter 3 name"
                    name="Communication skills"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: { value: 25, message: 'Max characters : 25' },
                    }}
                    defaultValue="Communication skills"
                    disabled
                  />
                  <Controller
                    as={Input}
                    type="number"
                    min={1}
                    max={5}
                    label="Value"
                    name="communication_rating"
                    control={control}
                    error={get(errors, `communication_rating`, {})}
                    rules={{
                      required: true,
                      min: { value: 1, message: 'Min: 1' },
                      max: { value: 5, message: 'Max: 5' },
                    }}
                    defaultValue={5}
                  />
                </PerformanceItem>
                <PerformanceItem>
                  <Controller
                    as={Input}
                    label="Parameter 4 name"
                    name="Problem solving skills"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: { value: 25, message: 'Max characters : 25' },
                    }}
                    defaultValue="Problem solving skills"
                    disabled
                  />
                  <Controller
                    as={Input}
                    type="number"
                    min={1}
                    max={5}
                    label="Value"
                    name="problem_solving_rating"
                    control={control}
                    error={get(errors, 'problem_solving_rating', {})}
                    rules={{
                      required: true,
                      min: { value: 1, message: 'Min: 1' },
                      max: { value: 5, message: 'Max: 5' },
                    }}
                    defaultValue={5}
                  />
                </PerformanceItem>
              </PerformanceRow>
            </PerformanceWrapper>
          </EditorBlock>

          <EditorBlock>
            <EditorTitle>Action Items</EditorTitle>
            <EditorWrapper>
              <EditorToolbar id="2" />
              <ReactQuill
                placeholder="Add text"
                value={actionItems}
                onChange={setActionItems}
                modules={getModules('2')}
                formats={formats}
              />
            </EditorWrapper>
          </EditorBlock>

          <EditorBlock>
            <EditorTitle>Keep</EditorTitle>
            <EditorWrapper>
              <EditorToolbar id="3" />
              <ReactQuill
                placeholder="Add text"
                value={keep}
                onChange={setKeep}
                modules={getModules('3')}
                formats={formats}
              />
            </EditorWrapper>
          </EditorBlock>

          <EditorBlock>
            <EditorTitle>Work on</EditorTitle>
            <EditorWrapper>
              <EditorToolbar id="4" />
              <ReactQuill
                placeholder="Add text"
                value={workOn}
                onChange={setWorkOn}
                modules={getModules('4')}
                formats={formats}
              />
            </EditorWrapper>
          </EditorBlock>
          {saveSessionReportError && (
            <Notification onClose={clearErrorUpdate} message={saveSessionReportError} />
          )}
          {interviewCompleteError && (
            <Notification onClose={clearErrorComplete} message={interviewCompleteError} />
          )}
          {interviewToEndError && (
            <Notification onClose={clearErrorToEnd} message={interviewToEndError} />
          )}
          <ButtonsWrapper>
            <ButtonStyled useTheme type="submit" title="Update info" />
            <ButtonStyled
              useTheme
              type="button"
              title="Send to candidate"
              disabled={!saveSessionReportSuccess || !isReportProvided}
              onClick={() =>
                dispatch(interviewComplete({ agencyId: id, id: interviewId }))
              }
            />
          </ButtonsWrapper>
        </Form>
      </div>
    </>
  )
}

export default SessionReport
