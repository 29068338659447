import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

export const StatusNotification = styled.div<{ isExpert: boolean }>`
  align-items: center;
  background: rgba(245, 246, 248, 0.11);
  backdrop-filter: blur(18px);
  border-radius: 3rem;
  bottom: 5%;
  bottom: ${({ isExpert }) => (isExpert ? `calc(1.5rem + 5%)` : '5%')};

  display: flex;
  left: 50%;
  max-width: 80%;
  position: absolute;
  transform: translate(-50%, 0);

  > p {
    padding: 0.75em 1.5em;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`

export const MobileStatusNotification = styled.div`
  align-items: center;
  background: rgba(245, 246, 248, 0.11);
  backdrop-filter: blur(18px);
  border-radius: 32px;
  top: 72%;
  display: flex;
  height: 45px;
  left: 50%;
  max-width: 80%;
  padding: 0 20px;
  position: absolute;
  transform: translate(-50%, 0);
  width: max-content;

  > p {
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  @media (orientation: landscape) {
    top: 63%;
  }
`

const BrowserCallNotification = styled.div`
  position: absolute;
  top: 100px;
  left: 35px;
`

const MobileCallNotification = styled.div`
  background: rgba(24, 11, 49, 0.8);
  backdrop-filter: blur(18px);
  border: 1px solid #353c5a;
  border-radius: 12px;
  box-siizing: border-box;
  height: 104px;
  left: 50%;
  position: absolute;
  top: 104px;
  transform: translate(-50%, 0);
  width: 334.61px;

  @media (orientation: landscape) {
    left: 12px;
    top: 67px;
    transform: none;
  }
`

export default function CallNotification({ children }: React.HTMLProps<HTMLDivElement>) {
  return (
    <>
      {!isMobileOnly && <BrowserCallNotification>{children}</BrowserCallNotification>}
      {isMobileOnly && <MobileCallNotification>{children}</MobileCallNotification>}
    </>
  )
}
