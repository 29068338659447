import React from 'react'
import styled from '@emotion/styled'
import RotateImg from 'src/static/icRotate.png'

const Container = styled.div<{ toTheLeft?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-ndex: 999;
  background: rgba(115, 121, 145, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (orientation: portrait) {
    display: none;
  }
`

const DataContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 34px 40px 28px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageStyled = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`

const TextStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #3b424f;
  margin-top: 26px;
`

const RotatePhoneComponent = () => {
  return (
    <Container>
      <DataContainer>
        <ImageStyled src={RotateImg} alt="rotate" />
        <TextStyled>Please rotate phone.</TextStyled>
      </DataContainer>
    </Container>
  )
}

export default RotatePhoneComponent
