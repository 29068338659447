import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PrivateRoute, NotFound, SuperAdminRoute } from './components'
import Auth from './auth'
import Admin from './admin'
import Agency from './agency'
import Interview from './interview'
import VideoCall from './videocall'
import RecordCall from './recordcall'
import RecordHashRoute from './components/RecordHashRoute'
import Ava from './ava'
import AvaSpeechDataGenerator from './ava/containers/AvaSpeechDataGenerator'
import Landing from './landing'

import { getAgencyBySubdomain } from './interview/actions'
import * as interviewSelectors from './interview/selectors'
import useSubdomain from './utils/useSubdomain'

function Router() {
  const dispatch = useDispatch()
  const agency = useSelector(interviewSelectors.agency)
  const { subdomain } = useSubdomain()

  useEffect(() => {
    if (subdomain) dispatch(getAgencyBySubdomain({ subdomain }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdomain])

  useEffect(() => {
    const path = window.location.pathname
    const descMetaElement = document.head
      .getElementsByTagName('meta')
      .namedItem('description')
    if (path.startsWith('/ava')) {
      document.title = 'Wizco AI-based coaching'
      descMetaElement.content =
        'Meet Ava, your AI-powered job interview coach and unlock your full potential.'
    } else if (path.startsWith('/interviews')) {
      document.title = 'Wizco Expert-based coaching'
      const coachingHost = window.location.host.startsWith('coaching')
      descMetaElement.content = `${
        coachingHost ? 'Looking for a coaching session? ' : ''
      }Meet our 5,000+ experts and get personalized feedback from a professional who has first-hand experience in hiring for your desired position.`
    } else if (path.startsWith('/user/login')) {
      document.title = 'Wizco Admin Login'
      descMetaElement.content = `Welcome to Wizco. We're looking forward to working with you in supporting your applicants on their career journeys.`
    } else {
      document.title = 'Wizco.io'
      descMetaElement.content = ''
    }
  }, [])

  return (
    <Switch>
      <RecordHashRoute exact path="/">
        {subdomain !== 'www' && agency?.enable_experts === true && (
          <Redirect to="/interviews" />
        )}
        {subdomain !== 'www' && agency?.enable_ava === true && <Redirect to="/ava" />}
        {(subdomain === 'www' ||
          (agency?.enable_experts === false && agency?.enable_ava === false)) && (
          <Redirect to="/home" />
        )}
      </RecordHashRoute>
      <Route path="/home">
        <Landing />
      </Route>
      <Route path="/user">
        <Auth />
      </Route>
      <SuperAdminRoute path="/admin">
        <Admin />
      </SuperAdminRoute>
      <PrivateRoute path="/agencies">
        <Agency />
      </PrivateRoute>
      <Route path="/interviews">
        <Interview />
      </Route>
      <Route path="/call">
        <VideoCall />
      </Route>
      <Route path="/record">
        <RecordCall />
      </Route>
      <Route path="/ava">
        <Ava />
      </Route>
      {process.env.REACT_APP_DEV && (
        <Route path="/speechdata">
          <AvaSpeechDataGenerator />
        </Route>
      )}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Router
