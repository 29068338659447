import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
`

const Header = styled.div`
  font-size: 13px;
  margin: 15px 0;
  color: ${({ theme }: { theme: any }) => theme.precallTextColor};

  ${!isMobileOnly &&
  css`
    font-size: 19.5px;
    margin: 22px 0;
    @media screen and (max-width: 2500px) {
      font-size: 16px;
      margin: 19px 0;
    }
    @media screen and (max-width: 2000px) {
      font-size: 13px;
      margin: 15px 0;
    }
    @media screen and (max-width: 1200px) {
      font-size: 10px;
      margin: 11px 0;
    }
    @media screen and (max-width: 900px) {
      font-size: 6.5px;
      margin: 7px 0;
    }
  `}
`
const Text1 = styled.div`
  color: #737991;
  font-size: 13px;
  margin-bottom: 15px;

  ${!isMobileOnly &&
  css`
    font-size: 19.5px;
    margin-bottom: 22px;
    @media screen and (max-width: 2500px) {
      font-size: 16px;
      margin-bottom: 19px;
    }
    @media screen and (max-width: 2000px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 10px;
      margin-bottom: 11px;
    }
    @media screen and (max-width: 900px) {
      font-size: 6.5px;
      margin-bottom: 7px;
    }
  `}
`
const Text2 = styled(Text1)`
  font-style: italic;
  margin: 0;
`

interface IProps {
  header?: string
  text1: string
  text2: string
}

const PrecallTestFailView = ({ header, text1, text2 }: IProps) => {
  return (
    <Container>
      {header != null ? <Header>{header}</Header> : null}
      <Text1>{text1}</Text1>
      <Text2>{text2}</Text2>
    </Container>
  )
}

PrecallTestFailView.defaultProps = {
  header: null,
}

export default PrecallTestFailView
