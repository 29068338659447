import React from 'react'

interface SvgProps {
  fill: string
}

function SvgShare({ fill }: SvgProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.37574 13.6668C3.08921 13.6668 2.84379 13.5646 2.63947 13.3603C2.43516 13.156 2.33301 12.9106 2.33301 12.6241V3.37623C2.33301 3.0897 2.43516 2.84427 2.63947 2.63996C2.84379 2.43565 3.08921 2.3335 3.37574 2.3335H7.57399V3.17108H3.37574C3.32445 3.17108 3.27744 3.19245 3.23471 3.2352C3.19196 3.27793 3.17059 3.32494 3.17059 3.37623V12.6241C3.17059 12.6754 3.19196 12.7224 3.23471 12.7651C3.27744 12.8078 3.32445 12.8292 3.37574 12.8292H12.6236C12.6749 12.8292 12.7219 12.8078 12.7646 12.7651C12.8074 12.7224 12.8287 12.6754 12.8287 12.6241V8.42581H13.6663V12.6241C13.6663 12.9106 13.5642 13.156 13.3598 13.3603C13.1555 13.5646 12.9101 13.6668 12.6236 13.6668H3.37574ZM6.42957 10.1668L5.83984 9.57023L12.239 3.17108H9.00136V2.3335H13.6663V6.99845H12.8287V3.76765L6.42957 10.1668Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgShare
