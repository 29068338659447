import React from 'react'
import styled from '@emotion/styled'

import { Text, TextBold } from '../../components'
import reactions from './Reactions'

const ActiveFeedback = styled.div<{ hasIcon: boolean }>`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  height: 12vh;
  justify-content: center;
  left: 50%;
  padding: 0.75rem;
  position: absolute;
  transform: translate(-50%, 0);
  width: 16vw;
  z-index: 200;
`

const FeedbackTitle = styled(TextBold)`
  color: #fff;
  text-align: center;

  font-size: 27px;
  line-height: 31px;
  @media screen and (max-width: 2500px) {
    font-size: 23px;
    line-height: 26px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media screen and (max-width: 900px) {
    font-size: 9px;
    line-height: 11px;
  }
`

const FeedbackText = styled(Text)`
  color: #fff;
  flex-shrink: 0;
  margin-top: 10px;
  text-align: center;

  font-size: 21px;
  line-height: 25px;
  @media screen and (max-width: 2500px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 11px;
    line-height: 13px;
  }
  @media screen and (max-width: 900px) {
    font-size: 7px;
    line-height: 9px;
  }
`

export const Feedback = ({ activeFeedback }: { activeFeedback: string }) => {
  const feedback = reactions.find((i) => i.text === activeFeedback)
  return (
    <ActiveFeedback hasIcon={!!feedback}>
      <FeedbackTitle>Feedback added!</FeedbackTitle>
      <FeedbackText>{activeFeedback}</FeedbackText>
    </ActiveFeedback>
  )
}

const MobileActiveFeedback = styled.div<{ isFullscreen: boolean }>`
  align-items: center;
  background: rgba(0, 0, 0, 0.11);
  border-radius: 100px;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 92px;
  justify-content: center;
  left: 50%;
  padding: 0.75rem;
  position: absolute;
  transform: translate(-50%, 0);
  width: 183px;
  z-index: 200;

  @media (orientation: landscape) {
    top: ${({ isFullscreen }) => (isFullscreen ? '8%' : '67px')};
  }
`

const MobileFeedbackText = styled(Text)`
  color: #fff;
  flex-shrink: 0;
  margin-top: 10px;
  text-align: center;

  font-size: 14px;
  line-height: 17pxpx;
`

export const MobileFeedback = ({
  activeFeedback,
  isFullscreen,
}: {
  activeFeedback: string
  isFullscreen: boolean
}) => {
  return (
    <MobileActiveFeedback isFullscreen={isFullscreen}>
      <MobileFeedbackText>{activeFeedback}</MobileFeedbackText>
    </MobileActiveFeedback>
  )
}
