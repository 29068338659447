/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import useLogrocket from 'src/utils/useLogrocket'
import { isMobileOnly } from 'react-device-detect'
import Slider from 'react-slick'

import LandingHeader from 'src/components/LandingHeader'
import LandingFooter from 'src/components/LandingFooter'
import { AgencyExpertSessionType, AgencyTheme, BusinessModel } from '../../api'
import { BookingStatus, subscribeAction, updateBookingStatusAction } from '../../redux'

import LandingTopPerson from '../../static/landing-top-person.png'
import LandingTopPersonDei from '../../static/landing-top-person-dei.png'
import LandingTopPersonEx from '../../static/landing-top-person-ex.png'
import LandingBottomPerson from '../../static/landing-bottom-person.png'
import LandingBottomPersonEx from '../../static/landing-bottom-person-ex.png'
import OurExpertsImg from '../../static/ic-our-experts.png'
import OurExpertsExImg from '../../static/ic-our-experts-ex.png'
import GiudanceImg from '../../static/guidance_icon.png'
import GiudanceImgDei from '../../static/guidance_icon_dei.png'
import GiudanceImgEx from '../../static/guidance_icon_ex.png'
import recommendationImg11 from '../../static/ic-recommendation1.1.png'
import recommendationImg11Dei from '../../static/ic-recommendation1.1-dei.png'
import recommendationImg11Ex from '../../static/ic-recommendation1.1-ex.png'
import recommendationImg12 from '../../static/ic-recommendation1.2.png'
import recommendationImg2 from '../../static/ic-recommendation2.png'
import recommendationImg3 from '../../static/ic-recommendation3.png'
import recommendationImg4 from '../../static/ic-recommendation4.png'
import recommendationImg5 from '../../static/ic-recommendation5.png'
import recommendationImg6 from '../../static/ic-recommendation6.png'
import RecommendationImgMobile from '../../static/ic-recommendation-mobile.png'
import RecommendationImgMobileDei from '../../static/ic-recommendation-mobile-dei.png'
import RecommendationImgMobileEx from '../../static/ic-recommendation-mobile-ex.png'
import * as interviewSelectors from '../selectors'
import DisabledLanding from './DisabledLanding'
import LoaderComponent from '../../components/Loader'
import { validateAgencyLogo } from '../../utils/validateAgencyLogo'
import CycledCarousel from '../components/CycledCarousel'
import AppearBlock from '../components/AppearBlock'
import DynamicNumber from '../components/DynamicNumber'
import BlockCarousel from '../components/BlockCarousel'
import FaqBlock from '../components/FaqBlock'
import * as Components from '../components/landing-styles'
import * as MobileCompoinents from '../components/landing-styles-mobile'
import * as Constants from '../landingConstants'
import SchoolAccountPopup from '../components/SchoolAccountPopup'

const StuckAnimationLength = 2000

const Landing = () => {
  const dispatch = useDispatch()
  const { setLogrocket } = useLogrocket()
  const [selectedHowItWorksItem, setSelectedHowItWorksItem] = useState(
    Constants.HowItWorksSteps[0]
  )

  // we have a bit different page for careerbuilder
  const isCareerBuilder = window.location.host.indexOf('careerbuilder') !== -1

  const stuckAnimationBlock = useRef(null)
  const playerRef = useRef(null)

  const agency = useSelector(interviewSelectors.agency)
  const [showKippPopup, setShowKippPopup] = useState(false)

  const interviewDuration = get(agency, 'duration', 60)
  Constants.HowItWorksSteps[2].title = `${interviewDuration}-minute coaching session`
  Constants.HowItWorksSteps[1].title =
    agency.business_model === BusinessModel.B2B
      ? 'Pick your expert and time'
      : 'Scheduling and payment'
  Constants.HowItWorksSteps[1].text =
    agency.business_model === BusinessModel.B2B
      ? `We ensure you'll only be matched with experts whose professional experience is aligned with your desired position and specific coaching needs.`
      : `All that's left to do is pick your preferred expert and time. We'll never ask you to pay for a session before you're satisfied with one of the experts provided.`

  const getAgencyMinPrice = () => {
    if (!agency) return 0
    const minValue = Math.min.apply(
      null,
      agency.agency_price_tiers?.map((t) => t.price_min)
    )
    return `${agency.currency_sign}${minValue}`
  }

  const getAgencyMaxPrice = () => {
    if (!agency) return 0
    const maxValue = Math.max.apply(
      null,
      agency.agency_price_tiers?.map((t) => t.price_max)
    )
    return `${agency.currency_sign}${maxValue}`
  }

  const getAgencySessionPrice = () => {
    return `${agency.currency_sign}${agency.session_price}`
  }

  const getCostAnswer = () => {
    switch (agency.business_model) {
      case BusinessModel.B2B:
        return `In commitment to supporting you on your career path, ${
          agency.name ?? agency.internal_name
        } offers Wizco coaching sessions free of charge.`
      case BusinessModel.B2B2C:
        return `In partnership with Wizco, ${
          agency.name ?? agency.internal_name
        } is able to offer you an exclusive rate of ${getAgencySessionPrice()} per session.`
      default:
        return `Our session prices range from ${getAgencyMinPrice()} - ${getAgencyMaxPrice()} depending on your needs and budget.`
    }
  }

  Constants.FaqsItems[1].answer = getCostAnswer()
  Constants.CBFaqsItems[2].answer = getCostAnswer()

  const scheduleInterview = () => {
    if (agency.name.toLowerCase() === 'kipp') {
      setShowKippPopup(true)
    } else {
      dispatch(updateBookingStatusAction(BookingStatus.Position))
    }
  }

  const handleBookDemo = (email: string) => {
    dispatch(subscribeAction({ email, source: 'home_expert' }))
  }

  const [showIntelligenceImages, setShowIntelligenceImages] = useState(false)
  const [showNumbers, setShowNumbers] = useState(false)
  const [selectedReviewCardIndex, setSelectedReviewCardIndex] = useState(2)

  const [stuckRecommendationBlock, setStuckRecommendationBlock] = useState(false)
  const [stuckAnimationFinished, setStuckAnimationFinished] = useState(false)
  const [stuckAnimationProgress, setStuckAnimationProgress] = useState(0)

  const allowDirectRegistration = get(agency, 'allow_direct_registration', false)
  const [showLoading, setShowLoading] = useState(true)

  const interview = useSelector(interviewSelectors.interview)
  const interviewCode: string = get(interview, 'token.code', '')
  const hasInterviewCode =
    interviewCode &&
    interviewCode !== '' &&
    interviewCode !== null &&
    interviewCode !== 'null'

  const showLanding = hasInterviewCode || allowDirectRegistration

  function getDynamicPrice() {
    let priceString = ''
    if (agency.business_model === BusinessModel.B2C) {
      let min = 9999999
      let max = 0
      if (agency.agency_price_tiers && agency.agency_price_tiers.length > 0) {
        agency.agency_price_tiers.forEach((tear) => {
          if (tear.price_min < min) {
            min = tear.price_min
          }

          if (tear.price_max > max) {
            max = tear.price_max
          }
        })
        priceString = `${min} - ${agency.currency_sign}${max}`
      }
    } else {
      priceString = agency.session_price.toString()
    }
    return `${agency.currency_sign}${priceString}`
  }

  const changeHowItWorkStep = (item: any) => {
    setSelectedHowItWorksItem(item)
  }

  const getTopPersonImage = useCallback(() => {
    if (isCareerBuilder) {
      return LandingTopPersonDei
    }
    switch (agency.theme) {
      case AgencyTheme.DEI:
        return LandingTopPersonDei
      case AgencyTheme.EXECUTIVE:
        return LandingTopPersonEx
      default:
        return LandingTopPerson
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency])

  const getBottomPersonImage = useCallback(() => {
    switch (agency.theme) {
      case AgencyTheme.EXECUTIVE:
        return LandingBottomPersonEx
      default:
        return LandingBottomPerson
    }
  }, [agency])

  const getOurExpertsImage = useCallback(() => {
    switch (agency.theme) {
      case AgencyTheme.EXECUTIVE:
        return OurExpertsExImg
      default:
        return OurExpertsImg
    }
  }, [agency])

  const getORecomendation1Image = useCallback(() => {
    switch (agency.theme) {
      case AgencyTheme.DEI:
        return recommendationImg11Dei
      case AgencyTheme.EXECUTIVE:
        return recommendationImg11Ex
      default:
        return recommendationImg11
    }
  }, [agency])

  const getGiudanceImage = useCallback(() => {
    switch (agency.theme) {
      case AgencyTheme.DEI:
        return GiudanceImgDei
      case AgencyTheme.EXECUTIVE:
        return GiudanceImgEx
      default:
        return GiudanceImg
    }
  }, [agency])

  const getRecomendationMobileImage = useCallback(() => {
    switch (agency.theme) {
      case AgencyTheme.DEI:
        return RecommendationImgMobileDei
      case AgencyTheme.EXECUTIVE:
        return RecommendationImgMobileEx
      default:
        return RecommendationImgMobile
    }
  }, [agency])

  const goToAnotherAccount = (url: string) => {
    window.open(url, '_self')
  }

  const mobileSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <MobileCompoinents.SliderDotsContainerMobile>
        {dots}
      </MobileCompoinents.SliderDotsContainerMobile>
    ),
  }

  const mobileSliderReviews = {
    ...mobileSliderSettings,
    initialSlide: 2,
  }

  const howItWorksmobileSliderSettings = {
    ...mobileSliderSettings,
    infinite: false,
    afterChange: (index) => {
      if (isMobileOnly) return

      for (let ind = 0; ind < 4; ind += 1) {
        const player = document.getElementById(
          `how-it-work-mobile-video-${ind}`
        ) as HTMLVideoElement
        if (index !== ind) {
          player.pause()
          player.currentTime = 0
        } else {
          player.play()
        }
      }
    },
  }

  const getTopHeadText = (): any => {
    if (isCareerBuilder) {
      return (
        <>
          100,000 careers.{'\n\r'}One of them <span>is yours</span>.
        </>
      )
    }
    if (agency.expert_session_type === AgencyExpertSessionType.MOCK) {
      return (
        <>
          Interview preparation{'\n\r'}
          <span>reimagined</span>
        </>
      )
    }

    return (
      <>
        Take charge{'\n\r'}of <span>your career</span>
      </>
    )
  }

  const getTopSubText = (): any => {
    if (isCareerBuilder) {
      return `We're on a mission to help people in the Black community build the career of their dreams.\n\nMeet with an expert for a free coaching session and get the professional feedback and guidance you need to secure career stability, and a solid foundation for future growth.`
    }
    if (agency.expert_session_type === AgencyExpertSessionType.MOCK) {
      return 'You deserve a coach as unique as your needs.\n\rMeet an expert with first-hand hiring knowledge and extensive industry experience for a personalized mock interview, polish your skills and ace your next interview.'
    }

    return `You deserve a coach as unique as your needs. With first-hand hiring knowledge and extensive industry experience, our world-class experts are here to guide you in achieving your full career potential.`
  }

  const getScheduleSessionButtonText = () => {
    if (isCareerBuilder) {
      return 'Schedule your free coaching session'
    }
    return 'Schedule your coaching session'
  }

  const getCareerJourneyText = () => {
    if (isCareerBuilder) {
      return 'CareerBuilder has teamed up with the Black Information Network to launch the “100,000 Careers” initiative, helping you leverage your skills in overcoming the unique professional challenges the Black community faces.'
    }
    return `From new grads to seasoned executives, we've helped thousands of people develop and meet their career goals. You're our next success story.`
  }

  useEffect(() => {
    let isMounted = true
    if (!agency) return null
    const agencyHasId =
      agency.id && agency.id !== '' && agency.id !== null && agency.id !== 'null'
    setShowLoading(!agencyHasId)

    setTimeout(() => {
      if (isMounted) setShowLoading(false)
    }, 500)

    return () => {
      isMounted = false
    }
  }, [agency])

  useEffect(() => {
    if (interview.interview?.order_number) {
      setLogrocket(
        `${interview.interview?.order_number.toString()}`,
        'Applicant landing page'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  useEffect(() => {
    window.addEventListener(
      'scroll',
      (ev) => {
        if (!stuckAnimationBlock.current) return
        const { scrollTop } = ev.target as any
        const anchorTop = (stuckAnimationBlock.current as HTMLDivElement).offsetTop
        const anchorDiff = scrollTop - anchorTop + 115
        if (anchorDiff > 0) {
          if (anchorDiff > StuckAnimationLength) {
            // finish stuck animation A
            setStuckRecommendationBlock(false)
            setStuckAnimationFinished(true)
          } else {
            // show stuck animation
            setStuckRecommendationBlock(true)
            const animationProgress = anchorDiff / StuckAnimationLength
            setStuckAnimationProgress(animationProgress)
          }
        } else {
          // finish stuck animation B
          setStuckRecommendationBlock(false)
          setStuckAnimationFinished(false)
        }
      },
      true
    )
  }, [])

  useEffect(() => {
    const player = playerRef.current as any
    player?.play()
  }, [selectedHowItWorksItem])

  if (showLoading) return <LoaderComponent />
  if (!showLanding) return <DisabledLanding />

  const logo = agency?.whitelisted && agency?.company_logo?.url

  return (
    <>
      <Components.LandingWrapper
        id="landing-root"
        popupShowed={showKippPopup}
        usePadding={agency.display_topbar_footer}
      >
        <LandingHeader
          onStart={scheduleInterview}
          startButtonText="Schedule your session"
          showMenu={agency.display_topbar_footer}
          showLoginButton={agency.display_topbar_footer}
          logo={logo}
        />
        {isMobileOnly && (
          <>
            <MobileCompoinents.TopSectionMobile>
              <MobileCompoinents.TopLandingContainerMobile>
                <MobileCompoinents.LogoMobile
                  src={validateAgencyLogo(agency.company_logo?.url)}
                  alt="Logo"
                  isHidden={!agency.company_logo?.url}
                />
                <MobileCompoinents.TopInfoContainerMobile>
                  <MobileCompoinents.TopInfoBlockMobile showBg>
                    <MobileCompoinents.PersonImageMobile
                      src={getTopPersonImage()}
                      alt="person"
                    />
                  </MobileCompoinents.TopInfoBlockMobile>
                  <MobileCompoinents.TopInfoBlockMobile fullWidth>
                    <MobileCompoinents.TopSectionTextMobile
                      isCareerBuilder={isCareerBuilder}
                    >
                      {getTopHeadText()}
                    </MobileCompoinents.TopSectionTextMobile>
                  </MobileCompoinents.TopInfoBlockMobile>
                </MobileCompoinents.TopInfoContainerMobile>
              </MobileCompoinents.TopLandingContainerMobile>
              <MobileCompoinents.TopInfoBottomContainerMobile>
                <MobileCompoinents.TopSectionInfoMobile>
                  {getTopSubText()}
                </MobileCompoinents.TopSectionInfoMobile>
                <MobileCompoinents.StyledButtonMobile
                  onClick={scheduleInterview}
                  title={getScheduleSessionButtonText()}
                />
              </MobileCompoinents.TopInfoBottomContainerMobile>
            </MobileCompoinents.TopSectionMobile>

            {agency.expert_session_type !== AgencyExpertSessionType.MOCK && (
              <MobileCompoinents.CoachingStepsBlockMobile>
                <MobileCompoinents.BlockTitleMobile>
                  Coaching for every step of your career journey
                </MobileCompoinents.BlockTitleMobile>
                <MobileCompoinents.CoachingStepsInfoMobile>
                  {getCareerJourneyText()}
                </MobileCompoinents.CoachingStepsInfoMobile>
                <MobileCompoinents.CoachingStepsItemsContainerMobile>
                  <Slider {...mobileSliderSettings}>
                    {Constants.CoachingSteps.map((step) => (
                      <MobileCompoinents.CoachingStepsItemContainerMobile
                        key={step.title}
                      >
                        <Components.CoachingStepsItemIcon src={step.icon} />
                        <MobileCompoinents.CoachingStepsItemTitleMobile>
                          {step.title}
                        </MobileCompoinents.CoachingStepsItemTitleMobile>
                        <MobileCompoinents.CoachingStepsItemTextMobile>
                          {step.text}
                        </MobileCompoinents.CoachingStepsItemTextMobile>
                      </MobileCompoinents.CoachingStepsItemContainerMobile>
                    ))}
                  </Slider>
                </MobileCompoinents.CoachingStepsItemsContainerMobile>
              </MobileCompoinents.CoachingStepsBlockMobile>
            )}

            <MobileCompoinents.HowItWorksBlockMobile>
              <MobileCompoinents.BlockTitleMobile>
                How it works
              </MobileCompoinents.BlockTitleMobile>
              <MobileCompoinents.HowItWorksDataContainerMobile>
                <Slider {...howItWorksmobileSliderSettings}>
                  {Constants.HowItWorksSteps.map((item) => (
                    <MobileCompoinents.HowItWorksItemContainerMobile key={item.title}>
                      <MobileCompoinents.HowItWorksItemTitleMobile>
                        {item.title}
                      </MobileCompoinents.HowItWorksItemTitleMobile>
                      <MobileCompoinents.HowItWorksItemDataContainerMobile>
                        <MobileCompoinents.HowItWorksItemTextMobile>
                          {item.text}
                        </MobileCompoinents.HowItWorksItemTextMobile>
                        <MobileCompoinents.HowItWorksItemAnimationMobile>
                          <MobileCompoinents.HowItWorksItemAnimationImgMobile
                            src={item.videoIos}
                            alt="vid"
                          />
                        </MobileCompoinents.HowItWorksItemAnimationMobile>
                      </MobileCompoinents.HowItWorksItemDataContainerMobile>
                    </MobileCompoinents.HowItWorksItemContainerMobile>
                  ))}
                </Slider>
              </MobileCompoinents.HowItWorksDataContainerMobile>
            </MobileCompoinents.HowItWorksBlockMobile>

            <MobileCompoinents.OurExpertsBlockMobile>
              <MobileCompoinents.BlockTitleMobile>
                Meet our 5,000+ world-class experts
              </MobileCompoinents.BlockTitleMobile>
              <MobileCompoinents.OurExpertsDataContainerMobile>
                <MobileCompoinents.OurExpertsTextsContainerMobile>
                  <MobileCompoinents.OurExpertsTextHeaderMobile>
                    Hiring experience
                  </MobileCompoinents.OurExpertsTextHeaderMobile>
                  <MobileCompoinents.OurExpertsTextLabelMobile>
                    Representing 3,000 + companies, each of our experts has been recruited
                    for their first-hand hiring experience and love of coaching.
                  </MobileCompoinents.OurExpertsTextLabelMobile>
                  <MobileCompoinents.OurExpertsTextHeaderMobile>
                    Smart matching
                  </MobileCompoinents.OurExpertsTextHeaderMobile>
                  <MobileCompoinents.OurExpertsTextLabelMobile>
                    Using our smart AI technology, you&apos;re guaranteed to be matched
                    with the expert who meets your unique coaching needs.
                  </MobileCompoinents.OurExpertsTextLabelMobile>
                </MobileCompoinents.OurExpertsTextsContainerMobile>
                <MobileCompoinents.OurExpertsImageMobile
                  src={getOurExpertsImage()}
                  alt="OurExperts"
                />
              </MobileCompoinents.OurExpertsDataContainerMobile>
              <CycledCarousel
                firstLineItems={
                  isCareerBuilder ? Constants.CBAdBanners1 : Constants.AdBanners1
                }
                secondLineItems={
                  isCareerBuilder ? Constants.CBAdBanners2 : Constants.AdBanners2
                }
              />
            </MobileCompoinents.OurExpertsBlockMobile>

            <MobileCompoinents.RecommendationBlockWrapperMobile>
              <MobileCompoinents.BlockTitleMobile>
                Post-session insights and recommendations
              </MobileCompoinents.BlockTitleMobile>
              <MobileCompoinents.RecommendationTextMobile>
                Using a unique combination of one-on-one personalized expert feedback and
                our advanced AI technology, your Wizco report provides you with a detailed
                performance analysis and tailored action plan.
              </MobileCompoinents.RecommendationTextMobile>
              <MobileCompoinents.RecommendationImgMobile
                src={getRecomendationMobileImage()}
              />
            </MobileCompoinents.RecommendationBlockWrapperMobile>

            {agency.enable_conversation_intelligence && (
              <MobileCompoinents.IntelligenceBlockMobile>
                <MobileCompoinents.BlockTitleMobile>
                  Interview intelligence AI
                </MobileCompoinents.BlockTitleMobile>
                <MobileCompoinents.IntelligenceTextMobile
                  marginBottom={12}
                  marginTop={12}
                >
                  Our advanced AI tool, Interview Intelligence, records, transcribes and
                  analyzes your interview performance focussing on six key interview
                  metrics.
                </MobileCompoinents.IntelligenceTextMobile>
                <MobileCompoinents.IntelligenceTextMobile>
                  Get a data driven insights of how you are perceived in a professional
                  setting complete with personalized recommendations on where and how to
                  improve.
                </MobileCompoinents.IntelligenceTextMobile>
                <AppearBlock onAppear={setShowIntelligenceImages}>
                  <MobileCompoinents.IntelligenceImagesWrapperMobile>
                    {Constants.IntelligenceItems.map((iItem, index) => (
                      <MobileCompoinents.IntelligenceImageMobile
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        src={iItem.img}
                        width={iItem.widthMobile}
                        show={showIntelligenceImages}
                        animationLength={Constants.IntelligenceAnimationLengthSec}
                        showDelay={Constants.IntelligenceAnimationStepSec * iItem.order}
                      />
                    ))}
                  </MobileCompoinents.IntelligenceImagesWrapperMobile>
                </AppearBlock>
              </MobileCompoinents.IntelligenceBlockMobile>
            )}

            <MobileCompoinents.NumbersBlockMobile>
              <MobileCompoinents.BlockTitleMobile>
                Numbers speak louder than words
              </MobileCompoinents.BlockTitleMobile>
              <MobileCompoinents.NumbersTextMobile>
                The benefits of professional coaching have never been clearer.
              </MobileCompoinents.NumbersTextMobile>
              <AppearBlock onAppear={setShowNumbers}>
                <MobileCompoinents.NumbersItemsContainerMobile>
                  <MobileCompoinents.NumbersItemMobile hasBorder>
                    <MobileCompoinents.NumbersItemNumberMobile color="#F1BE2B">
                      <DynamicNumber value={87} show={showNumbers} round />%
                    </MobileCompoinents.NumbersItemNumberMobile>
                    <MobileCompoinents.NumbersItemTextMobile>
                      of job-seekers employed within 90 days of use
                    </MobileCompoinents.NumbersItemTextMobile>
                  </MobileCompoinents.NumbersItemMobile>
                  <MobileCompoinents.NumbersItemMobile hasBorder>
                    <MobileCompoinents.NumbersItemNumberMobile color="#B202FF">
                      <DynamicNumber value={96} show={showNumbers} round />%
                    </MobileCompoinents.NumbersItemNumberMobile>
                    <MobileCompoinents.NumbersItemTextMobile>
                      of users would recommend Wizco coaching to a friend or colleague
                    </MobileCompoinents.NumbersItemTextMobile>
                  </MobileCompoinents.NumbersItemMobile>
                  <MobileCompoinents.NumbersItemMobile>
                    <MobileCompoinents.NumbersItemNumberMobile color="#324FA5">
                      <DynamicNumber value={4.91} show={showNumbers} />
                      <span
                        style={{
                          opacity: 0.5,
                          fontSize: 16,
                          alignSelf: 'end',
                          marginBottom: 8,
                        }}
                      >
                        /5
                      </span>
                    </MobileCompoinents.NumbersItemNumberMobile>
                    <MobileCompoinents.NumbersItemTextMobile>
                      average expert rating
                    </MobileCompoinents.NumbersItemTextMobile>
                  </MobileCompoinents.NumbersItemMobile>
                </MobileCompoinents.NumbersItemsContainerMobile>
              </AppearBlock>
            </MobileCompoinents.NumbersBlockMobile>

            {(agency.business_model === BusinessModel.B2B2C ||
              agency.business_model === BusinessModel.B2C) && (
              <MobileCompoinents.ProffessionalGuidanceContainerMobile>
                <MobileCompoinents.BlockTitleMobile>
                  {agency.business_model === BusinessModel.B2C
                    ? 'Flexible pricing solutions'
                    : `Exclusive rates`}
                </MobileCompoinents.BlockTitleMobile>
                <MobileCompoinents.GuidancePriceTextMobile>
                  {agency.business_model === BusinessModel.B2C
                    ? `We're on a mission to make coaching accessible to as many people as possible and offer a range of session prices.`
                    : `Our partnership with Wizco grants you full access to the Wizco expert network at a discounted session price.`}
                </MobileCompoinents.GuidancePriceTextMobile>
                <MobileCompoinents.GuidanceImageMobile src={getGiudanceImage()} />
                <MobileCompoinents.GuidancePriceBlockMobile>
                  <Components.GuidanceLine>
                    <MobileCompoinents.GuidancePriceLabelMobile>
                      Session price:
                    </MobileCompoinents.GuidancePriceLabelMobile>
                    <MobileCompoinents.GuidancePriceMobile>
                      {getDynamicPrice()}
                    </MobileCompoinents.GuidancePriceMobile>
                  </Components.GuidanceLine>
                  <MobileCompoinents.GuidancePriceSubTextMobile>
                    {agency.business_model === BusinessModel.B2C
                      ? '(subject to expert seniority)'
                      : `(Exclusive ${agency.name} price)`}
                  </MobileCompoinents.GuidancePriceSubTextMobile>
                  <MobileCompoinents.StyledButtonMobile
                    fullWidth
                    onClick={scheduleInterview}
                    title={getScheduleSessionButtonText()}
                  />
                </MobileCompoinents.GuidancePriceBlockMobile>
              </MobileCompoinents.ProffessionalGuidanceContainerMobile>
            )}

            <MobileCompoinents.ReviewsBlockMobile>
              <Slider {...mobileSliderReviews}>
                {(isCareerBuilder
                  ? Constants.CBReviewsItems
                  : Constants.ReviewsItems
                ).map((item) => (
                  <MobileCompoinents.ReviewsCardMobile key={item.text}>
                    <MobileCompoinents.ReviewsCardTextMobile>
                      {item.text}
                    </MobileCompoinents.ReviewsCardTextMobile>
                    <MobileCompoinents.ReviewsAuthorMobile>
                      {item.author}
                    </MobileCompoinents.ReviewsAuthorMobile>
                    <MobileCompoinents.ReviewsLogoMobile
                      src={item.logo}
                      height={item.height}
                    />
                  </MobileCompoinents.ReviewsCardMobile>
                ))}
              </Slider>
            </MobileCompoinents.ReviewsBlockMobile>

            <MobileCompoinents.FaqsContainerMobile>
              <MobileCompoinents.BlockTitleMobile>
                FAQS
              </MobileCompoinents.BlockTitleMobile>
              {(isCareerBuilder ? Constants.CBFaqsItems : Constants.FaqsItems).map(
                (item) => (
                  <FaqBlock
                    key={item.question}
                    question={item.question}
                    answer={item.answer}
                    link={item.link}
                    linkUrl={item.linkUrl}
                  />
                )
              )}
            </MobileCompoinents.FaqsContainerMobile>

            <MobileCompoinents.BottomBlockMobile>
              <MobileCompoinents.BottomBlockTitleMobile>
                Ready to land your dream job?
              </MobileCompoinents.BottomBlockTitleMobile>
              <MobileCompoinents.BottomPersonImageMobile
                src={getBottomPersonImage()}
                alt="person"
              />
              <MobileCompoinents.StyledButtonMobile
                onClick={scheduleInterview}
                title={getScheduleSessionButtonText()}
              />
            </MobileCompoinents.BottomBlockMobile>
          </>
        )}
        {!isMobileOnly && (
          <>
            <Components.TopSection>
              <Components.TopLandingContainer>
                <Components.Logo
                  src={validateAgencyLogo(agency.company_logo?.url)}
                  alt="Logo"
                  isHidden={!agency.company_logo?.url}
                />
                <Components.TopInfoContainer>
                  <Components.TopInfoBlock>
                    <Components.PersonImage src={getTopPersonImage()} alt="person" />
                  </Components.TopInfoBlock>
                  <Components.TopInfoBlock fullWidth centered>
                    <Components.TopSectionText isCareerBuilder={isCareerBuilder}>
                      {getTopHeadText()}
                    </Components.TopSectionText>
                    <Components.TopSectionInfo isCareerBuilder={isCareerBuilder}>
                      {getTopSubText()}
                    </Components.TopSectionInfo>
                    <Components.StyledButtonWidth
                      onClick={scheduleInterview}
                      title={getScheduleSessionButtonText()}
                    />
                  </Components.TopInfoBlock>
                </Components.TopInfoContainer>
              </Components.TopLandingContainer>
            </Components.TopSection>

            {agency.expert_session_type !== AgencyExpertSessionType.MOCK && (
              <Components.CoachingStepsBlock>
                <Components.BlockTitle>
                  Coaching for every step of your career journey
                </Components.BlockTitle>
                <Components.CoachingStepsInfo>
                  {getCareerJourneyText()}
                </Components.CoachingStepsInfo>
                <Components.CoachingStepsItemsContainer>
                  {Constants.CoachingSteps.map((step) => (
                    <Components.CoachingStepsItemContainer key={step.title}>
                      <Components.CoachingStepsItemIcon src={step.icon} />
                      <Components.CoachingStepsItemTitle>
                        {step.title}
                      </Components.CoachingStepsItemTitle>
                      <Components.CoachingStepsItemText>
                        {step.text}
                      </Components.CoachingStepsItemText>
                    </Components.CoachingStepsItemContainer>
                  ))}
                </Components.CoachingStepsItemsContainer>
              </Components.CoachingStepsBlock>
            )}

            <Components.HowItWorksBlock>
              <Components.BlockTitle>How it works</Components.BlockTitle>
              <Components.HowItWorksDataContainer>
                <Components.HowItWorksMenuContainer>
                  {Constants.HowItWorksSteps.map((item) => (
                    <Components.HowItWorksMenuItem
                      key={item.title}
                      selected={item.title === selectedHowItWorksItem.title}
                      onClick={() => changeHowItWorkStep(item)}
                    >
                      {item.title}
                    </Components.HowItWorksMenuItem>
                  ))}
                </Components.HowItWorksMenuContainer>
                <Components.HowItWorksItemAnimationContainer>
                  <Components.HowItWorksItemAnimation>
                    <AppearBlock
                      key={selectedHowItWorksItem.text}
                      onAppear={(appear) => {
                        if (playerRef.current && appear) {
                          const player = playerRef.current as any
                          player?.play()
                        }
                      }}
                    >
                      <video
                        src={selectedHowItWorksItem.video}
                        ref={playerRef}
                        width="100%"
                        height="100%"
                        loop
                        muted
                      />
                    </AppearBlock>
                  </Components.HowItWorksItemAnimation>
                  <Components.HowItWorksItemText>
                    {selectedHowItWorksItem.text}
                  </Components.HowItWorksItemText>
                </Components.HowItWorksItemAnimationContainer>
              </Components.HowItWorksDataContainer>
            </Components.HowItWorksBlock>

            <Components.OurExpertsBlock>
              <Components.BlockTitle>
                Meet our 5,000+ world-class experts
              </Components.BlockTitle>
              <Components.OurExpertsDataContainer>
                <Components.OurExpertsImage src={getOurExpertsImage()} alt="OurExperts" />
                <Components.OurExpertsTextsContainer>
                  <Components.OurExpertsTextHeader>
                    Hiring experience
                  </Components.OurExpertsTextHeader>
                  <Components.OurExpertsTextLabel>
                    Representing 3,000 + companies, each of our experts has been recruited
                    for their first-hand hiring experience and love of coaching.
                  </Components.OurExpertsTextLabel>
                  <Components.OurExpertsTextHeader>
                    Smart matching
                  </Components.OurExpertsTextHeader>
                  <Components.OurExpertsTextLabel>
                    Using our smart AI technology, you&apos;re guaranteed to be matched
                    with the expert who meets your unique coaching needs.
                  </Components.OurExpertsTextLabel>
                </Components.OurExpertsTextsContainer>
              </Components.OurExpertsDataContainer>
              <CycledCarousel
                firstLineItems={
                  isCareerBuilder ? Constants.CBAdBanners1 : Constants.AdBanners1
                }
                secondLineItems={
                  isCareerBuilder ? Constants.CBAdBanners2 : Constants.AdBanners2
                }
              />
            </Components.OurExpertsBlock>

            <Components.RecommendationBlockWrapper
              animationLenght={StuckAnimationLength}
              ref={stuckAnimationBlock}
              animationFinished={stuckAnimationFinished}
            >
              <Components.RecommendationBlock isStuck={stuckRecommendationBlock}>
                <Components.RecommendationSideBlock>
                  <Components.RecommendationImg
                    src={getORecomendation1Image()}
                    height={239}
                    show={stuckAnimationProgress > 0.1}
                  />
                  <Components.RecommendationImg
                    src={recommendationImg12}
                    height={37}
                    marginTop={29}
                    width={455}
                    align="right"
                    show={stuckAnimationProgress > 0.1}
                  />
                  <Components.RecommendationImg
                    src={recommendationImg2}
                    height={11}
                    marginTop={28}
                    show={stuckAnimationProgress > 0.3}
                  />
                  <Components.RecommendationImg
                    src={recommendationImg3}
                    height={11}
                    marginTop={28}
                    show={stuckAnimationProgress > 0.5}
                  />
                  <Components.RecommendationImg
                    src={recommendationImg4}
                    height={11}
                    marginTop={28}
                    show={stuckAnimationProgress > 0.7}
                  />
                  <Components.RecommendationImg
                    src={recommendationImg5}
                    height={26}
                    marginTop={28}
                    show={stuckAnimationProgress > 0.9}
                  />
                  <Components.RecommendationImg
                    src={recommendationImg6}
                    height={181}
                    marginTop={32}
                    show={stuckAnimationProgress > 0.9}
                    absolute
                    left={204}
                    bottom={28}
                    width={12}
                  />
                </Components.RecommendationSideBlock>
                <Components.RecommendationSideBlock>
                  <Components.BlockTitle alignLeft>
                    Post-session insights and recommendations
                  </Components.BlockTitle>
                  <Components.RecommendationText>
                    Using a unique combination of one-on-one personalized expert feedback
                    and our advanced AI technology, your Wizco report provides you with a
                    detailed performance analysis and tailored action plan.
                  </Components.RecommendationText>
                </Components.RecommendationSideBlock>
              </Components.RecommendationBlock>
            </Components.RecommendationBlockWrapper>

            {agency.enable_conversation_intelligence && (
              <Components.IntelligenceBlock>
                <Components.IntelligenceSideBlock widthPct={43}>
                  <Components.BlockTitle alignLeft>
                    Interview intelligence AI
                  </Components.BlockTitle>
                  <Components.IntelligenceText marginBottom={45}>
                    Our advanced AI tool, Interview Intelligence, records, transcribes and
                    analyzes your interview performance focussing on six key interview
                    metrics.
                  </Components.IntelligenceText>
                  <Components.IntelligenceText>
                    Get a data driven insights of how you are perceived in a professional
                    setting complete with personalized recommendations on where and how to
                    improve.
                  </Components.IntelligenceText>
                </Components.IntelligenceSideBlock>
                <Components.IntelligenceSideBlock widthPct={53}>
                  <AppearBlock onAppear={setShowIntelligenceImages}>
                    <Components.IntelligenceImagesWrapper>
                      {Constants.IntelligenceItems.map((iItem, index) => (
                        <Components.IntelligenceImage
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          src={iItem.img}
                          width={iItem.width}
                          show={showIntelligenceImages}
                          animationLength={Constants.IntelligenceAnimationLengthSec}
                          showDelay={Constants.IntelligenceAnimationStepSec * iItem.order}
                        />
                      ))}
                    </Components.IntelligenceImagesWrapper>
                  </AppearBlock>
                </Components.IntelligenceSideBlock>
              </Components.IntelligenceBlock>
            )}

            <Components.NumbersBlock>
              <Components.BlockTitle>
                Numbers speak louder than words
              </Components.BlockTitle>
              <Components.NumbersText>
                The benefits of professional coaching have never been clearer.
              </Components.NumbersText>
              <AppearBlock onAppear={setShowNumbers}>
                <Components.NumbersItemsContainer>
                  <Components.NumbersItem>
                    <Components.NumbersItemRow color="#F1BE2B">
                      <DynamicNumber value={87} show={showNumbers} round />%
                    </Components.NumbersItemRow>
                    <Components.NumbersItemText>
                      of job-seekers employed within 90 days of use
                    </Components.NumbersItemText>
                  </Components.NumbersItem>
                  <Components.NumbersItem>
                    <Components.NumbersItemRow color="#B202FF">
                      <DynamicNumber value={96} show={showNumbers} round />%
                    </Components.NumbersItemRow>
                    <Components.NumbersItemText>
                      of users would recommend Wizco coaching to a friend or colleague
                    </Components.NumbersItemText>
                  </Components.NumbersItem>
                  <Components.NumbersItem>
                    <Components.NumbersItemRow color="#324FA5">
                      <DynamicNumber value={4.91} show={showNumbers} />
                      <span
                        style={{
                          opacity: 0.5,
                          fontSize: 40,
                          alignSelf: 'end',
                          marginBottom: 19,
                        }}
                      >
                        /5
                      </span>
                    </Components.NumbersItemRow>
                    <Components.NumbersItemText>
                      average expert rating
                    </Components.NumbersItemText>
                  </Components.NumbersItem>
                </Components.NumbersItemsContainer>
              </AppearBlock>
            </Components.NumbersBlock>

            {(agency.business_model === BusinessModel.B2B2C ||
              agency.business_model === BusinessModel.B2C) && (
              <Components.ProffessionalGuidanceContainer>
                <Components.ResponsiveWrapper>
                  <Components.GuidanceData>
                    <Components.GuidanceImage src={getGiudanceImage()} />
                    <Components.GuidancePriceBlock>
                      <Components.GuidancePriceTitle>
                        {agency.business_model === BusinessModel.B2C
                          ? 'Flexible pricing solutions'
                          : `Exclusive rates`}
                      </Components.GuidancePriceTitle>
                      <Components.GuidancePriceText>
                        {agency.business_model === BusinessModel.B2C
                          ? `We're on a mission to make coaching accessible to as many people as possible and offer a range of session prices.`
                          : `Our partnership with Wizco grants you full access to the Wizco expert network at a discounted session price.`}
                      </Components.GuidancePriceText>
                      <Components.GuidanceLine>
                        <Components.GuidancePriceLabel>
                          Session price:
                        </Components.GuidancePriceLabel>
                        <Components.GuidancePrice>
                          {getDynamicPrice()}
                        </Components.GuidancePrice>
                      </Components.GuidanceLine>
                      <Components.GuidancePriceSubText>
                        {agency.business_model === BusinessModel.B2C
                          ? '(subject to expert seniority)'
                          : `(Exclusive ${agency.name} price)`}
                      </Components.GuidancePriceSubText>
                      <Components.StyledButtonWidth
                        fullWidth
                        onClick={scheduleInterview}
                        title={getScheduleSessionButtonText()}
                      />
                    </Components.GuidancePriceBlock>
                  </Components.GuidanceData>
                </Components.ResponsiveWrapper>
              </Components.ProffessionalGuidanceContainer>
            )}

            <Components.ReviewsBlock>
              <BlockCarousel
                items={
                  isCareerBuilder ? Constants.CBReviewsItems : Constants.ReviewsItems
                }
                render={(item) => (
                  <Components.ReviewsCard>
                    <Components.ReviewsCardText>{item.text}</Components.ReviewsCardText>
                    <Components.ReviewsAuthor>{item.author}</Components.ReviewsAuthor>
                    <Components.ReviewsLogo src={item.logo} height={item.height} />
                  </Components.ReviewsCard>
                )}
                selectedIndex={selectedReviewCardIndex}
                onSelectedIndexChanged={setSelectedReviewCardIndex}
              />
            </Components.ReviewsBlock>

            <Components.FaqsContainer>
              <Components.BlockTitle>FAQS</Components.BlockTitle>
              {(isCareerBuilder ? Constants.CBFaqsItems : Constants.FaqsItems).map(
                (item) => (
                  <FaqBlock
                    key={item.question}
                    question={item.question}
                    answer={item.answer}
                    link={item.link}
                    linkUrl={item.linkUrl}
                  />
                )
              )}
            </Components.FaqsContainer>

            <Components.BottomBlock>
              <Components.BottomContentContainer>
                <Components.BottomSectionContent>
                  <Components.BottomSectionTitle>
                    Ready to land your dream job?
                  </Components.BottomSectionTitle>
                  <Components.StyledButton
                    onClick={scheduleInterview}
                    title={getScheduleSessionButtonText()}
                  />
                </Components.BottomSectionContent>
                <Components.BottomSectionContent>
                  <Components.BottomPersonImage
                    src={getBottomPersonImage()}
                    alt="person"
                  />
                </Components.BottomSectionContent>
              </Components.BottomContentContainer>
            </Components.BottomBlock>
          </>
        )}
        {agency.display_topbar_footer && <LandingFooter onClick={handleBookDemo} />}
      </Components.LandingWrapper>
      {showKippPopup && (
        <SchoolAccountPopup
          onClose={() => setShowKippPopup(false)}
          onContinue={(v) => goToAnotherAccount(v)}
        />
      )}
    </>
  )
}

export default Landing
