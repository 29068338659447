/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from '@emotion/styled'
import { IExpertProfile } from 'src/api/models'
import useBranding from 'src/utils/useBranding'
import { Avatar, Text, TextBold } from '../../components'

const ScrollContainer = styled.div``

const ScrollWrapper = styled.div`
  width: 500px;
  .rcs-custom-scroll .rcs-inner-handle {
    background-color: rgba(150, 150, 150, 0.4);
  }

  @media screen and (max-width: 600px) {
    width: auto;
    .rcs-custom-scroll {
      height: auto !important;
    }
  }
`

const ScrollView = styled.div`
  overflow-y: auto;
  height: 500px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ExpertContainer = styled.div<{ selected: boolean; fill: string }>`
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #edeef2;
  border-radius: 8px;
  margin-right: 25px;
  margin-bottom: 20px;
  background: ${(props) => (props.selected ? `${props.fill}12` : 'transparent')};
  border-radius: 8px;
  overflow: auto;
  cursor: pointer;
`

const StyledText = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
`

const StyledTextBold = styled(TextBold)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
  }
`

const StyledTextSmall = styled(Text)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 26px;
`

const TopPart = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #edeef2;
`

const MainInfo = styled.div``

const About = styled.div`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 11px;
`

const InfoBlock = styled.div`
  margin-top: 15px;
`

const BlockTitle = styled(StyledTextSmall)`
  color: rgba(115, 121, 145, 0.8);
`

interface IProps {
  experts: IExpertProfile[]
  onSelect: (expert: IExpertProfile) => void
  selectedExpert: IExpertProfile
}

export default function InterviewExpertsList({
  experts,
  onSelect,
  selectedExpert,
}: IProps) {
  const { agencyColor } = useBranding()

  return (
    <ScrollWrapper>
      <ScrollView>
        <ScrollContainer>
          {experts.map((expert) => (
            <Container
              key={expert.id}
              onClick={() => onSelect(selectedExpert?.id === expert.id ? null : expert)}
            >
              <ExpertContainer
                fill={agencyColor}
                selected={selectedExpert?.id === expert.id}
              >
                <TopPart>
                  <Avatar user={expert} size={45} />
                  <MainInfo>
                    <StyledTextBold>
                      {expert.first_name} {expert.last_name}
                    </StyledTextBold>
                    <StyledText>{`${expert.current_position} ${
                      expert.company ? `at ${expert.company}` : ''
                    }`}</StyledText>
                  </MainInfo>
                </TopPart>
                <About>
                  <StyledTextSmall>{expert.about}</StyledTextSmall>
                </About>
                {selectedExpert?.id === expert.id && (
                  <>
                    {expert.experiences?.some((e) => e !== '') && (
                      <InfoBlock>
                        <BlockTitle>Experience</BlockTitle>
                        {expert.experiences.map((exp, i) => (
                          <StyledTextSmall key={i}>{exp}</StyledTextSmall>
                        ))}
                      </InfoBlock>
                    )}
                    {expert.educations?.some((e) => e !== '') && (
                      <InfoBlock>
                        <BlockTitle>Education</BlockTitle>
                        {expert.educations.map((ed, i) => (
                          <StyledTextSmall key={i}>{ed}</StyledTextSmall>
                        ))}
                      </InfoBlock>
                    )}
                  </>
                )}
              </ExpertContainer>
            </Container>
          ))}
        </ScrollContainer>
      </ScrollView>
    </ScrollWrapper>
  )
}
