/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'

const LevelRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;

  ${!isMobileOnly &&
  css`
    height: 77px;
    @media screen and (max-width: 2500px) {
      height: 64px;
    }
    @media screen and (max-width: 2000px) {
      height: 51px;
    }
    @media screen and (max-width: 1200px) {
      height: 38px;
    }
    @media screen and (max-width: 900px) {
      height: 26px;
    }
  `}
`

const LevelItem = styled.div<{ isActive: boolean }>`
  width: 0px;
  border-radius: 4.5px;
  margin-right: 5.5px;
  background: ${({ isActive }) =>
    isActive ? '#6938AA' : !isMobileOnly ? '#8f9ab3' : '#E1E3EA'};
  border: 4.5px solid
    ${({ isActive }) => (isActive ? '#6938AA' : !isMobileOnly ? '#8f9ab3' : '#E1E3EA')};
  :nth-of-type(1) {
    height: 2%;
    border-width: 3px;
  }
  :nth-of-type(2) {
    height: 3%;
    border-width: 4px;
  }
  :nth-of-type(3) {
    height: 6%;
  }
  :nth-of-type(4) {
    height: 24%;
  }
  :nth-of-type(5) {
    height: 39%;
  }
  :nth-of-type(6) {
    height: 63%;
  }
  :nth-of-type(7) {
    height: 82%;
  }
  :nth-of-type(8) {
    height: 100%;
  }
  :nth-of-type(9) {
    height: 82%;
  }
  :nth-of-type(10) {
    height: 63%;
  }
  :nth-of-type(11) {
    height: 39%;
  }
  :nth-of-type(12) {
    height: 24%;
  }
  :nth-of-type(13) {
    height: 6%;
  }
  :nth-of-type(14) {
    height: 3%;
    border-width: 4px;
  }
  :nth-of-type(15) {
    height: 2%;
    border-width: 3px;
  }

  ${!isMobileOnly &&
  css`
    margin-right: 8.25px;
    border-width: 6.75px;
    :nth-of-type(1),
    :nth-of-type(15) {
      border-width: 4.5px;
    }
    :nth-of-type(2),
    :nth-of-type(14) {
      border-width: 6px;
    }
    @media screen and (max-width: 2500px) {
      border-width: 5.625px;
      margin-right: 6.875px;
      :nth-of-type(1),
      :nth-of-type(15) {
        border-width: 3.75px;
      }
      :nth-of-type(2),
      :nth-of-type(14) {
        border-width: 5px;
      }
    }
    @media screen and (max-width: 2000px) {
      border-width: 4.5px;
      margin-right: 5.5px;
      :nth-of-type(1),
      :nth-of-type(15) {
        border-width: 3px;
      }
      :nth-of-type(2),
      :nth-of-type(14) {
        border-width: 4px;
      }
    }
    @media screen and (max-width: 1200px) {
      border-width: 3.375px;
      margin-right: 4.125px;
      :nth-of-type(1),
      :nth-of-type(15) {
        border-width: 2.25px;
      }
      :nth-of-type(2),
      :nth-of-type(14) {
        border-width: 3px;
      }
    }
    @media screen and (max-width: 900px) {
      border-width: 2.25px;
      margin-right: 2.75px;
      :nth-of-type(1),
      :nth-of-type(15) {
        border-width: 1.5px;
      }
      :nth-of-type(2),
      :nth-of-type(14) {
        border-width: 2px;
      }
    }
  `}
`
const PrecallVolumeView = ({ track }: { track: IMicrophoneAudioTrack }) => {
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const level = track?.getVolumeLevel()
      setVolume(level * 10)
    }, 300)
    return () => clearInterval(interval)
  }, [track])

  return (
    <LevelRow>
      {new Array(15)
        .fill(1)
        .map((it, key) => key)
        .map((it, k) => (
          <LevelItem key={it} isActive={volume > k * 0.6} />
        ))}
    </LevelRow>
  )
}

export default PrecallVolumeView
