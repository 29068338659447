import React from 'react'
import styled from '@emotion/styled'

import { Title, Text } from '../../components'
import danger from '../../static/danger.svg'

// Browser

const Container = styled.div`
  width: 537px;
  background-color: #fff;
  justify-content: center;
  padding: 40px 48px 35px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  border: 1px solid #b202ff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 13px;
`

const StyledText = styled(Text)`
  text-align: center;
  margin-bottom: 18px;
`

const Button = styled.button`
  height: 30px;
  width: 101px;
  border-radius: 12px;
  border: none;
  color: #fff;
  background-color: #353c5a;
  font-weight: 500;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const DangerIconContainer = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 35px;
  /* border: #fbf3ff; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`

const DangerIcon = styled.img`
  width: 100%;
`

export default function CameraPermission({ onClose }: { onClose: () => void }) {
  const title = 'Allow Wizco to use camera and microphone'
  const text =
    'Wizco needs access to your camera and microphone so that other participants can see and hear you.'
  return (
    <Container>
      <DangerIconContainer>
        <DangerIcon src={danger} />
      </DangerIconContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledText>{text}</StyledText>
      <Button onClick={onClose}>Dismiss</Button>
    </Container>
  )
}
