import styled from '@emotion/styled'

interface IVideoResizeContainerProps {
  height: number
  width: number
}

const VideoResizeContainer = styled.div<IVideoResizeContainerProps>`
  box-sizing: border-box;
  width: ${({ width }) => `${width}px`};
  position: relative;
  max-height: 100%;
  // overflow: hidden;
  height: ${({ height }) => `${height}px`};
  margin: 0 auto;
`

export default VideoResizeContainer
