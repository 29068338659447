export enum InterviewPart {
  Intro = 'Intro',
  Interview = 'Interview',
  Report = 'Insights',
}

export enum AvaStep {
  Landing,
  Onboarding,
  Intro,
  Interview,
  Report,
}
