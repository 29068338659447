import { put, takeEvery, call } from 'typed-redux-saga'

import { avaInterviewRetryReportGeneration } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { startAvaReportWatcherAction } from '../../watchers/avaReportWatcher'
import { avaGetInterviewAction } from './getInterview'

export interface IAvaRetryReportGenerationAction {
  interviewToken: string
}

const {
  action: avaRetryReportGenerationAction,
  actionFailure: avaRetryReportGenerationActionFailure,
  actionSuccess: avaRetryReportGenerationActionSuccess,
  actionSuccessClear: avaRetryReportGenerationActionSuccessClear,
  actionFailureClear: avaRetryReportGenerationActionFailureClear,
  actionType: avaRetryReportGenerationActionType,
  actionTypeFailure: avaRetryReportGenerationActionFailureType,
  actionTypeSuccess: avaRetryReportGenerationActionSuccessType,
  reducer: avaRetryReportGenerationRequestReducer,
  selectors: avaRetryReportGenerationSelectors,
} = reduxRequestFactory<IAvaRetryReportGenerationAction>(
  'avaRetryReportGeneration',
  'requests'
)

export {
  avaRetryReportGenerationAction,
  avaRetryReportGenerationActionFailure,
  avaRetryReportGenerationActionSuccess,
  avaRetryReportGenerationActionSuccessClear,
  avaRetryReportGenerationActionFailureClear,
  avaRetryReportGenerationActionType,
  avaRetryReportGenerationActionFailureType,
  avaRetryReportGenerationActionSuccessType,
  avaRetryReportGenerationRequestReducer,
  avaRetryReportGenerationSelectors,
}

function* avaRetryReportGenerationSaga(
  action: ISagaAction<IAvaRetryReportGenerationAction>
) {
  try {
    const { interviewToken } = action.payload
    yield* call(avaInterviewRetryReportGeneration, interviewToken)
    yield put(avaRetryReportGenerationActionSuccess())
    yield put(avaGetInterviewAction({ interviewToken }))
    yield put(startAvaReportWatcherAction({ interviewToken }))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaRetryReportGenerationActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaRetryReportGeneration() {
  yield takeEvery(avaRetryReportGenerationActionType, avaRetryReportGenerationSaga)
}
