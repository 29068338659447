import React from 'react'
import styled from '@emotion/styled'

import closeIcon from 'src/static/close_gray.svg'

import {
  OnboardingPopup,
  MobileOnboardingPopup,
  StyledText,
  MobileStyledText,
  MobileNextBtn,
  MobileClose,
} from './onboarding/BaseOnboarding'

const EndPopup = styled(OnboardingPopup)`
  position: absolute;
  right: 50%;
  top: 100%;
  transform: translate(20%, 0.75rem);

  &:after {
    left: auto;
    right: 19%;
  }
`

const MobileEndPopup = styled(MobileOnboardingPopup)`
  &:after {
    left: auto;
    right: 12%;
  }

  right: 15px;
  top: 72px;

  @media (orientation: landscape) {
    right: 28%;
    top: 70px;

    &:after {
      right: 13%;
    }
  }
`

const CloseLink = styled(StyledText)`
  font-weight: 500;
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
`

export function EndCallNotification({ onClose }: { onClose: () => void }) {
  const label =
    'Please make sure to click here at the end of the session to fill out the report.'
  const buttonText = 'Got it'

  const handleClose = (e) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <EndPopup>
      <StyledText>{label}</StyledText>
      <CloseLink onClick={handleClose}>{buttonText}</CloseLink>
    </EndPopup>
  )
}

export function EndCallNotificationMobile({ onClose }: { onClose: () => void }) {
  const label =
    'Please make sure to click here at the end of the session to fill out the report.'
  const buttonText = 'Got it'
  return (
    <MobileEndPopup>
      <MobileClose onClick={onClose} src={closeIcon} />
      <MobileStyledText>{label}</MobileStyledText>
      <MobileNextBtn onClick={onClose}>{buttonText}</MobileNextBtn>
    </MobileEndPopup>
  )
}
