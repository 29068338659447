import httpClient from '../httpClient'

interface IAuthCall {
  user: {
    email: string
    password: string
  }
}

interface IResetCall {
  user: {
    email: string
  }
}

interface IChangePasswordCall {
  password: string
  resetToken: string
}

const signIn = ({ user }: IAuthCall) => {
  const { email, password } = user
  return httpClient({
    method: 'POST',
    url: '/users/sign_in',
    data: { email, password },
  }).then((response) => response.headers.authorization)
}

const signOut = () => {
  return httpClient({
    method: 'DELETE',
    url: '/users/sign_out',
  }).catch(() => {})
}

const reset = ({ user }: IResetCall) => {
  const { email } = user
  return httpClient.post('/users/password', { email })
}

const changePassword = ({ password, resetToken }: IChangePasswordCall) => {
  return httpClient.put('/users/password', {
    password,
    reset_password_token: resetToken,
  })
}

export default {
  signIn,
  reset,
  changePassword,
  signOut,
}
