import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import cameraBlocked from '../../static/camera_blocked.png'
import danger from '../../static/danger.svg'

import { Title, Text, TextBold } from '../../components'

const Container = styled.div`
  width: 537px;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  border-radius: 10px;
`

const StyledTitle = styled(Title)`
  color: ${({ theme }: { theme: any }) => theme.textColor};
  text-align: center;
  margin-bottom: 13px;
`

const StyledSubtitle = styled(Text)`
  color: ${({ theme }: { theme: any }) => theme.textColor};
  text-align: center;
  margin-bottom: 30px;
`

const InstructionsContainer = styled.div`
  width: 100%;
  padding: 21px 34px;
  border: 1px solid ${({ theme }: { theme: any }) => theme.modalPermissionBorder};
  border-radius: 10px;
  background-color: ${({ theme }: { theme: any }) => theme.modalPermissionBackground};
`

const InstructionsHeading = styled(TextBold)`
  color: ${({ theme }: { theme: any }) => theme.textColor};
`

const Instruction = styled(Text)`
  color: ${({ theme }: { theme: any }) => theme.textColor};
`

const Button = styled.button`
  height: 30px;
  width: 101px;
  border-radius: 12px;
  border: none;
  color: #fff;
  background-color: #b202ff;
  font-weight: 500;
  margin: 20px auto 0;
  display: block;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const AddressBarWrapper = styled.div`
  position: relative;
  height: 33px;
  margin-bottom: 30px;
`

const AddressBar = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  opacity: 0.33;
  border: 1px solid #353c5a;
  border-radius: 16px;
  background: ${({ theme }: { theme: any }) => theme.cameraBlockedAddressBackground};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
`

const Address = styled(Text)`
  color: ${({ theme }: { theme: any }) => theme.textColor};
  opacity: 0.8;
`

const CameraBlockedContainer = styled.div`
  height: 14px;
`

const CameraBlockedAddressContainer = styled(CameraBlockedContainer)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-46%);
`

const CameraBlocked = styled.img`
  height: 100%;
  width: auto;
  display: block;
`

const CameraBlockedContainerInline = styled(CameraBlockedContainer)`
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
`

// Mobile

const MobileContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 190px;
  justify-content: space-between;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 308px;

  @media (orientation: landscape) {
    width: 365px;
    height: calc(100% - 67px - 72px);
  }
`

const MobileHeader = styled.div`
  height: 30px;
  width: 30px;
`

const DangerIcon = styled.img`
  width: 100%;
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const MobileText = styled.p`
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.textColor};
  display: flex;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: 17px;
  text-align: center;
  margin: 12px 0;
`

const MobileTitle = styled(MobileText)`
  font-size: 16px;
  line-height: 19px;
`

const MobileControls = styled.div`
  display: flex;
  height: 24%;
  margin-top: 27px;

  @media (orientation: landscape) {
    margin-top: 0;
  }
`

const MobileButton = styled.button`
  align-items: flex-start;
  background: #6938aa;
  border: none;
  border-radius: 14px;
  color: ${({ theme }: { theme: any }) => theme.textColor};
  display: flex;
  flex-direction: row;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  height: 33px;
  line-height: 17px;
  padding: 8px 20px;
  width: 94px;
`

export default function CameraNotAllowed() {
  const title = 'Your camera and microphone are blocked'
  const textMobile =
    'To start the coaching session please refresh your page and allow Wizco to access your camera and microphone'

  // eslint-disable-next-line no-restricted-globals
  const onRefresh = () => location.reload()

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <AddressBarWrapper>
            <AddressBar>
              <Address>{`${window.location.protocol}//${window.location.host}`}</Address>
            </AddressBar>
            <CameraBlockedAddressContainer>
              <CameraBlocked src={cameraBlocked} />
            </CameraBlockedAddressContainer>
          </AddressBarWrapper>
          <StyledTitle>{title}</StyledTitle>
          <StyledSubtitle>
            The coaching session requires access to your camera and microphone.
          </StyledSubtitle>
          <InstructionsContainer>
            <InstructionsHeading>To start the coaching session:</InstructionsHeading>
            <Instruction>
              1. Click the camera blocked icon{' '}
              <CameraBlockedContainerInline>
                <CameraBlocked src={cameraBlocked} />
              </CameraBlockedContainerInline>{' '}
              in your browser’s address bar
            </Instruction>
            <Instruction>2. Allow access and then refresh the page</Instruction>
            <Button onClick={onRefresh}>Refresh</Button>
          </InstructionsContainer>
        </Container>
      )}

      {isMobileOnly && (
        <MobileContainer>
          <MobileHeader>
            <DangerIcon src={danger} />
          </MobileHeader>
          <MobileContent>
            <MobileTitle>{title}</MobileTitle>
            <MobileText>{textMobile}</MobileText>
          </MobileContent>
          <MobileControls>
            <MobileButton onClick={onRefresh}>Refresh</MobileButton>
          </MobileControls>
        </MobileContainer>
      )}
    </>
  )
}
