import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import CoachingSessionDetails from '../components/CoachingSessionDetails'
import { getInterview } from '../actions'

function CoachingSessionDetailsContainer() {
  const { id, interviewId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInterview({ agencyId: id, id: interviewId }))
  }, [dispatch, interviewId, id])

  return <CoachingSessionDetails />
}

export default CoachingSessionDetailsContainer
