import * as React from 'react'

interface SvgProps {
  fill: string
  disabled: boolean
  inactive: boolean
}

function SvgShareScreen({ fill, disabled, inactive }: SvgProps) {
  return (
    <svg
      style={{ overflow: 'visible' }}
      width="37"
      height="20"
      viewBox="0 0 37 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7354 3C25.5492 3 26.209 3.67155 26.209 4.50001V13.5C26.209 14.2766 25.6291 14.9154 24.8861 14.9923L24.7354 15H11.4736C10.6597 15 10 14.3284 10 13.5V4.50001C10 3.67159 10.6597 3 11.4736 3H24.7354ZM24.7354 4.50001H11.4736V13.5H24.7354V4.50001ZM17.5585 7.20041C17.7965 6.88773 18.1818 6.88698 18.4203 7.20041L20.3962 9.79663C20.8731 10.4233 20.6649 10.9312 19.9283 10.9312H16.0506C15.3152 10.9312 15.1039 10.4257 15.5826 9.79663L17.5585 7.20041ZM17.9892 9.44413V13.4097V9.44413Z"
        fill={fill || '#FFF'}
      />
      {!inactive && !disabled && (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9054 7C12.1021 7 13.1854 7.48502 13.9696 8.2692C14.7537 9.05338 15.2388 10.1367 15.2388 11.3333V14.6667C15.2388 15.8633 14.7537 16.9466 13.9696 17.7308C13.1854 18.515 12.1021 19 10.9054 19H7.5721C6.37549 19 5.29215 18.515 4.50797 17.7308C3.72379 16.9466 3.23877 15.8633 3.23877 14.6667L3.23877 11.3333C3.23877 10.1367 3.72379 9.05338 4.50797 8.2692C5.29215 7.48502 6.37549 7 7.5721 7H10.9054Z"
            fill="white"
            stroke="#1D0E3B"
            strokeWidth="2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9054 8C12.7464 8 14.2388 9.49238 14.2388 11.3333V14.6667C14.2388 16.5076 12.7464 18 10.9054 18H7.5721C5.73115 18 4.23877 16.5076 4.23877 14.6667V11.3333C4.23877 9.49238 5.73115 8 7.5721 8H10.9054ZM11.2964 11.2653C11.0164 11.0674 10.6234 11.1262 10.4185 11.3966L8.94675 13.3387L7.99682 12.4191C7.7518 12.1819 7.35407 12.1814 7.10847 12.418C6.86286 12.6547 6.86239 13.0388 7.10741 13.276L8.28292 14.414C8.31184 14.442 8.34242 14.4683 8.37451 14.4926C8.81468 14.8261 9.44192 14.7397 9.77548 14.2995L11.4323 12.1132C11.6372 11.8428 11.5763 11.4632 11.2964 11.2653Z"
            fill="#00DE9F"
          />
        </>
      )}
    </svg>
  )
}

export default SvgShareScreen
