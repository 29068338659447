import React from 'react'
import styled from '@emotion/styled'
import { FormattedInput } from '@buttercup/react-formatted-input'
import { Container, LabelsContainer, Label, OptionalLabel, Error } from './input-styles'

interface ILabelsContainer {
  isSelected: boolean
}

export const ExtendedLabel = styled.label`
  display: inline-block;
  line-height: 20px;
  transition: transform 150ms ease-in, font-size 150ms ease-in;
  opacity: 0.5;
  color: #21304e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  pointer-events: none;
  margin: 0;
  min-width: 140px;
  white-space: nowrap;
`

const InputWrapper = styled.div`
  width: 100%;
  height: 100%;
  > input {
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: #f5f6f8 !important;
    border-radius: 4px;
    padding-top: 14px;
    padding-left: 9px;
    color: #21304e;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
  }
`

export const TimeLabelsContainer = styled(LabelsContainer)`
  margin-top: -22px;
  > label {
    font-size: 10px;
  }
`

const TimeInput = React.forwardRef((props: any, ref: any) => {
  const { name, error, onChange, value, disabled, label, optional } = props
  const hasError = !!error.type

  return (
    <Container disabled={disabled} hasError={hasError}>
      <InputWrapper>
        <FormattedInput
          name={name}
          format={[
            { char: /\d/, repeat: 2 },
            { exactly: ':' },
            { char: /[012345]/ },
            { char: /\d/ },
          ]}
          ref={ref}
          value={value}
          onChange={(formattedValue) => {
            onChange(formattedValue)
          }}
        />
      </InputWrapper>
      <TimeLabelsContainer>
        <Label>{label}</Label>
        {optional && <OptionalLabel>Optional</OptionalLabel>}
      </TimeLabelsContainer>
      {error && error!.type !== 'required' && <Error>{error.message}</Error>}
      {error && error!.type === 'required' && <Error>Required</Error>}
    </Container>
  )
})

TimeInput.defaultProps = {
  error: {
    type: '',
    message: '',
    ref: '',
  },
  type: 'text',
  optional: false,
  useTheme: false,
  customTheme: '',
}

export default TimeInput
