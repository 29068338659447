import React from 'react'

interface SvgProps {
  color?: string
}

function SvgCvFile({ color }: SvgProps) {
  return (
    <svg
      className="svg"
      style={{ overflow: 'visible' }}
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.3333 0H4.57143C4.17717 0.00134396 3.81145 0.205803 3.60381 0.540952L0.556192 3.58857C0.21057 3.79531 -0.000726952 4.1687 1.87939e-06 4.57143V14.8571C1.87939e-06 15.4883 0.511677 16 1.14286 16H13.3333C13.9645 16 14.4762 15.4883 14.4762 14.8571V1.14286C14.4762 0.511675 13.9645 0 13.3333 0ZM3.42857 2.33143V3.42857H2.33143L3.42857 2.33143ZM13.3333 14.8571H1.14286V4.60952H1.16572L1.18857 4.58667H4.57143V1.20381L4.59429 1.18095V1.1581H13.3181L13.3333 14.8571Z"
          fill={color}
        />
        <path
          d="M11.0477 8.76192H3.42861C3.11301 8.76192 2.85718 9.01775 2.85718 9.33335C2.85718 9.64894 3.11301 9.90477 3.42861 9.90477H11.0477C11.3632 9.90477 11.6191 9.64894 11.6191 9.33335C11.6191 9.01775 11.3632 8.76192 11.0477 8.76192Z"
          fill={color}
        />
        <path
          d="M9.9048 11.4285H3.42861C3.11301 11.4285 2.85718 11.6844 2.85718 12C2.85718 12.3156 3.11301 12.5714 3.42861 12.5714H9.9048C10.2204 12.5714 10.4762 12.3156 10.4762 12C10.4762 11.6844 10.2204 11.4285 9.9048 11.4285Z"
          fill={color}
        />
        <path
          d="M8.38102 7.2381H10.6667C11.2979 7.2381 11.8096 6.72643 11.8096 6.09524V3.80953C11.8096 3.17835 11.2979 2.66667 10.6667 2.66667H8.38102C7.74983 2.66667 7.23816 3.17835 7.23816 3.80953V6.09524C7.23816 6.72643 7.74983 7.2381 8.38102 7.2381ZM8.38102 3.80953H10.6667V6.09524H8.38102V3.80953Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

SvgCvFile.defaultProps = {
  color: '#200E32',
}

export default SvgCvFile
