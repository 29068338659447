import httpClient from '../../httpClient'

// ava_interviews#create - POST /ava/interviews
export const avaCreateInterview = (agencyId, candidate) => {
  return httpClient
    .post(`/ava/interviews?agency_id=${agencyId}`, { candidate })
    .then((response) => response.data)
}

// ava_interviews#show - GET /ava/interviews/:interview_token
export const avaGetInterview = (interviewToken) => {
  return httpClient
    .get(`/ava/interviews/${interviewToken}`)
    .then((response) => response.data)
}

// ava_interviews#index - GET /ava/interviews
export const avaGetInterviews = (agencyId) => {
  return httpClient
    .get(`/ava/interviews?agency_id=${agencyId}`)
    .then((response) => response.data)
}

// ava_interviews#answer - POST /ava/interviews/:interview_token/answer
export const avaSaveAnswer = (interviewToken, data) => {
  return httpClient
    .post(`/ava/interviews/${interviewToken}/answer`, data)
    .then((response) => response.data)
}

// ava_interviews#complete - POST /ava/interviews/:interview_token/complete
export const avaCompleteInterview = (interviewToken) => {
  return httpClient
    .post(`/ava/interviews/${interviewToken}/complete`)
    .then((response) => response.data)
}

// ava_interviews#info - POST /ava/interviews/:interview_token/info
export const avaSaveInfo = (interviewToken, body, params) => {
  return httpClient
    .post(`/ava/interviews/${interviewToken}/info`, body, { params })
    .then((response) => response.data)
}

// ava_interviews#status - GET /ava/interviews/:interview_token/status
export const avaInterviewStatus = (interviewToken) => {
  return httpClient
    .get(`/ava/interviews/${interviewToken}/status`)
    .then((response) => response.data)
}

// ava_interviews#prompts - GET /ava/interviews/:interview_token/prompts
export const avaGetInterviewPrompts = (interviewToken) => {
  return httpClient
    .get(`/ava/interviews/${interviewToken}/prompts`)
    .then((response) => response.data)
}

// ava_interviews#replace_question - POST /ava/interviews/:interview_token/replace_question
export const avaInterviewReplaceQuestion = (interviewToken, body) => {
  return httpClient
    .post(`/ava/interviews/${interviewToken}/replace_question`, body)
    .then((response) => response.data)
}

// ava_interviews#retry_questions_generation - POST /ava/interviews/:interview_token/retry_questions_generation
export const avaInterviewRetryQuestionsGeneration = (interviewToken) => {
  return httpClient
    .post(`/ava/interviews/${interviewToken}/retry_questions_generation`)
    .then((response) => response.data)
}

// ava_interviews#retry_report_generation - POST /ava/interviews/:interview_token/retry_report_generation
export const avaInterviewRetryReportGeneration = (interviewToken) => {
  return httpClient
    .post(`/ava/interviews/${interviewToken}/retry_report_generation`)
    .then((response) => response.data)
}

// ava#synthesize_voice - GET /ava/synthesize_voice
export const avaSynthesizeVoice = (text) => {
  return httpClient
    .post(`/ava/synthesize_voice`, { text }, { responseType: 'arraybuffer' })
    .then((response) => response.data)
}
