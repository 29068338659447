import React from 'react'

function SvgMore({ active, fill }: { active: boolean; fill: string }) {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.5C13.3807 15.5 14.5 16.6193 14.5 18C14.5 19.3807 13.3807 20.5 12 20.5C10.6193 20.5 9.5 19.3807 9.5 18C9.5 16.6193 10.6193 15.5 12 15.5ZM4.5 15.5C5.88071 15.5 7 16.6193 7 18C7 19.3807 5.88071 20.5 4.5 20.5C3.11929 20.5 2 19.3807 2 18C2 16.6193 3.11929 15.5 4.5 15.5ZM19.5 15.5C20.8807 15.5 22 16.6193 22 18C22 19.3807 20.8807 20.5 19.5 20.5C18.1193 20.5 17 19.3807 17 18C17 16.6193 18.1193 15.5 19.5 15.5Z"
        fill={fill}
      />
      {active && (
        <>
          <circle cx="25.5" cy="9.5" r="9.5" fill="#FF002A" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1244 2.00006C28.0665 2.02179 30.69 3.85443 31.7187 6.60691C32.7475 9.35967 31.9674 12.4607 29.7581 14.4014C27.5492 16.3418 24.37 16.7194 21.7668 15.3507L21.7344 15.3393C21.7293 15.3363 21.7285 15.3338 21.7274 15.3304L21.244 15.0569C21.1455 15.0166 21.0364 15.01 20.9698 15.0269C20.4672 15.2076 19.9536 15.3559 19.432 15.4711L19.3364 15.4826C18.7623 15.4954 18.473 15.1228 18.473 14.5807L18.4868 14.4645C18.6172 13.9253 18.7806 13.3946 18.9666 12.9031C18.9954 12.8101 18.9858 12.7094 18.936 12.6161L18.8058 12.3623C17.655 10.1619 17.7417 7.52046 19.0346 5.40007C20.3273 3.27996 22.637 1.99018 25.1244 2.00006ZM25.1176 2.98786L24.9186 2.99098C22.8556 3.05 20.9561 4.1471 19.8785 5.9146C18.7662 7.73885 18.6915 10.0112 19.6834 11.9077L19.8116 12.1577C19.982 12.4766 20.0177 12.8504 19.9011 13.2236C19.7199 13.705 19.5684 14.1969 19.4475 14.6968L19.5142 14.4364L19.794 14.3633C19.986 14.3104 20.1789 14.252 20.3748 14.1872L20.6714 14.0854C20.9846 13.9991 21.3176 14.0191 21.6457 14.1543C21.7146 14.1875 21.8 14.2336 21.9077 14.2945L22.2069 14.4661C22.2119 14.4675 22.2164 14.4687 22.2198 14.4697L22.211 14.4685L22.4148 14.5706C24.5579 15.6054 27.111 15.3011 28.9499 13.7913L29.1058 13.6589C31.0068 11.989 31.6779 9.32113 30.7929 6.95291C29.9077 4.58443 27.6497 3.00712 25.1176 2.98786ZM21.978 8.27553C22.4243 8.27553 22.7864 8.63708 22.7864 9.08348C22.7864 9.52988 22.4243 9.89143 21.978 9.89143C21.5316 9.89143 21.1695 9.52988 21.1695 9.08348C21.1695 8.63708 21.5316 8.27553 21.978 8.27553ZM25.1208 8.27553C25.5671 8.27553 25.9292 8.63708 25.9292 9.08348C25.9292 9.52988 25.5671 9.89143 25.1208 9.89143C24.6744 9.89143 24.3123 9.52988 24.3123 9.08348C24.3123 8.63708 24.6744 8.27553 25.1208 8.27553ZM28.2635 8.27553C28.7099 8.27553 29.072 8.63708 29.072 9.08348C29.072 9.52988 28.7099 9.89143 28.2635 9.89143C27.8172 9.89143 27.4551 9.52988 27.4551 9.08348C27.4551 8.63708 27.8172 8.27553 28.2635 8.27553Z"
            fill="white"
          />
        </>
      )}
    </svg>
  )
}

export default SvgMore
