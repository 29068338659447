import { useDispatch } from 'react-redux'
import useStateRef from 'react-usestateref'
import { useEffect } from 'react'

import { IExpertNoteMood } from '../../api'
import { removeExpertNoteAction, saveExpertNoteAction } from '../../redux'

const useFeedback = () => {
  const dispatch = useDispatch()
  const [activeFeedback, setActiveFeedback, activeFeedbackRef] = useStateRef('')

  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(items.join('\n'))

  const sendFeedback = (interviewToken: string, text: string, mood?: IExpertNoteMood) => {
    dispatch(saveExpertNoteAction({ mood, text, interviewToken }))
    log('Feedback sent')
    setActiveFeedback('Your comment was added to the report summary')
  }

  const editFeedback = (interviewToken: string, id: string, text: string) => {
    dispatch(saveExpertNoteAction({ id, text, interviewToken }))
  }

  const deleteFeedback = (interviewToken: string, id: string) => {
    dispatch(removeExpertNoteAction({ id, interviewToken }))
  }

  useEffect(() => {
    if (activeFeedback) {
      setTimeout(() => {
        if (activeFeedback === activeFeedbackRef.current) setActiveFeedback('')
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFeedback])

  return {
    activeFeedback,
    sendFeedback,
    deleteFeedback,
    editFeedback,
  }
}

export default useFeedback
