import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'

import { callNetworkLocalSelectors, callNetworkRemoteSelectors } from '../../../redux'
import { CallNetworkQuality } from '../../../redux/types'
import getNetworkQuality from '../../../utils/networkQuality'

const Container = styled.div`
  align-items: center;
  background: #00000080;
  border-radius: 30%;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20% 33%;
  width: 100%;
`

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10%;
  height: 100%;
  width: 100%;
`

const BarContainer = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 10%;
  height: 75%;
`

const IndicatorBar = styled.div<{ active: boolean; quality: CallNetworkQuality }>`
  background-color: ${({ active, quality }) => {
    if (!active) return '#FFFFFF'
    switch (quality) {
      case CallNetworkQuality.EXCELENT:
        return '#00de9f'
      case CallNetworkQuality.NORMAL:
        return '#f1be2b'
      case CallNetworkQuality.BAD:
        return '#ff3d71'
      default:
        return '#ffffff'
    }
  }};
  border-radius: 5%;
  width: 33%;
`

const LowIndicatorBar = styled(IndicatorBar)`
  height: 50%;
`

const MiddleIndicatorBar = styled(IndicatorBar)`
  height: 75%;
`

const HighIndicatorBar = styled(IndicatorBar)`
  height: 100%;
`

const IndicatorFooter = styled.div`
  background-color: #ffffff;
  border-radius: 5%;
  height: 25%;
  width: 100%;
`

interface IProps {
  quality: CallNetworkQuality
}

const NetworkQualityIndicator = ({ quality }: IProps) => {
  return (
    <Container>
      <IndicatorContainer>
        <BarContainer>
          <LowIndicatorBar active={quality >= CallNetworkQuality.BAD} quality={quality} />
          <MiddleIndicatorBar
            active={quality >= CallNetworkQuality.NORMAL}
            quality={quality}
          />
          <HighIndicatorBar
            active={quality === CallNetworkQuality.EXCELENT}
            quality={quality}
          />
        </BarContainer>
        <IndicatorFooter />
      </IndicatorContainer>
    </Container>
  )
}

export const LocalNetworkQualityIndicator = () => {
  const data = useSelector(callNetworkLocalSelectors.data)
  const quality = getNetworkQuality(data)

  if (!quality) return null
  return <NetworkQualityIndicator quality={quality} />
}

export const RemoteNetworkQualityIndicator = () => {
  const data = useSelector(callNetworkRemoteSelectors.data)
  const quality = getNetworkQuality(data)

  if (!quality) return null
  return <NetworkQualityIndicator quality={quality} />
}
