import styled from '@emotion/styled'
import React from 'react'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  place-self: center;

  @media screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    min-height: 70px;
  }
`

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
`

const IconStyled = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 12px;
`

const TextStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #222b45;
`

const ValueStyled = styled.div<{ color?: string }>`
  position: relative;
  font-weight: 500;
  font-size: 30px;
  color: #222b45;
  padding-left: 16px;
  line-height: 34px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: ${({ color }) => color ?? '#B202FF'};
  }

  @media screen and (max-width: 1280px) {
    margin-top: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`

const EmojiStyled = styled.div`
  font-weight: 500;
  font-size: 34px;
`

const UnavailableStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #222b45;
  padding-right: 10px;
`

interface IProps {
  icon: any
  text: string
  value: string
  color: string
  unavailable?: boolean
}

const ReportNumberComponent = ({ icon, text, value, color, unavailable }: IProps) => {
  return (
    <Container>
      <LineContainer>
        <IconStyled src={icon} alt="icon" />
        <TextStyled>{text}</TextStyled>
      </LineContainer>
      {unavailable && (
        <>
          <EmojiStyled>🫣</EmojiStyled>
          <UnavailableStyled>Not publicly disclosed</UnavailableStyled>
        </>
      )}
      {!unavailable && <ValueStyled color={color}>{value}</ValueStyled>}
    </Container>
  )
}

ReportNumberComponent.defaultProps = {
  unavailable: false,
}

export default ReportNumberComponent
