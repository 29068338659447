import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStateRef from 'react-usestateref'

import {
  callNetworkLocalSelectors,
  callNetworkRemoteSelectors,
  updateCallNetworkRemoteAction,
} from '../../redux'
import { CallNetworkQuality } from '../../redux/types'

const usePeerNetworkQuality = (peerConnected: boolean) => {
  const dispatch = useDispatch()
  const timer = useRef(undefined)
  const [offset, setOffset, offsetRef] = useStateRef(0)

  const resetTimer = useCallback(() => {
    if (!timer.current) return
    clearInterval(timer.current)
    setOffset(0)
    timer.current = null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const localQuality = useSelector(callNetworkLocalSelectors.data)
  const [hasPoorConnection, setHasPoorConnection] = useStateRef(false)
  useEffect(() => {
    setHasPoorConnection(
      localQuality?.downlinkNetworkQuality &&
        localQuality?.uplinkNetworkQuality &&
        (localQuality?.downlinkNetworkQuality === CallNetworkQuality.BAD ||
          localQuality?.uplinkNetworkQuality === CallNetworkQuality.BAD)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localQuality])

  useEffect(() => {
    if (!peerConnected || hasPoorConnection) resetTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerConnected, hasPoorConnection])

  const peerQuality = useSelector(callNetworkRemoteSelectors.data)
  useEffect(() => {
    if (
      !timer.current &&
      peerConnected &&
      !hasPoorConnection &&
      peerQuality?.downlinkNetworkQuality &&
      peerQuality?.uplinkNetworkQuality
    ) {
      timer.current = setInterval(() => {
        setOffset(offsetRef.current + 1)
      }, 1000)
    }

    if (
      peerQuality?.downlinkNetworkQuality &&
      peerQuality?.uplinkNetworkQuality &&
      peerQuality?.downlinkNetworkQuality !== CallNetworkQuality.BAD &&
      peerQuality?.uplinkNetworkQuality !== CallNetworkQuality.BAD
    ) {
      setOffset(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peerConnected, hasPoorConnection, peerQuality])

  useEffect(() => {
    if (offset === 10)
      dispatch(
        updateCallNetworkRemoteAction({
          downlinkNetworkQuality: CallNetworkQuality.BAD,
          uplinkNetworkQuality: CallNetworkQuality.BAD,
        })
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])
}

export default usePeerNetworkQuality
