export enum Browser {
  Firefox = 'Mozilla Firefox',
  Samsung = 'Samsung',
  Opera = 'Opera',
  IE = 'IE',
  EdgeLegacy = 'Edge (Legacy)',
  Edge = 'Edge',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Unknown = 'Unknown',
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
// The order matters here, and this may report false positives for unlisted browsers.

export const detectBrowser = (): Browser => {
  const agent = navigator.userAgent || ''
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  if (agent.indexOf('Firefox') > -1) return Browser.Firefox
  // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  if (agent.indexOf('SamsungBrowser') > -1) return Browser.Samsung
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  if (agent.indexOf('Opera') > -1 || agent.indexOf('OPR') > -1) return Browser.Opera
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  if (agent.indexOf('Trident') > -1) return Browser.IE
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  if (agent.indexOf('Edge') > -1) return Browser.EdgeLegacy
  // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
  if (agent.indexOf('Edg') > -1) return Browser.Edge
  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  if (agent.indexOf('Chrome') > -1) return Browser.Chrome
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  if (agent.indexOf('Safari') > -1) return Browser.Safari
  return Browser.Unknown
}
