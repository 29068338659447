import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { subDays } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'
import '../datapicker.css'
import Input from './DatePickerInput'

const getColor = ({ theme, useTheme }: any) => {
  if (theme.agencyColor && useTheme) {
    return theme.agencyColor
  }

  return '#B202FF'
}

const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))

interface IDatePicker {
  value: any
  useTheme?: boolean
  optional?: boolean
  label?: string
  openOnLoad?: boolean
  showTimeSelect?: boolean
  shouldCloseOnSelect?: boolean
  onChange: (date: Date) => void
  inline?: boolean
  disableFuture?: boolean
}

interface IContainer {
  useTheme?: boolean
  inline?: boolean
}

const Container = styled.div<IContainer>`
  width: 100%;
  position: relative;
  
  & > div {
    width: 100%;
  }
  
  & .datapicker-component {
    box-shadow: ${({ inline }) => (inline ? 'none!important' : '0 2px 24px 0 #ededed')};

  & .react-datepicker__day--keyboard-selected,
  & .react-datepicker__day--selected {
    background-color: ${({ theme, useTheme }: any) => {
      const checkedColor = theme.agencyColor && useTheme ? theme.agencyColor : '#B202FF'
      const checkedColorRGB = hexToRgb(checkedColor)

      return `rgba(${checkedColorRGB[0]}, ${checkedColorRGB[1]}, ${checkedColorRGB[2]}, 0.4)`
    }};
  }

  & .react-datepicker__day--highlighted {
    background-color: ${(props) => getColor(props)};
  }

`

const StyledDatePicker = styled(DatePicker)``

const today = new Date()

function DatePickerComponent(props: IDatePicker) {
  const {
    value,
    onChange,
    openOnLoad,
    useTheme,
    label,
    showTimeSelect,
    shouldCloseOnSelect,
    optional,
    inline,
    disableFuture,
  } = props

  const inputRef = useRef(null)

  useEffect(() => {
    if (openOnLoad) {
      inputRef.current.handleFocus()
    }
  }, [openOnLoad])

  return (
    <Container useTheme={useTheme} inline={!!inline}>
      <StyledDatePicker
        ref={inputRef}
        popoverAttachment="bottom right"
        popoverTargetAttachment="top right"
        popperPlacement="top-start"
        adjustDateOnChange
        selected={value}
        inline={inline}
        customInput={
          <Input useTheme={useTheme} customLabel={label} optional={optional} />
        }
        showTimeSelect={showTimeSelect}
        onChange={onChange}
        dayClassName={() => 'datapicker-day'}
        className="datapicker-input"
        calendarClassName="datapicker-component"
        highlightDates={[today]}
        shouldCloseOnSelect={shouldCloseOnSelect}
        dateFormat={showTimeSelect ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy'}
        filterDate={(date: string) => {
          if (disableFuture) {
            return Date.parse(date) - subDays(today, 0).valueOf() <= 0
          }
          return Date.parse(date) - subDays(today, 1).valueOf() >= 0
        }}
        fixedHeight
      />
    </Container>
  )
}

DatePickerComponent.defaultProps = {
  useTheme: false,
  label: '',
  optional: false,
  openOnLoad: false,
  showTimeSelect: false,
  shouldCloseOnSelect: false,
  inline: false,
  disableFuture: false,
}

export default DatePickerComponent
