import React from 'react'
import styled from '@emotion/styled'

import { IMicrophoneAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng'

import peopleIcon from 'src/static/icUser.png'
import SvgAudioMute from 'src/static/components/SvgAudioMute'

import Volume from '../volume'
import { Text as MobileText } from '../MobileText'
import {
  LocalNetworkQualityIndicator,
  RemoteNetworkQualityIndicator,
} from '../volume/NetworkQualityIndicator'

const Video = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;

  > div {
    border-radius: 7px;
  }
`

const LocalVideo = styled(Video)`
  transform: scale(-1, 1);
`

interface IVideoContainerProps {
  height: number
  width: number
}

const VideoContainer = styled.div<IVideoContainerProps>`
  background-color: #353c5a;
  border-radius: 7px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: ${({ width }) => `${width}px`};
  min-height: 177px;
  min-width: 100px;
  height: ${({ height }) => `${height}px`};
  overflow: inherit;

  @media (orientation: landscape) {
    min-height: 100px;
    min-width: 177px;
  }
`

const MuteOverlay = styled.div`
  align-items: center;
  background-color: #353c5a;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  height: calc(100% + 1px);
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% + 1px);
  box-shadow: 0px 0px 1px black;
`

const MuteText = styled(MobileText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 8px;
  width: 24px;
`

const LeftTopContainer = styled.div`
  align-items: center;
  display: flex;
  height: 18px;
  position: absolute;
  left: 10px;
  top: 8px;
  width: 24px;
`

const MicWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  width: 60px;
`

const MobileAvatarLogo = styled.img`
  border-radius: 50px;
  height: 100px;
  width: 100px;
`

const MobileAvatarDefault = styled.img`
  background: white;
  border-radius: 50px;
  height: 100px;
  padding: 35px;
  width: 100px;
`

interface IProps {
  id: string
  isLocal: boolean
  username: string
  videoMuted: boolean
  audioMuted: boolean
  track: IMicrophoneAudioTrack | IRemoteAudioTrack
  dimensions: {
    height: number
    width: number
  }
  logo: string
}

const SmallVideoMobile = ({
  id,
  isLocal,
  username,
  videoMuted,
  audioMuted,
  track,
  dimensions: { height, width },
  logo,
}: IProps) => {
  return (
    <VideoContainer height={height} width={width}>
      {isLocal ? <LocalVideo id={id} /> : <Video id={id} />}
      {videoMuted && (
        <>
          <MuteOverlay>
            {logo ? (
              <MobileAvatarLogo src={logo} />
            ) : (
              <MobileAvatarDefault src={peopleIcon} />
            )}
            <MuteText>{username}</MuteText>
          </MuteOverlay>
        </>
      )}
      <LeftTopContainer>
        {isLocal ? <LocalNetworkQualityIndicator /> : <RemoteNetworkQualityIndicator />}
      </LeftTopContainer>
      <ButtonsContainer>
        {!audioMuted && track && (
          <MicWrap>
            <Volume.AudioVolumeIndicator element={undefined} track={track} />
          </MicWrap>
        )}
        {audioMuted && <SvgAudioMute />}
      </ButtonsContainer>
    </VideoContainer>
  )
}

export default SmallVideoMobile
