import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { NotFound } from '../components'
import useSubdomain from '../utils/useSubdomain'

import Interview from './containers/Interview'
import * as interviewSelectors from './selectors'

function InterviewHOC({ children }: { children: JSX.Element }) {
  const agency = useSelector(interviewSelectors.agency)
  const { isAdminSubdomain } = useSubdomain()

  if (agency?.id === '' && !isAdminSubdomain) return null
  return isAdminSubdomain || !!agency?.enable_experts ? children : <NotFound />
}

function InterviewContainer() {
  return (
    <Switch>
      <Route exact path="/interviews">
        <InterviewHOC>
          <Interview />
        </InterviewHOC>
      </Route>
      <Route path="/interviews/:interviewToken">
        <InterviewHOC>
          <Interview />
        </InterviewHOC>
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default InterviewContainer
