import React from 'react'
import styled from '@emotion/styled'

const VideoContainer = styled.div<{
  isExpert: boolean
  hasJoined: boolean
  inCodeSharing: boolean
  isPortraitTablet: boolean
}>`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: ${({ isExpert, hasJoined, inCodeSharing }) => {
    if (isExpert && inCodeSharing) return '1.5rem'
    return hasJoined && !isExpert ? '5vh' : '0'
  }};
  max-height: ${({ hasJoined, isPortraitTablet }) =>
    // eslint-disable-next-line no-nested-ternary
    hasJoined ? '100%' : isPortraitTablet ? '60%' : '30.37vw'};
  overflow: hidden;
  position: relative;
  width: ${({ hasJoined }) => (hasJoined ? '100%' : '54vw')};

  .slick-slider {
    width: 100%;
  }
`

interface IMainVideo extends React.HTMLProps<HTMLDivElement> {
  isExpert: boolean
  hasJoined: boolean
  inCodeSharing: boolean
  isPortraitTablet: boolean
}

export function MainVideo({
  children,
  inCodeSharing,
  isPortraitTablet,
  isExpert,
  hasJoined,
}: IMainVideo) {
  return (
    <VideoContainer
      isExpert={isExpert}
      hasJoined={hasJoined}
      inCodeSharing={inCodeSharing}
      isPortraitTablet={isPortraitTablet}
    >
      {children}
    </VideoContainer>
  )
}

const MobileContainer = styled.div<{
  hasScreenshare: boolean
  preview: boolean
  isFullscreen: boolean
  isExpert: boolean
}>`
  height: ${({ preview, isFullscreen, isExpert }) =>
    // eslint-disable-next-line no-nested-ternary
    preview
      ? '62%'
      : // eslint-disable-next-line no-nested-ternary
      isFullscreen
      ? isExpert
        ? 'calc(100% - 70px)'
        : '80%'
      : isExpert
      ? 'calc(100% - 70px)'
      : '80%'};
  position: relative;
  width: 100vw;

  .slick-dots {
    align-item: center;
    bottom: -10px;
    display: flex !important;
    justify-content: center;
    position: relateive;

    > li {
      height: 8px;
      margin: 0 2px;
      width: 8px;

      button {
        height: 8px;
        width: 8px;
        padding: 0;

        &::before {
          color: #e1e3ea;
          height: 7px;
          opacity: 1;
          width: 7px;
        }
      }

      &.slick-active {
        button::before {
          color: #953aed;
        }
      }
    }
  }

  @media (orientation: landscape) {
    height: ${({ preview }) => (preview ? '35vh' : '84%')};
    max-width: ${({ preview }) => (preview ? '50%' : 'calc(100vw - 16px)')};
    margin: 0;

    .slick-dots {
      bottom: ${({ isFullscreen }) => (isFullscreen ? '-10px' : '20%')};
    }
  }
`

interface IMobileMainVideo extends React.HTMLProps<HTMLDivElement> {
  hasScreenshare: boolean
  preview: boolean
  isFullscreen: boolean
  isExpert: boolean
}

export function MobileMainVideo({
  children,
  hasScreenshare,
  preview,
  isFullscreen,
  isExpert,
}: IMobileMainVideo) {
  return (
    <MobileContainer
      isFullscreen={isFullscreen}
      hasScreenshare={hasScreenshare}
      preview={preview}
      isExpert={isExpert}
    >
      {children}
    </MobileContainer>
  )
}
