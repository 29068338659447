import React, { useState } from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  position: relative;
  padding: 10px;
  border: solid 1px #b202ff;
  border-radius: 4px;
  height: 40px;
  font-size: 12px;
  z-index: 1;
  cursor: pointer;
`

const ListContainer = styled.div`
  position: absolute;
  width: max-content;
  top: 39px;
  left: 0px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px grey;
  border-radius: 4px;
  border: solid 1px white;
  background: white;
`

const ListItem = styled.div`
  font-size: 12px;
  background: white;
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: #f5f6f8;
  }
`

interface IProps {
  items: any[]
  onItemSelect?: (item: any) => void
  children?: any
}

export default function SingleSelect({ items, onItemSelect, children }: IProps) {
  const [showDropdown, setShowDropdown] = useState(false)
  const onMouseEnter = () => {
    setShowDropdown(true)
  }
  const onMouseLeave = () => {
    setShowDropdown(false)
  }
  const onItemSelected = (item: string) => {
    onItemSelect(item)
    setShowDropdown(false)
  }
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      {showDropdown && (
        <ListContainer>
          {items.map((item) => (
            <ListItem key={item} onClick={() => onItemSelected(item)}>
              {item}
            </ListItem>
          ))}
        </ListContainer>
      )}
    </Container>
  )
}

SingleSelect.defaultProps = {
  onItemSelect: () => {},
  children: null,
}
