import { reduxDataFactory } from '../../../utils/redux'

const {
  action: updateCompletedPreMeetingAction,
  actionType: updateCompletedPreMeetingActionType,
  reducer: completedPreMeetingDataReducer,
  selectors: completedPreMeetingSelectors,
} = reduxDataFactory<boolean>('completedPreMeeting', 'interviewParams', false)

const proceedToMeetingAction = updateCompletedPreMeetingAction(true)

export {
  updateCompletedPreMeetingAction,
  updateCompletedPreMeetingActionType,
  completedPreMeetingDataReducer,
  completedPreMeetingSelectors,
  proceedToMeetingAction,
}
