import { datadogLogs } from '@datadog/browser-logs'
import { IVirtualBackgroundExtension } from 'agora-extension-virtual-background'
import {
  ClientConfig,
  IAgoraRTC,
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  NetworkQuality,
  UID,
} from 'agora-rtc-sdk-ng'
import moment from 'moment'
import { useCallback, useEffect, useRef } from 'react'
import { isMobileOnly, isSafari } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import useStateRef from 'react-usestateref'
import { updateCallNetworkLocalAction } from 'src/redux/data/callNetworkLocal'
import playSound from 'src/utils/playSound'

import { getAgoraToken } from '../../api'
import { DefaultVideoProfile } from '../components/VideoProfiles'

const StudentUidPrefix = 1
const ExpertUidPrefix = 2

const CameraUidPrefix = 1
const ScreensharingUidPrefix = 2

const CHANNEL = 'test'

const StatsCollectIntervalMs = 10 * 1000

const config: ClientConfig = {
  codec: 'vp8',
  mode: 'rtc',
}

const consoleStyles = ['%c%s', 'font-weight: bold;', '[Wizco RTC]']

const JoinAudio = '/static/videocall_event.mp3'
const ShareAudio = '/static/videocall_share.mp3'

const useRtc = (channelId = CHANNEL, isExpert = false, orderNumber) => {
  const dispatch = useDispatch()

  const [rtc, setRtc, rtcRef] = useStateRef<IAgoraRTC>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uid, setUid, uidRef] = useStateRef<UID>(
    (isExpert ? ExpertUidPrefix : StudentUidPrefix) * 10 + CameraUidPrefix
  )
  const [token, setToken, tokenRef] = useStateRef<string>()
  const [client, setClient, clientRef] = useStateRef<IAgoraRTCClient>()

  const [audioTrack, setAudioTrack, audioTrackRef] = useStateRef<IMicrophoneAudioTrack>()
  const [cameraTrack, setCameraTrack, cameraTrackRef] = useStateRef<ICameraVideoTrack>()
  const [remoteAudioTrack, setRemoteAudioTrack, remoteAudioTrackRef] = useStateRef<
    IRemoteAudioTrack
  >()
  const [remoteCameraTrack, setRemoteCameraTrack, remoteCameraTrackRef] = useStateRef<
    IRemoteVideoTrack
  >()
  const [remoteScreenTrack, setRemoteScreenTrack, remoteScreenTrackRef] = useStateRef<
    IRemoteVideoTrack
  >()

  const vbExtension = useRef<IVirtualBackgroundExtension>(null)
  const vbProcessor = useRef(null)
  const [isBackgroundBlurEnabled, setIsBackgroundBlurEnabled] = useStateRef(false)

  const [audioMuted, setAudioMuted, audioMutedRef] = useStateRef(false)
  const [cameraMuted, setCameraMuted, cameraMutedRef] = useStateRef(false)
  const [canMuteAudio, setCanMuteAudio] = useStateRef(true)
  const [canMuteCamera, setCanMuteCamera] = useStateRef(true)
  const [remoteAudioMuted, setRemoteAudioMuted] = useStateRef(false)
  const [remoteCameraMuted, setRemoteCameraMuted] = useStateRef(false)

  const [audioPublished, setAudioPublished, audioPublishedRef] = useStateRef(false)
  const [cameraPublished, setCameraPublished, cameraPublishedRef] = useStateRef(false)
  const [peerUid, setPeerUid] = useStateRef<UID>()

  const [joinedToCall, setJoinedToCall, joinedToCallRef] = useStateRef(false)
  const [joinedToCallFailure, setJoinedToCallFailure] = useStateRef(false)

  const [peerJoined, setPeerJoined, peerJoinedRef] = useStateRef(false)
  const [peerLeft, setPeerLeft, peerLeftRef] = useStateRef(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCodeSharing, setIsCodeSharing, isCodeSharingRef] = useStateRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isScreenSharing, setIsScreenSharing, isScreenSharingRef] = useStateRef(false)

  const [isDisconnected, setIsDisconnected] = useStateRef(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoProfile, setVideoProfile, videoProfileRef] = useStateRef(
    DefaultVideoProfile
  )
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pendingVideoProfileChange,
    setPendingVideoProfileChange,
    pendingVideoProfileChangeRef,
  ] = useStateRef(false)

  const [hasDualStream, setHasDualStream, hasDualStreamRef] = useStateRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dualStreamQuality, setDualStreamQuality, dualStreamQualityRef] = useStateRef<
    0 | 1
  >(0)

  const statsLastSavedAt = useRef<string>()

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.join('\n'))
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.join('\n'))

  const generateToken = async () => {
    const data = await getAgoraToken(uidRef.current as number, channelId)
    setToken(data.token)
  }

  const isScreensharing = (userUid: string) => {
    return userUid.endsWith(ScreensharingUidPrefix.toString())
  }

  const isMyStream = (userUid: string) => {
    return isExpert
      ? userUid.startsWith(ExpertUidPrefix.toString())
      : userUid.startsWith(StudentUidPrefix.toString())
  }

  const sendRtcStatsToDatadog = useCallback(
    (networkQuality: NetworkQuality) => {
      if (!statsLastSavedAt.current) {
        statsLastSavedAt.current = moment().toString()
      } else {
        const duration = moment().diff(new Date(statsLastSavedAt.current), 'ms')
        if (duration >= StatsCollectIntervalMs) {
          statsLastSavedAt.current = moment().toString()
          const packet: any = {}
          packet.networkQuality = networkQuality
          packet.rtcStats = clientRef.current.getRTCStats()
          packet.localVideoStats = clientRef.current.getLocalVideoStats()
          packet.localAudioStats = clientRef.current.getLocalAudioStats()
          packet.isExpert = isExpert
          packet.timestamp = new Date()
          packet.interviewId = orderNumber
          datadogLogs.logger.log('rtc stats', packet)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderNumber]
  )

  const playLocalCamera = (forcePreview: boolean = false) => {
    const isSmallVideo =
      remoteScreenTrackRef.current ||
      isScreenSharingRef.current ||
      isCodeSharingRef.current ||
      (peerJoinedRef.current && !peerLeftRef.current)

    cameraTrackRef.current?.stop()
    cameraTrackRef.current?.play(
      isSmallVideo && !forcePreview ? 'local-video' : 'preview-video',
      {
        fit: 'cover',
        mirror: false,
      }
    )
    log('Local camera started playing')
  }

  const playRemoteCamera = () => {
    const isSmallVideo =
      remoteScreenTrackRef.current ||
      isScreenSharingRef.current ||
      isCodeSharingRef.current

    remoteCameraTrackRef.current?.stop()
    remoteCameraTrackRef.current?.play(isSmallVideo ? 'peer-video' : 'main-video', {
      fit: 'contain',
      mirror: false,
    })
    log('Remote camera started playing')
  }

  const playRemoteScreenshare = () => {
    remoteScreenTrackRef.current.stop()
    remoteScreenTrackRef.current.play('main-video', {
      fit: 'contain',
      mirror: false,
    })
  }

  const restoreLocalCamera = async () => {
    if (isMobileOnly && !cameraMutedRef.current && cameraTrackRef.current) {
      await cameraTrackRef.current.setEnabled(false)
      await cameraTrackRef.current.setEnabled(true)
      log('Mobile camera track restored')
    }
  }

  const handleTokenPrivilegeWillExpire = async () => {
    await generateToken()
    clientRef.current.renewToken(tokenRef.current)
    log('Renewed token')
  }

  const handleConnectionChange = (state, prevState, reason) => {
    log(
      `Connection state changed from ${prevState} to ${state}${
        reason ? ` [${reason}]` : ''
      }`
    )
    if (state === 'DISCONNECTED' && reason === 'UID_BANNED') {
      setIsDisconnected(true)
    }
  }

  const handleClientException = (exception) => {
    error('Error', JSON.stringify(exception))
  }

  const handleFirstFrameDecoded = () => {
    const {
      aspectRatio,
      height,
      width,
    } = remoteCameraTrackRef.current.getMediaStreamTrack().getSettings()
    const aspect = aspectRatio || width / height
    log(`Remote track aspect ratio: ${aspect} (${width}/${height}px)`)
  }

  const handlePeerPublishedAudio = (track) => {
    setRemoteAudioTrack(track)
    log(`Peer published audio`)
    remoteAudioTrackRef.current.play()
  }

  const handlePeerPublishedCamera = (track) => {
    track.on('first-frame-decoded', handleFirstFrameDecoded)
    setRemoteCameraTrack(track)
    log(`Peer published video`)
    playRemoteCamera()
  }

  const handlePeerPublishedScreen = (track) => {
    setRemoteScreenTrack(track)
    log(`Peer shared screen`)
  }

  const handleUserPublished = async (user, mediaType) => {
    const userUid = user.uid.toString()
    const isPeer = !isScreensharing(userUid)
    if (isPeer) setPeerUid(userUid)

    try {
      await clientRef.current.subscribe(user, mediaType)
    } catch (e) {
      error(
        `Failed to subscribe to user ${
          isPeer ? mediaType : 'screen'
        } track (uid: ${userUid}): ${JSON.stringify(e)}`
      )
      return
    }

    switch (mediaType) {
      case 'audio':
        handlePeerPublishedAudio(user.audioTrack)
        break
      case 'video':
        if (isPeer) handlePeerPublishedCamera(user.videoTrack)
        else if (!isMyStream(userUid)) {
          handlePeerPublishedScreen(user.videoTrack)
          playSound(ShareAudio)
        }
        break
      default:
        break
    }
  }

  const handlePeerUnpublishedAudio = () => {
    remoteAudioTrackRef.current?.stop()
    setRemoteAudioTrack(undefined)
    log(`Peer stopped audio`)
  }

  const handlePeerUnpublishedCamera = () => {
    remoteCameraTrackRef.current?.stop()
    setRemoteCameraTrack(undefined)
    log(`Peer stopped video`)
  }

  const handlePeerUnpublishedScreen = () => {
    remoteScreenTrackRef.current?.stop()
    setRemoteScreenTrack(undefined)
    log(`Peer stopped sharing screen`)
  }

  const handleUserUnpublished = (user, mediaType) => {
    const userUid = user.uid.toString()
    switch (mediaType) {
      case 'audio':
        handlePeerUnpublishedAudio()
        break
      case 'video':
        if (!isScreensharing(userUid)) handlePeerUnpublishedCamera()
        else if (!isMyStream(userUid)) handlePeerUnpublishedScreen()
        break
      default:
        break
    }
  }

  const handleNetworkQuality = (quality: NetworkQuality) => {
    dispatch(updateCallNetworkLocalAction(quality))
  }

  const handleUserInfoUpdated = (userUid, msg) => {
    if (isScreensharing(userUid.toString())) return
    switch (msg) {
      case 'mute-audio':
        setRemoteAudioMuted(true)
        break
      case 'mute-video':
        setRemoteCameraMuted(true)
        break
      case 'unmute-audio':
        setRemoteAudioMuted(false)
        break
      case 'unmute-video':
        setRemoteCameraMuted(false)
        break
      default:
        break
    }
  }

  const handleUserJoined = (user) => {
    const userUid = user.uid.toString()

    if (!isScreensharing(userUid)) {
      if (isMyStream(userUid)) {
        log('Detected multiple attempts to join. Disconnecting...')
        setIsDisconnected(true)
      } else {
        setPeerJoined(true)
        setPeerLeft(false)
        log(`Peer joined (uid: ${userUid})`)
        playLocalCamera()
        clientRef.current.on('network-quality', sendRtcStatsToDatadog)
        playSound(JoinAudio)
      }
    } else {
      log(
        `${isMyStream(userUid) ? 'Local' : 'Remote'} screenshare joined (uid: ${userUid})`
      )
    }
  }

  const handleUserLeft = (user) => {
    const userUid = user.uid.toString()

    if (!isScreensharing(userUid)) {
      setPeerLeft(true)
      log(`Peer left (uid: ${userUid})`)
      setRemoteAudioMuted(false)
      setRemoteCameraMuted(false)
      clientRef.current.off('network-quality', sendRtcStatsToDatadog)
      playLocalCamera()
    } else {
      log(
        `${isMyStream(userUid) ? 'Local' : 'Remote'} screenshare left (uid: ${userUid})`
      )
    }
  }

  const changeDualStreamQuality = async (quality: 0 | 1) => {
    if (!hasDualStreamRef.current) return
    const label = !quality ? 'high' : 'low'
    setDualStreamQuality(quality)
    try {
      await clientRef.current?.setRemoteDefaultVideoStreamType(quality)
      log(`Switch to ${label}-quality peer stream`)
    } catch (e) {
      error(`Failed to switch to ${label}-quality peer stream: ${JSON.stringify(e)}`)
    }
  }

  const notifyLocalScreenshareChange = (active: boolean) => {
    setIsScreenSharing(active)
  }

  const switchFromCodeshare = () => {
    if (!joinedToCallRef.current) return
    setIsCodeSharing(false)
    playLocalCamera()
    playRemoteCamera()
  }

  const switchFromScreenshare = () => {
    if (!joinedToCallRef.current) return
    if (remoteScreenTrackRef.current) remoteScreenTrackRef.current?.stop()
    playLocalCamera()
    playRemoteCamera()
    changeDualStreamQuality(0)
  }

  const switchToCodeshare = () => {
    if (!joinedToCallRef.current) return
    setIsCodeSharing(true)
    playLocalCamera()
    playRemoteCamera()
  }

  const switchToScreenshare = () => {
    if (!joinedToCallRef.current) return
    if (remoteScreenTrackRef.current) playRemoteScreenshare()
    playLocalCamera()
    playRemoteCamera()
    changeDualStreamQuality(1)
  }

  const changeAudio = async (microphoneId, handleTrackEnded) => {
    if (!microphoneId) return
    if (audioTrackRef.current) {
      log(`Changing audio track device (${microphoneId})`)
      try {
        await audioTrackRef.current?.setDevice(microphoneId)
        log(`Successfully changed audio track device`)
      } catch (e) {
        error(`Failed to change audio track device: ${JSON.stringify(e)}`)
        throw e
      }
    } else {
      log(`Creating new audio track with device (${microphoneId})`)
      try {
        setAudioTrack(await rtcRef.current.createMicrophoneAudioTrack({ microphoneId }))
        audioTrackRef.current.on('track-ended', handleTrackEnded)
        log(`Successfully created new audio track`)
      } catch (e) {
        error(`Failed to create new audio track: ${JSON.stringify(e)}`)
        throw e
      }
    }
  }

  const changeCamera = async (cameraId) => {
    if (!cameraId) return
    if (cameraTrackRef.current) {
      log(`Changing camera track device (${cameraId})`)
      try {
        await cameraTrackRef.current?.setDevice(cameraId)
        log(`Successfully changed camera track device`)
      } catch (e) {
        error(`Failed to change camera track device: ${JSON.stringify(e)}`)
        throw e
      }
    } else {
      log(`Creating new camera track with device (${cameraId})`)
      try {
        setCameraTrack(
          await rtcRef.current.createCameraVideoTrack({
            optimizationMode: 'detail',
            facingMode: 'user',
            encoderConfig: {
              width: videoProfileRef.current.width,
              height: videoProfileRef.current.height,
              frameRate: videoProfileRef.current.frameRate,
              bitrateMin: videoProfileRef.current.bitrate,
              bitrateMax: videoProfileRef.current.bitrate,
            },
            cameraId,
          })
        )
        log(`Successfully created new camera track`)
      } catch (e) {
        error(`Failed to create new camera track: ${JSON.stringify(e)}`)
        throw e
      }
    }
    playLocalCamera()
  }

  const changeEncoderConfig = async (profile = videoProfileRef.current) => {
    if (cameraTrackRef.current) {
      if (cameraMutedRef.current) {
        setVideoProfile(profile)
        setPendingVideoProfileChange(true)
        log('Camera video profile will be changed next time camera is active')
      } else {
        try {
          log(
            `Changing camera video profile (${profile.width}x${profile.height}px, ${profile.frameRate}fps)`
          )
          await cameraTrackRef.current.setEncoderConfiguration({
            width: profile.width,
            height: profile.height,
            frameRate: profile.frameRate,
            bitrateMin: profile.bitrate,
            bitrateMax: profile.bitrate,
          })
          setVideoProfile(profile)
          setPendingVideoProfileChange(false)
          log('Successfully changed camera video profile')
        } catch (e) {
          error(`Failed to change camera video profile: ${JSON.stringify(e)}`)
          throw e
        }
      }
    }
  }

  const changeSpeaker = async (speakerId) => {
    if (!speakerId) return
    if (remoteAudioTrackRef.current) {
      try {
        log(`Changing speaker device (${speakerId})`)
        await remoteAudioTrackRef.current.setPlaybackDevice(speakerId)
        log(`Successfully changed speaker device`)
      } catch (e) {
        error(`Failed to change speaker device: ${JSON.stringify(e)}`)
        throw e
      }
    }
  }

  const muteAudio = async () => {
    if (audioTrackRef.current) {
      const muted = !audioMutedRef.current
      setCanMuteAudio(false)
      try {
        await audioTrackRef.current.setEnabled(!muted)
        setAudioMuted(muted)
        log(muted ? 'Muted audio feed' : 'Unmuted audio feed')
      } catch (e) {
        error(`Failed to ${muted ? 'mute' : 'unmute'} audio feed: ${JSON.stringify(e)}`)
      }
      setCanMuteAudio(true)
    }
  }

  const muteCamera = async () => {
    if (cameraTrackRef.current) {
      const muted = !cameraMutedRef.current
      setCanMuteCamera(false)
      try {
        await cameraTrackRef.current.setEnabled(!muted)
        setCameraMuted(muted)
        log(muted ? 'Muted camera feed' : 'Unmuted camera feed')
        if (!muted && pendingVideoProfileChangeRef.current) {
          await changeEncoderConfig()
          await restoreLocalCamera()
        }
      } catch (e) {
        error(`Failed to ${muted ? 'mute' : 'unmute'} camera feed: ${JSON.stringify(e)}`)
      }
      setCanMuteCamera(true)
    }
  }

  const muteCameraPoorNetwork = () => {
    muteCamera()
  }

  const initCall = () => {
    setClient(rtcRef.current.createClient(config))
    clientRef.current.on('token-privilege-will-expire', handleTokenPrivilegeWillExpire)
    // eslint-disable-next-line no-underscore-dangle
    log(`RTC client initializing (id: ${(clientRef.current as any)?._clientId})`)
  }

  const publishTracks = async () => {
    const tracks = []
    const shouldPublishAudio =
      audioTrackRef.current && !audioPublishedRef.current && !audioMutedRef.current
    const shouldPublishCamera =
      cameraTrackRef.current && !cameraPublishedRef.current && !cameraMutedRef.current
    if (shouldPublishAudio) tracks.push(audioTrackRef.current)
    if (shouldPublishCamera) tracks.push(cameraTrackRef.current)
    try {
      if (shouldPublishAudio || shouldPublishCamera)
        await clientRef.current.publish(tracks)
      if (shouldPublishAudio) {
        setAudioPublished(true)
        log('Successfully published audio track')
      }
      if (shouldPublishCamera) {
        setCameraPublished(true)
        log('Successfully published camera track')
      }
    } catch (e) {
      const shouldPublishMultiple = shouldPublishAudio && shouldPublishCamera
      error(
        `Failed to publish ${shouldPublishAudio ? 'audio' : ''}${
          shouldPublishMultiple ? ' and ' : ''
        }${shouldPublishCamera ? 'camera' : ''} track${
          shouldPublishMultiple ? 's' : ''
        }: ${JSON.stringify(e)}`
      )
    }
  }

  const joinCall = async () => {
    log('Join call started...')
    const appId = process.env.REACT_APP_AGORA_APP_ID

    try {
      await generateToken()
    } catch (e) {
      error(`Failed to generate token for RTC: ${JSON.stringify(e)}`)
      setJoinedToCallFailure(true)
      return false
    }

    if (!isSafari && !hasDualStreamRef.current) {
      try {
        await clientRef.current.enableDualStream()
        setHasDualStream(true)
        log('Dual-stream mode enabled')
      } catch (e) {
        setHasDualStream(false)
        error(`Failed to enable dual-stream mode: ${JSON.stringify(e)}`)
      }
    }

    try {
      await clientRef.current.join(appId, channelId, tokenRef.current, uidRef.current)
      setJoinedToCallFailure(false)
      setJoinedToCall(true)
      log(`Successfully joined call (uid: ${uidRef.current})`)
      clientRef.current.on('user-published', handleUserPublished)
      clientRef.current.on('user-unpublished', handleUserUnpublished)
      clientRef.current.on('network-quality', handleNetworkQuality)
      clientRef.current.on('user-info-updated', handleUserInfoUpdated)
      clientRef.current.on('user-joined', handleUserJoined)
      clientRef.current.on('user-left', handleUserLeft)
      clientRef.current.on('connection-state-change', handleConnectionChange)
      clientRef.current.on('exception', handleClientException)
      log('Successfully set up events')
      playSound(JoinAudio)
    } catch (e) {
      error(`Failed to join the channel: ${JSON.stringify(e)}`)
      setJoinedToCallFailure(true)
      return false
    }

    return true
  }

  const leaveCall = (leaveDeviceAlive: boolean = false) => {
    if (!leaveDeviceAlive) {
      audioTrackRef.current?.close()
      setAudioTrack(undefined)
      cameraTrackRef.current?.close()
      setCameraTrack(undefined)
      log('Closed audio/video tracks')
    } else {
      playLocalCamera(true)
    }
    clientRef.current?.leave()
    setJoinedToCall(false)
    log('Left channel')
  }

  const getProcessorInstance = async () => {
    if (!vbProcessor.current && cameraTrackRef.current) {
      vbProcessor.current = vbExtension.current.createProcessor()

      try {
        await vbProcessor.current.init('./static/wasms')
      } catch (e) {
        log('Fail to load WASM resource')
        return null
      }
      cameraTrackRef.current
        .pipe(vbProcessor.current)
        .pipe(cameraTrackRef.current.processorDestination)
    }
    return vbProcessor.current
  }

  const setBackgroundBlurring = async (enable: boolean) => {
    if (cameraTrackRef.current) {
      const processor = await getProcessorInstance()
      try {
        if (enable) {
          processor.setOptions({ type: 'blur', blurDegree: 2 })
          await processor.enable()
        } else {
          await processor.disable()
        }
        log(`Background blurring is ${enable ? 'enabled' : 'disabled'}`)
        setIsBackgroundBlurEnabled(enable)
      } catch (e) {
        log('Enable background blurring failed')
      }
    }
  }

  useEffect(() => {
    const importObj =
      process.env.TEST === '1' ? import('./agoraRtcMock') : import('./agoraRtc')
    importObj.then((rtcService) => {
      const importVirtualExtensionObj =
        process.env.TEST === '1'
          ? import('./virtualBackgroundExtensionMock')
          : import('./virtualBackgroundExtension')
      importVirtualExtensionObj.then((virtualBackgroundExtension) => {
        vbExtension.current = virtualBackgroundExtension.default()
        rtcService.default().registerExtensions([vbExtension.current])
      })
      setRtc(rtcService.default())
      rtcRef.current.enableLogUpload()
      // rtcRef.current.setLogLevel(
      //   Number.parseInt(process.env.REACT_APP_AGORA_LOG_LEVEL, 10)
      // )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    callInitialized: !!client,
    callToken: token,
    callUid: uid,
    canMuteAudio,
    canMuteCamera,
    dualStreamQuality,
    hasDualStream,
    hasScreenshare: isScreenSharing || !!remoteScreenTrack,
    isDisconnected,
    isRemoteScreenSharing: !!remoteScreenTrack,
    joinedToCall,
    joinedToCallFailure,
    localAudioMuted: audioMuted,
    localAudioPublished: audioPublished,
    localAudioTrack: audioTrack,
    localAudioTrackBusy: !audioMuted && !audioTrack,
    localCameraMuted: cameraMuted,
    localCameraPublished: cameraPublished,
    localCameraTrack: cameraTrack,
    localCameraTrackBusy: !cameraMuted && !cameraTrack,
    peerJoined,
    peerLeft,
    peerUid,
    remoteAudioMuted,
    remoteAudioTrack,
    remoteCameraMuted,
    remoteCameraTrack,
    rtc,
    isBackgroundBlurEnabled,
    rtcError: joinedToCallFailure,
    changeEncoderConfig,
    changeLocalAudio: changeAudio,
    changeLocalCamera: changeCamera,
    changeLocalSpeaker: changeSpeaker,
    initCall,
    joinCall,
    leaveCall,
    muteAudio,
    muteCamera,
    muteCameraPoorNetwork,
    notifyLocalScreenshareChange,
    publishTracks,
    restoreLocalCamera,
    switchFromCodeshare,
    switchFromScreenshare,
    switchToCodeshare,
    switchToScreenshare,
    setBackgroundBlurring,
  }
}

export default useRtc
