import styled from '@emotion/styled'
import React from 'react'

import SvgMore from 'src/static/components/SvgMore'

import { MobileHeaderButtonContainer, MobileHeaderButtonLabel } from './BaseButtons'

const MoreHeaderButtonContainer = styled(MobileHeaderButtonContainer)`
  & > svg {
    transform: translateX(5px);
  }
`
interface IMoreButton {
  disabled: boolean
  showBadge: boolean
  theme: any
  onClick: () => void
}

export default function MoreButton({ disabled, showBadge, theme, onClick }: IMoreButton) {
  return (
    <MoreHeaderButtonContainer onClick={!disabled ? onClick : undefined}>
      <SvgMore active={showBadge} fill={theme.textColor} />

      <MobileHeaderButtonLabel>More</MobileHeaderButtonLabel>
    </MoreHeaderButtonContainer>
  )
}
