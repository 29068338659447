import {
  IAdminInterview,
  IAdminStats,
  IAgency,
  IAgencyStats,
  IGetAdminInterviewsParams,
  IGetAdminStatsParams,
} from '../api'
import { IFailure } from '../utils/redux'

export const GET_AGENCIES = 'ADMIN/GET_AGENCIES'
export const GET_AGENCIES_SUCCESS = 'ADMIN/GET_AGENCIES_SUCCESS'
export const GET_AGENCIES_FAILURE = 'ADMIN/GET_AGENCIES_FAILURE'
export const GET_INTERVIEWS = 'ADMIN/GET_INTERVIEWS'
export const GET_INTERVIEWS_SUCCESS = 'ADMIN/GET_INTERVIEWS_SUCCESS'
export const GET_INTERVIEWS_FAILURE = 'ADMIN/GET_INTERVIEWS_FAILURE'
export const GET_STATS = 'ADMIN/GET_STATS'
export const GET_STATS_SUCCESS = 'ADMIN/GET_STATS_SUCCESS'
export const GET_STATS_FAILURE = 'ADMIN/GET_STATS_FAILURE'

export interface IGetAgenciesAction {
  type: typeof GET_AGENCIES
  payload: null
}

export interface IGetAgenciesSuccessAction {
  type: typeof GET_AGENCIES_SUCCESS
  payload: {
    agencies: IAgency[]
  }
}

export interface IGetAgenciesFailureAction {
  type: typeof GET_AGENCIES_FAILURE
  payload: IFailure
}

export interface IGetInterviewsAction {
  type: typeof GET_INTERVIEWS
  payload: IGetAdminInterviewsParams
}

export interface IGetInterviewsSuccessAction {
  type: typeof GET_INTERVIEWS_SUCCESS
  payload: {
    interviews: IAdminInterview[]
    pages: number
  }
}

export interface IGetInterviewsFailureAction {
  type: typeof GET_INTERVIEWS_FAILURE
  payload: IFailure
}

export interface IGetStatsAction {
  type: typeof GET_STATS
  payload: IGetAdminStatsParams
}

export interface IGetStatsSuccessAction {
  type: typeof GET_STATS_SUCCESS
  payload: {
    stats: IAdminStats | IAgencyStats
  }
}

export interface IGetStatsFailureAction {
  type: typeof GET_STATS_FAILURE
  payload: IFailure
}
