import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

import chevron from 'src/static/chevron-down.svg'

import { IAgencyPositions } from '../../../api'
import { Input, Text } from '../../../components'
import CheckBox from '../../components/CheckBox'

const Container = styled.div`
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label<{ collapsed: boolean }>`
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: all 200ms;
  }
`

const List = styled.div<{ collapsed: boolean }>`
  border-top: 1px solid #e1e3ea;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  margin-top: 10px;
  padding-top: 10px;
`

const RoleItem = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  border: 1px solid;
  border-radius: 10px;
  padding: 5px;
  margin: 10px 0;

  > div {
    margin: 0;
    padding: 0;

    > input {
      padding: 0;
    }
  }
`

const PositionItem = styled.div``

const RolesContainer = styled.div``

const RoleLabel = styled(Text)`
  font-size: 14px;
`

const PositionLabel = styled(Text)`
  font-size: 16px;
  font-weight: bold;
`

const EditLabel = styled(Text)`
  cursor: pointer;
  margin: 0;
  margin-right: 10px;
  text-decoration: underline;
`

const Options = styled.div`
  display: flex;
`

interface IProps {
  positions: IAgencyPositions
  onChange: (positions: IAgencyPositions) => void
}

interface IRoleProps {
  role: { label: string; is_manager?: boolean }
  active: boolean
  current: string
  onActive: () => void
  onDelete: () => void
  onSave: (data: { label: string; is_manager?: boolean }) => void
}

const Role = ({
  role: { label, is_manager: isManager = false },
  active,
  current,
  onActive,
  onDelete,
  onSave,
}: IRoleProps) => {
  const [value, setValue] = useState<string>(label)

  useEffect(() => {
    setValue(label)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  const handleSave = () => {
    onSave({ label: value, is_manager: isManager })
  }

  return (
    <RoleItem>
      {!active && (
        <>
          <RoleLabel>{label}</RoleLabel>
          <Options>
            <EditLabel onClick={onActive}>Edit</EditLabel>
            {!current && <EditLabel onClick={onDelete}>Delete</EditLabel>}
          </Options>
          <CheckBox
            label="Manager?"
            onChange={(checked) => onSave({ label: value, is_manager: checked })}
            value={isManager}
            name={label}
          />
        </>
      )}
      {active && (
        <>
          <Input value={value} onChange={setValue} />
          <EditLabel onClick={handleSave}>Save</EditLabel>
        </>
      )}
    </RoleItem>
  )
}

interface IPositionProps {
  label: string
  roles: { label: string; is_manager?: boolean }[]
  active: boolean
  current: string
  onActive: (label: string) => void
  onDelete: () => void
  onSave: (d: {
    newLabel: string
    data: { label: string; is_manager?: boolean }[]
  }) => void
}

const Position = ({
  label,
  roles,
  active,
  current,
  onActive,
  onSave,
  onDelete,
}: IPositionProps) => {
  const [value, setValue] = useState<string>(label)
  const [newRole, setNewRole] = useState<string>()

  const handleRoleSave = (role, data) => {
    onSave({ newLabel: value, data: roles.map((r) => (r === role ? data : r)) })
  }

  const handleRoleDelete = (role) => {
    onSave({ newLabel: value, data: roles.filter((r) => r !== role) })
  }

  const handlePositionSave = () => {
    onSave({ newLabel: value, data: roles })
  }

  const handleAddRole = () => {
    onSave({ newLabel: value, data: [...roles, { label: newRole, is_manager: false }] })
    setNewRole(undefined)
  }

  useEffect(() => {
    setNewRole(undefined)
  }, [current])

  return (
    <PositionItem>
      {!active && (
        <>
          <PositionLabel>{label}</PositionLabel>
          <Options>
            <EditLabel onClick={() => onActive(label)}>Edit</EditLabel>
            {!current && <EditLabel onClick={onDelete}>Delete</EditLabel>}
          </Options>

          {!current && newRole === undefined && (
            <EditLabel onClick={() => setNewRole('')}>+ Add Role</EditLabel>
          )}
          {!current && newRole !== undefined && (
            <>
              <Input value={newRole} onChange={setNewRole} />
              <EditLabel onClick={handleAddRole}>Add</EditLabel>
            </>
          )}
        </>
      )}
      {active && (
        <>
          <Input value={value} onChange={setValue} />
          <EditLabel onClick={handlePositionSave}>Save</EditLabel>
        </>
      )}

      <RolesContainer>
        {roles.map((r) => (
          <Role
            key={r.label}
            role={r}
            active={current === `${label}.${r.label}`}
            current={current}
            onActive={() => onActive(`${label}.${r.label}`)}
            onSave={(data) => handleRoleSave(r, data)}
            onDelete={() => handleRoleDelete(r)}
          />
        ))}
      </RolesContainer>
    </PositionItem>
  )
}

const AgencyPositions = ({ positions, onChange }: IProps) => {
  const [current, setCurrent] = useState<string>()
  const [newPosition, setNewPosition] = useState<string>()
  const [collapsed, setCollapsed] = useState(true)

  if (!positions) return null

  const handleActive = (newActive) => {
    setNewPosition(undefined)

    if (newActive === current) setCurrent(undefined)
    else setCurrent(newActive)
  }

  const handlePositionSave = (label, newLabel, data) => {
    onChange(
      Object.keys(positions).reduce((acc, k) => {
        if (k !== label) acc[k] = positions[k]
        else acc[newLabel] = data
        return acc
      }, {})
    )
    setCurrent(undefined)
  }

  const handlePositionDelete = (label) => {
    onChange(
      Object.keys(positions).reduce((acc, k) => {
        if (k !== label) acc[k] = positions[k]
        return acc
      }, {})
    )
    setCurrent(undefined)
  }

  const handleAddPosition = () => {
    onChange({ ...positions, [newPosition]: [] })
    setNewPosition(undefined)
  }

  return (
    <Container>
      <Title collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        Roles and Positions
      </Title>
      <List collapsed={collapsed}>
        {!current && newPosition === undefined && (
          <>
            <EditLabel onClick={() => setNewPosition('')}>+ Add Position</EditLabel>
          </>
        )}
        {!current && newPosition !== undefined && (
          <>
            <Input value={newPosition} onChange={setNewPosition} />
            <EditLabel onClick={handleAddPosition}>Add</EditLabel>
          </>
        )}

        {Object.keys(positions).map((label) => (
          <Position
            key={label}
            label={label}
            roles={positions[label]}
            active={current === label}
            current={current}
            onActive={handleActive}
            onSave={({ newLabel, data }) => handlePositionSave(label, newLabel, data)}
            onDelete={() => handlePositionDelete(label)}
          />
        ))}
      </List>
    </Container>
  )
}

export default AgencyPositions
