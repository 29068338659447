import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import SvgClose from 'src/static/close_gray.svg'
import { avaSettingsSelectors, updateAvaSettingsAction } from 'src/redux'
import SvgSettings from 'src/static/components/SvgSettings'
import { Browser, detectBrowser } from 'src/videocall/services/browserDetection'
import Volume from 'src/videocall/components/volume'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import SpeakerVolumeIndicator from './SpeakerVolumeIndicator'
import Setting from './Setting'

const AudioFile = '/static/test_sound.wav'

const ContainerWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: ${({ visible }) => (visible && isMobileOnly ? 'all' : 'none')};
  background: ${({ visible }) =>
    visible && isMobileOnly ? 'rgba(115, 121, 145, 0.3)' : 'transparent'};
  backdrop-filter: ${({ visible }) => (visible && isMobileOnly ? 'blur(10px)' : 'none')};
`

const Container = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 264px;
  pointer-events: all;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.07);
  background: white;
  border-radius: 10px;
  top: 72px;
  right: 0;
  transform: ${({ visible }) =>
    isMobileOnly
      ? `translateY(${visible ? '0' : '110'}%);`
      : `translateX(${visible ? -42 : 264}px);`};
  flex-direction: column;
  justify-content: start;
  transition: all 0.3s ease;

  @media screen and (max-width: 1200px) {
    width: 214px;
  }

  @media screen and (max-width: 900px) {
    width: 184px;
  }

  ${isMobileOnly &&
  css`
    width: calc(100% - 32px) !important;
    left: 16px !important;
    bottom: 16px !important;
    height: fit-content !important;
    top: unset !important;
  `}
`

const HeadContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 12px;
  align-items: center;
  border-bottom: 1px solid rgb(225, 227, 234);

  > svg {
    width: 20px;
    height: 20px;

    @media screen and (max-width: 1200px) {
      height: 16px;
      width: 16px;
    }

    @media screen and (max-width: 900px) {
      height: 12px;
      width: 12px;
    }

    ${isMobileOnly &&
    css`
      height: 20px !important;
      width: 20px !important;
    `}
  }
`

const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-left: 6px;
  color: #737991;

  @media screen and (max-width: 1200px) {
    font-size: 12px;
    line-height: 18px;
  }

  @media screen and (max-width: 900px) {
    font-size: 10px;
    line-height: 12px;
  }

  ${isMobileOnly &&
  css`
    font-size: 16px !important;
    line-height: 24px !important;
  `}
`

const CloseButton = styled.img`
  cursor: pointer;
  margin-left: auto;
  height: 20px;
  width: 20px;
  padding: 3px;

  @media screen and (max-width: 1200px) {
    height: 16px;
    width: 16px;
  }

  @media screen and (max-width: 900px) {
    height: 12px;
    width: 12px;
  }

  ${isMobileOnly &&
  css`
    height: 20px !important;
    width: 20px !important;
  `}
`

const DataContainer = styled.div`
  padding: 36px 12px 14px 12px;

  @media screen and (max-width: 1200px) {
    padding: 28px 12px 14px 12px;
  }

  @media screen and (max-width: 900px) {
    padding: 20px 12px 14px 12px;
  }

  ${isMobileOnly &&
  css`
    padding: 20px 12px 14px 12px !important;
  `}
`

const SpeakerTest = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;

  margin-left: 15px;
  width: 100px;
  @media screen and (max-width: 2500px) {
    margin-left: 12px;
    width: 75px;
  }
  @media screen and (max-width: 2000px) {
    margin-left: 10px;
    width: 60px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 7px;
    width: 50px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 5px;
    width: 33px;
  }

  ${isMobileOnly &&
  css`
    width: auto !important;
    margin-left: 15px !important;
  `}
`

const Button = styled.input`
  background: #f4dcff;
  border: none;
  text-align: center;
  color: #c746ff;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  width: 100%;

  &:focus {
    outline: none;
  }

  border-radius: 18px;
  font-size: 18px;
  height: 45px;
  line-height: 24px;
  @media screen and (max-width: 2500px) {
    border-radius: 15px;
    font-size: 15px;
    height: 37px;
    line-height: 20px;
  }
  @media screen and (max-width: 2000px) {
    border-radius: 12px;
    font-size: 12px;
    height: 30px;
    line-height: 16px;
  }
  @media screen and (max-width: 1200px) {
    border-radius: 9px;
    font-size: 9px;
    height: 22px;
    line-height: 12px;
  }
  @media screen and (max-width: 900px) {
    border-radius: 6px;
    font-size: 6px;
    height: 15px;
    line-height: 8px;
  }

  ${isMobileOnly &&
  css`
    height: 30px !important;
    font-size: 15px !important;
    width: 57px !important;
    border-radius: 10px !important;
  `}
`

const VolumeContainer = styled.div`
  align-items: center;
  background: #f4dcff;
  bottom: -100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  width: 100%;

  border-radius: 18px;
  height: 45px;
  padding: 0 22%;
  @media screen and (max-width: 2500px) {
    border-radius: 15px;
    height: 37px;
  }
  @media screen and (max-width: 2000px) {
    border-radius: 12px;
    height: 30px;
  }
  @media screen and (max-width: 1200px) {
    border-radius: 9px;
    height: 22px;
  }
  @media screen and (max-width: 900px) {
    border-radius: 6px;
    height: 15px;
  }

  ${isMobileOnly &&
  css`
    height: 30px !important;
    border-radius: 10px !important;
    top: 37px;
  `}
`

const SettingsBar = () => {
  const dispatch = useDispatch()
  const browser = detectBrowser()
  const isFirefox = browser === Browser.Firefox
  const isSafari = browser === Browser.Safari
  const avaSettings = useSelector(avaSettingsSelectors.data)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [musicIndicator, setMusicIndicator] = useState<boolean>(false)
  const audio = useRef<any>(new Audio(AudioFile))

  const microphones = devices.filter((d) => d.kind === 'audioinput')
  const speakers = devices.filter((d) => d.kind === 'audiooutput')

  const handleContainerClick = (ev) => {
    ev.stopPropagation()
  }

  const handleClose = () => {
    dispatch(updateAvaSettingsAction({ ...avaSettings, showSettings: false }))
  }

  const handleSwitchMicrophone = (ev) => {
    dispatch(updateAvaSettingsAction({ ...avaSettings, microphoneId: ev.target.value }))
  }

  const handleSwitchSpeaker = (ev) => {
    dispatch(updateAvaSettingsAction({ ...avaSettings, speakerId: ev.target.value }))
  }

  const handleWindowClick = useCallback(() => {
    if (avaSettings.showSettings) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaSettings])

  const testSpeaker = useCallback(() => {
    if (!audio.current.paused) {
      audio.current.pause()
      audio.current.currentTime = 0
    }

    if (audio.current.setSinkId) {
      audio.current.setSinkId(avaSettings.speakerId)
    }
    audio.current.addEventListener('ended', () => {
      setMusicIndicator(false)
    })
    audio.current
      .play()
      .then(() => setMusicIndicator(true))
      // eslint-disable-next-line no-console
      .catch((error) => console.error('playback prevented', error))
  }, [avaSettings.speakerId])

  useEffect(() => {
    window.onclick = handleWindowClick
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaSettings])

  useEffect(() => {
    if (avaSettings.showSettings) {
      const checkInterval = setInterval(() => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          clearInterval(checkInterval)
          navigator.mediaDevices.enumerateDevices().then((result) => setDevices(result))
          stream.getTracks().forEach((tr) => tr.stop())
        })
      }, 1000)
    }
  }, [avaSettings.showSettings])

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((result) => setDevices(result))
    navigator.mediaDevices.ondevicechange = (ev) => {
      ;(ev.target as any).enumerateDevices().then((result) => setDevices(result))
    }
  }, [])

  return (
    <ContainerWrapper visible={avaSettings.showSettings}>
      <Container visible={avaSettings.showSettings} onClick={handleContainerClick}>
        <HeadContainer>
          <SvgSettings fill="#737991" />
          <Title>Settings</Title>
          <CloseButton onClick={handleClose} src={SvgClose} />
        </HeadContainer>
        <DataContainer>
          <Setting
            isCompact={false}
            items={microphones}
            label="Microphone"
            value={avaSettings.microphoneId}
            onValueChange={handleSwitchMicrophone}
          >
            <SpeakerVolumeIndicator
              active={avaSettings.showSettings}
              deviceId={avaSettings.microphoneId}
            />
          </Setting>
          {!isFirefox && !isSafari && (
            <Setting
              isCompact
              items={speakers}
              label="Speakers"
              value={avaSettings.speakerId}
              onValueChange={handleSwitchSpeaker}
            >
              <SpeakerTest>
                <Button type="button" value="Test" onClick={testSpeaker} />
                {musicIndicator && (
                  <VolumeContainer>
                    <Volume.AudioVolumeIndicator
                      element={audio.current}
                      track={undefined}
                    />
                  </VolumeContainer>
                )}
              </SpeakerTest>
            </Setting>
          )}
        </DataContainer>
      </Container>
    </ContainerWrapper>
  )
}

export default SettingsBar
