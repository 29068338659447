import { put, takeEvery, call } from 'typed-redux-saga'

import { getConversation } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { conversationAction } from '../data/conversation'

export interface IGetConversationAction {
  interviewToken: string
}

const {
  action: getConversationAction,
  actionFailure: getConversationActionFailure,
  actionSuccess: getConversationActionSuccess,
  actionSuccessClear: getConversationActionSuccessClear,
  actionFailureClear: getConversationActionFailureClear,
  actionType: getConversationActionType,
  actionTypeFailure: getConversationActionFailureType,
  actionTypeSuccess: getConversationActionSuccessType,
  reducer: getConversationReducer,
  selectors: getConversationSelectors,
} = reduxRequestFactory<IGetConversationAction>('getConversation', 'requests')

export {
  getConversationAction,
  getConversationActionFailure,
  getConversationActionSuccess,
  getConversationActionSuccessClear,
  getConversationActionFailureClear,
  getConversationActionType,
  getConversationActionFailureType,
  getConversationActionSuccessType,
  getConversationReducer,
  getConversationSelectors,
}

function* getConversationSaga(action: ISagaAction<IGetConversationAction>) {
  try {
    const { interviewToken } = action.payload
    const conversation = yield* call(getConversation, interviewToken)
    yield put(getConversationActionSuccess())
    yield put(conversationAction(conversation))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      getConversationActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetConversation() {
  yield takeEvery(getConversationActionType, getConversationSaga)
}
