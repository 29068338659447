import React, { useState } from 'react'
import styled from '@emotion/styled'
import AvatarEditor from 'react-avatar-editor'
import closeIcon from 'src/static/close.svg'
import { Button } from '../../components'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`
const BackDoor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
`

const ModalWindow = styled.div`
  position: relative;
  z-index: 2;
  width: 600px;
  background: #fff;
  padding: 40px 20px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AvatarWrapper = styled.div`
  border: 1px solid #666;
  margin-bottom: 15px;
  border-radius: 6px;
  overflow: hidden;
`

const ButtonStyled = styled(Button)`
  && {
    margin-top: 20px;
  }
`

const StyledLabel = styled.label`
  cursor: pointer;
  border: 1px solid #b202ff;
  border-radius: 4px;
  font-size: 12px;
  padding: 10px 30px;
  margin-bottom: 15px;

  input {
    position: fixed;
    top: -5000%;
    left: -5000%;
  }
`

const CloseButton = styled.label`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  > img {
    display: block;
    width: 20px;
    height: 20px;
  }
`

interface IPhotoEditor {
  saveImage: (Blob) => void
  onClose?: () => void
}

const PhotoEditor = ({ saveImage, onClose }: IPhotoEditor) => {
  const [scale, setScale] = useState(1)
  const [applicantPhoto, setApplicantPhoto] = useState()
  const [image, setImage] = useState<any>()

  const handleImage = (e) => {
    setApplicantPhoto(e.target.files[0])
  }

  const handleScaleImage = (e) => {
    setScale(e.target.value)
  }

  const handleIm = (img) => setImage(img)

  const handleSave = () => {
    if (image) {
      saveImage(image.getImageScaledToCanvas())
    }
  }

  return (
    <ModalWrapper>
      <ModalWindow>
        <CloseButton onClick={onClose}>
          <img src={closeIcon} alt="close" />
        </CloseButton>
        <AvatarWrapper>
          <AvatarEditor
            style={{ display: 'block' }}
            ref={handleIm}
            image={applicantPhoto}
            width={300}
            height={300}
            border={0}
            borderRadius={150}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
          />
        </AvatarWrapper>
        <StyledLabel>
          Select photo
          <input
            name="avatar"
            type="file"
            onChange={handleImage}
            accept=".jpg, .jpeg, .png"
          />
        </StyledLabel>
        <input
          type="range"
          min="0.5"
          max="3"
          step="0.1"
          value={scale}
          onChange={handleScaleImage}
        />
        <ButtonStyled disabled={!applicantPhoto} title="Save" onClick={handleSave} />
      </ModalWindow>
      <BackDoor />
    </ModalWrapper>
  )
}

PhotoEditor.defaultProps = {
  onClose: () => {},
}

export default PhotoEditor
