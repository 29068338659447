import { UID } from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk'
import * as moment from 'moment'
import { useEffect } from 'react'
import useStateRef from 'react-usestateref'

import { getRTMAgoraToken } from '../../api'
import { IChatItem } from '../components/sidebars/Chat'
import makeRandom from './random'

const CHANNEL = 'test'

const consoleStyles = ['%c%s', 'font-weight: bold;', '[Wizco Chat]']

const useChat = (username: string, channelId = CHANNEL) => {
  const [isOpen, setOpen, isOpenRef] = useStateRef<boolean>(false)
  const [hasBadge, setBadge] = useStateRef<boolean>(false)
  const [hasNotification, setNotification, hasNotificationRef] = useStateRef<number>(
    undefined
  )

  const [joinedToChat, setJoinedToChat, joinedToChatRef] = useStateRef(false)
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    joinedToChatFailure,
    setJoinedToChatFailure,
    joinedToChatFailureRef,
  ] = useStateRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [leftChat, setLeftChat, leftChatRef] = useStateRef(false)

  const [uid, setUid, uidRef] = useStateRef<UID>()
  const [token, setToken, tokenRef] = useStateRef<string>()
  const [client, setClient, clientRef] = useStateRef<
    ReturnType<typeof AgoraRTM.createInstance>
  >()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channel, setChannel, channelRef] = useStateRef<
    ReturnType<typeof client.createChannel>
  >()

  const [messages, setMessages, messagesRef] = useStateRef([])
  const [lastMessage, setLastMessage] = useStateRef(null)

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.toString())
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.toString())

  const generateUid = () => {
    const saltLenght = 5
    const limit = 64
    let rtmUid = `${username}_${makeRandom(saltLenght)}`
    if (username.length > limit - saltLenght - 1) {
      rtmUid = `${username.slice(0, limit - saltLenght - 1)}_${makeRandom(5)}`
    }
    setUid(rtmUid)
  }

  const generateToken = async () => {
    const data = await getRTMAgoraToken(uidRef.current as string)
    setToken(data.token)
  }

  function setAuthorVisibility(chatItem: IChatItem): IChatItem {
    const newMessage = chatItem
    if (messagesRef.current.length === 0) return newMessage
    const lastIndex = messagesRef.current.length - 1
    const prevMessage = messagesRef.current[lastIndex]
    if (prevMessage.author === newMessage.author) {
      const timeDiffSec =
        (newMessage.timestamp.getTime() - prevMessage.timestamp.getTime()) / 1000
      if (timeDiffSec < 60) {
        newMessage.showAuthor = false
      }
    }
    return newMessage
  }

  const handleConnectionStateChanged = (state, reason) => {
    log(`Connection state changed to ${state} [${reason}]`)
    switch (state) {
      case 'CONNECTED':
        channelRef.current.join().then(() => {
          setJoinedToChat(true)
        })
        break
      case 'ABORTED':
        clientRef.current.logout()
        // eslint-disable-next-line no-case-declarations
        const timer = setInterval(() => {
          clientRef.current
            .login({
              uid: uidRef.current as string,
              token: tokenRef.current,
            })
            .then(() => {
              clearInterval(timer)
            })
            .catch((err) => {
              log(`Relogin failed: ${err}`)
            })
        }, 10000)
        break
      case 'CONNECTING':
      case 'DISCONNECTED':
        break
      default:
        try {
          channelRef.current.leave().then(() => {
            setJoinedToChat(false)
          })
        } catch (e) {
          log(e as string)
        }
    }
  }

  const handleTokenExpired = async () => {
    await generateToken()
    clientRef.current.renewToken(tokenRef.current)
  }

  const handleChannelMessage = (message, memberId) => {
    const isFile = message.messageType === 'FILE'
    let newMessage: IChatItem = {
      text: isFile ? message.fileName : message.text,
      author: memberId,
      timestamp: new Date(),
      isMy: false,
      showAuthor: true,
      mediaId: isFile ? message.mediaId : undefined,
    }
    newMessage = setAuthorVisibility(newMessage)
    setMessages([...messagesRef.current, newMessage])
    setLastMessage(newMessage)
    if (!isOpenRef.current) {
      setBadge(true)
      setNotification(Date.now())
    }
  }

  const joinChat = async () => {
    do {
      try {
        generateUid()
        // eslint-disable-next-line no-await-in-loop
        await generateToken()
      } catch (e) {
        error(`Failed to generate token for chat: ${JSON.stringify(e)}`)
        // setJoinedToChatFailure(true)
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 5000))
        // eslint-disable-next-line no-continue
        continue
      }

      setClient(AgoraRTM.createInstance(process.env.REACT_APP_AGORA_APP_ID))
      setChannel(clientRef.current.createChannel(`${channelId}_chat`))
      clientRef.current.on('ConnectionStateChanged', handleConnectionStateChanged)
      clientRef.current.on('TokenExpired', handleTokenExpired)

      try {
        // eslint-disable-next-line no-await-in-loop
        await clientRef.current.login({
          uid: uidRef.current as string,
          token: tokenRef.current,
        })
        channelRef.current.on('ChannelMessage', handleChannelMessage)
        setJoinedToChatFailure(false)
        log('Joined successfully')
      } catch (e) {
        error(`Failed to log into chat: ${JSON.stringify(e)}`)
        setJoinedToChatFailure(true)
      }
    } while (joinedToChatFailureRef.current && !leftChatRef.current)
  }

  const leaveChat = async () => {
    await clientRef.current?.logout()
    setLeftChat(true)
    log('Left successfully')
  }

  const sendChatMessage = async (rawText: string) => {
    if (!joinedToChatRef.current) {
      log('Message skipped. Not joined')
      return
    }

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
    const text = rawText.replace(urlRegex, (url) => {
      let hyperlink = url
      if (!hyperlink.match('^https?://')) {
        hyperlink = `http://${hyperlink}`
      }
      return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`
    })

    const message = { text }
    channelRef.current
      .sendMessage(message)
      .then(() => {
        log(`Message sent ${message.text}`)
        let newMessage: IChatItem = {
          text: message.text,
          author: username,
          timestamp: new Date(),
          isMy: true,
          showAuthor: true,
          mediaId: undefined,
        }
        newMessage = setAuthorVisibility(newMessage)
        setMessages([...messagesRef.current, newMessage])
        setLastMessage(newMessage)
        if (!isOpenRef.current) {
          setBadge(true)
          setNotification(Date.now())
        }
      })
      .catch((err) => {
        error(`Message sent error: ${err}`)
      })
  }

  // const sendChatFile = async (file: File) => {
  //   if (!joinedToChatRef.current) {
  //     log('File skipped. Not joined')
  //     return
  //   }

  //   const mediaMessage = await clientRef.current.createMediaMessageByUploading(file, {
  //     messageType: 'FILE',
  //     fileName: file.name,
  //     description: '',
  //   })

  //   channelRef.current
  //     .sendMessage(mediaMessage)
  //     .then(() => {
  //       log(`File sent ${file.name}`)
  //       let newMessage: IChatItem = {
  //         text: file.name,
  //         author: username,
  //         timestamp: new Date(),
  //         isMy: true,
  //         showAuthor: true,
  //         mediaId: mediaMessage.mediaId,
  //       }
  //       newMessage = setAuthorVisibility(newMessage)
  //       setMessages([...messagesRef.current, newMessage])
  //       setLastMessage(newMessage)
  //       if (!isOpenRef.current) {
  //         setBadge(true)
  //         setNotification(Date.now())
  //       }
  //     })
  //     .catch((err) => {
  //       error('File send error', err)
  //     })
  // }

  const hideChatNotification = () => {
    setNotification(undefined)
  }

  const setChatOpen = (state: boolean) => {
    setOpen(state)
    setBadge(false)
    hideChatNotification()
  }

  useEffect(() => {
    if (hasNotification) {
      setTimeout(() => {
        const duration = moment.default().diff(hasNotificationRef.current, 's')
        if (duration > 12) setNotification(undefined)
      }, 15000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNotification])

  return {
    chatBadge: hasBadge,
    chatJoined: joinedToChat,
    chatLastMessage: lastMessage,
    chatMessages: messages,
    chatNotification: !!hasNotification,
    chatOpen: isOpen,
    chatToken: token,
    chatUid: uid,
    hideChatNotification,
    joinChat,
    leaveChat,
    sendChatMessage,
    // sendChatFile,
    setChatOpen,
  }
}

export default useChat
