import LogRocket from 'logrocket'

const useLogrocket = () => {
  const canRunLogrocket = !window.location.hostname.includes('localhost')

  const log = (...params: any[]) => {
    // eslint-disable-next-line no-console
    console.log('[LogRocket]', params)
  }

  const initLogrocket = (appId: string) => {
    if (!canRunLogrocket) {
      log('skip init')
      return
    }
    log('init')
    LogRocket.init(appId)
  }

  const setLogrocket = (id: string, page: string, restart: boolean = true) => {
    if (!canRunLogrocket) {
      log('skip setting')
      return
    }

    log(id, page)
    if (restart) {
      LogRocket.startNewSession()
    }
    LogRocket.identify(`[${id}] ${page}`, {
      sessionId: id,
    })
  }

  return { setLogrocket, initLogrocket }
}

export default useLogrocket
