import React, { useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import OnImg from '../../static/expert-toggle-on.png'
import OffImg from '../../static/expert-toggle-off.png'
import InfoIcon from '../../static/info-icon.png'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: ${isMobileOnly ? 'row-reverse' : 'row'};
  justify-content: ${isMobileOnly ? 'flex-end' : 'center'};
  width: ${isMobileOnly ? '100%' : 'auto'};
  padding: ${isMobileOnly ? '12px 0' : '0'};
`

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #b202ff;
  margin: 0 10px;
`

const Icon = styled.div`
  background: url(${InfoIcon});
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
`

const TipContainer = styled.div<{ small?: boolean }>`
  position: absolute;
  top: ${(props) => (props.small ? '-55' : '-145')}px;
  top: ${(props) => {
    let padding = props.small ? -55 : -145
    if (isMobileOnly) {
      padding += 10
    }
    return padding
  }}px;
  left: ${isMobileOnly ? 0 : -32}px;
  width: ${isMobileOnly ? '330px' : 'auto'};
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #3c424e;
  background: white;
  padding: 16px;
  box-shadow: 0px 0px 7px -3px grey;
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.small ? '50' : '140')}px;
  justify-content: ${(props) => (props.small ? 'center' : 'space-between')};

  > p {
    margin: 0;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: ${isMobileOnly ? 281 : 32}px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
`

const Toggle = styled.div<{ checked: boolean }>`
  height: 32px;
  width: 52px;
  cursor: pointer;
  flex-direction: column;
  background: url(${(props) => (props.checked ? OnImg : OffImg)});
  background-size: 100% 100%;
`

interface IProps {
  checked: boolean
  label: string
  onChange: (checked: boolean) => void
}

function CheckBox({ checked, label, onChange }: IProps) {
  const [isTipVisible, setIsTipVisible] = useState(false)

  return (
    <Container>
      <Icon
        onMouseEnter={() => setIsTipVisible(true)}
        onMouseLeave={() => setIsTipVisible(false)}
        onClick={() => setIsTipVisible(true)}
      />
      <Label>{label}</Label>
      <Toggle onClick={() => onChange(!checked)} checked={checked} />
      {isTipVisible && (
        <TipContainer small={!checked}>
          {checked && (
            <>
              <p>
                Currently showing times inside the applicant&apos;s preferred timeframe.
              </p>
              <p>
                Can&apos;t provide times in the applicant&apos;s preferred timeframe? Feel
                free to include additional times by toggling this switch.
              </p>
            </>
          )}
          {!checked && (
            <p>
              Currently showing times inside and outside the applicant&apos;s preferred
              timeframe
            </p>
          )}
        </TipContainer>
      )}
    </Container>
  )
}

export default CheckBox
