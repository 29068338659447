import styled from '@emotion/styled'
import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useDispatch } from 'react-redux'
import LandingFooter from 'src/components/LandingFooter'
import { subscribeAction } from 'src/redux/requests/subscribe'
import { isMobileOnly } from 'react-device-detect'
import LandingHeader from 'src/components/LandingHeader'
import LandingTopBlock from '../components/LandingTopBlock'
import LandingTrusted from '../components/LandingTrusted'
import LandingPreparation from '../components/LandingPreparation'
import LandingInsightsResult from '../components/LandingInsightsResult'
import LandingNumbers from '../components/LandingNumbers'
import LandingReviews from '../components/LandingReviews'
import LandingMeetUs from '../components/LandingMeetUs'
import LandingSubscribe from '../components/LandingSubscribe'
import LandingDemoPopup from '../components/LandingDemoPopup'

const Container = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  background: #0e0224;
  min-width: 600px;

  ${isMobileOnly &&
  css`
    min-width: 100vw;
  `}
`

const HomeContainer = () => {
  const dispatch = useDispatch()

  const [showDemoPopup, setShowDemoPopup] = useState(false)

  const handleRequestDemo = () => {
    setShowDemoPopup(true)
  }

  const handleBookDemo = (email: string) => {
    dispatch(subscribeAction({ email, source: 'home' }))
  }

  return (
    <Container id="landing-root">
      <LandingHeader
        onStart={handleRequestDemo}
        showLoginButton
        startButtonText="Request a demo"
      />
      <LandingTopBlock onStart={handleRequestDemo} />
      <LandingTrusted />
      <LandingPreparation />
      <LandingMeetUs onStart={handleRequestDemo} />
      <LandingInsightsResult />
      <LandingNumbers />
      <LandingReviews />
      <LandingSubscribe />
      <LandingFooter onClick={handleBookDemo} />
      {showDemoPopup && <LandingDemoPopup onClose={() => setShowDemoPopup(false)} />}
    </Container>
  )
}

export default HomeContainer
