import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import SvgExpert from 'src/static/components/SvgExpert'
import SvgStudent from 'src/static/components/SvgStudent'
import SvgCvFile from 'src/static/components/SvgCvFile'

import SvgCoaching from 'src/static/components/SvgCoaching'
import * as interviewSelectors from '../../../interview/selectors'
import { HyperLink, Text, TextBold } from '../../../components'
import { Text as MobileText } from '../MobileText'
import SessionDetails from './SessionDetails'

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e3ea;
  display: flex;
  justify-content: flex-start;

  height: 105px;
  padding: 0 39px;
  @media screen and (max-width: 2500px) {
    height: 87px;
    padding: 0 32px;
  }
  @media screen and (max-width: 2000px) {
    height: 70px;
    padding: 0 26px;
  }
  @media screen and (max-width: 1200px) {
    height: 52px;
    padding: 0 19px;
  }
  @media screen and (max-width: 900px) {
    height: 35px;
    padding: 0 13px;
  }

  > svg {
    height: 20px;
    width: 20px;
    @media screen and (max-width: 2500px) {
      height: 18px;
      width: 18px;
    }
    @media screen and (max-width: 2000px) {
      height: 14px;
      width: 14px;
    }
    @media screen and (max-width: 1200px) {
      height: 11px;
      width: 11px;
    }
    @media screen and (max-width: 900px) {
      height: 7px;
      width: 7px;
    }
  }
`

const HeaderTitle = styled(Text)`
  display: flex;
  align-items: center;
  color: ${({ theme }: { theme: any }) => theme.sidebarTitleColor};

  font-size: 18px;
  line-height: 36px;
  margin-left: 15px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
    margin-left: 13px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
    margin-left: 10px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
    margin-left: 8px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
    margin-left: 5px;
  }
`

const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;

  padding: 60px 37px;
  @media screen and (max-width: 2500px) {
    padding: 55px 31px;
  }
  @media screen and (max-width: 2000px) {
    padding: 40px 25px;
  }
  @media screen and (max-width: 1200px) {
    padding: 30px 18px;
  }
  @media screen and (max-width: 900px) {
    padding: 20px 12px;
  }
`

const PhotoContainer = styled.div`
  width: 100px;
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  margin-bottom: 20px;
  border-radius: 100px;
  overflow: hidden;
  flex: 0;
`

const Photo = styled.img`
  width: auto;
  height: 100%;
`

const Name = styled(TextBold)`
  text-align: center;

  font-size: 18px;
  line-height: 36px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
  }
`

const CurrentPosition = styled(Text)`
  text-align: center;

  font-size: 18px;
  line-height: 36px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
  }
`

const Info = styled.div`
  width: 100%;
`

const Subtitle = styled(Text)`
  color: rgba(115, 121, 145, 0.8);

  font-size: 18px;
  line-height: 36px;
  margin-top: 38px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
    margin-top: 31px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
    margin-top: 25px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
    margin-top: 19px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
    margin-top: 12px;
  }
`

const ExperienceContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ExperienceText = styled(Text)`
  font-size: 18px;
  line-height: 36px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 24px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 18px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 12px;
  }
`

const MobileContainer = styled.div`
  align-items: center;
  background: ${({ theme }: { theme: any }) => theme.sidebarBackgroundColor};
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  padding: 24px;

  @media (orientation: landscape) {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`

const MobileHeader = styled.div`
  align-items: center;
  border-bottom: 0.5px solid #324fa5;
  display: flex;
  padding: 4px 8px;
  width: 100%;

  @media (orientation: landscape) {
    background: #1d0e3b;
    border: none;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 57px;
    padding: 29px 24px 4px 24px;
  }
`

const MobileHeaderTitle = styled(MobileText)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`

const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (orientation: landscape) {
    height: calc(100% - 57px);
    overflow: scroll;
    padding: 0 44px;
  }
`

const MobileInfoContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 12px 0;
`

const MobilePhotoContainer = styled.div`
  background: grey;
  border-radius: 38px;
  display: flex;
  height: 76px;
  margin-right: 20px;
  width: 76px;
`

const MobilePhoto = styled.img`
  height: 100%;
  width: auto;
`

const MobileInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-evenly;
  width: calc(100% - 96px);
`

const MobileName = styled(MobileText)`
  font-weight: 500;
`

const MobileInfoText = styled(MobileText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`

const MobilePositionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MobileExperienceEducation = styled.div`
  display: flex;
  flex-direction: column;
`

const MobileExperienceContainer = styled.div`
  margin-bottom: 20px;
`

const MobileExperienceLabel = styled(MobileText)`
  color: #737991;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 8px;
`

const CvContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

const CvLink = styled(HyperLink)`
  font-weight: 400;
  line-height: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 10px;
  flex: 1;
`

const TabsContainer = styled.div`
  width: 100%;
  height: 34px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0px 4px 9px rgba(115, 121, 145, 0.14);
  display: flex;
`

const Tab = styled.div<{ active?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  color: ${({ active }) => (active ? '#b202ff' : '#1D0E3B')};
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
`

const TapSeparator = styled.div`
  width: 1px;
  height: 100%;
  background: #e1e3ea;
`

interface IDetailsSidebar {
  isExpert: boolean
  theme: any
}

export default function DetailsSidebar({ isExpert, theme }: IDetailsSidebar) {
  const {
    interview: { desired_position, cv_file },
    peer: profile,
  } = useSelector(interviewSelectors.interview)
  const [selectedTab, setSelectedTab] = useState<'Profile' | 'Details'>('Details')
  const filledEducations = profile.educations.filter(Boolean)
  const filledExperiences = profile.experiences.filter(Boolean)
  const name = `${profile.first_name} ${profile.last_name}`
  const title = `${isExpert ? 'Coaching info' : 'Expert profile'}`

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Header>
            <SvgCoaching fill="#2f2f2f" />
            <HeaderTitle>{title}</HeaderTitle>
          </Header>
          {isExpert && (
            <TabsContainer>
              <Tab
                active={selectedTab === 'Profile'}
                onClick={() => setSelectedTab('Profile')}
              >
                Profile
              </Tab>
              <TapSeparator />
              <Tab
                active={selectedTab === 'Details'}
                onClick={() => setSelectedTab('Details')}
              >
                Session details
              </Tab>
            </TabsContainer>
          )}
          {(!isExpert || selectedTab === 'Profile') && (
            <Body>
              {profile.logo.url && (
                <PhotoContainer>
                  <Photo src={profile.logo.url} />
                </PhotoContainer>
              )}
              <Name>
                {profile.first_name} {profile.last_name}
              </Name>
              <CurrentPosition>
                {profile.current_position} {!isExpert && `at ${profile.company}`}
              </CurrentPosition>
              <Info>
                <ExperienceContainer>
                  {filledExperiences.length > 0 && (
                    <div>
                      <Subtitle>Experience</Subtitle>
                      {filledExperiences.map((exp) => (
                        <ExperienceText key={exp}>{exp}</ExperienceText>
                      ))}
                    </div>
                  )}
                  {filledEducations.length > 0 && (
                    <div>
                      <Subtitle>Education</Subtitle>
                      {filledEducations.map((edu) => (
                        <ExperienceText key={edu}>{edu}</ExperienceText>
                      ))}
                    </div>
                  )}
                  {cv_file && isExpert && (
                    <div>
                      <Subtitle>Resume</Subtitle>
                      <CvContainer>
                        <SvgCvFile />
                        <CvLink href={cv_file.file.url} target="_blank">
                          {`${profile.first_name}'s resume`}
                        </CvLink>
                      </CvContainer>
                    </div>
                  )}
                </ExperienceContainer>
              </Info>
            </Body>
          )}
          {isExpert && selectedTab === 'Details' && <SessionDetails />}
        </Container>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileHeader>
            {isExpert ? (
              <SvgStudent fill={theme.textColor} />
            ) : (
              <SvgExpert fill={theme.textColor} />
            )}
            <MobileHeaderTitle>{title}</MobileHeaderTitle>
          </MobileHeader>
          <MobileContent>
            <MobileInfoContainer>
              {(true || profile.logo.url) && (
                <MobilePhotoContainer>
                  <MobilePhoto src={profile.logo.url} />
                </MobilePhotoContainer>
              )}
              <MobileInfo>
                <MobileName>{name}</MobileName>
                {isExpert && <MobileInfoText>{desired_position}</MobileInfoText>}
                <MobilePositionContainer>
                  {profile.current_position && (
                    <MobileInfoText>{profile.current_position}</MobileInfoText>
                  )}
                </MobilePositionContainer>
              </MobileInfo>
            </MobileInfoContainer>
            <MobileExperienceEducation>
              {filledExperiences.length > 0 && (
                <MobileExperienceContainer>
                  <MobileExperienceLabel>Experience</MobileExperienceLabel>
                  {filledExperiences.map((exp) => (
                    <MobileInfoText key={exp}>{exp}</MobileInfoText>
                  ))}
                </MobileExperienceContainer>
              )}
              {filledEducations.length > 0 && (
                <MobileExperienceContainer>
                  <MobileExperienceLabel>Education</MobileExperienceLabel>
                  {filledEducations.map((edu) => (
                    <MobileInfoText key={edu}>{edu}</MobileInfoText>
                  ))}
                </MobileExperienceContainer>
              )}
            </MobileExperienceEducation>
          </MobileContent>
        </MobileContainer>
      )}
    </>
  )
}
