import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div`
  height: 12px;
  flex: 1;
  background: #e1e3ea;
  border-radius: 6px;
  z-index: 2;

  ${!isMobileOnly &&
  css`
    border-radius: 9px;
    height: 18px;
    @media screen and (max-width: 2500px) {
      border-radius: 8px;
      height: 16px;
    }
    @media screen and (max-width: 2000px) {
      border-radius: 6px;
      height: 12px;
    }
    @media screen and (max-width: 1200px) {
      border-radius: 4px;
      height: 8px;
    }
    @media screen and (max-width: 900px) {
      border-radius: 3px;
      height: 6px;
    }
  `}
`

const Progress = styled.div<{ value: number; color: string }>`
  height: 100%;
  border-radius: 6px;
  background: ${(props) => props.color};
  width: ${(props) => `calc(100% * ${props.value})`};

  ${!isMobileOnly &&
  css`
    border-radius: 9px;
    @media screen and (max-width: 2500px) {
      border-radius: 8px;
    }
    @media screen and (max-width: 2000px) {
      border-radius: 6px;
    }
    @media screen and (max-width: 1200px) {
      border-radius: 4px;
    }
    @media screen and (max-width: 900px) {
      border-radius: 3px;
    }
  `}
`

interface IProps {
  value: number
  color?: string
}

export default function ProgressView({ value, color }: IProps) {
  return (
    <Container>
      <Progress value={value} color={color} />
    </Container>
  )
}

ProgressView.defaultProps = {
  color: '#6938aa',
}
