import { useDispatch, useSelector } from 'react-redux'
import useStateRef from 'react-usestateref'

import {
  avaCompleteInterviewAction,
  avaInterviewSelectors,
  avaQuestionSelectors,
  avaSaveAnswerAction,
  updateAvaQuestionAction,
  updateAvaStepAction,
} from '../../redux'
import { AvaStep } from '../models'

export enum Warnings {
  AnswerSize = 1,
  NoAnswers,
  FewAnswers,
  HasAnswer,
}

export const MinCharCount = 250
const RequiredAnswers = 3

const useInterview = (interviewToken: string) => {
  const dispatch = useDispatch()
  const interview = useSelector(avaInterviewSelectors.data)
  const question = useSelector(avaQuestionSelectors.data)

  const [warning, setWarning, warningRef] = useStateRef<Warnings>(undefined)
  const [dismissedWarnings, setDismissedWarnings] = useStateRef<Warnings[]>([])

  const questions = (interview?.questions || [])
    .map((q) => [q, ...(q.follow_up || [])])
    .flat()
  const questionIndex = questions.findIndex((q) => q.id === question?.id)
  const questionsAnswered = (answer: string) =>
    questions.filter((q) => !!q.answer?.text).length +
    (answer?.length >= MinCharCount ? 1 : 0)
  const needAnswers = (answer: string) => RequiredAnswers - questionsAnswered(answer)

  const checkAnswers = (answer?: string, report?: boolean) => {
    const answered = questionsAnswered(answer)
    if (
      !dismissedWarnings.includes(Warnings.AnswerSize) &&
      !report &&
      !question.answer?.text &&
      answer &&
      answer.length < MinCharCount
    ) {
      setWarning(Warnings.AnswerSize)
    } else if (
      !dismissedWarnings.includes(Warnings.NoAnswers) &&
      (questionIndex === 4 || report) &&
      answered === 0
    ) {
      setWarning(Warnings.NoAnswers)
    } else if (
      !dismissedWarnings.includes(Warnings.FewAnswers) &&
      (questionIndex === 4 || report) &&
      answered !== 0 &&
      answered < RequiredAnswers
    ) {
      setWarning(Warnings.FewAnswers)
    }
  }

  const clearWarning = (warn: Warnings) => {
    if (warning === warn) setWarning(undefined)
    setDismissedWarnings(dismissedWarnings.filter((w) => w !== warn))
  }

  const clearWarnings = () => {
    setWarning(undefined)
    setDismissedWarnings([])
  }

  const dismissWarning = (warn: Warnings) => {
    if (warning === warn) setWarning(undefined)
    if (!dismissedWarnings.includes(warn))
      setDismissedWarnings([...dismissedWarnings, warn])
  }

  const goBack = () => {
    if (!question) return

    const backIndex = questionIndex - 1
    if (backIndex < 0) return
    clearWarnings()

    dispatch(updateAvaQuestionAction(questions[backIndex]))
  }

  const goReport = () => {
    clearWarnings()
    dispatch(avaCompleteInterviewAction({ interviewToken }))
    dispatch(updateAvaStepAction(AvaStep.Report))
  }

  const goNext = () => {
    if (!question) return
    clearWarnings()

    const nextIndex = questionIndex + 1
    if (nextIndex < questions.length) {
      dispatch(updateAvaQuestionAction(questions[nextIndex]))
    } else goReport()
  }

  const goNextUnanswered = () => {
    if (questions.length) {
      clearWarnings()

      if (!question)
        dispatch(updateAvaQuestionAction(questions.find((q) => !q.answer?.text)))
      else {
        const unanswered = [
          ...questions.slice(questionIndex + 1, questions.length),
          ...questions.slice(0, questionIndex),
        ].find((q) => !q.answer?.text)
        dispatch(updateAvaQuestionAction(unanswered))
      }
    }
  }

  const saveAnswer = (answer: string) => {
    dispatch(
      avaSaveAnswerAction({
        interviewToken,
        questionId: question.id,
        answer,
      })
    )
  }

  return {
    canGoBack: !!question && !!questionIndex,
    checkAnswers,
    clearWarning,
    dismissWarning,
    goBack,
    goNext,
    goNextUnanswered,
    goReport,
    needAnswers,
    question,
    questions,
    questionCount: questions.length,
    questionIndex,
    saveAnswer,
    setWarning,
    warningRef,
  }
}

export default useInterview
