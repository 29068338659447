import React from 'react'
import styled from '@emotion/styled'
import Loader from 'react-loader-spinner'

export const HiddenText = styled.p`
  display: none;
`

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;
`

const LoaderComponent = () => {
  return (
    <LoaderWrapper>
      <Loader type="Bars" color="#b202ff" height={100} width={100} />
      <HiddenText>Find the perfect match</HiddenText>
    </LoaderWrapper>
  )
}

export default LoaderComponent
