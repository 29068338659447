import { Footer, FooterSeparator, FooterBlocker, FooterMobile } from './Footer'
import {
  Header,
  HeaderControls,
  HeaderDivider,
  HeaderMobile,
  HeaderSession,
  HeaderSettings,
} from './Header'
import { Main, MainContent, MainMobile } from './Main'
import { MainVideo, MobileMainVideo } from './MainVideo'
import { Page, PageMobile } from './Page'
import { DrawerMobile, Sidebar } from './Sidebar'

export default {
  DrawerMobile,
  Footer,
  FooterSeparator,
  FooterBlocker,
  FooterMobile,
  Header,
  HeaderControls,
  HeaderDivider,
  HeaderMobile,
  HeaderSession,
  HeaderSettings,
  Main,
  MainContent,
  MainMobile,
  MainVideo,
  MobileMainVideo,
  Page,
  PageMobile,
  Sidebar,
}
