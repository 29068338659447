import { IApplicantNote } from '../../api'
import { reduxDataFactory } from '../../utils/redux'

const {
  action: updateApplicantNotesAction,
  actionType: updateApplicantNotesActionType,
  reducer: applicantNotesDataReducer,
  selectors: applicantNotesSelectors,
} = reduxDataFactory<IApplicantNote[]>('applicantNotes', undefined, [])

export {
  updateApplicantNotesAction,
  updateApplicantNotesActionType,
  applicantNotesDataReducer,
  applicantNotesSelectors,
}
