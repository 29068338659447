import React, { useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useSelector } from 'react-redux'
import useLogrocket from 'src/utils/useLogrocket'
import { IExpertProfile } from '../../api'
import AvailabilityApplicantInfo from '../components/AvailabilityApplicantInfo'
import AvailabilityFooter from '../components/AvailabilityFooter'
import * as interviewSelectors from '../selectors'
import PreStartHeader from '../components/PreStartHeader'
import PreStartInfoComponent from '../components/PreStartInfoComponent'
import PreStartHeaderMobile from '../components/PreStartHeaderMobile'
import PreStartApplicantInfoMobile from '../components/PreStartApplicantInfoMobile'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${isMobileOnly ? '#B202FF' : 'white'};
  height: auto;
  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      height: 100vh;
    }
  `}
`

const DataContainer = styled.div`
  margin: 0 53px 0 86px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;

  > div:nth-of-type(1) {
    width: 37%;
  }

  > div:nth-of-type(2) {
    width: 60%;
  }
`

const DataContainerMobile = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  z-index: 10;

  @media (orientation: landscape) {
    margin: 0 16px;
  }
`

const MobileContainer = styled.div`
  background: #b202ff;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  overflow-y: auto;

  @media (orientation: landscape) {
    height: 100%;
  }
`

const MobileHeaderElement1 = styled.div<{ margin?: number }>`
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  width: 214px;
  height: 200px;
  background: #6938aa;
  border-radius: 0 0 100px 0;
  margin-top: ${(props) => (props.margin ? props.margin : '0')}px;
`

const MobileHeaderElement2 = styled.div<{ margin?: number }>`
  top: 132px;
  right: -51px;
  z-index: 1;
  position: absolute;
  width: 168px;
  height: 208px;
  background: #f23482;
  border-radius: 90px;
  margin-top: ${(props) => (props.margin ? props.margin : '0')}px;
`

function ExpertPreStartContainer() {
  const { setLogrocket } = useLogrocket()
  const interviewDetails = useSelector(interviewSelectors.interview)
  const showOnboarding = (interviewDetails.profile as IExpertProfile).include_onboarding

  useEffect(() => {
    if (interviewDetails) {
      setLogrocket(
        `${interviewDetails.interview?.order_number.toString()}`,
        'Expert pre-meeting page'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewDetails])

  return (
    <Container>
      {!isMobileOnly && (
        <>
          <PreStartHeader isExpert />
          <DataContainer>
            <AvailabilityApplicantInfo showTitle={false} height={430} preCall />
            <PreStartInfoComponent isExpert showExpertOnboarding={showOnboarding} />
          </DataContainer>
          <AvailabilityFooter />
        </>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileHeaderElement1 />
          <MobileHeaderElement2 />
          <PreStartHeaderMobile isExpert />
          <DataContainerMobile>
            <PreStartApplicantInfoMobile />
            <PreStartInfoComponent isExpert showExpertOnboarding={showOnboarding} />
          </DataContainerMobile>
          <AvailabilityFooter />
        </MobileContainer>
      )}
    </Container>
  )
}

export default ExpertPreStartContainer
