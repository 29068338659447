import styled from '@emotion/styled'
import React, { useState } from 'react'
import LogoImg from 'src/static/logo.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { InterviewPart } from 'src/ava/models'
import { useDispatch, useSelector } from 'react-redux'
import SupportIcon from 'src/static/components/SvgSupport'
import { avaSettingsSelectors, updateAvaSettingsAction } from 'src/redux'
import HamburgerButton from 'src/components/HamburgerButton'
import LandingSideMenu from 'src/components/LandingSideMenu'
import useBranding from 'src/utils/useBranding'
import MuteButton from '../elements/MuteButton'
import * as interviewSelectors from '../../../interview/selectors'

const DefSettingsColor = '#1d0e3b'

const LineContainer = styled.div<{ backgroundMobile: string }>`
  position: relative;
  display: flex;
  height: 72px;
  min-height: 72px;
  width: 100%;
  justify-content: ${isMobileOnly ? 'center !important' : 'space-between'};
  align-items: center;
  margin-bottom: 72px;
  padding: 0 42px 0 24px;
  background: ${({ backgroundMobile }) => (isMobileOnly ? backgroundMobile : 'unset')};

  @media screen and (max-width: 900px) {
    padding: 0 16px;
  }

  ${isMobileOnly &&
  css`
    padding: 0;
    height: 72px;
    min-height: 72px;
    justify-content: center;
    margin: 0;
  `}
`

const Logo = styled.img`
  margin-left: 24px;
  max-width: 120px;
  max-height: 80%;
  object-fit: contain;

  ${isMobileOnly &&
  css`
    margin: 0;
    width: 88px;
    height: 22px;
  `}
`

const StepPanel = styled.div`
  display: flex;
`

const StepItem = styled.div<{ active?: boolean }>`
  font-family: 'Rubik';
  font-weight: ${({ active }) => (active ? 500 : 400)};
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
  white-space: break-spaces;
`

const ButtonsContainer = styled.div<{ toTheRight?: boolean }>`
  z-index: 10;
  margin-left: ${({ toTheRight }) => (toTheRight ? 'auto' : '0')};
  display: flex;

  ${isMobileOnly &&
  css`
    position: absolute;
    right: 24px;
    z-index: 0;
  `}
`

const SupportButton = styled.div<{ selected?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  color: ${({ theme, selected }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return selected ? color : DefSettingsColor
  }};

  > svg {
    margin-left: 12px;
  }
`

const SideMenuContainer = styled.div`
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
`

const SideItemContainer = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;

  > svg {
    width: 24px;
    height: 24px;
  }
`

const SideItemText = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 16px;
`

interface IProps {
  backgroundMobile?: string
  showMuteButton?: boolean
}

const Steps = [InterviewPart.Intro, InterviewPart.Interview, InterviewPart.Report]

const ReportHeader = ({ backgroundMobile, showMuteButton }: IProps) => {
  const dispatch = useDispatch()
  const avaSettings = useSelector(avaSettingsSelectors.data)
  const agency = useSelector(interviewSelectors.agency)
  const { agencyColor } = useBranding()

  const [showSideMenu, setShowSideMenu] = useState(false)

  const handleSupport = () => {
    setShowSideMenu(false)
    dispatch(
      updateAvaSettingsAction({ ...avaSettings, showSupport: !avaSettings.showSupport })
    )
  }

  const handleMute = () => {
    dispatch(updateAvaSettingsAction({ ...avaSettings, muted: !avaSettings.muted }))
  }

  const logo = agency?.whitelisted && agency?.company_logo?.url

  return (
    <LineContainer backgroundMobile={backgroundMobile}>
      <Logo src={logo ?? LogoImg} alt="logo" />
      {!isMobileOnly && (
        <StepPanel>
          {Steps.map((s, index) => (
            <div style={{ display: 'flex' }} key={s}>
              <StepItem active={s === InterviewPart.Report}>{s}</StepItem>
              {index !== Steps.length - 1 && <StepItem> - </StepItem>}
            </div>
          ))}
        </StepPanel>
      )}
      {!isMobileOnly && (
        <ButtonsContainer>
          <SupportButton onClick={handleSupport} selected={avaSettings.showSupport}>
            Support
            <SupportIcon
              fill={avaSettings.showSupport ? agencyColor : DefSettingsColor}
            />
          </SupportButton>
        </ButtonsContainer>
      )}
      {isMobileOnly && (
        <ButtonsContainer>
          {showMuteButton && (
            <MuteButton
              onClick={handleMute}
              toTheRight
              fill="#000"
              muted={avaSettings.muted}
            />
          )}
          <HamburgerButton onClick={() => setShowSideMenu(true)} />
        </ButtonsContainer>
      )}
      {isMobileOnly && (
        <LandingSideMenu
          show={showSideMenu}
          autoHeight
          blur
          onClose={() => setShowSideMenu(false)}
        >
          <SideMenuContainer>
            <SideItemContainer onClick={handleSupport}>
              <SupportIcon fill={DefSettingsColor} />
              <SideItemText>Live support</SideItemText>
            </SideItemContainer>
          </SideMenuContainer>
        </LandingSideMenu>
      )}
    </LineContainer>
  )
}

ReportHeader.defaultProps = {
  backgroundMobile: '#f6f4fd',
  showMuteButton: false,
}

export default ReportHeader
