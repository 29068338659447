import * as React from 'react'

function SvgDisclaimer({ fill }: { fill: string }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM10.25 5.9375C10.25 5.83437 10.3344 5.75 10.4375 5.75H11.5625C11.6656 5.75 11.75 5.83437 11.75 5.9375V12.3125C11.75 12.4156 11.6656 12.5 11.5625 12.5H10.4375C10.3344 12.5 10.25 12.4156 10.25 12.3125V5.9375ZM11 16.25C10.7056 16.244 10.4253 16.1228 10.2192 15.9125C10.0132 15.7022 9.89773 15.4195 9.89773 15.125C9.89773 14.8305 10.0132 14.5478 10.2192 14.3375C10.4253 14.1272 10.7056 14.006 11 14C11.2944 14.006 11.5747 14.1272 11.7808 14.3375C11.9868 14.5478 12.1023 14.8305 12.1023 15.125C12.1023 15.4195 11.9868 15.7022 11.7808 15.9125C11.5747 16.1228 11.2944 16.244 11 16.25Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgDisclaimer
