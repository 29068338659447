import { NetworkQuality } from 'agora-rtc-sdk-ng'

import { reduxDataFactory } from '../../utils/redux'

const {
  action: updateCallNetworkLocalAction,
  actionType: updateCallNetworkLocalActionType,
  reducer: callNetworkLocalDataReducer,
  selectors: callNetworkLocalSelectors,
} = reduxDataFactory<NetworkQuality>('networkLocal', 'call')

export {
  updateCallNetworkLocalAction,
  updateCallNetworkLocalActionType,
  callNetworkLocalDataReducer,
  callNetworkLocalSelectors,
}
