import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import ProgressTooltipImg from 'src/static/icProgressTooltip.png'

const LoadingContentContainer = styled.div<{
  progress?: number
  showTooltip?: boolean
  animationSpeedMs: number
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 392px;
  align-items: center;

  ${isMobileOnly &&
  css`
    margin: 0 32px;
    width: calc(100% - 32px);
  `}

  &:after {
    content: '${({ progress }) => Math.round(progress * 100)}%';
    position: absolute;
    display: ${({ showTooltip }) => (showTooltip ? 'flex' : 'none')};
    width: 64px;
    height: 60px;
    background: url(${ProgressTooltipImg});
    background-size: 100% 100%;
    top: -57px;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    color: rgba(115, 121, 145, 0.6);
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    transition: all ${({ animationSpeedMs }) => animationSpeedMs}ms linear;
    left: ${({ progress }) => `calc(${progress * 100}% - 32px)`};
  }
`

const ProgressBar = styled.div<{ progress?: number; animationSpeedMs: number }>`
  position: relative;
  width: 100%;
  height: 10px;
  background: #ececec;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 1100px) {
    margin-top: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    width: ${({ progress }) => progress * 100}%;
    height: 100%;
    top: 0;
    background: ${({ theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return color
    }};
    border-radius: 5px;
    transition: all ${({ animationSpeedMs }) => animationSpeedMs}ms linear;
  }
`

interface IProps {
  animationSpeedMs: number
  progress: number
}

const LoadingBar = ({ progress, animationSpeedMs }: IProps) => {
  return (
    <LoadingContentContainer
      showTooltip
      progress={progress}
      animationSpeedMs={animationSpeedMs}
    >
      <ProgressBar progress={progress} animationSpeedMs={animationSpeedMs} />
    </LoadingContentContainer>
  )
}

export default LoadingBar
