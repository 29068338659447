/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import {
  BaseLayout,
  LandingButton,
  LandingInfo,
  LandingTitle,
  LayoutWrapper,
} from './landing-styled-components'

const Layout = styled(BaseLayout)`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 70px 0 80px;
  display: flex;
  align-items: center;
  height: 576px;

  @media screen and (max-width: 1439px) {
    padding: 44px 40px;
    justify-content: center;
    height: auto;
  }

  @media screen and (max-width: 1144px) {
    max-width: unset;
  }

  @media screen and (max-width: 899px) {
    padding: 48px;
  }

  ${isMobileOnly &&
  css`
    padding: 48px 16px !important;
  `}
`

const TextBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 70px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    margin: 0;
    padding-right: 0px;
  }

  @media screen and (max-width: 1279px) {
    max-width: 560px;
  }

  @media screen and (max-width: 899px) {
    max-width: unset;
  }
`

const VideoStyled = styled.video`
  width: 620px;
  height: auto;
  object-fit: contain;
  align-self: end;

  @media screen and (max-width: 1439px) {
    width: 519px;
  }

  @media screen and (max-width: 1280px) {
    width: 360px;
  }

  @media screen and (max-width: 899px) {
    display: none;
  }
`

const LandingTitleStyled = styled(LandingTitle)`
  font-size: 48px;
  line-height: 64px;
  text-align: start;

  @media screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 48px;
  }

  @media screen and (max-width: 899px) {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    font-size: 20px !important;
    line-height: 28px !important;
  `}
`

const LandingInfoStyled = styled(LandingInfo)`
  margin-top: 14px;
  font-size: 24px;
  line-height: 32px;
  text-align: start;

  @media screen and (max-width: 1280px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    font-size: 16px !important;
    line-height: 24px !important;
  `}
`

const LandingButtonStyled = styled(LandingButton)`
  margin-top: 40px;

  @media screen and (max-width: 1439px) {
    margin: 32px 0 0 0;
  }

  @media screen and (max-width: 899px) {
    margin: 24px auto 0 auto;
  }
`

const ColoredSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`

interface IProps {
  onStart: () => void
}

const LandingTopBlock = ({ onStart }: IProps) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1144px)' }) && !isMobileOnly

  const isWindows = window.navigator.userAgent.indexOf('Win') !== -1

  return (
    <LayoutWrapper background={isWindows ? '#f1e6ff' : '#f3e9ff'}>
      <Layout>
        <TextBlock>
          <LandingTitleStyled>
            Turning interview preparation into competitive
            <ColoredSpan color="#B202FF"> advantage</ColoredSpan>
          </LandingTitleStyled>
          <LandingInfoStyled>
            {isMobileOnly || isSmallScreen
              ? 'Boosting placement rates with personalized coaching powered by AI'
              : `Boosting placement rates with personalized coaching\npowered by AI`}
          </LandingInfoStyled>
          <LandingButtonStyled onClick={onStart}>Request a demo</LandingButtonStyled>
        </TextBlock>
        <VideoStyled
          src="/static/media/video/landing_home.mp4"
          autoPlay
          muted
          loop
          playsInline
          onContextMenu={(event) => event.preventDefault()}
        />
      </Layout>
    </LayoutWrapper>
  )
}

export default LandingTopBlock
