import { put, takeEvery, call } from 'typed-redux-saga'

import { getInterviewSuccess } from '../../agency/actions'
import { getAgencyInterview, adminRescheduleInterview } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface IAdminRescheduleInterviewAction {
  adminComment?: string
  agencyId: string
  expertIds: string[]
  id: string
  time?: number[]
}

const {
  action: adminRescheduleInterviewAction,
  actionFailure: adminRescheduleInterviewActionFailure,
  actionSuccess: adminRescheduleInterviewActionSuccess,
  actionSuccessClear: adminRescheduleInterviewActionSuccessClear,
  actionFailureClear: adminRescheduleInterviewActionFailureClear,
  actionType: adminRescheduleInterviewActionType,
  actionTypeFailure: adminRescheduleInterviewActionFailureType,
  actionTypeSuccess: adminRescheduleInterviewActionSuccessType,
  reducer: adminRescheduleInterviewRequestReducer,
  selectors: adminRescheduleInterviewSelectors,
} = reduxRequestFactory<IAdminRescheduleInterviewAction>(
  'adminRescheduleInterview',
  'requests'
)

export {
  adminRescheduleInterviewAction,
  adminRescheduleInterviewActionFailure,
  adminRescheduleInterviewActionSuccess,
  adminRescheduleInterviewActionSuccessClear,
  adminRescheduleInterviewActionFailureClear,
  adminRescheduleInterviewActionType,
  adminRescheduleInterviewActionFailureType,
  adminRescheduleInterviewActionSuccessType,
  adminRescheduleInterviewRequestReducer,
  adminRescheduleInterviewSelectors,
}

function* adminRescheduleInterviewSaga(
  action: ISagaAction<IAdminRescheduleInterviewAction>
) {
  try {
    const { agencyId, adminComment, expertIds, id, time = [] } = action.payload
    yield* call(
      adminRescheduleInterview,
      agencyId,
      id,
      { comment: adminComment, expert_ids: expertIds },
      time.map((t) => Math.floor(t / 1000))
    )
    yield put(adminRescheduleInterviewActionSuccess())
    const interview = yield* call(getAgencyInterview, agencyId, id)
    yield put(getInterviewSuccess({ interview }))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      adminRescheduleInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAdminRescheduleInterview() {
  yield takeEvery(adminRescheduleInterviewActionType, adminRescheduleInterviewSaga)
}
