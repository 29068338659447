import React from 'react'
import { CheckboxButton } from '../../components'
import { CheckboxContentContainer, CheckboxContentTitle } from './checkbox-styles'

interface IAvailabilityDaysCheckbox {
  title: string
  name: string
  onBlur: () => void
  onChange: (val) => void
  checked: boolean
  useTheme?: boolean
  hideCheckBox?: boolean
}

export default function AvailabilityDaysCheckbox(props: IAvailabilityDaysCheckbox) {
  const { title, onBlur, onChange, checked, name, useTheme, hideCheckBox } = props
  return (
    <CheckboxButton
      onBlur={onBlur}
      onChange={onChange}
      checked={checked}
      name={name}
      useTheme={useTheme}
      hideCheckBox={hideCheckBox}
    >
      <CheckboxContentContainer>
        <CheckboxContentTitle>{title}</CheckboxContentTitle>
      </CheckboxContentContainer>
    </CheckboxButton>
  )
}

AvailabilityDaysCheckbox.defaultProps = {
  useTheme: false,
  hideCheckBox: false,
}
