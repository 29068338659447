import httpClient from '../../httpClient'

import {
  IExpertProfile,
  IInterview,
  IAgency,
  ICandidateProfile,
  AdminInterviewStatus,
  AgencyInterviewStatus,
} from '../models'

export type InterviewSearch =
  | 'candidate_email'
  | 'candidate_name'
  | 'desired_position'
  | 'expert_name'
  | 'position_company'
  | 'order_number'
  | 'conversation_id'
export type InterviewSort =
  | 'candidate_name'
  | 'scheduled_time'
  // | 'status'
  | 'updated_at'
  | 'hired_chance'

export interface IGetAdminInterviewsParams {
  agency_id?: string
  exclude_agencies?: string[]
  exclude_ops?: string[]
  exclude_priorities?: string[]
  page?: number
  search_field?: InterviewSearch
  search?: string
  sort?: InterviewSort
  status?: AdminInterviewStatus | AgencyInterviewStatus
}

export interface IGetAdminStatsParams {
  agency_id?: string
  exclude_agencies?: string[]
  exclude_ops?: string[]
  exclude_priorities?: string[]
  search_field?: InterviewSearch
  search?: string
}

export type IAdminInterviewExpert = Pick<
  IExpertProfile,
  'company' | 'current_position' | 'first_name' | 'last_name'
>

export type IAdminInterview = Pick<
  IInterview,
  | 'admin_status'
  | 'agency_status'
  | 'agree_to_share'
  | 'candidate_token_code'
  | 'scheduled_time'
  | 'days_since_update'
  | 'desired_position'
  | 'hired_chance_scale'
  | 'hired_chance_score'
  | 'id'
  | 'is_hired'
  | 'is_incomplete'
  | 'interview_date'
  | 'interview_date_option'
  | 'ops_manager_email'
  | 'order_number'
  | 'position_company'
  | 'priority'
  | 'priority_reason'
  | 'started_at'
  | 'status'
> & {
  agency: Pick<IAgency, 'id' | 'name' | 'internal_name' | 'show_interview_links'>
  candidate_profile: Pick<ICandidateProfile, 'email' | 'first_name' | 'last_name'>
  expert_profile: IAdminInterviewExpert
}

export type IAdminStats = {
  [key in AdminInterviewStatus]?: number
}

export type IAgencyStats = {
  [key in AgencyInterviewStatus]?: number
}

// admin#report - GET /admin/interviews

export const getInterviews = (
  params?: IGetAdminInterviewsParams
): Promise<{ interviews: Array<IAdminInterview>; pages: number }> => {
  return httpClient.get('/admin/interviews', { params }).then((response) => response.data)
}

// admin#report - GET /admin/report

export const downloadAdminReport = (agencyId?: string): Promise<string> => {
  return httpClient
    .get('/admin/report.csv', { params: { agency_id: agencyId } })
    .then((response) => response.data)
}

// admin#stats - GET /admin/stats

export const getStats = (
  params?: IGetAdminStatsParams
): Promise<IAdminStats | IAgencyStats> => {
  return httpClient.get('/admin/stats', { params }).then((response) => response.data)
}
