import React from 'react'
import { Link } from '../../components'
import Layout from '../Layout'
import ChangePasswordForm from '../forms/ChangePassword'
import List from '../components/List'

const listDataSource = [
  'At least 8 characters',
  'At least one lowercase letter, one uppercase letter',
  'Must contain a number and a letter',
]

function ChangePassword() {
  return (
    <Layout
      sidebarText="Find the perfect match from thousands of experts to prepare your applicants for their job interviews"
      title="Change password"
      subtitle="In order to protect your account, make sure your password is:"
    >
      <>
        <List data={listDataSource} />
        <ChangePasswordForm />
        <Link to="login">Back to login</Link>
      </>
    </Layout>
  )
}

export default ChangePassword
