/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useState } from 'react'
import ImgInsights1 from 'src/static/landing/landing_insights_1.png'
import ImgInsights2 from 'src/static/landing/landing_insights_2.png'
import ImgInsights3 from 'src/static/landing/landing_insights_3.png'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import AppearBlock from 'src/interview/components/AppearBlock'
import {
  BaseLayout,
  LandingInfo,
  LandingTitle,
  LayoutWrapper,
} from './landing-styled-components'

const LayoutWrapperStyled = styled(LayoutWrapper)`
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
`

const Layout = styled(BaseLayout)`
  display: flex;
  padding: 0 140px 0 80px;
  position: relative;
  align-items: center;
  width: auto;

  @media screen and (max-width: 1439px) {
    flex-direction: column;
    padding: 56px 40px;
    height: auto;
  }

  ${isMobileOnly &&
  css`
    padding: 24px !important;
  `}
`

const SideBlock = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 94px;

  @media screen and (max-width: 1439px) {
    width: 100%;
    padding: 0;
    height: auto;
    align-items: center;
  }
`

const ImagesBlock = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const AppearBlockWrapper = styled.div`
  width: 501px;
  height: 464px;
  margin: -42px 0px;

  @media screen and (max-width: 1439px) {
    margin: 32px 0 0;
  }

  @media screen and (max-width: 1280px) {
    margin: 48px 0 0;
  }

  ${isMobileOnly &&
  css`
    width: 259px;
    height: 239px;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  text-align: left;

  @media screen and (max-width: 1439px) {
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 8px !important;
  `}
`

const LandingInfoStyled = styled(LandingInfo)`
  text-align: left;

  @media screen and (max-width: 1439px) {
    text-align: center;
    max-width: 720px;
  }
`

const ImageStyled = styled.img<{
  show: boolean
  width: number
  height: number
  top: number
  right: number
}>`
  position: absolute;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  top: ${({ top }) => top}px;
  right: ${({ right }) => right}px;
  object-fit: contain;
  opacity: ${({ show }) => (show ? 1 : 0)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;

  &:nth-child(1) {
    transition: opacity 0.6s linear;
  }

  &:nth-child(2) {
    transition: opacity 0.6s linear 0.4s;
  }

  &:nth-child(3) {
    transition: opacity 0.6s linear 1s;
  }

  &:nth-child(4) {
    transition: opacity 0.6s linear 1.6s;
  }
`

const Images = isMobileOnly
  ? [
      { src: ImgInsights1, width: 164, height: 95, top: 0, right: 0 },
      { src: ImgInsights2, width: 85, height: 120, top: 60, right: 174 },
      { src: ImgInsights3, width: 165, height: 133, top: 106, right: 0 },
    ]
  : [
      { src: ImgInsights1, width: 317, height: 184, top: 0, right: 0 },
      { src: ImgInsights2, width: 164, height: 233, top: 116, right: 336 },
      { src: ImgInsights3, width: 319, height: 258, top: 206, right: 0 },
    ]

const LandingInsightsResult = () => {
  const [show, setShow] = useState(false)

  const showBlock = (shown: boolean) => {
    if (shown) {
      setShow(true)
    }
  }

  return (
    <LayoutWrapperStyled background="#fff">
      <Layout>
        <SideBlock>
          <LandingTitleStyled>Real insights mean real results.</LandingTitleStyled>
          <LandingInfoStyled>
            Whether it&apos;s an expert-led or AI-powered session, the Wizco post-session
            report equips your applicants with a detailed performance analysis, tailored
            tailored action plan, and communication tips to boost their confidence and
            increase their chances of interview success.
          </LandingInfoStyled>
        </SideBlock>
        <AppearBlockWrapper>
          <AppearBlock onAppear={showBlock} margin={isMobileOnly ? 100 : 400}>
            <ImagesBlock>
              {Images.map((img, index) => (
                <ImageStyled
                  key={index}
                  src={img.src}
                  width={img.width}
                  height={img.height}
                  top={img.top}
                  right={img.right}
                  show={show}
                />
              ))}
            </ImagesBlock>
          </AppearBlock>
        </AppearBlockWrapper>
      </Layout>
    </LayoutWrapperStyled>
  )
}

export default LandingInsightsResult
