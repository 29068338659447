import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'
import { validateAgencyLogoWhite } from '../../utils/validateAgencyLogo'

const Heading = styled.h1`
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 65px;
  margin-top: 0;
  margin-bottom: 40px;

  @media screen and (max-width: 600px) {
    font-size: 35px;
    line-height: 120%;
  }
`

const LogoContainer = styled.div`
  width: 131px;
  height: 50px;
  margin-bottom: 70px;

  @media screen and (max-width: 600px) {
    height: 35px;
  }
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

function SidebarSuccess({ expert }: { expert: boolean }) {
  const agency = useSelector(interviewSelectors.agency)

  return (
    <>
      <LogoContainer>
        <Logo src={validateAgencyLogoWhite(agency.negative_logo?.url)} />
      </LogoContainer>
      <Heading>
        {expert ? (
          <>All done.</>
        ) : (
          <>
            Wishing
            <br />
            you good
            <br />
            luck.
          </>
        )}
      </Heading>
    </>
  )
}

export default SidebarSuccess
