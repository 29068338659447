import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { agencySelectors } from '../../redux'

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const Label = styled('label')`
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 0;
`

const ChBox = styled.input<{ color: string }>`
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 11px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    height: 18px;
    width: 18px;
    border: 1px solid rgba(143, 155, 179, 0.49);
    border-radius: 3px;
    background-color: #ffffff;
    flex-shrink: 0;
  }

  &:checked + label:before {
    background-color: ${(props) => props.color || '#B202FF'};
    flex-shrink: 0;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -6px 0 white;
    transform: rotate(45deg);
  }
`

const Tooltip = styled.span`
  font-size: 10px;
  margin-left: 36px;
`

interface IProps {
  value: boolean
  label: string
  name: string
  disabled?: boolean
  tooltip?: string
  onChange?: (v: boolean) => void
}

const CheckBox = (props: IProps) => {
  const { value, label, disabled, onChange, name, tooltip } = props

  const agency = useSelector(agencySelectors.data)

  function onChanged() {
    onChange(!value)
  }

  return (
    <Container onClick={onChanged} disabled={disabled}>
      <ChBox type="checkbox" name={name} checked={value} color={agency.main_color} />
      <Label htmlFor={name}>{label}</Label>
      {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </Container>
  )
}

CheckBox.defaultProps = {
  disabled: false,
  tooltip: null,
  onChange: () => undefined,
}

export default CheckBox
