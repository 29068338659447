import React from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { Title, Subtitle, RegularLink, RegularHyperLink } from '../components'

import bgTopLeft from '../static/combined_shape.png'
import bgTopRight from '../static/report_accent_mobile.svg'
import headerAccentImg from '../static/report_accent.png'

import * as interviewSelectors from '../interview/selectors'
import { validateAgencyLogoWhite } from '../utils/validateAgencyLogo'

const Layout = styled.div`
  min-height: 100vh;
  padding: 65px 60px;
  background-color: #f5f6f8;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 1160px;
  flex: 1;
`

const HeaderContent = styled.div`
  padding: 60px;
  flex: 1;

  @media screen and (max-width: 600px) {
    padding: 30px 0 0 35px;
    min-height: auto;
    background-image: url(${bgTopLeft}), url(${bgTopRight});
    background-position: top left, 110% -72px;
    background-repeat: no-repeat;
    background-size: 200px 100%, 38%;
    color: rgb(59, 66, 79);
  }
`

const HeaderBorderAccent = styled.div`
  width: 105px;
  background-color: #ffdb46;
  border-radius: 0 100px 100px 0;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const HeaderAccent = styled.div`
  height: 193px;
  width: 93px;
  background: url(${headerAccentImg}) no-repeat center;
  background-size: cover;
  right: 60px;
  top: 60px;
  position: absolute;
  @media screen and (max-width: 600px) {
    display: none;
  }

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      display: none;
    }
  `}
`

const Header = styled.header`
  background-color: #fff;
  border-radius: 20px 100px 100px 19px;
  display: flex;
  position: relative;

  @media screen and (max-width: 600px) {
    border-radius: 0;
  }
`

const Logo = styled.div`
  & img {
    height: 100%;
    width: auto;
  }

  @media screen and (max-width: 600px) {
    height: 35px;
    margin-bottom: 40px;
  }
`

const TitleStyled = styled(Title)`
  font-size: 36px;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    font-size: 28px;
    font-weight: 500;
    width: 65%;
  }
`

const SubtitleStyled = styled(Subtitle)`
  font-size: 20px;
  margin-bottom: 0;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    width: 65%;
    margin-bottom: 80px;
  }
`

const Body = styled.div`
  @media screen and (max-width: 600px) {
    margin-top: -60px;
    z-index: 1;
    padding: 0 15px;
  }
`

const SponsoredHeader = styled.div`
  align-items: center;
  background: #b202ff0d;
  border-radius: 16px;
  display: flex;
  height: 81px;
  justify-content: space-between;
  margin: 28px 0;
  padding: 24px 60px;

  @media screen and (max-width: 600px) {
    background: #b202ff0d;
    display: block;
    height: auto;
    margin: 0;
    padding: 15px;
  }
`

const SponsoredTitle = styled.div`
  font-family: Rubik;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
`

const RegularLinkStyled = styled(RegularHyperLink)`
  background: #b202ff;
  border: none;
  border-radius: 30px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-family: Rubik;
  font-weight: 500;
  line-height: 24px;
  outline: none !important;
  padding: 10px 30px;
  text-decoration: none;
  width: auto;

  &:hover {
    color: white;
    opacity: 0.75;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
  }

  > p {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`.withComponent(RegularLink)

interface IReportLayoutProps {
  children: React.ReactElement
  title: string
  subtitle?: string
  sponsored?: number
}

function ReportLayout(props: IReportLayoutProps) {
  const { title, subtitle, children, sponsored } = props
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const agency = useSelector(interviewSelectors.agency)

  const logoRenderFunc = () => {
    return <img src={validateAgencyLogoWhite(agency.company_logo?.url)} alt="Logo" />
  }

  const redirectUrl =
    agency?.additional_booking_url_sponsored || agency?.additional_booking_url

  return (
    <Layout>
      <Content>
        {!!sponsored && (
          <SponsoredHeader>
            <SponsoredTitle>{`You have ${sponsored} more sponsored session available`}</SponsoredTitle>
            <RegularLinkStyled href={redirectUrl} target="_blank">
              <p>Book another session</p>
            </RegularLinkStyled>
          </SponsoredHeader>
        )}
        <Header>
          <HeaderContent>
            {isMobile && <Logo>{logoRenderFunc()}</Logo>}
            <TitleStyled>{title}</TitleStyled>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
          </HeaderContent>
          <HeaderBorderAccent />
          <HeaderAccent />
        </Header>
        <Body>{children}</Body>
        {!!sponsored && (
          <SponsoredHeader>
            <SponsoredTitle>{`You have ${sponsored} more sponsored session available`}</SponsoredTitle>
            <RegularLinkStyled href={redirectUrl} target="_blank">
              <p>Book another session</p>
            </RegularLinkStyled>
          </SponsoredHeader>
        )}
      </Content>
    </Layout>
  )
}

ReportLayout.defaultProps = {
  subtitle: '',
  sponsored: 0,
}

export default ReportLayout
