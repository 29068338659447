import { IVideoProfile } from '../interfaces'

const DefaultVideoProfileName = '720p'

export const VideoProfiles: IVideoProfile[] = [
  { name: '240p', width: 320, height: 240, frameRate: 15, bitrate: 200 },
  { name: '360p', width: 640, height: 360, frameRate: 15, bitrate: 400 },
  { name: '720p', width: 1280, height: 720, frameRate: 15, bitrate: 1500 },
]

export const DefaultVideoProfile: IVideoProfile = VideoProfiles.find(
  (p) => p.name === DefaultVideoProfileName
)
