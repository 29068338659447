import { put, takeEvery, call } from 'typed-redux-saga'
import { get, isObject } from 'lodash'
import { toastr } from 'react-redux-toastr'

import handleSuccessSaga from 'src/utils/handleSuccessSaga'
import handleErrorSaga from 'src/utils/handleErrorSaga'
import {
  getInterviews as getInterviewsAction,
  getInterviewsSuccess,
  getInterviewsFailure,
  getInterview,
  getInterviewSuccess,
  getInterviewFailure,
  sendToApplicant,
  sendToApplicantFailure,
  sendToApplicantSuccess,
  saveSessionReport as saveSessionReportAction,
  saveSessionReportFailure,
  saveSessionReportSuccess,
  interviewComplete,
  interviewCompleteFailure,
  interviewCompleteSuccess,
  interviewToEnd,
  interviewToEndFailure,
  interviewToEndSuccess,
  loadExpertDataSuccess,
  loadExpertDataFailure,
  askExpertAvailability,
  getStats as getStatsAction,
  getStatsSuccess,
  getStatsFailure,
} from './actions'
import {
  IGetInterviewAction,
  ISessionReportAction,
  IAskExpertAvailability,
  IGetInterviewsAction,
  IGetStatsAction,
  ISaveInterviewDetailsActionPayload,
} from './types'
import isHTML from '../utils/isHTML'

import {
  IUpdateOrderInfoPayload,
  updateAvailabilityInfoActionType,
  updateOrderInfoActionType,
  updatePostSessionInfoActionType,
  updateSchedulingInfoActionType,
  updateOrderInfoActionSuccess,
  updateOrderInfoActionFailure,
  updateAvailabilityInfoActionSuccess,
  updateAvailabilityInfoActionFailure,
  updateSchedulingInfoActionSuccess,
  updateSchedulingInfoActionFailure,
  updatePostSessionInfoActionSuccess,
  updatePostSessionInfoActionFailure,
  IUpdateAvailabilityInfoPayload,
  IUpdatePostSessionInfoPayload,
  IUpdateSchedulingInfoPayload,
} from './redux'
import { ISagaAction } from '../utils/redux'
import {
  completeInterview,
  endAgencyInterview,
  getAgencyInterview,
  getInterviews,
  getStats,
  matchApplicant,
  requestExpertAvailability,
  saveSessionReport,
  updateAvailabilityInfo,
  updateOrderInfo,
  updatePostSessionInfo,
  updateSchedulingInfo,
} from '../api'

function* askExpertAvailabilitySaga(action: IAskExpertAvailability) {
  try {
    const { expertId, interviewId, agencyId } = action.payload
    yield* call(requestExpertAvailability, expertId, interviewId)
    const interview = yield* call(getAgencyInterview, agencyId, interviewId)
    yield put(getInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* getInterviewsSaga(action: IGetInterviewsAction) {
  try {
    const { params } = action.payload
    const { interviews, pages } = yield* call(getInterviews, params)
    yield put(getInterviewsSuccess({ interviews, pages }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getInterviewsFailure({
        error: errorMessage,
      })
    )
  }
}

function* getInterviewDetailsSaga(action: IGetInterviewAction) {
  try {
    const { id, agencyId } = action.payload
    const interview = yield* call(getAgencyInterview, agencyId, id)
    yield put(getInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getInterviewFailure({
        error: errorMessage,
      })
    )
  }
}

function* saveSessionReportSaga(action: ISessionReportAction) {
  try {
    const { id, agencyId, report } = action.payload
    yield* call(saveSessionReport, agencyId, id, report)
    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(saveSessionReportSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data && !isHTML(err.response.data)) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      saveSessionReportFailure({
        error: errorMessage,
      })
    )
  }
}

function* sendToCandidateSaga(action: ISagaAction<ISaveInterviewDetailsActionPayload>) {
  try {
    const { id, agencyId } = action.payload
    yield* call(matchApplicant, agencyId, id)
    yield call(handleSuccessSaga, 'Successfully sent')
    yield put(sendToApplicantSuccess())
    const interview = yield* call(getAgencyInterview, agencyId, id)
    yield put(getInterviewSuccess({ interview }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data && !isHTML(err.response.data)) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      sendToApplicantFailure({
        error: errorMessage,
      })
    )
  }
}

function* interviewCompleteSaga(action: ISagaAction<ISaveInterviewDetailsActionPayload>) {
  try {
    const { id, agencyId } = action.payload
    yield* call(completeInterview, agencyId, id)
    yield put(interviewCompleteSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data && !isHTML(err.response.data)) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      interviewCompleteFailure({
        error: errorMessage,
      })
    )
  }
}

function* interviewToEndSaga(action: ISagaAction<ISaveInterviewDetailsActionPayload>) {
  try {
    const { id, agencyId } = action.payload
    yield* call(endAgencyInterview, agencyId, id)
    yield put(interviewToEndSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data && !isHTML(err.response.data)) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      interviewToEndFailure({
        error: errorMessage,
      })
    )
  }
}

function* getStatsSaga(action: IGetStatsAction) {
  const { params } = action.payload
  try {
    const stats = yield* call(getStats, params)
    yield put(getStatsSuccess({ stats }))
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    if (err.response && err.response.data) {
      errorMessage = err.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getStatsFailure({
        error: errorMessage,
      })
    )
  }
}

function* loadExpertDataSuccessSaga() {
  yield call(toastr.success, 'Success', 'Successfully saved')
}

function* loadExpertDataFailureSaga() {
  yield call(toastr.warning, 'No results', 'No export records found')
}

export function* watchAskExpertAvailabilitySaga() {
  yield takeEvery(askExpertAvailability.type, askExpertAvailabilitySaga)
}

export function* watchGetInterviews() {
  yield takeEvery(getInterviewsAction.type, getInterviewsSaga)
}

export function* watchGetInterview() {
  yield takeEvery(getInterview.type, getInterviewDetailsSaga)
}

export function* watchSendToCandidate() {
  yield takeEvery(sendToApplicant.type, sendToCandidateSaga)
}

export function* watchSaveSessionReport() {
  yield takeEvery(saveSessionReportAction.type, saveSessionReportSaga)
}

export function* watchInterviewComplete() {
  yield takeEvery(interviewComplete.type, interviewCompleteSaga)
}

export function* watchInterviewToEnd() {
  yield takeEvery(interviewToEnd.type, interviewToEndSaga)
}

export function* watchLoadExpertDataSuccess() {
  yield takeEvery(loadExpertDataSuccess.type, loadExpertDataSuccessSaga)
}

export function* watchLoadExpertDataFailure() {
  yield takeEvery(loadExpertDataFailure.type, loadExpertDataFailureSaga)
}

export function* watchGetStats() {
  yield takeEvery(getStatsAction.type, getStatsSaga)
}

function* updateOrderInfoSaga({ payload }: ISagaAction<IUpdateOrderInfoPayload>) {
  try {
    const {
      id,
      agencyId,
      student,
      position,
      codingPlaybackUrl,
      codingSessionUrl,
      cv_file_id,
      interview_internal_experts_attributes,
    } = payload

    yield* call(updateOrderInfo, agencyId, id, {
      candidate: {
        first_name: student.firstName,
        last_name: student.lastName,
        email: student.email,
        linkedin_profile: student.linkedin,
      },
      interview: {
        desired_position: position.name,
        position_company: position.company,
        position_location: position.location,
        link_to_position: position.link,
        position_description: position.description,
        programming_language: position.programmingLanguage,
        interview_date: position.date,
        special_request: position.requestApplicant,
        special_request_by_admin: position.request,
        coding_session_url: codingSessionUrl,
        coding_playback_url: codingPlaybackUrl,
        cv_file_id,
        coaching_language: position.coachingLanguage,
        coaching_type: position.coachingType,
        interview_internal_experts_attributes,
        availability_days: student.availabilityDays,
        availability_time: student.availabilityTime,
        candidate_available_at: student.candidateAvailableAt.map((t) =>
          Math.floor(t / 1000)
        ),
      },
    })
    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(getInterview({ agencyId, id }))
    yield put(updateOrderInfoActionSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    const errorData = get(err, 'response.data')
    if (err.response && err.response.data && !isHTML(errorData) && !isObject(errorData)) {
      errorMessage = err.response.data
    } else if (errorData.error && !isHTML(errorData.error)) {
      errorMessage = errorData.error
    }
    yield call(handleErrorSaga, err)
    yield put(
      updateOrderInfoActionFailure({
        error: errorMessage,
      })
    )
  }
}

function* updateAvailabilityInfoSaga({
  payload,
}: ISagaAction<IUpdateAvailabilityInfoPayload>) {
  try {
    const {
      id,
      agencyId,
      experts,
      candidate,
      use_general_link,
      enable_conversation_intelligence,
    } = payload

    yield* call(updateAvailabilityInfo, agencyId, id, {
      experts,
      candidate,
      interview: { use_general_link, enable_conversation_intelligence },
    })

    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(getInterview({ agencyId, id }))
    yield put(updateAvailabilityInfoActionSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    const errorData = get(err, 'response.data')
    if (err.response && err.response.data && !isHTML(errorData) && !isObject(errorData)) {
      errorMessage = err.response.data
    } else if (errorData.error && !isHTML(errorData.error)) {
      errorMessage = errorData.error
    }
    yield call(handleErrorSaga, err)
    yield put(
      updateAvailabilityInfoActionFailure({
        error: errorMessage,
      })
    )
  }
}

function* updateSchedulingInfoSaga({
  payload,
}: ISagaAction<IUpdateSchedulingInfoPayload>) {
  try {
    const { id, agencyId, interview, reschedule_request } = payload

    yield* call(updateSchedulingInfo, agencyId, id, { interview, reschedule_request })

    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(getInterview({ agencyId, id }))
    yield put(updateSchedulingInfoActionSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    const errorData = get(err, 'response.data')
    if (err.response && err.response.data && !isHTML(errorData) && !isObject(errorData)) {
      errorMessage = err.response.data
    } else if (errorData.error && !isHTML(errorData.error)) {
      errorMessage = errorData.error
    }
    yield call(handleErrorSaga, err)
    yield put(
      updateSchedulingInfoActionFailure({
        error: errorMessage,
      })
    )
  }
}

function* updatePostSessionInfoSaga({
  payload,
}: ISagaAction<IUpdatePostSessionInfoPayload>) {
  try {
    const {
      id,
      agencyId,
      hired_candidate,
      expert_paid,
      expert_payment_provider,
      expert_payment_reference,
    } = payload

    yield* call(updatePostSessionInfo, agencyId, id, {
      interview: {
        hired_candidate,
        expert_paid,
        expert_payment_provider,
        expert_payment_reference,
      },
    })

    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(getInterview({ agencyId, id }))
    yield put(updatePostSessionInfoActionSuccess())
  } catch (err) {
    let errorMessage = 'Server error. Please, contact support'
    const errorData = get(err, 'response.data')
    if (err.response && err.response.data && !isHTML(errorData) && !isObject(errorData)) {
      errorMessage = err.response.data
    } else if (errorData.error && !isHTML(errorData.error)) {
      errorMessage = errorData.error
    }
    yield call(handleErrorSaga, err)
    yield put(
      updatePostSessionInfoActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchUpdateOrderInfo() {
  yield takeEvery(updateOrderInfoActionType, updateOrderInfoSaga)
}

export function* watchUpdateAvailabilityInfo() {
  yield takeEvery(updateAvailabilityInfoActionType, updateAvailabilityInfoSaga)
}

export function* watchUpdateSchedulingInfo() {
  yield takeEvery(updateSchedulingInfoActionType, updateSchedulingInfoSaga)
}

export function* watchUpdatePostSessionInfo() {
  yield takeEvery(updatePostSessionInfoActionType, updatePostSessionInfoSaga)
}
