import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgMute({ fill }: SvgProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8965 1.74277C13.0401 0.813548 14.75 1.62741 14.75 3.10097V20.899C14.75 22.3726 13.0401 23.1864 11.8965 22.2572L6.34911 17.75H4C2.48122 17.75 1.25 16.5188 1.25 15V9C1.25 7.48122 2.48122 6.25 4 6.25H6.3491L11.8965 1.74277ZM13.25 3.10097C13.25 2.89046 13.0057 2.77419 12.8424 2.90694L6.75 7.85697V16.143L12.8424 21.0931C13.0057 21.2258 13.25 21.1095 13.25 20.899V3.10097ZM5.25 7.75V16.25H4C3.30964 16.25 2.75 15.6904 2.75 15V9C2.75 8.30964 3.30964 7.75 4 7.75H5.25Z"
        fill={fill}
      />
      <path
        d="M19.2166 12C19.2166 14.0231 18.1035 15.7441 16.5499 16.382V18C18.8502 17.3123 20.5499 14.8867 20.5499 12C20.5499 9.1133 18.8502 6.68773 16.5499 6V7.61801C18.1035 8.25586 19.2166 9.97694 19.2166 12Z"
        fill={fill}
      />
      <path
        d="M17.8833 12C17.8833 13.1465 17.347 14.1476 16.5499 14.6832V9.3168C17.347 9.85239 17.8833 10.8534 17.8833 12Z"
        fill={fill}
      />
    </svg>
  )
}

export default SvgMute
