import { push } from 'connected-react-router'
import { put, takeEvery, call } from 'typed-redux-saga'

import { deleteAgencyInterview } from '../../api'

import handleErrorSaga from '../../utils/handleErrorSaga'
import handleSuccessSaga from '../../utils/handleSuccessSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface IDeleteAgencyInterviewAction {
  agencyId: string
  id: string
}

const {
  action: deleteAgencyInterviewAction,
  actionFailure: deleteAgencyInterviewActionFailure,
  actionSuccess: deleteAgencyInterviewActionSuccess,
  actionSuccessClear: deleteAgencyInterviewActionSuccessClear,
  actionFailureClear: deleteAgencyInterviewActionFailureClear,
  actionType: deleteAgencyInterviewActionType,
  actionTypeFailure: deleteAgencyInterviewActionFailureType,
  actionTypeSuccess: deleteAgencyInterviewActionSuccessType,
  reducer: deleteAgencyInterviewRequestReducer,
  selectors: deleteAgencyInterviewSelectors,
} = reduxRequestFactory<IDeleteAgencyInterviewAction>('deleteAgencyInterview', 'requests')

export {
  deleteAgencyInterviewAction,
  deleteAgencyInterviewActionFailure,
  deleteAgencyInterviewActionSuccess,
  deleteAgencyInterviewActionSuccessClear,
  deleteAgencyInterviewActionFailureClear,
  deleteAgencyInterviewActionType,
  deleteAgencyInterviewActionFailureType,
  deleteAgencyInterviewActionSuccessType,
  deleteAgencyInterviewRequestReducer,
  deleteAgencyInterviewSelectors,
}

function* deleteAgencyInterviewSaga({
  payload,
}: ISagaAction<IDeleteAgencyInterviewAction>) {
  try {
    const { agencyId, id } = payload
    yield* call(deleteAgencyInterview, agencyId, id)
    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(deleteAgencyInterviewActionSuccess())
    yield put(push(`/agencies/${agencyId}/activity`))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      deleteAgencyInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchDeleteAgencyInterview() {
  yield takeEvery(deleteAgencyInterviewActionType, deleteAgencyInterviewSaga)
}
