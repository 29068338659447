import {
  AgencyExpertSessionType,
  AgencyTheme,
  ApplicantAvailabilityFlow,
  BusinessModel,
  FirstPageBehaviors,
  IAgency,
} from '../../api'
import { reduxDataFactory } from '../../utils/redux'

export const defaultAgency: IAgency = {
  id: '',

  additional_booking_url: '',
  additional_info: true,
  admin_booking: true,
  agency_managers: [],
  agency_positions: null,
  agency_price_tiers: null,
  allow_direct_registration: false,
  applicant_availability_flow: ApplicantAvailabilityFlow.NEW_FLOW,
  business_model: BusinessModel.B2B,
  code_sharing: null,
  company_logo: { url: null },
  created_at: '',
  currency: 'USD',
  direct_users_first_page: FirstPageBehaviors.Landing,
  display_topbar_footer: null,
  duration: 45,
  email: '',
  email_domain_validations: [],
  enable_ava: null,
  enable_conversation_intelligence: null,
  enable_experts: null,
  expert_session_type: AgencyExpertSessionType.FULL,
  has_ab_testing: false,
  ignored_emails: [],
  invited_users_first_page: FirstPageBehaviors.Landing,
  main_color: '',
  meeting_url: 'https://wizcocoaching.callbridge.com/conf/call/4210850',
  name: '',
  negative_logo: { url: null },
  priority_account: null,
  r_and_d: null,
  session_price: null,
  show_interview_links: null,
  subdomain: '',
  success_manager_email: null,
  success_manager_name: null,
  theme: AgencyTheme.NORMAL,
  updated_at: '',
  use_new_applicant_availability_flow: true,
}

const {
  action: updateAgencyAction,
  actionType: updateAgencyActionType,
  reducer: agencyDataReducer,
  selectors: agencySelectors,
} = reduxDataFactory<IAgency>('agency', 'agency', defaultAgency)

export { updateAgencyAction, updateAgencyActionType, agencyDataReducer, agencySelectors }
