import React from 'react'
import styled from '@emotion/styled'

import SvgFeedback from 'src/static/components/SvgFeedback'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

export const DetailsButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

const DetailsButtonContainer = styled(HeaderButtonContainer)`
  cursor: default;
`

interface IFeedbackButton extends React.HTMLProps<HTMLDivElement> {
  disabled: boolean
  active: boolean
  theme: any
  onClick: () => void
}

export default function FeedbackButton({
  disabled,
  active,
  theme,
  children,
  onClick,
}: IFeedbackButton) {
  const label = 'Feedback'
  return (
    <DetailsButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgFeedback fill={active ? theme.textColorActive : theme.textColor} />
        <DetailsButtonLabel active={active}>{label}</DetailsButtonLabel>
      </HeaderIconContainer>
      {children}
    </DetailsButtonContainer>
  )
}
