import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Statistics from '../components/Statistics'
import { getInterviews } from '../actions'

function StatisticsContainer() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInterviews({ params: {} }))
  }, [dispatch])

  return <Statistics />
}

export default StatisticsContainer
