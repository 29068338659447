import styled from '@emotion/styled'
import React from 'react'
import communicationIcon from 'src/static/report_communication.svg'
import { isMobileOnly } from 'react-device-detect'
import CommunicationInsightsComponent from './CommunicationInsightsComponent'
import AvaReportBlockContainer from './AvaReportBlockContainer'
import { ReportSubtitleText, ReportTitle } from './report-styled-components'
import CommunicationInsightsComponentMobile from './CommunicationInsightsComponentMobile'

const Container = styled.div``

const ResponseAssessmentsBlock = () => {
  return (
    <Container>
      {!isMobileOnly && (
        <>
          <ReportTitle>Response assessment</ReportTitle>
          <ReportSubtitleText>
            Comprehensive feedback on your answer&apos;s quality and delivery
          </ReportSubtitleText>
        </>
      )}
      <AvaReportBlockContainer icon={communicationIcon}>
        {!isMobileOnly && <CommunicationInsightsComponent />}
        {isMobileOnly && <CommunicationInsightsComponentMobile />}
      </AvaReportBlockContainer>
    </Container>
  )
}

export default ResponseAssessmentsBlock
