import { AudioMuteButton, AudioMuteButtonMobile } from './AudioMuteButton'
import CodeShareButton from './CodeShareButton'
import DetailsButton from './DetailsButton'
import { EndButton, EndButtonMobile } from './EndButton'
import MoreButton from './MoreButton'
import ScreenShareButton from './ScreenShareButton'
import SettingsButton from './SettingsButton'
import { VideoMuteButton, VideoMuteButtonMobile } from './VideoMuteButton'
import ChatButton from './ChatButton'
import CoachingButton from './CoachingButton'
import NotesButton from './NotesButton'
import FeedbackButton from './FeedbackButton'

export default {
  AudioMuteButton,
  AudioMuteButtonMobile,
  CodeShareButton,
  DetailsButton,
  EndButton,
  EndButtonMobile,
  MoreButton,
  ScreenShareButton,
  SettingsButton,
  VideoMuteButton,
  VideoMuteButtonMobile,
  ChatButton,
  CoachingButton,
  NotesButton,
  FeedbackButton,
}
