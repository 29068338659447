import React from 'react'

import BulkInvites from '../components/BulkInvites'
import Layout from '../Layout'

function AgencyBulkContainer() {
  return (
    <Layout title="Bulk Invites" hideBg fullBody>
      <BulkInvites />
    </Layout>
  )
}

export default AgencyBulkContainer
