import { put, takeEvery, call } from 'typed-redux-saga'

import {
  bulkCreateAvaInterview,
  bulkCreateInterview,
  IBulkInterviewData,
  uploadBulkInterviewFile,
} from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateBookingInfoAction } from '../data/bookingInfo'

interface IBulkCreateInterviewAction {
  agencyId: string
  isAvaInterview: boolean
  data: IBulkInterviewData[]
  file: any
}

const {
  action: bulkCreateInterviewAction,
  actionFailure: bulkCreateInterviewActionFailure,
  actionSuccess: bulkCreateInterviewActionSuccess,
  actionSuccessClear: bulkCreateInterviewActionSuccessClear,
  actionFailureClear: bulkCreateInterviewActionFailureClear,
  actionType: bulkCreateInterviewActionType,
  actionTypeFailure: bulkCreateInterviewActionFailureType,
  actionTypeSuccess: bulkCreateInterviewActionSuccessType,
  reducer: bulkCreateInterviewRequestReducer,
  selectors: bulkCreateInterviewSelectors,
} = reduxRequestFactory<IBulkCreateInterviewAction>('bulkCreateInterview', 'requests')

export {
  bulkCreateInterviewAction,
  bulkCreateInterviewActionFailure,
  bulkCreateInterviewActionSuccess,
  bulkCreateInterviewActionSuccessClear,
  bulkCreateInterviewActionFailureClear,
  bulkCreateInterviewActionType,
  bulkCreateInterviewActionFailureType,
  bulkCreateInterviewActionSuccessType,
  bulkCreateInterviewRequestReducer,
  bulkCreateInterviewSelectors,
}

function* bulkCreateInterviewSaga(action: ISagaAction<IBulkCreateInterviewAction>) {
  try {
    const { agencyId, data, file, isAvaInterview } = action.payload
    const { file: uploadedFile } = yield* call(uploadBulkInterviewFile, file)
    const { count } = yield* call(
      isAvaInterview ? bulkCreateAvaInterview : bulkCreateInterview,
      agencyId,
      uploadedFile.id,
      data
    )
    yield put(bulkCreateInterviewActionSuccess())
    yield put(updateBookingInfoAction({ count }))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      bulkCreateInterviewActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchBulkCreateInterview() {
  yield takeEvery(bulkCreateInterviewActionType, bulkCreateInterviewSaga)
}
