/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import SvgPrice from 'src/static/components/SvgPrice'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'
import { CheckboxButton } from '../../components'
import { IAgency, IAgencyPriceTier } from '../../api'

const Container = styled.div`
  width: 100%;
  margin: 40px 0 32px 0;
  display: flex;
  flex-direction: column;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const TitleText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #21304e;
`

const InfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin: 16px 0;
  color: #979797;
`

const PriceTireContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 16px 12px 16px 46px;
`

const TireTitle = styled.div<{ selected: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #21304e;
  flex: 1;
  opacity: ${(props) => (props.selected ? 1 : 0.81)};
`

const PriceTitle = styled.div<{ fill: string }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.fill || '#6938AA'};
  margin-left: 8px;
`

const PriceInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #353c5a;
  margin-top: 8px;
`

interface IPriceTierProps {
  agency: IAgency
  item: IAgencyPriceTier
  selected: boolean
  onSelect: (item: IAgencyPriceTier) => void
}

function PriceTier({ agency, item, selected, onSelect }: IPriceTierProps) {
  if (!item) return null

  return (
    <CheckboxButton
      defaultValue
      useTheme
      name={item.name}
      checked={selected}
      top={18}
      left={12}
      onChange={() => onSelect(item)}
    >
      <PriceTireContainer>
        <ColumnContainer>
          <RowContainer>
            <TireTitle selected={selected}>{item.name}</TireTitle>
            <SvgPrice fill={agency.main_color} />
            <PriceTitle
              fill={agency.main_color}
            >{`${agency.currency_sign}${item.price_min} - ${item.price_max}`}</PriceTitle>
          </RowContainer>
          <PriceInfo>{item.description}</PriceInfo>
        </ColumnContainer>
      </PriceTireContainer>
    </CheckboxButton>
  )
}

interface IProps {
  onChange: (items: IAgencyPriceTier[]) => void
}

export default function PriceTiers({ onChange }: IProps) {
  const agency = useSelector(interviewSelectors.agency)
  const [selectedItems, setSelectedItems] = useState([])

  function onSelect(item: IAgencyPriceTier) {
    const items = [...selectedItems]
    if (items.some((i) => i.name === item.name)) {
      const indexOf = items.findIndex((i) => i.name === item.name)
      items.splice(indexOf, 1)
    } else {
      items.push(item)
    }
    setSelectedItems(items)
    onChange(items)
  }

  const senior = agency.agency_price_tiers?.find((t) => t.name === 'Senior Collaborator')
  const teamLead = agency.agency_price_tiers?.find((t) => t.name === 'Team Lead')
  const executive = agency.agency_price_tiers?.find((t) => t.name === 'Executive')

  return (
    <Container>
      <TitleText>YOUR DESIRED EXPERT</TitleText>
      <InfoText>
        We&apos;ll ask you to pay only once we&apos;ve found your perfect expert match and
        you&apos;re ready to schedule your session.
      </InfoText>
      <PriceTier
        selected={selectedItems.some((i) => i.name === senior.name)}
        item={senior}
        agency={agency}
        onSelect={onSelect}
      />
      <PriceTier
        selected={selectedItems.some((i) => i.name === teamLead.name)}
        item={teamLead}
        agency={agency}
        onSelect={onSelect}
      />
      <PriceTier
        selected={selectedItems.some((i) => i.name === executive.name)}
        item={executive}
        agency={agency}
        onSelect={onSelect}
      />
    </Container>
  )
}
