import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { isEqualDate, normalizeDate } from 'src/utils/date'
import { IExpertProfile, ITimeSlot } from 'src/api/models'
import SvgCalendar from 'src/static/components/SvgCalendar'
import SvgPrice from 'src/static/components/SvgPrice'
import isSlotExpired from 'src/utils/slots'
import useBranding from 'src/utils/useBranding'

const StartScrollHeight = 500
const Container = styled.div``

const ScrollWrapper = styled.div`
  width: 500px;
  .rcs-custom-scroll .rcs-inner-handle {
    background-color: rgba(150, 150, 150, 0.4);
  }

  @media screen and (max-width: 600px) {
    width: auto;
    .rcs-custom-scroll {
      height: auto !important;
    }
  }
`

const ScrollView = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ height }) => height}px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }
`

const DayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #edeef2;
  padding: 12px 0 6px 0;
`

const ExpertName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #3b424f;
`

const DayLabelContainer = styled.div`
  display: flex;
  width: 108px;
  align-items: center;
  justify-content: space-between;

  > svg {
    margin-right: 15px;
  }
`

const WeekDay = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #353c5a;
`

const DateLabel = styled(WeekDay)`
  font-weight: 500;
`

const SlotContainer = styled.div<{ selected?: boolean; fill?: string }>`
  width: 76px;
  height: 40px;
  background: ${({ selected, fill }) => (selected ? fill ?? '#B202FF' : '#ffffff')};
  border: 1px solid #e1e3ea;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${({ selected }) => (selected ? 'white' : '#3b424f')};
  margin: 6px 8px 6px 8px;
  cursor: pointer;

  &:nth-child(5n + 6) {
    margin-left: 24px;
  }
`

const ExpertFooter = styled.div<{ centered?: boolean }>`
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
  border-top: 1px solid #edeef2;
  border-bottom: 1px solid #edeef2;
`

const SelectedSlotDetails = styled.div`
  height: 50px;
  border-top: 1px solid #edeef2;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: break-spaces;
`

const BottomContainer = styled.div``

const PriceBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`

const PriceText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #200e32;
  margin: 0 4px;
`

const Button = styled.div<{ fill: string; disabled?: boolean }>`
  background: ${(props) => props.fill ?? '#B202FF'};
  border-radius: 4px;
  color: white;
  width: 162px;
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`

const OutlineButton = styled(Button)`
  color: ${(props) => props.fill ?? '#B202FF'};
  border: 1px solid ${(props) => props.fill ?? '#B202FF'};
  background: white;
  height: auto;
  width: auto;
  padding: 5px 16px;
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 100px;

  &:hover {
    opacity: 1;
    background: ${(props) => props.fill ?? '#B202FF'};
    color: white;
  }
  &:active {
    opacity: 0.5;
  }
`

const EmptyContainer = styled.div<{ boldText?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: ${({ boldText }) => (boldText ? 500 : 300)};
  font-size: 14px;
  line-height: 26px;
  color: #3b424f;
`

interface IProps {
  expert?: IExpertProfile
  sessionPrice?: string
  onSlotSelect: (slot: ITimeSlot) => void
  onSlotRequested: () => void
}

export default function InterviewSlotsList({
  expert,
  sessionPrice,
  onSlotSelect,
  onSlotRequested,
}: IProps) {
  const [days, setDays] = useState([])
  const [selectedSlot, setSelectedSlot] = useState<ITimeSlot>(null)
  const { agencyColor } = useBranding()

  const getDaySlots = (date) => {
    const daySlots = expert.time_slots.filter(
      (s) => isEqualDate(s.from, date) && !isSlotExpired(s.from)
    )
    return daySlots
  }

  const getSlotDetails = (slot: ITimeSlot) => {
    const timeStamp = normalizeDate(slot.from)
    const date = format(timeStamp, 'MM/dd')
    const weekDay = format(timeStamp, 'eee')
    const time = format(timeStamp, 'h:mm a')
    return (
      <>
        <WeekDay>{weekDay}, </WeekDay>
        <DateLabel>{date}</DateLabel>
        <WeekDay>{`  |  ${time}`}</WeekDay>
      </>
    )
  }

  useEffect(() => {
    const newDays = []
    expert?.time_slots?.forEach((s) => {
      if (!newDays.some((d) => isEqualDate(d, s.from)) && !isSlotExpired(s.from)) {
        newDays.push(s.from)
      }
    })
    setDays(newDays)
    setSelectedSlot(null)
  }, [expert])

  return (
    <ScrollWrapper>
      <ScrollView height={StartScrollHeight - 64 - (selectedSlot ? 50 : 0)}>
        {days.length === 0 && !expert && (
          <EmptyContainer>Select an expert to view their availability</EmptyContainer>
        )}
        {days.length === 0 && expert && (
          <EmptyContainer boldText>
            {expert.first_name}&apos;s time slots have expired
            <OutlineButton fill={agencyColor} onClick={onSlotRequested}>
              Request new time slots
            </OutlineButton>
          </EmptyContainer>
        )}
        {days.length > 0 && expert && (
          <Container>
            <ExpertName>
              Schedule for {expert.first_name} {expert.last_name}
            </ExpertName>
            {days.map((day) => {
              const timeStamp = normalizeDate(day)
              const date = format(timeStamp, 'MM/dd')
              const weekDay = format(timeStamp, 'eee')
              return (
                <DayContainer key={day}>
                  <DayLabelContainer>
                    <SvgCalendar fill="black" />
                    <WeekDay>{weekDay}, </WeekDay>
                    <DateLabel>{date}</DateLabel>
                  </DayLabelContainer>
                  {getDaySlots(day).map((slot) => {
                    const slotTimeStamp = normalizeDate(slot.from)
                    const time = format(slotTimeStamp, 'h:mm a')
                    return (
                      <SlotContainer
                        key={slot.id}
                        onClick={() => setSelectedSlot(slot)}
                        fill={agencyColor}
                        selected={selectedSlot?.id === slot.id}
                      >
                        {time}
                      </SlotContainer>
                    )
                  })}
                </DayContainer>
              )
            })}
          </Container>
        )}
      </ScrollView>
      <BottomContainer>
        {selectedSlot && (
          <SelectedSlotDetails>{getSlotDetails(selectedSlot)}</SelectedSlotDetails>
        )}
        <ExpertFooter centered={!sessionPrice}>
          {sessionPrice && (
            <PriceBlock>
              <SvgPrice fill={agencyColor} />
              <PriceText>{`Session price: ${sessionPrice}`}</PriceText>
            </PriceBlock>
          )}
          <Button
            onClick={() => onSlotSelect(selectedSlot)}
            fill={agencyColor}
            disabled={!selectedSlot}
          >
            Book a session
          </Button>
        </ExpertFooter>
      </BottomContainer>
    </ScrollWrapper>
  )
}

InterviewSlotsList.defaultProps = {
  expert: null,
  sessionPrice: '',
}
