import React from 'react'

import SvgAudio from 'src/static/components/SvgAudio'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
  MobileHeaderButtonContainer,
  MobileHeaderButtonLabel,
} from './BaseButtons'

interface IAudioMuteButton {
  active: boolean
  disabled: boolean
  theme: any
  onClick: () => void
}

export function AudioMuteButton({ active, disabled, theme, onClick }: IAudioMuteButton) {
  const label = active ? 'Mute' : 'Unmute'
  return (
    <HeaderButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgAudio inactive={!active} disabled={disabled} fill={theme.textColor} />
        <HeaderButtonLabel>{label}</HeaderButtonLabel>
      </HeaderIconContainer>
    </HeaderButtonContainer>
  )
}

export function AudioMuteButtonMobile({
  active,
  disabled,
  theme,
  onClick,
}: IAudioMuteButton) {
  const label = active ? 'Mute' : 'Unmute'
  return (
    <MobileHeaderButtonContainer onClick={!disabled ? onClick : undefined}>
      <SvgAudio inactive={!active} disabled={disabled} fill={theme.textColor} />
      <MobileHeaderButtonLabel>{label}</MobileHeaderButtonLabel>
    </MobileHeaderButtonContainer>
  )
}
