import { put, takeEvery, call } from 'typed-redux-saga'

import { downloadAdminReport } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface IDownloadAgencyReportAction {
  id: string
}

const {
  action: downloadAgencyReportAction,
  actionFailure: downloadAgencyReportActionFailure,
  actionSuccess: downloadAgencyReportActionSuccess,
  actionSuccessClear: downloadAgencyReportActionSuccessClear,
  actionFailureClear: downloadAgencyReportActionFailureClear,
  actionType: downloadAgencyReportActionType,
  actionTypeFailure: downloadAgencyReportActionFailureType,
  actionTypeSuccess: downloadAgencyReportActionSuccessType,
  reducer: downloadAgencyReportRequestReducer,
  selectors: downloadAgencyReportSelectors,
} = reduxRequestFactory<IDownloadAgencyReportAction>('downloadAgencyReport', 'requests')

export {
  downloadAgencyReportAction,
  downloadAgencyReportActionFailure,
  downloadAgencyReportActionSuccess,
  downloadAgencyReportActionSuccessClear,
  downloadAgencyReportActionFailureClear,
  downloadAgencyReportActionType,
  downloadAgencyReportActionFailureType,
  downloadAgencyReportActionSuccessType,
  downloadAgencyReportRequestReducer,
  downloadAgencyReportSelectors,
}

function* downloadAgencyReportSaga(action: ISagaAction<IDownloadAgencyReportAction>) {
  try {
    const { id } = action.payload

    const report = yield* call(downloadAdminReport, id)

    const element = document.createElement('a')
    const file = new Blob([report], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'activity_table.csv'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()

    yield put(downloadAgencyReportActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      downloadAgencyReportActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchDownloadAgencyReport() {
  yield takeEvery(downloadAgencyReportActionType, downloadAgencyReportSaga)
}
