import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import styled from '@emotion/styled'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import FormControl from '@material-ui/core/FormControl'
import { get } from 'lodash'
import { Chip } from '@material-ui/core'
import { css } from '@emotion/core'
import colors from '../static/colors'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon htmlColor="#b202ff" fontSize="small" />

const themes = {
  default: {
    borderColor: colors.formActiveAccentColor,
  },
  blue: {
    borderColor: '#0095FF',
  },
}

const disabledStyles = {
  borderColor: '#E1E3EA',
  backgroundColor: '#F5F6F8',
  opacity: '0.6',
  color: '#21304E',
}

interface IStyledInputProps {
  isSelected: boolean
  disabled: boolean
  useTheme?: boolean
  hasError: boolean
  customTheme?: string
  customOnchange?: any
}

interface IStyledTextProps {
  useTheme?: boolean
  hasError: boolean
  customTheme?: string
}

const getColor = ({ customTheme, theme, useTheme }: any) => {
  if (theme.agencyColor && useTheme) {
    return theme.agencyColor
  }

  if (themes[customTheme]) {
    return themes[customTheme].borderColor
  }

  return '#B202FF'
}

const StyledWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  min-height: 66px;
  border: 1px solid #e1e3ea;
  border-radius: 6px;
  background-color: #f5f6f8;
  margin-bottom: 21px;
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #edeef2;
        border-color: #e1e3ea;
      }
    `}
`

const StyledFormControl = styled(FormControl)`
  background-color: #f5f6f8;
  width: 100%;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  ${({ disabled }) =>
    disabled &&
    css`
    > div {
      > div {
        > div {
          > div {
            > fieldset {
              border-color: transparent !important;
            }
          }
        }
      }
    `}
  }
`

const StyledSelect = styled(Autocomplete)<IStyledInputProps>`
  outline: none;
  margin-top: 0 !important;
  width: 100%;
  min-height: 44px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background: transparent;
  border-radius: 4px;

  color: #21304e;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;

  &.Mui-focused {
    background: #fff;
  }

  .MuiSelect-root {
    background: none !important;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Rubik';
    padding-left: 9px;
    padding-top: 18px;
  }

  .MuiInputBase-root {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Rubik';
  }

  &:disabled {
    ${disabledStyles};
    border-color: transparent;
    cursor: not-allowed;
  }
  &:before,
  &:after {
    display: none;
  }
`

const StyledInput = styled.div<IStyledTextProps>`
  .MuiInputLabel-root {
    transform: translate(9px, 20px) scale(1);
    font-size: 12px;
    font-family: 'Rubik';
    font-weight: 500;
    letter-spacing: 1px;
    opacity: 0.5;
    color: #21304e;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      z-index: 2;
      font-size: 10px;
      transform: translate(9px, 5px) scale(1);
    }
    &.MuiInputLabel-outlined.MuiInputLabel-shrink {
      letter-spacing: 0.7px;
      font-size: 10px;
      background: transparent;
      transform: translate(14px, 5px) scale(1);
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 6px 65px 6px 9px;
    .MuiAutocomplete-input {
      padding: 15px 4px 5px;
    }
  }
  .MuiChip-root {
    height: auto;
    padding: 2px;
    font-size: 12px;
    font-family: 'Rubik', Arial, sans-serif;
    font-weight: 500;
    .MuiChip-label {
      overflow: initial;
      white-space: normal;
      text-overflow: initial;
    }
  }
  .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    span {
      display: none;
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ customTheme, hasError, theme, useTheme }) => {
      if (hasError) return 'transparent'
      if (customTheme || useTheme) return getColor({ customTheme, theme, useTheme })

      return '#b202ff'
    }};
    border-width: 1px;
  }
  .MuiAutocomplete-tag {
    margin: 14px 3px 0px;
  }
`

const CheckboxWrapper = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const ChipContainer = styled.div<{ inline: boolean }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  flex-wrap: ${({ inline }) => (inline ? 'wrap' : 'no-wrap')};
  margin-top: 20px;
`

const OptionStyled = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  width: 100%;
  padding: 8px;
  color: black;
`

const SelectComponent = (props: any) => {
  const {
    onChange,
    value,
    label,
    options,
    readonlyOptions = [],
    error,
    disabled,
    customTheme,
    useTheme,
    customOnchange,
    multiple,
    disableCloseOnSelect,
    inline,
    noOptionsText,
  } = props
  const hasError = !!error.type

  const onDeleteItem = (item: any) => {
    const index = (options as any[]).indexOf((o) => o.label === item.label)
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  return (
    <StyledWrapper disabled={disabled}>
      <StyledFormControl disabled={disabled} error={error}>
        <StyledSelect
          multiple={multiple}
          options={options}
          disableCloseOnSelect={disableCloseOnSelect}
          noOptionsText={noOptionsText}
          getOptionLabel={(option) => get(option, 'label')}
          renderTags={(val) => (
            <ChipContainer inline={inline}>
              {val.map((v) => {
                const canDelete = !readonlyOptions.some(
                  (opt) => opt.value === (v as any).value
                )
                const itemLabel = (v as any).label
                return (
                  <Chip
                    key={itemLabel}
                    label={itemLabel}
                    style={{
                      marginBottom: 6,
                      height: 30,
                      width: 'fit-content',
                      marginRight: inline ? 5 : 0,
                    }}
                    onDelete={canDelete ? () => onDeleteItem(v) : null}
                  />
                )
              })}
            </ChipContainer>
          )}
          renderOption={(option, { selected }) => {
            const lab = get(option, 'label')
            if (multiple) {
              return (
                <CheckboxWrapper
                  disabled={readonlyOptions?.some(
                    (opt) => opt.value === (option as any).value
                  )}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {lab}
                </CheckboxWrapper>
              )
            }
            return <OptionStyled> {lab}</OptionStyled>
          }}
          renderInput={(params) => (
            <StyledInput
              customTheme={customTheme}
              hasError={hasError}
              useTheme={useTheme}
            >
              <TextField
                // eslint-disable-next-line
                {...params}
                variant="outlined"
                label={label}
              />
            </StyledInput>
          )}
          value={value}
          onChange={(e, val) => {
            let newVal = val as any[]
            if (multiple) {
              const isReadonlyDeleted =
                newVal.filter((v) =>
                  readonlyOptions.some((opt) => opt.value === (v as any).value)
                ).length !== readonlyOptions.length
              if (isReadonlyDeleted) {
                newVal = newVal.filter(
                  (v) => !readonlyOptions.some((opt) => opt.value === (v as any).value)
                )
                readonlyOptions.forEach((v) => newVal.push(v))
              }
            }
            onChange(newVal)
            if (customOnchange) customOnchange(newVal)
          }}
          useTheme={useTheme}
          disabled={disabled}
          hasError={hasError}
          isSelected={!!value}
          customTheme={customTheme}
        />
      </StyledFormControl>
    </StyledWrapper>
  )
}

SelectComponent.defaultProps = {
  error: {
    type: '',
    message: '',
    ref: '',
  },
  useTheme: false,
  customTheme: '',
  options: [],
  disableCloseOnSelect: true,
  inline: false,
  noOptionsText: 'No options',
}

export default SelectComponent
