import { IAvaQuestion } from '../../../api'
import { reduxDataFactory } from '../../../utils/redux'

const {
  action: updateAvaQuestionAction,
  actionType: updateAvaQuestionActionType,
  reducer: avaQuestionDataReducer,
  selectors: avaQuestionSelectors,
} = reduxDataFactory<IAvaQuestion>('question', 'ava')

const clearAvaQuestionAction = updateAvaQuestionAction(undefined)

export {
  updateAvaQuestionAction,
  updateAvaQuestionActionType,
  avaQuestionDataReducer,
  avaQuestionSelectors,
  clearAvaQuestionAction,
}
