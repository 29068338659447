import { NetworkQuality, UID } from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk'
import useStateRef from 'react-usestateref'

import { getRTMAgoraToken } from '../../api'
import makeRandom from './random'

const CHANNEL = 'test'

const consoleStyles = ['%c%s', 'font-weight: bold;', '[Wizco RTM]']

export enum RtmCommands {
  PleaseStopScreensharingForMe = 'PLEASE_STOP_SCREENSHARING_FOR_ME',
  CallEnded = 'CALL_ENDED',
  OrientationChangedLandscape = 'ORIENTATION_CHANGED_LANDSCAPE',
  OrientationChangedPortrait = 'ORIENTATION_CHANGED_PORTRAIT',
  PeerJoinedCoding = 'PEER_JOINED_CODE',
  PeerLeftCoding = 'PEER_LEFT_CODE',
  PeerNetworkQuality = 'PEER_NETWORK_QUALITY',
  ConnectAltRoom = 'CONNECT_ALT_ROOM',
}

const useRtm = (callUid: UID, channelId = CHANNEL) => {
  const [joinedToRtm, setJoinedToRtm, joinedToRtmRef] = useStateRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [joinedToRtmFailure, setJoinedToRtmFailure, joinedToRtmFailureRef] = useStateRef(
    false
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [leftRtm, setLeftRtm, leftRtmRef] = useStateRef(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inCodesharing, setInCodesharing, inCodesharingRef] = useStateRef(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orientation, setOrientation, orientationRef] = useStateRef(false)

  const [uid, setUid, uidRef] = useStateRef<UID>()
  const [token, setToken, tokenRef] = useStateRef<string>()
  const [client, setClient, clientRef] = useStateRef<
    ReturnType<typeof AgoraRTM.createInstance>
  >()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channel, setChannel, channelRef] = useStateRef<
    ReturnType<typeof client.createChannel>
  >()

  const [lastMessage, setLastMessage] = useStateRef(null)

  const error = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.error(...consoleStyles, items.toString())
  const log = (...items: string[]) =>
    // eslint-disable-next-line no-console
    console.log(...consoleStyles, items.toString())

  const generateUid = () => {
    const saltLenght = 5
    const limit = 64
    const userName = `${callUid}_${makeRandom(10)}`
    let rtmUid = `${userName}_${makeRandom(saltLenght)}`
    if (userName.length > limit - saltLenght - 1) {
      rtmUid = `${userName.slice(0, limit - saltLenght - 1)}_${makeRandom(5)}`
    }
    setUid(rtmUid)
  }

  const generateToken = async () => {
    const data = await getRTMAgoraToken(uidRef.current as string)
    setToken(data.token)
  }

  const sendRtmMessage = async (message) => {
    if (!joinedToRtmRef.current) {
      log('Message skipped. Not joined')
      return
    }
    channelRef.current
      .sendMessage(message)
      .then(() => log(`Message sent ${message.text}`))
      .catch((err) => error(`Message sent error: ${err}`))
  }

  const handleConnectionStateChanged = (state, reason) => {
    log(`Connection state changed to ${state} [${reason}]`)
    switch (state) {
      case 'CONNECTED':
        channelRef.current.join().then(() => {
          setJoinedToRtm(true)
        })
        break
      case 'ABORTED':
        clientRef.current.logout()
        // eslint-disable-next-line no-case-declarations
        const timer = setInterval(() => {
          clientRef.current
            .login({
              uid: uidRef.current as string,
              token: tokenRef.current,
            })
            .then(() => {
              clearInterval(timer)
            })
            .catch((err) => {
              log(`Relogin failed: ${err}`)
            })
        }, 10000)
        break
      case 'CONNECTING':
      case 'DISCONNECTED':
        break
      default:
        try {
          channelRef.current.leave().then(() => {
            setJoinedToRtm(false)
          })
        } catch (e) {
          error(e as string)
        }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMemberJoined = async (memberId) => {
    await sendRtmMessage({
      text: orientationRef.current
        ? RtmCommands.OrientationChangedPortrait
        : RtmCommands.OrientationChangedLandscape,
    })

    if (inCodesharingRef.current) {
      await sendRtmMessage({ text: RtmCommands.PeerJoinedCoding })
    }
  }

  const handleTokenExpired = async () => {
    await generateToken()
    clientRef.current.renewToken(tokenRef.current)
  }

  const handleChannelMessage = (message) => {
    setLastMessage(message)
  }

  const joinRtm = async () => {
    do {
      try {
        generateUid()
        // eslint-disable-next-line no-await-in-loop
        await generateToken()
      } catch (e) {
        error(`Failed to generate token for RTM: ${JSON.stringify(e)}`)
        // setJoinedToRtmFailure(true)
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 5000))
        // eslint-disable-next-line no-continue
        continue
      }

      // eslint-disable-next-line no-await-in-loop
      // await new Promise((resolve) => setTimeout(resolve, 10000))

      setClient(AgoraRTM.createInstance(process.env.REACT_APP_AGORA_APP_ID))
      setChannel(clientRef.current.createChannel(channelId))
      clientRef.current.on('ConnectionStateChanged', handleConnectionStateChanged)
      clientRef.current.on('TokenExpired', handleTokenExpired)

      try {
        // eslint-disable-next-line no-await-in-loop
        await clientRef.current.login({
          uid: uidRef.current as string,
          token: tokenRef.current,
        })
        channelRef.current.on('ChannelMessage', handleChannelMessage)
        channelRef.current.on('MemberJoined', handleMemberJoined)
        setJoinedToRtmFailure(false)
        log('Joined successfully')
      } catch (e) {
        error(`Failed to log into RTM: ${JSON.stringify(e)}`)
        setJoinedToRtmFailure(true)
      }
    } while (joinedToRtmFailureRef.current && !leftRtmRef.current)
  }

  const leaveRtm = async () => {
    await clientRef.current?.logout()
    setLeftRtm(true)
    log('Left successfully')
  }

  const sendCallEnded = async () => {
    await sendRtmMessage({ text: RtmCommands.CallEnded })
  }

  const sendCodeshareStatus = async (status: boolean) => {
    setInCodesharing(status)
    await sendRtmMessage({
      text: status ? RtmCommands.PeerJoinedCoding : RtmCommands.PeerLeftCoding,
    })
  }

  const sendInterruptScreenshare = async () => {
    await sendRtmMessage({ text: RtmCommands.PleaseStopScreensharingForMe })
  }

  const sendOrientationStatus = async (isPortrait: boolean) => {
    setOrientation(isPortrait)
    await sendRtmMessage({
      text: isPortrait
        ? RtmCommands.OrientationChangedPortrait
        : RtmCommands.OrientationChangedLandscape,
    })
  }

  const sendNetworkQuality = async (quality: NetworkQuality) => {
    await sendRtmMessage({
      text: `${RtmCommands.PeerNetworkQuality}_${quality.downlinkNetworkQuality}_${quality.uplinkNetworkQuality}`,
    })
  }

  const sendConnectAltRoom = async () => {
    await sendRtmMessage({
      text: RtmCommands.ConnectAltRoom,
    })
  }

  return {
    rtmJoined: joinedToRtm,
    rtmLastMessage: lastMessage,
    rtmToken: token,
    rtmUid: uid,
    rtmError: joinedToRtmFailure,
    joinRtm,
    leaveRtm,
    sendCallEnded,
    sendCodeshareStatus,
    sendInterruptScreenshare,
    sendOrientationStatus,
    sendNetworkQuality,
    sendConnectAltRoom,
  }
}

export default useRtm
