import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { NotFound } from '../components'
import AuthRoute from './components/AuthRoute'
import Login from './containers/Login'
import Reset from './containers/Reset'
import ResetSuccess from './containers/ResetSuccess'
import ChangePassword from './containers/ChangePassword'
import ChangePasswordSuccess from './containers/ChangePasswordSuccess'

function Auth() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/login`} />
      </Route>
      <AuthRoute path={`${path}/login`}>
        <Login />
      </AuthRoute>
      <Route path={`${path}/reset`}>
        <Reset />
      </Route>
      <Route path={`${path}/reset_success`}>
        <ResetSuccess />
      </Route>
      <Route path={`${path}/change_password`}>
        <ChangePassword />
      </Route>
      <Route path={`${path}/change_password_success`}>
        <ChangePasswordSuccess />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Auth
