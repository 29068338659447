import { IConversationToken } from '../api'
import { reduxDataFactory, reduxRequestFactory } from '../utils/redux'

const {
  action: generateConversationTokenAction,
  actionFailure: generateConversationTokenActionFailure,
  actionSuccess: generateConversationTokenActionSuccess,
  actionSuccessClear: generateConversationTokenActionSuccessClear,
  actionFailureClear: generateConversationTokenActionFailureClear,
  actionType: generateConversationTokenActionType,
  actionTypeFailure: generateConversationTokenActionFailureType,
  actionTypeSuccess: generateConversationTokenActionSuccessType,
  reducer: generateConversationTokenReducer,
  selectors: generateConversationTokenSelectors,
} = reduxRequestFactory<void>('generateConversationToken', 'interview')

const {
  action: conversationTokenAction,
  actionType: conversationTokenActionType,
  reducer: conversationTokenReducer,
  selectors: conversationTokenSelectors,
} = reduxDataFactory<IConversationToken>('conversationToken', 'symbl')

export interface ILinkConversationActionPayload {
  conversationId: string
  interviewToken: string
}

const {
  action: linkConversationAction,
  actionFailure: linkConversationActionFailure,
  actionSuccess: linkConversationActionSuccess,
  actionSuccessClear: linkConversationActionSuccessClear,
  actionFailureClear: linkConversationActionFailureClear,
  actionType: linkConversationActionType,
  actionTypeFailure: linkConversationActionFailureType,
  actionTypeSuccess: linkConversationActionSuccessType,
  reducer: linkConversationReducer,
  selectors: linkConversationSelectors,
} = reduxRequestFactory<ILinkConversationActionPayload>('linkConversation', 'interview')

export {
  generateConversationTokenAction,
  generateConversationTokenActionFailure,
  generateConversationTokenActionSuccess,
  generateConversationTokenActionSuccessClear,
  generateConversationTokenActionFailureClear,
  generateConversationTokenActionType,
  generateConversationTokenActionFailureType,
  generateConversationTokenActionSuccessType,
  generateConversationTokenReducer,
  generateConversationTokenSelectors,
  conversationTokenAction,
  conversationTokenActionType,
  conversationTokenReducer,
  conversationTokenSelectors,
  linkConversationAction,
  linkConversationActionFailure,
  linkConversationActionSuccess,
  linkConversationActionSuccessClear,
  linkConversationActionFailureClear,
  linkConversationActionType,
  linkConversationActionFailureType,
  linkConversationActionSuccessType,
  linkConversationReducer,
  linkConversationSelectors,
}
