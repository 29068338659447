import React from 'react'
import styled from '@emotion/styled'
import students from '../static/students_bg.jpg'
import studentsMobile from '../static/sidebar-student-mobile.jpg'
import studentSuccess from '../static/student_success.jpg'
import studentSuccessMobile from '../static/sidebar-student-success-mobile.jpg'
import { Title, Text } from '../components'
import SidebarComponent from './components/Sidebar'
import SidebarSuccessComponent from './components/SidebarSuccess'

interface ISidebarProps {
  success?: boolean
}

const Layout = styled.div`
  display: flex;
  min-height: 100vh;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const Sidebar = styled.div<ISidebarProps>`
  border-radius: 0 24px 24px 0;
  width: 640px;
  background: url(${(props) => (props.success ? studentSuccess : students)}) 0 0 no-repeat;
  background-color: #0c1415;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  padding: 0 85px 85px 0;
  padding-left: ${(props) => (props.success ? '90px' : '160px')};
  padding-top: ${(props) => (props.success ? '185px' : '222px')};
  box-sizing: border-box;
  background-size: 100% auto;

  @media screen and (max-width: 600px) {
    padding: 30px;
    width: 100%;
    border-radius: 0;
    position: relative;
    z-index: 1;
    background: url(${(props) => (props.success ? studentSuccessMobile : studentsMobile)})
      0 0 no-repeat;
    background-size: cover;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 40px;

  @media screen and (max-width: 600px) {
    justify-content: normal;
    padding: 30px;
    border-radius: 24px 24px 0 0;
    position: relative;
    z-index: 2;
    margin-top: -20px;
    background: #fff;
    padding-top: 50px;
  }
`

const Body = styled.div`
  width: 400px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

interface IStudentLayoutProps {
  children: JSX.Element
  title: string
  subtitle: string
  success?: boolean
  expert?: boolean
}

function StudentLayout(props: IStudentLayoutProps) {
  const { children, title, subtitle, success, expert } = props

  return (
    <Layout>
      <Sidebar success={success}>
        {!success && <SidebarComponent />}
        {success && <SidebarSuccessComponent expert={expert} />}
      </Sidebar>
      <Content>
        <Body>
          <Title>{title}</Title>
          <Text>{subtitle}</Text>
          {children}
        </Body>
      </Content>
    </Layout>
  )
}

StudentLayout.defaultProps = {
  success: false,
  expert: false,
}

export default StudentLayout
