import React from 'react'
import styled from '@emotion/styled'

function pad(string) {
  return `0${string}`.slice(-2)
}

function format(seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

const Container = styled.time`
  opacity: 0.5;

  &:nth-child(1) {
    opacity: 1;
  }
`

export default function Duration({ seconds }: { seconds: number }) {
  return (
    <Container>
      <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>
    </Container>
  )
}
