import * as React from 'react'

interface SvgProps {
  fill?: string
}

function SvgUpload({ fill = '#200E32' }: SvgProps) {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1437_9)">
        <path
          d="M17.0947 5.88207C16.5103 3.4265 14.7381 0.188538 10.7084 0.188538C7.62594 0.188538 6.11301 1.57421 5.38718 2.73837C5.10808 3.18717 4.89684 3.67475 4.76032 4.18532C2.82791 4.73676 0.339351 6.30154 0.339351 9.61491C0.276945 12.032 1.76679 14.2184 4.0392 15.0445C5.11097 15.4793 6.25284 15.7156 7.40913 15.7421H7.88045V14.3281H7.40913C7.40913 14.3281 1.75331 14.3281 1.75331 9.61491C1.75331 5.84436 5.99518 5.37304 5.99518 5.37304C5.99518 5.37304 5.99518 1.60249 10.7084 1.60249C15.4216 1.60249 15.8929 7.25832 15.8929 7.25832C16.0232 7.25103 16.1538 7.25103 16.2841 7.25832C17.2267 7.25832 19.6634 7.55996 19.6634 10.5576C19.6634 14.3281 14.0076 14.3281 14.0076 14.3281H13.065V15.7421H14.0076C16.4537 15.7421 21.0774 14.658 21.0774 10.5576C21.0774 7.48455 19.079 6.15543 17.0947 5.88207Z"
          fill={fill}
        />
        <path
          d="M7.87103 17.863C7.75284 17.7245 7.58168 17.6423 7.39971 17.6367C7.22394 17.6352 7.05464 17.703 6.92839 17.8253C6.64339 18.0921 6.62862 18.5394 6.8954 18.8245L9.72331 21.888C10.1492 22.1397 10.6783 22.1397 11.1043 21.888L14.1678 18.7679C14.4435 18.4919 14.4435 18.0447 14.1678 17.7687C13.8692 17.4903 13.4061 17.4903 13.1074 17.7687L11.1985 19.654V9.73744C11.1985 9.34698 10.882 9.03046 10.4916 9.03046C10.1011 9.03046 9.78458 9.34698 9.78458 9.73744V19.9839L7.87103 17.863Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1437_9">
          <rect width="22" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

SvgUpload.defaultProps = {
  fill: undefined,
}

export default SvgUpload
