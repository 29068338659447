import React from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import { isMobileOnly } from 'react-device-detect'

import { css } from '@emotion/core'
import { Title as BrowserTitle } from '../../../components'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: transparent;
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  ${isMobileOnly &&
  css`
    width: 90%;
    padding: 19px;
    @media (orientation: landscape) {
      width: 50%;
    }
  `}
`

const Label = styled(BrowserTitle)`
  color: white;
  text-shadow: 0 0 2px black;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 169%;
  margin-bottom: 20px;
`

const LinkLabel = styled(Label)`
  font-size: 14px;
`

const ConnectButton = styled(Button)`
  width: fit-content;
  height: auto;
  border-radius: 14px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin: 12px 0;
  align-self: center;
`

interface IProps {
  peerName: string
  link: string
}

const GoToAltRoomNotification = ({ peerName, link }: IProps) => {
  // eslint-disable-next-line no-restricted-globals
  const goToAltRoom = () => {
    window.location.assign(link)
  }

  return (
    <Container>
      <DataContainer>
        <Label>
          Sorry, it looks like {peerName} is having technical issues connecting to the
          session and was redirected to an alternative video room
        </Label>
        <Label>Please click the button below to join them.</Label>
        <ConnectButton onClick={goToAltRoom} title={`Join ${peerName}`} />
        <LinkLabel>Your link: {link}</LinkLabel>
      </DataContainer>
    </Container>
  )
}

export default GoToAltRoomNotification
