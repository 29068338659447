import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import useLogrocket from 'src/utils/useLogrocket'
import { IExpertProfile, ExpertDeclineReason, InterviewStatus } from '../../api'
import AvailabilityHeader from '../components/AvailabilityHeader'
import AvailabilityApplicantInfo from '../components/AvailabilityApplicantInfo'
import AvailabilityExpertCalendar from '../components/AvailabilityExpertCalendar'
import AvailabilityFooter from '../components/AvailabilityFooter'
import ConfirmExpertAvailability from '../components/ConfirmExpertAvailability'
import AvailabilityExpertFinal from '../components/AvailabilityExpertFinal'
import { SelectingState } from '../types'
import DeclineReasonComponent from '../components/DeclineReasonConponent'
import { declineAvailability, submitAvailability } from '../actions'
import * as interviewSelectors from '../selectors'
import AvailabilityHeaderMobile from '../components/AvailabilityHeaderMobile'
import AvailabilityApplicantInfoMobile from '../components/AvailabilityApplicantInfoMobile'
import BackArrowImg from '../../static/back-arrow.png'

const Container = styled.div<{ fullscreen?: boolean; isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(props) => (props.isMobile ? '#B202FF' : 'white')};
  height: ${(props) => (props.fullscreen ? '100vh' : 'auto')};
  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      height: 100vh;
    }
  `}
`

const DataContainer = styled.div`
  margin: 0 53px 0 86px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;

  > div:nth-of-type(1) {
    width: 37%;
  }

  > div:nth-of-type(2) {
    width: 60%;
  }
`

const DataContainerMobile = styled.div`
  background: #fff;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  padding: 16px 0;
  flex-direction: column;
  z-index: 10;

  @media (orientation: landscape) {
    margin: 0 16px;
  }
`

const MobileContainer = styled.div`
  background: #b202ff;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  overflow-y: auto;

  @media (orientation: landscape) {
    height: 100%;
  }
`

const MobileHeaderElement1 = styled.div<{ margin?: number }>`
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  width: 214px;
  height: 200px;
  background: #6938aa;
  border-radius: 0 0 100px 0;
  margin-top: ${(props) => (props.margin ? props.margin : '0')}px;
`

const MobileHeaderElement2 = styled.div<{ margin?: number }>`
  top: 132px;
  right: -51px;
  z-index: 1;
  position: absolute;
  width: 168px;
  height: 208px;
  background: #f23482;
  border-radius: 90px;
  margin-top: ${(props) => (props.margin ? props.margin : '0')}px;
`

const BackButtonMobile = styled.img`
  margin: 37px 0 21px 20px;
  height: 14px;
  width: 16px;
  z-index: 10;
`

const DeclineContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.1);
`

enum SelectingStep {
  Select,
  Confirm,
}

enum SelectingStepMobile {
  Info,
  Select,
  Confirm,
}

function ExpertAvailabilityContainer() {
  const { setLogrocket } = useLogrocket()
  const { interviewToken } = useParams()
  const token = useSelector(interviewSelectors.token)
  const interviewDetails = useSelector(interviewSelectors.interview)
  const submitAvailabilitySuccess = useSelector(
    interviewSelectors.submitAvailability.success
  )
  const declineAvailabilitySuccess = useSelector(
    interviewSelectors.declineAvailability.success
  )
  const dispatch = useDispatch()
  const [selectedDays, setSelectedDays] = useState<number[]>([])
  const [showDeclinePopup, setShowDeclinePopup] = useState(false)
  const [selectingStep, setSelectingStep] = useState<SelectingStep>(SelectingStep.Select)
  const [selectingStepMobile, setSelectingStepMobile] = useState<SelectingStepMobile>(
    SelectingStepMobile.Info
  )
  const [selectingState, setSelectingState] = useState<SelectingState>(
    SelectingState.Select
  )

  useEffect(() => {
    const expertProfile: IExpertProfile = interviewDetails.profile
    if (expertProfile.time_slots?.length > 0) {
      setSelectingState(SelectingState.Success)
    }
  }, [interviewDetails])

  useEffect(() => {
    if (submitAvailabilitySuccess) {
      setSelectingState(SelectingState.Success)
    }
  }, [submitAvailabilitySuccess])

  useEffect(() => {
    if (declineAvailabilitySuccess) {
      setSelectingState(SelectingState.Decline)
    }
  }, [declineAvailabilitySuccess])

  useEffect(() => {
    if (token.role === 'rejected_expert') {
      setSelectingState(SelectingState.Decline)
      return
    }

    if (
      token.role === 'potential_expert' &&
      !(interviewDetails.profile as IExpertProfile).availability_link_sent_at
    ) {
      setSelectingState(SelectingState.Expire)
      return
    }

    if (interviewDetails.interview.interview_date) {
      const iDate = new Date(interviewDetails.interview.interview_date)
      const todayDate = new Date().setHours(0, 0, 0, 0)
      if (iDate < new Date(todayDate)) {
        setSelectingState(SelectingState.Expire)
        return
      }
    }
    switch (interviewDetails.interview.status) {
      case InterviewStatus.SCHEDULED:
        if (interviewDetails.interview.is_incomplete) {
          const expert = interviewDetails.profile as IExpertProfile
          if (expert.time_slots?.length === 0) {
            break
          } else {
            setSelectingState(SelectingState.Success)
          }
        } else if (
          interviewDetails.interview.expert_profile?.id !== interviewDetails.profile?.id
        ) {
          setSelectingState(SelectingState.Expire)
        }
        break
      case InterviewStatus.PENDING:
      case InterviewStatus.STARTED:
      case InterviewStatus.ENDED:
      case InterviewStatus.REPORT_PROVIDED:
      case InterviewStatus.COMPLETED:
        if (
          interviewDetails.interview.expert_profile?.id !== interviewDetails.profile?.id
        ) {
          setSelectingState(SelectingState.Expire)
        }
        break
      default:
      // do nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (interviewDetails && showDeclinePopup) {
      setLogrocket(
        `${interviewDetails.interview?.order_number.toString()}`,
        'Expert availability page - declined '
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewDetails, showDeclinePopup])

  const onDeclineClicked = () => {
    setShowDeclinePopup(true)
  }

  const onConfirmClicked = (data) => {
    dispatch(
      submitAvailability({
        expertToken: interviewToken,
        timeSlots: selectedDays,
        expertComment: data.comment,
      })
    )
  }

  const onContinueMobileClicked = () => {
    switch (selectingStepMobile) {
      case SelectingStepMobile.Info:
        setSelectingStepMobile(SelectingStepMobile.Select)
        break
      case SelectingStepMobile.Select:
        setSelectingStepMobile(SelectingStepMobile.Confirm)
        break
      default:
        // onConfirmClicked()
        break
    }
    document.getElementById('MobileContainer').scrollTo(0, 0)
  }

  const onBackMobile = () => {
    switch (selectingStepMobile) {
      case SelectingStepMobile.Select:
        setSelectingStepMobile(SelectingStepMobile.Info)
        break
      case SelectingStepMobile.Confirm:
        setSelectingStepMobile(SelectingStepMobile.Select)
        break
      default:
        // do nothing
        break
    }
  }

  const onDecline = (reason: ExpertDeclineReason, info: string) => {
    setShowDeclinePopup(false)
    dispatch(
      declineAvailability({
        expertToken: interviewToken,
        declineReason: reason,
        expertComment: info,
      })
    )
  }

  function renderStep(step: SelectingStep) {
    switch (step) {
      case SelectingStep.Select:
        return (
          <AvailabilityExpertCalendar
            selectedDays={selectedDays}
            onChange={setSelectedDays}
            onDecline={onDeclineClicked}
            onContinue={() => setSelectingStep(SelectingStep.Confirm)}
          />
        )
      default: {
        return (
          <ConfirmExpertAvailability
            selectedDays={selectedDays}
            onChange={setSelectedDays}
            onEdit={() => setSelectingStep(SelectingStep.Select)}
            onConfirm={onConfirmClicked}
          />
        )
      }
    }
  }

  function setMobileStep(step: SelectingStepMobile) {
    setSelectingStepMobile(step)
    document.getElementById('MobileContainer').scrollTo(0, 0)
  }

  function renderStepMobile(step: SelectingStepMobile) {
    switch (step) {
      case SelectingStepMobile.Info:
        return (
          <AvailabilityApplicantInfoMobile
            onContinue={onContinueMobileClicked}
            onDecline={onDeclineClicked}
          />
        )
      case SelectingStepMobile.Select:
        return (
          <AvailabilityExpertCalendar
            selectedDays={selectedDays}
            onChange={setSelectedDays}
            onDecline={onDeclineClicked}
            onContinue={() => setMobileStep(SelectingStepMobile.Confirm)}
          />
        )
      default: {
        return (
          <ConfirmExpertAvailability
            selectedDays={selectedDays}
            onChange={setSelectedDays}
            onEdit={() => setMobileStep(SelectingStepMobile.Select)}
            onConfirm={(data) => {
              dispatch(
                submitAvailability({
                  expertToken: interviewToken,
                  timeSlots: selectedDays,
                  expertComment: data.comment,
                })
              )
            }}
          />
        )
      }
    }
  }

  const mobileHeaderElementsMargin =
    selectingStepMobile === SelectingStepMobile.Info ? 0 : -110

  function renderState(state: SelectingState) {
    switch (state) {
      case SelectingState.Select:
        return (
          <>
            {!isMobileOnly && (
              <>
                <AvailabilityHeader />
                <DataContainer>
                  <AvailabilityApplicantInfo />
                  {renderStep(selectingStep)}
                </DataContainer>
                <AvailabilityFooter />
              </>
            )}
            {isMobileOnly && (
              <MobileContainer id="MobileContainer">
                <MobileHeaderElement1 margin={mobileHeaderElementsMargin} />
                <MobileHeaderElement2 margin={mobileHeaderElementsMargin} />
                {selectingStepMobile === SelectingStepMobile.Info ? (
                  <AvailabilityHeaderMobile />
                ) : (
                  <BackButtonMobile src={BackArrowImg} onClick={onBackMobile} />
                )}
                <DataContainerMobile>
                  {renderStepMobile(selectingStepMobile)}
                </DataContainerMobile>
                <AvailabilityFooter />
              </MobileContainer>
            )}
          </>
        )
      default:
        return <AvailabilityExpertFinal state={selectingState} />
    }
  }

  const isFinalStep = selectingState !== SelectingState.Select

  return (
    <Container fullscreen={isFinalStep} isMobile={isMobileOnly}>
      {renderState(selectingState)}
      {showDeclinePopup && (
        <DeclineContainer>
          {isMobileOnly && (
            <DeclineReasonComponent
              onClose={() => setShowDeclinePopup(false)}
              onDecline={onDecline}
            />
          )}
          {!isMobileOnly && (
            <DeclineReasonComponent
              onClose={() => setShowDeclinePopup(false)}
              onDecline={onDecline}
            />
          )}
        </DeclineContainer>
      )}
    </Container>
  )
}

export default ExpertAvailabilityContainer
