import styled from '@emotion/styled'
import React from 'react'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div`
  height: 24px;
  width: 24px;
  padding: 6px;
  border-radius: 15px;
  background-color: #737991;
  margin-right: 8px;
  box-sizing: border-box;
  display: ${isMobileOnly ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
`

const DataIcon = styled.img`
  height: auto;
  max-height: 12px;
  width: 100%;
`

interface IProps {
  icon: string
}

const BlockIcon = ({ icon }: IProps) => {
  return (
    <Container>
      <DataIcon src={icon} />
    </Container>
  )
}

export default BlockIcon
