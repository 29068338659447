import { IAvaInterview } from '../../../api'
import { reduxDataFactory } from '../../../utils/redux'

const {
  action: updateAvaInterviewsAction,
  actionType: updateAvaInterviewsActionType,
  reducer: avaInterviewsDataReducer,
  selectors: avaInterviewsSelectors,
} = reduxDataFactory<IAvaInterview[]>('interviews', 'ava', [])

export {
  updateAvaInterviewsAction,
  updateAvaInterviewsActionType,
  avaInterviewsDataReducer,
  avaInterviewsSelectors,
}
