import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  applicantNotesSelectors,
  getApplicantNotesAction,
  removeApplicantNoteAction,
  sendApplicantNoteAction,
} from '../../redux'

const useApplicantNotes = (interviewToken: string) => {
  const dispatch = useDispatch()

  const applicantNotes = useSelector(applicantNotesSelectors.data)

  const removeApplicantNote = (id: string) => {
    dispatch(removeApplicantNoteAction({ interviewToken, id }))
  }

  const sendApplicantNote = (text: string, id?: string) => {
    dispatch(sendApplicantNoteAction({ interviewToken, text, id }))
  }

  useEffect(() => {
    if (interviewToken) dispatch(getApplicantNotesAction({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  return {
    applicantNotes,
    removeApplicantNote,
    sendApplicantNote,
  }
}

export default useApplicantNotes
