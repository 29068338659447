import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { SpeechTexts } from 'src/ava/speechService'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { avaInterviewSelectors, updateAvaInterviewAction } from 'src/redux'
import { useDispatch, useSelector } from 'react-redux'
import { getPrivacyLink, getTermsOfuseLink } from 'src/utils/links'
import { NavButton, TitleText } from '../elements/styled-components'
import LiveText from '../LiveText'
import CheckBox from '../CheckBox'

const Container = styled.div`
  height: calc(100% - 144px);
  padding: 0 0 0 64px;
  display: flex;
  align-items: start;
  flex-direction: column;

  ${isMobileOnly &&
  css`
    padding: 42px 16px 16px;
    height: calc(100% - 72px);
    background: white;
    align-items: center;
  `}
`

const Title = styled(TitleText)`
  font-size: 68px;
  line-height: 80px;
  margin-bottom: 24px;

  ${isMobileOnly &&
  css`
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    margin-bottom: 18px;
    padding: 0 8px;
  `}
`

const InfoText = styled.div`
  font-weight: 600;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: black;
  white-space: break-spaces;

  ${isMobileOnly &&
  css`
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 150%;
    text-align: center;
    padding: 0 8px;
  `}
`

const BoldInfo = styled(InfoText)`
  font-weight: 700;
  margin-top: 9px;
  margin-bottom: 20px;

  ${isMobileOnly &&
  css`
    letter-spacing: 1px;
    font-size: 14px;
    margin-top: 84px;
    padding: 0 8px;
  `}
`

const GoButton = styled(NavButton)`
  margin-top: auto;
  margin-bottom: 24px;
  height: 55px;
  touch-action: manipulation;

  ${isMobileOnly &&
  css`
    margin-top: auto;
    width: 100%;
    margin-bottom: 0px;
  `}
`

const CheckBoxContainer = styled.div`
  margin-bottom: 80px;

  ${isMobileOnly &&
  css`
    margin-bottom: 0;
    align-self: start;
    margin-top: 16px;
  `}
`

const CheckBoxLabel = styled.div<{ error?: boolean }>`
  color: ${({ error }) => (error ? '#FF1F44' : '#737991')};
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`

const LinkSpan = styled.a<{ error: boolean }>`
  color: ${({ error }) => (error ? '#FF1F44' : '#3b424f')};
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: ${({ error }) => (error ? '#FF1F44' : '#3b424f')};
  }
`

interface IProps {
  error: boolean
  goNext: () => void
}

const GreetingComponent = ({ error, goNext }: IProps) => {
  const dispatch = useDispatch()
  const [titleDone, setTitleDone] = useState(false)
  const [subTitleDone, setSubTitleDone] = useState(false)
  const avaInterview = useSelector(avaInterviewSelectors.data)
  const {
    agree_to_receive_offers: agreeToReceiveOffers,
    agree_terms_and_privacy: agreeToTermsAndPrivacy,
  } = avaInterview || {}

  const setAgreeTermsAndPrivacy = (checked: boolean) => {
    dispatch(
      updateAvaInterviewAction({ ...avaInterview, agree_terms_and_privacy: checked })
    )
  }

  const setAgreeToReceiveOffers = (checked: boolean) => {
    dispatch(
      updateAvaInterviewAction({ ...avaInterview, agree_to_receive_offers: checked })
    )
  }

  useEffect(() => {
    if (!avaInterview) return
    if (avaInterview.agree_terms_and_privacy === null) setAgreeTermsAndPrivacy(true)
    if (avaInterview.agree_to_receive_offers === null) setAgreeToReceiveOffers(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaInterview])

  return (
    <Container>
      <Title textAlign="start">
        <LiveText
          delayMs={1000}
          text={SpeechTexts.MyNameIsAva}
          onFinish={() => setTitleDone(true)}
        />
      </Title>
      {titleDone && (
        <InfoText>
          <LiveText
            text={SpeechTexts.IamHereToHelpYou}
            onFinish={() => setSubTitleDone(true)}
          />
        </InfoText>
      )}
      {subTitleDone && (
        <BoldInfo>
          <LiveText text={SpeechTexts.AreYouReadyToStart} />
        </BoldInfo>
      )}
      <GoButton onClick={goNext} hideIcon>
        Let&apos;s Go!
      </GoButton>
      <CheckBoxContainer>
        <CheckBox
          error={error && !agreeToTermsAndPrivacy}
          onChange={setAgreeTermsAndPrivacy}
          value={agreeToTermsAndPrivacy}
        >
          <CheckBoxLabel error={error && !agreeToTermsAndPrivacy}>
            I agree to the Wizco{' '}
            <LinkSpan
              error={error && !agreeToTermsAndPrivacy}
              href={getTermsOfuseLink()}
              target="_blank"
            >
              Terms of Use
            </LinkSpan>
            {' and '}
            <LinkSpan
              error={error && !agreeToTermsAndPrivacy}
              href={getPrivacyLink()}
              target="_blank"
            >
              Privacy Policy
            </LinkSpan>
          </CheckBoxLabel>
        </CheckBox>
        <CheckBox onChange={setAgreeToReceiveOffers} value={agreeToReceiveOffers}>
          <CheckBoxLabel>
            I agree to receive marketing and commercial offers from Wizco
          </CheckBoxLabel>
        </CheckBox>
      </CheckBoxContainer>
    </Container>
  )
}

export default GreetingComponent
