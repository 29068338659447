import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import FaqBlock from 'src/interview/components/FaqBlock'
import * as interviewSelectors from 'src/interview/selectors'
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { FaqsItems } from './landingConstants'
import { LandingTitle } from './landing-styled-components'

const Layout = styled.div`
  width: 100%;
  margin-bottom: 90px;

  @media screen and (max-width: 1280px) {
    margin: 0 -16px 80px -16px;
    width: auto;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 60px;
  `}
`

const FaqsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LandingTitleStyled = styled(LandingTitle)`
  ${isMobileOnly &&
  css`
    font-size: 40px !important;
  `}
`

const LandingFAQBlock = () => {
  const agency = useSelector(interviewSelectors.agency)
  const [items, setItems] = useState(FaqsItems)

  useEffect(() => {
    if (agency) {
      const newItems = [...FaqsItems]
      newItems[0].answer = FaqsItems[0].answer.replace(
        '[external agency name]',
        agency.name
      )
      setItems(newItems)
    }
  }, [agency])

  return (
    <Layout>
      <FaqsContainer>
        <LandingTitleStyled>FAQs</LandingTitleStyled>
        {items.map((item) => (
          <FaqBlock key={item.answer} question={item.question} answer={item.answer} />
        ))}
      </FaqsContainer>
    </Layout>
  )
}

export default LandingFAQBlock
