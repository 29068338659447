import styled from '@emotion/styled'

import { Text } from '../../../../components'

import { Text as MobileText } from '../../MobileText'

export const OnboardingPopup = styled.div`
  background-color: white;
  border: 1px solid #b202ff;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  visibility: visible;
  z-index: 200;

  padding: 27px 36px;
  width: 450px;

  &:after {
    background-color: white;
    border-top: 1px solid #b202ff;
    border-right: 1px solid #b202ff;
    top: -3px;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    transform: rotate(-45deg);

    height: 9px;
    width: 9px;
    margin-left: -9px;
  }

  @media screen and (max-width: 2500px) {
    padding: 22px 30px;
    width: 375px;
    &:after {
      height: 7px;
      width: 7px;
      margin-left: -7px;
    }
  }
  @media screen and (max-width: 2000px) {
    padding: 18px 24px;
    width: 300px;
    &:after {
      height: 6px;
      width: 6px;
      margin-left: -6px;
    }
  }
  @media screen and (max-width: 1200px) {
    padding: 13px 18px;
    width: 225px;
    &:after {
      height: 4px;
      width: 4px;
      margin-left: -4px;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 9px 12px;
    width: 150px;
    &:after {
      height: 3px;
      width: 3px;
      margin-left: -3px;
    }
  }
`

export const OnboardingPopupChat = styled(OnboardingPopup)`
  padding: 27px 0px;

  @media screen and (max-width: 2500px) {
    padding: 22px 0px;
  }
  @media screen and (max-width: 2000px) {
    padding: 18px 0px;
  }
  @media screen and (max-width: 1200px) {
    padding: 13px 0px;
  }
  @media screen and (max-width: 900px) {
    padding: 9px 0px;
  }
`

export const StyledTitle = styled(Text)`
  color: #222b45;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0;

  font-size: 21px;
  line-height: 30px;
  margin: 0 0 16px;
  @media screen and (max-width: 2500px) {
    font-size: 17px;
    line-height: 25px;
    margin: 0 0 14px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 11px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 10px;
    line-height: 15px;
    margin: 0 0 8px;
  }
  @media screen and (max-width: 900px) {
    font-size: 7px;
    line-height: 10px;
    margin: 0 0 5px;
  }
`

export const StyledText = styled(Text)`
  color: #3b424f;
  font-family: Rubik;
  letter-spacing: 0;

  font-size: 18px;
  line-height: 28px;
  margin: 0 0 16px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 14px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 19px;
    margin: 0 0 11px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 14px;
    margin: 0 0 8px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 9px;
    margin: 0 0 5px;
  }
`

export const MessageTitle = styled(Text)`
  color: #6938aa;
  font-family: Rubik;
  font-weight: 400;
  letter-spacing: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;

  font-size: 21px;
  line-height: 30px;
  margin: 0 30px 16px 0;
  @media screen and (max-width: 2500px) {
    font-size: 17px;
    line-height: 25px;
    margin: 0 30px 14px 0;
  }
  @media screen and (max-width: 2000px) {
    font-size: 14px;
    line-height: 20px;
    margin: 0 30px 11px 0;
  }
  @media screen and (max-width: 1200px) {
    font-size: 10px;
    line-height: 15px;
    margin: 0 30px 8px 0;
  }
  @media screen and (max-width: 900px) {
    font-size: 7px;
    line-height: 10px;
    margin: 0 30px 5px 0;
  }
`

export const MessageText = styled(Text)`
  color: #292d32;
  font-family: Rubik;
  letter-spacing: 0;
  overflow: hidden;

  font-size: 18px;
  line-height: 29px;
  margin: 8px 20px 0 20px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 24px;
    margin: 8px 20px 0 20px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 19px;
    margin: 8px 20px 0 20px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 14px;
    margin: 8px 20px 0 20px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 9px;
    margin: 8px 20px 0 20px;
  }
`

export const Nav = styled.div`
  align-items: center;
  color: #21304e;
  display: flex;
  font-family: Rubik;
  justify-content: space-between;
  letter-spacing: 3px;
  width: 100%;

  font-size: 18px;
  line-height: 33px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 27px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 22px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 16px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 11px;
  }
`

export const NextBtn = styled.div`
  background: none;
  border: none;
  color: #21304e;
  cursor: pointer;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0;
  text-align: right;
  text-decoration: underline;

  font-size: 18px;
  line-height: 33px;
  @media screen and (max-width: 2500px) {
    font-size: 15px;
    line-height: 27px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 12px;
    line-height: 22px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 9px;
    line-height: 16px;
  }
  @media screen and (max-width: 900px) {
    font-size: 6px;
    line-height: 11px;
  }
`

export const Close = styled.img`
  align-self: flex-end;
  cursor: pointer;

  height: 18px;
  margin-bottom: 18px;
  width: 18px;
  @media screen and (max-width: 2500px) {
    height: 15px;
    margin-bottom: 15px;
    width: 15px;
  }
  @media screen and (max-width: 2000px) {
    height: 12px;
    margin-bottom: 12px;
    width: 12px;
  }
  @media screen and (max-width: 1200px) {
    height: 9px;
    margin-bottom: 9px;
    width: 9px;
  }
  @media screen and (max-width: 900px) {
    height: 6px;
    margin-bottom: 6px;
    width: 6px;
  }
`

export const ChatIcon = styled.img`
  align-self: flex-start;
  cursor: pointer;
  flex-shrink: 0;

  height: 14px;
  margin-bottom: 0px;
  margin-right: 8px;
  width: 14px;
  margin-left: 20px;
  @media screen and (max-width: 2500px) {
    height: 25px;
    margin-right: 8px;
    margin-bottom: 0px;
    width: 25px;
  }
  @media screen and (max-width: 2000px) {
    height: 20px;
    margin-right: 8px;
    margin-bottom: 0px;
    width: 20px;
  }
  @media screen and (max-width: 1200px) {
    height: 15px;
    margin-right: 8px;
    margin-bottom: 0px;
    width: 15px;
  }
  @media screen and (max-width: 900px) {
    height: 10px;
    margin-right: 8px;
    margin-bottom: 0px;
    width: 10px;
  }
`

export const ChevronIcon = styled.img`
  align-self: flex-start;
  cursor: pointer;

  height: 10px;
  margin-bottom: 0px;
  width: 10px;
  position: absolute;
  right: 20px;
  @media screen and (max-width: 2500px) {
    height: 24px;
    margin-bottom: 0px;
    width: 6px;
  }
  @media screen and (max-width: 2000px) {
    height: 19px;
    margin-bottom: 0px;
    width: 6px;
  }
  @media screen and (max-width: 1200px) {
    height: 14px;
    margin-bottom: 0px;
    width: 6px;
  }
  @media screen and (max-width: 900px) {
    height: 9px;
    margin-bottom: 0px;
    width: 6px;
  }
`

export const MobileOnboardingPopup = styled.div`
  background: #ffffff;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px #e1e3ea);
  width: 284px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  visibility: visible;
  z-index: 200;

  padding: 24px;

  &:after {
    background-color: white;
    top: -3px;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    transform: rotate(-45deg);

    height: 9px;
    width: 9px;
    margin-left: -9px;
  }
`

export const MobileOnboardingPopupChat = styled(MobileOnboardingPopup)`
  padding: 14px 0px;
`

export const MobileClose = styled.img`
  align-self: flex-end;
  height: 12px;
  margin-bottom: 16px;
  width: 12px;
`

export const MobileChatIcon = styled.img`
  flex-shrink: 0;
  height: 14px;
  margin-left: 21px;
  margin-right: 8px;
  width: 14px;
`
export const MobileChevronIcon = styled.img`
  height: 10px;
  margin-bottom: 0px;
  margin-right: 20px;
  width: 10px;
  position: absolute;
  right: 0;
`

export const MobileMessageTitle = styled(MobileText)`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  margin-right: 33px;
  line-height: 19px;
  color: #6938aa;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

export const MobileMessageText = styled(MobileText)`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin: 8px 20px 0 20px;
  color: #292d32;
  -webkit-line-clamp: 3;
  flex: 1;
  overflow: hidden;
`

export const MobileStyledText = styled(MobileText)`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  color: #3c424e;
`

export const MobileStyledTitle = styled(MobileText)`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 19px;
  color: #3c424e;
`

export const MobileNav = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #3c424e;
  letter-spacing: 4px;
`

export const MobileNextBtn = styled(MobileText)`
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #3c424e;
  text-decoration-line: underline;
  letter-spacing: 0;
  align-self: flex-end;
`
