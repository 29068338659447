import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as interviewSelectors from 'src/interview/selectors'
import { avaInterviewSelectors } from '../../../redux'
import { SpeechTexts } from '../../speechService'
import ChoiseComponent from './ChoiseComponent'

interface IProps {
  backMode: boolean
  goBack: () => void
  goNext: (value: string) => void
}

const PositionComponent = ({ backMode, goNext, goBack }: IProps) => {
  const interview = useSelector(avaInterviewSelectors.data)
  const agency = useSelector(interviewSelectors.agency)

  const [selectedItem, setSelectedItem] = useState<string>()
  const [showError, setShowError] = useState(false)

  const positions = Object.keys(agency?.agency_positions || {}) || []

  const onNext = () => {
    if (!selectedItem) {
      setShowError(true)
    } else {
      goNext(selectedItem)
    }
  }

  useEffect(() => {
    if (!interview.position) return
    setSelectedItem(interview.position.position)
  }, [interview])

  useEffect(() => {
    if (selectedItem) {
      setShowError(false)
    }
  }, [selectedItem])

  return (
    <ChoiseComponent
      title={SpeechTexts.WhatField}
      backMode={backMode}
      subTitle={
        interview.candidate?.first_name &&
        `Lovely to meet you, ${interview.candidate.first_name}!`
      }
      errorText="Please select the field you want to practice for"
      showError={showError}
      items={positions}
      onSelect={setSelectedItem}
      selectedItem={selectedItem}
      goNext={onNext}
      goBack={goBack}
    />
  )
}

export default PositionComponent
