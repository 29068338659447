import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { isMobileOnly } from 'react-device-detect'
import { TitleText } from '../elements/styled-components'

export const ReportTitle = styled(TitleText)`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 8px;

  @media screen and (max-width: 1280px) {
    font-size: 24px;
  }

  ${isMobileOnly &&
  css`
    color: #222b45;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  `}
`

export const ReportSubtitleText = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #222b45;
  margin-bottom: 29px;

  @media screen and (max-width: 1280px) {
    font-size: 16px;
  }

  ${isMobileOnly &&
  css`
    color: #3b424f;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
  `}
`
