import styled from '@emotion/styled'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IBulkInterviewNotification } from '../../api'
import { HyperLink, Text } from '../../components'
import {
  agencySelectors,
  bulkInterviewsSelectors,
  clearBulkInterviewsAction,
  getBulkInterviewsAction,
} from '../../redux'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 10px;
`

const BulkInterviewItem = styled.div`
  padding: 10px;
  border: 1px solid;
`

const HeaderText = styled(HyperLink)`
  text-decoration: underline;
  padding: 10px;
  cursor: pointer;
`

const BulkInviteNotificationContainer = styled.div`
  border-top: 1px solid;
  padding: 10px;
`

const BulkInviteNotificationRow = styled.div`
  align-items: center;
  display: flex;
`

const BulkInviteNotificationLabel = styled(Text)`
  font-weight: bold;
`

const BulkInviteNotificationText = styled(Text)<{ active?: boolean }>`
  margin-right: 10px !important;
  color: ${({ active }) => (active ? '#92c57b' : '#3b424f')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`

const BulkInviteNotificationDate = styled.span`
  color: #3b424f;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  border: 1px solid;
  padding: 2px 10px;
`

export const BulkInviteNotification = ({
  label,
  notification,
}: {
  label: string
  notification: IBulkInterviewNotification
}) => {
  return (
    <BulkInviteNotificationContainer>
      <BulkInviteNotificationLabel>{label}</BulkInviteNotificationLabel>
      <BulkInviteNotificationRow>
        <BulkInviteNotificationText>Status:</BulkInviteNotificationText>
        <BulkInviteNotificationText active={notification.sent}>
          {notification.sent ? 'Sent' : 'Scheduled'}
        </BulkInviteNotificationText>
      </BulkInviteNotificationRow>
      <BulkInviteNotificationRow>
        <BulkInviteNotificationText>Date:</BulkInviteNotificationText>
        <BulkInviteNotificationDate>
          {moment(notification.date).format('DD/MM/YY, HH:mm, [GMT] Z')}
        </BulkInviteNotificationDate>
      </BulkInviteNotificationRow>
      <BulkInviteNotificationRow>
        <BulkInviteNotificationText>Applicants:</BulkInviteNotificationText>
        <BulkInviteNotificationText>
          {notification.count || '-'}
        </BulkInviteNotificationText>
      </BulkInviteNotificationRow>
    </BulkInviteNotificationContainer>
  )
}

const BulkInvites = () => {
  const dispatch = useDispatch()
  const agency = useSelector(agencySelectors.data)
  const bulkInterviews = useSelector(bulkInterviewsSelectors.data)

  useEffect(() => {
    return () => dispatch(clearBulkInterviewsAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (agency?.id) dispatch(getBulkInterviewsAction({ agencyId: agency.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency])

  return (
    <Container>
      {bulkInterviews.map((i) => (
        <BulkInterviewItem key={i.file.id}>
          <HeaderText href={i.file.file.url} target="_blank">
            {i.file.file_name}
          </HeaderText>
          <BulkInviteNotification
            label="Invite email"
            notification={i.candidate_invited_at}
          />
          <BulkInviteNotification
            label="1st follow up"
            notification={i.first_bulk_reminder_at}
          />
          <BulkInviteNotification
            label="2nd follow up"
            notification={i.second_bulk_reminder_at}
          />
          <BulkInviteNotification
            label="3rd follow up"
            notification={i.third_bulk_reminder_at}
          />
          <BulkInviteNotification
            label="4th follow up"
            notification={i.fourth_bulk_reminder_at}
          />
        </BulkInterviewItem>
      ))}
    </Container>
  )
}

export default BulkInvites
