import { put, takeEvery, call } from 'typed-redux-saga'

import {
  CoachingType,
  createAgencyAvaInterview,
  createAgencyInterview,
  IInternalExpert,
} from '../../api'

import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateBookingInfoAction } from '../data/bookingInfo'

interface ICreateInterviewAction {
  id: string
  firstName: string
  lastName: string
  email: string
  internal_experts?: IInternalExpert[]
  moreInfo?: string
  desiredPosition?: string
  programmingLanguage?: string
  company?: string
  confirmedDate?: Date
  positionLink?: string
  interview_date_option?: string
  coaching_type?: CoachingType
  isAvaInterview: boolean
}

const {
  action: createInterviewAction,
  actionFailure: createInterviewActionFailure,
  actionSuccess: createInterviewActionSuccess,
  actionSuccessClear: createInterviewActionSuccessClear,
  actionFailureClear: createInterviewActionFailureClear,
  actionType: createInterviewActionType,
  actionTypeFailure: createInterviewActionFailureType,
  actionTypeSuccess: createInterviewActionSuccessType,
  reducer: createInterviewRequestReducer,
  selectors: createInterviewSelectors,
} = reduxRequestFactory<ICreateInterviewAction>('createInterview', 'requests')

export {
  createInterviewAction,
  createInterviewActionFailure,
  createInterviewActionSuccess,
  createInterviewActionSuccessClear,
  createInterviewActionFailureClear,
  createInterviewActionType,
  createInterviewActionFailureType,
  createInterviewActionSuccessType,
  createInterviewRequestReducer,
  createInterviewSelectors,
}

function* createInterviewSaga({ payload }: ISagaAction<ICreateInterviewAction>) {
  try {
    const { id, isAvaInterview, ...data } = payload
    const token = isAvaInterview
      ? yield* call(createAgencyAvaInterview, { id, data })
      : yield* call(createAgencyInterview, { id, data })
    yield put(createInterviewActionSuccess())

    const { lastName, firstName, email } = data
    yield put(
      updateBookingInfoAction({
        lastName,
        firstName,
        token,
        email,
      })
    )
  } catch (err) {
    yield call(handleErrorSaga, err)
    yield put(
      createInterviewActionFailure({
        error: 'Server error. Please, contact support',
      })
    )
  }
}

export function* watchCreateInterview() {
  yield takeEvery(createInterviewActionType, createInterviewSaga)
}
