import React from 'react'
import styled from '@emotion/styled'

import SvgSettingIcon from 'src/static/components/SvgSettings'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

export const SettingsButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

interface ISettingsButton {
  disabled: boolean
  active: boolean
  theme: any
  onClick: () => void
}

export default function SettingsButton({
  disabled,
  active,
  theme,
  onClick,
}: ISettingsButton) {
  return (
    <HeaderButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgSettingIcon fill={active ? theme.textColorActive : theme.textColor} />
        <SettingsButtonLabel active={active}>Settings</SettingsButtonLabel>
      </HeaderIconContainer>
    </HeaderButtonContainer>
  )
}
