import { put, takeEvery, call } from 'typed-redux-saga'

import { avaInterviewRetryQuestionsGeneration } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { startAvaInterviewWatcherAction } from '../../watchers/avaInterviewWatcher'
import { avaGetInterviewAction } from './getInterview'

export interface IAvaRetryQuestionsGenerationAction {
  interviewToken: string
}

const {
  action: avaRetryQuestionsGenerationAction,
  actionFailure: avaRetryQuestionsGenerationActionFailure,
  actionSuccess: avaRetryQuestionsGenerationActionSuccess,
  actionSuccessClear: avaRetryQuestionsGenerationActionSuccessClear,
  actionFailureClear: avaRetryQuestionsGenerationActionFailureClear,
  actionType: avaRetryQuestionsGenerationActionType,
  actionTypeFailure: avaRetryQuestionsGenerationActionFailureType,
  actionTypeSuccess: avaRetryQuestionsGenerationActionSuccessType,
  reducer: avaRetryQuestionsGenerationRequestReducer,
  selectors: avaRetryQuestionsGenerationSelectors,
} = reduxRequestFactory<IAvaRetryQuestionsGenerationAction>(
  'avaRetryQuestionsGeneration',
  'requests'
)

export {
  avaRetryQuestionsGenerationAction,
  avaRetryQuestionsGenerationActionFailure,
  avaRetryQuestionsGenerationActionSuccess,
  avaRetryQuestionsGenerationActionSuccessClear,
  avaRetryQuestionsGenerationActionFailureClear,
  avaRetryQuestionsGenerationActionType,
  avaRetryQuestionsGenerationActionFailureType,
  avaRetryQuestionsGenerationActionSuccessType,
  avaRetryQuestionsGenerationRequestReducer,
  avaRetryQuestionsGenerationSelectors,
}

function* avaRetryQuestionsGenerationSaga(
  action: ISagaAction<IAvaRetryQuestionsGenerationAction>
) {
  try {
    const { interviewToken } = action.payload
    yield* call(avaInterviewRetryQuestionsGeneration, interviewToken)
    yield put(avaRetryQuestionsGenerationActionSuccess())
    yield put(avaGetInterviewAction({ interviewToken }))
    yield put(startAvaInterviewWatcherAction({ interviewToken }))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaRetryQuestionsGenerationActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaRetryQuestionsGeneration() {
  yield takeEvery(avaRetryQuestionsGenerationActionType, avaRetryQuestionsGenerationSaga)
}
