import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  avaOnboardingStepSelectors,
  updateAvaOnboardingStepAction,
  updateAvaStepAction,
} from 'src/redux/data/ava/step'
import { css } from '@emotion/core'
import { isMobileOnly } from 'react-device-detect'
import {
  avaInterviewSelectors,
  avaSaveInfoAction,
  avaSettingsSelectors,
  updateAvaSettingsAction,
} from 'src/redux'
import GreetingComponent from '../components/onboarding/GreetingComponent'
import OnboardingStepLayout from '../components/onboarding/OnboardingStepLayout'
import { AvaStep } from '../models'
import { SpeechTexts } from '../speechService'
import RotatePhoneComponent from '../components/elements/RotatePhoneComponent'
import Header from '../components/Header'
import SettingsBar from '../components/elements/SettingsBar'
import useAvaSound from '../services/avaSound'

const Layout = styled.div`
  width: 100%;
  height: 100%;
  min-width: 600px;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    height: auto;
  }

  ${isMobileOnly &&
  css`
    position: absolute;
    background: #f6f4fd;
    height: 100% !important;
    min-width: 100%;
  `}
`

export enum OnboardingStep {
  Greeting,
  Intro,
  Interview,
  Insights,
}

interface IStepData {
  step: OnboardingStep
  nextStep?: OnboardingStep
}

const OnboardingSteps: IStepData[] = [
  {
    step: OnboardingStep.Greeting,
    nextStep: OnboardingStep.Intro,
  },
  {
    step: OnboardingStep.Intro,
    nextStep: OnboardingStep.Interview,
  },
  {
    step: OnboardingStep.Interview,
    nextStep: OnboardingStep.Insights,
  },
]

const OnboardingContainer = () => {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()

  const avaInterview = useSelector(avaInterviewSelectors.data)
  const stepData = useSelector(avaOnboardingStepSelectors.data)
  const avaSettings = useSelector(avaSettingsSelectors.data)
  const [termsCheckError, setTermsCheckError] = useState(false)
  const { playSound } = useAvaSound()

  const stotageKey = `avaSpeakerPopupShown_${interviewToken}`
  const avaSpeakerPopupShown = localStorage.getItem(stotageKey)

  const goNextStep = (currStep: IStepData) => {
    dispatch(updateAvaOnboardingStepAction({ onboardingStep: currStep.nextStep }))
  }

  const checkTermsAndPrivacy = useCallback(() => {
    if (!avaInterview.agree_terms_and_privacy) {
      setTermsCheckError(true)
      return false
    }

    setTermsCheckError(false)
    dispatch(
      avaSaveInfoAction({
        interviewToken,
        interview: {
          agree_terms_and_privacy: avaInterview.agree_terms_and_privacy,
          agree_to_receive_offers: avaInterview.agree_to_receive_offers,
        },
      })
    )
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaInterview])

  const goNextStepGreeting = useCallback(
    (currentStepData) => {
      if (checkTermsAndPrivacy()) {
        localStorage.setItem(stotageKey, '1')
        playSound('/static/sound/ava-run-interview.wav')
        goNextStep(currentStepData)
        dispatch(updateAvaSettingsAction({ ...avaSettings, showSpeakerPopup: false }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkTermsAndPrivacy]
  )

  const goNextStepHeader = () => {
    dispatch(updateAvaSettingsAction({ ...avaSettings, showSpeakerPopup: false }))
    if (checkTermsAndPrivacy()) dispatch(updateAvaStepAction(AvaStep.Intro))
  }

  const handleClosePopup = useCallback(() => {
    if (isMobileOnly) {
      localStorage.setItem(stotageKey, '1')
      const currentStepData = OnboardingSteps.find(
        (s) => s.step === stepData.onboardingStep
      )
      goNextStepGreeting(currentStepData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData, goNextStepGreeting])

  const goBack = (currentStep: OnboardingStep) => {
    const prevStepData = OnboardingSteps.find((s) => s.nextStep === currentStep)
    dispatch(updateAvaOnboardingStepAction({ onboardingStep: prevStepData.step }))
  }

  const runIntro = () => {
    dispatch(updateAvaStepAction(AvaStep.Intro))
  }

  const renderStep = (currentStep: OnboardingStep) => {
    const currentStepData = OnboardingSteps.find((s) => s.step === currentStep)
    switch (currentStep) {
      case OnboardingStep.Greeting:
        return (
          <GreetingComponent
            error={termsCheckError}
            goNext={() => {
              if (isMobileOnly && !avaSpeakerPopupShown) {
                dispatch(
                  updateAvaSettingsAction({ ...avaSettings, showSpeakerPopup: true })
                )
              } else {
                goNextStepGreeting(currentStepData)
              }
            }}
          />
        )
      case OnboardingStep.Intro:
        return (
          <OnboardingStepLayout
            title={SpeechTexts.WhatYouCanExpect}
            subTitle={SpeechTexts.ToKnowYourGoals}
            nextButtonText="Got it"
            videoUrl="/static/media/video/onboarding_intro.mp4"
            goNext={() => goNextStep(currentStepData)}
            goBack={() => goBack(stepData.onboardingStep)}
          />
        )
      case OnboardingStep.Interview:
        return (
          <OnboardingStepLayout
            title={SpeechTexts.WhatYouCanExpect}
            subTitle={SpeechTexts.NextIAskYou}
            nextButtonText="Okay"
            videoUrl="/static/media/video/onboarding_interview.mp4"
            goNext={() => goNextStep(currentStepData)}
            goBack={() => goBack(stepData.onboardingStep)}
          />
        )
      case OnboardingStep.Insights:
        return (
          <OnboardingStepLayout
            title={SpeechTexts.WhatYouCanExpect}
            subTitle={SpeechTexts.LastlyIProvideReport}
            nextButtonText="Start practicing"
            hideNextButtonIcon
            videoUrl="/static/media/video/onboarding_report.mp4"
            goNext={runIntro}
            goBack={() => goBack(stepData.onboardingStep)}
          />
        )
      default:
        return 'Oops... You should not see that!'
    }
  }

  useEffect(() => {
    if (!isMobileOnly && !avaSpeakerPopupShown) {
      dispatch(updateAvaSettingsAction({ ...avaSettings, showSpeakerPopup: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showBackButton = stepData.onboardingStep !== OnboardingStep.Greeting
  const showRunButton = stepData.onboardingStep !== OnboardingStep.Insights

  return (
    <Layout>
      <Header
        showBackButton={showBackButton}
        showSettingsButton
        onBack={() => goBack(stepData.onboardingStep)}
        onNext={goNextStepHeader}
        onClosePopup={handleClosePopup}
        showRunButton={!isMobileOnly && showRunButton}
      />
      {renderStep(stepData.onboardingStep)}
      {isMobileOnly && <RotatePhoneComponent />}
      <SettingsBar />
      <audio id="ava-audio" />
    </Layout>
  )
}

export default OnboardingContainer
