import { isMobileOnly } from 'react-device-detect'
import useStateRef from 'react-usestateref'

export enum DrawerTypes {
  Chat = 'Chat',
  Details = 'Details',
  Menu = 'Menu',
  Precall = 'Precall',
  Settings = 'Settings',
  SessionDetails = 'SessionDetails',
  Notes = 'Notes',
  Feedback = 'Feedback',
}

const useDrawer = () => {
  const [drawer, setDrawer] = useStateRef<DrawerTypes>()

  const closeDrawer = () => setDrawer(undefined)
  const openChatDrawer = () => setDrawer(DrawerTypes.Chat)
  const openDetailsDrawer = () => setDrawer(DrawerTypes.Details)
  const openMenuDrawer = () => setDrawer(DrawerTypes.Menu)
  const openPrecallDrawer = () => setDrawer(DrawerTypes.Precall)
  const openSettingsDrawer = () => setDrawer(DrawerTypes.Settings)
  const openSessionDetailsDrawer = () => setDrawer(DrawerTypes.SessionDetails)
  const openNotesDrawer = () => setDrawer(DrawerTypes.Notes)
  const openFeedbackDrawer = () => setDrawer(DrawerTypes.Feedback)

  const chatDrawerOpen = drawer === DrawerTypes.Chat
  const detailsDrawerOpen = drawer === DrawerTypes.Details
  const menuDrawerOpen = drawer === DrawerTypes.Menu
  const precallDrawerOpen = drawer === DrawerTypes.Precall
  const settingsDrawerOpen = drawer === DrawerTypes.Settings
  const sessionDetailsDrawerOpen = drawer === DrawerTypes.SessionDetails
  const notesDrawerOpen = drawer === DrawerTypes.Notes
  const feedbackDrawerOpen = drawer === DrawerTypes.Feedback

  const hasDrawer =
    (!isMobileOnly && chatDrawerOpen) ||
    (!isMobileOnly && notesDrawerOpen) ||
    detailsDrawerOpen ||
    menuDrawerOpen ||
    precallDrawerOpen ||
    settingsDrawerOpen ||
    sessionDetailsDrawerOpen ||
    (!isMobileOnly && feedbackDrawerOpen)

  return {
    chatDrawerOpen,
    detailsDrawerOpen,
    hasDrawer,
    menuDrawerOpen,
    precallDrawerOpen,
    settingsDrawerOpen,
    sessionDetailsDrawerOpen,
    notesDrawerOpen,
    feedbackDrawerOpen,
    closeDrawer,
    openChatDrawer,
    openDetailsDrawer,
    openMenuDrawer,
    openPrecallDrawer,
    openSettingsDrawer,
    openSessionDetailsDrawer,
    openNotesDrawer,
    openFeedbackDrawer,
  }
}

export default useDrawer
