import React from 'react'

interface SvgProps {
  fill: string
}

function SvgSupport({ fill }: SvgProps) {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30882 20.5557C1.25 20.5557 1.25 18.4168 1.25 17.3473L1.25 4.514C1.25 1.94733 3.9951 1.30566 5.36765 1.30566L14.6324 1.30566C17.9265 1.30566 18.75 3.44455 18.75 4.514L18.75 22.6946L13.6029 20.5557L3.30882 20.5557Z"
        fill="white"
        stroke={fill}
      />
      <path
        d="M6.02344 14.0312C7.3492 15.357 10.7962 17.2131 13.978 14.0312"
        stroke={fill}
        strokeWidth="1.59091"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgSupport
