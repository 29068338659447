import { NetworkQuality } from 'agora-rtc-sdk-ng'

import { CallNetworkQuality } from '../redux/types'

export default function getNetworkQuality(
  quality: NetworkQuality = {
    downlinkNetworkQuality: undefined,
    uplinkNetworkQuality: undefined,
  }
) {
  const { downlinkNetworkQuality: down, uplinkNetworkQuality: up } = quality
  if (down === 0 || up === 0) return undefined
  if (down <= 1 && up <= 1) return CallNetworkQuality.EXCELENT
  if (down <= 3 && up <= 3) return CallNetworkQuality.NORMAL
  if (down <= 5 && up <= 5) return CallNetworkQuality.BAD
  if (down <= 6 && up <= 6) return CallNetworkQuality.NONE
  return undefined
}
