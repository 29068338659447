import React from 'react'
// import { useSelector } from 'react-redux'
import styled from '@emotion/styled'

// import * as interviewSelectors from '../interview/selectors'
import { useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { useMediaQuery } from '@material-ui/core'
import dudeImg from '../static/report_feedback_dude.png'
import dudeImg2 from '../static/report_feedback_dude2.jpg'
import dudeImg3 from '../static/report_feedback_dude3.png'
import girlImg from '../static/report_feedback_girl.png'
import girlImg2 from '../static/report_feedback_girl2.png'
import { Title, Subtitle } from '../components'
import * as interviewSelectors from '../interview/selectors'
// import wizcoLogo from '../static/logo.png'

const Layout = styled.div`
  min-height: 100vh;
  padding: 65px 60px;
  background-color: #f5f6f8;
  justify-content: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 900px) {
    padding: 20px;
    align-items: start;
  }

  @media screen and (max-width: 567px) {
    padding: 20px;
    align-items: start;
  }
`

const Content = styled.div<{ submitted: boolean }>`
  max-width: 1160px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    max-width: 100%;
  }

  @media screen and (max-width: 567px) {
    flex-direction: ${(props) => (props.submitted ? 'column-reverse' : 'column')};
  }
`

const LandscapeLayout = styled.div`
  display: flex;
  flex-direction: row;
`

const Header = styled.header<{ submitted?: boolean }>`
  margin-top: ${(props) => (props.submitted ? 40 : 0)}px;
`

const TitleStyled = styled(Title)`
  font-size: 36px;
  line-height: 37px;
  margin-bottom: 36px;
  @media screen and (orientation: landscape) {
    margin-bottom: 4px !important;
  }
  @media screen and (max-width: 900px) {
    padding-bottom: 0;
    padding-top: 15px;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 27px;
  }
  @media screen and (max-width: 567px) {
    padding-top: 25px;
  }
`

const SubtitleStyled = styled(Subtitle)`
  font-size: 20px;
  margin-bottom: 0;

  @media screen and (orientation: landscape) {
    margin-top: 22px !important;
  }

  @media screen and (max-width: 900px) {
    font-size: 16px;
    margin-bottom: 6px;
  }
  @media screen and (max-width: 400px) {
    padding: 0 0 5px;
  }
`

const Body = styled.div`
  margin-top: 14px;

  @media screen and (max-width: 900px) {
    margin-top: 0;
    & > p {
      font-size: 14px;
      margin-bottom: 20px;
      padding-top: 15px;
    }
  }
  @media screen and (max-width: 567px) {
    margin-top: 10px;
    & > p {
      font-size: 14px;
      margin-bottom: 20px;
      padding-top: 0;
    }
  }
`
const LogoContainer = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: flex-end;
  max-width: 1160px;
  margin: 0 auto 57px;

  @media screen and (max-width: 900px) {
    justify-content: flex-start;
    margin: 0 0 10px 0;
    height: 35px;
    width: auto;
  }
`
const Logo = styled.img`
  height: 100%;
  width: auto;
  @media screen and (max-width: 900px) {
    height: 35px;
  }
`
const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Sidebar = styled.div<{ submitted: boolean }>`
  height: 252px;
  width: ${(props) => (props.submitted ? 292 : 250)}px;
  margin-right: 50px;
  background: url(${(props) => (props.submitted ? girlImg : dudeImg)}) no-repeat top left;
  background-size: contain;

  @media screen and (max-width: 900px) {
    background: url(${(props) => (props.submitted ? girlImg2 : dudeImg3)}) no-repeat
      center center;
    margin: ${(props) => (props.submitted ? '-25px 0 0 -20px' : '0px auto 0')};
    width: 223px;
    background-size: contain;
  }

  @media screen and (max-width: 567px) {
    margin: 0 auto;
  }

  @media screen and (orientation: portrait) {
    background: url(${(props) => (props.submitted ? girlImg2 : dudeImg2)}) no-repeat
      center center;
    background-size: contain;
  }
`

interface IReportLayoutProps {
  children: React.ReactElement
  title: string
  subtitle?: string
  submitted?: boolean
}

function ReportLayout(props: IReportLayoutProps) {
  // const interviewData = useSelector(interviewSelectors.interview)
  const agency = useSelector(interviewSelectors.agency)
  const companyLogo = agency.company_logo?.url
  const { title, subtitle, children, submitted } = props

  const isLandscape = useMediaQuery('(orientation: landscape)')
  const isMobileLandscape = isMobileOnly && isLandscape

  function mobileLayout() {
    if (submitted) {
      return (
        <Content submitted={submitted}>
          <LandscapeLayout>
            <Header>
              <TitleStyled>{title}</TitleStyled>
            </Header>
            <Sidebar submitted={submitted} />
          </LandscapeLayout>
        </Content>
      )
    }
    return (
      <Content submitted={submitted}>
        <Header>
          <TitleStyled>{title}</TitleStyled>
        </Header>
        <LandscapeLayout>
          <Main>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
            <Body>{children}</Body>
          </Main>
          <Sidebar submitted={submitted} />
        </LandscapeLayout>
      </Content>
    )
  }

  return (
    <Layout>
      <LogoContainer>
        <Logo src={companyLogo} />
      </LogoContainer>
      {isMobileLandscape && mobileLayout()}
      {!isMobileLandscape && (
        <Content submitted={submitted}>
          <Sidebar submitted={submitted} />
          <Main>
            <Header submitted={submitted}>
              <TitleStyled>{title}</TitleStyled>
              <SubtitleStyled>{subtitle}</SubtitleStyled>
            </Header>
            <Body>{children}</Body>
          </Main>
        </Content>
      )}
    </Layout>
  )
}

ReportLayout.defaultProps = {
  subtitle: '',
  submitted: false,
}

export default ReportLayout
