import React from 'react'
import styled from '@emotion/styled'

import PlaceholderImage from 'src/static/statistics_image.png'
import { TextBold } from 'src/components'
import Layout from '../Layout'

const Placeholder = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 400px);
`

const PlaceholderTitle = styled(TextBold)`
  font-size: 26px;
  margin-bottom: 20px;
`
const PlaceholderSubTitle = styled(TextBold)`
  font-size: 18px;
  margin-bottom: 20px;
`
const PlaceholderImageWrapper = styled.div`
  img {
    width: 120px;
    height: 120px;
  }
`
const Statistics = () => {
    return (
      <Layout title="Statistics" hideBg fullBody>
        <Placeholder>
          <PlaceholderTitle>Here you will see your account statistics</PlaceholderTitle>
          <PlaceholderSubTitle>A minimum of 50 sessions is required</PlaceholderSubTitle>
          <PlaceholderImageWrapper>
            <img src={PlaceholderImage} alt="placeholder" />
          </PlaceholderImageWrapper>
        </Placeholder>
      </Layout>
    )
  }
  
  export default Statistics