import React from 'react'
import styled from '@emotion/styled'

import peopleIcon from '../../static/icUser.png'

const VideoMutedContainer = styled.div`
  align-items: center;
  background-color: #353c5a;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  width: 100%;
`

const VideoMutedAvatar = styled.div`
  height: 60%;
  width: 33.75%;
  max-height: 12.5vh;
  max-width: 12.5vh;
  overflow: hidden;
  text-align: center;
`

const VideoMutedAvatarLogo = styled.img`
  border-radius: 50%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
`

const VideoMutedAvatarDefault = styled.img`
  background: white;
  border-radius: 50%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 35%;
`

const VideoMutedUsername = styled.div`
  color: white;
  font-size: 16px;
  margin-top: 30px;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  padding: 0 15px;
  overflow: hidden;
  font-weight: 500;
`

interface IProps {
  logo: string
  username: string
}

export default function VideoMutedOverlay({ logo, username }: IProps) {
  return (
    <VideoMutedContainer>
      <VideoMutedAvatar>
        {logo ? (
          <VideoMutedAvatarLogo src={logo} />
        ) : (
          <VideoMutedAvatarDefault src={peopleIcon} />
        )}
      </VideoMutedAvatar>
      <VideoMutedUsername>{username}</VideoMutedUsername>
    </VideoMutedContainer>
  )
}
