import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import wrapper_bg from 'src/static/not-found-bg.jpg'

import { agencySelectors } from '../redux'
import { validateAgencyLogoWhite } from '../utils/validateAgencyLogo'

import { HyperLink, Text, TextBold } from './Typography'

const LandingWrapper = styled.div`
  background: #b16072 url(${wrapper_bg}) no-repeat center / auto 100%;
  width: 100vw;
  background-size: cover;
  border: 1px solid transparent;
  @media screen and (max-width: 600px) {
    padding: 30px 29px;
  }
`

const Content = styled.div`
  height: 100vh !important;
  max-width: 1210px;
  margin: auto;
  padding: 100px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  @media screen and (max-width: 600px) {
    padding: 0px;
  }
`

const Logo = styled.div`
  width: 100%;
  height: 52px;

  & img {
    height: 100%;
    width: auto;
  }
  @media screen and (max-width: 600px) {
    height: 35px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    margin: 0px auto;
  }
`

const NumbersText = styled(TextBold)`
  color: #fff;
  font-size: 155px;
  line-height: 184px;
  @media screen and (max-width: 600px) {
    font-size: 130px;
    line-height: 154px;
  }
`

const BigText = styled(TextBold)`
  color: #fff;
  font-size: 31px;
  line-height: 37px;
  margin-bottom: 30px;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    color: #fff;
    font-size: 31px;
    line-height: 37px;
    margin-bottom: 30px;
    font-weight: bold;
  }
`
const SmallText = styled(Text)`
  color: #fff;
  font-size: 16px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 25px;
  }
`
const TextLinkWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`
const GoBackHomeLink = styled(HyperLink)`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-left: 9px;
  &:hover {
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const NotFound = () => {
  const agencyBySubdomain = useSelector(agencySelectors.data)

  return (
    <LandingWrapper>
      <Content>
        <Logo>
          <img
            src={validateAgencyLogoWhite(agencyBySubdomain?.negative_logo.url)}
            alt="Logo"
          />
        </Logo>
        <TextWrapper>
          <NumbersText>404</NumbersText>
          <BigText>Got lost?</BigText>
          <TextLinkWrapper>
            <SmallText>Page not found</SmallText>
            <GoBackHomeLink href="https://wizco.io">Back Home</GoBackHomeLink>
          </TextLinkWrapper>
        </TextWrapper>
      </Content>
    </LandingWrapper>
  )
}

export default NotFound
