import httpClient from '../../httpClient'

// agencies#create - POST /agencies/
export const createAgency = (agency, price_tiers?) => {
  return httpClient
    .post(`/agencies`, { agency, price_tiers })
    .then((response) => response.data)
}

// agencies#index - GET /agencies/
export const getAgencies = () => {
  return httpClient.get('/agencies').then((response) => response.data)
}

// agencies#show - GET /agencies/:agency_id
export const getAgency = (agencyId) => {
  return httpClient.get(`/agencies/${agencyId}`).then((response) => response.data)
}

// agencies#update - PUT /agencies/:agency_id
export const editAgency = (agencyId, data) => {
  return httpClient.put(`/agencies/${agencyId}`, data).then((response) => response.data)
}

// agencies#bulk_interviews - GET /agencies/:agency_id/bulk_interviews
export const getBulkInterviews = (agencyId) => {
  return httpClient
    .get(`/agencies/${agencyId}/bulk_interviews`)
    .then((response) => response.data)
}

// agencies#by_subdomain - GET /agencies/:subdomain/by_subdomain
export const getAgencyBySubdomain = (subdomain) => {
  return httpClient
    .get(`/agencies/${subdomain}/by_subdomain`)
    .then((response) => response.data)
}
