import React from 'react'
import styled from '@emotion/styled'
import CompletedIcon from 'src/static/complete-rescheduling-icon.png'
import BackgroundImg from 'src/static/complete-rescheduling-background.png'
import BackgroundImgMobile from 'src/static/complete-rescheduling-background-mobile.png'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
`

const ContentWrapper = styled.div`
  width: 781px;
  height: 480px;
  background: url(${isMobileOnly ? BackgroundImgMobile : BackgroundImg});
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    margin: 8px;
    width: 100%;
    height: 430px;
  }
`

const Icon = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
  margin-bottom: 13px;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: #222b45;
  margin-bottom: 12px;
  max-width: 350px;
  text-align: center;
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #21304e;
`

const ApplicantRescheduledComponent = () => {
  return (
    <Container>
      <ContentWrapper>
        <Icon src={CompletedIcon} alt="icon" />
        <Title>Thank you for updating your preferred availability.</Title>
        <SubTitle>We&apos;ll contact you shortly with new available time slots.</SubTitle>
      </ContentWrapper>
    </Container>
  )
}

export default ApplicantRescheduledComponent
