import styled from '@emotion/styled'
import React from 'react'
import bagIcon from 'src/static/icBag.png'
import calendarIcon from 'src/static/icCalendar.png'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'
import { isMobileOnly } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { formattedDateShort, getShortLocalTime } from 'src/utils/date'
import { avaInterviewSelectors } from 'src/redux'
import { ButtonBase } from '../elements/styled-components'

const Container = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  color: #3b424f;
  margin-bottom: 23px;
`

const InfoBlock = styled.div<{ icon?: string; flex?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 226px;
  padding-left: ${({ icon }) => (icon ? 36 : 0)}px;
  flex: ${({ flex }) => (flex && isMobileOnly ? '1' : 'unset')} !important;

  ${isMobileOnly &&
  css`
    width: auto;

    &:last-child {
      margin-right: 0 !important;
    }
  `}

  @media screen and (max-width: 900px) {
    width: auto;
    flex: 1;
    margin-right: 24px;
  }

  ${({ icon }) =>
    icon &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: url(${icon});
        background-size: auto 100%;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
      }
    `}
`

const LineContainer = styled.div<{ useBackground?: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  padding: ${({ useBackground }) => (useBackground && isMobileOnly ? '8px' : 0)};
  background: ${({ useBackground }) =>
    useBackground && isMobileOnly ? '#F5F6F8' : 'transparent'};
  border-radius: 8px;

  ${isMobileOnly &&
  css`
    &:last-child {
      margin-bottom: 0 !important;
    }
  `}
`

const HeadText = styled.div<{ flex?: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 4px;
  flex: ${({ flex }) => (flex ? '1' : 'unset')};
`

const InfoText = styled(HeadText)<{ toLeft?: boolean }>`
  font-weight: 400;
  margin: 0;
  text-align: ${({ toLeft }) => (toLeft ? 'end' : 'unset')};
`

const ButtonStyled = withProps()(styled(ButtonBase)`
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 32px;
  padding: 18px 46px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-left: auto;

  @media screen and (max-width: 1280px) {
    display: none;
  }
`)

interface IProps {
  onNewSession: () => void
}

const SessionDeatilsComponent = ({ onNewSession }: IProps) => {
  const interview = useSelector(avaInterviewSelectors.data)
  if (!interview) return undefined

  const date = formattedDateShort(interview?.completed_at)
  const time = getShortLocalTime(interview?.completed_at)

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <InfoBlock>
            <HeadText>
              {interview.candidate.first_name} {interview.candidate.last_name}
            </HeadText>
            <InfoText>{interview.candidate.email}</InfoText>
          </InfoBlock>
          <InfoBlock icon={bagIcon}>
            <HeadText>{interview.position.role}</HeadText>
            <InfoText>{interview.position.company}</InfoText>
          </InfoBlock>
          <InfoBlock icon={calendarIcon}>
            <HeadText>{date}</HeadText>
            <InfoText>{time}</InfoText>
          </InfoBlock>
          <ButtonStyled onClick={onNewSession}>Practice again</ButtonStyled>
        </Container>
      )}
      {isMobileOnly && (
        <Container column>
          <LineContainer>
            <HeadText flex>
              {interview.candidate.first_name} {interview.candidate.last_name}
            </HeadText>
            <InfoText flex toLeft>
              {interview.candidate.email}
            </InfoText>
          </LineContainer>
          <LineContainer useBackground>
            <InfoBlock icon={bagIcon} flex>
              <HeadText>{interview.position.role}</HeadText>
              <InfoText>{interview.position.company}</InfoText>
            </InfoBlock>
            <InfoBlock icon={calendarIcon}>
              <HeadText>{date}</HeadText>
              <InfoText>{time}</InfoText>
            </InfoBlock>
          </LineContainer>
        </Container>
      )}
    </>
  )
}

export default SessionDeatilsComponent
