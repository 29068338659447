import React from 'react'
import styled from '@emotion/styled'

import SvgChat from 'src/static/components/SvgChat'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

export const DetailsButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

interface IDetailsButton extends React.HTMLProps<HTMLDivElement> {
  disabled: boolean
  active: boolean
  showBadge: boolean
  theme: any
  onClick: () => void
}

export default function ChatButton({
  disabled,
  active,
  theme,
  showBadge,
  children,
  onClick,
}: IDetailsButton) {
  const label = 'Chat'
  return (
    <HeaderButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgChat
          fill={active ? theme.textColorActive : theme.textColor}
          showBadge={showBadge}
        />
        <DetailsButtonLabel active={active}>{label}</DetailsButtonLabel>
      </HeaderIconContainer>
      {children}
    </HeaderButtonContainer>
  )
}
