import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import * as interviewSelectors from '../selectors'
import arrowDown from '../../static/arrow-down2.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const DetailsContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #edeef2;
  border-radius: 8px;
`

const DetailsHeader = styled.div`
  display: flex;
  height: 80px;
  padding: 8px 15px;
  align-items: center;
`

const ProfilePhoto = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 32px;
`

const HeaderDataContainer = styled.div<{ centered?: boolean }>`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(props) => (props.centered ? 'center' : 'start')};
`

const ApplicantName = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #3b424f;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #222b45;
  margin: 0 0 10px 0;
`

const DetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px 15px;
  border-top: 1px solid #edeef2;
`

const DetailsTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: rgba(115, 121, 145, 0.8);
  margin: 12px 0 4px;
`

const DetailsText = styled.div<{ zeroMargin?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b424f;
  margin-bottom: ${(props) => (props.zeroMargin ? 0 : 6)}px;

  > svg {
    margin-right: 8px;
  }

  > p {
    margin-bottom: 0;
  }
`

const HeadArrow = styled.span<{ expanded: boolean }>`
  background-image: url(${arrowDown});
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%;
  margin-right: 5px;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : '')};
`

function PreStartExpertInfoMobile() {
  // const agency = useSelector(interviewSelectors.agency)
  const interviewDetails = useSelector(interviewSelectors.interview)

  const [showDetails, setShowDetails] = useState(false)
  const peerFullName = `${interviewDetails.peer.first_name} ${interviewDetails.peer.last_name}`

  return (
    <Container>
      <Title>You&apos;ll be meeting</Title>
      <DetailsContainer>
        <DetailsHeader onClick={() => setShowDetails(!showDetails)}>
          <ProfilePhoto src={interviewDetails.peer.logo.url} />
          <HeaderDataContainer centered>
            <ApplicantName>{peerFullName}</ApplicantName>
            <DetailsText zeroMargin>{interviewDetails.peer.current_position}</DetailsText>
          </HeaderDataContainer>
          <HeadArrow expanded={showDetails} />
        </DetailsHeader>
        {showDetails && (
          <DetailsInfoContainer>
            {interviewDetails.peer.about && (
              <>
                <DetailsText zeroMargin>{interviewDetails.peer.about}</DetailsText>
              </>
            )}
            <DetailsTitle>Experience</DetailsTitle>
            {interviewDetails.peer.experiences.map((exp) => (
              <DetailsText zeroMargin key={exp}>
                {exp}
              </DetailsText>
            ))}
            <DetailsTitle>Education</DetailsTitle>
            {interviewDetails.peer.educations.map((exp) => (
              <DetailsText zeroMargin key={exp}>
                {exp}
              </DetailsText>
            ))}
          </DetailsInfoContainer>
        )}
      </DetailsContainer>
    </Container>
  )
}

export default PreStartExpertInfoMobile
