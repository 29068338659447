import DeviceUnavailable from './DeviceUnavailable'
import { EndCallNotification, EndCallNotificationMobile } from './EndCallNotification'
import { PeerLeft, MobilePeerLeft } from './PeerLeft'
import {
  PoorConnectionIndicator,
  MobilePoorConnectionIndicator,
} from './PoorConnectionIndicator'
import { StartingSoon, MobileStartingSoon } from './StartingSoon'
import { OnBoarding, OnBoardingMobile } from './onboarding/OnBoardingSteps'
import { OnBoardingChat, OnBoardingChatMobile } from './onboarding/OnBoardingChat'
import EndCallView from './EndCallView'
import {
  MobilePeerJoinedCodeNotification,
  PeerJoinedCodeNotification,
} from './PeerJoinedCodeNotification'
import PeerLeftCodeNotification from './PeerLeftCodeNotification'
import DisconnectedNotification from './DisconnectedNotification'
import AgoraError from './AgoraError'
import ConnectAlternativeRoom from './ConnectAlternativeRoom'
import GoToAltRoomNotification from './GoToAltRoomNotification'
import ScreenShareErrorNotification from './ScreenShareErrorNotification'

export default {
  DeviceUnavailable,
  EndCallNotification,
  EndCallNotificationMobile,
  OnBoarding,
  OnBoardingMobile,
  OnBoardingChat,
  OnBoardingChatMobile,
  PeerLeft,
  PoorConnectionIndicator,
  StartingSoon,
  MobileStartingSoon,
  MobilePeerLeft,
  EndCallView,
  MobilePoorConnectionIndicator,
  PeerJoinedCodeNotification,
  PeerLeftCodeNotification,
  MobilePeerJoinedCodeNotification,
  DisconnectedNotification,
  AgoraError,
  ConnectAlternativeRoom,
  GoToAltRoomNotification,
  ScreenShareErrorNotification,
}
