import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import SvgClose from 'src/static/close_gray.svg'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(9px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DataContainer = styled.div`
  background: #ffffff;
  border: 1px solid #b202ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 549px;
  padding: 40px;
`

const HeadContainer = styled.div`
  display: flex;
  width: 100;
`

const Title = styled.div`
  font-size: 32px;
  line-height: 34px;
  color: #1d0e3b;
  font-weight: 700;
  margin-bottom: 12px;
  flex: 1;
  @media screen and (max-width: 2500px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 900px) {
    font-size: 16px;
    line-height: 18px;
  }
`

const BottomButton = styled(Button)`
  width: fit-content;
  height: auto;
  border-radius: 14px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
`

const CloseButton = styled.img`
  cursor: pointer;

  height: 18px;
  width: 18px;
  @media screen and (max-width: 2500px) {
    height: 16px;
    width: 16px;
  }
  @media screen and (max-width: 2000px) {
    height: 14px;
    width: 14px;
  }
  @media screen and (max-width: 1200px) {
    height: 12px;
    width: 12px;
  }
  @media screen and (max-width: 900px) {
    height: 10px;
    width: 10px;
  }
`

const ContentText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #3c424e;
  margin-bottom: 24px;
`

const BoldText = styled.span`
  font-weight: 500;
`

export enum UserLateMessage {
  None,
  First,
  FirstClosed,
  Last,
  LastClosed,
}

interface IProps {
  messageType: UserLateMessage
  isExpert: boolean
  peerFirstName: string
  peerLastName: string
  onClose: () => void
}

const LateMessage = ({
  messageType,
  isExpert,
  peerFirstName,
  peerLastName,
  onClose,
}: IProps) => {
  const getUserLateMessageTitle = useCallback(() => {
    switch (messageType) {
      case UserLateMessage.First:
        return `We are checking on ${peerFirstName} ${peerLastName}'s availability and status update`
      case UserLateMessage.Last:
        return `${isExpert ? 'Applicant' : 'Expert'} No Show`
      default:
        return ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType, isExpert])

  const getUserLateMessageContent = useCallback(() => {
    switch (messageType) {
      case UserLateMessage.First:
        return (
          <>
            <ContentText>
              {`We apologize for the delay in starting the coaching session. We are doing
              our best to ensure that the session can begin as soon as possible, and we
              are checking with ${peerFirstName} to see if they are joining the session`}
            </ContentText>
            <ContentText>
              <BoldText>
                Please wait 5 more minutes while we try to resolve this issue.
              </BoldText>{' '}
              We appreciate your patience and understanding.
            </ContentText>
          </>
        )
      case UserLateMessage.Last:
        if (isExpert) {
          return (
            <>
              <ContentText>
                It seems that {`${peerFirstName}`} can’t join the session. We understand
                that your time is valuable and want to assure you that you{' '}
                <BoldText>
                  will be fully compensated for your time spent waiting.
                </BoldText>
              </ContentText>
              <ContentText>
                {`In the meantime, you can click the 'Leave Session' button below and we'll
                be in touch soon.`}
              </ContentText>
            </>
          )
        }

        return (
          <>
            <ContentText>
              {`It seems that ${peerFirstName} can’t join the session. We understand that
              your time is valuable and we apologize for the inconvenience. We want to
              assure you that we will do our best to reschedule the session as soon as
              possible.`}
            </ContentText>
            <ContentText>
              {`In the meantime, you can click the 'Leave Session' button below and we'll be
              in touch soon.`}
            </ContentText>
          </>
        )
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType, isExpert])

  const getUserLateMessageButtonTitle = useCallback(() => {
    switch (messageType) {
      case UserLateMessage.First:
        return 'Okay'
      case UserLateMessage.Last:
        return 'Leave Session'
      default:
        return ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageType])

  return (
    <Container>
      <DataContainer>
        <HeadContainer>
          <Title>{getUserLateMessageTitle()}</Title>
          <CloseButton onClick={onClose} src={SvgClose} />
        </HeadContainer>
        {getUserLateMessageContent()}
        <BottomButton title={getUserLateMessageButtonTitle()} onClick={onClose} />
      </DataContainer>
    </Container>
  )
}

export default LateMessage
