import { RootState } from '../redux'

export const agencies = {
  agencies(state: RootState) {
    return state.admin.agencies.agencies
  },
  isLoading(state: RootState) {
    return state.admin.agencies.isLoading
  },
  error(state: RootState) {
    return state.admin.agencies.error
  },
}

export const interviews = {
  interviews(state: RootState) {
    return state.admin.interviews.interviews
  },
  pages(state: RootState) {
    return state.admin.interviews.pages || 1
  },
  isLoading(state: RootState) {
    return state.admin.interviews.isLoading
  },
  error(state: RootState) {
    return state.admin.interviews.error
  },
}

export const stats = {
  stats(state: RootState) {
    return state.admin.stats.stats
  },
  isLoading(state: RootState) {
    return state.admin.stats.isLoading
  },
  error(state: RootState) {
    return state.admin.stats.error
  },
}
