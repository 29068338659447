import { RootState } from '../redux'

export const isLoading = (state: RootState) => {
  return state.interview.interview.isLoading
}

export const error = (state: RootState) => {
  return state.interview.interview.error
}

export const interview = (state: RootState) => {
  return state.interview.interview.data
}

export const conversationIntelligence = (state: RootState) => {
  return state.interview.interview.data.interview.enable_conversation_intelligence
}

export const agency = (state: RootState) => {
  return state.interview.interview.data.agency
}

export const hasEmailDomainValidation = (state: RootState) => {
  return state.interview.interview.data.agency?.email_domain_validations?.length > 0
}

export const experts = (state: RootState) => {
  return state.interview.interview.data.experts
}

export const profile = (state: RootState) => {
  return state.interview.interview.data.profile
}

export const token = (state: RootState) => {
  return state.interview.interview.data.token
}

export const businessModel = (state: RootState) => {
  return state.interview.interview.data.agency.business_model
}

export const agencyMainColor = (state: RootState) => {
  return state.interview.interview.data.agency.main_color
}

export const status = (state: RootState) => {
  return state.interview.interview.data.interview.status
}

export const preventRedirect = (state: RootState) => {
  return state.interview.interview.preventRedirect
}

export const success = (state: RootState) => {
  return state.interview.interview.success
}

export const assignTimeSlotSuccess = (state: RootState) => {
  return state.interview.assignTimeSlot.success
}

export const createIntentSuccess = (state: RootState) => {
  return state.interview.createIntent.success
}

export const clientSecret = (state: RootState) => {
  return state.interview.createIntent.clientSecret
}

export const createIntentError = (state: RootState) => {
  return state.interview.createIntent.error
}

export const createPaymentSuccess = (state: RootState) => {
  return state.interview.createPayment.success
}

export const createPaymentPending = (state: RootState) => {
  return state.interview.createPayment.isLoading
}

export const createPaymentError = (state: RootState) => {
  return state.interview.createPayment.error
}

export const paySucceedSuccess = (state: RootState) => {
  return state.interview.paySucceed.success
}

export const invoicePaid = (state: RootState) => {
  return state.interview.interview.data.interview.invoice_paid
}

export const interviewStartedAt = (state: RootState) => {
  return state.interview.interview.data.interview.started_at
}

export const interviewPrice = (state: RootState) => {
  return state.interview.interview.data.interview.price
}

export const interviewCurrency = (state: RootState) => {
  return state.interview.interview.data.agency.currency
}

export const interviewOrderNumber = (state: RootState) => {
  return state.interview.interview.data.interview.order_number
}

export const interviewPositionCompany = (state: RootState) => {
  return state.interview.interview.data.interview.position_company
}

export const interviewPosition = (state: RootState) => {
  return state.interview.interview.data.interview.desired_position
}

export const interviewDuration = (state: RootState) => {
  return state.interview.interview.data.interview.duration
}

export const candidateName = (state: RootState) => {
  const { role } = state.interview.interview.data.token

  if (role === 'candidate') {
    const { first_name, last_name } = state.interview.interview.data.profile

    return `${first_name} ${last_name}`
  }

  if (role === 'expert' || role === 'potential_expert' || role === 'rejected_expert') {
    const { first_name, last_name } = state.interview.interview.data.peer

    return `${first_name} ${last_name}`
  }

  return ''
}

export const agencyCodeSharing = (state: RootState) => {
  return state.interview.interview.data.agency.code_sharing
}

export const codingSessionUrl = (state: RootState) => {
  return state.interview.interview.data.interview.coding_session_url
}
export const codingPlaybackUrl = (state: RootState) => {
  return state.interview.interview.data.interview.coding_playback_url
}

export const hasRescheduled = (state: RootState) => {
  return state.interview.interview.data.interview.has_rescheduled
}

export const createBooking = {
  position(state: RootState) {
    return state.interview.createBooking.position
  },
  isLoading(state: RootState) {
    return state.interview.createBooking.isLoading
  },
  success(state: RootState) {
    return state.interview.createBooking.success
  },
  error(state: RootState) {
    return state.interview.createBooking.error
  },
}

export const submitAvailability = {
  isLoading(state: RootState) {
    return state.interview.submitAvailability.isLoading
  },
  success(state: RootState) {
    return state.interview.submitAvailability.success
  },
  error(state: RootState) {
    return state.interview.submitAvailability.error
  },
}

export const declineAvailability = {
  isLoading(state: RootState) {
    return state.interview.declineAvailability.isLoading
  },
  success(state: RootState) {
    return state.interview.declineAvailability.success
  },
  error(state: RootState) {
    return state.interview.declineAvailability.error
  },
}
