import { put, takeEvery, call } from 'typed-redux-saga'

import {
  editAgency,
  IAdminProfile,
  IAgency,
  IAgencyPriceTiersB2C,
  IInternalExpert,
} from '../../api'

import handleErrorSaga from '../../utils/handleErrorSaga'
import handleSuccessSaga from '../../utils/handleSuccessSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { updateAgencyAction } from '../data/agency'

interface IEditAgencyAction {
  agencyId: string
  agency: Partial<IAgency>
  agencyAdmins: IAdminProfile[]
  agencyManagers: {
    email: string
    name?: string
  }[]
  internalExperts: IInternalExpert[]
  priceTiers?: IAgencyPriceTiersB2C
}

const {
  action: editAgencyAction,
  actionFailure: editAgencyActionFailure,
  actionSuccess: editAgencyActionSuccess,
  actionSuccessClear: editAgencyActionSuccessClear,
  actionFailureClear: editAgencyActionFailureClear,
  actionType: editAgencyActionType,
  actionTypeFailure: editAgencyActionFailureType,
  actionTypeSuccess: editAgencyActionSuccessType,
  reducer: editAgencyRequestReducer,
  selectors: editAgencySelectors,
} = reduxRequestFactory<IEditAgencyAction>('editAgency', 'requests')

export {
  editAgencyAction,
  editAgencyActionFailure,
  editAgencyActionSuccess,
  editAgencyActionSuccessClear,
  editAgencyActionFailureClear,
  editAgencyActionType,
  editAgencyActionFailureType,
  editAgencyActionSuccessType,
  editAgencyRequestReducer,
  editAgencySelectors,
}

function* editAgencySaga({ payload }: ISagaAction<IEditAgencyAction>) {
  try {
    const {
      agencyId,
      agency,
      agencyAdmins: agency_admins,
      agencyManagers: agency_managers,
      internalExperts: internal_experts,
      priceTiers: price_tiers,
    } = payload

    const updatedAgency = yield* call(editAgency, agencyId, {
      agency,
      agency_admins,
      agency_managers,
      internal_experts,
      price_tiers,
    })
    yield call(handleSuccessSaga, 'Successfully saved')
    yield put(updateAgencyAction(updatedAgency))
    yield put(editAgencyActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'

    if (error.response && error.response.data) {
      errorMessage = error.response.data.errors
    }

    yield call(handleErrorSaga, { message: errorMessage })
    yield put(
      editAgencyActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchEditAgency() {
  yield takeEvery(editAgencyActionType, editAgencySaga)
}
