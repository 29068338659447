import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgDiscloserSmall({ fill }: SvgProps) {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6001 1L4.6001 4L1.6001 0.999999"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgDiscloserSmall
