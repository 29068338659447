import React from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'

import topSectionSvg from 'src/static/sheduling-top.svg'
import { useSelector } from 'react-redux'
import { Text } from 'src/components'
import { IExpertProfile } from 'src/api/models'
import colors from '../../static/colors'

import * as interviewSelectors from '../selectors'
import ScheduleThankYou from '../components/ScheduleThankYou'
import { validateAgencyLogoWhite } from '../../utils/validateAgencyLogo'

const MainWrap = styled.div`
  overflow: visible;
`

const LandingWrapper = styled.div`
  color: ${colors.formHint};
  max-width: 1920px;
  margin: 0 auto;
  border: 1px solid transparent;
`
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 0;

  @media screen and (max-width: 600px) {
    margin: 0;
    background-size: cover;
    justify-content: normal;
    background: #cb55ff url(${topSectionSvg}) no-repeat;
    align-items: normal;
    flex-direction: column-reverse;
    padding: 30px 30px 50px;
  }
`
const Promo = styled.div`
  height: 190px;
  width: 820px;
  border-radius: 95px;
  background-color: #f5f6f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 200px;
  position: relative;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 0;
    border-radius: 0;
    background: none;
    padding-top: 80px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: #ffdb46;
    width: 190px;
    height: 190px;
    z-index: 1;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 95px;
    background: #f5f6f8;
    width: 100px;
    height: 190px;
    z-index: 1;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`

const VioletBlock = styled.div`
  display: block;
  position: absolute;
  left: 48px;
  top: 48px;
  width: 96px;
  height: 195px;
  background: #b202ff;
  z-index: 2;
  border-radius: 48px;
  &:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    border-radius: 50%;
    background: #cb55ff;
    width: 96px;
    height: 96px;
    z-index: 3;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
`
const PromoTitle = styled(Text)`
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    color: #fff;
    font-size: 34px;
    font-weight: 500;
    line-height: 120%;
    width: 90%;
  }
`

const PromoSubTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const Logo = styled.div`
  height: 54px;

  & img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }

  @media screen and (max-width: 600px) {
    height: 35px;
  }
`

const ResponsiveWrapper = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    padding: 0;
    margin: 0;
  }
`

const ThankYouWrapper = styled.div`
  display: flex;
  margin-top: 80px;
  @media screen and (min-height: 900px) {
    margin-top: 135px;
  }

  @media screen and (max-width: 600px) {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin-top: -30px;
    background: #fff;
    padding: 40px 30px 30px;
    display: block;
  }
`

const SchedulingThankYou = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  const agency = useSelector(interviewSelectors.agency)
  const data = useSelector(interviewSelectors.interview)
  const paySucceedSuccess = useSelector(interviewSelectors.paySucceedSuccess)
  const invoicePaid = useSelector(interviewSelectors.invoicePaid)

  const {
    interview: {
      desired_position: desiredPosition,
      position_company: desiredCompany,
      payment_required: paymentRequired,
      main_time_slot_id,
    },
    peer: expert,
  } = data
  const slot = (expert as IExpertProfile).time_slots.find(
    (s) => s.id === main_time_slot_id
  )

  const showThanks =
    (paymentRequired && paySucceedSuccess) || !paymentRequired || invoicePaid
  const interviewPosition = `${desiredPosition} ${
    desiredCompany ? `at ${desiredCompany}` : ''
  }`

  return (
    <MainWrap>
      <LandingWrapper>
        <ResponsiveWrapper>
          <TopSection>
            <Promo>
              <VioletBlock />
              <PromoTitle>It pays to be prepared.</PromoTitle>
              <PromoSubTitle>{`Coaching session for ${interviewPosition}`}</PromoSubTitle>
            </Promo>
            <Logo>
              <img
                src={validateAgencyLogoWhite(
                  isMobile ? agency.negative_logo?.url : agency.company_logo?.url
                )}
                alt="Logo"
              />
            </Logo>
          </TopSection>
          <ThankYouWrapper>
            <ScheduleThankYou
              slot={slot}
              position={interviewPosition}
              expert={expert}
              showThanks={showThanks}
            />
          </ThankYouWrapper>
        </ResponsiveWrapper>
      </LandingWrapper>
    </MainWrap>
  )
}

export default SchedulingThankYou
