import * as React from 'react'

interface SvgProps {
  fill: string
}

function SvgBag({ fill }: SvgProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8652 8.51642C13.267 8.51642 13.5927 8.8405 13.5927 9.24028C13.5927 9.60675 13.319 9.90961 12.9639 9.95754L12.8652 9.96415H12.8208C12.419 9.96415 12.0933 9.64006 12.0933 9.24028C12.0933 8.87382 12.367 8.57096 12.7221 8.52303L12.8208 8.51642H12.8652ZM7.20951 8.51642C7.61129 8.51642 7.937 8.8405 7.937 9.24028C7.937 9.60675 7.66331 9.90961 7.30822 9.95754L7.20951 9.96415H7.16511C6.76333 9.96415 6.43762 9.64006 6.43762 9.24028C6.43762 8.87382 6.71131 8.57096 7.0664 8.52303L7.16511 8.51642H7.20951Z"
        fill={fill || '#fff'}
      />
      <path
        d="M10.009 -2.3189e-05C12.7655 -2.3189e-05 15 1.95013 15 4.3557C15 4.71152 14.6695 4.99998 14.2617 4.99998C13.854 4.99998 13.5235 4.71152 13.5235 4.3557C13.5235 2.66178 11.95 1.28858 10.0059 1.28857C9.07121 1.28514 8.17345 1.60676 7.51114 2.18232C6.84883 2.75788 6.47652 3.53999 6.47652 4.3557C6.47652 4.71152 6.14599 4.99998 5.73826 4.99998C5.33053 4.99998 5 4.71152 5 4.3557C5 3.1973 5.52873 2.08662 6.4693 1.26925C7.40985 0.451887 8.68476 -0.00484835 10.009 -2.3189e-05Z"
        fill={fill || '#fff'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6035 14.2117C18.6555 16.5657 16.7825 18.5227 14.4335 18.5727C14.3385 18.5757 14.2425 18.5747 14.1065 18.5687C11.3325 18.6157 8.56951 18.6157 5.85051 18.5697C3.48051 18.6827 1.50251 16.8427 1.39951 14.4897C1.39545 14.3977 1.39451 14.3037 1.39651 14.1957V9.8557C1.37151 8.7147 1.79151 7.6337 2.58151 6.8107C3.36951 5.9857 4.43151 5.5197 5.57451 5.4947C5.66551 5.4927 5.75651 5.49373 5.88251 5.4987H14.1195C14.1515 5.4987 14.1715 5.4967 14.1955 5.4967C16.6105 5.4967 18.4995 7.2407 18.6005 9.5787C18.6046 9.6717 18.6055 9.7647 18.6035 9.8697V14.2117ZM14.1145 4.1037H11.2665L5.91651 4.1047C5.79051 4.0967 5.66451 4.0957 5.54151 4.0997C4.02851 4.1317 2.61951 4.7517 1.57351 5.8457C0.526506 6.9387 -0.0314937 8.3727 0.00150628 9.8697V14.1817C-0.000493722 14.3057 -0.000493714 14.4297 0.00550629 14.5527C0.142506 17.6717 2.79051 20.0957 5.86951 19.9647C7.21151 19.9877 8.57551 19.9997 9.94651 19.9997C11.3255 19.9997 12.7115 19.9877 14.0895 19.9637C14.2115 19.9687 14.3325 19.9717 14.4605 19.9687C17.5825 19.8997 20.0665 17.3037 19.9985 14.1957V9.8857C20.0005 9.7627 19.9995 9.6387 19.9945 9.5177C19.8605 6.4407 17.2975 4.0777 14.1145 4.1037Z"
        fill={fill || '#fff'}
      />
    </svg>
  )
}

export default SvgBag
