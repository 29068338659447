import axios from 'axios'
import { getAuthorizationToken } from './utils/jwtToken'

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: getAuthorizationToken(),
    Accept: 'application/json',
  },
})

export const httpClientWithBlob = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: getAuthorizationToken(),
    // Accept: 'multipart/form-data',
    'Content-Type': 'multipart/form-data',
  },
})

export default httpClient
