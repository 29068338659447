import React from 'react'
import styled from '@emotion/styled'
import RadioButton from './RadioButton'

const Container = styled.div<{ disabled: boolean; direction: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

interface IItem {
  text: string
  value: string
}
interface IProps<T extends IItem> {
  value: string
  useTheme: boolean
  onChange: (value) => void
  options: T[]
  disabled?: boolean
  direction?: 'row' | 'column'
}

function RadioButtonGroup<T extends IItem>({
  value,
  onChange,
  useTheme,
  options,
  disabled,
  direction,
}: IProps<T>) {
  return (
    <Container disabled={disabled} direction={direction}>
      {options.map((option) => (
        <RadioButton
          key={option.text}
          useTheme={useTheme}
          label={option.text}
          onClick={() => onChange(option.value)}
          checked={value === option.value}
        />
      ))}
    </Container>
  )
}

RadioButtonGroup.defaultProps = {
  disabled: false,
}

RadioButtonGroup.defaultProps = {
  direction: 'row',
}

export default RadioButtonGroup
