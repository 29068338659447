import React, { useState } from 'react'
import styled from '@emotion/styled'
// import colors from '../../static/colors'
import { IAgency } from '../../api'
import { Link, RadioButtonGroup, TextBold, Title } from '../../components'

interface IAgenciesListProps {
  agencies: IAgency[]
  isSuperAdmin: boolean
}

const Container = styled.div`
  box-sizing: border-box;
`

const Name = styled.td``

const NameContainer = styled.div`
  display: flex;
`

const TagBlock = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  color: #fff;
  padding: 2px 12px;
  font-size: 12px;
  border-radius: 5px;
  width: 62px;
  text-align: center;
  margin: 2px 0;
`

const Table = styled.table`
  width: 100%;
  margin-top: 16px;
`

const Header = styled.header`
  margin-bottom: 25px;
`

const CreateLink = styled(Link)`
  color: blue;
`

const AgencyLink = styled(Link)`
  width: 220px;
`

enum SessionType {
  All = 'All',
  Expert = 'Expert',
  Ava = 'Ava',
}

const SessionTypes = [SessionType.All, SessionType.Expert, SessionType.Ava]

function AgenciesList(props: IAgenciesListProps) {
  const { agencies, isSuperAdmin } = props

  const [sessionType, setSessionType] = useState<SessionType>(SessionType.All)

  const getAgencyTypeColor = (agency: IAgency) => {
    if (agency.enable_ava && agency.enable_experts)
      return 'linear-gradient(20deg, #f1c232 0%, #f1c232 50%, #9900ff 50%,  #9900ff 100%)'
    if (agency.enable_ava) return '#f1c232'
    if (agency.enable_experts) return '#9900ff'
    return undefined
  }

  const getAgencyType = (agency: IAgency) => {
    if (agency.enable_ava && agency.enable_experts) return 'Hybrid'
    if (agency.enable_ava) return 'Ava'
    if (agency.enable_experts) return 'Expert'
    return undefined
  }

  return (
    <Container>
      <Header>
        <Title>Agencies</Title>
      </Header>
      <TextBold>Agency type</TextBold>
      <RadioButtonGroup
        value={sessionType}
        onChange={setSessionType}
        useTheme={false}
        options={SessionTypes.map((o) => {
          return { text: o, value: o }
        })}
      />
      <Table>
        <thead>
          <tr>
            <Name>
              <TextBold>Agency Name</TextBold>
            </Name>
            {isSuperAdmin && (
              <Name>
                <CreateLink to="/agencies/create">+ Create new agency</CreateLink>
              </Name>
            )}
          </tr>
        </thead>
        <tbody>
          {agencies
            .filter((agency) => {
              switch (sessionType) {
                case SessionType.Ava:
                  return agency.enable_ava
                case SessionType.Expert:
                  return agency.enable_experts
                default:
                  return true
              }
            })
            .map((agency) => (
              <tr key={agency.id}>
                <Name>
                  <NameContainer>
                    <AgencyLink to={`/agencies/${agency.id}`}>
                      {agency.internal_name || agency.name}
                    </AgencyLink>
                    <TagBlock color={getAgencyTypeColor(agency)}>
                      {getAgencyType(agency)}
                    </TagBlock>
                  </NameContainer>
                </Name>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default AgenciesList
