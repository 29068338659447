import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import useSupport from '../services/useSupport'

const Container = styled.div`
  height: 108px;
  display: flex;
  flex-direction: row;
  background: ${isMobileOnly ? 'white' : 'transparent'};
  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin: 0 16px;
    }
  `}
`

const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #3b424f;
  margin: ${isMobileOnly ? '50px 0 0 16px' : '58px 0 0 87px'};
`

const TextButton = styled(Title)`
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color: #b202ff;
  margin-left: 12px;
`

function AvailabilityFooter() {
  const support = useSupport()

  return (
    <Container>
      <Title>Do you have any questions?</Title>
      <TextButton onClick={() => support.openSupport()}>Contact Us</TextButton>
    </Container>
  )
}

export default AvailabilityFooter
