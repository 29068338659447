import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import withProps from 'recompose/withProps'
import LogoImg from 'src/static/logo_light.png'
import { css } from '@emotion/core'
import { validateEmail } from 'src/utils/string'
import ErrorIconImg from 'src/static/icErrorInfo.png'
import { subscribeAction } from 'src/redux/requests/subscribe'
import {
  BaseLayout,
  LayoutWrapper,
} from 'src/landing/components/landing-styled-components'
import { getBaseAddress, getPrivacyLink, getTermsOfuseLink } from 'src/utils/links'
import { ButtonBase } from './styled-components'

const isProd = window.location.host.indexOf('wizco.io') !== -1

const Layout = styled(BaseLayout)`
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  @media screen and (max-width: 1439px) {
    padding-top: 92px;
    max-width: unset;
  }

  ${isMobileOnly &&
  css`
    width: auto;
    padding-top: 48px !important;
  `}
`

const LinksContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media screen and (max-width: 1439px) {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 899px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  }

  ${isMobileOnly &&
  css`
    margin-bottom: 8px !important;
  `}
`

const Label = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #fff;
  margin-bottom: 32px;
  text-align: left;

  @media screen and (max-width: 1439px) {
    text-align: center;
  }
`

const LinksBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-bottom: 40px;
  }
`

const LinkTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 16px;

  @media screen and (max-width: 899px) {
    text-align: center;
  }

  ${isMobileOnly &&
  css`
    font-size: 20px;
  `}
`

const LinkItem = styled.a<{ disabled?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: ${({ disabled }) => (disabled ? '26px' : '20px')};
  color: #fff;
  text-decoration: none;
  white-space: break-spaces;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  margin-bottom: 8px;

  @media screen and (max-width: 899px) {
    text-align: center;
  }

  &:hover {
    text-decoration: underline;
    color: #fff;
  }
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  width: fit-content;
  align-self: center;

  ${isMobileOnly &&
  css`
    margin: 0 16px;
    margin-bottom: 17px;
    align-self: auto;
    width: auto;
  `}
`

const InputStyled = styled.input<{ error?: boolean; isHidden?: boolean }>`
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: ${({ error }) => (error ? '#F23482' : '#222b45')};
  width: 278px;
  outline: none;
  border-radius: 5px 0 0 5px;
  border: none;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};

  ${isMobileOnly &&
  css`
    flex: 1;
    width: auto;
  `}
`

const EmailButton = withProps()(styled(ButtonBase)<{ isHidden?: boolean }>`
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  color: #fff;
  display: flex;
  align-items: center;
  width: 172px;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};

  ${isMobileOnly &&
  css`
    font-size: 14px !important;
    line-height: 20px !important;
    min-width: 80px;
  `}
`)

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 80px;
  margin-bottom: 142px;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    padding: 0 80px;
  }

  @media screen and (max-width: 1144px) {
    flex-direction: column;
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 88px;
  }

  ${isMobileOnly &&
  css`
    padding: 0 40px !important;
    margin-bottom: 40px !important;
  `}
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 140px;

  @media screen and (max-width: 1280px) {
    margin-left: 60px;
  }

  @media screen and (max-width: 1144px) {
    margin: 0;
  }
`

const ErrorContainer = styled.div`
  width: fit-content;
  position: absolute;
  left: 0px;
  top: 48px;
  color: #f23482;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  padding: 6px 9px 6px 17px;
  &:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    top: 8px;
    left: 0;
    background: url(${ErrorIconImg});
    background-size: 100% 100%;
  }
`

const SuccessContainer = withProps()(styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  text-transform: uppercase;
  background: #0e0224;
  color: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};

  @media screen and (max-width: 1439px) {
    align-items: center;
  }
`)

const CopyrightContainer = styled.div`
  padding: 32px 0;
  margin: 0 80px;
  border-top: solid 1px rgba(217, 217, 217, 0.25);
  display: flex;

  @media screen and (max-width: 1280px) {
    margin: 0 40px;
  }

  @media screen and (max-width: 899px) {
    margin: 0;
  }

  ${isMobileOnly &&
  css`
    margin: 0 16px !important;
    padding: 20px 0 !important;
  `}
`

const Logo = styled.img`
  width: 56px;
  height: 12px;
  object-fit: contain;
  margin-right: 8px;
`

const CopyrightText = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`

const LinksData = [
  {
    title: 'Learn More',
    links: [
      { title: 'AI coaching', url: '/ava' },
      {
        title: 'Expert coaching',
        url: '/interviews',
      },
      {
        title: 'About us',
        url: `${getBaseAddress()}/about-us`,
      },
    ],
  },
  {
    title: 'Useful links',
    links: [
      {
        title: 'Compliance Framework',
        url: `https://www.wizco.${isProd ? 'io' : 'dev'}/compliance-framework`,
      },
      { title: 'Privacy Policy', url: getPrivacyLink() },
      { title: 'Terms of use', url: getTermsOfuseLink() },
      {
        title: 'Expert Terms and Conditions',
        url: `https://www.wizco.${isProd ? 'io' : 'dev'}/experts-terms-and-conditions`,
      },
    ],
  },
  {
    title: `Let's Talk`,
    links: [{ title: `Need to get in touch?\nContact us at : hi@wizco.io`, url: '' }],
  },
]

interface IProps {
  onClick?: (email: string) => void
}

const LandingFooter = ({ onClick }: IProps) => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleSubscribe = useCallback(
    (ev) => {
      ev.stopPropagation()
      if (!email) {
        setError('Please enter your email address')
      } else if (!validateEmail(email)) {
        setError('Please enter a valid email address')
      } else {
        setError('')
        setSuccess(true)
        dispatch(subscribeAction({ email, source: 'home' }))
        onClick(email)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email]
  )

  useEffect(() => {
    setError('')
  }, [email])

  useEffect(() => {
    window.onclick = () => setSuccess(false)
  }, [])

  return (
    <LayoutWrapper background="#0e0224">
      <Layout>
        <ContentContainer>
          <LinksContainer>
            {LinksData.map((linkData) => (
              <LinksBlock key={linkData.title}>
                <LinkTitle>{linkData.title}</LinkTitle>
                {linkData.links.map((link) => (
                  <LinkItem
                    href={link.url}
                    target="_self"
                    disabled={!link.url}
                    key={link.title}
                  >
                    {link.title}
                  </LinkItem>
                ))}
              </LinksBlock>
            ))}
          </LinksContainer>
          <DataContainer>
            <Label>Subscribe to our newsletter</Label>
            <InputContainer>
              <InputStyled
                placeholder="Your email"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
                error={error !== ''}
                isHidden={success}
              />
              {error && <ErrorContainer>{error}</ErrorContainer>}
              <EmailButton onClick={handleSubscribe} isHidden={success}>
                Subscribe
              </EmailButton>
              {success && <SuccessContainer>Thank you for subscribing!</SuccessContainer>}
            </InputContainer>
          </DataContainer>
        </ContentContainer>
        <CopyrightContainer>
          <Logo src={LogoImg} alt="logo" />
          <CopyrightText>Wizco.io 2023 ©</CopyrightText>
        </CopyrightContainer>
      </Layout>
    </LayoutWrapper>
  )
}

LandingFooter.defaultProps = {
  onClick: () => {},
}

export default LandingFooter
