/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'

import closeIcon from 'src/static/close_white.svg'
import useInterview from 'src/videocall/services/useInterview'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex: 1;
`

const Label = styled.p<{ marginBottom?: number; bold?: boolean }>`
  color: white;
  text-align: center;
  text-shadow: 0 0 2px black;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
  line-height: 150%;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

const LabelMobile = styled(Label)`
  line-height: 19px !important;
`

const BoldLabelMobile = styled(LabelMobile)`
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  @media (orientation: landscape) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const Close = styled.img`
  position: absolute;
  top: 85px;
  right: 85px;
  width: 20px;
  height: 20px;
  padding: 4px;
  cursor: pointer;
`

const CloseMobile = styled(Close)`
  top: 25px;
  right: 25px;
`

const Button = styled.button<{ color: string; textColor?: string }>`
  padding: 5px 15px;
  border-radius: 12px;
  border: none;
  color: ${({ textColor }) => textColor ?? '#fff'};
  background-color: ${({ color }) => color};
  font-weight: 500;
  font-size: 14px;
  margin: 0 auto;
  display: block;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:first-child {
    margin-right: 32px;
  }
`

const MobileContainer = styled.div`
  z-index: 999;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(18px);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
`

const MobileContent = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (orientation: landscape) {
    max-width: 350px;
  }
`

interface IDeviceUnavailable {
  extra: boolean
  isExpert: boolean
  onEnd: () => void
  onClose: (showFeedback?: boolean) => void
}

export default function EndCallView({
  extra,
  isExpert,
  onEnd,
  onClose,
}: IDeviceUnavailable) {
  // eslint-disable-next-line no-restricted-globals
  const onRefresh = () => location.reload()
  const { peerFirstName } = useInterview()

  return (
    <>
      {!isMobileOnly && (
        <Container>
          <Close src={closeIcon} onClick={() => onClose(false)} />
          <Label bold marginBottom={20}>
            {extra
              ? `Your session with ${peerFirstName} has ended.\nWhat would you like to do?`
              : 'Are you sure you want to end the session?'}
          </Label>
          {!extra && (
            <Label marginBottom={15}>
              {!isExpert
                ? `If you end the session, you'll not be able to rejoin the session`
                : `If you end the session, you'll not be able to rejoin the session or edit your feedback `}
            </Label>
          )}
          <ButtonContainer>
            <Button color="white" textColor="black" onClick={() => onClose(extra)}>
              {extra ? 'Edit my in-session feedback' : 'Cancel'}
            </Button>
            <Button
              color={extra ? '#B202FF' : '#FF002A'}
              onClick={extra ? onRefresh : onEnd}
            >
              {extra ? 'Complete report' : 'End session'}
            </Button>
          </ButtonContainer>
        </Container>
      )}
      {isMobileOnly && (
        <MobileContainer>
          <MobileContent>
            <CloseMobile src={closeIcon} onClick={() => onClose()} />
            <BoldLabelMobile>
              {extra
                ? `Your session with ${peerFirstName} has ended.\nWhat would you like to do?`
                : 'Are you sure you want to end the session?'}
            </BoldLabelMobile>
            <LabelMobile marginBottom={18}>
              {!isExpert
                ? `If you end the session, you'll not be able to rejoin the session`
                : `If you end the session, you'll not be able to rejoin the session or edit your feedback`}
            </LabelMobile>
            <ButtonContainer>
              <Button color="white" textColor="black" onClick={() => onClose(extra)}>
                {extra ? 'Edit my in-session feedback' : 'Cancel'}
              </Button>
              <Button
                color={extra ? '#B202FF' : '#FF002A'}
                onClick={extra ? onRefresh : onEnd}
              >
                {extra ? 'Complete report' : 'End session'}
              </Button>
            </ButtonContainer>
          </MobileContent>
        </MobileContainer>
      )}
    </>
  )
}
