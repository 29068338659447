import httpClient from '../../httpClient'

import { ISystemSetting, SystemSettingName } from '../models'

export interface ISettings {
  agencyPositions?: string
  speechSpeedMin?: number
  speechSpeedMax?: number
  singularPronounsMax?: number
  singularPronounsMin?: number
  confidentScoreMin?: number
  confidentScoreMax?: number
  dataUsageScoreMin?: number
  dataUsageScoreMax?: number
  pastTenseRangeMin?: number
  pastTenseRangeMax?: number
  sentimentPositiveMin?: number
  sentimentNegativeMax?: number
  analyticsDurationAfterStart?: number
  analyticsDurationBeforeEnd?: number
  singularPronounsAWords?: string
  singularPronounsBWords?: string
  dataUsageWords?: string
  confidentPhrases?: string
  tensePastVerbs?: string
  tensePresentVerbs?: string
  tenseFutureVerbs?: string
  minWordsCount?: number
  blurBackgroundEnabled?: boolean
  sttProvider?: string
}

type TransformedSettings = {
  [key in SystemSettingName]: string
}

// system_settings#index - GET /system_settings

export const getSystemSettings = (): Promise<ISettings> => {
  return httpClient.get('/system_settings').then((response) => {
    const settings = ((response.data as ISystemSetting[]) || []).reduce((acc, s) => {
      acc[s.name] = s.value
      return acc
    }, {} as TransformedSettings)

    return {
      agencyPositions: JSON.parse(settings.agency_positions),
      speechSpeedMin: Number(settings.speech_speed_min),
      speechSpeedMax: Number(settings.speech_speed_max),
      singularPronounsMax: Number(settings.singular_pronouns_max),
      singularPronounsMin: Number(settings.singular_pronouns_min),
      confidentScoreMin: Number(settings.confident_score_min),
      confidentScoreMax: Number(settings.confident_score_max),
      dataUsageScoreMin: Number(settings.data_usage_score_min),
      dataUsageScoreMax: Number(settings.data_usage_score_max),
      pastTenseRangeMin: Number(settings.past_tense_range_min),
      pastTenseRangeMax: Number(settings.past_tense_range_max),
      sentimentPositiveMin: Number(settings.sentiment_min),
      sentimentNegativeMax: Number(settings.sentiment_max),
      singularPronounsAWords: settings.singular_pronouns_a_words,
      singularPronounsBWords: settings.singular_pronouns_b_words,
      dataUsageWords: settings.data_usage_words,
      confidentPhrases: settings.confident_phrases,
      tensePastVerbs: settings.tense_past_verbs,
      tensePresentVerbs: settings.tense_present_verbs,
      tenseFutureVerbs: settings.tense_future_verbs,
      analyticsDurationAfterStart: Number(settings.analytics_duration_after_start),
      analyticsDurationBeforeEnd: Number(settings.analytics_duration_before_end),
      minWordsCount: Number(settings.min_words_count),
      blurBackgroundEnabled: settings.blur_background_enabled === '1',
      sttProvider: settings.stt_provider,
    }
  })
}

// system_settings#update - POST /system_settings/update

export const updateSystemSettings = (params: ISettings): Promise<void> => {
  const settings: ISystemSetting[] = [
    { name: 'agency_positions', value: JSON.stringify(params.agencyPositions) },
    { name: 'speech_speed_max', value: params.speechSpeedMax?.toString() },
    { name: 'speech_speed_min', value: params.speechSpeedMin?.toString() },
    { name: 'singular_pronouns_max', value: params.singularPronounsMax?.toString() },
    { name: 'singular_pronouns_min', value: params.singularPronounsMin?.toString() },
    { name: 'confident_score_min', value: params.confidentScoreMin?.toString() },
    { name: 'confident_score_max', value: params.confidentScoreMax?.toString() },
    { name: 'data_usage_score_min', value: params.dataUsageScoreMin?.toString() },
    { name: 'data_usage_score_max', value: params.dataUsageScoreMax?.toString() },
    { name: 'data_usage_words', value: params.dataUsageWords },
    { name: 'past_tense_range_min', value: params.pastTenseRangeMin?.toString() },
    { name: 'past_tense_range_max', value: params.pastTenseRangeMax?.toString() },
    { name: 'sentiment_min', value: params.sentimentPositiveMin?.toString() },
    { name: 'sentiment_max', value: params.sentimentNegativeMax?.toString() },
    { name: 'singular_pronouns_a_words', value: params.singularPronounsAWords },
    { name: 'singular_pronouns_b_words', value: params.singularPronounsBWords },
    { name: 'confident_phrases', value: params.confidentPhrases },
    { name: 'tense_past_verbs', value: params.tensePastVerbs },
    { name: 'tense_present_verbs', value: params.tensePresentVerbs },
    { name: 'tense_future_verbs', value: params.tenseFutureVerbs },
    {
      name: 'analytics_duration_after_start',
      value: params.analyticsDurationAfterStart.toString(),
    },
    {
      name: 'analytics_duration_before_end',
      value: params.analyticsDurationBeforeEnd.toString(),
    },
    { name: 'min_words_count', value: params.minWordsCount.toString() },
    { name: 'blur_background_enabled', value: params.blurBackgroundEnabled ? '1' : '0' },
    { name: 'stt_provider', value: params.sttProvider },
  ]
  return httpClient
    .post('/system_settings/update', { settings })
    .then((response) => response.data)
}
