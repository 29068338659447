import {
  IInterview,
  IInterviewToken,
  IInterviewReport,
  ICVFile,
  IExpertProfile,
  ICandidateProfile,
  InterviewStatus,
  IGetInterviewResponse,
  BusinessModel,
} from './api'

export const token: IInterviewToken = {
  id: '',
  interview_id: '',
  role: null,
  code: '',
  created_at: '',
  updated_at: '',
}

export const interviewProfle: IExpertProfile | ICandidateProfile = {
  about: null,
  company: null,
  created_at: '',
  current_position: null,
  educations: [],
  email: '',
  experiences: [],
  first_name: '',
  id: '',
  last_name: '',
  linkedin_profile: null,
  logo: {
    url: null,
  },
  rating: '',
  updated_at: '',
  contact_email: '',
}

export const interviewBody: IInterview = {
  id: '',
  agency_id: '',
  candidate_invited_at: null,
  candidate_profile_id: null,
  ended_at: null,
  joined_at: null,
  main_time_slot_id: null,
  report_provided_at: null,
  scheduled_at: null,
  started_at: null,
  status: InterviewStatus.PENDING,
  created_at: '',
  programming_language: '',
  order_number: 0,
  stripe_client_secret: null,
  updated_at: '',
  desired_position: null,
  special_request: null,
  position_company: null,
  position_location: null,
  link_to_position: null,
  position_description: null,
  interview_date: '',
  agree_to_share: null,
  scheduled_time: null,
  price: 0,
  feedback_rating: 0,
  feedback_expert_rating: 0,
  duration: 0,
  feedback_text: '',
  invoice_paid: false,
  special_request_by_admin: null,
  interview_date_option: null,
  coding_session_url: null,
  coding_playback_url: null,
  cv_file_id: null,
  cv_file: null,
  meta_data: null,
}
export const interviewReport: IInterviewReport = {
  id: '',
  interview_id: '',
  advancement: true,
  advancement_rating: 5,
  communication_rating: 5,
  first_impression_rating: 5,
  items_to_accomplish: '',
  items_to_improve: '',
  items_to_keep: '',
  knowledge_rating: 5,
  problem_solving_rating: 5,
  summary: '',
  typeform_response_id: '',
}

export const interview: IGetInterviewResponse = {
  interview: interviewBody,
  interview_price_tiers: [],
  token,
  janus_server_url: '',
  janus_server_url2: '',
  janus_plugin: '',
  agency: {
    id: '',

    additional_booking_url: '',
    additional_info: null,
    admin_booking: null,
    agency_price_tiers: null,
    business_model: BusinessModel.B2B,
    code_sharing: null,
    company_logo: { url: null },
    created_at: '',
    currency: 'USD',
    duration: 0,
    email: '',
    main_color: '',
    name: '',
    negative_logo: { url: null },
    r_and_d: null,
    session_price: 0,
    show_interview_links: null,
    subdomain: '',
    success_manager_email: null,
    success_manager_name: null,
    updated_at: '',
  },
  profile: interviewProfle,
  peer: interviewProfle,
  experts: [],
}

export const cvFile: ICVFile = {
  content_type: '',
  file: { url: '' },
  file_name: '',
  id: '',
  size: 0,
}
