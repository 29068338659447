/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled'
import { Button } from 'src/components'
import withProps from 'recompose/withProps'
import { isMobileOnly } from 'react-device-detect'
import colors from '../../static/colors'
import bgTop from '../../static/landing_main_banner.png'
import bgTopBottom from '../../static/top-background-bottom.png'
import bgBottom from '../../static/landing_bottom_background.png'
import bgBottomBottom from '../../static/landing_bottom_background_bottom.png'
import bgProfessionalGuidance from '../../static/guidance_background.png'
import bgPerson from '../../static/landing-person-background.png'
import bgCoachingSteps from '../../static/ic-coachingsteps-bg.png'
import bgOurExperts from '../../static/our-experts-bg.png'
import icQuote from '../../static/ic-quote-yellow.png'

interface ILandingButtonProps {
  onClick: () => void
  accentColor: string
}

const MiddleContainer = styled.div`
  max-width: 1210px;
  margin: 0 auto;
`

const DetailsText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;

  @media screen and (max-width: 1350px) {
    font-size: 18px;
  }
`

export const LandingWrapper = styled.div<{ popupShowed?: boolean; usePadding?: boolean }>`
  color: ${colors.formHint};
  margin: 0 auto;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  pointer-events: ${({ popupShowed }) => (popupShowed ? 'none' : 'all')};
`

export const TopSection = styled.div`
  position: relative;
  display: flex;
  background: url(${bgTop}) no-repeat;
  padding-bottom: 20px;
  background-size: auto 100%;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background: url(${bgTopBottom}) no-repeat;
    background-size: 100% 100%;
    height: 43px;
    bottom: 0px;
    z-index: 99;
  }
`
export const TopLandingContainer = styled(MiddleContainer)`
  width: 100%;
  max-width: 1410px;
  margin: 0 auto;
  font-family: 'Rubik', sans-serif;

  @media screen and (max-width: 1440px) {
    max-width: 1210px;
  }
`
export const Logo = styled.img<{ isHidden?: boolean }>`
  height: auto;
  max-height: 180px;
  width: 180px;
  object-fit: contain;
  object-position: top;
  margin-top: 40px;
  margin-bottom: 16px;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
`

export const TopInfoContainer = styled.div`
  display: flex;
  margin-bottom: -14px;
`
export const TopInfoBlock = styled.div<{ fullWidth?: boolean; centered?: boolean }>`
  flex: ${({ fullWidth }) => (fullWidth ? '1' : 'none')};
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-self: ${({ centered }) => (centered ? 'center' : 'auto')};
`
export const TopSectionText = styled.div<{ isCareerBuilder?: boolean }>`
  font-family: 'Open sans';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isCareerBuilder }) => (isCareerBuilder ? 60 : 90)}px;
  line-height: 108.18%;
  color: #21304e;
  width: ${({ isCareerBuilder }) => (isCareerBuilder ? 'auto' : '672px')};
  white-space: break-spaces;
  margin-bottom: 16px;

  > span {
    font-weight: bold;
  }

  @media screen and (max-width: 1350px) {
    font-size: 70px;
    width: 488px;
  }

  @media screen and (max-width: 1150px) {
    font-size: 70px;
    width: 448px;
  }
`

export const PersonImage = styled.img`
  width: 585px;
  height: 585px;
  margin: 0px 50px 0 -90px;
  object-fit: contain;
  background: url(${bgPerson}) no-repeat;
  background-size: 100%;
  background-position: bottom;
`

export const TopSectionInfo = styled(DetailsText)<{ isCareerBuilder?: boolean }>`
  margin-bottom: 42px;
  color: black;
  white-space: break-spaces;
  font-size: ${({ isCareerBuilder }) => (isCareerBuilder ? 20 : 24)}px;
`

export const LandingButton = styled.button<ILandingButtonProps>`
  background: ${({ accentColor }) => accentColor};
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.67px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border: none;
  padding: 16px 35px;
  transition: all 0.4s ease;

  &:hover {
    opacity: 0.6;
  }
`

export const BlockTitle = styled(MiddleContainer)<{ alignLeft?: boolean }>`
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
  color: #21304e;
  margin-bottom: 24px;
  margin: ${({ alignLeft }) => (alignLeft ? '0' : '0 auto')};
  text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
`

export const CoachingStepsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 140px;
`

export const CoachingStepsInfo = styled(MiddleContainer)`
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: black;
  margin-bottom: 24px;
  padding: 0 30px;
  text-align: center;
`
export const CoachingStepsItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background: url(${bgCoachingSteps}) no-repeat;
  background-size: 100%;
  background-position: center;
`

export const CoachingStepsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #b202ff;
  width: 391px;
  height: 370px;
  border-radius: 12px;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
  padding: 29px 0 0 24px;
  &:before {
    content: '';
    width: 330px;
    height: 330px;
    border-radius: 165px;
    background: #bf45f8;
    position: absolute;
    left: 163px;
    bottom: 130px;
    z-index: 1;
  }
`

export const CoachingStepsItemIcon = styled.img`
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
  object-fit: contain;
`

export const CoachingStepsItemTitle = styled.div`
  font-family: 'Open sans';
  font-weight: 700;
  font-size: 27px;
  line-height: 150%;
  color: white;
  margin-bottom: 12px;
  z-index: 2;
`

export const CoachingStepsItemText = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: white;
  z-index: 2;
  margin-right: 32px;
`

export const HowItWorksBlock = styled(MiddleContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 115px;
`

export const HowItWorksDataContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 42px;
  height: 370px;
`

export const HowItWorksMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid #000000;
  width: 404px;
`

export const HowItWorksMenuItem = withProps()(styled(DetailsText)<{ selected?: boolean }>`
  margin: 21px 0 21px 16px;
  font-weight: ${({ selected }) => (selected ? '700' : '400')};
  line-height: 160%;
  color: ${({ selected, theme }: any) =>
    selected ? theme.agencyColor ?? '#b202ff' : '#21304e'};
  cursor: pointer;
`)

export const HowItWorksItemAnimationContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: row;
  margin-left: 68px;
`

export const HowItWorksItemAnimation = styled.div`
  width: 208px;
  height: auto;
`

export const HowItWorksItemText = styled(DetailsText)`
  color: black;
  width: 437px;
  display: flex;
  align-items: center;
  margin-left: 46px;
`

export const OurExpertsBlock = styled.div`
  margin-top: 106px;
  height: 991px;
  width: 100%;
  background: url(${bgOurExperts}) no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
`

export const OurExpertsDataContainer = styled(MiddleContainer)`
  display: flex;
  margin-bottom: 107px;
`

export const OurExpertsImage = styled.img`
  width: 628px;
  height: auto;
  object-fit: contain;
`

export const OurExpertsTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
`

export const OurExpertsTextHeader = styled.div`
  font-weight: 500;
  font-size: 27px;
  line-height: 32px;
  margin-top: 45px;
  margin-bottom: 12px;
  color: #21304e;
`

export const OurExpertsTextLabel = styled(DetailsText)`
  color: #21304e;
`

export const RecommendationBlockWrapper = styled(MiddleContainer)<{
  animationLenght: number
  animationFinished: boolean
}>`
  height: calc(489px + ${({ animationLenght }) => animationLenght}px);
  display: flex;
  flex-direction: ${({ animationFinished }) =>
    animationFinished ? 'column-reverse' : 'column'};
  pointer-events: none;
`

export const RecommendationBlock = styled(MiddleContainer)<{ isStuck: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 115px;
  height: 489px;
  position: ${({ isStuck }) => (isStuck ? 'fixed' : 'relative')};
  top: ${({ isStuck }) => (isStuck ? '0px' : 'auto')};
`

export const RecommendationSideBlock = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
`

export const RecommendationText = styled(DetailsText)`
  color: #000000;
`

export const RecommendationImg = styled.div<{
  src: string
  height: number
  align?: string
  marginTop?: number
  width?: number
  show?: boolean
  absolute?: boolean
  left?: number
  bottom?: number
}>`
  position: ${({ absolute }) => (absolute ? 'absolute' : 'relative')};
  left: ${({ left }) => left ?? 0}px;
  bottom: ${({ bottom }) => bottom ?? 0}px;
  width: ${(props) => (props.absolute ? `${props.width ?? 0}px` : 'auto')};
  height: ${({ height }) => height}px;
  background: url(${({ src }) => src}) no-repeat;
  background-size: ${({ width }) => (width ? `${width}px` : '100%')} 100%;
  margin-top: ${({ marginTop }) => marginTop ?? 0}px;
  background-position: ${({ align }) => align ?? 'center'};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: translateY(${({ show }) => (show ? 0 : 50)}px);
  transition: opacity 0.25s linear, transform 0.25s linear;
`

export const IntelligenceBlock = styled(MiddleContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 160px;
`

export const IntelligenceSideBlock = styled.div<{ widthPct?: number }>`
  width: ${({ widthPct }) => widthPct || 100}%;
  display: flex;
  flex-direction: column;
`

export const IntelligenceText = styled(DetailsText)<{ marginBottom?: number }>`
  color: #000000;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`

export const IntelligenceImagesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const IntelligenceImage = styled.img<{
  width?: number | string
  show: boolean
  animationLength?: number
  showDelay?: number
}>`
  width: ${({ width }) => width || 119}px;
  height: 169px;
  margin: 7px 4px;
  object-fit: contain;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity ${({ animationLength }) => animationLength || 0.5}s
    ${({ showDelay }) => showDelay || 0}s;
`

export const NumbersBlock = styled(MiddleContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 123px;
`

export const NumbersText = styled(DetailsText)`
  color: #000000;
  margin-top: 20px;
  text-align: center;
`

export const NumbersItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 100px;
`

export const NumbersItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`

export const NumbersItemRow = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 80px;
  color: ${({ color }) => color};
`

export const NumbersItemText = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #21304e;
`

export const ReviewsBlock = styled.div`
  width: 97%;
  margin: 0 auto;
  max-width: 1600px;
  overflow-x: hidden;
  margin-top: 133px;
`

export const ReviewsCard = styled.div`
  background: #ffffff;
  height: 373px;
  border: 1px solid #e1e3ea;
  border-radius: 4px;
`

export const ReviewsCardText = styled.div`
  margin: 32px 32px 18px 32px;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  color: #21304e;
  height: 240px;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 30px;

  &:before {
    content: '';
    background: url(${icQuote}) no-repeat;
    background-size: 100%;
    width: ${isMobileOnly ? 35 : 50}px;
    height: ${isMobileOnly ? 23 : 33}px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    background: url(${icQuote}) no-repeat;
    background-size: 100%;
    transform: rotate(180deg);
    width: ${isMobileOnly ? 35 : 50}px;
    height: ${isMobileOnly ? 23 : 33}px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 1325px) {
    font-size: 18px;
  }
`

export const ReviewsAuthor = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #21304e;
  margin-left: 32px;
`

export const ReviewsLogo = styled.img<{ height?: number }>`
  width: 110px;
  height: ${({ height }) => height ?? 31}px;
  object-fit: contain;
  object-position: left;
  margin-left: 32px;
  opacity: 0.4;
  margin-top: 12px;
`

export const FaqsContainer = styled.div`
  width: 100%;
  margin-top: 145px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ResponsiveWrapper = styled(MiddleContainer)``

export const BottomBlock = styled.div`
  position: relative;
  background: url(${bgBottom}) no-repeat;
  background-size: 100% 100%;
  height: 547px;
  margin-top: 122px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    background: url(${bgBottomBottom}) no-repeat;
    background-size: 100% 100%;
    height: 43px;
    bottom: 0px;
    z-index: 99;
  }
`

export const BottomContentContainer = styled(MiddleContainer)`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 34px;
  padding-left: 25px;
`

export const BottomSectionContent = styled.div`
  position: relative;
  z-index: 2;
  color: #000;
  width: 48%;
`

export const BottomSectionTitle = styled.div`
  font-size: 60px;
  font-weight: bold;
  line-height: 65px;
  margin-bottom: 40px;
`

export const BottomPersonImage = styled.img`
  width: 494px;
  height: 494px;
  object-fit: contain;
  background: url(${bgPerson}) no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding: 50px 0 0;
  margin-top: 10px;
`

export const StyledButton = withProps()(styled(Button)`
  font-size: 16px;
  background-color: ${({ theme }: any) => {
    const color = theme.agencyColor ?? '#B202FF'
    return color
  }};

  &:hover {
    background-color: ${({ theme }: any) => {
      const color = theme.agencyColor ?? '#B202FF'
      return color
    }};
    opacity: 0.5;
  }

  &:active {
    background-color: ${({ theme }: any) => {
      const color = theme.agencyColor ?? '#B202FF'
      return color
    }};
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${({ theme }: any) => {
      const color = theme.agencyColor ?? '#B202FF'
      return color
    }};
    opacity: 0.5;
  }
`)

export const StyledButtonLess = styled(Button)`
  font-size: 14px;
`
export const StyledButtonWidth = styled(StyledButton)<{ fullWidth?: boolean }>`
  font-size: 16px;
  width: 311px;
  height: 55px;
`
export const HiddenText = styled.p`
  display: none;
`

export const ProffessionalGuidanceContainer = styled.div`
  background: url(${bgProfessionalGuidance}) no-repeat;
  background-size: 100% 100%;
  margin-top: 194px;
  height: 602px;
`

export const GuidanceData = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 100px;
`

export const GuidanceImage = styled.img`
  width: auto;
  height: 434px;
`

export const GuidancePriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 70px;
  margin-top: 70px;
`

export const GuidancePriceTitle = styled.div`
  font-weight: 700;
  font-size: 50px;
  line-height: 145.69%;
  color: #21304e;
  margin-bottom: 24px;
`

export const GuidancePriceText = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #21304e;
  margin-bottom: 45px;
`

export const GuidanceLine = styled.div`
  display: flex;
  flex-direction: row;
`

export const GuidancePriceLabel = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-right: 12px;
  color: #000000;
`

export const GuidancePrice = styled(DetailsText)`
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }: any) => theme.agencyColor ?? '#B202FF'};
`

export const GuidancePriceSubText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #8992a3;
  margin-top: 9px;
  margin-bottom: 24px;
`

export const HumanCenteredTextBox = styled.div`
  font-size: 14px;
  line-height: 26px;

  p {
    padding-bottom: 25px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`
