import styled from '@emotion/styled'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'
import { useDispatch, useSelector } from 'react-redux'
import { avaDataSelectors } from 'src/redux/data/ava/data'
import { PromptStatus } from '../../api'
import {
  avaInterviewSelectors,
  avaCreateInterviewAction,
  getSystemSettingsAction,
  startAvaReportWatcherAction,
} from '../../redux'
import RotatePhoneComponent from '../components/elements/RotatePhoneComponent'
import { ButtonBase, TitleText } from '../components/elements/styled-components'
import InsightsComponent from '../components/report/InsightsComponent'
import CompanyOverviewComponent from '../components/report/CompanyOverviewComponent'
import ProTipsComponent from '../components/report/ProTipsContainer'
import SessionDeatilsComponent from '../components/report/SessionDetailComponent'
import ReportFooterComponent from '../components/report/ReportFooterComponent'
import LoadingComponent from '../components/interview/LoadingComponent'
import CompanyOverviewComponentMobile from '../components/report/CompanyOverviewComponentMobile'
import ProTipsContainerMobile from '../components/report/ProTipsContainerMobile'
import ReportHeader from '../components/report/ReportHeader'
import QuestionDetailsPopup from '../components/report/QuestionDetailsPopup'
import ResponseAssessmentsBlock from '../components/report/ResponseAssessmentsBlock'

const Layout = styled.div<{ withPopup?: boolean }>`
  width: 100%;
  height: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: ${({ withPopup }) => (withPopup ? 'hidden' : 'auto')};
  background: ${isMobileOnly ? '#F5F5F7' : 'transparent'};

  ${isMobileOnly &&
  css`
    position: fixed;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
  `}
`

const DataContainer = styled.div`
  padding: 40px 60px 30px 60px;
  overflow: auto;

  @media screen and (max-width: 1280px) {
    padding: 40px 16px 30px 16px;
  }

  ${isMobileOnly &&
  css`
    margin-top: 10px;
    padding: 36px 18px;
    background: white;
    border-radius: 30px 30px 0 0;
    overflow: unset;
  `}
`

const TabsContainer = styled.div`
  position: relative;
  display: flex;
  margin: -27px 60px 10px 60px;

  @media screen and (max-width: 1280px) {
    margin: -27px 16px 10px 16px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -10px;
    background: #e6e6e6;
  }
`

const TabItem = styled.div<{ selected: boolean }>`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: ${({ selected }) => (selected ? '#3b424f' : '#737991')};
  margin-right: 36px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: -10px;
    background: #000000;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    z-index: 10;
  }
`

const TitleTextStyled = styled(TitleText)`
  font-size: 36px;
  line-height: 32px;
  margin-bottom: 0;

  @media screen and (max-width: 1280px) {
    width: auto;
    font-size: 24px;
  }

  ${isMobileOnly &&
  css`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  `}
`

const AnchorStyled = styled.div`
  height: 20px;
  ${isMobileOnly &&
  css`
    height: 0px;
  `}
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #e1e3ea;
  display: none;

  ${isMobileOnly &&
  css`
    display: block;
    margin-bottom: 32px;
  `}
`

const ButtonStyled = withProps()(styled(ButtonBase)`
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 32px;
  padding: 8px 16px;
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-left: auto;
  display: none;
  text-align: center;

  @media screen and (max-width: 1280px) {
    display: block;
  }
`)

const DataContainerTitle = styled.div`
  margin-bottom: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

enum Tab {
  InterviewIntelligence = 'Interview intelligence™',
  ResponseAssessment = 'Response assessment',
  CompanyOverview = 'Company overview',
  Tips = 'ProTips',
}

function ReportContainer() {
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const interview = useSelector(avaInterviewSelectors.data)
  const avaData = useSelector(avaDataSelectors.data)

  const topAnchorRef = useRef<HTMLDivElement>()
  const insightsAnchorRef = useRef<HTMLDivElement>()
  const assessmentsAnchorRef = useRef<HTMLDivElement>()
  const overviewAnchorRef = useRef<HTMLDivElement>()
  const tipsAnchorRef = useRef<HTMLDivElement>()

  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.InterviewIntelligence)
  // const [isLoadingReport, setIsLoadingReport] = useState(true)

  const onTabSelected = (tab: Tab) => {
    switch (tab) {
      case Tab.InterviewIntelligence:
        insightsAnchorRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case Tab.ResponseAssessment:
        assessmentsAnchorRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case Tab.CompanyOverview:
        overviewAnchorRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      default:
        tipsAnchorRef.current.scrollIntoView({ behavior: 'smooth' })
        break
    }
    setSelectedTab(tab)
  }

  const onDataScroll = (ev) => {
    const { scrollTop } = ev.target
    const scrollShift = 170
    let activeTab = null
    if (scrollTop >= tipsAnchorRef.current?.offsetTop - scrollShift) {
      activeTab = Tab.Tips
    } else if (
      overviewAnchorRef &&
      scrollTop >= overviewAnchorRef.current?.offsetTop - scrollShift
    ) {
      activeTab = Tab.CompanyOverview
    } else if (scrollTop >= assessmentsAnchorRef.current?.offsetTop - scrollShift) {
      activeTab = Tab.ResponseAssessment
    } else {
      activeTab = Tab.InterviewIntelligence
    }
    setSelectedTab(activeTab)
  }

  const handleNewSessionClicked = useCallback(() => {
    if (interview?.agency_id) {
      const windowReference = window.open() as any
      dispatch(
        avaCreateInterviewAction({
          agencyId: interview?.agency_id,
          email: interview.candidate.email,
          firstName: interview.candidate.first_name,
          lastName: interview.candidate.last_name,
          onCreated: (token) => {
            windowReference.location = `/ava/${token}?sl=1`
          },
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview])

  const handleScrollTop = () => {
    topAnchorRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (interviewToken) dispatch(startAvaReportWatcherAction({ interviewToken }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewToken])

  useEffect(() => {
    dispatch(getSystemSettingsAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoadingReport =
    !Object.values<PromptStatus>(interview?.prompt_status.report).every((v) =>
      [PromptStatus.Skipped, PromptStatus.Done, PromptStatus.Failed].includes(v)
    ) || interview.agree_to_share === null
  const hasPopup = avaData.selectedQuestion !== null

  return (
    <Layout withPopup={hasPopup}>
      <AnchorStyled ref={topAnchorRef} />
      <ReportHeader
        backgroundMobile={!isLoadingReport ? '#F5F5F7' : '#f6f4fd'}
        showMuteButton={isLoadingReport}
      />
      {isLoadingReport && <LoadingComponent />}
      {!isLoadingReport && (
        <>
          {!isMobileOnly && (
            <TabsContainer>
              {Object.values(Tab)
                .filter((item) => {
                  if (interview.position?.company_valid) return true
                  return item !== Tab.CompanyOverview
                })
                .map((tabName) => (
                  <TabItem
                    key={tabName}
                    onClick={() => onTabSelected(tabName)}
                    selected={tabName === selectedTab}
                  >
                    {tabName}
                  </TabItem>
                ))}
            </TabsContainer>
          )}
          <DataContainer onScroll={onDataScroll}>
            <DataContainerTitle>
              <TitleTextStyled>Session Report</TitleTextStyled>
              <ButtonStyled onClick={handleNewSessionClicked}>
                Practice again
              </ButtonStyled>
            </DataContainerTitle>
            <SessionDeatilsComponent onNewSession={handleNewSessionClicked} />
            <Separator />
            <AnchorStyled ref={insightsAnchorRef} />
            <InsightsComponent />
            <AnchorStyled ref={assessmentsAnchorRef} />
            <ResponseAssessmentsBlock />
            {interview.position?.company_valid && (
              <>
                <AnchorStyled ref={overviewAnchorRef} />
                {isMobileOnly ? (
                  <CompanyOverviewComponentMobile />
                ) : (
                  <CompanyOverviewComponent />
                )}
              </>
            )}
            <AnchorStyled ref={tipsAnchorRef} />
            {isMobileOnly ? <ProTipsContainerMobile /> : <ProTipsComponent />}
            <ReportFooterComponent
              onNewSession={handleNewSessionClicked}
              onScrollTop={handleScrollTop}
            />
          </DataContainer>
        </>
      )}
      {isMobileOnly && <RotatePhoneComponent />}
      {isMobileOnly && (
        <QuestionDetailsPopup visible={avaData.selectedQuestion !== null} />
      )}
      <audio id="ava-audio" />
    </Layout>
  )
}

export default ReportContainer
