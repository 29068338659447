import { put, takeEvery, call } from 'typed-redux-saga'

import { sendApplicantNote } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'
import { updateApplicantNotesAction } from '../data/applicantNotes'

interface ISendApplicantNoteAction {
  id?: string
  interviewToken: string
  text: string
}

const {
  action: sendApplicantNoteAction,
  actionFailure: sendApplicantNoteActionFailure,
  actionSuccess: sendApplicantNoteActionSuccess,
  actionSuccessClear: sendApplicantNoteActionSuccessClear,
  actionFailureClear: sendApplicantNoteActionFailureClear,
  actionType: sendApplicantNoteActionType,
  actionTypeFailure: sendApplicantNoteActionFailureType,
  actionTypeSuccess: sendApplicantNoteActionSuccessType,
  reducer: sendApplicantNoteRequestReducer,
  selectors: sendApplicantNoteSelectors,
} = reduxRequestFactory<ISendApplicantNoteAction>('sendApplicantNote', 'requests')

export {
  sendApplicantNoteAction,
  sendApplicantNoteActionFailure,
  sendApplicantNoteActionSuccess,
  sendApplicantNoteActionSuccessClear,
  sendApplicantNoteActionFailureClear,
  sendApplicantNoteActionType,
  sendApplicantNoteActionFailureType,
  sendApplicantNoteActionSuccessType,
  sendApplicantNoteRequestReducer,
  sendApplicantNoteSelectors,
}

function* sendApplicantNoteSaga(action: ISagaAction<ISendApplicantNoteAction>) {
  try {
    const { interviewToken, id, text } = action.payload
    const notes = yield* call(sendApplicantNote, interviewToken, text, id)
    yield put(sendApplicantNoteActionSuccess())
    yield put(updateApplicantNotesAction(notes))
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      sendApplicantNoteActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchSendApplicantNote() {
  yield takeEvery(sendApplicantNoteActionType, sendApplicantNoteSaga)
}
