import { put, takeEvery, call } from 'typed-redux-saga'

import { moveToAlternativeLink } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

interface IMoveToAlternativeLinkAction {
  interviewToken: string
}

const {
  action: moveToAlternativeLinkAction,
  actionFailure: moveToAlternativeLinkActionFailure,
  actionSuccess: moveToAlternativeLinkActionSuccess,
  actionSuccessClear: moveToAlternativeLinkActionSuccessClear,
  actionFailureClear: moveToAlternativeLinkActionFailureClear,
  actionType: moveToAlternativeLinkActionType,
  actionTypeFailure: moveToAlternativeLinkActionFailureType,
  actionTypeSuccess: moveToAlternativeLinkActionSuccessType,
  reducer: moveToAlternativeLinkRequestReducer,
  selectors: moveToAlternativeLinkSelectors,
} = reduxRequestFactory<IMoveToAlternativeLinkAction>('moveToAlternativeLink', 'requests')

export {
  moveToAlternativeLinkAction,
  moveToAlternativeLinkActionFailure,
  moveToAlternativeLinkActionSuccess,
  moveToAlternativeLinkActionSuccessClear,
  moveToAlternativeLinkActionFailureClear,
  moveToAlternativeLinkActionType,
  moveToAlternativeLinkActionFailureType,
  moveToAlternativeLinkActionSuccessType,
  moveToAlternativeLinkRequestReducer,
  moveToAlternativeLinkSelectors,
}

function* moveToAlternativeLinkSaga(action: ISagaAction<IMoveToAlternativeLinkAction>) {
  try {
    const { interviewToken } = action.payload
    yield* call(moveToAlternativeLink, interviewToken)
    yield put(moveToAlternativeLinkActionSuccess())
  } catch (err) {
    const errorMessage = 'Server error. Please, contact support'
    yield call(handleErrorSaga, err)
    yield put(
      moveToAlternativeLinkActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchMoveToAlternativeLink() {
  yield takeEvery(moveToAlternativeLinkActionType, moveToAlternativeLinkSaga)
}
