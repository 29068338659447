import React from 'react'
import styled from '@emotion/styled'
import { get } from 'lodash'

interface IAvatarWrapperProps {
  size: number
  padding?: number
  border?: number
}
const AvatarWrapper = styled.div<IAvatarWrapperProps>`
  display: block;
  margin-right: ${(props) => (props.padding && props.padding > 0 ? `0` : '14px')};
  img {
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};
    border-radius: 50%;
    overflow: hidden;
    border: ${(props) => props.border && props.border > 0 && `1px solid #E1E3EA`};
    padding: ${(props) => props.padding && props.padding > 0 && `${props.padding}px`};
    display: block;
  }
`

const AvatarReplace = styled.div<IAvatarWrapperProps>`
  display: block;
  border: 1px solid #eee;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba(115, 121, 145, 0.8);
  text-transform: uppercase;
`

const Avatar = (props: any) => {
  const { user, size, padding, border } = props
  const logoUrl = get(user, 'logo.url', false)
  const first_name = get(user, 'first_name', '')
  const last_name = get(user, 'last_name', '')

  return (
    <AvatarWrapper size={size} padding={padding} border={border}>
      {logoUrl ? (
        <img src={logoUrl} alt="avatar" />
      ) : (
        <AvatarReplace size={size}>
          <span>{`${first_name.charAt(0)}${last_name.charAt(0)}`}</span>
        </AvatarReplace>
      )}
    </AvatarWrapper>
  )
}

Avatar.defaultProps = {
  user: {},
  size: 40,
}

export default Avatar
