/* eslint-disable no-console */
import AgoraRTM from 'agora-rtm-sdk'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import useStateRef from 'react-usestateref'

import { getRTMAgoraToken } from '../api'
import { setInterviewTimestampAction } from '../redux'
import makeRandom from './services/random'

enum RtmCommands {
  PleaseStopScreensharingForMe = 'PLEASE_STOP_SCREENSHARING_FOR_ME',
  CallEnded = 'CALL_ENDED',
  OrientationChangedLandscape = 'ORIENTATION_CHANGED_LANDSCAPE',
  OrientationChangedPortrait = 'ORIENTATION_CHANGED_PORTRAIT',
  PeerJoinedCoding = 'PEER_JOINED_CODE',
  PeerLeftCoding = 'PEER_LEFT_CODE',
}

const generateRtmUsername = (username: string) => {
  const saltLenght = 5
  const userNameLenghtLimit = 64
  let rmtUserName = `${username}_${makeRandom(saltLenght)}`
  if (username.length > userNameLenghtLimit - saltLenght - 1) {
    rmtUserName = `${username.slice(
      0,
      userNameLenghtLimit - saltLenght - 1
    )}_${makeRandom(5)}`
  }
  console.log(`RTM. init for ${rmtUserName}`)
  return rmtUserName
}

export default function useRtm(interviewToken: string) {
  const dispatch = useDispatch()
  const client = useRef<any>()
  const channel = useRef<any>()
  const [usersInCode, setUsersInCode, usersInCodeRef] = useStateRef<any>(new Set<1 | 2>())

  const initRtm = async (userName: string, channelName: string) => {
    const rmtUserName = generateRtmUsername(userName)
    const rtmTokenData = await getRTMAgoraToken(rmtUserName)

    const rtmClient = AgoraRTM.createInstance(process.env.REACT_APP_AGORA_APP_ID)
    client.current = rtmClient
    const rtmChannel = rtmClient.createChannel(channelName)
    channel.current = rtmChannel

    rtmClient.on('ConnectionStateChanged', (state, reason) => {
      console.debug(`RTM. ConnectionStateChanged to ${state}:`, reason)
      switch (state) {
        case 'CONNECTED':
          rtmChannel.join()
          break
        case 'ABORTED':
          rtmClient.logout()
          // eslint-disable-next-line no-case-declarations
          const timer = setInterval(() => {
            console.debug('RTM. Try relogin...')
            rtmClient
              .login({
                uid: rmtUserName,
                token: rtmTokenData.token,
              })
              .then(() => {
                console.debug('RTM. Relogin success')
                clearInterval(timer)
              })
              .catch((err) => {
                console.debug('RTM. Relogin failed', err)
              })
          }, 10000)
          break
        case 'CONNECTING':
        case 'DISCONNECTED':
          break
        default:
          try {
            rtmChannel.leave()
          } catch (err) {
            console.log(err)
          }
      }
    })
    rtmClient.on('TokenExpired', async () => {
      const newRtmTokenData = await getRTMAgoraToken(rmtUserName)
      rtmClient.renewToken(newRtmTokenData.token)
      console.debug('RTM. Renew token')
    })
    await rtmClient.login({
      uid: rmtUserName,
      token: rtmTokenData.token,
    })
    rtmChannel.on('ChannelMessage', (message, memberId) => {
      console.debug('RTM. message received', message)
      const newSet = new Set(usersInCodeRef.current)
      switch ((message as any).text) {
        case RtmCommands.PeerJoinedCoding:
          newSet.add(Number(memberId[0]))
          setUsersInCode(newSet)

          if (usersInCodeRef.current.size === 2) {
            dispatch(
              setInterviewTimestampAction({
                interviewToken,
                name: 'code_start',
                time: Date.now(),
              })
            )
          }
          break
        case RtmCommands.PeerLeftCoding:
          if (usersInCodeRef.current.size === 2) {
            dispatch(
              setInterviewTimestampAction({
                interviewToken,
                name: 'code_stop',
                time: Date.now(),
              })
            )
          }

          newSet.delete(Number(memberId[0]))
          setUsersInCode(newSet)
          break
        default:
          break
      }
    })
  }

  const removeRtmUser = (userPrefix: 1 | 2) => {
    const newSet = new Set(usersInCodeRef.current)
    newSet.delete(userPrefix)
    setUsersInCode(newSet)
  }

  return { codeActive: usersInCode.size === 2, initRtm, removeRtmUser }
}
