import styled from '@emotion/styled'
import ArrowImg from 'src/static/icArrow.png'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'
import ErrorIconImg from 'src/static/icErrorInfo.png'
import { isMobileOnly } from 'react-device-detect'

export const AnimatedBase = styled.div`
  animation: fadeInFromNone 0.5s ease-out;

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`

export const BaseDisable = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export const ComponentContainer = styled.div<{ nonFullHeight?: boolean }>`
  height: 568px;
  margin: 0 64px;
  display: flex;
  flex-direction: row;
  padding-bottom: 72px;
  margin-top: -21px;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }

  ${isMobileOnly &&
  css`
    flex-direction: column;
    margin: 0;
    padding: 42px 16px 0 16px;
    background: white;
    border-radius: 20px 20px 0 0;
    overflow-y: auto;
    height: calc(100% - 72px) !important;
  `}
`

export const QuestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 420px;
  margin-right: 92px;

  @media screen and (max-width: 1100px) {
    margin-right: 0;
    width: 100%;
  }

  ${isMobileOnly &&
  css`
    width: 100%;
    padding: 0;
    margin: 0 0 21px 0;
  `}
`

export const InputContainer = styled.div`
  position: relative;
  height: fit-content;
  min-height: 100%;
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.07);
  border-radius: 28px;
  padding: 72px 64px;
  overflow-y: auto;

  @media screen and (max-width: 1100px) {
    min-height: 500px;
    margin: 20px 0;
  }

  ${isMobileOnly &&
  css`
    width: 100%;
    padding: 0;
    margin: 0 !important;
    box-shadow: none;
    min-height: auto !important;
    max-height: unset;
    overflow: visible;
  `}
`

export const ErrorBlock = styled.div`
  padding: 16px;
  background: white;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #b202ff;
  cursor: default;
  margin-left: 20px;
  margin-top: 5px;

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: 26px;
    width: 0;
    height: 0;
    border-left: 5px solid #b202ff;
    border-top: 5px solid #b202ff;
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: rotate(45deg);
  }
`

export const SubTitleText = styled(BaseDisable)<{
  textAlign?: 'start' | 'center' | 'end'
}>`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: black;
  text-align: ${({ textAlign }) => textAlign ?? 'start'};
  width: 100%;
  margin-bottom: 12px;
  white-space: break-spaces;

  ${isMobileOnly &&
  css`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  `}
`

export const TitleText = styled(SubTitleText)`
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;

  ${isMobileOnly &&
  css`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  `}
`

export const ButtonBase = styled(AnimatedBase)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`

export const NavButton = styled(ButtonBase)<{
  disabled?: boolean
  transparent?: boolean
  hideIcon?: boolean
}>`
  position: relative;
  text-align: center;
  background: ${({ transparent }) => (transparent ? 'transparent' : 'black')};
  color: ${({ transparent }) => (transparent ? 'black' : 'white')};
  border-radius: 60px;
  padding: ${({ transparent, hideIcon }) =>
    transparent || hideIcon || isMobileOnly ? '18px 40px' : '18px 73px 18px 40px'};
  font-size: 16px;
  font-weight: 500;
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
  line-height: 19px;

  &:hover {
    opacity: ${isMobileOnly ? 1 : 0.8};
  }
  &:active {
    opacity: 0.6;
  }
  ${({ transparent, hideIcon }) =>
    !transparent &&
    !hideIcon &&
    !isMobileOnly &&
    css`
      &:after {
        content: '';
        position: absolute;
        right: 40px;
        top: 21px;
        width: 14px;
        height: 12px;
        background: url(${ArrowImg});
        background-size: 100% 100%;
      }
    `}

  ${isMobileOnly &&
  css`
    width: 100%;
  `}
`

export const BackButton = styled.img`
  width: 55px;
  height: 55px;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 30px;
  object-fit: contain;
  cursor: pointer;
  align-self: flex-start;
  margin-top: auto;
  margin-bottom: 72px;
  animation: fadeInFromNone 0.5s ease-out;

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
`

export const MuteButton = styled.img<{ toTheLeft?: boolean }>`
  width: 26px;
  height: 26px;
  padding: 2px;
  object-fit: contain;
  cursor: pointer;
  z-index: 10;
  margin-left: ${({ toTheLeft }) => (toTheLeft ? 'auto' : '0')};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`
export const TextImage = styled.img`
  width: 233px;
  height: 277px;
  object-fit: contain;
  margin-top: auto;
`

export const ErrorContainer = styled(AnimatedBase)<{
  bottom?: number
  right?: number
  left?: number
  top?: number
  maxWidth?: number
}>`
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: ${({ right }) => right ?? 53}px;
  left: ${({ left }) => `${left}px` ?? 'unset'};
  top: ${({ top }) => `${top}px` ?? 'unset'};
  bottom: ${({ bottom }) => bottom ?? 35}px;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  color: #ff1f44;
  padding: 6px 18px 6px 40px;
  background: #f7dde1;
  border-radius: 8px;
  max-width: ${({ maxWidth }) => maxWidth ?? 9999}px;
  &:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    background: url(${ErrorIconImg});
    background-size: 100% 100%;
  }

  ${isMobileOnly &&
  css`
    position: fixed;
    z-index: 11;
    top: 10px;
    height: 48px;
    display: flex;
    align-items: center;
    left: 16px;
    right: 16px;
    width: auto;
  `}
`

export const QuestionCounterContainer = withProps()(styled.div`
  display: flex;
  align-items: center;
  color: #a9a9a9;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  margin-bottom: 8px;
  width: fit-content;

  > svg {
    margin-right: 4px;
  }
`)

export const LoadingBar = styled.div<{ active?: boolean; width?: string }>`
  position: relative;
  width: ${({ width }) => width ?? '222px'};
  height: 10px;
  background: ${({ theme, active }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return active ? '#ececec' : color
  }};
  border-radius: 5px;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    margin-top: 10px;
  }

  &:after {
    display: ${({ active }) => (active ? 'block' : 'none')};
    content: '';
    position: absolute;
    width: 33%;
    height: 100%;
    top: 0;
    background: ${({ theme }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return color
    }};
    border-radius: 5px;
    animation: rotation 2s infinite linear;

    @keyframes rotation {
      from {
        left: -33%;
      }
      to {
        left: 133%;
      }
    }
  }
`

export const CheckedLabel = styled.div<{ checked?: boolean; hideCheck?: boolean }>`
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-left: ${({ hideCheck }) => (hideCheck ? 0 : 18)}px;
  cursor: pointer;
  margin: ${isMobileOnly ? '0 24px 0 0' : '0 24px 0 0'};

  &:before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    border: 1px solid #c0c5db;
    border-radius: 7px;
    display: ${({ hideCheck }) => (hideCheck ? 'none' : 'block')};
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 2px;
    top: 2px;
    background: ${({ theme, checked }: any) => {
      const color =
        !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      return checked ? color : 'transparent'
    }};
    border-radius: 7px;
    display: ${({ hideCheck }) => (hideCheck ? 'none' : 'block')};
  }
`

export const WarningContainer = styled(AnimatedBase)<{
  large?: boolean
  top?: boolean
}>`
  width: fit-content;
  height: fit-content;
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 145.19%;
  color: white;
  padding: 14px 18px;
  background: #0e0224;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ top }) =>
    !isMobileOnly &&
    !top &&
    css`
      right: 64px;
      bottom: 140px;
      max-width: 360px;
    `}

  ${({ top }) =>
    !isMobileOnly &&
    top &&
    css`
      right: 42px;
      top: 70px;
      max-width: 270px;

      @media screen and (max-width: 1280px) {
        right: 16px;
      }
    `}
  
  ${({ top, large }) =>
    !isMobileOnly &&
    top &&
    large &&
    css`
      max-width: 360px;
    `}

  ${isMobileOnly &&
  css`
    align-items: flex-start;
    position: fixed;
    z-index: 11;
    top: 10px;
    left: 16px;
    right: 16px;
    width: auto;
  `}
`
