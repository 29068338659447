import { put, takeEvery, call } from 'typed-redux-saga'

import { avaGetInterviewPrompts } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { updateAvaInterviewPromptsAction } from '../../data/ava/interviewPrompts'

export interface IAvaGetInterviewPromptsAction {
  interviewToken: string
}

const {
  action: avaGetInterviewPromptsAction,
  actionFailure: avaGetInterviewPromptsActionFailure,
  actionSuccess: avaGetInterviewPromptsActionSuccess,
  actionSuccessClear: avaGetInterviewPromptsActionSuccessClear,
  actionFailureClear: avaGetInterviewPromptsActionFailureClear,
  actionType: avaGetInterviewPromptsActionType,
  actionTypeFailure: avaGetInterviewPromptsActionFailureType,
  actionTypeSuccess: avaGetInterviewPromptsActionSuccessType,
  reducer: avaGetInterviewPromptsRequestReducer,
  selectors: avaGetInterviewPromptsSelectors,
} = reduxRequestFactory<IAvaGetInterviewPromptsAction>(
  'avaGetInterviewPrompts',
  'requests'
)

export {
  avaGetInterviewPromptsAction,
  avaGetInterviewPromptsActionFailure,
  avaGetInterviewPromptsActionSuccess,
  avaGetInterviewPromptsActionSuccessClear,
  avaGetInterviewPromptsActionFailureClear,
  avaGetInterviewPromptsActionType,
  avaGetInterviewPromptsActionFailureType,
  avaGetInterviewPromptsActionSuccessType,
  avaGetInterviewPromptsRequestReducer,
  avaGetInterviewPromptsSelectors,
}

function* avaGetInterviewPromptsSaga(action: ISagaAction<IAvaGetInterviewPromptsAction>) {
  try {
    const { interviewToken } = action.payload
    const prompts = yield* call(avaGetInterviewPrompts, interviewToken)
    yield put(avaGetInterviewPromptsActionSuccess())
    yield put(updateAvaInterviewPromptsAction(prompts))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaGetInterviewPromptsActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaGetInterviewPrompts() {
  yield takeEvery(avaGetInterviewPromptsActionType, avaGetInterviewPromptsSaga)
}
