import { ISettings } from '../../api'
import { reduxDataFactory } from '../../utils/redux'

const {
  action: updateSystemSettingsDataAction,
  actionType: updateSystemSettingsDataActionType,
  reducer: systemSettingsDataReducer,
  selectors: systemSettingsSelectors,
} = reduxDataFactory<ISettings>('systemSettings')

export {
  updateSystemSettingsDataAction,
  updateSystemSettingsDataActionType,
  systemSettingsDataReducer,
  systemSettingsSelectors,
}
