/* eslint-disable no-alert */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { get, cloneDeep, difference, findIndex } from 'lodash'
import styled from '@emotion/styled'

import isSlotExpired from 'src/utils/slots'
import {
  BusinessModel,
  ICandidateProfile,
  IExpertProfile,
  ITimeSlot,
  loadExpertData,
} from '../../api'
import { Input, DateZonePicker, Textarea, Button, Select } from '../../components'
import { getColor } from '../../components/Button'
import { agencySelectors } from '../../redux'
import { getTimezoneOffset, normalizeDate } from '../../utils/date'
import { EmailRegex } from '../../utils/regex'

import {
  askExpertAvailability,
  loadExpertDataFailure,
  loadExpertDataSuccess,
} from '../actions'
import CheckBox from '../components/CheckBox'
import PhotoEditor from '../components/PhotoEditor'
import { interview as interviewSelector } from '../selectors'

import { ICoachFormLogos } from './CoachFormInterfaces'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  > div {
    width: 48%;
    > div > div > div {
      width: 100%;
    }
  }
`

const RowFull = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
  > div {
    width: 100%;
    > div {
      width: 100%;
      > div > div {
        width: 100%;
      }
    }
  }
`

const RowFullTextArea = styled(RowFull)`
  > div > div {
    width: auto !important;
    left: 6px;
    right: 6px;
    /* position: absolute !important; */
  }
`

const RowEmpty = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 30px;
`

const PhotoWrapper = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto 15px;
`

interface IPhotoPlaceHolder {
  hasLogoError: boolean
}

const PhotoPlaceHolder = styled.div<IPhotoPlaceHolder>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: ${(props) => (props.hasLogoError ? '1px solid #f00' : '1px solid #666')};
  border-radius: 50%;
  overflow: hidden;
`

const StyledImg = styled.img`
  border: 1px solid #666;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  display: block;
`

const SlotsWrapper = styled.div`
  margin: 15px 0;
  font-size: 12px;
  .react-datepicker-wrapper {
    width: 100%;
  }
`

const AddMoreButton = styled(Button)`
  margin: 15px 0;
  font-size: 12px;
`

const DeleteSlotButton = styled(Button)`
  margin: 15px 10px;
  font-size: 12px;
  display: inline-block;
  width: auto;
  height: auto;
`

const Logo = styled.img`
  width: 100%;
  display: block;
  overflow: hidden;
`

const ClearSlotButton = styled(DeleteSlotButton)``

const Badge = styled.div<{ positive?: boolean }>`
  font-size: 12px;
  font-weight: 500;
  padding: 1px 6px;
  border: 1px solid ${(props) => (props.positive ? '#00ff00' : '#ff0000')};
  border-radius: 5px;
  background: rgba(${(props) => (props.positive ? '0, 255, 0' : '255, 0, 0')}, 0.1);
  color: ${(props) => (props.positive ? '#00ff00' : '#ff0000')};
  width: fit-content;
  margin: 10px 0;
`

const ExpiredBlock = styled(Badge)`
  float: right;
  margin: 15px 10px;
`

const SelectedExpertBadge = styled(Badge)`
  position: absolute;
  top: 10px;
  left: 10px;
`

const DeclineComentText = styled.div`
  width: 400px;
  white-space: break-spaces;
  font-size: 12px;
  opacity: 0.6;
  margin: 6px 0;
`

const SelectedSlotBadge = styled(Badge)``

const LoadExpertButton = styled(Button)`
  background-color: ${(props) => getColor(props, 'color', 'primary')};
  border-color: ${(props) => getColor(props, 'backgroundColor', 'primary')};
  color: ${(props) => getColor(props, 'backgroundColor', 'primary')};

  &:hover {
    background-color: ${(props) => getColor(props, 'color', 'hover')};
    border-color: ${(props) => getColor(props, 'backgroundColor', 'hover')};
    color: ${(props) => getColor(props, 'backgroundColor', 'hover')};
  }

  &:active {
    background-color: ${(props) => getColor(props, 'color', 'active')};
    border-color: ${(props) => getColor(props, 'backgroundColor', 'active')};
    color: ${(props) => getColor(props, 'backgroundColor', 'active')};
  }

  &:disabled {
    background-color: ${(props) => getColor(props, 'color', 'disabled')};
    border-color: ${(props) => getColor(props, 'backgroundColor', 'disabled')};
    color: ${(props) => getColor(props, 'backgroundColor', 'disabled')};
  }
`

const BottomTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const SentText = styled.div`
  font-size: 14px;
  opacity: 0.8;
  margin: 0 4px;
`

const StyledLink = styled.a`
  font-size: 14px;
`

const CommentText = styled.p`
  width: 400px;
  white-space: break-spaces;
  font-size: 12px;
  color: green;
`

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function updateArray(initial: string[], added: string[]) {
  const i = Array.isArray(initial) && initial.length === 3 ? initial : ['', '', '']
  const a = Array.isArray(added) && added.length === 3 ? added : ['', '', '']
  return i.map((e, index) => e || a[index])
}

interface IExpertDetails {
  control: any
  errors: any
  setValue: (field: string, value: any) => void
  getValues: () => {
    experts: IExpertProfile[]
    applicant: ICandidateProfile
    useGeneralLink: boolean
  }
  expertId: number
  expertUid: string | number
  time_slots: ITimeSlot[]
  setExperts: (any) => void
  setLogos: (any) => void
  logoUrl: string | null
  isSuperAdmin: boolean
  apiExpert: IExpertProfile
  onLogoChange: () => void
}

const currencyList = [
  { value: 'USD', label: 'USD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'ILS', label: 'ILS' },
  { value: 'CAD', label: 'CAD' },
  { value: 'AUD', label: 'AUD' },
]

const fromEmailList = [
  { value: 'barry@wizco.io', label: 'Barry Tout' },
  { value: 'callen@wizco.io', label: 'Callen Byrnes' },
  { value: 'carin@wizco.io', label: 'Carin Abrahams' },
  { value: 'chamabert@wizco.io', label: 'Chamabert Cant' },
  { value: 'daniela@wizco.io', label: 'Daniela Marano' },
  { value: 'jenny@wizco.io', label: 'Jennifer Kis' },
  { value: 'rachel@wizco.io', label: 'Rachel Bici' },
  { value: 'sadrina@wizco.io', label: 'Sadrina Chitua' },
  { value: 'flow@wizco.io', label: 'Wizco (flow)' },
]

function ExpertDetails({
  control,
  errors,
  setValue,
  getValues,
  expertId,
  expertUid,
  time_slots,
  setExperts,
  setLogos,
  logoUrl,
  isSuperAdmin,
  apiExpert,
  onLogoChange,
}: IExpertDetails) {
  const dispatch = useDispatch()
  const [isInitial, setIsInitial] = useState(true)
  const [showPhotoEditor, setShowPhotoEditor] = useState(false)
  const [croppedPhoto, setCroppedPhoto] = useState<any>()
  const dataURLImage = useMemo(() => (croppedPhoto ? croppedPhoto.toDataURL() : ''), [
    croppedPhoto,
  ])
  const slotsCount = time_slots.length
  const onlyOneSlot = useMemo(() => slotsCount === 1, [slotsCount])
  const prevTimeSlots: ITimeSlot[] = usePrevious(time_slots) || []
  const formExpertSlots: ITimeSlot[] = get(
    getValues(),
    `experts[${expertId}].time_slots`,
    []
  )
  const interview = useSelector(interviewSelector.interview)
  const interviewDuration: number = get(interview, 'interview.duration', 60)
  const selectedExpertId: string = get(interview, 'expert_profile.id', false)
  const isSelectedExpert = selectedExpertId === expertUid
  const isDeclinedExpert = apiExpert?.interview_token?.role === 'rejected_expert'
  const selectedSlotId: string = get(interview, 'interview.main_time_slot_id', false)
  const adHoc: boolean = get(getValues(), `experts[${expertId}].adhoc`, false)
  const onboarding: boolean = get(
    getValues(),
    `experts[${expertId}].include_onboarding`,
    false
  )
  const email = useWatch<string>({ control, name: `experts[${expertId}].email` })

  const agency = useSelector(agencySelectors.data)
  const isB2C = agency?.business_model === BusinessModel.B2C
  const initialSessionPrice = isB2C ? '' : agency.session_price
  const fullDomain = get(agency, 'full_domain', '')
  const availabilityLink = `${fullDomain}/interviews/${apiExpert?.interview_token?.code}`

  const firstName: string = get(getValues(), `experts[${expertId}].first_name`, '')
  const lastName: string = get(getValues(), `experts[${expertId}].last_name`, '')

  const localTzShift = new Date().getTimezoneOffset() * -1
  const applicantTzShift = getTimezoneOffset(interview.candidate_profile.time_zone)
  const generalTzShift = (applicantTzShift - localTzShift) / 60

  const getPrice = () => {
    if (agency.business_model === BusinessModel.B2C) {
      if (interview.interview_price_tiers) {
        return interview.interview_price_tiers
          .map((item) => {
            const priceTier = agency.agency_price_tiers.find(
              (itm) => itm.id === item.agency_price_tier_id
            )
            return `${agency.currency_sign}${priceTier.price_min} - ${agency.currency_sign}${priceTier.price_max} ${priceTier.name}`
          })
          .join('\n')
      }
      return ''
    }

    return `${agency.currency_sign}${agency.session_price}`
  }

  const getPriceTearsRowCount = () => {
    if (interview.interview_price_tiers?.length > 1) {
      return interview.interview_price_tiers?.length
    }
    return 1
  }

  const handleSave = (photo) => {
    setCroppedPhoto(photo)
    setLogos((prev) => {
      const prevExperts = cloneDeep(prev.experts)
      prevExperts[expertId] = {
        ...prev.experts[expertId],
        logo: photo.toDataURL(),
      }
      return {
        ...prev,
        experts: prevExperts,
      }
    })
    setShowPhotoEditor(false)
    onLogoChange()
  }

  const handleChangeZone = useCallback(
    (index) => (from, to, tz) => {
      setExperts((prev) => {
        const newArr = cloneDeep(prev)
        newArr[expertId].time_slots[index].from = from
        newArr[expertId].time_slots[index].to = to
        newArr[expertId].time_slots[index].time_zone = tz
        return newArr
      })
    },
    [setExperts, expertId]
  )

  const checkOutsideDate = (dateMs: number) => {
    if (!dateMs) return false

    const date = new Date(dateMs)
    const day = date.getDay()
    if (
      (interview.interview.availability_days?.weekday && day > 0 && day < 6) ||
      (interview.interview.availability_days?.weekend && (day === 0 || day === 6))
    ) {
      const hours = date.getHours() + generalTzShift
      if (interview.interview.availability_time?.['8-12'] && hours > 7 && hours < 12) {
        return false
      }
      if (interview.interview.availability_time?.['12-18'] && hours > 11 && hours < 18) {
        return false
      }
      if (interview.interview.availability_time?.['18-22'] && hours > 17 && hours < 22) {
        return false
      }
      return true
    }

    return true
  }

  const handleLoadExpert = useCallback(async () => {
    const expertData = await loadExpertData(email)
    if (expertData) {
      const {
        about,
        company,
        current_position,
        educations,
        experiences,
        expert_currency,
        expert_price,
        first_name,
        last_name,
        linkedin_profile,
        logo,
        rating,
      } = expertData

      const expert: IExpertProfile = getValues().experts[expertId]
      if (!expert.about) setValue(`experts[${expertId}].about`, about)
      if (!expert.company) setValue(`experts[${expertId}].company`, company)
      if (!expert.current_position)
        setValue(`experts[${expertId}].current_position`, current_position)
      if (!expert.educations || expert.educations.some((e) => !e))
        setValue(
          `experts[${expertId}].educations`,
          updateArray(expert.educations, educations)
        )
      if (!expert.experiences || expert.experiences?.some((e) => !e))
        setValue(
          `experts[${expertId}].experiences`,
          updateArray(expert.experiences, experiences)
        )
      if (!expert.expert_currency)
        setValue(`experts[${expertId}].expert_currency`, expert_currency)
      if (!expert.expert_price)
        setValue(`experts[${expertId}].expert_price`, expert_price)
      if (!expert.first_name) setValue(`experts[${expertId}].first_name`, first_name)
      if (!expert.last_name) setValue(`experts[${expertId}].last_name`, last_name)
      if (!expert.linkedin_profile)
        setValue(`experts[${expertId}].linkedin_profile`, linkedin_profile)
      if (!expert.rating) setValue(`experts[${expertId}].rating`, rating)

      setLogos((prev: ICoachFormLogos) => {
        const prevExperts = cloneDeep(prev.experts)
        if (!prevExperts[expertId].logo && !prevExperts[expertId].logoUrlFromApi) {
          prevExperts[expertId].logoUrlFromApi = logo?.url
        }
        return {
          ...prev,
          experts: prevExperts,
        }
      })
      dispatch(loadExpertDataSuccess())
    } else {
      dispatch(loadExpertDataFailure())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  const getDateString = (dateString: string) => {
    const date = new Date(dateString)
    return date.toLocaleString()
  }

  useEffect(() => {
    if (prevTimeSlots.length > time_slots.length) {
      const deletedItem = difference(prevTimeSlots, time_slots)
      const deletedIndex = findIndex(
        prevTimeSlots,
        (prSlot) => prSlot.id === deletedItem[0].id
      )
      const newSlotsFromForm = cloneDeep(
        formExpertSlots.filter((slot, slotIndex) => slotIndex !== deletedIndex)
      )
      setValue(`experts[${expertId}].time_slots`, newSlotsFromForm)
    }
  }, [time_slots, expertId, formExpertSlots, prevTimeSlots, setValue])

  // This effect fot for avoid taken values from initial values when new slots added
  useEffect(() => {
    if (!isInitial && time_slots.length > formExpertSlots.length) {
      const newSlotsFromForm = cloneDeep(formExpertSlots)
      newSlotsFromForm.push({
        id: Date.now().toString(),
        from: '',
        to: '',
        time_zone: '',
      })
      setValue(`experts[${expertId}].time_slots`, newSlotsFromForm)
    }
  }, [time_slots, expertId, formExpertSlots, prevTimeSlots, isInitial, setValue])

  const enableContactEmailSelecting =
    !apiExpert || apiExpert?.availability_link_sent_at === null

  const applicantAvailabilityNotProvided =
    !interview.interview?.availability_days && !interview.interview?.availability_time

  const canAskForAvailability =
    !applicantAvailabilityNotProvided &&
    interview.candidate_profile.logo?.url &&
    apiExpert?.first_name &&
    apiExpert?.last_name &&
    apiExpert?.email &&
    apiExpert?.expert_price &&
    apiExpert?.expert_currency &&
    apiExpert?.contact_email &&
    apiExpert?.availability_link_sent_at === null

  return (
    <div>
      {isSelectedExpert && <SelectedExpertBadge positive>Selected</SelectedExpertBadge>}
      {isDeclinedExpert && <SelectedExpertBadge>Declined</SelectedExpertBadge>}

      <PhotoWrapper onClick={() => setShowPhotoEditor(true)}>
        {dataURLImage ? (
          <StyledImg src={dataURLImage} alt="Add photo" />
        ) : (
          <PhotoPlaceHolder hasLogoError={false}>
            {logoUrl ? (
              <Logo src={logoUrl} alt="" />
            ) : (
              <>
                <span>Upload photo</span>
              </>
            )}
          </PhotoPlaceHolder>
        )}
      </PhotoWrapper>

      <RowFull>
        <Controller
          name={`experts[${expertId}].adhoc`}
          render={({ onChange, value, name }) => (
            <CheckBox
              label="Ad-hoc expert"
              onChange={onChange}
              value={value ?? false}
              name={name}
            />
          )}
          control={control}
          defaultChecked={adHoc}
          defaultValue={adHoc}
        />
      </RowFull>
      <Row>
        <Controller
          as={Input}
          label="First name"
          name={`experts[${expertId}].first_name`}
          control={control}
          error={get(errors, `experts[${expertId}].first_name`, {})}
          rules={{
            maxLength: { value: 50, message: 'Max characters : 50' },
          }}
          defaultValue=""
          useTheme
        />
        <Controller
          as={Input}
          label="Last name"
          name={`experts[${expertId}].last_name`}
          control={control}
          error={get(errors, `experts[${expertId}].last_name`, {})}
          rules={{
            maxLength: { value: 50, message: 'Max characters : 50' },
          }}
          defaultValue=""
          useTheme
        />
      </Row>
      <RowFull>
        <Controller
          as={Input}
          label="Email"
          name={`experts[${expertId}].email`}
          control={control}
          error={get(errors, `experts[${expertId}].email`, {})}
          rules={{
            validate: {
              valid: (value) =>
                !value ||
                EmailRegex.test(value) ||
                "That format doesn't look right. Make sure there aren't any typos",
            },
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <LoadExpertButton
        onClick={handleLoadExpert}
        title="Load expert data"
        type="button"
        disabled={!email}
        useTheme
      />
      <Row>
        <Controller
          as={Input}
          label="Expert payment"
          name={`experts[${expertId}].expert_price`}
          control={control}
          error={get(errors, `experts[${expertId}].expert_price`, {})}
          rules={{
            validate: {
              valid: (value) =>
                !value ||
                /^[\d]+(\.[\d]+)*$/.test(value) ||
                'Please enter a valid number',
            },
          }}
          defaultValue=""
          useTheme
        />
        <Controller
          name={`experts[${expertId}].expert_currency`}
          control={control}
          render={({ onChange, value, name: selectName }) => (
            <Select
              name={selectName}
              label="Currency"
              onChange={onChange}
              value={value ?? ''}
              options={currencyList}
              useTheme
              error={get(errors, `experts[${expertId}].expert_currency`, {})}
            />
          )}
        />
      </Row>
      <Row>
        <Controller
          as={Input}
          label="Session price"
          name={`experts[${expertId}].slot_price`}
          control={control}
          error={get(errors, `experts[${expertId}].slot_price`, {})}
          rules={{ required: isB2C }}
          defaultValue={initialSessionPrice}
          disabled={!isB2C}
          type="number"
          useTheme
        />
        <Controller
          name={`experts[${expertId}].session_currency`}
          control={control}
          render={({ onChange, value, name: selectName }) => (
            <Select
              name={selectName}
              label="Currency"
              onChange={onChange}
              value={value ?? ' '}
              options={currencyList}
              useTheme
              disabled
            />
          )}
          disabled
          defaultValue={agency.currency}
        />
      </Row>
      {isB2C && (
        <RowFull>
          <Controller
            as={Textarea}
            rows={getPriceTearsRowCount()}
            label="Price tiers"
            name={`experts[${expertId}].price_tiers`}
            control={control}
            rules={{ required: false }}
            defaultValue={getPrice()}
            disabled
            useTheme
          />
        </RowFull>
      )}
      <RowFull>
        <Controller
          as={Input}
          label="LinkedIn profile"
          name={`experts[${expertId}].linkedin_profile`}
          control={control}
          error={get(errors, `experts[${expertId}].linkedin_profile`, {})}
          rules={{ required: false }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Company"
          name={`experts[${expertId}].company`}
          control={control}
          error={get(errors, `experts[${expertId}].company`, {})}
          rules={{
            required: false,
            maxLength: { value: 100, message: 'Max characters : 100' },
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Position"
          name={`experts[${expertId}].current_position`}
          control={control}
          error={get(errors, `experts[${expertId}].current_position`, {})}
          rules={{
            required: false,
            maxLength: { value: 100, message: 'Max characters : 100' },
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Rating"
          name={`experts[${expertId}].rating`}
          control={control}
          error={get(errors, `experts[${expertId}].rating`, {})}
          rules={{
            min: 1,
            max: 5,
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFullTextArea>
        <Controller
          as={Textarea}
          label="About"
          name={`experts[${expertId}].about`}
          control={control}
          error={get(errors, `experts[${expertId}].about`, {})}
          rules={{
            required: false,
            maxLength: { value: 255, message: 'Max characters : 255' },
          }}
          defaultValue=""
          useTheme
        />
      </RowFullTextArea>
      <RowFull>
        <Controller
          as={Input}
          label="Education 1"
          name={`experts[${expertId}].educations[0]`}
          control={control}
          error={get(errors, `experts[${expertId}].educations[0]`, {})}
          rules={{
            required: false,
            maxLength: { value: 200, message: 'Max characters : 200' },
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Education 2"
          name={`experts[${expertId}].educations[1]`}
          control={control}
          error={get(errors, `experts[${expertId}].educations[1]`, {})}
          rules={{
            required: false,
            maxLength: { value: 200, message: 'Max characters : 200' },
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Education 3"
          name={`experts[${expertId}].educations[2]`}
          control={control}
          error={get(errors, `experts[${expertId}].educations[2]`, {})}
          rules={{
            required: false,
            maxLength: { value: 200, message: 'Max characters : 200' },
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowEmpty />
      <RowFull>
        <Controller
          as={Input}
          label="Experience 1"
          name={`experts[${expertId}].experiences[0]`}
          control={control}
          error={get(errors, `experts[${expertId}].experiences[0]`, {})}
          rules={{
            required: false,
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Experience 2"
          name={`experts[${expertId}].experiences[1]`}
          control={control}
          error={get(errors, `experts[${expertId}].experiences[1]`, {})}
          rules={{
            required: false,
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <RowFull>
        <Controller
          as={Input}
          label="Experience 3"
          name={`experts[${expertId}].experiences[2]`}
          control={control}
          error={get(errors, `experts[${expertId}].experiences[2]`, {})}
          rules={{
            required: false,
          }}
          defaultValue=""
          useTheme
        />
      </RowFull>
      <Row>Session Duration: {interviewDuration} minutes</Row>
      {apiExpert?.comment && !isDeclinedExpert && (
        <CommentText>{apiExpert.comment}</CommentText>
      )}
      {time_slots.map((i, index) => {
        const isSelectedSlot = selectedSlotId === i.id
        return (
          <SlotsWrapper key={i.id}>
            {`Slot ${index + 1}`}
            {isSelectedSlot ? (
              <SelectedSlotBadge positive>Selected</SelectedSlotBadge>
            ) : (
              <>
                <ClearSlotButton
                  title="Clear"
                  customTheme="outline"
                  useTheme
                  onClick={() => {
                    setValue(`experts[${expertId}].time_slots[${index}].from`, '')
                    setValue(`experts[${expertId}].time_slots[${index}].to`, '')
                  }}
                />
                <DeleteSlotButton
                  title="Delete"
                  customTheme="outline"
                  onClick={() => {
                    setExperts((prev) => {
                      const experts = prev.slice()
                      const newSlotsFromState = prev[expertId].time_slots.filter(
                        (slot) => slot.id !== i.id
                      )
                      experts[expertId] = {
                        ...prev[expertId],
                        time_slots: newSlotsFromState,
                      }

                      return experts
                    })
                    setIsInitial(false)
                  }}
                  disabled={onlyOneSlot}
                  useTheme
                />
                {isSlotExpired(i.from) && <ExpiredBlock>Expired</ExpiredBlock>}
              </>
            )}

            <RowFull>
              <Controller
                render={({ onChange, value }) => (
                  <DateZonePicker
                    showTimeSelect
                    label="Availability From"
                    onChange={onChange}
                    interviewDuration={interviewDuration}
                    onChangeZone={handleChangeZone(index)}
                    value={normalizeDate(value)}
                    readOnly={isSelectedSlot}
                    showWarning={checkOutsideDate(value)}
                  />
                )}
                name={`experts[${expertId}].time_slots[${index}].from`}
                control={control}
                error={get(errors, `experts[${expertId}].time_slots[${index}].from`, {})}
                rules={{ required: false }}
                defaultValue=""
                useTheme
              />
            </RowFull>
          </SlotsWrapper>
        )
      })}
      {isDeclinedExpert && (
        <>
          <Badge>Declined</Badge>
          {apiExpert?.decline_reason && (
            <DeclineComentText>{apiExpert.decline_reason}</DeclineComentText>
          )}
          {apiExpert?.comment && (
            <DeclineComentText>&quot;{apiExpert.comment}&quot;</DeclineComentText>
          )}
        </>
      )}
      <AddMoreButton
        onClick={() => {
          setIsInitial(false)
          setExperts((prev) => {
            const experts = prev.slice()
            experts[expertId].time_slots.push({
              id: Date.now(),
              from: '',
              to: '',
            })
            return experts
          })
        }}
        type="button"
        title="Add additional periods"
        customTheme="outline"
        useTheme
      />
      {isSuperAdmin && (
        <>
          <RowFull>
            <Controller
              name={`experts[${expertId}].contact_email`}
              control={control}
              render={({ onChange, value, name: selectName }) => (
                <Select
                  name={selectName}
                  disabled={!enableContactEmailSelecting}
                  label="Email to the expert will be sent from"
                  onChange={onChange}
                  value={value ?? ''}
                  options={fromEmailList}
                  useTheme
                />
              )}
              rules={{
                required: false,
              }}
            />
          </RowFull>
          <RowFull>
            <Controller
              name={`experts[${expertId}].include_onboarding`}
              render={({ onChange, value, name }) => (
                <CheckBox
                  label="Include onboarding"
                  onChange={onChange}
                  value={value ?? false}
                  name={name}
                />
              )}
              control={control}
              defaultChecked={onboarding}
              defaultValue={onboarding}
              useTheme
            />
          </RowFull>
          <AddMoreButton
            disabled={!canAskForAvailability}
            onClick={() => {
              const confirmed = window.confirm(
                `Are you sure you want to ask ${firstName} ${lastName} for availability?`
              )
              if (confirmed) {
                dispatch(
                  askExpertAvailability({
                    interviewId: interview.interview.id,
                    expertId: apiExpert.id,
                    agencyId: interview.interview.agency_id,
                  })
                )
              }
            }}
            type="button"
            title="Ask for expert availability"
            useTheme
          />
          {apiExpert?.availability_link_sent_at && (
            <BottomTextContainer>
              <StyledLink href={availabilityLink} target="_blank">
                Availability link
              </StyledLink>
              <SentText>
                (Sent: {getDateString(apiExpert.availability_link_sent_at)})
              </SentText>
            </BottomTextContainer>
          )}
        </>
      )}

      {showPhotoEditor && (
        <PhotoEditor onClose={() => setShowPhotoEditor(false)} saveImage={handleSave} />
      )}
    </div>
  )
}

export default ExpertDetails
