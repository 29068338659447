import { createAction, createReducer } from '@reduxjs/toolkit'

export interface IDefaultState {
  error: any
  isLoading: boolean
  success?: boolean
}

export interface IDefaultDataState<T> {
  data: T
}

export interface IFailure<F = any> {
  error: F
}

export interface ISagaAction<T> {
  type: string
  payload: T
}

export function reduxRequestFactory<A, F extends IFailure = IFailure>(
  actionName: string,
  prefix: string
) {
  const actionType = `${prefix.toUpperCase()}/${actionName.toUpperCase()}`
  const actionTypeSuccess = `${prefix.toUpperCase()}/${actionName.toUpperCase()}_SUCCESS`
  const actionTypeFailure = `${prefix.toUpperCase()}/${actionName.toUpperCase()}_FAILURE`
  const actionTypeSuccessClear = `${prefix.toUpperCase()}/${actionName.toUpperCase()}_SUCCESS_CLEAR`
  const actionTypeFailureClear = `${prefix.toUpperCase()}/${actionName.toUpperCase()}_FAILURE_CLEAR`

  const action = createAction<A>(actionType)
  const actionSuccess = createAction<void>(actionTypeSuccess)
  const actionFailure = createAction<F>(actionTypeFailure)
  const actionSuccessClear = createAction<void>(actionTypeSuccessClear)
  const actionFailureClear = createAction<void>(actionTypeFailureClear)

  const defaultState: IDefaultState = {
    isLoading: false,
    success: false,
    error: undefined,
  }

  const reducer = createReducer(defaultState, {
    [actionType]: (state: IDefaultState) => ({
      ...state,
      isLoading: true,
      error: undefined,
    }),
    [actionTypeSuccess]: (state: IDefaultState) => ({
      ...state,
      isLoading: false,
      success: true,
      error: undefined,
    }),
    [actionTypeFailure]: (state: IDefaultState, failureAction: ISagaAction<F>) => ({
      ...state,
      isLoading: false,
      success: false,
      error: failureAction.payload.error,
    }),
    [actionTypeSuccessClear]: (state: IDefaultState) => ({
      ...state,
      success: false,
    }),
    [actionTypeFailureClear]: (state: IDefaultState) => ({
      ...state,
      error: undefined,
    }),
  })

  const selectors = {
    success(state: any): boolean {
      return state[prefix][actionName].success
    },
    isLoading(state: any): boolean {
      return state[prefix][actionName].isLoading
    },
    error(state: any): any {
      return state[prefix][actionName].error
    },
  }

  return {
    action,
    actionSuccess,
    actionFailure,
    actionSuccessClear,
    actionFailureClear,
    actionType,
    actionTypeSuccess,
    actionTypeFailure,
    reducer,
    selectors,
  }
}

export function reduxDataFactory<A>(actionName: string, prefix?: string, data?: A) {
  const actionTypePrefix = prefix ? `${prefix.toUpperCase()}/` : ''
  const actionType = `${actionTypePrefix}${actionName.toUpperCase()}`

  const action = createAction<A>(actionType)

  const defaultState: IDefaultDataState<A> = { data }

  const reducer = createReducer(defaultState, {
    [actionType]: (state: IDefaultDataState<any>, a: ISagaAction<A>) => ({
      ...state,
      data: a.payload,
    }),
  })

  const selectors = {
    data(state: any): A {
      return prefix ? state[prefix][actionName].data : state[actionName].data
    },
  }

  return {
    action,
    actionType,
    reducer,
    selectors,
  }
}

export function reduxWatcherFactory<A>(actionName: string) {
  const startActionType = `WATCHER/START_WATCH_${actionName.toUpperCase()}`
  const stopActionType = `WATCHER/STOP_WATCH_${actionName.toUpperCase()}`

  const startAction = createAction<A>(startActionType)
  const stopAction = createAction<void>(stopActionType)

  return {
    startAction,
    startActionType,
    stopAction,
    stopActionType,
  }
}
