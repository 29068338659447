const reactions = [
  {
    text: 'On point',
    mood: 'Positive',
  },
  {
    text: 'Well-articulated',
    mood: 'Positive',
  },
  {
    text: 'Engaging',
    mood: 'Positive',
  },
  {
    text: 'Strong eye contact',
    mood: 'Positive',
  },
  {
    text: 'Confident',
    mood: 'Positive',
  },
  {
    text: 'Could be more concise',
    mood: 'Negative',
  },
  {
    text: 'Difficult to follow',
    mood: 'Negative',
  },
  {
    text: 'Lacked relevance',
    mood: 'Negative',
  },
  {
    text: 'Inaccurate',
    mood: 'Negative',
  },
  {
    text: 'Poor eye contact',
    mood: 'Negative',
  },
  {
    text: 'Lacked confidence',
    mood: 'Negative',
  },
]

export default reactions
