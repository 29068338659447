/* eslint-disable no-nested-ternary */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import arrowDown from '../../static/arrow-down2.svg'
import { IExpertNote } from '../../api'
import Notes from './Notes'
import { setActiveNoteAction } from '../redux'

const Container = styled.div<{ maxHeight: string | number }>`
  overflow: hidden;
  position: relative;
  max-height: ${(props) => props.maxHeight}px;
  height: auto;
  border: 1px solid #e1e3ea;
  border-radius: 8px;
  transition: max-height 0.4s linear;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const HeaderContainer = styled.div`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e1e3ea;
  padding: 0 8px 0 20px;
`

const HeaderIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  object-fit: contain;
`

const HeaderLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  flex: 1;
`

const HeadArrow = styled.span<{ expanded: boolean }>`
  background-image: url(${arrowDown});
  width: 8px;
  height: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%;
  margin-right: 5px;
  transition: height 0.3s linear;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : '')};
`

const ChildrenContainer = styled.div`
  overflow-y: auto;
  display: flex;
`

const ScrollButton = styled.div<{ top: boolean }>`
  position: absolute;
  left: 50%;
  bottom: ${(props) => (props.top ? 'unset' : '12px')};
  top: ${(props) => (props.top ? (isMobileOnly ? '12px' : '81px') : 'unset')};
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: #f5f6f8;
  border: 1px solid #b202ff;
  cursor: pointer;
  color: #b202ff;
  font-size: 32px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%) rotate(${(props) => (props.top ? 180 : 0)}deg);
`

enum ScrollDirection {
  None,
  Top,
  Bottom,
}

interface IProps {
  title: string
  icon: any
  expanded: boolean
  enableConversationIntelligence: boolean
  onExpand?: () => void
  onCollapse?: () => void
  notes: IExpertNote[]
  seekTo: any
  duration: any
  progress: any
  accentColor: string
  showIcons?: boolean
  maxHeight?: number
}

const DropdownBlock = forwardRef((props: IProps, ref: any) => {
  const {
    title,
    icon,
    enableConversationIntelligence,
    onExpand,
    onCollapse,
    expanded,
    notes,
    seekTo,
    duration,
    progress,
    accentColor,
    showIcons,
    maxHeight,
  } = props
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef(null)
  const [selectedTextIndex, setSelectedTextIndex] = useState(0)
  const [activeNote, setActiveNote] = useState<IExpertNote>(null)
  // const activeNote = useSelector(activeTimelineInfoSelectors.data)

  const onHeaderClicked = () => {
    if (onExpand && !expanded) {
      onExpand()
    } else if (onCollapse && expanded) {
      onCollapse()
    }
  }
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.None
  )

  const scrollParentToChild = (parent: HTMLElement, child: HTMLElement) => {
    if (!parent || !child) {
      return
    }
    const parentRect = parent.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()
    const scrollTop = (parentRect.top - childRect.top) * -1

    parent.scrollBy({
      left: 0,
      top: scrollTop - 2,
      behavior: 'smooth',
    })
  }

  const onScroll = useCallback(() => {
    const child = containerRef.current.children[0].children[
      selectedTextIndex
    ] as HTMLDivElement
    if (!child) {
      setScrollDirection(ScrollDirection.None)
      return
    }
    const parent = containerRef.current
    const parentRect = parent.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    if (childRect.height + childRect.top <= parentRect.top) {
      setScrollDirection(ScrollDirection.Top)
    } else if (childRect.top > parentRect.top + parentRect.height) {
      setScrollDirection(ScrollDirection.Bottom)
    } else {
      setScrollDirection(ScrollDirection.None)
    }
  }, [selectedTextIndex])

  const onScrollToSelectedItem = useCallback(
    (force?: boolean, index?: number) => {
      if (!force && scrollDirection !== ScrollDirection.None) {
        onScroll()
        return
      }

      const item = containerRef.current.children[0].children[
        index ?? selectedTextIndex
      ] as HTMLDivElement
      scrollParentToChild(containerRef.current, item)
    },
    [onScroll, scrollDirection, selectedTextIndex]
  )

  const scrollToSelectedItem = useCallback(
    (newValue: number, force?: boolean) => {
      const possibleActiveNote = notes.filter((note) => {
        return note.offset > newValue
      })
      if (possibleActiveNote.length > 0) {
        const index = notes.findIndex((n) => n.id === possibleActiveNote[0].id)
        setSelectedTextIndex(index)
        onScrollToSelectedItem(force, index)
      }
    },
    [notes, onScrollToSelectedItem]
  )

  const getHeight = useCallback(() => {
    if (maxHeight) return maxHeight
    return expanded ? (enableConversationIntelligence ? '568' : '626') : '48'
  }, [maxHeight, expanded, enableConversationIntelligence])

  useImperativeHandle(
    ref,
    () => ({
      scrollToSelectedItem,
    }),
    [scrollToSelectedItem]
  )

  useEffect(() => {
    const possibleActiveNote = notes.filter((note) => {
      return Math.abs(note.offset - duration * progress) < 0.4
    })
    if (possibleActiveNote.length > 0) {
      const closestNote = possibleActiveNote[0]
      const changed = closestNote.id !== activeNote?.id
      if (changed) {
        setActiveNote(closestNote)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  useEffect(() => {
    if (activeNote) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        setActiveNote(null)
        if (activeNote.offset / duration > 1) {
          dispatch(setActiveNoteAction(null))
        }
        timeoutRef.current = null
        const index = notes.findIndex((n) => n.id === activeNote.id)
        setSelectedTextIndex(index === notes.length - 1 ? index : index + 1)
      }, activeNote.duration ?? 5000)

      const index = notes.findIndex((n) => n.id === activeNote.id)
      const item = containerRef.current.children[0].children[index] as HTMLDivElement
      scrollParentToChild(containerRef.current, item)
      if (activeNote.offset / duration > 1) {
        seekTo(0)
        dispatch(setActiveNoteAction(activeNote))
      } else {
        seekTo(activeNote.offset / duration)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNote])

  return (
    <Container maxHeight={getHeight()}>
      {!isMobileOnly && (
        <HeaderContainer onClick={onHeaderClicked}>
          <HeaderIcon src={icon} />
          <HeaderLabel>{title}</HeaderLabel>
          <HeadArrow expanded={expanded} />
        </HeaderContainer>
      )}
      <ChildrenContainer onScroll={onScroll} ref={containerRef}>
        <Notes
          accentColor={accentColor}
          // seekTo={seekTo}
          onNoteSelected={setActiveNote}
          notes={notes}
          duration={duration}
          progress={progress}
          showIcons={showIcons}
          activeNote={activeNote}
        />
      </ChildrenContainer>
      {scrollDirection !== ScrollDirection.None && expanded && (
        <ScrollButton
          top={scrollDirection === ScrollDirection.Top}
          onClick={() => onScrollToSelectedItem(true)}
        >
          ↓
        </ScrollButton>
      )}
    </Container>
  )
})

export default DropdownBlock

DropdownBlock.defaultProps = {
  onExpand: () => {},
  onCollapse: () => {},
  showIcons: false,
  maxHeight: null,
}
