import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { Textarea, Text, Button } from 'src/components'
import useLogrocket from 'src/utils/useLogrocket'
import * as reportActions from '../actions'
import Layout from '../FeedbackLayout'
import * as interviewSelectors from '../../interview/selectors'

const RatingContainer = styled.div`
  margin: 12px 0 34px;
  @media screen and (max-width: 900px) {
    margin-bottom: 25px;
    .star-ratings {
      padding: 0 5px !important;
      display: flex !important;
      justify-content: space-around;

      .star-container {
        padding: 0 !important;
        margin: 0;
      }
    }
  }
  width: ${isMobileOnly ? '85' : '100'}%;

  @media screen and (orientation: landscape) {
    width: 70% !important;
  }
`

const TextAreaContainer = styled(RatingContainer)`
  @media screen and (orientation: landscape) {
    width: 100%;
    max-width: 400px;
  }
`

const TextareaStyled = styled(Textarea)`
  @media screen and (max-width: 900px) {
    width: 350px;
  }
  @media screen and (max-width: 567px) {
    width: 400px;
  }
`

const ButtonStyled = styled(Button)`
  @media screen and (max-width: 900px) {
    font-size: 17px;
    font-weight: 100;
  }
`

function FeedbackContainer() {
  const { setLogrocket } = useLogrocket()
  const dispatch = useDispatch()
  const { interviewToken } = useParams()
  const [rating, setRating] = useState(0)
  const [expertRating, setExpertRating] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  const [feedback, setFeedback] = useState('')

  const interviewData = useSelector(interviewSelectors.interview)

  const onSubmit = useCallback(() => {
    setSubmitted(true)
    dispatch(
      reportActions.sendFeedback({ rating, expertRating, feedback, interviewToken })
    )
  }, [dispatch, rating, expertRating, feedback, interviewToken])

  if (submitted) {
    return (
      <Layout
        title="Thank you for submitting your feedback!"
        subtitle=""
        submitted={submitted}
      >
        <></>
      </Layout>
    )
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (interviewData) {
      setLogrocket(
        `${interviewData.interview?.order_number.toString()}`,
        'Applicant post-session rating'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewData])

  const canSubmit = rating || expertRating || feedback

  return (
    <Layout
      title="You've completed your coaching session!&#13;&#10;We'll notify you as soon as your feedback report is ready."
      subtitle="Your feedback is important to us"
    >
      <>
        <Text>How would you rate the audio and video quality of your call?</Text>
        <RatingContainer>
          <StarRatings
            rating={rating}
            starRatedColor="#B202FF"
            starHoverColor="#B202FF"
            starEmptyColor="#E1E3EA"
            changeRating={setRating}
            numberOfStars={5}
            svgIconPath="M28.7889 35C28.4966 35 28.2024 34.9317 27.9323 34.7913L18.4991 29.8723L9.06766 34.7913C8.44235 35.1145 7.68754 35.0591 7.12144 34.6455C6.55163 34.2319 6.26858 33.532 6.38883 32.8396L8.1852 22.4476L0.563108 15.0894C0.0562017 14.6001 -0.126951 13.8652 0.089502 13.1931C0.305955 12.5247 0.885012 12.0353 1.58432 11.9356L12.1294 10.4068L16.8433 0.941704C17.4686 -0.313901 19.5314 -0.313901 20.1567 0.941704L24.8706 10.4068L35.4157 11.9356C36.115 12.0353 36.694 12.5247 36.9105 13.1931C37.1269 13.8652 36.9438 14.6001 36.4369 15.0894L28.8148 22.4476L30.6112 32.8396C30.7314 33.532 30.4465 34.2319 29.8786 34.6455C29.5567 34.8818 29.1737 35 28.7889 35Z"
            starDimension="37px"
            svgIconViewBox="0 0 37 37"
            name="rating"
          />
        </RatingContainer>

        <Text>How would you rate your coach?</Text>
        <RatingContainer>
          <StarRatings
            rating={expertRating}
            starRatedColor="#B202FF"
            starHoverColor="#B202FF"
            starEmptyColor="#E1E3EA"
            changeRating={setExpertRating}
            numberOfStars={5}
            svgIconPath="M28.7889 35C28.4966 35 28.2024 34.9317 27.9323 34.7913L18.4991 29.8723L9.06766 34.7913C8.44235 35.1145 7.68754 35.0591 7.12144 34.6455C6.55163 34.2319 6.26858 33.532 6.38883 32.8396L8.1852 22.4476L0.563108 15.0894C0.0562017 14.6001 -0.126951 13.8652 0.089502 13.1931C0.305955 12.5247 0.885012 12.0353 1.58432 11.9356L12.1294 10.4068L16.8433 0.941704C17.4686 -0.313901 19.5314 -0.313901 20.1567 0.941704L24.8706 10.4068L35.4157 11.9356C36.115 12.0353 36.694 12.5247 36.9105 13.1931C37.1269 13.8652 36.9438 14.6001 36.4369 15.0894L28.8148 22.4476L30.6112 32.8396C30.7314 33.532 30.4465 34.2319 29.8786 34.6455C29.5567 34.8818 29.1737 35 28.7889 35Z"
            starDimension="37px"
            svgIconViewBox="0 0 37 37"
            name="rating"
          />
        </RatingContainer>

        <Text>How could we improve our product and your experience? </Text>
        <TextAreaContainer>
          <TextareaStyled optional onChange={setFeedback} value={feedback} />
        </TextAreaContainer>

        <ButtonStyled disabled={!canSubmit} title="Submit" onClick={onSubmit} />
      </>
    </Layout>
  )
}

export default FeedbackContainer
