/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'
import {
  getShortLocalTime,
  getTimezoneOffset,
  isAvailableDayMatchWeekPosition,
  isEqualFullDate,
  isToday,
  isTomorow,
} from 'src/utils/date'
import SvgClose from 'src/static/close.svg'
import SvgDanger from 'src/static/danger.svg'
import useLogrocket from 'src/utils/useLogrocket'
import moment from 'moment'
import { ICandidateProfile } from '../../api'
import WeekButtonImg from '../../static/week-button.png'
import CheckBox from './CheckBox'
import * as interviewSelectors from '../selectors'

const Container = styled.div<{ disabled: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`

const CalendarContainer = styled.div`
  position: relative;
  height: 540px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 14px -3px lightgrey;
  border-radius: 8px;
`

const CalendarHeader = styled.div`
  height: 80px;
  padding: 0 24px;
  display: flex;
  flex-direction: ${isMobileOnly ? 'column-reverse' : 'row'};
  margin: ${isMobileOnly ? '16px 0' : '0'};
  justify-content: ${isMobileOnly ? 'space-around' : 'space-between'};
  align-items: center;
`

const WeekSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`

const WeekButton = withProps()(styled.div<{ toRight?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${(props) => (props.disabled ? '#27323830' : 'rgba(178, 2, 255, 0.2)')};
  background: ${({ disabled, theme, useTheme }: any) =>
    disabled
      ? '#27323830'
      : useTheme
      ? `${theme.agencyColor}30`
      : 'rgba(178, 2, 255, 0.2)'};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  border-radius: 15px;

  &:after {
    content: '';
    display: block;
    background: url(${WeekButtonImg});
    width: 20px;
    height: 20px;
    background-size: 7px 11px;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(${(props) => (props.toRight ? '180deg' : '0deg')});
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  }
`)

const WeekDaysLabel = styled.div<{ wide: boolean }>`
  font-weight: 500;
  width: ${(props) => (props.wide ? 'auto' : '144px')};
  font-size: 16px;
  text-align: center;
  color: #222b45;
  margin: 0 18px;
`

const TimeZoneLabel = styled.div`
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.666667px;
  color: black;
`

const CalendarItemsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-top: 1px solid #edeef2;
  overflow-x: visible;
  overflow-y: clip;
  min-height: 0;
  ${isMobileOnly &&
  css`
    overflow-y: auto;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
      height: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
    }
  `}
`

const WeekContainer = styled.div`
  height: 80px;
  width: ${isMobileOnly ? 'fit-content' : '100%'};
  display: flex;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      width: 100%;
    }
  `}
`

const WeekDayContainer = styled.div`
  width: ${isMobileOnly ? '81px' : 'calc(100% / 7)'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      width: calc(100% / 7);
    }
  `}
`

const WeekLabel = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: #222b45;
`

const DayLabel = withProps()(styled.div<{
  today?: boolean
  highlight?: boolean
  disabled?: boolean
  showHint?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => (props.today ? '500' : '400')};
  font-size: 15px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: ${({ highlight, theme, useTheme, disabled }: any) => {
    if (disabled) {
      return 'rgba(103, 103, 103, 0.1)'
    }
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return highlight ? (useTheme ? agencyColor : '#F23482') : 'transparent'
  }};
  color: ${({ highlight, today, theme, useTheme, disabled }: any) => {
    if (disabled) {
      return 'rgba(34, 43, 69, 0.5)'
    }
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return highlight ? 'white' : today ? (useTheme ? agencyColor : '#B202FF') : '#222b45'
  }};
  &:after {
    visibility: ${(props) => (props.today && !props.disabled ? 'visible' : 'hidden')};
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: ${({ theme, useTheme }: any) =>
      useTheme ? (theme.agencyColor === '' ? '#B202FF' : theme.agencyColor) : '#b202ff'};
    position: absolute;
    bottom: 0;
  }
`)

const DayLabelHint = styled.div`
  width: 210px;
  height: auto;
  border-radius: 12px;
  background: rgba(24, 11, 49, 0.8);
  border: 1px solid #353c5a;
  position: absolute;
  bottom: -74px;
  left: -89px;
  padding: 10px;
  z-index: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  white-space: break-spaces;
  color: white;
  text-align: center;
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid rgba(24, 11, 49, 0.8);
    position: absolute;
    top: -11px;
    left: 99px;
  }
`

const TimeSlotContainer = styled.div`
  min-width: 100%;
  flex: 1;
  display: flex;
  overflow: auto;
  width: ${isMobileOnly ? 'fit-content' : '100%'};
  padding: 12px 0;
  border-top: 1px solid #edeef2;
  border-bottom: 1px solid #edeef2;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  }

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      width: 100%;
    }
  `}
`

const TimeSlotBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobileOnly ? '81px' : 'calc(100% / 7)'};
  height: fit-content;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      width: calc(100% / 7);
    }
  `}
`

const TimeSlot = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
`

const TimeSlotContent = withProps()(styled.div<{ selected: boolean; colored?: boolean }>`
  width: calc(100% - 12px);
  height: 39px;
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  font-size: 12px;
  border: 1px solid
    ${({ colored, selected, theme, useTheme }: any) => {
      const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
      const color = colored || selected ? (useTheme ? agencyColor : '#b202ff') : '#E1E3EA'
      return color
    }};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ selected, theme, useTheme }: any) => {
    const agencyColor = theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    const color = selected ? (useTheme ? agencyColor : '#b202ff') : 'transparent'
    return color
  }};
  color: ${({ selected }) => (selected ? 'white' : '#3B424F')};
`)

const InterviewsDateLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #f23482;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 51px;
`

const CalendarFooter = styled.div`
  height: ${isMobileOnly ? 'auto' : '64px'};
  display: flex;
  padding: 0 16px;
  flex-direction: ${isMobileOnly ? 'column' : 'row'};
  align-items: center;
  justify-content: space-between;
  border-bottom: ${isMobileOnly ? '1px' : '0'} solid #edeef2;
`

const FooterLabelContainer = styled.div`
  display: flex;
  flex-direction: ${isMobileOnly ? 'row' : 'column'};
  justify-content: ${isMobileOnly ? 'space-between' : ''};
  width: ${isMobileOnly ? '100%' : 'auto'};
  padding: ${isMobileOnly ? '11px 0' : '0'};
  border-bottom: ${isMobileOnly ? '1px' : '0'} solid #edeef2;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      justify-content: start;
    }
  `}
`

const FooterLabel = styled.div<{ filled?: boolean }>`
  position: relative;
  font-weight: 300;
  font-size: 12px;
  color: black;
  line-height: 14px;
  padding-left: 24px;
  margin: 5px 0;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-right: 30px;
    }
  `}

  &:before {
    background: ${(props) => (props.filled ? '#B202FF' : 'transparent')};
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 2px;
    border: 1px solid #b202ff;
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #222b45;
  margin: 36px 12px 0;
`

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin: 12px;
  color: #222b45;
`

const BottomButtonsContainer = styled.div`
  height: 64px;
  width: 100%;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: ${isMobileOnly ? 'space-between' : 'center'};
  padding: ${isMobileOnly ? '0 18px' : '0'};

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      justify-content: start;
    }
  `}
`

const BottomButton = styled.div<{ width: number; filled?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.filled ? 'white' : '#B202FF')};
  background: ${(props) => (props.filled ? '#B202FF' : 'white')};
  border: 1px solid #b202ff;
  border-radius: 4px;
  margin: ${isMobileOnly ? '0' : '0 12px'};
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  font-weight: 500;
  font-size: 12px;

  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      margin-right: 40px;
    }
  `}

  & :hover {
    opacity: 0.8;
  }

  & :active {
    opacity: 0.5;
  }
`

const WarningContainer = styled.div`
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  bottom: ${isMobileOnly ? 108 : 64}px;
  left: ${isMobileOnly ? 15 : 24}px;
  flex-direction: row;
  padding: 20px;
  border: 1px solid #edeef2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  ${isMobileOnly &&
  css`
    @media (orientation: portrait) {
      right: 15px;
    }
  `}
`

const WarningLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #3c424e;
  margin: 0 14px;
`

const CloseIcon = styled.img`
  height: 14px;
  width: 14px;
  cursor: pointer;
`

const WarningIcon = styled.img`
  height: 20px;
  width: 20px;
`

const NoSlotsLabel = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  opacity: 0.5;
`

const TimeZoneWrapper = styled.div`
  width: 280px;
  font-size: 12px;
`

interface IProps {
  selectedDays: number[]
  fullWeek?: boolean
  showTimezonePicker?: boolean
  useTheme?: boolean
  disabled?: boolean
  staticTimezone?: string
  useStaticTimezone?: boolean
  disableTodaySlots?: boolean
  ignoreTimezone?: boolean
  onChange: (dates: number[]) => void
  onDecline?: () => void
  onContinue?: () => void
  onChahgeTimeZone?: (tzOffset: number) => void
}

interface ITimeSlot {
  date: number
  disabled?: boolean
}

interface IWeekDay {
  label: string
  number: number
  today?: boolean
  interviewDay?: boolean
  highlighted?: boolean
  slots?: ITimeSlot[]
  date?: Date
}

const WeekDays: IWeekDay[] = [
  { label: 'M', number: 5 },
  { label: 'T', number: 6 },
  { label: 'W', number: 7 },
  { label: 'T', number: 8 },
  { label: 'F', number: 9 },
  { label: 'S', number: 10 },
  { label: 'S', number: 11 },
]

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const AllHoursFrom = 9
const AllHoursTo = 23
const MaxWeekShift = 8

const getZoneLabel = (tz: any) => {
  const offset = moment.tz(tz).utcOffset() / 60
  return `${tz}, GMT ${offset >= 0 ? '+' : ''}${offset}`
}

const timeZoneOptions = moment.tz
  .names()
  .map((i) => ({
    label: getZoneLabel(i),
    value: getZoneLabel(i),
    offset: moment.tz(i).utcOffset() / 60,
  }))
  .filter((z) => z.label !== 'US/Pacific-New')

function AvailabilityExpertCalendar({
  selectedDays,
  fullWeek,
  showTimezonePicker,
  useTheme,
  disabled,
  staticTimezone,
  useStaticTimezone,
  disableTodaySlots,
  ignoreTimezone,
  onChange,
  onDecline,
  onContinue,
  onChahgeTimeZone,
}: IProps) {
  const { setLogrocket } = useLogrocket()
  const interviewDetails = useSelector(interviewSelectors.interview)
  const [inited, setInited] = useState(false)
  const [weekShift, setWeekShift] = useState(0)
  const [canGoForward, setCanGoForward] = useState(true)
  const [canGoBack, setCanGoBack] = useState(true)
  const [month, setMonth] = useState('')
  const [prevMonth, setPrevMonth] = useState('')
  const [showApplicantPrefferedSlots, setShowApplicantPrefferedSlots] = useState(true)
  const [weekDays, setWeekDays] = useState(WeekDays)
  const [showWarning, setShowWarning] = useState(false)
  const [selectedTimeZone, setSelectedTimeZone] = useState({
    offset: moment.tz(moment.tz.guess()).utcOffset() / 60,
    label: getZoneLabel(moment.tz.guess()),
    value: getZoneLabel(moment.tz.guess()),
  })

  const isWideWeekDaysTitle = weekDays[6].number < weekDays[0].number
  const candidateTz = (interviewDetails.peer as ICandidateProfile)?.time_zone

  const staticTzUtcOffset = moment().tz(staticTimezone)?.utcOffset()
  const myUtcOffset = moment().utcOffset()

  const localTzShift = new Date().getTimezoneOffset() * -1
  const applicantTzShift = getTimezoneOffset(candidateTz)
  const generalTzShift = (applicantTzShift - localTzShift) / 60
  const candidateAvailabileAt =
    interviewDetails.interview?.candidate_available_at?.map((t) => t * 1000) || []

  const localTzIsWeird = localTzShift % 60 !== 0
  const candidateAvailabilityIsWeird = candidateAvailabileAt.some(
    (s) => s % 3600000 !== 0
  )
  const genericDateExtraMin =
    (localTzIsWeird && !candidateAvailabilityIsWeird) ||
    (!localTzIsWeird && candidateAvailabilityIsWeird)
      ? 30
      : 0

  const getTimeHours = (time: string) => {
    switch (time) {
      case '8-12':
        return { min: 8, max: 12 }
      case '9-12':
        return { min: 9, max: 12 }
      case '12-15':
        return { min: 12, max: 15 }
      case '12-18':
        return { min: 12, max: 18 }
      case '15-18':
        return { min: 15, max: 18 }
      case '18-21':
        return { min: 18, max: 21 }
      case '18-22':
        return { min: 18, max: 22 }
      case '21-24':
        return { min: 21, max: 24 }
      default:
        return { min: 0, max: 0 }
    }
  }

  const getDay = (date: Date) => {
    let day = date.getDay() - 1
    if (day < 0) day = 6
    return day
  }

  const getWeekDates = useCallback((): Date[] => {
    const curr = new Date()
    const first = curr.getDate() - getDay(curr) + weekShift * 7
    const last = first + 7
    const dateList = []
    for (let i = first; i < last; i += 1) {
      const date = new Date(new Date().setDate(i))
      const weekPosition = getDay(date)

      if (interviewDetails.interview.candidate_available_days) {
        interviewDetails.interview.candidate_available_days.forEach((day) => {
          if (isAvailableDayMatchWeekPosition(day, weekPosition)) {
            interviewDetails.interview.candidate_available_hours.forEach((hours) => {
              const timehours = getTimeHours(hours)
              for (let j = timehours.min; j < timehours.max; j += 1) {
                dateList.push(
                  new Date(date).setHours(j - generalTzShift, genericDateExtraMin, 0, 0)
                )
              }
            })
          }
        })
      }
    }
    return dateList.map((dd) => new Date(dd))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekShift])

  const getSlots = (date: Date): ITimeSlot[] => {
    const slots: ITimeSlot[] = []

    let slotsDisabled = new Date().setHours(0, 0, 0, 0) > date.setHours(0, 0, 0, 0)

    if (interviewDetails.interview.interview_date && !fullWeek) {
      slotsDisabled =
        slotsDisabled ||
        new Date(interviewDetails.interview.interview_date).setHours(0, 0, 0, 0) <
          date.setHours(0, 0, 0, 0)
    }
    if (slotsDisabled) return []

    let minApplicantHour = AllHoursFrom
    if (candidateAvailabileAt.length > 0) {
      candidateAvailabileAt.forEach((t) => {
        const tDate = new Date(t * 1000)
        if (tDate.getHours() < minApplicantHour) {
          minApplicantHour = tDate.getHours()
        }
      })
    } else if (selectedDays.length > 0) {
      selectedDays.forEach((t) => {
        let time = t
        if (useStaticTimezone) {
          time -= (myUtcOffset - staticTzUtcOffset) * 60 * 1000
        }
        const tDate = new Date(time)
        if (tDate.getHours() < minApplicantHour) {
          minApplicantHour = tDate.getHours()
        }
      })
    } else if (!ignoreTimezone) {
      const weekDateList = getWeekDates()
      weekDateList.forEach((w) => {
        if (w.getHours() < minApplicantHour) {
          minApplicantHour = w.getHours()
        }
      })
    }

    for (let i = minApplicantHour; i <= AllHoursTo; i += 1) {
      const slot: ITimeSlot = {
        date: new Date(date).setHours(i, genericDateExtraMin, 0, 0),
      }
      slots.push(slot)
    }

    return slots
  }

  const moveWeek = (back: boolean) => {
    if (back) {
      setWeekShift((old) => old - 1)
    } else {
      setWeekShift((old) => old + 1)
    }
  }

  const onSlotSelected = (date: number) => {
    const selectedDaysCopy = [...selectedDays]
    let newDate = date
    if (useStaticTimezone) {
      newDate -= (staticTzUtcOffset - myUtcOffset) * 60 * 1000
    }
    if (selectedDays.some((d) => d === newDate)) {
      const index = selectedDays.indexOf(newDate)
      selectedDaysCopy.splice(index, 1)
    } else {
      selectedDaysCopy.push(newDate)
    }
    onChange(selectedDaysCopy.sort())
  }

  const getWeekDaysTitle = () => {
    if (isWideWeekDaysTitle) {
      let lastMonthDay = weekDays[0].number
      let index = 1
      while (lastMonthDay < weekDays[index].number) {
        lastMonthDay = weekDays[index].number
        index += 1
      }
      const firstBlock =
        weekDays[0].number !== lastMonthDay
          ? `${weekDays[0].number}-${lastMonthDay}`
          : `${weekDays[0].number}`
      const lastBlock = weekDays[6].number !== 1 ? `1-${weekDays[6].number}` : `1`
      return `${prevMonth}, ${firstBlock} / ${month}, ${lastBlock}`
    }
    return `${month}, ${weekDays[0].number}-${weekDays[6].number}`
  }

  const handleUpdateZone = (tz: any) => {
    onChange([])

    onChahgeTimeZone(tz.offset)
    setSelectedTimeZone(tz)
  }

  const checkOutsideDate = (dateMs: number) => {
    if (!dateMs) return false

    const date = new Date(dateMs)
    let hours = date.getHours() + generalTzShift
    if (hours < 0) {
      hours = 24 + hours
    }
    if (candidateAvailabileAt.length > 0) {
      const result = !candidateAvailabileAt.some((time) => time === dateMs)
      return result
    }

    const weekDayList = getWeekDates()
    const matched = weekDayList.some((w) => isEqualFullDate(w, new Date(dateMs)))
    return !matched
  }

  const checkOutsideTime = (dateMs: number) => {
    if (!dateMs) return false

    const date = new Date(dateMs)
    let hours = date.getHours() + generalTzShift
    if (hours < 0) {
      hours = 24 + hours
    }
    if (candidateAvailabileAt.length > 0) {
      const result = !candidateAvailabileAt.some((time) => {
        const result2 = new Date(time).getHours() === date.getHours()
        return result2
      })
      return result
    }

    const weekDayList = getWeekDates()
    const result = !weekDayList.some((wDate) => {
      return wDate.getHours() === date.getHours()
    })
    return result
  }

  const checkFutureSlots = () => {
    if (fullWeek) return true

    if (candidateAvailabileAt.length > 0) {
      let hasFutureSlots = false
      candidateAvailabileAt.forEach((t) => {
        hasFutureSlots = hasFutureSlots || new Date(t) > new Date()
      })
      return hasFutureSlots
    }

    return true
  }

  const getHasSlots = (weekPosition: number) => {
    if (fullWeek) return true

    if (candidateAvailabileAt.length > 0) {
      return candidateAvailabileAt.some((t) => getDay(new Date(t)) === weekPosition)
    }

    const weekDayList = getWeekDates()
    return weekDayList.some((w) => getDay(w) === weekPosition)
  }

  useEffect(() => {
    const curr = new Date()
    const first = curr.getDate() - getDay(curr) + weekShift * 7
    const last = first + 7

    const prevWeekDays = [...weekDays]

    for (let i = first; i < last; i += 1) {
      let date = new Date(new Date().setDate(i))
      if (i < 0) {
        date = new Date(new Date().setDate(1))
        const days = 86400000
        date = new Date(date.getTime() + days * (i - 1))
      }
      const weekPosition = getDay(date)
      if (weekPosition === 6) {
        setMonth(monthNames[date.getMonth()])
      } else if (weekPosition === 0) {
        setPrevMonth(monthNames[date.getMonth()])
      }
      const number = date.getDate()
      const today =
        date.getFullYear() === curr.getFullYear() &&
        number === curr.getDate() &&
        date.getMonth() === curr.getMonth()
      if (
        (weekPosition === 5 || weekPosition === 6) &&
        today &&
        disableTodaySlots &&
        !inited
      ) {
        setWeekShift((v) => v + 1)
        return
      }
      prevWeekDays[weekPosition].date = date
      prevWeekDays[weekPosition].number = number
      prevWeekDays[weekPosition].today = today
      prevWeekDays[weekPosition].interviewDay =
        new Date(interviewDetails.interview.interview_date).setHours(0, 0, 0, 0) ===
        date.setHours(0, 0, 0, 0)
      const hasSlots = getHasSlots(weekPosition)
      prevWeekDays[weekPosition].slots =
        hasSlots || !showApplicantPrefferedSlots || fullWeek ? getSlots(date) : []
    }
    if (!inited) {
      const hasTimeslots = prevWeekDays.some(
        (w) => w.slots?.filter((s) => !checkOutsideDate(s.date)).length > 0
      )
      const hasFutureSlots = checkFutureSlots()
      if (!hasTimeslots && hasFutureSlots && !fullWeek) {
        setWeekShift((v) => v + 1)
        return
      }
    }
    setWeekDays(prevWeekDays)
    if (interviewDetails.interview.interview_date && !fullWeek) {
      let date = new Date(new Date().setDate(last))
      if (last < 0) {
        date = new Date(new Date().setDate(1))
        const days = 86400000
        date = new Date(date.getTime() + days * (last - 1))
      }
      setCanGoForward(
        new Date(interviewDetails.interview.interview_date).setHours(0, 0, 0, 0) >
          date.setHours(0, 0, 0, 0)
      )
    } else {
      setCanGoForward(weekShift < MaxWeekShift || fullWeek)
    }
    setCanGoBack(weekShift > 0)
    let hasNonPrefferedSlots = false
    if (showApplicantPrefferedSlots) {
      hasNonPrefferedSlots = selectedDays.some((d) => checkOutsideDate(d))
    }
    setShowWarning(hasNonPrefferedSlots)
    setInited(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekShift, showApplicantPrefferedSlots])

  const hasTimeslots = weekDays.some(
    (w) => w.slots?.length > 0 && w.slots?.some((s) => s !== null)
  )

  useEffect(() => {
    if (interviewDetails) {
      setLogrocket(
        `${interviewDetails.interview?.order_number.toString()}`,
        'Expert availability page - selection'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewDetails])

  const getTimezoneBlock = () => {
    if (showTimezonePicker) {
      return (
        <TimeZoneWrapper>
          <ReactSelect
            name="Select timezone"
            value={selectedTimeZone}
            onChange={handleUpdateZone}
            options={timeZoneOptions}
          />
        </TimeZoneWrapper>
      )
    }

    if (staticTimezone) {
      return <TimeZoneLabel>{getZoneLabel(staticTimezone)}</TimeZoneLabel>
    }

    return (
      <TimeZoneLabel>
        {Intl.DateTimeFormat().resolvedOptions().timeZone} (
        {getShortLocalTime(new Date())})
      </TimeZoneLabel>
    )
  }

  const [activeDayHintIndex, setActiveDayHintIndex] = useState(-1)
  return (
    <Container disabled={disabled}>
      {!fullWeek && (
        <>
          <Title>
            {`
        If you're able to help ${interviewDetails.peer.first_name}, please select your availability from the time slots
        below`}
          </Title>
          <SubTitle>
            The more slots you provide, the more likely you are to be matched!
          </SubTitle>
        </>
      )}
      <CalendarContainer>
        <CalendarHeader>
          <WeekSwitchContainer>
            <WeekButton
              onClick={() => moveWeek(true)}
              disabled={!canGoBack || disabled}
              useTheme={useTheme}
            />
            <WeekDaysLabel wide={isWideWeekDaysTitle}>{getWeekDaysTitle()}</WeekDaysLabel>
            <WeekButton
              onClick={() => moveWeek(false)}
              toRight
              useTheme={useTheme}
              disabled={!canGoForward || disabled}
            />
          </WeekSwitchContainer>
          {getTimezoneBlock()}
        </CalendarHeader>
        <CalendarItemsContainer>
          {hasTimeslots && (
            <>
              <WeekContainer>
                {weekDays.map((weekDay, index) => {
                  if (
                    isMobileOnly &&
                    weekDay.date?.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                  ) {
                    return null
                  }
                  const slotDate = new Date(weekDay.slots[0]?.date ?? 0)
                  const isDisabled =
                    disableTodaySlots && (isToday(slotDate) || isTomorow(slotDate))
                  return (
                    <WeekDayContainer key={index}>
                      <WeekLabel>{weekDay.label}</WeekLabel>
                      <DayLabel
                        today={weekDay.today}
                        highlight={weekDay.interviewDay}
                        useTheme={useTheme}
                        disabled={isDisabled}
                        onMouseMove={() => setActiveDayHintIndex(index)}
                        onMouseLeave={() => setActiveDayHintIndex(-1)}
                      >
                        {weekDay.number}
                        {activeDayHintIndex === index && isDisabled && (
                          <DayLabelHint>
                            You can&apos;t use this date.{`\n\r`}It is too short of notice
                            to do that.
                          </DayLabelHint>
                        )}
                      </DayLabel>
                    </WeekDayContainer>
                  )
                })}
              </WeekContainer>
              <TimeSlotContainer>
                {weekDays.map((weekDay, dayIndex) => {
                  if (
                    isMobileOnly &&
                    weekDay.date?.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
                  ) {
                    return null
                  }
                  return (
                    <TimeSlotBlock key={dayIndex}>
                      {weekDay.slots?.map((slot, slotIndex) => {
                        const slotDateMs = slot?.date
                        const selected = selectedDays.some((d) => {
                          let selectedDay = d
                          if (useStaticTimezone) {
                            selectedDay -= (myUtcOffset - staticTzUtcOffset) * 60 * 1000
                          }
                          return selectedDay === slotDateMs
                        })
                        const index = slotIndex + dayIndex * weekDay.slots.length
                        const isOutsideDate = checkOutsideDate(slotDateMs)
                        const isOutsideTime = checkOutsideTime(slotDateMs)
                        const isPastSlot = slotDateMs < new Date().getTime()
                        const collapseSlot =
                          showApplicantPrefferedSlots &&
                          !fullWeek &&
                          ((isOutsideDate && isOutsideTime) || !slotDateMs)

                        if (collapseSlot) return null
                        const showSlot =
                          (showApplicantPrefferedSlots &&
                            !isOutsideTime &&
                            !isOutsideDate) ||
                          !showApplicantPrefferedSlots ||
                          fullWeek
                        const slotDate = new Date(slotDateMs)
                        const isDisabled =
                          disableTodaySlots && (isToday(slotDate) || isTomorow(slotDate))
                        return (
                          <TimeSlot
                            key={index}
                            disabled={slot?.disabled || disabled || isDisabled}
                          >
                            {!isPastSlot && showSlot && (
                              <TimeSlotContent
                                useTheme={useTheme}
                                selected={selected}
                                colored={!isOutsideDate && !fullWeek}
                                onClick={() => onSlotSelected(slotDateMs)}
                              >
                                {getShortLocalTime(new Date(slotDateMs))}
                              </TimeSlotContent>
                            )}
                          </TimeSlot>
                        )
                      })}
                      {weekDay.interviewDay && interviewDetails.peer && (
                        <InterviewsDateLabel>{`${interviewDetails.peer.first_name}’s interview!`}</InterviewsDateLabel>
                      )}
                    </TimeSlotBlock>
                  )
                })}
              </TimeSlotContainer>
            </>
          )}
          {!hasTimeslots && <NoSlotsLabel>No timeslots for this week</NoSlotsLabel>}
        </CalendarItemsContainer>
        {showWarning && !fullWeek && (
          <WarningContainer>
            <WarningIcon src={SvgDanger} />
            <WarningLabel>
              You&apos;re filtering availability and only some of your selected slots are
              displayed.
            </WarningLabel>
            <CloseIcon src={SvgClose} onClick={() => setShowWarning(false)} />
          </WarningContainer>
        )}
        {!fullWeek && (
          <CalendarFooter>
            <FooterLabelContainer>
              <FooterLabel>Applicant preferred availability</FooterLabel>
              <FooterLabel filled>Your availability</FooterLabel>
            </FooterLabelContainer>
            <CheckBox
              label="Applicant’s preferred availability"
              checked={showApplicantPrefferedSlots}
              onChange={() =>
                setShowApplicantPrefferedSlots(!showApplicantPrefferedSlots)
              }
            />
          </CalendarFooter>
        )}
      </CalendarContainer>
      {!fullWeek && (
        <BottomButtonsContainer>
          <BottomButton onClick={onDecline} width={112}>
            Decline
          </BottomButton>
          <BottomButton
            onClick={onContinue}
            width={186}
            filled
            disabled={selectedDays.length === 0}
          >
            Continue
          </BottomButton>
        </BottomButtonsContainer>
      )}
    </Container>
  )
}

AvailabilityExpertCalendar.defaultProps = {
  onDecline: () => {},
  onContinue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChahgeTimeZone: (t) => {},
  fullWeek: false,
  showTimezonePicker: false,
  useTheme: false,
  disabled: false,
  disableTodaySlots: false,
  staticTimezone: '',
  useStaticTimezone: false,
  ignoreTimezone: false,
}

export default AvailabilityExpertCalendar
