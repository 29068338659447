import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import Background from '../../static/rescheduling_policy_background.png'
import BackgroundMobile from '../../static/rescheduling_policy_background_mobile.png'

const Container = styled.div<{ top?: number }>`
  display: flex;
  flex-direction: column;
  padding: ${isMobileOnly ? '16px' : '24px 40px'};
  background: url(${isMobileOnly ? BackgroundMobile : Background});
  background-size: 100% 100%;
  margin-top: ${(props) => props.top ?? 0}px;
  align-items: inline;
`

const LineContainer = styled.div<{ top?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${(props) => props.top ?? 0}px;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`

const Title = styled.div`
  font-weight: 500;
  font-size: ${isMobileOnly ? 18 : 20}px;
  color: #222b45;
`

const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #21304e;
  margin-bottom: 16px;
`

const BottomButton = styled.div<{ filled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px 31px;
  color: ${(props) => (props.filled ? 'white' : '#b202ff')};
  background: ${(props) => (props.filled ? '#b202ff' : 'white')};
  border: 1px solid #b202ff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;

  & :hover {
    opacity: 0.8;
  }

  & :active {
    opacity: 0.5;
  }
`

interface IProps {
  onReschedule: () => void
}

function PreStartRescheduleComponent({ onReschedule }: IProps) {
  const joinButtonLabel = 'Reschedule'

  return (
    <Container>
      <TitleContainer>
        <Title>Rescheduling policy</Title>
      </TitleContainer>
      <InfoText>
        We know you are busy and we understand that life happens. Our experts are working
        professionals who are here to support you and we ask you respect their time and
        try give them at least 24 hours notice of rescheduling.
      </InfoText>
      {isMobileOnly && (
        <>
          <BottomButton onClick={onReschedule}>{joinButtonLabel}</BottomButton>
        </>
      )}
      {!isMobileOnly && (
        <>
          <LineContainer top={18}>
            <BottomButton onClick={onReschedule}>{joinButtonLabel}</BottomButton>
          </LineContainer>
        </>
      )}
    </Container>
  )
}

export default PreStartRescheduleComponent
