import React, { useCallback, useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import chevron from 'src/static/chevron-down.svg'
import { InterviewSort } from '../../api'

const SortWrapper = styled.div`
  position: relative;
`

interface ISortButtonProps {
  expanded: boolean
}
const SortButton = styled.div<ISortButtonProps>`
  position: relative;
  padding-right: 25px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: all 200ms;
  }
`

const SortItems = styled.div`
  position: absolute;
  background: #fff;
  padding: 15px 0;
  z-index: 5;
  border-radius: 10px;
  box-shadow: -10px 2px 29px 0 #edeef2;
  width: 150px;
`

interface ISortItemProps {
  isSelected: boolean
}

interface ISortProps {
  onSelect: (val) => void
}

const SortItem = styled.div<ISortItemProps>`
  padding: 5px 25px;
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const Sort = ({ onSelect }: ISortProps) => {
  const sortRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState<InterviewSort>('updated_at')

  const handleSelect = useCallback(
    (val: InterviewSort) => () => {
      onSelect(val)
      setVisible(false)
      setSelected(val)
    },
    [onSelect]
  )

  const toggleVisible = useCallback(() => {
    setVisible((prev) => !prev)
  }, [])

  const clickOutsideDropMenu = useCallback((e) => {
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    !(sortRef.current && sortRef.current.contains(e.target)) && setVisible(false)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideDropMenu)
    return () => {
      document.removeEventListener('mousedown', clickOutsideDropMenu)
    }
  }, [clickOutsideDropMenu])

  return (
    <SortWrapper ref={sortRef}>
      <SortButton expanded={visible} onClick={toggleVisible}>
        Sort by
      </SortButton>
      {visible && (
        <SortItems>
          <SortItem
            isSelected={selected === 'updated_at'}
            onClick={handleSelect('updated_at')}
          >
            Last updated
          </SortItem>
          <SortItem
            isSelected={selected === 'candidate_name'}
            onClick={handleSelect('candidate_name')}
          >
            Applicant name
          </SortItem>
          <SortItem
            isSelected={selected === 'scheduled_time'}
            onClick={handleSelect('scheduled_time')}
          >
            Coaching date
          </SortItem>
          {/* <SortItem isSelected={selected === 'status'} onClick={handleSelect('status')}>
            Status
          </SortItem> */}
          <SortItem
            isSelected={selected === 'hired_chance'}
            onClick={handleSelect('hired_chance')}
          >
            Success chance
          </SortItem>
        </SortItems>
      )}
    </SortWrapper>
  )
}

export default Sort
