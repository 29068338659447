import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RadioButtonGroup, TextBold } from '../../components'
import { agencySelectors } from '../../redux'

import BulkBookingForm from '../forms/BulkBookingForm'
import SingleBookingForm from '../forms/SingleBookingForm'
import Layout from '../Layout'

enum InterviewType {
  Expert = 'Expert Coaching',
  Ava = 'Ava Coaching',
}
const InterviewTypes = [InterviewType.Expert, InterviewType.Ava]

enum BookingType {
  Single = 'Single invite',
  Bulk = 'Bulk invite',
}
const BookingTypes = [BookingType.Bulk, BookingType.Single]

function NewBooking() {
  const agency = useSelector(agencySelectors.data)
  const [interviewType, setInterviewType] = useState(InterviewType.Expert)
  const [bookingType, setBookingType] = useState(BookingType.Single)

  useEffect(() => {
    if (agency?.enable_ava && !agency.enable_experts) {
      setInterviewType(InterviewType.Ava)
      setBookingType(BookingType.Bulk)
    } else {
      setInterviewType(InterviewType.Expert)
      setBookingType(BookingType.Single)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency])

  return (
    <Layout title="New Booking">
      <>
        <TextBold>CHOOSE YOUR OPTION</TextBold>

        {agency.enable_ava && agency.enable_experts && (
          <RadioButtonGroup
            value={interviewType}
            onChange={setInterviewType}
            useTheme={false}
            options={InterviewTypes.map((o) => {
              return { text: o, value: o }
            })}
          />
        )}

        <RadioButtonGroup
          value={bookingType}
          onChange={setBookingType}
          useTheme={false}
          options={BookingTypes.map((o) => {
            return { text: o, value: o }
          })}
        />
        {bookingType === BookingType.Single && (
          <SingleBookingForm isAvaInterview={interviewType === InterviewType.Ava} />
        )}
        {bookingType === BookingType.Bulk && (
          <BulkBookingForm isAvaInterview={interviewType === InterviewType.Ava} />
        )}
      </>
    </Layout>
  )
}

export default NewBooking
