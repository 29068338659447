import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import CloseImg from 'src/static/close.svg'
import { css } from '@emotion/core'
import SvgRadioButton from '../../static/components/SvgRadioButton'
import CheckboxEmpty from '../../static/checkbox_empty.png'
import { ExpertDeclineReason } from '../../api'

const Container = styled.div`
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(115, 121, 145, 0.3);
  ${isMobileOnly &&
  css`
    backdrop-filter: blur(5px);
    @media (orientation: landscape) {
      overflow-y: auto;
      position: relative;
    }
  `}
`

const DeclineContainer = styled.div`
  width: 466px;
  height: 583px;
  background: white;
  border-radius: 16px;
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin: ${isMobileOnly ? 20 : 0}px;
  ${isMobileOnly &&
  css`
    @media (orientation: landscape) {
      position: absolute;
      top: 0;
    }
  `}
`

const DeclineTitle = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;
  color: #222b45;
  margin: 16px 0 24px;
`

const CloseIcon = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
  align-self: end;
`

const DeclineLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #222b45;
`

const DeclineOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

const DeclineOption = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

const DeclineOptionText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #3b424f;
  margin-left: 22px;
`

const SlotUncheck = styled.img`
  width: 18px;
  height: 18px;
`

const DetailsText = styled.textarea`
  background: #f5f6f8;
  flex: 1;
  border: 1px solid #e1e3ea;
  border-radius: 12px;
  padding: 14px 16px;
  font-weight: 500;
  font-size: 12px;
  outline: 0;
  resize: none;
`

const DeclineButton = styled.div<{ disabled?: boolean }>`
  height: 48px;
  background: #b202ff;
  border-radius: 4px;
  width: 100%;
  color: white;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  &:active {
    opacity: 0.5;
  }
`

interface IProps {
  onDecline: (reason: ExpertDeclineReason, info: string) => void
  onClose: () => void
}

const DeclineOptions: ExpertDeclineReason[] = ['Too busy', 'On leave', 'Bad fit', 'Other']

function DeclineReasonComponent({ onDecline, onClose }: IProps) {
  const [selectedDeclineOption, setSelectedDeclineOption] = useState<ExpertDeclineReason>(
    undefined
  )

  const detailsTextRef = useRef<HTMLTextAreaElement>(null)

  function onDeclineClicked() {
    onDecline(selectedDeclineOption, detailsTextRef?.current?.value)
  }

  return (
    <Container>
      <DeclineContainer>
        <CloseIcon src={CloseImg} alt="" onClick={onClose} />
        <DeclineTitle>I&apos;m declining this session</DeclineTitle>
        <DeclineLabel>Thanks for letting us know.</DeclineLabel>
        <DeclineLabel>To improve our service, may we ask why?</DeclineLabel>
        <DeclineOptionContainer>
          {DeclineOptions.map((option) => {
            const selected = selectedDeclineOption === option
            return (
              <DeclineOption
                key={option}
                onClick={() => setSelectedDeclineOption(option)}
              >
                {selected ? (
                  <SvgRadioButton fill="#B202FF" />
                ) : (
                  <SlotUncheck src={CheckboxEmpty} />
                )}
                <DeclineOptionText>{option}</DeclineOptionText>
              </DeclineOption>
            )
          })}
        </DeclineOptionContainer>
        <DetailsText ref={detailsTextRef} placeholder="Additional info" />
        <DeclineButton onClick={onDeclineClicked}>Decline</DeclineButton>
      </DeclineContainer>
    </Container>
  )
}

export default DeclineReasonComponent
