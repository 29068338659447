import React from 'react'
import styled from '@emotion/styled'
import logo from '../static/logo.png'
import colors from '../static/colors'
import { Title, Subtitle } from '../components'
import sidebar from '../static/sidebar.png'
import sidebar_bg from '../static/sidebar_bg.jpg'

const Layout = styled.div`
  display: flex;
  height: 100%;
`

const Content = styled.div`
  padding: 120px 122px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentWrapper = styled.div``

const Sidebar = styled.div`
  width: 533px;
  height: 100%;
  background-color: ${colors.formBackgroundColor};
  border-radius: 40px 0 0 40px;
  display: flex;
  align-items: flex-end;
  background: url(${sidebar_bg}) 50% 0 no-repeat;
  background-color: #1d0e3b;
  position: relative;
  overflow: hidden;
`

const SidebarImg = styled.img`
  width: 100%;
`

const Header = styled.header``

const Body = styled.div`
  margin-bottom: 50px;
  width: 400px;
`

const Logo = styled.img`
  height: 38px;
  margin-bottom: 50px;
`

const SidebarText = styled.p`
  position: absolute;
  bottom: 0;
  right: 200px;
  color: white;
  width: 174px;
  font-size: 18px;
  text-align: center;
  line-height: 27px;
  bottom: 81px;
  right: 38px;
`

interface IAuthLayoutProps {
  children: React.ReactElement
  sidebarText: string
  title: string
  subtitle?: string
}

function AuthLayout(props: IAuthLayoutProps) {
  const { title, subtitle, children, sidebarText } = props

  return (
    <Layout>
      <Content>
        <ContentWrapper>
          <Header>
            <Logo src={logo} />
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Header>
          <Body>{children}</Body>
        </ContentWrapper>
      </Content>
      <Sidebar>
        <SidebarImg src={sidebar} />
        <SidebarText>{sidebarText}</SidebarText>
      </Sidebar>
    </Layout>
  )
}

AuthLayout.defaultProps = {
  subtitle: '',
}

export default AuthLayout
