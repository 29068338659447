import { format } from 'date-fns'
import moment from 'moment'

import httpClient from '../../httpClient'

import {
  IAgency,
  IExpertProfile,
  IInterview,
  IExpertNote,
  IInterviewPriceTier,
  ICandidateProfile,
  IInterviewRecording,
  IInterviewReport,
  IInterviewTimestamp,
  IInterviewToken,
  CoachingLanguage,
  CoachingType,
  IApplicantNote,
  IInterviewAvailabilityTime,
  IInterviewAvailabilityDays,
} from '../models'

export type ExpertDeclineReason = 'Too busy' | 'On leave' | 'Bad fit' | 'Other'

export type NestedAttibutes<T> = {
  [id: number]: T
}

export interface IGetInterviewResponse {
  agency?: IAgency
  applicant_notes?: IApplicantNote[]
  experts?: IExpertProfile[]
  expert_notes?: IExpertNote[]
  interview: IInterview
  interview_price_tiers?: IInterviewPriceTier[]
  interview_recording?: IInterviewRecording
  janus_server_url?: string
  janus_server_url2?: string
  janus_plugin?: string
  peer?: ICandidateProfile | IExpertProfile
  profile?: ICandidateProfile | IExpertProfile
  report?: IInterviewReport
  timestamps?: IInterviewTimestamp[]
  token?: IInterviewToken
  video_start_at?: string
}

export interface IInterviewPositionState {
  firstName?: string
  name?: string
  lastName?: string
  request?: string
  requestApplicant?: string
  company?: string
  location?: string
  link?: string
  description?: string
  date?: string
  desiredPosition?: string
  programmingLanguage?: string
  email?: string
  link_to_position?: string
  priceTears?: any[]
  coachingLanguage?: CoachingLanguage
  coachingType?: CoachingType
}

export interface IInterviewCandidateState {
  firstName: string
  lastName: string
  email: string
  linkedin?: string
  availabilityDays: IInterviewAvailabilityDays
  availabilityTime: IInterviewAvailabilityTime
  timezone?: string
  candidateAvailableAt?: number[]
}

export interface IUpdateInterviewPayload {
  position: IInterviewPositionState
  student: IInterviewCandidateState
  interviewToken?: string
  agree_terms_and_privacy: boolean
  agree_to_share: boolean
  agree_to_receive_offers: boolean
  cv_file_id: string
  interview_price_tiers_attributes?: NestedAttibutes<IInterviewPriceTier>
}

export interface ICreateInterviewPayload {
  position: IInterviewPositionState
  student: IInterviewCandidateState
  agencyId?: string
  agree_terms_and_privacy: boolean
  agree_to_share: boolean
  agree_to_receive_offers: boolean
  cv_file_id: string
  interview_price_tiers_attributes?: NestedAttibutes<IInterviewPriceTier>
}
export interface IClientSecret {
  clientSecret: string
}
export interface ILinkConversationBody {
  conversationId: string
}

export interface ISendFeedbackPayload {
  interviewToken: string
  rating: number
  expertRating: number
  feedback: string
}

// interviews#create - POST /interviews
export const createInterview = (payload) => {
  const {
    position,
    student,
    agencyId,
    agree_terms_and_privacy,
    agree_to_share,
    agree_to_receive_offers,
    cv_file_id,
    interview_price_tiers_attributes,
  } = payload

  return httpClient
    .post(`/interviews`, {
      candidate: {
        first_name: student.firstName,
        last_name: student.lastName,
        email: student.email,
        linkedin_profile: student.linkedin,
        time_zone: student.timezone,
      },
      interview: {
        desired_position: position.name,
        programming_language: position.programmingLanguage,
        position_company: position.company,
        position_location: position.location,
        link_to_position: position.link,
        position_description: position.description,
        interview_date: position.date
          ? format(new Date(position.date), 'dd-MM-yyyy')
          : '',
        special_request: position.request,
        agree_terms_and_privacy,
        agree_to_share,
        agree_to_receive_offers,
        cv_file_id,
        interview_price_tiers_attributes,
        coaching_language: position.coachingLanguage,
        coaching_type: position.coachingType,
        availability_days: student.availabilityDays,
        availability_time: student.availabilityTime,
      },
      agency_id: agencyId,
    })
    .then((response) => response.data)
}

// interviews#show - GET /interviews/:interviewToken
export const getInterview = (interviewToken) => {
  return httpClient.get(`/interviews/${interviewToken}`).then((response) => response.data)
}

// interviews#update - PUT /interviews/:interviewToken
export const updateInterview = (payload) => {
  const {
    position,
    student,
    interviewToken,
    agree_terms_and_privacy,
    agree_to_share,
    agree_to_receive_offers,
    cv_file_id,
    interview_price_tiers_attributes,
  } = payload

  return httpClient
    .put(`/interviews/${interviewToken}`, {
      candidate: {
        first_name: position.firstName || student.firstName,
        last_name: position.lastName || student.lastName,
        email: position.email || student.email,
        linkedin_profile: student.linkedin,
        time_zone: student.timezone,
      },
      interview: {
        desired_position: position.desiredPosition || position.name,
        programming_language: position.programmingLanguage,
        position_company: position.company || position.company,
        position_location: position.location,
        link_to_position: position.link_to_position || position.link,
        position_description: position.description,
        interview_date: position.date
          ? format(new Date(position.date), 'dd-MM-yyyy')
          : '',
        special_request: position.request,
        agree_terms_and_privacy,
        agree_to_share,
        agree_to_receive_offers,
        cv_file_id,
        interview_price_tiers_attributes,
        coaching_language: position.coachingLanguage,
        coaching_type: position.coachingType,
        availability_days: student.availabilityDays,
        availability_time: student.availabilityTime,
      },
    })
    .then((response) => response.data)
}

// interviews#applicant_notes - GET /interviews/:interview_token/applicant_notes
export const getApplicantNotes = (interviewToken) => {
  return httpClient
    .get(`/interviews/${interviewToken}/applicant_notes`)
    .then((response) => response.data)
}

// interviews#assign_time_slot - POST /interviews/:interview_token/assign_time_slot
export const assignTimeSlot = (interviewToken, time_zone, time_slot_id) => {
  return httpClient
    .post(`/interviews/${interviewToken}/assign_time_slot`, { time_zone, time_slot_id })
    .then((response) => response.data)
}

// interviews#code_sharing - POST /interviews/:interview_token/code_sharing
export const interviewCodeSharing = (interviewToken) => {
  return httpClient
    .post(`/interviews/${interviewToken}/code_sharing`)
    .then((response) => response.data)
}

// interviews#conversation - GET /interviews/:interview_token/conversation
export const getConversation = (interviewToken) => {
  return httpClient
    .get(`/interviews/${interviewToken}/conversation`)
    .then((response) => response.data || {})
}

// interviews#decline_availability - POST /interviews/:interview_token/decline_availability
export const declineAvailability = (expertToken, expertComment, declineReason) => {
  return httpClient
    .post(`/interviews/${expertToken}/decline_availability`, {
      expert: {
        comment: expertComment || undefined,
        decline_reason: declineReason,
      },
    })
    .then((response) => response.data)
}

// interviews#end - POST /interviews/:interview_token/end
export const endInterview = (interviewToken) => {
  return httpClient
    .post(`/interviews/${interviewToken}/end`)
    .then((response) => response.data)
}

// interviews#feedback - PUT /interviews/:interview_token/feedback
export const sendFeedback = ({ rating, expertRating, feedback, interviewToken }) => {
  return httpClient
    .put(`/interviews/${interviewToken}/feedback`, {
      rating,
      expert_rating: expertRating,
      text: feedback,
    })
    .then((response) => response.data)
}

// interviews#link_conversation - POST /interviews/:interview_token/link_conversation
export const linkConversation = (interviewToken, conversation_id) => {
  return httpClient
    .post(`/interviews/${interviewToken}/link_conversation`, { conversation_id })
    .then((response) => response.data)
}

// interviews#move_to_alternative_link - POST /interviews/:interview_token/move_to_alternative_link
export const moveToAlternativeLink = (interviewToken) => {
  return httpClient
    .post(`/interviews/${interviewToken}/move_to_alternative_link`)
    .then((response) => response.data)
}

// interviews#pay - GET /interviews/:interview_token/pay
export const createIntent = (interviewToken) => {
  return httpClient
    .post(`/interviews/${interviewToken}/pay`)
    .then((response) => response.data)
}

// interviews#pay_sandbox - POST /interviews/:interview_token/pay_sandbox

// interviews#pay_succeed - POST /interviews/:interview_token/pay_succeed
export const paySucceed = (interviewToken, payment_intent_id, payment_method) => {
  return httpClient
    .post(`/interviews/${interviewToken}/pay_succeed`, {
      payment_intent_id,
      payment_method,
    })
    .then((response) => response.data)
}

// interviews#remove_applicant_note - POST /interviews/:interview_token/remove_applicant_note
export const removeApplicantNote = (interviewToken, note_id) => {
  return httpClient
    .post(`/interviews/${interviewToken}/remove_applicant_note`, { note_id })
    .then((response) => response.data)
}

// interviews#remove_expert_note - POST /interviews/:interview_token/remove_expert_note
export const removeExpertNote = (interviewToken, expert_note) => {
  return httpClient
    .post(`/interviews/${interviewToken}/remove_expert_note`, { expert_note })
    .then((response) => response.data)
}

// interviews#report - POST /interviews/:interview_token/report
export const reportInterview = (interviewToken, response_id) => {
  return httpClient
    .post(`/interviews/${interviewToken}/report`, { response_id })
    .then((response) => response.data)
}

// interviews#reschedule - POST /interviews/:interview_token/reschedule
export const candidateRescheduleInterview = (
  interviewToken,
  interviewParams,
  rescheduleParams
) => {
  return httpClient
    .post(`/interviews/${interviewToken}/reschedule`, {
      interview: interviewParams,
      reschedule_request: rescheduleParams,
    })
    .then((response) => response.data)
}

// interviews#save_applicant_note - POST /interviews/:interview_token/save_applicant_note
export const sendApplicantNote = (interviewToken, text, note_id?) => {
  return httpClient
    .post(`/interviews/${interviewToken}/save_applicant_note`, { note_id, text })
    .then((response) => response.data)
}

// interviews#save_expert_note - POST /interviews/:interview_token/save_expert_note
export const saveExpertNote = (interviewToken, expert_note) => {
  return httpClient
    .post(`/interviews/${interviewToken}/save_expert_note`, { expert_note })
    .then((response) => response.data)
}

// interviews#set_interview_timestamp - POST /interviews/:interview_token/set_interview_timestamp
export const setInterviewTimestamp = (interviewToken, name, time) => {
  return httpClient
    .post(`/interviews/${interviewToken}/set_interview_timestamp`, { name, time })
    .then((response) => response.data)
}

// interviews#set_recording_timestamp - POST /interviews/:interview_token/set_recording_timestamp
export const setRecordingTimestamp = (interviewToken, recording_timestamp) => {
  return httpClient
    .post(`/interviews/${interviewToken}/set_recording_timestamp`, {
      recording_timestamp,
    })
    .then((response) => response.data)
}

// interviews#start - POST /interviews/:interview_token/start
export const startInterview = (interviewToken) => {
  return httpClient
    .post(`/interviews/${interviewToken}/start`)
    .then((response) => response.data)
}

// interviews#stop - POST /interviews/:interview_token/stop
export const stopRecording = (interviewToken) => {
  return httpClient
    .post(`/interviews/${interviewToken}/stop`)
    .then((response) => response.data)
}

// interviews#submit_availability - POST /interviews/:interview_token/submit_availability
export const submitAvailability = (expertToken, timeSlots, expertComment?) => {
  return httpClient
    .post(`/interviews/${expertToken}/submit_availability`, {
      expert: {
        comment: expertComment,
        time_slots_from: timeSlots.map((ts) => Math.floor(ts / 1000)),
      },
      time_zone: moment.tz.guess(),
    })
    .then((response) => response.data)
}
