import React from 'react'
import styled from '@emotion/styled'
import { IRemoteAudioTrack } from 'agora-rtc-sdk-ng'

import audioMutedIcon from '../../static/candidate_cockpit_muted.png'
import MicVolumeIndicator from './MicVolumeIndicator'
import VideoMutedOverlay from './VideoMutedOverlay'

const Video = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 100%;

  div {
    background-color: #edeef2 !important;
  }
`

const MuteIcon = styled.img`
  width: 40px;
  height: 40px;
  background: black;
  border-radius: 100px;
  opacity: 0.8;
`

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  align-items: center;
`

const VideoSmallResizeContainer = styled.div<{ sidebar: boolean }>`
  box-sizing: border-box;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 100%;
  background-color: #353c5a;
  display: flex;
  justify-content: center;
  height: ${({ sidebar }) => (sidebar ? '180px' : '100%')};
  margin: ${({ sidebar }) => (sidebar ? '5px 0' : '0')};
  border-radius: ${({ sidebar }) => (sidebar ? '5px' : '0')};
`

interface IProps {
  id: string
  userName: string
  userAvatar: string
  videoMuted: boolean
  audioMuted: boolean
  sidebar: boolean
  track: IRemoteAudioTrack
}

const SmallVideo = ({
  id,
  userName,
  userAvatar,
  videoMuted,
  audioMuted,
  sidebar,
  track,
}: IProps) => {
  return (
    <VideoSmallResizeContainer sidebar={sidebar}>
      <Video id={id} />
      {videoMuted && <VideoMutedOverlay logo={userAvatar} username={userName} />}
      <IconsContainer>
        {!audioMuted && <MicVolumeIndicator track={track} />}
        {audioMuted && <MuteIcon src={audioMutedIcon} />}
      </IconsContainer>
    </VideoSmallResizeContainer>
  )
}

export default SmallVideo
