/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import SvgCloseDrawer from 'src/static/components/SvgCloseDrawer'
import { checkPersonalEmail, validateEmail } from 'src/utils/string'
import { useDispatch } from 'react-redux'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import { LandingButton, LandingInfo, LandingTitle } from './landing-styled-components'
import InputStyled from './InputStyled'
import { requestDemoAction } from '../../redux'

const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 2, 36, 0.87);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`

const DataContainer = styled.div`
  position: relative;
  width: 704px;
  height: auto;
  border-radius: 32px;
  background: #f0e4f8;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 899px) {
    width: 552px;
    padding: 40px 40px 80px 40px;
  }

  ${isMobileOnly &&
  css`
    height: 371px !important;
    width: 100% !important;
    max-width: 552px;
    padding: 0 24px !important;
    max-height: 100% !important;
  `}
`

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 40px;
  width: 20px;
  height: 20px;
  right: 40px;
  z-index: 10;

  @media screen and (max-width: 899px) {
    top: 24px;
    right: 24px;
  }

  > svg {
    width: 100%;
  }
`

const EmailHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #0e0224;
  margin-bottom: 8px;
`

const EmailInfo = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #0e0224;
  white-space: break-spaces;
  margin-bottom: 32px;

  ${isMobileOnly &&
  css`
    font-size: 12px !important;
    line-height: 16px !important;
  `}
`

const LandingTitleStyled = styled(LandingTitle)`
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 24px;
  text-align: start;

  @media screen and (max-width: 899px) {
    font-size: 40px;
    line-height: 48px;
  }

  ${isMobileOnly &&
  css`
    font-size: 24px !important;
    line-height: 32px !important;
  `}
`

const SuccessTitle = styled(LandingTitleStyled)`
  @media screen and (max-width: 1280px) {
    margin: 10px 0;
    width: 260px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
`

const SuccessDataContainer = styled(DataContainer)<{ show?: boolean }>`
  background: #b202ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.4s;
  pointer-events: none;
`

const LandingInfoStyled = styled(LandingInfo)``

interface IProps {
  onClose?: () => void
}

const LandingDemoPopup = ({ onClose }: IProps) => {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const handleBookDemo = useCallback(() => {
    if (!email) {
      setError('Please enter your email address')
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email address')
    } else if (checkPersonalEmail(email)) {
      setError('Please enter a valid work email address')
    } else {
      setError('')
      setSuccess(true)
      dispatch(requestDemoAction(email))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  const onContainerClick = (ev) => {
    ev.stopPropagation()
  }

  useEffect(() => {
    setError('')
  }, [email])

  return (
    <Layout onClick={onClose}>
      <DataContainer onClick={onContainerClick}>
        <CloseButton onClick={onClose}>
          <SvgCloseDrawer fill={success ? 'white' : '#B202FF'} />
        </CloseButton>
        <LandingTitleStyled>Ready to see Wizco in action?</LandingTitleStyled>
        <EmailHeader>Work Email</EmailHeader>
        <InputStyled
          placeholder="email"
          value={email}
          onChange={setEmail}
          error={error}
        />
        <EmailInfo>
          {`Try Wizco today and see the results for yourself.\nSchedule a 30-minute demo.`}
        </EmailInfo>
        <ButtonContainer>
          <LandingButton onClick={handleBookDemo}>Book your demo</LandingButton>
        </ButtonContainer>
        <SuccessDataContainer show={success}>
          <LandingInfoStyled color="#fff">Great!</LandingInfoStyled>
          <SuccessTitle uppercase color="#fff" align="center !important">
            We received your request!
          </SuccessTitle>
          <LandingInfoStyled color="#fff">We’ll contact you soon</LandingInfoStyled>
        </SuccessDataContainer>
      </DataContainer>
    </Layout>
  )
}

LandingDemoPopup.defaultProps = {
  onClose: () => {},
}

export default LandingDemoPopup
