import React from 'react'
import styled from '@emotion/styled'

import SvgUser from 'src/static/components/SvgUser'

import {
  HeaderButtonContainer,
  HeaderButtonLabel,
  HeaderIconContainer,
} from './BaseButtons'

export const DetailsButtonLabel = styled(HeaderButtonLabel)<{ active: boolean }>`
  color: ${({ active, theme }: { active: boolean; theme: any }) =>
    active ? theme.textColorActive : theme.textColor};
`

const DetailsButtonContainer = styled(HeaderButtonContainer)`
  cursor: default;
`

interface IDetailsButton extends React.HTMLProps<HTMLDivElement> {
  disabled: boolean
  active: boolean
  userName: string
  theme: any
  onClick: () => void
}

export default function DetailsButton({
  disabled,
  active,
  userName,
  theme,
  children,
  onClick,
}: IDetailsButton) {
  const label = `${userName}'s profile`
  return (
    <DetailsButtonContainer>
      <HeaderIconContainer disabled={disabled} onClick={!disabled ? onClick : undefined}>
        <SvgUser fill={active ? theme.textColorActive : theme.textColor} />
        <DetailsButtonLabel active={active}>{label}</DetailsButtonLabel>
      </HeaderIconContainer>
      {children}
    </DetailsButtonContainer>
  )
}
