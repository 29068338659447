export default {
  dark: {
    bodyBackgroundColor: '#1d0e3b',
    footerBackgroundColor: '#0c051a',
    headerBackgroundColor: '#1d0e3b',
    precallTextColor: '#000000',
    sidebarIconColor: '#353c5a',
    sidebarTitleColor: '#1d0e3b',
    textColor: '#fff',
    textColorActive: '#00de9f',

    modalPermissionBackground: '#0c051a',
    modalPermissionBorder: '#353c5a',
    cameraBlockedAddressBackground:
      'linear-gradient(270deg, #fafafa 0%, rgba(242, 243, 244, 0) 100%)',
    mutedBackgroundColor: 'transparent',
    localPlaceholderBackgroundColor: '255 255 255 / 20%',
  },
  light: {
    bodyBackgroundColor: '#f5f6f8',
    footerBackgroundColor: '#f5f6f8',
    headerBackgroundColor: '#fff',
    precallTextColor: '#000000',
    sidebarIconColor: '#353c5a',
    sidebarTitleColor: '#1d0e3b',
    textColor: '#353c5a',
    textColorActive: '#b202ff',

    modalPermissionBackground: '#fff',
    modalPermissionBorder: '#B202FF',
    cameraBlockedAddressBackground: 'transparent',
    mutedBackgroundColor: '#353c5a',
    localPlaceholderBackgroundColor: '#353C5A',
  },
  mobile: {
    bodyBackgroundColor: '#2B1B4B',
    footerBackgroundColor: '#1D0E3B',
    headerBackgroundColor: '#1D0E3B',
    menuSidebarSeparatorColor: '#324FA5',
    precallTextColor: '#ffffff',
    sidebarBackgroundColor: '#1D0E3B',
    sidebarMaskColor: 'rgba(29, 14, 59, 0.8)',
    sidebarMaskColorLandscape: 'rgba(29, 14, 59, 0.11)',
    textColor: '#fff',
  },
}
