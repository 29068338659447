import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'

import chevron from 'src/static/chevron-down.svg'

import { Input, Text } from '../../../components'
import { EmailRegex } from '../../../utils/regex'

const Container = styled.div`
  border: 1px dashed #e1e3ea;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
`

const Title = styled.label<{ collapsed: boolean }>`
  cursor: pointer;
  margin: 0;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: url(${chevron}) no-repeat center / 12px 12px;
    transform: ${({ collapsed }) => (collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: all 200ms;
  }
`

const List = styled.div<{ collapsed: boolean }>`
  border-top: 1px solid #e1e3ea;
  display: ${({ collapsed }) => (collapsed ? 'none' : 'block')};
  margin-top: 10px;
  padding-top: 10px;
`

const Item = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 24px;

  > div {
    height: 40px;
    margin: 0 5px;

    > input {
      padding-top: 10px;
    }

    > p {
      margin: 0;
      padding-top: 5px;
    }
  }
`

const AddButton = styled(Text)`
  cursor: pointer;
  margin: 0;
  margin-top: 10px;
  text-decoration: underline;
  width: fit-content;
`

const DeleteButton = styled(Text)`
  color: red;
  cursor: pointer;
  margin: 0 10px;
  width: fit-content;
`

interface IProps {
  control: any
  errors: any
}

function AgencyManagers({ control, errors }: IProps) {
  const [collapsed, setCollapsed] = useState(true)
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'agency.agency_managers',
  })

  return (
    <Container>
      <Title collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
        Agency Managers
      </Title>
      <List collapsed={collapsed}>
        {fields.map((item, index) => (
          <Item key={item.id}>
            <Controller
              as={Input}
              name={`agency.agency_managers[${index}].email`}
              label="Email"
              control={control}
              error={errors.agency?.agency_managers?.[index]?.email}
              rules={{
                validate: {
                  validEmail: (value) =>
                    EmailRegex.test(value) ||
                    "That format doesn't look right. Make sure there aren't any typos",
                  unique: (value) =>
                    !fields.some((f) => f.id !== item.id && f.email === value) ||
                    'Email is already added',
                },
              }}
              defaultValue={item.email}
              disabled={!!item.agency_id}
            />
            <Controller
              as={Input}
              name={`agency.agency_managers[${index}].name`}
              label="Name"
              control={control}
              error={errors.agency?.agency_managers?.[index]?.name}
              defaultValue={item.name}
              disabled={!!item.agency_id}
            />
            <DeleteButton onClick={() => remove(index)}>Delete</DeleteButton>
          </Item>
        ))}
        <AddButton onClick={() => append({ email: '', name: '' })}>+ Add</AddButton>
      </List>
    </Container>
  )
}

export default AgencyManagers
