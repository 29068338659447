import { put, takeEvery, call } from 'typed-redux-saga'

import { getBulkInterviews } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { updateBulkInterviewsAction } from '../data/bulkInterviews'

export interface IGetBulkInterviewsAction {
  agencyId: string
}

const {
  action: getBulkInterviewsAction,
  actionFailure: getBulkInterviewsActionFailure,
  actionSuccess: getBulkInterviewsActionSuccess,
  actionSuccessClear: getBulkInterviewsActionSuccessClear,
  actionFailureClear: getBulkInterviewsActionFailureClear,
  actionType: getBulkInterviewsActionType,
  actionTypeFailure: getBulkInterviewsActionFailureType,
  actionTypeSuccess: getBulkInterviewsActionSuccessType,
  reducer: getBulkInterviewsRequestReducer,
  selectors: getBulkInterviewsSelectors,
} = reduxRequestFactory<IGetBulkInterviewsAction>('getBulkInterviews', 'requests')

export {
  getBulkInterviewsAction,
  getBulkInterviewsActionFailure,
  getBulkInterviewsActionSuccess,
  getBulkInterviewsActionSuccessClear,
  getBulkInterviewsActionFailureClear,
  getBulkInterviewsActionType,
  getBulkInterviewsActionFailureType,
  getBulkInterviewsActionSuccessType,
  getBulkInterviewsRequestReducer,
  getBulkInterviewsSelectors,
}

function* getBulkInterviewsSaga(action: ISagaAction<IGetBulkInterviewsAction>) {
  try {
    const { agencyId } = action.payload
    const data = yield* call(getBulkInterviews, agencyId)
    yield put(getBulkInterviewsActionSuccess())
    yield put(updateBulkInterviewsAction(data))
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      getBulkInterviewsActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchGetBulkInterviews() {
  yield takeEvery(getBulkInterviewsActionType, getBulkInterviewsSaga)
}
