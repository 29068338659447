import React from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import Lottie from 'react-lottie-player'
import lottieJson from 'src/animation/buttonAnimation.json'
import { ButtonBase } from '../elements/styled-components'

const Container = styled(ButtonBase)`
  position: relative;
  width: 185px;
  height: 56px;
  background: ${({ theme }: any) => {
    const color =
      !theme.agencyColor || theme.agencyColor === '' ? '#B202FF' : theme.agencyColor
    return color
  }};
  border-radius: 32px;

  ${isMobileOnly &&
  css`
    width: 160px;
    height: 40px;
    align-items: center;
    display: flex;
  `}
`

const DataContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const LottieContainer = styled.div<{ hide?: boolean }>`
  width: 100%;
  height: 100%;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: all 0.4s;
`

interface IProps {
  children: any
  animate: boolean
  onClick: () => void
  disabled?: boolean
}

const AnimatedButton = ({ children, animate, onClick, disabled }: IProps) => {
  return (
    <Container onClick={onClick} disabled={disabled}>
      <LottieContainer hide={!animate}>
        <Lottie loop animationData={lottieJson} play style={{ flex: '1' }} />
      </LottieContainer>
      <DataContainer>{children}</DataContainer>
    </Container>
  )
}

AnimatedButton.defaultProps = {
  disabled: false,
}

export default AnimatedButton
