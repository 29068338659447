import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import * as interviewSelectors from '../../../interview/selectors'
import { Text } from '../MobileText'

import { StatusNotification, MobileStatusNotification } from './BaseNotifications'

const Label = styled(Text)`
  font-size: 24px;
  line-height: 28px;
  @media screen and (max-width: 2500px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 2000px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media screen and (max-width: 900px) {
    font-size: 8px;
    line-height: 9px;
  }
`

export function StartingSoon({ isExpert }: { isExpert: boolean }) {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const label = `Please wait for ${peer.first_name} to join the session`

  return (
    <StatusNotification isExpert={isExpert}>
      <Label>{label}</Label>
    </StatusNotification>
  )
}

const MobileLabel = styled(Text)`
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
`

export function MobileStartingSoon() {
  const interview = useSelector(interviewSelectors.interview)
  const { peer } = interview
  const label = `Please wait for ${peer.first_name} to join the session`

  return (
    <MobileStatusNotification>
      <MobileLabel>{label}</MobileLabel>
    </MobileStatusNotification>
  )
}
