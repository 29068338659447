import React from 'react'
import styled from '@emotion/styled'

import { useSelector } from 'react-redux'
import wrapper_bg from 'src/static/disable-landing-bg.jpg'

import { HyperLink, Text, TextBold } from 'src/components'
import * as interviewSelectors from '../selectors'
import { validateAgencyLogoWhite } from '../../utils/validateAgencyLogo'

const DisabledLandingWrapper = styled.div`
  background: #c9888e url(${wrapper_bg}) no-repeat center / auto 100%;
  width: 100%;
  height: 100%;
`
const InternalWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1210px;
  margin: auto;
  font-family: 'Rubik', sans-serif;
  padding: 100px 0;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 30px;
  }
`

const Logo = styled.div`
  margin-bottom: 80px;
  height: 52px;

  & img {
    height: 100%;
    width: auto;
  }

  @media screen and (max-width: 600px) {
    flex: none;
    max-width: 100%;
    margin-left: 0;
    border-radius: 0;
    overflow: hidden;
    width: 100%;
    height: 35px;
  }
`

const TextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 30px;
  }
`

const BigText = styled(TextBold)`
  color: #fff;
  font-size: 32px;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-bottom: 12px;
    color: #fff;
    font-size: 31px;
    line-height: 37px;
    font-weight: bold;
  }
`
const SmallText = styled(Text)`
  color: #fff;
  font-size: 16px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    padding-bottom: 22px;
    line-height: 25px;
  }
`

const HiddenText = styled.p`
  display: none;
`
const GoBackHomeLink = styled(HyperLink)`
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  &:hover {
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const DisabledLanding = () => {
  const agency = useSelector(interviewSelectors.agency)

  return (
    <DisabledLandingWrapper>
      <InternalWrapper>
        <Logo>
          <img src={validateAgencyLogoWhite(agency.negative_logo?.url)} alt="Logo" />
        </Logo>
        <TextWrapper>
          <BigText>Sorry, you don&apos;t have access to this page</BigText>
          <SmallText>Please contact your account administrator</SmallText>
          <GoBackHomeLink href="https://wizco.io">Back Home</GoBackHomeLink>
          <HiddenText>Find the perfect match</HiddenText>
        </TextWrapper>
      </InternalWrapper>
    </DisabledLandingWrapper>
  )
}

export default DisabledLanding
