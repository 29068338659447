import handleSuccessSaga from 'src/utils/handleSuccessSaga'
import { put, takeEvery, call } from 'typed-redux-saga'

import { updateSystemSettings, getSystemSettings, ISettings } from '../../api'
import handleErrorSaga from '../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../utils/redux'

import { updateSystemSettingsDataAction } from '../data/systemSettings'
import { getSystemSettingsActionSuccess } from './getSystemSettings'

interface IUpdateSystemSettingsAction {
  settings: ISettings
}

const {
  action: updateSystemSettingsAction,
  actionFailure: updateSystemSettingsActionFailure,
  actionSuccess: updateSystemSettingsActionSuccess,
  actionSuccessClear: updateSystemSettingsActionSuccessClear,
  actionFailureClear: updateSystemSettingsActionFailureClear,
  actionType: updateSystemSettingsActionType,
  actionTypeFailure: updateSystemSettingsActionFailureType,
  actionTypeSuccess: updateSystemSettingsActionSuccessType,
  reducer: updateSystemSettingsRequestReducer,
  selectors: updateSystemSettingsSelectors,
} = reduxRequestFactory<IUpdateSystemSettingsAction>('updateSystemSettings', 'requests')

export {
  updateSystemSettingsAction,
  updateSystemSettingsActionFailure,
  updateSystemSettingsActionSuccess,
  updateSystemSettingsActionSuccessClear,
  updateSystemSettingsActionFailureClear,
  updateSystemSettingsActionType,
  updateSystemSettingsActionFailureType,
  updateSystemSettingsActionSuccessType,
  updateSystemSettingsRequestReducer,
  updateSystemSettingsSelectors,
}

function* updateSystemSettingsSaga(action: ISagaAction<IUpdateSystemSettingsAction>) {
  try {
    const { settings } = action.payload
    yield* call(updateSystemSettings, settings)
    yield put(updateSystemSettingsActionSuccess())
    const data = yield* call(getSystemSettings)
    yield put(getSystemSettingsActionSuccess())
    yield put(updateSystemSettingsDataAction(data))
    yield call(handleSuccessSaga, 'Successfully updated')
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      updateSystemSettingsActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchUpdateSystemSettings() {
  yield takeEvery(updateSystemSettingsActionType, updateSystemSettingsSaga)
}
