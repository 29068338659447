import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { isAdminRole } from 'src/utils/jwtToken'
import FeedbackContainer from './containers/CandidateFeedbackContainer'
import ReportContainer from './containers/CandidateReportContainer'
import * as interviewSelectors from '../interview/selectors'

function Report() {
  const interviewData = useSelector(interviewSelectors.interview)
  const { interview } = interviewData
  const token = useSelector(interviewSelectors.token)
  const isAdmin = isAdminRole()
  const isCandidate = token.role === 'candidate'
  const isAllowed = (isCandidate && !isAdmin) || true
  const hasFeedback = interviewData.interview.feedback_rating
  const feedbackExpired = moment().diff(moment(interview.ended_at), 'minutes') > 5

  if (!hasFeedback && isAllowed && !feedbackExpired) {
    return <FeedbackContainer />
  }

  return <ReportContainer />
}

export default Report
