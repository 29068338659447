/* eslint-disable no-param-reassign */
import { useEffect, useRef } from 'react'

let mutationObserver: MutationObserver

function observeDOM(obj, callback) {
  const { MutationObserver } = window
  if (!obj || obj.nodeType !== 1) return

  if (MutationObserver) {
    mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(obj, { childList: true, subtree: true })
    // eslint-disable-next-line consistent-return
    return mutationObserver
  }

  // browser support fallback
  if (window.addEventListener) {
    obj.addEventListener('DOMNodeInserted', callback, false)
    obj.addEventListener('DOMNodeRemoved', callback, false)
  }
}

const useSupport = (showButton: boolean = false) => {
  const intercomButtons = useRef<HTMLElement[]>([])
  const forceHidden = useRef(false)

  const openSupport = () => {
    intercomButtons.current.forEach((button) => button.click())
  }

  const hideLauncher = () => {
    intercomButtons.current.forEach((button) => {
      button.hidden = true
    })
    forceHidden.current = true
  }

  useEffect(() => {
    observeDOM(document.body, (m) => {
      m.forEach((record) => {
        if (record.addedNodes.length > 0) {
          record.addedNodes.forEach((node) => {
            const nodeElement = node as HTMLElement
            if (nodeElement.classList) {
              if (nodeElement.classList.contains('intercom-launcher')) {
                intercomButtons.current.push(nodeElement)
                if (!showButton || forceHidden.current) {
                  nodeElement.hidden = true
                }
              }
              if (nodeElement.classList.contains('intercom-app')) {
                const launchers = nodeElement.getElementsByClassName(
                  'intercom-launcher-frame'
                )
                if (launchers.length > 0) {
                  const launchFrame = launchers[0] as HTMLElement
                  intercomButtons.current.push(launchFrame.parentElement)
                  if (!showButton || forceHidden.current) {
                    launchFrame.hidden = true
                  }
                }
              }
            }
          })
        }
      })
    })
    const iLaunchers = document.getElementsByClassName('intercom-launcher')
    if (iLaunchers.length > 0) {
      const launcher = iLaunchers[0] as HTMLElement
      intercomButtons.current.push(launcher)
      if (!showButton) {
        launcher.hidden = true
      }
    }
    const iApp = document.getElementsByClassName('intercom-app')
    if (iApp.length > 0) {
      const ilaunchers = iApp[0].getElementsByClassName('intercom-launcher-frame')
      if (ilaunchers.length > 0) {
        const launchFrame = ilaunchers[0] as HTMLElement
        intercomButtons.current.push(launchFrame.parentElement)
        if (!showButton) {
          launchFrame.hidden = true
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    openSupport,
    hideLauncher,
  }
}

export default useSupport
