import React from 'react'
import styled from '@emotion/styled'

import closeIcon from 'src/static/close_gray.svg'

import {
  OnboardingPopup,
  MobileOnboardingPopup,
  Close,
  StyledText,
  StyledTitle,
  Nav,
  NextBtn,
  MobileClose,
  MobileStyledText,
  MobileStyledTitle,
  MobileNav,
  MobileNextBtn,
} from './BaseOnboarding'
import { IOnBoardingStep, OnBoardingStep } from './OnBoardingConstants'

const HeaderOnboardingContainer = styled(OnboardingPopup)`
  position: absolute;
  right: 50%;
  top: 100%;
  transform: translate(20%, 0.75rem);

  &:after {
    left: auto;
    right: 19%;
  }
`

const ReactionContainer = styled(OnboardingPopup)`
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -0.75rem);

  &:after {
    bottom: -3px;
    left: 50%;
    top: auto;
    transform: rotate(135deg);
  }
`

const MobileProfileContainer = styled(MobileOnboardingPopup)`
  &:after {
    left: auto;
    right: 27%;
  }

  left: 39px;
  top: 72px;

  @media (orientation: landscape) {
    left: 30%;
    top: 70px;

    &:after {
      right: 23%;
    }
  }
`

const MobileReactionContainer = styled(MobileOnboardingPopup)`
  &:after {
    left: 27%;
    top: auto;
    bottom: -3px;
  }

  left: 28px;
  bottom: 92px;

  @media (orientation: landscape) {
    left: 30%;
    bottom: 75px;

    &:after {
      left: 60%;
    }
  }
`

const MobileReportContainer = styled(MobileOnboardingPopup)`
  &:after {
    left: auto;
    right: 12%;
  }

  right: 15px;
  top: 72px;

  @media (orientation: landscape) {
    right: 28%;
    top: 70px;

    &:after {
      right: 13%;
    }
  }
`

interface IOnBoardingContainer extends React.HTMLProps<HTMLDivElement> {
  stepType: OnBoardingStep
}

const OnBoardingContainer = ({ children, stepType }: IOnBoardingContainer) => {
  switch (stepType) {
    case OnBoardingStep.Reactions:
      return <ReactionContainer>{children}</ReactionContainer>
    default:
      return <HeaderOnboardingContainer>{children}</HeaderOnboardingContainer>
  }
}

interface IOnBoardingContainerMobile extends React.HTMLProps<HTMLDivElement> {
  stepType: OnBoardingStep
  isExpert: boolean
}

const OnBoardingContainerMobile = ({
  children,
  stepType,
  isExpert,
}: IOnBoardingContainerMobile) => {
  if (isExpert)
    switch (stepType) {
      case OnBoardingStep.Profile:
        return <MobileProfileContainer>{children}</MobileProfileContainer>
      case OnBoardingStep.Reactions:
        return <MobileReactionContainer>{children}</MobileReactionContainer>
      case OnBoardingStep.Report:
        return <MobileReportContainer>{children}</MobileReportContainer>
      default:
        return null
    }
  else
    switch (stepType) {
      case OnBoardingStep.Notes:
        return <MobileProfileContainer>{children}</MobileProfileContainer>
      case OnBoardingStep.Profile:
        return <MobileProfileContainer>{children}</MobileProfileContainer>
      default:
        return null
    }
}

interface IOnBoarding {
  step: IOnBoardingStep
  onClose: () => void
  onNext: () => void
  isExpert?: boolean
}

export function OnBoarding({ step, onClose, onNext, isExpert }: IOnBoarding) {
  const { index, subtitle, subtitleAlt, title, total, type } = step

  const handleClose = (e) => {
    e.stopPropagation()
    onClose()
  }

  const handleNext = (e) => {
    e.stopPropagation()
    onNext()
  }
  const hasNext = index !== total
  const subtitleText = isExpert && subtitleAlt ? subtitleAlt : subtitle

  return (
    <OnBoardingContainer stepType={type}>
      <Close onClick={handleClose} src={closeIcon} />
      {title && <StyledTitle>{title}</StyledTitle>}
      {subtitleText && <StyledText>{subtitleText}</StyledText>}
      <Nav>
        <span>
          {index}/{total}
        </span>
        <NextBtn onClick={hasNext ? handleNext : onClose}>
          {hasNext ? 'Next' : 'Got it'}
        </NextBtn>
      </Nav>
    </OnBoardingContainer>
  )
}

OnBoarding.defaultProps = {
  isExpert: false,
}

interface IOnBoardingMobile {
  step: IOnBoardingStep
  onClose: () => void
  onNext: () => void
  isExpert?: boolean
}

export function OnBoardingMobile({ step, onClose, onNext, isExpert }: IOnBoardingMobile) {
  const { index, subtitle, subtitleAlt, title, total, type } = step

  const hasNext = index !== total
  const subtitleText = isExpert && subtitleAlt ? subtitleAlt : subtitle

  return (
    <OnBoardingContainerMobile stepType={type} isExpert={isExpert}>
      <MobileClose onClick={onClose} src={closeIcon} />
      {title && <MobileStyledTitle>{title}</MobileStyledTitle>}
      {subtitleText && <MobileStyledText>{subtitleText}</MobileStyledText>}
      <MobileNav>
        <span>
          {index}/{total}
        </span>
        <MobileNextBtn onClick={hasNext ? onNext : onClose}>
          {hasNext ? 'Next' : 'Got it'}
        </MobileNextBtn>
      </MobileNav>
    </OnBoardingContainerMobile>
  )
}

OnBoardingMobile.defaultProps = {
  isExpert: false,
}
