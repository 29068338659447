import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'
import React, { useCallback, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'

const Container = styled.div<{ selected: boolean }>`
  color: #222b45;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: ${({ selected }) => (selected ? 'block' : '-webkit-box')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: auto;
  max-height: ${({ selected }) => (selected ? '120px' : '40px')};
  transition: all 0.4s;
  min-height: 40px;
`

const Tooltip = styled.div`
  position: absolute;
  border-radius: 8px;
  border: 1px solid #353c5a;
  background: rgba(24, 11, 49, 0.8);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 8px;
  bottom: 40px;
  right: 18px;
  max-width: 256px;

  @media screen and (max-width: 920px) {
    display: none;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -11px;
    right: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    border-top: 10px solid rgba(24, 11, 49, 0.8);
  }
`

interface IProps {
  text: string
  selected?: boolean
}

const QuestionShortText = ({ text, selected }: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 899px)' }) || isMobileOnly

  const handleMouseMove = useCallback(() => {
    if (!isSmallScreen && !isMobileOnly && text.length > 70) setShowTooltip(true)
  }, [text, isSmallScreen])

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <Container
      selected={selected}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {text}
      {!selected && !isMobileOnly && showTooltip && <Tooltip>{text}</Tooltip>}
    </Container>
  )
}

QuestionShortText.defaultProps = {
  selected: false,
}

export default QuestionShortText
