import httpClient from '../../httpClient'

// general#request_demo - POST /request_demo

export const requestDemo = (email: string) => {
  return httpClient.post(`/request_demo`, { email }).then((response) => response.data)
}

// general#subscribe - POST /subscribe

export const subscribe = (email: string, params: { source: string }) => {
  return httpClient
    .post('/subscribe', { email }, { params })
    .then((response) => response.data)
}
