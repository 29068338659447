import { put, takeEvery, call } from 'typed-redux-saga'

import store from 'src/redux/store'
import { avaSaveAnswer } from '../../../api'
import handleErrorSaga from '../../../utils/handleErrorSaga'
import { ISagaAction, reduxRequestFactory } from '../../../utils/redux'
import { startAvaInterviewFollowUpWatcherAction } from '../../watchers/avaInterviewFollowUpWatcher'
import { updateAvaInterviewAction } from '../../data/ava/interview'

export interface IAvaSaveAnswerAction {
  answer: string
  interviewToken: string
  questionId: string
}

const {
  action: avaSaveAnswerAction,
  actionFailure: avaSaveAnswerActionFailure,
  actionSuccess: avaSaveAnswerActionSuccess,
  actionSuccessClear: avaSaveAnswerActionSuccessClear,
  actionFailureClear: avaSaveAnswerActionFailureClear,
  actionType: avaSaveAnswerActionType,
  actionTypeFailure: avaSaveAnswerActionFailureType,
  actionTypeSuccess: avaSaveAnswerActionSuccessType,
  reducer: avaSaveAnswerRequestReducer,
  selectors: avaSaveAnswerSelectors,
} = reduxRequestFactory<IAvaSaveAnswerAction>('avaSaveAnswer', 'requests')

export {
  avaSaveAnswerAction,
  avaSaveAnswerActionFailure,
  avaSaveAnswerActionSuccess,
  avaSaveAnswerActionSuccessClear,
  avaSaveAnswerActionFailureClear,
  avaSaveAnswerActionType,
  avaSaveAnswerActionFailureType,
  avaSaveAnswerActionSuccessType,
  avaSaveAnswerRequestReducer,
  avaSaveAnswerSelectors,
}

function* avaSaveAnswerSaga(action: ISagaAction<IAvaSaveAnswerAction>) {
  try {
    const { answer: text, interviewToken, questionId: question_id } = action.payload

    const state = store.getState()
    const { data } = state.ava.interview
    const { questions, ...rest } = data
    const answer = { id: `${question_id}_answer`, text }

    yield put(
      updateAvaInterviewAction({
        ...rest,
        questions: questions.map((q) => (q.id === question_id ? { ...q, answer } : q)),
      })
    )

    yield* call(avaSaveAnswer, interviewToken, {
      answer: { text },
      question_id,
    })

    const currentQuestion = questions.find((q) => q.id === state.ava.question.data?.id)
    if (currentQuestion.order === 1) {
      yield put(startAvaInterviewFollowUpWatcherAction({ interviewToken }))
    }

    yield put(avaSaveAnswerActionSuccess())
  } catch (err) {
    const error = err as any
    let errorMessage = 'Server error. Please, contact support'
    if (error.response && error.response.data) {
      errorMessage = error.response.data
    }
    yield call(handleErrorSaga, err)
    yield put(
      avaSaveAnswerActionFailure({
        error: errorMessage,
      })
    )
  }
}

export function* watchAvaSaveAnswer() {
  yield takeEvery(avaSaveAnswerActionType, avaSaveAnswerSaga)
}
