import styled from '@emotion/styled'
import { get } from 'lodash'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { IExpertProfile, InterviewStatus } from '../../api'
import { Button, Autocomplete, RadioButtonGroup, Textarea } from '../../components'
import AvailabilityExpertCalendar from '../../interview/components/AvailabilityExpertCalendar'
import { adminRescheduleInterviewAction } from '../../redux'

import { interview as agencyInterviewSelector } from '../selectors'

const Form = styled.form``

const FormTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
`

const ReschedulingTopContainer = styled.div`
  display: grid;
  padding-bottom: 10px;
  grid-template-columns: 1fr 1fr;

  > div:last-of-type {
    align-items: center;
    justify-content: flex-end;
  }
`

const ReschedulingBottomContainer = styled.div`
  display: grid;
  padding-top: 10px;
  grid-template-rows: 80px 130px 60px;
  grid-gap: 10px;

  > div:first-of-type {
    margin-bottom: 0;
  }

  > div:nth-of-type(2) {
    margin-bottom: 0;
    width: auto;
  }

  > div:last-of-type {
    align-items: center;
    justify-content: flex-end;
  }
`

const ReschedulingSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
`

const BlockWrapper = styled.div`
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid #eee;
`

const ButtonsWrapper = styled.div`
  display: flex;
`

const ButtonStyled = styled(Button)`
  margin: 0;
`

const getTzDate = (date: number, tzOffset: number) => {
  const myTimezoneOffset = moment.tz(moment.tz.guess()).utcOffset() / 60
  const newDate = date - (tzOffset - myTimezoneOffset) * 60 * 60 * 1000
  return newDate
}

const ReschedulingManualOption = 'Select specific time slots'
const ReschedulingOrderFormOption = 'Select group days and times'
const ReschedulingOptions = [ReschedulingManualOption, ReschedulingOrderFormOption]

const ReschedulingForm = () => {
  const dispatch = useDispatch()
  const { id, interviewId } = useParams()

  const interview = useSelector(agencyInterviewSelector.interview)
  const rescheduleRequests = interview.interview.reschedule_requests || []
  const latestReschedule = rescheduleRequests[rescheduleRequests.length - 1]
  const selectedExpert: Partial<IExpertProfile> = get(interview, 'expert_profile', {})

  const candidateTimeZone =
    moment.tz(interview.candidate_profile.time_zone).utcOffset() / 60

  const getStatusOrder = (status: string) => {
    switch (status) {
      case 'Selected':
        return 0
      case 'Availability provided':
        return 1
      case 'Pending availability':
        return 2
      default:
        return 3
    }
  }

  const internalExpertsOptions = useMemo(
    () =>
      (interview.expert_profiles || [])
        .map((profile) => {
          let status = ''
          if (profile.interview_token?.role === 'rejected_expert') {
            status = 'Declined'
          } else if (interview.expert_profile?.id === profile.id) {
            status = 'Selected'
          } else if (profile.time_slots?.length > 0) {
            status = 'Availability provided'
          } else {
            status = 'Pending availability'
          }
          return {
            label: `${profile.first_name} ${profile.last_name} (${status})`,
            value: profile.id,
            status,
          }
        })
        .sort((a, b) => (getStatusOrder(a.status) < getStatusOrder(b.status) ? -1 : 1)),
    [interview]
  )

  const {
    handleSubmit: handleReschedulingSumbit,
    control: reschedulingControl,
    errors: reschedulingErrors,
    formState: { isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      availabilityType: ReschedulingManualOption,
      selectedExperts:
        !latestReschedule || latestReschedule.use_same_expert
          ? internalExpertsOptions.filter((ex) => ex.value === selectedExpert?.id)
          : [],
      selectedSlots: [],
      adminComment: undefined,
    },
  })

  const onReshedulingSubmit = (payload: any) => {
    const time =
      payload.selectedSlots?.map((s) => getTzDate(s, candidateTimeZone)) || undefined
    const expertIds = payload.selectedExperts.map((e) => e.value)
    const confirmed = window.confirm('Are you sure you want to reschedule the session?')
    if (confirmed) {
      dispatch(
        adminRescheduleInterviewAction({
          agencyId: id,
          id: interviewId,
          expertIds,
          time,
          adminComment: payload.adminComment,
        })
      )
    }
  }

  const availabilityType = watch('availabilityType')
  const canReschedule = interview.interview.status === InterviewStatus.SCHEDULED

  return (
    <Form onSubmit={handleReschedulingSumbit(onReshedulingSubmit)}>
      <FormTitle>Rescheduling</FormTitle>
      <BlockWrapper>
        <ReschedulingTopContainer>
          <ReschedulingSubtitle>Applicant Availability</ReschedulingSubtitle>
          <Controller
            name="availabilityType"
            control={reschedulingControl}
            render={({ onChange, value }) => (
              <RadioButtonGroup
                value={value}
                disabled={!canReschedule}
                onChange={onChange}
                useTheme
                options={ReschedulingOptions.map((o) => {
                  return { text: o, value: o }
                })}
              />
            )}
            defaultValue={ReschedulingManualOption}
          />
        </ReschedulingTopContainer>
        {availabilityType === ReschedulingManualOption && (
          <Controller
            name="selectedSlots"
            control={reschedulingControl}
            render={({ onChange, value }) => (
              <AvailabilityExpertCalendar
                selectedDays={value}
                onChange={onChange}
                fullWeek
                useTheme
                disabled={!canReschedule}
                staticTimezone={interview.candidate_profile.time_zone}
              />
            )}
            error={reschedulingErrors.selectedSlots}
            rules={{
              required: true,
              validate: {
                valid: (value) =>
                  value.length > 0 || 'Please select at least one time slot',
              },
            }}
            defaultValue={[]}
          />
        )}
        <ReschedulingBottomContainer>
          <Controller
            as={Autocomplete}
            multiple
            useTheme
            disabled={!canReschedule}
            label="Select experts for rescheduling"
            name="selectedExperts"
            error={reschedulingErrors.selectedExperts !== null}
            options={internalExpertsOptions}
            readonlyOptions={internalExpertsOptions.filter(
              (ex) => ex.value === selectedExpert?.id
            )}
            control={reschedulingControl}
            rules={{
              required: true,
              validate: {
                valid: (value) => value.length > 0 || 'Please select at least one expert',
              },
            }}
            customOnchange={() => {}}
            defaultValue={[]}
          />
          {canReschedule && (
            <>
              <Controller
                as={Textarea}
                label="System admin comment"
                name="adminComment"
                control={reschedulingControl}
                rules={{
                  maxLength: { value: 250, message: 'Max characters : 250' },
                }}
                value=""
                useTheme
              />
              <ButtonsWrapper>
                <ButtonStyled
                  disabled={!isValid}
                  useTheme
                  type="submit"
                  title="Reschedule session"
                />
              </ButtonsWrapper>
            </>
          )}
        </ReschedulingBottomContainer>
      </BlockWrapper>
    </Form>
  )
}

export default ReschedulingForm
