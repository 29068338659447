import React from 'react'
import styled from '@emotion/styled'
import SvgMute from 'src/static/components/SvgMute'
import SvgUnMute from 'src/static/components/SvgUnMute'

const Container = styled.div<{ toTheRight?: boolean }>`
  width: 26px;
  height: 26px;
  padding: 2px;
  cursor: pointer;
  display: flex;
  z-index: 10;
  margin-left: ${({ toTheRight }) => (toTheRight ? 'auto' : '0')};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`

interface IProps {
  fill?: string
  muted?: boolean
  toTheRight: boolean
  onClick?: () => void
}

const MuteButton = ({ fill, muted, toTheRight, onClick }: IProps) => {
  return (
    <Container onClick={onClick} toTheRight={toTheRight}>
      {!muted && <SvgMute fill={fill} />}
      {muted && <SvgUnMute fill={fill} />}
    </Container>
  )
}

MuteButton.defaultProps = {
  fill: '#fff',
  muted: false,
  onClick: () => {},
}

export default MuteButton
