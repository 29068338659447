import { CancelToken } from 'axios'

import httpClient from '../../httpClient'

// cv_files#create - POST /cv_files

// eslint-disable-next-line import/prefer-default-export
export const uploadCV = (
  file: any,
  onProgress: (number) => void,
  cancelToken: CancelToken = null
) => {
  const formData = new FormData()

  formData.append('file', file)
  return httpClient
    .post('/cv_files', formData, {
      cancelToken,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (data) => {
        onProgress(Math.round(data.loaded / data.total))
      },
    })
    .then((response) => response.data)
}
