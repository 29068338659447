import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { isMobileOnly } from 'react-device-detect'
import { css } from '@emotion/core'
import withProps from 'recompose/withProps'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const DataContainer = styled.div<{ isOpen: boolean; maxHeight?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: ${({ isOpen, maxHeight }) =>
    isOpen ? `${(maxHeight || 230) + 96}px` : '96px'};
  transition: max-height 0.2s linear;
  overflow: hidden;
`

const QuestionStyled = styled.div<{ isOpen: boolean; hover: boolean }>`
  font-weight: ${({ hover, isOpen }) =>
    // eslint-disable-next-line no-nested-ternary
    !isMobileOnly ? (hover ? 700 : 400) : isOpen ? 700 : 400};
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  min-height: 96px;
  color: #000000;
  cursor: pointer;
  width: 100%;
  border-bottom: ${({ isOpen }) => (isOpen ? 0 : 1)}px solid #e1e3ea;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1439px) {
    font-size: 20px;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
  }

  ${isMobileOnly &&
  css`
    font-size: 16px !important;
    line-height: 19px;
    color: #21304e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 32px);
  `}
`

const AnswerWrapper = styled.div`
  background: rgba(225, 227, 234, 0.2);
  display: flex;
  justify-content: center;
  width: 100%;
`

const AnswerStyled = styled.div`
  width: 765px;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #21304e;
  padding: 24px 0;
  white-space: break-spaces;

  @media screen and (max-width: 1439px) {
    font-size: 20px;
  }

  @media screen and (max-width: 899px) {
    font-size: 16px;
  }

  ${isMobileOnly &&
  css`
    font-size: 16px !important;
    line-height: 150%;
    color: #21304e;
    margin: 0 18px;
  `}
`

const PlusIcon = withProps()(styled.div`
  position: absolute;
  top: 76px;
  width: 36px;
  height: 36px;
  background: ${({ theme }: any) =>
    !theme.agencyColor || theme.agencyColor === '' ? '#b202ff' : theme.agencyColor};
  border-radius: 18px;
  color: white;
  font-size: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  cursor: pointer;
  left: 50%;
  opacity: 0.8;
  transform: translateX(-50%);

  ${isMobileOnly &&
  css`
    top: 76px;
  `}
`)

const Href = styled.a``

interface IProps {
  question: string
  answer: string
  link?: string
  linkUrl?: string
}

const FaqBlock = ({ question, answer, link, linkUrl }: IProps) => {
  const answerBlockRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [hover, setHover] = useState(false)

  return (
    <>
      {isMobileOnly && (
        <Container>
          <DataContainer isOpen={isOpen} maxHeight={answerBlockRef.current?.clientHeight}>
            <QuestionStyled
              onClick={() => setIsOpen((v) => !v)}
              hover={hover}
              isOpen={isOpen}
            >
              {question}
            </QuestionStyled>
            <AnswerWrapper ref={answerBlockRef}>
              <AnswerStyled>
                {answer}
                {link && (
                  <Href href={linkUrl} target="_blank">
                    {link}
                  </Href>
                )}
              </AnswerStyled>
            </AnswerWrapper>
          </DataContainer>
          <PlusIcon onClick={() => setIsOpen((v) => !v)}>{isOpen ? '-' : '+'}</PlusIcon>
        </Container>
      )}
      {!isMobileOnly && (
        <Container
          onMouseMove={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <DataContainer isOpen={isOpen} maxHeight={answerBlockRef.current?.clientHeight}>
            <QuestionStyled
              onClick={() => setIsOpen((v) => !v)}
              hover={hover}
              isOpen={isOpen}
            >
              {question}
            </QuestionStyled>
            <AnswerWrapper ref={answerBlockRef}>
              <AnswerStyled>
                {answer}
                {link && (
                  <Href href={linkUrl} target="_blank">
                    {link}
                  </Href>
                )}
              </AnswerStyled>
            </AnswerWrapper>
          </DataContainer>
          {hover && (
            <PlusIcon onClick={() => setIsOpen((v) => !v)}>{isOpen ? '-' : '+'}</PlusIcon>
          )}
        </Container>
      )}
    </>
  )
}

FaqBlock.defaultProps = {
  link: '',
  linkUrl: '',
}

export default FaqBlock
