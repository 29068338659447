import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { isSuperAdminRole } from 'src/utils/jwtToken'
import logo from '../static/logo.png'
import colors from '../static/colors'
import agency_sidebar_bg from '../static/agency_sidebar_bg.jpg'
import { signOut } from '../auth/actions'

const Layout = styled.div`
  display: flex;
  min-height: 100vh;
`

const Content = styled.div`
  padding: 100px 140px;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  @media screen and (max-width: 1440px) {
    padding: 100px 100px;
  }
  @media screen and (max-width: 1300px) {
    padding: 100px 50px;
  }
`

const Sidebar = styled.div`
  width: 255px;
  background-color: ${colors.formBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 65px;
`

const SidebarRight = styled.div`
  width: 300px;
  background-color: ${colors.formBackgroundColor};
  background: url(${agency_sidebar_bg}) 0 0 no-repeat;
  background-color: #6938ab;
`

const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Body = styled.div`
  margin-bottom: 50px;
`

const WizcoLogo = styled.img`
  width: 80px;
  height: 20px;
  margin-bottom: 50px;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SignOut = styled.p`
  color: #3b424f;
  font-size: 12px;
  line-height: 48px;
  letter-spacing: 0.6px;
  height: 48px;
  box-sizing: border-box;
  padding-left: 40px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`

const MenuLink = styled(NavLink)`
  color: #3b424f;
  &.${(props) => props.activeClassName} {
    border-right: 5px solid #b202ff;
    font-weight: bold;
  }
  font-size: 12px;
  line-height: 48px;
  letter-spacing: 0.6px;
  height: 48px;
  box-sizing: border-box;
  padding: 0 40px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
  }
  display: block;
  margin: 0;
  min-width: 210px;
`

interface IAuthLayoutProps {
  children: React.ReactElement
  showRightBar?: boolean
}

function AuthLayout(props: IAuthLayoutProps) {
  const dispatch = useDispatch()
  const { children, showRightBar } = props

  const isSuperAdmin = isSuperAdminRole()

  return (
    <Layout>
      <Sidebar>
        <SidebarBody>
          <Menu>
            <SignOut onClick={() => dispatch(signOut())}>Sign out</SignOut>
          </Menu>
          <MenuLink activeClassName="active" exact to="/admin">
            Agencies
          </MenuLink>
          <MenuLink activeClassName="active" exact to="/admin/overview">
            Overview
          </MenuLink>
          {/* <MenuLink activeClassName="active" exact to="/admin/dashboard">
            Dashboard
          </MenuLink> */}
          {isSuperAdmin && (
            <MenuLink activeClassName="active" exact to="/admin/settings">
              System settings
            </MenuLink>
          )}
        </SidebarBody>
        <WizcoLogo src={logo} />
      </Sidebar>
      <Content>
        <Body>{children}</Body>
      </Content>
      {showRightBar && <SidebarRight />}
    </Layout>
  )
}

AuthLayout.defaultProps = {
  showRightBar: false,
}

export default AuthLayout
